import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'common'
import {
  PixsyGrid,
  PixsyGridItem,
  PixsyButtonGroup,
} from '../../containers/Cases/CaseSubmission/Components'

export default function MatchUrlOffline({ onIgnore, url }) {
  return (
    <PixsyGrid alignContent="stretch" direction="column" alignItems="center">
      <h4>Confirm online status</h4>
      <p>
        Pixsy has detected that the match image file does not link to an
        online/active image. This may occur if the image has been removed since
        the last we scanned, or if there are too many redirects for our system
        to accurately perform a check. Please check that this matched image is
        still online before submitting this case{/*action.toLowerCase()*/}.
      </p>

      <PixsyGridItem style={{ textAlign: 'center' }}>
        <b>Image URL:</b>
        <ul>
          <li>
            {url ? (
              <a target="_blank" href={url} rel="noreferrer">
                {url}
              </a>
            ) : (
              <p>Url field is blank</p>
            )}
          </li>
        </ul>
      </PixsyGridItem>
      <PixsyGridItem>
        <PixsyButtonGroup>
          <Button
            full
            onClick={() =>
              (window.location.href = `mailto:support@pixsy.com?subject=Blocked by: Matched image unavailable&body=##### Do not edit ####${'<br/>'}URL: ${
                window.location.href
              }${'<br/>'}${'<br/>'}Blocked by: Matched image unavailable${'<br/>'}${'<br/>'}We aim to respond to all queries within 48 hours. Please leave this information intact and write your question below the line.${'<br/>'}###################`)
            }
            grey
          >
            Contact us
          </Button>
          <Button full hoverMain onClick={onIgnore}>
            Ignore
          </Button>
        </PixsyButtonGroup>
      </PixsyGridItem>
    </PixsyGrid>
  )
}

MatchUrlOffline.propTypes = {
  onIgnore: PropTypes.func.isRequired,
  url: PropTypes.string,
}
