import React from 'react'
import { Tooltip } from '@material-ui/core'
import styled from 'react-emotion'
import { withStyles } from '@material-ui/core/styles'

const TruncateText = ({ text, link = false }) => {
  const maxStringLength = window.innerWidth > 1500 ? 70 : 30
  const truncated =
    text.length > maxStringLength
      ? `${text.slice(0, maxStringLength / 2)} ... ${text.slice(-maxStringLength / 2)}`
      : text

  return link ? (
    <TruncateTextLinkStyled href={text} target="_blank" rel="noreferrer">
      <TooltipStyled
        title={<span style={{ wordBreak: 'break-all', color: '#ffffff', fontSize: 15, lineHeight: 1.5 }}>{text}</span>}
      >
        <p style={{ margin: 0 }}>{truncated}</p>
      </TooltipStyled>
    </TruncateTextLinkStyled>
  ) : (
    <TooltipStyled
      title={<span style={{ wordBreak: 'break-all', color: '#ffffff', fontSize: 15, lineHeight: 1.5 }}>{text}</span>}
    >
      <p style={{ margin: 0 }}>{truncated}</p>
    </TooltipStyled>
  )
}

export default TruncateText

const TruncateTextLinkStyled = styled.a`
  color: #212121;
  &:hover {
    color: #00758d;
  }
`

const TooltipStyled = withStyles(() => ({
  tooltip: {
    maxWidth: 550,
  },
}))(Tooltip)
