import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, ChooseBestImageSource, Dialog, SexyThumbnail } from 'common'
import { connect } from 'react-redux'
import styled, { css, cx } from 'react-emotion'
import { withRouter } from 'react-router'
import { notifSend } from 'redux-notifications/lib/actions'
import * as Icons from '../Icons'
import { assembleClusterByIds } from '../../../../../redux/selectors'
import { ignoreClusters, load } from '../../../../../redux/modules/clusters'

class IgnoreDialog extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const previousClusters = prevProps.selectedClusters
    const currentClusters = this.props.selectedClusters

    const previouslyClustersRemaining = previousClusters.some((c) =>
      c.tags.includes('hidden:true')
    )
    const nowAllHidden = currentClusters.every((c) =>
      c.tags.includes('hidden:true')
    )

    if (previouslyClustersRemaining && nowAllHidden) {
      this.props.onClose()
    }
  }
  createIgnoreHandlerForAction = (action) => async () => {
    const { selection, ignoreClusters, notifSend, onClose } = this.props

    notifSend({
      message: 'Ignoring matches. Please wait...',
      kind: 'info',
      dismissAfter: 10e3,
    })

    await ignoreClusters(selection, action)
    onClose()
  }

  render() {
    const {
      isOpen,
      loading,
      onClose,
      onIgnoreDomain,
      selectedClusters,
      updatingClusters,
    } = this.props

    const matchesCount = selectedClusters.reduce(
      (total, cluster) => total + cluster.matches.length,
      0
    )
    const selectedClustersWithIgnoredLast =
      selectedClusters.length <= 2
        ? selectedClusters
        : [
            ...selectedClusters.filter(
              (cluster) => !cluster.tags.includes('hidden:true')
            ),
            ...selectedClusters.filter((cluster) =>
              cluster.tags.includes('hidden:true')
            ),
          ]

    return (
      <Dialog isOpen={isOpen} wide onRequestClose={!loading ? onClose : null}>
        <div>
          <h1 css={dialogHeader}>Before you proceed..</h1>
          <p css={dialogDescription}>
            Please note that you will hide {matchesCount} individual match
            {matchesCount !== 1 ? 'es' : ''}.
            <br />
            If you want to hide similar matches as well, please chose{' '}
            <i>Ignore Domain</i>.
          </p>
          <div css={dialogImagesContainer}>
            {selectedClustersWithIgnoredLast.map((c) => (
              <ListEntry
                key={c._id}
                cluster={c}
                onIgnoreDomain={onIgnoreDomain}
                isUpdating={updatingClusters.includes(c._id)}
              />
            ))}
          </div>
          <div css={dialogFooter}>
            <Button
              onClick={this.createIgnoreHandlerForAction('approved')}
              disabled={loading}
              greyOutline
            >
              <span css={dialogFooterButtonLabel}>
                <span>Approve</span>
                {/* {matchesCount}
                {matchesCount > 1 ? ' matches' : ' match'} */}
              </span>
            </Button>
            <Button
              onClick={this.createIgnoreHandlerForAction('ignore')}
              disabled={loading}
            >
              <span css={dialogFooterButtonLabel}>
                <span>Ignore</span>
                {/* {matchesCount}
                {matchesCount > 1 ? ' matches' : ' match'} */}
              </span>
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

function ListEntry({ cluster, onIgnoreDomain, isUpdating }) {
  const firstMatch = cluster.matches[0]

  return (
    <div
      key={cluster._id}
      className={cx(dialogImageRow, selectedRow, isUpdating && updatingRow)}
    >
      <ChooseBestImageSource.ForCluster cluster={cluster}>
        {({ url, isError }) => (
          <div css={dialogThumbnail}>
            <SexyThumbnail
              url={url}
              isError={isError}
              ignored={cluster.tags.includes('hidden:true')}
              approved={cluster.matches[0].approved}
              caption={
                cluster.matches.length > 1 && (
                  <div css={groupSizeIndicator}>
                    <div>
                      <em>{cluster.matches.length}</em>
                      <span>Matches</span>
                    </div>
                  </div>
                )
              }
            />
          </div>
        )}
      </ChooseBestImageSource.ForCluster>
      <dl className={cx(dialogColumn, dialogLeftColumn)}>
        {firstMatch.origin && firstMatch.origin.url && (
          <React.Fragment>
            <dt css={rowHeader}>Page URL</dt>
            <RowDescription nowrap>
              <a href={firstMatch.origin.url} target="_blank">
                {firstMatch.origin.url}
              </a>
            </RowDescription>
          </React.Fragment>
        )}
        <dt css={rowHeader}>Image URL</dt>
        <RowDescription nowrap>
          <a href={firstMatch.url} target="_blank">
            {firstMatch.url}
          </a>
        </RowDescription>
      </dl>
      <dl className={cx(dialogColumn, dialogRightColumn)}>
        {!isUpdating && (
          <ActionButton
            icon={Icons.Ignore}
            onClick={onIgnoreDomain(cluster._id, firstMatch.origin.url)}
            label="IGNORE DOMAIN"
          />
        )}
      </dl>
    </div>
  )
}

export default withRouter(
  connect(
    (state, props) => ({
      selectedClusters: assembleClusterByIds(state, props),
      updatingClusters: state.rules.updatingClusters,
    }),
    { load, notifSend, ignoreClusters }
  )(IgnoreDialog)
)

IgnoreDialog.propTypes = {
  selectedClusters: PropTypes.array.isRequired,
  updatingClusters: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onIgnoreDomain: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
}

ListEntry.propTypes = {
  cluster: PropTypes.object.isRequired,
  onIgnoreDomain: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
}

const ActionButton = ({
  icon: Icon,
  onClick = null,
  width = 56,
  disabled = false,
  label = '',
}) => (
  <a
    href={disabled ? '' : '#'}
    onClick={disabled ? null : onClick}
    style={{ width: `${width}px` }}
    css={cx(actionBtn, disabled ? disabledActionBtn : activeActionBtn)}
  >
    <Icon />
    <span>{label}</span>
  </a>
)
const actionBtn = css`
  color: #696969;
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 12px;

  > svg {
    height: 20px;
  }
`
const activeActionBtn = css`
  opacity: 0.6;
  outline: none !important;
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    color: inherit;
  }
`
const disabledActionBtn = css`
  opacity: 0.2;
  outline: none !important;
`
const groupSizeIndicator = css`
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 40%);
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: flex-end;
  color: #fff;
  text-align: center;
  font-weight: 600;
  padding: 0 0 5px 10px;
  > * {
    display: block;
    margin: 0;
  }
  em {
    display: block;
    font-size: 1.8em;
    font-style: normal;
    position: relative;
    bottom: -10px;
  }
  span {
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: block;
    opacity: 0.5;
  }
`
const dialogImagesContainer = css`
  max-height: 335px;
  overflow-y: auto;
  padding: 5px;
`
const dialogThumbnail = css`
  flex: 215px 0 0;
`
const dialogImageRow = css`
  height: 142px;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s ease-in;
`
const selectedRow = css`
  opacity: 1 !important;
  transition: opacity 0.2s ease-out;
`
const updatingRow = css`
  opacity: 0.4 !important;
  cursor: progress;
`

const dialogColumn = css`
  display: flex;
  height: 100%;
  flex-grow: 1;
  padding: 20px 15px 0;
  flex-direction: column;
  overflow: hidden;
`
const dialogRightColumn = css`
  min-width: 90px;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #dedede;
`
const dialogLeftColumn = css`
  /* min-width: 135px;
  max-width: 135px; */
`
const dialogHeader = css`
  text-align: center;
`
const dialogDescription = css`
  padding-bottom: 20px;
  text-align: center;
`
const rowHeader = css`
  display: block;
  font-size: 0.8em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 2px;
  white-space: nowrap;
`
const RowDescription = styled.dd`
  display: block;
  font-size: 12px;
  margin-bottom: 15px;
  max-height: 40px;
  min-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ nowrap }) => (nowrap ? `white-space: nowrap;` : '')};
`
const dialogFooter = css`
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }
`
const dialogFooterButtonLabel = css`
  display: inline-block;

  > span {
    display: block;
    font-weight: 600;
  }
`
