import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { resolveCountryNameForCode } from 'pixsy-constants'
import COUNTRIES from 'pixsy-constants/countries.json'
import { withRouter } from 'react-router-dom'

import { validateSync } from '../../../../../../../shared/isSubmittableSync'
import { CaseSubmissionErrors } from '../../CaseSubmissionErrors'
import sharedStyles from '../../styles'
import StyledModal from '../../StyledModal'
import MatchSummary from '../../MatchSummary'
import * as Shapes from '../../../utils/shapes'
import getClusterTitle from '../../../utils/getClusterTitle'
import ActionSelectionBox from './ActionSelectionBox'
import { ScrollableContent, InfoColumn, Divider } from '../../InfoSectionElements'

const ChooseActionModal = ({ cluster, matchId, isOpen, isMultiMatch, onRequestClose, history }) => {
  const [DisplayError, setDisplayError] = useState(null)
  const caseSubmitErrors = validateSync(cluster)
  const isCaseSubmittable = !Object.keys(caseSubmitErrors).length
  // FIXME: how it should work in case of domain===undefined ?
  const country = resolveCountryNameForCode((cluster.domain || {}).country || '')
  const match = matchId ? cluster.matches.find((_match) => _match._id === matchId) : cluster.matches[0]

  useEffect(() => {
    try {
      window.mixpanel.track(`Matches.mv3.takeActionModal`, {
        clusterId: cluster.id,
      })
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }, [])

  const checkActive = () => {
    const country = COUNTRIES.find(c => c.iso === cluster.domain.country)
    if(country && country.minimumCCScore && country.minimumCCScore >= cluster.score) return false

    return true
  }

  return (
    <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div css={sharedStyles.modalHeader}>
        <div css={sharedStyles.modalTitleWrapper}>
          <span css={sharedStyles.modalTitle}>Take action</span>
          <span css={sharedStyles.modalSubTitle}>Choose which course of action you wish to take</span>
        </div>
      </div>
      <ScrollableContent>
        <InfoColumn fullWidth>
          <div css={styles.selectionBoxesWrapper}>
            <ActionSelectionBox
              boxType="caseSubmission"
              orangeBorder
              onClick={() => {
                history.replace(window.location.pathname)
                history.push(`/cases/submission/new?from=${cluster._id}`)
              }}
              isMultiMatch={isMultiMatch}
              active={isCaseSubmittable && checkActive()}
              handleDisplayError={() =>
                setDisplayError((prev) =>
                  prev ? null : <CaseSubmissionErrors errors={Object.keys(caseSubmitErrors)} errProps={{ country }} />
                )
              }
            />
            <ActionSelectionBox
              boxType="takedown"
              onClick={() => {
                history.replace(window.location.pathname)
                history.push(`/takedowns/submit/new?from=${cluster._id}`)
              }}
              isMultiMatch={isMultiMatch}
              active
              handleDisplayError={null}
            />
          </div>
          {DisplayError}
          <Divider marginBottom={47} />
          <MatchSummary match={match} cluster={cluster} displayName={getClusterTitle(cluster)} />
        </InfoColumn>
      </ScrollableContent>
    </StyledModal>
  )
}

const styles = {
  selectionBoxesWrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 38px;
    margin-bottom: 40px;
  `,
}

ChooseActionModal.propTypes = {
  cluster: Shapes.Cluster,
  matchId: PropTypes.string,
  isOpen: PropTypes.bool,

  onRequestClose: PropTypes.func.isRequired,
}

export default withRouter(ChooseActionModal)
