import { gql } from '@apollo/client'

export default gql`
  mutation saveUserDetails($values: USER_CONTENT_INPUT!) {
    saveUserDetails(values: $values) {
      _id
      photographerFields
    }
  }
`
