import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { SelectableContext } from 'common'
import { connect } from 'react-redux'
import { withEmptyStates } from '../../../decorators'
import { mergeImages, load, bulkIgnore } from '../../../redux/modules/images'
import { notifSend } from 'redux-notifications/lib/actions'
import ImageCard from './ImageCard'
import NoResults from './NoResults'
import ActionPanel from './ActionPanel/ActionPanel'
import MergeDialog from './ActionPanel/MergeDialog'

export class ImagesCardGrid extends React.Component {
  static SELECTION_LIMIT = 10

  state = {
    merging: false,
    displayMergeDialog: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (state.merging && !props.merging) {
      return {
        merging: false,
      }
    } else if (!state.merging && props.merging) {
      return { merging: true }
    }
    return null
  }

  onMerge = e => {
    e.preventDefault()
    this.setState({
      displayMergeDialog: true,
    })
  }

  onIgnore = async e => {
    const { selection, allImages, bulkIgnore, clear, reload } = this.props

    await bulkIgnore({
      imageIds: selection,
      newState: !allImages[selection[0]].ignored,
    })

    reload()
    clear()
  }

  onDialogClose = () => {
    this.setState({
      displayMergeDialog: false,
    })
  }

  render() {
    const { images, linkbase, mergeImages } = this.props
    const { merging, displayMergeDialog } = this.state

    return (
      <React.Fragment>
        <div css={grid}>
          {images.map(image => (
            <ImageCard key={image._id} linkbase={linkbase} image={image} />
          ))}
        </div>
        <React.Fragment>
          {displayMergeDialog && (
            <MergeDialog
              isOpen
              mergeImages={mergeImages}
              onClose={this.onDialogClose}
              merging={merging}
            />
          )}
          <ActionPanel onMerge={this.onMerge} onIgnore={this.onIgnore} />
        </React.Fragment>
      </React.Fragment>
    )
  }
}

function SelectableCardGrid(props) {
  const handleLimitExceeded = limit => {
    props.notify({
      kind: 'info',
      message: `You have selected the maximum number of items for this action (${limit}).`,
      dismissAfter: 5e3,
    })
  }
  return (
    <SelectableContext.Wrapper
      limit={ImagesCardGrid.SELECTION_LIMIT}
      handleLimitExceeded={handleLimitExceeded}
    >
      <SelectableContext.Consumer>
        {({ selection, clear }) => (
          <ImagesCardGrid {...props} selection={selection} clear={clear} />
        )}
      </SelectableContext.Consumer>
    </SelectableContext.Wrapper>
  )
}

export default connect(
  ({ images, entities }) => ({
    merging: images.merging,
    allImages: entities.images,
  }),
  {
    notify: notifSend,
    load,
    mergeImages,
    bulkIgnore,
  }
)(
  withEmptyStates([
    {
      shouldRender: ({ loading, total }) => !loading && total === 0,
      render: () => <NoResults />,
    },
  ])(SelectableCardGrid)
)

ImagesCardGrid.propTypes = {
  images: PropTypes.array.isRequired,
  allImages: PropTypes.object.isRequired,
  linkbase: PropTypes.object.isRequired,
}

const grid = css`
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  grid-gap: 2.5rem 5.2rem;
  position: relative;

  &:focus {
    outline: 0 !important;
  }
`
