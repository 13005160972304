import React, { Component } from 'react'
import { asyncConnect } from 'redux-connect'
import { Col, Row } from 'react-bootstrap'
import { notifSend } from 'redux-notifications/lib/actions'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { ScrollToTop } from 'common'
import {
  DATA_ELEVIO,
  DROPBOX,
  EYEEM,
  FLICKR,
  GOOGLE,
  humanize,
  INSTAGRAM,
  PHOTODECK,
  PHOTOSHELTER,
  SHOPIFY,
  SMUGMUG,
  TUMBLR,
  UPLOAD,
  ZENFOLIO,
} from 'pixsy-constants'
import { synchronize } from '../../../../redux/modules/images'
import { load as loadPolicy } from '../../../../redux/modules/policy'
import GoogleImport from './GoogleImport'
import DropboxImport from './DropboxImport'
import ModalImport from './ModalImport'
import ImportCard from './ImportCard'
import OAuthImport from './OAuthImport'
import PhotodeckDialog from './PhotodeckDialog'
import PhotoshelterDialog from './PhotoshelterDialog'
import FlickrDialog from './FlickrDialog'
import SmugmugDialog from './SmugmugDialog'
import ZenfolioDialog from './ZenfolioDialog'
import ShopifyDialog from './ShopifyDialog'
import BusinessPlanDialog from './BusinessPlanDialog'
import FTPUploadDialog from './FTPUploadDialog'

function mapStateToProps(state, props) {
  const query = qs.parse(String(props.location.search).substr(1))
  return {
    synchronizeService: query && query.synchronize,
  }
}

export class Import extends Component {
  componentDidMount() {
    const { synchronizeService, synchronize, notifSend, history, location } = this.props

    if (synchronizeService) {
      setTimeout(() => {
        synchronize(synchronizeService)
        notifSend({
          message: 'Importing images from ' + humanize(synchronizeService) + '.',
          kind: 'success',
          dismissAfter: 6e3,
        })
        if (location.search.length) history.replace({ ...location, search: null })
      }, 0)
    }
  }

  render() {
    // TODO: MOVE Import Column right of Sync column
    const { user } = this.props
    return (
      <div css={wrap}>
        <ScrollToTop />
        <Row>
          <Col md={9} xs={12}>
            <h2 className="text-center" data-elevio-selector={DATA_ELEVIO.IMAGES_IMPORT_SYNC}>
              Synchronize
            </h2>
            <Col md={3} sm={4} xs={6}>
              <ModalImport service={FLICKR} component={FlickrDialog} configurable>
                <ImportCard service={FLICKR} />
              </ModalImport>
              <ModalImport service={PHOTOSHELTER} component={PhotoshelterDialog}>
                <ImportCard service={PHOTOSHELTER} />
              </ModalImport>
              <ModalImport service="aws-s3" component={BusinessPlanDialog}>
                <ImportCard service="aws-s3" />
              </ModalImport>
            </Col>

            <Col md={3} sm={4} xs={6}>
              <OAuthImport service={INSTAGRAM} location={location}>
                <ImportCard service={INSTAGRAM} />
              </OAuthImport>
              <ModalImport service={PHOTODECK} component={PhotodeckDialog}>
                <ImportCard service={PHOTODECK} />
              </ModalImport>

              <ModalImport
                service="ftp"
                blurred={user.features && !user.features.ftp}
                component={FTPUploadDialog}
                componentProps={{ user: this.props.user }}
              >
                <ImportCard
                  service="ftp"
                  connected={user.features && user.features.ftp}
                  blurred={user.features && !user.features.ftp}
                />
              </ModalImport>
            </Col>

            <Col md={3} sm={4} xs={6}>
              <OAuthImport service={EYEEM}>
                <ImportCard service={EYEEM} />
              </OAuthImport>
              <ModalImport service={SMUGMUG} location={location} component={SmugmugDialog}>
                <ImportCard service={SMUGMUG} />
              </ModalImport>
              <ModalImport service="api" component={BusinessPlanDialog}>
                <ImportCard service="api" />
              </ModalImport>
            </Col>

            <Col md={3} sm={4} xs={6}>
              <OAuthImport service={TUMBLR}>
                <ImportCard service={TUMBLR} />
              </OAuthImport>
            </Col>
            <Col md={3} sm={4} xs={6}>
              <ModalImport service={ZENFOLIO} component={ZenfolioDialog}>
                <ImportCard service={ZENFOLIO} highlighted />
              </ModalImport>
            </Col>

            <Col md={12} sm={4} xs={12} className="text-center">
              <p css={serviceMissingNote}>
                Your favorite service is missing?
                <br />
                <a className="text-center" href="mailto:feedback@pixsy.com">
                  Let us know
                </a>
              </p>
            </Col>
            <Col md={12} sm={12}>
              <p css={storageDisclaimer}>
                We recommend using a platform or social network to synchronize instead of uploading images directly.
                This way Pixsy can manage metadata and duplicate images for you. For the best possible Pixsy experience,
                we suggest connecting as few different images sources as possible.
              </p>
              <p css={storageDisclaimer}>
                Keep in mind that we do not store synchronized images on our servers — they remain hosted on the
                respective network. If you synchronize your account with Pixsy and then remove images from that source
                or change your privacy settings, it may interrupt Pixsy’s ability to access these images.
                <br />
                <strong>Supported file types for direct file upload: JPG, JPEG, PNG, GIF</strong>
              </p>
            </Col>
          </Col>
          <Col md={3} xs={12} css={mobileWrap}>
            <h2 data-elevio-selector={DATA_ELEVIO.IMAGES_IMPORT_UPLOAD}>Upload</h2>
            <Col xs={4} md={12} css={mobile}>
              <GoogleImport>
                <ImportCard service={GOOGLE} />
              </GoogleImport>
            </Col>
            <Col xs={4} md={12} css={mobile}>
              <DropboxImport>
                <ImportCard service={DROPBOX} />
              </DropboxImport>
            </Col>
            <Col xs={4} md={12} css={mobile}>
              <Link
                to={
                  !String(global.DISABLED_IMPORTS)
                    .split(',')
                    .includes(UPLOAD) && '/images/import/upload'
                }
              >
                <ImportCard service={UPLOAD} />
              </Link>
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
}

export default asyncConnect(
  [
    {
      promise: async function fetchDataForImports({ store: { getState, dispatch } }) {
        if (!getState().policy.data) {
          return dispatch(loadPolicy())
        }
      },
    },
  ],
  mapStateToProps,
  {
    notifSend,
    synchronize,
  }
)(Import)

const mobile = css`
  @media (max-width: 768px) {
    padding-left: 2px;
    padding-right: 2px;

    p {
      font-size: 8px;
    }
  }
`

const mobileWrap = css`
  @media (max-width: 768px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`

const wrap = css`
  h2 {
    margin-bottom: 45px;
    text-align: center;
  }
`

const storageDisclaimer = css`
  color: #3b3b3baa;
  margin-top: 15px;
`

const serviceMissingNote = css`
  font-size: 0.95em;
  color: #777;

  a {
    text-decoration: underline;
  }
`
