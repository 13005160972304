import COUNTRIES from 'pixsy-constants/countries.json'

export function getPriorityLevelOfTag(tag) {
  const matchingBucket = valueBuckets.find(b => b.test(tag))
  const rating = matchingBucket ? matchingBucket.priority : 0
  return rating
}

export default function getTagSuggestions(tags = []) {
  return tags
    .map(({ tag, count }) => ({
      tag,
      count,
      priority: getPriorityLevelOfTag(tag),
    }))
    .filter(t => t.priority >= 0)
    .sort((x, y) => y.priority - x.priority || y.count - x.count)
}

const valueBuckets = [
  {
    priority: 11,
    test: tag => tag === 'pixsy:flagged',
  },
  {
    priority: 10,
    test: tag => tag === 'commercial:true',
  },
  {
    priority: 9,
    test: tag => tag === 'online:true',
  },
  {
    priority: 8,
    test: tag => tag === 'unseen',
  },
  {
    priority: 7,
    test: tag => {
      const [category, maybeIsoCode] = tag.split(':')
      if (category !== 'country') return false
      return isCountryResolvable(maybeIsoCode)
    },
  },
  {
    priority: 6,
    test: tag => tag.startsWith('multi'),
  },
  {
    priority: 5,
    test: tag => tag === 'hd',
  },
  {
    priority: 4,
    test: tag => {
      const [category, maybeIsoCode] = tag.split(':')
      if (category !== 'country') return false
      return isCountryResolvable(maybeIsoCode) === false
    },
  },
  {
    priority: 3,
    test: tag => tag === 'flagged',
  },
  {
    priority: 2,
    test: tag => 'commercial:false',
  },
  {
    priority: 1,
    test: tag => tag.startsWith('online'),
  },
  {
    priority: 0,
    test: tag => tag.startsWith('submitted'),
  },
  {
    priority: -1,
    test: tag => tag === 'category:undefined' || tag === 'country:undefined',
  },
]

function isCountryResolvable(code) {
  if (code === 'undefined') return true
  const matchingCountry = COUNTRIES.find(c => c.iso === code)
  return matchingCountry && matchingCountry.resolution === true
}
