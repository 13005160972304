export default {
  INFRINGEMENT_URL_TOOLTIP: {
    title: 'Where the image is published online',
    body:
      'This is the URL (online address) that is displaying your image without permission.',
    footer: null,
  },
  INFRINGEMENT_FILE_URL_TOOLTIP: {
    title: 'The exact online location of the image file',
    body: `A direct image URL is the exact location of the image on the
      web and usually ends in a common image file extension such as jpg, png, or gif.`,
    elevioId: 69,
  },
}
