import React from 'react'
import { FlickrImportDialogContent } from 'common'

const COOKIE_KEY = 'pixsy-flickr-reminder-seen'

export function FlickrShortcut(props) {
  return (
    <div>
      <FlickrImportDialogContent
        endpoint="flickr-signup"
        enableCloseButton
        {...props}
      />
    </div>
  )
}

FlickrShortcut.eligible = (user, _, cookies) => {
  return (
    !cookies.get(COOKIE_KEY) &&
    user.flickr.registeredWithDedicatedForm &&
    !user.flickr.id
  )
}

export default FlickrShortcut
