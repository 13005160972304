/* global gapi, google, GOOGLE_PICKER_CLIENT_ID, GOOGLE_PICKER_CLIENT_KEY */
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { notifSend } from 'redux-notifications/lib/actions'
import { connect } from 'react-redux'
import { saveImageGoogle } from '../../../../redux/modules/images'
import { GOOGLE } from 'pixsy-constants'
import { css } from 'react-emotion'

export class GoogleImport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pickerApiPromise: null,
      oauthTokenPromise: null,
      displaySpinner: false,
    }

    const DISABLED_IMPORTS = global.DISABLED_IMPORTS || ''
    this._disabled = DISABLED_IMPORTS.split(',').includes(GOOGLE)
    this.mounted = null
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadPickerApi() {
    let { pickerApiPromise } = this.state

    if (!pickerApiPromise) {
      pickerApiPromise = new Promise((resolve, reject) => {
        gapi.load('picker', {
          callback: () => {
            gapi.load('client', {
              callback: () => gapi.client.load('drive', 'v3', resolve),
              onerror: () => {
                reject('Failed to load Google Drive API')
              },
            })
          },
          onerror: () => {
            reject('Failed to load Google Picker')
          },
        })
      })
      this.setState({ pickerApiPromise })
    }

    return pickerApiPromise
  }

  getOauthToken() {
    const { notifSend } = this.props

    let { oauthTokenPromise } = this.state

    if (!oauthTokenPromise) {
      oauthTokenPromise = new Promise((resolve, reject) => {
        gapi.load('auth', {
          callback: () => {
            gapi.auth.authorize(
              {
                client_id: GOOGLE_PICKER_CLIENT_ID,
                scope: [
                  'https://www.googleapis.com/auth/drive.readonly',
                  'https://www.googleapis.com/auth/drive.photos.readonly',
                  'https://www.googleapis.com/auth/drive.metadata.readonly',
                  // On March 31, 2021, support for Google Photos and other non-Drive file types, such as Webcam and YouTube videos, will be deprecated.
                  // https://developers.google.com/picker/docs/reference
                  // TODO: Look into a separate import for Google Photos or a workaround.
                  // 'https://www.googleapis.com/auth/photoslibrary.readonly'
                ],
                immediate: false,
              },
              authResult => {
                if (authResult && !authResult.error) {
                  resolve(authResult.access_token)
                } else {
                  console.error(authResult.error)
                  reject(authResult.error)
                }
              }
            )
          },
        })
      })
      oauthTokenPromise.catch(e => {
        // Better luck next time! (user might have dismissed the permission popup)
        this.setState({ oauthTokenPromise: null })
        notifSend({
          kind: 'warning',
          message:
            'There was an issue authorizing with your Google account. Please ensure you don‘t block pop ups, try again or contact support@pixsy.com if the problem persists.',
          dismissAfter: 15e3,
        })
      })
      this.setState({ oauthTokenPromise })
    }

    return oauthTokenPromise
  }

  upload(oauthToken, images) {
    const { saveImageGoogle, notifSend } = this.props

    setTimeout(() => {
      notifSend({
        kind: 'info',
        message: `Importing ${images.length} image${
          images.length !== 1 ? 's' : ''
        } from Google`,
        dismissAfter: 8e3,
      })
    }, 0)

    return saveImageGoogle(oauthToken, images)
  }

  handleClick = e => {
    const { notifSend } = this.props
    if (this._disabled) {
      return
    }

    if (!(typeof gapi !== 'undefined' && typeof gapi.load === 'function')) {
      // Google API not (yet) loaded
      notifSend({
        kind: 'warning',
        message:
          'Unable to start Google Drive image selection. Please check your pop up blocker or contact support@pixsy.com if the problem persists.',
        dismissAfter: 15e3,
      })
      return
    }

    let preventSpinnerDisplay = false
    Promise.all([this.getOauthToken(), this.loadPickerApi()])
      .then(([oauthToken]) => {
        // Fetch files recursively from selected folders.
        const flattenToFiles = async (files, selectedItems) =>
          Promise.all(
            selectedItems.map(async item => {
              if (item.mimeType !== 'application/vnd.google-apps.folder') {
                files.push(item)
              } else {
                let pageToken = null
                do {
                  const { result } = await gapi.client.drive.files.list({
                    q: `'${item.id}' in parents and (mimeType contains 'image/' or mimeType = 'application/vnd.google-apps.folder')`,
                    fields: 'nextPageToken, files(id, name, mimeType)',
                    pageSize: 1000, // Max 1000
                    spaces: 'drive,photos',
                    pageToken,
                  })
                  pageToken = result.nextPageToken

                  // Push images to files array.
                  files.push(
                    ...result.files.filter(
                      file =>
                        file.mimeType !== 'application/vnd.google-apps.folder'
                    )
                  )

                  // Process folders further until it can be pushed as images.
                  await flattenToFiles(
                    files,
                    result.files.filter(
                      file =>
                        file.mimeType === 'application/vnd.google-apps.folder'
                    )
                  )
                } while (!!pageToken)
              }
            })
          )

        // Construct a new view that includes folders, and images only from the root folder.
        const view = new google.picker.DocsView(
          google.picker.ViewId.DOCS_IMAGES
          // On March 31, 2021, support for Google Photos and other non-Drive file types, such as Webcam and YouTube videos, will be deprecated.
          // https://developers.google.com/picker/docs/reference
          // TODO: Look into a separate import for Google Photos or a workaround.
          // google.picker.ViewId.ViewId.PHOTOS
        )
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true)
          .setParent('root')

        // Initialize the Google file picker.
        const picker = new google.picker.PickerBuilder()
          .addView(view)
          .setOAuthToken(oauthToken)
          .setDeveloperKey(GOOGLE_PICKER_CLIENT_KEY)
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setCallback(async data => {
            if (
              data[google.picker.Response.ACTION] ===
              google.picker.Action.PICKED
            ) {
              const selectedItems = data[google.picker.Response.DOCUMENTS]

              setTimeout(() => {
                if (!preventSpinnerDisplay) {
                  this.setState({
                    displaySpinner: true,
                  })
                }
              }, 3000)

              // Fetch files for selected folders, and keep selected files.
              const documents = []
              await flattenToFiles(documents, selectedItems)

              const photos = documents.map(doc => ({
                id: doc.id,
                url: `https://www.googleapis.com/drive/v3/files/${
                  doc[google.picker.Document.ID]
                }?alt=media`,
                title: doc[google.picker.Document.NAME],
              }))

              await this.upload(oauthToken, photos)
              preventSpinnerDisplay = true

              if (this.mounted) {
                this.setState({
                  displaySpinner: false,
                })
              }
            }
          })
          .build()

        picker.setVisible(true)
      })
      .catch(error => {
        preventSpinnerDisplay = true
        this.setState({
          displaySpinner: false,
        })

        notifSend({
          kind: 'warning',
          message:
            'Unable to start Google Drive image selection. Please reload the page and try again or contact support@pixsy.com if the problem persists.',
          dismissAfter: 15e3,
        })

        console.error(error)
      })
  }

  render() {
    return (
      <div onClick={this.handleClick}>
        {this.state.displaySpinner && (
          <div css={spinnerWrapper}>
            <div css={spinnerInner}>
              <div css={circle} />
              <div css={spinnerText}>
                Please don’t close this browser tab. <br />
                We are importing your Google Drive images...
              </div>
            </div>
          </div>
        )}
        <Helmet>
          <script async defer src="//apis.google.com/js/api.js" />
        </Helmet>
        {this.props.children}
      </div>
    )
  }
}

GoogleImport.propTyes = {
  saveImageGoogle: Function,
  notifSend: Function,
}

const spinnerWrapper = css`
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.85);
  left: 0;
  top: 0;
`

const spinnerInner = css`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin: 15px;
  max-width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const spinnerText = css`
  text-align: center;
  margin-top: 0.5rem;
`

const circle = css`
  display: inline-block;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 3px solid transparent;
  border-top-color: #008aab;
  border-right-color: #008aab;
  border-radius: 50%;
  margin-bottom: 40px;
  animation: move 0.8s linear infinite;

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      border-radius: 50%;
    }
  }
`

export default connect(null, { notifSend, saveImageGoogle })(GoogleImport)
