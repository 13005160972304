import * as React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'

export default function ActionButton({
  icon = null,
  onClick,
  active,
  disabled,
  text,
  ...props
}) {
  return (
    <button
      className={cx(actionButton, active && !disabled && activeBtn)}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {icon} {text}
    </button>
  )
}
ActionButton.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
}

const activeBtn = css`
  color: #008aab;
  svg {
    color: #008aab;
  }
`
const actionButton = css`
  align-items: center;
  background: none;
  border: none;
  color: #3f3f3f;
  display: flex;
  font-size: 0.8em;
  letter-spacing: 0.25em;
  height: 22.48px;
  justify-content: flex-start;
  line-height: 22px;
  margin: 16.82px 0 26px;
  outline: none;
  padding: 0;
  text-transform: uppercase;
  transition-duration: 0.4s;
  width: 100%;
  opacity: 0.8;

  &:hover {
    color: #000;
    opacity: 1;

    svg {
      color: #000;
    }
  }

  &:disabled {
    color: rgba(63, 63, 63, 0.5);
    svg {
      color: rgba(63, 63, 63, 0.5);
    }
  }

  @media (max-width: 768px) {
    width: auto;
    float: left;
    margin: 16.82px 20px 16.82px 0;
  }

  svg {
    color: #3f3f3f;
    margin-right: 14px;
    padding: 1px;
  }
`
