import {
  ActionSection,
  Button,
  ContentSection,
  OffSectionTooltip,
} from 'common'
import PropTypes from 'prop-types'
import React from 'react'
import { PHASE } from '../../../../../../shared/forms/registrationSubmission'

export default function SubmissionFormSubmit({
  onWaypoint,
  loading,
  values,
  phase,
  registration,
  onSubmit,
  registrationId,
  validate,
}) {
  let label = resolveLabel(
    phase,
    loading,
    registrationId,
    registration,
    validate,
    values
  )
  const isSave = requiresSave(registrationId, registration, validate, values)
  return (
    <ContentSection id="submit">
      {isSave && (
        <OffSectionTooltip>
          After saving you will be prompted to complete the registration.
        </OffSectionTooltip>
      )}
      <ActionSection last>
        <Button
          hoverMain
          onClick={isSave ? () => onSubmit(true) : onSubmit}
          disabled={loading || phase === PHASE.FINAL}
        >
          {label}
        </Button>
      </ActionSection>
    </ContentSection>
  )
}

SubmissionFormSubmit.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
}

function requiresSave(registrationId, registration, validate, values) {
  if (typeof validate !== 'function') return true
  const errors = validate(values)
  if (errors && Object.keys(errors).length > 0) return true
  const paymentReceived =
    registration &&
    (registration.isFreeSubmission ||
      (registration.salesforce && registration.salesforce.PaymentReceivedD__c))

  return registrationId === 'new' || !registrationId || !paymentReceived
}

export function resolveLabel(
  phase,
  loading,
  registrationId,
  registration,
  validate,
  values
) {
  if (phase === PHASE.FINAL) return 'Processing'

  if (loading) {
    return phase !== PHASE.FINAL ? 'Saving...' : 'Submitting...'
  }

  return requiresSave(registrationId, registration, validate, values)
    ? 'Save'
    : 'Complete Registration'
}
