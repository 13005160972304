import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as reduxAsyncConnect } from 'redux-connect'
import { routerReducer as router } from 'react-router-redux'
import { reducer as notifs } from 'redux-notifications'

import images, {
  paginationKey as imagesPaginationKey,
  IMAGES_INVALIDATE,
  IMAGES_LOAD_SUCCESS,
} from './images'
import clusters, {
  paginationKey as clustersPaginationKey,
  CLUSTERS_INVALIDATE,
  CLUSTERS_LOAD_SUCCESS,
} from './clusters'
import rules, { RULES_LOAD_SUCCESS, RULES_INVALIDATE } from './rules'
// paginationKey as rulesPaginationKey,
import takedowns, {
  paginationKey as takedownPaginationKey,
  TAKEDOWNS_LOAD_SUCCESS,
  TAKEDOWNS_INVALIDATE,
} from './takedowns'

import cases, {
  paginationKey as casesPaginationKey,
  CASES_INVALIDATE,
  CASES_LOAD_SUCCESS,
  CASE_DELETE_DRAFT_SUCCESS,
} from './cases'
import paginate from './paginate'
import auth from './auth'
import plans from './plans'
import policy from './policy'
import entities from './entities'
import charges from './charges'
import registrations, {
  paginationKey as registrationsPaginationKey,
  REGISTRATIONS_INVALIDATE,
  REGISTRATIONS_LOAD_SUCCESS,
} from './registrations'

const pagination = combineReducers({
  imagesByPage: paginate(
    'images',
    IMAGES_LOAD_SUCCESS,
    IMAGES_INVALIDATE,
    [],
    ({ payload: { query } }) => imagesPaginationKey(query)
  ),
  clustersByPage: paginate(
    'clusters',
    CLUSTERS_LOAD_SUCCESS,
    CLUSTERS_INVALIDATE,
    [],
    ({ payload: { query } }) => clustersPaginationKey(query)
  ),
  // usersByPage: paginate(
  //   'users',
  //   USERS_LOAD_SUCCESS,
  //   USERS_INVALIDATE,
  //   ({ payload: { query } }) => usersPaginationKey(query)
  // ),
  // domainsByPage: paginate(
  //   'domains',
  //   DOMAINS_LOAD_SUCCESS,
  //   DOMAINS_INVALIDATE,
  //   ({ payload: { query } }) => domainsPaginationKey(query)
  // ),
  casesByPage: paginate(
    'cases',
    CASES_LOAD_SUCCESS,
    CASES_INVALIDATE,
    [CASE_DELETE_DRAFT_SUCCESS],
    ({ payload: { query } }) => casesPaginationKey(query)
  ),
  registrationsByPage: paginate(
    'registrations',
    REGISTRATIONS_LOAD_SUCCESS,
    REGISTRATIONS_INVALIDATE,
    [],
    ({ payload: { query } }) => registrationsPaginationKey(query)
  ),
  rulesByTab: paginate(
    'rules',
    RULES_LOAD_SUCCESS,
    RULES_INVALIDATE,
    [],
    () => 'all'
  ),
  takedownsByPage: paginate(
    'takedowns',
    TAKEDOWNS_LOAD_SUCCESS,
    TAKEDOWNS_INVALIDATE,
    [],
    ({ payload: { query } }) => takedownPaginationKey(query)
  ),
})

/**
 * @see http://rackt.org/redux/docs/api/combineReducers.html
 */
export default combineReducers({
  router,
  form: formReducer,
  entities,
  notifs,
  pagination,
  auth,
  cases,
  images,
  clusters,
  plans,
  policy,
  takedowns,
  registrations,
  rules,
  charges,
  reduxAsyncConnect,
  // domains,
  // caseSubmission,
  // users,
  // copyrightRegistration,
  // modal,
  // banners,
  // formkit,
})
