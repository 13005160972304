import React, { Component } from 'react'
import { css, cx } from 'react-emotion'
import { PayPalButton } from 'common'
import StripePaymentForm from './StripePaymentForm'
import StripeLastCCForm from './StripeLastCCForm'

export default class PaymentsContainer extends Component {
  constructor(props) {
    super(props)
    const { user } = props

    this.setStripe = () => this.setState({ method: 'stripe' })
    this.setPaypal = () => this.setState({ method: 'paypal' })
    this.setLastCC = () => this.setState({ method: 'lastCc' })

    this.state = {
      method:
        (user && user.stripe && user.stripe.last4 && 'lastCc') || 'stripe',
    }
  }

  render() {
    const { registration, onPaymentSuccess, user } = this.props
    const { method } = this.state

    const paymentFormProps = {
      registration,
      onPaymentSuccess,
      user,
    }

    return (
      <div css={wrap}>
        {user && user.stripe && user.stripe.last4 && (
          <div css={methodSelection}>
            <button
              className={cx({ active: method === 'lastCc' })}
              onClick={this.setLastCC}
            >
              Saved Card
            </button>
            <button
              className={cx({ active: method === 'stripe' })}
              onClick={this.setStripe}
            >
              New Credit Card
            </button>
          </div>
        )}

        <div css={paymentFormWrap}>
          {(method === 'stripe' && (
            <StripePaymentForm {...paymentFormProps} />
          )) ||
            (method === 'lastCc' && (
              <StripeLastCCForm {...paymentFormProps} />
            )) || (
              <PayPalButton
                type="registrations"
                requestId={registration.registration__c}
                onSuccess={onPaymentSuccess}
              />
            )}
        </div>

        <p css={greyNote}>
          By submitting payment you agree to the{' '}
          <a href="https://www.pixsy.com/terms-of-use/" target="_blank">
            Pixsy Terms of Use
          </a>
        </p>
      </div>
    )
  }
}

const wrap = css`
  max-width: 600px;
`

const paymentFormWrap = css`
  margin: 30px auto 10px;
`

const methodSelection = css`
  display: flex;
  > button {
    appearance: none;
    border: 0;
    height: 55px;
    width: 50%;
    font-size: 1.2em;
    font-weight: 400;
    outline: 1px solid #e8e8e8;
    outline-offset: -1px;
    padding: 10px;
    &:active {
      outline: inherit;
    }

    &.active {
      color: #008aab;

      outline: 2px solid #008aab;
      outline-offset: -2px;
    }
  }
`

const greyNote = css`
  color: #3b3b3baa;
`
