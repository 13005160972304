import React from 'react'
import { css } from 'react-emotion'

export default function TwoInputsNextToEachOther({ children }) {
  return <div className={twoInputsNextToEachOther}>{children}</div>
}

const twoInputsNextToEachOther = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  align-content: start;

  > div{
    width: calc(50% - 20px);
  }
`
