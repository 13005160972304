import { gql } from '@apollo/client'
import ClusterFragment from '../queries/cluster.fragment'

export default gql`
  mutation ignoreCluster($clusterId: String!, $state: Boolean!) {
    ignoreCluster(clusterId: $clusterId, state: $state) {
      ...cluster
    }
  }
  ${ClusterFragment}
`
