import * as Sentry from '@sentry/browser'

/* @see https://github.com/captbaritone/raven-for-redux/issues/93#issuecomment-435854873 */
const middleware = store => {
  if (__DEVELOPMENT__) {
    return store => next => action => next(action)
  }

  Sentry.addGlobalEventProcessor(event => {
    const reduxState = store.getState()

    if (!(reduxState.auth && reduxState.auth.user)) {
      return {
        ...event,
        extra: {
          ...event.extra,
          'redux:state': state,
        },
      }
    }

    const {
      auth: {
        user: { email, _id },
      },
      ...state
    } = reduxState

    return {
      ...event,
      extra: {
        ...event.extra,
        'redux:state': state,
      },
      user: {
        ...event.user,
        email,
        _id,
      },
    }
  })

  return next => action => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
    })

    return next(action)
  }
}

export default middleware
