import React, { Component } from 'react'
import { Dialog, Button, PhantomLabelSpacer } from 'common'
import { css } from 'react-emotion'
import DeleteAccountDialog from './DeleteAccountDialog'

export default class DeleteAccountForm extends Component {
  state = {
    modal: null,
  }
  handleModalShow = () => {
    this.setState({ modal: <DeleteAccountDialog /> })
  }

  handleModalClose = () => {
    this.setState({ modal: null })
  }

  render() {
    const { modal } = this.state
    return (
      <div>
        <h4>Delete Account</h4>
        <Dialog isOpen={Boolean(modal)} onRequestClose={this.handleModalClose}>
          {modal}
        </Dialog>
        <PhantomLabelSpacer>
          <div css={mobile}>
            <Button danger onClick={this.handleModalShow}>
              Delete Account
            </Button>
          </div>
        </PhantomLabelSpacer>
      </div>
    )
  }
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

DeleteAccountForm.propTypes = {}
