import React from 'react'
import PropTypes from 'prop-types'
import { Button, DoubleCenteredContent } from 'common'
import { css } from 'react-emotion'

function Stack({ error }) {
  if (!error) {
    console.error('Crashite invoked without error')
    return null
  }
  return (
    <pre css={stack}>
      <div>
        {error.stack &&
          error.stack.split('\n').map((l, idx) => <div key={idx}>{l}</div>)}
      </div>
    </pre>
  )
}

Stack.propTypes = {
  error: PropTypes.object.isRequired,
}

function Oops() {
  const deadImageIcon = require('./deadImageIcon.svg')
  const imageIcon = require('./imageIcon.svg')
  return (
    <React.Fragment>
      <h1 css={h1}>Oops.</h1>
      <div css={stage}>
        <div
          css={gradientLeft}
          dangerouslySetInnerHTML={{ __html: imageIcon }}
        />
        <div dangerouslySetInnerHTML={{ __html: deadImageIcon }} />
        <div
          css={gradientRight}
          dangerouslySetInnerHTML={{ __html: imageIcon }}
        />
      </div>
    </React.Fragment>
  )
}

export default function CrashSite({ children, error }) {
  let node

  node = children || (
    <React.Fragment>
      <p>We are sorry, but you have encountered an error.</p>
      <p>
        Please try again soon, otherwise contact:{' '}
        <a href="mailto:support@pixsy.com">support@pixsy.com</a>
      </p>
    </React.Fragment>
  )

  if (!global.PRODUCTION && error) {
    node = <Stack error={error} />
  }

  return (
    <DoubleCenteredContent>
      <div css={crashSiteCanvas}>
        <Oops />
        {node}

        <a
          href="/"
          onClick={e => {
            if (error) {
              e.preventDefault()
              location.href = location.href
            }
          }}
        >
          <Button>Back</Button>
        </a>
      </div>
    </DoubleCenteredContent>
  )
}

const gradientLeft = css`
  :after {
    z-index: 1;
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -10px;
    left: -10px;
    background: linear-gradient(
      to right,
      #fcfcfc 20%,
      rgba(255, 255, 255, 0.8) 100%
    );
  }
`
const gradientRight = css`
  :after {
    z-index: 1;
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -10px;
    left: -10px;
    background: linear-gradient(
      to left,
      #fcfcfc 20%,
      rgba(255, 255, 255, 0.8) 100%
    );
  }
`

const crashSiteCanvas = css`
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  text-align: center;

  p {
    color: #3b3b3baa;
    font-size: 1.1em;
    line-height: 1.4em;
    max-width: 450px;
    margin: 0 auto 5px;
  }

  button {
    margin-top: 30px;
  }
`

const h1 = css`
  color: #212121;
  font-weight: 600;
  font-size: 84px;
`

const stage = css`
  margin: 65px auto;
  display: flex;

  > * {
    position: relative;
  }

  svg {
    height: 170px;
    border: 0;
    outline: 0;
    margin: 0 30px;
  }
`

const stack = css`
  margin: 0 auto;
  width: 800px;
  text-align: left;
  font-size: 1.2em;

  > * {
    position: relative;
  }

  * {
    white-space: pre-wrap;
  }

  p {
    width: 100%;
    max-width: inherit;
  }

  svg {
    height: 170px;
    border: 0;
    outline: 0;
    margin: 0 30px;
  }

  pre {
    color: #000;
  }
`
