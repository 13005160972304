import { gql } from '@apollo/client'

export default gql`
  query getCurrentUser {
    user {
      _id
      email
      photographerFields
      clusterSummary {
        actionable
        excludedViaPreferences
        tags(all: true) {
          tag
          count
        }
      }
      matchPreferences {
        _id
        hidden {
          countries
          categories
          platforms {
            twitter
            facebook
            instagram
            linkedin
            pinterest
            youtube
            blogspot
            wordpress
            tumblr
            alamy
            shutterstock
            getty
            etsy
            amazon
            ebay
            aliexpress
            flickr
          }
          userContent {
            twitter
            facebook
            linkedin
            instagram
            pinterest
            websites
          }
        }
      }
    }
  }
`
