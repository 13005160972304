import React, { useState, useMemo, useRef, useEffect } from 'react'
import { css, cx } from 'react-emotion'
import Autosuggest from 'react-autosuggest'
import onClickOutside from 'react-onclickoutside'

import { SearchIcon } from '../Icons'
import Suggestion from './Suggestion'
import * as Shapes from '../../utils/shapes'
import useKeypress from '../../utils/useKeypress'
import { addTypeToItems, getSuggestionValue, SEARCH_TYPE, SEARCH_START_KEY_THRESHOLD } from './constants'
import { useMatchViewerStore } from '../../store/context'
import { actionCreators } from '../../store'

const SearchInput = ({ mappedFiltersData }) => {
  const [isInputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const autosuggestRef = useRef(null)
  const { dispatch } = useMatchViewerStore()

  SearchInput.handleClickOutside = () => setInputVisible(false)
  useKeypress('Escape', () => SearchInput.handleClickOutside())

  const getStaticSuggestionsForValue = (value) => {
    return [
      { type: SEARCH_TYPE.DOMAIN, id: value, value },
      { type: SEARCH_TYPE.URL, id: value, value },
    ]
  }

  const removeUndesiredCategories = (items) => {
    return items.filter((item) => item.id !== 'adult')
  }

  const suggestionsDataSet = useMemo(() => {
    const categorySuggestions = addTypeToItems(
      removeUndesiredCategories(mappedFiltersData.categoryItems),
      SEARCH_TYPE.CATEGORY
    )
    const countrySuggestions = addTypeToItems(mappedFiltersData.countryItems, SEARCH_TYPE.COUNTRY)
    const actionSuggestions = addTypeToItems(mappedFiltersData.actionItems, SEARCH_TYPE.ACTION)

    // FIXME: get count from GQL, and hide if === 0
    // move to mapFiltersData.js
    const flagSuggestions = [
      { type: SEARCH_TYPE.FLAG, id: 'isFlagged', value: 'Flagged' },
      {
        type: SEARCH_TYPE.FLAG,
        id: 'isPixsyFlagged',
        value: 'Pixsy Flagged',
      },
    ]

    const staticSuggestions = getStaticSuggestionsForValue('Search') // This is only used to decorate the default suggestion

    const allSuggestions = [...categorySuggestions, ...countrySuggestions, ...actionSuggestions, ...flagSuggestions]

    const oneSuggestionFromEachType = [
      categorySuggestions.sort((a, b) => b.count - a.count)[0],
      countrySuggestions.sort((a, b) => b.count - a.count)[0],
      staticSuggestions[0],
      actionSuggestions[0],
      flagSuggestions[0],
      flagSuggestions[1],
    ]

    return [allSuggestions, oneSuggestionFromEachType]
  }, [mappedFiltersData])

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const [allSuggestions, oneSuggestionFromEachType] = suggestionsDataSet

    if (inputLength === 0) {
      return oneSuggestionFromEachType
    }

    return inputLength < SEARCH_START_KEY_THRESHOLD
      ? []
      : [
          ...allSuggestions.filter((item) => item.value.toLowerCase().includes(inputValue)),
          ...getStaticSuggestionsForValue(value),
        ]
  }

  const inputProps = {
    placeholder: 'Search for name, country, category...',
    value: inputValue,
    onChange,
  }

  function onChange(_, { newValue }) {
    setInputValue(newValue)
  }

  function handleSuggestionsFetchRequested({ value }) {
    setSuggestions(getSuggestions(value))
  }

  function handleSuggestionsClearRequested() {
    setSuggestions([])
  }

  function handleSuggestionSelect(_, { suggestion }) {
    const { type, id } = suggestion

    setInputValue('')
    setInputVisible(false)
    switch (type) {
      case SEARCH_TYPE.COUNTRY:
        dispatch(actionCreators.addSelectedCountryId(id))
        break
      case SEARCH_TYPE.CATEGORY:
        dispatch(actionCreators.addSelectedCategoryId(id))
        break
      case SEARCH_TYPE.ACTION:
        dispatch(actionCreators.addSelectedActionId(id))
        break
      case SEARCH_TYPE.FLAG:
        dispatch(actionCreators.updateSelectedFiltersState({ [id]: true }))
        break
      case SEARCH_TYPE.DOMAIN:
        dispatch(actionCreators.updateSelectedFiltersState({ selectedKeywordPairs: [`domain:${id}`] }))
        break
      case SEARCH_TYPE.URL:
        dispatch(actionCreators.updateSelectedFiltersState({ selectedKeywordPairs: [`url:${id}`] }))
        break
    }
  }

  useEffect(() => {
    if (isInputVisible && autosuggestRef) {
      autosuggestRef.current.input.focus()
    }
  }, [isInputVisible, autosuggestRef])

  useEffect(() => {
    if (suggestions.length) {
      try {
        window.mixpanel.track(`Matches.mv3.searchOpen`)
      } catch (e) {
        console.error(`Unable to invoke mixpanel method:`, e)
      }
    }
  }, [suggestions])

  return (
    <div
      css={cx(styles.container, {
        [styles.containerWhenInputVisible]: isInputVisible,
      })}
    >
      <SearchIcon onClick={() => setInputVisible(!isInputVisible)} css={styles.searchIcon} />
      <Autosuggest
        ref={autosuggestRef}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        onSuggestionSelected={handleSuggestionSelect}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(suggestion) => suggestion && <Suggestion suggestion={suggestion} />}
        inputProps={inputProps}
        alwaysRenderSuggestions
        renderInputComponent={(inputProps) => <input {...inputProps} autofill="pixsy-auto-search" />}
      />
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 25px;
    height: 24px;
    overflow: hidden;

    .react-autosuggest__container {
      width: 100%;

      input {
        width: 100%;
        padding-left: 30px;
        border: none;
        outline: none;
        height: 30px;
        border-bottom: 1px solid #e8e8e8;
        color: #121212;
        font-size: 12px;
        background: white;
      }
    }

    .react-autosuggest__suggestions-container {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          height: 26px;
          display: flex;
          background: white;
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }

    .react-autosuggest__suggestion--highlighted {
      .searchSuggestionItemApplyButton {
        display: block;
      }
    }
  `,
  searchIcon: css`
    top: 6px;
    left: 8px;
    position: absolute;
    cursor: pointer;
  `,
  containerWhenInputVisible: css`
    background-color: #fcfcfc;
    height: auto;
    max-height: 161px;
    right: 0;
    width: 100%;
  `,
}

const clickOutsideConfig = {
  handleClickOutside: () => SearchInput.handleClickOutside,
}

SearchInput.propTypes = {
  mappedFiltersData: Shapes.MappedFiltersData,
}

export default onClickOutside(SearchInput, clickOutsideConfig)
export { SEARCH_TYPE }
