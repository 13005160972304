import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { set, get } from 'lodash'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import {
  Button,
  ContentSection,
  CountrySelect,
  TitleSelect,
  TextField,
  DatePicker,
  AlignLabels,
  PhantomLabelSpacer,
  OffSectionTooltip,
} from 'common'
import { updateUser } from '../../../redux/modules/auth'
import moment from 'moment'

const HalfWidthTextField = props => <TextField width="50%" {...props} />
const ThirdWidthTextField = props => <TextField width="33%" {...props} />

class Form extends Component {
  componentDidMount() {
    const { values, forceValidation, touch, history, location } = this.props
    if (forceValidation) {
      touch(...getFields(values).map(f => f.name))
    } else {
      history.replace({ ...location, state: { forceContactValidation: false } })
    }
  }

  render() {
    const { values, valid, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <ContentSection>
          <AlignLabels align="left" width="160px">
            {getFields(values).map(({ name, render }, idx) => (
              <div key={name}>{render({ name })}</div>
            ))}
          </AlignLabels>
          <PhantomLabelSpacer alignContent="right">
            <div css={mobile}>
              <Button disabled={!valid || submitting} submit>
                Save
              </Button>
            </div>
          </PhantomLabelSpacer>
        </ContentSection>
      </form>
    )
  }
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

const ConnectedForm = reduxForm({
  form: 'contactDetails',
  onSubmit: (values, dispatch) => dispatch(updateUser(values)),
})(Form)

const createHardValidate = fields => values =>
  fields
    .map(field => ({
      name: field.name,
      label: field.validate[0],
      required: field.validate[2],
      maxLength: field.validate[3],
    }))
    .reduce((obj, { name, label, required, maxLength = 255 }) => {
      const value = get(values, name)
      if (!value && required) set(obj, name, `${label} required.`)
      if (String(value).valueOf().length >= maxLength)
        set(obj, name, `${label} must be shorter than ${maxLength} characters.`)
      return obj
    }, {})

const createSoftValidate = fields => values =>
  fields
    .map(field => ({
      name: field.name,
      label: field.validate[0],
      required: field.validate[1],
      maxLength: field.validate[3],
    }))
    .reduce((obj, { name, label, required, maxLength = 255 }) => {
      const value = get(values, name)
      if (!value && required) set(obj, name, `${label} required.`)
      if (String(value).valueOf().length >= maxLength)
        set(obj, name, `${label} must be shorter than ${maxLength} characters.`)
      return obj
    }, {})

export default connect(state => ({
  values:
    state.form.contactDetails && state.form.contactDetails.values
      ? state.form.contactDetails.values
      : {},
}))(function ContactDetailsForm({
  values,
  user,
  forceValidation,
  history,
  location,
}) {
  const fields = getFields(values)
  const validate = forceValidation
    ? createHardValidate(fields)
    : createSoftValidate(fields)
  const props = {
    values,
    user,
    validate,
    history,
    location,
    initialValues: user,
    forceValidation: location.state && location.state.forceContactValidation,
  }
  return <ConnectedForm {...props} />
})

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.object,
  valid: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

function getFields(user) {
  return [
    {
      name: 'details.title',
      render: props => [
        <Field {...props} key="title" label="Title" component={TitleSelect} />,
      ],
      validate: ['Title', true, true],
      // validate(value) {
      //   if (!value) return 'Title required.'
      // },
    },
    {
      name: 'details.first_name',
      render: props => [
        <Field
          {...props}
          key="fname"
          label="First Name"
          component={HalfWidthTextField}
        />,
      ],
      validate: ['First Name', true, true, 40],
    },
    {
      name: 'details.last_name',
      render: props => [
        <Field
          {...props}
          key="lname"
          label="Last Name"
          component={HalfWidthTextField}
        />,
      ],
      validate: ['Last Name', true, true, 40],
    },
    {
      name: 'details.legal_name',
      render: props => [
        <Field
          {...props}
          key="fln"
          label="Full Legal Name"
          component={TextField}
        />,
      ],
      validate: ['Full Legal Name', false, true, 80],
    },
    {
      name: 'details.company',
      render: props => [
        <Field {...props} key="cmp" label="Company" component={TextField} />,
      ],
      validate: ['Company', false, false, 100],
    },
    {
      name: 'email',
      render: props => (
        <React.Fragment>
          <OffSectionTooltip verticalOffset={5}>
            To change your email, go to{' '}
            <Link to="profile/settings#change-email">settings</Link>.
          </OffSectionTooltip>
          <Field
            {...{ ...props, readOnly: true }}
            key="email"
            label="Email"
            component={TextField}
          />
        </React.Fragment>
      ),
      validate: ['Email', false, false, 255],
    },
    {
      name: 'details.date_of_birth',
      render: props => (
        <React.Fragment>
          <OffSectionTooltip verticalOffset={5}>
            During a case, Pixsy is representing you as your agent and in some
            cases commencing legal proceedings. We need full and accurate
            contact information in order to proceed with a case.
          </OffSectionTooltip>
          <Field
            {...props}
            key="dob"
            label="Date of Birth"
            component={DatePicker}
            datepickerProps={{
              minDate: moment().subtract(150, 'years'),
              maxDate: moment().subtract(16, 'years'),
            }}
          />
        </React.Fragment>
      ),
      validate: ['Date of Birth', false, true],
    },
    {
      name: 'details.phone',
      render: props => [
        <Field
          {...props}
          key="phone"
          label="Phone No."
          component={HalfWidthTextField}
        />,
      ],
      validate: ['Phone No.', false, true, 40],
    },
    {
      name: 'details.street',
      render: props => [
        <Field
          {...props}
          key="street"
          label="Street and No."
          component={TextField}
        />,
      ],
      validate: ['Street and No.', false, true, 50],
    },
    {
      name: 'details.city',
      render: props => [
        <Field
          {...props}
          key="city"
          label="City"
          component={HalfWidthTextField}
        />,
      ],
      validate: ['City', false, true, 40],
    },
    {
      name: 'details.postal_code',
      render: props => [
        <Field
          {...props}
          key="zip"
          label="ZIP/Post Code"
          component={ThirdWidthTextField}
        />,
      ],
      validate: ['ZIP/Post Code', false, true, 20],
    },
    {
      name: 'details.state',
      render: props => [
        <Field
          {...props}
          key="state"
          label="State"
          component={HalfWidthTextField}
        />,
      ],
      validate: [
        'State ',
        false,
        user && user.details && user.details.country === 'United States',
        20,
      ],
    },
    {
      name: 'details.country',
      render: props => [
        <Field
          {...props}
          key="Country"
          label="Country"
          component={CountrySelect}
        />,
      ],
      validate: ['Country ', true, true, 80],
    },
  ]
}
