import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { css } from 'react-emotion'
import { ActionButton, ClearbitLogo, Flag } from 'common'
import sharedStyles from '../../styles'
import { CrossIcon } from '../../Icons'
import StyledModal from '../../StyledModal'
import * as Shapes from '../../../utils/shapes'
import resolveValuesFromTags from '../../../utils/resolveValuesFromTags'
import resolveValuesFromDomain from '../../../utils/resolveValuesFromDomain'
import calculateAccuracyFromScore from '../../../utils/calculateAccuracyFromScore'
import { Divider, InfoColumn, RowData, ScrollableContent } from '../../InfoSectionElements'
import { useIgnoreCategory, useIgnoreCountry, useIgnoreDomain } from '../../../utils/useClusters'
import { actionCreators } from '../../../store'
import { useMatchViewerStore } from '../../../store/context'
import HelpIcon from '../../assets/HelpIcon'
import getClearBitLogoFallbackLetter from '../../../utils/getClearBitLogoFallbackLetter'

const MoreModal = ({ cluster, isMultiMatch, isOpen, globalDispatch, onRequestClose, onIgnoreMatchRequest }) => {
  const { dispatch } = useMatchViewerStore()
  const { countryName, countryCode, categoryCode } = resolveValuesFromTags(cluster.tags)
  const { categoryName } = resolveValuesFromDomain(cluster.domain)
  const image = cluster.images[0]

  const closeGroupView = () => {
    dispatch(actionCreators.setGroupMatchesViewClose(cluster._id))
  }

  const [ignoreCategory] = useIgnoreCategory(globalDispatch, categoryName, {
    onCompleted: () => {
      onRequestClose()
      closeGroupView()
    },
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
  })

  const [ignoreCountry] = useIgnoreCountry(globalDispatch, countryName, {
    onCompleted: () => {
      onRequestClose()
      closeGroupView()
    },
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
  })

  const [ignoreDomain] = useIgnoreDomain(globalDispatch, cluster.domain.host, {
    onCompleted: () => {
      onRequestClose()
      closeGroupView()
    },
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
  })

  const handleIgnoreCategory = () => {
    ignoreCategory({ variables: { category: categoryCode, state: true } })
  }

  const handleIgnoreCountry = () => {
    ignoreCountry({ variables: { isoCode: countryCode, state: true } })
  }

  const handleIgnoreDomain = () => {
    ignoreDomain({ variables: { url: cluster.domain.host, clusterId: cluster._id } })
  }

  const handleContactSupportRequest = () => window.open('https://www.pixsy.com/contact/', '_blank')

  useEffect(() => {
    setTimeout(() => {
      try {
        window.mixpanel.track(`Matches.mv3.moreModal`, {
          clusterId: cluster._id,
        })
      } catch (e) {
        console.error(`Unable to invoke mixpanel method:`, e)
      }
    }, 0)
  }, [])

  return (
    <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div css={sharedStyles.modalHeader}>
        <div css={sharedStyles.modalTitleWrapper}>
          <span css={sharedStyles.modalTitle}>More options</span>
        </div>
      </div>
      <ScrollableContent>
        {!isMultiMatch && (
          <Fragment>
            <InfoColumn directionRow flexWrap paddingBottom={30}>
              {[
                ['DETECTED', moment(cluster.created).format('MMMM D, YYYY')],
                ['LAST SCAN', moment(image.job.scanned).format('MMMM D, YYYY')],
                // TODO: implement accuracy for a group, or at least check if
                // calculateAccuracyFromScore(cluster.score) is correct in such case
                isMultiMatch ? null : ['ACCURACY', calculateAccuracyFromScore(cluster.score)],
                ['UPLOAD DATE', moment(image.created).format('MMMM D, YYYY')],
                ['TITLE', image.title],
              ]
                .filter(Boolean)
                .map(([key, value]) => (
                  <RowData key={key} col={key === 'TITLE' ? 1 : 2} property={key} titleSize={10} textSize={18}>
                    {value}
                  </RowData>
                ))}
            </InfoColumn>
            <Divider />
          </Fragment>
        )}
        <div css={styles.sectionTitle}>Ignore</div>
        <div css={styles.buttonsWrapper}>
          {!isMultiMatch && <ActionButton onClick={onIgnoreMatchRequest} Icon={CrossIcon} text="IGNORE MATCH" />}
          {cluster.domain.host && (
            <ActionButton
              onClick={handleIgnoreDomain}
              Icon={() => (
                <ClearbitLogo
                  host={cluster.domain.host}
                  size={30}
                  fallbackLetter={getClearBitLogoFallbackLetter(cluster)}
                />
              )}
              text="IGNORE DOMAIN"
            />
          )}
          {categoryName && (
            <ActionButton
              onClick={handleIgnoreCategory}
              Icon={() => <span className={styles.smallText}>{categoryName}</span>}
              text="IGNORE CATEGORY"
            />
          )}
          {countryName && (
            <ActionButton
              onClick={handleIgnoreCountry}
              Icon={() => (
                <Flag
                  country={countryName.replace(/ /g, '-')}
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                />
              )}
              text="IGNORE COUNTRY"
            />
          )}
          <p css={styles.buttonDescription}>Stop matches from appearing in your results.</p>
        </div>
        <Divider />
        <div css={styles.sectionTitle}>Help</div>
        <div css={styles.buttonsWrapper}>
          <div className={styles.buttonTextWrapper}>
            <a href="https://support.pixsy.com/en" target="_blank" rel="noreferrer">
              <ActionButton
                className={styles.orangeButton}
                Icon={() => <HelpIcon color="#ffffff" />}
                text="HELP CENTER"
              />
            </a>
            <p css={styles.buttonDescription}>Visit our help center for guides, videos, and support info.</p>
          </div>
          <div className={styles.buttonTextWrapper}>
            <ActionButton
              onClick={handleContactSupportRequest}
              Icon={() => <HelpIcon color="#00000080" />}
              text="CONTACT SUPPORT"
            />
            <p css={styles.buttonDescription}>I have a question about this match and need help.</p>
          </div>
        </div>
      </ScrollableContent>
    </StyledModal>
  )
}

const styles = {
  orangeButton: css`
    background-color: #ffa168;
    color: white;
  `,
  buttonDescription: css`
    width: 158px;
    margin-left: 26px;
    margin-right: 20px;
    font-size: 17px;
    color: #00000054;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  `,
  buttonsWrapper: css`
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    .actionButton {
      width: 120px;
      height: 102px;
      font-size: 14px;
      padding: 10px;
      margin-right: 6px;

      > svg {
        width: 20px;
        height: 20px;
      }

      > font {
        font-size: 46px;
        line-height: 36px;
        font-weight: normal;
        text-align: center;
      }
    }
  `,
  buttonTextWrapper: css`
    flex: 1;
    display: flex;
    flex-direction: row;
  `,
  smallText: css`
    font-size: 12px;
    line-height: 1.2;
    font-weight: normal;
    text-align: center;
  `,
  sectionTitle: css`
    font-size: 26px;
    color: #212121;
    margin-top: 30px;
    margin-bottom: 35px;
  `,
}

MoreModal.propTypes = {
  isOpen: PropTypes.bool,
  cluster: Shapes.Cluster,

  onRequestClose: PropTypes.func.isRequired,
  onIgnoreMatchRequest: PropTypes.func.isRequired,
}

export default connect(null, (globalDispatch) => ({ globalDispatch }))(MoreModal)
