import React from 'react'
import { css } from 'react-emotion'
import { ContentSection, SexySeparator, ScrollToTop } from 'common'
import Path from './Path'
import Flags from './Flags'

export default function Guide() {
  return (
    <ContentSection centered>
      <ScrollToTop />
      <h1 css={specialHeading}>Pixsy Case Resolution Service</h1>
      <div css={matchSubmissionIntroTextCont}>
        <div css={matchSubmissionIntroCard}>
          <IconOne />
          <h3>You’re in safe hands</h3>
          <ul>
            <li>
              <span>
                Assigning a case to Pixsy is easy and affordable - we only
                charge a service fee if we are successful in recovering
                compensation for you.
              </span>
            </li>
            <li>
              <span>
                To date Pixsy has handled over 70,000 cases around the world.
              </span>
            </li>
          </ul>
        </div>

        <div css={matchSubmissionIntroCard}>
          <IconTwo />
          <h3>We’re here for you</h3>
          <ul>
            <li>
              <span>
                Our team of expert copyright Case Managers handle every step of
                your case and negotiate on your behalf.
              </span>
            </li>
            <li>
              <span>
                We partner globally with over 25 copyright/IP law firms to bring
                your case the best local legal support possible.
              </span>
            </li>
          </ul>
        </div>

        <div css={matchSubmissionIntroCard}>
          <IconThree />
          <h3>Keep Informed</h3>
          <ul>
            <li>
              <span>
                You’re informed every step of the way via the Case Dashboard and
                email notifications.
              </span>
            </li>
            <li>
              <span>
                We remit payments within 14-21 days of receipt to the method of
                your choice.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <SexySeparator space={40} />
      <h1>Case Processing Timeline</h1>
      <Path />
      <SexySeparator space={40} />
      <h1>Supported Countries</h1>
      <p>
        Pixsy operates in the following countries, based on where the website
        owner (business) is legally operating.
      </p>
      <Flags />

      <p className={greyNote}>
        Legal evidence and screenshots are captured automatically as soon as you
        complete the submission, by our Pixsy Evidence Service.
      </p>
    </ContentSection>
  )
}

function IconOne() {
  const imageIcon1 = require('../EmptyState/ExpertCare.svg')
  return (
    <div
      css={emptyStateCardPic}
      dangerouslySetInnerHTML={{ __html: imageIcon1 }}
    />
  )
}

function IconTwo() {
  const imageIcon2 = require('../EmptyState/HereForYou.svg')
  return (
    <div
      css={emptyStateCardPic}
      dangerouslySetInnerHTML={{ __html: imageIcon2 }}
    />
  )
}

function IconThree() {
  const imageIcon3 = require('../EmptyState/KeepInformed.svg')
  return (
    <div
      css={emptyStateCardPic}
      dangerouslySetInnerHTML={{ __html: imageIcon3 }}
    />
  )
}

const specialHeading = css`
  font-weight: 600 !important;
  font-size: 40px !important;
`

const matchSubmissionIntroTextCont = css`
  width: 100%;
  display: inline-block;
`

const greyNote = css`
  margin: 30px 0;
  color: #3b3b3baa;
`

const matchSubmissionIntroCard = css`
  background-color: white;
  display: inline-block;
  width: 33.3%;
  @media (max-width: 994px) {
    width: 100%;
  }
  height: auto;
  float: left;
  padding: 15px 5px;
  h3 {
    font-size: 1.5em;
    margin: 0 0 15px 0;
  }

  ul {
    text-align: left;
    padding: 0 10px 0 30px;
    width: 100%;
  }
  li {
    color: black;
    padding-bottom: 10px;
    color: #008aab;
    span {
      color: rgb(40, 40, 40);
      font-size: 1em !important;
      opacity: 0.75;
    }
  }
`

const emptyStateCardPic = css`
  width: 100%;
  padding-bottom: 49.99%;
  position: relative;
  margin: 10px 0 15px 0;
  svg {
    position: absolute;
    top: 0;
    left: 15%;
    max-width: 70%;
    height: 100%;
  }
`
