import React from 'react'
import PropTypes from 'prop-types'
import styled, { cx, css } from 'react-emotion'
import DowngradePlanWarningModal from './Modals/DowngradePlanWarningModal'
import {
  FLICKR_PRO_TAKEDOWNS_ALLOTMENT,
  SMUGMUG_TAKEDOWNS_ALLOTMENT,
  PARTNERSHIP_PLAN_FAMILIES,
} from 'pixsy-constants'

const specialTakedownAllotment = {
  flickr: FLICKR_PRO_TAKEDOWNS_ALLOTMENT,
  smugmug: SMUGMUG_TAKEDOWNS_ALLOTMENT,
}

const PlansWrapper = styled.div`
  margin: 70px 0 10px;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`

export default function Plans({ user, activePlan, plans, subscribe, loading, paymentMethods }) {
  // see PlansContainer mapStateToProps
  return (
    <PlansWrapper>
      {plans.map(plan => {
        const isPartnershipPlan = PARTNERSHIP_PLAN_FAMILIES.includes(
          plan.family
        )
        return (
          <Plan
            user={user}
            key={plan._id}
            subscribe={subscribe}
            isActive={plan._id === activePlan._id}
            isPartnershipPlan={isPartnershipPlan}
            plan={plan}
            loading={loading}
            paymentMethods={paymentMethods}
          />
        )
      })}
    </PlansWrapper>
  )
}

Plans.propTypes = {
  user: PropTypes.object.isRequired,
}

class Plan extends React.Component {
  constructor(props) {
    super(props)


    this.state = { showDowngradeWarning: false }
  }

  openDowngradeWarningModal = () => {
    this.setState({ showDowngradeWarning: true })
  }

  closeDowngradeWarningModal = () => {
    this.setState({ showDowngradeWarning: false })
  }

  subscribe = () => {
    const { subscribe, plan, paymentMethods } = this.props
    const { showDowngradeWarning } = this.state

    if (showDowngradeWarning) {
      this.closeDowngradeWarningModal()
    }
    const client_secret = localStorage.getItem('client_secret')
    if(!paymentMethods.length) {
      const element = document.getElementById('add-a-new-payment-method') || document.getElementById('submit-payment');
      element.click();
      element.scrollIntoView();
    } else {
      subscribe(plan._id, client_secret)
    }
  }

  handleUpOrDowngradeButtonClick = () => {
    const { user, plan, isActive } = this.props

    const isUpgrade = plan.price >= user.plan.price && !isActive

    if (isUpgrade) {
      this.subscribe()
    } else {
      this.openDowngradeWarningModal()
    }
  }

  getScanningLevelDescription(level) {
    const { plan } = this.props

    if (PARTNERSHIP_PLAN_FAMILIES.includes(plan.family)) {
      return 'Standard'
    } else {
      return [, 'Highest', 'High', 'Medium', 'Medium', 'Low', 'Low'][
        plan.features.rescheduling
      ]
    }
  }

  render() {
    const { user, plan, isActive, loading, isPartnershipPlan } = this.props
    const { showDowngradeWarning } = this.state

    const { title, price, description, billing_period, features } = plan

    // const scanningLevelDescription = ['Low', 'Medium', 'High', 'Highest']
    // no plan where reschduling = 0

    const wouldBeDowngrade = plan.price < user.plan.price

    const takedownNotices =
      (isPartnershipPlan && specialTakedownAllotment[plan.family]) ||
      features.takedowns

    return (
      <StyledPlanCard active={isActive}>
        <div css={styledPlanContent}>
          <h4>{title}</h4>
          <figure>{price}</figure>
          <strong>
            {price === 0 ? 'free' : billing_period === 12 ? 'year' : 'month'}
          </strong>
          <blockquote
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <ul>
            <li>
              <em>{Number(features.photos).toLocaleString()}</em> Images
              monitored
            </li>
            <li>
              <em>{this.getScanningLevelDescription()}</em> priority scanning
            </li>

            <li>
              <React.Fragment>
                <em>{Number(takedownNotices).toLocaleString()}</em> Takedown
                Notices
              </React.Fragment>
            </li>
          </ul>

          <DowngradePlanWarningModal
            onCancel={this.closeDowngradeWarningModal}
            isOpen={wouldBeDowngrade && showDowngradeWarning}
            onDowngrade={this.subscribe}
            plan={plan}
          />

          <button
            className={cx(button, { [active]: isActive })}
            disabled={loading}
            onClick={this.handleUpOrDowngradeButtonClick}
          >
            {isActive
              ? 'Current'
              : plan.price >= user.plan.price
              ? 'Upgrade'
              : 'Downgrade'}
          </button>
          {/* {!user.trialEnd && plan.price > 0 && <span>7 day free trial</span>} */}
        </div>
      </StyledPlanCard>
    )
  }
}

const StyledPlanCard = styled.div`
  flex: 1;
  margin: 2px;
  outline-style: inset;
  outline: ${props => (props.active ? '0' : '1px')} solid #e8e8e8;
  position: relative;
  background-color: white;

  @media (max-width: 768px) {
    flex: 0;
    width: 100%;
    height: auto;
  }

  ${props =>
    props.active &&
    css`
      & {
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
        background-color: white;
        z-index: 3;
      }
      &:before {
        position: absolute;
        display: block;
        top: -44px;
        width: 100%;
        height: 44px;
        line-height: 44px;
        content: 'Your current plan';
        background-color: #148aa9;
        color: #fff;
        text-align: center;
      }
      &:after {
        position: absolute;
        display: block;
        top: -1px;
        left: 50%;
        transform: translate(-50%);
        width: 0px;
        height: 0px;
        content: '';
        color: #fff;
        text-align: center;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-top: 10px solid #148aa9;
      }
    `};
`

const styledPlanContent = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0 20px;
  height: 100%;

  h4 {
    font-weight: 600;
    font-size: 1.3em;
  }

  figure {
    font-size: 2.9em;
    margin: -12px 0 -12px;
    position: relative;
    left: -0.1em;
    &:before {
      content: '$';
      font-size: 10px;
      position: relative;
      color: #3b3b3b80;
      top: -2.5em;
      left: -3px;
    }
  }

  strong {
    color: #3b3b3b80;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.8em;
  }

  span {
    color: #3b3b3bcc;
    font-size: 0.8em;
  }

  dfn {
    font-weight: 400;
    font-style: normal;
  }
  blockquote {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 400;
    font-style: normal;
    color: #3b3b3bb3;
    height: 160px;
    overflow: hidden;
    border-left: 0;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 7px;
  }

  em {
    color: #148aa9;
    font-weight: 600;
    font-style: normal;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`

const button = css`
  width: 100px;
  margin: 10px auto;
  padding: 5px 0;
  opacity: 1;
  border: 1px solid #008aab;
  font-weight: 600;
`

const active = css`
  width: 100px;
  margin: 10px auto;
  opacity: 0.3;
  border: 1px solid #3b3b3b;
`
