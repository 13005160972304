import { SERVICE_REMOVE_SUCCESS, IMAGES_SYNCHRONIZE_SUCCESS } from './images'

export const AUTH_LOAD = 'AUTH_LOAD'
export const AUTH_LOAD_SUCCESS = 'AUTH_LOAD_SUCCESS'
export const AUTH_LOAD_FAIL = 'AUTH_LOAD_FAIL'

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL'

export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER'
export const AUTH_UPDATE_USER_SUCCESS = 'AUTH_UPDATE_USER_SUCCESS'
export const AUTH_UPDATE_USER_FAIL = 'AUTH_UPDATE_USER_FAIL'

export const AUTH_DELETE_USER = 'AUTH_DELETE_USER'
export const AUTH_DELETE_USER_SUCCESS = 'AUTH_DELETE_USER_SUCCESS'
export const AUTH_DELETE_USER_FAIL = 'AUTH_DELETE_USER_FAIL'

export const AUTH_REGISTER = 'AUTH_REGISTER'
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS'
export const AUTH_REGISTER_FAIL = 'AUTH_REGISTER_FAIL'

export const AUTH_SUBSCRIBE = 'AUTH_SUBSCRIBE'
export const AUTH_SUBSCRIBE_SUCCESS = 'AUTH_SUBSCRIBE_SUCCESS'
export const AUTH_SUBSCRIBE_FAIL = 'AUTH_SUBSCRIBE_FAIL'

export const AUTH_SET_CARD = 'AUTH_SET_CARD'
export const AUTH_SET_CARD_SUCCESS = 'AUTH_SET_CARD_SUCCESS'
export const AUTH_SET_CARD_FAIL = 'AUTH_SET_CARD_FAIL'

export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD'
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS'
export const AUTH_CHANGE_PASSWORD_FAIL = 'AUTH_CHANGE_PASSWORD_FAIL'

export const AUTH_CHANGE_EMAIL = 'AUTH_CHANGE_EMAIL'
export const AUTH_CHANGE_EMAIL_SUCCESS = 'AUTH_CHANGE_EMAIL_SUCCESS'
export const AUTH_CHANGE_EMAIL_FAIL = 'AUTH_CHANGE_EMAIL_FAIL'

export const INVITE = 'INVITE'
export const INVITE_SUCCESS = 'INVITE_SUCCESS'
export const INVITE_FAIL = 'INVITE_FAIL'

export const INVITE_CODE_LOAD = 'INVITE_CODE_LOAD'
export const INVITE_CODE_LOAD_SUCCESS = 'INVITE_CODE_LOAD_SUCCESS'
export const INVITE_CODE_LOAD_FAIL = 'INVITE_CODE_LOAD_FAIL'

export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL'

export const EMAIL_VERIFIY_REQUEST = 'EMAIL_VERIFIY_REQUEST'
export const EMAIL_VERIFIY_REQUEST_SUCCESS = 'EMAIL_VERIFIY_REQUEST_SUCCESS'
export const EMAIL_VERIFIY_REQUEST_FAIL = 'EMAIL_VERIFIY_REQUEST_FAIL'

export const PASSWORD_SET = 'PASSWORD_SET'
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS'
export const PASSWORD_SET_FAIL = 'PASSWORD_SET_FAIL'

const SOURCES_ADD = 'SOURCES_ADD'

const WEBSITE_ADD = 'WEBSITE_ADD'
export const WEBSITE_ADD_SUCCESS = 'WEBSITE_ADD_SUCCESS'
const WEBSITE_ADD_FAIL = 'WEBSITE_ADD_FAIL'

const WEBSITE_REFRESH = 'WEBSITE_REFRESH'
export const WEBSITE_REFRESH_SUCCESS = 'WEBSITE_REFRESH_SUCCESS'
const WEBSITE_REFRESH_FAIL = 'WEBSITE_REFRESH_FAIL'

const WEBSITE_DELETE = 'WEBSITE_DELETE'
export const WEBSITE_DELETE_SUCCESS = 'WEBSITE_DELETE_SUCCESS'
const WEBSITE_DELETE_FAIL = 'WEBSITE_DELETE_FAIL'

const PAYMENT_FRAME_URL_LOAD = 'PAYMENT_FRAME_URL_LOAD'
const PAYMENT_FRAME_URL_LOAD_SUCCESS = 'PAYMENT_FRAME_URL_LOAD_SUCCESS'
const PAYMENT_FRAME_URL_LOAD_FAIL = 'PAYMENT_FRAME_URL_LOAD_FAIL'

export const AUTH_RESET_CARD = 'AUTH_RESET_CARD'
const AUTH_CANCEL_RESET_CARD = 'AUTH_CANCEL_RESET_CARD'

const AUTH_LOAD_USAGE = 'AUTH_LOAD_USAGE'
const AUTH_LOAD_USAGE_SUCCESS = 'AUTH_LOAD_USAGE_SUCCESS'
const AUTH_LOAD_USAGE_FAIL = 'AUTH_LOAD_USAGE_FAIL'

const GET_REFERRAL_CODE = 'GET_REFERRAL_CODE'
const GET_REFERRAL_CODE_SUCCESS = 'GET_REFERRAL_CODE_SUCCESS'
const GET_REFERRAL_CODE_FAIL = 'GET_REFERRAL_CODE_FAIL'

const AUTH_SUBSCRIBE_UPDATE = 'AUTH_SUBSCRIBE_UPDATE'
const AUTH_SUBSCRIBE_SUCCESS_UPDATE = 'AUTH_SUBSCRIBE_SUCCESS_UPDATE'
const AUTH_SUBSCRIBE_FAIL_UPDATE = 'AUTH_SUBSCRIBE_FAIL_UPDATE'

const FETCH_PAYMENT_OPTIONS = 'FETCH_PAYMENT_OPTIONS'
const FETCH_PAYMENT_OPTIONS_SUCCESS = 'FETCH_PAYMENT_OPTIONS_SUCCESS'
const FETCH_PAYMENT_OPTIONS_FAIL = 'FETCH_PAYMENT_OPTIONS_FAIL'

const initialState = {
  loading: false,
  loaded: false,
  loggingOut: false,
  submitting: false,
  emailVerifyLoading: false,
  updatingUser: false,
  usage: {},
  code: null,
  paymentOptions: {
    paymentMethods: [],
    subs: [],
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH_LOAD:
    case AUTH_LOAD_USAGE:
    case AUTH_SET_CARD:
    case AUTH_SUBSCRIBE:
    case WEBSITE_DELETE:
    case WEBSITE_REFRESH:
    case INVITE_CODE_LOAD:
    case AUTH_CHANGE_PASSWORD:
    case INVITE_CODE_LOAD:
    case PAYMENT_FRAME_URL_LOAD:
      return {
        ...state,
        loading: true,
      }

    case AUTH_LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        user: action.payload.user,
      }

    case AUTH_LOAD_FAIL:
    case AUTH_LOAD_USAGE_FAIL:
    case AUTH_CHANGE_PASSWORD_SUCCESS:
    case WEBSITE_REFRESH_SUCCESS:
    case WEBSITE_ADD_SUCCESS:
    case WEBSITE_REFRESH_FAIL:
    case WEBSITE_DELETE_FAIL:
    case INVITE_CODE_LOAD_FAIL:
    case INVITE_CODE_LOAD_FAIL:
    case PAYMENT_FRAME_URL_LOAD_FAIL:
    case AUTH_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
      }
    case AUTH_LOGIN:
      return {
        ...state,
        loading: true,
        submitting: true,
      }
    case AUTH_LOGIN_SUCCESS:
      if (action.payload.graphApiToken) {
        localStorage.setItem('graph-session', action.payload.graphApiToken)
        localStorage.removeItem('pixsy-shark')
      }
      return {
        ...state,
        loaded: true,
        loading: false,
        submitting: false,
        user: action.payload.user,
      }
    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        submitting: false,
      }
    case AUTH_LOGOUT:
    case AUTH_DELETE_USER:
      localStorage.removeItem('pixsy-shark')
      localStorage.removeItem('graph-session')
      return {
        ...state,
        loggingOut: true,
        loading: true,
      }
    case AUTH_LOGOUT_SUCCESS:
    case AUTH_DELETE_USER_SUCCESS:
      return {
        ...state,
        loaded: false,
        loading: false,
        loggingOut: false,
        user: null,
      }
    case AUTH_LOGOUT_FAIL:
    case AUTH_DELETE_USER_FAIL:
      return {
        ...state,
        loggingOut: false,
        loading: false,
      }
    case AUTH_UPDATE_USER:
      return {
        ...state,
        loading: true,
        updatingUser: true,
      }
    case AUTH_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        updatingUser: false,
        user: action.payload.user,
      }
    case AUTH_UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        updatingUser: false,
      }
    case AUTH_SUBSCRIBE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case AUTH_REGISTER:
      return {
        ...state,
        loading: true,
        submitting: true,
      }
    case AUTH_REGISTER_SUCCESS:
      if (action.payload.graphApiToken) {
        localStorage.setItem('graph-session', action.payload.graphApiToken)
      }
      return {
        ...state,
        loaded: true,
        loading: false,
        user: action.payload.user,
        submitting: true,
      }
    case AUTH_REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        submitting: false,
      }
    case AUTH_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      }
    case AUTH_SET_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        set: true,
        user: action.payload.user,
      }
    case AUTH_SET_CARD_FAIL:
      return {
        ...state,
        loading: false,
        set: false,
      }
    case AUTH_RESET_CARD:
      return {
        ...state,
        user: {
          ...state.user,
          stripe: {
            ...state.user.stripe,
            editing: true,
          },
        },
      }
    case AUTH_CANCEL_RESET_CARD:
      return {
        ...state,
        user: {
          ...state.user,
          stripe: {
            ...state.user.stripe,
            editing: false,
          },
        },
      }

    case SERVICE_REMOVE_SUCCESS:
    case IMAGES_SYNCHRONIZE_SUCCESS:
      const newUser = action.payload && action.payload.user
      return {
        ...state,
        user: newUser || state.user,
      }

    case SOURCES_ADD:
      return {
        ...state,
        user: {
          ...state.user,
          sources: {
            ...state.user.sources,
            [action.service]: {
              id: action.id,
            },
          },
        },
      }

    case WEBSITE_ADD:
      return {
        ...state,
        loading: true,
        user: {
          ...state.user,
          sources: {
            ...state.user.sources,
            websites: [...state.user.sources.websites, action.meta.url],
          },
        },
      }

    case WEBSITE_ADD_FAIL:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          sources: {
            ...state.user.sources,
            websites: state.user.sources.websites.slice(0, -1),
          },
        },
      }

    case WEBSITE_DELETE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          sources: {
            ...state.user.sources,
            websites: [
              ...state.user.sources.websites.slice(0, action.meta.index),
              ...state.user.sources.websites.slice(action.meta.index + 1),
            ],
          },
        },
        loading: false,
      }

    case INVITE_CODE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
      }
    case INVITE_CODE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
      }
    case PAYMENT_FRAME_URL_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentFrameURL: action.payload.paymentFrameURL,
      }
    case AUTH_LOAD_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usage: action.payload,
      }
    case EMAIL_VERIFIY_REQUEST:
      return {
        ...state,
        emailVerifyLoading: true,
      }
    case EMAIL_VERIFIY_REQUEST_FAIL:
    case EMAIL_VERIFIY_REQUEST_SUCCESS:
      return {
        ...state,
        emailVerifyLoading: false,
      }
    case GET_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        code: action.payload.code,
      }
    case FETCH_PAYMENT_OPTIONS_SUCCESS: {
      const { paymentMethods, subs } = _convertPaymentResponse(action.payload)
      return {
        ...state,
        paymentOptions: {
          paymentMethods,
          subs,
        },
      }
    }
    default:
      return state
  }
}

/**
 * @param {Object} globalState
 * @returns {boolean}
 */
export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded
}

/**
 * @param {Object} globalState
 * @returns {boolean}
 */
export function isLoggingOut(globalState) {
  return globalState.auth && globalState.auth.loggingOut
}

/**
 * @returns {Object} Action
 */
export function load() {
  return {
    types: [AUTH_LOAD, AUTH_LOAD_SUCCESS, AUTH_LOAD_FAIL],
    promise: (client) => client.get('/auth/load'),
  }
}

/**
 * @param {Object} data payload
 * @returns {Object} Action
 */
export function register(data) {
  return {
    types: [AUTH_REGISTER, AUTH_REGISTER_SUCCESS, AUTH_REGISTER_FAIL],
    promise: (client) => client.post('/auth/register', { data }),
  }
}

/**
 * @param {Object} data payload
 * @returns {Object} Action
 */
export function requestEmailVerification() {
  return {
    types: [EMAIL_VERIFIY_REQUEST, EMAIL_VERIFIY_REQUEST_SUCCESS, EMAIL_VERIFIY_REQUEST_FAIL],
    promise: (client) => client.get('/users/me/verify-email'),
  }
}

export function changePassword(data) {
  return {
    types: [AUTH_CHANGE_PASSWORD, AUTH_CHANGE_PASSWORD_SUCCESS, AUTH_CHANGE_PASSWORD_FAIL],
    promise: (client) => client.put('/users/me/password', { data }),
  }
}

export function changeEmail(data) {
  return {
    types: [AUTH_CHANGE_EMAIL, AUTH_CHANGE_EMAIL_SUCCESS, AUTH_CHANGE_EMAIL_FAIL],
    promise: (client) => client.put('/users/me/email', { data }),
  }
}

/**
 * @param {Object} data payload
 * @returns {Object} Action
 */
export function login(data) {
  return {
    types: [AUTH_LOGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAIL],
    promise: (client) => client.post('/auth/login', { data }),
  }
}

/**
 * @returns {Object} Action
 */
export function logout() {
  return {
    types: [AUTH_LOGOUT, AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_FAIL],
    promise: (client) => client.get('/auth/logout'),
  }
}

/**
 * @param {Object} data payload
 * @returns {Object} Action
 *
 * Use silent when you don‘t want "Updated successfully" to show
 */
export function updateUser(data, silent = false) {
  return {
    types: [AUTH_UPDATE_USER, AUTH_UPDATE_USER_SUCCESS, AUTH_UPDATE_USER_FAIL],
    promise: (client) =>
      client.put('/users/me', {
        data,
        ...(silent && {
          headers: {
            'x-pixsy-silent': '1',
          },
        }),
      }),
  }
}

/**
 * @returns {Object} Action
 */
export function deleteUser() {
  return {
    types: [AUTH_DELETE_USER, AUTH_DELETE_USER_SUCCESS, AUTH_DELETE_USER_FAIL],
    promise: (client) => client.delete('/users/me'),
  }
}

export function subscribe(id, client_secret) {
  return {
    types: [AUTH_SUBSCRIBE, AUTH_SUBSCRIBE_SUCCESS, AUTH_SUBSCRIBE_FAIL],
    promise: (client) => client.post('/plans/' + id + '/subscribe', { data: { client_secret } }),
  }
}

export function updateSubscriptPaymentMethod(id, payment_method) {
  return {
    types: [AUTH_SUBSCRIBE_UPDATE, AUTH_SUBSCRIBE_SUCCESS_UPDATE, AUTH_SUBSCRIBE_FAIL_UPDATE],
    promise: (client) => client.post('/plans/' + id + '/subscribe/update', { data: { payment_method } }),
  }
}

/**
 * @param {Object} data payload
 * @returns {Object} Action
 */
export function applyCoupon(data) {
  return {
    types: [AUTH_SET_CARD, AUTH_SET_CARD_SUCCESS, AUTH_SET_CARD_FAIL],
    promise: (client) => client.put('users/me/applyCoupon', { data }),
  }
}

/**
 * @param {Object} data payload
 * @returns {Object} Action
 */
export function fetchPaymentOptions() {
  return {
    types: [FETCH_PAYMENT_OPTIONS, FETCH_PAYMENT_OPTIONS_SUCCESS, FETCH_PAYMENT_OPTIONS_FAIL],
    promise: (client) => client.get('/user/paymentMethods'),
  }
}

/**
 * @returns {Object} Action
 */
export function resetCard() {
  return {
    type: AUTH_RESET_CARD,
  }
}

export function cancelReset() {
  return {
    type: AUTH_CANCEL_RESET_CARD,
  }
}

/**
 * @returns {Object} Action
 */
export function resetPassword(data) {
  return {
    types: [PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL],
    promise: (client) => client.delete('/users/me/password', { data }),
  }
}

/**
 * @returns {Object} Action
 */
export function setPassword(data) {
  return {
    types: [PASSWORD_SET, PASSWORD_SET_SUCCESS, PASSWORD_SET_FAIL],
    promise: (client) => client.post('/users/me/password', { data }),
  }
}

/**
 * @returns {Object} Action
 */
export function addSource(service, id) {
  return {
    type: SOURCES_ADD,
    service,
    id,
  }
}

/**
 * @returns {Object} Action
 */
export function addWebsite(url) {
  return {
    types: [WEBSITE_ADD, WEBSITE_ADD_SUCCESS, WEBSITE_ADD_FAIL],
    promise: (client) => client.post('/users/me/sources/website', { data: { url } }),
    meta: {
      url,
    },
  }
}

/**
 * @returns {Object} Action
 */
export function refreshWebsite(url) {
  return {
    types: [WEBSITE_REFRESH, WEBSITE_REFRESH_SUCCESS, WEBSITE_REFRESH_FAIL],
    promise: (client) => client.put('/users/me/sources/website', { data: { url } }),
  }
}

/**
 * @returns {Object} Action
 */
export function deleteWebsite(index, url) {
  return {
    types: [WEBSITE_DELETE, WEBSITE_DELETE_SUCCESS, WEBSITE_DELETE_FAIL],
    promise: (client) => client.delete('/users/me/sources/website', { data: { url } }),
    meta: {
      index,
    },
  }
}

/**
 * @returns {Object} Action
 */
export function getPaymentFrameURL() {
  return {
    types: [PAYMENT_FRAME_URL_LOAD, PAYMENT_FRAME_URL_LOAD_SUCCESS, PAYMENT_FRAME_URL_LOAD_FAIL],
    promise: (client) => client.get('/users/me/payment'),
  }
}

export function getUsage() {
  return {
    types: [AUTH_LOAD_USAGE, AUTH_LOAD_USAGE_SUCCESS, AUTH_LOAD_USAGE_FAIL],
    promise: (client) => client.get('/users/me/usage'),
  }
}

export function getUserRefferalCode() {
  return {
    types: [GET_REFERRAL_CODE, GET_REFERRAL_CODE_SUCCESS, GET_REFERRAL_CODE_FAIL],
    promise: (client) => client.get('/users/me/code'),
  }
}

function _convertPaymentResponse(res) {
  const { paymentMethods, subs } = res
  const { data } = paymentMethods
  const { data: subsData } = subs
  return {
    paymentMethods: data || [],
    subs: subsData || [],
  }
}
