import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import Slider from '@material-ui/core/Slider'

import useDebouncedEffect from '../utils/useDebouncedEffect'

const YearRangeSlider = ({
  max,
  min,
  selectedYearRange,
  onSelectedYearRange,
}) => {
  const [value, setValue] = useState([,])

  useEffect(() => {
    setValue(selectedYearRange.map(n => -n))
  }, [selectedYearRange])

  useDebouncedEffect(
    () => {
      onSelectedYearRange(value.map(Math.abs))
    },
    500,
    value
  )

  return (
    <div css={styles.container}>
      <Slider
        css={styles.slider}
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        valueLabelDisplay="on"
        max={-min}
        min={-max}
        valueLabelFormat={Math.abs}
      />
      <div css={styles.legendsWrapper}>
        <span>Present</span>
        <span>Past</span>
      </div>
    </div>
  )
}

const styles = {
  container: css`
    padding: 15px 25px 10px 25px;
    background-color: #fafafa;
  `,
  slider: css`
    padding: 30px 0 5px 0 !important;

    .MuiSlider-valueLabel {
      top: -25px;

      // circle
      > span {
        background: none;

        // text
        > span {
          color: #121212;
          font-size: 14px;
          font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
        }
      }
    }

    .MuiSlider-rail {
      background-color: #c7c7c7;
      opacity: 1;
    }

    .MuiSlider-track {
      background-color: #ffa168;
    }

    .MuiSlider-thumb {
      width: 15px;
      height: 15px;
      margin-top: -7px;
      background-color: #ffa168;
      box-shadow: none !important;
      border: 2px solid white;
    }
  `,
  legendsWrapper: css`
    display: flex;
    justify-content: space-between;

    span {
      font-size: 10px;
      color: #121212;
    }
  `,
}

YearRangeSlider.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  selectedYearRange: PropTypes.arrayOf(PropTypes.number),

  onSelectedYearRange: PropTypes.func.isRequired,
}

export default YearRangeSlider
