import * as React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { css } from 'react-emotion'

export class PixsyMenuItemSeparator extends React.PureComponent {
  render() {
    return (
      <MenuItem
        classes={menuItemClasses}
        divider
        disableGutters
        disabled
        {...this.props}
      />
    )
  }
}
const pixsyMenuItemStyle = css`
  min-height: 1px !important;
  max-height: 1px !important;
  padding: 0 !important;
  margin: 10px 0 !important;
`
const menuItemClasses = {
  root: pixsyMenuItemStyle,
}
