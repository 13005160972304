import React from 'react'
import styled, { css } from 'react-emotion'

const ToggleButton = ({ text, onClick, active, color }) => {
  return (
    <ToggleButtonStyled onClick={onClick} active={active} color={color}>
      {text}
    </ToggleButtonStyled>
  )
}

export default ToggleButton

const ToggleButtonStyled = styled.button`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? props.color : '#ffffff')};
  color: ${(props) => (props.active ? (props.color === '#ffa168' ? '#ffffff' : '#000000') : '#939393')};
  border: 0;

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        transition: all 0.1s linear;
        background: ${props.color};
        color: ${props.color === '#ffa168' ? '#ffffff' : '#939393'};
      }
    `};

  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
`
