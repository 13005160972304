import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'
import { FLICKR_PRO_TAKEDOWNS_ALLOTMENT } from 'pixsy-constants'

const progressBarWrap = css`
  height: 6px;
  background-color: #e8e8e8;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`

const ProgressBar = styled.div`
  height: 100%;
  width: ${props => props.completeness * 100}%;
  max-width: 100%;
  background-color: ${props =>
    props.completeness === 1 ? '#dda300' : '#008aab'};
`

const headerRow = css`
  width: 100%;
  float: left;
  position: relative;
`

const headerBlock = css`
  width: 32%;
  float: left;
  height: 165px;
  background-color: white;
  margin-right: 2%;
  display: flex;
  position: relative;
  align-items: top;
  justify-content: center;
  text-align: center;
  padding: 35px 10px;
  border: solid 1px rgb(240, 240, 240);
  margin-bottom: 90px;
  @media (max-width: 994px) {
    width: 100%;
    height: auto;
    margin: 0 0 15px 0;
  }
  &:last-of-type {
    margin: 0;
  }
  h3 {
    font-size: 3em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p {
    margin-top: 0;
    margin-bottom: 5px;
    opacity: 0.75;
    font-size: 1em;
    line-height: 1;
  }
`

const mobile = css`
  @media (max-width: 768px) {
    overflow: hidden;
  }
`

export default function PlansHeader({ user, usage }) {
  const normalize = fraction =>
    isNaN(fraction) ? 0 : fraction > 1 ? 1 : fraction

  const format = n => (isNaN(n) ? '-' : Number(n).toLocaleString())

  const isOnPartnershipPlan =
    user.plan.family === 'flickr' || user.plan.family === 'smugmug'

  return (
    <div css={mobile}>
      <div className={headerRow}>
        <div className={headerBlock}>
          <div>
            <p>Your current plan</p>
            <h3>{user.plan.title}</h3>
          </div>
        </div>
        <div className={headerBlock}>
          <div>
            <p>Images monitored</p>
            <h3>
              {format(Math.min(usage.totalPhotos, usage.trackablePhotos))}
            </h3>
            <p>
              of the {format(user.plan.features.photos)} included in your plan
            </p>
            <div className={`${progressBarWrap}`}>
              <ProgressBar
                completeness={normalize(
                  Math.min(usage.totalPhotos, usage.trackablePhotos) /
                    user.plan.features.photos
                )}
              />
            </div>
          </div>
        </div>
        <div className={headerBlock}>
          {isOnPartnershipPlan ? (
            <div>
              <p>Takedown notices sent</p>
              <h3>{format(usage.totalTakedownsSent)}</h3>
              <p>
                of the {format(FLICKR_PRO_TAKEDOWNS_ALLOTMENT)} included in your
                plan
              </p>
              <div className={`${progressBarWrap}`}>
                <ProgressBar
                  completeness={normalize(
                    usage.totalTakedownsSent / FLICKR_PRO_TAKEDOWNS_ALLOTMENT
                  )}
                />
              </div>
            </div>
          ) : (
            <div>
              <p>Takedown notices sent</p>
              <h3>{format(usage.sentTakedownsThisMonth)}</h3>
              <p>
                of the {format(user.plan.features.takedowns)} included in your
                plan
              </p>
              <div className={`${progressBarWrap}`}>
                <ProgressBar
                  completeness={normalize(
                    usage.sentTakedownsThisMonth / user.plan.features.takedowns
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

PlansHeader.propTypes = {
  user: PropTypes.object.isRequired,
}
