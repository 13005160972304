import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import styled from 'react-emotion'

const ActionArea = styled.div`
  padding: 0 0;
  textarea {
    height: 200px;
    padding: 5px;
    width: 100%;
    border: 1px solid #c7c7c7;
    font-size: 18px;
    resize: none;
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }
  }
  flex: 1;

  @media (max-width: 768px) {
    textarea {
      margin-top: 20px;
    }
  }
`

export default function TextArea(props) {
  const { input, meta = {}, label } = props

  return (
    <FormRow {...props}>
      <Label title={label} align="left" />
      <ActionArea width={props.width}>
        <textarea maxLength={10000} {...input} />
        <Feedback meta={meta} />
      </ActionArea>
    </FormRow>
  )
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
}

TextArea.defaultProps = {
  width: '100%',
}
