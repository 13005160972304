import React from 'react'
import { NavLink } from 'react-router-dom'
import { css } from 'react-emotion'
import { Button, GridWrappingColumn, ActionSection } from 'common'
import Guide from '../Guide/Guide'

export default function EmptyState() {
  return (
    <GridWrappingColumn maxWidth={876} padding={250}>
      <Guide />
      <ActionSection>
        <NavLink to="/matches/overview" css={mobileLink}>
          <Button fixedWidth css={mobileButton}>
            Review Matches
          </Button>
        </NavLink>
        <p className="button-separator">or</p>
        <NavLink
          to={{
            pathname: '/cases/submission/new',
            state: { skip: 'intro' },
          }}
          css={mobileLink}
        >
          <Button fixedWidth outline css={mobileButton}>
            Submit a case manually
          </Button>
        </NavLink>
      </ActionSection>
    </GridWrappingColumn>
  )
}

const mobileLink = css`
  @media (max-width: 768px) {
    width: 100%;
    // padding: 0 10px;
  }
`

const mobileButton = css`
  @media (max-width: 768px) {
    width: 100%;
  }
`
