import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

const InnerLabel = styled.div`

  flex: ${({ vertical, theme, align }) =>
    vertical
      ? '1'
      : theme && theme.labelWidth
      ? `0 1 ${theme.labelWidth}`
      : align === 'left'
      ? '1 1 300px'
      : '1 1 230px'};
  /*line-height: ${props => (props.align === 'left' ? '1.8em' : '40px')};*/
  line-height: 1.5;
  text-align: ${props =>
    props.theme && props.theme.labelAlign
      ? props.theme.labelAlign
      : props.align};
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.15em;
  padding-right: ${props => (props.align === 'left' ? '15px;' : '24px;')};
  color: #434343;

  @media(max-width: 768px) {
    flex: 1;
    width: 100%;
    text-align: left !important;
  }
`

export default function Label({
  title,
  vertical,
  align = 'right',
  inactive = false,
  ...props
}) {
  return (
    <InnerLabel
      align={align}
      vertical={vertical}
      inactive={inactive}
      {...props}
    >
      {title}
    </InnerLabel>
  )
}

Label.propTypes = {
  title: PropTypes.node,
  vertical: PropTypes.bool,
  align: PropTypes.string,
}
