import React, { Fragment } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Button, ChooseBestImageSource, SexySeparator } from 'common'
import { CATEGORY_LABELS, DATA_ELEVIO, getProxyUrlToMatch, resolveCountryNameForCode } from 'pixsy-constants'
import { css, cx } from 'react-emotion'
import MatchImageViewer from './MatchImageViewer/MatchImageViewer'
import moment from 'moment'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import MatchDetailsAccuracy from './MatchDetailsAccuracy/MatchDetailsAccuracy'
import MatchDetailsEntity from './MatchDetailsEntity/MatchDetailsEntity'
import MatchDetailsScore from './MatchDetailsScore/MatchDetailsScore'
import { Circle } from '../CardView/Icons'

const prettyURL = (url) => (url && url.split('://')[1] && url.length > 100 ? url.slice(0, 100) + '...' : url)

export const MatchDetails = ({
  updateMatch,
  match,
  image,
  cluster,
  clusterLength,
  onSingleMatchHideApproved,
  onSingleMatchHideInvalid,
  onSingleMatchHideReset,
  onSingleMatchHideIgnore,
  user,
  showMobileActionsMenu,
  allCookies,
}) => {
  const { _id, entity, domain, images, case: caze, takedown, matches, score, tags } = cluster
  const countryTagInTags = tags.find((t) => t.startsWith('country'))
  const countryCode = countryTagInTags ? countryTagInTags.split(':')[1] : domain.country
  const detectedCountry = resolveCountryNameForCode(countryCode)
  const isSharkMode = !!allCookies['pixsy-shark']

  const matchSource = match.meta && (match.meta.source || match.meta.matchSource)
  const isDeepSearch = ['gris', 'gris-go'].includes(matchSource)

  return (
    <div className={matchDetails}>
      <div>
        <ChooseBestImageSource candidates={[match.url, getProxyUrlToMatch(match._id)]} type="match" match={match}>
          {({ url, isError }) => (
            <Row>
              <Col sm={6}>
                <MatchImageViewer
                  match={{
                    ...match,
                    url,
                  }}
                  image={image}
                />
              </Col>
              <Col sm={6}>
                <div
                  css={css`
                    ${inspectorWrapper} ${clusterLength > 0 ? minimized : ''};
                  `}
                >
                  <div css={inspectorInner}>
                    <div css={inspectorDefinitionLists}>
                      <dl css={inspectPrimaryDetails}>
                        <dt>Detected</dt>
                        <dd>{moment.utc(match.created).format('LL')}</dd>
                        <dt>Country</dt>
                        <dd>{detectedCountry || 'Undefined'}</dd>
                        <dt>Category</dt>
                        <dd>{(domain.category && CATEGORY_LABELS[domain.category]) || 'Unavailable'}</dd>
                        {isSharkMode && (
                          <Fragment>
                            <dt>Match Source</dt>
                            <dd>{isDeepSearch ? 'Deep Search' : 'Pixsy Spiders'}</dd>
                          </Fragment>
                        )}
                        <MatchDetailsAccuracy
                          match={match}
                          testMode={
                            user.email && (user.email.includes('@rspective.') || user.email.includes('@pixsy.'))
                          }
                        />
                        <MatchDetailsScore
                          score={score}
                          match={match}
                          cluster={cluster}
                          testMode={
                            user.email && (user.email.includes('@rspective.') || user.email.includes('@pixsy.'))
                          }
                        />
                        <MatchDetailsEntity
                          entity={entity}
                          domain={domain}
                          testMode={
                            user.email && (user.email.includes('@rspective.') || user.email.includes('@pixsy.'))
                          }
                        />
                        {/* <dt>Type</dt> */}
                        {/* <dd>{domain.country || 'Unavailable'}</dd> */}
                      </dl>

                      <dl css={inspectSecondaryDetails}>
                        {match.origin && match.origin.title && (
                          <Fragment>
                            <dt>Page Title</dt>
                            <dd
                              dangerouslySetInnerHTML={{
                                __html: match.origin.title,
                              }}
                            />
                          </Fragment>
                        )}

                        {match.origin && match.origin.url && (
                          <Fragment>
                            <dt>Page URL</dt>
                            <dd>
                              <a href={match.origin.url} target="_blank" rel="noreferrer">
                                {prettyURL(match.origin.url)}
                              </a>
                            </dd>
                          </Fragment>
                        )}

                        <dt>Image URL</dt>
                        <dd>
                          {match.url ? (
                            <a href={match.url} target="_blank">
                              {prettyURL(match.url)}
                            </a>
                          ) : (
                            '-'
                          )}
                        </dd>
                        {match.url && typeof match.isOnline === 'boolean' && (
                          <React.Fragment>
                            <dt>Status</dt>
                            <dd>
                              <Fragment>
                                {match.url.startsWith('x-raw-image://') && (
                                  <Fragment>
                                    <Circle color="#e8bb00" /> Undefined
                                  </Fragment>
                                )}
                              </Fragment>
                              <Fragment>
                                {!match.url.startsWith('x-raw-image://') && match.isOnline && (
                                  <Fragment>
                                    <Circle color="#36ea7b" /> Online
                                  </Fragment>
                                )}
                              </Fragment>
                              <Fragment>
                                {!match.url.startsWith('x-raw-image://') && match.isOnline === false && (
                                  <Fragment>
                                    <Circle color="red" /> Offline
                                  </Fragment>
                                )}
                              </Fragment>
                            </dd>
                          </React.Fragment>
                        )}
                      </dl>
                    </div>
                    {!caze && !takedown && (
                      <Fragment>
                        <SexySeparator
                          /*label="Hide Single Match"
                labelStyles={{ backgroundColor: '#262626', color: '#666' }}*/
                          lineColor="rgba(149,152,154,0.1)"
                        />
                        <div className={cx(inspectorButtonWrapper, showMobileActionsMenu && mobileActionsMenu)}>
                          <label data-elevio-selector={DATA_ELEVIO.MATCHES_VIEWER_HIDE_SINGLE}>Hide Single Match</label>
                          <div className={cn(viewerButtonGroup, inspectorButtonGroup)}>
                            {!match.ignored && !match.invalid && (
                              <Button
                                title="Indicates that this use of your image is approved or you are happy for it to remain online."
                                className={{
                                  [active]: match.approved,
                                }}
                                onClick={onSingleMatchHideApproved}
                              >
                                <IconApprove />
                                Approved Use
                              </Button>
                            )}
                            {!match.ignored && !match.approved && (
                              <Button
                                title="Indicates that the match Pixsy has delivered is not your image and is a ‘false positive’. This information allows us to improve our services."
                                className={{
                                  [active]: match.invalid,
                                }}
                                onClick={onSingleMatchHideInvalid}
                              >
                                <IconCircle />
                                Not My Image
                              </Button>
                            )}
                            {match.ignored ? (
                              <Button title="Undo this action." onClick={onSingleMatchHideReset}>
                                <IconUndo />
                                Undo
                              </Button>
                            ) : (
                              <Button
                                title="Indicates you wish to exclude this single match from Case Submission and Takedowns."
                                onClick={onSingleMatchHideIgnore}
                              >
                                <IconIgnore />
                                Ignore
                              </Button>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </ChooseBestImageSource>
      </div>
    </div>
  )
}
const ConnectedMatchDetails = connect((state) => ({ user: state.auth.user }))(withCookies(MatchDetails))

MatchDetails.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line
}

export default ConnectedMatchDetails

function IconUndo() {
  return (
    <svg viewBox="-635.037 5967.429 32.567 32.884">
      <defs>
        <style>
          {`.svg-viewer-undo {
              fill: #fff;
              opacity: 0.6
            }`}
        </style>
      </defs>
      <path
        className="svg-viewer-undo"
        d="M20.39,9.816l2.3-2.3H15.668v7.017l3.5-3.5a10.465,10.465,0,1,1-16.636,3.8l-1.6-.661A12.184,12.184,0,0,0,16.865,30.11,12.191,12.191,0,0,0,20.39,9.816Z"
        transform="translate(-629.355 6004.075) rotate(-120)"
      />
    </svg>
  )
}

function IconApprove() {
  return (
    <svg viewBox="-891.201 2203.65 28.917 21.469">
      <defs>
        <style>
          {`.svg-viewer-approve {
                          fill: none;
                          stroke: #fff;
                          stroke-width: 2px;
                          opacity: 0.6;
                        }`}
        </style>
      </defs>
      <path
        className="svg-viewer-approve"
        d="M6573.778,3228.232l7.777,7.626,8.247-8.086L6601.3,3216.5"
        transform="translate(-7464.279 -1012.14)"
      />
    </svg>
  )
}

function IconIgnore() {
  return (
    <svg viewBox="-568.4 2203.442 22.015 22.016">
      <defs>
        <style>
          {`
            .svg-viewer-invalid {
              fill: none;
              stroke: #a8a8a8;
              stroke-width: 2px;
          }`}
        </style>
      </defs>
      <g transform="translate(-1886 1615)">
        <path className="svg-viewer-invalid" d="M20396.156,2444l20.6,20.6" transform="translate(-19077.85 -1854.849)" />
        <path className="svg-viewer-invalid" d="M0,0,20.6,20.6" transform="translate(1318.307 609.745) rotate(-90)" />
      </g>
    </svg>
  )
}

function IconCircle() {
  return (
    <svg viewBox="-724 2203 23 23">
      <defs>
        <style>
          {`
            .svg-viewer-circle-1, .svg-viewer-circle-3 {
              fill: none;
            }

            .svg-viewer-circle-1 {
              stroke: #fff;
              stroke-width: 2px;
              opacity: 0.6;
            }

            .svg-viewer-circle-2 {
              stroke: none;
            }`}
        </style>
      </defs>
      <g className="svg-viewer-circle-1" transform="translate(-724 2203)">
        <circle className="svg-viewer-circle-2" cx="11.5" cy="11.5" r="11.5" />
        <circle className="svg-viewer-circle-3" cx="11.5" cy="11.5" r="10.5" />
      </g>
    </svg>
  )
}

const viewerButtonGroup = css`
  button {
    background-color: transparent;
    display: inline-block;
    margin: 0;
    border: 0;
    color: rgba(226, 226, 226, 0.702);
    border: solid 1px rgba(226, 226, 226, 0.702);
    border-right: none;
    line-height: 1;
    padding: 17px 42px;

    @media (max-width: 1280px) {
      padding: 18px 22px;
    }

    @media (max-width: 768px) {
      padding: 17px 0px !important;
    }

    @media (min-width: 768px) and (max-width: 1340px) {
      white-space: normal;
    }

    &:hover {
      background-color: #fff !important;
      color: #000;
    }
  }

  button:last-of-type {
    border-right: solid 1px rgba(226, 226, 226, 0.702);
  }
`

const matchDetails = css`
  width: 100%;
  max-height: 515px;
  margin-bottom: 25px;
  padding: 30px;
  background-color: #333;
  box-shadow: 0 1px 10px #1e1e1e;

  @media (max-width: 1200px) {
    height: 370px;
    min-height: auto;

    .singleView {
      height: 275px;
    }
  }
  @media (max-width: 1000px) {
    height: 300px;
    min-height: auto;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    height: auto;
  }

  @media (max-width: 768px) {
    background-color: transparent;
    box-shadow: none;
  }
`

const inspectorWrapper = css`
  position: relative;
  width: 100%;
  display: inline-block;
  padding-bottom: 66.66%;

  @media (max-width: 1280px) and (min-width: 100px) {
    padding-bottom: 77%;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: calc(100px + 66.33vw);
    left: 0;
    width: 100%;
    height: calc(100% - 66.34vw - 150px);
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 !important;
  }
`

const minimized = css`
  @media (max-width: 768px) {
    position: fixed;
    // top: calc(100px + 66.33vw + 90px);
    top: calc(100px + 66.33vw + ((22.75vw * 0.6633) + 54px));
    left: 0;
    width: 100%;
    // height: calc(100% - 66.34vw - 250px);
    height: calc(100vh - 100px - 66.33vw - ((22.75vw * 0.6633) + 54px) - 60px);
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 !important;
  }
`

const inspectorInner = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px 15px 0 15px;
`

const inspectPrimaryDetails = css`
  margin-right: 20px;
  width: 130px;
  flex: 0 0 200px;
  padding-right: 20px;

  @media (max-width: 1200px) {
    flex: 0 0 auto;
    display: block !important;
    float: left;
    height: 100%;
  }

  @media (max-width: 1200px) {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding-right: 0;
  }

  dd {
    font-size: 1.3em;
    font-weight: 400;
    opacity: 1;
    @media (max-width: 1200px) {
      font-size: 1em;
    }
  }
`

const inspectSecondaryDetails = css`
  flex: 50% 1 0;
`

const inspectorDefinitionLists = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80%;

  @media (max-width: 1200px) {
    height: 75%;
    display: block;
  }

  a {
    transition: color 200ms ease;
    &:hover {
      color: #fff;
    }
  }

  dl {
    padding: 10px 0 5px;
    justify-content: space-between;
    min-width: 0;
    overflow-wrap: break-word;
    margin-bottom: 10px;
    height: 76%;
    @media (max-width: 1200px) {
      display: inline-block !important;
      float: left;
      height: 100%;
    }
    @media (max-width: 1200px) {
      float: left;
      width: 100%;
      height: auto;
    }
  }

  dt {
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    @media (max-width: 1200px) {
      float: left;
      width: 30%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  dd {
    margin-top: -2px;
    margin-bottom: 4px;
    opacity: 0.8;
    @media (max-width: 1200px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    svg {
      vertical-align: 1px;
      height: 8px;
      margin-right: 3px;
    }
    a {
      color: #bbb;
      width: 100%;
      word-break: break-all;
    }
  }
`

const inspectorButtonWrapper = css`
  label {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-top: 15px;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-weight: 400;
    opacity: 0.5;
    font-size: 0.9em;
    @media (max-width: 1200px) {
      font-size: 0.8em;
    }
  }
  @media (max-width: 768px) {
    // display: none;
    position: fixed;
    // bottom: calc(60px - 116px);
    top: calc(-84px - 120px);
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #262626;
    padding: 10px;
    transition: all 300ms linear;
    border-top: solid 1px rgba(226, 226, 226, 0.702);

    label {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
`

const mobileActionsMenu = css`
  @media (max-width: 768px) {
    // display: block;
    // bottom: 60px;
    // top: 86px;
    top: 116px;
  }
`

const inspectorButtonGroup = css`
  max-width: 90%;
  margin: 5px auto 0;
  display: flex;

  @media (max-width: 1000px) {
    margin: 0;
    max-width: 100%;
  }

  button {
    text-align: center;
    font-size: 0.85em;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
    flex-basis: 33%;
    flex-grow: 1;
    svg {
      display: block;
      height: 15px;
      margin: 0 auto 8px auto;
    }
    @media (max-width: 1200px) {
      font-size: 0.8em;
      padding-top: 13px;
      padding-bottom: 13px;
      svg {
        height: 12px;
      }
    }
    @media (max-width: 1000px) {
      width: 33.33%;
      font-size: 0.7em;
    }
    @media (max-width: 768px) {
      font-size: 0.65em;
    }

    &:hover {
      background-color: #e8e8e8;
      color: #141414;

      .svg-viewer-approve {
        stroke: #141414;
      }
      .svg-viewer-circle-1 {
        stroke: #141414;
      }
      .svg-viewer-undo {
        fill: #141414;
      }
      .svg-viewer-invalid {
        stroke: #141414;
      }
    }
  }
`

const active = css`
  background-color: #e8e8e8;
  color: #141414;

  .svg-viewer-approve {
    stroke: #141414;
  }
  .svg-viewer-circle-1 {
    stroke: #141414;
  }
  .svg-viewer-undo {
    fill: #141414;
  }
  .svg-viewer-invalid {
    stroke: #141414;
  }
`

// function Circle({ color }) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="-6771 2098 8.499 8.499">
//       <ellipse
//         style={{ fill: color }}
//         cx="4.25"
//         cy="4.25"
//         rx="4.25"
//         ry="4.25"
//         transform="translate(-6771 2098)"
//       />
//     </svg>
//   )
// }
