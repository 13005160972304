import React from 'react'
import { Button } from 'common'
import { css } from 'react-emotion'

export default function RemoveServiceDialog({ onCancel, onConfirm }) {
  return (
    <div css={content}>
      <h4>
        Are you sure you want to unlink this account and remove its images and
        matches?
      </h4>

      <div css={buttons}>
        <Button outline onClick={onCancel}>
          Cancel
        </Button>
        <Button danger onClick={onConfirm}>
          Remove
        </Button>
      </div>
    </div>
  )
}

RemoveServiceDialog.propTypes = {}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h4 {
    text-align: center;
  }
`

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
