import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['canada-type-gibson', 'Open Sans', 'Roboto', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#008aab',
    },
    secondary: {
      main: '#ff7f2f',
    },
  },
})

export default theme
