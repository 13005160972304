import * as React from 'react'
import { css, cx } from 'react-emotion'
import Grid from '@material-ui/core/Grid'

// @see - https://next.material-ui.com/api/grid/
export const PixsyGrid = ({
  alignContent,
  alignItems,
  children,
  className,
  component = 'div',
  direction = 'row',
  justify,
  spacing,
  wrap,
  style = {},
  onMouseEnter,
  onMouseLeave,
  onClick,
  onFocus,
  onBlur,
}) => {
  const styles = {
    container: cx(className, css(style)),
  }
  return (
    <Grid
      container
      alignContent={alignContent}
      alignItems={alignItems}
      classes={styles}
      component={component}
      direction={direction}
      justify={justify}
      wrap={wrap}
      spacing={spacing}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
    </Grid>
  )
}
