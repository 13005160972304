import { IMAGE, IMAGE_ARRAY } from './schemas' // eslint-disable-line import/named

export const IMAGE_LOAD = 'IMAGE_LOAD'
export const IMAGE_LOAD_SUCCESS = 'IMAGE_LOAD_SUCCESS'
export const IMAGE_LOAD_FAIL = 'IMAGE_LOAD_FAIL'

export const IMAGES_INVALIDATE = 'IMAGES_INVALIDATE'

export const IMAGES_LOAD = 'IMAGES_LOAD'
export const IMAGES_LOAD_SUCCESS = 'IMAGES_LOAD_SUCCESS'
const IMAGES_LOAD_FAIL = 'IMAGES_LOAD_FAIL'

const IMAGE_DIRECT_SAVE = 'IMAGE_DIRECT_SAVE'
export const IMAGE_DIRECT_SAVE_SUCCESS = 'IMAGE_DIRECT_SAVE_SUCCESS'
const IMAGE_DIRECT_SAVE_FAIL = 'IMAGE_DIRECT_SAVE_FAIL'

const IMAGE_DROPBOX_SAVE = 'IMAGE_DROPBOX_SAVE'
export const IMAGE_DROPBOX_SAVE_SUCCESS = 'IMAGE_DROPBOX_SAVE_SUCCESS'
const IMAGE_DROPBOX_SAVE_FAIL = 'IMAGE_DROPBOX_SAVE_FAIL'

const IMAGE_GOOGLE_SAVE = 'IMAGE_GOOGLE_SAVE'
export const IMAGE_GOOGLE_SAVE_SUCCESS = 'IMAGE_GOOGLE_SAVE_SUCCESS'
const IMAGE_GOOGLE_SAVE_FAIL = 'IMAGE_GOOGLE_SAVE_FAIL'

const IMAGE_SUMMARY_LOAD = 'IMAGE_SUMMARY_LOAD'
const IMAGE_SUMMARY_LOAD_SUCCESS = 'IMAGE_SUMMARY_LOAD_SUCCESS'
const IMAGE_SUMMARY_LOAD_FAIL = 'IMAGE_SUMMARY_LOAD_FAIL'

const IMAGE_IGNORE = 'IMAGE_IGNORE'
export const IMAGE_IGNORE_SUCCESS = 'IMAGE_IGNORE_SUCCESS'
const IMAGE_IGNORE_FAIL = 'IMAGE_IGNORE_FAIL'

const IMAGE_IGNORE_BULK = 'IMAGE_IGNORE_BULK'
export const IMAGE_IGNORE_BULK_SUCCESS = 'IMAGE_IGNORE_BULK_SUCCESS'
const IMAGE_IGNORE_BULK_FAIL = 'IMAGE_IGNORE_BULK_FAIL'

const IMAGE_UNIGNORE = 'IMAGE_UNIGNORE'
export const IMAGE_UNIGNORE_SUCCESS = 'IMAGE_UNIGNORE_SUCCESS'
const IMAGE_UNIGNORE_FAIL = 'IMAGE_UNIGNORE_FAIL'

const IMAGES_SYNCHRONIZE = 'IMAGES_SYNCHRONIZE'
export const IMAGES_SYNCHRONIZE_SUCCESS = 'IMAGES_SYNCHRONIZE_SUCCESS'
const IMAGES_SYNCHRONIZE_FAIL = 'IMAGES_SYNCHRONIZE_FAIL'

export const SERVICE_REMOVE = 'SERVICE_REMOVE'
export const SERVICE_REMOVE_SUCCESS = 'SERVICE_REMOVE_SUCCESS'
export const SERVICE_REMOVE_FAIL = 'SERVICE_REMOVE_FAIL'

const IMAGE_UPDATE = 'IMAGE_UPDATE'
const IMAGE_UPDATE_SUCCESS = 'IMAGE_UPDATE_SUCCESS'
const IMAGE_UPDATE_FAIL = 'IMAGE_UPDATE_FAIL'

const IMAGE_DELETE = 'IMAGE_DELETE'
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS'
const IMAGE_DELETE_FAIL = 'IMAGE_DELETE_FAIL'

const IMAGE_MERGE = 'IMAGE_MERGE'
export const IMAGE_MERGE_SUCCESS = 'IMAGE_MERGE_SUCCESS'
const IMAGE_MERGE_FAIL = 'IMAGE_MERGE_FAIL'

export const S3_UPLOAD = 'S3_UPLOAD'
export const S3_UPLOAD_FINISH = 'S3_UPLOAD_FINISH'

const initialState = {
  summary: null,
  total: null,
  loading: null,
  uploading: null,
  merging: null,
  loadingService: null,
}

export default function images(state = initialState, action = {}) {
  switch (action.type) {
    case IMAGES_LOAD:
    case IMAGE_UPDATE:
    case IMAGE_DELETE:
    case IMAGES_SYNCHRONIZE:
    case IMAGE_SUMMARY_LOAD:
      return {
        ...state,
        loading: true,
        loadingService: action.service,
      }
    case IMAGE_MERGE: {
      return {
        ...state,
        merging: true,
      }
    }

    case IMAGES_LOAD_SUCCESS:
    case IMAGES_LOAD_FAIL:
    case IMAGE_UPDATE_SUCCESS:
    case IMAGE_UPDATE_FAIL:
    case IMAGE_DELETE_SUCCESS:
    case IMAGE_DELETE_FAIL:
    case IMAGES_SYNCHRONIZE_SUCCESS:
    case IMAGE_SUMMARY_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      }
    case IMAGE_SUMMARY_LOAD_SUCCESS: {
      return {
        ...state,
        summary: action.payload,
        loading: false,
      }
    }
    case IMAGE_MERGE_FAIL:
    case IMAGE_MERGE_SUCCESS: {
      return {
        ...state,
        merging: false,
      }
    }
    case S3_UPLOAD: {
      return {
        ...state,
        uploading: true,
      }
    }
    case S3_UPLOAD_FINISH: {
      return {
        ...state,
        uploading: false,
      }
    }
    default:
      return state
  }
}

export function defaultQuery() {
  return {
    page: 1,
    tags: [],
    // ignored: false,
    // pageSize: 54,
    // search: '',
    // source: 'all',
    // sort: '-created',
    // tracked: true,
  }
}

/**
 * Get key to access loaded entities from imagesByPage object
 * Sort to make sure its always in the same order
 *
 * @param {?Object) query
 * @returns {string} "like a-b-c-d-e"
 */
export function paginationKey(query) {
  query = {
    ...defaultQuery(),
    ...query,
  }
  return Object.keys(query)
    .sort()
    .map(k => query[k].toString())
    .join('-')
}

/**
 * @param {Object} globalState
 * @returns {boolean}
 */
export function isLoaded(globalState) {
  return globalState.images && globalState.images.loaded
}

export function saveImageDirect(data) {
  return {
    types: [
      IMAGE_DIRECT_SAVE,
      IMAGE_DIRECT_SAVE_SUCCESS,
      IMAGE_DIRECT_SAVE_FAIL,
    ],
    promise: client => client.post('/images', { data }),
  }
}

export function saveImageDropbox(data) {
  return {
    types: [
      IMAGE_DROPBOX_SAVE,
      IMAGE_DROPBOX_SAVE_SUCCESS,
      IMAGE_DROPBOX_SAVE_FAIL,
    ],
    promise: client => client.post('/images/dropbox', { data }),
  }
}

export function saveImageGoogle(token, images) {
  return {
    types: [
      IMAGE_GOOGLE_SAVE,
      IMAGE_GOOGLE_SAVE_SUCCESS,
      IMAGE_GOOGLE_SAVE_FAIL,
    ],
    promise: client =>
      client.post('/images/google', { data: { token, images } }),
  }
}

/**
 * @param {string} _id of the photo mongodb record
 * @returns {Object} Action
 */
export function ignore(id) {
  return {
    types: [IMAGE_IGNORE, IMAGE_IGNORE_SUCCESS, IMAGE_IGNORE_FAIL],
    promise: client =>
      client.put(`/images/${id}/ignore`, { data: { ignored: true } }),
    schema: { photo: IMAGE },
  }
}

/**
 * @param {Array<string>} imageIds of the image mongodb records
 * @param {boolean} newState of the documents
 * @returns {Object} Action
 */
export function bulkIgnore({ imageIds, newState }) {
  return {
    types: [
      IMAGE_IGNORE_BULK,
      IMAGE_IGNORE_BULK_SUCCESS,
      IMAGE_IGNORE_BULK_FAIL,
    ],
    promise: client =>
      client.put(`/images/bulk/ignore`, { data: { imageIds, newState } }),
    schema: { images: IMAGE_ARRAY },
  }
}

/**
 * @param {string} _id of the photo mongodb record
 * @returns {Object} Action
 */
export function unignore(id) {
  return {
    types: [IMAGE_UNIGNORE, IMAGE_UNIGNORE_SUCCESS, IMAGE_UNIGNORE_FAIL],
    promise: client =>
      client.put(`/images/${id}/ignore`, { data: { ignored: false } }),
    schema: { photo: IMAGE },
  }
}

/**
 * Resets outdated pages of pagimation
 * @returns {Object} Action
 */
export function invalidate(query = {}) {
  return {
    type: IMAGES_INVALIDATE,
    schema: { images: IMAGE_ARRAY },
    payload: { query },
  }
}

/**
 * @returns {Object} Action
 */
export function load(query, options = {}) {
  return {
    types: [IMAGES_LOAD, IMAGES_LOAD_SUCCESS, IMAGES_LOAD_FAIL],
    promise: client =>
      client.get('/images', { params: { ...query, ...options } }),
    schema: { images: IMAGE_ARRAY },
    payload: { query },
  }
}

/**
 * @returns {Object} Action
 */
export function loadOne(id, params = {}) {
  return {
    types: [IMAGE_LOAD, IMAGE_LOAD_SUCCESS, IMAGE_LOAD_FAIL],
    promise: client => client.get(`/images/${id}`, { params }),
    schema: IMAGE,
  }
}

export function loadSummary(id) {
  return {
    types: [
      IMAGE_SUMMARY_LOAD,
      IMAGE_SUMMARY_LOAD_SUCCESS,
      IMAGE_SUMMARY_LOAD_FAIL,
    ],
    promise: client => client.get(`/images/${id}/summary`),
  }
}

/**
 * @returns {Object} Action
 */
export function synchronize(source, id) {
  return {
    types: [
      IMAGES_SYNCHRONIZE,
      IMAGES_SYNCHRONIZE_SUCCESS,
      IMAGES_SYNCHRONIZE_FAIL,
    ],
    promise: client =>
      client.put(`/users/me/sources/${source}`, { data: { id } }),
    schema: { images: IMAGE_ARRAY },
    service: source,
  }
}

/**
 * @returns {Object} Action
 */
export function removeService(source) {
  return {
    types: [SERVICE_REMOVE, SERVICE_REMOVE_SUCCESS, SERVICE_REMOVE_FAIL],
    promise: client => client.delete(`/users/me/sources/${source}`),
    schema: { images: IMAGE_ARRAY },
    service: source,
  }
}

/**
 * @param {Object} data payload
 * @returns {Object} Action
 */
export function update(id, data) {
  return {
    types: [IMAGE_UPDATE, IMAGE_UPDATE_SUCCESS, IMAGE_UPDATE_FAIL],
    promise: client => client.put(`/images/${id}/update`, { data }),
    schema: { image: IMAGE },
  }
}

/**
 * @returns {Object} Action
 */
export function remove(id) {
  return {
    types: [IMAGE_DELETE, IMAGE_DELETE_SUCCESS, IMAGE_DELETE_FAIL],
    promise: client => client.delete(`/images/${id}`),
    schema: { image: IMAGE },
  }
}

export function mergeImages(primaryImage, images) {
  return {
    types: [IMAGE_MERGE, IMAGE_MERGE_SUCCESS, IMAGE_MERGE_FAIL],
    promise: client =>
      client.post('/images/merge', { data: { primaryImage, images } }),
  }
}

export function s3Upload(type) {
  return {
    type,
  }
}
