// 7. Review, pricing, and legal aggrements
import Fade from '@material-ui/core/Fade'
import { TooltipContainer, TooltipContext } from 'common'
import isEmpty from 'lodash/isEmpty'
import {
  PRICE_RANGE_ENUM,
  SETTLEMENT_KEYS,
} from 'pixsy-schema/case/SettlementSchema'
import {
  SUBMISSION_LABEL as LABELS,
  SUBMISSION_NAMES as NAMES,
  SUBMISSION_REQUIRED as REQUIRED,
} from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import { css } from 'react-emotion'
import {
  PixsyBooleanField,
  PixsyErrorMessage,
  PixsyFileList,
  PixsyFormGroup,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
  PixsyTextField,
  UppyDragDrop,
} from '../../../Components'
import { PriceCurrency } from './PriceCurrency'
import reviewStageTooltipContent from './reviewStageTooltipContent'

/**
 * @augments {React.Component<import('../StageProps').IProps, {}>}
 */
export class ReviewStage extends React.Component {
  fieldsDisplayOrder = [
    NAMES.continued_use_license,
    NAMES.price_range,
    NAMES.requested_price.min.value,
    NAMES.requested_price.max.value,
    NAMES.requested_price.max.currency,
    NAMES.comments,
    SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__,
    NAMES.koDisclaimerTruth,
  ]

  getDisabledFields = () => {
    const { errors } = this.props
    const disabled = {}
    let disableRest = false

    this.fieldsDisplayOrder.forEach(name => {
      if (disableRest) {
        disabled[name] = true
        return
      }

      const error = errors[name]
      if (
        error &&
        typeof error.message === 'object' &&
        'title' in error.message
      ) {
        disableRest = true
      }
      disabled[name] = false
    })

    return disabled
  }

  Question = ({ name, disabled, greyBox, validateOtherPaths }) => {
    const tooltip = <TooltipContainer id={name} />
    const { errors } = this.props
    const hasError = errors[name]

    return (
      <PixsyPaper padding="20px 60px 20px 60px" disabled={disabled[name]}>
        <PixsyFormGroup tooltip={tooltip}>
          <PixsyGridItem xs={9}>
            <PixsyLabel text={LABELS[name]} required={REQUIRED[name]} />
          </PixsyGridItem>
          <PixsyGridItem xs={3}>
            <PixsyBooleanField
              name={name}
              redBox={!!hasError}
              disabled={disabled[name]}
              greyBox={greyBox}
              validateOnChange
              validateOtherPaths={validateOtherPaths}
            />
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }

  KoDisclaimerTruth = ({ name, disabled }) => {
    const tooltip = <TooltipContainer id={name} />
    const { errors } = this.props
    const hasError = errors[name]

    return (
      <PixsyPaper padding="30px 60px 35px 60px" disabled={disabled[name]}>
        <PixsyFormGroup
          alignItems="center"
          alignTooltip="flex-end"
          tooltip={tooltip}
        >
          <PixsyGridItem xs={9}>
            <PixsyLabel>
              I certify that all the information and statements provided by me,
              concerning this case, are true and accurate to the best of my
              knowledge.
            </PixsyLabel>
            <PixsyLabel padding="25px 0">
              I understand that providing false information can lead to legal
              liability and costs.
            </PixsyLabel>
            <PixsyLabel>
              I agree to, and read, the{' '}
              <a href="/agreements/agent-authorization" target="_blank">
                Pixsy Agent Authorization Agreement
              </a>{' '}
              and understand my responsibilities within it.
            </PixsyLabel>
          </PixsyGridItem>
          <PixsyGridItem xs={3} alignSelf="flex-end">
            <PixsyBooleanField
              name={name}
              redBox={!!hasError}
              disabled={disabled[name]}
              validateOnChange
            />
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }

  TextField = ({
    name,
    type,
    disabled,
    validateOtherPaths,
    fieldContext,
    validateEntireForm,
  }) => {
    const tooltip = <TooltipContainer id={name} />
    return (
      <PixsyPaper padding="20px 60px 20px 60px" disabled={disabled[name]}>
        <PixsyFormGroup tooltip={tooltip}>
          <PixsyGridItem xs={8}>
            <PixsyLabel text={LABELS[name]} required={REQUIRED[name]} />
          </PixsyGridItem>
          <PixsyGridItem xs={4}>
            <PixsyTextField
              type={type}
              name={name}
              disabled={disabled[name]}
              validateOtherPaths={validateOtherPaths}
              fieldContext={fieldContext}
              validateEntireForm={validateEntireForm}
              validateOnChange
            />
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }

  ErrorBox = function ErrorBoxInner({
    name,
    disabled,
    onlyWhenTouched = true,
  }) {
    return (
      !disabled[name] && (
        <PixsyErrorMessage
          name={name}
          onlyWhenTouched={onlyWhenTouched}
          render={({
            error: {
              message: { title, subtitle },
            },
          }) => (
            <Fade in>
              <PixsyPaper
                padding="36px 60px 36px 60px"
                className={errorStyle}
                expanded
                variant="red"
              >
                {title && <div css={errorTitleStyle}>{title}</div>}
                {subtitle && <div css={errorSubTitleStyle}>{subtitle}</div>}
              </PixsyPaper>
            </Fade>
          )}
        />
      )
    )
  }

  TextAreaGeneralNotes = ({ disabled }) => {
    const questionName = NAMES.comments
    const isDisabled = disabled[questionName]
    const placeholder = `Is there any other information related to the submission or image(s) that you would like to add?`.trim()

    return (
      <PixsyPaper padding="36px 60px 36px 60px" disabled={isDisabled}>
        <PixsyLabel
          text={LABELS[questionName]}
          required={REQUIRED[questionName]}
        />
        <PixsyFormGroup>
          <PixsyGridItem xs={12}>
            <PixsyPaper variant="white" borderless>
              <PixsyTextField
                name={questionName}
                disabled={isDisabled}
                placeholder={placeholder}
                multiline
                rows={4}
              />
            </PixsyPaper>
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }

  render() {
    const disabled = this.getDisabledFields()
    const {
      Question,
      TextField,
      ErrorBox,
      KoDisclaimerTruth,
      TextAreaGeneralNotes,
    } = this
    const {
      getValue,
      generalFiles,
      values,
      removeGeneralFile,
      retryGeneralFile,
      sourceLocation,
      isFormReadOnly,
      fullStageValidation,
    } = this.props

    const displayRequestedPrice = [
      PRICE_RANGE_ENUM.Client,
      PRICE_RANGE_ENUM.Highest,
    ].includes(getValue(NAMES.price_range))

    return (
      <TooltipContext content={reviewStageTooltipContent}>
        <Question name={NAMES.continued_use_license} disabled={disabled} />
        <ErrorBox
          name={NAMES.continued_use_license}
          disabled={disabled}
          onlyWhenTouched={fullStageValidation === 'REVIEW' ? false : undefined}
        />

        <TextField
          name={NAMES.price_range}
          type="price-negotiations-select"
          validateEntireForm
          disabled={disabled}
        />
        {displayRequestedPrice && (
          <React.Fragment>
            <PriceCurrency
              name={NAMES.requested_price.min.value}
              leftName={NAMES.requested_price.min.value}
              padding="20px 60px 20px 60px"
              disabled={disabled}
            />
            <ErrorBox
              name={NAMES.requested_price.min.value}
              disabled={disabled}
              onlyWhenTouched={
                fullStageValidation === 'REVIEW' ? false : undefined
              }
            />
            <ErrorBox
              name={NAMES.requested_price.max.value}
              disabled={disabled}
              onlyWhenTouched={
                fullStageValidation === 'REVIEW' ? false : undefined
              }
            />
          </React.Fragment>
        )}

        <TextAreaGeneralNotes disabled={disabled} />

        {!isFormReadOnly && (
          <PixsyPaper
            padding="36px 60px 26px 60px"
            borderlessBottom
            disabled={disabled[SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__]}
          >
            <UppyDragDrop
              uppy={generalFiles.uppy}
              description="Any files, invoices or additional evidence you need to upload?"
            />
          </PixsyPaper>
        )}
        {!isEmpty(values.__GENERAL_ATTACHMENTS__) && (
          <PixsyPaper
            padding="0px 60px 26px 60px"
            borderlessTop
            disabled={disabled[SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__]}
          >
            <PixsyFileList
              files={values.__GENERAL_ATTACHMENTS__}
              onRemove={removeGeneralFile}
              onRetry={retryGeneralFile}
              sourceLocation={sourceLocation}
              disabled={
                disabled[SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__] ||
                isFormReadOnly
              }
            />
          </PixsyPaper>
        )}

        <KoDisclaimerTruth name={NAMES.koDisclaimerTruth} disabled={disabled} />
        <ErrorBox
          name={NAMES.koDisclaimerTruth}
          disabled={disabled}
          onlyWhenTouched={fullStageValidation === 'REVIEW' ? false : undefined}
        />
      </TooltipContext>
    )
  }
}

const errorStyle = css`
  * {
    text-align: center;
  }
`
const errorTitleStyle = css`
  font-size: 20px;
  color: #2e2e2e;
`
const errorSubTitleStyle = css`
  font-size: 16px;
  color: #6e6e6e;
  margin-top: 12px;
`
