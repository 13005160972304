import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'
import moment from 'moment'
import { Button } from 'common'

export default function BulkSubmissionFormImagesVerifier({ images, locked }) {
  const pagination = 20
  const [loadMoreLimit, setLoadMoreLimit] = React.useState(pagination)
  const paginatedImages = images.slice(0, loadMoreLimit).filter(Boolean)

  const loadMore = () => {
    setLoadMoreLimit(loadMoreLimit + pagination)
  }

  return (
    <React.Fragment>
      {!locked && <p>Please check that below image details are correct.</p>}

      {paginatedImages.map(r => (
        <RegisterCard key={`registration_${r._id}`}>
          <RegisterCardImage>
            <img src={r.url} />
          </RegisterCardImage>

          <RegisterCardDetail className="pixsyId">
            <RegisterCardDetailLabel>Title</RegisterCardDetailLabel>
            <RegisterCardDetailValue>{r.title}</RegisterCardDetailValue>
          </RegisterCardDetail>

          <RegisterCardDetail>
            <RegisterCardDetailLabel>
              Date of first Publication
            </RegisterCardDetailLabel>
            <RegisterCardDetailValue>
              {moment(
                r.licensing &&
                  r.licensing.first_published &&
                  r.licensing.first_published.date
              ).format('YYYY/MM/DD')}
            </RegisterCardDetailValue>
          </RegisterCardDetail>

          <RegisterCardDetail>
            <RegisterCardDetailLabel>
              Country of first Publication
            </RegisterCardDetailLabel>
            <RegisterCardDetailValue>
              {r.licensing &&
                r.licensing.first_published &&
                r.licensing.first_published.country}
            </RegisterCardDetailValue>
          </RegisterCardDetail>
        </RegisterCard>
      ))}

      {loadMoreLimit <= images.length && (
        <div className={centerContent}>
          <p>
            Displaying {loadMoreLimit} of {images.length} images.
          </p>
          <p>
            <Button hoverMain onClick={loadMore}>
              Load More
            </Button>
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

BulkSubmissionFormImagesVerifier.propTypes = {
  images: PropTypes.array.isRequired,
  locked: PropTypes.bool.isRequired,
}

const centerContent = css`
  text-align: center;
`

const RegisterCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #e6e6e6;
  &:last-child {
    margin-bottom: 15px;
  }
`

const RegisterCardImage = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  img {
    max-height: 90px;
    max-width: 90px;
  }
`
const RegisterCardDetail = styled.div`
  width: 20%;
  padding: 15px 5px 0 5px;
  text-align: left;
`
const RegisterCardDetailLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.9px;
  line-height: 22px;
  overflow-wrap: break-word;
  text-transform: uppercase;
`
const RegisterCardDetailValue = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`
