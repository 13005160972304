import Grow from '@material-ui/core/Grow'
import RefreshIcon from '@material-ui/icons/RefreshTwoTone'
import { SexyThumbnail } from 'common'
import isFunction from 'lodash/isFunction'
import React, { useState, useCallback, useEffect } from 'react'
import { css, cx } from 'react-emotion'
import { PixsyGrid, PixsyGridItem } from '../../../Components'
import {
  getCaption,
  getImageUrl,
  getImgTitle,
  getPercentage,
  isItLoading,
  shouldRetry,
} from '../../util/images'
import { SelectableItem } from './SelectableItem'

const BASE_TIMEOUT_ANIMATION = 150 // ms

export const ImagesGrid = ({ images, ...props }) => (
  <PixsyGrid spacing={4}>
    {images.map((image, index) => (
      <ImagesGridSelectable
        key={image.uuid || image._id || image.url || index}
        image={image}
        index={index}
        {...props}
      />
    ))}
  </PixsyGrid>
)

const ImagesGridSelectable = ({
  image,
  index,
  isImagesSearch,
  retryImageImportFile,
  color,
  colorSelected,
  disabled,
  icon,
  iconSelected,
  removeImage,
  addImage,
}) => {
  const [loading, setLoading] = useState(true)
  const [isRetry, setIsRetry] = useState(false)
  const [percent, setPercent] = useState(null)
  const [caption, setCaption] = useState(null)
  const [preview, setPreview] = useState(null)
  const [title, setTitle] = useState(null)
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    setLoading(isItLoading(image.file))
    setIsRetry(shouldRetry(image.file))
    setPercent(getPercentage(image.file))
  }, [image.file])

  useEffect(() => {
    setCaption(getCaption(isRetry, loading, percent))
  }, [loading, isRetry, percent])

  useEffect(() => {
    setPreview(getImageUrl(image.file, image))
    setTitle(getImgTitle(image.file, image))
    setIsSelected(isImagesSearch ? !!image.uuid : false)
  }, [image, isImagesSearch])

  const onFileRetry = React.useCallback(
    (isRetry, img) => {
      if (isImagesSearch) return
      if (isRetry) {
        if (!isFunction(retryImageImportFile)) return
        return retryImageImportFile(image.file.id)
      }
      return null
    },
    [isImagesSearch, retryImageImportFile]
  )

  const removeImageHandler = useCallback(() => {
    removeImage(image.uuid)
  }, [image.uuid, removeImage])

  const addImageHandler = useCallback(() => {
    if (isFunction(addImage)) addImage(image)
  }, [image])

  return (
    <Grow in timeout={BASE_TIMEOUT_ANIMATION * (index + 1)}>
      <PixsyGridItem xs={3}>
        <SelectableItem
          color={color}
          colorSelected={colorSelected}
          enabled={!disabled}
          icon={icon}
          iconSelected={iconSelected}
          id={image.uuid || image._id || image.url || index}
          isSelected={isSelected}
          // isSelecting={isImagesSearch}
          toggleSelection={
            isSelected || !isImagesSearch ? removeImageHandler : addImageHandler
          }
        >
          <ImageItem
            handleRetry={onFileRetry}
            isLoading={loading}
            isRetry={isRetry}
            percentage={percent}
            thumbnailLabel={caption}
            title={title}
            url={preview}
          />
        </SelectableItem>
      </PixsyGridItem>
    </Grow>
  )
}

export const ImageItem = React.memo(
  ({
    url,
    isLoading,
    isRetry,
    title,
    thumbnailLabel,
    percentage,
    handleRetry,
  }) => {
    return (
      <PixsyGrid wrap="nowrap" direction="column">
        <PixsyGridItem xs={12}>
          <SexyThumbnail
            url={url}
            loading={isLoading}
            loadingPercentage={percentage}
            icon={isRetry && <RefreshIcon fontSize="large" />}
            iconLabel={thumbnailLabel}
            onClick={handleRetry}
          />
        </PixsyGridItem>
        <PixsyGridItem
          xs={12}
          className={cx({
            [titleStyle]: true,
            [colorRed]: isRetry,
            [colorBlue]: isLoading,
          })}
        >
          {title}
        </PixsyGridItem>
      </PixsyGrid>
    )
  }
)

const titleStyle = css`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9em;
  line-height: 1.35em;
  padding-top: 0.9em;
  text-align: center;
  color: #3b3b3b;
`
const colorRed = css`
  color: #ff8181;
`
const colorBlue = css`
  color: #008aab;
`
