import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Button, createContactUsHandler } from 'common'
import { resolveCountryNameForCode } from 'pixsy-constants'

// @TODO: Change Text
export default function SubmissionValidationTMOnly({
  onTakedown,
  onIgnore,
  cluster,
}) {
  const country = resolveCountryNameForCode(cluster.domain.country)
  return (
    <div css={wrap}>
      <h1>We are unable to accept this case</h1>

      <h4>
        Due to our experience of legal cases in {country} (this legal
        jurisdiction), we have determined we are unable to proceed with this
        case.
      </h4>

      <p>
        We work in partnership with our local legal partner in {country} to make
        these determinations based on strict criteria.
        <br />
        We are only able to accept cases where the counterpart is a large
        (operating commercial) company, and the specifics of the image use and
        image would mean a legal case is viable.
      </p>

      <p>
        Pixsy uses a set of advanced algorithms to pre-determine if a case is
        suitable for resolution. We do this to save you time, and to ensure that
        our case management team can focus their efforts on recovering
        compensation for viable cases.
      </p>

      <p>
        We recommend sending a takedown notice for this match. If you feel this
        should be a viable commercial case, you can contact us and we can
        further evaluate the case for you.
      </p>

      <div className="dialog-slider--button-wrapper">
        <Button
          onClick={createContactUsHandler('Country TM Only')}
          greyTertiary
        >
          Contact us
        </Button>
        <Button grey onClick={onIgnore}>
          Ignore Match
        </Button>
        <Button hoverMain onClick={onTakedown}>
          Send Takedown
        </Button>
      </div>
      {!global.PRODUCTION && (
        <p className="debug">SubmissionValidationTMOnly</p>
      )}
    </div>
  )
}

SubmissionValidationTMOnly.propTypes = {
  onTakedown: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  cluster: PropTypes.object.isRequired,
}

const wrap = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
`
