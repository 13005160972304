import * as React from 'react'
import PropTypes from 'prop-types'
import { RightArrow } from 'common'
import { css, cx } from 'react-emotion'

export default class GoToPage extends React.PureComponent {
  state = {
    value: this.props.value,
    onButtonFocus: false,
  }

  onHandleChange = e => {
    const value = e.target.value.trim()
    if (isNaN(value)) return

    const totalPages = this.props.totalPages
    const page = Math.abs(Number(value))

    if (page > totalPages) return

    this.setState({
      value: value === '' ? '' : page,
    })
  }

  onHandleEnterPress = e => {
    if (e.key === 'Enter') {
      this.onHandlePageChange()
    }
  }

  onHandleBlur = e => {
    const isPageNumber = this.state.value !== ''

    if (isPageNumber) return

    this.setState({ value: this.props.value })
  }

  onHandlePageChange = () => {
    const { totalPages, value } = this.props
    const userSelectedPage = this.state.value
    const realPageNumber = Math.min(this.state.value, totalPages) || value

    if (realPageNumber === value) return

    if (userSelectedPage !== realPageNumber) {
      this.setState({ value: realPageNumber }, () =>
        this.props.onPageChange({ selected: realPageNumber - 1 })
      )
    } else {
      this.props.onPageChange({ selected: this.state.value - 1 })
    }
  }

  render() {
    const { value } = this.state

    return (
      <div className={cx('paginationGotoContainer', paginationGotoContainer)}>
        <label css={paginationLabel} htmlFor="goto">
          Go to page
        </label>
        <input
          className={paginationGotoInput}
          type="text"
          name="goto"
          value={value}
          onChange={this.onHandleChange}
          onKeyPress={this.onHandleEnterPress}
          onBlur={this.onHandleBlur}
          data-lpignore="true" // disable lastPass auto-complete for single input
        />
        <button css={nextPageButton} onClick={this.onHandlePageChange}>
          <RightArrow />
        </button>
      </div>
    )
  }
}
GoToPage.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}
const paginationLabel = css`
  font-size: 12px;
  font-weight: 500;
  color: #464646;
  margin-bottom: 0px;
`
const paginationGotoContainer = css`
  text-align: right;
`
const paginationGotoInput = css`
  margin-left: 20.8px;
  height: 28px;
  width: 51px;
  border-radius: 0px !important;
  padding: 0px 10px;
  background: white;
  border: 1px solid #e6e6e6;
  color: rgba(70, 70, 70, 0.5);
  outline: none;
  text-align: right;
  &:focus {
    color: rgb(70, 70, 70);
  }
`
const nextPageButton = css`
  margin-left: 8.69px;
  width: 22.76px;
  height: 24px;
  background: none;
  border: none;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  svg {
    min-width: 7.76px;
    opacity: 0.5;
  }
`
