import React from 'react'
import { Transition, animated, config } from 'react-spring/renderprops.cjs'
import * as PropTypes from 'prop-types'
import { css } from 'react-emotion'

const animationWrapper = children => animationProps => (
  <animated.div className={animatedWrapper} style={animationProps}>
    {children}
  </animated.div>
)

const AnimatedComponentSwitcher = ({ condition, children }) => {
  return (
    <div className={wrapper}>
      <Transition
        native
        initial={null}
        items={condition}
        from={{ transform: 'scaleY(0)', height: 0, opacity: 0 }}
        enter={{ transform: 'scaleY(1)', height: 'auto', opacity: 1 }}
        leave={{ transform: 'scaleY(0)', height: 0, opacity: 0 }}
        config={config.gentle}
      >
        {condition => animationWrapper(children(condition))}
      </Transition>
    </div>
  )
}

const wrapper = css`
  display: grid;
  grid-template: 1fr / 1fr;
`

const animatedWrapper = css`
  grid-column: 1 / 1;
  grid-row: 1 / 1;
`

AnimatedComponentSwitcher.propTypes = {
  condition: PropTypes.bool,
  renderOnTrue: PropTypes.node,
  renderOnFalse: PropTypes.node,
}

export default AnimatedComponentSwitcher
