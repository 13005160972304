import { CATEGORY_LABELS } from 'pixsy-constants'

const resolveValuesFromDomain = (domain) => {
  let categoryName = CATEGORY_LABELS[domain.category]
  return {
    categoryName,
  }
}

export default resolveValuesFromDomain
