import React from 'react'

export const SubmissionValidationDialogCategoryUnresolvable = () => (
  <div>
    <h6>
      We have determined that this site does not meet our criteria for a{' '}
      <span data-elevio-article="70">viable commercial case.</span>
    </h6>
    <p>
      Pixsy uses a set of advanced algorithms to pre-determine if a case is
      suitable for resolution. We do this to save you time, and to ensure that
      our case management team can focus their efforts on recovering
      compensation for viable cases.
    </p>
    <p>
      We recommend that you <b>send a Takedown Notice</b> for this match,
      requesting the image is removed by the website operator or host.
    </p>
    <p>
      If you feel this should be a viable commercial case, you can contact us
      and we can further evaluate the case for you.
    </p>
  </div>
)
