import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as Shapes from '../../utils/shapes'
import sharedStyles from '../../components/styles'
import { SingleMatchImageInfo } from '../../components/ImagesInfo'
import ButtonsWrapper from '../../components/ButtonsWrapper'
import { actionCreators, withMatchViewerContext } from '../../store'
import { useIgnoreMatch } from '../../utils/useClusters'
import SingleMatchTiles from '../../components/SingleMatchTiles'

const GroupMatchListItem = ({ cluster, match, dispatch, globalDispatch }) => {
  const [ignoreMatch] = useIgnoreMatch(globalDispatch)
  const image = cluster.images.find((i) => i._id === match.image) || {}

  const handleMoreModalOpenRequest = () =>
    dispatch(actionCreators.setMoreModalViewOpen(cluster._id, match._id, cluster))

  const handleActionsModalViewOpenRequest = () =>
    dispatch(actionCreators.setActionsModalViewOpen(cluster._id, match._id, cluster, false))

  const handleDetailsViewOpenRequest = () => {
    dispatch(actionCreators.setDetailsModalViewOpen(cluster._id, match._id, cluster))
  }

  const handleIgnoreMatchRequest = async () => {
    ignoreMatch({
      variables: {
        clusterId: cluster._id,
        matchId: match._id,
        state: true,
      },
      onCompleted: () => {
        try {
          window.mixpanel.track('Matches.mv3.Ignore', {
            _id: match._id,
            tm: cluster.tags.some((t) => ['top', 'top:world', 'top:us'].includes(t)),
          })
        } catch (e) {
          console.error(`Unable to invoke mixpanel method:`, e)
        }
      },
    })
  }

  const handleUndoIgnoreMatchRequest = () =>
    ignoreMatch({
      variables: {
        clusterId: cluster._id,
        matchId: match._id,
        state: false,
      },
    })

  const bodyClassNames = cx(sharedStyles.clusterListItemBody, {
    [styles.ignored]: match.ignored,
  })

  return (
    <div className={sharedStyles.clusterListItemContainer}>
      <div className={bodyClassNames}>
        <SingleMatchTiles image={image} match={match} expandView={handleDetailsViewOpenRequest} height={168} />
        <SingleMatchImageInfo cluster={cluster} match={match} />
        <ButtonsWrapper
          isGroupView
          isMatchIgnored={match.ignored}
          isMultiMatch={false}
          onOpenMoreModal={handleMoreModalOpenRequest}
          onIgnoreRequest={handleIgnoreMatchRequest}
          openTakeActionModal={handleActionsModalViewOpenRequest}
          onUndoIgnoreMatchRequest={handleUndoIgnoreMatchRequest}
          isMultiMatchView
          caseId={cluster.case}
          takedownId={cluster.takedown}
        />
      </div>
    </div>
  )
}

const styles = {
  ignored: css`
    opacity: 0.5;
  `,
}

GroupMatchListItem.propTypes = {
  cluster: Shapes.Cluster,
  match: Shapes.Match,

  dispatch: PropTypes.func.isRequired,
}

export default compose(
  connect(null, (globalDispatch) => ({ globalDispatch })),
  withMatchViewerContext
)(GroupMatchListItem)
