import React, { Fragment, useState, useEffect } from 'react'
import styled from 'react-emotion'
import { Img } from 'react-image'
import { CircularProgress } from '@material-ui/core'
import { useInView } from 'react-hook-inview'
import SelfCenter from './SelfCenter'

const FixedHeightImageContained = ({ url, id, height, offlineLabel = 'match' }) => {
  const [loadImage, setLoadImage] = useState(false)

  const [ref, isVisible] = useInView({
    rootMargin: '200px',
  })

  useEffect(() => {
    if (isVisible) {
      setLoadImage(true)
    }
  }, [isVisible])

  const urlsForContent = [
    ...(url && url.startsWith('x-raw-image://')
      ? ['https://static.pixsy.io/nopreview.svg']
      : [url, ...(id ? [`https://accessible.pixsy.io/image/${id}`] : [])]),
    `https://static.pixsy.io/${offlineLabel}_offline.svg`,
    'https://static.pixsy.io/404.png',
  ]

  /**
   * If one of the black and white images are loaded, use no cover to avoid blur
   */
  const urlsForCover = urlsForContent.filter((u) => u && !u.startsWith('https://static.pixsy.io'))

  return (
    <FixedHeightImageContainedStyled innerRef={ref} height={height}>
      {loadImage && (
        <Fragment>
          <Img className="background" src={urlsForCover} alt="" />
          <Img
            className="contain"
            src={urlsForContent}
            alt=""
            loader={
              <SelfCenter>
                <CircularProgress size={20} />
              </SelfCenter>
            }
          />
        </Fragment>
      )}
    </FixedHeightImageContainedStyled>
  )
}

export default FixedHeightImageContained

const FixedHeightImageContainedStyled = styled.div`
  position: relative;
  height: ${(props) => props.height}px;
  overflow: hidden;
  background: #fcfcfc;

  img {
    display: block;
    width: 100%;
    height: 100%;
    &.contain {
      object-fit: contain;
      position: relative;
      z-index: 2;
    }

    &.background {
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.3);
      filter: blur(12px) brightness(95%);
      z-index: 1;
    }
  }
`
