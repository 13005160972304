import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Field } from 'redux-form'
import {
  Button,
  SexySeparator,
  ActionSection,
  TickCheckbox,
  TextAreaVertical,
  ContentSection,
} from 'common'

export default function TakedownFormEmailSubmitSection({
  onWaypoint,
  submitting,
  fetchingData,
}) {
  return (
    <ContentSection id="submit">
      <Waypoint onEnter={onWaypoint} />

      <Field
        name="comment"
        label="Additional Explanation"
        subLabel="(to be included in the notice)"
        component={TextAreaVertical}
      />
      <SexySeparator space={0} />
      <Field
        name="goodFaith"
        type="checkbox"
        label="I swear, under penalty of perjury, that I am the copyright owner or am authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. I have a good faith belief that use of the copyrighted materials described above as allegedly infringing is not authorized by the copyright owner, its agent, or the law. The information provided in the notification is accurate to the best of my knowledge and belief."
        component={TickCheckbox}
      />
      <SexySeparator space={0} />
      <Field
        name="disclaimer"
        type="checkbox"
        label="I understand that the recipient of my takedown notice may require additional information or clarification, which I will need to provide. Pixsy has no control over how notices are handled by the recipient, and is not responsible for any adverse consequences or liability directly or indirectly related to this takedown notice."
        component={TickCheckbox}
      />
      <ActionSection last>
        <Button submit disabled={submitting || fetchingData}>
          Send Takedown
        </Button>
        <p>
          The takedown notice will be emailed with your email account address
          displayed as the sender.
        </p>
      </ActionSection>
    </ContentSection>
  )
}

TakedownFormEmailSubmitSection.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}
