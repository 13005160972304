import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'
import onClickOutside from 'react-onclickoutside'
import { XIcon /*, LeftArrow, RightArrow */ } from 'common'
import {
  title as titleStyle,
  body as bodyStyle,
  footer as footerStyle,
} from './defaultStyle'

const log = require('debug')(':tooltip')
/**
 * Class for tooltip component.
 *
 * @class      Tooltip (name)
 * @param      {string} [title] - title as string
 * @param      {string} [body] - body as string
 * @param      {string} [footer] - footer as string
 * @param      {func} [next] - tooltip navigation next
 * @param      {func} [previous] - tooltip navigation previous
 * @param      {bool} [keepOpen] - if true, tooltip won't close on mouseLeave (useful for navigation behaviour)
 */
class Tooltip extends React.Component {
  constructor(props) {
    super(props)

    this.contentRef = React.createRef()

    this.state = {
      visible: true,
      overflow: {
        width: 0,
        height: 0,
      },
    }
  }

  show() {
    log('show')
    this.setState({
      visible: true,
    })
  }

  close(fadingDelay = 0) {
    log('close after %o', fadingDelay)
    window.setTimeout(() => {
      // the user might have hovered on the tooltip
      // canceling the fading (onMouseEnter event)
      // if (this.state.fadingAway) {
      //   this.setState({  overflow: {} })
      // }
      this.setState({
        visible: false,
      })
    }, fadingDelay)
  }

  toggleKeepOpen = () => {
    this.setState(state => ({
      keepOpen: !state.keepOpen,
    }))
  }

  handleClickOutside = event => {
    log('click outside', event)
    this.handleClose()
  }

  handleMouseLeave = () => {
    log('mouse leave')
    if (this.props.keepOpen) {
      return
    }
    if (this.state.visible) {
      this.close(1000)
    }
  }

  handleMouseEnter = () => {
    log('mouse enter')
    if (this.props.keepOpen) {
      return
    }
    // if (this.state.fadingAway) {
    //   this.setState({
    //     visible: true,
    //     fadingAway: false,
    //   })
    // }
  }

  handleClose = () => {
    this.setState({
      keepOpen: false,
    })
  }

  componentDidUpdate = () => {
    if (this.state.visible) {
      requestAnimationFrame(() => {
        this.checkOverflow()
      })
    }
  }

  render() {
    const { title, body, footer /*, children, previous, next*/ } = this.props

    const { keepOpen } = this.state

    return (
      <div className={cx(container)}>
        <button css={questionMark} onClick={this.toggleKeepOpen} tabIndex={-1}>
          ?
        </button>
        <StyledContainer
          innerRef={ref => {
            this.contentRef = ref
          }}
          className={cx(activeContainer, keepOpen && forceDisplay)}
          overflowWidth={this.state.overflow.width > 0}
          overflowHeight={this.state.overflow.height > 0}
          onMouseLeave={this.handleMouseLeave}
          onMouseEnter={this.handleMouseEnter}
        >
          <div
            className={cx(
              questionMark,
              questionMarkInner
              // keepOpen && questionMarkActive
            )}
          >
            ?
          </div>
          <div>
            <h5 css={titleStyle}>{title}</h5>
            <div css={bodyStyle}>{body}</div>
            {footer && <div css={footerStyle}>{footer}</div>}
          </div>
          {/* {(previous || next) && (
              <div css={arrowWrapper}>
                {previous && (
                  <div
                    className={cx(arrowBase, leftArrow)}
                    onClick={() => previous()}
                  >
                    <LeftArrow />
                  </div>
                )}
                {next && (
                  <div
                    className={cx(arrowBase, rightArrow)}
                    onClick={() => next()}
                  >
                    <RightArrow />
                  </div>
                )}
              </div>
            )} */}
          {keepOpen && (
            <span
              className={cx(closeButton)}
              aria-hidden="true"
              onClick={this.handleClose}
            >
              <XIcon />
            </span>
          )}
        </StyledContainer>
      </div>
    )
  }

  checkOverflow = () => {
    const element = this.contentRef
    if (!element) {
      return
    }
    const rect = element.getClientRects()[0]
    if (!rect) {
      return
    }
    if (!rect.toJSON || typeof rect.toJSON !== 'function') {
      return
    }
    const { bottom, right } = rect.toJSON()
    const stateOverflow = this.state.overflow
    const overflow = {}
    if (bottom > window.innerHeight) {
      const height = bottom - window.innerHeight
      // stop cyclical updates
      if (stateOverflow.height !== height) {
        overflow.height = height
      }
    }
    if (right > window.innerWidth) {
      const width = right - window.innerWidth
      // stop cyclical updates
      if (stateOverflow.width !== width) {
        overflow.width = width
      }
    }
    if (Object.keys(overflow).length > 0) {
      this.setState({ overflow })
    }
  }
}

Tooltip.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.node,
  // previous: PropTypes.func,
  // next: PropTypes.func,
}

export default onClickOutside(Tooltip)

const pixsyBlue = '#008aab'

const container = css`
  width: 20px;
  height: 20px;
  border: none;
  position: relative;
`

const StyledContainer = styled.div`
  @media (min-width: 600px) {
    transform: ${props => {
      let transform = ''
      if (props.overflowWidth) {
        transform += 'translateX(-90%)'
      }
      if (props.overflowHeight) {
        transform += ' translateY(-90%)'
      }
      return `${transform};`
    }};
  }
`

const activeContainer = css`
  padding: 18px 20px 20px;
  background-color: #ffffff;
  z-index: 100;
  position: fixed;
  top: -6px;
  height: 80%;
  width: 75%;
  left: 50%;
  margin-left: -37.5%;
  overflow: auto;

  box-shadow: 0px 0px 25px 7px rgba(0, 0, 0, 0.16);
  pointer-events: none;

  transition: all 0.15s ease-in-out;
  transform: translate3d(0, -10px, 0);
  opacity: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 40px solid transparent;
    border-bottom: 40px solid ${pixsyBlue};
    transform: translate(-50%, -50%) rotate(-45deg);
    pointer-events: none;
  }

  @media (min-width: 600px) {
    position: relative;
    width: 250px;
    height: auto;
    &:before {
      overflow: hidden;
      width: 80px;
      height: 80px;
      background-color: ${pixsyBlue};
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`

// const openOnHover = css`
//   &:hover {
//     transform: translate3d(0, 0, 0);
//     opacity: 1;
//     pointer-events: inherit;
//     opacity: 1;
//   }
// `
const forceDisplay = css`
  transform: translate3d(0, 0, 0);
  opacity: 1;
  pointer-events: inherit;
  opacity: 1;
`

const questionMark = css`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border: 1px solid rgba(67, 67, 67, 0.3);
  color: rgba(67, 67, 67, 0.3);
  background-color: transparent;
  user-select: none;
  text-align: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 4px;

  padding: 0;
  outline: none !important;

  display: block;
  font-size: 0.85em;
  font-weight: 500;
  line-height: 0.26em;

  /* &:hover + div,
  &:active + div {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: inherit;
  } */
`

const questionMarkInner = css`
  cursor: pointer;
  border: none;
  background-color: white;
  width: 20px;
  height: 20px;
  font-size: 1.1em;
  color: ${pixsyBlue};
  line-height: 1.27em;
`

// const questionMarkActive = css`
//   background-color: ${pixsyBlue};
//   color: white;
// `

const closeButton = css`
  position: fixed;
  top: calc(10% + 2px);
  height: 16px;
  width: 16px;
  opacity: 1;
  cursor: pointer;
  fill: none;
  stroke: rgba(0, 0, 0, 0.7);
  stroke-width: 1px;

  @media (min-width: 600px) {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  svg {
    stroke: #3b3b3b;
  }
`

// const arrowWrapper = css`
//   position: relative;
//   height: 25px;
// `

// const arrowBase = css`
//   position: absolute;
//   top: 0;
//   height: 15px;
//   width: 15px;
//   cursor: pointer;
// `

// const rightArrow = css`
//   right: 2px;
// `

// const leftArrow = css`
//   left: 2px;
// `
