import React, { Component } from 'react'
import { css } from 'react-emotion'
import PropTypes from 'prop-types'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import { push, replace } from 'react-router-redux'
import debugFactory from 'debug'
import qs from 'querystring'

import {
  GridWrappingColumn,
  Pagination,
  CardFilterBar,
  StatusHeading,
  LoadingOverlay,
  MonitoringQuotaExceeded,
  PaginationFooter,
} from 'common'
import {
  paginationKey,
  load,
  ignore,
  unignore,
} from '../../redux/modules/images'
import { getImagesQuery } from '../../redux/selectors'
import { withEmptyStates } from '../../decorators'
import { getUsage } from '../../redux/modules/auth'
import EmptyState from './EmptyState/EmptyState'
import ImagesCardGrid from './CardGrid/ImagesCardGrid'
import DefaultFloater from './QueryBar//DefaultFloater'
import QueryBarFloater from './QueryBar//QueryBarFloater'
import { SortBarLabel } from './SortBar'

const debug = debugFactory('images')

function mapStateToProps(state, ownProps) {
  const {
    entities: { images },
    pagination: { imagesByPage },
    images: { loading },
    auth: { usage, user },
  } = state
  const { match, location } = ownProps

  const query = getImagesQuery({}, { location, match })
  debug('mapStateToProps', query)

  debug('key %o', paginationKey(query))
  const imagesPagination = imagesByPage[paginationKey(query)] || { ids: [] }
  const visibleImages = imagesPagination.ids.map(id => images[id])
  const total = imagesPagination.total

  return {
    isB2C: user.role !== 'api',
    usage,
    imagesPagination,
    total,
    loading,
    pageCount: Math.ceil(total / query.pageSize),

    // detailImage: images[objectIdInPath && objectIdInPath[0]] || null,
    images: visibleImages,
    query,
    // photosOverLimitCount,
  }
}

export class Images extends Component {
  state = {
    loadingImage: false,
  }

  handlePageChange = event => {
    const selected = typeof event === 'number' ? event : event.selected
    const { query } = this.props
    const {
      location: { pathname },
      history,
    } = this.props

    history.push({
      pathname,
      search: qs.stringify({
        page: selected,
        pageSize: query.pageSize,
        sort: query.sort,
      }),
    })
  }

  handlePerPageChange = nPages => {
    const { query } = this.props
    const {
      location: { pathname },
      history,
    } = this.props

    history.push({
      pathname,
      search: qs.stringify({
        page: 0,
        pageSize: nPages,
        sort: query.sort,
      }),
    })
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     this.props.match.params.imageId !== nextProps.match.params.imageId ||
  //     this.props.match.params.slug !== nextProps.match.params.slug ||
  //     this.props.photos.length !== nextProps.photos.length ||
  //     this.state.loadingImage !== nextState.loadingImage
  //   )
  // }

  getPaginationProps = () => {
    const { total, query, pageCount } = this.props
    return {
      total,
      perPage: query.pageSize,
      forcePage: query.page,
      pageCount,
    }
  }

  renderTopPagination = () => {
    const { total } = this.props

    return total ? (
      <Pagination
        {...this.getPaginationProps()}
        onPageChange={this.handlePageChange}
        marginPagesDisplayed={0}
        size="small"
      />
    ) : null
  }

  renderBottomPagination = () => {
    const { total } = this.props

    return total ? (
      <PaginationFooter
        singularType="image"
        pluralType="images"
        onPageChange={this.handlePageChange}
        onPerPageChange={this.handlePerPageChange}
        paginationProps={this.getPaginationProps()}
        marginPagesDisplayed={2}
      />
    ) : null
  }

  render() {
    const {
      isB2C,
      reload,
      usage,
      total,
      // detailImage,
      location,
      images,
      loading,
      pageCount,
      match,
      query: { tags /* page */ },
      // photosOverLimitCount,
    } = this.props

    const showDefaultNavigation =
      !(location.search && location.search.includes('search')) &&
      (tags.length === 0 ||
        (tags.length === 1 && (tags[0] === 'new' || tags[0] === 'ignored')))

    const untrackedImages = Math.max(
      0,
      usage.totalPhotos - usage.trackablePhotos
    )

    return (
      <GridWrappingColumn maxWidth={1480}>
        {/* TODO: Status Heading */}
        {showDefaultNavigation ? (
          <DefaultFloater location={location} match={match} tags={tags} />
        ) : (
          <QueryBarFloater location={location} match={match} tags={tags} />
        )}

        <LoadingOverlay show={loading}>
          {total !== 0 && (
            <StatusHeading
              title={`${Number(total).toLocaleString()} image${
                total !== 1 ? 's' : ''
              }`}
              subtitle={
                pageCount > 0 &&
                `on ${Number(pageCount).toLocaleString()} page${
                  pageCount > 1 ? 's' : ''
                }`
              }
              isVisible={Number.isInteger(total) && total > 0}
            />
          )}

          <hr
            css={`
              ${hr}
            `}
          />

          {isB2C && untrackedImages > 0 && (
            <MonitoringQuotaExceeded untrackedImagesCount={untrackedImages} />
          )}

          {total !== 0 && (
            <CardFilterBar
              renderLeftAddon={props => <SortBarLabel {...props} />}
              renderCenterAddon={this.renderTopPagination}
            />
          )}

          <ImagesCardGrid
            location={location}
            match={match}
            total={total}
            loading={loading}
            images={images}
            linkbase={{ ...location }}
            reload={reload}
          />

          {total !== 0 && this.renderBottomPagination()}
        </LoadingOverlay>
      </GridWrappingColumn>
    )
  }
}

export default asyncConnect([
  {
    promise({ store: { dispatch, getState }, match, location }) {
      const query = getImagesQuery({}, { location, match })
      debug('query %o', query)
      const promise = Promise.all([dispatch(load(query)), dispatch(getUsage())])

      return __SERVER__ && promise
    },
  },
])(
  connect(
    mapStateToProps,
    {
      load,
      ignore,
      unignore,
      push,
      replace,
    },
    (stateProps, dispatchProps, ownProps) => {
      const { location, match } = ownProps
      const query = getImagesQuery({}, { location, match })

      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        reload: () => dispatchProps.load(query),
      }
    }
  )(
    withEmptyStates([
      {
        shouldRender: ({ loading, query, total }) =>
          !loading && query.tags.length === 0 && total === 0,
        render: () => <EmptyState />,
      },
    ])(Images)
  )
)

Images.propTypes = {
  total: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  usage: PropTypes.object.isRequired,
}

const hr = css`
  width: 40px;
  background-color: #eee;
  height: 2px;
  border: 0;
`
