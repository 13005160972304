import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useContext } from 'react'
import { css, cx } from 'react-emotion'
import { PixsyFormContext } from '../PixsyForm/PixsyForm'
import { PixsyGrid } from '../PixsyGrid/PixsyGrid'
import { PixsyGridItem } from '../PixsyGrid/PixsyGridItem'

const DEFAULT_ITEMS = [
  {
    label: 'NO',
    value: false,
  },
  {
    label: 'YES',
    value: true,
  },
]

export const PixsyBooleanField = React.memo(
  ({
    name,
    redBox,
    greyBox,
    disabled,
    items = DEFAULT_ITEMS,
    validateOnChange = false,
    validateOnBlur = false,
    validateOtherPaths,
    validateEntireForm,
    fieldContext,
    fieldContextOnBlur,
    fieldContextOnChange,
    readOnly,
  }) => {
    const FLEX_BASIS = '50%'

    if (items.length !== 2)
      throw new Error(
        'PixsyBooleanField - unexpected number of items (expected 2)'
      )

    const api = useContext(PixsyFormContext)

    const currentValue = api.getValue(name)
    const lSideValue = items[0].value
    const rSideValue = items[1].value
    const isEqualNull = currentValue === null
    const isEqualLeft = lSideValue === currentValue
    const isEqualRight = rSideValue === currentValue

    const lTabIndex = isEqualNull || isEqualLeft ? 0 : -1
    const rTabIndex = isEqualRight ? 0 : -1

    const contextOnChange = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnChange || {}),
      }),
      [fieldContext, fieldContextOnChange]
    )
    const contextOnBlur = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnBlur || {}),
      }),
      [fieldContext, fieldContextOnBlur]
    )

    const handleValidateOtherPaths = useCallback(
      ctx => {
        if (validateOtherPaths) {
          if (typeof validateOtherPaths === 'string') {
            api.handleValidationPath(validateOtherPaths, ctx)
          } else if (Array.isArray(validateOtherPaths)) {
            validateOtherPaths.forEach(otherName =>
              api.handleValidationPath(otherName, ctx)
            )
          }
        }
      },
      [validateOtherPaths]
    )

    const handleValidateCurrentPath = useCallback(
      ctx => {
        api.handleValidationPath(name, ctx)
        handleValidateOtherPaths(ctx)
      },
      [name]
    )

    const makeOnChange = useCallback(
      v => e => {
        if (e.target.checked) {
          api.handleChange(name, v)

          if (validateOnChange) {
            if (validateEntireForm) {
              return api.validateForm(null, null, contextOnChange)
            }
            handleValidateCurrentPath(contextOnChange)
          }
        }
      },
      [name, validateOnChange, validateEntireForm, contextOnChange]
    )
    const handleOnBlur = useCallback(() => {
      api.notifyListenersOnBlurEvent(name)

      if (validateOnBlur) {
        if (validateEntireForm) {
          return api.validateForm(null, null, contextOnBlur)
        }
        handleValidateCurrentPath(contextOnBlur)
      }
    }, [name, validateOnBlur, validateEntireForm, contextOnBlur])

    const handleLSideOnChange = useMemo(() => makeOnChange(lSideValue), [
      lSideValue,
      name,
    ])
    const handleRSideOnChange = useMemo(() => makeOnChange(rSideValue), [
      rSideValue,
      name,
    ])

    return (
      <PixsyGrid>
        <Box
          isLeftBox
          flexBasis={FLEX_BASIS}
          expectedValue={lSideValue}
          currentValue={currentValue}
          name={name}
          onChange={handleLSideOnChange}
          onBlur={handleOnBlur}
          redBox={!!redBox}
          greyBox={!!greyBox}
          boxLabel={items[0].label}
          boxText={items[0].text}
          tabIndex={lTabIndex}
          disabled={!!disabled || !!readOnly}
        />
        <Box
          isLeftBox={false}
          flexBasis={FLEX_BASIS}
          expectedValue={rSideValue}
          currentValue={currentValue}
          name={name}
          onChange={handleRSideOnChange}
          onBlur={handleOnBlur}
          redBox={!!redBox}
          greyBox={!!greyBox}
          boxLabel={items[1].label}
          boxText={items[1].text}
          tabIndex={rTabIndex}
          disabled={!!disabled || !!readOnly}
        />
      </PixsyGrid>
    )
  }
)
PixsyBooleanField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  redBox: PropTypes.bool,
  greyBox: PropTypes.bool,
  fieldContext: PropTypes.object,
  readOnly: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      text: PropTypes.any,
    })
  ),
}

const Box = React.memo(
  ({
    flexBasis,
    expectedValue,
    currentValue, // field.value
    onChange,
    onBlur,
    name,
    isLeftBox,
    redBox,
    greyBox,
    boxLabel,
    boxText,
    tabIndex,
    disabled,
  }) => {
    const isEqual = expectedValue === currentValue

    return (
      <PixsyGridItem flexBasis={flexBasis}>
        <PixsyGrid direction="column">
          <PixsyGridItem fullWidth flex direction="column">
            <label
              className={cx(
                labelStyle,
                {
                  // Left Box
                  [variants.darkWhite.label]: isLeftBox && currentValue == null,
                  [variants.red.label]: isLeftBox && isEqual && redBox,
                },
                {
                  // Right Box
                  [variants.white.label]: !isLeftBox && currentValue == null,
                  [variants.grey.label]: !isLeftBox && isEqual && greyBox,
                }
              )}
            >
              <span className={cx(commonStyle)}>{boxLabel}</span>
              <input
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                type="radio"
                value={expectedValue}
                checked={isEqual}
                tabIndex={tabIndex}
                disabled={disabled}
                className={cx(
                  commonStyle,
                  inputStyle,
                  {
                    // Left Box
                    [variants.darkWhite.input]:
                      isLeftBox && currentValue == null,
                    [variants.red.input]: isLeftBox && isEqual && redBox,
                  },
                  {
                    // Right Box
                    [variants.white.input]: !isLeftBox && currentValue == null,
                    [variants.grey.input]: !isLeftBox && isEqual && greyBox,
                  }
                )}
              />
            </label>
            {boxText && (
              <span className={cx(textStyle, isEqual && textActiveStyle)}>
                {boxText}
              </span>
            )}
          </PixsyGridItem>
        </PixsyGrid>
      </PixsyGridItem>
    )
  }
)

const commonStyle = css`
  width: 100%;
  height: 34px;
  min-width: 100%;
  max-width: 100%;
  display: block;

  &:focus {
    outline: 0 !important;
  }
`
const labelStyle = css`
  color: #ffffff;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  cursor: pointer;

  > span {
    align-items: center;
    display: flex;
    font-size: 0.9em !important;
    font-weight: 400;
    justify-content: center;
    letter-spacing: 0.15em;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
  }
`

const inputStyle = css`
  top: 0;
  left: 0;
  appearance: none;
  background: #e8e8e8;
  border: none;
  margin: 0 !important;
  position: absolute;

  &:focus {
    border: 0 !important;
    outline: 1px solid #148aa9 !important;
    outline-offset: -1px !important;
    z-index: 1;
  }

  &:checked {
    background: #008aab;

    &:focus {
      border: 0 !important;
      outline: 1px solid white !important;
      outline-offset: -2px !important;
    }
  }
`
const textStyle = css`
  color: rgba(0, 0, 0, 0.1);
  padding: 7px 10px 0 10px;
  display: block;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
`
const textActiveStyle = css`
  color: #3b3b3b;
`
const whiteVariant = css`
  background: #ffffff;
  border: 1px solid #ebebeb;

  &:checked {
    background: #008aab;
  }
`
const darkWhiteVariant = css`
  background: #f7f7f7;

  &:checked {
    background: #008aab;
  }
`

const labelGrayColor = css`
  color: #505050;
`
const labelWhiteColor = css`
  color: #ffffff !important;
`

const greyVariant = css`
  &:checked {
    background: #555555;
  }
`
const redVariant = css`
  &:checked {
  }
  &:checked {
    background: #fff2f2;

    &:focus {
      border: 0 !important;
      outline: 1px solid #008aab !important;
      outline-offset: -2px !important;
    }
  }
`
const labelBlackColor = css`
  color: #000;
`
const variants = {
  white: {
    input: whiteVariant,
    label: labelGrayColor,
  },
  darkWhite: {
    input: darkWhiteVariant,
    label: labelGrayColor,
  },
  grey: {
    input: greyVariant,
    label: labelWhiteColor,
  },
  red: {
    input: redVariant,
    label: labelBlackColor,
  },
}
