import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

/**
 * A replacement component for the react-router `Prompt`.
 * Allows for more flexible dialogs.
 *
 * @example
 * <ExitPrompt when={this.props.isDirty}>
 *   {(isOpen, onConfirm, onCancel) => (
 *     <Modal show={isOpen}>
 *       <div>
 *         <p>Do you really want to leave?</p>
 *         <button onClick={onCancel}>Cancel</button>
 *         <button onClick={onConfirm}>Ok</button>
 *       </div>
 *     </Modal>
 *   )}
 * </ExitPrompt>
 */
class ExitPrompt extends React.Component {
  static UNBLOCK = null
  constructor(props) {
    super(props)
    this.state = { nextLocation: null, openModal: false }
    this.onCancel = this.onCancel.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
  }

  componentDidMount() {
    this.enable()
  }

  enable() {
    if (ExitPrompt.UNBLOCK) {
      return
    }

    /**
     * Next location is not a `tx` as per history docs
     * So rather than `tx.retry()` we recreate manually
     */
    ExitPrompt.UNBLOCK = this.props.history.block(nextLocation => {
      if(nextLocation.state && nextLocation.state.force) {        
        this.setState({
          openModal: false,
          nextLocation,
        })
        this.disable()
        this.props.history.replace(nextLocation)
      }
      if (this.props.when) {
        this.setState({
          openModal: true,
          nextLocation,
        })
      }
      return !this.props.when
    })
  }

  disable() {
    if (ExitPrompt.UNBLOCK) {
      ExitPrompt.UNBLOCK()
      ExitPrompt.UNBLOCK = null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.when && !nextProps.when) {
      this.disable()
    }
    if (!this.props.when && nextProps.when) {
      this.enable()
    }
  }

  componentWillUnmount() {
    this.disable()
  }

  onCancel() {
    this.setState({ nextLocation: null, openModal: false })
  }

  onConfirm() {
    this.disable()
    this.props.history.push(this.state.nextLocation)
  }

  render() {
    return (
      <div>
        {this.props.render({
          isOpen: this.state.openModal,
          onConfirm: this.onConfirm,
          onCancel: this.onCancel,
        })}
      </div>
    )
  }
}

ExitPrompt.propTypes = {
  when: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
}

export default withRouter(ExitPrompt)
