import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { LoadingOverlay } from 'common'
import { css } from 'react-emotion'

const debug = require('debug')('rails')
debug.enabled = false

export default class RailsPayeeFrame extends PureComponent {
  state = { loading: true }

  onLoad = f => this.setState({ loading: false })

  render() {
    return (
      <LoadingOverlay show={this.state.loading}>
        <iframe css={styledIFrame} src={this.props.url} onLoad={this.onLoad} />
      </LoadingOverlay>
    )
  }
}
RailsPayeeFrame.propTypes = {
  url: PropTypes.string.isRequired,
}

const styledIFrame = css`
  border: 0;
  margin-top: 40px;
  display: block;
  width: 100%;
  height: 950px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
`
