import React from 'react'
import PropTypes from 'prop-types'
import { Button, createContactUsHandler } from 'common'
import {
  PixsyGrid,
  PixsyGridItem,
  PixsyButtonGroup,
} from '../../containers/Cases/CaseSubmission/Components'

export default function TLDBlocked({ onTakedown, onIgnore }) {
  return (
    <PixsyGrid>
      <h1>This match is not a viable case</h1>

      <h4>
        Pixsy has detected that this domain falls outside of the{' '}
        <span data-elevio-article="17">
          jurisdictions we currently support.
        </span>
      </h4>

      <p>
        We recommend that you <b>send a Takedown Notice</b> for this match,
        requesting the image is removed by the website operator or host.
      </p>

      <p>
        Pixsy uses a set of advanced algorithms to pre-determine if a case is
        suitable for resolution. We do this to save you time, and to ensure that
        our case management team can focus their efforts on recovering
        compensation for viable cases.
      </p>
      <p>
        If you believe this domain has been blocked but the case is still
        viable, you can contact us and we can further evaluate the case for you.
      </p>

      <PixsyGridItem>
        <PixsyButtonGroup>
          <Button
            full
            onClick={createContactUsHandler('Unresolvable TLD')}
            greyTertiary
          >
            Contact us
          </Button>
          <Button full grey onClick={onIgnore}>
            Ignore Match
          </Button>
          <Button full hoverMain onClick={onTakedown}>
            Send Takedown
          </Button>
        </PixsyButtonGroup>
      </PixsyGridItem>
      {!global.PRODUCTION && <p className="debug">TLDBlocked</p>}
    </PixsyGrid>
  )
}

TLDBlocked.propTypes = {
  onTakedown: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
}
