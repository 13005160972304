export default function getClearBitLogoFallbackLetter(cluster) {
  if (cluster.entityData && cluster.entityData.company_name) {
    return cluster.entityData.company_name.charAt(0)
  }

  if (cluster.domain.name) {
    return cluster.domain.name.charAt(0)
  }

  return ''
}
