import React from 'react'
import { useMutation, gql } from '@apollo/client'
import { css } from 'react-emotion'
import { Button, SexyThumbnail } from 'common'
import ExitAndDeleteDraftButton from '../../../Table/DeleteDraft'

const MUTATION = gql`
  mutation confirmMatchImageOnPage($matchId: String!) {
    confirmMatchImageOnPage(matchId: $matchId) {
      _id
      meta
    }
  }
`

export function ImageOnPageDialog({ onConfirm, onCancel, match, caze }) {
  const [confirmMatchImageOnPage, { loading }] = useMutation(MUTATION, {
    variables: { matchId: match._id },
    onCompleted: () => {
      onConfirm()
    },
  })

  return (
    <div css={content}>
      <h1>Warning - Our system cannot currently detect the image displaying on the matching page.</h1>
      <p>
        This might be due to the image being removed or the page edited since the last time our system checked the page.
        Please carefully review the match web page{' '}
        <a href={match.origin.url} target="_blank">
          {match.origin.url}
        </a>{' '}
        and verify your image is still being displayed on the page.
      </p>
      <div css={imageThumbnailWrapper}>
        <SexyThumbnail url={match.url} loading={false} />
      </div>
      <div css={buttons}>
        <Button greyOutline domProps={{ style: { textAlign: 'center' }, target: '_blank' }} to={match.origin.url}>
          Open Match Page
        </Button>

        <Button hoverMain onClick={loading ? null : confirmMatchImageOnPage}>
          {loading ? 'Confirming...' : 'Confirm image is displayed'}
        </Button>

        <ExitAndDeleteDraftButton
          caze={caze}
          buttonProps={{ orange: true }}
          title="Image not displayed. Exit Form and Delete Draft"
          afterDelete={() => {
            window.location.replace({
              pathname: '/cases',
              state: { force: true },
            })
          }}
        />
      </div>
    </div>
  )
}

const imageThumbnailWrapper = css`
  margin: 20px 0;
  width: 50%;
`

const buttons = css`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  button {
    margin: 10px 4px 0 4px;
  }
`

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    text-align: start;
  }
`
