import React from 'react'
import { ThreeDashesIcon } from '../../../../components/Icons'
import MultiSelectRichList from '../../../../components/MultiSelectRichList'
import Collapsable from '../../../../components/Collapsable'
import useClusterFilters, { useHandleCollapsableOpen } from '../../../../utils/useClusterFilters'

const CategoriesFilter = () => {
  const {
    mappedFiltersData: { categoryItems: items },
    filters: { selectedCategoryIds: selectedIds },
    updateFilterState,
  } = useClusterFilters()

  const onChange = (ids) => updateFilterState({ selectedCategoryIds: ids })
  const onCollapsableOpen = useHandleCollapsableOpen()

  return (
    <Collapsable
      Icon={ThreeDashesIcon}
      text="Category"
      onOpen={onCollapsableOpen}
      content={
        <MultiSelectRichList 
          items={items} 
          selectedIds={selectedIds} 
          onSelectionChange={onChange} 
          enableTextSearch 
        />
      }
      badgeCount={selectedIds.length}
    />
  )
}

export default CategoriesFilter
