import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { get } from 'lodash'
import { AlignLabels, FormRow, Label, Button, PhantomLabelSpacer } from 'common'
import { css, cx } from 'react-emotion'
import { AGENCIES } from 'pixsy-constants'
import { updateUser } from '../../../redux/modules/auth'

const ConnectedForm = reduxForm({
  form: 'agencies',
  enableReinitialize: true,
  onSubmit: (values, dispatch) => {
    dispatch(updateUser(values))
  },
})(Form)

export default function AgencyForm({ user }) {
  const { photographerFields } = user
  return (
    <ConnectedForm
      initialValues={{
        photographerFields: {
          ...photographerFields,
          stockPhotoAgencies: {
            ...photographerFields.stockPhotoAgencies,
            agencies: get(
              user,
              'photographerFields.stockPhotoAgencies.agencies',
              []
            ),
          },
        },
      }}
    />
  )
}

function Form({ valid, handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit} css={agencyForm}>
      <AlignLabels align="left" width="260px">
        <FormRow>
          <Label
            title="Please select the stock photo agencies you have worked or are
          currently working with."
          />
          <div css={actionArea}>
            <ul css={agencyList}>
              {AGENCIES.map((agency, idx) => (
                <AgencyField key={idx} agency={agency} />
              ))}
            </ul>
          </div>
        </FormRow>
      </AlignLabels>

      <PhantomLabelSpacer alignContent="right">
        <div css={mobile}>
          <Button disabled={submitting} submit>
            Save
          </Button>
        </div>
      </PhantomLabelSpacer>
    </form>
  )
}

function AgencyField(agency, index) {
  return (
    <Field
      name="photographerFields.stockPhotoAgencies.agencies"
      component={AgencyCard}
      {...agency}
    />
  )
}
function AgencyCard({ input, label, agency: { title, logo } }) {
  const valueInSelectionIndex = input.value.indexOf(title)
  return (
    <li className={cx({ [selected]: valueInSelectionIndex > -1 })}>
      <label htmlFor={`agency-selection-${logo}`} title={title}>
        <div
          className={imageHolder}
          style={{ backgroundImage: `url("/img/agencies/${logo}")` }}
        >
          <input
            type="checkbox"
            {...input}
            onChange={e => {
              const idx = valueInSelectionIndex
              if (idx < 0) {
                if (e.target.checked) {
                  input.onChange([...input.value, title])
                }
              } else {
                if (!e.target.checked) {
                  const copy = [...input.value]
                  copy.splice(idx, 1)
                  input.onChange(copy)
                }
              }
            }}
            checked={valueInSelectionIndex > -1}
            onBlur={e => input.onBlur(input.value)}
            id={`agency-selection-${logo}`}
          />
        </div>
        <p>{title}</p>
      </label>
    </li>
  )
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

const imageHolder = css`
  width: 100%;
  padding-bottom: 89.99%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.25;
  box-sizing: border-box;
  border: 5px solid white;
  outline: 3px solid white;
  transition: all 250ms ease;
  &:hover {
    opacity: 1;
  }
`
const selected = css`
  .${imageHolder} {
    opacity: 1;
    outline: 3px solid #008aab;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.25);
  }
`

const agencyList = css`
  margin: -4px;
  padding-left: 0;

  li {
    margin: 2px 10px 8px 10px;
    width: calc(25% - 20px);
    display: inline-flex;

    input {
      position: absolute;
      left: -100vh;
      opacity: 1;
    }

    p {
      text-align: center;
      font-weight: 400;
      font-size: 1em;
      width: 100%;
    }

    @media (max-width: 768px) {
      width: calc(50% - 20px);
    }
  }

  label {
    font-size: 0.85em;
    color: #212121;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0;

    &:hover: {
      ${imageHolder} {
        border: 1px solid #008aab;
      }
    }
  }

  p {
    margin-top: 10px;
  }
`

const actionArea = css`
  flex: 1;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

const agencyForm = css``
