import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'
import { withCookies } from 'react-cookie'
import { ChooseBestImageSource } from 'common'
import moment from 'moment'
import { debounce } from 'lodash'

export class ImageComparison extends React.Component {
  static COOKIE_RECENT_MODE = 'pixsy-submission-recent-comparison-mode'

  constructor({ cookies }) {
    super()

    const mode = cookies.get(ImageComparison.COOKIE_RECENT_MODE) || 'compare'

    this.state = {
      mode,
      ratio: 0.5,
    }

    this.blurInputAfterChangeDebounced = debounce(() => {
      this._rangeInput && this._rangeInput.blur()
    }, 100)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.matchURL !== nextProps.matchURL) {
      this.setState({
        ratio: 0.5,
      })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.mode !== nextState.mode) {
      this.props.cookies.remove(ImageComparison.COOKIE_RECENT_MODE)

      setTimeout(() => {
        this.props.cookies.set(ImageComparison.COOKIE_RECENT_MODE, nextState.mode, {
          path: '/',
          expires: moment().add(2, 'months').toDate(),
        })
      })
    }
  }

  setRangeInputRef = (element) => {
    this._rangeInput = element
  }

  selectOriginal = () => {
    this.setState({ mode: 'original' })
  }

  selectMatch = () => {
    this.setState({ mode: 'match' })
  }

  selectCompare = () => {
    this.setState({ mode: 'compare' })
  }

  handleRatioChange = (e) => {
    this.setState({ mode: 'compare', ratio: e.target.value })
    // this.blurInputAfterChangeDebounced()
  }

  renderSingle(url) {
    return (
      <div css={singleView}>
        <img className="previewImage" src={url} />
      </div>
    )
  }

  render() {
    const { imageURL, matchURL, onlyShowSlideControl, additionalClassName, showTitlesOnSlide } = this.props
    const { mode, ratio } = this.state

    const iconImage = require(`./image.svg`)
    return (
      <div className={cx(wrap, additionalClassName)}>
        <ChooseBestImageSource candidates={[matchURL]} type="match">
          {({ url: validatedMatchURL }) => (
            <ChooseBestImageSource candidates={[imageURL]} type="image">
              {({ url: validatedImageURL }) => (
                <React.Fragment>
                  {mode === 'original' ? (
                    this.renderSingle(imageURL)
                  ) : mode === 'match' ? (
                    this.renderSingle(matchURL)
                  ) : (
                    <SideView>
                      <div style={{ width: `${ratio * 100}%` }}>
                        <img className="previewImage" src={validatedImageURL} />
                      </div>
                      <div style={{ width: `${(1 - ratio) * 100}%` }}>
                        <img className="previewImage" src={validatedMatchURL} />
                      </div>
                    </SideView>
                  )}

                  <div css={controls} className="imageComparisonControls">
                    {!onlyShowSlideControl && [
                      <div
                        key="originalButton"
                        className={cx(controlSection, {
                          [active]: mode === 'original',
                        })}
                        onClick={this.selectOriginal}
                      >
                        <button type="button">
                          <i dangerouslySetInnerHTML={{ __html: iconImage }} />
                          Original
                        </button>
                      </div>,
                      <div css={divider} key="divider1" />,
                      <div
                        key="matchButton"
                        className={cx(controlSection, {
                          [active]: mode === 'match',
                        })}
                        onClick={this.selectMatch}
                      >
                        <button type="button">
                          <i dangerouslySetInnerHTML={{ __html: iconImage }} />
                          Match
                        </button>
                      </div>,
                      <div css={divider} key="divider2" />,
                    ]}
                    <div
                      className={cx(controlSection, {
                        [active]: mode === 'compare',
                      })}
                      onClick={this.selectCompare}
                    >
                      {!onlyShowSlideControl && (
                        <button type="button">
                          <div>
                            <i dangerouslySetInnerHTML={{ __html: iconImage }} />
                            <i dangerouslySetInnerHTML={{ __html: iconImage }} />
                          </div>
                          Compare
                        </button>
                      )}
                      {showTitlesOnSlide && <span>SOURCE</span>}
                      <input
                        ref={this.setRangeInputRef}
                        css={range}
                        type="range"
                        min={0.3}
                        step={0.01}
                        value={ratio}
                        max={0.7}
                        onChange={this.handleRatioChange}
                      />
                      {showTitlesOnSlide && <span>MATCH</span>}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </ChooseBestImageSource>
          )}
        </ChooseBestImageSource>
      </div>
    )
  }
}

export default withCookies(ImageComparison)

ImageComparison.propTypes = {
  imageURL: PropTypes.string.isRequired,
  matchURL: PropTypes.string.isRequired,

  onlyShowSlideControl: PropTypes.bool,
  additionalClassName: PropTypes.string,
  showTitlesOnSlide: PropTypes.bool,
}

ImageComparison.defaultProps = {
  onlyShowSlideControl: false,
  showTitlesOnSlide: false,
}

const wrap = css`
  @media (min-width: 1200px) {
    /*max-width: 650px;*/
    margin: 0 auto;
  }
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 30px;
  h1 {
    font-size: 1.5em;
    margin: 0 50px 0 0;
    padding-left: 30px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    text-align: left;
    height: 50px;
  }
`
const singleView = css`
  flex: 1;
  overflow: hidden;
  padding: 15px 0;

  @media (max-width: 1000px) {
    /*height: 350px;*/
    min-height: auto;
  }
  > * {
    width: 100%;
  }

  .previewImage {
    margin: 0 auto;
    max-height: 100%;
    width: auto;
    display: block;
    user-select: none;
    max-height: 100.2%;
    max-width: 100.2%;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);*/
  }
`
const SideView = styled.div`
  flex: 1;
  @media (max-width: 1000px) {
    /*height: 350px;*/
    min-height: auto;
  }
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  > div {
    height: 100%;
    margin: 10px;
    overflow: hidden;
    /*max-height: 100%;*/
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .previewImage {
    margin: 0 auto;
    width: 100%;
    display: block;
    user-select: none;
    border: solid 1px rgba(100, 100, 100, 0.1);
`

const range = css`
  appearance: none;
  max-width: 350px;
  height: 2px;
  outline: none;
  background-color: #aaa;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-left: 10px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 22px;
    background: #000;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 22px;
    background: #000;
    cursor: pointer;
  }
`

const controls = css`
  height: 50px;
  margin: 20px 0;
  font-size: 0.75em;
  color: #000;
  font-weight: 300;
  display: flex;
  align-items: stretch;
  justify-content: center;

  button {
    height: 100%;
    display: flex;
    appearance: none;
    background: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`
const controlSection = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.8;
  letter-spacing: 0.25em;
  svg {
    height: 14px;
  }
  &:hover {
    opacity: 1;
  }
`

const active = css`
  svg {
    opacity: 1;
  }
  path {
    fill: #008aab;
  }
  font-weight: 400;
`

const divider = css`
  width: 0;
  margin-left: 10px;
  margin-right: 10px;
  border-left: 1px solid #f0f0f0;
`
