import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

const StyledDiv = styled.div`
  margin-left: ${props => (props.fullscreen ? 0 : '110px')};
  margin-top: ${props => (props.fullscreen ? 0 : '30px')};
  position: relative;
  height: 100vh;

  /* router... */
  > div:not(.empty-state) {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
  }

  @media print {
    padding-top: 0;
  }
`
export default function ContentCanvas({ children, fullscreen }) {
  return <StyledDiv fullscreen={fullscreen}>{children}</StyledDiv>
}

ContentCanvas.defaultProps = {
  fullscreen: false,
}

ContentCanvas.propTypes = {
  fullscreen: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
