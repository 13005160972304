import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import styled from 'react-emotion'

const ActionArea = styled.div`
  flex: 1;
  input {
    height: 40px;
    width: 100%;
    padding: 0 12px;
    border: 1px solid #c7c7c7;
    font-size: 18px;
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }

    @media (max-width: 768px) {
      border-radius: 0;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
    }
  }
`

export default function TextFieldPlain(props) {
  const { meta = {}, label } = props
  const { maxLength, disabled, readOnly, ...rest } = props

  const innerProps = {
    ...props.input,
    ...(maxLength && { maxLength }),
    ...(disabled && { disabled }),
    ...(readOnly && { readOnly }),
  }

  return (
    <FormRow {...rest}>
      <Label title={label} align={props.labelAlign} />
      <ActionArea>
        <input {...innerProps} />
        <Feedback meta={meta} />
      </ActionArea>
    </FormRow>
  )
}

TextFieldPlain.propTypes = {
  label: PropTypes.string.isRequired,
  labelAlign: PropTypes.string,
}

TextFieldPlain.defaultProps = {
  labelAlign: 'right',
}
