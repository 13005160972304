import * as yup from 'yup'
import {
  QString,
  QDate,
  QObjectID,
  QSchema,
  QArray,
  prefixConstEnumValues,
  prefixConstEnumKeys,
  objectWithKeysAsValues,
} from '../core'

import {
  LicensingSchema,
  LICENSING_NAMES,
  LICENSING_LABEL,
  LICENSING_REQUIRED,
} from './LicensingSchema'
import {
  RegistrationSchema,
  REGISTRATION_NAMES,
  REGISTRATION_LABEL,
  REGISTRATION_REQUIRED,
} from './RegistrationSchema'
import { FileSchema } from '../file/FileSchema'

export const IMAGE_KEYS = objectWithKeysAsValues({
  uuid: 'uuid',
  file: 'file',
  __US_REGISTRATION_FILES__: '__US_REGISTRATION_FILES__',
  __LICENSING_HISTORY_FILES__: '__LICENSING_HISTORY_FILES__',
  _id: '_id',
  url: 'url',
  thumbnail: 'thumbnail',
  title: 'title',
  date_taken: 'date_taken',
  place_taken: 'place_taken',
  licensing: 'licensing',
  registration: 'registration',
  registrations: 'registrations',
  case: 'case',
})

export const IMAGE_NAMES = {
  ...IMAGE_KEYS,
  [IMAGE_KEYS.licensing]: prefixConstEnumValues(
    IMAGE_KEYS.licensing,
    LICENSING_NAMES
  ),
  [IMAGE_KEYS.registration]: prefixConstEnumValues(
    IMAGE_KEYS.registration,
    REGISTRATION_NAMES
  ),
}

export const IMAGE_LABEL = {
  [IMAGE_NAMES.title]: `What is the title of the image?`,
  [IMAGE_NAMES.date_taken]: `When and where was the image created?`, //`When was the image created?`,
  [IMAGE_NAMES.place_taken]: `Where was the image created?`,
  [IMAGE_NAMES.__US_REGISTRATION_FILES__]: `Please upload your US Copyright Office certificate`,
  [IMAGE_NAMES.__LICENSING_HISTORY_FILES__]: `Please upload some invoices/receipts to support your license history`,
  ...prefixConstEnumKeys(IMAGE_KEYS.licensing, LICENSING_LABEL),
  ...prefixConstEnumKeys(IMAGE_KEYS.registration, REGISTRATION_LABEL),
}

export const IMAGE_REQUIRED = {
  [IMAGE_NAMES.title]: true,
  [IMAGE_NAMES.url]: true,
  [IMAGE_NAMES.date_taken]: true,
  [IMAGE_NAMES.place_taken]: true,
  [IMAGE_NAMES.__US_REGISTRATION_FILES__]: false,
  [IMAGE_NAMES.__LICENSING_HISTORY_FILES__]: false,
  ...prefixConstEnumKeys(IMAGE_KEYS.licensing, LICENSING_REQUIRED),
  ...prefixConstEnumKeys(IMAGE_KEYS.registration, REGISTRATION_REQUIRED),
}

export const IMAGE_INFO = {
  [IMAGE_KEYS.title]: {
    title: 'You must provide a title for your image.',
  },
  [IMAGE_KEYS.date_taken]: {
    title: 'You must provide the date the image was created.',
  },
  [IMAGE_KEYS.place_taken]: {
    title: 'You must provide the place you created this image.',
  },
}

// prettier-ignore
export const IMAGE_TYPES = {
  [IMAGE_KEYS.uuid]: QString.strip(true),
  [IMAGE_KEYS.file]: FileSchema({ strip: true })
    .strip(true)
    .notRequired(),
  [IMAGE_KEYS._id]: QObjectID,
  [IMAGE_KEYS.url]: QString.url().required(),
  [IMAGE_KEYS.thumbnail]: QString,
  [IMAGE_KEYS.title]: QString.required(IMAGE_INFO[IMAGE_KEYS.title]).test(
    'is-not-untitled',
    {
      title:
        'Please provide the title of your image.',
      alwaysShow: true,
    },
    function(value) {
      return String(value || '').toLowerCase().trim() !== 'untitled image'
    }
  ),


  [IMAGE_KEYS.date_taken]: QDate.required(IMAGE_INFO[IMAGE_KEYS.date_taken]),
  [IMAGE_KEYS.place_taken]: QString.required(IMAGE_INFO[IMAGE_KEYS.place_taken]),
  [IMAGE_KEYS.__US_REGISTRATION_FILES__]: QArray
    .of(FileSchema({ strip: true }))
    .compact(v => !v.uploadComplete || !v.url),
  [IMAGE_KEYS.__LICENSING_HISTORY_FILES__]: QArray
    .of(FileSchema({ strip: true }))
    .compact(v => !v.uploadComplete || !v.url),
  [IMAGE_KEYS.licensing]: LicensingSchema,
  [IMAGE_KEYS.registration]: RegistrationSchema,
  [IMAGE_KEYS.case]: QObjectID,
}

export const ImageSchema = QSchema.shape(IMAGE_TYPES)
