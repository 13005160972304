import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'
import FormRow from './FormRow'
import Feedback from './Feedback'
import Label from './Label'

export default function ClosedQuestionRadio(props) {
  let {
    input,
    label,
    iteration,
    meta,
    addon,
    locked,
    items,
    showNoAnswer,
    subLabel,
  } = props

  if (input.value === '') {
    input.value = null
  }
  const defaultItems = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ]
  items = items || defaultItems

  return (
    <FormRow {...props}>
      <div className={stacked}>
        <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
          <div className={labelContainer}>
            <Label title={label} align="left" />
            {subLabel && <p css={subLabelCss}>{subLabel}</p>}
          </div>

          {locked ? (
            <p css={lockedValue}>
              {input.value === true
                ? 'Yes'
                : input.value === false
                ? 'No'
                : showNoAnswer
                ? 'No answer'
                : '-'}
            </p>
          ) : (
            <ActionArea items={props.items}>
              {items.map((item, index) => (
                <div
                  key={item.value}
                  className={hitbox}
                  style={item.width ? { flexBasis: item.width } : {}}
                >
                  <input
                    onChange={e => input.onChange(e) && input.onBlur(e)}
                    checked={input.value === item.value}
                    name={input.name}
                    type="radio"
                    id={`${input.name}-${iteration}-${index}`}
                    value={item.value}
                  />
                  <label htmlFor={`${input.name}-${iteration}-${index}`}>
                    {item.label}
                  </label>
                </div>
              ))}
            </ActionArea>
          )}
          {addon && addon({ name: input.name })}
        </div>
        <Feedback meta={meta} />
      </div>
    </FormRow>
  )
}

const stylesUnlocked = css``

const stylesLocked = css`
  opacity: 0.5;
`

const stacked = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const lockedValue = css`
  font-size: 1.14em;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;

  flex-basis: 100px;
  text-align: center;
`

const labelContainer = css`
  flex-direction: column;
`

const subLabelCss = css`
  font-size: 1em;
  color: #999999;
`

const wrap = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;

  label {
    font-weight: 300;
    font-size: 1.42em;
    display: block;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ActionArea = styled.div`
  flex: ${props =>
    props.items
      ? `0 1 ${props.items.reduce(
          (sum, item) => (sum += parseInt(item.width) || 0),
          0
        )}px`
      : '0 0 140px'};
  text-align: center;
  margin: 0px 0px 0 15px;
  display: flex;

  @media (max-width: 768px) {
    flex: 0;
    margin: 2px;
  }

  label {
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    user-select: none;
    color: #a0a0a0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.85em;
  }

  label,
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    appearance: none;
    margin: 0;
    outline: 1px solid #d9d9d9;
    font-weight: 400;
    font-size: 0.9em !important;

    &:focus {
      outline: inherit;
      outline-offset: inherit;
      border: inherit;
    }

    &:checked {
      background: #008aab;
      + label {
        outline: 1px solid #008aab;
        color: #fff;
        font-weight: 600;
        font-size: 0.9em !important;
      }
    }
  }

  input[type='radio'] {
    border: none;
  }
`

const hitbox = css`
  position: relative;
  display: inline-block;
  flex-basis: 50%;
  height: 30px;

  &:nth-of-type(2) {
    transform: translateX(1px);
  }
`

ClosedQuestionRadio.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
    })
  ),
  iteration: PropTypes.number,
}

ClosedQuestionRadio.defaultProps = {
  iteration: 0,
}
