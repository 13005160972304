import { ContentSection, SexySeparator } from 'common'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'
import ChangeEmailForm from './ChangeEmailForm'
import ChangePasswordForm from './ChangePasswordForm'
import DeleteAccountForm from './DeleteAccountForm'
import NotificationSettings from './NotificationSettings'
import RuleSettings from './RuleSettings'
import ReferralCode from './ReferralCode'
import MatchPreferences from './MatchPreferences'
// import ImagesExportForm from './ImagesExportForm'

class SettingsContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.emailRef = React.createRef()
  }

  componentDidMount() {
    this.props.location.hash === '#change-email' &&
      this.emailRef.current &&
      this.scrollToElement(this.emailRef.current)
  }

  scrollToElement(ref) {
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    ref.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  render() {
    const { user, code } = this.props
    // const ENABLE_COPYRIGHT_REGISTRATION =
    //   !global.PRODUCTION ||
    //   (user.features && user.features.copyrightRegistration)

    const MATCH_PREFERENCES_ENABLED = user.features && user.features.graph

    return (
      <React.Fragment>
        {MATCH_PREFERENCES_ENABLED && (
          <ContentSection>
            <MatchPreferences />
          </ContentSection>
        )}
        <ContentSection>
          <ReferralCode code={code} />
        </ContentSection>
        <ContentSection>
          <RuleSettings />
        </ContentSection>
        {/** ENABLE_COPYRIGHT_REGISTRATION && (
          <ContentSection>
            <ImagesExportForm user={user} />
          </ContentSection>
        ) */}
        <ContentSection>
          <NotificationSettings user={user} />
        </ContentSection>
        <ContentSection>
          <ChangePasswordForm />
          <SexySeparator space={40} />
          <div ref={this.emailRef}>
            <ChangeEmailForm id="change-email" />
          </div>
          <SexySeparator space={40} />
          <DeleteAccountForm />
        </ContentSection>
      </React.Fragment>
    )
  }
}

SettingsContainer.propTypes = {
  user: PropTypes.object.isRequired,
}

export default withRouter(SettingsContainer)
