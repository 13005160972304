import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { Elements } from 'react-stripe-elements'
import { AlignLabels, Button } from 'common'
import { Glyphicon, FormGroup, InputGroup } from 'react-bootstrap'
import { handleStripePayment } from '../../../../../redux/modules/registrations'

class LastCCForm extends Component {
  constructor(props) {
    super(props)
  }

  handleSubmit = async values => {
    const { registration, dispatch } = this.props
    const coupon =
      (typeof registration.couponUsed === 'string' &&
        registration.couponUsed.length > 0 &&
        registration.couponUsed) ||
      undefined
    return dispatch(
      handleStripePayment(registration._id, {
        registrationId: registration.registration__c,
        coupon,
      })
    )
  }

  render() {
    const { handleSubmit, submitting, user } = this.props
    return (
      <form css={wrap} onSubmit={handleSubmit(this.handleSubmit)}>
        <AlignLabels align="right" width="190px">
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>
                <Glyphicon glyph="credit-card" />
              </InputGroup.Addon>
              <input
                css={ccInput}
                type="text"
                placeholder="Card Number"
                value={`**** **** **** ${user.stripe.last4}`}
                disabled
              />
            </InputGroup>
          </FormGroup>

          <div css={buttonAndStripe}>
            <Button submit disabled={submitting}>
              Submit Secure Payment
            </Button>
            <a href="https://stripe.com" target="_blank">
              <div css={stripeWrap}>
                <img src="/img/powered_by_stripe.svg" />
              </div>
            </a>
          </div>
        </AlignLabels>
      </form>
    )
  }
}

const ConnectedStripeLastCCForm = reduxForm({
  form: 'registrationPaymentForm',
  onSubmit: (values, dispatch, props) =>
    dispatch(values._id, handleStripePayment(values)).then(
      () => props.onSuccess && props.onSuccess()
    ),
})(connect(null, {})(LastCCForm))

export default function StripeLastCCForm(props) {
  return (
    <Elements>
      <ConnectedStripeLastCCForm {...props} />
    </Elements>
  )
}

const ccInput = css`
  width: 100%;
  text-align: center;
  letter-spacing: 12px;
`

const wrap = css`
  position: relative;
  & > div[class*='-Wrap'],
  & > div[class*='-half'] {
    margin-top: 25px;
  }
`

const buttonAndStripe = css`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
`

const stripeWrap = css`
  margin-top: 10px;
`

// const lockedValue = css`
//   font-size: 1.14em;
//   font-weight: 500;
//   color: #000;
//   margin-bottom: 0;
// `
