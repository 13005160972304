import React, { useCallback, useEffect, useState } from 'react'
import { Divider, FormControl, FormHelperText, Grid, Input, makeStyles, Button } from '@material-ui/core'
import { Add, Delete, AddBox } from '@material-ui/icons'
import { css } from 'react-emotion'

import { useMutation } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'

import saveUserDetailsMutation from '../../../../graphql/queries/saveUserDetails.mutation'
import Content from './content'
import Checkbox from './Checkbox'
import EditableCheckbox from './EditableCheckbox'

const PLACEHOLDERS = {
  twitter: 'twitter.com/ProfileName',
  facebook: 'facebook.com/ProfileName',
  linkedin: 'linkedin.com/in/ProfileName',
  instagram: 'instagram.com/ProfileName',
  pinterest: 'pinterest.com/ProfileName',
  websites: 'www.yourwebsite.com',
}

const LABELS = {
  twitter: 'Twitter',
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
  instagram: 'Instagram',
  pinterest: 'Pinterest',
}

const useStyles = makeStyles({
  input: {
    fontFamily: '"canada-type-gibson", Helvetica, Arial, sans-serif',
    fontSize: '1.5rem',
    '&::placeholder': {
      color: 'hsl(0, 0%, 50%) !important',
    },
  },
  error: {
    marginTop: 0,
    fontFamily: '"canada-type-gibson", Helvetica, Arial, sans-serif',
    fontSize: '1.2rem',
  },
})

const useUserContent = (hiddenUserContent, ALL_DETAILS) => {
  const [hidden, setHidden] = useState(hiddenUserContent)
  const [values, setValues] = useState(ALL_DETAILS)

  const onChangeWebsite = useCallback(
    (index, value) => {
      setValues((state) => {
        const sites = [...state.websites]
        const oldValue = sites[index]
        const hiddenWebsiteIncludes = (hidden.websites || []).includes(oldValue)
        if (hiddenWebsiteIncludes) {
          let newHidden
          if (value.length === 0) {
            newHidden = hidden.websites.filter((w) => w !== oldValue)
          } else {
            newHidden = hidden.websites.map((w) => (w === oldValue ? value : w))
          }
          setHidden({ ...hidden, websites: newHidden })
        }
        sites[index] = value
        if (sites[index].length === 0) {
          delete sites[index]
        }

        return { ...state, websites: sites.filter(Boolean) }
      })
    },
    [hidden, setHidden, setValues, values]
  )

  const onChange = useCallback(
    (key, value) => {
      const updatedHidden = { ...hidden }
      if (key === 'websites') {
        if (!updatedHidden.websites) updatedHidden.websites = []
        updatedHidden.websites = updatedHidden.websites.filter((w) => values.websites.includes(w))
        if (updatedHidden.websites.includes(value)) {
          updatedHidden.websites = updatedHidden.websites.filter((c) => c.toLowerCase() !== value.toLowerCase())
        } else {
          updatedHidden.websites = [...updatedHidden.websites, value && value.toLowerCase()].filter(Boolean)
        }
      } else {
        if (updatedHidden[key] === value) delete updatedHidden[key]
        else updatedHidden[key] = value && value.toLowerCase()
      }
      setHidden(updatedHidden)
    },
    [hidden, values]
  )

  const setLocalValue = useCallback(
    (key, e) => {
      const value = e.target.value.length > 0 ? e.target.value.toLowerCase() : undefined
      setValues(Object.assign({}, values, { [key]: value }))
      if (hidden[key]) onChange(key, value)
    },
    [setValues, values, onChange]
  )

  return {
    hidden,
    values,
    onChange,
    setLocalValue,
    onChangeWebsite,
  }
}

const UserContentComponent = ({ userPhotographerFields = {}, hiddenUserContent = {}, updateMatchPreference }) => {
  const ALL_DETAILS = {
    twitter: userPhotographerFields.twitter || '',
    facebook: userPhotographerFields.facebook || '',
    linkedin: userPhotographerFields.linkedin || '',
    instagram: userPhotographerFields.instagram || '',
    pinterest: userPhotographerFields.pinterest || '',
    websites: userPhotographerFields.websites || '',
    hostImages: userPhotographerFields.hostImages || [],
  }

  const { hidden, values, onChange, setLocalValue, onChangeWebsite } = useUserContent(hiddenUserContent, ALL_DETAILS)

  const [validationErrors, setValidationErrors] = useState({
    websites: {},
  })

  const objSort = (o) => {
    const obj = cloneDeep(o) || {}
    return Object.keys(obj)
      .sort()
      .reduce((prev, next) => {
        if (next === 'websites') {
          prev[next] = obj[next].sort()
        } else {
          prev[next] = obj[next]
        }
        return prev
      }, {})
  }

  const isSame = useCallback((obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2), [])

  const shouldSave =
    !isSame(objSort(hidden), objSort(hiddenUserContent)) || !isSame(objSort(values), objSort(ALL_DETAILS))

  const [saveUser] = useMutation(saveUserDetailsMutation)
  const saveMatchPreference = useCallback(() => {
    let hasError = false
    const websites = values.websites || []
    const errors = { websites: {} }

    websites.forEach((site) => {
      try {
        new URL(site).origin
      } catch (err) {
        hasError = true
        errors.websites[site] = 'URL must start with http:// or https://'
      }
    })

    if (hasError) {
      setValidationErrors(errors)
      return false
    }

    return saveUser({ variables: { values } }).then(() => {
      return updateMatchPreference({ variables: { userContent: hidden } })
    })
  }, [hidden, updateMatchPreference, values, saveUser, validationErrors, setValidationErrors])

  const profileKeys = Object.entries(ALL_DETAILS)
    .filter(([key, value]) => key !== 'websites' && !!value)
    .map(([key]) => key)
  const hiddenProfileKeys = Object.entries(hidden)
    .filter(([key, value]) => key !== 'websites' && !!value)
    .map(([key]) => key)
  const profileValuesExists = profileKeys.length > 0
  const websiteValuesExists = ALL_DETAILS.websites.length > 0

  const allProfileSelected = hiddenProfileKeys.length === 0
  const allProfilesDeSelected = hiddenProfileKeys.length === profileKeys.length

  const allWebsitesSelected = hidden.websites && hidden.websites.length === 0
  const allWebsitesDeselected =
    hidden.websites && ALL_DETAILS.websites && (hidden.websites.length === ALL_DETAILS.websites.length) === 0

  return (
    <Content defaultExpanded title="Your Content" showSaveButton={shouldSave} onSave={saveMatchPreference}>
      <Grid container>
        <Grid md={6} xs={12}>
          {(profileValuesExists && (
            <Checkbox
              large
              title="Your Profiles"
              checked={allProfileSelected}
              indeterminate={!allProfileSelected && !allProfilesDeSelected}
            />
          )) || <div style={{ fontSize: '1.5rem' }}>Your Profiles</div>}
          <div style={{ marginLeft: 20, marginTop: 10 }}>
            {Object.keys(ALL_DETAILS).map(
              (key) =>
                key !== 'websites' &&
                key !== 'hostImages' && (
                  <EditableCheckbox
                    key={key}
                    value={values[key]}
                    label={LABELS[key]}
                    checked={typeof hidden[key] === 'undefined'}
                    placeholder={PLACEHOLDERS[key]}
                    onTextChange={setLocalValue.bind(this, key)}
                    onChange={onChange.bind(this, key, values[key])}
                  />
                )
            )}
          </div>
        </Grid>
        <Grid md={6} xs={12}>
          {(websiteValuesExists && (
            <Checkbox
              large
              title="Your Websites"
              checked={allWebsitesSelected}
              indeterminate={!allWebsitesSelected && !allWebsitesDeselected}
            />
          )) || <div style={{ fontSize: '1.5rem' }}>Your Websites</div>}
          <div style={{ marginLeft: 20, marginTop: 10 }}>
            <Websites
              values={values.websites}
              onChangeText={onChangeWebsite}
              onChange={onChange}
              hiddenWebsites={hidden.websites || []}
              validationErrors={validationErrors.websites}
            />
          </div>
        </Grid>
        <Grid xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Divider />
        </Grid>
        <Grid container alignItems="center">
          <Grid md={6} xs={12} alignContent="center" alignItems="center" justify="center">
            <div style={{ fontSize: '1.8rem' }}>Your Image Host</div>
            <div style={{ margin: 10 }}>
              <HostImages values={values.hostImages} allValues={values} onSave={saveMatchPreference} />
            </div>
          </Grid>
          <Grid md={6} xs={12} style={{ paddingLeft: 15 }} alignContent="center" alignItems="center" justify="center">
            <div style={{ fontSize: 14, color: '#808080', fontStyle: 'italic' }}>
              You can hide match results where the image is hosted on your own server or gallery. This will hide any
              match where the image file url matches the server/gallery you provide (regardless of the match display
              page) - this is great for hiding ‘hot-linked matches’.
              <br />
              Caution: only insert the URL path that relates to your image hosting location e.g.:{' '}
              <a href="#">images.mywebsite.com</a>&nbsp; or <a href="#">mywebsite.com/images/</a>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Content>
  )
}

const Websites = ({ values = [], onChangeText, onChange, hiddenWebsites, validationErrors }) => {
  return (
    <Grid item>
      <EditableWebsite
        idx={0}
        value={values[0]}
        checked={!hiddenWebsites.includes(values[0])}
        onChange={onChange.bind(this, 'websites', values[0])}
        onChangeText={onChangeText}
        error={validationErrors[values[0]]}
        placeholder="https://www.yourwebsite.com"
      />
      <EditableWebsite
        idx={1}
        value={values[1]}
        checked={!hiddenWebsites.includes(values[1])}
        onChange={onChange.bind(this, 'websites', values[1])}
        onChangeText={onChangeText}
        error={validationErrors[values[1]]}
        placeholder="https://www.yourgallery.com"
      />
      <EditableWebsite
        idx={2}
        value={values[2]}
        checked={!hiddenWebsites.includes(values[2])}
        onChange={onChange.bind(this, 'websites', values[2])}
        onChangeText={onChangeText}
        error={validationErrors[values[2]]}
        placeholder="https://www.youragent.com"
      />
      <EditableWebsite
        idx={3}
        value={values[3]}
        checked={!hiddenWebsites.includes(values[3])}
        onChange={onChange.bind(this, 'websites', values[3])}
        onChangeText={onChangeText}
        error={validationErrors[values[3]]}
        placeholder="https://www.flickr.com/photos/username"
      />
      <EditableWebsite
        idx={4}
        value={values[4]}
        checked={!hiddenWebsites.includes(values[4])}
        onChange={onChange.bind(this, 'websites', values[4])}
        onChangeText={onChangeText}
        error={validationErrors[values[4]]}
        placeholder="https://www.otherprofile.com"
      />
    </Grid>
  )
}

const HostImages = ({ values = [], allValues = [], onSave }) => {
  const { hostImages } = allValues
  const classes = useStyles()
  const [text, setText] = useState('')
  const [hasError, setError] = useState(false)
  const handleChange = (e) => {
    const value = e.target.value || ''
    if (value.includes('://')) {
      setError(true)
    } else if (value.startsWith('www.')) {
      setError(true)
    } else {
      setError(false)
    }
    setText(e.target.value)
  }

  const handleDelete = (idx) => {
    let temp = [...hostImages]
    temp.splice(idx, 1)
    allValues.hostImages = temp
    onSave()
  }

  const handleSave = () => {
    let temp = [...hostImages]
    temp.push(text)
    allValues.hostImages = temp
    setText('')
    onSave()
  }
  return (
    <Grid container>
      {hostImages.map((value, idx) => (
        <Grid key={idx} item xs={12}>
          <div className={grid} style={{ paddingLeft: '1rem' }}>
            <span>{value}</span>
            <Button variant="text" style={{ color: 'red', letterSpacing: 1.5 }} onClick={() => handleDelete(idx)}>
              DELETE
            </Button>
          </div>
        </Grid>
      ))}
      {hostImages.length < 10 && (
        <Grid xs={12} alignContent="space-around" className={hostContentInput}>
          <div className={grid}>
            <FormControl fullWidth error={hasError}>
              <Input
                disableUnderline
                value={text}
                placeholder="Enter Host Image URL"
                onChange={handleChange}
                classes={{ input: classes.input }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handleSave()
                  }
                }}
              />
              {hasError && (
                <FormHelperText classes={{ error: classes.error }}>
                  Incorrect host url. Please enter a valid url.
                </FormHelperText>
              )}
            </FormControl>
            {text && !hasError && <AddBox color="action" fontSize="large" onClick={handleSave} />}
          </div>
        </Grid>
      )}
    </Grid>
  )
}

const EditableWebsite = React.memo(({ value, onChangeText, onChange, checked, idx, error, placeholder }) => {
  return (
    <React.Fragment>
      <EditableCheckbox
        onlyTextbox
        value={value || ''}
        onChange={onChange}
        onTextChange={(e) => onChangeText(idx, e.target.value)}
        placeholder={placeholder}
        checked={checked}
        error={error}
      />
    </React.Fragment>
  )
})

export default React.memo(UserContentComponent)

const grid = css`
  display: grid;
  grid-template-columns: 12fr 1fr;
  font-size: 1.5rem;
  align-items: center;
`
const hostContentInput = css`
  font-size: 1.5rem;
  padding: 0.7rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
`
