import React, { PureComponent } from 'react'
import { css } from 'react-emotion'
import PropTypes from 'prop-types'
import { withCookies } from 'react-cookie'
import moment from 'moment'
import { Button } from 'common'

class MobileWarningScreen extends PureComponent {
  static COOKIE_KEY = 'pixsy-mobile-warning-seen'
  static BREAKPOINT = 768

  constructor(props) {
    super()
    this.state = { showMobileWarning: this.shouldShowWarning(props) }
  }

  shouldShowWarning(props) {
    const w = window.screen.availWidth

    if (w >= MobileWarningScreen.BREAKPOINT) return false

    const seenRecently = props.cookies.get(MobileWarningScreen.COOKIE_KEY)

    if (seenRecently) return false

    return true
  }

  handleWarningDismiss = () => {
    this.setState({ showMobileWarning: false }, () => {
      this.props.cookies.set(MobileWarningScreen.COOKIE_KEY, 1, {
        path: '/',
        expires: moment()
          .add(2, 'hour')
          .toDate(),
      })
    })
  }

  render() {
    if (!this.state.showMobileWarning) return null

    return (
      <div css={styles} role="dialog">
        <div>
          <h3>Optimized for desktop</h3>
          <p>
            For the best user experience log in to Pixsy from your computer.
          </p>
          <Button hoverMain onClick={this.handleWarningDismiss}>
            Proceed
          </Button>
        </div>
      </div>
    )
  }
}

export default withCookies(MobileWarningScreen)

const styles = css`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 111;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  div {
    width: 90vw;
    max-width: 400px;
    height: auto;
    overflow: hidden;
    background-color: white;
    border-top: solid 5px #008aab;
    box-shadow: 0 0 20px rgba(30, 30, 30, 0.3);
    padding: 20px 30px;
  }
`

MobileWarningScreen.propTypes = {
  cookies: PropTypes.object.isRequired,
}
