import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { css } from 'react-emotion'
import { Button, CountrySelectStandalone } from 'common'
import { updateUser } from '../../../redux/modules/auth'
import { get } from 'lodash'

export function CountryQuestionaire({ valid, submitting, handleSubmit }) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>One quick question</h1>

      <span>
        Choose your country of residence to help us improve our service.
      </span>

      <div css={action}>
        <Field
          name="details.country"
          label="Your Country"
          component={CountrySelectStandalone}
        />

        <Button submit disabled={!valid || submitting}>
          Save
        </Button>
      </div>
    </form>
  )
}

CountryQuestionaire.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const CountryQuestionaireWrapper = reduxForm({
  form: 'CountryQuestionaire',
  validate: values => {
    return {
      details: {
        ...(undefined === get(values, 'details.country') && {
          country: 'Choice required',
        }),
      },
    }
  },
  onSubmit: (values, dispatch, { onComplete }) =>
    dispatch(updateUser(values)).then(onComplete),
})(CountryQuestionaire)

CountryQuestionaireWrapper.eligible = user => !user.details.country

export default CountryQuestionaireWrapper

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const action = css`
  width: 250px;
  margin-top: 40px;
  margin-bottom: 30px;

  button {
    margin-top: 10px;
    width: 100%;
  }
`
