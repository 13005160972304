import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { FacebookShareButton, FacebookIcon } from 'react-share'

// TODO apparently sharing facebook pages stop facebook from adding the quote to the post
const PIXSY_FB = 'https://www.facebook.com/PixsyHQ'

const Button = props => (
  <FacebookShareButton url={PIXSY_FB} css={style} {...props}>
    <FacebookIcon size={32} />
    Share with friends
  </FacebookShareButton>
)

Button.propTypes = {
  quote: PropTypes.string,
  hashtag: PropTypes.string,
}

const style = css`
  width: 100%;
  display: flex;
  background-color: #3b5998;
  color: white;
  align-items: center;
  justify-content: center;
`

export default React.memo(Button)
