import {
  IMAGE_INDEX,
  MATCH_INDEX,
  SUBMISSION_LABEL as LABELS,
  SUBMISSION_REQUIRED as REQUIRED,
} from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import {
  PixsyBooleanField,
  PixsyFormGroup,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
} from '../../../Components'
import { TooltipContainer } from 'common'

export const Question = React.memo(
  ({
    name,
    disabled,
    errors,
    greyBox,
    padding,
    validateOnChange = true,
    validateOnBlur,
    fieldContextOnBlur,
    fieldContextOnChange,
    index,
    readOnly,
  }) => {
    const nameWithIndex = name
      .replace(IMAGE_INDEX, index)
      .replace(MATCH_INDEX, index)
    const hasError = errors[name]

    const tooltip = <TooltipContainer id={name} />

    return (
      <PixsyPaper
        padding={padding}
        disabled={disabled[name]}
        borderless
        variant="transparent"
      >
        <PixsyFormGroup tooltip={tooltip}>
          <PixsyGridItem xs={9}>
            <PixsyLabel text={LABELS[name]} required={REQUIRED[name]} />
          </PixsyGridItem>
          <PixsyGridItem xs={3}>
            <PixsyBooleanField
              name={nameWithIndex}
              redBox={!!hasError}
              disabled={disabled[name]}
              greyBox={greyBox}
              validateOnChange={validateOnChange}
              validateOnBlur={validateOnBlur}
              fieldContextOnBlur={fieldContextOnBlur}
              fieldContextOnChange={fieldContextOnChange}
              validateEntireForm
              readOnly={readOnly}
            />
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }
)
