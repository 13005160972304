import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Button } from 'common'
import { css } from 'react-emotion'
import { remove } from '../../../redux/modules/images'

export function DeleteImageDialog({ submitting, remove }) {
  return (
    <div css={content}>
      <h1>Are you sure?</h1>

      <p>This will remove the Image and all it’s Matches from your account.</p>

      <p>
        <b>Important</b>: Images and Matches that are related to submitted
        Takedowns or Cases will never be removed.
      </p>

      <Button danger onClick={remove} disabled={submitting}>
        Delete
      </Button>
    </div>
  )
}

export default withRouter(
  connect(
    state => ({ submitting: state.images.loading }),
    { remove },
    (
      stateProps,
      { remove },
      { image, onCancel, history, location, overrideLocation }
    ) => ({
      ...stateProps,
      remove: () => {
        remove(image._id).then(response => {
          const status = response.error && response.error.status

          if (status > 200) {
            onCancel(true)
          } else {
            const destination =
              overrideLocation ||
              (location.state && location.state.imageContext
                ? location.state.imageContext
                : '/images')

            history.replace(destination)

            if (overrideLocation) {
              history.go()
            }
          }
        }, onCancel)
      },
    })
  )(DeleteImageDialog)
)

DeleteImageDialog.propTypes = {
  remove: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 400px;
  margin: 0 auto;

  p {
    text-align: center;
  }
`
