import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { ChooseBestImageSource, SexyThumbnail, SelectableItem, SelectableContext } from 'common'
import moment from 'moment'
import getBestImageBasedOnTag from '../../../../../helpers/getBestImageBasedOnTag'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

class ViewCardGrid extends React.Component {
  render() {
    const { cluster, location, tags, allCookies } = this.props

    const { matches, images, domain, created } = cluster
    const [, image] = getBestImageBasedOnTag(tags, matches, images)
    const isClusterIgnored = cluster.tags.includes('hidden:true')
    const isClusterApproved = cluster.matches.every((m) => m.approved)
    const latestMatchDate = Math.max(...cluster.matches.map((m) => new Date(m.created).getTime()))
    const isSortingByDate = location.search.includes('sort=-matches.created')

    const destination = {
      ...location,
      pathname: location.pathname.split('/view')[0] + '/view/' + cluster._id,
      state: {
        ...(location.state || {}),
        lastLocation: {
          pathname: location.pathname,
          search: location.search,
          state: location.state,
        },
      },
    }

    const deepSearchMatches = matches.filter((m) => {
      const matchSource = m.meta && (m.meta.matchSource || m.meta.source)
      return ['gris', 'gris-go'].includes(matchSource)
    })

    return (
      <SelectableContext.Consumer>
        {({ isSelecting }) => (
          <div style={{ zIndex: '2' }}>
            <div css={gridItem}>
              <SelectableItem id={cluster._id}>
                <ChooseBestImageSource.ForCluster preferredImage={image._id} cluster={cluster}>
                  {({ url, isError }) => (
                    <SexyThumbnail
                      url={url}
                      linkDestination={!isSelecting && destination}
                      isError={isError}
                      ignored={isClusterIgnored}
                      approved={isClusterApproved}
                      caption={
                        matches.length > 1 && (
                          <div css={groupSizeIndicator}>
                            <div>
                              <em>{matches.length}</em>
                              <span>Matches</span>
                            </div>
                          </div>
                        )
                      }
                    />
                  )}
                </ChooseBestImageSource.ForCluster>
              </SelectableItem>
              {isSortingByDate ? (
                <div css={textHolder}>
                  <h4>{domain.host}</h4>
                  <span title={`Detected ${moment(created).format('LL')}`}>{moment(latestMatchDate).fromNow()}</span>
                  {allCookies['pixsy-shark'] && (
                    <span title="Results breakdown" style={{ display: 'list-item' }}>
                      Deep Search: {deepSearchMatches.length.toString()} Spiders:{' '}
                      {(matches.length - deepSearchMatches.length).toString()}
                    </span>
                  )}
                </div>
              ) : (
                <div css={textHolder}>
                  <h4>{domain.host}</h4>
                  <span title={`Updated ${moment(latestMatchDate).format('LL')}`}>{moment(created).fromNow()}</span>
                  {allCookies['pixsy-shark'] && (
                    <span title="Results breakdown" style={{ display: 'list-item' }}>
                      Deep Search: {deepSearchMatches.length.toString()} Spiders:{' '}
                      {(matches.length - deepSearchMatches.length).toString()}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </SelectableContext.Consumer>
    )
  }
}

export default connect((state) => ({ user: state.auth.user }))(withCookies(ViewCardGrid))

ViewCardGrid.propTypes = {
  cluster: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
}

const groupSizeIndicator = css`
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 40%);
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: flex-end;
  color: #fff;
  text-align: center;
  font-weight: 600;
  // text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.50);
  padding: 0 0 5px 10px;
  > * {
    display: block;
    margin: 0;
  }
  em {
    display: block;
    font-size: 1.8em;
    font-style: normal;
    position: relative;
    bottom: -10px;
  }
  span {
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: block;
    opacity: 0.5;
  }
`

const textHolder = css`
  text-align: center;
  padding: 10px 0;
  transition: opacity 150ms ease;

  h4 {
    font-weight: normal;
    margin: 0 0 -4px;
    font-size: 1em;
  }

  span {
    font-size: 0.8em;
    color: #0000004d;
  }
`

const gridItem = css`
  position: relative;
  /* height: 180px; */
  width: 100%;
  &:hover {
    opacity: 0.85;
    .effectHolder {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }
    img {
      transform: scale(1.018);
    }
    .textholder {
      opacity: 0.75;
    }
  }
`
