import AlertIcon from '@material-ui/icons/ErrorRounded'
import isPlainObject from 'lodash/isPlainObject'
import * as React from 'react'
import { css } from 'react-emotion'
import {
  PixsyGrayedLabel,
  PixsyGrid,
  PixsyGridItem,
  PixsyPaper,
  PixsyProgress,
} from '../../Components'
import {
  getCaption,
  getImageUrl,
  getPercentage,
  getSubTitle,
  isItLoading,
  shouldRetry,
} from '../util/images'
import { ImageItem } from './ImageItem'

/**
 * @typedef {import('../../CaseSubmission').FileValues} IFile
 * @typedef {import('../../CaseSubmission').ImageValues} IImage
 * @typedef {import('../../CaseSubmission').MatchValues} IMatch
 * @typedef {import('../../CaseSubmission').IState} ISubmissionState
 *
 * @typedef {Object} ImagesSidebarProps
 * @property {string=} domain
 * @property {IImage[]} images
 * @property {ISubmissionState["imagesCompleted"]} imagesCompleted
 * @property {boolean} isFormReadOnly
 * @property {IMatch[]} matches
 * @property {function} removeImage
 * @property {function} retryImageImportFile
 * @property {number=} selectedImage
 * @property {any} stageInfo
 * @property {() => ({ text: string, progress: boolean }) | null} getStageInfoStatus
 * @property {string} title
 */

/**
 * @type {(props: ImagesSidebarProps) => any}
 */
export const ImagesSidebar = React.memo(
  /**
   * @param {ImagesSidebarProps} param0
   */
  ({
    children,
    domain,
    handleSelectImage,
    images,
    imagesCompleted,
    isFormReadOnly,
    matches,
    removeImage,
    retryImageImportFile,
    selectedImage,
    stageInfo,
    stageInfoStatus,
    title,
  }) => (
    <PixsyGridItem xs={4} style={{ zIndex: 1 }}>
      <PixsyPaper variant="transparentWithSeparator" padding="0 0 10px 0">
        {!!title && <PixsyGrayedLabel element="p">{title}</PixsyGrayedLabel>}
        {!!domain && <p css={domainHeading}>{domain}</p>}
      </PixsyPaper>

      <PixsyPaper variant="transparentWithSeparator" padding="20px 0 0">
        {!!images.length && (
          <PixsyPaper variant="transparent" padding="0 0 20px">
            <PixsyGrid direction="column" spacing={1}>
              {images.map((i, idx) => {
                const isLoading = isItLoading(i.file)
                const isRetry = shouldRetry(i.file)
                const percent = getPercentage(i.file)

                const props = {
                  index: idx,
                  loading: isLoading,
                  isRetry,
                  percent,
                  url: getImageUrl(i.file, i),
                  onRetry: i.file
                    ? () => retryImageImportFile(i.file.id)
                    : null,
                  title: i.title || 'Untitled',
                  subTitle: getSubTitle(isRetry, isLoading, matches[idx]),
                  onRemove: e => {
                    e.preventDefault()
                    removeImage(i.uuid)
                  },
                  active: idx === selectedImage,
                  thumbnailLabel: getCaption(isRetry, isLoading, percent),
                  isComplete: imagesCompleted[idx],
                  onSelectImage: handleSelectImage,
                  isFormReadOnly,
                  totalImages: images.length,
                }

                return (
                  <ImageItem key={i.uuid || i._id || i.url || idx} {...props} />
                )
              })}
            </PixsyGrid>
          </PixsyPaper>
        )}

        <PixsyGrid spacing={1} direction="column" className={footerStyle}>
          {children && <PixsyGridItem xs={12}>{children}</PixsyGridItem>}
          <StageInfoStatus stageInfoStatus={stageInfoStatus} />
        </PixsyGrid>
      </PixsyPaper>
      {stageInfo && (
        <PixsyGrid spacing={1} wrap="nowrap" style={{ paddingTop: '30px' }}>
          <PixsyGridItem flexShrink={0} css={infoIconStyle}>
            <AlertIcon fontSize="inherit" />
          </PixsyGridItem>
          <PixsyGridItem flexGrow={1} css={infoDetailsStyle}>
            <h1>{stageInfo.title}</h1>
            {stageInfo.content}
            <a
              href="https://support.pixsy.com/en/articles/70"
              target="_about:blank"
            >
              More questions?
            </a>
          </PixsyGridItem>
        </PixsyGrid>
      )}
    </PixsyGridItem>
  )
)

const StageInfoStatus = React.memo(({ stageInfoStatus }) => {
  const PROGRESS_SIZE_PX = 16

  if (!isPlainObject(stageInfoStatus)) return null

  return (
    <PixsyGridItem xs={12}>
      <div css={stageInfoStatusStyle}>
        {stageInfoStatus.progress && (
          <React.Fragment>
            <PixsyProgress
              size={PROGRESS_SIZE_PX}
              color="rgba(67, 67, 67, 0.7)"
            />
          </React.Fragment>
        )}
        {stageInfoStatus.text}
      </div>
    </PixsyGridItem>
  )
})

// const headerPaperStyle = css`
//   margin-bottom: 9px;
// `
const domainHeading = css`
  font-size: 29px;
  line-height: 29px;
  font-weight: 600;
  text-transform: lowercase;
  word-break: break-all;
  margin: 8px 0;
`
const footerStyle = css`
  padding: 0px 0 20px 0;
  button {
    width: 100%;
  }
`

const stageInfoStatusStyle = css`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgba(67, 67, 67, 0.7);
  padding: 8px 0 0px;
  margin: 0;

  div {
    vertical-align: sub;
    margin-right: 8px;
  }
`
const infoIconStyle = css`
  font-size: 30px;
  color: #b7b7b7;
`
const infoDetailsStyle = css`
  h1 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }

  a {
    display: block;
    margin-top: 18px;
    text-align: center;
  }
`
