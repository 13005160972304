import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

const StyledFooter = styled.footer`
  min-width: 320px;
  margin: 30px auto 0;
  padding: 15px 0;
  font-size: 0.85em;

  @media (max-width: 768px) {
    padding-bottom: 79px;
  }
`

const LinkList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-basis: 200px;
  flex-wrap: wrap;
  ${props =>
    props.light &&
    `
      background: rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding-top: 2px;
      padding-bottom: 1px;
  `}

  a {
    padding: 0 8px;
    color: ${props => (props.light ? '#FFF' : '#3b3b3b')};
    text-shadow: ${props => (props.light ? '0px 0px 4px rgba(0,0,0,1)' : '0')};
    font-weight: 400;
  }
`
export default function Footer({ light, skipClearbit }) {
  return (
    <StyledFooter>
      <LinkList light={light}>
        <a target="_blank" href="https://www.pixsy.com/blog">
          Blog
        </a>
        <a target="_blank" href="https://www.pixsy.com/academy">
          Academy
        </a>
        <a target="_blank" href="https://www.pixsy.com/terms-of-use">
          Terms of Use
        </a>
        <a target="_blank" href="https://www.pixsy.com/privacy-policy">
          Privacy Policy
        </a>
        {skipClearbit || (
          <a target="_blank" href="https://clearbit.com">
            Domain logos provided by Clearbit
          </a>
        )}
      </LinkList>
    </StyledFooter>
  )
}

Footer.defaultProps = {
  light: false,
  skipClearbit: false,
}

Footer.propTypes = {
  light: PropTypes.bool,
  skipClearbit: PropTypes.bool,
}
