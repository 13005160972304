import React from 'react'
import { css, cx } from 'react-emotion'

export default function ActionButton({ Icon, text, textClassName, className, ...rest }) {
  return (
    <div className={cx('actionButton', styles.container, className)} {...rest}>
      <div className={styles.iconWrapper}>
        <Icon />
      </div>
      <span css={styles.text} className={textClassName}>
        {text}
      </span>
    </div>
  )
}

const styles = {
  container: css`
    display: grid;
    gap: 10px;
    grid-template-rows: 4fr 3fr;
    width: 79px;
    height: 66px;
    font-size: 9px;
    font-weight: 600;
    border: 1px solid #ebebeb;
    box-shadow: #07070713 0 1px 2px;
    cursor: pointer;
    background: white;
    text-decoration: none;
    color: #212121;

    &:hover {
      opacity: 0.8;
    }
  `,
  iconWrapper: css`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 25px;
  `,
  text: css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.15em;
    font-size: 10px;
    line-height: 1.25;
    padding: 0 5px;
  `,
}
