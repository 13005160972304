import { Component } from 'react'
import PropTypes from 'prop-types'

export default class PaypalContext extends Component {
  componentDidMount() {
    if (!window.paypal) {
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src = 'https://www.paypalobjects.com/api/checkout.js'
      document.body.appendChild(script)
    }
  }

  render() {
    return this.props.children
  }
}

PaypalContext.propTypes = { children: PropTypes.node.isRequired }
