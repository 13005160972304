import React from 'react'
import { asyncConnect } from 'redux-connect'
import EmptyState from '../EmptyState/EmptyState'
import { withEmptyStates } from '../../../decorators'
import { load } from '../../../redux/modules/takedowns'
import { withRouter } from 'react-router'
import TakedownOverview from './TakedownOverview'
import {
  getTakedownsQuery,
  assembleTakedownsAndTotal,
} from '../../../redux/selectors'

const TakedownOverviewContainer = withRouter(
  asyncConnect(
    [
      {
        promise: function fetchTakedownsForOverview({
          store: { getState, dispatch },
          location,
          match,
        }) {
          const query = getTakedownsQuery(getState(), { location, match })

          const promise = dispatch(load(query))
          return __SERVER__ && promise
        },
      },
    ],
    (state, props) => {
      const { takedowns, ...rest } = assembleTakedownsAndTotal(state, props)
      const enrichWithOnlineStatus = t => ({
        done: t.cluster.matches.filter(
          m => m.inspection && m.inspection.status === 'offline'
        ).length,
        total: t.cluster.matches.length,
        ...t,
      })

      return {
        loading: state.takedowns.loading,
        takedowns: takedowns.map(enrichWithOnlineStatus),
        ...rest,
      }
    },
    { load }
  )(
    withEmptyStates([
      {
        shouldRender: props => props.total === 0,
        render: () => <EmptyState />,
      },
    ])(TakedownOverview)
  )
)

export default TakedownOverviewContainer
