import React, { Component } from 'react'
import { humanize } from 'pixsy-constants'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { withRouter } from 'react-router'
import moment from 'moment'
import { Button } from 'common'

class ImportReminder extends Component {
  static COOKIE_KEY = 'pixsy-import-reminder-seen'
  static HASH_KEY = '#import-refresh'

  static getOutdated(user) {
    const sources = user.sources

    return Object.entries(sources)
      .filter(([key, value]) => value && key !== 'fivehundredpx') // legacy source
      .reduce((arr, [key, value]) => {
        const updated = value.updated
        const differenceInDays = moment().diff(updated, 'days')

        if (updated && differenceInDays > 31) {
          arr.push({
            service: key,
            updated,
          })
        }

        return arr
      }, [])
  }

  static isEligible(user, cookies) {
    const seenRecently = cookies.get(ImportReminder.COOKIE_KEY)

    if (seenRecently) return false

    return ImportReminder.getOutdated(user).length
  }

  handleWarningLater = () => {
    const { cookies } = this.props
    cookies.set(ImportReminder.COOKIE_KEY, 1, {
      path: '/',
      expires: moment().add(2, 'days').toDate(),
    })
  }

  handleWarningNavigate = () => {
    const { cookies, history } = this.props

    history.push({
      pathname: '/images/import',
    })

    setTimeout(() => {
      cookies.set(ImportReminder.COOKIE_KEY, 1, {
        path: '/',
        expires: moment().add(1, 'hour').toDate(),
      })
    })
  }

  render() {
    const outdated = ImportReminder.getOutdated(this.props.user)
    return (
      <div css={content}>
        <h1>Re-sync Your Connection</h1>

        <div>
          You have not refreshed your connection to the following image import
          sources for some time:
          <ul>
            {outdated.map(({ service, updated }) => (
              <li key={service}>
                <b>{humanize(service)}</b> ({moment(updated).fromNow()})
              </li>
            ))}
          </ul>
        </div>

        <div>
          We recommend re-syncing your images regularly to:
          <ul>
            <li>Import new images</li>
            <li>Update image titles and metadata</li>
            <li>Update and repair broken images</li>
            <li>
              Remove any images that have been deleted from your import sources*
            </li>
          </ul>
        </div>

        <h4>How to re-sync</h4>

        <div css={sideBySide}>
          <ol>
            <li>Navigate to Images &gt; Import</li>
            <li>
              Click the synchronize icon in the top left of the source card
            </li>
            <li>Follow any prompts from the import source to refresh</li>
            <li>
              Clicking the X will disconnect the import source, removing all
              associated images and their matches from your account
            </li>
          </ol>
          <figure>
            <img src={require('./importReminderExample.png')} />
          </figure>
        </div>

        <div css={footNote}>
          * re-syncing with your chosen import source will restore images
          previously deleted from Pixsy.
        </div>

        <div css={buttons}>
          <Button outline onClick={this.handleWarningLater}>
            Remind me later
          </Button>
          <Button main onClick={this.handleWarningNavigate}>
            Take me to my Imports
          </Button>
        </div>
      </div>
    )
  }
}

const ConnectedImportReminder = connect((state) => ({ user: state.auth.user }))(
  withRouter(withCookies(ImportReminder))
)

export default ConnectedImportReminder

ImportReminder.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line
}

ConnectedImportReminder.eligible = (user, location, cookies) =>
  ImportReminder.isEligible(user, cookies)

const content = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  h1,
  h4 {
    text-align: center;
    width: 100%;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    ul,
    ol {
      padding-left: 18px;
    }
    ol {
      margin-bottom: 20px;
    }
  }
`

const sideBySide = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  ol {
    padding-right: 20px;
  }
`

const footNote = css`
  margin-top: 20px;
  color: #3b3b3b80;
  font-size: 0.9em;
  padding: 0 20px;
`

const buttons = css`
  width: 100%;

  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  button {
    margin: 30px 4px 0 4px;
  }
`
