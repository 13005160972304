import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import moment from 'moment'

function TakedownAction({ takedown: { created } }) {
  const format = d => {
    if (!d) return '-'

    let humanized = moment(d).fromNow()

    if (humanized.includes('a few seconds ago')) {
      humanized = 'just now'
    }

    return humanized
  }

  return (
    <p css={actionCell} title={moment(created).format('dddd, MMMM Do YYYY')}>
      Sent {format(created)}
    </p>
  )
}

TakedownAction.propTypes = {
  takedown: PropTypes.shape({
    created: PropTypes.string.isRequired,
  }).isRequired,
}

export default React.memo(TakedownAction)

const actionCell = css`
  font-size: 0.9em;
  white-space: nowrap;
`
