import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import TakedownOverviewContainer from './Overview/TakedownOverviewContainer'

export default function Takedowns({ location, history, match }) {
  return (
    <React.Fragment>
      <Helmet title="Takedowns" />

      <TakedownOverviewContainer
        location={location}
        history={history}
        match={match}
      />
    </React.Fragment>
  )
}

Takedowns.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}
