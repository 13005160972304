import React, { useState, useCallback } from 'react'
import { css } from 'react-emotion'
import { CircularProgress } from '@material-ui/core'
import { BackArrowIcon } from '../../../components/Icons'
import AnimatedNumbers from '../../../components/AnimatedNumbers'
import SimilarityToggle from './SimilarityToggle'
// import DateFoundFilter from './DateFound'
import ImagesFilter from './Images'
import ActionsFilter from './Actions'
import { ACTION_ITEMS } from '../../../store/constants'

const MatchGroupFilters = ({ filteredCluster, cluster, setFilters, filters, onExitGroupView }) => {
  const [selectedActioned, setSelectedActioned] = useState([])
  const onSimilarityToggle = useCallback(
    (similarity) => {
      switch (similarity) {
        case 'exact':
          setFilters({ ...filters, similarity: (m) => m.similarity >= 0.65 })
          break
        case 'similar':
          setFilters({
            ...filters,
            similarity: (m) => m.similarity < 0.65 && m.similarity >= 0.45,
          })
          break
        default:
          setFilters({ ...filters, similarity: null })
      }
    },
    [cluster, filters]
  )

  // const onYearChange = useCallback(
  //   (dates) => {
  //     const startDate = new Date(dates[1], 0, 1)
  //     const endDate = new Date(dates[0], 11, 31)
  //     setFilters({
  //       ...filters,
  //       date: (m) => new Date(m.created) >= startDate && new Date(m.created) <= endDate,
  //     })
  //   },
  //   [cluster, filters]
  // )

  const onSelectImageIds = useCallback(
    (imageIds) => {
      if (imageIds.length === 0) {
        setFilters({ ...filters, image: null })
      } else {
        setFilters({ ...filters, image: (m) => imageIds.includes(m.image) })
      }
    },
    [cluster, filters]
  )

  const imagesFilterItems = Object.entries(
    (cluster ? cluster.matches : []).reduce((acc, match) => {
      if (!acc[match.image]) {
        const image = cluster.images.find((i) => i._id === match.image) || {}

        acc[match.image] = {
          value: image.title,
          count: 0,
        }
      }

      acc[match.image].count++

      return acc
    }, {})
  ).map(([id, data]) => ({ id, ...data }))

  const onActionsSelect = useCallback(
    (actionIds) => {
      if (actionIds.length === 0) {
        setFilters({ ...filters, action: null })
      } else {
        const filterFn = (match) => {
          return actionIds.reduce((acc, action) => {
            if (acc) return acc
            switch (action) {
              case 'Ignored':
                return match.ignored
              case 'Offline':
                return match.isOnline === false
              case 'Approved':
                return match.approved
              case 'Case Submitted':
                return !!match.case
              case 'Takedown Sent':
                return !!match.takedown
              default:
                return false
            }
          }, false)
        }
        setFilters({ ...filters, action: filterFn })
      }
    },
    [cluster, filters]
  )

  const actionFilterItems = Object.values(ACTION_ITEMS).map((action) => {
    let matchCount = 0
    const matches = cluster ? cluster.matches : []
    switch (action) {
      case 'Ignored':
        matchCount = matches.filter((m) => m.ignored).length
        break
      case 'Offline':
        matchCount = matches.filter((m) => m.isOnline === false).length
        break
      case 'Approved':
        matchCount = matches.filter((m) => m.approved).length
        break
      case 'Case Submitted':
        matchCount = matches.filter((m) => !!m.case).length
        break
      case 'Takedown Sent':
        matchCount = matches.filter((m) => !!m.takedown).length
        break
    }
    return { id: action, value: action, count: matchCount }
  })

  return (
    <div css={styles.container}>
      <div className={styles.exitGroupView} onClick={onExitGroupView}>
        <BackArrowIcon /> EXIT GROUP VIEW
      </div>
      <div css={styles.filtersBox}>
        <div css={styles.searchResultInfoWrapper}>
          <div css={styles.searchResultsCount}>
            {filteredCluster && filteredCluster.matches ? (
              <AnimatedNumbers
                number={
                  filteredCluster.matches.filter((match) => (selectedActioned.length ? true : !match.ignored)).length
                }
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <div css={styles.pageInfo}>matches in group</div>
        </div>
        <div css={styles.filterWrapper}>
          <SimilarityToggle onSelect={onSimilarityToggle} />
          <div css={styles.toggleList}>
            {/* <DateFoundFilter onSelect={onYearChange} /> */}
            <ImagesFilter items={imagesFilterItems} onSelect={onSelectImageIds} />
            <ActionsFilter
              items={actionFilterItems}
              onSelect={onActionsSelect}
              selected={selectedActioned}
              setSelected={setSelectedActioned}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: css`
    position: sticky;
    top: 0;
    left: 0;
    align-self: flex-start;
    width: 290px;
    padding-left: 15px;
    font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
    z-index: 10;
  `,
  exitGroupView: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    width: 150px;
    height: 28px;
    border: 1px solid #dddddd;
    color: #212121;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }
  `,
  searchResultInfoWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #121212;
    margin-bottom: 18px;
    margin-top: 18px;
  `,
  searchResultsCount: css`
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
  `,
  pageInfo: css`
    color: #12121250;
    font-size: 12px;
  `,
  searchAndRefresh: css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    margin-bottom: 13px;
    height: 24px;
  `,
  filtersBox: css`
    border: 1px solid #dddddd;
    border-bottom: 0;
    background: #ffffff;
  `,
  refreshButton: css`
    position: absolute;
    top: 6px;
    right: 8px;
  `,
  refreshButtonWhenFiltersChanged: css`
    cursor: pointer;

    path {
      stroke: #ffa168;
    }
  `,
  filterWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  toggleList: css`
    display: flex;
    flex-direction: column;
    border-top: 1px #e8e8e8 solid;
  `,
  toggleContainer: css`
    display: flex;
    flex-direction: row;

    &:nth-of-type(1) {
      border-top: 1px #e8e8e8 solid;
    }
  `,
}

export default MatchGroupFilters
