import '../../wdyr'
import React from 'react'
import { ReduxAsyncConnect } from 'redux-connect'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { ConnectedRouter } from 'react-router-redux/index'
import { hot } from 'react-hot-loader'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '../../graphql/apollo'

// Admin: https://www.google.com/recaptcha/admin/site/432315374
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'


function Root({ store, history }) {
  // When root is being re-required after hot module replacement
  // Webpack downloads the module in es5 style
  // So fallback access via default is required
  const routesModuleContent = require('../../routes')
  const routes = Array.isArray(routesModuleContent)
    ? routesModuleContent
    : routesModuleContent.default

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lfum8QZAAAAAH5tfxtrKnWbRy7QY1RSxiKzPL3e">
      <Provider store={store} key="provider">
        <CookiesProvider>
          <ConnectedRouter history={history}>
            <ApolloProvider client={apolloClient}>
              <ReduxAsyncConnect routes={routes} />
            </ApolloProvider>
          </ConnectedRouter>
        </CookiesProvider>
      </Provider>
    </GoogleReCaptchaProvider>
  )
}

export default hot(module)(Root)
