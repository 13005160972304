import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'

import { CaseSubmissionIcon, TakeDownIcon } from '../../Icons'

const ActionSelectionBox = ({ boxType, isMultiMatch, active, handleDisplayError, onClick, orangeBorder }) => {
  const { Icon, title, description, buttonText } = {
    caseSubmission: {
      Icon: CaseSubmissionIcon,
      title: 'Submit Case',
      description: 'Let Pixsy recover compensation for the use of your image',
      buttonText: 'SUBMIT CASE',
    },
    takedown: {
      Icon: TakeDownIcon,
      title: 'Send Takedown',
      description: 'Get the image taken down by issuing a legal takedown notice',
      buttonText: 'SEND TAKEDOWN',
    },
  }[boxType]

  return (
    <div css={cx(styles.container, { [styles.orangeBorder]: orangeBorder && active })}>
      <div css={styles.infoContainer}>
        <span css={styles.smallTitle}>{isMultiMatch ? 'GROUP MATCH' : 'SINGLE MATCH'}</span>
        <Icon css={styles.icon} />
        <span css={styles.title}>{title}</span>
        <span css={styles.description}>{description}</span>
        {!active && Boolean(handleDisplayError) ? (
          <span css={styles.learnWhy} onClick={handleDisplayError}>
            Learn why.
          </span>
        ) : (
          <span css={styles.learnWhy} />
        )}
      </div>
      <button disabled={!active} onClick={onClick} css={cx(styles.button, { [styles.activeButton]: active })}>
        {buttonText}
      </button>
    </div>
  )
}

const styles = {
  button: css`
    border: none;
    padding: 14px 30px 11px 30px;
    background-color: #ebebeb;
    box-shadow: #00000013 0 1px 4px;
    line-height: 16px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.25em;
    color: white;
  `,
  activeButton: css`
    background-color: #ffa168;
  `,
  description: css`
    font-size: 13px;
    text-align: center;
    margin: 0 38px;
    line-height: 16px;
  `,
  title: css`
    font-weight: 300;
    font-size: 33px;
    margin: 0 70px 0 70px;
    text-align: center;
    line-height: 40px;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 370px;
    align-items: center;
    justify-content: space-evenly;
    border: 0.5px #ebebeb solid;
    box-shadow: #07070713 0 1px 2px;
    color: #212121;
  `,
  infoContainer: css`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 270px;
    align-items: center;
    justify-content: space-evenly;
  `,
  learnWhy: css`
    color: #148aa9;
    height: 20px;
    cursor: pointer;
  `,
  smallTitle: css`
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.25em;
  `,
  orangeBorder: css`
    border: #ffa168 3px solid;
  `,
}

ActionSelectionBox.propTypes = {
  boxType: PropTypes.oneOf(['caseSubmission', 'takedown']),
  active: PropTypes.bool,
  isMultiMatch: PropTypes.bool,
  handleDisplayError: PropTypes.func,
  onClick: PropTypes.func,
}

export default ActionSelectionBox
