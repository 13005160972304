import React, { useEffect } from 'react'
import { css } from 'react-emotion'
import { useLazyQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'

import GET_CLUSTER from '../graphql/queries/cluster'

const withEnsureClusterData = (WrappedComponent, showProgress = false) => {
  return function EnsuredClusterDataComponent({ cluster, clusterId, ...rest }) {
    const [loadCluster, { data }] = useLazyQuery(GET_CLUSTER, {
      fetchPolicy: 'cache-and-network',
    })
    const clusterToPass = (data && data.cluster) || cluster

    useEffect(() => {
      if (clusterId) {
        loadCluster({
          variables: {
            id: clusterId,
          },
        })
      }
    }, [clusterId])

    if (!clusterToPass) {
      if (showProgress) {
        return (
          <div css={progressContainer}>
            <CircularProgress />
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <WrappedComponent
        clusterId={clusterId}
        cluster={clusterToPass}
        {...rest}
      />
    )
  }
}

const progressContainer = css`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 130px;
`

export default withEnsureClusterData
