import React from 'react'
import { ContentSection } from 'common'
import PaymentRailsContainer from './PaymentRailsContainer'

export default function PaymentsContainer() {
  return (
    <ContentSection>
      <PaymentRailsContainer />
    </ContentSection>
  )
}

PaymentsContainer.propTypes = {}
