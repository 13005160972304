import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { css } from 'react-emotion'
import { ImageComparison, ActionButton } from 'common'
import { Link } from 'react-router-dom'
import { humanize } from 'pixsy-constants'
import { SearchIcon, ImageLayersIcon, TrashBinIcon, CrossIcon, FalseIcon } from '../../Icons'
import { InfoSection, InfoColumn, ColumnTitle, RowData, ScrollableContent } from '../../InfoSectionElements'
import * as Shapes from '../../../utils/shapes'
import useClusters from '../../../utils/useClusters'
import { actionCreators } from '../../../store'
import { useMatchViewerStore } from '../../../store/context'

const ImageDataContent = ({
  cluster,
  matchId,
  onFalsePositiveMatchRequest,
  onIgnoreMatchRequest,
  onImageDeletion,
  globalDispatch,
  onRequestClose,
}) => {
  const { ignoreImage } = useClusters(globalDispatch)
  const { dispatch } = useMatchViewerStore()
  const match = cluster.matches.find((m) => m._id === matchId) || cluster.matches[0]
  const image = cluster.images.find((i) => i._id === match.image) || cluster.images[0]
  const metadata = image.metadata || {}

  const handleIgnoreImage = async () => {
    await ignoreImage({
      variables: {
        id: image._id,
        state: true,
      },
      onCompleted: () => {
        onRequestClose()
        dispatch(actionCreators.setGroupMatchesViewClose(cluster._id))
      },
    })
  }

  return (
    <ScrollableContent>
      <ImageComparison
        onlyShowSlideControl
        showTitlesOnSlide
        additionalClassName={styles.imageCompare}
        imageURL={image.url}
        matchURL={match.url}
      />
      <InfoSection>
        <InfoColumn left grid>
          <ColumnTitle>
            <span>IMAGE</span>
          </ColumnTitle>
          <div>
            <RowData property="IMAGE TITLE">{image.title}</RowData>
            <RowData property="UPLOADED">{moment(image.created).format('MMMM D, YYYY')}</RowData>
            <RowData property="EXIF">
              {metadata.DeviceManufacturer && (
                <span>
                  {metadata.DeviceManufacturer} <br />
                </span>
              )}
              {metadata.DeviceModel && (
                <span>
                  {metadata.DeviceModel} <br />
                </span>
              )}
              {metadata.ImageSize && (
                <span>
                  {metadata.ImageSize} <br />
                </span>
              )}
            </RowData>
            <RowData property="SOURCE">{humanize(image.origin.source)}</RowData>
          </div>
          <div className="infoColumnButtonsWrapper">
            <Link to={`/images/${image._id}`} target="_blank">
              <ActionButton Icon={SearchIcon} text="OPEN IMAGE" />
            </Link>
            <Link to={`/matches/images:${image._id}`} target="_blank">
              <ActionButton Icon={ImageLayersIcon} text="RELATED MATCHES" />
            </Link>
            <ActionButton Icon={TrashBinIcon} text="DELETE IMAGE" onClick={onImageDeletion} />
            <ActionButton Icon={CrossIcon} text="IGNORE IMAGE" onClick={handleIgnoreImage} />
          </div>
        </InfoColumn>

        <InfoColumn right grid>
          <ColumnTitle>
            <span>MATCH</span>
          </ColumnTitle>
          <div>
            <RowData property="DETECTED">{moment(match.created).format('MMMM D, YYYY')}</RowData>
            <RowData property="PAGE TITLE">{match.origin.title}</RowData>
            <RowData property="MATCH URL">
              <a href={match.origin.url} target="_blank">
                {match.origin.url}
              </a>
            </RowData>
            <RowData property="IMAGE URL">
              <a href={match.url} target="_blank">
                {match.url}
              </a>
            </RowData>
          </div>
          <div className="infoColumnButtonsWrapper">
            <ActionButton Icon={FalseIcon} text="NOT MY IMAGE" onClick={onFalsePositiveMatchRequest} />
            <ActionButton Icon={CrossIcon} text="IGNORE MATCH" onClick={onIgnoreMatchRequest} />
          </div>
        </InfoColumn>
      </InfoSection>
    </ScrollableContent>
  )
}

const styles = {
  imageCompare: css`
    .imageComparisonControls {
      position: absolute;
      font-size: 12px;
      margin: 0;
      top: 18px;
      right: 31px;

      input {
        margin: 0 10px;
      }
    }

    img {
      box-shadow: #00000016 0 3px 6px;
    }
  `,
}

ImageDataContent.propTypes = {
  cluster: Shapes.Cluster,
  matchId: PropTypes.string,
  onFalsePositiveMatchRequest: PropTypes.func.isRequired,
  onIgnoreMatchRequest: PropTypes.func.isRequired,
}

export default connect(null, (globalDispatch) => ({ globalDispatch }))(ImageDataContent)
