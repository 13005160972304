
export const CHARGES_LOAD = 'CHARGES_LOAD'
export const CHARGES_LOAD_SUCCESS = 'CHARGES_LOAD_SUCCESS'
export const CHARGES_LOAD_FAIL = 'CHARGES_LOAD_FAIL'


export const INTENT_LOAD = 'INTENT_LOAD'
export const INTENT_LOAD_SUCCESS = 'INTENT_LOAD_SUCCESS'
export const INTENT_LOAD_FAIL = 'INTENT_LOAD_FAIL'


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  totalCharge: 0,
  transactionCount: 0,
  result: [],
  client_secret: '',
}

export default function charges(state = initialState, action = {}) {
  switch (action.type) {
    case CHARGES_LOAD:
      return {
        ...state,
        loading: true,
      }
    case CHARGES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ..._processCharges(action.payload.charges)
      }
    case CHARGES_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      }
    case INTENT_LOAD_SUCCESS:
      return {
        ...state,
        client_secret: action.payload.client_secret
      }
    case INTENT_LOAD_FAIL:
      return {
        ...state,
        client_secret: ''
      }
    default:
      return state
  }
}

// GET /user/charges
export function loadCharge() {
  return {
    types: [CHARGES_LOAD, CHARGES_LOAD_SUCCESS, CHARGES_LOAD_FAIL],
    promise: client => client.get('/users/auth/charges'),
  }
}

function _processCharges(charges = {}) {
  const { data = [] } = charges || {}
  let totalCharge = 0
  let transactionCount = 0
  let result = []
  data.forEach((charge) => {
    if(charge.total > 0 ) {
      result.push(charge)
      totalCharge += charge.total
      transactionCount++
    }
  })
  return {
    totalCharge,
    transactionCount,
    result
  }
}
