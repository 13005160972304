import { Grid } from '@material-ui/core'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import _COUNTRIES from 'pixsy-constants/countries.json'
import React, { useState, useCallback, useMemo } from 'react'
import Checkbox from './Checkbox'
import Content from './content'
import { useColumnSort } from './helpers'

const COUNTRIES = [
  ..._COUNTRIES,
  {
    iso: 'undefined',
    displayName: 'Undefined',
    resolution: false,
  },
]

function useMatchPreferenceCountries(hiddenCountries) {
  const [hidden, setHidden] = useState(hiddenCountries)

  const onChange = useCallback(
    (iso) => {
      if (hidden.includes(iso)) setHidden(hidden.filter((h) => h !== iso))
      else setHidden([...hidden, iso])
    },
    [hidden, setHidden]
  )

  // HACK: in some cases it's build with 'strict mode', so array cloning is required here
  const showSaveButton = !isEqual(hiddenCountries.slice().sort(), hidden.slice().sort())

  const toggleAll = useCallback((categories, hideAll) => {
    if (hideAll) {
      setHidden(Array.from(new Set([...hidden, ...categories])))
    } else {
      setHidden(hidden.filter((h) => !categories.includes(h)))
    }
  })

  return {
    hidden,
    showSaveButton,
    toggleAll,
    onChange,
  }
}

function MatchPreferenceCountries({ hiddenCountries = [], updateMatchPreference, tagCounts }) {
  const { hidden, showSaveButton, toggleAll, onChange } = useMatchPreferenceCountries(hiddenCountries)

  const saveMatchPreference = useCallback(() => {
    return updateMatchPreference({ variables: { countries: hidden } })
  }, [hidden])

  const pixsyCountries = useMemo(() => uniq(COUNTRIES.filter((c) => c.resolution).map((c) => c.displayName)))
  const restOfWorld = useMemo(() => uniq(COUNTRIES.filter((c) => !c.resolution).map((c) => c.displayName)))

  const sortedPixsy = useColumnSort(pixsyCountries, 2, 4)
  const sortedWorld = useColumnSort(restOfWorld, 2, 4)

  return (
    <Content title="Countries" showSaveButton={showSaveButton} onSave={saveMatchPreference}>
      <Grid container>
        <RenderCountries
          countries={sortedPixsy}
          title="Pixsy Jurisdictions"
          onChange={onChange}
          toggleAll={toggleAll}
          tagCounts={tagCounts}
          hidden={hidden}
        />
        <div style={{ margin: 10 }} />
        <RenderCountries
          countries={sortedWorld}
          title="Rest of the world"
          onChange={onChange}
          toggleAll={toggleAll}
          tagCounts={tagCounts}
          hidden={hidden}
        />
      </Grid>
    </Content>
  )
}

const RenderCountries = React.memo(({ title, countries, onChange, toggleAll, tagCounts, hidden }) => {
  const displayCountries = useMemo(
    () => countries.filter(Boolean).map((country) => COUNTRIES.find((c) => c.displayName === country)),
    [countries]
  )

  const allSelected = useMemo(() => displayCountries.every((c) => !hidden.includes(c.iso)), [displayCountries, hidden])
  const allDeselected = useMemo(() => displayCountries.every((c) => hidden.includes(c.iso)), [displayCountries, hidden])

  return (
    <Grid container>
      <Checkbox
        large
        indeterminate={!allSelected && !allDeselected}
        checked={allSelected}
        title={`${title} (${displayCountries.reduce((sum, { iso }) => {
          const countData = tagCounts.find(({ tag }) => tag === `country:${iso}`)
          return sum + (countData ? countData.count : 0)
        }, 0)})`}
        onChange={toggleAll.bind(
          this,
          displayCountries.map((c) => c.iso),
          allSelected
        )}
      />
      <Grid container style={{ paddingLeft: 20 }}>
        {countries.map((name) => {
          if (!name) return <Grid item md={3} xs={6} />
          const country = COUNTRIES.find((c) => c.displayName === name)
          const checkboxTitle = `${country.displayName} (${
            (tagCounts.find(({ tag }) => tag === 'country:' + country.iso) || { count: 0 }).count
          })`
          return (
            <Grid item key={country.iso} md={3} xs={6}>
              <Checkbox
                title={checkboxTitle}
                checked={!hidden.includes(country.iso)}
                onChange={onChange.bind(this, country.iso)}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
})

export default React.memo(MatchPreferenceCountries)