import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { connect } from 'react-redux'
import {
  Button,
  LoadingOverlay,
  ChooseBestImageSource,
  createContactUsHandler,
} from 'common'
import moment from 'moment'
import { checkDuplicate } from '../../../../../../redux/modules/cases'
import getCaseStatusFromSubmission from '../../../../../../helpers/getCaseStatusFromSubmission'

class SubmissionValidationDialogDuplicateTest extends React.Component {
  state = { cases: [], total: 0, error: null, skip: false }

  componentDidMount() {
    this._unmounted = false
    const { checkDuplicate, cluster, recalculateHeight } = this.props

    this._listener = checkDuplicate(cluster.domain.host)
      .then(action => {
        if (!this._unmounted) {
          const { cases, total } = action.payload
          if (!cases.length) {
            this.props.skip()
          } else
            this.setState(
              {
                cases,
                total,
              },
              recalculateHeight
            )
        }
      })
      .catch(e => {
        if (!this._unmounted) {
          this.setState({
            error: true,
          })
        }
      })

    setTimeout(() => {
      if (!this._unmounted) {
        this.setState(({ cases, error }) => {
          if (!cases.length && !error) {
            return { error: true }
          }
        })
      }
    }, 20e3)
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  renderResults() {
    const { cases, total } = this.state
    const { cluster, onComplete, onIgnore } = this.props

    return (
      <React.Fragment>
        <h1>You have already submitted a case for {cluster.domain.host}</h1>
        <p>
          {' '}
          If you wish to add these matches to an open case, please reply to the
          case email you received from our Case Management Team (including the
          case ref #). If you can not find this email, please contact us.
        </p>

        <p>
          If this is a new match that is unaffiliated with the already submitted
          case, please continue.
        </p>

        <div css={caseInfoWrapper}>
          {Object.values(cases).map(c => (
            <div key={c._id} css={columnsContainer}>
              <div className={cx(column, thumbColumn)}>
                <div css={thumbnail}>
                  <ChooseBestImageSource
                    candidates={[
                      c.cluster && c.cluster.images[0] && c.cluster.images[0].url || '',
                    ]}
                    type="image"
                  >
                    {({ url }) => (
                      <div
                        css={thumbWrap}
                        style={{ backgroundImage: `url("${url}")` }}
                      >
                        {c.cluster && c.cluster.matches && c.cluster.matches.length > 1 &&
                          `+${c.cluster.matches.length - 1}`}
                      </div>
                    )}
                  </ChooseBestImageSource>
                </div>
              </div>
              <dl css={column}>
                <dt>Case NO</dt>
                <dd>{c.reference} </dd>
                <dt>Domain</dt>
                <dd>{c.cluster && c.cluster.domain.host}</dd>
              </dl>
              <dl css={column}>
                <dt>Submitted</dt>
                <dd>{moment.utc(c.created).fromNow()}</dd>
                <dt>Status</dt>
                <dd>{getCaseStatusFromSubmission(c)}</dd>
              </dl>
              <div className={cx(column, buttonColumn)}>
                <a target="_blank" href={`/cases/${c._id}`}>
                  <Button greyTertiary>View Case</Button>
                </a>
              </div>
            </div>
          ))}
          {total > cases.length && (
            <div css={casesFooter}>
              <div css={casesMore}>+{total - cases.length} more</div>
              <a target="_blank" href={`/cases/host:${cluster.domain.host}`}>
                <Button greyTertiary>Show all cases</Button>
              </a>
            </div>
          )}
        </div>

        <div className="dialog-slider--button-wrapper">
          <Button
            onClick={createContactUsHandler('Existing case')}
            greyTertiary
          >
            Contact us
          </Button>

          <Button grey onClick={onIgnore}>
            Ignore Match
          </Button>
          <Button hoverMain onClick={onComplete}>
            Continue
          </Button>
        </div>
      </React.Fragment>
    )
  }

  renderLoading() {
    const { cluster } = this.props
    return (
      <React.Fragment>
        <h4>
          Looking for previous cases on {cluster.domain.host}
          ...
        </h4>
        <div css={loading}>
          <LoadingOverlay.Dot />
        </div>
        <div className="dialog-slider--button-wrapper">
          <Button greyTertiary>Cancel</Button>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { cases } = this.state

    return (
      <div css={wrap}>
        {cases.length ? this.renderResults() : this.renderLoading()}
      </div>
    )
  }
}

// // const offlineImages = results.images.filter(m => m.image === 'offline')
// if (offlineImages.length) {
//   return (
//     <div css={offlineMatchesStyles}>
//       <p>
//         We detected the following images are offline. We will ask you to
//         provide an alternative URL during Case submission.
//       </p>
//       <ul>
//         {offlineImages.map(m => (
//           <li key={m._id}>{cluster.matches.find(m => m._id).url}</li>
//         ))}
//       </ul>
//     </div>
//   )
// }

export default connect(null, { checkDuplicate })(
  SubmissionValidationDialogDuplicateTest
)

SubmissionValidationDialogDuplicateTest.propTypes = {
  onComplete: PropTypes.func.isRequired,
  cluster: PropTypes.object.isRequired,
  checkDuplicate: PropTypes.func.isRequired,
  recalculateHeight: PropTypes.func.isRequired,
}

const loading = css`
  position: relative;
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const casesMore = css`
  margin: 15px 0;
  font-size: 1.3em;
  color: #c7c7c7;
`
const casesFooter = css`
  text-align: center;
`
const wrap = css`
  padding: 40px 70px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 40px 10px 30px;
  }

  h4 {
    color: #fff;
  }

  ul {
    padding-left: 0;
    list-style: none;
    width: 100%;

    li {
      color: #ff7f2f;
      text-align: center;

      a {
        max-width: 100%;
        word-wrap: break-word;
        color: #dda300;
        text-align: left;
      }
    }
  }
`

const caseInfoWrapper = css`
  max-height: calc(50vh - 150px);
  overflow-y: auto;
  margin-top: 20px;
  width: 100%;
`

const columnsContainer = css`
  width: 100%;
  display: flex;
  margin-top: 30px;

  dl {
    padding: 10px 0 5px;
    flex: 1;
    justify-content: space-between;
    min-width: 0;
    overflow-wrap: break-word;
    margin-bottom: 10px;
    height: 76%;
    @media (max-width: 1200px) {
      display: inline-block !important;
      float: left;
      height: 100%;
    }
    @media (min-width: 1200px) {
      float: left;
      width: 100%;
      height: auto;
    }
  }

  dt {
    color: rgba(255, 255, 255, 0.75);
    font-size: 0.85em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    /* @media (max-width: 1200px) {
      float: left;
      width: 30%;
    } */
  }

  dd {
    margin-top: -2px;
    margin-bottom: 4px;
    opacity: 0.8;
    color: #fff;
    letter-spacing: 0.1em;
    font-size: 1.2em;
    @media (max-width: 1200px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70%;
    }
    svg {
      vertical-align: 1px;
      height: 8px;
      margin-right: 3px;
    }
    a {
      color: #bbb;
      width: 100%;
      word-break: break-all;
    }
  }
`
const column = css`
  margin: 0 10px;

  h2 {
    margin-top: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.75);
    font-weight: 300;
    text-transform: uppercase;
    + span {
      color: rgba(255, 255, 255, 0.3);
      font-size: 0.9em;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
  }

  button {
    padding-left: 30px;
    padding-right: 30px;
  }
`
const thumbColumn = css`
  width: 100px;
`
const buttonColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const thumbnail = css`
  height: 100px;
  width: 100px;
  color: #000;
`
const thumbWrap = css`
  height: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 22px;
  margin-right: 20px;
  color: white;
  font-weight: 500;
`
