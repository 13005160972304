import React from 'react'
import { css } from 'react-emotion'

export function TakedownOverviewHeaderRow() {
  return (
    <thead css={styles}>
      <tr>
        <th>Image</th>
        <th>Domain / Reference</th>
        <th>Status</th>
        <th>Progress</th>
        <th>Sent</th>
      </tr>
    </thead>
  )
}

export default React.memo(TakedownOverviewHeaderRow)

const styles = css`
  th {
    color: #939393;
    cursor: pointer;
    font-weight: 400;
    padding: 0 20px 5px !important;
    border: 0;
    font-size: 0.9em;
    border-bottom: 0 !important;
    text-align: center;

    /* Buttons Button*/
    @media (max-width: 768px) {
      display: none;
    }

    &:nth-child(1) {
      /* image */
      width: 90px !important;
      text-align: left;
    }

    &:nth-child(2) {
      /* image */
      text-align: left;
    }

    &:nth-child(3) {
      /* status */
      width: 200px !important;
    }
  }
`
