import * as containers from './containers/index'

export default [
  {
    component: containers.AppContainer,
    routes: [
      {
        path: '/(login|register)',
        component: containers.Home,
      },
      {
        path: '/matches/overview',
        component: containers.MatchesOverview,
      },
      {
        path: '/resolve/:caseId',
        component: containers.ResolveContainer,
      },
      {
        path: '/matches',
        component: containers.Matches,
        routes: [
          {
            path: '/matches/overview',
            component: containers.MatchesOverview,
          },
          {
            path: '/matches/:tags*',
            component: containers.MatchesContainer,
            routes: [
              {
                path: '/matches/:set?/view/:clusterId',
                component: containers.Viewer,
              },
            ],
          },
        ],
      },
      {
        path: '/takedowns/submit/:takedownId',
        component: containers.TakedownSubmissionContainer,
      },
      {
        path: '/takedowns',
        component: containers.TakedownOverviewContainer,
      },

      {
        path: '/cases',
        routes: [
          {
            path: '/cases/submission/:caseId',
            component: containers.CaseSubmissionProvider,
          },
          {
            path: '/cases/:id([a-f\\d]{24})',
            component: containers.CaseDetailsContainer,
          },
          {
            path: '/cases/:filter(all|open|archived|pending|draft|closed|\\bhost\\b[\\x3A][^/?#&]+)?',
            component: containers.CasesContainer,
          },
        ],
      },
      {
        path: '/images/import',
        component: containers.Imports,
      },
      {
        path: '/images',
        component: containers.ImagesContainer,
        routes: [
          {
            path: '/images/:id([a-f\\d]{24})',
            component: containers.ImageDetailsContainer,
          },
          {
            path: '/images/:tags*',
            component: containers.Images,
          },
        ],
      },
      {
        path: '/profile/:slug?',
        component: containers.ProfileContainer,
        routes: [
          {
            path: '/profile/payments',
            component: containers.PaymentRailsContainer,
          },
          {
            path: '/profile/plans',
            component: containers.PlansContainer,
          },
          {
            path: '/profile/settings',
            component: containers.RuleSettings,
          },
        ],
      },
      {
        path: '/registrations',
        routes: [
          {
            path: '/registrations/submit/:registrationId',
            component: containers.RegistrationSubmissionContainer,
          },
          {
            path: '/registrations/:filter(all|final|draft|open)?',
            component: containers.RegistrationsContainer,
          },
        ],
      },
      {
        path: '/manager',
        component: containers.ManagerContainer,
        routes: [
          {
            path: '/manager/false-positive-review',
            component: containers.FalsePositiveReviewContainer,
          },
          {
            path: '/manager/false-positive-review/:matchId',
            component: containers.FalsePositiveReviewContainer,
          },
        ],
      },
    ],
  },
]
