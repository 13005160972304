import React from 'react'
import PropTypes from 'prop-types'
import { Notifs, actions } from 'redux-notifications'
import { css, cx } from 'react-emotion'

export default function Notifications({ darkMode }) {
  return (
    <Notifs
      className={cx(theme, darkMode && themeDark)}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
      CustomComponent={Notification}
    />
  )
}

function Notification(
  {
    kind,
    componentClassName,
    dispatch,
    actionLabel,
    onActionClick,
    id,
    message,
  },
  { dismiss }
) {
  const icons = {
    info: require(`./iconExclamation.svg`),
    warning: require(`./iconClose.svg`),
    success: require(`./iconCheck.svg`),
  }

  return (
    <div className={cx(notif, `${kind}`)}>
      <div className={icon} dangerouslySetInnerHTML={{ __html: icons[kind] }} />
      <div className={text}>{message}</div>

      <div
        className={close}
        onClick={() => dispatch(actions.notifDismiss(id))}
        dangerouslySetInnerHTML={{ __html: icons.warning }}
      />
    </div>
  )
}

const theme = css`
  position: fixed;
  top: 140px;
  right: 50px;
  z-index: 10000;
  width: 300px;
  margin: auto;
  @media (max-width: 768px) {
    max-width: 100%;
    // left: 5px;
    right: 5px;
    margin: 0;
  }
`

const text = css`
  flex: 1;
`

const notif = css`
  position: relative;
  display: flex;
  align-items: stretch;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
  color: #434343;
  font-size: 1.1em normal;
  padding: 1em 30px 1em 1em;
  background: #fff;
  line-height: 1.6em;
  user-select: none;

  @media (max-width: 768px) {
    width: calc(100% - 45px);
  }
`

const close = css`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    svg {
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1));
    }
  }

  svg {
    height: 15px;

    line {
      stroke-width: 3px;
    }
  }
`

const themeDark = css`
  .${close} .svg-notifications-close {
    fill: #fff;
    stroke: #fff;
  }
`

const icon = css`
  width: 46px;
  display: flex;
  justify-content: center;

  .info & svg {
    height: 28px;
  }

  .success & svg {
    margin-top: 4px;
    height: 16px;
  }

  .warning & svg {
    height: 16px;
    margin-top: 4px;
    line {
      stroke: #d16363;
    }
  }
`

Notification.propTypes = {
  kind: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}
