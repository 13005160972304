/* eslint-disable */
// TODO: Please fix eslint problems

import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import propTypes from 'prop-types'
import React, {
  useCallback,
  useContext,
  // useEffect,
  useMemo,
  // useState,
} from 'react'
import { css, cx } from 'react-emotion'
import { PixsyFormContext } from '../PixsyForm/PixsyForm'
import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'
import { LeftAddon, RightAddon } from './Addons'
import { CountryMenuItems } from './CountryMenuItems'
import { CreativeCommonsItems } from './CreativeCommonsItems'
import { CurrenciesItems } from './CurrenciesItems'
import { PixsyDatePicker } from './PixsyDatePicker'
import { PriceNegotiations } from './PriceNegotiationsItems'
import { TitleMenuItems } from './TitleMenuItems'
import { USCopyrightOfficeItems } from './USCopyrightOfficeItems'
import { WatermarkItems } from './WatermarkItems'

const baseTypes = [
  { match: 'cc-select', replace: 'select', default: '', isSelect: true },
  { match: 'country-select', replace: 'select', default: '', isSelect: true },
  {
    match: 'currency-select',
    replace: 'select',
    default: 'USD',
    isSelect: true,
  },
  { match: 'date', replace: 'text', default: '', isReadOnly: true },
  { match: 'email', default: '' },
  { match: 'number', default: '' },
  { match: 'password', default: '' },
  { match: 'price-negotiations-select', replace: 'select', default: '', isSelect: true }, // prettier-ignore
  { match: 'select', default: '', isSelect: true },
  { match: 'text', default: '' },
  { match: 'title-select', replace: 'select', default: '', isSelect: true },
  { match: 'url', default: '' },
  { match: 'us-usco-select', replace: 'select', default: '', isSelect: true },
  { match: 'watermark-select', replace: 'select', default: '', isSelect: true },
]

/**
 * @see PixsyForm
 * PixsyTextField is meant to be used within a PixsyForm provider
 * - It's only (very) required field is `name` which is used to set values in PixsyForm
 * - It's a wrapper for every type of input
 *  e.g:
 *    <PixsyTextField name="example1" />  ( default type = `text` )
 *    <PixsyTextField name="example2" type="watermark-select" />  ( a custom `select` type which contains pre-defined items )
 *    <PixsyTextField name="example3" type="country-select" />  ( ... )
 *
 *  selects:
 *    const items = [{ label, value, disabled }] // disabled is optional
 *    <PixsyTextField name="example4" type="select" items={items} />
 *
 *  validation:
 *    By default, if PixsyForm was provided a schema, PixsyTextField doesn't validate anything.
 *    The way an PixsyTextField `value` is validated is according to one of these properties:
 *
 *      validateOnChange - validate current `value` whenever it changes
 *      validateOnBlur - validate current `value` on blur
 *      validateOtherPaths - validate other paths whenever this field changes (validateOnChange | validateOnBlur)
 *        e.g:
 *          <PixsyTextField name="min" validateOnChange validateOtherPaths={[ 'max' ]} />
 *          <PixsyTextField name="max" validateOnChange validateOtherPaths={[ 'min' ]} />
 *        It's useful if the validation of one field depends on the value of another
 *
 *      validateEntireForm - alternatively, use to validate all values of all fields against the schema provider to PixsyForm
 *        - very expensive in development but it's ok in production
 *        - use instead of `validateOtherPaths` if there is complex validation of multiple fields validation that depends on many other fields
 *
 *      fieldContext - pass context to validation onBlur & onChange (when `validateOnChange` or `validateOnBlur`)
 *      fieldContextOnBlur - pass context to validation onBlur (when using `validateOnBlur`)
 *      fieldContextOnChange - pass context to validation onChange (when using `validateOnChange`)
 *        -> context is metadata passed to the validation schema used by PixsyForm
 *        -> context defined additional information that the validation tests may need in the schema
 *          e.g:
 *            Submission V6 form uses context to conditionally perform async validation on blur but not on change
 *            see `pixsy-schema/case/MatchSchema.js` how the context passed is used in the schema
 *
 *      debounceValidation - debounce validation on this field by X number of ms (when validateOnChange | validateOnBlur)
 *      debounceValidationOnChange - debounce validation on this field by X number of ms (when validateOnChange)
 *      debounceValidationOnBlur - debounce validation on this field by X number of ms (when validateOnBlur)
 *        -> use debounced validation if the validation in the schema contains expensive tests
 *        -> .. or preferability when using `validateEntireForm` and `validateOnChange` together
 *  Addons:
 *    leftAddon - displays a component inside the PixsyTextField as if it was part of the input on the left side
 *    rightAddon - displays a component inside the PixsyTextField as if it was part of the input on the right side
 *
 *  Dirty / Pristine Form
 *    keepPristine - by default, if a change happens on any field, the form is marked as dirty (isFormPristine: false)
 *                 however, use this if you may want for certain fields to be editable but not to make the form dirty
 */
export const PixsyTextField = React.memo(
  ({
    id,
    name,
    input,
    leftAddon: lAddon,
    multiline,
    multiple,
    rightAddon: rAddon,
    rows = 1,
    rowsMax,
    type = 'text',
    items,
    disabled,
    readOnly,
    placeholder,
    step,
    min,
    max,
    inputMode,
    label,
    validateOnChange = false,
    validateOnBlur = false,
    validateOtherPaths,
    validateEntireForm,
    fieldContext,
    fieldContextOnBlur,
    fieldContextOnChange,
    boderless,
    borderlessLeft,
    borderlessRight,
    borderlessTop,
    borderlessBottom,
    className,
    onChange,
    onBlur,
    onKeyPress,
    onKeyDown,
    onKeyUp,
    keepPristine = false,
    debounceValidation,
    debounceValidationOnChange,
    debounceValidationOnBlur,
  }) => {
    const api = useContext(PixsyFormContext)

    const inputType = useMemo(
      () => baseTypes.find(v => v.match === type) || baseTypes[0],
      [type]
    )
    const clearValue = useCallback(
      v => {
        if (v == null) return inputType.default
        return v
      },
      [inputType]
    )
    // const currentValue = clearValue(api.getValue(name))

    // const [v, setInnerValue] = useState(currentValue)

    // useEffect(() => {
    //   if (v !== currentValue) {
    //     // If values are manually set using the Form API
    //     setInnerValue(currentValue)
    //   }
    // }, [currentValue, name])

    const SelectProps = useMemo(
      () => ({
        multiline: !!multiline,
        disabled: !!disabled || !!readOnly,
        readOnly: !!readOnly,
        classes: selectClasses,
        native: true,
        // renderValue: v => v,
      }),
      [!!multiline, !!disabled, !!readOnly, !!api.isFormReadOnly]
    )
    const inputClasses = useMemo(
      () => ({
        root: cx(
          className,
          inputStyles,
          boderless && boderlessInputStyle,
          borderlessLeft && borderlessLeftInputStyle,
          borderlessRight && borderlessRightInputStyle,
          borderlessTop && borderlessTopInputStyle,
          borderlessBottom && borderlessBottomInputStyle
        ),
        input: inputElementStyle,
        focused: inputFocused,
      }),
      [
        !!boderless,
        !!borderlessLeft,
        !!borderlessRight,
        !!borderlessTop,
        !!borderlessBottom,
        className,
      ]
    )
    const InputProps = useMemo(
      () => ({
        classes: inputClasses,
        disabled: !!disabled || !!readOnly,
        readOnly: !!readOnly,
        startAdornment: lAddon ? <LeftAddon>{lAddon}</LeftAddon> : null,
        endAdornment: rAddon ? <RightAddon>{rAddon}</RightAddon> : null,
        inputComponent: type === 'date' ? PixsyDatePicker : undefined,
      }),
      [!!disabled, !!readOnly, lAddon, rAddon, !!api.isFormReadOnly]
    )
    const selectItems = useMemo(
      () =>
        getSelectItems(type) ||
        (type === 'select' && getDynamicSelectItems(items)) ||
        undefined,
      [type, items]
    )

    const contextOnChange = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnChange || {}),
      }),
      [fieldContext, fieldContextOnChange]
    )
    const contextOnBlur = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnBlur || {}),
      }),
      [fieldContext, fieldContextOnBlur]
    )

    const handleValidateOtherPaths = useCallback(
      ctx => {
        if (validateOtherPaths) {
          if (typeof validateOtherPaths === 'string') {
            api.handleValidationPath(validateOtherPaths, ctx)
          } else if (Array.isArray(validateOtherPaths)) {
            validateOtherPaths.forEach(otherName =>
              api.handleValidationPath(otherName, ctx)
            )
          }
        }
      },
      [validateOtherPaths, name]
    )

    const handleValidateCurrentPath = useCallback(
      ctx => {
        api.handleValidationPath(name, ctx)
        handleValidateOtherPaths(ctx)
      },
      [name, handleValidateOtherPaths]
    )

    const handleValidateOnChange = useMemo(() => {
      const validator = () => {
        if (validateOnChange) {
          if (validateEntireForm) {
            return api.validateForm(null, null, contextOnChange)
          }
          handleValidateCurrentPath(contextOnChange)
        }
      }
      if (isNumber(debounceValidation)) {
        return debounce(validator, debounceValidation)
      }
      if (isNumber(debounceValidationOnChange)) {
        return debounce(validator, debounceValidationOnChange)
      }
      return validator
    }, [
      name,
      debounceValidation,
      debounceValidationOnChange,
      validateOnChange,
      validateEntireForm,
      contextOnChange,
    ])

    const handleValidateOnBlur = useMemo(() => {
      const validator = () => {
        if (validateOnBlur) {
          if (validateEntireForm) {
            return api.validateForm(null, null, contextOnBlur)
          }
          handleValidateCurrentPath(contextOnBlur)
        }
      }
      if (isNumber(debounceValidation)) {
        return debounce(validator, debounceValidation)
      }
      if (isNumber(debounceValidationOnBlur)) {
        return debounce(validator, debounceValidationOnBlur)
      }
      return validator
    }, [
      name,
      debounceValidation,
      debounceValidationOnBlur,
      validateOnChange,
      validateEntireForm,
      contextOnBlur,
    ])

    const handleOnChange = useCallback(
      e => {
        const raw = e.target.value
        const val =
          type === 'number'
            ? Number.isNaN(Number(raw)) || raw === ''
              ? null
              : Number(raw)
            : String(raw == null ? '' : raw)

        // setInnerValue(val)
        api.handleChange(name, val, keepPristine)

        if (isFunction(onChange)) onChange(e)

        handleValidateOnChange()
      },
      [name, keepPristine, onChange, handleValidateOnChange]
    )

    const handleOnBlur = useCallback(() => {
      api.notifyListenersOnBlurEvent(name)
      api.setFieldTouched(name, true)

      if (isFunction(onBlur)) onBlur()

      handleValidateOnBlur()
    }, [name, onBlur, handleValidateOnBlur])

    // https://next.material-ui.com/api/text-field/

    return (
      <TextField
        css={baseInputStyle}
        disabled={!!disabled || api.isFormReadOnly}
        id={id}
        input={input}
        InputLabelProps={InputLabelProps}
        InputProps={InputProps}
        multiline={!!multiline}
        multiple={!!multiple}
        placeholder={placeholder}
        step={step}
        min={min}
        max={max}
        inputMode={inputMode}
        label={label}
        name={name}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        readOnly={!!(inputType.isReadOnly || disabled || readOnly)}
        rows={rows}
        rowsMax={rowsMax}
        select={inputType.isSelect}
        SelectProps={SelectProps}
        type={inputType.replace || inputType.match}
        value={clearValue(api.getValue(name))}
        // value={clearValue(currentValue)}
        variant="standard"
        fullWidth
      >
        {selectItems}
      </TextField>
    )
  }
)
PixsyTextField.propTypes = {
  name: propTypes.string.isRequired,
  id: propTypes.string,
  input: propTypes.any,
  leftAddon: propTypes.any,
  multiline: propTypes.bool,
  multiple: propTypes.bool,
  rightAddon: propTypes.any,
  rows: propTypes.number,
  rowsMax: propTypes.number,
  type: propTypes.string,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  value: propTypes.any,
  disabled: propTypes.bool,
  items: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.any.isRequired,
      label: propTypes.any.isRequired,
      disabled: propTypes.boolean,
    })
  ),
}

const getSelectItems = type => {
  switch (type) {
    case 'country-select':
      return CountryMenuItems()
    case 'watermark-select':
      return WatermarkItems()
    case 'cc-select':
      return CreativeCommonsItems()
    case 'us-usco-select':
      return USCopyrightOfficeItems()
    case 'currency-select':
      return CurrenciesItems()
    case 'price-negotiations-select':
      return PriceNegotiations()
    case 'title-select':
      return TitleMenuItems()
    default:
      return
  }
}
const getDynamicSelectItems = items => {
  if (Array.isArray(items)) {
    return items.map(({ label, value, disabled }) => (
      <PixsyMenuItem key={value} value={value} disabled={disabled}>
        {label}
      </PixsyMenuItem>
    ))
  }
}

/** If default label is used in input */
const labelRootStyles = css`
  color: #707070;
  padding: 0 10px !important;
  transform: translate(0, 20px) scale(1) !important;
`
const labelShrinkStyles = css`
  transform: translate(-9px, -5px) scale(0.9) !important;
  z-index: 1;
`
const labelFocusedStyles = css`
  color: #434343 !important;
`
const inputLabelClasses = {
  root: labelRootStyles,
  shrink: labelShrinkStyles,
  focused: labelFocusedStyles,
}

/** Outlined box around input */
const baseInputStyle = css`
  > div {
    font-size: 14px;
    font-family: inherit;
    line-height: inherit;
    color: #434343;
    input,
    select {
      padding: 0 10px;
      height: 22px;
      min-height: 22px;
      max-height: 22px;
    }
    select {
      line-height: 14px;
      width: calc(100% - 30px);
      height: 22px;
      min-height: 22px;
      max-height: 22px;
      padding: 0 10px !important;
    }
    textarea {
      padding: 0 10px;
    }
  }
  label {
    font-size: 14px;
    font-family: inherit;
    line-height: inherit;
    font-weight: 400;
  }
`
const inputFocused = css`
  border: 1px solid #148aa9 !important;
  /* border-color: #148aa9 !important; */
`
const boderlessInputStyle = css`
  /* border: none !important; */
  border-color: transparent;
`
const borderlessLeftInputStyle = css`
  /* border-left: none !important; */
  border-left-color: transparent;
`
const borderlessRightInputStyle = css`
  /* border-right: none !important; */
  border-right-color: transparent;
`
const borderlessTopInputStyle = css`
  /* border-top: none !important; */
  border-top-color: transparent;
`
const borderlessBottomInputStyle = css`
  /* border-bottom: none !important; */
  border-bottom-color: transparent;
`

const inputStyles = css`
  padding: 4px 0px;
  border: 1px solid #e6e6e6;
  &::after,
  &::before {
    display: none;
  }
`
const inputElementStyle = css`
  ::placeholder {
    color: #939393 !important;
    opacity: 1 !important;
  }
`

/** when type="select" */
const rootSelectBoxStyle = css`
  padding: 0 !important;
`
const selectBoxStyle = css`
  padding: 0 24px 0 0px !important;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  background: transparent !important;
  line-height: 22px !important;
`
// const selectMenuPaper = css`
//   border-radius: 0 !important;
//   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11) !important;
//   border: 1px solid #148aa9 !important;
// `

const selectClasses = {
  root: rootSelectBoxStyle,
  select: selectBoxStyle,
  icon: css({
    fontSize: '1.5em !important',
  }),
}
// const selectMenuProps = {
//   className: rootSelectBoxStyle,
//   classes: {
//     paper: selectMenuPaper,
//   },
// }
const InputLabelProps = { classes: inputLabelClasses }
