import React from 'react'
import styled from 'react-emotion'

const StyledDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default function FooterWrap(props) {
  return <StyledDiv>{props.children}</StyledDiv>
}
