import * as React from 'react'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import { PixsyGrid } from '../PixsyGrid/PixsyGrid'
import { PixsyGridItem } from '../PixsyGrid/PixsyGridItem'
import 'react-datepicker/dist/react-datepicker.css'
import { injectGlobal, css, cx } from 'react-emotion'

injectGlobal`
  .react-datepicker {
    border-radius: 0;
    border: 1px solid #c7c7c7;
  }
  .react-datepicker__header {
    background-color: #fcfcfc;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #148aa9;
  }

  .react-datepicker__navigation--years-upcoming {
    top: -4px !important;
    border-bottom-color: #ccc !important;
  }

  .react-datepicker__navigation--years-previous {
    top: 4px !important;
    border-top-color: #ccc !important;
  }
`

export class PixsyDatePicker extends React.PureComponent {
  handleChange = (date, event) => {
    const { onChange } = this.props

    if (onChange) {
      const vEvent = {
        target: {
          value: moment(date).isValid() ? moment(date).format() : null,
        },
      }
      onChange(vEvent)
    }
  }
  handleBlur = event => {
    const { onBlur } = this.props
    onBlur()
  }
  handleChangeRaw = e => {
    e.preventDefault()
  }

  render() {
    const {
      value,
      datePickerProps = {},
      inputRef,
      name,
      disabled,
      onFocus,
      className,
      placeholder,
    } = this.props

    const selected = !value
      ? null
      : value._isAMomentObject
      ? value
      : moment.utc(correctInputVal(value))
    const maxDate = moment.utc()
    const minDate = moment.utc().subtract(100, 'years')

    return (
      <PixsyGrid wrap="nowrap" style={{ height: '22px' }}>
        <PixsyGridItem flexGrow={1}>
          <div css={datePickerStyle}>
            <ReactDatePicker
              ref={ref => {
                this.inputRef = ref
                inputRef(ref ? ref.inputElement : null)
              }}
              name={name}
              className={cx(className)}
              selected={selected}
              onChange={this.handleChange}
              onChangeRaw={this.handleChangeRaw}
              onFocus={onFocus}
              onBlur={this.handleBlur}
              minDate={minDate}
              maxDate={maxDate}
              placeholderText={placeholder}
              dateFormat="LL"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              disabled={disabled}
              {...datePickerProps}
            />
          </div>
        </PixsyGridItem>
        <PixsyGridItem className={iconStyle}>
          <CalendarIcon fontSize="inherit" />
        </PixsyGridItem>
      </PixsyGrid>
    )
  }
}

const datePickerStyle = css`
  width: 100%;

  input,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  input {
    padding-right: 26px;
  }
`
const iconStyle = css`
  width: 22px;
  min-width: 22px;
  max-width: 22px;
  display: flex;
  color: #909090;
  position: absolute;
  right: 10px;
  pointer-events: none;
  font-size: 1.5em;
`

// Firefox is unable to parse GMT dates. See DEV-474
function correctInputVal(inputVal) {
  try {
    const parsed = moment
      .utc(inputVal)
      .format('LL')
      .toLowerCase()
    const lower = inputVal.toLowerCase()
    if (parsed === 'invalid date' && lower.endsWith('gmt')) {
      return lower.replace('gmt', '').trim()
    }
  } catch (err) {}

  return inputVal
}
