// 3. Introduction to the case submission process
import * as React from 'react'
import { css } from 'react-emotion'
import { SexySeparator } from 'common'
import Flags from './Flags'
import { PixsyPaper, PixsyGrid, PixsyGridItem } from '../../../Components'

export const OverviewStage = React.memo(
  () => (
    <React.Fragment>
      <PixsyPaper padding="30px 60px 30px 60px">
        <PixsyGrid direction="column" spacing={3} style={wrapper}>
          <SubmissionExplanation />
          <SexySeparator space={10} />
          <TimelineExplanation />
          <SexySeparator space={10} />
          <CountriesExplanation />
        </PixsyGrid>
      </PixsyPaper>
    </React.Fragment>
  ),
  () => true
)

const SubmissionExplanation = () => (
  <PixsyGridItem>
    <h3>Pixsy Case Resolution Service</h3>
    <div css={intro}>
      Our expert team of copyright case managers and legal partners handle every
      part of the case for you. We fight to recover your compensation from
      businesses using your images. To date Pixsy has handled over 100,000 cases
      worldwide, so you are in safe hands.
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.pixsy.com/resolve" target="_blank">
        Learn more
      </a>
    </div>
  </PixsyGridItem>
)

const STEPS = [
  {
    title: 'Submission',
    text: `Providing us all the key information upfront, means we have everything
      we need to manage your case smoothly and efficiently.
    `,
  },
  {
    title: 'Preparation',
    text: `Your case is assigned to a dedicated Case Manager who prepares everything
    to initiate the case and contacts the image user.
    `,
  },
  {
    title: 'Pursuit',
    text: `Pixsy and our legal partners handle all communications, negotiations,
      and settlements to attempt to bring your case to a successful
      resolution.
    `,
  },
  {
    title: 'Payment',
    text: `Once Pixsy is in receipt of the funds, payment is remitted to your bank or PayPal
    within 14-21 days anywhere in the world.
    `,
  },
]

const CaseStep = ({ title, text, index }) => (
  <React.Fragment>
    <div css={caseStepWrapper}>
      <div css={stepNumber}>{index + 1}</div>
      <h4>{title}</h4>
    </div>
    <p css={stepDescription}>{text}</p>
  </React.Fragment>
)

const TimelineExplanation = () => (
  <PixsyGridItem>
    <h3>Case Overview</h3>
    <p>From submission to payment in four easy steps:</p>
    <PixsyGrid spacing={2}>
      {STEPS.map((step, index) => (
        <PixsyGridItem key={step.title} xs={12} md={3}>
          <CaseStep {...step} index={index} />
        </PixsyGridItem>
      ))}
    </PixsyGrid>
  </PixsyGridItem>
)

const CountriesExplanation = () => (
  <PixsyGridItem css={countriesSection}>
    <h3>Supported Countries</h3>
    <p>
      Pixsy operates in the following countries, based on where the website
      owner (business) is legally operating
    </p>
    <Flags />
    <ul>
      <li>We add new countries every few months...</li>
    </ul>
  </PixsyGridItem>
)

const pixsyBlue = '#008aab'

const wrapper = css`
  h3 {
    font-size: 2.25em;
    font-weight: 300;
    margin: 10px 0 5px 0;
  }
  p {
    margin: 0 0 10px 0;
  }
`

const intro = css`
  text-align: justify;
  display: flex;
  justify-content: space-around;
  position: relative;
  font-size: 1.05em;
  font-weight: 300;
  a {
    font-size: 14px;
    display: inline-block;
    padding-left: 10px;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`
const caseStepWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 14px 0 8px 0px;
  font-size: 1.1em;
  text-align: center;
  line-height: 1.2em;
  height: 22px;
  h4 {
    margin-left: 2px;
    font-weight: 400;
    font-size: 1.1em;
  }
  align-items: center;
`

const stepNumber = css`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  color: white;
  background-color: ${pixsyBlue};
  margin: 0 4px 0 0;
  line-height: 1.6em;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const stepDescription = css`
  margin: 0;
  font-size: 1.05em;
  font-weight: 300;
  line-height: 1.5em;
`

const countriesSection = css`
  p {
    margin-bottom: 20px;
    font-size: 0.9em;
  }
  ul {
    padding-left: 0;
    margin-top: 15px;
    font-size: 0.9em;

    li {
      list-style: none;

      &::before {
        color: #008aab;

        content: '•';
        /* Unicode of character to precede the list item */

        font-size: 0.9em;
        /* em or %*/

        padding-right: 0.45em;
        /* use em*/

        position: relative;
        top: 0em;
        /* use em or % */ /* color of bullet or square */
      }
    }
  }
`
