import * as React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { DATA_ELEVIO } from 'pixsy-constants'

const ViewToolbar = ({ children }) => (
  <div
    css={viewToolbarContainer}
    data-elevio-selector={DATA_ELEVIO.MATCHES_VIEW_SELECTOR}
  >
    <span>View</span>
    <div css={toolbar}>{children}</div>
  </div>
)

const ToolbarButton = ({ icon, active, onClick, title }) => (
  <button
    onClick={onClick}
    className={cx(toolbarBtn, active && toolbarBtnActive)}
    title={title}
  >
    {icon}
  </button>
)
ToolbarButton.propTypes = {
  icon: PropTypes.element.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
}

ViewToolbar.Button = ToolbarButton
export default ViewToolbar

const viewToolbarContainer = css`
  margin-bottom: 15px;
  margin-top: 5x;
  display: flex;
  justify-content: center;

  @media (max-width: 576px) {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .elevioTooltip {
      > div {
        top: 22px !important;
      }
    }
  }

  > span {
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 13px;
    line-height: 25px;
    margin-right: 5px;
    color: rgba(147, 147, 147, 0.3);
  }
`
const toolbar = css`
  &:last-of-type {
    border-right: none !important;
  }
`
const toolbarBtn = css`
  height: 23.98px;
  width: 28px;
  background-color: transparent;
  border: none;
  outline: none;
  border-right: 1px solid #e6e6e6;
  padding: 0 5px;

  svg {
    color: rgba(112, 112, 112, 0.3);
  }

  &:last-of-type {
    border-right: none;
  }
`
const toolbarBtnActive = css`
  svg {
    color: rgba(41, 41, 41, 0.789);
  }
`
