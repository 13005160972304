import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { GridWrappingColumn, StickyFloater, Dialog, ExitPrompt } from 'common'
import { Link } from 'react-router-dom'
import UppyImport from './Import/UppyImport'
import UppyExitPrompt from './UppyExitPrompt'

class UppyImportContainer extends Component {
  renderExitPrompt({ isOpen, onCancel, onConfirm }) {
    return (
      <Dialog isOpen={isOpen} onRequestClose={onCancel}>
        <UppyExitPrompt onCancel={onCancel} onNavigate={onConfirm} />
      </Dialog>
    )
  }

  render() {
    const { uploading } = this.props
    return (
      <div>
        <ExitPrompt when={uploading} render={this.renderExitPrompt} />
        <GridWrappingColumn maxWidth={1040}>
          <Helmet title="Import Files" />
          <StickyFloater>
            <Link to="/images/import" className="sticky-floater--back-button" />
            <span className="sticky-floater--left-aligned-description">
              <b>Import Images from Computer</b>
            </span>
          </StickyFloater>

          <UppyImport {...this.props} />
        </GridWrappingColumn>
      </div>
    )
  }
}

export default connect(state => ({
  uploading: state.images.uploading,
}))(UppyImportContainer)
