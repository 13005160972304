import React from 'react'
import { Table } from 'common'
import CircularProgress from '@material-ui/core/CircularProgress'
import { css, cx } from 'react-emotion'
import { currency_symbols, epochToDate } from '../../../../helpers/date'


const Charges = ({ charges ={} }) => {
  const renderColumn = () => {
    return  [
      { id: 'date', label: 'DATE', minWidth: 100 },
      { id: 'status', label: 'STATUS', minWidth: 100, },
      { id: 'amount', label: 'AMOUNT', minWidth: 100 },
      {
        id: 'link',
        label: 'LINK',
        minWidth: 100,
        align: 'center'
      }
    ]
  }

  const colorCodes = {
    'Pending Review': '#D39A1C',
    'Action Needed': '#008AAB',
    'Not Accepted': '#FD8282',
    Merged: '#FD8282',
    Discontinued: '#FD8282',
    'paid': '#28BF45',
    'failed': '#FD8282',
    Canceled: '#FD8282',
  }

  const transformCharge = (result) => {
    let data = [];
    data = result.map(d => {
      return {
        date: epochToDate(d.created),
        status: <div
        style={{
          backgroundColor:
            (colorCodes[d.status] || '#D39A1C') + '50',
          padding: '5px 10px',
          borderRadius: 4,
          display: 'inline-block',
        }}
      >

        <span style={{ textTransform: 'capitalize' }}>{d.status || 'Submitted'}</span>
      </div>,
        amount: (d.total && d.currency ? currency_symbols[d.currency.toUpperCase()] : '') + parseFloat(d.total/100).toFixed(2),
        link: (d.invoice_pdf || d.hosted_invoice_url) ? <div> {d.hosted_invoice_url && <a href={ d.hosted_invoice_url } target="_blank"> View / </a>}
        {d.invoice_pdf && <a href={ d.invoice_pdf } target="_blank"> Download </a>}
        </div> : '-'
      }
    })
    return data
  }

  const renderRows = () => {
    const { result } = charges
    return transformCharge(result)
  }

  const checkForData = () => {
    const { result } = charges
    return result && result.length > 0
  }

  const checkForLoading = () => {
    const { loading, loaded } = charges
    return loading && !loaded
  }

  return (
    <div css={chargeParent}>
        <h1>Receipts</h1>
        {checkForLoading() && <CircularProgress
            thickness={5}
            css={circularProgressStyle}
            size={'5rem'}
            variant={ 'indeterminate'}
        />}
        {checkForData() ? 
          <Table columns={renderColumn()}  rows={renderRows()}/>
          : <div>No Receipts</div>
        }
    </div>
  )

}

const chargeParent = css`
  text-align: center;  
`

const circularProgressStyle = css`
  color: #212121 !important;
  margin: 5px 0;
  display: 'inline-block';
`

export default Charges