import React from 'react'
import { PaginationFooter } from 'common'
import sharedStyles from '../../../components/styles'

const ClustersFooter = ({ pageOptionProps }) => {
  const {
    sort,
    onSortChange,
    onPageChange,
    onPerPageChange,
    ...paginationOptions
  } = pageOptionProps

  return (
    <PaginationFooter
      className={sharedStyles.paginationFooter}
      marginPagesDisplayed={2}
      size="big"
      singularType="result"
      pluralType="results"
      onPageChange={onPageChange}
      onPerPageChange={onPerPageChange}
      paginationProps={paginationOptions}
      isRenderWhenOnePage
    />
  )
}

export default ClustersFooter
