import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import { css, cx } from 'react-emotion'
import Label from './Label'
import Feedback from './Feedback'

export function RadioBar(props) {
  let { input, label, name, items, meta, locked, addon } = props

  return (
    <FormRow {...props}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
        <Label vertical title={label} />
        {locked ? (
          <p css={lockedValue}>{props.input.value}</p>
        ) : (
          <React.Fragment>
            <ul css={list}>
              {items.map((item, index) => (
                <li key={item.value}>
                  <input
                    onChange={e => input.onChange(e) && input.onBlur(e)}
                    checked={input.value === item.value}
                    name={name}
                    type="radio"
                    id={`${input.name}-${index}`}
                    value={item.value}
                  />
                  <label htmlFor={`${input.name}-${index}`}>{item.label}</label>
                </li>
              ))}
            </ul>

            <Feedback meta={meta} />
          </React.Fragment>
        )}

        {addon && addon({ name: input.name })}
      </div>
    </FormRow>
  )
}

const wrap = css`
  width: 100%;

  display: flex;
  align-items: stretch;
`

const stylesUnlocked = css`
  padding: 10px 0;
  flex-direction: column;
`

const stylesLocked = css`
  opacity: 0.5;
  justify-content: space-between;
  p {
    margin: 0;
  }
`

const lockedValue = css`
  font-size: 1.1em;
  font-weight: 500;
  flex-basis: 100px;
  text-align: center;
  line-height: 1.8;
`

const list = css`
  display: inline-flex;
  padding: 0;
  border-right: 0;
  margin-top: 13px;
  margin-bottom: 5px;

  li {
    position: relative;
    height: 56px;
    min-width: 150px;
    max-width: 170px;
    display: inline-block;
    border-right: 1px solid #777777;
    line-height: 1.6em;
  }

  input[type='radio'] {
    outline: 1px solid #d3d3d3 !important;
    display: block;
    appearance: none;

    outline-offset: 0 !important;
    border: 0 !important;
    &:focus {
    }

    &:checked + label {
      background: #148aa9; /* $action; */
      color: #fff;
      outline: 1px solid #148aa9;
    }
  }

  label,
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  label {
    text-align: center;
    padding-top: 8px;
    cursor: pointer;
    font-weight: 400 !important;
    font-size: 1.1em !important;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    display: block;

    li {
      min-width: 49%;
      max-width: 49%;
      min-height: 84px;
    }
  }
`

export default RadioBar

RadioBar.propTypes = {
  label: PropTypes.string.isRequired,
}
