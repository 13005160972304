import * as React from 'react'
import { css } from 'react-emotion'

const leftArrowStyle = css`
  fill: none;
  stroke: #707070;
  stroke-width: 2px;
`

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.879 16.93">
    <path
      id="Path_687"
      data-name="Path 687"
      css={leftArrowStyle}
      d="M-18145.607,2480.949l-7.758,7.758,7.758,7.758"
      transform="translate(18154.779 -2480.242)"
    />
  </svg>
)
