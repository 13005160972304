import React from 'react'
import PropTypes from 'prop-types'
import { cx, css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { Button } from 'common'

const wrap = css`
  text-align: center;
  margin: 20px 0 30px;

  span {
    font-size: 1em;
    color: #3b3b3b80;
    b {
      color: #3b3b3b;
    }
  }

  h3 {
    font-size: 22px;
    letter-spacing: 2px;
    margin: 2px 0 10px;
  }
`

const button = css`
  margin-top: 10px;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`

export default function MonitoringQuotaExceeded({ untrackedImagesCount }) {
  return (
    <div css={cx(wrap)}>
      <h3>MONITORING QUOTA EXCEEDED&nbsp;</h3>
      <span>
        <b>{`${Number(untrackedImagesCount).toLocaleString()} images`}</b>
        &nbsp; are not currently monitored. Upgrade your plan to start
        monitoring more images.
      </span>
      <br />
      <Link to="/profile/plans">
        <Button orange css={button}>
          Upgrade plan
        </Button>
      </Link>
    </div>
  )
}

MonitoringQuotaExceeded.propTypes = {
  untrackedImagesCount: PropTypes.number,
}
