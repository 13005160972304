import React, { Component } from 'react'
import { css, injectGlobal } from 'react-emotion'
import UppyModal from './layout/UppyModal'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'

export default class UppyDashboard extends Component {
  constructor(props) {
    super(props)
    this.modalContainer = React.createRef()
  }

  render() {
    return (
      <div onClick={e => e.stopPropagation()} className={pixsyUppyStyle}>
        <UppyModal
          ref={el => (this.modalContainer = el)}
          modalOpen={this.props.modalOpen}
        />
        {this.props.children}
      </div>
    )
  }
}

const pixsyBlue = '#148aa9'

export const pixsyUppyStyle = css`
  @media (max-width: 768px) {
    .uppy-DashboardContent-bar,
    .uppy-DashboardItem-action,
    .uppy-StatusBar-actions,
    .uppy-StatusBar {
      z-index: 10 !important;
    }
  }
  .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
    background-color: ${pixsyBlue} !important;
    &:hover {
      background-color: ${pixsyBlue} !important;
    }
  }
  .uppy-DashboardContent-back {
    color: ${pixsyBlue} !important;
  }
  .UppyIcon {
    fill: ${pixsyBlue} !important;
  }
  .uppy-DashboardItem.is-complete .progress-group {
    .progress {
      fill: ${pixsyBlue} !important;
      stroke: ${pixsyBlue} !important;
    }
  }
  .uppy-StatusBar.is-complete .uppy-StatusBar-progress {
    background-color: ${pixsyBlue} !important;
  }
  .uppy-Dashboard-innerWrap {
    z-index: 99;
  }
`

injectGlobal`
.uppy-Dashboard-poweredBy {
    display: none !Important;
  }
`
