import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextFieldVertical, ChooseBestImageSource } from 'common'
import { DATA_ELEVIO, getProxyUrlToMatch } from 'pixsy-constants'
import { Field } from 'redux-form'

import styled, { css } from 'react-emotion'

const ImageHolder = styled.div`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  height: 40%;
  position: relative;
  display: block;
  box-sizing: border-box;
  background: url("${props => props.image}") center center no-repeat;
  background-size: contain;
`

export default class TakedownFormImageComparison extends PureComponent {
  render() {
    const { image, index, cluster } = this.props
    const copyrightRegistrationLocked =
      image.registrations && image.registrations.length > 0

    const matchIndex = cluster.matches.findIndex(
      m => image._id === (m.image._id || m.image)
    )
    if (matchIndex === -1) return null

    return (
      <div css={wrap}>
        <div css={flex}>
          <div css={half}>
            <h4>Original</h4>
            <ChooseBestImageSource candidates={[image.url]} type="image">
              {({ url }) => <ImageHolder image={url} />}
            </ChooseBestImageSource>
            <Field
              name={`images[${index}].title`}
              label="Title"
              type="text"
              component={TextFieldVertical}
              disabled={copyrightRegistrationLocked}
            />
            {copyrightRegistrationLocked && (
              <p css={hotlinkingNote}>
                This information is locked as it is linked to a copyright
                registration and cannot be edited
              </p>
            )}
            <Field
              name={`images[${index}].licensing.first_published.place`}
              label="Published Location (URL)"
              labelProps={{
                'data-elevio-selector': DATA_ELEVIO.TAKEDOWN_PUBLISHED_LOCATION,
              }}
              type="text"
              component={TextFieldVertical}
            />
          </div>
          <div css={half}>
            <h4>Infringement</h4>
            <ChooseBestImageSource
              candidates={[
                cluster.matches[matchIndex].url,
                getProxyUrlToMatch(cluster.matches[matchIndex]._id),
              ]}
              type="match"
              match={cluster.matches[matchIndex]}
            >
              {({ url }) => <ImageHolder image={url} />}
            </ChooseBestImageSource>
            <Field
              name={`matches[${matchIndex}].url`}
              label="Image URL"
              component={TextFieldVertical}
            />
            <Field
              name={`matches[${matchIndex}].origin.url`}
              label="Page URL"
              component={TextFieldVertical}
            />
          </div>
        </div>
      </div>
    )
  }
}

TakedownFormImageComparison.propTypes = {
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  cluster: PropTypes.object.isRequired,
}

const wrap = css`
  display: flex;
  flex-direction: column;
`
const flex = css`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const half = css`
  flex-basis: 50%;
  text-align: center;
  height: 500px;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }

  h4 {
    color: #43434380;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 1em;
    text-transform: uppercase;
    margin: 0px auto 15px;
  }

  input {
    text-overflow: ellipsis;
    padding-right: 25px;
  }

  &:nth-of-type(1) {
    border-right: 1px solid #e3e3e366;
  }
`

const hotlinkingNote = css`
  color: #3b3b3bbf;
  font-size: 0.9em;
  text-align: center;
  margin: 0;
`
