import getIn from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isMatch from 'lodash/isMatch'
import urijs from 'urijs'
import {
  objectWithKeysAsValues,
  prefixConstEnumValues,
  QArray,
  QBoolean,
  QDefineConstEnums,
  QEnumString,
  QObjectID,
  QSchema,
  QString,
} from '../core'

export const WATERMARK_ENUM = QDefineConstEnums(
  'Removed',
  'Added',
  'Replaced',
  'Unchanged'
)

export const MATCH_KEYS = objectWithKeysAsValues({
  uuid: 'uuid',
  __ADDITIONAL_LINK__: '__ADDITIONAL_LINK__',
  _id: '_id',
  additional_links: 'additional_links',
  url: 'url',
  attribution_provided: 'attribution_provided',
  origin: 'origin',
  watermark: 'watermark',
})
export const MATCH_ORIGIN_KEYS = objectWithKeysAsValues({
  url: 'url',
  domain: 'domain',
  title: 'title',
})

export const MATCH_NAMES = {
  ...MATCH_KEYS,
  [MATCH_KEYS.origin]: prefixConstEnumValues(
    MATCH_KEYS.origin,
    MATCH_ORIGIN_KEYS
  ),
}

export const MATCH_LABEL = {
  [MATCH_NAMES.attribution_provided]: `Did the image user provide any attribution or otherwise credit you?`,
  [MATCH_NAMES.url]: `Image file location`,
  [MATCH_NAMES.__ADDITIONAL_LINK__]: `Additional pages displaying this image`,
  [MATCH_NAMES.additional_links]: `Additional pages showing this image`,
  [MATCH_NAMES.watermark]: `Did the image user add or remove a watermark?`,
  [MATCH_NAMES.origin.url]: `Infringing webpage`,
}

export const MATCH_REQUIRED = {
  [MATCH_NAMES._id]: false,
  [MATCH_NAMES.attribution_provided]: true,
  [MATCH_NAMES.url]: true,
  [MATCH_NAMES.__ADDITIONAL_LINK__]: false,
  [MATCH_NAMES.additional_links]: false,
  [MATCH_NAMES.watermark]: true,
  [MATCH_NAMES.origin.url]: true,
}

export const MATCH_INFO = {
  [MATCH_NAMES.origin.url]: {
    is_not_url: {
      title: `You must provide a valid URL starting with http:// or https://`,
    },
  },
  [MATCH_NAMES.url]: {
    title: `You must provide a valid URL starting with http:// or https://`,
  },
  [MATCH_NAMES.attribution_provided]: {
    title: `Very few legal precedents have been set in defining specific Creative Commons license terms.`,
    subtitle: `Until these terms have been legally defined, we are not able to accept case submissions where a good-faith effort has been made to abide by your CC license terms.`,
  },
  [MATCH_NAMES.__ADDITIONAL_LINK__]: {
    title: `You must provide a valid URL starting with http:// or https://`,
  },
  [MATCH_NAMES.watermark]: {
    title:
      'Please choose if the image user added or removed a watermark on your image',
  },
}

// Tests
export const FAIL_ON_FALSE = optional => value => {
  return value === false || (optional && value == null)
}

const handleAsyncResult = (test, value, ignoredErrors) => res => {
  if (isPlainObject(res) && isArray(res.payload) && isArray(ignoredErrors)) {
    const isResolvable = res.payload.every(v => v.resolvable)

    if (isResolvable) return true

    const nextPayload = res.payload.filter(e => {
      if (isPlainObject(e) && isPlainObject(e.data) && isString(e.data.type)) {
        const ignoredError = ignoredErrors.find(i => isMatch(i, e.data))

        if (ignoredError) return false

        return true
      }
      return false
    })

    if (isEmpty(nextPayload)) return true

    const [firstPayload] = nextPayload

    return test.createError({
      message: {
        payload: nextPayload,
        asyncError: true,
        warning: firstPayload && firstPayload.reason,
        data: firstPayload && firstPayload.data,
      },
      path: test.path,
    })
  }
}

const handleAsyncError = err => {
  console.warn('[AsyncValidation][Err]', err)
  return true
}

// prettier-ignore
export const MATCH_ORIGIN_TYPES = {
  [MATCH_ORIGIN_KEYS.domain]: QObjectID,
  [MATCH_ORIGIN_KEYS.title]: QString,
  [MATCH_ORIGIN_KEYS.url]: QString
  .required({
    title: 'You must provide the URL where the match was found.'
  })
  .default('')
    .url(MATCH_INFO[MATCH_NAMES.origin.url].is_not_url)
    
    .test('is-domain-name-valid', null, function isDomainNameCorrect(value) {
      const ctx = this.options.context

      if (
        isString(value) &&
        isPlainObject(ctx) &&
        isFunction(ctx.getDomain) &&
        QString.url().required().isValidSync(value)
      ) {
        const domainName = ctx.getDomain()

        if (
          !isString(domainName) ||
          isEmpty(domainName)
        ) return true

        try {
          const domain = urijs(value).domain()

          if (domain.toLowerCase() === domainName.toLowerCase()) return true

          return this.createError({
            message: {
              title: 'Please provide an infringing page on',
              subtitle: domainName
            },
            path: this.path
          })
        } catch(e) {}
      }

      return true
    })
    .test('async-checks-is-resolvable', null, function isOnline(value) {
      const ctx = this.options.context
    
      if (
        isPlainObject(ctx) &&
        isFunction(ctx.validator) &&
        isFunction(ctx.getDomain) &&
        isFunction(ctx.getAsyncIgnoredError) &&
        QString.url(MATCH_INFO[MATCH_NAMES.origin.url].is_not_url).required().isValidSync(value)
      ) {
        const domainName = ctx.getDomain()
        if (
          !isString(domainName) ||
          isEmpty(domainName)
        ) return true

        try {
          const domain = urijs(value).domain()

          if (domain.toLowerCase() !== domainName.toLowerCase()) return true
        } catch(e) {}

        const submissionData = ctx.values || {}
        const matches = submissionData.matches || []
        const match = matches.find(m => m.origin && m.origin.url === value)
        const matchId = match && match._id
        const images = submissionData.images || []
        const imageIds = images.map(i => i._id)

        return ctx.validator('match.origin.url', {
          host: domainName,
          matchOriginUrl: value,
          caseId: ctx.caseId,
          matchId,
          imageIds,
        })
          .then(handleAsyncResult(this, value, ctx.getAsyncIgnoredError()))
          .catch(handleAsyncError)
      }

      return true
    })
}
export const MatchOriginSchema = QSchema.shape(MATCH_ORIGIN_TYPES)

// prettier-ignore
export const MATCH_TYPES = {
  [MATCH_KEYS.uuid]: QString.strip(true),
  [MATCH_KEYS._id]: QObjectID,
  [MATCH_KEYS.attribution_provided]: QBoolean.test( // Optional require (based on image.licensing.cc)
    'no false attribution',
    MATCH_INFO[MATCH_NAMES.attribution_provided],
    function didUserProvideAttribution(value) {
      const ctx = this.options.context

      if (
        isPlainObject(ctx) &&
        isNumber(ctx.index) &&
        isPlainObject(ctx.values) &&
        isArray(ctx.values.images)
      ) {
        const ccName = `images[${ctx.index}].licensing.cc`
        const underCC = getIn(ctx.values, ccName)

        const ccType = `images[${ctx.index}].licensing.license`
        const ccTypeValue = getIn(ctx.values, ccType)

        if (['CC BY-NC', 'CC BY-NC-SA', 'CC BY-NC-ND'].includes(ccTypeValue)) {
          return true
        }

        if (!underCC) return true

        if (value == null) {
          return this.createError({
            path: this.path,
            message: {
              title: 'Please choose if the image user provided attribution to you or the image'
            },
          })
        }

        return value === false
      }
      return true
    }
  ),
  // [MATCH_KEYS.url]: QString.url(MATCH_INFO[MATCH_NAMES.url]).required(MATCH_INFO[MATCH_NAMES.url]),
  [MATCH_KEYS.url]: QString.required(MATCH_INFO[MATCH_NAMES.url]).test('is-valid-url', MATCH_INFO[MATCH_NAMES.url], function(value) {
    try {
      const url = new URL(value)
      return !!url.protocol
    } catch (err) {
      return false
    }
  }),
  // .test('async-checks-is-resolvable', null, function isOnline(value) {
  //     const ctx = this.options.context

  //     if (
  //       isPlainObject(ctx) &&
  //       isFunction(ctx.validator) &&
  //       QString.url().required().isValidSync(value)
  //     ) {
  //       return ctx.validator('match.url', {
  //         matchUrl: value,
  //       })
  //         .then(handleAsyncResult(this, value, ctx.getAsyncIgnoredError()))
  //         .catch(handleAsyncError)
  //     }

  //     return true
  //   }),
  [MATCH_KEYS.__ADDITIONAL_LINK__]: QString.url(
    MATCH_INFO[MATCH_NAMES.__ADDITIONAL_LINK__]
  ).strip(true), // validate but remove on output
  [MATCH_KEYS.additional_links]: QArray.of(QString.url().required()).compact(),
  [MATCH_KEYS.watermark]: QEnumString(WATERMARK_ENUM, MATCH_INFO[MATCH_NAMES.watermark]).required(MATCH_INFO[MATCH_NAMES.watermark]),
  [MATCH_KEYS.origin]: MatchOriginSchema,
}
export const MatchSchema = QSchema.shape(MATCH_TYPES)
