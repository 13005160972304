import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class Typekit extends Component {
  render() {
    /**
     * For billing and administrative purposes, we'd like to use the
     * KitID linked to the pixsy.com domain. However, more font weights are
     * enabled in the Pixsy Kit, so we need to first adjust the font weights
     * before switching the KitID in production
     *
     * https://fonts.adobe.com/my_fonts#web_projects-section
     */
    const kitIdOnTorstensAccount = 'xws2xcq'
    // const kitIdOnPixsyAccount = 'ltz8aqc'
    return (
      <Helmet>
        <script>
          {`(function(d) {var config = {
                kitId: '${kitIdOnTorstensAccount}',
                scriptTimeout: 3000,
                async: true
              },h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)})(document);
          `}
        </script>
      </Helmet>
    )
  }
}
