import React from 'react'
import { css } from 'react-emotion'
import { FlickrImportDialogContent } from 'common'

export default function FlickrDialog() {
  return (
    <div css={content}>
      <FlickrImportDialogContent endpoint="flickr" />
    </div>
  )
}

FlickrDialog.propTypes = {}

const content = css`
  padding: 5px;
`
