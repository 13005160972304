import React from 'react'
import { Button } from 'common'
import { css } from 'react-emotion'

export default function SubmissionExitPrompt({
  onCancel,
  onNavigate,
  onSave,
  isSaveDisabled,
}) {
  return (
    <div css={content}>
      <h1>Are you sure?</h1>

      <span css={alertContent}>
        You have not yet completed your submission, however, we have saved the
        form for you. You can always come back and start where you left.
      </span>

      <div css={buttons}>
        <Button hoverMain onClick={onCancel}>
          Stay
        </Button>
        {!isSaveDisabled && (
          <Button outline onClick={onSave}>
            Save
          </Button>
        )}
        <Button greyOutline onClick={onNavigate}>
          Exit
        </Button>
      </div>
    </div>
  )
}

SubmissionExitPrompt.propTypes = {}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    h1 {
      text-align: center;
    }
  }
`

const alertContent = css`
  text-align: center;
`

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
