import React from 'react'
import FormRow from './FormRow'
import styled, { css, cx } from 'react-emotion'
import Feedback from './Feedback'
import { Button, Flag } from 'common'
import { notifSend } from 'redux-notifications/lib/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

export class RegistrationTypeBar extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onChange(e, input, item) {
    input.onChange(e) && input.onBlur(e)
  }

  onClick(e, input, item) {
    const { notify, location, locked } = this.props

    if (locked) {
      // read only
      e.preventDefault()
      return false
    }

    if (location.search.includes('image') && item.value === 'USCO_MULTI') {
      try {
        window.gtag('event', 'page_view', {
          page_path: '/c/vpv/fu/co/reg/err/multi-in-single',
        })
      } catch (e) {
        console.error(e)
      }
      notify({
        kind: 'info',
        message: 'For multi image registration, navigate to "Register"',
        dismissAfter: 5e3,
      })
      e.preventDefault()
      return false
    }

    if (
      !location.search &&
      (item.value === 'USCO_SINGLE' || item.value === 'CIPO_SINGLE')
    ) {
      try {
        window.gtag('event', 'page_view', {
          page_path: '/c/vpv/fu/co/reg/err/single-in-multi',
        })
      } catch (e) {
        console.error(e)
      }
      notify({
        kind: 'info',
        message:
          'To register a single image, navigate to Images, click on the Image and select “New Registration”',
        dismissAfter: 10e3,
      })
      e.preventDefault()
      return false
    }

    if (input.value === item.value) {
      // checked item clicked
      e.preventDefault()
      const resetInput = document.getElementById(`${input.name}-reset`)
      resetInput.checked = 'checked'
      resetInput.click()
      return false
    }
    input.onChange(e) && input.onBlur(e)
  }

  render() {
    const {
      input,
      items,
      meta,
      locked,
      addon,
      label,
      country,
      maxCardsNum = 1,
    } = this.props
    const jurisdiction = input.name
    return (
      <FormRow {...this.props}>
        <Row>
          <CardsLabel>
            {country && <Flag country={country} />}
            <h4>
              {label}
              <span>{jurisdiction}</span>
            </h4>
          </CardsLabel>
          <div className={wrap}>
            <Cards>
              {items.map((item, index) => {
                const checked = item.value === input.value
                return (
                  <Card
                    key={item.value}
                    className={cx(
                      checked ? 'checked' : '',
                      locked ? 'locked' : ''
                    )}
                    maxCardsNum={maxCardsNum}
                  >
                    <input
                      id={`${input.name}-${index}`}
                      onChange={e => this.onChange(e, input, item)}
                      checked={checked}
                      name={name}
                      type="radio"
                      value={item.value}
                    />
                    <h3>{item.label}</h3>
                    {item.description.split('\n\n').map((line, idx) => (
                      <p key={idx}>{line}</p>
                    ))}
                    <BottomContainer>
                      <PriceContainer value={item.price} currency="$" />
                      <Button>{checked ? <IconChecked /> : 'Select'}</Button>
                    </BottomContainer>
                    <label
                      htmlFor={`${input.name}-${index}`}
                      onClick={e => this.onClick(e, input, item)}
                    />
                  </Card>
                )
              })}
            </Cards>
            {addon && addon({ name: input.name })}
            <input
              id={`${input.name}-reset`}
              onChange={e => this.onChange(e, input, { value: '' })}
              name={input.name}
              type="radio"
              value=""
              css={resetInput}
            />
          </div>
          <Feedback meta={meta} />
        </Row>
      </FormRow>
    )
  }
}

const Row = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
`

const wrap = css`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  @media (max-width: 994px) {
    display: block;
    width: 100%;
  }
`

const Cards = styled.ul`
  display: inline-flex;
  width: 100%;
  padding: 0;
  border-right: 0;

  & > :first-of-type {
    margin-left: 0;
  }
`

const Card = styled.li`
  position: relative;
  display: inline-block;
  padding: 30px 25px 150px;
  margin-left: 20px;
  min-height: 340px;
  border: 1px solid #e6e6e6;
  width: ${({ maxCardsNum }) =>
    `calc((100% - (${maxCardsNum} - 1) * 20px) / ${maxCardsNum})`};

  input[type='radio'] {
    display: none;
    appearance: none;
  }

  label {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &.checked {
    border: 1px solid #148aa9;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);

    & label {
      border: 4px solid #148aa9;
    }
  }

  &.locked {
    opacity: 0.5;
    & label {
      cursor: default;
    }
  }
  &.checked.locked {
    opacity: 1;
  }

  h3 {
    font-size: 1.2em;
    margin: 0 0 15px 0;
  }

  p {
    font-size: 0.95em;
    line-height: 1.1em;
  }
`

const resetInput = css`
  display: none;
`

const BottomContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 100%;

  button {
    transform: scale(0.8);
    width: 140px;
    padding: 8px 30px;
  }

  button svg {
    position: relative;
    top: 5px;
    height: 17px;
  }
`
const PriceContainer = ({ value, currency }) => {
  const Price = styled.div`
    font-weight: 400;
    font-size: 3em;
  `

  const Currency = styled.span`
    font-size: 10px;
    position: relative;
    color: #3b3b3b80;
    top: -2.5em;
    left: -3px;
  `
  return (
    <Price>
      <Currency>{currency}</Currency>
      {value}
    </Price>
  )
}

const CardsLabel = styled.div`
  display: inline-block;
  padding: 80px 20px 0 0;
  float: left;
  height: 350px;
  width: 20%;
  @media (max-width: 994px) {
    height: auto;
    width: 100%;
    padding: 0 0 20px 0;
  }
  img {
    margin-bottom: 10px;
  }
  h4 {
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: normal;
    span {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
`

function IconChecked() {
  return (
    <svg
      id="f847363d-143c-4a46-ac28-03851dcb36b1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: '10px',
          isolation: 'isolate',
        }}
        d="M.70752,41.62988,27.31641,68.23877,55.5293,40.02588,94.84912.70581"
      />
    </svg>
  )
}

export default withRouter(
  connect(null, { notify: notifSend })(RegistrationTypeBar)
)

RegistrationTypeBar.propTypes = {}
