import { gql } from '@apollo/client'
import ClusterFragment from '../queries/cluster.fragment'

export default gql`
  mutation ignoreMatchWebApp($clusterId: String!, $matchId: String!, $state: Boolean!) {
    ignoreMatchWebApp(clusterId: $clusterId, matchId: $matchId, state: $state) {
      ...cluster
    }
  }
  ${ClusterFragment}
`
