import {
  prevalidateEmailAddress,
  getPasswordStrength,
  validateReferralCode,
} from '../../../redux/modules/users'
import { updateSyncWarnings } from 'redux-form'

let cachedEmailAddress = null
let hasShownErrorForReferralCode = false

let emailValidateCachedResponses = Object.create(null)
let emailValidateInFlight = false

async function asyncEmailValidateMemoized(dispatch, email) {
  if (emailValidateInFlight) {
    await Promise.delay(1e3)
    return asyncEmailValidateMemoized(dispatch, email)
  } else {
    if (emailValidateCachedResponses[email]) {
      return emailValidateCachedResponses[email]
    } else {
      emailValidateInFlight = true
      const response = await dispatch(prevalidateEmailAddress(email)).catch(
        e => {
          emailValidateInFlight = false
        }
      )
      emailValidateCachedResponses[email] = response
      emailValidateInFlight = false
      return response
    }
  }
}

export async function asyncValidate(
  { email, password, password_confirm, referral_code },
  dispatch
) {
  const errors = {}
  const throwErrors = () => {
    if (Object.keys(errors).length > 0) {
      throw errors
    }
  }

  // Validate email address.
  if (email) {
    // Reset any previous warnings.
    dispatch(
      updateSyncWarnings('register', {
        email: '',
      })
    )

    // Check for errors and warnings.
    const {
      payload: { type, validationMessage },
    } = await asyncEmailValidateMemoized(dispatch, email)

    // When it's a warning trigger the sync update.
    // Prevalidate email only if it has been changed
    if (type === 'warning' && email !== cachedEmailAddress) {
      dispatch(
        updateSyncWarnings('register', {
          email: validationMessage,
        })
      )
      cachedEmailAddress = email
    } else if (type === 'error') {
      cachedEmailAddress = email
      errors.email = validationMessage
    } else {
      // Defer to back-end validation.
      cachedEmailAddress = null
    }
  }

  if (!password_confirm) return throwErrors()

  if (password !== password_confirm)
    errors.password_confirm = 'Passwords don‘t match.'

  const {
    payload: { score, warning },
  } = await dispatch(getPasswordStrength(password))
  if (score < 2)
    errors.password = warning || `Please choose a stronger password.`

  if (referral_code && !hasShownErrorForReferralCode) {
    const {
      payload: { isValid, feedback },
    } = await dispatch(validateReferralCode(referral_code))
    if (!isValid) {
      hasShownErrorForReferralCode = true
      errors.referral_code = feedback
    }
  }

  throwErrors()
}

export const asyncBlurFields = [
  'password',
  'password_confirm',
  'referral_code',
]

export const initialValues = { country: '' }

// @see https://stackoverflow.com/questions/8935632/check-if-character-is-number/24121361#24121361
const possibleSingleNumbers = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
function isCharacterNumber(n) {
  return Boolean(possibleSingleNumbers[n])
}

export const fields = {
  EMAIL: {
    name: 'email',
    type: 'email',
    props: {
      placeholder: 'Email',
    },
    validate(value) {
      // https://tylermcginnis.com/validate-email-address-javascript/
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
        return 'Invalid email address.'
      if (
        value &&
        /^[^\s@]+@(gmail|icloud)\.[^\\.]+$/.test(value) &&
        !value.endsWith('.com')
      )
        return 'Invalid email address.'
    },
  },
  FIRST_NAME: {
    name: 'first_name',
    type: 'text',
    props: {
      placeholder: 'First Name',
    },
    validate(_value) {
      if (!_value) return 'First Name required.'
      const value = _value.trim()
      if (!value) return 'First Name required.'
      if (value.length > 40) return 'First name too long (max 40 letters)'
      if (value[0] !== value[0].toUpperCase()) {
        return 'Please ensure the first letter of your name is capitalized correctly.'
      }
      if (value === value.toUpperCase()) {
        return 'Please ensure your name is capitalized correctly.'
      }
      if (value === value.toLowerCase()) {
        return 'Please ensure your name is capitalized correctly.'
      }
      if (isCharacterNumber(value[0])) {
        return 'Please ensure the first letter of your name is a character.'
      }
    },
  },
  LAST_NAME: {
    name: 'last_name',
    type: 'text',
    props: {
      placeholder: 'Last Name',
    },
    validate(_value) {
      if (!_value) return 'Last Name required.'
      const value = _value.trim()
      if (!value) return 'Last Name required.'
      if (isCharacterNumber(value[0]))
        return 'Please ensure the first letter of your name is a character.'
      if (value === value.toUpperCase()) {
        return 'Please ensure your name is capitalized correctly.'
      }
      if (value === value.toLowerCase()) {
        return 'Please ensure your name is capitalized correctly.'
      }
    },
  },
  COMPANY: {
    name: 'company',
    type: 'text',
    props: {
      placeholder: 'Company Name',
    },
  },
  COUNTRY: {
    name: 'country',
    type: 'select',
    props: {
      placeholder: 'Country',
    },
    validate(value) {
      return !value && 'Country required.'
    },
  },
  PASSWORD: {
    name: 'password',
    type: 'password',
    props: {
      placeholder: 'Password',
    },
    validate(value) {
      return !value && 'Password required.'
    },
  },
  PASSWORD_CONFIRM: {
    name: 'password_confirm',
    type: 'password',
    props: {
      placeholder: 'Repeat Password',
    },
    validate(value) {
      return !value && 'Password required.'
    },
  },
  REFERRAL_CODE: {
    name: 'referral_code',
    type: 'text',
    props: {
      placeholder: '(Optional) If you have a referral code',
    },
  },
}
