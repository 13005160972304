import * as React from 'react'
import { WORK_TYPES, REGISTRATION_TYPES } from 'pixsy-constants'

export const PHASE = {
  INTRO: 'INTRO',
  DRAFT: 'DRAFT',
  REVIEW: 'REVIEW',
  FINAL: 'FINAL',
}

const normalizeBoolean = value =>
  value === 'true'
    ? true
    : value === 'false'
    ? false
    : typeof value === 'boolean'
    ? value
    : null

const validateExistence = value => !value && 'Response required.'

const validateRegistrationType = values => {
  const regTypes = Object.keys(REGISTRATION_TYPES).map(key => key.toLowerCase())
  const selectedRegTypes = Object.keys(values).filter(
    key => regTypes.includes(key) && values[key]
  )
  if (selectedRegTypes.length === 0) {
    return 'Please select at least one type of registration.'
  }
}

export const initialValues = {
  __PHASE__: PHASE.INTRO,
  __UPLOADS__: [],
  __DRAFT__: true,
  __ACTION__: 'payment',
  workType: Object.keys(WORK_TYPES)[0], // PHOTO
}
export const fields = {
  // OVERVIEW SECTION

  REGISTRATION_WORK_TYPE: {
    type: 'select',
    name: 'workType',
    props: {
      label: 'Please select the type of work',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value, form) {
      if (value && form.usco === 'USCO_MULTI' && value !== 'PHOTO') {
        return 'ONLY_PHOTOGRAPHS_FOR_USCO_MULTI'
      }

      if (value && value.length === 0) {
        return 'Response Required'
      }
      return validateExistence(value)
    },
  },

  REGISTRATION_IS_PUBLISHED: {
    name: 'publishedBefore',
    props: {
      label: 'Has your work been published?',
      normalize: normalizeBoolean,
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (typeof value !== 'boolean') {
        return 'Response required.'
      }
    },
  },

  REGISTRATION_CLIENT_IS_RIGHTS_HOLDER: {
    name: 'koDisclaimerOwnership',
    object: 'submission',
    props: {
      label: 'Are you the creator and rights owner of the work?',
      normalize: normalizeBoolean,
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value, ...args) {
      if (typeof value === 'boolean' && value === false) {
        return 'Only the creator and rights holder can submit a registration claim.'
      }

      if (typeof value === 'undefined') {
        return 'Response Required'
      }
    },
  },

  // IMAGES SECTION

  IMAGE_TITLE: {
    name: 'image.title',
    //object: 'image',
    type: 'text',
    props: {
      label: 'Title',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  IMAGE_DATE_TAKEN: {
    name: 'image.date_taken',
    //object: 'image',
    type: 'text',
    props: {
      label: 'Date of creation (completion)',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      return validateExistence(value)
    },
  },

  IMAGE_DATE_PUBLISHED: {
    name: 'image.licensing.first_published.date',
    //object: 'image',
    type: 'text',
    validate: validateExistence,
    props: {
      label: 'Date of first publication',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value, values) {
      if (
        values &&
        values.image &&
        values.image.date_taken &&
        value < values.image.date_taken
      ) {
        return 'The publication date can not precede the date the work was created.'
      }
    },
  },

  IMAGE_COUNTRY_PUBLISHED: {
    name: 'image.licensing.first_published.country',
    //object: 'image',
    type: 'select',
    props: {
      label: 'Country of first publication',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  IMAGE_USER_THE_ONLY_AUTHOR: {
    name: 'image.licensing.isSingleAuthor',
    //object: 'image',
    props: {
      label:
        'Does the work that you’re registering contain material only created by you?',
      normalize: normalizeBoolean,
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (typeof value !== 'boolean') {
        return 'Response required.'
      }
    },
  },

  IMAGE_PUBLISHED_BEFORE: {
    name: 'image.licensing.publishedBefore',
    // object: 'image',
    props: {
      label:
        'Does the work contain or is it based on previously registered or published material, material in the public domain or material that is not owned by you?',
      normalize: normalizeBoolean,
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (typeof value !== 'boolean') {
        return 'Response required.'
      }
    },
  },

  IMAGE_REGISTERED_CO_BEFORE: {
    name: 'image.registration.previous.registered',
    // object: 'image',
    props: {
      label:
        'Have you registered this image previously at the copyright office?',
      normalize: normalizeBoolean,
    },
  },

  IMAGE_REGISTERED_CO_BEFORE_YEAR: {
    name: 'image.registration.previous.year',
    // object: 'image',
    type: 'text',
    props: {
      label: 'Year of Registration',
      maxlength: 4,
    },
  },

  IMAGE_REGISTERED_CO_BEFORE_NUMBER: {
    name: 'image.registration.previous.id',
    // object: 'image',
    type: 'text',
    props: {
      label: 'Registration Number',
    },
  },

  // AUTHOR SECTION

  AUTHOR_FIRST_NAME: {
    name: 'firstName',
    object: 'author',
    type: 'text',
    props: {
      label: 'First Name',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_LAST_NAME: {
    name: 'lastName',
    object: 'author',
    type: 'text',
    props: {
      label: 'Last Name',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_DATE_OF_BIRTH: {
    name: 'dateOfBirth',
    object: 'author',
    type: 'text',
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
    props: {
      label: 'Date of Birth',
    },
  },

  AUTHOR_CITIZEN: {
    name: 'citizen',
    object: 'author',
    type: 'select',
    props: {
      label: 'Citizen',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_RESIDENT: {
    name: 'resident',
    object: 'author',
    type: 'select',
    props: {
      label: 'Resident',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_STREET: {
    name: 'street',
    object: 'author',
    type: 'text',
    props: {
      label: 'Address',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_CITY: {
    name: 'city',
    object: 'author',
    type: 'text',
    props: {
      label: 'City',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_STATE: {
    name: 'state',
    object: 'author',
    type: 'text',
    props: {
      label: 'State',
    },
  },

  AUTHOR_POSTAL_CODE: {
    name: 'postalCode',
    object: 'author',
    type: 'text',
    props: {
      label: 'Post Code',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_COUNTRY: {
    name: 'country',
    object: 'author',
    type: 'select',
    props: {
      label: 'Country',
    },
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate: validateExistence,
  },

  AUTHOR_PHONE: {
    name: 'phone',
    object: 'author',
    type: 'text',
    props: {
      label: 'Phone',
    },
  },

  // LEGAL SECTION

  LEGAL_COMMENTS: {
    name: 'comments',
    object: 'submission',
    props: {
      label: 'Comments',
    },
  },

  KNOCKOUT_USER_IS_AUTHOR: {
    name: 'koIsAuthor',
    object: 'submission',
    type: 'checkbox',
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (!value) {
        return 'Required'
      }
    },
    props: {
      label:
        'I certify that I am the author, copyright claimant and owner of this work and that the information given in this application is correct to the best of my knowledge',
    },
  },

  KNOCKOUT_LIABILITY: {
    name: 'koDisclaimerLiability',
    object: 'submission',
    type: 'checkbox',
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (!value) {
        return 'Required'
      }
    },
    props: {
      label:
        'I understand that providing false information can lead to legal liability and costs',
    },
  },

  KNOCKOUT_PERSISTENCE: {
    name: 'koDisclaimerPersistence',
    object: 'submission',
    type: 'checkbox',
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (!value) {
        return 'Required'
      }
    },
    props: {
      label:
        'I understand that this information will be registered with the official government body and become official record. As such, the related data points will be locked in my Pixsy account to ensure they match for future reference',
    },
  },

  KNOCKOUT_AGENT_AUTHORIZATION: {
    name: 'koDisclaimerAgentAuthorization',
    object: 'submission',
    type: 'checkbox',
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (!value) {
        return 'Required'
      }
    },
    props: {
      label: 'I authorize Pixsy as my Agent for handling this registration',
    },
  },

  KNOCKOUT_TOS: {
    name: 'koDisclaimerTos',
    object: 'submission',
    type: 'checkbox',
    runValidationIn: [PHASE.DRAFT, PHASE.REVIEW],
    validate(value) {
      if (!value) {
        return 'Required'
      }
    },
    props: {
      label: (
        <p>
          I agree to, and have read, the&nbsp;
          <a href="https://www.pixsy.com/terms-of-use/" target="_blank">
            Pixsy Terms of Use&nbsp;
          </a>
          and understand my responsibilities within it
        </p>
      ),
    },
  },

  COUPON_PROXY: {
    name: 'couponUsed',
    type: 'text',
    props: {
      label: 'If Pixsy has provided you with a coupon, enter it here',
    },
  },

  USCO_TYPE: {
    name: 'usco',
    object: 'registrationTypes',
    props: {
      items: REGISTRATION_TYPES.USCO.items,
      name: 'usco',
    },
    runValidationIn: [PHASE.INTRO, PHASE.DRAFT, PHASE.REVIEW],
    validate(value, values) {
      return validateRegistrationType(values)
    },
  },

  CIPO_TYPE: {
    name: 'cipo',
    object: 'registrationTypes',
    props: {
      items: REGISTRATION_TYPES.CIPO.items,
      name: 'cipo',
    },
    runValidationIn: [PHASE.INTRO, PHASE.DRAFT],
    validate(value, values) {
      return validateRegistrationType(values)
    },
  },
}

function getStruct() {
  return Object.keys(fields).reduce((o, k) => {
    const field = fields[k]
    if (field.object) {
      o[field.name] = field.object
    }
    return o
  }, {})
}

export function restruct(values) {
  const structure = getStruct()
  const result = Object.keys(values).reduce((o, k) => {
    const struct =
      structure[k] ||
      structure[
        Object.keys(structure)
          .filter(x => x.startsWith(k + '.'))
          .pop()
      ]
    if (struct) {
      if (!o[struct]) o[struct] = {}
      o[struct][k] = values[k]
    } else {
      o[k] = values[k]
    }
    return o
  }, {})
  return result
}

export function flatten(object) {
  const keysWithObjects = [...new Set(Object.values(getStruct()))]
  const image = object.images[0]
  return {
    ...Object.assign({}, object, ...keysWithObjects.map(key => object[key])),
    image,
  }
  // return {
  //   ...object,
  //   ...object.author,
  //   ...object.submission,
  // }
}

export async function asyncValidate() {
  return Promise.delay(1000)
}
