import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridWrappingColumn, StickyFloater, Button } from 'common'
import { asyncConnect } from 'redux-connect'
import { withCookies } from 'react-cookie'
import Helmet from 'react-helmet'
import { redirectWhen } from '../../../decorators'
import { loadSingle as loadCluster } from '../../../redux/modules/clusters'
import { loadTakedownContentForCluster } from '../../../redux/modules/takedowns'
import { filterIgnoredPairsOnStitchedCluster } from '../../../redux/selectors'
import TakedownFormDataProvider from './TakedownFormDataProvider'
import TakedownSuccess from './TakedownSuccess'
import TakedownIntro from '../Intro/TakedownIntro'
import qs from 'qs'
import moment from 'moment'
import { createSelector } from 'reselect'
import _get from 'lodash/get'

const log = require('debug')('takedown')

export class TakedownContainer extends Component {
  static COOKIE_DATE_LAST_DISCLAIMER = 'pixsy-takedown-intro-recently-seen'

  state = { show: null }

  constructor(props) {
    super()

    this.state = {
      show: this.showIntro(props) ? 'intro' : 'form',
    }
  }

  componentDidMount() {
    if (this.state.show === 'intro') {
      try {
        window.gtag('event', 'page_view', {
          page_path: '/c/vpv/takedown/start',
          event_callback(res) {
            console.info('pageview success')
            console.info(res)
          },
        })
        window.gtag('event', 'start', {
          event_category: 'takedown',
          event_label: 'Takedown started',
          event_callback(res) {
            console.info('event success')
            console.info(res)
          },
        })
      } catch (e) {
        console.error(`Unable to invoke google-analytics method:`, e)
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.cluster && !nextProps.cluster) return false

    return true
  }

  showIntro(_props) {
    let props = _props || this.props
    const recently_seen =
      props.cookies.get(TakedownContainer.COOKIE_DATE_LAST_DISCLAIMER) || null

    return !recently_seen
  }

  handleIntroSeen = (doSetCookie) => {
    log('handleIntroSeen | Will set cookie: %o', doSetCookie)
    if (doSetCookie) {
      const cookie = {
        path: '/',
        expires: moment()
          .add(2, 'months')
          .toDate(),
      }

      this.props.cookies.set(
        TakedownContainer.COOKIE_DATE_LAST_DISCLAIMER,
        1,
        cookie
      )
      log('handleIntroSeen | Cookie set: %o', cookie)
    }
    this.setState({ show: 'form' })
  }

  handleSuccess = remainingQuota => {
    this.setState({
      show: 'success',
      remainingQuota,
    })
  }

  renderIntroActionSection = () => {
    return (
      <React.Fragment>
        <Button hoverMain onClick={this.handleIntroSeen.bind(this, false)}>
          Continue to Takedown
        </Button>
        <p
          className="clickable"
          onClick={this.handleIntroSeen.bind(null, true)}
        >
          Continue and hide this message for 60 days
        </p>
      </React.Fragment>
    )
  }

  render() {
    const {
      user,
      cluster,
      history,
      location,
      query: { takedownId },
    } = this.props
    const { show, remainingQuota } = this.state

    return (
      <GridWrappingColumn maxWidth={876}>
        <Helmet title="Takedown" />
        <StickyFloater>
          <Button
            onClick={history.goBack}
            className="sticky-floater--back-button"
          />
          <span className="sticky-floater--left-aligned-description">
            <b>New Takedown</b>
            &nbsp;
            {cluster && `for ${cluster.domain.host}`}
          </span>
        </StickyFloater>

        {show === 'intro' ? (
          <TakedownIntro
            takedownId={takedownId}
            renderActionSection={this.renderIntroActionSection}
          />
        ) : show === 'success' ? (
          <TakedownSuccess
            cluster={cluster}
            remainingQuota={remainingQuota}
            location={location}
            history={history}
          />
        ) : (
          <TakedownFormDataProvider
            user={user}
            cluster={cluster}
            takedownId={takedownId}
            onSuccess={this.handleSuccess}
            location={location}
          />
        )}
      </GridWrappingColumn>
    )
  }
}

const resolveLocation = (location, match) => {
  const { from } = qs.parse(String(location.search).substring(1))
  const {
    params: { takedownId },
  } = match
  return {
    clusterId: from,
    takedownId,
  }
}

const getClusterId = (state, props) =>
  qs.parse(String(props.location.search).substring(1)).from

const getTakedownId = (state, props) => props.match.params.takedownId

const getEntities = (state, props) => state.entities

const getCluster = createSelector(
  [getClusterId, getEntities],
  (clusterId, entities) => {
    if (!clusterId || !entities.clusters[clusterId]) return null

    const cluster = { ...entities.clusters[clusterId] }
    cluster.domain = getDomain(entities.domains[cluster.domain], cluster)
    cluster.images = cluster.images.map(i => entities.images[i])
    cluster.matches = cluster.matches
      .map(i => entities.matches[i])
      .filter(m => m.ignored !== true)

    return cluster
  }
)

const getDomain = (domain, cluster) => {
  let contacts = domain.contacts || []
  const isDomainReviewed = _get(domain, 'override.emails') ? true : false

  if (
    !isDomainReviewed &&
    contacts.length === 0 &&
    cluster.entity &&
    cluster.entity.address &&
    cluster.entity.address.email
  ) {
    contacts.push(cluster.entity.address.email)
  }

  if (
    !isDomainReviewed &&
    contacts.length === 0 &&
    domain.contact &&
    domain.contact.emails &&
    domain.contact.emails.length > 0
  ) {
    contacts = domain.contact.emails.map(e => e.email).filter(Boolean)
  }

  return {
    ...domain,
    contacts,
  }
}

const getQuery = createSelector(
  getClusterId,
  getTakedownId,
  (clusterId, takedownId) => ({
    clusterId,
    takedownId,
  })
)

export default asyncConnect(
  [
    {
      key: 'fetchDataForTakedownContainer',
      promise: async function fetchDataForTakedownSubmissionContainer({
        store: { getState, dispatch },
        match,
        location,
      }) {
        const { clusterId } = resolveLocation(location, match)

        if (clusterId && clusterId.length === 24) {
          const response = await dispatch(loadCluster(clusterId))

          if (
            response &&
            response.payload &&
            response.payload.entities &&
            response.payload.entities.domains
          ) {
            const domains = Object.values(response.payload.entities.domains)

            if (domains && domains[0].dmcaFormUrl) {
              await dispatch(loadTakedownContentForCluster({ clusterId }))
            }
          }
        }
      },
    },
  ],
  (state, props) => ({
    query: getQuery(state, props),
    cluster: filterIgnoredPairsOnStitchedCluster(getCluster(state, props)),
    user: state.auth.user,
  }),
  { loadTakedownContentForCluster }
)(
  redirectWhen({
    shouldRedirect: ({ cluster }) => !cluster,
    to: ({ location }) => ({
      ...location,
      pathname: '/takedowns',
    }),
  })(withCookies(TakedownContainer))
)

TakedownContainer.propTypes = {
  cluster: PropTypes.object,
  history: PropTypes.object.isRequired,
  query: PropTypes.shape({
    takedownId: PropTypes.string.isRequired,
  }).isRequired,
}
