import {
  Button,
  ChooseBestImageSource,
  Flag,
  GridWrappingColumn,
  LoadingOverlay,
  StatusHeading,
} from 'common'
import { format } from 'currency-formatter'
import moment from 'moment'
import { REGISTRATION_TYPES, REGISTRATION_TYPES_ENUM } from 'pixsy-constants'
// CR_STYL: import _styles from '../Registrations.styl'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { branch, compose } from 'recompose'
import { PHASE } from '../../../../shared/forms/registrationSubmission'
// import URI from 'urijs'
import NoResults from './NoResults'

const log = require('debug')('registrations:registrations-table')

export class RegistrationsTable extends Component {
  /*
  props: {
    registrations: Array<Object>,
    loading: boolean,
    onSortSelection: Function,
  }
  */

  constructor() {
    super()

    this._phaseColorMapping = {
      DRAFT: '#D39A1C50',
      NEW: '#28BF4550',
    }

    this._columnConfiguration = {
      Registration: {
        sortKey: 'ref',
        visibleFor: ['open', 'draft', 'final', 'all'],
        getCellData: (r, key) => (
          <td key={key} style={{ verticalAlign: 'middle' }}>
            <div style={{ color: '#808080' }}>
              <span>{(r.salesforce && r.salesforce.Name) || 'Incomplete'}</span>
            </div>
            <div style={{ fontSize: 24, fontWeight: 800, maxWidth: 400 }}>
              {r.images.length > 1 && <span>{r.images.length} Images</span>}
              {r.images.length === 1 && <span>{r.images[0].title}</span>}
            </div>
            <div style={{ color: '#808080' }}>
              <span>{moment(r.created).fromNow()}</span>
            </div>
          </td>
        ),
      },
      Type: {
        visibleFor: ['open', 'draft', 'final', 'all'],
        sortKey: 'workType',
        getCellData: (r, key) => (
          <td key={key} style={{ verticalAlign: 'middle' }}>
            <div>
              <span style={{ fontSize: 14, color: '#808080' }}>
                {(r.salesforce && r.salesforce.WorkType__c) || '-'}
              </span>
            </div>
            <div>
              <span style={{ fontSize: 18, fontWeight: 500 }}>
                {Object.values(r.registrationTypes).map(registrationType => (
                  <div key={registrationType}>
                    <Flag
                      country={
                        REGISTRATION_TYPES[registrationType.split('_')[0]].flag
                      }
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 5,
                        marginBottom: 4,
                      }}
                    />
                    {REGISTRATION_TYPES_ENUM[registrationType] &&
                      REGISTRATION_TYPES_ENUM[registrationType].label}
                  </div>
                ))}
              </span>
            </div>
          </td>
        ),
      },
      Status: {
        visibleFor: ['open', 'draft', 'final', 'all'],
        sortKey: 'status',
        getCellData: (r, key) => {
          let label =
            (r.status && r.status.replace('_', ' ')) ||
            PHASE[r.phase.toUpperCase()] ||
            'Draft'

          return (
            <td key={key} style={{ verticalAlign: 'middle' }}>
              <span
                style={{
                  backgroundColor:
                    this._phaseColorMapping[label] || '#28BF4550',
                  padding: '2px 20px',
                  borderRadius: 3,
                }}
              >
                {label.toUpperCase()}
              </span>
            </td>
          )
        },
      },
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.loading !== nextProps.loading ||
      nextProps.registrations.map(x => x._id).join('-') !==
        this.props.registrations.map(x => x._id).join('-')
    )
  }

  renderPrice({ value, currency }) {
    return !value
      ? '-'
      : format(value, {
          format: '%s %v',
          code: currency,
          thousand: ',',
          decimal: '.',
        }) +
          ' ' +
          currency
  }

  handleCaptionClick(sortKey) {
    log(sortKey)
  }

  renderAction(registration) {
    return (
      <Link to={`/registrations/submit/${registration._id}`}>
        <Button>
          {/* <MagnifyingGlass /> */}
          {registration.phase === 'FINAL' ? 'Details' : 'Edit'}
        </Button>
      </Link>
    )
  }

  render() {
    const { registrations, filter, loading } = this.props

    return (
      <GridWrappingColumn maxWidth={1340}>
        <StatusHeading
          title={`${Number(
            registrations.length
          ).toLocaleString()} Registrations`}
          isVisible={!loading}
        />
        <LoadingOverlay show={loading}>
          <Table
            responsive /* CR_STYL: className={_styles.registrationsTable}*/
            className={registrationTable}
          >
            {/** <thead align="center">
              <tr>
                <th>&nbsp;</th>
                {Object.entries(this._columnConfiguration)
                  .filter(([key, value]) => value.visibleFor.includes(filter))
                  .map(([key, { sortKey, getCellData }], idx) => (
                    <th
                      key={sortKey}
                      onClick={this.handleCaptionClick.bind(this, sortKey)}
                    >
                      {key}
                      {!idx && <Arrow />}
                    </th>
                  ))}
                <th />
              </tr>
                  </thead>*/}
            <tbody>
              {registrations.map(r => (
                <tr key={r._id}>
                  <td width={10}>
                    <div className={previewImage}>
                      <ChooseBestImageSource
                        candidates={[
                          r.images && r.images[0] && r.images[0].url,
                        ]}
                      >
                        {({ url }) => <img src={url} />}
                      </ChooseBestImageSource>
                    </div>
                  </td>
                  {Object.entries(this._columnConfiguration)
                    .filter(([key, value]) => value.visibleFor.includes(filter))
                    .map(([key, { sortKey, getCellData }], idx) =>
                      getCellData(r, key)
                    )}
                  <td
                    width={50}
                    align="center"
                    style={{ verticalAlign: 'middle' }}
                  >
                    {this.renderAction(r)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </LoadingOverlay>
      </GridWrappingColumn>
    )
  }
}

export default compose(
  branch(
    props => props.total === 0 && !props.loading && props.filter === 'all',
    () => NoResults
  ),
  branch(
    props => props.total === 0 && !props.loading,
    () => NoResults
  )
)(RegistrationsTable)

RegistrationsTable.propTypes = {
  registrations: PropTypes.array.isRequired,
}

// function MagnifyingGlass() {
//   return (
//     <svg viewBox="5419.006 -6373.018 13.194 14.114">
//       <path
//         d="M22.988,23.758a5.058,5.058,0,1,0-.734.566l4.79,4.79.65-.65Zm-3.432.435a4.137,4.137,0,1,1,4.137-4.137A4.141,4.141,0,0,1,19.556,24.192Z"
//         transform="translate(5404.506 -6388.018)"
//       />
//     </svg>
//   )
// }

const registrationTable = css(`
button {
  padding: 7px 30px;
  background-color: transparent;
  border: 2px solid #ccc;
  color: #000;
  opacity: 0.6;
  font-size: 0.8em;
  margin: 0 25px 0 10px;
  transition: all 200ms ease-in;

  &:hover {
    border-color: #008aab;
    opacity: 1;
    background-color: transparent;
  }

  svg {
    height: 14px;
    vertical-align: -3px;
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    margin: 0 10px;
  }
}
`)

const previewImage = css`
  width: 115px;
  height: 115px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.02);
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    background-color: rgba(0, 0, 0, 0.02);
  }
`
