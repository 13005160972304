import {
  PHASE,
  fields as fieldSpecifications,
} from '../../../../../shared/forms/registrationSubmission'
import { get, set } from 'lodash'

const FIELDS = Object.values(fieldSpecifications)

const log = require('debug')('submission:validate')

function validateRootLevelForPhase(phase, values) {
  const errors = FIELDS.filter(
    field => field.runValidationIn && field.runValidationIn.includes(phase)
  )
    .filter(f => !f.path)
    .map(f => log('Validate %o', f.name) || f)
    .map(f => ({
      name: f.name,
      error: f.validate(get(values, f.name), values),
    }))
    .filter(f => f.error)
    .reduce(
      // (results, { name, error }) => ({
      //   ...results,
      //   ...({ [name]: error } || {}),
      // }),
      (results, { name, error }) => set(results, name, error),
      {}
    )

  // console.log('Errors:', JSON.parse(JSON.stringify(errors)), phase)

  return errors
  // return {
  //   ...errors,
  //   // images: getEntityErrorsForPhase('images', phase, values),
  // }
}

function validateIntro(values) {
  log('Validating INTRO')

  const errors = {
    ...validateRootLevelForPhase(PHASE.INTRO, values),
  }
  log('Errors %o', errors)

  return errors
}

function validateDraft(values) {
  log('Validating DRAFT', values)

  const errors = {
    ...validateRootLevelForPhase(PHASE.DRAFT, values),
  }
  log('Errors %o', errors)

  return errors
}

function validateReview(values) {
  log('Validating REVIEW', values)

  const errors = {
    ...validateDraft(values),
    ...validateRootLevelForPhase(PHASE.REVIEW, values),
  }
  log('Errors %o', errors)
  return errors
}

function validateFinal(values) {
  log('Validating FINAL', values)

  const errors = {
    ...validateReview(values),
    ...validateRootLevelForPhase(PHASE.FINAL, values),
  }
  log('Errors %o', errors)
  return errors
}

const validateUnlessDraft = validate => values =>
  values.__DRAFT__ ? validateDraft(values) : validate(values)
export default {
  [PHASE.INTRO]: validateIntro,
  [PHASE.DRAFT]: validateDraft,
  [PHASE.REVIEW]: validateUnlessDraft(validateReview),
  [PHASE.FINAL]: validateUnlessDraft(validateFinal),
  dontValidate: () => {
    log('Validation Skipping')
    return {}
  },
}

// function getEntityErrorsForPhase(entityType, phase, values) {
//   return values[entityType].map((entity, index) => {
//     const entityErrors = {}
//     FIELDS.filter(field => field.path === entityType)
//       .filter(
//         field =>
//           field.runValidationIn &&
//           field.runValidationIn.includes(phase) &&
//           field.validate
//       )
//       .forEach(field => {
//         log('validate %o field %o', entityType, field.name)
//         const error = field.validate(get(entity, field.name), values, entity)
//         if (error) set(entityErrors, field.name, error)
//       })

//     return entityErrors
//   })
// }
