import {
  defaultSelectedClusterFiltersState,
  defaultSelectedGroupMatchFiltersState,
  LIST_TYPE,
  YEAR_DIFF,
} from './constants'

const isYearRangeChanged = (state) => !(Math.abs(state.selectedYearRange[0] - state.selectedYearRange[1]) === YEAR_DIFF)

const selectNone = () => ({})

const areFiltersChanged = (filters, view) => {
  const defaultFilters =
    view === LIST_TYPE.CLUSTER ? defaultSelectedClusterFiltersState : defaultSelectedGroupMatchFiltersState

  const didFiltersChange = !!(
    filters.selectedCountryIds.length ||
    filters.selectedCategoryIds.length ||
    filters.selectedImageIds.length ||
    filters.selectedKeywordPairs.join().length ||
    filters.selectedActionIds.length ||
    filters.selectedYearRange[0] !== defaultFilters.selectedYearRange[0] ||
    filters.selectedYearRange[1] !== defaultFilters.selectedYearRange[1] ||
    filters.selectedMatchesType !== defaultFilters.selectedMatchesType ||
    filters.selectedSeenState !== defaultFilters.selectedSeenState ||
    filters.selectedSimilarityType !== defaultFilters.selectedSimilarityType ||
    filters.selectedCommercialType !== defaultFilters.selectedCommercialType ||
    filters.isFlagged !== defaultFilters.isFlagged ||
    filters.isPixsyFlagged !== defaultFilters.isPixsyFlagged ||
    filters.selectedEntityId !== null ||
    filters.selectedMatchId !== null
  )
  return didFiltersChange
}

export { isYearRangeChanged, selectNone, areFiltersChanged }
