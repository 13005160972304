import {
  SUBMISSION_VALIDATION_SCENARIOS,
  UNSUBMITTABLE_TLDS,
  SUPPORTED_COUNTRIES,
  UNRESOLVABLE_DOMAIN_CATEGORIES,
} from 'pixsy-constants'
import COUNTRIES from 'pixsy-constants/countries.json'
import urijs from 'urijs'
import get from 'lodash/get'

const log = require('debug')('is-submittable-sync')
// log.enabled = true

const scenarios = new Map()

  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_STOCK, {
    fn: domainIsStock,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_COUNTRY_UNRESOLVABLE, {
    fn: domainCountryIsNotResolvable,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_UNRESOLVABLE, {
    fn: domainIsUnresolvable,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_CATEGORY_UNRESOLVABLE, {
    fn: domainCategoryIsUnresolvable,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_MATCH_HOTLINKED_IMAGE, {
    fn: clusterFeaturesHotlinkedImage,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_COUNTRY_TM_ONLY, {
    fn: clusterIsNotATopMatchButOnlyTopMatchesAreAllowedForThatCountry,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_IMAGE_CC0, {
    fn: clusterImageCreativeCommonsZeroCheck,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_IMAGE_CC_LEGAL_ONLY, {
    fn: clusterImageCCLegalOnlyJurisdictions,
    messageShort: 'NYI',
  })
  .set(SUBMISSION_VALIDATION_SCENARIOS.ERROR_CLUSTER_MINIMUM_SCORE, {
    fn: clusterMinimumScore,
    messageShort: 'NYI',
  })
// TEMPORARY DISABLED
// .set(SUBMISSION_VALIDATION_SCENARIOS.WARNING_MATCH_HOTLINKED_ORIGIN, {
//   fn: clusterFeaturesHotlinkedOrigin,
// })

export function validateSync(cluster) {
  if (cluster.tags.includes('pixsy:flagged')) {
    return {}
  }

  return Array.from(scenarios.entries()).reduce((obj, [key, { fn, messageShort }]) => {
    let result = fn(cluster)

    log('%o => %o ', key, result)

    return result
      ? {
          ...obj,
          [key]: messageShort,
        }
      : obj
  }, {})
}

export default function isClusterSubmittable(cluster) {
  const functions = scenarios.keys()

  for (const fn of functions) {
    if (fn(cluster) === true) {
      // Not resolvable, return explanation
      const explanation = scenarios.get(fn)
      return [false, explanation]
    }
  }
  // Resolvable
  return [true, null]
}

export function clusterHasBeenSubmittedAsCaseBefore(cluster) {
  if (cluster.case) return true

  return false
}
export function clusterHasBeenSubmittedAsTakedownBefore(cluster) {
  if (cluster.takedown) return true

  return false
}
export function clusterHasBeenSubmitted(cluster) {
  if (cluster.tags.some((t) => t.startsWith('submitted:'))) return true

  return false
}

export function domainIsStock(cluster) {
  if (cluster.domain) {
    if (cluster.domain.stock === true) {
      return true
    } else if (cluster.domain === 'adobe.com' && cluster.matches.some((m) => m.origin.url.includes('stock.'))) {
      return true
    }
  }

  return false
}

export function domainCountryIsNotResolvable(cluster) {
  const domainCountry = cluster.domain && cluster.domain.country

  if (domainCountry) {
    const entryOnCountries = COUNTRIES.find((c) => c.iso === domainCountry)
    if (entryOnCountries) {
      return !entryOnCountries.resolution
    }
  }

  const domainTLD = cluster.domain && cluster.domain.tld

  if (domainTLD) {
    const isTLDInvalid = domainTLD && UNSUBMITTABLE_TLDS.includes(domainTLD)

    if (isTLDInvalid && SUPPORTED_COUNTRIES.findIndex((c) => c.code === cluster.domain.country) < 0) {
      return true
    }
  }

  return false
}

export function domainIsUnresolvable(cluster) {
  if (cluster.domain && cluster.domain.resolvable === false) {
    return true
  }

  return false
}

export function domainCategoryIsUnresolvable(cluster) {
  if (cluster.domain && cluster.domain.category && UNRESOLVABLE_DOMAIN_CATEGORIES.includes(cluster.domain.category)) {
    return true
  }

  return false
}

export function clusterIsNotATopMatchButOnlyTopMatchesAreAllowedForThatCountry(cluster) {
  const TOP_MATCH_TAGS = ['top', 'top:us', 'top:world']
  const clusterIsATopMatch = cluster.tags && cluster.tags.some((t) => TOP_MATCH_TAGS.includes(t))

  const onlyTopMatchesAreAllowedForCountry =
    cluster.domain &&
    cluster.domain.country &&
    COUNTRIES.some((c) => c.iso === cluster.domain.country && c.tmSubmissionOnly === true)

  if (!onlyTopMatchesAreAllowedForCountry) return false

  return !(clusterIsATopMatch && onlyTopMatchesAreAllowedForCountry)
}

export function clusterImageCreativeCommonsZeroCheck(cluster) {
  if (
    cluster.images &&
    cluster.images.some((i) => i.licensing && i.licensing.license && i.licensing.license === 'CC0')
  ) {
    return true
  }
  return false
}

export function clusterImageCCLegalOnlyJurisdictions(cluster) {
  const domainCountry = get(cluster, 'domain.country', '') || ''
  const images = cluster.images || []
  const isCC = images.some((i) =>
    get(i, 'licensing.license', '') !== null ? get(i, 'licensing.license', '').startsWith('CC') : false
  )

  if (isCC && ['greece', 'spain', 'gr', 'es'].includes(domainCountry.toLowerCase())) {
    return true
  }

  return !!(isCC && COUNTRIES.some((c) => c.iso === domainCountry && c.legalOnly === true))
}

export function clusterMinimumScore(cluster) {
  const domainCountry = get(cluster, 'domain.country', '') || ''
  const country = COUNTRIES.find((c) => c.iso === domainCountry && c.legalOnly === true)
  if(country && country.minimumCCScore && country.minimumCCScore >= cluster.score) return true
  return false;
}

export function clusterFeaturesHotlinkedOrigin(cluster) {
  const BLACKLIST = [
    'ftcdn.net', // fotolia cdn
  ]

  const hasBlacklistEntry = cluster.matches.some((m) => BLACKLIST.includes(urijs(m.url).domain()))

  if (hasBlacklistEntry) return false

  return cluster.matches
    .filter((m) => m.url && m.origin.url)
    .some((m) => urijs(m.url).domain() !== urijs(m.origin.url).domain())
}

export function clusterFeaturesHotlinkedImage(cluster) {
  const imageURLs = cluster.images.map((i) => i.url)

  return cluster.matches.some((m) => imageURLs.includes(m.url))
}
