import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ExitPrompt, Dialog } from 'common'
import { withRouter } from 'react-router'
import SubmissionExitPrompt from './SubmissionExitPrompt'

export class SubmissionFormNavigationBehavior extends PureComponent {
  state = {
    blockExit: this.props.dirty,
  }

  // static getDerivedStateFromProps(props, state, prevProps = {}) {
  //   const { blockExit } = state
  //   const { dirty, submitting } = props

  //   if (!blockExit && !prevProps.dirty && dirty) {
  //     return { blockExit: true }
  //   }
  //   if (blockExit === true && !prevProps.submitting && submitting) {
  //     return { blockExit: false }
  //   }

  //   return null
  // }

  // converting this to getDerivedState From Props wasnt yielding expected behavior!
  componentWillReceiveProps(nextProps) {
    const { blockExit } = this.state
    const { dirty, submitting } = this.props

    if (!blockExit && !dirty && nextProps.dirty) {
      this.setState({ blockExit: true })
    }
    if (blockExit === true && !submitting && nextProps.submitting) {
      this.setState({ blockExit: false })
    }
  }

  handleExitSave = onCancel => {
    onCancel()
    this.props.onSave()
  }

  renderExitPrompt = ({ isOpen, onConfirm, onCancel }) => {
    const { isSaveDisabled } = this.props
    return (
      <Dialog isOpen={isOpen} onRequestClose={onCancel}>
        <SubmissionExitPrompt
          isSaveDisabled={isSaveDisabled}
          onCancel={onCancel}
          onNavigate={onConfirm}
          onSave={() => this.handleExitSave(onCancel)}
        />
      </Dialog>
    )
  }

  render() {
    const { blockExit } = this.state

    return <ExitPrompt when={blockExit} render={this.renderExitPrompt} />
  }
}

export default withRouter(SubmissionFormNavigationBehavior)

SubmissionFormNavigationBehavior.propTypes = {
  dirty: PropTypes.bool.isRequired, // eslint-disable-line
  submitting: PropTypes.bool.isRequired, // eslint-disable-line
  onSave: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
}
