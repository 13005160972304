import React, { useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { LoadingOverlay } from 'common'
import falsePositiveReviewQuery from '../../../graphql/queries/falsePositiveReviewMatches.query'
import { useMutation, useQuery } from '@apollo/client'
import { css } from 'react-emotion'
import _get from 'lodash/get'
import FalsePositiveViewer from './Viewer'
import updateMatchReview from '../../../graphql/queries/updateMatchFalsePositiveReview.mutation'

const FalsePositiveReviewContainer = ({
  match: {
    params: { matchId },
  },
  location: { search },
  history,
}) => {
  // const { loading, error, data } = useQuery(falsePositiveReviewQuery, {
  //   fetchPolicy: 'cache-and-network',
  //   variables: { limit: 50, lastMatchId: matchId, search },
  // })

  const loading = false
  const error = null
  const data = {
    image: {
      _id: '560a9edbc28fc41200a4c79b',
      url: 'https://farm3.staticflickr.com/2883/10726659585_50dd6f13f9_b.jpg',
    },
    matches: [
      {
        _id: '560a9ee87994a7d705bdd441',
        url:
          'http://www.i-shot-it.com/gs-photo.php?path=ishot-it%2F2013-10-26-526c296ee7436-526c297335871-thumbnail.jpg',
        imagesimCollectionEvent: {
          _id: '560a9ee87994a7d705bdd441',
          '10_proba': 0.001160904997959733,
          '11_proba': 0.020148485898971558,
          '12_proba': 0.9786906242370605,
          __v: 0,
          collections: ['2021-06-02T10:34:57.379Z'],
          fp_prediction: 12,
          last_collection: '2021-06-02T10:34:57.382Z',
          score: 0.9920487403869629,
          user: '5450fa254bdfb90c00806083',
          image: '560a9edbc28fc41200a4c79b',
        },
      },
      {
        _id: '60b430696bc4ef01aef7a912',
        url:
          'https://cdn3.eyeem.com/thumb/d5446a5cf273678178f27af39b82e658dccc4a15-1577398900043/w/800',
        imagesimCollectionEvent: {
          _id: '60b430696bc4ef01aef7a912',
          '10_proba': 0.00010539551294641569,
          '11_proba': 0.00046408604248426855,
          '12_proba': 0.9994304776191711,
          __v: 0,
          collections: ['2021-06-02T10:34:57.379Z'],
          fp_prediction: 12,
          last_collection: '2021-06-02T10:34:57.383Z',
          score: 0.9997874916489444,
          user: '5450fa254bdfb90c00806083',
          image: '560a9edbc28fc41200a4c79b',
        },
      },
    ],
  }

  const [updateReview] = useMutation(updateMatchReview)

  const [matches, setMatches] = useState(data.matches)
  const [currentMatchId, setCurrentMatchId] = useState(
    _get(data, 'falsePositiveReview[0]._id')
  )

  useEffect(() => {
    setMatches((data && data.falsePositiveReview) || [])
    setCurrentMatchId(_get(data, 'falsePositiveReview[0]._id'))
  }, [_get(data, 'falsePositiveReview[0]._id', matchId)])

  /**
   * Handle Next Navigation
   */
  const goToNextMatch = useCallback(() => {
    const idx = matches.findIndex(m => m._id === currentMatchId)
    if (idx === matches.length - 1) {
      history.push(
        `/manager/false-positive-review/${currentMatchId}?date=${Date.now()}`
      )
    } else {
      setCurrentMatchId(matches[idx + 1]._id)
    }
  }, [currentMatchId, setCurrentMatchId, matches])

  /**
   * Handle Prev Match
   */
  const goToPrevMatch = useCallback(() => {
    const idx = matches.findIndex(m => m._id === currentMatchId)
    if (idx === 0) {
      history.goBack()
    } else {
      setCurrentMatchId(matches[idx - 1]._id)
    }
  }, [currentMatchId, setCurrentMatchId, matches])

  /**
   * Handle action
   */
  const takeAction = useCallback(
    review => {
      const currentMatches = matches.map(m => {
        if (m._id !== currentMatchId) return m
        return {
          ...m,
          verification: `1${Number(review)}`,
        }
      })
      setMatches(currentMatches)

      updateReview({
        variables: { id: currentMatchId, review },
        optimisticResponse: {
          __typename: 'Mutation',
          updateMatchFalsePositiveReview: {
            __typename: 'Match',
            ...matches.find(m => m._id === currentMatchId),
            verification: `1${Number(review)}`,
          },
        },
      }).then(goToNextMatch)
    },
    [updateReview, currentMatchId, matches, goToNextMatch]
  )

  /**
   * If errors from Graph API, render them on the screen
   * Since, it is an internal only feature, it is okay to show errors!
   * Helpful to ask for the Error stack ;)
   */
  if (error) {
    return (
      <React.Fragment>
        <h1>Error Fetching matches:</h1>
        <pre>{error.message}</pre>
        <pre>{error.graphQLErrors.toString()}</pre>
        <pre>{error.stack.toString()}</pre>
      </React.Fragment>
    )
  }

  // const match = matches.find(m => m._id === currentMatchId)

  if (!matches || loading) {
    return <LoadingOverlay show />
  }

  // if (!matchId && match._id !== matchId) {
  //   history.push(`/manager/false-positive-review/${currentMatchId}`)
  // }

  return (
    <React.Fragment>
      <Helmet title={`False Positive Review | ${matchId}`} />
      <div css={wrapper}>
        <FalsePositiveViewer
          data={data}
          next={goToNextMatch}
          prev={goToPrevMatch}
          takeAction={takeAction}
        />
      </div>
      <hr style={{ marginTop: 40 }} />
      <div>
        <h3>Notes:</h3>
        <ul>
          <li>
            Use Drag And Drop to move the matches into the appropriate columns
          </li>
          <li>Click on the source image to navigate to user’s image</li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const wrapper = css`
  div[tabindex='-1']:focus {
    outline: 0;
  }
`

export default FalsePositiveReviewContainer
