import React from 'react'
import PropTypes from 'prop-types'
import { OffSectionTooltip } from 'common'
import { UppyDragDrop } from '../../../../Cases/CaseSubmission/Components'

export default function BulkSubmissionFormFileUpload({ uppy, uploading }) {
  return (
    <React.Fragment>
      {uploading && <h1>Processing received Excel file...</h1>}
      {!uploading && (
        <React.Fragment>
          <OffSectionTooltip>
            Once you upload the Excel file we’ll verify data integrity - then
            you'll have a chance to confirm your submission.
          </OffSectionTooltip>
          <UppyDragDrop
            uppy={uppy}
            title="Drop your file here or %{browse} to select"
            description="Excel file"
            browse="click"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

BulkSubmissionFormFileUpload.propTypes = {
  uppy: PropTypes.object.isRequired,
  uploading: PropTypes.bool.isRequired,
}
