import { gql } from '@apollo/client'

export default gql`
  fragment cluster on Cluster {
    _id
    score
    tags
    created
    case
    takedown
    matches {
      _id
      url
      origin
      ignored
      invalid
      isOnline
      created
      similarity
      image
      meta
    }
    images
    domain
    entityData {
      name
      company_name
    }
  }
`
