import React from 'react'
import { css } from 'react-emotion'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import { Button, ClosedQuestionRadio, TextField } from 'common'

import { updateUser } from '../../../redux/modules/auth'

const HighValuePhoneNumberQuestionaireComponent = ({
  valid,
  submitting,
  handleSubmit,
}) => (
  <form css={content} onSubmit={handleSubmit}>
    <h1>Personalize Support</h1>
    <div css={formWrap}>
      <Field
        name="photographerFields.personalizedSupport"
        label="Would you like a Pixsy account manager to help you set up your account and get the most of Pixsy?"
        component={ClosedQuestionRadio}
        normalize={normalizeBoolean}
      />
      <Field name="details.phone" label="Phone No." component={TextField} />
    </div>
    <Button submit disabled={!valid || submitting}>
      Continue
    </Button>
  </form>
)

HighValuePhoneNumberQuestionaireComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

HighValuePhoneNumberQuestionaireComponent.eligible = user => {
  const hasAnsweredPersonalizedSupportQuestion =
    user.photographerFields &&
    typeof user.photographerFields.personalizedSupport === 'boolean'

  const caseValue = user.caseValue
  const isHighValue = ['High', 'High (New)'].includes(caseValue)
  // used for new sign ups where case value is not yet updated
  const isGoingToBeHighValue = user.reportedLicensingPrice === '>750'

  return (
    !hasAnsweredPersonalizedSupportQuestion &&
    (isHighValue || isGoingToBeHighValue)
  )
}

export default connect(state => ({
  user: state.auth.user,
}))(
  reduxForm({
    form: 'HighValuePhoneNumberQuestionaire',
    validate: values => ({
      photographerFields: {
        ...(undefined ===
          _get(values, 'photographerFields.personalizedSupport') && {
          personalizedSupport: 'Choice required',
        }),
      },
      details: {
        ...(_get(values, 'details.phone') === undefined &&
          _get(values, 'photographerFields.personalizedSupport') && {
            phone: 'Please provide your phone number',
          }),
        ...(_get(values, 'details.phone') &&
          !testPhoneNumber(_get(values, 'details.phone')) && {
            phone: 'Please verify your phone number',
          }),
      },
    }),
    onSubmit: (values, dispatch, { onComplete }) =>
      dispatch(updateUser(values)).then(onComplete),
  })(HighValuePhoneNumberQuestionaireComponent)
)

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
`

const formWrap = css`
  width: 100%;
  padding: 15px 0 15px 0;
`

function normalizeBoolean(value) {
  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  return null
}

function testPhoneNumber(value) {
  // eslint-disable-next-line no-useless-escape
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)
}
