import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import { Button, StickyFloater } from 'common'

export default function DefaultFloater({ location, tags }) {
  const searchIcon = require('../../Matches/V2/QueryBar/searchIcon.svg')
  const routes = [
    {
      renderLabel: () => 'All',
      to: '',
      condition: tags => !tags.includes('new') && !tags.includes('ignored'),
    },
    {
      renderLabel: () => 'New',
      to: '/new',
      condition: tags => tags.includes('new'),
    },
    {
      renderLabel: () => 'Ignored',
      to: '/ignored',
      condition: tags => tags.includes('ignored'),
    },
  ]

  return (
    <StickyFloater navigation>
      {routes.map(({ to, condition, renderLabel }) => (
        <Link
          key={'to-' + to}
          to={`/images${to}`}
          {...(condition(tags) ? { className: 'active' } : {})}
        >
          {renderLabel()}
        </Link>
      ))}
      <Link
        to={{
          ...location,
          search: '?search',
        }}
      >
        <i css={search} dangerouslySetInnerHTML={{ __html: searchIcon }} />
        Search
      </Link>
      <Link to="/images/import">
        <div className="sticky-floater--highlight">
          <Button>Import</Button>
        </div>
      </Link>
    </StickyFloater>
  )
}

DefaultFloater.defaultProps = {
  tags: [],
}

DefaultFloater.propTypes = {
  //   location: PropTypes.object.isRequired,
  //   match: PropTypes.object.isRequired,
  tags: PropTypes.array,
}

const search = css`
  line-height: 13px;
  position: relative;
  bottom: -8px;
  svg {
    height: 30px;
    display: inline-block;

    .svg-search-2,
    .svg-search-3 {
      stroke: #999;
    }
    .svg-search-2 {
      fill: #fff;
    }

    .svg-search-4 {
      display: none;
    }
  }
`
