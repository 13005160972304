import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { css } from 'react-emotion'

export default class DialogSlider extends Component {
  constructor({ children }) {
    super()

    const kids = [
      ...React.Children.toArray(React.Children.map(children, this.cloneChild)),
    ]

    this.state = {
      current: 0,
      skipped: new Array(children.length).fill(false),
      children: kids,
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  cloneChild = (child, index) => {
    return React.cloneElement(child, {
      key: child.type.displayName || child.type.name,
      onComplete: this.handleNext,
      recalculateHeight: this.recalculateHeight,
      skip: this.createSkipHandler(index),
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      React.Children.count(nextProps.children) !==
      React.Children.count(this.props.children)
    ) {
      const children = [
        ...React.Children.toArray(
          React.Children.map(nextProps.children, this.cloneChild)
        ),
      ]
      this.setState({
        skipped: new Array(children.length).fill(false),
        children,
      })
    }
  }

  handleNext = () => {
    this.refs.slick && this.refs.slick.slickNext()
  }

  recalculateHeight = () => {
    if (this._isMounted) {
      // Must not call force update if component is not mounted
      this.refs.slick && this.refs.slick.forceUpdate()
    }
  }

  beforeChange = (current, next) => {
    this.setState({ current: next })
  }

  createSkipHandler = i => cb => {
    const { children, onFinish } = this.props

    if (children.length - 1 === i) {
      return onFinish()
    }
    const { current, skipped: lastSkipped } = this.state
    const skipped = lastSkipped.slice()

    skipped[i] = true

    this.setState({ skipped }, () => {
      if (current === i) {
        const nextIndex = skipped.indexOf(false, i)

        if (~nextIndex) {
          this.refs.slick && this.refs.slick.slickGoTo(nextIndex)
        }
      }
      typeof cb === 'function' && cb()
    })
  }

  render() {
    const { children } = this.state
    // if async check ends up making dialog render null, dont include in children

    return (
      <div css={wrap}>
        <Slider
          ref="slick"
          adaptiveHeight
          lazyLoad="ondemand"
          draggable={false}
          dotsClass={dotsClass}
          infinite={false}
          arrows={false}
          beforeChange={this.beforeChange}
        >
          {children}
        </Slider>
      </div>
    )
  }
}

DialogSlider.propTypes = {
  children: PropTypes.node.isRequired,
  onFinish: PropTypes.func.isRequired,
}

const wrap = css`
  width: 100%;

  h1 {
    text-align: center;
    + p {
      margin-top: 20px;
    }
  }

  h4 {
    width: 80%;
    color: white;
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 1em;
  }

  .dialog-slider--button-wrapper {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;

      button {
        margin-bottom: 10px;
        margin-top: 0px;

        &:nth-child(1) {
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }
    }

    a + button,
    button + button,
    button + a {
      margin-left: 20px;
      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }
    a button {
      height: 100%;
    }

    // @media (max-width: 768px) {
    //   button {
    //     margin-top: 10px;

    //     &:first-child {
    //       margin-top: 0px;
    //     }
    //   }
    // }
  }
`
const dotsClass = css`
  background-color: red;
`

// Removed progrss indcator due to state headache when children can
// asynchronously decide to render null instead if a modal (like online check)

// const progressIndicator = css`
//   margin-top: 30px;
//   padding: 0;
//   display: flex;
//   justify-content: center;
//   list-style: none;
//   position: absolute;
//   width: 100%;

//   li {
//     display: inline-block;
//     padding: 5px;
//     appearance: none;
//     border-radius: 50%;
//     border: 1px solid #93939380;
//     margin-right: 7px;
//     margin-top: 15px;
//   }
// `

// const activeProgressIndicator = css`
//   background-color: #008aab;
//   border: 1px solid #008aab;
// `

// const pointerStyle = css`
//   cursor: pointer;
// `
