import React from 'react'
import { css } from 'react-emotion'
import { DoubleCenteredContent } from 'common'

export default function withEmptyStates(list) {
  return function EmptyStateCheck(WrappedComponent) {
    return function ConditionalRender(moreProps) {
      const activeEmptyState = list.find(({ shouldRender }) =>
        shouldRender(moreProps)
      )

      return !activeEmptyState ? (
        <WrappedComponent {...moreProps} />
      ) : (
        <DoubleCenteredContent>
          <div className={emptyStateContentStyles}>
            {activeEmptyState.render(moreProps)}
          </div>
        </DoubleCenteredContent>
      )
    }
  }
}

const emptyStateContentStyles = css`
  position: relative;
  width: 100%;
  min-height: 50vh;

  /* cta buttons: "or" separator */
  a + p {
    font-size: 1.35em;
    color: #21212180;
    margin-bottom: 4px;
  }
`
