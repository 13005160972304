import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { css, cx } from 'react-emotion'
import { StickyFloater } from 'common'
import qs from 'querystring'

export function DefaultStickyFloater({ linkBase, location, match, tags }) {
  const searchIcon = require(`./searchIcon.svg`)
  const routes = [
    {
      label: 'Unseen',
      to: '/matches/unseen',
      condition: (tags, path) => tags.includes('unseen'),
    },
    {
      label: 'Top Matches',
      to: '/matches/top',
      top: true,
      condition: (tags, path) => tags.includes('top'),
      onClick: () => {
        try {
          window.mixpanel.track('Matches.Display.Top', {
            source: 'navigation',
          })

          window.gtag('event', 'clickTopMatches', {
            topMatchesClicked: 1,
            event_category: 'matches',
            event_label: 'Top Matches clicked',
          })
        } catch (e) {
          console.error(e)
        }
      },
    },
    {
      label: 'All',
      to: '/matches',
      condition: (tags, path) =>
        path === '/matches/:tags*' &&
        !tags.includes('overview') &&
        !tags.includes('new') &&
        !tags.includes('hidden:true'),
    },
  ]

  return (
    <StickyFloater navigation>
      <NavLink to="/matches/overview">Overview</NavLink>
      {routes

        .map(({ top, condition, ...rest }) => {
          const classNames = []
          if (condition(tags, match.path)) {
            classNames.push('active')
          }
          if (top) {
            classNames.push(topMatchesLink)
          }
          return {
            ...rest,
            ...(classNames.length > 0 && {
              className: cx(classNames),
            }),
          }
        })
        .map(({ label, ...rest }) => (
          <Link key={label} {...rest}>
            {label}
          </Link>
        ))}
      <Link
        to={{
          ...location,
          pathname:
            location.pathname === '/matches/overview'
              ? '/matches'
              : location.pathname,
          search:
            '?' +
            qs.stringify({
              ...(location.search && qs.parse(location.search.slice(1))),
              search: '',
            }),
          state: { ...(location.state || {}), fromDefaultStickyFloater: true },
        }}
      >
        <i css={search} dangerouslySetInnerHTML={{ __html: searchIcon }} />
        Search
      </Link>
      {/* <Button disabled>Search</Button> */}
    </StickyFloater>
  )
}

export default withRouter(DefaultStickyFloater)

DefaultStickyFloater.defaultProps = {
  tags: [],
}

DefaultStickyFloater.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  tags: PropTypes.array,
}

const search = css`
  line-height: 13px;
  position: relative;
  bottom: -8px;
  svg {
    height: 30px;
    display: inline-block;

    .svg-search-2,
    .svg-search-3 {
      stroke: #999;
    }
    .svg-search-2 {
      fill: #fff;
    }

    .svg-search-4 {
      display: none;
    }
  }
`

const topMatchesLink = css`
  color: #ff7f2f !important;
`
