import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { css } from 'react-emotion'

const anchor = css`
  position: relative;
  min-height: 100px;

  @media (max-width: 768px) {
    min-height: auto;
  }
`
const overlay = css`
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  background: rgba(252, 252, 252, 0);
  z-index: 10;
  transition: opacity 300ms ease;
  pointer-events: none;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    position: fixed;
    height: 100vh;
    overflow: hidden;
  }

  div {
    opacity: 0;
  }
`
const noOverlap = css`
  left: 0;
  right: 0;
`

const active = css`
  background: rgba(252, 252, 252, 0.95);

  div {
    opacity: 1;
  }
`

const animContainer = css`
  position: absolute;
  top: 50px;
  transform: translateY(-50%);
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  z-index: 11;
`

const animAnchor = css`
  position: relative;
  height: 100%;
  background: red;
`

const dot = css`
  position: absolute;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-top-color: #008aab;
  border-right-color: #008aab;
  border-radius: 50%;
  animation: move 0.8s linear infinite;
  transform: translateZ(0);

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      border-radius: 50%;
    }
  }
`

export function LoadingOverlay({ loading, overlap = true, children }) {
  return (
    <div css={anchor}>
      <div
        className={`${overlay} ${loading ? active : ''} ${
          overlap ? '' : noOverlap
        }`}
      >
        <div className={animAnchor}>
          <div className={animContainer}>
            <Dot />
          </div>
        </div>
      </div>
      {children ? children : null}
    </div>
  )
}

function Dot() {
  return <div className={dot} />
}

const LoadingOverlayConnected = connect((state, { show }) => ({
  loading: show || !state.reduxAsyncConnect.loaded,
}))(LoadingOverlay)

export default LoadingOverlayConnected

LoadingOverlayConnected.Dot = Dot

LoadingOverlay.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
}
