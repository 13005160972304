import { Grid } from '@material-ui/core'
import isEqual from 'lodash/isEqual'
import { CATEGORY_LABELS as _CATEGORY_LABELS, PIXSY_RECOMMENDED_CATEGORIES } from 'pixsy-constants'
import React, { useState, useCallback, useMemo } from 'react'

import Checkbox from './Checkbox'
import Content from './content'
import { useColumnSort } from './helpers'

const CATEGORY_LABELS = {
  ..._CATEGORY_LABELS,
  undefined: 'Undefined',
}

function useMatchPreferenceCategories(hiddenCategories) {
  const [hidden, setHidden] = useState(hiddenCategories)

  const onChange = useCallback((category) => {
    setHidden((state) => {
      if (state.includes(category)) return state.filter((s) => s !== category)
      return [...state, category]
    })
  }, [])

  const toggleAll = useCallback((categories, hideAll) => {
    if (hideAll) {
      setHidden((state) => Array.from(new Set([...state, ...categories])).filter(Boolean))
    } else {
      setHidden((state) => state.filter((s) => !categories.includes(s)).filter(Boolean))
    }
  }, [])

  const showSaveButton = useMemo(() => !isEqual(hiddenCategories.sort(), hidden.sort()), [hiddenCategories, hidden])

  return {
    hidden,
    onChange,
    showSaveButton,
    toggleAll,
  }
}

const MatchPreferenceCategories = ({ hiddenCategories = [], updateMatchPreference, tagCounts }) => {
  const { hidden, onChange, showSaveButton, toggleAll } = useMatchPreferenceCategories(hiddenCategories.filter(Boolean))

  const saveMatchPreference = useCallback(() => {
    return updateMatchPreference({
      variables: { categories: hidden.filter(Boolean) },
    })
  }, [hidden])

  const labels = useMemo(() => Object.keys(CATEGORY_LABELS), [])
  const pixsyRecommendedLabels = useColumnSort(
    labels.filter((l) => PIXSY_RECOMMENDED_CATEGORIES.includes(l)),
    2,
    3
  )
  const otherLabels = useColumnSort(
    labels.filter((l) => !PIXSY_RECOMMENDED_CATEGORIES.includes(l)),
    2,
    3
  )

  return (
    <Content title="Categories" showSaveButton={showSaveButton} onSave={saveMatchPreference}>
      <Grid container>
        <RenderCategories
          categories={pixsyRecommendedLabels}
          tagCounts={tagCounts}
          hidden={hidden}
          onChange={onChange}
          title="Pixsy Recommended"
          toggleAll={toggleAll}
        />
        <RenderCategories
          categories={otherLabels}
          tagCounts={tagCounts}
          hidden={hidden}
          onChange={onChange}
          title="Other"
          toggleAll={toggleAll}
        />
      </Grid>
    </Content>
  )
}

const RenderCategories = ({ categories, tagCounts, hidden, onChange, title, toggleAll }) => {
  const allSelected = categories.filter(Boolean).every((c) => !hidden.includes(c))
  const allDeselected = categories.filter(Boolean).every((c) => hidden.includes(c))

  return (
    <React.Fragment>
      <Checkbox
        large
        indeterminate={!allSelected && !allDeselected}
        checked={allSelected}
        title={`${title} (${categories.reduce((sum, category) => {
          const countData = tagCounts.find(({ tag }) => tag === `category:${category}`)
          return sum + (countData ? countData.count : 0)
        }, 0)})`}
        onChange={toggleAll.bind(this, categories, allSelected)}
      />
      <Grid container style={{ paddingLeft: 20, marginBottom: 20 }}>
        {categories.map((category) => {
          if (!category) return <Grid item md={4} xs={6} />

          return (
            <Grid item key={category} md={4} xs={6}>
              <Checkbox
                title={
                  CATEGORY_LABELS[category] +
                  ` (${
                    (
                      tagCounts.find(({ tag }) => tag === 'category:' + category) || {
                        count: 0,
                      }
                    ).count
                  })`
                }
                checked={!hidden.includes(category)}
                onChange={() => onChange(category)}
              />
            </Grid>
          )
        })}
      </Grid>
    </React.Fragment>
  )
}

export default React.memo(MatchPreferenceCategories)