import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Modal, GridWrappingColumn, Button } from 'common'
import { HotKeys } from 'react-hotkeys'
import qs from 'qs'
import { redirectWhen } from '../../../../decorators'
import { css } from 'react-emotion'
import MatchDetails from './MatchDetails'
import CardGrid from './CardGrid'
import ScrollOnMount from './ScrollOnMount'
import AddIgnoreRuleDialog from '../AddIgnoreRuleDialog/AddIgnoreRuleDialog'
import ViewerHeader from './Header/ViewerHeader'
import KeyboardOverlay from './KeyboardOverlay'
import { assembleClusterById, getClustersQuery } from '../../../../redux/selectors'
import { flag, hide, touch, updateMatch } from '../../../../redux/modules/clusters'
import { remove as removeRule } from '../../../../redux/modules/rules'
import { withCookies } from 'react-cookie'
import IgnoreDialog from '../CardView/ActionsPanel/IgnoreDialog'

const log = require('debug')('matches:viewer')

class Viewer extends Component {
  state = {
    displayIgnoreDialog: false,
    displayIgnoreRuleDialog: false,
    showKeyboardOverlay: false,
    showMobileActionsMenu: false,
  }

  queueAsyncAction = (cb) => {
    let done
    let fail
    const wrapPromise = new Promise((d, f) => {
      done = d
      fail = f
    })
    // prettier-ignore
    this.promise = Promise
      .resolve(this.promise)
      .then(() => cb()
        .then(done)
        .catch(fail)
      )

    return wrapPromise
  }

  constructor(props) {
    super()

    this.promise = Promise.resolve()

    this._keyMap = {
      close: 'esc',
      previous: 'left',
      next: 'right',
      nextMatch: 'down',
      prevMatch: 'up',
      openDomainModal: 'd',
      singleIgnore: 'i',
      approveMatch: 'a',
      singleInvalid: 'n',
      undo: 'u',
      showKeys: { sequence: 'shift', action: 'keydown' },
      hideKeys: { sequence: 'shift', action: 'keyup' },
    }

    this._keyHandlers = {
      close: this.handleClose,
      previous: this.handlePrevious,
      next: this.handleNext,
      showKeys: () => this.setState({ showKeyboardOverlay: true }),
      hideKeys: () => this.setState({ showKeyboardOverlay: false }),
      approveMatch: this.handleSingleMatchHideApproved,
      singleInvalid: this.handleSingleMatchHideInvalid,
      singleIgnore: this.handleSingleMatchHideIgnore,
      undo: this.handleUndo,
      openDomainModal: this.handleIgnoreModalOpen,

      nextMatch: this.handleNavigationInCluster,
      prevMatch: this.handleNavigationInCluster,
    }

    this._clusterPageInvalidated = false
  }

  UNSAFE_componentWillMount() {
    this.maybeNavigateIntoFirstMatch(this.props)
  }

  componentDidMount() {
    this.maybeTouch(this.props)

    // Triggers GA event for open match viewer
    try {
      window.gtag('event', 'page_view', {
        page_path: '/c/vpv/matches/openMatchViewer',
        event_callback(res) {
          console.info('pageview success')
          console.info(res)
        },
      })
      window.gtag('event', 'openMatchViewer', {
        event_category: 'matches',
        event_label: 'Match Viewer Opened',
        event_callback(res) {
          console.info('event success')
          console.info(res)
        },
      })
    } catch (e) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.cluster._id !== this.props.cluster._id) {
      this.maybeNavigateIntoFirstMatch(nextProps)
    }
  }

  componentDidUpdate(prevProps) {
    const { cluster } = this.props
    if (cluster._id !== prevProps.cluster._id) {
      this.maybeTouch(this.props)
    }
    if (!prevProps.cluster.tags.includes('hidden:true') && cluster.tags.includes('hidden:true')) {
      requestAnimationFrame(() => {
        this.handleNext()
      })
    }
  }

  maybeTouch(props) {
    const { tags, _id } = props.cluster
    clearTimeout(this._touchTimer)
    if (tags.includes('unseen')) {
      this._touchTimer = setTimeout(() => this.props.touch(_id), 200)
    }
  }

  maybeNavigateIntoFirstMatch(props) {
    const { cluster, history, location, matchId, tags } = props

    log('maybeNavigateIntoFirstMatch', cluster.matches.length)
    if (!matchId && cluster.matches.length > 1) {
      const imageScopeTag = tags.find((t) => t.startsWith('image:'))
      let preferredIndex = 0

      if (imageScopeTag) {
        const preferredImageId = imageScopeTag.split(':')[1]
        preferredIndex = cluster.matches.findIndex((m) => m.image._id === preferredImageId)
        if (preferredIndex === -1) preferredIndex = 0
      }

      history.replace({
        ...location,
        pathname: `${location.pathname}/${cluster.matches[preferredIndex]._id}`,
      })
    }
  }

  handleNext = () => {
    const { clusters, position, parent, history, location, clustersAfter, page } = this.props

    const hasOpenModals = document.getElementsByClassName('viewer-modal').length !== 0
    if (hasOpenModals) return

    const nextCluster = clusters[position + 1]

    if (nextCluster) {
      history.push({
        ...location,
        pathname: `${parent}/view/${nextCluster._id}`,
      })
    } else if (clustersAfter > 0) {
      const oldQuery = qs.parse(String(location.search).substr(1))
      const newQuery = {
        ...oldQuery,
        page: page + 1,
      }
      history.push({
        ...location,
        pathname: `${parent}/view`,
        search: qs.stringify(newQuery),
        state: {
          ...location.state,
          navigateToClusterInPage: 0,
        },
      })
    }
  }

  handlePrevious = () => {
    const { clusters, position, parent, history, location, page, clustersBefore, pageSize } = this.props

    const hasOpenModals = document.getElementsByClassName('viewer-modal').length !== 0
    if (hasOpenModals) return

    const previousCluster = clusters[position - 1]

    if (previousCluster) {
      history.push({
        ...location,
        pathname: `${parent}/view/${previousCluster._id}`,
      })
    } else if (clustersBefore > 0) {
      const oldQuery = qs.parse(String(location.search).substr(1))
      const newQuery = {
        ...oldQuery,
        page: page - 1,
      }
      history.push({
        ...location,
        pathname: `${parent}/view`,
        search: qs.stringify(newQuery),
        state: {
          ...location.state,
          navigateToClusterInPage: pageSize - 1,
        },
      })
    }
  }

  handleClose = () => {
    const { parent, history, location } = this.props
    if (this.state.displayIgnoreRuleDialog) {
      this.setState({ displayIgnoreRuleDialog: false })
    } else {
      history.push({
        ...location,
        pathname: parent,
        state: {
          ...location.state,
          clusterPageInvalidated: this._clusterPageInvalidated,
        },
      })
    }
  }

  handleIgnoreDialogOpen = (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreDialog: true })
  }

  handleIgnoreDialogClose = (e) => {
    e && e.preventDefault()
    if (this.state.displayIgnoreDialog) this.setState({ displayIgnoreDialog: false })
  }

  handleIgnoreRuleDialogOpen = () => (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreRuleDialog: true })
  }

  handleIgnoreRuleDialogClose = (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreRuleDialog: false })
  }

  handleRuleCreationSuccess = (action) => {
    const rule = action && action.payload && action.payload.rule

    this._ruleIdCreatedWithLastAction = rule
  }

  handleNavigationInCluster = (ev) => {
    ev.preventDefault()

    if (ev.key === 'ArrowDown') {
      this.handleNextMatch()
    } else if (ev.key === 'ArrowUp') {
      this.handlePreviousMatch()
    }
  }

  handlePreviousMatch() {
    const { cluster, parent, history, location, matchId } = this.props

    const index = cluster.matches.findIndex((i) => i._id === matchId)
    const previousMatch = cluster.matches[index - 1]

    this._ruleIdCreatedWithLastAction = null

    if (previousMatch) {
      const targetId = previousMatch._id
      history.replace({
        ...location,
        pathname: `${parent}/view/${cluster._id}/${targetId}`,
      })
    }
  }

  handleNextMatch() {
    const { cluster, parent, history, location, matchId } = this.props

    const index = cluster.matches.findIndex((i) => i._id === matchId)
    const nextMatch = cluster.matches[index + 1]

    this._ruleIdCreatedWithLastAction = null

    if (nextMatch) {
      const targetId = nextMatch._id
      history.replace({
        ...location,
        pathname: `${parent}/view/${cluster._id}/${targetId}`,
      })
    }
  }

  handleIgnoreModalOpen = (e) => {
    this._clusterPageInvalidated = true
    this.setState({ displayIgnoreDialog: true })
  }

  handleSingleMatchHideInvalid = () => {
    const { updateMatch, cluster, matchId } = this.props

    const targetMatchId = matchId || cluster.matches[0]._id
    const targetMatch = cluster.matches.find((m) => m._id === targetMatchId)

    this._ruleIdCreatedWithLastAction = null

    if (targetMatch.ignored) {
      return this.handleSingleMatchHideReset()
    }

    this.handleNextMatch()

    this.queueAsyncAction(() =>
      updateMatch(cluster._id, targetMatchId, {
        ignored: true,
        invalid: true,
      })
    )

    try {
      window.mixpanel.track('Matches.Invalidate', {
        _id: targetMatch._id,
        confidence: targetMatch.confidence,
        orb: targetMatch.orb,
        proximity: targetMatch.proximity,
        similarity: targetMatch.similarity,
        tm: cluster.tags.some((t) => ['top', 'top:world', 'top:us'].includes(t)),
      })
    } catch (e) {}

    this._clusterPageInvalidated = true

    // Triggers GA event for approved match
    try {
      window.gtag('event', 'page_view', {
        page_path: '/c/vpv/matches/notMyImage',
        event_callback(res) {
          console.info('pageview success')
          console.info(res)
        },
      })
      window.gtag('event', 'notMyImage', {
        event_category: 'matches',
        event_label: 'Match notMyImage',
        event_callback(res) {
          console.info('event success')
          console.info(res)
        },
      })
    } catch (e) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }
  }

  handleSingleMatchHideApproved = () => {
    const { updateMatch, cluster, matchId } = this.props

    const targetMatchId = matchId || cluster.matches[0]._id
    const targetMatch = cluster.matches.find((m) => m._id === targetMatchId)

    this._ruleIdCreatedWithLastAction = null

    if (targetMatch.ignored) {
      return this.handleSingleMatchHideReset()
    }

    this.handleNextMatch()

    this.queueAsyncAction(() =>
      updateMatch(cluster._id, targetMatchId, {
        ignored: true,
        approved: true,
      })
    )

    this._clusterPageInvalidated = true

    try {
      window.mixpanel.track('Matches.Approve', {
        _id: targetMatch._id,
        tm: cluster.tags.some((t) => ['top', 'top:world', 'top:us'].includes(t)),
      })
    } catch (e) {}

    // Triggers GA event for approved match
    try {
      window.gtag('event', 'page_view', {
        page_path: '/c/vpv/matches/approve',
        event_callback(res) {
          console.info('pageview success')
          console.info(res)
        },
      })
      window.gtag('event', 'approve', {
        event_category: 'matches',
        event_label: 'Match approved',
        event_callback(res) {
          console.info('event success')
          console.info(res)
        },
      })
    } catch (e) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }
  }

  handleSingleMatchHideIgnore = () => {
    const { updateMatch, cluster, matchId } = this.props

    const targetMatchId = matchId || cluster.matches[0]._id
    const targetMatch = cluster.matches.find((m) => m._id === targetMatchId)

    this._ruleIdCreatedWithLastAction = null

    if (targetMatch.ignored) {
      return this.handleSingleMatchHideReset()
    }

    this.handleNextMatch()

    this.queueAsyncAction(() =>
      updateMatch(cluster._id, targetMatchId, {
        ignored: true,
        approved: false,
        invalid: false,
      })
    )

    this._clusterPageInvalidated = true

    try {
      window.mixpanel.track('Matches.Ignore', {
        _id: targetMatch._id,
        tm: cluster.tags.some((t) => ['top', 'top:world', 'top:us'].includes(t)),
      })
    } catch (e) {}

    // Triggers GA event for ignored match
    try {
      window.gtag('event', 'page_view', {
        page_path: '/c/vpv/matches/ignore',
        event_callback(res) {
          console.info('pageview success')
          console.info(res)
        },
      })
      window.gtag('event', 'ignore', {
        event_category: 'matches',
        event_label: 'Match ignored',
        event_callback(res) {
          console.info('event success')
          console.info(res)
        },
      })
    } catch (e) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }
  }

  handleUndo = () => {
    const { updateMatch, cluster, matchId, removeRule } = this.props

    if (this._ruleIdCreatedWithLastAction) {
      removeRule(this._ruleIdCreatedWithLastAction)
      this._ruleIdCreatedWithLastAction = null
      return
    }

    const targetMatchId = matchId || cluster.matches[0]._id
    const targetMatch = cluster.matches.find((m) => m._id === targetMatchId)

    if (!targetMatch.ignored) {
      return
    }

    this.queueAsyncAction(() =>
      updateMatch(cluster._id, matchId || cluster.matches[0]._id, {
        ignored: false,
        approved: false,
        invalid: false,
      })
    )

    this._clusterPageInvalidated = true
  }

  handleSingleMatchHideReset = () => {
    const { updateMatch, cluster, matchId } = this.props

    const targetMatchId = matchId || cluster.matches[0]._id
    const targetMatch = cluster.matches.find((m) => m._id === targetMatchId)

    if (!targetMatch.ignored) {
      return
    }

    this.queueAsyncAction(() =>
      updateMatch(cluster._id, matchId || cluster.matches[0]._id, {
        ignored: false,
        approved: false,
        invalid: false,
      })
    )

    this._clusterPageInvalidated = true
  }

  handleToggleMobilePopup = () => {
    this.setState((prevState) => ({
      showMobileActionsMenu: !prevState.showMobileActionsMenu,
    }))
  }

  render() {
    const {
      cluster,
      parent,
      flag,
      hide,
      matchId,
      clusterId,
      clustersBefore,
      clustersAfter,
      location,
      user,
      allCookies,
    } = this.props

    const { showKeyboardOverlay } = this.state

    const refs = this.refs
    const sharkMode = !!allCookies['pixsy-shark']

    const matches = cluster.matches

    let scrollDist

    const activeMatchIndex = matchId && matches.findIndex((m) => m._id === matchId)

    const activeMatch = matches.length > 1 && matches[activeMatchIndex]

    if (matches.length > 1 && activeMatchIndex >= 0) {
      let val = 226 * Math.floor(activeMatchIndex / 4)
      if (window.innerWidth < 1000) {
        scrollDist = val - 75
      } else {
        scrollDist = val
      }
    }

    return (
      <div className={modalContainer}>
        {/* {this.state.currentlyIgnoring && (
          <IgnoreModal
            matches={this.state.currentlyIgnoring}
            onApprove={() => alert(' approve')}
            onIgnore={() => alert(' ignore')}
          />
        )} */}
        <Helmet
          title={`Matches | ${cluster.domain.host} ${cluster.matches.length > 1 ? `(${cluster.matches.length})` : ''}`}
        />
        <HotKeys keyMap={this._keyMap} focused handlers={this._keyHandlers} attach={global}>
          <Modal isOpen={this.state.displayIgnoreRuleDialog} onRequestClose={this.handleClose} theme="dark">
            <AddIgnoreRuleDialog
              clusterId={clusterId}
              matchUrl={matches[0].origin.url}
              onClose={this.handleClose}
              onSuccess={this.handleRuleCreationSuccess}
              queueAsyncAction={this.queueAsyncAction}
            />
          </Modal>
          <IgnoreDialog
            isOpen={this.state.displayIgnoreDialog && !this.state.displayIgnoreRuleDialog}
            selection={[clusterId]}
            onClose={this.handleIgnoreDialogClose}
            onIgnoreDomain={this.handleIgnoreRuleDialogOpen}
          />
          <div className={headerContainer}>
            <GridWrappingColumn maxWidth={1300} padding={50} fluid>
              <ViewerHeader
                cluster={cluster}
                matches={matches}
                flag={flag}
                hide={hide}
                onIgnoreModalOpen={this.handleIgnoreModalOpen}
                showMobileActionsMenu={this.state.showMobileActionsMenu}
                location={location}
                activeMatch={activeMatch}
                user={user}
              />
            </GridWrappingColumn>
          </div>

          <div className={closeButton} onClick={this.handleClose}>
            <i dangerouslySetInnerHTML={{ __html: require('./close.svg') }} />
          </div>

          <div className={modalContent} ref="modalContent">
            <div className={modalContentInner}>
              {matches.length > 1 ? (
                <CardGrid
                  key={`${parent}/${clusterId}`}
                  activeMatchIndex={activeMatchIndex}
                  matches={matches}
                  location={location}
                  linkbase={`${parent}/view/${clusterId}`}
                  renderDetails={(activeLinkElement) => (
                    <div className={fullRowForDetails}>
                      <ScrollOnMount
                        key={`${parent}/${clusterId}/scroll`}
                        container={refs.modalContent}
                        element={activeLinkElement}
                        offset={scrollDist}
                      >
                        <MatchDetails
                          key={activeMatch._id}
                          match={activeMatch}
                          image={activeMatch.image}
                          cluster={cluster}
                          clusterLength={cluster.matches.length}
                          onSingleMatchHideApproved={this.handleSingleMatchHideApproved}
                          onSingleMatchHideInvalid={this.handleSingleMatchHideInvalid}
                          onSingleMatchHideReset={this.handleSingleMatchHideReset}
                          onSingleMatchHideIgnore={this.handleSingleMatchHideIgnore}
                          showMobileActionsMenu={this.state.showMobileActionsMenu}
                        />
                      </ScrollOnMount>
                    </div>
                  )}
                />
              ) : (
                <div className={detailsStandaloneWrapper}>
                  <MatchDetails
                    key={matches[0]._id}
                    match={matches[0]}
                    image={matches[0].image}
                    cluster={cluster}
                    onSingleMatchHideApproved={this.handleSingleMatchHideApproved}
                    onSingleMatchHideInvalid={this.handleSingleMatchHideInvalid}
                    onSingleMatchHideReset={this.handleSingleMatchHideReset}
                    onSingleMatchHideIgnore={this.handleSingleMatchHideIgnore}
                    showMobileActionsMenu={this.state.showMobileActionsMenu}
                  />
                </div>
              )}
            </div>
          </div>

          <KeyboardOverlay isVisible={showKeyboardOverlay} />

          {clustersBefore > 0 && (
            <div className={leftArrow} onClick={this.handlePrevious}>
              <IconArrowLeft />
              <span>{clustersBefore}</span>
            </div>
          )}
          {clustersAfter > 0 && (
            <div className={rightArrow} onClick={this.handleNext}>
              <IconArrowRight />
              <span>{clustersAfter}</span>
            </div>
          )}
        </HotKeys>
        <div css={mobileBarBottom}>
          <div className={clustersBefore === 0 ? 'disabled' : undefined} onClick={this.handlePrevious}>
            <span>{clustersBefore}</span>
            <IconArrowLeft />
          </div>

          <Button onClick={this.handleToggleMobilePopup}>
            {this.state.showMobileActionsMenu ? 'Hide Actions' : 'Actions'}
          </Button>

          <div className={clustersAfter === 0 ? 'disabled' : undefined} onClick={this.handleNext}>
            <IconArrowRight />
            <span>{clustersAfter}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state, props) => {
    const {
      location: { pathname },
      clusters,
      clusterId,
      total,
      pageSize,
    } = props

    const { page } = getClustersQuery(state, props)
    const position = clusters.findIndex((c) => c._id === clusterId)
    const parent = pathname.split('/view/')[0]

    const clustersBefore = pageSize * page + position
    const clustersAfter = total - 1 - pageSize * page - position

    return {
      position,
      parent,
      page,
      clustersBefore,
      clustersAfter,
      cluster: assembleClusterById(state, props),
    }
  },
  { flag, hide, touch, updateMatch, removeRule }
)(
  redirectWhen({
    shouldRedirect: (props) => !props.cluster,
    to: (props) => props.parent,
  })(withCookies(Viewer))
)

Viewer.propTypes = {
  removeRule: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired, // eslint-disable-line
  clusters: PropTypes.array.isRequired,
  cluster: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  parent: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  clusterId: PropTypes.string.isRequired,
  matchId: PropTypes.string,
}

function IconArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1893.723 1967.407 28.447 56.187">
      <defs>
        <style>
          {`.svg-viewer-arrow-left {
        fill: none;
        stroke: #fff;
      }`}
        </style>
      </defs>
      <g id="Group_791" transform="translate(-1971 1490)">
        <line id="Line_4" className="svg-viewer-arrow-left" x1="27.74" y1="27.74" transform="translate(77.63 505.5)" />
        <line id="Line_5" className="svg-viewer-arrow-left" x1="27.74" y2="27.74" transform="translate(77.63 477.76)" />
      </g>
    </svg>
  )
}

function IconArrowRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-82.723 1971.407 28.447 56.187">
      <defs>
        <style>
          {`.svg-viewer-arrow-right {
        fill: none;
        stroke: #fff;
      }`}
        </style>
      </defs>
      <g id="Group_782" transform="translate(23 2505) rotate(180)">
        <line id="Line_4" className="svg-viewer-arrow-right" x1="27.74" y1="27.74" transform="translate(77.63 505.5)" />
        <line
          id="Line_5"
          className="svg-viewer-arrow-right"
          x1="27.74"
          y2="27.74"
          transform="translate(77.63 477.76)"
        />
      </g>
    </svg>
  )
}

const modalContainer = css`
  background-color: #262626;
  color: #fff;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  // Resolves Blue border in Match viewer
  div[tabindex='-1']:focus {
    outline: 0;
  }
`

const headerContainer = css`
  height: 180px;
  padding-top: 60px;
  background-color: #343434;
  box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.53);
  z-index: 1;
  position: relative;

  @media (max-width: 1280px) {
    height: 120px;
    padding: 30px 10px 0px 20px;
  }

  @media (max-width: 768px) {
    height: 100px;
    padding: 30px 10px 0px 20px;
  }

  h1 {
    color: #fff;
    margin: 0;
    font-size: 52px;
    @media (max-width: 1280px) {
      padding-top: 8px;
      font-size: 32px;
    }
    @media (max-width: 768px) {
      padding-top: 4px;
      font-size: 24px;
    }
  }

  h4 {
    color: #ccc;
    margin: 0;
    text-transform: uppercase;
    font-size: 1.1em;
    letter-spacing: 0.25em;
  }
`

const modalContent = css`
  height: calc(100vh - 180px);
  overflow-y: overlay;
  padding-top: 30px;

  @media (max-width: 1280px) {
    height: calc(100vh - 120px);
    padding-top: calc(50vh - 270px);
  }

  @media (max-width: 1000px) {
    padding-top: calc(50vh - 220px);
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    padding-top: 40px;
  }
`

const modalContentInner = css`
  margin: 0 auto;
  height: 100%;
  max-height: calc(100vh - 180px);

  @media (max-width: 1280px) {
    height: calc(100vh - 120px);
  }

  @media (min-width: 992px) {
    width: 85%;
  }

  @media (min-width: 1200px) {
    width: 90%;
  }

  @media (min-width: 1440px) {
    width: 1300px;
  }

  @media (max-width: 768px) {
    width: 85%;
    height: calc(100vh - 120px);
    position: fixed;
    // top: calc(100px + 73vw);
    top: calc(100px + 66.33vw);
    // left: 5%;
    left: 0;
    width: auto;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
  }
`

const closeButton = css`
  position: fixed;
  z-index: 2;
  top: 15px;
  right: 15px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  text-align: center;
  letter-spacing: 2px;
  user-select: none;
  padding: 5px;

  @media (max-width: 1280px) {
    height: 30px;
    width: 30px;
    top: 10px;
    right: 10px;
  }

  svg {
    transform: scale(1);
    transition: transform 200ms ease;
    g {
      stroke: #fff;
      stroke-width: 2px;
    }
  }

  &:hover {
    color: #323232;
  }

  &:hover svg {
    transform: scale(1.1);
  }
`

const leftArrow = css`
  font-size: 0.75em;
  letter-spacing: 0.2em;
  position: fixed;
  top: 50%;
  height: 80px;
  width: 60px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  user-select: none;
  left: 10px;

  @media (max-width: 768px) {
    display: none;
  }

  svg {
    display: block;
    height: 36px;
    stroke-width: 3px;
    transition: opacity 150ms;
    &:hover {
      opacity: 0.7;
    }
  }

  span {
    display: block;
    margin-top: 10px;
    font-size: 1.1em;
    letter-spacing: 0.15em;
    color: #b4b4b4;
    text-align: center;
  }
`

const rightArrow = css`
  font-size: 0.75em;
  letter-spacing: 0.2em;
  position: fixed;
  top: 50%;
  height: 80px;
  width: 60px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  user-select: none;
  right: 10px;

  @media (max-width: 768px) {
    display: none;
  }

  svg {
    display: block;
    height: 36px;
    stroke-width: 3px;
    transition: opacity 150ms;
    &:hover {
      opacity: 0.7;
    }
  }

  span {
    display: block;
    margin-top: 10px;
    font-size: 1.1em;
    letter-spacing: 0.15em;
    color: #b4b4b4;
    text-align: center;
  }
`

const fullRowForDetails = css`
  width: 100%;
  @media (max-width: 768px) {
    padding-top: 0;
    position: fixed;
    top: 50vw;
  }
`

const detailsStandaloneWrapper = css`
  display: auto;
`

const mobileBarBottom = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.3);
    z-index: 30;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: stretch;
    background-color: #262626;

    > div {
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      > span {
        display: inline-block;
        margin: 2px;
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.1em;
      }
    }

    button {
      padding: 8px 0;
      width: 180px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
`
