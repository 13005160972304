import React from 'react'
import { SHOPIFY } from 'pixsy-constants'
import { Button, TextFieldNoLabel } from 'common'
import { reduxForm, Field } from 'redux-form'
import { css } from 'react-emotion'

export function ShopifyDialog({ submitting, handleSubmit, valid }) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Importing from Shopify</h1>
      <p>
        Pixsy can automatically connect to your Shopify account and import your
        product images.
      </p>

      <p>
        In order to import your images from Shopify, you need to provide the
        <b> store name</b> (this can be found in your admin settings) and grant
        Pixsy access to your store via the Shopify API.
      </p>

      <p>
        <i>Example:</i>
        <br />
        <b>example-store-1</b>
      </p>

      <div css={actionArea}>
        <div css={fieldRow}>
          <Field
            component={TextFieldNoLabel}
            type="text"
            name="shopifyShop"
            label="Store Name"
            labelAlign="right"
          />
        </div>

        <Button submit disabled={!valid || submitting}>
          Import
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'shopifyImport',
  validate(values) {
    if (!values.shopifyShop) return { shopifyShop: 'Shop Name is required!' }
  },
  onSubmit(values) {
    window.location = `/api/auth/${SHOPIFY}?shop=${values.shopifyShop}`
  },
})(ShopifyDialog)

ShopifyDialog.propTypes = {}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  em {
    color: #008aab;
  }
`

const actionArea = css`
  width: 100%;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  input[type='text'] {
    width: 100%;
  }

  button {
    margin-bottom: 10px;
    max-width: 240px;
    margin: 0 auto;
  }
`

const fieldRow = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 20px;
`
