import React from 'react'
import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import UncheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckedIcon from '@material-ui/icons/CheckBoxOutlined'
import IndeterminateCheckboxIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

const useStyles = makeStyles((theme) => {
  return {
    checkbox: {
      padding: 0,
      fontSize: '2rem',
      color: 'hsl(0,0%, 40%)',
      '& svg > path:nth-of-type(2)': {
        fill: 'hsl(0,0%, 90%)',
      },
    },
    label: {
      fontSize: '1.2rem',
      fontFamily: 'inherit',
      paddingLeft: 5,
    },
    biggerLabel: {
      fontSize: '1.5rem',
      fontFamily: 'inherit',
      paddingLeft: 5,
    },
  }
})

const CheckBoxWithTitle = ({ title, checked = false, onChange, indeterminate = false, large = false }) => {
  const styles = useStyles()
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          icon={<UncheckIcon fontSize="inherit" />}
          checkedIcon={<CheckedIcon fontSize="inherit" />}
          indeterminateIcon={<IndeterminateCheckboxIcon fontSize="inherit" />}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          className={styles.checkbox}
        />
      }
      label={title}
      classes={{ label: large ? styles.biggerLabel : styles.label }}
    />
  )
}

export default React.memo(CheckBoxWithTitle)