import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import { Dialog } from 'common'
import { withRouter } from 'react-router'
import RemoveServiceDialog from './RemoveServiceDialog'
import { notifSend } from 'redux-notifications/lib/actions'
import { synchronize, removeService } from '../../../../redux/modules/images'
import { humanize } from 'pixsy-constants'

class OAuthImport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      requestRemove: false,
    }

    const DISABLED_IMPORTS = global.DISABLED_IMPORTS || ''
    this._disabled = DISABLED_IMPORTS.split(',').includes(props.service)
  }

  handleUpdate = e => {
    if (this._disabled) return
    const { synchronize, notifSend, service } = this.props
    synchronize(this.props.service, null)
    notifSend({
      message: `Importing photos from ${humanize(service)}`,
      kind: 'success',
      dismissAfter: 6e3,
    })
    e.preventDefault()
    e.stopPropagation()
  }

  handleRemove = e => {
    e.stopPropagation()
    this.setState({ requestRemove: true })
  }

  removeService = () => {
    const { removeService, service } = this.props
    removeService(service)
    this.setState({ requestRemove: false })
  }

  render() {
    const { source, children, service, loading, loadingService } = this.props
    const { requestRemove } = this.state

    const connected = source && source.id

    return connected ? (
      <React.Fragment>
        {React.cloneElement(children, {
          connected: true,
          onUpdate: this.handleUpdate,
          onRemove: this.handleRemove,
          loading,
          loadingService,
        })}
        <Dialog
          isOpen={requestRemove}
          onRequestClose={() => {
            this.setState({ requestRemove: false })
          }}
        >
          <RemoveServiceDialog
            onCancel={() => {
              this.setState({ requestRemove: false })
            }}
            onConfirm={this.removeService}
          />
        </Dialog>
      </React.Fragment>
    ) : (
      <a href={'/api/auth/' + service}>
        {React.cloneElement(children, {
          connected: false,
          onUpdate: this.handleUpdate,
          onRemove: this.handleRemove,
        })}
      </a>
    )
  }
}

export default withRouter(
  connect(
    (
      { auth: { user }, images: { loading, loadingService } },
      { location, service }
    ) => {
      const queryStringParams = qs.parse(String(location.search).substr(1))

      return {
        source: user.sources[service],
        executeSyncForService: queryStringParams.synchronize,
        loading,
        loadingService,
      }
    },
    { synchronize, removeService, notifSend }
  )(OAuthImport)
)

OAuthImport.propTypes = {
  source: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  service: PropTypes.string.isRequired,
  removeService: PropTypes.func.isRequired,
  synchronize: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}
