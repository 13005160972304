import React from 'react'
import { Transition, animated, config } from 'react-spring/renderprops.cjs'

const AnimatedList = ({ items, keys, children }) => (
  <Transition
    native
    initial={null}
    items={items}
    keys={keys}
    from={{ transform: 'scaleY(0)', height: 0, opacity: 0 }}
    enter={{ transform: 'scaleY(1)', height: 'auto', opacity: 1 }}
    leave={{ transform: 'scaleY(0)', height: 0, opacity: 0 }}
    config={config.gentle}
  >
    {item => animationProps => (
      <animated.div style={animationProps}>{children(item)}</animated.div>
    )}
  </Transition>
)

export default AnimatedList
