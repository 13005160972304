import * as React from 'react'
import { css, cx } from 'react-emotion'

const pixsyPage = css`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: -100px; /* TODO: maybe handle this better (by default there is 100px padding-top) */
`

export const PixsyPage = ({ children, className, style = {} }) => {
  const ownStyle = css(style)
  return (
    <div data-type="pixsy-page" className={cx(pixsyPage, ownStyle, className)}>
      {children}
    </div>
  )
}
