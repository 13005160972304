import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import MatchTilesSwitch from '../../../components/MatchTilesSwitch'
import sharedStyles from '../../../components/styles'
import ImagesInfo from '../../../components/ImagesInfo'
import ButtonsWrapper from '../../../components/ButtonsWrapper'
import { actionCreators } from '../../../store'
import ClusterHeader from '../../../components/ClusterHeader'
import IgnoredMatchItem from '../../../components/IgnoredMatchItem'
import AnimatedComponentSwitcher from '../../../components/AnimatedComponentSwitcher'
import resolveValuesFromTags from '../../../utils/resolveValuesFromTags'
import resolveValuesFromDomain from '../../../utils/resolveValuesFromDomain'
import getClusterTitle from '../../../utils/getClusterTitle'
import styled from 'react-emotion'
import useClusters from '../../../utils/useClusters'

const ClusterListItem = ({ cluster, globalDispatch }) => {
  const { ignoreCluster, flagCluster, dispatch } = useClusters(globalDispatch)
  const isMultiMatch = cluster.matches.length > 1
  const isClusterIgnored = cluster.matches.every((match) => match.ignored)
  const { countryName, isTopMatch, isFlagged, isPixsyFlagged } = resolveValuesFromTags(cluster.tags)
  const { categoryName } = resolveValuesFromDomain(cluster.domain)

  const handleMoreModalOpenRequest = useCallback(
    () =>
      dispatch(actionCreators.setMoreModalViewOpen(cluster._id, isMultiMatch ? null : cluster.matches[0]._id, cluster)),
    []
  )

  const handleActionsModalViewOpenRequest = useCallback(
    () => dispatch(actionCreators.setActionsModalViewOpen(cluster._id, cluster.matches[0]._id, cluster, true)),
    []
  )

  const handleOpenMatchGroupViewOpenRequest = useCallback(
    () => dispatch(actionCreators.setGroupMatchesViewOpen(cluster._id, cluster)),
    []
  )

  const handleDetailsViewOpenRequest = useCallback(() => {
    if (isMultiMatch) {
      dispatch(actionCreators.setGroupMatchesViewOpen(cluster._id, cluster))
    } else {
      dispatch(actionCreators.setDetailsModalViewOpen(cluster._id, cluster.matches[0]._id, cluster))
    }
  }, [])

  const handleIgnoreClusterRequest = useCallback((ignoreStatus) => {
    ignoreCluster({
      variables: {
        clusterId: cluster._id,
        state: ignoreStatus,
      },
    }).catch((e) => console.error(e))
  }, [])

  const handleFlagClusterRequest = useCallback(() => {
    flagCluster({ variables: { clusterId: cluster._id } }).catch((e) => console.error(e))
  }, [])

  return (
    <AnimatedComponentSwitcher condition={isClusterIgnored}>
      {(isIgnored) =>
        isIgnored ? (
          <IgnoredMatchItem cluster={cluster} undoIgnore={() => handleIgnoreClusterRequest(false)} />
        ) : (
          <div data-clusterid={cluster._id} className={sharedStyles.clusterListItemContainer}>
            <GroupWrapperStyled>
              <div>
                <ClusterHeader
                  countryName={countryName}
                  categoryName={categoryName}
                  isTopMatch={isTopMatch}
                  score={cluster.score}
                  domain={cluster.domain.host}
                  displayName={getClusterTitle(cluster)}
                  caseId={cluster.case}
                  takedownId={cluster.takedown}
                />
                <div style={{ display: 'flex' }}>
                  <MatchTilesSwitch
                    image={cluster.images[0]}
                    matches={cluster.matches}
                    expandView={handleDetailsViewOpenRequest}
                  />
                  <ImagesInfo cluster={cluster} />
                </div>
              </div>
              <ButtonsWrapper
                isMultiMatch={isMultiMatch}
                isFlagged={isFlagged}
                isPixsyFlagged={isPixsyFlagged}
                onOpenMoreModal={handleMoreModalOpenRequest}
                onIgnoreRequest={() => handleIgnoreClusterRequest(true)}
                onFlagClusterRequest={handleFlagClusterRequest}
                openTakeActionModal={handleActionsModalViewOpenRequest}
                onOpenGroupClick={handleOpenMatchGroupViewOpenRequest}
                caseId={cluster.case}
                takedownId={cluster.takedown}
              />
            </GroupWrapperStyled>
          </div>
        )
      }
    </AnimatedComponentSwitcher>
  )
}

ClusterListItem.whyDidYouRender = true

export default connect(null, (globalDispatch) => ({ globalDispatch }))(ClusterListItem)

const GroupWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px #e8e8e8 solid;
  box-shadow: #00000010 0 1px 10px;
  background: #ffffff;
`
