import React, { useCallback, useContext, useMemo } from 'react'
import { css, cx } from 'react-emotion'
import { PixsyFormContext } from '../PixsyForm/PixsyForm'
import { PixsyGrid } from '../PixsyGrid/PixsyGrid'
import { PixsyGridItem } from '../PixsyGrid/PixsyGridItem'

/**
 * @see PixsyTextField
 * @see PixsyForm
 */
export const PixsyRadio = React.memo(
  ({
    id,
    name,
    value,
    label,
    text,
    flexBasis,
    disabled,
    variant,
    height = 40,
    validateOnChange = false,
    validateOnBlur = false,
    validateOtherPaths,
    validateEntireForm,
    fieldContext,
    fieldContextOnChange,
    fieldContextOnBlur,
    keepPristine = false,
  }) => {
    const variantStyle = variantStyles[variant] || defaultVariant
    const dynamicStyle = css({ height: `${height}px` })

    const api = useContext(PixsyFormContext)
    const currentValue = api.getValue(name)

    const contextOnChange = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnChange || {}),
      }),
      [fieldContext, fieldContextOnChange]
    )
    const contextOnBlur = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnBlur || {}),
      }),
      [fieldContext, fieldContextOnBlur]
    )

    const handleValidateOtherPaths = useCallback(
      ctx => {
        if (validateOtherPaths) {
          if (typeof validateOtherPaths === 'string') {
            api.handleValidationPath(validateOtherPaths, ctx)
          } else if (Array.isArray(validateOtherPaths)) {
            validateOtherPaths.forEach(otherName =>
              api.handleValidationPath(otherName, ctx)
            )
          }
        }
      },
      [validateOtherPaths]
    )

    const handleValidateCurrentPath = useCallback(
      ctx => {
        api.handleValidationPath(name, ctx)
        handleValidateOtherPaths(ctx)
      },
      [name]
    )

    const handleOnChange = useCallback(
      e => {
        api.handleChange(name, e.target.value, keepPristine)

        if (validateOnChange) {
          if (validateEntireForm) {
            return api.validateForm(null, null, contextOnChange)
          }
          handleValidateCurrentPath(contextOnChange)
        }
      },
      [
        name,
        keepPristine,
        validateOnChange,
        validateEntireForm,
        contextOnChange,
      ]
    )

    const handleOnBlur = useCallback(() => {
      api.notifyListenersOnBlurEvent(name)

      if (validateOnBlur) {
        if (validateEntireForm) {
          return api.validateForm(null, null, contextOnBlur)
        }
        handleValidateCurrentPath(contextOnBlur)
      }
    }, [name, validateOnBlur, validateEntireForm, contextOnBlur])

    return (
      <PixsyGridItem flexBasis={flexBasis}>
        <PixsyGrid direction="column">
          <PixsyGridItem fullWidth flex direction="column">
            <label
              className={cx(
                labelStyle,
                currentValue !== value && labelInactive,
                currentValue === value && variantStyle.labelFocus,
                currentValue !== value && variantStyle.label
              )}
              id={id}
            >
              <span className={cx(commonStyle, dynamicStyle)}>{label}</span>
              <input
                name={name}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                type="radio"
                value={value}
                checked={currentValue === value}
                disabled={!!disabled}
                className={cx(
                  commonStyle,
                  dynamicStyle,
                  inputStyle,
                  variantStyle.input
                )}
              />
            </label>
            {text && (
              <span
                className={cx(
                  textStyle,
                  currentValue === value && textActiveStyle
                )}
              >
                {text}
              </span>
            )}
          </PixsyGridItem>
        </PixsyGrid>
      </PixsyGridItem>
    )
  }
)

const commonStyle = css`
  width: 100%;
  /* height: 40px; */
  min-width: 100%;
  max-width: 100%;
  display: block;

  &:focus {
    outline: 0 !important;
  }
`
const labelInactive = css`
  cursor: pointer;
  color: #000;
`
const labelStyle = css`
  color: #ffffff;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  > span {
    align-items: center;
    display: flex;
    font-size: 0.9em !important;
    font-weight: 400;
    justify-content: center;
    letter-spacing: 0.15em;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
  }
`
const inputStyle = css`
  top: 0;
  left: 0;
  appearance: none;
  background: #e8e8e8;
  border: none;
  margin: 0 !important;
  position: absolute;

  &:focus {
    border: 0 !important;
    outline: 1px solid #148aa9 !important;
    outline-offset: -2px !important;
    z-index: 1;
  }

  &:checked {
    background: #008aab;

    &:focus {
      border: 0 !important;
      outline: 1px solid white !important;
      outline-offset: -2px !important;
    }
  }
`
const textStyle = css`
  color: rgba(0, 0, 0, 0.1);
  padding: 7px 10px 0 10px;
  display: block;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
`
const textActiveStyle = css`
  color: #3b3b3b;
`

const defaultVariant = css`
  &:checked {
    background: #008aab;
  }
`
const greyVariant = css`
  &:checked {
    background: #555555;
  }
`
const redVariant = css`
  &:checked {
    background: #fff2f2;
  }
`
const whiteVariant = css`
  background: #ffffff;
  border: 1px solid #ebebeb;

  &:checked {
    background: #008aab;
  }
`
const darkWhiteVariant = css`
  background: #f7f7f7;

  &:checked {
    background: #008aab;
  }
`

const labelGrayColor = css`
  color: #505050;
`
const labelWhiteColor = css`
  color: #ffffff !important;
`
const labelBlackColor = css`
  color: #000 !important;
`
const variantStyles = {
  default: {
    input: defaultVariant,
    label: labelWhiteColor,
    labelFocus: labelWhiteColor,
  },
  grey: {
    input: greyVariant,
    label: labelWhiteColor,
    labelFocus: labelWhiteColor,
  },
  red: {
    input: redVariant,
    label: labelBlackColor,
    labelFocus: labelBlackColor,
  },
  white: {
    input: whiteVariant,
    label: labelGrayColor,
    labelFocus: labelWhiteColor,
  },
  darkWhite: {
    input: darkWhiteVariant,
    label: labelGrayColor,
    labelFocus: labelWhiteColor,
  },
}
