import React from 'react'
import { SquareIcon } from '../../../../components/Icons'
import MultiSelectRichList from '../../../../components/MultiSelectRichList'
import Collapsable from '../../../../components/Collapsable'
import useClusterFilters, { useHandleCollapsableOpen } from '../../../../utils/useClusterFilters'

const ActionsFilter = () => {
  const {
    mappedFiltersData: { actionItems: items },
    filters: { selectedActionIds: selectedIds },
    updateFilterState,
  } = useClusterFilters()

  const onChange = (ids) => updateFilterState({ selectedActionIds: ids })
  const onCollapsableOpen = useHandleCollapsableOpen()

  return (
    <Collapsable
      Icon={SquareIcon}
      text="Actioned"
      onOpen={onCollapsableOpen}
      content={
        <MultiSelectRichList
          items={items}
          selectedIds={selectedIds}
          onSelectionChange={onChange}
        />
      }
      badgeCount={selectedIds.length}
    />
  )
}

export default ActionsFilter
