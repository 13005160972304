import {
  QDefineConstEnums,
  QString,
  QEnumString,
  QSchema,
  prefixConstEnumValues,
  objectWithKeysAsValues,
  QNumber,
  QBoolean,
} from '../core'

export const US_USCO_STATUS_ENUM = QDefineConstEnums(
  'No',
  'Pending',
  'Yes',
  'Pixsy'
)

export const REGISTRATION_KEYS = objectWithKeysAsValues({
  us: 'us',
})
export const REGISTRATION_US_KEYS = objectWithKeysAsValues({
  id: 'id',
  status: 'status',
})

export const REGISTRATION_NAMES = {
  ...REGISTRATION_KEYS,
  [REGISTRATION_KEYS.us]: prefixConstEnumValues(
    REGISTRATION_KEYS.us,
    REGISTRATION_US_KEYS
  ),
}

// prettier-ignore
export const REGISTRATION_LABEL = {
  [REGISTRATION_NAMES.us.status]: `Have you registered your image with the US Copyright Office?`,
  [REGISTRATION_NAMES.us.id]: `Please enter the registration number supplied to you by the US Copyright Office`,
}

export const REGISTRATION_REQUIRED = {
  [REGISTRATION_NAMES.us.status]: true,
  [REGISTRATION_NAMES.us.id]: false,
  [REGISTRATION_NAMES.us.year]: false,
  [REGISTRATION_NAMES.us.registered]: false,
  [REGISTRATION_NAMES.us.title]: false,
}

export const REGISTRATION_US_TYPES = {
  [REGISTRATION_US_KEYS.status]: QEnumString(US_USCO_STATUS_ENUM, {
    title:
      'Please choose if you have registered your image with the US Copyright Office',
  }).required({
    title:
      'Please choose if you have registered your image with the US Copyright Office',
  }),
  [REGISTRATION_US_KEYS.id]: QString.when('status', {
    is: v =>
      [
        US_USCO_STATUS_ENUM.Pending,
        US_USCO_STATUS_ENUM.Yes,
        US_USCO_STATUS_ENUM.Pixsy,
      ].includes(v),
    otherwise: QString.transform(v => QString.default()),
  }),
  [REGISTRATION_US_KEYS.year]: QNumber,
  [REGISTRATION_US_KEYS.title]: QString,
  [REGISTRATION_US_KEYS.registered]: QBoolean,
}
export const Registration_US_Schema = QSchema.noUnknown(false).shape(
  REGISTRATION_US_TYPES
)

export const REGISTRATION_TYPES = {
  [REGISTRATION_KEYS.us]: Registration_US_Schema,
}
export const RegistrationSchema = QSchema.shape(REGISTRATION_TYPES)
