import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { css, cx } from 'react-emotion'
import { Button } from 'common'
import { updateUser } from '../../../redux/modules/auth'

const PhotographerCard = profileCardFactory(
  'photographer',
  'You’re creating great photos and want to monitor and protect them online'
)
const ArtistCard = profileCardFactory(
  'artist',
  'You’re creating art, illustrations, design or logos which are posted online as image files'
)
const ShopCard = profileCardFactory(
  'shop',
  'You’re an online business with product images, artwork and logos'
)
const AgencyCard = profileCardFactory(
  'agency',
  'You’re representing a group of photographers or artists and want to protect their rights'
)
export function ProfileTypeQuestionaire({ valid, submitting, handleSubmit }) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Profile Type</h1>

      <span>Your background helps us to personalize our service.</span>

      <div css={grid}>
        <Field name="type" label="Photographer" component={PhotographerCard} />
        <Field name="type" label="Artist / Designer" component={ArtistCard} />
        <Field name="type" label="Online Shop" component={ShopCard} />
        <Field name="type" label="Agency" component={AgencyCard} />
      </div>

      <Button submit disabled={!valid || submitting}>
        Continue
      </Button>
    </form>
  )
}

ProfileTypeQuestionaire.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const ProfileTypeQuestionaireWrapper = reduxForm({
  form: 'ProfileTypeQuestionaire',
  validate: values => ({
    ...(!values.type && { type: 'Choice required' }),
  }),
  onSubmit: (values, dispatch, { onComplete }) =>
    dispatch(updateUser(values)).then(onComplete),
})(ProfileTypeQuestionaire)

ProfileTypeQuestionaireWrapper.eligible = user =>
  !user.type ||
  (user.type === 'artist' &&
    (!user.artist.media || !user.artist.media.length)) ||
  (user.type === 'shop' && !user.shop) ||
  (user.type === 'agency' && !user.agency) ||
  (user.type === 'photographer' &&
    !(
      user.photographerFields &&
      typeof user.photographerFields.registeredForCopyright == 'boolean'
    ))

export default ProfileTypeQuestionaireWrapper

function profileCardFactory(key, title) {
  const icon = require(`./${key}Icon.svg`)
  const id = `profile-questionaire-${key}`
  return function SpecificProfileCard({ input, label }) {
    return (
      <label
        css={cx(card, input.value === key && active)}
        htmlFor={id}
        title={title}
      >
        <div className="inner-wrap">
          <div className="heading-wrap">
            <i
              className={'profile-questionaire-icon-' + key}
              dangerouslySetInnerHTML={{ __html: icon }}
            />
            <h5>{label}</h5>
          </div>
          <p>{title}</p>
          <input
            {...input}
            id={id}
            type="radio"
            value={key}
            onChange={e => input.onChange(e) && input.onBlur(e)}
            checked={input.value === 'key'}
          />
        </div>
      </label>
    )
  }
}

const card = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 40%;
  outline: 1px solid #999;
  // margin: 18px 20px;
  margin: 10px;
  padding: 20px 10px;
  opacity: 0.4;
  cursor: pointer;

  &:hover {
    opacity: 1;
    outline: 3px solid #008aab;
  }

  @media (min-width: 768px) {
    .inner-wrap {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  h5 {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000000;
    font-weight: 400;
    font-size: 1em;
  }
  i {
    display: block;
    font-style: normal;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    .inner-wrap {
      justify-content: flex-start;
      .heading-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        i,
        h5 {
          display: inline-block;
        }
        i {
          height: 20px;
          padding-right: 10px;
          margin: 0 0 4px 0;
          svg {
            height: 20px;
          }
        }
      }
    }
  }

  p {
    text-align: center;
    font-weight: 400;
    color: #333;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  .profile-questionaire-icon-photographer svg {
    height: 33px;
  }
  .profile-questionaire-icon-artist svg {
    height: 46px;
  }
  .profile-questionaire-icon-shop svg {
    height: 46px;
  }
  .profile-questionaire-icon-agency svg {
    height: 50px;
  }

  @media (max-width: 768px) {
    margin: 5px;
    padding: 10px;
    margin: 0px;
    padding: 0px;
    flex-basis: auto;
    width: 50%;
    text-align: center;
    outline: 0;

    .inner-wrap {
      border: 1px solid #999;
      margin: 1px;
      padding: 7px;
      // height: 200px;
      // height: 116px;
      height: 140px;
      width: 98%;
    }

    &:hover {
      opacity: 1;
      outline: 0;
      .inner-wrap {
        border-width: 2px;
        border-color: #008aab;
      }
    }

    h5 {
      width: 100%;
      font-size: 0.9em;
    }

    p {
      font-size: 10px;
      // display: none;
    }

    i {
      margin-bottom: 5px;
      display: none;
    }

    .profile-questionaire-icon-photographer svg {
      height: 23px;
    }
    .profile-questionaire-icon-artist svg {
      height: 26px;
    }
    .profile-questionaire-icon-shop svg {
      height: 26px;
    }
    .profile-questionaire-icon-agency svg {
      height: 30px;
    }
  }
`

const grid = css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-flow: row wrap;
  margin: 20px auto;
  /* padding: 40px; */
`

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const active = css`
  opacity: 1;
  outline: 3px solid #008aab;
`
