import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import * as React from 'react'
import { css, cx } from 'react-emotion'
import { PixsyGrid, PixsyGridItem, PixsyIconButton } from '../../../Components'

export class AdditionalLinksList extends React.PureComponent {
  state = {
    itemFocused: null,
  }
  handleFocus = url => () => this.setState({ itemFocused: url })
  handleBlur = () => this.setState({ itemFocused: null })

  render() {
    const { links = [], onRemoveLink, disabled, readOnly } = this.props
    const { itemFocused } = this.state

    return (
      <React.Fragment>
        {links.map(url => (
          <PixsyGrid
            key={url}
            wrap="nowrap"
            className={cx(linkStyle, itemFocused === url && linkFocusedStyle)}
            onMouseEnter={this.handleFocus(url)}
            onMouseLeave={this.handleBlur}
          >
            <PixsyGridItem flexGrow={1}>
              <label>{url}</label>
              <PixsyIconButton
                onClick={() => onRemoveLink(url)}
                onFocus={this.handleFocus(url)}
                onBlur={this.handleBlur}
                disabled={disabled || readOnly}
                color="#ff8181"
                icon={CloseIcon}
                size={16}
              />
            </PixsyGridItem>
          </PixsyGrid>
        ))}
      </React.Fragment>
    )
  }
}
AdditionalLinksList.propTypes = {
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemoveLink: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
}

const linkStyle = css`
  word-break: break-all;
  font-size: 14px;
  opacity: 0.65;
  label {
    font-weight: 400;
    display: inline;
  }
  button {
    margin: 0 0 0 5px;
    vertical-align: sub;
  }
`
const linkFocusedStyle = css`
  opacity: 1;
`
