import * as React from 'react'
import { css } from 'react-emotion'

const baseStyle = css`
  .icon-takedown-1 {
    stroke: #3b3b3b;
    stroke-width: 1px;
    fill: none;
  }
  .icon-takedown-2 {
    fill: #3b3b3b;
  }
  .icon-takedown-3 {
    stroke: none;
  }
  .icon-takedown-4 {
    fill: none;
  }
`

export default React.memo(({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-20355.063 -20487.809 29.123 30.617"
    css={baseStyle}
  >
    <g transform="translate(-21564.063 -20637.809)">
      <g className="icon-takedown-1" transform="translate(1209 150)">
        <rect className="icon-takedown-3" width="29.123" height="30.617" />
        <rect
          className="icon-takedown-4"
          x="1"
          y="1"
          width="27.123"
          height="28.617"
        />
      </g>
      <path
        className="icon-takedown-2"
        d="M1220.274,155.1l-5.314,9.025,7.156-.166-1.6,7.805,5.309-9.866-7.349.308Z"
        transform="matrix(0.995, 0.105, -0.105, 0.995, 26.353, -125.018)"
      />
    </g>
  </svg>
))
