import * as React from 'react'
import PropTypes from 'prop-types'
import { PixsyFormContext } from '../PixsyForm/PixsyForm'

/**
 * @see PixsyTextField
 *
 * Displays `children` or `render` whenever a field has an error,
 * and the error contains a message object with a `title` property
 */
function PixsyErrorMessageInner({ name, children, render, onlyWhenTouched }) {
  return (
    <PixsyFormContext.Consumer>
      {api => {
        const error = api.errors[name]
        if (!error) return

        const touched =
          (error.message && error.message.alwaysShow) || api.getTouched(name)
        if (onlyWhenTouched && !touched) return

        if (typeof error.message === 'object' && 'title' in error.message) {
          if (typeof render === 'function') return render({ name, error })
          return children
        }

        return null
      }}
    </PixsyFormContext.Consumer>
  )
}

export const PixsyErrorMessage = React.memo(PixsyErrorMessageInner)

PixsyErrorMessageInner.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  render: PropTypes.func,
  onlyWhenTouched: PropTypes.bool,
}
