import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentSection, StripeContext, LoadingOverlay } from 'common'
import { asyncConnect } from 'redux-connect'
import PlansHeader from './PlansHeader'
import Plans from './Plans'
import styled, { css } from 'react-emotion'
import { load as loadAuth, getUsage, subscribe, applyCoupon, fetchPaymentOptions } from '../../../redux/modules/auth'
import { loadCharge } from '../../../redux/modules/charges'
import { load as loadPlans } from '../../../redux/modules/plans'
import PaymentElement from '../../PaymentContainer/Payment'
import Charges from './Charges/charge'

const log = require('debug')('plans')

export class PlansContainer extends Component {
  constructor(props) {
    super()

    this.state = { billingCycle: props.user.plan.billing_period }
  }

  toggleBillingCycleView = () => {
    this.setState(({ billingCycle }) => ({
      billingCycle: billingCycle === 1 ? 12 : 1,
    }))
  }

  render() {
    const {
      subscribe,
      user,
      usage,
      plans,
      loading,
      charges,
      paymentMethods,
      applyCoupon,
      fetchPaymentOptions,
    } = this.props
    log('Considering for render: %O', plans)

    const { billingCycle } = this.state

    const activePlan =
      plans.find((p) => p._id === user.plan._id) ||
      plans.find((p) => p.billing_period === user.plan.billing_period && p.level === user.plan.level)
    log('Active plan: %O', activePlan)

    const needToShowFreePlan = user.plan.price > 0
    log('Needs to show free plan: %O', needToShowFreePlan)

    let plansToDisplay = plans.filter(
      (p) => p.billing_period === billingCycle || p._id === activePlan._id || (needToShowFreePlan && p.price === 0)
    )
    log('Plans to display [1]: %O', plansToDisplay)

    if (user.plan.price === 0) {
      plansToDisplay = plansToDisplay.filter((p) => p.price !== 0 || p._id === user.plan._id)
    }
    log('Plans to display [2]: %O', plansToDisplay)

    plansToDisplay = plansToDisplay.sort((a, b) => a.price - b.price)
    log('Plans to display [3]: %O', plansToDisplay)

    return (
      <LoadingOverlay show={loading}>
        <div css={mobile}>
          <PlansHeader user={user} usage={usage} />
          <Plans
            user={user}
            plans={plansToDisplay}
            subscribe={subscribe}
            loading={loading}
            activePlan={activePlan}
            paymentMethods={paymentMethods}
          />
          <div css={detailsWrapper}>
            <Toggle
              onClick={this.toggleBillingCycleView}
              checked={billingCycle === 12}
              disabled={plansToDisplay.length === 1}
            >
              <span>Yearly</span>
              <div>
                <div />
              </div>
              <span>Monthly</span>
            </Toggle>
            <PlansPriceDisclaimer>* All prices are $ USD</PlansPriceDisclaimer>
          </div>
          <ContentSection>
            {/* <StripeContext account="subscription"> */}
            {/* {user.stripe.last4 && !user.stripe.editing ? (
                  <RemoveCard user={user} />
                ) : ( */}
            <PaymentElement
              stripeUser={user.stripe}
              applyCoupon={applyCoupon}
              fetchPaymentOptions={fetchPaymentOptions}
            />
            {/* )} */}
            {/* </StripeContext> */}
          </ContentSection>
          <ContentSection>
            <Charges charges={charges} />
          </ContentSection>
        </div>
      </LoadingOverlay>
    )
  }
}

PlansContainer.propTypes = {
  user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  usage: state.auth.usage,
  paymentMethods: (state.auth.paymentOptions && state.auth.paymentOptions.paymentMethods) || [],
  charges: state.charges,
  plans: Object.values(state.entities.plans).filter((plan) => {
    // get people on old plan to upgrade..
    log('[PlansContainer][mapStateToPros][%s] Considering..', plan.stripe)
    const currentPlan = state.auth.user.plan

    let globalFamilyFilter = currentPlan.family

    const partnerships = ['flickr', 'cherrydeck-free', 'cherrydeck-pro', 'smugmug']

    const familiesEligibleToUpgradeToPixsyPlans = ['pixsy', 'pixsy.0716', 'friends', ...partnerships]
    if (familiesEligibleToUpgradeToPixsyPlans.includes(globalFamilyFilter)) {
      globalFamilyFilter = 'pixsy.0317'
    }

    log('[PlansContainer][mapStateToPros][%s] globalFamilyFilter: %s', plan.stripe, globalFamilyFilter)

    const isPassed =
      globalFamilyFilter === plan.family || currentPlan._id === plan._id || partnerships.includes(plan.family)

    log('[PlansContainer][mapStateToPros][%s] Result: %o', plan.stripe, isPassed)
    return isPassed
  }),
})

export default asyncConnect(
  [
    {
      promise: function fetchDataForPlans({ store: { getState, dispatch } }) {
        const promise = Promise.all([
          dispatch(loadCharge()),
          dispatch(loadAuth()),
          dispatch(loadPlans()),
          dispatch(getUsage()),
        ])
        return __SERVER__ && promise
      },
    },
  ],
  mapStateToProps,
  {
    subscribe,
    applyCoupon,
    fetchPaymentOptions,
  }
)(PlansContainer)

const PlansPriceDisclaimer = styled.div`
  margin: 4px 0 30px;
  display: inline-block;
  float: left;
  width: 25%;
  text-align: right;
  opacity: 0.5;
  font-size: 1em;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const Toggle = styled.div`
  margin: 0px 0 20px;
  width: 50%;
  float: left;
  display: block;
  margin-left: 25%;
  text-align: center;
  opacity: 1;
  font-size: 1.3em;
  user-select: none;
  visibility: ${(props) => (props.disabled ? 'hidden' : 'inherit')};

  > div {
    display: inline-block;
    cursor: pointer;
    width: 50px;
    height: 1.3em;
    background-color: rgba(235, 235, 235);
    position: relative;
    bottom: -0.3em;
    border-radius: 12px;
    margin: 0 15px;
    background-color: white;
    border: solid 2px rgb(230, 230, 230);
    position: relative;

    > div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      left: ${(props) => (props.checked ? '1' : '29')}px;
      background-color: #148aa9;
      border-radius: 50%;
      overflow: hidden;
      transition: all 200ms ease;
      margin: 0;

      &.active {
        float: right;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`

const detailsWrapper = css`
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
  background: transparent;
`

const mobile = css`
  @media (max-width: 768px) {
    margin: 0 10px;
  }
`
