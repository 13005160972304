import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Elements } from '@stripe/react-stripe-js'
import { SexySeparator } from 'common'
import { css } from 'react-emotion'
import SetupForm from './SetupForm'
import PaymentMethodsComponent from './PaymentMethods'

let stripePromise
// const deduceLocationApiURL = 'https://ifconfig.co/json';

const Payment = ({ stripeUser = {}, applyCoupon, fetchPaymentOptions }) => {
  const [client_secret, setClientSecret] = useState('')
  // const [clientLocation, setClientLocation] = useState(null);
  const [loading, setLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState()
  const [currentSubscription, setCurrentSubscription] = useState()
  const { payment_method_status } = stripeUser

  useEffect(() => {
    fetchPaymentMethods()

    return () => {
      stripePromise = null
    }
  }, [])

  const fetchPaymentMethods = async () => {
    setLoading(true)
    const res = await fetchPaymentOptions()
    const { paymentMethods, subs } = res.payload
    const { data } = paymentMethods
    const { data: subsData } = subs
    const currentSubs = subsData && subsData.find((item) => item.id === stripeUser.subscriptionId)
    setCurrentSubscription(currentSubs)
    setPaymentMethods(data)
    setLoading(false)
    return data
  }

  const fetchClientSecret = async () => {
    setLoading(true)
    const _stripeKey = window ? window.STRIPE_SUBSCRIPTION_PUBLISHABLE_KEY : ''

    stripePromise = await loadStripe(_stripeKey)
    let country = 'others'
    const response = await fetch('/api/user/secret/' + country)
    const { client_secret: clientSecret, id } = await response.json()
    localStorage.setItem('client_secret', id)
    setClientSecret(clientSecret)
    setLoading(false)
  }

  const makeDefault = async (id) => {
    await fetchPaymentMethods()
  }

  const options = {
    clientSecret: client_secret,
  }

  const addNewMethod = async () => {
    setPaymentMethods([])
    await fetchClientSecret()
  }

  const paymentIncomplete = () => {
    if (payment_method_status === 'requires_action' || payment_method_status === 'failed') {
      return (
        <span className={savedPaymentInfoRow}>
          Your last payment method is incomplete. Please add a new payment method.
        </span>
      )
    }
    return ''
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress thickness={5} css={circularProgressStyle} size="4rem" variant="indeterminate" />
      </div>
    )
  }
  if (paymentMethods && paymentMethods.length) {
    return (
      <div>
        <h1>Payment Method</h1>
        <SexySeparator space={20} />
        <PaymentMethodsComponent
          list={paymentMethods}
          subscription={currentSubscription}
          handleMakeDefault={makeDefault}
        />
        <SexySeparator space={20} />
        <div id="add-a-new-payment-method" className={removeCardButton} onClick={addNewMethod}>
          Add a new Payment Method
        </div>
        {paymentIncomplete()}
      </div>
    )
  }
  return (
    <div>
      <h1>Payment Method</h1>
      <SexySeparator space={20} />
      {stripePromise && client_secret ? (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm applyCoupon={applyCoupon} />
        </Elements>
      ) : (
        <div>
          <div id="add-a-new-payment-method" className={removeCardButton} onClick={addNewMethod}>
            Add a new Payment Method
          </div>
          {paymentIncomplete()}
        </div>
      )}
    </div>
  )
}

export default Payment

const savedPaymentInfoRow = css`
  text-align: center;
  font-weight: 300;
  width: 100%;
  font-size: 1.5em;
  margin-bottom: 6px;
  color: red;
`
const removeCardButton = css`
  color: rgb(150, 150, 150);
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  transition: color 250ms ease;

  :hover {
    color: 80, 80, 80;
  }
`
const circularProgressStyle = css`
  color: #212121 !important;
  margin: 5px 0;
  display: 'inline-block';
`
