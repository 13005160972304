import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import { resolveCountryNameForCode, CATEGORY_LABELS, DATA_ELEVIO } from 'pixsy-constants'
import { ChooseBestImageSource, LoadingOverlay, Modal, SexyThumbnail, SelectableContext, SelectableItem } from 'common'
import AddIgnoreRuleDialog from '../../AddIgnoreRuleDialog/AddIgnoreRuleDialog'
import { update, hide } from '../../../../../redux/modules/clusters'
import { remove as removeRule } from '../../../../../redux/modules/rules'
import ListCardItem from './ListCardItem'
import ActionButton from './ActionButton'
import Label from './Label'
import ImageUrls from './ImageUrls'
import * as Icon from '../Icons'
import * as Column from './Columns'
import getBestImageBasedOnTag from '../../../../../helpers/getBestImageBasedOnTag'
import PageTitleAndUrl from './PageTitleAndUrl'
import IgnoreDialog from '../ActionsPanel/IgnoreDialog'
import { HotKeys } from 'react-hotkeys'

class ViewCardList extends PureComponent {
  state = {
    displayIgnoreDialog: false,
    displayIgnoreRuleDialog: false,
  }

  resetView = (cb) => {
    this.setState(
      {
        displayIgnoreDialog: false,
        displayIgnoreRuleDialog: false,
      },
      cb
    )
  }

  handleIgnoreDialogOpen = (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreDialog: true })
  }

  handleIgnoreDialogClose = (e) => {
    e && e.preventDefault()
    if (this.state.displayIgnoreDialog) this.setState({ displayIgnoreDialog: false })
  }

  handleIgnoreRuleDialogOpen = () => (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreRuleDialog: true })
  }

  handleIgnoreRuleDialogClose = (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreRuleDialog: false })
  }

  handleUnignore = () => {
    this.props.hide(this.props.cluster._id, false)
  }

  keyMap = {
    close: 'esc',
  }

  render() {
    const { cluster, index, loading, location, tags, update } = this.props
    const { matches, images, domain = {} /*, created */ } = cluster
    const { displayIgnoreDialog, displayIgnoreRuleDialog } = this.state

    const [match, image] = getBestImageBasedOnTag(tags, matches, images)
    const country = resolveCountryNameForCode(domain.country)
    const flagged = cluster.tags.includes('flagged')
    const isClusterIgnored = cluster.tags.includes('hidden:true')
    const clusterId = cluster._id
    const isClusterApproved = cluster.matches.every((m) => m.approved)
    const latestMatchDate = Math.max(...cluster.matches.map((m) => new Date(m.created).getTime()))
    const isSortingByDate = location.search.includes('sort=-matches.created')

    const destination = {
      ...location,
      pathname: location.pathname.split('/view')[0] + '/view/' + cluster._id,
      state: {
        ...(location.state || {}),
        lastLocation: {
          pathname: location.pathname,
          search: location.search,
          state: location.state,
        },
      },
    }

    return (
      <React.Fragment>
        <LoadingOverlay show={loading}>
          <Modal isOpen={displayIgnoreRuleDialog} onRequestClose={this.handleIgnoreRuleDialogClose} theme="light">
            <AddIgnoreRuleDialog
              location={location}
              clusterId={clusterId}
              matchUrl={matches[0].origin.url}
              onClose={this.handleIgnoreRuleDialogClose}
              white
            />
          </Modal>
          <IgnoreDialog
            isOpen={displayIgnoreDialog && !displayIgnoreRuleDialog}
            selection={[clusterId]}
            loading={loading}
            onClose={this.handleIgnoreDialogClose}
            onIgnoreDomain={this.handleIgnoreRuleDialogOpen}
          />
          <HotKeys
            keyMap={this.keyMap}
            handlers={{
              close: () => this.resetView(),
            }}
            focused
            attach={global}
          />
        </LoadingOverlay>
        <ChooseBestImageSource.ForCluster cluster={cluster} preferredIage={image._id} type="match" useCache>
          {({ url, isError }) => (
            <ListCardItem ignored={isClusterIgnored} approved={isClusterApproved}>
              <SelectableContext.Consumer>
                {({ isSelecting }) => (
                  <SelectableItem id={cluster._id}>
                    <SexyThumbnail
                      url={url}
                      className={wrapThumbnail}
                      linkDestination={!isSelecting && destination}
                      ignored={isClusterIgnored}
                      approved={isClusterApproved}
                      isError={isError}
                      caption={
                        matches.length > 1 && (
                          <div css={groupSizeIndicator}>
                            <div>
                              <em>{matches.length}</em>
                              <span>Matches</span>
                            </div>
                          </div>
                        )
                      }
                    />
                  </SelectableItem>
                )}
              </SelectableContext.Consumer>

              <Column.Left>
                {isSortingByDate ? (
                  <Label
                    header="updated"
                    title={`Detected ${moment(cluster.created).format('LL')}`}
                    text={moment(latestMatchDate).format('LL')}
                  />
                ) : (
                  <Label
                    header="First detected"
                    title={`Updated ${moment(latestMatchDate).format('LL')}`}
                    text={moment(cluster.created).format('LL')}
                  />
                )}

                {country && <Label header="Country" text={country} title={domain.country} />}
                {domain.category && (
                  <Label
                    header="Category"
                    text={`${CATEGORY_LABELS[domain.category] || domain.category}`}
                    title={CATEGORY_LABELS[domain.category] || domain.category}
                  />
                )}
              </Column.Left>
              <Column.Middle>
                <PageTitleAndUrl matches={matches} />

                <ImageUrls
                  title={match.origin && match.origin.title ? 1 : Math.min(2, matches.length)}
                  matches={matches}
                />
              </Column.Middle>
              <Column.Right>
                <Link to={destination}>
                  <ActionButton text="TAKE ACTION" disabled={isClusterIgnored} icon={<Icon.MagnifierGlass />} />
                </Link>
                <ActionButton
                  text="FLAG"
                  active={flagged}
                  disabled={isClusterIgnored}
                  icon={<Icon.Flag active={flagged && !isClusterIgnored} />}
                  onClick={update.bind(null, cluster._id, {
                    [flagged ? '$pull' : '$addToSet']: {
                      tags: 'flagged',
                    },
                  })}
                  {...(index === 0 ? { 'data-elevio-selector': DATA_ELEVIO.MATCHES_FLAG } : {})}
                />
                <ActionButton
                  text={isClusterIgnored ? 'UNDO IGNORE' : 'IGNORE'}
                  active={isClusterIgnored}
                  icon={<Icon.Ignore />}
                  onClick={isClusterIgnored ? this.handleUnignore : this.handleIgnoreDialogOpen}
                />
              </Column.Right>
            </ListCardItem>
          )}
        </ChooseBestImageSource.ForCluster>
      </React.Fragment>
    )
  }
}

ViewCardList.propTypes = {
  index: PropTypes.number.isRequired,
  cluster: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  update: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
}

export default connect(null, { hide, update, removeRule })(ViewCardList)

const wrapThumbnail = css`
  min-width: 325px;
  max-width: 325px;
`
const groupSizeIndicator = css`
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 40%);
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: flex-end;
  color: #fff;
  text-align: center;
  font-weight: 600;
  // text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.50);
  padding: 0 0 5px 10px;

  > * {
    display: block;
    margin: 0;
  }

  em {
    display: block;
    font-size: 1.8em;
    font-style: normal;
    position: relative;
    bottom: -10px;
  }

  span {
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: block;
    opacity: 0.5;
  }
`