import React, { useMemo } from 'react'
import { CalendarIcon } from '../../../../components/Icons'
import YearRangeSlider from '../../../../components/YearRangeSlider'
import Collapsable from '../../../../components/Collapsable'
import { constants, selectors } from '../../../../store'
import useClusterFilters, {
  useHandleCollapsableOpen,
} from '../../../../utils/useClusterFilters'

const { YEAR_MAX, YEAR_MIN } = constants

const DateFoundFilter = () => {
  const {
    filters: { selectedYearRange },
    updateFilterState,
  } = useClusterFilters()

  const hasYearRangeChanged = useMemo(
    () => selectors.isYearRangeChanged({ selectedYearRange }),
    selectedYearRange
  )
  const onChange = ids => updateFilterState({ selectedYearRange: ids })
  const onCollapsableOpen = useHandleCollapsableOpen()

  return (
    <Collapsable
      Icon={CalendarIcon}
      text="Date Found"
      onOpen={onCollapsableOpen}
      content={
        <YearRangeSlider
          selectedYearRange={selectedYearRange}
          onSelectedYearRange={onChange}
          max={YEAR_MAX}
          min={YEAR_MIN}
        />
      }
      badgeCount={hasYearRangeChanged ? 1 : null}
    />
  )
}

export default DateFoundFilter
