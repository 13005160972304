import React from 'react'
import { css } from 'react-emotion'
import { Pagination } from 'common'
import sharedStyles from '../../../components/styles'
import Header from './Header'
import useClusterFilters from '../../../utils/useClusterFilters'
import {
  ActionsFilter,
  CategoriesFilter,
  CountriesFilter,
  DateFoundFilter,
  ImagesFilter,
  KeywordFilter,
} from './CollapsableGroup/index'
import Toggles from './Toggles'

const ClusterFilters = () => {
  const { pageOptionProps } = useClusterFilters({ fromCache: false })

  return (
    <div css={styles.container}>
      <div css={styles.filtersBox}>
        <Header />
        <div css={styles.filterWrapper}>
          <Toggles />
          <div css={styles.filtersList}>
            <CountriesFilter />
            <CategoriesFilter />
            <KeywordFilter />
            <DateFoundFilter />
            <ImagesFilter />
            <ActionsFilter />
          </div>
        </div>
      </div>
      <Pagination
        containerClassName={sharedStyles.paginationInFilterPanel}
        pageRangeDisplayed={1}
        size="small"
        {...pageOptionProps}
      />
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    position: sticky;
    justify-content: center;
    top: 30px;
    left: 0;
    width: 290px;
    margin: 0 15px;
    font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
    user-select: none;
    border: 1px solid #dddddd;
    max-height: calc(100vh - 100px);

    @media only screen and (max-width: 960px) {
      display: none;
    }
  `,
  filtersBox: css`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #ffffff;
    overflow: auto;
  `,
  filtersList: css`
    display: flex;
    flex-direction: column;
  `,
  filterWrapper: css`
    display: flex;
    flex-direction: column;
  `,
}

ClusterFilters.whyDidYouRender = true

export default React.memo(ClusterFilters)
