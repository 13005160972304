import * as React from 'react'
import { css, cx } from 'react-emotion'
import { PixsyGrid } from '../PixsyGrid/PixsyGrid'
import { PixsyGridItem } from '../PixsyGrid/PixsyGridItem'

const ADDON_WIDTH = 26
const TOOLTIP_WIDTH = 26
const TOOLTIP_HEIGHT = 34

export const PixsyFormGroup = ({
  children,
  rightAddon,
  leftAddon,
  tooltip,
  className,
  style = {},
  padding,
  spacing = 2,
  direction,
  alignItems,
  alignTooltip,
}) => {
  const ownStyle = { padding }
  return (
    <PixsyGrid
      wrap="nowrap"
      className={cx(className, css(ownStyle), css(style))}
    >
      {leftAddon && <PixsyFormGroupAddon>{leftAddon}</PixsyFormGroupAddon>}
      <PixsyGridItem flexGrow={1}>
        <PixsyGrid
          spacing={spacing}
          direction={direction}
          alignItems={alignItems}
        >
          {children}
        </PixsyGrid>
      </PixsyGridItem>
      {rightAddon && <PixsyFormGroupAddon>{rightAddon}</PixsyFormGroupAddon>}
      {tooltip && (
        <PixsyFormGroupTooltip alignSelf={alignTooltip}>
          {tooltip}
        </PixsyFormGroupTooltip>
      )}
    </PixsyGrid>
  )
}

const PixsyFormGroupAddon = ({ children }) => (
  <PixsyGridItem className={addonStyle}>{children}</PixsyGridItem>
)
const PixsyFormGroupTooltip = ({ children, alignSelf }) => (
  <PixsyGridItem
    className={tooltipContainerStyle}
    flex
    direction="row"
    justify="center"
    alignSelf={alignSelf}
    alignItems="center"
  >
    {children}
  </PixsyGridItem>
)

const addonStyle = css`
  /* width: ${ADDON_WIDTH}px; */
  /* max-width: ${ADDON_WIDTH}px; */
  min-width: ${ADDON_WIDTH}px;
`
const tooltipContainerStyle = css`
  width: ${TOOLTIP_WIDTH}px;
  max-width: ${TOOLTIP_WIDTH}px;
  min-width: ${TOOLTIP_WIDTH}px;
  height: ${TOOLTIP_HEIGHT}px;
  min-height: ${TOOLTIP_HEIGHT}px;
  max-height: ${TOOLTIP_HEIGHT}px;
`
