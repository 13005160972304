import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ChooseBestImageSource, ClearbitLogo } from 'common'
import { css, cx } from 'react-emotion'
import URI from 'urijs'
import TakedownProgress from './TakedownProgress'
import TakedownStatus from './TakedownStatus'
import TakedownAction from './TakedownAction'
import { getProxyUrlToMatch } from 'pixsy-constants'

export default function TakedownOverviewRow({ takedown }) {
  const hostname = resolveHostname(takedown)

  const firstMatch = takedown.cluster.matches && takedown.cluster.matches[0]
  const firstImage = takedown.cluster.images && takedown.cluster.images[0]

  return (
    <tr key={takedown._id} css={takedownTableRow}>
      <td>
        <div css={previewImage}>
          <ChooseBestImageSource
            type="image"
            candidates={[
              firstMatch && firstMatch.url,
              firstImage && firstImage.url,
              firstMatch && getProxyUrlToMatch(firstMatch._id),
            ]}
          >
            {({ url, isError }) =>
              firstImage && firstImage._id ? (
                <Link to={`/images/${firstImage._id}`}>
                  <div
                    css={cx(previewImage, isError && previewImageError)}
                    style={{ backgroundImage: `url("${url}")` }}
                  />
                </Link>
              ) : (
                <div
                  css={cx(previewImage, isError && previewImageError)}
                  style={{ backgroundImage: `url("${url}")` }}
                />
              )
            }
          </ChooseBestImageSource>
          {takedown.done === takedown.total && <DoneCheckboxOverlay />}
        </div>
      </td>
      <td>
        <div css={summaryWrapper}>
          <span css={summaryWrapperLabel}>
            <ClearbitLogo host={hostname} fallbackLetter={hostname.charAt(0)} />
            <h4>{hostname}</h4>
          </span>
          <em>TDN-{takedown.reference}</em>
        </div>
      </td>
      <td>
        <TakedownStatus takedown={takedown} />
      </td>
      <td>
        <TakedownProgress takedown={takedown} />
      </td>
      <td>
        <TakedownAction takedown={takedown} />
      </td>
    </tr>
  )
}

TakedownOverviewRow.propTypes = {
  takedown: PropTypes.object.isRequired,
}

const previewImage = css`
  background-size: cover;
  width: 90px;
  height: 100%;
  min-height: 90px;
  position: relative;
`

const previewImageError = css`
  background-position-x: -21px;
`

const summaryWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h4 {
    margin: 0;
  }

  em {
    display: block;
    color: #00000080;
    font-style: normal;
    font-size: 0.8em;
    margin-top: 8px;
  }
`

const summaryWrapperLabel = css`
  display: inline-flex;
  align-items: center;
  img {
    height: 20px;
    margin-right: 6px;
  }
`

const takedownTableRow = css`
  background-color: #fff;

  td {
    height: 30px;
    padding: 0 10px 0 20px !important;
    vertical-align: middle !important;
    text-align: center;
    border-top: 0 !important;
    font-weight: 400;
    color: #000;

    > p > span {
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      max-width: 50ch;
    }

    &:nth-child(1) {
      /* image */
      padding: 0 !important;
    }

    &:nth-child(5) {
      /* status */
      padding-right: 30px !important;
    }
  }

  /* button {
    padding: 15px 30px;
    background-color: transparent;
    border: 1px solid #ccc;
    color: #000;
    opacity: 0.6;
    margin: 0 45px 0 10px;

    svg {
      height: 14px;
      vertical-align: -3px;
      margin-right: 5px;
    }

    &:hover {
      opacity: 1;
      background-color: transparent;
    }
  } */

  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    tr {
      border: 0;
      margin: 10px;
      padding: 10px 0;
    }
    tbody td {
      text-align: center !important;
      &:nth-child(6) {
        text-align: center !important;
      }
    }
  }
`

// const previewImage = css`
//   width: 90px;
//   height: 90px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;

//   a {
//     display: block;
//     height: 100%;
//   }

//   img {
//     display: block;
//     height: 100%;
//     width: 90px;
//   }

//   @media (max-width: 768px) {
//     margin-right: 0;
//     width: 100%;
//     background-color: rgba(0, 0, 0, 0.02);
//   }
// `

// const arrow = css`
//   margin-left: 7px;
//   height: 7px;
// `

function resolveHostname(c) {
  try {
    if (c.cluster && c.cluster.domain && c.cluster.domain.host) {
      return c.cluster.domain.host
    }
    if (
      c.submission.cluster &&
      c.submission.cluster.matches &&
      c.submission.cluster.matches[0] &&
      ((c.submission.cluster.matches[0].origin && c.submission.cluster.matches[0].origin.url) ||
        c.submission.cluster.matches[0].url)
    ) {
      return URI(c.submission.cluster.matches[0].origin.url || c.submission.cluster.matches[0].url)
        .host()
        .replace('www.', '')
    }
    return null
  } catch (err) {
    return null
  }
}

function DoneCheckboxOverlay() {
  return (
    <div css={doneCheckboxOverlayStyle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-891.201 2203.65 28.917 21.469">
        <path
          d="M6573.778,3228.232l7.777,7.626,8.247-8.086L6601.3,3216.5"
          transform="translate(-7464.279 -1012.14)"
          style={{
            fill: 'none',
            stroke: '#008AAB',
            strokeWidth: '3px',
          }}
        />
      </svg>
    </div>
  )
}

const doneCheckboxOverlayStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);

  svg {
    width: 40px;
  }
`
