import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import FormRow from './FormRow'
import Label from './Label'

const ActionArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${props =>
    props.alignContent === 'left' ? 'flex-start' : 'flex-end'};
`

export default function PhantomLabelSpacer({
  children,
  title,
  labelAlign,
  alignContent,
}) {
  return (
    <FormRow>
      <Label title={title} align={labelAlign} />
      <ActionArea alignContent={alignContent}>{children}</ActionArea>
    </FormRow>
  )
}

PhantomLabelSpacer.propTypes = {
  children: PropTypes.element.isRequired,
  alignContent: PropTypes.string,
  title: PropTypes.string,
}

PhantomLabelSpacer.defaultProps = {
  title: '',
  alignContent: 'left',
}
