import React from 'react'
import MultiMatchTiles from './MultiMatchTiles'
import SingleMatchTiles from './SingleMatchTiles'

const MatchTilesSwitch = ({ matches, image, expandView }) => {
  if (matches.length === 1) {
    return <SingleMatchTiles match={matches[0]} image={image} expandView={expandView} height={168} />
  } else {
    return <MultiMatchTiles matches={matches} expandView={expandView} />
  }
}

export default MatchTilesSwitch
