import React from 'react'
import ReactModal from 'react-modal'
import styled, { css, cx } from 'react-emotion'
import DialogSlider from './DialogSlider'

export default function Dialog({ children, wide, dark, title = '', subtitle = '', ...config }) {
  return (
    <ReactModal
      className={cx(dialogWrap, dark && darkDialogWrap, wide && dialogWrapWide, dark && wide && darkDialogWrapWide)}
      overlayClassName={cx(overlay, dark && darkOverlay)}
      shouldCloseOnEsc
      {...config}
    >
      {config.onRequestClose && (
        <CloseButton onClick={config.onRequestClose}>
          <IconClose />
        </CloseButton>
      )}
      {(title || subtitle) && (
        <div css={modalHeader}>
          <div css={cx(modalHeaderTitle, modalHeaderLeftTitle)}>{title}</div>
          <div css={cx(modalHeaderTitle, modalHeaderRightTitle)}>{subtitle}</div>
        </div>
      )}
      <div css={dialogBody}>{children}</div>
    </ReactModal>
  )
}
Dialog.Slider = DialogSlider

function IconClose() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2126.207 1485.793 34.953 34.953">
      <defs>
        <style>
          {`.svg-modal-close-button {
                fill: none;
                stroke: #3B3B3B;
                stroke-width: 2px;
              }`}
        </style>
      </defs>
      <g id="Group_785" data-name="Group 785" transform="translate(-3903 1427)">
        <line
          id="Line_45"
          data-name="Line 45"
          className="svg-modal-close-button"
          x2="33.539"
          y2="33.539"
          transform="translate(1777.5 59.5)"
        />
        <line
          id="Line_46"
          data-name="Line 46"
          className="svg-modal-close-button"
          y1="33.539"
          x2="33.539"
          transform="translate(1777.5 59.5)"
        />
      </g>
    </svg>
  )
}

const modalHeader = css`
  display: flex;
  height: 85px;
  width: 100%;
  background-color: #141414;
`
const modalHeaderTitle = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const modalHeaderLeftTitle = css`
  color: white;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  padding: 0px 10px 20px 59px;
`
const modalHeaderRightTitle = css`
  color: #b9b9b9;
  font-size: 24px;
  line-height: 24px;
  text-align: right;
  padding: 0px 59px 20px 10px;
`
const dialogBody = css`
  overflow-y: auto;
  padding: 30px;

  @media (max-height: 768px) and (min-width: 768px) {
    max-height: 90vh;
  }
`
const darkDialogWrap = css`
  background-color: #262626;
  color: #fff;
  border-top: none;
  max-height: calc(100vh - 180px);
  /* overflow-y: auto;
  overflow-x: hidden; */
  h1 {
    font-size: 32px;
    font-weight: 600;
    color: white;
    padding-top: 23px;
    padding-bottom: 10px;
    margin: 0;
    text-align: center;
    + span {
      display: block;
      margin-top: -10px;
      font-size: 20px;
      font-weight: 300;
      color: #b9b9b9;
      text-align: center;
      padding-bottom: 43px;
    }
  }

  @media screen and (min-height: 830px) {
    top: 30px;
  }
`
const darkOverlay = css`
  background-color: rgba(0, 0, 0, 0.87);
`
const darkDialogWrapWide = css`
  @media (max-width: 1280px) {
    height: calc(100vh - 120px);
  }
  @media (min-width: 768px) {
    width: 85%;
  }
  @media (min-width: 992px) {
    width: 85%;
  }
  @media (min-width: 1200px) {
    width: 90%;
  }
  @media (min-width: 1440px) {
    width: 1032px;
  }
`

const dialogWrap = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 582px;
  /* padding: 30px; */
  background-color: white;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15);
  border-top: 3px solid #008aab;

  @media screen and (min-height: 830px) {
    transform: translateX(-50%);
    top: 100px !important;
  }

  @media (max-width: 768px) {
    transform: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    overflow-y: scroll;
  }

  &:focus {
    outline: 0;
  }

  h1 {
    font-size: 2.2em;
    padding-bottom: 10px;
    + span {
      margin-top: -10px;
      font-size: 1.1em;
      color: #3b3b3bb3;
      font-weight: 300;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
    font-size: 1.6em;
    margin: 30px auto;
    text-align: center;
    li {
      margin-bottom: 10px;
      font-weight: 400;
      em {
        font-style: normal;
        color: #008aab;
      }
    }
  }

  a > p {
    color: #3b3b3baa;
  }
`

const dialogWrapWide = css`
  width: 700px;
`

const overlay = css`
  z-index: 2000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.85);
`

const CloseButton = styled.div`
  position: absolute;
  top: -55px;
  right: -55px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  align-items: center;
  svg {
    height: 25px;
  }

  @media (max-width: 768px) {
    top: 0;
    left: auto;
    right: 0;
  }
`