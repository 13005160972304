import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import { css, cx } from 'react-emotion'

export const SexyThumbnail = React.memo(function({
  url,
  isError,
  children,
  linkDestination,
  ignored = false,
  approved = false,
  caption,
  className,
  history,
  renderAddon,
  loading,
  loadingPercentage,
  loadingSize = 24,
  icon,
  iconLabel,
  onClick,
  containerClassName,
}) {
  if (children) {
    throw new Error('Nope')
  }

  const [hover, setHover] = useState(false)

  return (
    <div
      className={cx(wrap, className, onClick && clickable)}
      onClick={onClick}
    >
      <div className={cx(effect, containerClassName)}>
        <div
          className={cx(!isError && blur)}
          style={{ backgroundImage: `url("${url}")` }}
        />

        {((ignored || approved) && (
          <div css={messageWrapper}>
            {approved === true ? <IconApproved /> : <IconIgnored />}
            <p>{approved ? 'Approved' : 'Ignored'}</p>
          </div>
        )) ||
          (loading && (
            <div css={messageWrapper}>
              <CircularProgress
                thickness={5}
                css={circularProgressStyle}
                size={loadingSize}
                variant={loadingPercentage == null ? 'indeterminate' : 'static'}
                value={loadingPercentage}
              />
              {iconLabel && <small>{iconLabel}</small>}
            </div>
          )) ||
          (icon && (
            <div css={messageWrapper}>
              <span className={cx(customIcon)}>{icon}</span>
              {iconLabel && <small>{iconLabel}</small>}
            </div>
          ))}

        <div
          className={cx(imageWrapper, linkDestination && wrapperWithLink)}
          onClick={
            linkDestination ? () => history.push(linkDestination) : undefined
          }
          {...(renderAddon && {
            onMouseEnter: e => setHover(true),
            onMouseLeave: e => setHover(false),
          })}
        >
          <img css={image} src={url} />
          {renderAddon && renderAddon({ hover })}
        </div>
        {caption && caption}
      </div>
    </div>
  )
})

export default withRouter(SexyThumbnail)

function IconApproved() {
  return (
    <svg viewBox="-891.201 2203.65 28.917 21.469">
      <path
        d="M6573.778,3228.232l7.777,7.626,8.247-8.086L6601.3,3216.5"
        transform="translate(-7464.279 -1012.14)"
      />
    </svg>
  )
}

function IconIgnored() {
  return (
    <svg viewBox="-568.4 2203.442 22.015 22.016">
      <g transform="translate(-1886 1615)">
        <path
          d="M20396.156,2444l20.6,20.6"
          transform="translate(-19077.85 -1854.849)"
        />
        <path
          d="M0,0,20.6,20.6"
          transform="translate(1318.307 609.745) rotate(-90)"
        />
      </g>
    </svg>
  )
}

SexyThumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  // displayCheckAlways: PropTypes.bool,
  ignored: PropTypes.bool,
  renderAddon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}

const messageWrapper = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  font-size: 1em;
  > svg {
    margin-top: 10%;
    height: 50px;
    path {
      stroke: #707070;
      fill: none;
      stroke-width: 1px;
    }
  }
  p {
    color: #3b3b3b;
    margin-top: 14px;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-weight: 600;
  }
  small {
    color: #3b3b3b;
    margin-top: 0px;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 10px;
    font-weight: 400;
  }
`

const backplate = css`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const blur = css`
  ${backplate};
  filter: blur(12px) brightness(95%);
  transform: scale(1.3);
  height: 100%;
  transition: all 150ms ease;
`

const imageWrapper = css`
  ${backplate} display: flex;
  align-items: center;
  justify-content: center;
`

const effect = css`
  height: 0;
  padding-top: 66.666666%; /* aspect ratio 2/3*/
  background-size: cover;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  &:hover {
    opacity: 0.85;
  }
`

const wrap = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
`

const image = css`
  max-width: 100%;
  max-height: 100%;
`

const wrapperWithLink = css`
  cursor: pointer;
`

const circularProgressStyle = css`
  color: #212121 !important;
  margin: 5px 0;
`
const customIcon = css`
  > svg {
    vertical-align: middle;
  }
`
const clickable = css`
  cursor: pointer;
`
