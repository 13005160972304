import React from 'react'
import { css } from 'react-emotion'
import PaymentMethodItem from './PaymentMethodItem'

const PaymentMethods = ({ list = [], subscription, handleMakeDefault }) => {
  return (
    <div>
      <ul css={PaymentItemCss}>
        {list.map((item, index) => {
          return (
            <PaymentMethodItem
              key={index}
              item={item}
              subscription={subscription}
              handleMakeDefault={handleMakeDefault}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default PaymentMethods

const PaymentItemCss = css`
  width: 100%;

  li:hover {
    background-color: #fafafa;
  }
`
