import React from 'react'
import { SUBMISSION_VALIDATION_SCENARIOS } from 'pixsy-constants'

import { SubmissionValidationDialogCategoryUnresolvable } from './ErrorMessages/SubmissionValidationDialogCategoryUnresolvable'
import { SubmissionValidationDialogCC0 } from './ErrorMessages/SubmissionValidationDialogCC0'
import { SubmissionValidationDialogCCLegalOnly } from './ErrorMessages/SubmissionValidationDialogCCLegalOnly'
import { SubmissionValidationDialogCountryUnresolvable } from './ErrorMessages/SubmissionValidationDialogCountryUnresolvable'
import { SubmissionValidationDialogDirectHotlink } from './ErrorMessages/SubmissionValidationDialogDirectHotlink'
import { SubmissionValidationDialogDomainStock } from './ErrorMessages/SubmissionValidationDialogDomainStock'
import { SubmissionValidationDialogDomainUnresolvable } from './ErrorMessages/SubmissionValidationDialogDomainUnresolvable'
import { SubmissionValidationDialogIndirectHotlink } from './ErrorMessages/SubmissionValidationDialogIndirectHotlink'
import { SubmissionValidationDialogTMOnly } from './ErrorMessages/SubmissionValidationDialogTMOnly'

export const CaseSubmissionErrors = ({ errors, errProps: { country } }) => {
  const errComponents = {
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_IMAGE_CC0]: props => (
      <SubmissionValidationDialogCC0
        key="SubmissionValidationDialogCC0"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_COUNTRY_UNRESOLVABLE]:
      props => (
        <SubmissionValidationDialogCountryUnresolvable
          key="SubmissionValidationDialogCountryUnresolvable"
          {...props}
        />
      ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_CATEGORY_UNRESOLVABLE]:
      props => (
        <SubmissionValidationDialogCategoryUnresolvable
          key="SubmissionValidationDialogCategoryUnresolvable"
          {...props}
        />
      ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_STOCK]: props => (
      <SubmissionValidationDialogDomainStock
        key="SubmissionValidationDialogDomainStock"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_UNRESOLVABLE]: props => (
      <SubmissionValidationDialogDomainUnresolvable
        key="SubmissionValidationDialogDomainUnresolvable"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_MATCH_HOTLINKED_IMAGE]: props => (
      <SubmissionValidationDialogDirectHotlink
        key="SubmissionValidationDialogDirectHotlink"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.WARNING_MATCH_HOTLINKED_ORIGIN]: props => (
      <SubmissionValidationDialogIndirectHotlink
        key="SubmissionValidationDialogIndirectHotlink"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_COUNTRY_TM_ONLY]: props => (
      <SubmissionValidationDialogTMOnly
        key="SubmissionValidationDialogTMOnly"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_IMAGE_CC_LEGAL_ONLY]: props => (
      <SubmissionValidationDialogCCLegalOnly
        key="SubmissionValidationDialogCCLegalOnly"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_CLUSTER_MINIMUM_SCORE]: props => (
      <SubmissionValidationDialogCCLegalOnly
        key="SubmissionValidationDialogCCLegalOnly"
        {...props}
      />
    ),
  }

  // TODO: rethink how UI can display all errors
  return errors.length ? errComponents[errors[0]]({ country }) : null
}
