import React from 'react'

const resolveLabelForAccuracy = (value) => (value > 0.7 ? 'High' : value > 0.3 ? 'Medium' : 'Low')

const resolveLabelForORB = (value) => (isNaN(value) ? '-' : value > 0.05 ? 'Pass' : 'Fail')

/**
 *
 * match.similarity = "logreg" algorithm
 * match.orb = "orb" algorithm
 * match.confidence = "goldstein" algorithm
 *
 */
function resolveLabel({ similarity, confidence: _confidence, orb: _orb, meta, imagesimPrediction, verification }) {
  const labelMapping = {
    10: 'Very Low',
    11: 'Medium',
    12: 'High',
  }
  if (verification) {
    return labelMapping[verification]
  }

  if (imagesimPrediction && imagesimPrediction.verification) {
    return labelMapping[imagesimPrediction.verification]
  }

  if (meta && meta.verification_v3) {
    return labelMapping[meta.verification_v3]
  }

  if (meta && meta.similarityV3) {
    if (meta.similarityV3 >= 0.65) {
      return 'High'
    }

    if (meta.similarityV3 >= 0.55) {
      return 'Medium'
    }

    return 'Low'
  }

  const [confidence, orb] = [_confidence, _orb].map((x) => (x === null || x === undefined ? NaN : x))
  if (isNaN(confidence) && isNaN(orb)) {
    return 'Low'
  }
  // negative confidence + negative rb
  if (similarity >= 0) {
    if (similarity >= 0.85) {
      return 'High'
    }
    if (similarity >= 0.65) {
      return 'Medium'
    }
    if (similarity < 0.55) {
      // keep updated with pixsy-models/lib/match/similarity.js
      return 'Very low'
    }
    return 'Low'
  }

  if (confidence >= 0 && !(orb >= 0)) {
    if (confidence > 0.85) {
      return 'High'
    }
    if (confidence > 0.7) {
      return 'Medium'
    }
    if (confidence < 0.525) {
      return 'Low' // Will be purged out by API once migration is complete
    }
    return 'Low'
  }
  return resolveLabelForAccuracy(confidence)
}

export const MatchDetailsAccuracy = ({ match, testMode }) => {
  const matchSource = match.meta && (match.meta.matchSource || match.meta.source)
  const isDeepSearch = ['gris', 'gris-go'].includes(matchSource)

  const debugContent =
    testMode === true ||
    (!global.PRODUCTION && (
      <React.Fragment>
        <dt className="debug">Source: {isDeepSearch ? 'Deep Search' : 'Pixsy Spiders'}</dt>
        <dt className="debug">
          Goldstein: {match.confidence} ({resolveLabelForAccuracy(match.confidence)})
        </dt>
        <dt className="debug">
          ORB: {Number(match.orb).toFixed(3)} ({resolveLabelForORB(match.orb)})
        </dt>
        {match.orb >= 0 && (
          <React.Fragment>
            <dt className="debug">LogReg: {Number(100 * match.similarity).toFixed(3)}</dt>
          </React.Fragment>
        )}
      </React.Fragment>
    ))

  return (
    <React.Fragment>
      <dt>Accuracy</dt>
      <dd>{resolveLabel(match)}</dd>
      {debugContent}
    </React.Fragment>
  )
}

export default MatchDetailsAccuracy
