import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

const styles = css`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 0;
    transform: scale(0.5);
  }
  svg {
    height: 70px;
  }
`

export default function CardIcon({ tag }) {
  let icon = null

  try {
    icon = require(`./assets/${tag.replace(':', '_')}.svg`)
  } catch (e) {
    return null
  }

  return (
    <div
      className={styles}
      dangerouslySetInnerHTML={{
        __html: icon,
      }}
    />
  )
}

CardIcon.propTypes = {
  tag: PropTypes.string,
}
