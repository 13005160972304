import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { submit } from '../../../../redux/modules/registrations'
import { replace } from 'react-router-redux'
import SubmissionForm from './SubmissionForm'
import { arrayPush, arrayRemoveAll } from 'redux-form'
import {
  PHASE,
  initialValues,
  restruct,
  flatten,
} from '../../../../../shared/forms/registrationSubmission'
import { redirectWhen } from '../../../../decorators'
import validationStrategies from './validationStrategies'

const log = require('debug')('submission:data')

const {
  Provider: LockContextProvider,
  Consumer: LockContextConsumer,
} = React.createContext({ toggle: () => {}, locked: [] })

export { LockContextConsumer }

export class SubmissionFormDataProvider extends React.Component {
  static MAX_IMAGES_PER_SUBMISSION = 20

  constructor() {
    super()

    this.state = { locked: [] }
  }

  componentWillReceiveProps(nextProps) {
    const { values, registeredFields } = this.props
    if (
      values &&
      values.__UPLOADS__ &&
      values.__UPLOADS__.length === 0 &&
      nextProps.values &&
      nextProps.values.__UPLOADS__ &&
      nextProps.values.__UPLOADS__.length !== 0
    ) {
      this.handleImageFileUpload(nextProps.values.__UPLOADS__)
    }

    if (!registeredFields && nextProps.phase === PHASE.FINAL) {
      this.maybeLockFields(nextProps)
    }
  }

  UNSAFE_componentWillMount() {
    this.maybeLockFields(this.props)
  }

  maybeLockFields(props) {
    const { registeredFields } = props
    const { locked } = this.state
    if (!locked.length && registeredFields) {
      const locked = Object.keys(registeredFields)
      this.setState({ locked })
    }
  }

  async handleSave(dispatch, body) {
    const res = await dispatch(submit(body))

    log('[handleSave SubmissionFormDataProvider] %o', res)
    this.props.onSave(res.payload.registration)
  }

  async handleSubmit(dispatch, body) {
    log('[handleSubmit FormDataProvider] %o // %o', dispatch, body)
    await dispatch(submit(body))
    this.props.onSuccess()
  }

  handleFormSubmission = async (_, dispatch, props) => {
    const { registrationId, values } = this.props
    log('handleSubmission', values)
    const body = new FormData()

    // Gather root level specific file uploads
    // Object.entries(values)
    //   .filter(
    //     ([key, value]) =>
    //       key.startsWith('__UPLOADS__') ||
    //       key.startsWith('__GENERAL_ATTACHMENTS__')
    //   )
    //   .forEach(([key, files]) => files.forEach(f => body.append(key + '[]', f)))

    // // Gather image specific file uploads
    // Object.entries(values.images).forEach(([index, image]) => {
    //   ;['__US_REGISTRATION_FILES__', '__LICENSING_HISTORY_FILES__'].forEach(
    //     key => {
    //       if (Array.isArray(image[key])) {
    //         image[key].forEach(f => body.append(`__IMAGE__${index}${key}[]`, f))
    //       }
    //     }
    //   )
    // })

    const restructValues = restruct(JSON.parse(JSON.stringify(values))) // deep copy of values to fix state mutation error
    const action = values.__ACTION__

    // If we perform multi register - we have multiple images and cannot override restructValues like that.
    if (values.usco !== 'USCO_MULTI') {
      // restructValues.images = [restructValues.image]
      restructValues.image = restructValues.images.find(a => !!a._id)
    }
    if (action === 'submit') {
      restructValues.__PHASE__ = 'FINAL'
      restructValues.phase = 'FINAL'
      restructValues.status = 'NEW'
    } else {
      restructValues.status = 'DRAFT'
    }

    body.append('stringified', JSON.stringify(restructValues))
    body.append('action', action)
    body.append('registrationId', registrationId)

    log(action)
    if (action === 'submit') {
      this.handleSubmit(dispatch, body)
    } else {
      this.handleSave(dispatch, body)
    }
  }

  handleImageFileUpload = (values) => {
    const { arrayRemoveAll, arrayPush } = this.props
    arrayRemoveAll('images')
    for (
      let i = 0;
      i < values.length &&
      i < SubmissionFormDataProvider.MAX_IMAGES_PER_SUBMISSION;
      i++
    ) {
      arrayPush('images', values[i])
    }
  }

  validate = (values) => {
    if (values.phase) {
      this.props.values.__PHASE__ = values.phase
    } else if (values.__PHASE__ === PHASE.INTRO) {
      this.props.values.__PHASE__ = PHASE.INTRO
    }
    const phase =
      this.props.values && this.props.values.__PHASE__
        ? this.props.values.__PHASE__
        : 'dontValidate'

    return validationStrategies[phase](values)
  }

  getDefaults() {
    const { registration, user, image } = this.props

    const author = {
      firstName: user.details.first_name,
      lastName: user.details.last_name,
      dateOfBirth: user.details.date_of_birth,
      citizen: user.details.country,
      resident: user.details.country,
      city: user.details.city,
      street: user.details.street,
      state: user.details.state,
      postalCode: user.details.postal_code,
      country: user.details.country,
      email: user.email,
      phone: user.details.phone,
    }
    const returnValue = {
      ...initialValues,
      image,
      ...author,
      images: [image].filter(Boolean),
    }

    return registration
      ? {
          ...returnValue,
          ...flatten(registration),
        }
      : returnValue
  }

  unlock = item => {
    const { locked } = this.state
    const filtered = locked.filter(n => n !== item)

    if (filtered.length !== locked.length) this.setState({ locked: filtered })
  }

  render() {
    const { registration, registrationId, phase, user } = this.props
    const { locked } = this.state

    return (
      <LockContextProvider value={{ locked, unlock: this.unlock, phase }}>
        <SubmissionForm
          user={user}
          registration={registration}
          phase={phase}
          lockedFields={locked}
          onSubmit={this.handleFormSubmission}
          validate={this.validate}
          initialValues={this.getDefaults()}
          registrationId={
            registrationId === 'new' ? null : registration && registrationId
          }
          // TODO: Fix warnings
        />
      </LockContextProvider>
    )
  }
}

export default redirectWhen({
  shouldRedirect: ({ registration }) =>
    registration &&
    registration.__PHASE__ === PHASE.FINAL &&
    registration.__DRAFT__ === false,
  to: ({ location }) => ({
    ...location,
    pathname: '/registrations/final',
  }),
})(
  connect(
    (state, { registration }) => {
      const values =
        (state.form.submission && state.form.submission.values) || {}
      const phase =
        (values && values.__PHASE__) ||
        (registration && registration.phase) ||
        PHASE.DRAFT

      return {
        values,
        phase,
        registeredFields:
          state.form.submission && state.form.submission.registeredFields,
        registration,
      }
    },
    { replace, arrayPush, arrayRemoveAll },
    null,
    { pure: false }
  )(SubmissionFormDataProvider)
)

export function createFormData(fields) {
  const body = new FormData()

  body.append('stringified', JSON.stringify(fields))
  return body
}

SubmissionFormDataProvider.propTypes = {
  values: PropTypes.object,
  registeredFields: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  phase: PropTypes.string.isRequired,
}
