import * as React from 'react'
import { css, cx } from 'react-emotion'
import { PixsyGrayedLabel } from '../PixsyGrayedLabel/PixsyGrayedLabel'

export const PixsyLabel = React.memo(
  ({
    children = null,
    className,
    disabled,
    htmlFor,
    text = null,
    required = true,
    fontSize,
    style = {},
    padding,
    isHorizontal = false,
  }) => {
    const ownStyle = css({
      fontSize,
      padding,
      ...style,
    })
    return (
      <label
        htmlFor={htmlFor}
        className={cx(
          labelStyle,
          className,
          ownStyle,
          disabled && labelDisabledStyle
        )}
      >
        {text || children}
        {!required && (
          <React.Fragment>
            {!isHorizontal ? <br /> : ' '}
            <PixsyGrayedLabel text="optional" />
          </React.Fragment>
        )}
      </label>
    )
  }
)

const labelStyle = css`
  color: rgba(67, 67, 67, 0.9);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 4px;
  margin: 0;
`
const labelDisabledStyle = css`
  color: rgba(67, 67, 67, 0.5);
`
