import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import InstalmentPaymentConfig from './InstalmentPaymentConfig'
import { SexySeparator } from 'common'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

function renderPaymentList({ amount, currency, startDate, interval, parts }) {
  const instalments = []
  for (let i = 0; i < parts; i++) {
    let date
    if (interval === 'weekly') {
      date = moment(startDate).add(i, 'weeks')
    } else if (interval === 'bimonthly') {
      date = moment(startDate).add(i * 2, 'weeks')
    } else if (interval === 'monthly') {
      date = moment(startDate).add(i, 'months')
    }
    instalments.push({
      date: date.format('MMM D, YYYY'),
    })
  }
  return (
    <li css={paymentList}>
      <ul>
        <h5>Date</h5>
        <h5>Amount</h5>
      </ul>
      {instalments.map((instalment, i) => (
        <div key={i}>
          <ul>
            <p>{instalment.date}</p>
            <p>{`${currency} ${amount}`}</p>
          </ul>
          {i < instalments.length - 1 && <SexySeparator />}
        </div>
      ))}
    </li>
  )
}

export default function InstalmentPaymentSection({
  onChange,
  parts,
  interval,
  startDate,
  maxDate,
  minDate,
  currency,
  amount,
}) {
  const total = amount * parts
  return (
    <div css={container}>
      <h4>Part Payment Configuration</h4>
      <InstalmentPaymentConfig
        onChange={onChange}
        parts={parts}
        interval={interval}
        startDate={startDate}
        maxDate={maxDate}
        minDate={minDate}
      />
      <br />
      <SexySeparator />
      <h6>Part Payment Overview</h6>
      {renderPaymentList({ amount, currency, startDate, interval, parts })}
      <p css={totalText}>
        Grand total of <b>{`${currency} ${total.toFixed(2)}`}</b>
      </p>
    </div>
  )
}

InstalmentPaymentSection.PropTypes = {
  onChange: PropTypes.func.isRequired,
  parts: PropTypes.number.isRequired,
  interval: PropTypes.number.isRequired,
  startDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  minDate: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
}

const container = css`
  position: relative;
  h4,
  h6 {
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
  }
  h6 {
    font-weight: normal;
    font-size: 1em;
  }
`

const totalText = css`
  font-size: 1em;
  color: #43434380;
  text-align: center;
`

const paymentList = css`
  list-style: none outside none;
  ul {
    p {
      height: 100%;
      text-align: center;
      margin: 0;
    }
    padding: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }
`
