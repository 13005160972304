import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import styled, { injectGlobal } from 'react-emotion'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

const ActionArea = styled.div`
  max-width: 200px;
  background-color: ${({ isError }) => (isError ? '#FFF0F0' : 'transparent')};
  input {
    height: 40px;
    width: 200px;
    padding: 0 12px;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    cursor: pointer;
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }
  }
`

injectGlobal`

  .react-datepicker {
    border-radius: 0;
    border: 1px solid #c7c7c7;
    font-family: inherit;
  }
  .react-datepicker__header {
    background-color: #fcfcfc;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #148aa9;
  }

  .react-datepicker__navigation--years-upcoming {
    top: -4px !important;
    border-bottom-color: #ccc !important;
  }

  .react-datepicker__navigation--years-previous {
    top: 4px !important;
    border-top-color: #ccc !important;
  }

`

const DatePickerWrapper = styled.div`
  position: relative;

  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 200px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
`
export class DatePickerStandalone extends React.Component {
  handleChangeRaw = e => {
    e.preventDefault()
  }

  handleChange = date => {
    this.props.input.onChange(moment(date).format('YYYY-MM-DD'))
  }

  render() {
    const { input, meta = {}, datepickerProps = {} } = this.props
    const { dirty, touched, warning, error } = meta

    const actionAreaProps = {}

    if ((touched || dirty) && (warning || error)) {
      if (error) {
        actionAreaProps.isError = true
        actionAreaProps['data-error'] = true
      }
    }

    let maxDate = new Date()
    if (new Date(input.value) > maxDate) {
      maxDate = new Date(input.value)
    }

    let selected = null

    if (input.value) {
      if (input.value._d) {
        selected = input.value // is moment
      } else {
        selected = moment.utc(input.value)
      }
    }

    return (
      <ActionArea {...actionAreaProps}>
        <DatePickerWrapper>
          <ReactDatePicker
            onChange={this.handleChange}
            onChangeRaw={this.handleChangeRaw}
            minDate={moment.utc().subtract(100, 'years')}
            maxDate={moment.utc(maxDate)}
            dateFormat="LL"
            selected={selected}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            {...datepickerProps}
          />
          <IconArrowDown />
        </DatePickerWrapper>
        <Feedback meta={meta} />
      </ActionArea>
    )
  }
}

export default function DatePicker(props) {
  const { label } = props

  return (
    <FormRow {...props}>
      <Label title={label} />
      <DatePickerStandalone {...props} />
    </FormRow>
  )
}

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
}

DatePicker.defaultProps = {
  width: '100%',
}

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}
