// 5. Screening/Knockout Questions
import Fade from '@material-ui/core/Fade'
import { Button, TooltipContainer, TooltipContext } from 'common'
import { SETTLEMENT_KEYS } from 'pixsy-schema/case/SettlementSchema'
import {
  SUBMISSION_INFO as INFO,
  SUBMISSION_LABEL as LABELS,
  SUBMISSION_NAMES as NAMES,
  SUBMISSION_REQUIRED as REQUIRED,
} from 'pixsy-schema/case/SubmissionSchema'
import { FILE_SOURCES } from 'pixsy-schema/file/FileSchema'
import * as React from 'react'
import { css } from 'react-emotion'
import {
  PixsyBooleanField,
  PixsyErrorMessage,
  PixsyFileList,
  PixsyFormGroup,
  PixsyGrayedLabel,
  PixsyGrid,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
  PixsyTextField,
  UppyDragDrop,
} from '../../../Components'
import screeningStageTooltipContent from './screeningStageTooltipContent'

/* eslint-disable react/no-this-in-sfc */
/**
 * @augments {React.Component<import('../StageProps').IProps, {}>}
 */
export class ScreeningStage extends React.Component {
  fieldsDisplayOrder = [
    NAMES.koUserIsLegalRightsOwner,
    NAMES.koImageUserIsBusiness,
    NAMES.screeningCaseAboutBreachOfContract,
    NAMES.screeningCaseAboutBreachOfContractComment,
    NAMES.stockIncluded,
    NAMES.stockVerified,
    NAMES.screeningPreviousContactWithImageUser,
    NAMES.screeningPreviousContactWithImageUserComment,
    NAMES.workForHire,
    NAMES.contest,
    NAMES.country,
    NAMES.noticed,
  ]

  getIsBreachAttachments = (files = []) => {
    return files.filter(
      f =>
        f.source === FILE_SOURCES.CASE_GENERAL_FILES_SCREENING_BREACH_CONTRACT
    )
  }
  getContactUserAttachments = (files = []) => {
    return files.filter(
      f => f.source === FILE_SOURCES.CASE_GENERAL_FILES_SCREENING_CONTACT_USER
    )
  }
  handleSaveEditField = (name, value) => e => {
    e && e.preventDefault()
    const { setFieldValue } = this.props
    setFieldValue(name, value)
  }

  Question = React.memo(
    ({ name, disabled, greyBox, validateOtherPaths, readOnly }) => {
      const tooltip = <TooltipContainer id={name} />
      const { errors } = this.props
      const hasError = errors[name] //getIn(errors, name)

      return (
        <PixsyPaper padding="20px 60px 20px 60px" disabled={disabled[name]}>
          <PixsyFormGroup tooltip={tooltip}>
            <PixsyGridItem xs={9}>
              <PixsyLabel text={LABELS[name]} required={REQUIRED[name]} />
            </PixsyGridItem>
            <PixsyGridItem xs={3}>
              <PixsyBooleanField
                name={name}
                redBox={!!hasError}
                disabled={disabled[name]}
                greyBox={greyBox}
                readOnly={readOnly}
                validateOnChange
                validateOtherPaths={validateOtherPaths}
              />
            </PixsyGridItem>
          </PixsyFormGroup>
        </PixsyPaper>
      )
    }
  )

  QuestionWithAttachment = React.memo(
    ({ name, disabled, getFiles, savedName, validateOtherPaths, readOnly }) => {
      const tooltip = <TooltipContainer id={name} />
      const { errors, getValue } = this.props
      const hasError = errors[name]
      const files = getValue(SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__) || []
      const isSaved = getValue(savedName)
      const isDisabled = disabled[name]
      const screeningFilesCount = getFiles(files).length

      return (
        <PixsyPaper padding="20px 60px 20px 60px" disabled={isDisabled}>
          <PixsyFormGroup tooltip={tooltip}>
            <PixsyGridItem xs={9}>
              <PixsyGrid justify="space-between" alignItems="flex-start">
                <PixsyGridItem>
                  <PixsyLabel required={REQUIRED[name]}>
                    {LABELS[name]}
                    {!!screeningFilesCount && (
                      <React.Fragment>
                        <br />
                        <PixsyGrayedLabel>
                          {screeningFilesCount}{' '}
                          {screeningFilesCount === 1
                            ? 'attachment'
                            : 'attachments'}
                        </PixsyGrayedLabel>
                      </React.Fragment>
                    )}
                  </PixsyLabel>
                </PixsyGridItem>
                <PixsyGridItem>
                  {isSaved && (
                    <PixsyPaper variant="transparent" padding="5px 0 0">
                      <a
                        href="#"
                        onClick={this.handleSaveEditField(savedName, false)}
                        tabIndex={isDisabled ? -1 : 0}
                      >
                        <PixsyGrayedLabel text="edit" />
                      </a>
                    </PixsyPaper>
                  )}
                </PixsyGridItem>
              </PixsyGrid>
            </PixsyGridItem>
            <PixsyGridItem xs={3}>
              <PixsyBooleanField
                name={name}
                redBox={!!hasError}
                disabled={isDisabled}
                validateOnChange
                validateOtherPaths={validateOtherPaths}
                greyBox={!isSaved}
                readOnly={readOnly}
              />
            </PixsyGridItem>
          </PixsyFormGroup>
        </PixsyPaper>
      )
    }
  )

  TextField = React.memo(({ name, type, disabled, readOnly }) => {
    const tooltip = <TooltipContainer id={name} />
    return (
      <PixsyPaper padding="20px 60px 20px 60px" disabled={disabled[name]}>
        <PixsyFormGroup tooltip={tooltip}>
          <PixsyGridItem xs={9}>
            <PixsyLabel text={LABELS[name]} required={REQUIRED[name]} />
          </PixsyGridItem>
          <PixsyGridItem xs={3}>
            <PixsyTextField
              type={type}
              name={name}
              readOnly={readOnly}
              disabled={disabled[name]}
              validateOnChange
            />
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  })

  TextAreaWithFiles = React.memo(
    ({
      disabled,
      questionName,
      answerName,
      savedName,
      info,
      placeholder,
      getFiles,
      tooltipID,
      pixsyUploader,
      readOnly,
    }) => {
      const {
        getValue,
        removeGeneralFile,
        retryGeneralFile,
        sourceLocation,
        errors,
      } = this.props
      const tooltip = <TooltipContainer id={tooltipID} />
      const files = getValue(SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__) || []
      const hasError = errors[answerName]
      const isDisabled = disabled[questionName]
      const isTrue = getValue(questionName) === true
      const isSaved = getValue(savedName)
      const sectionFiles = getFiles(files)

      return (
        isTrue &&
        !isSaved &&
        !isDisabled && (
          <PixsyPaper padding="36px 60px 36px 60px" expanded variant="grey">
            <div css={greyTitleStyle}>{info}</div>
            <PixsyFormGroup direction="column" tooltip={tooltip}>
              <PixsyGridItem xs={12}>
                <PixsyPaper variant="white" borderless>
                  <PixsyTextField
                    validateOnChange
                    validateOnBlur
                    placeholder={placeholder}
                    name={answerName}
                    readOnly={readOnly}
                    multiline
                    rows={5}
                  />
                </PixsyPaper>
              </PixsyGridItem>
              <PixsyGridItem xs={12}>
                <UppyDragDrop
                  uppy={pixsyUploader.uppy}
                  disabled={isDisabled || readOnly}
                />
              </PixsyGridItem>
              <PixsyGridItem xs={12}>
                <PixsyFileList
                  files={sectionFiles}
                  onRemove={removeGeneralFile}
                  onRetry={retryGeneralFile}
                  sourceLocation={sourceLocation}
                  disabled={isDisabled}
                  readOnly={readOnly}
                />
              </PixsyGridItem>
              <PixsyGridItem xs={12}>
                <PixsyGrid justify="flex-end">
                  <PixsyGridItem>
                    <Button
                      grey
                      withFocusOutline
                      onClick={this.handleSaveEditField(savedName, true)}
                      disabled={isDisabled || !!hasError}
                    >
                      save and continue
                    </Button>
                  </PixsyGridItem>
                </PixsyGrid>
              </PixsyGridItem>
            </PixsyFormGroup>
          </PixsyPaper>
        )
      )
    }
  )

  ErrorBox = React.memo(({ name, disabled, onlyWhenTouched = true }) => {
    return (
      !disabled[name] && (
        <PixsyErrorMessage
          name={NAMES[name]}
          onlyWhenTouched={onlyWhenTouched}
          render={({
            error: {
              message: { title, subtitle },
            },
          }) => (
            <Fade in>
              <PixsyPaper
                padding="36px 60px 36px 60px"
                className={errorStyle}
                expanded
                variant="red"
              >
                <div css={errorTitleStyle}>{title}</div>
                {subtitle && <div css={errorSubTitleStyle}>{subtitle}</div>}
              </PixsyPaper>
            </Fade>
          )}
        />
      )
    )
  })

  WarningBox = React.memo(
    ({ name, disabled, withBorder = false, onlyWhenTouched = true }) => {
      const { WarningBoxContent } = this
      return (
        !disabled[name] && (
          <PixsyErrorMessage
            name={NAMES[name]}
            onlyWhenTouched={onlyWhenTouched}
            render={({
              error: {
                message: { title, subtitle, warning },
              },
            }) => (
              <WarningBoxContent
                withBorder={withBorder}
                title={title}
                subtitle={subtitle}
              />
            )}
          />
        )
      )
    }
  )

  WarningBoxContent = ({ withBorder, title, subtitle }) => (
    <Fade in>
      <PixsyPaper
        variant="transparent"
        borderlessLeft
        borderlessRight
        borderless={!withBorder}
        borderVariant={withBorder && 'default'}
      >
        <PixsyPaper
          padding="15px 60px 15px 60px"
          className={errorStyle}
          expanded
          variant="yellow"
        >
          <div css={warningTitleStyle}>{title}</div>
          {subtitle && <div css={warningSubTitleStyle}>{subtitle}</div>}
        </PixsyPaper>
      </PixsyPaper>
    </Fade>
  )

  getDisabledFields = () => {
    const { errors } = this.props
    const disabled = {}
    let disableRest = false

    this.fieldsDisplayOrder.forEach(name => {
      if (disableRest) {
        disabled[name] = true
        return
      }

      const error = errors[name]
      if (
        error &&
        typeof error.message === 'object' &&
        'title' in error.message &&
        !error.message.warning
      ) {
        disableRest = true
      }
      disabled[name] = false
    })

    return disabled
  }

  render() {
    const disabled = this.getDisabledFields()
    const {
      ErrorBox,
      Question,
      TextAreaWithFiles,
      QuestionWithAttachment,
      TextField,
      WarningBox,
      WarningBoxContent,
    } = this
    const {
      screeningBreachFiles,
      screeningContactUsrFiles,
      fullStageValidation,
      getValue,
      user,
      isFormReadOnly,
    } = this.props

    return (
      <TooltipContext content={screeningStageTooltipContent}>
        <Question
          name={NAMES.koUserIsLegalRightsOwner}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        <ErrorBox
          onlyWhenTouched={
            fullStageValidation === 'SCREENING' ? false : undefined
          }
          name={NAMES.koUserIsLegalRightsOwner}
          disabled={disabled}
        />

        <Question
          name={NAMES.koImageUserIsBusiness}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        <ErrorBox name={NAMES.koImageUserIsBusiness} disabled={disabled} />

        <QuestionWithAttachment
          name={NAMES.screeningCaseAboutBreachOfContract}
          savedName={NAMES.__BREACH_CONTRACT_SAVED__}
          validateOtherPaths={[NAMES.screeningCaseAboutBreachOfContractComment]}
          getFiles={this.getIsBreachAttachments}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        <TextAreaWithFiles
          questionName={NAMES.screeningCaseAboutBreachOfContract}
          answerName={NAMES.screeningCaseAboutBreachOfContractComment}
          savedName={NAMES.__BREACH_CONTRACT_SAVED__}
          tooltipID={NAMES.screeningCaseAboutBreachOfContractComment}
          getFiles={this.getIsBreachAttachments}
          info={INFO.screeningCaseAboutBreachOfContract.title}
          placeholder="Help us understand the original agreement and how it was breached."
          pixsyUploader={screeningBreachFiles}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />

        <Question
          name={NAMES.stockIncluded}
          validateOtherPaths={[NAMES.stockVerified]}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        <ErrorBox
          onlyWhenTouched={
            fullStageValidation === 'SCREENING' ? false : undefined
          }
          name={NAMES.stockIncluded}
          disabled={disabled}
        />

        {!!getValue(NAMES.stockIncluded) && (
          <React.Fragment>
            <Question
              name={NAMES.stockVerified}
              disabled={disabled}
              readOnly={isFormReadOnly}
            />
            <ErrorBox
              onlyWhenTouched={
                fullStageValidation === 'SCREENING' ? false : undefined
              }
              name={NAMES.stockVerified}
              disabled={disabled}
            />
          </React.Fragment>
        )}

        {user.photographerFields &&
          user.photographerFields.stockPhotoAgencies &&
          user.photographerFields.stockPhotoAgencies.agencies &&
          user.photographerFields.stockPhotoAgencies.agencies.length > 0 &&
          getValue(NAMES.stockIncluded) === false && (
            <WarningBoxContent title="Heads up! Your profile indicates that you have worked with image agencies before, please ensure your choice is correct." />
          )}

        <QuestionWithAttachment
          name={NAMES.screeningPreviousContactWithImageUser}
          savedName={NAMES.__PR_CONTACT_USER_SAVED__}
          validateOtherPaths={[
            NAMES.screeningPreviousContactWithImageUserComment,
          ]}
          getFiles={this.getContactUserAttachments}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        <TextAreaWithFiles
          questionName={NAMES.screeningPreviousContactWithImageUser}
          answerName={NAMES.screeningPreviousContactWithImageUserComment}
          savedName={NAMES.__PR_CONTACT_USER_SAVED__}
          tooltipID={NAMES.screeningPreviousContactWithImageUserComment}
          getFiles={this.getContactUserAttachments}
          info={INFO.screeningPreviousContactWithImageUser.title}
          placeholder="Help us understand any past communication with the image user."
          pixsyUploader={screeningContactUsrFiles}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />

        <Question
          name={NAMES.workForHire}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        {getValue(NAMES.workForHire) === true && (
          <WarningBoxContent title="Heads up! Many infringements come from commissioned work where the client then shared or made public the image. Please check within the agreement that your client does not have the legal rights to share or reproduce the image. We will require a copy of the agreement. You can upload this at the end of the form." />
        )}
        <ErrorBox
          onlyWhenTouched={
            fullStageValidation === 'SCREENING' ? false : undefined
          }
          name={NAMES.workForHire}
          disabled={disabled}
        />

        <Question
          name={NAMES.contest}
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        {getValue(NAMES.contest) === true && (
          <WarningBoxContent title="Heads up! It's our experience that many contests hold third party rights for further distribution of submitted content. Please check the contest rules to verify that there are no third party distribution or sub-licensing rights prior to submitting this case." />
        )}
        <WarningBox onlyWhenTouched name={NAMES.contest} disabled={disabled} />

        <TextField
          name={NAMES.country}
          type="country-select"
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
        <ErrorBox
          onlyWhenTouched={
            fullStageValidation === 'SCREENING' ? false : undefined
          }
          name={NAMES.country}
          disabled={disabled}
        />
        <TextField
          name={NAMES.noticed}
          type="date"
          disabled={disabled}
          readOnly={isFormReadOnly}
        />
      </TooltipContext>
    )
  }
}
/* eslint-enable react/no-this-in-sfc */

const errorStyle = css`
  * {
    text-align: center;
  }
`
const errorTitleStyle = css`
  font-size: 20px;
  color: #2e2e2e;
`
const errorSubTitleStyle = css`
  font-size: 16px;
  color: #6e6e6e;
  margin-top: 12px;
`
const greyTitleStyle = css`
  font-size: 16px;
  margin-bottom: 24px;
`
const warningTitleStyle = css`
  font-size: 14px;
  color: #2e2e2e;
`
const warningSubTitleStyle = css`
  font-size: 12px;
  color: #6e6e6e;
  margin-top: 6px;
`
