import React from 'react'
import PropTypes from 'prop-types'
import { SexyThumbnail, SelectableItem } from 'common'

export default function ImageCardThumbnail({
  url,
  isError,
  image,
  destination,
  renderAddon,
  isSelecting,
}) {
  return (
    <SelectableItem id={image._id} key={image._id}>
      <SexyThumbnail
        url={url}
        linkDestination={!isSelecting && destination}
        isError={isError}
        {...(!isSelecting &&
          renderAddon && {
            renderAddon,
          })}
      />
    </SelectableItem>
  )
}

ImageCardThumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  image: PropTypes.object.isRequired,
  destination: PropTypes.object,
  renderAddon: PropTypes.func,
  isSelecting: PropTypes.bool.isRequired,
}
