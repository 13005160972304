import React from 'react'
import { Grid, makeStyles, FormControl, Input, FormHelperText } from '@material-ui/core'
import Checkbox from './Checkbox'

const useStyles = makeStyles({
  input: {
    fontFamily: '"canada-type-gibson", Helvetica, Arial, sans-serif',
    fontSize: '1.2rem',
    '&::placeholder': {
      color: 'hsl(0, 0%, 50%) !important',
    },
  },
  error: {
    marginTop: 0,
  },
})

const EditableCheckBox = ({
  value,
  checked,
  onChange,
  label,
  placeholder,
  onTextChange,
  onlyTextbox = false,
  error,
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={onlyTextbox ? 1 : 4}>
        <Checkbox title={label} checked={value && checked} onChange={onChange} />
      </Grid>
      <Grid item xs={onlyTextbox ? 11 : 8}>
        <FormControl fullWidth error={!!error}>
          <Input
            disableUnderline
            value={value}
            placeholder={placeholder}
            onChange={onTextChange}
            classes={{ input: classes.input }}
          />
          {error && <FormHelperText classes={{ error: classes.error }}>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default React.memo(EditableCheckBox)