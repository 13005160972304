import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Field } from 'redux-form'
import { Button, ContentSection, ActionSection, ClipboardHelper } from 'common'

export default function TakedownFormExternalSection({
  onWaypoint,
  onSaveAndRegenerate,
  loading,
  dmcaFormUrl,
  onExternalPageVisited,
  imageUseInformationUpdated,
}) {
  return (
    <ContentSection id="external">
      <Waypoint onEnter={onWaypoint} />
      <h1>External Takedown</h1>

      {imageUseInformationUpdated ? (
        <Fragment>
          <p>
            Please verify above information is correct and press this button to
            re-generate your takedown message according to the information you
            updated.
          </p>

          <ActionSection>
            <Button hoverMain disabled={loading} onClick={onSaveAndRegenerate}>
              Save AND Continue{' '}
            </Button>
          </ActionSection>
        </Fragment>
      ) : (
        <Fragment>
          This domain has an external submission form for DMCA Takedowns. Use
          the information below to fill out the external form.
          <Field name="content" component={ClipboardHelper} />
          <ActionSection last>
            {loading ? (
              <Button disabled>Reloading Message..</Button>
            ) : (
              <Fragment>
                <a
                  href={dmcaFormUrl}
                  target="_blank"
                  onClick={onExternalPageVisited}
                >
                  <Button hoverMain>Go to External Form</Button>
                </a>
                <p>
                  Return to this page when finished to save your takedown
                  details at the bottom of the page.
                </p>
              </Fragment>
            )}
          </ActionSection>
        </Fragment>
      )}
    </ContentSection>
  )
}

TakedownFormExternalSection.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  dmcaFormUrl: PropTypes.string.isRequired,
}
