import * as React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { css } from 'react-emotion'

export class PixsyMenuItem extends React.PureComponent {
  render() {
    const { children, ...props } = this.props
    return (
      <MenuItem {...props} classes={menuItemClasses}>
        {children}
      </MenuItem>
    )
  }
}
const pixsyMenuItemStyle = css`
  font-size: 14px !important;
  font-family: inherit !important;
  min-height: 32px !important;
`
const menuItemClasses = {
  root: pixsyMenuItemStyle,
  selected: css`
    color: #fff !important;
    background-color: #008aab !important;
  `,
}
