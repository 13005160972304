import {
  SUBMISSION_LABEL as LABELS,
  SUBMISSION_REQUIRED as REQUIRED,
} from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import {
  PixsyFormGroup,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
  PixsyTextField,
} from '../../../Components'
import { TooltipContainer } from 'common'

export const PriceCurrency = React.memo(function PriceCurrencyInner({
  name,
  leftName,
  fieldContext,
  rightName,
  middleName,
  disabled,
  padding,
}) {
  const tooltip = <TooltipContainer id={name} />

  return (
    <PixsyPaper padding={padding} disabled={disabled[leftName]}>
      <PixsyFormGroup tooltip={tooltip} alignTooltip="center">
        <PixsyGridItem xs={7}>
          <PixsyLabel
            padding="20px 0 0"
            text={LABELS[leftName]}
            required={REQUIRED[leftName]}
          />
        </PixsyGridItem>
        <PixsyGridItem xs={5}>
          <PixsyFormGroup spacing={0}>
            <PixsyGridItem xs={8}>
              <PixsyTextField
                type="number"
                step={0.01}
                min={0}
                max={10000000}
                label="Minimum"
                inputMode="numeric"
                name={leftName}
                disabled={disabled[leftName]}
                fieldContext={fieldContext}
                validateEntireForm
                validateOnChange
                borderlessRight
              />
            </PixsyGridItem>
            <PixsyGridItem xs={4}>
              <PixsyTextField
                type="currency-select"
                label="Currency"
                name={rightName}
                disabled={disabled[leftName]}
                fieldContext={fieldContext}
                validateEntireForm
                validateOnChange
              />
            </PixsyGridItem>
          </PixsyFormGroup>
        </PixsyGridItem>
      </PixsyFormGroup>
    </PixsyPaper>
  )
})
