import React from 'react'
export default function NotAllowedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24.879 24.879">
      <g transform="translate(-684 -1319)">
        <g transform="translate(684 1319)" fill="none" stroke="#707070" strokeWidth="2">
          <circle cx="12.439" cy="12.439" r="12.439" stroke="none"/>
          <circle cx="12.439" cy="12.439" r="11.439" fill="none"/>
        </g>
        <line x2="16.051" y2="16.051" transform="translate(688.414 1323.414)" fill="none" stroke="#707070" strokeWidth="2"/>
      </g>
    </svg>
  )
} 
