import React from 'react'
import {
  InfoSection,
  InfoColumn,
  RowData,
  ScrollableContent,
  CenterAlignedBigText,
  ColumnTitle,
} from '../../InfoSectionElements'
import * as Shapes from '../../../utils/shapes'

const EXIFContent = ({ cluster }) => {
  const image = cluster.images[0]
  const imageEXIFContent = image.metadata ? (
    Object.entries(image.metadata).map(([key, value]) => (
      <RowData key={key} col={2} property={key}>
        {value}
      </RowData>
    ))
  ) : (
    <CenterAlignedBigText>EXIF Data is not available</CenterAlignedBigText>
  )

  return (
    <ScrollableContent>
      <InfoSection>
        <InfoColumn col={2} left>
          <ColumnTitle>
            <span>IMAGE</span>
          </ColumnTitle>
          {imageEXIFContent}
        </InfoColumn>
        <InfoColumn col={2} right>
          <ColumnTitle>
            <span>MATCH</span>
          </ColumnTitle>
          <div style={{ textAlign: 'center', fontWeight: 300, margin: '30px 0', opacity: 0.5 }}>
            EXIF Data is not available
          </div>
        </InfoColumn>
      </InfoSection>
    </ScrollableContent>
  )
}

EXIFContent.propTypes = {
  cluster: Shapes.Cluster,
}

export default EXIFContent
