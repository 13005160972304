import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'
import { withCookies } from 'react-cookie'
import { ChooseBestImageSource } from 'common'
import moment from 'moment'
import { debounce } from 'lodash'

export class MatchImageViewer extends React.Component {
  static COOKIE_RECENT_MODE = 'pixsy-viewer-recent-comparison-mode'

  constructor({ cookies }) {
    super()

    const mode = cookies.get(MatchImageViewer.COOKIE_RECENT_MODE) || 'side'

    this.state = {
      mode,
      ratio: 0.5,
    }

    this.blurInputAfterChangeDebounced = debounce(() => {
      this._rangeInput && this._rangeInput.blur()
    }, 100)

    this.updateWindowDimensions = debounce(
      this.updateWindowDimensions.bind(this),
      100
    )
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (width <= 768 && this.state.mode !== 'single')
      this.setState({ mode: 'single' })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match._id !== nextProps.match._id) {
      this.setState({
        ratio: 0.5,
      })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.mode !== nextState.mode) {
      this.props.cookies.remove(MatchImageViewer.COOKIE_RECENT_MODE)

      setTimeout(() => {
        this.props.cookies.set(
          MatchImageViewer.COOKIE_RECENT_MODE,
          nextState.mode,
          {
            path: '/',
            expires: moment()
              .add(2, 'months')
              .toDate(),
          }
        )
      })
    }
  }

  setRangeInputRef = (element) => {
    this._rangeInput = element
  }

  selectSide = () => {
    this.setState({ mode: 'side' })
  }

  selectSingle = () => {
    this.setState({ mode: 'single' })
  }

  handleRatioChange = (e) => {
    this.setState({ ratio: e.target.value })
    this.blurInputAfterChangeDebounced()
  }
  render() {
    const { match, image } = this.props
    const { mode, ratio } = this.state
    const iconSingle = require(`./iconSingle.svg`)
    const iconSide = require(`./iconSide.svg`)

    return (
      <ChooseBestImageSource
        candidates={[image.thumbnail, image.localUrl, image.fallbackUrl, image.url].filter(Boolean)}
        type="image"
        useCache
      >
        {({ url: imageURL, isError }) => {
          return (
            <div className={wrap}>
              {mode === 'single' ? (
                <div className={singleView}>
                  <div className="previewHolder">
                    <div
                      className={cx(!isError && layerOne)}
                      style={{ backgroundImage: `url("${match.localUrl || match.url}")` }}
                    />
                    <div>
                      <img className="previewImage" src={match.localUrl || match.url} />
                    </div>
                  </div>
                </div>
              ) : (
                <SideView>
                  <div
                    className="previewHolder"
                    style={{ width: `calc(${ratio * 100}% - 10px)` }}
                  >
                    <div>
                      <img className="previewImage" src={imageURL} />
                    </div>
                  </div>
                  <div
                    className="previewHolder"
                    style={{ width: `calc(${(1 - ratio) * 100}% - 10px)` }}
                  >
                    <div>
                      <img className="previewImage" src={match.localUrl || match.url} />
                    </div>
                    <div className={layerThree} />
                  </div>
                </SideView>
              )}

              <div css={controlsAndLink}>
                <div css={controls}>
                  <div
                    className={cx(buttonSingle, mode === 'side' && inactive)}
                    onClick={this.selectSingle}
                    dangerouslySetInnerHTML={{ __html: iconSingle }}
                  />
                  <div css={divider} />
                  <div
                    className={cx(buttonSide, mode === 'single' && inactive)}
                    onClick={this.selectSide}
                    dangerouslySetInnerHTML={{ __html: iconSide }}
                  />
                  {mode === 'side' && (
                    <input
                      ref={this.setRangeInputRef}
                      css={range}
                      type="range"
                      min={0.2}
                      step={0.01}
                      value={ratio}
                      max={0.8}
                      onChange={this.handleRatioChange}
                    />
                  )}
                </div>
                <a
                  href={`/images/${image._id}`}
                  target="_blank"
                  css={imageUrlLink}
                  disabled
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html: require('./iconImage.svg'),
                    }}
                  />{' '}
                  OPEN ORIGINAL
                </a>
              </div>
            </div>
          )
        }}
      </ChooseBestImageSource>
    )
  }
}

export default withCookies(MatchImageViewer)

MatchImageViewer.propTypes = {
  match: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
}

const wrap = css``
const singleView = css`
  height: 400px;
  @media (max-width: 1000px) {
    /*height: 350px;*/
    min-height: auto;
  }
  > * {
    width: 100%;
  }

  .previewHolder {
    padding-bottom: 66.66%;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
      position: fixed;
      top: 100px;
      left: 0;
    }
  }

  .previewImage {
    margin: 0 auto;
    max-width: 100%;
    max-height: 400px;
    display: block;
    user-select: none;
    max-height: 100.2%;
    max-width: 100.2%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-filter: blur(0);
    transform: translate(-50%, -51%);
    margin-top: 1%;
  }
`
const controlsAndLink = css`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`

const imageUrlLink = css`
  svg {
    height: 0.75em;
    top: 0px;
    position: relative;
  }
  display: inline-block;
  margin-top: 5px;
  color: rgb(140, 140, 140);
  display: block;
  font-size: 0.8em;
  letter-spacing: 0.25em;
  max-width: 100%;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  @media (max-width: 768px) {
    display: none;
  }
`
const SideView = styled.div`
  height: 400px;
  @media (max-width: 1000px) {
    height: 350px;
    min-height: auto;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  > * {
    margin: 10px;
    overflow: hidden;
    /*max-height: 100%;*/
  }

  .previewHolder {
    margin: 0;
  }

  .previewImage {
    margin: 0 auto;
    width: 100%;
    height: auto;
    display: block;
    user-select: none;
  }
`

const inactive = css`
  opacity: 0.6;
`

// const unavailableNotice = css`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   font-size: 1.2em;
//   letter-spacing: 3px;
//   text-transform: uppercase;
//   color: #fff;
//   opacity: 0.7;
// `

const range = css`
  appearance: none;
  max-width: 350px;
  height: 2px;
  outline: none;
  background-color: #aaa;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-left: 10px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 22px;
    background: #fff;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 22px;
    background: #fff;
    cursor: pointer;
  }
`

const controls = css`
  display: flex;
  flex: 1;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;

    &:first-of-type {
      margin-left: 0px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`
const buttonSingle = css`
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  svg {
    height: 12px;
    opacity: 0.9;
  }
`
const buttonSide = css`
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  svg {
    margin-top: 7px;
    height: 22px;
    margin-left: -3px;
  }
`
const divider = css`
  height: 24px;
  width: 0;
  border-left: 1px solid #66666680;
`

const layerOne = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.3);
  filter: blur(52px) brightness(60%);
`

const layerThree = css`
  display: none;
`
