import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Switch, Route } from 'react-router'
import { compose } from 'recompose'
import { asyncConnect } from 'redux-connect'
import { StickyFloater, GridWrappingColumn, ScrollToTop } from 'common'
import ContactDetailsForm from './ContactDetails/ContactDetailsForm'
import BioContainer from './Bio/BioContainer'
import PlansContainer from './Plans/PlansContainer'
import PaymentsContainer from './Payments/PaymentsContainer'
import SettingsContainer from './Settings/SettingsContainer'
import { getUserRefferalCode } from '../../redux/modules/auth'

class ProfileContainer extends Component {
  links = [
    { path: '/profile', label: 'Profile', isActive: slug => !slug },
    { path: '/profile/bio', label: 'Bio', isActive: slug => slug === 'bio' },
    {
      path: '/profile/plans',
      label: 'Plans',
      isActive: slug => slug === 'plans',
    },
    {
      path: '/profile/payments',
      label: 'Payments',
      isActive: slug => slug === 'payments',
    },
    {
      path: '/profile/settings',
      label: 'Settings',
      isActive: slug => slug === 'settings',
    },
  ]

  render() {
    const {
      user,
      location,
      history,
      match: {
        params: { slug = '' },
      },
      code,
    } = this.props
    return (
      <div>
        <Helmet title="Profile" />
        <ScrollToTop whenChanges={slug} />
        <StickyFloater>
          {this.links.map(({ path, label, isActive }) => (
            <Link
              key={path}
              className={isActive(slug) ? 'active' : null}
              to={path}
            >
              {label}
            </Link>
          ))}
        </StickyFloater>
        <GridWrappingColumn maxWidth={876} padding={200}>
          <Switch>
            <Route
              path="/profile/bio"
              render={() => <BioContainer user={user} />}
            />
            <Route
              path="/profile/plans"
              render={() => <PlansContainer user={user} />}
            />
            <Route
              path="/profile/payments"
              render={() => <PaymentsContainer />}
            />
            <Route
              path="/profile/settings"
              render={() => <SettingsContainer user={user} code={code} />}
            />
            <Route
              path="/profile"
              render={() => (
                <ContactDetailsForm
                  user={user}
                  location={location}
                  history={history}
                  forceValidation={
                    location.state && location.state.forceContactValidation
                  }
                />
              )}
            />
          </Switch>
        </GridWrappingColumn>
      </div>
    )
  }
}

function matchStateToProps(state, props) {
  const {
    auth: { code },
  } = state
  return {
    ...props,
    code,
  }
}

export default compose(
  asyncConnect(
    [
      {
        promise: function getCode({ store: { dispatch } }) {
          const promise = dispatch(getUserRefferalCode())
          return __SERVER__ && promise
        },
      },
    ],
    matchStateToProps
  )
)(ProfileContainer)

ProfileContainer.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
