import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Button, createContactUsHandler } from 'common'

// @TODO: Change TEXT
export default function SubmissionValidationDialogCategoryUnresolvable({
  onTakedown,
  onIgnore,
  cluster,
}) {
  return (
    <div css={wrap}>
      <h1>We are unable to accept this case</h1>

      <h4>
        We have determined that this site does not meet our criteria for a{' '}
        <span data-elevio-article="70">viable commercial case.</span>
      </h4>
      <p>
        Pixsy uses a set of advanced algorithms to pre-determine if a case is
        suitable for resolution. We do this to save you time, and to ensure that
        our case management team can focus their efforts on recovering
        compensation for viable cases.
      </p>
      <p>
        We recommend that you <b>send a Takedown Notice</b> for this match,
        requesting the image is removed by the website operator or host.
      </p>
      <p>
        If you feel this should be a viable commercial case, you can contact us
        and we can further evaluate the case for you.
      </p>

      <div className="dialog-slider--button-wrapper">
        <Button
          onClick={createContactUsHandler('Unresolvable Category')}
          greyTertiary
        >
          Contact us
        </Button>
        <Button grey onClick={onIgnore}>
          Ignore Match
        </Button>
        <Button hoverMain onClick={onTakedown}>
          Send Takedown
        </Button>
      </div>
      {!global.PRODUCTION && (
        <p className="debug">SubmissionValidationDialogCategoryUnresolvable</p>
      )}
    </div>
  )
}

SubmissionValidationDialogCategoryUnresolvable.propTypes = {
  onTakedown: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  cluster: PropTypes.object.isRequired,
}

const wrap = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
`
