import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { ChooseBestImageSource } from 'common'

const wrap = css`
  margin: 10px auto 20px;
  display: flex;
  justify-content: center;
  padding-left: 0;
  overflow: auto;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const item = css`
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 10px;
  box-sizing: border-box;
  opacity: 0.6;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const active = css`
  border: 3px solid #148aa9;
  opacity: 1;
`

const valid = css``

const oneOfMany = css`
  cursor: pointer;
`

export default function ImageWizardSelection({
  images,
  selectedIndex,
  validStates,
  onSelect,
}) {
  return (
    images.length > 1 && (
      <ul css={wrap}>
        {images.map((i, index) => (
          <ChooseBestImageSource
            type="image"
            candidates={[i.preview, i.url]}
            key={index}
          >
            {({ url }) => (
              <li
                onClick={() => onSelect(index)}
                style={{ backgroundImage: `url("${url}")` }}
                className={cx(item, {
                  [oneOfMany]: images.length > 1,
                  [active]: index === selectedIndex,
                  [valid]: !validStates || validStates[index] === true,
                })}
              />
            )}
          </ChooseBestImageSource>
        ))}
      </ul>
    )
  )
}

ImageWizardSelection.propTypes = {
  onSelect: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      preview: PropTypes.string,
    })
  ),
}
