import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Button } from 'common'
import { getClusterQueryTags } from '../../../../redux/selectors'

class NoResults extends Component {
  componentDidMount() {
    try {
      window.mixpanel.track('Matches.NoResults.View', {
        source: 'card',
        tags: this.props.tags.join('/'),
      })
    } catch (e) {
      console.error(e)
    }
  }

  handleCommercialClick() {
    try {
      window.mixpanel.track('Matches.NoResults.Commercial', {
        source: 'card',
      })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { tags } = this.props

    const alreadyOnCommercialFilter =
      tags.includes('pixsy:jurisdictions') ||
      tags.includes('similarity:medium+high') ||
      tags.includes('commercial')

    return (
      <div css={noResultsContainer}>
        <div>
          <IconMatches />
          <h1>0 Matches</h1>
          <p>Your current selection didn‘t return any matches.</p>

          {!alreadyOnCommercialFilter && (
            <Link to="/matches/pixsy:jurisdictions/similarity:medium+high/commercial:true">
              <Button type="primary">See commercial matches</Button>
            </Link>
          )}
        </div>
      </div>
    )
  }
}
export default withRouter(
  connect((state, props) => ({ tags: getClusterQueryTags(state, props) }))(
    NoResults
  )
)

NoResults.propTypes = {
  tags: PropTypes.array.isRequired,
}

const noResultsContainer = css`
  position: fixed;
  top: 15%;
  left: calc(10% + 110px);
  @media (max-width: 1280px) {
    left: calc(10% + 95px);
  }
  width: calc(80% - 110px);
  height: 70%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    min-width: 100px;
    max-height: 280px;
    opacity: 0.25;
  }
  @media (max-width: 768px) {
    left: 0;
    width: 100%;
    svg {
      max-height: 130px;
    }
    h1 {
      font-size: 38px;
    }
  }
`

function IconMatches() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="4629.127 -5981.06 24.379 18.693"
    >
      <defs>
        <style>{`.svg-matches-no-results {fill: #939393;}`}</style>
      </defs>
      <g transform="translate(4629.127 -5981.06)">
        <path
          className="svg-matches-no-results"
          d="M.4,41.078l11.54,4.939a.637.637,0,0,0,.255.052.654.654,0,0,0,.255-.052l11.54-4.939a.65.65,0,0,0-.025-1.2l-11.54-4.389a.647.647,0,0,0-.462,0L.419,39.873a.65.65,0,0,0-.024,1.2ZM12.19,36.786,22,40.515l-9.805,4.2-9.805-4.2Z"
          transform="translate(0 -35.441)"
        />
        <path
          className="svg-matches-no-results"
          d="M.4,126.438l11.795,5.049,11.795-5.049a.65.65,0,0,0-.511-1.195l-11.284,4.83L.906,125.243A.65.65,0,1,0,.4,126.438Z"
          transform="translate(-0.001 -117.991)"
        />
        <path
          className="svg-matches-no-results"
          d="M.4,158.837l11.795,5.049,11.795-5.049a.65.65,0,1,0-.511-1.195l-11.284,4.83L.906,157.642A.65.65,0,1,0,.4,158.837Z"
          transform="translate(-0.001 -147.791)"
        />
        <path
          className="svg-matches-no-results"
          d="M.4,191.24l11.795,5.049,11.795-5.049a.65.65,0,0,0-.511-1.195l-11.284,4.83L.906,190.046A.65.65,0,1,0,.4,191.24Z"
          transform="translate(-0.001 -177.595)"
        />
      </g>
    </svg>
  )
}
