import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { Button } from 'common'
import TakeActionMatchSelectionPreview from './TakeActionMatchSelectionPreview'

export default function SelectMatchScopeDialogTakedown({
  matches,
  cluster,
  location,
}) {
  return (
    <div css={wrap} id="modal-viewer-select-scope" className="viewer-modal">
      <h1>Submit Takedown</h1>
      <p>
        Are you sure you want to submit a takedown for the following matches on
        this domain?
      </p>

      <TakeActionMatchSelectionPreview matches={matches} />

      <Link
        to={{
          ...location,
          pathname: `/takedowns/submit/new`,
          search: `?from=${cluster._id}`,
        }}
      >
        <Button highlightedFromDark disabled={matches.length === 0}>
          Proceed to Takedown Form
        </Button>
      </Link>
    </div>
  )
}

const wrap = css`
  padding: 40px 70px 30px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 10px 30px;
  }

  h4 {
    color: #fff;
  }

  span {
    color: rgba(255, 255, 255, 0.75);
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  button {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
    h4 {
      margin: 0;
      width: 100%;
    }
    ul {
      margin: 20px 0;
    }
    button {
      max-width: 100%;
      white-space: auto;
    }
  }
`

SelectMatchScopeDialogTakedown.propTypes = {
  matches: PropTypes.array.isRequired,
  cluster: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
