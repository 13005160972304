import React from 'react'
import styled from 'react-emotion'

// import FormRow from './FormRow'
// import Label from './Label'
// import Feedback from './Feedback'

const Select = styled.select`
  padding: 0px 36px 0px 16px;
  width: 136px;
  height: 40px;
  appearance: none;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #c7c7c7;
  cursor: pointer;
  color: #434343;
  font-size: 1.25em;
  text-indent: 0.01px;
`

const SelectWrapper = styled.div`
  position: relative;

  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
`

export function CurrencySelectStandalone({ input }) {
  // lazy load here to avoid (considerable) startup lag
  const { CURRENCIES } = require('pixsy-constants')
  return (
    <SelectWrapper>
      <Select {...input}>
        {CURRENCIES.map(c => (
          <option key={c} value={c}>
            {c}{' '}
          </option>
        ))}
      </Select>
      <IconArrowDown />
    </SelectWrapper>
  )
}

// export default function CurrencySelect(props) {
//   const { input, meta, label } = props
//   return (
//     <FormRow {...props}>
//       <Label title={label} />

//       <div>
//        <CurrencySelectStandalone />
//         <Feedback meta={meta} />
//       </div>
//     </FormRow>
//   )
// }

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}
