import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { css } from 'react-emotion'
import {
  Button,
  FloatingInput,
  TwoInputsNextToEachOther,
  CountrySelectStandalone,
} from 'common'
import qs from 'querystring'
import { fields } from './fields'

export default class RegisterForm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { search } = this.props.location

    if (search) {
      const s = qs.parse(String(search).substr(1))
      if (s.referralcode) {
        this.props.change('referral_code', s.referralcode)
      }
    }
  }

  getFieldPropsFor(key) {
    return {
      name: fields[key].name,
      type: fields[key].type,
      props: fields[key].props,
      warn: fields[key].warn,
    }
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const disabled = submitting

    return (
      <form
        onSubmit={handleSubmit}
        role="form"
        className="form-horizontal home-small"
      >
        <Field
          component={FloatingInput}
          {...this.getFieldPropsFor('EMAIL')}
          autocomplete="username"
        />

        <TwoInputsNextToEachOther>
          <Field
            component={FloatingInput}
            {...this.getFieldPropsFor('FIRST_NAME')}
          />
          <Field
            component={FloatingInput}
            {...this.getFieldPropsFor('LAST_NAME')}
          />
        </TwoInputsNextToEachOther>

        <Field
          component={FloatingInput}
          {...this.getFieldPropsFor('COMPANY')}
          autocomplete="company"
        />

        <span css={errorStyle}>
          <Field
            component={CountrySelectStandalone}
            {...this.getFieldPropsFor('COUNTRY')}
            isRegister
          />
        </span>

        <Field
          component={FloatingInput}
          {...this.getFieldPropsFor('PASSWORD')}
          autocomplete="new-password"
        />
        <Field
          component={FloatingInput}
          {...this.getFieldPropsFor('PASSWORD_CONFIRM')}
          autocomplete="new-password"
        />

        <Field
          component={FloatingInput}
          maxLength={8}
          {...this.getFieldPropsFor('REFERRAL_CODE')}
        />

        <Button disabled={disabled} submit type="action" full>
          {disabled ? 'Preparing..' : 'Continue'}
        </Button>
      </form>
    )
  }
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const errorStyle = css`
  [data-error] {
    color: #e66363;
    font-size: 12px;
    font-style: normal;
  }
`
