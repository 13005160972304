import React from 'react'
import { PHOTOSHELTER } from 'pixsy-constants'
import PropTypes from 'prop-types'
import { Button, TextFieldNoLabel } from 'common'
import { reduxForm, Field } from 'redux-form'
import { css } from 'react-emotion'

export function PhotoshelterDialog({
  synchronize,
  submitting,
  handleSubmit,
  valid,
}) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Importing from Photoshelter</h1>

      <p>
        In order to import your images from Photoshelter, we need your{' '}
        <em>Photoshelter User ID</em>. On the Photoshelter website, go to “My
        Account”. Your User ID will be listed under “General Information”.
      </p>

      <p>
        <i>Important:</i>
        <br />
        Only publicly searchable images can be imported.{' '}
        <a
          href="https://support.photoshelter.com/hc/en-us/articles/203373500-Making-Images-Searchable"
          target="_blank"
        >
          Read More
        </a>
        .
      </p>

      <p>
        <i>Example:</i>
        <br />
        <em>U0000M5pzER81b8U</em>
      </p>

      <div css={actionArea}>
        <Field
          component={TextFieldNoLabel}
          type="text"
          name="photoshelterUserId"
          label="Photoshelter User ID"
          labelAlign="right"
        />

        <Button submit disabled={!valid || submitting}>
          {submitting ? 'Importing...' : 'Import'}
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'photodeckImport',
  validate(values, props) {
    if (!values.photoshelterUserId)
      return { photoshelterUserId: 'User ID required.' }
    if (!values.photoshelterUserId.startsWith('U')) {
      return { photoshelterUserId: 'User IDs start with the letter U' }
    }
  },
  onSubmit(values, dispatch, { synchronize, onClose }) {
    return synchronize(PHOTOSHELTER, values.photoshelterUserId).then(onClose)
  },
})(PhotoshelterDialog)

PhotoshelterDialog.propTypes = {
  synchronize: PropTypes.func.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  em {
    color: #008aab;
  }
`

const actionArea = css`
  max-width: 530px;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  input {
    margin-bottom: 10px;
  }
`
