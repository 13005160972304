import { RULE, RULE_ARRAY, CLUSTER, CLUSTER_ARRAY } from './schemas'

export const RULES_INVALIDATE = 'RULES_INVALIDATE'

const RULES_LOAD = 'RULES_LOAD'
export const RULES_LOAD_SUCCESS = 'RULES_LOAD_SUCCESS'
const RULES_LOAD_FAIL = 'RULES_LOAD_FAIL'

const RULE_CREATE = 'RULE_CREATE'
export const RULE_CREATE_SUCCESS = 'RULE_CREATE_SUCCESS'
const RULE_CREATE_FAIL = 'RULE_CREATE_FAIL'

export const RULE_REMOVE = 'RULE_REMOVE'
const RULE_REMOVE_SUCCESS = 'RULE_REMOVE_SUCCESS'
const RULE_REMOVE_FAIL = 'RULE_REMOVE_FAIL'

const RULES_BATCH_CREATE = 'RULES_BATCH_CREATE'
const RULES_BATCH_CREATE_SUCCESS = 'RULES_BATCH_CREATE_SUCCESS'
const RULES_BATCH_CREATE_FAIL = 'RULES_BATCH_CREATE_FAIL'

// const RULE_UPDATE = 'RULE_UPDATE'
// const RULE_UPDATE_SUCCESS = 'RULE_UPDATE_SUCCESS'
// const RULE_UPDATE_FAIL = 'RULE_UPDATE_FAIL'

const initialState = {
  loading: false,
  data: [],
  updatingClusters: [],
}

export default function rules(state = initialState, action = {}) {
  switch (action.type) {
    case RULES_LOAD:
    case RULE_CREATE:
    case RULES_BATCH_CREATE:
      return {
        ...state,
        loading: true,
        ...(action.payload &&
          action.payload.updatingClusters && {
            updatingClusters: action.payload.updatingClusters,
          }),
      }
    case RULES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.rules,
      }
    case RULES_LOAD_FAIL:
    case RULE_CREATE_SUCCESS:
    case RULE_CREATE_FAIL:
    case RULES_BATCH_CREATE_SUCCESS:
    case RULES_BATCH_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        ...(action.payload &&
          action.payload.clusters && {
            currentUpdatingCluster: state.updatingClusters.filter(
              currentUpdatingCluster =>
                !action.payload.updatingClusters.includes(
                  currentUpdatingCluster
                )
            ),
          }),
      }
    default:
      return state
  }
}

export function defaultQuery() {
  return {
    tab: 'ignored',
  }
}

export function paginationKey(query) {
  return 'all'
}

export function load() {
  return {
    types: [RULES_LOAD, RULES_LOAD_SUCCESS, RULES_LOAD_FAIL],
    promise: client => client.get('/rules'),
    schema: {
      rules: RULE_ARRAY,
    },
  }
}

export function create(rule, relatedClusterIds) {
  return {
    types: [RULE_CREATE, RULE_CREATE_SUCCESS, RULE_CREATE_FAIL],
    payload: {
      updatingClusters: relatedClusterIds,
    },
    promise: client =>
      client.post('/rules', { data: { rule, relatedClusterIds } }),
    schema: {
      rule: RULE,
      cluster: CLUSTER,
      clusters: CLUSTER_ARRAY,
    },
  }
}

export function createBatch(setting, list) {
  return {
    types: [
      RULES_BATCH_CREATE,
      RULES_BATCH_CREATE_SUCCESS,
      RULES_BATCH_CREATE_FAIL,
    ],
    promise: client => client.post('/rules/batch', { data: { setting, list } }),
  }
}

export function remove(id) {
  return {
    types: [RULE_REMOVE, RULE_REMOVE_SUCCESS, RULE_REMOVE_FAIL],
    promise: client => client.delete('/rules/' + id),
    schema: {
      cluster: CLUSTER,
    },
    payload: {
      invalidate: {
        rules: [id],
      },
    },
  }
}
