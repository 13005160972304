import { useQuery } from '@apollo/client'
import { helpers } from '../store'
import useClusterFilters from './useClusterFilters'
import useNotifyMutation from './useNotifyMutation'
import GET_CLUSTERS from '../graphql/queries/clusters'
import IGNORE_CLUSTER from '../graphql/mutations/ignoreCluster'
import IGNORE_MATCH from '../graphql/mutations/ignoreMatchWebApp'
import FLAG_CLUSTER from '../graphql/mutations/flagCluster'
import ClusterFragment from '../graphql/queries/cluster.fragment'
import IGNORE_CATEGORY from '../graphql/mutations/ignoreCategory'
import IGNORE_COUNTRY from '../graphql/mutations/ignoreCountry'
import IGNORE_DOMAIN from '../graphql/mutations/ignoreDomain'
import IGNORE_IMAGE from '../graphql/mutations/ignoreImage'
import FALSE_POSITIVE from '../graphql/mutations/falsePositive'

function updateClustersCache(mutationType) {
  return (cache, { data }) => {
    const mutationRes = data[mutationType]

    cache.modify({
      fields: {
        clusters: (existingClusters = []) => {
          const newClusterRef = cache.writeFragment({
            id: cache.identify(mutationRes),
            data: mutationRes,
            fragment: ClusterFragment,
          })

          return existingClusters.map((c) => (c.__ref === newClusterRef.__ref ? newClusterRef : c))
        },
      },
    })
  }
}

export function useIgnoreCluster(globalDispatch, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, IGNORE_CLUSTER, {
    update: updateClustersCache('ignoreCluster'),
    refetchQueries: ['getFiltersAndSummary'],
    requestMessage: 'Ignoring matches. Please wait...',
    successMessage: 'Matches updated',
    defaultErrorMessage: 'Could not ignore requested matches, please try again',
    ...useMutationConfig,
  })
}

export function useIgnoreMatch(globalDispatch, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, IGNORE_MATCH, {
    update: updateClustersCache('ignoreMatchWebApp'),
    refetchQueries: ['getFiltersAndSummary'],
    requestMessage: 'Ignoring match. Please wait...',
    successMessage: 'Match updated',
    defaultErrorMessage: 'Could not ignore match, please try again',
    ...useMutationConfig,
  })
}

export function useFlagMatch(globalDispatch, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, FLAG_CLUSTER, {
    update: updateClustersCache('toggleFlag'),
    ...useMutationConfig,
  })
}

export function useIgnoreCategory(globalDispatch, categoryName, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, IGNORE_CATEGORY, {
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
    requestMessage: `Ignoring all matches from category "${categoryName}". Please, wait...`,
    successMessage: `All matches from category "${categoryName}" were ignored`,
    defaultErrorMessage: `Could not ignore matches from category "${categoryName}", please, try again`,
    ...useMutationConfig,
  })
}

export function useIgnoreCountry(globalDispatch, countryName, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, IGNORE_COUNTRY, {
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
    requestMessage: `Ignoring all matches coming from "${countryName}". Please, wait...`,
    successMessage: `All matches coming from "${countryName}" were ignored`,
    defaultErrorMessage: `Could not ignore matches coming from "${countryName}". Please, try again`,
    ...useMutationConfig,
  })
}

export function useIgnoreDomain(globalDispatch, domainHost, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, IGNORE_DOMAIN, {
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
    requestMessage: `Ignoring all matches from "${domainHost}". Please, wait...`,
    successMessage: `All matches from "${domainHost}" were ignored`,
    defaultErrorMessage: `Could not ignore matches from "${domainHost}". Please, try again`,
    ...useMutationConfig,
  })
}

export function useIgnoreImage(globalDispatch, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, IGNORE_IMAGE, {
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
    requestMessage: 'Ignoring image. Please wait...',
    successMessage: 'image updated',
    defaultErrorMessage: 'Could not ignore image, please try again',
    ...useMutationConfig,
  })
}
export function useFalsePositiveMatch(globalDispatch, clusterId, useMutationConfig = {}) {
  return useNotifyMutation(globalDispatch, FALSE_POSITIVE, {
    refetchQueries: ['getClusters', 'getFiltersAndSummary'],
    requestMessage: 'Setting match as a false positive',
    successMessage: 'Match set as false positive successfully',
    defaultErrorMessage: 'Could not set match as false positive. Please, try again',
    ...useMutationConfig,
  })
}

export default function useClusters(globalDispatch) {
  const { pageOptionProps, selectedFilters, dispatch } = useClusterFilters()

  const variables = {
    limit: pageOptionProps.perPage,
    skip: pageOptionProps.perPage * pageOptionProps.forcePage,
    sort: helpers.getSortQueryByValue(pageOptionProps.sort),
    filters: selectedFilters,
  }

  const { loading, data } = useQuery(GET_CLUSTERS, {
    variables,
  })

  const clusters = (data && data.clusters) || []

  const [ignoreCluster] = useIgnoreCluster(globalDispatch)
  const [ignoreMatch] = useIgnoreMatch(globalDispatch)
  const [ignoreImage] = useIgnoreImage(globalDispatch)
  const [flagCluster] = useFlagMatch(globalDispatch)

  return {
    loading,
    clusters,
    pageOptionProps,
    ignoreCluster,
    ignoreMatch,
    ignoreImage,
    flagCluster,
    dispatch,
  }
}
