// 4. Profile
import Fade from '@material-ui/core/Fade'
import { Button } from 'common'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import {
  ProfileSchema,
  PROFILE_LABELS as LABELS,
  PROFILE_NAMES as NAMES,
  PROFILE_REQUIRED as REQUIRED,
} from 'pixsy-schema/user/ProfileSchema'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { connect } from 'react-redux'
import {
  load as loadAuth,
  requestEmailVerification,
  updateUser,
} from '../../../../../../redux/modules/auth'
import {
  PixsyErrorMessage,
  PixsyForm,
  PixsyFormGroup,
  PixsyGrid,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
  PixsyTextField,
} from '../../../Components'

/**
 * @augments {React.Component<{ user: import('../StageProps').IProps["user"] }, { isEmailVerified: boolean }>}
 */
class ProfileStageForm extends React.Component {
  fieldsDisplayOrder = [
    NAMES.title,
    NAMES.first_name,
    NAMES.published,
    NAMES.country,
  ]

  constructor(props) {
    super(props)
    this.state = {
      isEmailVerified: this.checkIfEmailIsVerified(),
    }
    this.props.subscribeToOnBlurEvent(this.triggerAutoSave)
  }

  componentDidMount() {
    this._isMounted = true
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  scheduleAutoSave = () => {
    const ATTEMPT_NEXT_SAVE_AT = 3e3 // 3 sec

    if (this.autoSaveTimer) {
      clearTimeout(this.autoSaveTimer)
    }

    this.autoSaveTimer = setTimeout(() => {
      if (this._isMounted) this.triggerAutoSave()
    }, ATTEMPT_NEXT_SAVE_AT)
  }

  triggerAutoSave = () => {
    if (!this._isMounted) return

    const { isFormSubmitting } = this.props

    if (isFormSubmitting) return void this.scheduleAutoSave()

    return void this.handleSave()
  }

  handleSave = () => {
    const { updateUser, values, setSubmitting, context = {} } = this.props

    const details = ProfileSchema.cast(values, { context: { values: {} } })

    delete details.email

    if (ProfileSchema.isValidSync(details, { context })) {
      setSubmitting(true)

      updateUser({ details }, true)
        .catch(console.warn)
        .finally(() => setSubmitting(false))
    }
  }

  checkIfEmailIsVerified = () => {
    const { user } = this.props
    if (
      isPlainObject(user.verification) &&
      isPlainObject(user.verification.email) &&
      isString(user.verification.email.dateVerified)
    )
      return true
  }

  getDisabledFields = () => {
    const { errors } = this.props
    const { isEmailVerified } = this.state
    const disabled = {}
    let disableRest = false

    this.fieldsDisplayOrder.forEach(name => {
      if (disableRest) {
        disabled[name] = true
        return
      }

      const error = errors[name]
      if (
        (isPlainObject(error) && isPlainObject(error.message)) ||
        (name === NAMES.email && !isEmailVerified)
      ) {
        disableRest = true
      }
      disabled[name] = false
    })

    return disabled
  }

  ErrorBox = ({ name, disabled }) => {
    return (
      !disabled[name] && (
        <PixsyErrorMessage
          name={NAMES[name]}
          render={({
            error: {
              message: { title, subtitle },
            },
          }) => (
            <Fade in>
              <PixsyPaper
                variant="transparent"
                borderVariant="default"
                borderlessLeft
                borderlessRight
              >
                <PixsyPaper
                  padding="15px 60px 15px 60px"
                  className={errorStyle}
                  expanded
                  variant="red"
                >
                  <div css={errorTitleStyle}>{title}</div>
                  {subtitle && <div css={errorSubTitleStyle}>{subtitle}</div>}
                </PixsyPaper>
              </PixsyPaper>
            </Fade>
          )}
        />
      )
    )
  }

  refreshStatus = () => {
    const { loadAuth } = this.props
    loadAuth().then(() => {
      this.setState({
        isEmailVerified: this.checkIfEmailIsVerified(),
      })
    })
  }

  requestConfirmationLink = e => {
    const {
      authVerifyEmailLoading,
      authLoading,
      requestEmailVerification,
    } = this.props

    e && e.preventDefault()

    if (!authLoading && !authVerifyEmailLoading) requestEmailVerification()
  }

  VerifyEmailWarningBox = () => {
    const { isEmailVerified } = this.state
    const { authVerifyEmailLoading, authLoading } = this.props

    return (
      !isEmailVerified && (
        <Fade in>
          <PixsyPaper
            variant="transparent"
            borderVariant="default"
            borderlessLeft
            borderlessRight
          >
            <PixsyPaper padding="36px 60px 36px 60px" expanded variant="grey">
              <PixsyFormGroup direction="column">
                <PixsyGridItem xs={12}>
                  <PixsyGrid justify="center">
                    <PixsyGridItem>
                      <div css={greyTitleStyle}>
                        Heads up! You need to verify your email address first.
                        Click the activation link in the verification email and
                        return to this page.
                      </div>
                    </PixsyGridItem>
                  </PixsyGrid>
                </PixsyGridItem>
                <PixsyGridItem xs={12}>
                  <PixsyGrid justify="center">
                    <PixsyGridItem>
                      <Button
                        grey
                        withFocusOutline
                        disabled={authLoading}
                        onClick={this.refreshStatus}
                      >
                        Refresh Status
                      </Button>
                    </PixsyGridItem>
                  </PixsyGrid>
                </PixsyGridItem>
                <Fade in={!authVerifyEmailLoading && !authLoading}>
                  <PixsyGridItem xs={12}>
                    <PixsyGrid justify="center">
                      <PixsyGridItem>
                        No Link?{' '}
                        <a href="#" onClick={this.requestConfirmationLink}>
                          Resend Email
                        </a>
                      </PixsyGridItem>
                    </PixsyGrid>
                  </PixsyGridItem>
                </Fade>
              </PixsyFormGroup>
            </PixsyPaper>
          </PixsyPaper>
        </Fade>
      )
    )
  }

  TextField = React.memo(
    ({
      name,
      type,
      disabled,
      borderlessBottom,
      borderlessTop,
      padding = '20px 60px 20px 60px',
      labelXs = 4,
      inputXs = 8,
      readOnly,
    }) => {
      const DEBOUNCE_VALIDATION_SYNC = 2e2

      return (
        <PixsyPaper
          padding={padding}
          disabled={disabled[name]}
          borderlessBottom={borderlessBottom}
          borderlessTop={borderlessTop}
        >
          <PixsyFormGroup>
            <PixsyGridItem xs={labelXs}>
              <PixsyLabel text={LABELS[name]} required={REQUIRED[name]} />
            </PixsyGridItem>
            <PixsyGridItem xs={inputXs}>
              <PixsyTextField
                type={type}
                name={name}
                readOnly={readOnly}
                disabled={disabled[name] || readOnly}
                debounceValidationOnChange={DEBOUNCE_VALIDATION_SYNC}
                validateOnChange
                validateOnBlur
              />
            </PixsyGridItem>
          </PixsyFormGroup>
        </PixsyPaper>
      )
    }
  )

  render() {
    const disabled = this.getDisabledFields()
    const { ErrorBox, TextField, VerifyEmailWarningBox } = this

    return (
      <React.Fragment>
        <TextField
          name={NAMES.title}
          type="title-select"
          disabled={disabled}
          padding="50px 60px 20px 60px"
          inputXs={2}
          borderlessBottom
        />
        <ErrorBox name={NAMES.title} disabled={disabled} />

        <TextField
          name={NAMES.first_name}
          disabled={disabled}
          inputXs={4}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.first_name} disabled={disabled} />

        <TextField
          name={NAMES.last_name}
          disabled={disabled}
          inputXs={4}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.last_name} disabled={disabled} />

        <TextField
          name={NAMES.legal_name}
          disabled={disabled}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.legal_name} disabled={disabled} />

        <TextField
          name={NAMES.email}
          disabled={disabled}
          readOnly
          borderlessBottom
          borderlessTop
        />

        {!disabled[NAMES.email] && <VerifyEmailWarningBox />}

        <TextField
          name={NAMES.date_of_birth}
          type="date"
          disabled={disabled}
          inputXs={4}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.date_of_birth} disabled={disabled} />

        <TextField
          name={NAMES.phone}
          disabled={disabled}
          inputXs={4}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.phone} disabled={disabled} />

        <TextField
          name={NAMES.street}
          disabled={disabled}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.street} disabled={disabled} />

        <TextField
          name={NAMES.city}
          disabled={disabled}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.city} disabled={disabled} />

        <TextField
          name={NAMES.postal_code}
          disabled={disabled}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.postal_code} disabled={disabled} />

        <TextField
          name={NAMES.state}
          disabled={disabled}
          borderlessBottom
          borderlessTop
        />
        <ErrorBox name={NAMES.state} disabled={disabled} />

        <TextField
          name={NAMES.country}
          type="country-select"
          disabled={disabled}
          padding="20px 60px 50px 60px"
          borderlessTop
        />
        <ErrorBox name={NAMES.country} disabled={disabled} />
      </React.Fragment>
    )
  }
}

const ProfileStageWithProps = connect(
  (state, props) => ({
    authLoading: state.auth.loading,
    authVerifyEmailLoading: state.auth.emailVerifyLoading,
    user: state.auth.user,
  }),
  { updateUser, loadAuth, requestEmailVerification }
)(ProfileStageForm)

export const ProfileStage = React.memo(
  /** @type {React.FC<import('../StageProps').IProps>} */
  (({ user }) => {
    const initialValues = useMemo(
      () => ({
        ...user.details,
        email: user.email,
      }),
      []
    )
    return (
      <PixsyForm
        initialValues={initialValues}
        schema={ProfileSchema}
        render={api => <ProfileStageWithProps {...api} />}
      />
    )
  })
)

ProfileStageWithProps.propTypes = {
  updateUser: PropTypes.func.isRequired,
  loadAuth: PropTypes.func.isRequired,
  requestEmailVerification: PropTypes.func.isRequired,
}

const errorStyle = css`
  * {
    text-align: center;
  }
`
const errorTitleStyle = css`
  font-size: 14px;
  color: #2e2e2e;
`
const errorSubTitleStyle = css`
  font-size: 12px;
  color: #6e6e6e;
  margin-top: 6px;
`
const greyTitleStyle = css`
  font-size: 16px;
  margin-bottom: 15px;
  text-align: center;
`
