import React from 'react'
import FormRow from './FormRow'
import styled from 'react-emotion'
import Label from './Label'
import Feedback from './Feedback'

const Select = styled.select`
  padding: 0px 36px 0px 16px;
  width: 260px;
  height: 40px;
  appearance: none;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #c7c7c7;
  cursor: pointer;
  color: #434343;
  font-size: 1.25em;
  text-indent: 0.01px;
`

const SelectWrapper = styled.div`
  position: relative;

  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
`

export default function JurisdictionSelect(props) {
  const { input, meta, label } = props

  // lazy load here to avoid (considerable) startup lag
  const { TAKEDOWN_JURISDICTIONS } = require('pixsy-constants')
  return (
    <FormRow {...props}>
      <Label title={label} />

      <div>
        <SelectWrapper>
          <Select {...input}>
            {TAKEDOWN_JURISDICTIONS.map(j => (
              <option key={j.code} value={j.code}>
                {j.title}
              </option>
            ))}
          </Select>
          <IconArrowDown />
        </SelectWrapper>
        <Feedback meta={meta} />
      </div>
    </FormRow>
  )
}

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}
