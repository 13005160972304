const SET_SELECTED_COUNTRY_IDS = '@FILTERS/SET_SELECTED_COUNTRY_IDS'
const SET_SELECTED_CATEGORY_IDS = '@FILTERS/SET_SELECTED_CATEGORY_IDS'
const SET_SELECTED_ACTION_IDS = '@FILTERS/SET_SELECTED_ACTION_IDS'
const SET_SELECTED_YEAR_RANGE = '@FILTERS/SET_SELECTED_YEAR_RANGE'
const SET_SELECTED_MATCHES_TYPE = '@FILTERS/SET_SELECTED_MATCHES_TYPE'
const SET_SELECTED_SEEN_STATE = '@FILTERS/SET_SELECTED_SEEN_STATE'
const RESET_USER_SELECTIONS_STATE = '@FILTERS/RESET_USER_SELECTIONS_STATE'
const SET_COLLAPSABLE_CONTENT_CLOSE_FUNCTION = '@FILTERS/SET_COLLAPSABLE_CONTENT_CLOSE_FUNCTION'

const ADD_SELECTED_COUNTRY_ID = '@FILTERS/ADD_SELECTED_COUNTRY_ID'
const ADD_SELECTED_CATEGORY_ID = '@FILTERS/ADD_SELECTED_CATEGORY_ID'
const ADD_SELECTED_ACTION_ID = '@FILTERS/ADD_SELECTED_ACTION_ID'

const UPDATE_SELECTED_FILTERS = '@FILTERS/UPDATE_SELECTED_STATE'
const UPDATE_SELECTED_CONTEXT = '@CONTEXT/UPDATE_SELECTED_STATE'

const SET_GROUP_MATCHES_VIEW_OPEN = '@MATCHES/SET_GROUP_MATCHES_VIEW_OPEN'
const SET_GROUP_MATCHES_VIEW_CLOSE = '@MATCHES/SET_GROUP_MATCHES_VIEW_CLOSE'
const RESET_SCROLL_TO_CLUSTER_ID = '@MATCHES/RESET_SCROLL_TO_CLUSTER_ID'

const SET_MORE_MODAL_VIEW_OPEN = '@DETAILS/SET_MORE_MODAL_VIEW_OPEN'
const SET_DETAILS_MODAL_VIEW_OPEN = '@DETAILS/SET_DETAILS_MODAL_VIEW_OPEN'
const SET_ACTIONS_MODAL_VIEW_OPEN = '@DETAILS/SET_ACTIONS_MODAL_VIEW_OPEN'
const SET_MODAL_VIEW_CLOSE = '@DETAILS/SET_MODAL_VIEW_CLOSE'

const SET_LAST_IGNORED_MATCH = '@CONTEXT/SET_LAST_IGNORED_MATCH'
const RESET_LAST_IGNORED_MATCH = '@CONTEXT/RESET_LAST_IGNORED_MATCH'

const SET_LIST_VIEW_PAGE_NUMBER = '@CONTEXT/SET_LIST_VIEW_PAGE_NUMBER'
const SET_LIST_VIEW_PER_PAGE_COUNT = '@CONTEXT/SET_LIST_VIEW_PER_PAGE_COUNT'
const SET_LIST_VIEW_PAGE_SORT = '@CONTEXT/SET_LIST_VIEW_PAGE_SORT'

export {
  SET_SELECTED_COUNTRY_IDS,
  SET_SELECTED_CATEGORY_IDS,
  SET_SELECTED_ACTION_IDS,
  SET_SELECTED_YEAR_RANGE,
  SET_COLLAPSABLE_CONTENT_CLOSE_FUNCTION,
  SET_SELECTED_MATCHES_TYPE,
  SET_SELECTED_SEEN_STATE,
  RESET_USER_SELECTIONS_STATE,
  ADD_SELECTED_COUNTRY_ID,
  ADD_SELECTED_CATEGORY_ID,
  ADD_SELECTED_ACTION_ID,
  UPDATE_SELECTED_FILTERS,
  SET_MORE_MODAL_VIEW_OPEN,
  SET_DETAILS_MODAL_VIEW_OPEN,
  SET_ACTIONS_MODAL_VIEW_OPEN,
  SET_GROUP_MATCHES_VIEW_OPEN,
  RESET_SCROLL_TO_CLUSTER_ID,
  SET_MODAL_VIEW_CLOSE,
  UPDATE_SELECTED_CONTEXT,
  SET_LAST_IGNORED_MATCH,
  RESET_LAST_IGNORED_MATCH,
  SET_LIST_VIEW_PAGE_NUMBER,
  SET_LIST_VIEW_PER_PAGE_COUNT,
  SET_LIST_VIEW_PAGE_SORT,
  SET_GROUP_MATCHES_VIEW_CLOSE,
}
