import {
  QDefineConstEnums,
  QNumber,
  QEnumString,
  QBoolean,
  QString,
  QArray,
  QSchema,
  prefixConstEnumValues,
  objectWithKeysAsValues,
  QSchemaConditional,
  QReference,
} from '../core'
import { CURRENCIES } from 'pixsy-constants/index'
import getIn from 'lodash/get'
import { FileSchema, FILE_SCHEMA_NAMES } from '../file/FileSchema'

export const CURRENCIES_ENUM = QDefineConstEnums(...CURRENCIES)
export const PRICE_RANGE_ENUM = QDefineConstEnums('Client', 'Pixsy', 'Highest')

export const SETTLEMENT_KEYS = objectWithKeysAsValues({
  __GENERAL_ATTACHMENTS__: '__GENERAL_ATTACHMENTS__',
  continued_use_license: 'continued_use_license',
  price_range: 'price_range',
  requested_price: 'requested_price',
  comments: 'comments',
  koDisclaimerTruth: 'koDisclaimerTruth',
  koDisclaimerCosts: 'koDisclaimerCosts',
  koDisclaimerAgentAgreement: 'koDisclaimerAgentAgreement',
})

export const SETTLEMENT_PRICE_KEYS = objectWithKeysAsValues({
  min: 'min',
  max: 'max',
})
export const SETTLEMENT_PRICE_MAX_KEYS = objectWithKeysAsValues({
  value: 'value',
  currency: 'currency',
})
export const SETTLEMENT_PRICE_MIN_KEYS = objectWithKeysAsValues({
  value: 'value',
  currency: 'currency',
})

export const SETTLEMENT_PRICE_NAMES = {
  [SETTLEMENT_PRICE_KEYS.max]: prefixConstEnumValues(
    SETTLEMENT_PRICE_KEYS.max,
    SETTLEMENT_PRICE_MAX_KEYS
  ),
  [SETTLEMENT_PRICE_KEYS.min]: prefixConstEnumValues(
    SETTLEMENT_PRICE_KEYS.max,
    SETTLEMENT_PRICE_MIN_KEYS
  ),
}

export const GENERAL_ATTACH_INDEX = '_GENERAL_ATTACH_INDEX_'

export const SETTLEMENT_NAMES = {
  ...SETTLEMENT_KEYS,
  [SETTLEMENT_KEYS.requested_price]: prefixConstEnumValues(
    SETTLEMENT_KEYS.requested_price,
    SETTLEMENT_PRICE_NAMES
  ),
  [SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__]: prefixConstEnumValues(
    `${SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__}[${GENERAL_ATTACH_INDEX}]`,
    FILE_SCHEMA_NAMES
  ),
}

// prettier-ignore
export const SETTLEMENT_LABEL = {
  [SETTLEMENT_NAMES.continued_use_license]: `Do you consent to Pixsy including a time-bound 'continued use' licence in exchange for a higher price?`,
  [SETTLEMENT_NAMES.price_range]: `What pricing do you prefer for negotiations?`,
  [SETTLEMENT_NAMES.requested_price.min.value]: `What is your suggested minimum price for this use?`,
  [SETTLEMENT_NAMES.comments]: `General notes and comments`,
  [SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__]: `Any files, attachments, invoices or evidence you would like to add?`,
  [SETTLEMENT_NAMES.koDisclaimerTruth]: `I certify that all the information and statements provided by me, concerning this case, are true and accurate to the best of my knowledge.`,
}

export const SETTLEMENT_REQUIRED = {
  [SETTLEMENT_NAMES.continued_use_license]: true,
  [SETTLEMENT_NAMES.price_range]: true,
  [SETTLEMENT_NAMES.comments]: false,
  [SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__]: false,
  [SETTLEMENT_NAMES.koDisclaimerTruth]: true,
  [SETTLEMENT_NAMES.requested_price.max.value]: true,
  [SETTLEMENT_NAMES.requested_price.max.currency]: true,
  [SETTLEMENT_NAMES.requested_price.min.value]: true,
  [SETTLEMENT_NAMES.requested_price.min.currency]: true,
}

export const SETTLEMENT_INFO = {
  [SETTLEMENT_NAMES.requested_price.min.value]: {
    title: `Minimum set price must be less than 100 million.`,
  },
  [SETTLEMENT_NAMES.koDisclaimerTruth]: {
    title: `You are legally authorizing Pixsy to act on your behalf, including negotiations, settlements and potential legal action. We must have your agreement in order to accept your case.`,
  },
}

// TESTS
export const FAIL_ON_FALSE = optional => value => {
  if (optional) {
    return value == null || value
  }
  return value
}

// prettier-ignore
export const SETTLEMENT_PRICE_MIN_TYPES = {
  [SETTLEMENT_PRICE_MIN_KEYS.value]: QNumber
    .positive({ title: 'Minimum allowed value is 1' })
    .required({ title: 'Please provide a valid price range' })
    .test(
      'check-size',
      SETTLEMENT_INFO[SETTLEMENT_NAMES.requested_price.min.value],
      function maxOneBillion(value) {
        const ctx = this.options.context

        if (
          typeof ctx === 'object' &&
          ctx !== null &&
          typeof ctx.values === 'object'
          && ctx.values !== null
        ) {
          const maxValue = 100000000 // 100 million yen are 1m usd 
          const minValue = Number(value)

          if (
            Number.isNaN(minValue) ||
            value == null ||
            value === ''
          ) return true

          if (minValue >= maxValue) {
            return false
          }
        }
        return true
      })
  ,
  [SETTLEMENT_PRICE_MIN_KEYS.currency]: QEnumString(CURRENCIES_ENUM)
    .required()
    .default(CURRENCIES_ENUM.USD),
}
export const SettlementPriceMinSchema = QSchema.shape(SETTLEMENT_PRICE_MIN_TYPES) // prettier-ignore

// prettier-ignore
export const SETTLEMENT_PRICE_MAX_TYPES = {
  [SETTLEMENT_PRICE_MAX_KEYS.value]: QNumber,
    // .positive({ title: 'Minimum allowed value is 1' })
    // .max(1e9, { title: `Maximum allowed value is ${Number(1e9).toLocaleString()}` })
    // .required({ title: 'Please provide the maximum amount' }),
  [SETTLEMENT_PRICE_MAX_KEYS.currency]: QEnumString(CURRENCIES_ENUM)
    .required()
    .default(CURRENCIES_ENUM.USD),
}
export const SettlementPriceMaxSchema = QSchema.shape(SETTLEMENT_PRICE_MAX_TYPES) // prettier-ignore

export const SETTLEMENT_PRICE_TYPES = {
  [SETTLEMENT_PRICE_KEYS.min]: SettlementPriceMinSchema,
  [SETTLEMENT_PRICE_KEYS.max]: SettlementPriceMaxSchema,
}

// prettier-ignore
export const SettlementPriceSchema = QSchema
  .shape(SETTLEMENT_PRICE_TYPES)
  .transform(function setMinCurrencyAsMaxCurrency(v) {
    if (this.isType(v) && v != null) {
      const rawMin = v[SETTLEMENT_PRICE_KEYS.min]
      const rawMax = v[SETTLEMENT_PRICE_KEYS.max]

      const min = SettlementPriceMinSchema.isType(rawMin)
        ? SettlementPriceMinSchema.cast(rawMin)
        : SettlementPriceMinSchema.default()

      const max = SettlementPriceMaxSchema.isType(rawMax)
        ? SettlementPriceMaxSchema.cast(rawMax)
        : SettlementPriceMaxSchema.default()

      const maxCurrency = max[SETTLEMENT_PRICE_MAX_KEYS.currency]

      min[SETTLEMENT_PRICE_MIN_KEYS.currency] = maxCurrency

      const nextValue = {
        [SETTLEMENT_PRICE_KEYS.min]: min,
        [SETTLEMENT_PRICE_KEYS.max]: max,
      }

      return nextValue
    }
    return v
  })

// prettier-ignore
export const SETTLEMENT_TYPES = {
  [SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__]: QArray
    .of(FileSchema({ strip: true }))
    .compact(v => !v.uploadComplete || !v.url),
  [SETTLEMENT_KEYS.continued_use_license]: QBoolean.required({
    title: 'Please confirm if you consent to Pixsy including a time-bound \'continued use\' licence in exchange for a higher price'
  }),
  [SETTLEMENT_KEYS.price_range]: QEnumString(PRICE_RANGE_ENUM).required().default(PRICE_RANGE_ENUM.Pixsy),
  [SETTLEMENT_KEYS.comments]: QString,
  [SETTLEMENT_KEYS.koDisclaimerTruth]: QBoolean
    .required({
      title: 'Please confirm if you have read, agree to and understand the Pixsy Agent Authorization Agreement'
    })
    .test(
      'no-false',
      SETTLEMENT_INFO[SETTLEMENT_NAMES.koDisclaimerTruth],
      FAIL_ON_FALSE(false)
    ),
  [SETTLEMENT_KEYS.koDisclaimerCosts]: QReference(SETTLEMENT_NAMES.koDisclaimerTruth),
  [SETTLEMENT_KEYS.koDisclaimerAgentAgreement]: QReference(SETTLEMENT_NAMES.koDisclaimerTruth),
  [SETTLEMENT_KEYS.requested_price]: QSchemaConditional(
    SettlementPriceSchema,
    SETTLEMENT_KEYS.price_range,
    v => v === PRICE_RANGE_ENUM.Client || v === PRICE_RANGE_ENUM.Highest,
  )
}

export const SettlementSchema = QSchema.shape(SETTLEMENT_TYPES)
