import React from 'react'
import PropTypes from 'prop-types'
import styled, { cx } from 'react-emotion'

const Locked = styled.div`
  font-size: 18.2px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: -2px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;

  &.small {
    font-size: 14px;
    line-height: 24px;
  }
`

export default function LockedValue({ value, size, ...props }) {
  const className = cx(props.className || '', size ? size : '')
  return (
    <Locked {...props} className={className}>
      {isValidUrl(value) ? (
        <a style={{ color: 'inherit' }} target="_blank" href={value}>
          {value}
        </a>
      ) : (
        value
      )}
    </Locked>
  )
}

LockedValue.propTypes = {
  size: PropTypes.string,
  value: PropTypes.node,
}

function isValidUrl(string) {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}
