import React from 'react'
import { Field } from 'redux-form'
import styled from 'react-emotion'
import { Flag, RegistrationTypeBar, SexySeparator } from 'common'
import { REGISTRATION_TYPES } from 'pixsy-constants'

export default function RegistrationTypeSelect({
  showBottomBar = false,
  ...props
}) {
  const types = Object.keys(REGISTRATION_TYPES).map(key => ({
    key,
    ...REGISTRATION_TYPES[key],
  }))
  const maxCardsNum = types.reduce(
    (max, type) => (type.items.length > max ? type.items.length : max),
    0
  )
  const selectedTypes = showBottomBar
    ? types.reduce((all, type) => {
        return all.concat(
          type.items.reduce((selected, opt) => {
            const { key, flag } = type
            if (props.values[key.toLowerCase()] === opt.value) {
              selected.push(Object.assign({}, opt, { key, flag }))
            }
            return selected
          }, [])
        )
      }, [])
    : []
  const total = selectedTypes
    .reduce((sum, { price }) => (sum += price), 0)
    .toFixed(2)
  return (
    <React.Fragment>
      {types.map(({ key, form, label, flag }) => (
        <React.Fragment key={key}>
          <SexySeparator />
          <Field
            component={RegistrationTypeBar}
            {...props.getFieldPropsFor(form)}
            label={label}
            country={flag}
            locked={props.locked}
            maxCardsNum={maxCardsNum}
          />
        </React.Fragment>
      ))}
      {showBottomBar && selectedTypes.length > 0 && (
        <FixedBottomBar>
          <OptionsContainer>
            <Label>Selected</Label>
            {selectedTypes.map(type => (
              <Card key={type.key} className="selectedTypeCard">
                {type.flag && <Flag country={type.flag} />}
                {type.label}
              </Card>
            ))}
          </OptionsContainer>
          <TotalContainer>
            <Label>Total</Label>
            <Card className="totalCard">
              <strong>US$&nbsp;{total}</strong>
            </Card>
          </TotalContainer>
        </FixedBottomBar>
      )}
    </React.Fragment>
  )
}

const FixedBottomBar = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 10px 25px;
  background-color: #008aab;
  text-align: center;
  z-index: 20;
`

const OptionsContainer = styled.div`
  float: left;
  width: 70%;
`

const TotalContainer = styled.div`
  float: left;
  width: 30%;
  text-align: right;

  @media (max-width: 994px) {
    text-align: center;
  }
`

const Label = styled.div`
  font-size: 1.1em;
  font-weight: normal;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;

  @media (max-width: 994px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`

const Card = styled.div`
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  margin-left: 20px;
  font-size: 1.3em;
  background-color: #fff;

  &.selectedTypeCard {
    letter-spacing: -0.02em;
    padding: 8px 20px 8px 72px;

    img {
      position: absolute;
      top: -8px;
      left: 8px;
      transform: scale(0.7);
      opacity: 0.7;
    }
  }

  @media (max-width: 994px) {
    &.totalCard {
      margin: 0;
    }
  }

  strong {
    font-size: 1.1em;
  }
`
