const POLICY_LOAD = 'POLICY_LOAD'
const POLICY_LOAD_SUCCESS = 'POLICY_LOAD_SUCCESS'
const POLICY_LOAD_FAIL = 'POLICY_LOAD_FAIL'

const initialState = {
  data: null,
  loading: false,
  loaded: false,
}

export default function policy(state = initialState, action = {}) {
  switch (action.type) {
    case POLICY_LOAD:
      return {
        ...state,
        loading: true,
      }
    case POLICY_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
      }
    case POLICY_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      }
    default:
      return state
  }
}

export function load() {
  return {
    types: [POLICY_LOAD, POLICY_LOAD_SUCCESS, POLICY_LOAD_FAIL],
    promise: client => client.get('/policy'),
  }
}
