import { createSubmissionData } from 'pixsy-schema/case/helpers/createSubmissionData'
import {
  IMPORT_TYPES,
  SubmissionSchema,
} from 'pixsy-schema/case/SubmissionSchema'
import PropTypes from 'prop-types'
import * as React from 'react'
import { asyncConnect } from 'redux-connect'
import {
  loadCaseSubmission,
  saveCase,
  submitCase,
  validate,
} from '../../../redux/modules/cases'
import { loadSingle as loadCluster } from '../../../redux/modules/clusters'
import { load as loadPolicy } from '../../../redux/modules/policy'
import {
  getCase,
  getCluster,
  getQuery,
  resolveLocation,
  getPreviousSubmission,
} from './CaseSelectors'
import { CaseSubmission } from './CaseSubmission'
import { PixsyForm } from './Components'
import { resolveCountryNameForCode } from 'pixsy-constants'
import NavigationBehaviour from './FormSections/Modals/NavigationBehaviour'
import PrefillCaseSubmissionInfoModal from './FormSections/Modals/PrefillCaseSubmissionInfo'

/**
 * Submission V6 starts here
 */
class CaseSubmissionContainer extends React.Component {
  constructor(...r) {
    super(...r)
    this.state = this.getInitialStateFromProps()
  }

  getInitialStateFromProps = () => {
    const {
      cluster,
      case: caze,
      query: { caseId },
      previousSubmission, // Autofill submission from previous case (Only Screening and Review Stage)
    } = this.props

    /**
     * Create or load `case.submission` data
     */
    const data = createSubmissionData(cluster, caze, caseId, previousSubmission)

    // Use Search by Image / Select by default
    data.submission.__IMPORT_VALUE__ = IMPORT_TYPES.SEARCH_IMAGE

    const knownCountry = this.getKnownCountry(data.submission, cluster)
    if (!data.submission.country && knownCountry) {
      data.submission.country = knownCountry
    }

    if (!data.submission.noticed && cluster && cluster.matches) {
      /** (plus)new Date() converts the date to time */
      data.submission.noticed = Math.max(
        ...cluster.matches.map((m) => +new Date(m.created))
      )
    }

    return {
      caseId: data.caseId,
      clusterId: data.clusterId,
      initialValues: data.submission,
      isSubmitting: false,
    }
  }

  getKnownCountry(submission, cluster) {
    if (!cluster) return false
    const countryTagList = cluster.tags.filter((t) => t.startsWith('country:'))
    if (countryTagList.length === 0) return false
    if (countryTagList[0] && countryTagList[0].split(':')[1]) {
      const country = countryTagList[0].split(':')[1]
      return resolveCountryNameForCode(country)
    }
    return false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      // case: caze,
      query: { caseId },
    } = nextProps
    // Case ID changes when a new case is first saved
    if (prevState.caseId === caseId) return null

    return { caseId }
  }

  /**
   * Async validation handler which is passed to PixsyForm context,
   * so it's available inside the SubmissionSchema to perform async checks when triggered
   */
  validator = (name, data) => {
    const { validate } = this.props

    // console.warn(`[validator][${name}]`, data)

    return validate([data])
  }

  setStateAsync = (options) =>
    new Promise((done) => this.setState(options, done.bind(this)))

  submitForm = (...args) => {
    return this.setStateAsync({
      isSubmitting: true,
    }).then(() => this.props.submitCase(...args))
  }

  render() {
    const { initialValues, caseId, clusterId } = this.state
    const {
      // query,
      location,
      history,
      user,
      policy,
      saveCase,
      cluster,
      case: caze,
      previousSubmission,
    } = this.props

    // Necessary because of DEV-1019
    if (!policy) {
      return null
    }

    const { validator, submitForm } = this

    const fieldContext = { validator, user, caseId }

    /**
     * Use SubmissionSchema to validate values set by fields
     * inside `CaseSubmission` component
     */
    return (
      <PixsyForm
        initialValues={initialValues}
        schema={SubmissionSchema}
        isFormReadOnly
        context={{ user, cluster }}
        render={(api) => [
          <NavigationBehaviour
            key="navigationBehaviour"
            isSaveDisabled
            dirty={
              (caze && caze.submission && caze.submission.__DRAFT__) ||
              (caze && caze.cm && caze.cm.changeRequested)
            }
            submitting={this.state.isSubmitting}
            onSave={() => false}
          />,
          previousSubmission && (
            <PrefillCaseSubmissionInfoModal
              key="prefillCaseSubModal"
              user={user}
              previousSubmission={previousSubmission}
            />
          ),
          <CaseSubmission
            key="caseSubForm"
            caseId={caseId}
            clusterId={clusterId}
            user={user}
            policy={policy.caseSubmission}
            history={history}
            location={location}
            fieldContext={fieldContext}
            submitCase={submitForm}
            saveCase={saveCase}
            case={caze}
            previousSubmission={previousSubmission}
            {...api}
          />,
        ]}
      />
    )
  }
}
CaseSubmissionContainer.propTypes = {
  cluster: PropTypes.object,
  case: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  validate: PropTypes.func,
  query: PropTypes.shape({
    caseId: PropTypes.string.isRequired,
    clusterId: PropTypes.string,
  }),
}

export const CaseSubmissionProvider = asyncConnect(
  [
    {
      key: 'fetchPolicyForCaseSubmission',
      promise: async function fetchDataForCaseSubmission({
        store: { getState, dispatch },
      }) {
        if (!getState().policy.data) {
          return dispatch(loadPolicy())
        }
      },
    },
    {
      key: 'fetchDataForCaseSubmission',
      promise: async function fetchDataForCaseSubmission({
        store: { getState, dispatch },
        match,
        location,
      }) {
        const { clusterId, caseId } = resolveLocation(location, match)

        if (caseId && caseId.length === 24) {
          return dispatch(loadCaseSubmission(caseId))
        }

        if (clusterId && clusterId.length === 24) {
          return dispatch(loadCluster(clusterId))
        }
      },
    },
    /**
     * Query for previous case submission details.
     * Once found, update the local redux store with the previous case.
     * Note, the previous submission will have a property `isPrevious` = true
     * @see pixsy-react/pixsy-app/api/controllers/cases/submission/readOne.js - fn: loadPreviousSubmissionData
     */
    {
      key: 'fetchDataFromPreviousSubmission',
      promise: async function fetchDataFromPreviousSubmission({
        store: { getState, dispatch },
        match,
        location,
      }) {
        const { caseId } = resolveLocation(location, match)

        if (caseId === 'new') {
          return dispatch(loadCaseSubmission('previous'))
        }
      },
    },
  ],
  (state, props) => ({
    case: getCase(state, props),
    cluster: getCluster(state, props),
    policy: state.policy.data,
    query: getQuery(state, props),
    user: state.auth.user,
    previousSubmission: getPreviousSubmission(state, props),
  }),
  { validate, submitCase, saveCase }
)(CaseSubmissionContainer)
