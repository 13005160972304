import { QBoolean, QString, QDate, QSchema, objectWithKeysAsValues } from '../core'
import COUNTRIES from 'pixsy-constants/countries.json'
import isPlainObject from 'lodash/isPlainObject'

// prettier-ignore
export const KNOCKOUT_KEYS = objectWithKeysAsValues({
  __BREACH_CONTRACT_SAVED__: '__BREACH_CONTRACT_SAVED__',
  __PR_CONTACT_USER_SAVED__: '__PR_CONTACT_USER_SAVED__',
  koUserIsLegalRightsOwner: 'koUserIsLegalRightsOwner',
  koImageUserIsBusiness: 'koImageUserIsBusiness',
  screeningCaseAboutBreachOfContract: 'screeningCaseAboutBreachOfContract',
  screeningCaseAboutBreachOfContractComment: 'screeningCaseAboutBreachOfContractComment',
  stockIncluded: 'stockIncluded',
  stockVerified: 'stockVerified',
  screeningPreviousContactWithImageUser: 'screeningPreviousContactWithImageUser',
  screeningPreviousContactWithImageUserComment: 'screeningPreviousContactWithImageUserComment',
  workForHire: 'workForHire',
  contest: 'contest',
  country: 'country',
  noticed: 'noticed',
})

export const KNOCKOUT_NAMES = KNOCKOUT_KEYS

export const KNOCKOUT_LABEL = {
  [KNOCKOUT_NAMES.koUserIsLegalRightsOwner]: `Are you the legal owner (rights holder) of the image(s) included in this submission, and is the use of your work unauthorized?`,
  [KNOCKOUT_NAMES.koImageUserIsBusiness]: `The use of your image(s) was by a business or organization.`,
  [KNOCKOUT_NAMES.screeningCaseAboutBreachOfContract]: `This submission is a breach of an existing contract or licensing terms.`,
  [KNOCKOUT_NAMES.screeningCaseAboutBreachOfContractComment]: `Help us understand the original agreement and how it was breached.`,
  [KNOCKOUT_NAMES.stockIncluded]: `Is or was this image ever available with any stock agencies?`,
  [KNOCKOUT_NAMES.stockVerified]: `Have you verified that a license for this specific use has not been sold through any of these sources?`,
  [KNOCKOUT_NAMES.screeningPreviousContactWithImageUser]: `Have you previously contacted the image user (directly or via an agent)?`,
  [KNOCKOUT_NAMES.screeningPreviousContactWithImageUserComment]: `Help us understand any past communication with the image user.`,
  [KNOCKOUT_NAMES.workForHire]: `Were you paid or commissioned to take one of these images for a client?`,
  [KNOCKOUT_NAMES.contest]: `Were any of these images submitted into a contest?`,
  [KNOCKOUT_NAMES.country]: `In which country is the image user/website legally operating?`,
  [KNOCKOUT_NAMES.noticed]: `When did you first become aware your image(s) was being used by this image user?`,
}

export const KNOCKOUT_REQUIRED = {
  [KNOCKOUT_NAMES.koUserIsLegalRightsOwner]: true,
  [KNOCKOUT_NAMES.koImageUserIsBusiness]: true,
  [KNOCKOUT_NAMES.screeningCaseAboutBreachOfContract]: true,
  [KNOCKOUT_NAMES.screeningCaseAboutBreachOfContractComment]: true,
  [KNOCKOUT_NAMES.stockIncluded]: true,
  [KNOCKOUT_NAMES.stockVerified]: true,
  [KNOCKOUT_NAMES.screeningPreviousContactWithImageUser]: true,
  [KNOCKOUT_NAMES.screeningPreviousContactWithImageUserComment]: true,
  [KNOCKOUT_NAMES.workForHire]: true,
  [KNOCKOUT_NAMES.contest]: true,
  [KNOCKOUT_NAMES.country]: false,
  [KNOCKOUT_NAMES.noticed]: false,
}

export const KNOCKOUT_INFO = {
  [KNOCKOUT_NAMES.koUserIsLegalRightsOwner]: {
    title: `Submitting a case to Pixsy authorizes us to act on behalf of the legal owner of the image, therefore you must be the legal owner to complete a submission.`,
  },
  [KNOCKOUT_NAMES.koImageUserIsBusiness]: {
    title: `Pixsy is only able to pursue commercial uses of your work, by an operating business or organization.`,
    subtitle: `For non-commercial use, we recommend sending a takedown notice to the image user.`,
  },
  [KNOCKOUT_NAMES.screeningCaseAboutBreachOfContract]: {
    title: `We require a copy of the full contract, including the clear terms that are in breach. Please briefly summarize the situation for us in the comment box below.`,
  },
  [KNOCKOUT_NAMES.screeningCaseAboutBreachOfContractComment]: {
    title: `Required`,
  },
  [KNOCKOUT_NAMES.stockIncluded]: {
    title: `Your profile indicates that you have worked with agencies before, please confirm your response is correct.`,
  },
  [KNOCKOUT_NAMES.stockVerified]: {
    title: `You must first verify with these agencies that a license was not sold to the image user.`,
    subtitle: `If you are unable to access the sales data, contact the support desk of the agency.`,
  },
  [KNOCKOUT_NAMES.screeningPreviousContactWithImageUser]: {
    title: `Previous communication may limit our ability to resolve the case. We require copies of all communication. Please briefly summarize the situation for us in the comment box below.`,
  },
  [KNOCKOUT_NAMES.screeningPreviousContactWithImageUserComment]: {
    title: `Required`,
  },
  [KNOCKOUT_NAMES.workForHire]: {
    title: `In our experience many infringements come from commissioned works where the client then shared or made public the image. Please confirm within the agreement that your client does have the legal rights to share or reproduce the image. We will require a copy of this agreement, which can be uploaded in the last stage of this form.`,
  },
  [KNOCKOUT_NAMES.contest]: {
    title: `Heads up! It's our experience that many contests hold third party rights for further distribution of submitted content. Please check the contest rules to verify that there are no third party distribution or sub-licensing rights prior to submitting this case.`,
    warning: true,
  },
  [KNOCKOUT_NAMES.country]: {
    title: `The country indicated is not currently a jurisdiction where Pixsy is operating for case resolutions.`,
    subtitle: `We are constantly expanding and will notify you when our jurisdictions changes in the future. You can however send a takedown notice for this match.`,
  },
}

export const COUNTRY_TEST = (country) => {
  if (!country || country === 'Undefined') return true
  const match = COUNTRIES.find((c) => c.country === country)
  return match ? match.resolution : false
}

export const FAIL_ON_FALSE = (value) => {
  return !(value === false)
}

export const FAIL_ON_TRUE = (value) => {
  return !(value === true)
}

// prettier-ignore
export const KNOCKOUT_TYPES = {
  [KNOCKOUT_KEYS.__BREACH_CONTRACT_SAVED__]: QBoolean
    .default(false)
    .strip(true),
  [KNOCKOUT_KEYS.__PR_CONTACT_USER_SAVED__]: QBoolean
    .default(false)
    .strip(true),
  [KNOCKOUT_KEYS.koUserIsLegalRightsOwner]: QBoolean
    .required({ title: 'Please confirm if you are the legal owner of the image(s) included in this submission, and is the use of your work unauthorized' })
    .test('isRightsOwner', KNOCKOUT_INFO.koUserIsLegalRightsOwner, FAIL_ON_FALSE),
  [KNOCKOUT_KEYS.koImageUserIsBusiness]: QBoolean
    .required({ title: 'Please confirm if the use of your work was by a business or an organization' })
    .test('isBusiness', KNOCKOUT_INFO.koImageUserIsBusiness, FAIL_ON_FALSE),
  [KNOCKOUT_KEYS.screeningCaseAboutBreachOfContract]: QBoolean
    .required({
      title: 'Please confirm if the image is a breach of existing contract or licensing terms'
    }),
  [KNOCKOUT_KEYS.screeningCaseAboutBreachOfContractComment]: QString
    .when(KNOCKOUT_KEYS.screeningCaseAboutBreachOfContract, {
      is: true,
      then: QString
        .min(1, KNOCKOUT_INFO.screeningCaseAboutBreachOfContractComment)
        .required(KNOCKOUT_INFO.screeningCaseAboutBreachOfContractComment),
      otherwise: QString.transform(() => null),
    }),
  [KNOCKOUT_KEYS.stockIncluded]: QBoolean.required({
    title: 'Please confirm if the image is available on stock agencies'
  }), // @TODO: verify user agencies
  [KNOCKOUT_KEYS.stockVerified]: QBoolean
    .when(KNOCKOUT_KEYS.stockIncluded, {
      is: true,
      then: QBoolean
        .required(KNOCKOUT_INFO[KNOCKOUT_NAMES.stockVerified])
        .test('should-answer-yes', { title: 
        'You must first verify with these image agencies that a license was not sold to the image user. If you are unable to access the sales data, contact the support desk of the agency.' }, FAIL_ON_FALSE),
      otherwise: QBoolean
        .transform(() => null)
    }),
  [KNOCKOUT_KEYS.screeningPreviousContactWithImageUser]: QBoolean.required({
    title: 'Please confirm if you have previously contacted the image user'
  }),
  [KNOCKOUT_KEYS.screeningPreviousContactWithImageUserComment]: QString
    .when(KNOCKOUT_KEYS.screeningPreviousContactWithImageUser, {
      is: true,
      then: QString
        .min(1, KNOCKOUT_INFO.screeningPreviousContactWithImageUserComment)
        .required(KNOCKOUT_INFO.screeningPreviousContactWithImageUserComment),
      otherwise: QString.transform(() => null),
    }),
  [KNOCKOUT_KEYS.workForHire]: QBoolean.required({
    title: 'Please confirm if you were paid or commissioned to take one of these images for a client'
  }),
  [KNOCKOUT_KEYS.contest]: QBoolean.required({
    title: 'Please confirm if the images were ever submitted to a contest'
  }),
  [KNOCKOUT_KEYS.country]: QString.test('country-test', KNOCKOUT_INFO.country, COUNTRY_TEST).test('top-match-only-counties', {
    title: 'Unable to accept case',
    subtitle: 'Based on our previous knowledge of legal cases in this jurisdiction, we are unable to accept this as a case. We encourage you to send a Takedown notice instead.'
  }, function isValidCountryAndAcceptsTopMatches (country) {
    if (!country || country === 'Undefined') return true
    const clusterCountry = COUNTRIES.find((c) => c.country === country)
    const resolvable = clusterCountry ? clusterCountry.resolution : false
    if (!resolvable) return false
    if (!clusterCountry.tmSubmissionOnly) return true
    const ctx = this.options.context
    
    if (ctx && ctx.cluster && ctx.cluster.tags) {
      if (ctx.cluster.tags.includes('pixsy:flagged')) {
        return true
      }
      const isClusterTopMatch = (
        ctx.cluster.tags.includes('top:us') ||
        ctx.cluster.tags.includes('top:world') ||
        ctx.cluster.tags.includes('top')
      )
      return isClusterTopMatch
    }
    return false
  }).test(
    'stock-image-country',
    {
      title: 'Unable to accept case',
      subtitle: 'Based on our previous knowledge of legal cases in this jurisdiction, we are unable to accept this as a case. We encourage you to send a Takedown notice instead.' // since this condition is only specific to the country and stock option so we can also use specific message instead of generic one
    }, function(value) {
      const ctx = this.options.context
      const clusterCountry = COUNTRIES.find((c) => c.country === value)
      if(ctx && ctx.values && ctx.values.stockIncluded && clusterCountry && clusterCountry.noStockImage) {
        return false
      }
      return true
    }
  ),
  [KNOCKOUT_KEYS.noticed]: QDate,
}

export const KnockoutSchema = QSchema.shape(KNOCKOUT_TYPES)
