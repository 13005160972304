import React from 'react'
import PropTypes from 'prop-types'
import { Button, ScrollToTop, ContentSection } from 'common'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
// import SubmissionHeader from '../SubmissionHeader'
// import { PHASE } from '../../../../../shared/forms/registrationSubmission'

SubmissionSuccess.propTypes = {
  registration: PropTypes.object,
}

export default function SubmissionSuccess({ registration }) {
  const matches = require(`./matchesIcon.svg`)
  const file = require(`./fileIcon.svg`)
  const takedown = require(`./takedownIcon.svg`)
  const image = require(`./imagesIcon.svg`)

  // const phase = registration ? registration.phase : PHASE.DRAFT

  return (
    <div css={page}>
      <ContentSection>
        <ScrollToTop />

        <div css={wrap}>
          <h1>Congratulations!</h1>
          <h4>
            Your registration is now submitted{' '}
            {registration &&
              registration.salesforce &&
              registration.salesforce.Name &&
              `(Ref ${registration.salesforce.Name})`}
          </h4>
          <p>
            Your copyright registration is on its way to a copyright expert for
            review and processing. Once reviewed, we will file this with the
            copyright office on your behalf. Processing times at the copyright
            office vary and are outside of our control - standard processing
            times are anywhere from 1-12 months.
          </p>
        </div>
      </ContentSection>
      <ContentSection>
        <div css={wrap}>
          <h3>What's next?</h3>
          <div css={nextSteps}>
            <div>
              <figure dangerouslySetInnerHTML={{ __html: file }} />
              <p>
                Our register dashboard gives you a full picture of your
                registration and is updated live.
              </p>
              <Link to="/registrations">
                <Button>View Registrations</Button>
              </Link>
            </div>
            <div>
              <figure dangerouslySetInnerHTML={{ __html: matches }} />
              <p>
                Don’t stop now, review additional matches for further commercial
                uses of your images.
              </p>
              <Link to="/matches">
                <Button>Review matches</Button>
              </Link>
            </div>
          </div>
        </div>
      </ContentSection>
      <ContentSection>
        <h3>Want more from Pixsy?</h3>
        <div css={upsellTop}>
          <div>
            <figure dangerouslySetInnerHTML={{ __html: image }} />
            Monitor more images
          </div>
          <div>
            <figure dangerouslySetInnerHTML={{ __html: takedown }} />
            Send Legal Takedowns
          </div>
          <div>
            <figure dangerouslySetInnerHTML={{ __html: matches }} />
            Priority Scanning
          </div>
        </div>
        <div css={upsellBottom}>
          <Link to="/profile/plans">
            <Button>Upgrade your plan today</Button>
          </Link>
        </div>
      </ContentSection>
    </div>
  )
}

const upsellTop = css`
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  justify-content: space-between;
  margin: 30px auto;

  > * {
    svg {
      display: block;
      height: 40px;
      margin: 20px auto;
    }
  }
`

const upsellBottom = css`
  button {
    margin: 20px 0 20px;
  }
`

const nextSteps = css`
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  > div {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    p {
      flex: 1;
    }
  }
  button {
    width: 90%;
    padding-left: 0;
    padding-right: 0;
    margin: 20px auto 20px;
    font-size: 0.9em;
  }
`

const page = css`
  text-align: center;
`

const wrap = css`
  h1 {
    font-size: 48px;
  }

  figure {
    height: 40px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;

    svg {
      height: 100%;
    }
  }
  button {
    margin-top: 20px;
  }
`
