/**
 * Compute the title of a cluster for displaying it on the clusters list or the details view
 *
 * @param cluster Cluster to obtain the title from
 * @returns {string} Title of the cluster
 */
const getClusterTitle = (cluster) => {
  let name = null

  if (cluster.entityData && cluster.entityData.company_name) {
    name = cluster.entityData.company_name
  } else if (cluster.entityData && cluster.entityData.name) {
    name = cluster.entityData.name
  } else if (cluster.domain && cluster.domain.name) {
    name = cluster.domain.name
  } else {
    throw new Error("Cannot get the cluster's title")
  }

  return name
}

export default getClusterTitle
