import React from 'react'
import { format } from 'currency-formatter'

export const MatchDetailsEntity = ({ match, entity, domain, testMode }) => {
  if (entity && (testMode === true || !global.PRODUCTION)) {
    return (
      <React.Fragment>
        <dt className="debug">Entity</dt>
        <dd className="debug">{entity.name}</dd>
        <dd className="debug">
          {entity.revenue &&
            format(entity.revenue, {
              format: '%s %v',
              code: 'USD',
              thousand: ',',
              decimal: '.',
            })}
        </dd>
      </React.Fragment>
    )
  }
  if (!entity && domain.hubspot && (testMode === true || !global.PRODUCTION)) {
    return (
      <React.Fragment>
        <dt className="debug">Hubspot</dt>
        <dd className="debug">{domain.hubspot.name}</dd>
        <dd className="debug">
          {domain.hubspot.annualrevenue &&
            format(domain.hubspot.annualrevenue, {
              format: '%s %v',
              code: 'USD',
              thousand: ',',
              decimal: '.',
            })}
        </dd>
      </React.Fragment>
    )
  }
  return null
}

export default MatchDetailsEntity
