import * as React from 'react'
import { css, cx } from 'react-emotion'

/**
 * PixsyPaper - exactly as a paper
 *  It serves as a generic component to set common styling or as a "Paper" where to draw components
 *
 * e.g:
 *  <PixsyPaper borderless variant="red">
 *    <PixsyTextField name="example" />
 *  </PixsyPaper>
 *
 * Results on a `PixsyTextField` with red background
 *
 * It has virtually no margin or padding by default, you can stack to papers together to simulate a single paper
 *
 *  <PixsyPaper>
 *    <h1>Hello</h1>
 *  </PixsyPaper>
 *  <PixsyPaper>
 *    <h1>World</h1>
 *  </PixsyPaper>
 *
 * Use `expanded` to separate one paper from another:
 *
 *  <PixsyPaper>
 *    <h1>Hello</h1>
 *  </PixsyPaper>
 *  <PixsyPaper expanded>
 *    <h1>World</h1>
 *  </PixsyPaper>
 *
 * `expanded` can be used to conditionally separate a section of the screen and put it back together
 * @see CaseSubmission.js - stages (separates sections of screen when there is an error)
 */
export const PixsyPaper = ({
  children,
  className,
  expanded,
  style = {},
  padding,
  disabled,
  variant = 'default',
  borderless = false,
  borderlessTop = false,
  borderlessBottom = false,
  borderlessRight = false,
  borderlessLeft = false,
  borderVariant: brdVariant,
}) => {
  const styleVariant = variants[variant] || defaultVariant
  const borderVariant = borderVariants[brdVariant]
  const ownStyle = css({
    padding,
    ...style,
  })

  return (
    <div
      data-type="PixsyPaper"
      className={cx(
        paperBase,
        styleVariant,
        expanded && expandedStyle,
        disabled && paperDisabled,
        borderlessTop && borderlessTopStyle,
        borderlessBottom && borderlessBottomStyle,
        borderlessRight && borderlessRightStyle,
        borderlessLeft && borderlessLeftStyle,
        borderless && defaultBorderlessStyle,
        borderVariant,
        className,
        css(ownStyle)
      )}
    >
      {children}
    </div>
  )
}

const paperDisabled = css`
  opacity: 0.2;
  pointer-events: none;
`
const paperBase = css`
  margin: -1px 0 0 0;
  padding: 1px 0 0 0;
  min-height: 0px;
`

/**
 * Expanded
 */
const expandedStyle = css`
  margin: 25px 0;
`

/**
 * Color variant styles
 */
const defaultVariant = css`
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
`
const transparent = css`
  background-color: none;
`
const transparentWithSeparator = css`
  border-bottom: 1px solid #f0f0f0;
`
const greyVariant = css`
  background-color: #f4f4f4;
`
const redVariant = css`
  background-color: #fff2f2;
`
const yellowVariant = css`
  background-color: #ffffdf;
`

/**
 * Border variants
 */
const defaultBorderColorStyle = css`
  border: 1px solid #e6e6e6;
`

/**
 * Borderless variants
 */
const defaultBorderlessStyle = css`
  margin: 0;
  padding: 0;
  border: none !important;
`
const borderlessTopStyle = css`
  margin: 0;
  padding: 0;
  border-top: none !important;
`
const borderlessBottomStyle = css`
  border-bottom: none !important;
`
const borderlessRightStyle = css`
  border-right: none !important;
`
const borderlessLeftStyle = css`
  border-left: none !important;
`

// ---- //
const variants = {
  default: defaultVariant,
  grey: greyVariant,
  red: redVariant,
  yellow: yellowVariant,
  transparent,
  transparentWithSeparator,
}
const borderVariants = {
  default: defaultBorderColorStyle,
}
