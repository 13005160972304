import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TruncateText } from 'common'
import { uniq } from 'lodash'
import moment from 'moment'
import React from 'react'
import { withCookies } from 'react-cookie'
import { css, cx } from 'react-emotion'
import getFirstSeenFromDateText, { getLastSeenFromDateText } from '../utils/getFirstSeenFromDateText'

import * as Shapes from '../utils/shapes'
import { EyeIcon, ImageIcon, LinkIcon, TIcon } from './Icons'

const ImagesInfo = ({ cluster }) => {
  const isMultiMatch = cluster.matches.length > 1

  if (isMultiMatch) {
    return <MultiMatchImageInfo cluster={cluster} />
  } else {
    return <SingleMatchImageInfo cluster={cluster} match={cluster.matches[0]} />
  }
}

const MultiMatchImageInfo = withCookies(({ cluster, cookies }) => {
  const actionableMatches = (cluster.matches || []).filter((match) => !match.ignored)
  const onlineActionableMatches = actionableMatches.filter((match) => match.isOnline !== false)
  const totalImages = uniq(actionableMatches.map((m) => m.image)).length
  const lastMatchDate = Math.max(...actionableMatches.map((m) => new Date(m.created).getTime()))
  const isSharkMode = !!cookies.get('pixsy-shark')
  const deepSearchMatches = actionableMatches.filter(
    (match) => match.meta && match.meta.matchSource && match.meta.matchSource.startsWith('gris')
  ).length

  return (
    <div className={cx(styles.container, styles.multiMatch)}>
      <div className="imageInfoItem">
        <EyeIcon />
        <DateTooltip
          title={`${moment(cluster.created).format('MMMM D, YYYY')} to ${moment(lastMatchDate).format('MMMM D, YYYY')}`}
        >
          <span>
            {getFirstSeenFromDateText(cluster.created)} & {getLastSeenFromDateText(lastMatchDate)}
          </span>
        </DateTooltip>
      </div>
      <div className="imageInfoItem">
        <div css={styles.onlineStatus}>
          <div className={`onlineStatusCircle ${onlineActionableMatches.length === 0 ? 'offline' : ''}`} />
          {onlineActionableMatches.length} Online
          {isSharkMode && deepSearchMatches > 0 && (
            <span>&nbsp;/&nbsp;{deepSearchMatches}&nbsp;Deep search matches</span>
          )}
        </div>
      </div>
      <div className="imageInfoItem">
        <LinkIcon />
        <a
          href={`https://${(cluster.entityData && cluster.entityData.name) || (cluster.domain && cluster.domain.host)}`}
          target="_blank"
          rel="noreferrer"
          className="primary_domain"
        >
          {(cluster.entityData && cluster.entityData.name) || (cluster.domain && cluster.domain.host)}
        </a>
      </div>
      <div className="imageInfoItem">
        <ImageIcon />
        {`${totalImages} Image${totalImages > 1 ? 's' : ''}`}
      </div>
    </div>
  )
})

const SingleMatchImageInfo = withCookies(({ cluster, match, cookies }) => {
  const displayMatch = { origin: {}, ...(match || cluster.matches[0]) }
  const isSharkMode = !!cookies.get('pixsy-shark')
  const matchSource = match.meta && (match.meta.matchSource || match.meta.source)
  const isDeepSearch = ['gris', 'gris-go'].includes(matchSource)

  return (
    <div css={styles.container}>
      <div className="imageInfoItem">
        <EyeIcon />
        <DateTooltip title={moment(match.created).format('MMMM D, YYYY')}>
          <span>{getFirstSeenFromDateText(match.created)}</span>
        </DateTooltip>
        {match.isOnline !== false ? (
          <div css={styles.onlineStatus}>
            <div className="onlineStatusCircle" />
            Online
          </div>
        ) : (
          <div css={styles.onlineStatus}>
            <div className="onlineStatusCircle offline" />
            Offline
          </div>
        )}
        {isSharkMode && (
          <div css={styles.onlineStatus}>
            <div className={`onlineStatusCircle ${isDeepSearch ? 'deepSearch' : 'spiders'}`} />
            {isDeepSearch ? 'Deep search' : 'Pixsy Spiders'}
          </div>
        )}
      </div>
      {displayMatch.origin.title && (
        <div className="imageInfoItem">
          <TIcon />
          <TruncateText text={displayMatch.origin.title} />
        </div>
      )}
      {displayMatch.origin.url && (
        <div className="imageInfoItem">
          <LinkIcon />
          <TruncateText text={displayMatch.origin.url} link />
        </div>
      )}
      {displayMatch.url && !displayMatch.url.startsWith('x-raw-image://') && (
        <div className="imageInfoItem">
          <ImageIcon />
          <TruncateText text={displayMatch.url} link />
        </div>
      )}
    </div>
  )
})

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 10px;
    flex: 1;

    .imageInfoItem {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 12px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > svg {
        width: 16px;
        height: 16px;
        margin-right: 16px;
      }

      a.primary_domain {
        color: #212121;

        &:hover {
          color: #00758d;
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      margin-left: 16px;
    }

    @media only screen and (max-width: 992px) {
      .imageInfoItem {
        margin: 9px 0;
      }
    }
  `,
  onlineStatus: css`
    display: flex;
    align-items: center;

    .onlineStatusCircle {
      width: 13px;
      height: 13px;
      border-radius: 100px;
      margin-right: 8px;
      margin-left: 25px;

      background-color: #1ac3a3;

      &.offline {
        background-color: #c31a3a;
      }

      &.deepSearch {
        background-color: #966bfa;
      }

      &.spiders {
        background-color: #989898;
      }
    }
  `,
  multiMatch: css`
    .onlineStatusCircle {
      margin-left: 0;
      margin-right: 16px;
    }
  `,
}

const DateTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip)

const IMAGES_INFO_PROP_TYPES = {
  cluster: Shapes.Cluster,
}

ImagesInfo.propTypes = IMAGES_INFO_PROP_TYPES
MultiMatchImageInfo.propTypes = IMAGES_INFO_PROP_TYPES
SingleMatchImageInfo.propTypes = IMAGES_INFO_PROP_TYPES

export default ImagesInfo
export { SingleMatchImageInfo, MultiMatchImageInfo }
