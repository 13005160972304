import { notifSend, notifClear } from 'redux-notifications/lib/actions'
import { IMAGE_DIRECT_SAVE_SUCCESS } from '../modules/images'

// store => next => action
export default ({ dispatch }) => next => action => {
  // Prevent recursion
  if (action.type && action.type.includes('NOTIF')) return next(action)
  if (
    [
      'AUTH_LOAD_FAIL',
      'MATCH_TAKEDOWN_FAIL',
      'MATCH_REPLACE_ATTRIBUTES_FAIL',
    ].includes(action.type)
  )
    return next(action)

  // Detect errors being thrown inside FSA error key
  let notification

  if (action.error) {
    if (
      action.meta &&
      action.meta.form &&
      !action.type.includes('STOP_ASYNC')
    ) {
      const EXCLUDE = ['login']
      if (EXCLUDE.includes(action.meta.form)) return

      let errorMessage =
        'Please review the form for any missing data or errors. If this error persists, contact: support@pixsy.com'

      if (action.error.message) {
        errorMessage = action.error.message
      }

      if (action.payload) {
        errorMessage = resolveMessageFromObject(action.payload)
      }
      notification = {
        id: errorMessage,
        message: errorMessage,
        kind: 'warning',
        dismissAfter: 8e3,
      }
    } else {
      notification = {
        id: action.error.message,
        message: action.error.message,
        kind: 'warning',
        dismissAfter: 10e3,
      }
    }
  }
  if (action.payload) {
    for (const k of ['message', 'info']) {
      if (typeof action.payload[k] === 'string') {
        notification = {
          id: action.payload[k],
          message: action.payload[k],
          kind: k === 'info' ? 'info' : 'success',
          dismissAfter: 5e3,
        }
      }
    }
  }

  if (
    action.type !== IMAGE_DIRECT_SAVE_SUCCESS &&
    notification &&
    notification.message
  ) {
    // Clear & Dispatch while avoiding UI lag

    // Clear notifications prior to success
    setTimeout(() => {
      if (notification.kind === 'success') {
        dispatch(notifClear())
      }

      notifSend(notification)(dispatch)
    })
  }

  return next(action)
}

function resolveMessageFromObject(obj) {
  const firstValue = Object.values(obj)[0]
  if (typeof firstValue === 'object' && firstValue !== null) {
    return resolveMessageFromObject(firstValue)
  } else {
    return firstValue
  }
}
