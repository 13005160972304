/**
 * # Client
 *
 * Entry point for the client application.
 * By initializing the router, the URL is mapped to a component
 * When in development mode, adds the Redux Dev Tools to the dom.
 *
 * @see server
 */
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './containers/App/Root'
import { createBrowserHistory } from 'history'
import createStore from './redux/create'
import ApiClient from './redux/apiClient'
import Bluebird from 'bluebird'
import { setAutoFreeze } from 'immer'

setAutoFreeze(false)

if (__CLIENT__) {
  // split if statements to ensure webpack dead code removal
  if (global.NODE_ENV === 'production') {
    require('./helpers/googleTranslateFix')
  }
}

const dest = document.getElementById('react-root')

Bluebird.config({
  warnings: false,
  cancellation: false,
  monitoring: false,
})

// Store and history persist throughout hot reloads, the routes do not.
// Because react-router doesn‘t support hot reloading routes, we force replacement by
// adding a random key

const client = new ApiClient()
const history = createBrowserHistory()
const store = createStore(client, history, window.__data)

const render = Component => {
  ReactDOM.render(<Component store={store} history={history} />, dest)
}
// Call once to compare against SSR
render(Root)

if (!global.PRODUCTION) {
  window.React = React // enable debugger

  if (module.hot) {
    module.hot.accept(['./containers/index.js'], () => {
      render(require('./containers/App/Root'))
    })
  }

  // if (process.env.NODE_ENV !== 'production') {
  //   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js')
  //   whyDidYouRender(React)
  // }

  // if (
  //   !dest ||
  //   !dest.firstChild ||
  //   !dest.firstChild.attributes ||
  //   !dest.firstChild.attributes['data-react-checksum']
  // ) {
  //   console.error(
  //     'Server-side React render was discarded. Make sure that your initial render does not contain any client-side code.'
  //   )
  // }
}
