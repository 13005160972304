import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { NavLink, Link } from 'react-router-dom'
import { Button, StickyFloater, LoadingOverlay } from 'common'
import { push } from 'react-router-redux'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import { cx } from 'react-emotion'
import Helmet from 'react-helmet'
import { compose } from 'recompose'
import { loadCases } from '../../redux/modules/cases'
import { assembleCasesAndTotal, getCasesQuery } from '../../redux/selectors'
import EmptyState from './EmptyState/EmptyState'
import CasesTable from './Table/CasesTable'
import { withEmptyStates } from '../../decorators'

import PropTypes from 'prop-types'

const debug = require('debug')('cases')

export class CasesContainer extends Component {
  constructor() {
    super()

    this._statusColorMapping = {
      'Pending Review': '#D39A1C',
      'Action Needed': '#008AAB',
      'Not Accepted': '#FD8282',
      Completed: '#28BF45',
    }
  }

  render() {
    const {
      loading,
      cases,
      total,
      history,
      location,
      query,
      paginationProps,
      pageCount,
      user,
      reload,
    } = this.props
    const { filter } = query
    const { pathname } = location
    const submitPathname = `/cases/submission/new`

    const slug = filter[0].toUpperCase() + filter.slice(1)
    return (
      <React.Fragment>
        <Helmet title={'Cases | ' + slug} />
        <StickyFloater navigation>
          <Link
            to="/cases/all"
            className={cx(pathname.endsWith('all') && 'active')}
          >
            All
          </Link>
          <NavLink to="/cases/draft">Draft</NavLink>
          <NavLink to="/cases/pending">Pending</NavLink>
          <NavLink to="/cases/open">Open</NavLink>
          <NavLink to="/cases/closed">Closed</NavLink>
          <NavLink to="/cases/archived">Archived</NavLink>

          <Link
            to={{
              pathname: submitPathname,
              state: { skip: filter === 'all' && total === 0 && 'intro' },
            }}
          >
            <div className="sticky-floater--highlight">
              <Button>Submit Case</Button>
            </div>
          </Link>
        </StickyFloater>
        <Switch>
          <Route path="/cases/:filter">
            <LoadingOverlay overlap={false} show={loading}>
              <CasesTable
                loading={loading}
                cases={cases}
                total={total}
                filter={filter}
                history={history}
                location={location}
                paginationProps={paginationProps}
                query={query}
                pageCount={pageCount}
                user={user}
                reload={reload}
              />
            </LoadingOverlay>
          </Route>
          <Redirect from="/cases" to="/cases/all" />
        </Switch>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  const {
    cases: { loading },
  } = state

  const { total, cases, query } = assembleCasesAndTotal(state, props)

  const pageCount = Math.ceil(total / query.pageSize)
  const paginationProps = {
    total: total || 0,
    forcePage: query.page,
    pageCount,
    perPage: query.pageSize,
  }

  return {
    loading,
    query,
    total,
    cases,
    key: location.key,
    paginationProps,
    pageCount,
    user: state.auth.user,
  }
}

export default compose(
  asyncConnect(
    [
      {
        promise: function fetchCasesForCasesContainer({
          store: { getState, dispatch },
          location,
          match,
        }) {
          const query = getCasesQuery(getState(), { location, match })
          debug('query %o', query)

          const promise = dispatch(loadCases(query))
          return __SERVER__ && promise
        },
      },
    ],
    mapStateToProps,
    { loadCases, push }
  ),
  connect(null, { loadCases, push }, (stateProps, dispatchProps, ownProps) => {
    const { location, match } = ownProps
    const query = getCasesQuery({}, { location, match })

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      reload: () => dispatchProps.loadCases(query),
    }
  }),
  withEmptyStates([
    {
      shouldRender: props => props.total === 0 && props.filter === 'all',
      render: () => <EmptyState />,
    },
  ])
)(CasesContainer)

CasesContainer.propTypes = {
  cases: PropTypes.array.isRequired,
  pageCount: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  paginationProps: PropTypes.shape({
    forcePage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  query: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.string.isRequired,
  }).isRequired,
}
