import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { withCookies } from 'react-cookie'
import moment from 'moment'

class ItemsPerPage extends React.Component {
  static defaultProps = {
    options: [20, 40, 100],
  }

  state = {
    pages: this.props.selected,
  }

  onHandlePerPageChange = e => {
    const { cookies } = this.props
    const pages = Number(e.target.value)

    cookies.set('pageSize', pages, {
      path: '/',
      expires: moment()
        .add(1, 'day')
        .toDate(),
    })

    this.setState({ pages }, () => {
      this.props.onPerPageChange(pages)
    })
  }

  render() {
    const { pages } = this.state
    const options = this.props.options.includes(pages)
      ? this.props.options
      : [pages].concat(this.props.options)

    return (
      <React.Fragment>
        <label css={paginationLabel} htmlFor="itemsPerPage">
          Items per page
        </label>
        <select
          className="paginationItemsPerPageSelect"
          css={paginationSelect}
          name="itemsPerPage"
          value={pages}
          onChange={this.onHandlePerPageChange}
        >
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </React.Fragment>
    )
  }
}
ItemsPerPage.propTypes = {
  onPerPageChange: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.number.isRequired),
}

const paginationLabel = css`
  font-size: 12px;
  font-weight: 500;
  color: #464646;
  margin-bottom: 0px;
`
const paginationSelect = css`
  margin-left: 9.8px;
  height: 28px;
  width: 63px;
  border-radius: 0px !important;
  padding: 0px 10px;
  outline: 1px solid #e6e6e6;
  color: #464646;
  border: none;
`

export default withCookies(ItemsPerPage)
