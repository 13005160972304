import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import styled, { css, cx } from 'react-emotion'
import { Label_ as LabelNew, LockedValue } from 'common'

export default function TextField(props) {
  const {
    input,
    meta = {},
    label,
    addon,
    locked,
    type = 'text',
    showNoAnswer,
  } = props

  return (
    <FormRow {...props}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
        <React.Fragment>
          {locked ? (
            <div css={stacked}>
              <LabelNew title={label} />
              <LockedValue
                value={
                  props.input.value
                    ? props.input.value
                    : showNoAnswer
                    ? '-'
                    : ''
                }
              />
            </div>
          ) : (
            <React.Fragment>
              <Label title={label} align={props.labelAlign} />
              <div css={stacked}>
                <Input {...input} type={type} width={props.width} />
                <Feedback meta={meta} />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
        {addon && addon({ name: input.name })}
      </div>
    </FormRow>
  )
}

const stacked = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const stylesUnlocked = css``

const stylesLocked = css`
  /* opacity: 0.5; */
`

const wrap = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Input = styled.input`
  height: 40px;
  width: ${props => props.width};
  padding: 0 12px;
  border: 1px solid #c7c7c7;
  font-size: 18px;
  &:focus {
    outline: 0;
    border: 1px solid #148aa9;
  }
`

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
  type: PropTypes.string,
  labelAlign: PropTypes.string,
}

TextField.defaultProps = {
  width: '100%',
  labelAlign: 'right',
}
