import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from 'common'
import { css } from 'react-emotion'
import { deleteUser } from '../../../redux/modules/auth'

export function DeleteAccountDialog({ deleteUser }) {
  return (
    <div css={content}>
      <h1>Are you sure?</h1>

      <p>
        We’re sorry to see you go. Please confirm your account deletion. <br />
        We’d also love to <a href="mailto:support@pixsy.com">
          hear from you
        </a>{' '}
        about why you are canceling.
      </p>

      <p>
        <b>Important</b>: Deleting your Pixsy Web Application account does not
        cancel any active Cases you might have with Pixsy, these remain active
        until we have written confirmation instructing us to cancel. Please
        contact us at resolution@pixsy.com and include the case number.
      </p>

      <Link to="/profile/plans">
        <Button danger onClick={deleteUser}>
          Delete Account
        </Button>
      </Link>
    </div>
  )
}

export default connect(null, { deleteUser })(DeleteAccountDialog)

DeleteAccountDialog.propTypes = {
  deleteUser: PropTypes.func.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }
`
