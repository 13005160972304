import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { FloatingInput, Button } from 'common'
import { Field, reduxForm } from 'redux-form'
import { css } from 'react-emotion'
import { login } from '../../../redux/modules/auth'
import { fields } from './fields'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

export class LoginForm extends Component {
  constructor(props) {
    super(props)
  }

  getFieldPropsFor(key) {
    return {
      name: fields[key].name,
      type: fields[key].type,
      props: fields[key].props,
      warn: fields[key].warn,
    }
  }

  render() {
    const { submitting, invalid, handleSubmit } = this.props

    const disabled = submitting || invalid

    return (
      <form method="post" onSubmit={handleSubmit}>
        <h2>Welcome</h2>
        <h5>Sign in to continue to Pixsy</h5>

        <Helmet title="Login" />

        <Field
          component={FloatingInput}
          {...this.getFieldPropsFor('EMAIL')}
          autocomplete="username"
        />

        <Field
          component={FloatingInput}
          {...this.getFieldPropsFor('PASSWORD')}
          autocomplete="current-password"
        />

        <p className={tosNotice}>
          By continuing, I accept the&nbsp;
          <a href="https://www.pixsy.com/terms-of-use/" target="_blank">
            Terms of Use
          </a>
          &nbsp;and have
          <br />
          read and understood the&nbsp;
          <a href="https://www.pixsy.com/privacy-policy/" target="_blank">
            Privacy Policy
          </a>
        </p>

        <p className={actionButton}>
          <Button type="action" disabled={disabled} full submit>
            Log In
          </Button>
        </p>

        <Link to="/login/reset">Forgot your password?</Link>
      </form>
    )
  }
}

export default withGoogleReCaptcha(
  withRouter(
    reduxForm({
      onSubmit: async (values, dispatch, { googleReCaptchaProps, destroy }) => {
        // dispatch(login) will resolve promise with undefined
        // irrespective of successful or failed login
        const recaptcha = await googleReCaptchaProps
          .executeRecaptcha('login')
          .catch(console.error)
        await dispatch(login({ ...values, recaptcha }))
      },

      form: 'login',
      validate,
    })(LoginForm)
  )
)

function validate(values) {
  return Object.values(fields).reduce(
    (obj, { name, validate = () => null }) => {
      const entry = validate(values[name])
      return entry ? { ...obj, [name]: entry } : obj
    },
    {}
  )
}

const tosNotice = css`
  font-size: 0.85em;
  text-align: center;
  margin: -5px auto 2px;
  padding: 0;
  opacity: 0.75;
`

const actionButton = css`
  margin: 15px 0 30px;
  text-align: center;
`
