import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import randID from 'uuid/v4'
import { QObjectID, QString } from '../../core'
import { FileSchema } from '../../file/FileSchema'
import { ImageSchema } from '../ImageSchema'
import { MatchSchema } from '../MatchSchema'
import {
  SubmissionSchema,
  SUBMISSION_SOURCES,
  SUBMISSION_VERSION,
} from '../SubmissionSchema'

/**
 * @typedef {ReturnType<typeof SubmissionSchema["default"]>} SubmissionValues
 * @typedef {SubmissionValues["images"]} ImagesValues
 * @typedef {SubmissionValues["matches"]} MatchesValues
 * @typedef {ReturnType<ReturnType<typeof FileSchema>["default"]>} FileObject
 * @typedef {{ images: ImagesValues, matches: MatchesValues }} Cluster
 *
 * @typedef {Object} SubmissionData
 * @property {SubmissionValues} submission
 * @property {string | 'new'} caseId
 * @property {string=} clusterId
 */

/**
 * @param {Cluster} cluster
 * @param {any} caze
 * @param {string=} caseId
 * @param {any} previousValues
 * @returns {SubmissionData}
 */
export const createSubmissionData = (cluster, caze, caseId, previousValues) => {
  const ObjID = QObjectID.required()
  const sources = Object.values(SUBMISSION_SOURCES)
  const NEW_CASE = 'new'

  /**
   * Editing Case Submission
   */
  if (
    caseId !== NEW_CASE &&
    ObjID.isValidSync(caseId) &&
    isPlainObject(caze) &&
    isPlainObject(caze.submission) &&
    caze._id === caseId
  ) {
    // If >= v6.0
    if (
      sources.includes(caze.submission.source) &&
      caze.submission.version >= SUBMISSION_VERSION
    ) {
      const submission = SubmissionSchema.cast(caze.submission, {
        context: { values: caze.submission, cluster },
      })
      submission.source = caze.submission.source

      submission.matches.length = submission.images.length

      assignUniqueIdentifier(submission)
      return { submission, caseId, clusterId: caze.cluster }
    }

    /**
     * <= V5 cases (has cluster)
     * - Transform v5 cases to v6 cases
     * - Figure out if external or from match
     * - Try best to recover a case if somewhat broken
     */
    if (
      isPlainObject(cluster) &&
      ObjID.isValidSync(cluster._id) &&
      cluster._id === caze.cluster
    ) {
      const { images, matches } = getImagesMatchesFromCluster(cluster)
      const caseSubmission = { ...caze.submission }

      delete caseSubmission.images
      delete caseSubmission.matches

      const submission = SubmissionSchema.cast(caseSubmission, {
        context: { values: caseSubmission },
      })
      submission.images = images
      submission.matches = matches
      submission.fromVersion = '5'
      submission.metadata = 'Has cluster. '

      submission.matches.length = submission.images.length

      if (!images.length) {
        submission.source = SUBMISSION_SOURCES.EXTERNAL
        submission.metadata += 'Has no images. Set to EXTERNAL'
        return { submission, caseId, clusterId: cluster._id }
      }

      assignUniqueIdentifier(submission)

      submission.source = figureOutCaseSource(cluster.matches)

      return { submission, caseId, clusterId: cluster._id }
    }

    /**
     * <= V5 cases (doesn't have cluster | maybe has submission.{images/matches})
     */
    if (isArray(caze.submission.images) && isArray(caze.submission.matches)) {
      const { images, matches } = getImagesMatchesFromCluster(caze.submission)
      const caseSubmission = { ...caze.submission }

      delete caseSubmission.images
      delete caseSubmission.matches

      const submission = SubmissionSchema.cast(caseSubmission, {
        context: { values: caseSubmission },
      })
      submission.images = images
      submission.matches = matches
      submission.fromVersion = '5'
      submission.metadata = 'Has no cluster. Has submission[images/matches]. '

      submission.matches.length = submission.images.length

      let source = figureOutCaseSource(caze.submission.matches)
      let clusterId = undefined

      if (source === SUBMISSION_SOURCES.MATCH) {
        clusterId = figureOutClusterIdFromMatches(caze.submission.matches)
        if (!clusterId) {
          source = SUBMISSION_SOURCES.EXTERNAL
        } else {
          submission.metadata += 'Found cluster in matches.'
        }
      }

      assignUniqueIdentifier(submission)

      submission.source = source

      return { submission, caseId, clusterId }
    }

    /**
     * <= V5 cases (has no cluster, no submission.{images/matches}) 🤷
     * - Assume it's an empty external v6 submission
     */
    const caseSubmission = { ...caze.submission }
    delete caseSubmission.images
    delete caseSubmission.matches
    const submission = SubmissionSchema.cast(caseSubmission, {
      context: { values: caseSubmission },
    })
    submission.fromVersion = '5'
    submission.metadata = 'Empty Case. No Cluster. No images/matches.'

    assignUniqueIdentifier(submission)

    return { submission, caseId, clusterId: caze.cluster }
  }

  /**
   * >= v6 from match
   * New Case Submission
   */
  if (
    isPlainObject(cluster) &&
    ObjID.isValidSync(cluster._id) &&
    isArray(cluster.images) &&
    isArray(cluster.matches) &&
    !isEmpty(cluster.images) &&
    !isEmpty(cluster.matches)
  ) {
    const { images, matches } = getImagesMatchesFromCluster(cluster, true)
    const defaults = {
      images,
      matches,
      ...previousValues, // Initiate with values from previous submission (if available)
    }
    const submission = SubmissionSchema.cast(defaults, {
      context: { values: defaults },
    })

    submission.matches.length = submission.images.length

    if (isEmpty(submission.images)) {
      submission.images = []
      submission.matches = []
      submission.source = SUBMISSION_SOURCES.EXTERNAL
      submission.metadata = `From match but doesn't have images. Set to EXTERNAL`
      return { submission, caseId: NEW_CASE }
    }
    submission.source = SUBMISSION_SOURCES.MATCH

    assignUniqueIdentifier(submission)

    return { submission, caseId: NEW_CASE, clusterId: cluster._id }
  }

  /**
   * >= v6 external
   * New Case Submission
   */
  const submission = SubmissionSchema.cast(
    {
      ...previousValues, // Initiate with values from previous submission (if available)
    },
    { context: { values: {} } }
  )
  submission.source = SUBMISSION_SOURCES.EXTERNAL

  return { submission, caseId: NEW_CASE }
}

export const getImagesMatchesFromCluster = (cluster, withoutCase = false) => {
  /** @type {Cluster} */
  const { images: clusterImages = [], matches: clusterMatches = [] } = cluster
  const ObjID = QObjectID.required()
  const ObjURL = QString.required().url()

  let images = []
  let matches = []

  clusterImages
    .filter(
      i =>
        isPlainObject(i) &&
        ObjID.isValidSync(i._id, { context: { values: {} } })
    )
    .forEach(i => {
      const uuid = randID()
      const imageData = {
        ...i,
        /** @TODO: This is a band-aid and remove this line once [DEV-996] is resolved */
        licensing: {
          ...i.licensing,
          first_published: (i.licensing && i.licensing.first_published) || {},
        },
      }

      delete imageData.__LICENSING_HISTORY_FILES__
      delete imageData.__US_REGISTRATION_FILES__

      const image = ImageSchema.cast(imageData, { context: { values: {} } })

      image.__LICENSING_HISTORY_FILES__ = cleanUpFiles(
        i.__LICENSING_HISTORY_FILES__
      )
      image.__US_REGISTRATION_FILES__ = cleanUpFiles(
        i.__US_REGISTRATION_FILES__
      )

      const assertCase = m => {
        if (withoutCase) return !m.case
        return true
      }

      const matchesWithImage = clusterMatches.filter(
        m =>
          isPlainObject(m) &&
          isPlainObject(m.origin) &&
          ObjURL.isValidSync(m.origin.url) &&
          m.image === i._id &&
          !m.ignored &&
          assertCase(m)
      )

      if (!matchesWithImage.length) return

      const match = MatchSchema.cast(matchesWithImage.shift())

      match.uuid = uuid
      image.uuid = uuid

      if (matchesWithImage.length) {
        match.additional_links = [
          ...new Set(matchesWithImage.map(m => m.origin.url)),
        ]
      }

      images.push(image)
      matches.push(match)
    })

  return { images, matches }
}

/**
 * @param {Cluster} param0
 */
export const assignUniqueIdentifier = ({ images, matches }) => {
  images.forEach((img, indx) => {
    img.uuid = randID()
    matches[indx].uuid = img.uuid
  })

  return { images, matches }
}

/**
 * @param {MatchesValues} matches
 * @returns {"EXTERNAL" | "MATCH"}
 */
export const figureOutCaseSource = matches => {
  if (!isArray(matches)) return SUBMISSION_SOURCES.EXTERNAL

  return matches.every(
    m => isPlainObject(m) && isPlainObject(m.origin) && 'title' in m.origin
  )
    ? SUBMISSION_SOURCES.MATCH
    : SUBMISSION_SOURCES.EXTERNAL
}

/**
 * @param {MatchesValues} matches
 * @returns {string | undefined}
 */
export const figureOutClusterIdFromMatches = matches => {
  if (!isArray(matches)) return null

  const ObjID = QObjectID.required()

  let clusterId = undefined
  const isNotEqualClusterId = matches.some(m => {
    if (!m.cluster || !ObjID.isValidSync(m.cluster)) return true
    if (!clusterId) {
      clusterId = m.cluster
      return false
    }
    return !(m.cluster === clusterId)
  })

  if (isNotEqualClusterId) return undefined
  return clusterId
}

/**
 * @param {FileObject[]} files
 */
export const cleanUpFiles = files => {
  if (!isArray(files)) return []

  const ObjURL = QString.required().url()
  const nextFiles = []

  files.forEach(file => {
    if (FileSchema({ strip: true }).isValidSync(file)) {
      const file = FileSchema({ strip: true }).cast(file)

      if (file.uploadComplete && ObjURL.isValidSync(file.url) && !file.error) {
        nextFiles.push(file)
      }
    }
  })

  return nextFiles
}
