/**
 * Modal to show information to the user suggesting the case was autofilled
 */

import React, { useState, useCallback } from 'react'
import { Modal, Button } from 'common'
import { connect } from 'react-redux'
import { hidePrefilledCaseSubmissionInfoModal } from '../../../../../redux/modules/users'
import { css } from 'react-emotion'
import get from 'lodash/get'

const PrefillCaseSubmissionInfoModal = ({
  markModalSeen,
  user,
  previousSubmission,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(
    Object.keys(previousSubmission).length > 0 &&
      !get(user, 'photographerFields.campaigns.caseSubPrefillInfo')
  )

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    markModalSeen()
  }, [setIsModalOpen])

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
      <div css={content}>
        <h1>Pixsy remembers your responses!</h1>
        <p>
          We will now auto-fill your responses to the case submission form based
          on your previous case submission. We hope this makes the submission
          processes even easier for you.
        </p>
        <p>
          Please carefully check the details each time (even if it’s
          auto-filled) to ensure the data is correct and specific for each case
          submission - we use this data when we handle your cases.
        </p>
        <div css={buttons}>
          <Button hoverMain onClick={closeModal}>
            Proceed to Case Submission
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default connect(null, {
  markModalSeen: hidePrefilledCaseSubmissionInfoModal,
})(PrefillCaseSubmissionInfoModal)

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
`

const buttons = css`
  display: flex;
  justify-content: center;
  flex-direction: row;

  button {
    margin: 30px 4px 30px 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
