import React from 'react'
import { css } from 'react-emotion'
import { Button, createContactUsHandler } from 'common'
import {
  PixsyGrid,
  PixsyGridItem,
  PixsyButtonGroup,
} from '../../../../Components'

export const ScenarioDomainTLDBlocked = ({
  onTakedown,
  onIgnore,
  showMatchActions,
}) => {
  return (
    <PixsyGrid className={style}>
      <PixsyGridItem fullWidth>
        <h1>This match is not a viable case</h1>
      </PixsyGridItem>

      <PixsyGridItem fullWidth>
        <h4>
          Pixsy has detected that this domain falls outside of the{' '}
          <span data-elevio-article="17">
            jurisdictions we currently support.
          </span>
        </h4>
      </PixsyGridItem>

      {showMatchActions && (
        <PixsyGridItem fullWidth>
          <p>
            We recommend that you <b>send a Takedown Notice</b> for this match,
            requesting the image is removed by the website operator or host.
          </p>
        </PixsyGridItem>
      )}

      <PixsyGridItem fullWidth>
        <p>
          Pixsy uses a set of advanced algorithms to pre-determine if a case is
          suitable for resolution. We do this to save you time, and to ensure
          that our case management team can focus their efforts on recovering
          compensation for viable cases.
        </p>
        <p>
          If you believe this domain has been blocked but the case is still
          viable, you can contact us and we can further evaluate the case for
          you.
        </p>
      </PixsyGridItem>

      <PixsyGridItem fullWidth>
        {(!showMatchActions && (
          <PixsyButtonGroup
            itemStyle={{ justify: 'center', style: { display: 'flex' } }}
          >
            <Button
              full
              onClick={createContactUsHandler('Unresolvable TLD')}
              greyOutline
            >
              Contact us
            </Button>
          </PixsyButtonGroup>
        )) || (
          <PixsyButtonGroup
            itemStyle={{ justify: 'center', style: { display: 'flex' } }}
          >
            <Button
              full
              onClick={createContactUsHandler('Unresolvable TLD')}
              greyOutline
            >
              Contact us
            </Button>
            <Button full hoverMain onClick={onTakedown}>
              Send Takedown
            </Button>
          </PixsyButtonGroup>
        )}
      </PixsyGridItem>
    </PixsyGrid>
  )
}

const style = css`
  text-align: center;
`
