import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import _set from 'lodash/set'
import _get from 'lodash/get'
import { css } from 'react-emotion'
import { Button, TextField, PhantomLabelSpacer, AlignLabels } from 'common'
import { updateUser } from '../../../redux/modules/auth'

const ConnectedForm = reduxForm({
  form: 'licensing',
  enableReinitialize: true,
  onSubmit: (values, dispatch) => {
    const $u = {}
    for (const [k, v] of Object.entries(values)) {
      _set($u, k, v)
    }
    return dispatch(updateUser(values))
  },
  validate(values) {
    const obj = {}
    const path = 'photographerFields.salesHistory.website'
    const url = _get(values, path)

    if (url) {
      const isURL = str =>
        /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(str)

      if (!isURL(url)) {
        _set(obj, path, 'Valid URL starting with http:// or https:// required.')
      }
    }
    return obj
  },
})(Form)

export default function LicensingForm({ user }) {
  return <ConnectedForm initialValues={user} />
}

function Form({ valid, handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      {/* <Field
        component={TextArea}
        name="photographerFields.creativeBackground.careerSummary"
        label="Which of the following describe the type of photographic work you do? Please select all that apply."
      /> */}

      {/* <SexySeparator space={20} />
      <Field
        component={TextArea}
        name="photographerFields.creativeBackground.equipment"
        label="If you sell or license your photos, how much do you typically earn per sale? (in USD)"
      /> 
      <SexySeparator space={20} /> */}

      <AlignLabels align="left" width="200px">
        <Field
          component={TextField}
          name="photographerFields.salesHistory.website"
          label="What is your website?"
        />

        <PhantomLabelSpacer alignContent="right">
          <div css={mobile}>
            <Button disabled={!valid || submitting} submit>
              Save
            </Button>
          </div>
        </PhantomLabelSpacer>
      </AlignLabels>
    </form>
  )
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}
