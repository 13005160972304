import * as React from 'react'

export default React.memo(() => (
  <svg viewBox="0 0 83.072 58.057">
    <g>
      <g fill="#3b3b3b">
        <path d="M1.07 15.269l31.261 13.38a1.727 1.727 0 0 0 .692.142 1.772 1.772 0 0 0 .692-.142l31.261-13.38a1.76 1.76 0 0 0-.067-3.264L33.648.116a1.752 1.752 0 0 0-1.251 0L1.136 12.005a1.76 1.76 0 0 0-.066 3.264zM33.022 3.644l26.562 10.1-26.562 11.372L6.46 13.747z" />
        <path d="M1.069 22.883L33.021 36.56l31.953-13.677a1.76 1.76 0 0 0-1.384-3.237L33.022 32.731 2.453 19.646a1.76 1.76 0 1 0-1.384 3.237z" />
        <path d="M1.069 29.924l31.952 13.677 31.952-13.677a1.76 1.76 0 1 0-1.384-3.236L33.022 39.772 2.453 26.688a1.76 1.76 0 1 0-1.384 3.236z" />
        <path d="M1.069 36.964l31.952 13.677 31.952-13.677a1.76 1.76 0 0 0-1.384-3.236L33.022 46.812 2.453 33.727a1.76 1.76 0 1 0-1.384 3.236z" />
      </g>
      <g transform="translate(-1205.291 -1015.307)">
        <circle
          cx={13.682}
          cy={13.682}
          r={13.682}
          transform="translate(1261 1046)"
          fill="#008aab"
        />
        <path
          fill="none"
          stroke="#fff"
          strokeWidth={3}
          d="M1274.682 1051.893v15.578"
        />
        <path
          fill="none"
          stroke="#fff"
          strokeWidth={3}
          d="M1282.471 1059.682h-15.578"
        />
      </g>
    </g>
  </svg>
))
