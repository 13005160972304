import { SUBMISSION_NAMES as NAMES } from 'pixsy-schema/case/SubmissionSchema'

export default {
  [NAMES.continued_use_license]: {
    title: 'What are the terms of a Pixsy license?',
    body: `Pixsy may on occasion offer a time-bound continued-use license to resolve
      your case at a higher fee. Our standard continued-use licenses are 1, 3 or 5 year
      continued use of your work ONLY on the link or links submitted to Pixsy in your
      case submission. If there is a specific reason you don't want to allow future
      use, or you not have the authority to issue this, please select no. On occasion
      an image user might request further usage rights, and in the event that the
      requested terms deviate from the above standard, we'll always check in with you
      first.`,
  },
  [NAMES.price_range]: {
    title: 'Pricing your work',
    body: `If you're unsure, please let Pixsy decide. It is our experience that amounts
      based upon our knowledge and experience are far more likely to be paid.`,
    elevioId: 168,
  },
  [NAMES.requested_price.min.value]: {
    title: 'Pricing your work',
    body: `If you set your own pricing calculation, we’ll also need you to provide
      licensing history that supports your pricing. You can attach past invoices to
      support your licensing history to the question “Any files, attachments,
      invoices or evidence you would like to add?” that appears at the end of this form.
      Don’t have past licenses? We recommend using Pixsys pricing as we use local fair
      market guides within each of our jurisdictions to decide the best fee for the
      use of your work.`,
    elevioId: 168,
  },
  [NAMES.koDisclaimerTruth]: {
    title: 'Authorization Agreement',
    body: `Please read and check thoroughly before submitting your case. You are legally
      authorizing Pixsy to act on your behalf, including negotiations, settlements and
      potential legal action. Once the process has been started you could incur cancelation
      costs if information provided to us was not correct. We look forward to enforcing
      your rights in this case.`,
    footer: null,
  },
}
