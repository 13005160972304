import React, { Component } from 'react'
import PropTypes from 'prop-types'
import qs from 'querystring'
import {
  Pagination,
  SexySeparator,
  CardFilterBar,
  StatusHeading,
  LoadingOverlay,
  PaginationFooter,
  Modal,
  SelectableContext,
} from 'common'
import { connect } from 'react-redux'
import { HotKeys } from 'react-hotkeys'
import { notifSend } from 'redux-notifications/lib/actions'
import { withCookies } from 'react-cookie'
import { withEmptyStates } from '../../../../decorators'
import SmartFilterSuggestions from '../SmartFilterSuggestions'
import View from './View'
import NoResults from './NoResults'
import SimilarityControl from './SimilarityControl'
import { SortBarLabel } from './SortBar'
import ViewToolbar from './ViewToolbar/ViewToolbar'
import ActionsPanel from './ActionsPanel/ActionsPanel'
import IgnoreDialog from './ActionsPanel/IgnoreDialog'
import AddIgnoreRuleDialog from '../AddIgnoreRuleDialog/AddIgnoreRuleDialog'
import * as Icon from './Icons'

const MATCHES_COOKIE_KEY = 'MATCHES-PAGEVIEW'

export class MatchesCardList extends Component {
  static SELECTION_LIMIT = 40

  state = {
    displayIgnoreDialog: false,
    displayIgnoreRuleDialog: false,
    ruleDialogClusterId: null,
    ruleDialogMatchUrl: null,
    ignoring: false,
  }

  handlePageChange = (event) => {
    const selected = typeof event === 'number' ? event : event.selected
    const { perPage } = this.props.paginationProps
    const {
      location: { pathname },
      history,
      query,
    } = this.props

    history.push({
      pathname,
      search: qs.stringify({
        page: selected,
        pageSize: perPage,
        sort: query.sort,
      }),
    })
  }

  handlePerPageChange = (nPages) => {
    const {
      location: { pathname },
      history,
      query,
    } = this.props

    history.push({
      pathname,
      search: qs.stringify({
        page: 0,
        pageSize: nPages,
        sort: query.sort,
      }),
    })
  }

  handleViewChange = (view) => () => {
    const { cookies } = this.props

    cookies.set(MATCHES_COOKIE_KEY, view, { path: '/' })

    try {
      window.gtag('event', 'setDimension', {
        lastMatchView: view,
        event_label: 'lastMatchView: ' + view,
      })

      window.gtag('event', 'page_view', {
        page_path: '/c/vpv/matches/changeView',
        event_callback(res) {
          console.info('pageview success')
          console.info(res)
        },
      })
      window.gtag('event', 'changeView', {
        event_category: 'matches',
        event_label: 'Match View Changed (' + view + ')',
        event_callback(res) {
          console.info('event success')
          console.info(res)
        },
      })
    } catch (e) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }

    try {
      window.mixpanel.track(
        'Matches.Display.' + view.charAt(0).toUpperCase() + view.substr(1)
      )
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }

  resetView = (cb) => {
    this.setState(
      {
        displayIgnoreDialog: false,
        displayIgnoreRuleDialog: false,
      },
      cb
    )
  }

  handleIgnoreDialogOpen = (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreDialog: true })
  }

  handleIgnoreDialogClose = (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreDialog: false })
  }

  handleIgnoreRuleDialogOpen = (
    ruleDialogClusterId,
    ruleDialogMatchUrl
  ) => (e) => {
    e && e.preventDefault()
    this.setState({
      displayIgnoreRuleDialog: true,
      ruleDialogClusterId,
      ruleDialogMatchUrl,
    })
  }

  handleIgnoreRuleDialogClose = (e) => {
    e && e.preventDefault()
    this.setState({ displayIgnoreRuleDialog: false })
  }

  keyMap = {
    close: 'esc',
  }

  render() {
    const {
      clusters,
      cookies,
      ignoringClusters,
      loading,
      location,
      match,
      newSubtitle,
      pageCount,
      paginationProps,
      query,
      total,
      availableTags,
      selection,
      clear,
      isSelecting,
    } = this.props
    const {
      displayIgnoreDialog,
      displayIgnoreRuleDialog,
      ruleDialogClusterId,
      ruleDialogMatchUrl,
    } = this.state

    const { tags: activeTags } = query

    const pageView = cookies.get(MATCHES_COOKIE_KEY) || 'list'

    // xx match(es)
    const title = `${Number(total).toLocaleString()} match${
      total !== 1 ? 'es' : ''
    }`
    // on x page(s)
    const subtitle =
      typeof newSubtitle === 'string'
        ? newSubtitle
        : pageCount > 0
        ? `on ${Number(pageCount).toLocaleString()} page${
            pageCount > 1 ? 's' : ''
          }`
        : ''

    return (
      <React.Fragment>
        <StatusHeading
          title={title}
          subtitle={subtitle}
          isVisible={Number.isInteger(total)}
        />

        <SmartFilterSuggestions
          loading={loading}
          linkbase={location.pathname}
          hidden={!loading && total < 5}
          availableTags={availableTags}
          activeTags={activeTags}
        />

        <CardFilterBar
          renderLeftAddon={(props) => <SortBarLabel {...props} />}
          renderCenterAddon={(props) => (
            <Pagination
              {...paginationProps}
              onPageChange={this.handlePageChange}
              marginPagesDisplayed={0}
              size="small"
              {...props}
            />
          )}
          renderRightAddon={(props) => <SimilarityControl {...props} />}
        />

        <LoadingOverlay show={loading}>
          {clusters.length > 0 && (
            <React.Fragment>
              <ViewToolbar>
                <ViewToolbar.Button
                  icon={<Icon.Grid height={10.17} />}
                  title="Grid View"
                  active={pageView === 'grid'}
                  onClick={this.handleViewChange('grid')}
                />
                <ViewToolbar.Button
                  icon={<Icon.List height={10.17} />}
                  title="List View"
                  active={pageView === 'list'}
                  onClick={this.handleViewChange('list')}
                />
              </ViewToolbar>
              <View
                tags={activeTags}
                loading={loading}
                clusters={clusters}
                location={location}
                view={pageView}
              />
            </React.Fragment>
          )}

          <Modal
            isOpen={displayIgnoreRuleDialog}
            onRequestClose={this.handleIgnoreRuleDialogClose}
            theme="light"
          >
            <AddIgnoreRuleDialog
              location={location}
              match={match}
              clusterId={ruleDialogClusterId}
              matchUrl={ruleDialogMatchUrl}
              onClose={this.handleIgnoreRuleDialogClose}
              white
            />
          </Modal>
          <IgnoreDialog
            isOpen={
              selection.length > 0 &&
              displayIgnoreDialog &&
              !displayIgnoreRuleDialog
            }
            selection={selection}
            loading={loading || ignoringClusters}
            onClose={() => {
              this.handleIgnoreDialogClose()
              clear()
            }}
            onIgnoreDomain={this.handleIgnoreRuleDialogOpen}
          />

          <HotKeys
            keyMap={this.keyMap}
            handlers={{
              close: () => this.resetView(clear),
            }}
            focused
            attach={global}
          />

          {clusters.length > 0 && (
            <ActionsPanel
              visible={isSelecting}
              selection={selection}
              onIgnoreDialogOpen={this.handleIgnoreDialogOpen}
            />
          )}

          {clusters.length > 0 && (
            <React.Fragment>
              <SexySeparator space={20} />
              <PaginationFooter
                singularType="match"
                pluralType="matches"
                onPerPageChange={this.handlePerPageChange}
                onPageChange={this.handlePageChange}
                paginationProps={paginationProps}
              />
            </React.Fragment>
          )}
        </LoadingOverlay>
      </React.Fragment>
    )
  }
}

export default connect(
  ({ clusters: { ignoringClusters } }) => ({
    ignoringClusters,
  }),
  {
    notifSend,
  }
)(
  withEmptyStates([
    {
      shouldRender: ({ loading, total }) => !loading && !(total > 0),
      render: () => <NoResults />,
    },
  ])(
    withCookies(function SelectableCardList(props) {
      const handleLimitExceeded = (limit) => {
        props.notifSend({
          kind: 'info',
          message: `You have selected the maximum number of items for this action (${limit}).`,
          dismissAfter: 5e3,
        })
      }
      return (
        <SelectableContext.Wrapper
          limit={MatchesCardList.SELECTION_LIMIT}
          handleLimitExceeded={handleLimitExceeded}
        >
          <SelectableContext.Consumer>
            {(contextProps) => <MatchesCardList {...contextProps} {...props} />}
          </SelectableContext.Consumer>
        </SelectableContext.Wrapper>
      )
    })
  )
)

MatchesCardList.propTypes = {
  clusters: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  newSubtitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  query: PropTypes.object.isRequired,
  locationMatch: PropTypes.object.isRequired,
  cookies: PropTypes.object,
  total: PropTypes.number,
  availableTags: PropTypes.array,
}
