import Grid from '@material-ui/core/Grid'
import * as React from 'react'
import { css, cx } from 'react-emotion'

// https://next.material-ui.com/customization/breakpoints
// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl
// @see - https://next.material-ui.com/api/grid/
export const PixsyGridItem = ({
  lg = false,
  md = false,
  sm = false,
  xl = false,
  xs = false,
  style = {},
  children,
  className,
  flexGrow,
  flexShrink,
  fullWidth,
  flexBasis,
  alignItems,
  alignSelf,
  flex,
  justify: justifyContent,
  direction: flexDirection,
  onClick,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  tabIndex,
}) => {
  const baseStyle = css({
    flexGrow,
    flexShrink,
    flexBasis,
    flexDirection,
    justifyContent,
    alignItems,
    alignSelf,
    width: fullWidth ? '100%' : 'unset',
    display: flex ? 'flex' : undefined,
  })
  const styles = {
    item: cx(baseStyle, className, css(style)),
  }
  return (
    <Grid
      item
      classes={styles}
      lg={lg}
      md={md}
      sm={sm}
      xl={xl}
      xs={xs}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={tabIndex}
    >
      {children}
    </Grid>
  )
}
