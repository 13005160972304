import React, { Component } from 'react'
import {
  GridWrappingColumn,
  SexySeparator,
  ScrollToTop,
  StatusHeading,
  LoadingOverlay,
} from 'common'
import { DATA_ELEVIO } from 'pixsy-constants'
import { asyncConnect } from 'redux-connect'
import { withCookies } from 'react-cookie'
import { withEmptyStates } from '../../../../decorators'
import {
  loadSummary as load,
  CLUSTER_SUMMARY_LOAD,
} from '../../../../redux/modules/clusters'
import EmptyState from '../EmptyState'
import ScanningLoader from '../ScanningLoader'
import OverviewGrid from './OverviewGrid'
import DefaultStickyFloater from '../QueryBar/DefaultStickyFloater'
import moment from 'moment'

export class MatchesOverview extends Component {
  countUnseen() {
    const unseen = this.props.summary.tags.find(t => t._id === 'unseen')
    return unseen ? unseen.count : 0
  }

  componentDidMount() {
    if (__SERVER__) return
    this.props.load()
  }

  render() {
    const {
      user,
      location,
      match,
      loading,
      actionable,
      commercial,
      lastCreated,
      lastScan,
      new: _new,
      scanning,
      tags,
      total,
    } = this.props

    return (
      <GridWrappingColumn maxWidth={1180}>
        <DefaultStickyFloater
          location={location}
          match={match}
          linkBase="/matches"
        />
        <LoadingOverlay show={loading}>
          <ScrollToTop />
          <StatusHeading
            title={Number(actionable).toLocaleString() + ' matches'}
            isVisible={!isNaN(actionable)}
          />

          <SexySeparator
            space={0}
            label="Stats"
            data-elevio-selector={DATA_ELEVIO.MATCHES_OVERVIEW}
          />

          {!isNaN(total) && (
            <OverviewGrid
              linkBase="/matches"
              user={user}
              actionable={actionable}
              commercial={commercial}
              lastCreated={lastCreated}
              lastScan={lastScan}
              new={_new}
              scanning={scanning}
              tags={tags}
              total={total}
            />
          )}
        </LoadingOverlay>
      </GridWrappingColumn>
    )
  }
}

const isNewFlickrUser = user =>
  user.flickr.id &&
  moment(user.flickr.verifiedAt) >
    moment()
      .utc()
      .subtract(5, 'minutes')
      .toDate() &&
  moment(user.created) >
    moment()
      .utc()
      .subtract(5, 'minutes')
      .toDate()

export default asyncConnect(
  [
    {
      promise({ store: { dispatch }, match, location }) {
        if (__SERVER__) return dispatch({ type: CLUSTER_SUMMARY_LOAD })
        return Promise.resolve({})
      },
    },
  ],
  state => ({
    user: state.auth.user,
    ...state.clusters,
  }),
  { load }
)(
  withEmptyStates([
    {
      shouldRender: ({ user, total, scanning, loading }) =>
        (isNewFlickrUser(user) && total === 0) ||
        (!loading && total === 0 && scanning),
      render: () => <ScanningLoader />,
    },
    {
      shouldRender: ({ total, scanning, loading }) =>
        !loading && total === 0 && !scanning,
      render: () => <EmptyState />,
    },
  ])(withCookies(MatchesOverview))
)
