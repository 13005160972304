import React from 'react'
import { css } from 'react-emotion'
import { ContentSection, SexySeparator } from 'common'
import * as Files from './AttachmentsFiles'
import get from 'lodash/get'

const getDownloadURL = (requestId, docId) =>
  !docId ? null : `/api/cases/content-document/${docId}?requestId=${requestId}`

const isDocumentId = /^[a-z0-9]+$/i

export default class AttachmentsSection extends React.Component {
  constructor(props) {
    super(props)
    const { case: caze, salesforce, screenshots } = this.props
    const getUrl = getDownloadURL.bind(null, caze.salesforce.Id)

    const isLV =
      caze.salesforce.Estimated_Value__c === 'Low' &&
      caze.salesforce.FirstLetterSentD__c >= '2019-06-25'

    const files = [
      {
        name: 'Unauthorized Use Letter',
        type: 'pdf',
        url: getUrl(salesforce.FirstLetterAttachmentID__c),
      },
      !isLV && {
        name: 'Evidence Report',
        type: 'pdf',
        url: getUrl(salesforce.EvidenceReportVersion_ID__c),
      },
      (salesforce.MainInfringerCase__r &&
        salesforce.MainInfringerCase__r.DDPLicensingInvoiceProxy__r &&
        salesforce.MainInfringerCase__r.DDPLicensingInvoiceProxy__r.S3Path__c &&
        salesforce.MainInfringerCase__r.DDPLicensingInvoiceProxy__r
          .DocumentID__c && {
          name: 'Invoice',
          type: 'pdf',
          url:
            salesforce.MainInfringerCase__r.DDPLicensingInvoiceProxy__r
              .S3Path__c,
        }) ||
        undefined,
      {
        name: 'Client Agent Confirmation',
        type: 'pdf',
        url: getUrl(
          salesforce.Photographer__r &&
            salesforce.Photographer__r.AgentConfirmationStatement__c
        ),
      },
      {
        name: 'Pixsy W9 Tax Form',
        type: 'pdf',
        url: 'https://static.pixsy.io/documents/PixsyIncW9.pdf',
      },
      // (salesforce.Photographer__r &&
      //   salesforce.Photographer__r.DDPLicenseSummary__r &&
      //   salesforce.Photographer__r.DDPLicenseSummary__r.S3Path__c &&
      //   salesforce.Photographer__r.DDPLicenseSummary__r.DocumentID__c && {
      //     name: 'License Summary',
      //     type: 'pdf',
      //     url: salesforce.Photographer__r.DDPLicenseSummary__r.S3Path__c,
      //   }) ||
      //   undefined,
    ].filter(Boolean)
    if (salesforce.V22_Evidence__c) {
      get(caze, 'submission__c.ImageUses__r.records', []).forEach(({ Evidence_Page_Screenshot_URL__c }) => {
        files.push({
          name: 'Screenshot',
          type: 'png',
          url: Evidence_Page_Screenshot_URL__c,
        })
      })
    } else {
      screenshots.forEach((screenshot) => {
        const url = isDocumentId.test(screenshot)
          ? getUrl(screenshot)
          : screenshot
        files.push({
          name: 'Screenshot',
          type: 'png',
          url,
        })
      })
    }

    this.state = {
      files,
    }
  }
  componentDidMount() {
    // const { screenshots, case: caze } = this.props
    // const getUrl = getDownloadURL.bind(null, caze._id)
    // screenshots.map(screenshot => {
    //   const image = new Image()
    //   image.onload = () => {
    //     if (!this._unmounted) {
    //       this.setState(({ files }) => ({
    //         files: [
    //           ...files,
    //           {
    //             name: 'Screenshot',
    //             type: 'png',
    //             url: screenshot,
    //           },
    //         ],
    //       }))
    //     }
    //   }
    //   // image.onerror = () => console.warn(screenshot, 'offline or not an image')
    //   image.src = screenshot
    // })
  }
  componentWillUnmount() {
    this._unmounted = true
  }
  render() {
    const { files } = this.state

    return (
      <ContentSection>
        <div className={wrap}>
          <h1>Attachments</h1>
          <SexySeparator space={30} />
          <Files.Container>
            {files
              .filter((f) => f.url)
              .map(({ name, url, type }) => (
                <Files.File key={url} type={type} name={name} url={url} />
              ))}
          </Files.Container>
        </div>
      </ContentSection>
    )
  }
}

const wrap = css`
  h3 {
    font-weight: 400;
    margin-top: 0;
    font-size: 1.7em;
    margin: 0 auto 30px;
  }
`
