import * as React from 'react'
import { css, cx } from 'react-emotion'

const baseStyle = css`
  fill: white;
  stroke-width: 2px;
`

export default React.memo(({ style = '' }) => (
  <svg viewBox="0 0 72.912 70.317" className={cx(baseStyle, style)}>
    <defs>
      <style>
        {
          '.multi_match_svg__a528b8f1-501b-491c-b028-3ef12ca5a435,.multi_match_svg__bec43b42-e328-48b9-9e39-245d5adf59a6,.multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826{fill:none}.multi_match_svg__bec43b42-e328-48b9-9e39-245d5adf59a6{stroke:#eaeaea}.multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826{stroke:#707070}.multi_match_svg__a528b8f1-501b-491c-b028-3ef12ca5a435{stroke:#959595}'
        }
      </style>
    </defs>
    <g>
      <g>
        <path
          className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
          d="M17.176.317h55v58h-55z"
        />
        <path
          className="multi_match_svg__bec43b42-e328-48b9-9e39-245d5adf59a6"
          d="M17.5.5v57.135h54.912V.5z"
        />
        <path
          className="multi_match_svg__bec43b42-e328-48b9-9e39-245d5adf59a6"
          d="M17.5 45.56h55.17"
        />
        <path
          className="multi_match_svg__bec43b42-e328-48b9-9e39-245d5adf59a6"
          d="M21.691 45.56l19.08-16.683 12.165 12.466"
        />
        <path
          className="multi_match_svg__bec43b42-e328-48b9-9e39-245d5adf59a6"
          d="M46.8 35.317l11.874-13.3 13.748 13.67"
        />
        <g>
          <circle
            className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
            cx={31.676}
            cy={20.817}
            r={5.5}
          />
          <circle
            className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
            cx={31.676}
            cy={20.817}
            r={5}
          />
        </g>
      </g>
      <g>
        <path
          className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
          d="M11.176 4.317h55v58h-55z"
        />
        <path
          className="multi_match_svg__4080507f-f136-4d06-bb11-35a000d4df0c"
          d="M11.5 4.5v57.135h54.912V4.5z"
        />
        <path
          className="multi_match_svg__4080507f-f136-4d06-bb11-35a000d4df0c"
          d="M11.5 49.56h55.17"
        />
        <path
          className="multi_match_svg__4080507f-f136-4d06-bb11-35a000d4df0c"
          d="M15.691 49.56l19.08-16.683 12.165 12.466"
        />
        <path
          className="multi_match_svg__4080507f-f136-4d06-bb11-35a000d4df0c"
          d="M40.8 39.317l11.874-13.3 13.748 13.67"
        />
        <g>
          <circle
            className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
            cx={25.676}
            cy={24.817}
            r={5.5}
          />
          <circle
            className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
            cx={25.676}
            cy={24.817}
            r={5}
          />
        </g>
      </g>
      <g>
        <path
          className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
          d="M6.176 8.317h55v58h-55z"
        />
        <path
          className="multi_match_svg__a528b8f1-501b-491c-b028-3ef12ca5a435"
          d="M6.5 8.5v57.135h54.912V8.5z"
        />
        <path
          className="multi_match_svg__a528b8f1-501b-491c-b028-3ef12ca5a435"
          d="M6.5 53.56h55.17"
        />
        <path
          className="multi_match_svg__a528b8f1-501b-491c-b028-3ef12ca5a435"
          d="M10.691 53.56l19.08-16.683 12.165 12.466"
        />
        <path
          className="multi_match_svg__a528b8f1-501b-491c-b028-3ef12ca5a435"
          d="M35.8 43.317l11.874-13.3 13.748 13.67"
        />
        <g>
          <circle
            className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
            cx={20.676}
            cy={28.817}
            r={5.5}
          />
          <circle
            className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
            cx={20.676}
            cy={28.817}
            r={5}
          />
        </g>
      </g>
      <g>
        <path
          className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
          d="M.176 12.317h55v58h-55z"
        />
        <path
          className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
          d="M.5 12.5v57.135h54.912V12.5z"
        />
        <path
          className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
          d="M.5 57.56h55.17"
        />
        <path
          className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
          d="M4.691 57.56l19.08-16.683 12.165 12.466"
        />
        <path
          className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
          d="M29.8 47.317l11.874-13.3 13.748 13.67"
        />
        <g>
          <circle
            className="multi_match_svg__865bf7cb-2755-44fc-ae33-6042cc38b706"
            cx={14.676}
            cy={32.817}
            r={5.5}
          />
          <circle
            className="multi_match_svg__fb581029-c12c-45a7-9308-f9b6ccbea826"
            cx={14.676}
            cy={32.817}
            r={5}
          />
        </g>
      </g>
    </g>
  </svg>
))
