import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import FormRow from './FormRow'
import Label from './Label'

export default function VerticalCheckboxGroup(props) {
  let { input, label, items, meta } = props

  return (
    <FormRow meta={meta}>
      <div className={verticalcheckboxGroup}>
        <Label title={label} vertical align="left" />

        <div className={actionArea}>
          {items.map((item, index) => (
            <label key={item.value}>
              <input
                checked={input.value.indexOf(item.value) > -1}
                name={input.name}
                type="checkbox"
                id={`${input.name}-${index}`}
                value={item.value}
                onChange={e => {
                  const idx = input.value.indexOf(item.value)
                  if (idx < 0) {
                    if (e.target.checked) {
                      input.onChange([...input.value, item.value])
                    }
                  } else {
                    if (!e.target.checked) {
                      const copy = [...input.value]
                      copy.splice(idx, 1)
                      input.onChange(copy)
                    }
                  }
                }}
              />
              <span>{item.label}</span>
            </label>
          ))}
        </div>
      </div>
    </FormRow>
  )
}

const verticalcheckboxGroup = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 0;

  label {
    font-weight: 300;
    font-size: 1.14em;
    line-height: 0.7em;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const actionArea = css`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-left: 0px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  label {
    text-align: left;
    line-height: 30px;
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: stretch;
    justify-content: start;
    margin-bottom: 10px;
  }

  span {
    color: #434343;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.6em;
  }

  input[type='checkbox'] {
    margin: 10px 10px 0 0;
    &:focus {
      outline: inherit;
      outline-offset: inherit;
      border: inherit;
    }

    &:checked {
      background: #008aab;

      + label {
        outline: 1px solid #008aab;
        color: #fff;
      }
    }
  }
`

VerticalCheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
}
