import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
// import availableTags from './availableTags'
// import getTagSuggestions from '../../../getTagSuggestions'
import { formatClusterTag } from 'pixsy-constants'

import Autosuggest from 'react-autosuggest'

// Teach Autosuggest how to calculate suggestions for any given input value.

const getSearchSuggestions = value => {
  return value && value.length ? ['title:' + value] : []
}
const formatSuggestionForTag = tag => {
  const [category] = tag.split(':')
  if (category === 'title') {
    return (
      <React.Fragment>
        Images titled <b>{formatClusterTag(tag)}</b>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      Images tagged <code>{formatClusterTag(tag)}</code>
    </React.Fragment>
  )
}
const getSuggestions = (value, activeTags) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  if (!inputLength === 0) return []

  return [
    //    ...getTagSuggestions(availableTags, 5, isQualifyingResult),
    ...getSearchSuggestions(value),
  ]
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => formatClusterTag(suggestion)

// Use your imagination to render suggestions.
const renderSuggestion = tag => <div>{formatSuggestionForTag(tag)}</div>

export default class QueryBarSearch extends Component {
  state = {
    value: '',
    suggestions: [],
  }

  componentDidMount() {
    try {
      this._input && this._input.focus()
    } catch (e) {}
  }

  componentWillUnmount() {
    this._input = null
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.setState({
        suggestions: [],
        value: '',
      })
    }
  }

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  handleKeyDown = ({ key, target }) => {
    if (key === 'Escape') {
      target.blur()
      this.props.history.push('/images')
    }
    // MAYBE: Handle backspace?
  }
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.activeTags),
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  onSuggestionSelected = (event, selection) => {
    const { suggestion } = selection
    const { history, location } = this.props
    history.push({
      ...location,
      pathname: location.pathname + '/' + suggestion,
    })
  }

  render() {
    const { value, suggestions } = this.state
    const { activeTags } = this.props
    const pristine = activeTags.length === 0

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder:
        activeTags.length < 3 ? 'Search for Title' : 'Add more filters',
      value,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyDown,
    }

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        highlightFirstSuggestion
        alwaysRenderSuggestions
        theme={{
          input: pristine ? inputStylesPristine : inputStylesAddMore,
          suggestionsContainer,
          suggestion,
          suggestionsList,
          suggestionHighlighted,
        }}
        inputProps={inputProps}
        renderInputComponent={inputProps => (
          <input {...inputProps} ref={el => (this._input = el)} />
        )}
      />
    )
  }
}

QueryBarSearch.propTypes = {
  location: PropTypes.object.isRequired,
  activeTags: PropTypes.array.isRequired,
}

const inputStyles = css`
  width: 100%;
  border: 0;
  height: 54px;
  padding: 2px 18px;
  font-size: 24px;
  font-weight: 300;
  caret-color: #dbdbdb;
  color: #000;

  &:focus {
    outline: 0;
  }
`
const inputStylesPristine = css`
  ${inputStyles};
  font-size: 18px;
  font-weight: 400;

  &::placeholder {
    color: rgb(70, 70, 70);
  }
`
const inputStylesAddMore = css`
  ${inputStyles} font-size: 18px;
  font-weight: 400;
  &::placeholder {
    color: #4b4b4b80;
  }
`

const suggestionsContainer = css`
  margin-top: 10px;
  background: #fff;
`

const suggestionsList = css`
  list-style: none;
  padding: 0 0;
  box-shadow: 0 0 6px rgba(190, 190, 190);
`

const suggestion = css`
  font-size: 1.4em;
  margin: 10px 0;
  font-weight: 300;
  padding: 6px 0;
  padding-left: 14px;
  border-left: 5px solid transparent;
  transition: background-color 200ms ease;

  code {
    color: #00000080;
    padding: 6px 12px;
    font-size: 1em;
  }
`

const suggestionHighlighted = css`
  background-color: rgb(251, 251, 251);
`
