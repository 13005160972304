import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { notifSend } from 'redux-notifications/lib/actions'
import FormRow from './FormRow'
import { css } from 'react-emotion'

function ClipboardHelper(props) {
  const { input, notify } = props
  const elementId = `clipboard-helper-${input.name}`
  const copyToClipboard = () => {
    const el = document.getElementById(elementId)
    el.value = input.value
    el.select()
    document.execCommand('copy')
    el.blur()
    notify({
      message: 'Copied to clipboard!',
      kind: 'success',
      dismissAfter: 10e3,
    })
  }

  return (
    <FormRow {...props}>
      <div css={actionArea}>
        <textarea id={elementId} {...input} />
        <div css={copyButton} onClick={copyToClipboard}>
          COPY
          <br />
          TEXT
        </div>
      </div>
    </FormRow>
  )
}

export default connect(
  null,
  { notify: notifSend }
)(ClipboardHelper)

ClipboardHelper.propTypes = {
  input: PropTypes.object,
}

const actionArea = css`
  padding: 0 0;
  display: flex;
  flex-direction: row;
  height: 200px;
  align-items: stretch;

  textarea {
    padding: 5px;
    width: 100%;
    border: 1px solid #c7c7c7;
    font-size: 18px;
    resize: none;
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }
  }
  flex: 1;
`

const copyButton = css`
  background: #008aab;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  letter-spacing: 2px;
  color: white;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #009dc3;
    border-color: #009dc3;
    color: #fff;
  }
`
