import { memoize } from 'lodash'
import * as React from 'react'
// import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'

const PRICE_RANGE = [
  { value: 'Pixsy', label: `Pixsy's Pricing` },
  { value: 'Client', label: 'My Pricing' },
  { value: 'Highest', label: `Mine, unless Pixsy's is higher` },
]
export const PriceNegotiations = memoize(() => {
  return PRICE_RANGE.map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ))
})
// export const PriceNegotiations = memoize(() => {
//   return PRICE_RANGE.map(({ value, label }) => (
//     <PixsyMenuItem key={value} value={value}>
//       {label}
//     </PixsyMenuItem>
//   ))
// })
