import { Button } from 'common'
import React from 'react'
import { css } from 'react-emotion'
import { PixsyGrid, PixsyGridItem } from '../../../../Components'

export const ScenarioDomainDuplicate = ({ onConfirm }) => {
  return (
    <PixsyGrid alignItems="center" direction="column" className={style}>
      <PixsyGridItem fullWidth>
        <h1>Duplicate Warning</h1>
      </PixsyGridItem>

      <PixsyGridItem fullWidth>
        <h4>
          We found that you have already submitted a case for these matches
        </h4>
      </PixsyGridItem>

      {/* Load Duplicate cases here */}

      <PixsyGridItem fullWidth>
        <Button greyOutline onClick={onConfirm}>
          Confirm And Proceed
        </Button>
      </PixsyGridItem>
    </PixsyGrid>
  )
}

const style = css`
  text-align: center;
`
