import qs from 'qs'
import { createSelector } from 'reselect'

const getCaseId = (state, props) => {
  return props.match && props.match.params && props.match.params.caseId
}

const getClusterId = (state, props) => {
  const from = qs.parse(String(props.location.search).substring(1)).from
  if (from) return from

  const existingCase = state.entities.cases[getCaseId(state, props)]
  if (existingCase) return existingCase.cluster
}

const getEntities = (state, props) => state.entities

export const getCase = createSelector(
  [getCaseId, getEntities],
  (caseId, entities) => {
    if (!caseId || !entities.cases[caseId]) return null

    const caze = { ...entities.cases[caseId] }
    const submission = { ...caze.submission }

    // console.warn('SUBMISSION:', submission)
    // console.warn('ENTITIES:', entities)

    submission.images = (submission.images || [])
      .map(i => entities.images[i])
      .filter(Boolean)
      .map(image => ({
        ...image,
        registrations:
          entities && entities.registrations && image.registrations
            ? image.registrations
                .map(r => entities.registrations[r])
                .filter(r => r && r.salesforce && r.salesforce.Name)
            : [],
      }))
      .map(image => ({
        ...image,
        ...(image.registrations.length > 0 && {
          registration: {
            us: {
              id: image.registrations[0].salesforce.Name,
              status: 'Pixsy',
            },
          },
        }),
      }))

    submission.matches = (submission.matches || [])
      .map(i => entities.matches[i])
      .filter(Boolean)

    caze.submission = submission

    return caze
  }
)

export const getPreviousSubmission = createSelector([getEntities], entities => {
  for (const caze of Object.values(entities.cases)) {
    /**
     * Previous case submission is tagged with `isPrevious` before sending the
     * records from the server
     * @see pixsy-react/pixsy-app/api/controllers/cases/submission/readOne.js - fn: loadPreviousSubmissionData
     */
    if (caze.isPrevious) return caze.submission
  }
  return null
})

export const getCluster = createSelector(
  [getClusterId, getEntities],
  (clusterId, entities) => {
    if (!clusterId || !entities.clusters[clusterId]) return null

    const cluster = { ...entities.clusters[clusterId] }
    cluster.domain = entities.domains[cluster.domain]
    cluster.matches = cluster.matches
      .map(i => entities.matches[i])
      .filter(m => !m || !m.ignored)

    cluster.images = cluster.images
      .map(i => entities.images[i])
      .filter(img => cluster.matches.map(c => c.image).includes(img._id))

    return cluster
  }
)

export const getQuery = createSelector(
  getClusterId,
  getCaseId,
  (clusterId, caseId) => ({
    clusterId,
    caseId,
  })
)

export const resolveLocation = (location, match) => {
  const { from } = qs.parse(String(location.search).substring(1))
  const {
    params: { caseId },
  } = match
  return {
    clusterId: from,
    caseId,
  }
}
