import { DETAIL_MODAL_VIEW_TYPE } from './constants'
import * as actions from './actions'

const setListViewPageSort = (sort) => ({
  type: actions.SET_LIST_VIEW_PAGE_SORT,
  payload: {
    sort,
  },
})

const setListViewPageNumber = (forcePage) => ({
  type: actions.SET_LIST_VIEW_PAGE_NUMBER,
  payload: {
    forcePage,
  },
})

const setListViewPerPageCount = (perPage) => ({
  type: actions.SET_LIST_VIEW_PER_PAGE_COUNT,
  payload: {
    forcePage: 0,
    perPage,
  },
})

const setLastIgnoredMatch = (lastIgnoredMatch) => ({
  type: actions.SET_LAST_IGNORED_MATCH,
  payload: {
    lastIgnoredMatch,
  },
})

const resetLastIgnoredMatch = () => ({
  type: actions.RESET_LAST_IGNORED_MATCH,
  payload: {
    lastIgnoredMatch: {
      matchId: null,
      clusterId: null,
      undoFn: null,
    },
  },
})

const setMoreModalViewOpen = (clusterId, matchId, cluster) => ({
  type: actions.SET_MORE_MODAL_VIEW_OPEN,
  payload: {
    clusterId,
    matchId,
    cluster,
    modal: DETAIL_MODAL_VIEW_TYPE.MORE,
  },
})

const setDetailsModalViewOpen = (clusterId, matchId, cluster) => ({
  type: actions.SET_DETAILS_MODAL_VIEW_OPEN,
  payload: {
    clusterId,
    matchId,
    cluster,
    modal: DETAIL_MODAL_VIEW_TYPE.DETAILS,
  },
})

const setActionsModalViewOpen = (clusterId, matchId, cluster) => ({
  type: actions.SET_ACTIONS_MODAL_VIEW_OPEN,
  payload: {
    clusterId,
    matchId,
    cluster,
    modal: DETAIL_MODAL_VIEW_TYPE.ACTIONS,
  },
})

const setModalViewClose = (clusterId, matchId, cluster) => ({
  type: actions.SET_MODAL_VIEW_CLOSE,
  payload: {
    clusterId,
    matchId,
    cluster,
    modal: null,
  },
})

const setGroupMatchesViewOpen = (clusterId, cluster) => ({
  type: actions.SET_GROUP_MATCHES_VIEW_OPEN,
  payload: {
    clusterId,
    cluster,
  },
})

const setGroupMatchesViewClose = (clusterId) => ({
  type: actions.SET_GROUP_MATCHES_VIEW_CLOSE,
  payload: {
    clusterId,
  },
})

const resetScrollToClusterId = () => ({
  type: actions.RESET_SCROLL_TO_CLUSTER_ID,
})

const updateSelectedFiltersState = (selectedFilters) => ({
  type: actions.UPDATE_SELECTED_FILTERS,
  payload: {
    ...selectedFilters,
  },
})

const updateSelectedContextState = (selectedContext) => ({
  type: actions.UPDATE_SELECTED_CONTEXT,
  payload: {
    ...selectedContext,
  },
})

const updateSelectedCountryIds = (selectedCountryIds) => ({
  type: actions.SET_SELECTED_COUNTRY_IDS,
  payload: {
    selectedCountryIds,
  },
})

const updateSelectedCategoryIds = (selectedCategoryIds) => ({
  type: actions.SET_SELECTED_CATEGORY_IDS,
  payload: {
    selectedCategoryIds,
  },
})

const updateSelectedActionIds = (selectedActionIds) => ({
  type: actions.SET_SELECTED_ACTION_IDS,
  payload: {
    selectedActionIds,
  },
})

const updateSelectedYearRange = (selectedYearRange) => ({
  type: actions.SET_SELECTED_YEAR_RANGE,
  payload: {
    selectedYearRange,
  },
})

const updateSelectedMatchesType = (selectedMatchesType) => ({
  type: actions.SET_SELECTED_MATCHES_TYPE,
  payload: {
    selectedMatchesType,
  },
})

const updateSelectedSeenState = (selectedSeenState) => ({
  type: actions.SET_SELECTED_SEEN_STATE,
  payload: {
    selectedSeenState,
  },
})

const resetUserSelectionState = () => ({
  type: actions.RESET_USER_SELECTIONS_STATE,
})

const addSelectedCountryId = (id) => ({
  type: actions.ADD_SELECTED_COUNTRY_ID,
  payload: {
    id,
    selectedFilterKey: 'selectedCountryIds',
  },
})

const addSelectedCategoryId = (id) => ({
  type: actions.ADD_SELECTED_CATEGORY_ID,
  payload: {
    id,
    selectedFilterKey: 'selectedCategoryIds',
  },
})

const addSelectedActionId = (id) => ({
  type: actions.ADD_SELECTED_ACTION_ID,
  payload: {
    id,
    selectedFilterKey: 'selectedActionIds',
  },
})

const updateCollapsableContentsCloseFunction = (lastOpenedCollapsablesCloseFn) => ({
  type: actions.SET_COLLAPSABLE_CONTENT_CLOSE_FUNCTION,
  payload: {
    lastOpenedCollapsablesCloseFn,
  },
})

export {
  updateSelectedCountryIds,
  updateSelectedCategoryIds,
  updateSelectedActionIds,
  updateSelectedYearRange,
  updateCollapsableContentsCloseFunction,
  updateSelectedMatchesType,
  updateSelectedSeenState,
  resetUserSelectionState,
  addSelectedCountryId,
  addSelectedCategoryId,
  addSelectedActionId,
  updateSelectedFiltersState,
  setGroupMatchesViewOpen,
  setMoreModalViewOpen,
  setDetailsModalViewOpen,
  setActionsModalViewOpen,
  setModalViewClose,
  updateSelectedContextState,
  setLastIgnoredMatch,
  resetLastIgnoredMatch,
  setListViewPageNumber,
  setListViewPerPageCount,
  setListViewPageSort,
  setGroupMatchesViewClose,
  resetScrollToClusterId,
}
