import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Feedback from './Feedback'
import Label from './Label'
import AlignLabels from './AlignLabels'
import { css, cx } from 'react-emotion'

export default function TickCheckboxLeft(props) {
  const { input, meta = {}, label, locked, addon } = props
  return (
    <FormRow {...props}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
        <AlignLabels align={props.labelAlign} width={props.labelWidth}>
          <Label title={label} />
        </AlignLabels>
        <Feedback meta={meta} />
        {locked ? (
          <p css={lockedValue}>{input.value ? 'Yes' : 'No'}</p>
        ) : (
          <div className={actionArea}>
            <div className={hitbox}>
              <input type="checkbox" id={input.name} {...input} />
              <label
                htmlFor={input.name}
                onMouseUp={input.onBlur}
                onMouseDown={input.onFocus}
              >
                <IconTick />
              </label>
            </div>
          </div>
        )}

        {addon && addon({ name: input.name })}
      </div>
    </FormRow>
  )
}

const wrap = css`
  display: flex;
  align-items: stretch;
  width: 100%;
`

const stylesUnlocked = css`
  padding: 4px 0;
`

const lockedValue = css`
  font-size: 1.14em;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  flex-basis: 100px;
`

const actionArea = css`
  text-align: center;
  margin-right: 0px;
  padding-top: 0.6em;
  display: flex;
  justify-content: flex-end;
`

const hitbox = css`
  user-select: none;

  input[type='checkbox'] {
    display: none;
    + label {
      svg {
        display: block;
        opacity: 0;
        transform: scale(0);
        transition: all 200ms ease;
      }
    }
    &:checked + label {
      svg {
        display: block;
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  label {
    height: 22px;
    width: 22px;
    appearance: none;
    outline: 1px solid #939393;
    display: flex;
    position: relative;
    left: -3px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 100ms ease;
    &:hover {
      outline: 1px solid #008aab;
      transform: scale(1.1);
    }
    svg {
      height: 11px;
      width: 17px;
      display: none;
    }
  }
`

const stylesLocked = css`
  opacity: 0.5;
  .${hitbox} {
    pointer-events: none;
  }
`

TickCheckboxLeft.propTypes = {
  label: PropTypes.node.isRequired,
  labelWidth: PropTypes.string,
  labelAlign: PropTypes.string,
}

TickCheckboxLeft.defaultProps = {
  labelWidth: '100%',
  labelAlign: 'left',
}

function IconTick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13372.229 -2588.734 18.04 13.817"
    >
      <path
        id="Path_366"
        style={{ fill: 'none', stroke: '#717171', strokeWidth: '2px' }}
        d="M6573.779,3223.6l4.7,4.611,4.987-4.889,6.95-6.813"
        transform="translate(6799.15 -5804.524)"
      />
    </svg>
  )
}
