import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import AlignLabels from './AlignLabels'
import { css } from 'react-emotion'

const actionAreaStyles = css`
  flex: 1;
  textarea {
    height: 100px;
    padding: 5px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border: 1px solid #c7c7c7;
    font-size: 14px;
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }
  }
`

const subLabelStyles = css`
  font-size: 14px;
  color: #999;
  line-height: 2.6em;
`

const wrapStyles = css`
  width: 100%;
`

const verticalLabelRowStyles = css`
  margin: 0 auto 20px;
  display: flex;
  justify-content: space-between;
`

export default function TextAreaVertical(props) {
  const { input, meta = {}, label, subLabel, placeholder } = props

  return (
    <FormRow {...props}>
      <div className={wrapStyles}>
        {label && (
          <div className={verticalLabelRowStyles}>
            <AlignLabels width="100%">
              <Label title={label} vertical align="left" />
            </AlignLabels>
            <div className={subLabelStyles}>{subLabel}</div>
          </div>
        )}
        <div className={actionAreaStyles}>
          <textarea {...input} placeholder={placeholder} />
          <Feedback meta={meta} />
        </div>
      </div>
    </FormRow>
  )
}

TextAreaVertical.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  subLabel: PropTypes.string,
}

TextAreaVertical.defaultProps = {
  width: '100%',
  placeholder: '',
}
