import PropTypes from 'prop-types'
import * as React from 'react'
import { css, cx } from 'react-emotion'

export class SelectableItem extends React.Component {
  state = {
    hovering: false,
  }

  handleMouseEnter = () => {
    this.setState({
      hovering: true,
    })
  }

  handleMouseLeave = () => {
    this.setState({
      hovering: false,
    })
  }

  handleToggleSelection = () => {
    const { toggleSelection, id } = this.props
    if (typeof toggleSelection === 'function') {
      toggleSelection(id)
    }
  }

  /**
   * @type {import('react').KeyboardEventHandler}
   */
  handleToggleSelectionOnKeypress = e => {
    const key = e.key.toString().toUpperCase()

    if (key === 'ENTER' || e.key === ' ') {
      this.handleToggleSelection()
    }
  }

  render() {
    const {
      children,
      enabled,
      isSelected,
      isSelecting,
      isAtLimit,
      icon: Icon,
      iconSelected: IconSelected,
      color = 'inherit',
      colorSelected = 'inherit',
    } = this.props

    const { hovering } = this.state

    const isCheckBoxVisible = isSelecting || hovering

    const passToWrapperWhenEnabled = {
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
      onFocus: this.handleMouseEnter,
      onBlur: this.handleMouseLeave,
    }

    const inactiveStyle = css({ color })
    const selectedStyle = css({ color: colorSelected })

    return (
      <div
        css={cx(
          wrap,
          isSelecting && selectModeStyle,
          (isSelected || isAtLimit) && notAllowedStyle
        )}
        {...(enabled && passToWrapperWhenEnabled)}
      >
        <div
          css={cx(checkContainer, {
            [checkContainerVisible]: isCheckBoxVisible && !isAtLimit,
            [checkContainerActive]: isSelected,
            [checkContainerAtLimit]: isAtLimit,
            [inactiveStyle]: !isSelected,
            [selectedStyle]: isSelected,
          })}
          onClick={this.handleToggleSelection}
          onKeyPress={this.handleToggleSelectionOnKeypress}
          tabIndex={enabled ? 0 : -1}
        >
          {isSelected
            ? IconSelected && (
                <IconSelected fontSize="inherit" color="inherit" />
              )
            : Icon && <Icon fontSize="inherit" color="inherit" />}
        </div>
        {React.Children.only(children)}
      </div>
    )
  }
}
SelectableItem.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired,
  icon: PropTypes.any,
  iconSelected: PropTypes.any,
  id: PropTypes.any,
  isAtLimit: PropTypes.bool,
  isSelecting: PropTypes.bool,
  isSelected: PropTypes.bool,
  toggleSelection: PropTypes.func,
}

const selectModeStyle = css`
  transform: scale(0.85);
  opacity: 0.65;
  &:hover {
    cursor: copy;
  }
`
const notAllowedStyle = css`
  transform: scale(1);
  opacity: 1;
  &:hover {
    cursor: not-allowed;
  }
`

const checkContainer = css`
  height: 39px;
  width: 39px;
  font-size: 24px;
  border: 1px solid red;
  background-color: rgba(255, 255, 255, 0.82);
  position: absolute;
  z-index: 3;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(112, 112, 112, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.15s ease-in;
  transition: background-color 0.25s ease-in;
  &:hover {
    background-color: rgba(255, 255, 255, 0.92);
    transition: background-color 0.25s ease-in;
    transform: scale(1.08);
  }
  &:focus {
    outline: none !important;
  }
`

const checkContainerVisible = css`
  opacity: 1;
  transition: opacity 0.2s ease-out;
`

const checkContainerActive = css`
  background-color: #fff;
  opacity: 1;

  svg {
    opacity: 1;
    position: relative;
    bottom: -1px;
  }
`

const checkContainerAtLimit = css`
  pointer-events: none;
`

const wrap = css`
  height: 100%;
  user-select: none;
  position: relative;
`
