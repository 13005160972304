import * as React from 'react'
// import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'
// import { PixsyMenuItemSeparator } from '../PixsyMenuItem/PixsyMenuItemSeparator'
import COUNTRIES from 'pixsy-constants/countries.json'
import { sortBy, memoize } from 'lodash'

// export const CountryMenuItems = memoize(() => {
//   return [
//     <PixsyMenuItem key="0-0" value="">
//       Choose Country
//     </PixsyMenuItem>,
//     <PixsyMenuItemSeparator key="0-1" />,
//   ]
//     .concat(
//       sortBy(COUNTRIES.filter(c => c.resolution), 'displayName').map(
//         country => (
//           <PixsyMenuItem key={country.iso} value={country.country}>
//             {country.displayName}
//           </PixsyMenuItem>
//         )
//       )
//     )
//     .concat([<PixsyMenuItemSeparator key="0-2" />])
//     .concat(
//       sortBy(COUNTRIES.filter(c => !c.resolution), 'displayName').map(
//         country => (
//           <PixsyMenuItem key={country.iso} value={country.country}>
//             {country.displayName}
//           </PixsyMenuItem>
//         )
//       )
//     )
// })

const MAX_WIDTH = 20
const SEPARATOR = '─'.repeat(MAX_WIDTH)

export const CountryMenuItems = memoize(() => {
  return [
    <option key="choose" value="">
      Choose Country
    </option>,
    <option key="supported-separator" disabled>
      {SEPARATOR}
    </option>,
  ]
    .concat(
      sortBy(
        COUNTRIES.filter(c => c.resolution),
        'displayName'
      ).map(country => (
        <option key={country.iso} value={country.country}>
          {country.displayName}
        </option>
      ))
    )
    .concat([
      <option key="unsupported-separator" disabled>
        {SEPARATOR}
      </option>,
    ])
    .concat(
      sortBy(
        COUNTRIES.filter(c => !c.resolution),
        'displayName'
      ).map(country => (
        <option key={country.iso} value={country.country}>
          {country.displayName}
        </option>
      ))
    )
})
