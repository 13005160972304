import CheckIcon from '@material-ui/icons/Check'
import RefreshIcon from '@material-ui/icons/RefreshTwoTone'
import CloseIcon from '@material-ui/icons/Close'
import { SexyThumbnail } from 'common'
import isFunction from 'lodash/isFunction'
import React, { useCallback, useState } from 'react'
import Fade from '@material-ui/core/Fade'
import { css, cx } from 'react-emotion'
import { PixsyGrid, PixsyGridItem, PixsyIconButton } from '../../Components'

export const ImageItem = React.memo(
  ({
    active,
    index,
    isComplete,
    isFormReadOnly,
    isLoading,
    isRetry,
    onRemove,
    onRetry,
    onSelectImage,
    percentage,
    subTitle,
    thumbnailLabel,
    title,
    url,
    totalImages,
  }) => {
    const icon = isRetry ? (
      <RefreshIcon fontSize="large" />
    ) : isComplete ? (
      <CheckIcon fontSize="large" />
    ) : (
      undefined
    )

    const REMOVE_ICON_COLOR = 'rgba(230, 99, 99, 1)'

    const [focused, setFocus] = useState(false)

    const handleOnFocus = useCallback(() => setFocus(true), [])
    const handleOnBlur = useCallback(() => setFocus(false), [])
    const handleRetry = isRetry && !isFormReadOnly ? onRetry : null
    const handleSelectImage = useCallback(
      () => isFunction(onSelectImage) && onSelectImage(index),
      [index, onSelectImage]
    )

    const isInteractive = !isLoading && !isRetry

    return (
      <Fade in>
        <PixsyGridItem
          className={cx(
            imageItemStyle,
            isInteractive && imageItemInteractiveStyle
          )}
          tabIndex={0}
          xs={12}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onMouseEnter={handleOnFocus}
          onMouseLeave={handleOnBlur}
          flexGrow
          fullWidth
        >
          <PixsyGrid wrap="nowrap" spacing={2}>
            <PixsyGridItem className={thumbnailContainer} flexShrink={0}>
              <div className={cx(active && imageItemActive)}>
                <SexyThumbnail
                  url={url}
                  loading={isLoading}
                  loadingPercentage={percentage}
                  icon={icon}
                  iconLabel={thumbnailLabel}
                  onClick={isInteractive ? handleSelectImage : handleRetry}
                />
              </div>
            </PixsyGridItem>
            <PixsyGridItem
              className={detailsContainerStyle}
              onClick={isInteractive ? handleSelectImage : null}
              flexGrow={1}
            >
              <PixsyGrid
                direction="column"
                justify="center"
                className={detailsStyle}
              >
                <PixsyGridItem fullWidth>{title}</PixsyGridItem>
                <PixsyGridItem
                  fullWidth
                  className={cx(
                    colorGray,
                    isRetry && colorRed,
                    isLoading && colorBlue
                  )}
                >
                  {subTitle}
                </PixsyGridItem>
              </PixsyGrid>
            </PixsyGridItem>
            {!active && totalImages > 1 && (
              <Fade in={focused}>
                <PixsyGridItem>
                  <PixsyIconButton
                    icon={CloseIcon}
                    color={REMOVE_ICON_COLOR}
                    onClick={!isFormReadOnly ? onRemove : null}
                    disabled={isFormReadOnly}
                  />
                </PixsyGridItem>
              </Fade>
            )}
          </PixsyGrid>
        </PixsyGridItem>
      </Fade>
    )
  }
)

const imageItemStyle = css`
  border: 1px solid transparent;
`
const imageItemInteractiveStyle = css`
  cursor: pointer;
  &:focus,
  &:hover {
    outline: none !important;
    background-color: rgba(0, 138, 171, 0.1);
  }
`

const imageItemActive = css`
  outline: 3px inset rgba(255, 161, 104, 0.6);
`
const detailsContainerStyle = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
`
const detailsStyle = css`
  height: 100%;
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const thumbnailContainer = css`
  height: calc(60px + 16px); /* 8px spacing top/bottom */
  width: calc(90px + 16px); /* 8px spacing left/right */
  min-height: 68px;
  min-width: 98px;
`
const colorGray = css`
  color: rgba(59, 59, 59, 0.5);
`
const colorRed = css`
  color: #ff8181;
`
const colorBlue = css`
  color: #008aab;
`
