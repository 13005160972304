import React from 'react'
import { css } from 'react-emotion'

export default function NoResults() {
  return (
    <React.Fragment>
      <div css={NoResultsContainer}>
        <div>
          <IconCases />
          <h1>0 Cases</h1>
          <p>Your current selection didn‘t return any cases.</p>
        </div>
      </div>
    </React.Fragment>
  )
}

const NoResultsContainer = css`
  position: fixed;
  top: 15%;
  left: calc(10% + 110px);
  @media (max-width: 1280px) {
    left: calc(10% + 95px);
  }
  width: calc(80% - 110px);
  height: 70%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    min-width: 100px;
    opacity: 0.25;
  }
  @media (max-width: 768px) {
    left: 0;
    width: 100%;
    svg {
      max-height: 130px;
    }
    h1 {
      font-size: 38px;
    }
  }
`

function IconCases() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="5523.445 -5828.071 154.61 199.275"
      width={36}
    >
      <defs>
        <style>{`.svg-navigation-cases-1 {fill: #939393;}`}</style>
      </defs>
      <g id="search" transform="translate(5516.945 -5828.071)">
        <path
          id="Path_36"
          data-name="Path 36"
          className="svg-navigation-cases-1"
          d="M159.217,41.872l-2.649-2.649L121.89,4.542l-2.649-2.649A6.414,6.414,0,0,0,114.675,0H14.962A8.468,8.468,0,0,0,6.5,8.462v182.35a8.468,8.468,0,0,0,8.462,8.462H152.647a8.47,8.47,0,0,0,8.462-8.462V46.434A6.4,6.4,0,0,0,159.217,41.872ZM119.881,12.249l28.981,28.981H119.881Zm34.358,178.564a1.594,1.594,0,0,1-1.591,1.591H14.962a1.594,1.594,0,0,1-1.591-1.591V8.462a1.594,1.594,0,0,1,1.591-1.591h98.246a5.294,5.294,0,0,0-.2,1.347V48.1h39.882a5.34,5.34,0,0,0,1.347-.2Z"
          transform="translate(0 0)"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          className="svg-navigation-cases-1"
          d="M77.952,80.465A37.808,37.808,0,1,0,72.468,84.7l35.8,35.8a3.435,3.435,0,0,0,4.858-4.858Zm-25.658,3.25A30.922,30.922,0,1,1,83.215,52.794,30.955,30.955,0,0,1,52.294,83.715Z"
          transform="translate(19.486 36.537)"
        />
        <path
          id="Path_38"
          data-name="Path 38"
          className="svg-navigation-cases-1"
          d="M42.55,21H21.936a3.436,3.436,0,1,0,0,6.872H42.55a3.436,3.436,0,0,0,0-6.872Z"
          transform="translate(29.229 51.151)"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          className="svg-navigation-cases-1"
          d="M30.936,27.872h6.872a3.436,3.436,0,1,0,0-6.872H30.936a3.436,3.436,0,1,0,0,6.872Z"
          transform="translate(51.151 51.151)"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          className="svg-navigation-cases-1"
          d="M31.807,29H24.936a3.436,3.436,0,1,0,0,6.872h6.872a3.436,3.436,0,1,0,0-6.872Z"
          transform="translate(36.537 70.637)"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          className="svg-navigation-cases-1"
          d="M37.807,29H30.936a3.436,3.436,0,1,0,0,6.872h6.872a3.436,3.436,0,1,0,0-6.872Z"
          transform="translate(51.151 70.637)"
        />
        <path
          id="Path_42"
          data-name="Path 42"
          className="svg-navigation-cases-1"
          d="M39.115,28.436A3.433,3.433,0,0,0,35.679,25H21.936a3.436,3.436,0,1,0,0,6.872H35.679A3.433,3.433,0,0,0,39.115,28.436Z"
          transform="translate(29.229 60.894)"
        />
        <path
          id="Path_43"
          data-name="Path 43"
          className="svg-navigation-cases-1"
          d="M47.115,25H29.936a3.436,3.436,0,1,0,0,6.872H47.115a3.436,3.436,0,1,0,0-6.872Z"
          transform="translate(48.715 60.894)"
        />
      </g>
    </svg>
  )
}
