import { reduxForm } from 'redux-form'
import { register } from '../../../redux/modules/auth'
import { withRouter } from 'react-router'
import { asyncBlurFields, asyncValidate, fields, initialValues } from './fields'
import RegisterForm from './RegisterForm'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

function validate(values) {
  return Object.values(fields).reduce(
    (obj, { name, validate = () => null }) => {
      const entry = validate(values[name])
      return entry ? { ...obj, [name]: entry } : obj
    },
    {}
  )
}

export default withGoogleReCaptcha(
  withRouter(
    reduxForm({
      form: 'register', // a unique identifier for this form
      validate,
      initialValues,
      asyncValidate,
      asyncBlurFields,
      onSubmit: async (
        values,
        dispatch,
        { location, match, history: { replace }, googleReCaptchaProps }
      ) => {
        const ppaSignup = match.path === '/register/ppa'
        const smugmugSignup = match.path === '/register/smugmug'
        let cherrydeckSignupKey = null
        if (location.pathname.includes('cherrydeck')) {
          const parts = location.pathname.split('/')
          const key = parts.indexOf('cherrydeck')
          cherrydeckSignupKey = parts[key + 1]
        }

        const $recaptcha = googleReCaptchaProps
          .executeRecaptcha('login')
          .catch(console.error)

        const recaptcha = await $recaptcha

        replace({ ...location, state: { redirect: '/images' } })

        // dispatch(register) will resolve promise with undefined
        // irrespective of successful or failed register
        await dispatch(
          register({
            ...values,
            cherrydeckSignupKey,
            ppaSignup,
            smugmugSignup,
            recaptcha,
          })
        )
      },
    })(RegisterForm)
  )
)
