import React from 'react'
import { Button } from 'common'
import { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { withRouter } from 'react-router'
import { hideTopMatchModal } from '../../../redux/modules/users'

function TopMatchModalComponent({ user, hideModal, viewTopMatches }) {
  return (
    <div css={content}>
      <h1>New Feature: Top Matches!</h1>
      <div onClick={hideModal} css={closeButton}>
        x
      </div>

      <div
        css={svgContainer}
        dangerouslySetInnerHTML={{
          __html: require(`../../Matches/V2/tmFill.svg`),
        }}
      />

      <p>
        Top Matches spotlights your most valuable and commercial matches. This
        NEW AI-powered super smart filter should be the first place you look
        for&nbsp;
        <a href="https://support.pixsy.com/en/articles/70" target="_blank">
          viable cases
        </a>
        &nbsp; to submit to Pixsy.&nbsp;
        <a href="https://support.pixsy.com/en/articles/181" target="_blank">
          Learn more about Top Matches
        </a>
        .
      </p>

      <p>
        Need help reviewing your matches? Feel free to{' '}
        <a href="mailto:support@pixsy.com" target="_blank">
          contact us
        </a>{' '}
        at any time!
      </p>

      <div css={buttons}>
        <Button hoverMain onClick={viewTopMatches}>
          Check Your Top Matches
        </Button>
      </div>
    </div>
  )
}

const TopMatchModal = compose(
  connect(null, { _hideModal: hideTopMatchModal }),
  withRouter,
  withHandlers({
    hideModal: props => () => {
      props.onComplete()
      props._hideModal()
    },
    track: props => () => {
      try {
        window.mixpanel.track('Matches.Display.Top', {
          source: 'modal',
        })

        window.gtag('event', 'clickTopMatches', {
          topMatchesClicked: 1,
          event_category: 'matches',
          event_label: 'Top Matches clicked',
        })
      } catch (e) {
        console.error(e)
      }
    },
  }),
  withHandlers({
    viewTopMatches: props => () => {
      props.hideModal()
      props.track()
      const { history } = props

      history.push({
        pathname: '/matches/top',
      })
    },
  })
)(TopMatchModalComponent)

TopMatchModal.delay = 60 * 1000

TopMatchModal.eligible = user =>
  user &&
  // user must be at least 30 minutes old,
  // so it doesn’t show before top matches have been evaluated
  new Date() - new Date(user.created) > 30 * 60 * 1000 &&
  !(
    user.photographerFields &&
    user.photographerFields.campaigns &&
    user.photographerFields.campaigns.topMatchModalSeen
  ) &&
  !global.E2E

export default TopMatchModal

const closeButton = css`
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
  border-width: 0px;
  padding: 5px;
`

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
`

const svgContainer = css`
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ff7f2f;

  svg {
    width: 80px;
    height: 80px;
    fill: currentColor;
  }
`

const buttons = css`
  display: flex;
  justify-content: center;
  flex-direction: row;

  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
