import React from 'react'

export const SubmissionValidationDialogCC0 = () => (
  <div>
    <h6>
      We have detected that this image was published under a CC0 license, and
      therefore is not a{' '}
      <span data-elevio-article="70">viable commercial case.</span>
    </h6>
    <p>
      We are only able to accept cases where the image was published under a
      rights managed license and was not public domain/CC0.
    </p>

    <p>
      We recommend that you <b>send a Takedown Notice</b> for this match,
      requesting the image is removed by the website operator or host.
    </p>

    <p>
      Pixsy uses a set of advanced algorithms to pre-determine if a case is
      suitable for resolution. We do this to save you time, and to ensure that
      our case management team can focus their efforts on recovering
      compensation for viable cases.
    </p>
    <p>
      If you believe this domain has been blocked but the case is still viable,
      you can contact us and we can further evaluate the case for you.
    </p>
  </div>
)
