import { actionCreators } from '../store'

const scrollToClusterInList = (dispatch, scrollToClusterId) => {
  setTimeout(() => {
    const element = document.querySelector(`[data-clusterid="${scrollToClusterId}"]`)
    element && element.scrollIntoView({ behavior: 'smooth' })
    dispatch(actionCreators.resetScrollToClusterId())
  }, 0)

  return null
}

export default scrollToClusterInList
