import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'

function CollapsedLink({ url, secondary, additionalStyle }) {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(true)
  }

  const { hostname, pathname } = new URL(url)

  const host = hostname.replace(/^www\./, '')

  const additionalSegments = pathname.split('/').filter(Boolean)

  const lastSegment = additionalSegments.pop()

  const hasAdditionalSegments = additionalSegments.length > 0

  const segments = [
    <a href={url} target="_blank" rel="noreferrer" key="host">
      {host + (hasAdditionalSegments ? '/' : '')}
    </a>,
  ]

  if (hasAdditionalSegments) {
    segments.push(<Spacer key="spacer" onClick={handleClick} />)
    segments.push(
      <a href={url} target="_blank" rel="noreferrer" key="last">
        {'/' + lastSegment}
      </a>
    )
  } else if (lastSegment) {
    segments.push(
      <a href={url} target="_blank" rel="noreferrer" key="last">
        {'/' + lastSegment}
      </a>
    )
  }

  return expanded ? (
    <a href={url} title={url} target="_blank" css={cx(style, additionalStyle, secondary && fadedStyle)}>
      {url}
    </a>
  ) : (
    <div title={url} target="_blank" css={cx(style, additionalStyle, secondary && fadedStyle)}>
      {segments}
    </div>
  )
}
export default React.memo(CollapsedLink)

function Spacer({ onClick }) {
  return (
    <div onClick={onClick} style={{ display: 'inline-block', margin: '0 4px', cursor: 'pointer' }}>
      <svg width="24" height="10" style={{ backgroundColor: '#939393' }}>
        <circle cx="6" cy="5" r="2" style={{ fill: '#ffffff' }} />
        <circle cx="12" cy="5" r="2" style={{ fill: '#ffffff' }} />
        <circle cx="18" cy="5" r="2" style={{ fill: '#ffffff' }} />
      </svg>
    </div>
  )
}

const style = css`
  color: #00758d;
`

const fadedStyle = css`
  filter: opacity(0.5);
`

CollapsedLink.propTypes = {
  url: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
}

CollapsedLink.defaultProps = {
  secondary: false,
}
