import * as React from 'react'
import { css, cx } from 'react-emotion'

export const Left = ({ children, ignored }) => <div className={cx(columnLeft, ignored && ignore)}>{children}</div>
export const Middle = ({ children, ignored }) => <div className={cx(columnMiddle, ignored && ignore)}>{children}</div>
export const Right = ({ children }) => <div className={cx(rightColumn)}>{children}</div>

const ignore = css`
  opacity: 0.2;
  /* pointer-events: none; */
`
const columnLeft = css`
  margin-left: 32px;
  margin-top: 0px;
  max-width: 242px;
  min-width: 242px;

  @media (max-width: 1440px) {
    max-width: 171px;
    min-width: 171px;
  }

  h1 {
    color: rgba(0, 0, 0, 0.3);

    font-size: 0.8em;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 10px;
    margin: 0 0 8px 0;
    text-transform: uppercase;

    + span {
      color: #000;
      display: block;
      font-size: 1.3em;
      font-weight: 100;
      line-height: 20px;
      margin-bottom: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      svg {
        margin-right: 2px;
      }
    }
  }

  @media (max-width: 768px) {
    position: relative;
    z-index: 1;
  }
`
const columnMiddle = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 62.5px;
  margin-top: 29.26px;
  min-width: 0px; /* Magic! */

  h1 {
    color: rgba(0, 0, 0, 0.3);
    font-size: 0.8em;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 10px;
    margin: 0 0 8px 0;
    text-transform: uppercase;

    + span {
      color: #000;
      display: block;
      font-size: 1.1em;
      line-height: 20px;
      margin-bottom: 21px;
      min-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  a {
    color: rgba(0, 0, 0, 0.7);
    direction: ltr;
    display: inline-flex;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 21px;
    min-height: 20px;
    width: 100%;

    > span {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    > span:first-child {
      overflow: visible;
    }
  }

  @media (max-width: 768px) {
    position: relative;
    z-index: 1;
  }
`
const rightColumn = css`
  border-left: 1px solid #dbdbdb;
  height: 158px;
  margin-top: 36px;
  min-width: 175px;
  padding-left: 34px;

  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
    padding-left: calc(325.67px + 32px);
    border: none;
    width: 100%;
    height: auto;
    z-index: 2;
  }
`
