import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Field } from 'redux-form'
import { compose, withProps } from 'recompose'
import {
  AlignLabels,
  ContentSection,
  ClosedQuestionRadio,
  OffSectionTooltip,
  TypeOfWorkSelect,
} from 'common'
import { PHASE } from '../../../../../../shared/forms/registrationSubmission'

function SubmissionFormOverview({
  phase,
  getFieldPropsFor,
  onWaypoint,
  labels,
  subLabels,
  values,
}) {
  return (
    <ContentSection id="overview">
      <Waypoint onEnter={onWaypoint} />
      <h1>Overview</h1>

      <AlignLabels>
        <Field
          component={TypeOfWorkSelect}
          {...getFieldPropsFor('REGISTRATION_WORK_TYPE')}
          float="right"
          disabled={phase === PHASE.FINAL}
          multi={values.usco === 'USCO_MULTI'}
        />
      </AlignLabels>

      <OffSectionTooltip>
        If your work was made publicly accessible online (for licensing or for
        display on a portfolio site, such as Flickr), Pixsy recommends
        registering your works as <i>published</i>
      </OffSectionTooltip>
      <Field
        component={ClosedQuestionRadio}
        {...getFieldPropsFor('REGISTRATION_IS_PUBLISHED')}
        label={labels.registrationIsPublished}
        subLabel={subLabels.registrationIsPublished}
        locked={phase === PHASE.FINAL}
      />

      <Field
        component={ClosedQuestionRadio}
        {...getFieldPropsFor('REGISTRATION_CLIENT_IS_RIGHTS_HOLDER')}
        locked={phase === PHASE.FINAL}
      />
    </ContentSection>
  )
}

export default compose(
  withProps(props => ({
    labels: {
      registrationIsPublished: props.isBulkSubmission
        ? 'Has your work been published?'
        : 'Has your work been published?',
    },
    subLabels: {
      registrationIsPublished:
        props.isBulkSubmission &&
        'Please note! All images must be first published in the same calendar year!',
    },
  }))
)(SubmissionFormOverview)

SubmissionFormOverview.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  getFieldPropsFor: PropTypes.func.isRequired,
  registrationId: PropTypes.string,
  phase: PropTypes.string.isRequired,
  isBulkSubmission: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    registrationIsPublished: PropTypes.string.isRequired,
  }),
}
