import React, { useCallback, useEffect, useState } from 'react'
import { css } from 'react-emotion'
import { connect } from 'react-redux'
import GroupMatchList from './GroupMatchList'
import GroupMatchFilters from './Filters'
import { actionCreators } from '../../store'
import useClusterCache from '../../utils/useClusterCache'
import { useMatchViewerStore } from '../../store/context'

const GroupMatchListView = () => {
  const { state, dispatch } = useMatchViewerStore()
  const clusterId = state.groupMatchListFilters.clusterId
  const cluster = useClusterCache(clusterId)
  const [filteredCluster, setFilteredCluster] = useState(cluster)
  const [filters, setFilters] = useState({
    similarity: null,
    image: null,
    date: null,
    action: null,
  })

  useEffect(() => {
    if (!filteredCluster && cluster) setFilteredCluster(cluster)
  }, [!!cluster, !!filteredCluster])

  useEffect(() => {
    if (cluster) {
      const matches = Object.values(filters)
        .filter(Boolean)
        .reduce(
          (acc, fn) => {
            return acc.filter(fn)
          },
          cluster.matches.filter((m) => !m.invalid)
        )
      setFilteredCluster({
        ...cluster,
        matches,
      })
    }
  }, [filters, cluster])

  const handleExitGroupView = useCallback(() => {
    return dispatch(actionCreators.setGroupMatchesViewClose(clusterId))
  }, [dispatch])

  // NOTE: We can't always control when the component is navigated out from (e.g. when clicking on a <Link> item to a
  //       different part of the application). In order to keep the store in sync with these navigations, we must ensure
  //       the data is cleared from the store when the component is unmounted.
  useEffect(() => {
    return () => {
      handleExitGroupView()
    }
  }, [dispatch, handleExitGroupView])

  const handleOpenMoreOnGroupRequest = () => {
    // TODO: Show more on group view modal
    dispatch(actionCreators.setMoreModalViewOpen(cluster._id, null, cluster))
  }

  const handleTakeActionOnGroupRequest = () => {
    dispatch(actionCreators.setActionsModalViewOpen(cluster._id, null, cluster, true))
  }

  useEffect(() => {
    if (clusterId) {
      // When server rendering page, mixpanel may not be available yet.
      window.setTimeout(() => {
        try {
          window.mixpanel.track('Matches.mv3.groupView', {
            clusterId,
          })
        } catch (e) {
          console.error(`Unable to invoke mixpanel method:`, e)
        }
      })
    }
  }, [clusterId])

  return (
    <div css={styles.container}>
      <GroupMatchFilters
        dispatch={dispatch}
        cluster={cluster}
        filteredCluster={filteredCluster}
        filters={filters}
        setFilters={setFilters}
        onExitGroupView={handleExitGroupView}
      />
      <GroupMatchList
        cluster={filteredCluster}
        clusterId={clusterId}
        onOpenMoreOnGroupRequest={handleOpenMoreOnGroupRequest}
        onTakeActionOnGroupRequest={handleTakeActionOnGroupRequest}
      />
    </div>
  )
}

const styles = {
  container: css`
    position: relative;
    display: grid;
    grid-template-columns: 320px 1fr;
    gap: 15px;
    max-width: 1600px;
    margin: 0 auto;
    font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
  `,
}

export default connect(null, (globalDispatch) => ({ globalDispatch }))(GroupMatchListView)
