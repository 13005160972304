import React from 'react'
import PropTypes from 'prop-types'
import { ContentSection, Button } from 'common'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'

export default function TakedownSuccess({ remainingQuota, location, history }) {
  const lightning = require(`../takedownIcon.svg`)
  const matches = require(`../matchesIcon.svg`)
  const { lastLocation } = location.state || {}

  return (
    <ContentSection>
      <div css={wrap}>
        <div css={svg} dangerouslySetInnerHTML={{ __html: lightning }} />
        <h1>Takedown notice sent!</h1>
        <p>
          Please note the host may email you directly with additional
          information or steps - the email contains all the information they
          need to process your request. As Pixsy is not the copyright holder of
          this photo, any follow ups must be managed by you directly. If the
          reply is not in your native language, we suggest you send the reply in
          English and, using a translation tool, the other language of
          correspondence.
        </p>
        {isFinite(remainingQuota) && (
          <p>This month you have {remainingQuota} Takedowns left to send.</p>
        )}
        <Link to={lastLocation || '/matches/'}>
          <Button>
            <i dangerouslySetInnerHTML={{ __html: matches }} />
            Review more matches{' '}
          </Button>
        </Link>
      </div>
    </ContentSection>
  )
}

TakedownSuccess.propTypes = {
  remainingQuota: PropTypes.number,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const wrap = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-takedown-1 {
    display: none;
  }

  p {
    color: #434343cc;
    line-height: 2em;
  }

  button {
    margin-top: 20px;
    height: 46px;
    line-height: 46px;
    padding-top: 0;
    padding-bottom: 0;

    svg {
      height: 45px;
      width: 25px;
      vertical-align: -17px;
      margin-right: 18px;
      path {
        fill: #fff;
      }
    }

    @media (max-width: 375px) {
      line-height: 1.5em;
      padding-top: 8px;
      padding-bottom: 16px;
    }

    @media (max-width: 768px) {
      height: auto;
    }
  }
`

const svg = css`
  svg {
    height: 95px;
  }

  /* hide frame */

  path {
    fill: #148aa9;
  }
`
