import React, { useEffect } from 'react'

import { withMatchViewerContext, actionCreators, helpers } from '../store'

const withMatchesHistoryBackHandler = WrappedComponent => {
  function ComponentWithMatchesHistoryBackHandler(props) {
    const { dispatch, history } = props

    const handleBackState = () => {
      const contextState = helpers.parseLocationForSelectedContextState(
        history.location
      )

      dispatch(actionCreators.updateSelectedContextState(contextState))
    }

    useEffect(() => {
      window.addEventListener('popstate', handleBackState)
      return () => window.removeEventListener('popstate', handleBackState)
    }, [])

    return <WrappedComponent {...props} />
  }

  return withMatchViewerContext(
    ComponentWithMatchesHistoryBackHandler,
    state => ({ history: state.routerHistory })
  )
}

export default withMatchesHistoryBackHandler
