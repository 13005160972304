import { memoize } from 'lodash'
import * as React from 'react'
// import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'
// import { PixsyMenuItemSeparator } from '../PixsyMenuItem/PixsyMenuItemSeparator'

const MAX_WIDTH = 5
const SEPARATOR = '─'.repeat(MAX_WIDTH)

const TITLE_ITEMS = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: `Mrs.` },
  { value: 'Miss.', label: `Miss.` },
  { value: 'Ms.', label: `Ms.` },
]
export const TitleMenuItems = memoize(() => {
  return [
    <option key="select" value="" disabled>
      Select
    </option>,
    <option key="options" disabled>
      {SEPARATOR}
    </option>,
  ].concat(
    TITLE_ITEMS.map(({ value, label }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))
  )
})
// export const TitleMenuItems = memoize(() => {
//   return [
//     <PixsyMenuItem key="0-0" value="" disabled>
//       Select
//     </PixsyMenuItem>,
//     <PixsyMenuItemSeparator key="0-1" />,
//   ].concat(
//     TITLE_ITEMS.map(({ value, label }) => (
//       <PixsyMenuItem key={value} value={value}>
//         {label}
//       </PixsyMenuItem>
//     ))
//   )
// })
