import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Switch, Route, Redirect, withRouter } from 'react-router'
import MatchesOverview from './Overview/Overview'
import MatchesContainer from './MatchesContainer'

function Matches({ location, history }) {
  useEffect(() => {
    const pathname = location.pathname

    if (pathname.startsWith('/matches/v3/')) {
      history.replace(pathname.replace(/^\/matches\/v3/, '/matches'))
    }
  }, [location.pathname, history])

  return (
    <React.Fragment>
      <Helmet title="Matches" />

      <Switch>
        <Route path="/matches/overview" component={MatchesOverview} />
        <Route path="/matches/:tags*" component={MatchesContainer} />
        <Redirect from="/matches" to="/matches/overview" />
      </Switch>
    </React.Fragment>
  )
}

export default withRouter(Matches)

Matches.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}
