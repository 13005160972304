import { mergeWith, uniq, cloneDeep } from 'lodash'

const isObjectId = str => /[a-f\d]{24}/.test(str)
const defaultEntityState = {
  users: {},
  images: {},
  matches: {},
  clusters: {},
  cases: {},
  plans: {},
  history: {},
  rules: {},
  takedowns: {},
  domains: {},
  registrations: {},
}

export default function entities(_state = defaultEntityState, action) {
  var state = _state
  const payload = action.payload

  if (
    payload &&
    payload.invalidate &&
    action.type &&
    action.type.endsWith('SUCCESS')
  ) {
    Object.entries(payload.invalidate).forEach(([collection, ids]) => {
      const newCollection = { ...state[collection] }

      ids.forEach(id => {
        delete newCollection[id]
      })

      state = {
        ...state,
        [collection]: newCollection,
      }
    })
  }

  if (payload && payload.entities) {
    const newState = cloneDeep(state)
    // Custom array merge to overwrite
    // array of strings (tags) but merge array of objects (entities)
    return mergeWith({}, newState, payload.entities, (oV, sV) => {
      if (Array.isArray(oV)) {
        if (!payload.invalidate && isObjectId(oV[0])) {
          return uniq([...oV, ...sV])
        } else {
          return sV
        }
      }
    })
  }

  return state
}
