import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'common'
import { css } from 'react-emotion'

const matchEmptyCont = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const emptyStateCard = css`
  background-color: white;
  display: inline-block;
  width: 320px;
  height: 420px;
  padding: 25px 5px;
  margin-top: 50px;
  margin-bottom: 35px;
  border: solid 1px rgb(240, 240, 240);
  h3 {
    font-size: 1.75em;
    margin: 0 0 15px 0;
  }
  p {
    margin: 0 0 15px 0;
    padding: 0 30px;
    opacity: 0.75;
  }
  ul {
    text-align: left;
    padding: 0 30px 0 40px;
    width: 100%;
  }
  li {
    padding-bottom: 10px;
    color: #008aab;
    span {
      color: rgb(40, 40, 40);
      font-size: 1em !important;
      opacity: 0.75;
    }
  }

  @media (max-width: 768px) {
    margin-top: 5px;
    margin-bottom: 5px;
    &:nth-child(1) {
      margin-top: 50px;
    }
  }
`

const emptyStateFlowIcon = css`
  width: 25px;
  margin: 0 25px;

  @media (max-width: 768px) {
    transform: rotate(90deg);
  }
`
const emptyStateCardPic = css`
  width: 100%;
  padding-bottom: 49.99%;
  position: relative;
  margin: 10px 0 15px 0;
  svg {
    position: absolute;
    top: 0;
    left: 15%;
    max-width: 70%;
    height: 100%;
  }
`

const mobileImportBtn = css`
  @media (max-width: 768px) {
    display: block;
    margin: 60px 0;
  }
`

export default function EmptyState() {
  return (
    <React.Fragment>
      <h1>Import images and Pixsy will start scanning for matches</h1>
      <div css={matchEmptyCont}>
        <div css={emptyStateCard}>
          <IconOne />
          <h3>Search & Filter</h3>
          <ul>
            <li>
              <span>
                Smart filters allow you to customize and sort your matches to
                find what’s important to you.
              </span>
            </li>
            <li>
              <span>
                Filter and query by country, category, commerciality, online
                status, and much more.
              </span>
            </li>
          </ul>
        </div>
        <FlowIcon />
        <div css={emptyStateCard}>
          <IconTwo />
          <h3>Review Matches</h3>
          <ul>
            <li>
              <span>
                Matches are automatically grouped by domain and populated with
                key information points.
              </span>
            </li>
            <li>
              <span>
                Our viewer and keyboard shortcuts allow you to efficiently
                review all the results.
              </span>
            </li>
          </ul>
        </div>
        <FlowIcon />
        <div css={emptyStateCard}>
          <IconThree />
          <h3>Take Action</h3>
          <ul>
            <li>
              <span>
                For any unauthorized use, take action either one by one, or in
                bulk.
              </span>
            </li>
            <li>
              <span>
                Submit a Case to Pixsy for resolution, or send a legal Takedown
                notice.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <NavLink to="/images/import" css={mobileImportBtn}>
        <Button>Import Images</Button>
      </NavLink>
    </React.Fragment>
  )
}

function IconOne() {
  const imageIcon1 = require('./MatchBlock.svg')
  return (
    <div
      css={emptyStateCardPic}
      dangerouslySetInnerHTML={{ __html: imageIcon1 }}
    />
  )
}

function IconTwo() {
  const imageIcon2 = require('./ReviewBlock.svg')
  return (
    <div
      css={emptyStateCardPic}
      dangerouslySetInnerHTML={{ __html: imageIcon2 }}
    />
  )
}

function IconThree() {
  const imageIcon3 = require('./TakeActionBlock.svg')
  return (
    <div
      css={emptyStateCardPic}
      dangerouslySetInnerHTML={{ __html: imageIcon3 }}
    />
  )
}

function FlowIcon() {
  const imageIconFlow = require('./FlowNext.svg')
  return (
    <div
      css={emptyStateFlowIcon}
      dangerouslySetInnerHTML={{ __html: imageIconFlow }}
    />
  )
}
