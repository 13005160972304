import React, { Component } from 'react'
import { css } from 'react-emotion'
import { format } from 'currency-formatter'

export default class PriceTag extends Component {
  renderPrice() {
    const { value, currency, originalPrice } = this.props
    return (
      <React.Fragment>
        {originalPrice && originalPrice > 0 && originalPrice > value && (
          <strike>
            <em css={em} style={{ display: 'block' }}>
              {format(originalPrice, {
                format: '%s %v',
                code: currency,
                thousand: ',',
                decimal: '.',
              })}
            </em>
          </strike>
        )}
        <em css={em}>
          {format(value, {
            format: '%s %v',
            code: currency,
            thousand: ',',
            decimal: '.',
          })}
        </em>
      </React.Fragment>
    )
  }

  render() {
    const { value, currency } = this.props
    if (typeof value !== 'number') {
      return '-'
    }

    return (
      <span>
        {this.renderPrice()}&nbsp;<span css={span}>{currency}</span>
      </span>
    )
  }
}

const span = css`
  color: #adadad;
  letter-spacing: 1px;
`

const em = css`
  font-style: normal;
  letter-spacing: normal;
  color: #434343;
  font-weight: 600;
  font-size: 1.7em;
  padding-right: 2px;
`
