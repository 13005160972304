import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'

export default function OffSectionTooltip({ children, verticalOffset = 0 }) {
  return (
    <div css={anchor}>
      <Content
        verticalOffset={verticalOffset}
        className="off-section-tooltip--content"
      >
        {children}
      </Content>
    </div>
  )
}

OffSectionTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  verticalOffset: PropTypes.number,
}

const anchor = css`
  position: relative;
  z-index: 1;

  .off-section-tooltip--content {
    transition: opacity 200ms ease-in-out;
  }
`

const Content = styled.aside`
  position: absolute;
  width: 200px;
  text-align: left;
  top: ${props => props.verticalOffset}px;
  right: -255px;
  padding: 10px 20px 0 5px;
  font-size: 0.9em;
  line-height: 1.75em;
  @media (max-width: 1280px) {
    position: relative;
    width: auto;
    left: 0;
    top: 0;
    color: rgb(150, 150, 150);
    font-size: 0.8em;
    padding: 5px 30px 10px 30px;
    margin: 0;
  }

  @media(max-width: 768px) {
    padding: 0;
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 5px;
    }
  }
`
