import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'common'
import RemoveServiceDialog from './RemoveServiceDialog'
import { connect } from 'react-redux'
import { synchronize, removeService } from '../../../../redux/modules/images'
import { notifSend } from 'redux-notifications/lib/actions'
import { humanize } from 'pixsy-constants'

export class ModalImport extends Component {
  constructor({ service, blurred }) {
    super()
    const DISABLED_IMPORTS = global.DISABLED_IMPORTS || ''
    this._disabled = blurred || DISABLED_IMPORTS.split(',').includes(service)
  }

  state = { element: null }

  open = () => {
    const { source } = this.props
    const connected = Boolean(source && source.id)
    !connected && this.handleConfig(false)
  }

  close = () => {
    this.setState({ element: null })
  }

  handleConfig = (connected = true) => {
    // the configurable logic should be better thought if other services use it as well
    // conditions were used now to avoid passing props that do not exist in other dialogs
    // because only the FlickrImportDialogContent has the connected prop
    const { component: Component, configurable = false } = this.props
    this.setState({
      element: configurable ? (
        <Component connected={connected} />
      ) : (
        <Component />
      ),
      requestRemove: false,
    })
  }

  handleUpdate = e => {
    e.stopPropagation && e.stopPropagation()
    const { synchronize, service, source, notifSend } = this.props

    synchronize(service, source.id)
    notifSend({
      message: `Importing images from ${humanize(service)}.`,
      kind: 'success',
      dismissAfter: 6e3,
    })
  }

  handleRemove = e => {
    e.stopPropagation()
    this.setState({ requestRemove: true })
  }

  removeService = () => {
    const { removeService, service } = this.props
    removeService(service)
    this.setState({ requestRemove: false })
  }

  render() {
    const { element, requestRemove } = this.state
    const {
      children,
      source,
      synchronize,
      component,
      loading,
      loadingService,
      configurable = false,
      componentProps,
    } = this.props
    const connected = Boolean(source && source.id)

    const card = connected
      ? React.cloneElement(children, {
          connected,
          onUpdate: this.handleUpdate,
          onRemove: this.handleRemove,
          onConfig: configurable && this.handleConfig,
          loading,
          loadingService,
        })
      : children

    // Remove me once all modal imports have components
    if (!component) return card
    return (
      <React.Fragment>
        <Dialog
          isOpen={requestRemove}
          onRequestClose={() => {
            this.setState({ requestRemove: false })
          }}
        >
          <RemoveServiceDialog
            onCancel={() => {
              this.setState({ requestRemove: false })
            }}
            onConfirm={this.removeService}
          />
        </Dialog>
        <Dialog
          isOpen={!this._disabled && Boolean(element)}
          onRequestClose={this.close}
        >
          {element &&
            React.cloneElement(element, {
              onClose: this.close,
              synchronize,
              initialValues: {
                id: connected ? source.id : '',
              },
              ...componentProps
            })}
        </Dialog>

        <div onClick={this.open}>{card}</div>
      </React.Fragment>
    )
  }
}

export default connect(
  (state, { service }) => ({
    source: state.auth.user.sources[service],
    loading: state.images.loading,
    loadingService: state.images.loadingService,
  }),
  { synchronize, removeService, notifSend }
)(ModalImport)

ModalImport.propTypes = {
  component: PropTypes.func,
  source: PropTypes.object,
  loading: PropTypes.bool,
}
