import React from 'react'

export default class ScrollToTop extends React.Component {
  componentWillReceiveProps({ whenChanges, offset }) {
    if (this.props.whenChanges !== whenChanges) {
      window.scrollTo(0, offset)
    }
  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  render() {
    return null
  }
}

ScrollToTop.defaultProps = {
  offset: 0,
}
