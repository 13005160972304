// 6. Image & Match
import Fade from '@material-ui/core/Fade'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import { Button, ImageComparison, SexyThumbnail, TooltipContext } from 'common'
import produce from 'immer'
import isPlainObject from 'lodash/isPlainObject'
import { US_USCO_STATUS_ENUM } from 'pixsy-schema/case/RegistrationSchema'
import { IMAGE_INDEX, MATCH_INDEX, SUBMISSION_NAMES as NAMES } from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import { css } from 'react-emotion'
import { withRouter } from 'react-router'
import {
  PixsyErrorMessage,
  PixsyFileList,
  PixsyFormGroup,
  PixsyGrid,
  PixsyGridItem,
  PixsyPaper,
  UppyDragDrop,
} from '../../../Components'
import { getImageUrl } from '../../util/images'
import { AdditionalLinksList } from './AdditionalLinksList'
import { Checkbox } from './Checkbox'
import { CombinedTextField } from './CombinedTextField'
import imagesStageTooltipContent from './imagesStageTooltipContent'
import { PriceCurrency } from './PriceCurrency'
import { Question } from './Question'
import { SingleTextField } from './SingleTextField'
import { ImageInformation } from './ImageInformation'
import DeleteDraftCase from '../../../../Table/DeleteDraft'
/* eslint-disable react/no-this-in-sfc */
/**
 * @augments {React.Component<import('../StageProps').IProps, {}>}
 */

class _DetailsStage extends React.Component {
  matchFieldsOrder = [
    NAMES.matches.origin.url,
    NAMES.matches.url,
    NAMES.matches.__ADDITIONAL_LINK__,
    NAMES.matches.additional_links,
    NAMES.matches.watermark,
  ]

  imageFieldsOrder = [
    NAMES.images.url,
    NAMES.images && NAMES.images.title,
    NAMES.images.date_taken,
    NAMES.images.place_taken,
    NAMES.images.licensing.first_published.date,
    NAMES.images.licensing.first_published.place,
    NAMES.images.licensing.cc,
    NAMES.images.licensing.license,
    NAMES.matches.attribution_provided, // exception (matches) since this validation is tied to the image license
    NAMES.images.registration.us.status,
    NAMES.images.__US_REGISTRATION_FILES__,
    NAMES.images.registration.us.id,
    NAMES.images.licensing.publicly_available,
    NAMES.images.licensing.available_on_stock,
    NAMES.images.licensing.price.value,
    NAMES.images.licensing.price.currency,
    NAMES.images.licensing.licensed_before,
    NAMES.images.licensing.price_1y_online.value,
    NAMES.images.licensing.price_1y_online.currency,
    NAMES.images.__LICENSING_HISTORY_FILES__,
    NAMES.images.licensing.agreementsUnavailable,
  ]

  fieldsDisplayOrder = [...this.matchFieldsOrder, ...this.imageFieldsOrder]

  // Non-blocking errors
  fieldsWarnings = [
    // NAMES.matches.origin.url,
    // NAMES.matches.url,
    // NAMES.matches.__ADDITIONAL_LINK__,
  ]

  _disabled = {}

  constructor(props) {
    super(props)
    this.state = {
      showEditImageFields: this.shouldShowImageFields(),
    }
  }

  shouldShowImageFields = () => {
    const { errors } = this.props
    const { image } = this.getCurrentImageMatch()

    /**
     * If the image was previously licensed under creative commons
     * the `attribution_provided` fields determines the viability
     * of the submission, so the field has to visible and can't
     * be hidden with the rest of the section
     */
    if (image && image.cc === true) return true

    for (const name of this.imageFieldsOrder) {
      const nameWithIndex = this.getNormalName(name)
      const error = errors[nameWithIndex]
      if (error && !this.fieldsWarnings.includes(name)) {
        return true
      }
    }
    return false
  }

  getDisabledFieldsForCopyRightRegisteredImage = () => {
    this._disabled = this.getDisabledFields()

    this._disabled['images[_IMAGE_INDEX_].title'] = true
    this._disabled['images[_IMAGE_INDEX_].date_taken'] = true
    this._disabled['images[_IMAGE_INDEX_].licensing.first_published.date'] = true
    this._disabled['images[_IMAGE_INDEX_].licensing.first_published.place'] = false

    return this._disabled
  }

  getDisabledFields = () => {
    this._disabled = produce(this._disabled, (draft) => {
      const { errors } = this.props
      const { length } = this.fieldsDisplayOrder

      let disableRest = false

      for (let i = 0; i < length; i++) {
        const name = this.fieldsDisplayOrder[i]

        if (disableRest) {
          draft[name] = true
          continue
        }

        const nameWithIndex = this.getNormalName(name)
        const error = errors[nameWithIndex]

        if (error && isPlainObject(error.message) && 'title' in error.message && !this.fieldsWarnings.includes(name)) {
          disableRest = true
        }
        draft[name] = false
      }
    })

    return this._disabled
  }

  getNormalName = (name) => {
    const { selectedImage } = this.props
    return name.replace(IMAGE_INDEX, selectedImage).replace(MATCH_INDEX, selectedImage)
  }

  ErrorBox = React.memo(({ name, disabled, withBorder = false, onlyWhenTouched = true }) => {
    const nameWithIndex = this.getNormalName(name)

    return (
      !disabled[nameWithIndex] && (
        <PixsyErrorMessage
          name={nameWithIndex}
          onlyWhenTouched={onlyWhenTouched}
          render={({
            error: {
              message: { title, subtitle },
            },
          }) => (
            <Fade in>
              <PixsyPaper
                variant="transparent"
                borderlessLeft
                borderlessRight
                borderless={!withBorder}
                borderVariant={withBorder && 'default'}
              >
                <PixsyPaper padding="36px 60px 36px 60px" className={errorStyle} expanded variant="red">
                  <div css={errorTitleStyle}>{title}</div>
                  {subtitle && <div css={errorSubTitleStyle}>{subtitle}</div>}
                </PixsyPaper>
              </PixsyPaper>
            </Fade>
          )}
        />
      )
    )
  })

  WarningBox = React.memo(({ name, disabled, withBorder = false }) => {
    const nameWithIndex = this.getNormalName(name)

    return (
      !disabled[nameWithIndex] && (
        <PixsyErrorMessage
          name={nameWithIndex}
          onlyWhenTouched
          render={({
            error: {
              message: { title, subtitle },
            },
          }) => (
            <Fade in>
              <PixsyPaper
                variant="transparent"
                borderlessLeft
                borderlessRight
                borderless={!withBorder}
                borderVariant={withBorder && 'default'}
              >
                <PixsyPaper padding="15px 60px 15px 60px" className={errorStyle} expanded variant="red">
                  <div css={warningTitleStyle}>{title}</div>
                  {subtitle && <div css={warningSubTitleStyle}>{subtitle}</div>}
                </PixsyPaper>
              </PixsyPaper>
            </Fade>
          )}
        />
      )
    )
  })

  WarningBoxContent = ({ withBorder, title, subtitle, showExitButton, caze, history, hack_isActuallyError }) => (
    <Fade in>
      <PixsyPaper
        variant="transparent"
        borderlessLeft
        borderlessRight
        borderless={!withBorder}
        borderVariant={withBorder && 'default'}
      >
        <PixsyPaper padding="15px 60px 15px 60px" className={errorStyle} expanded variant={hack_isActuallyError ? 'red' : 'yellow'}>
          <div css={warningTitleStyle}>{title}</div>
          {subtitle && <div css={warningSubTitleStyle}>{subtitle}</div>}
          {showExitButton && (
            <div css={matchExitButtonStyle}>
              <DeleteDraftCase
                caze={caze}
                title="Exit and delete draft case"
                afterDelete={() => 
                  this.props.history.replace('/cases',{
                    force: true
                  })
                }
              />
            </div>
          )}
        </PixsyPaper>
      </PixsyPaper>
    </Fade>
  )

  AdditionalLinks = ({ disabled, size = 3, padding, direction, readOnly }) => {
    const { matches: MATCH } = NAMES
    const { getValue, isFormReadOnly } = this.props
    const name = MATCH.additional_links
    const nameWithIndex = this.getNormalName(name)
    const additionalLinks = getValue(nameWithIndex)
    const isVertical = direction === 'column'
    const labelSize = isVertical ? 12 : size
    const fieldSize = isVertical ? 12 : 12 - size

    return (
      <PixsyPaper padding={padding} disabled={disabled[name]} variant="transparent" borderless>
        <PixsyFormGroup>
          <PixsyGridItem xs={labelSize} />
          <PixsyGridItem xs={fieldSize}>
            <AdditionalLinksList
              links={additionalLinks}
              disabled={disabled[name] || isFormReadOnly}
              readOnly={readOnly || isFormReadOnly}
              onRemoveLink={this.handleRemoveAdditionalLink}
            />
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }

  AddLinkButton = ({ disabled, readOnly }) => {
    const { matches: MATCH } = NAMES
    const { errors, getValue } = this.props
    const name = MATCH.__ADDITIONAL_LINK__
    const nameWithIndex = this.getNormalName(name)
    const hasError = errors[nameWithIndex]
    const currentValue = getValue(nameWithIndex)
    const isButtonDisabled = disabled[name] || hasError || !currentValue

    return (
      <Button
        hoverMain
        css={addLinksButtonStyle}
        onClick={this.handleClickToAddLinks}
        disabled={isButtonDisabled}
        readOnly={readOnly}
      >
        Add
      </Button>
    )
  }

  getValue = (name) => {
    const { getValue } = this.props
    const nameWithIndex = this.getNormalName(name)

    return getValue(nameWithIndex)
  }

  getError = (name) => {
    const { errors } = this.props
    const nameWithIndex = this.getNormalName(name)
    return errors[nameWithIndex]
  }

  getCurrentImageMatch = () => {
    const { selectedImage, values } = this.props
    return {
      image: values.images[selectedImage],
      match: values.matches[selectedImage],
    }
  }

  handleClickToAddLinks = () => {
    const { matches: MATCH } = NAMES
    const { getValue, setFieldTouched, setFieldValue } = this.props
    const singleLink = this.getNormalName(MATCH.__ADDITIONAL_LINK__)
    const arrayLinks = this.getNormalName(MATCH.additional_links)

    const singleLinkValue = getValue(singleLink)
    const arrayLinksValue = new Set((getValue(arrayLinks) || []).concat(singleLinkValue))

    setFieldValue(singleLink, '')
    setFieldValue(arrayLinks, [...arrayLinksValue])
    setFieldTouched(arrayLinks, true)
  }

  handleRemoveAdditionalLink = (url) => {
    const { matches: MATCH } = NAMES
    const { getValue, setFieldTouched, setFieldValue } = this.props
    const arrayLinks = this.getNormalName(MATCH.additional_links)
    const arrayLinksValue = new Set(getValue(arrayLinks) || [])

    arrayLinksValue.delete(url)

    setFieldValue(arrayLinks, [...arrayLinksValue])
    setFieldTouched(arrayLinks, true)
  }

  removeLicensingFile = (fid) => {
    const { images: IMAGE } = NAMES
    const { image } = this.getCurrentImageMatch()
    const { getLicensingFiles, removeImageFile } = this.props
    const uploader = getLicensingFiles(image.uuid)

    if (uploader) {
      uploader.removeFile(fid)
    }
    removeImageFile(fid, image.uuid, IMAGE.__LICENSING_HISTORY_FILES__)
  }
  removeUSRegistrationFile = (fid) => {
    const { images: IMAGE } = NAMES
    const { image } = this.getCurrentImageMatch()
    const { getRegistrationFiles, removeImageFile } = this.props
    const uploader = getRegistrationFiles(image.uuid)

    if (uploader) {
      uploader.removeFile(fid)
    }
    removeImageFile(fid, image.uuid, IMAGE.__US_REGISTRATION_FILES__)
  }

  retryLicensingFileUpload = (fid) => {
    const { getRegistrationFiles } = this.props
    const { image } = this.getCurrentImageMatch()
    const uploader = getRegistrationFiles(image.uuid)

    if (uploader) {
      uploader.retryUpload(fid)
    }
  }
  retryUSRegistrationFileUpload = (fid) => {
    const { getRegistrationFiles } = this.props
    const { image } = this.getCurrentImageMatch()
    const uploader = getRegistrationFiles(image.uuid)

    if (uploader) {
      uploader.retryUpload(fid)
    }
  }

  getDomain = () => {
    return this.props.domainName
  }

  getAsyncIgnoredError = () => {
    return this.props.asyncIgnoredErrors
  }

  handleShowEditImageFields = () => {
    this.setState({ showEditImageFields: true })
  }

  render() {
    const { image, match } = this.getCurrentImageMatch()

    if (!image) {
      // Temporary state when somehow the image selected was removed but the state `selectedImage`
      // on CasesContainer did not change. Timeout applied in CasesContainer.handleGotoNextStage
      // should handle this and fallback to the first available image.
      console.info('The image selected was removed. Selecting the first one!')
      return null
    }

    const {
      AdditionalLinks,
      AddLinkButton,
      ErrorBox,
      getAsyncIgnoredError,
      getDomain,
      getValue,
      WarningBox,
      WarningBoxContent,
    } = this
    const {
      asyncIgnoredErrors,
      errors,
      fieldContext,
      getLicensingFiles,
      getRegistrationFiles,
      isFormReadOnly: isReadOnly,
      selectedImage: index,
      sourceLocation,
      values,
      fullStageValidation,
      case: caze,
    } = this.props

    const readOnlyImage = !values.__DRAFT__ && !!(caze && caze.submission.images[index])

    const showEditImageFields = !readOnlyImage || this.state.showEditImageFields || this.shouldShowImageFields()
    const { images: IMAGE, matches: MATCH } = NAMES

    const isFormReadOnly = !((caze && caze.cm && caze.cm.changeRequested) || isReadOnly)

    const copyrightRegistrationLocked = image.registrations && image.registrations.length > 0
    const disabled = copyrightRegistrationLocked
      ? this.getDisabledFieldsForCopyRightRegisteredImage()
      : this.getDisabledFields()

    //  const isMatchSubmission = values.source === 'MATCH'
    const asyncCtx = {
      ...fieldContext,
      image,
      match,
      index,
      getDomain,
      asyncIgnoredErrors,
      getAsyncIgnoredError,
    }
    const syncCtx = { index, getDomain }

    const DEBOUNCE_VALIDATION_SYNC = 2e2

    const originUrlError = this.getError(MATCH.origin.url)
    const unrecoverableOriginUrlErrors = ['exactMatchHasNotBeenSubmittedBefore']

    return (
      <TooltipContext content={imagesStageTooltipContent}>
        {!!getValue(MATCH.url) && <ImageMatchComparison image={image} match={match} />}
        <PixsyPaper borderlessBottom>
          <SingleTextField
            name={MATCH.origin.url}
            type="url"
            index={index}
            disabled={disabled}
            readOnly={readOnlyImage}
            padding="40px 60px 20px 60px"
            validateOnChange
            validateOnBlur
            fieldContextOnBlur={asyncCtx}
            fieldContextOnChange={syncCtx}
            debounceValidationOnChange={DEBOUNCE_VALIDATION_SYNC}
          />
        </PixsyPaper>

        {this.getError(MATCH.origin.url) &&
          this.getError(MATCH.origin.url).message &&
          this.getError(MATCH.origin.url).message.asyncError && (
            <WarningBoxContent
              withBorder
              title={originUrlError.message.warning}
              showExitButton={unrecoverableOriginUrlErrors.includes(
                originUrlError.message.data && originUrlError.message.data.type
              )}
              caze={caze}
              hack_isActuallyError={true}
            />
          )}

        <ErrorBox
          name={MATCH.origin.url}
          disabled={disabled}
          onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
          withBorder
        />

        <PixsyPaper borderlessTop borderlessBottom>
          <SingleTextField
            name={MATCH.url}
            type="url"
            index={index}
            disabled={disabled}
            readOnly={readOnlyImage}
            padding="20px 60px 4px 60px"
            validateOnChange
            validateOnBlur
            debounceValidationOnChange={DEBOUNCE_VALIDATION_SYNC}
            fieldContextOnBlur={asyncCtx}
            rightAddon={<URLStatusAddon hasError={!!this.getError(MATCH.url)} />}
          />
        </PixsyPaper>
        <ErrorBox
          name={MATCH.url}
          disabled={disabled}
          withBorder
          onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
        />

        {this.getError(MATCH.url) &&
          this.getError(MATCH.url).message &&
          this.getError(MATCH.url).message.asyncError && (
            <WarningBoxContent withBorder title={this.getError(MATCH.url).message.warning} />
          )}

        <PixsyPaper borderlessTop borderlessBottom>
          <SingleTextField
            name={MATCH.__ADDITIONAL_LINK__}
            index={index}
            disabled={disabled}
            readOnly={readOnlyImage}
            padding="20px 60px 4px 60px"
            rightAddon={
              <React.Fragment>
                <URLStatusAddon hasError={!!this.getError(MATCH.__ADDITIONAL_LINK__)} />
                <AddLinkButton name={MATCH.__ADDITIONAL_LINK__} disabled={disabled} readOnly={readOnlyImage} />
              </React.Fragment>
            }
          />
        </PixsyPaper>
        {/* <ErrorBox
          name={MATCH.__ADDITIONAL_LINK__}
          disabled={disabled}
          withBorder
        /> */}
        <WarningBox
          name={MATCH.__ADDITIONAL_LINK__}
          disabled={disabled}
          withBorder
          onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
        />
        <PixsyPaper borderlessTop borderlessBottom>
          <AdditionalLinks padding="10px 60px 30px 60px" disabled={disabled} readOnly={readOnlyImage} />
        </PixsyPaper>

        <PixsyPaper padding="10px 0">
          <SingleTextField
            name={MATCH.watermark}
            index={index}
            disabled={disabled}
            type="watermark-select"
            padding="10px 60px 10px 60px"
            size={9}
            readOnly={readOnlyImage}
          />
        </PixsyPaper>
        <ErrorBox
          name={MATCH.watermark}
          disabled={disabled}
          withBorder
          onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
        />

        <PixsyPaper variant="transparent" />
        <p
          css={css`
            font-size: 1.1em;
            text-align: center;
            padding: 15px 0;
            margin: 0;
          `}
        >
          Please fill out image information - Information provided to Pixsy is automatically added below
        </p>

        {showEditImageFields ? (
          <React.Fragment>
            <PixsyPaper padding="20px 0" borderlessBottom>
              <PixsyGrid wrap="nowrap">
                <PixsyGridItem xs={4}>
                  <ImageThumbnail padding="10px 0 10px 60px" image={image} disabled={disabled} />
                </PixsyGridItem>

                <PixsyGridItem xs={8}>
                  {copyrightRegistrationLocked && (
                    <p>This information is locked as it is linked to a copyright registration and cannot be edited</p>
                  )}

                  <SingleTextField
                    name={IMAGE.title}
                    index={index}
                    alignTooltip="flex-end"
                    direction="column"
                    padding="10px 60px 10px 30px"
                    disabled={disabled}
                    readOnly={readOnlyImage}
                  />
                  <CombinedTextField
                    labelName={IMAGE.date_taken}
                    leftName={IMAGE.date_taken}
                    rightName={IMAGE.place_taken}
                    rightPlaceholder="Berlin, Germany"
                    leftType="date"
                    rightType="text"
                    leftValidateOnBlur
                    rightValidateOnBlur
                    index={index}
                    padding="10px 60px 10px 30px"
                    disabled={disabled}
                    readOnly={readOnlyImage}
                  />
                  <CombinedTextField
                    labelName={IMAGE.licensing.first_published.date}
                    leftName={IMAGE.licensing.first_published.date}
                    rightName={IMAGE.licensing.first_published.place}
                    rightPlaceholder="https://my-photography.com/trip-to-berlin"
                    leftType="date"
                    rightType="text"
                    leftValidateOnBlur
                    rightValidateOnBlur
                    rightContextOnBlur={asyncCtx}
                    index={index}
                    padding="10px 60px 10px 30px"
                    disabled={disabled}
                    readOnly={readOnlyImage}
                  />
                </PixsyGridItem>
              </PixsyGrid>
            </PixsyPaper>
            <ErrorBox
              name={IMAGE.title}
              disabled={disabled}
              withBorder
              onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
            />
            <ErrorBox
              name={IMAGE.date_taken}
              disabled={disabled}
              withBorder
              onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
            />
            <ErrorBox
              name={IMAGE.place_taken}
              disabled={disabled}
              withBorder
              onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
            />
            <ErrorBox
              name={IMAGE.licensing.first_published.date}
              disabled={disabled}
              withBorder
              onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
            />
            <ErrorBox
              name={IMAGE.licensing.first_published.place}
              disabled={disabled}
              withBorder
              onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
            />

            <PixsyPaper padding="10px 0 0" expanded borderlessTop borderlessBottom>
              <Question
                name={IMAGE.licensing.cc}
                index={index}
                disabled={disabled}
                fieldContextOnChange={syncCtx}
                errors={errors}
                padding="10px 60px 10px 60px"
                readOnly={readOnlyImage}
              />
            </PixsyPaper>
            <ErrorBox
              name={IMAGE.licensing.cc}
              disabled={disabled}
              withBorder
              onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
            />
            {getValue(IMAGE.licensing.cc) === true && (
              <React.Fragment>
                <PixsyPaper borderlessTop borderlessBottom>
                  <SingleTextField
                    name={IMAGE.licensing.license}
                    index={index}
                    disabled={disabled}
                    fieldContextOnChange={syncCtx}
                    type="cc-select"
                    size={9}
                    padding="10px 60px 10px 60px"
                    readOnly={readOnlyImage}
                  />
                </PixsyPaper>
                <ErrorBox
                  name={IMAGE.licensing.license}
                  disabled={disabled}
                  withBorder
                  onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
                />
                <PixsyPaper expanded borderlessTop borderlessBottom>
                  <Question
                    name={MATCH.attribution_provided}
                    index={index}
                    disabled={disabled}
                    fieldContextOnChange={syncCtx}
                    errors={errors}
                    padding="10px 60px 10px 60px"
                    readOnly={readOnlyImage}
                  />
                </PixsyPaper>
                <ErrorBox
                  name={MATCH.attribution_provided}
                  disabled={disabled}
                  withBorder
                  onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
                />
              </React.Fragment>
            )}

            <PixsyPaper borderlessTop borderlessBottom>
              <SingleTextField
                name={IMAGE.registration.us.status}
                index={index}
                disabled={disabled}
                readOnly={readOnlyImage}
                fieldContextOnChange={syncCtx}
                type="us-usco-select"
                size={9}
                padding="10px 60px 10px 60px"
              />
            </PixsyPaper>
            <ErrorBox
              name={IMAGE.registration.us.status}
              disabled={disabled}
              withBorder
              onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
            />
            {[US_USCO_STATUS_ENUM.Pending, US_USCO_STATUS_ENUM.Yes].includes(
              getValue(IMAGE.registration.us.status)
            ) && (
              <React.Fragment>
                <PixsyPaper
                  padding="10px 60px 10px 60px"
                  borderlessBottom
                  borderlessTop
                  disabled={disabled[IMAGE.__US_REGISTRATION_FILES__]}
                >
                  <UppyDragDrop
                    key={`key-${image._id}`}
                    uppy={getRegistrationFiles(image.uuid).uppy}
                    title="Please %{browse} your US Copyright Office certificate"
                    description="OPTIONAL"
                    browse="upload"
                  />
                </PixsyPaper>
                {!!IMAGE.__US_REGISTRATION_FILES__.length && (
                  <PixsyPaper
                    padding="0px 60px 0px 60px"
                    borderlessTop
                    borderlessBottom
                    disabled={disabled[IMAGE.__US_REGISTRATION_FILES__]}
                  >
                    <PixsyFileList
                      files={getValue(IMAGE.__US_REGISTRATION_FILES__)}
                      onRemove={this.removeUSRegistrationFile}
                      onRetry={this.retryUSRegistrationFileUpload}
                      sourceLocation={sourceLocation}
                      disabled={disabled[IMAGE.__US_REGISTRATION_FILES__] || isFormReadOnly}
                    />
                  </PixsyPaper>
                )}
              </React.Fragment>
            )}
            {[US_USCO_STATUS_ENUM.Pending, US_USCO_STATUS_ENUM.Yes, US_USCO_STATUS_ENUM.Pixsy].includes(
              getValue(IMAGE.registration.us.status)
            ) && (
              <PixsyPaper borderlessTop borderlessBottom>
                <SingleTextField
                  name={IMAGE.registration.us.id}
                  index={index}
                  disabled={disabled}
                  readOnly={readOnlyImage}
                  size={9}
                  padding="10px 60px 0px 60px"
                />
              </PixsyPaper>
            )}

            <PixsyPaper expanded borderlessTop borderlessBottom>
              <Question
                name={IMAGE.licensing.publicly_available}
                index={index}
                disabled={disabled}
                readOnly={readOnlyImage}
                fieldContextOnChange={syncCtx}
                errors={errors}
                padding="10px 60px 10px 60px"
              />
            </PixsyPaper>
            {this.getError(IMAGE.licensing.publicly_available) &&
              this.getError(IMAGE.licensing.publicly_available).message &&
              this.getError(IMAGE.licensing.publicly_available).message.warning && (
                <WarningBoxContent
                  withBorder
                  title={this.getError(IMAGE.licensing.publicly_available).message.warning}
                />
              )}

            {values.stockIncluded === true && getValue(IMAGE.licensing.publicly_available) === false && (
              <WarningBoxContent
                withBorder
                title="Your previous answers indicate that your work is published through a stock agency or agencies. Please ensure you provide correct information about their publicly available pricing, as a low license fee amount can impact negotiations when handling matters of unlicensed use."
              />
            )}

            {values.stockIncluded && values.stockVerified && (
              <React.Fragment>
                <PixsyPaper expanded borderlessTop borderlessBottom>
                  <Question
                    name={IMAGE.licensing.available_on_stock}
                    index={index}
                    disabled={disabled}
                    readOnly={readOnlyImage}
                    fieldContextOnChange={syncCtx}
                    errors={errors}
                    padding="10px 60px 10px 60px"
                  />
                </PixsyPaper>
                <ErrorBox
                  name={IMAGE.licensing.available_on_stock}
                  disabled={disabled}
                  withBorder
                  onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
                />
              </React.Fragment>
            )}
            {(getValue(IMAGE.licensing.publicly_available) === true ||
              getValue(IMAGE.licensing.available_on_stock) === true) && (
              <React.Fragment>
                <PixsyPaper expanded borderlessTop borderlessBottom>
                  <PriceCurrency
                    tooltipName={IMAGE.licensing.price.value}
                    leftName={IMAGE.licensing.price.value}
                    rightName={IMAGE.licensing.price.currency}
                    index={index}
                    disabled={disabled}
                    readOnly={readOnlyImage}
                    fieldContextOnChange={syncCtx}
                    padding="10px 60px 10px 60px"
                  />
                </PixsyPaper>
                <ErrorBox
                  name={IMAGE.licensing.price.value}
                  disabled={disabled}
                  onlyWhenTouched={fullStageValidation === 'IMAGES' ? false : undefined}
                />
              </React.Fragment>
            )}

            <PixsyPaper padding="0 0 30px" expanded borderlessTop>
              <Question
                name={IMAGE.licensing.licensed_before}
                index={index}
                disabled={disabled}
                readOnly={readOnlyImage}
                errors={errors}
                fieldContextOnChange={syncCtx}
                padding="10px 60px 10px 60px"
              />
              {getValue(IMAGE.licensing.licensed_before) === true && (
                <React.Fragment>
                  <PriceCurrency
                    tooltipName={IMAGE.licensing.price_1y_online.value}
                    leftName={IMAGE.licensing.price_1y_online.value}
                    rightName={IMAGE.licensing.price_1y_online.currency}
                    index={index}
                    disabled={disabled}
                    readOnly={readOnlyImage}
                    fieldContextOnChange={syncCtx}
                    padding="10px 60px 10px 60px"
                  />
                  <ErrorBox name={IMAGE.licensing.price_1y_online.value} disabled={disabled} />
                  <PixsyPaper
                    padding="10px 60px 10px 60px"
                    borderlessBottom
                    borderlessTop
                    disabled={disabled[IMAGE.__LICENSING_HISTORY_FILES__]}
                  >
                    <UppyDragDrop
                      uppy={getLicensingFiles(image.uuid).uppy}
                      title="Please %{browse} some invoices/receipts to support your license history"
                      description="OPTIONAL"
                      browse="upload"
                    />
                  </PixsyPaper>
                  {!!IMAGE.__LICENSING_HISTORY_FILES__.length && (
                    <PixsyPaper
                      padding="0px 60px 0px 60px"
                      borderless
                      disabled={disabled[IMAGE.__LICENSING_HISTORY_FILES__]}
                    >
                      <PixsyFileList
                        files={getValue(IMAGE.__LICENSING_HISTORY_FILES__)}
                        onRemove={this.removeLicensingFile}
                        onRetry={this.retryLicensingFileUpload}
                        sourceLocation={sourceLocation}
                        disabled={disabled[IMAGE.__LICENSING_HISTORY_FILES__] || isFormReadOnly}
                      />
                    </PixsyPaper>
                  )}
                  <Checkbox
                    name={IMAGE.licensing.agreementsUnavailable}
                    index={index}
                    disabled={disabled}
                    readOnly={readOnlyImage}
                    padding="10px 60px 10px 60px"
                  />
                </React.Fragment>
              )}
            </PixsyPaper>
          </React.Fragment>
        ) : (
          <ImageInformation
            disabled={isFormReadOnly}
            onClick={this.handleShowEditImageFields}
            image={image}
            match={match}
          />
        )}
      </TooltipContext>
    )
  }
}
export const DetailsStage = withRouter(_DetailsStage)

/* eslint-enable react/no-this-in-sfc */

const ImageMatchComparison = React.memo(
  ({ image, match }) => {
    const imageUrl = getImageUrl(image.file, image)

    return (
      <Fade in>
        <PixsyPaper padding="20px 60px 20px 60px">
          <PixsyFormGroup>
            <PixsyGridItem xs={12}>
              <ImageComparison imageURL={imageUrl} matchURL={match.url ? match.url : ''} />
            </PixsyGridItem>
          </PixsyFormGroup>
        </PixsyPaper>
      </Fade>
    )
  },
  ({ image: prevImage, match: prevMatch }, { image, match }) => {
    const prevUrl = getImageUrl(prevImage.file, prevImage)
    const nextUrl = getImageUrl(image.file, image)

    return prevUrl === nextUrl && prevMatch.url === match.url
  }
)

const ImageThumbnail = React.memo(
  ({ image, padding, disabled }) => {
    const imageUrl = getImageUrl(image.file, image)

    return (
      <Fade in>
        <PixsyPaper
          padding={padding}
          className={thumbnailPaper}
          disabled={disabled[NAMES.images && NAMES.images.title]}
          variant="transparent"
          borderless
        >
          <PixsyGrid wrap="nowrap">
            <PixsyGridItem className={thumbnailContainer} flexShrink={0}>
              <SexyThumbnail url={imageUrl} />
            </PixsyGridItem>
          </PixsyGrid>
        </PixsyPaper>
      </Fade>
    )
  },
  ({ image: prevImage, padding: prevPadding, disabled: prevDisabled }, { image, padding, disabled }) => {
    const prevUrl = getImageUrl(prevImage.file, prevImage)
    const nextUrl = getImageUrl(image.file, image)

    const prevDisabledValue = prevDisabled[NAMES.images && NAMES.images.title]
    const nextDisabledValue = disabled[NAMES.images && NAMES.images.title]

    return prevUrl === nextUrl && prevPadding === padding && prevDisabledValue !== nextDisabledValue
  }
)

const URLStatusAddon = React.memo(({ hasError }) => {
  return (
    <Fade in>
      <PixsyPaper css={urlStatusStyle} variant={hasError ? 'red' : 'transparent'} padding="0px 10px">
        {hasError ? <LinkOffIcon fontSize="inherit" /> : <LinkIcon fontSize="inherit" />}{' '}
        {/* <span>{hasError && 'Invalid URL'}</span> */}
      </PixsyPaper>
    </Fade>
  )
})

const errorStyle = css`
  * {
    text-align: center;
  }
`
const errorTitleStyle = css`
  font-size: 20px;
  color: #2e2e2e;
`
const errorSubTitleStyle = css`
  font-size: 16px;
  color: #6e6e6e;
  margin-top: 12px;
`
const warningTitleStyle = css`
  font-size: 14px;
  color: #2e2e2e;
`
const warningSubTitleStyle = css`
  font-size: 12px;
  color: #6e6e6e;
  margin-top: 6px;
`
const matchExitButtonStyle = css`
  font-size: 16px;
  color: #1e1e3e;
  margin-top: 10px;
`
const addLinksButtonStyle = css`
  height: 32px;
  padding: 4px 45px;
  /* margin-right: -11px; */

  &:focus {
    outline: 1px solid #fff !important;
    outline-offset: -2px !important;
  }
`

const thumbnailPaper = css`
  height: 100%;
  > div {
    height: 100%;
  }
`
const thumbnailContainer = css`
  height: 100%;
  width: 100%;
  > div {
    justify-content: center;
  }
`
const urlStatusStyle = css`
  height: 30px;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 30px;
  span,
  svg {
    vertical-align: middle;
  }

  svg {
    font-size: 14px;
  }
`
