import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'
import qs from 'querystring'
import flow from 'lodash/flow'
import urijs from 'urijs'
import Cookies from 'universal-cookie'
import { paginationKey as getCasePaginationKeyForQuery } from './modules/cases'
import { paginationKey as getClusterPaginationKeyForQuery } from './modules/clusters'
import { paginationKey as getTakedownPaginationKeyForQuery } from './modules/takedowns'
import { paginationKey as getRegistrationPaginationKeyForQuery } from './modules/registrations'

const log = require('debug')('selectors')

const get = (entities, entity, id) => entities[entity][id]

const getEntities = (state) => state.entities
const getPagesOf = (collection) => (state) => state.pagination[`${collection}ByPage`]
const getLocation = (state, props) => props.location
const getMatch = (state, props) => props.match

const EMPTY_PAGE = { ids: [], total: null }
const MAX_PER_PAGE_MATCHES = 100
const MIN_PER_PAGE_MATCHES = 20
const MAX_PER_PAGE_IMAGES = 100
const MIN_PER_PAGE_IMAGES = 20
const MAX_PER_PAGE_CASES = 100
const MIN_PER_PAGE_CASES = 20

const getCaseById = (state, props) => {
  return get(state.entities, 'cases', props.match.params.caseId)
}

const getPhotoId = (match) =>
  match && match.image ? match.image._id || match.image : (match.photo && match.photo._id) || match.photo

export const assembleClusterById = createSelector(
  [getEntities, (state, props) => props.clusterId],
  (entities, clusterId) => getClusterById(entities, clusterId)
)

export const assembleClusterByIds = createSelector([getEntities, (state, props) => props.selection], (entities, ids) =>
  ids.map((id) => getClusterById(entities, id))
)

export const getClusterById = (entities, clusterId) => {
  // log('getClusterById %o', clusterId)
  const cluster = {
    ...get(entities, 'clusters', clusterId),
  }

  if (!cluster || !cluster._id) return null

  const images = cluster.images.map((id) => get(entities, 'images', id))
  const matches = cluster.matches

    .map((id) => {
      const m = get(entities, 'matches', id)

      const img = images.find((i) => {
        return i._id === (m.image || m.photo)
      })
      if (!img) {
        // throw new Error(
        //   `Unable to populate cluster ${clusterId}. Image ${m.image ||
        //     m.photo} missing from match ${id}`
        // )
        try {
          window.mixpanel.track(`Error.Cluster.Populate`, {
            clusterId,
            imageId: m.image || m.photo,
            matchId: id,
          })
        } catch (e) {}
        console.error(`Unable to populate cluster ${clusterId}. Image ${m.image || m.photo} missing from match ${id}`)
        return null
      }
      return {
        ...m,
        image: img,
      }
    })
    .filter(Boolean)
    .sort((x, y) => y.similarity - x.similarity)

  const domain = get(entities, 'domains', cluster.domain)

  return {
    ...cluster,
    matches,
    images,
    domain,
  }
}

//-=x=-=x=-=x=-=x=- Submission & Takedown --=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-

export const filterIgnoredPairsOnStitchedCluster = (cluster) => {
  if (!cluster) return cluster
  log('Before')
  log('matches\n%O', cluster.matches)
  log('images\n%O', cluster.images)
  let matches = [...cluster.matches].filter((m) => !m.ignored)

  const hasAtLeastOneMatch = (image) => matches.map(getPhotoId).includes(image._id)

  let images = [...cluster.images].filter(hasAtLeastOneMatch)

  log('After')
  log('matches\n%O', matches)
  log('images\n%O', images)

  return {
    ...cluster,
    matches,
    images,
  }
}

//-=x=-=x=-=x=-=x=- Clusters Container --=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-

export const getClusterAndMatchIdFromPathname = createSelector([getLocation], (location) => {
  const [, viewerPath = ''] = location.pathname.split('/view/')
  const matches = viewerPath.match(/[a-f\d]{24}/g)

  return matches || []
})

const getSearchFromLocation = createSelector([getLocation], (location) => {
  return location.search
})

const getTagsStringFromRouterMatches = createSelector(
  [(state, props) => props.match && props.match.params && props.match.params.tags],
  (tagsString) => (tagsString ? tagsString : null)
)

export const getClusterQueryTags = createSelector([getTagsStringFromRouterMatches], (tagsString) => {
  if (!tagsString) {
    return []
  }

  return String(tagsString)
    .valueOf()
    .split('view/')[0]
    .split('/')
    .filter(Boolean)
    .filter((t) => t !== 'view')
})

export const getClustersQuery = createSelector([getSearchFromLocation], (locationSearch) => {
  const queryArgs = qs.parse(String(locationSearch).substr(1))

  const cookies = new Cookies()

  const localPageSize = cookies.get('pageSize') || '20'

  const pageSize = flow(
    (page) => Math.min(page, MAX_PER_PAGE_MATCHES),
    (page) => Math.max(page, MIN_PER_PAGE_MATCHES)
  )('pageSize' in queryArgs ? parseInt(queryArgs.pageSize, 10) : parseInt(localPageSize, 10))

  return {
    page: 'page' in queryArgs ? parseInt(queryArgs.page, 10) : 0,
    sort: 'sort' in queryArgs ? queryArgs.sort : '-score',
    pageSize,
  }
})

export const getSpecificClusterPage = createSelector(
  [getClustersQuery, getClusterQueryTags, getPagesOf('clusters')],
  (query, tags, pages) => {
    const paginationQuery = {
      ...query,
      tags,
    }
    const key = getClusterPaginationKeyForQuery(paginationQuery)
    return pages[key] || { ...EMPTY_PAGE, paginationQuery }
  }
)

export const assembleClustersAndTotal = createSelector(
  [getClustersQuery, getClusterQueryTags, getSpecificClusterPage, getEntities],
  (query, tagsFromLocation, page, entities) => {
    log('assembleClustersAndTotal %o', query, page, entities)
    const { total, tags, ids } = page

    const populatedClusters = ids.map((id) => getClusterById(entities, id))

    return {
      total,
      tags,
      clusters: populatedClusters,
      query: {
        ...query,
        tags: tagsFromLocation,
      },
      paginationProps: {
        total: total || 0,
        forcePage: query.page,
        pageCount: Math.ceil(total / query.pageSize),
        perPage: query.pageSize,
      },
    }
  }
)

export const assembleCase = createSelector([getEntities, getCaseById], (entities, caze) => {
  if (!caze) return null
  return {
    ...caze,
    ...(caze.cluster && { cluster: getClusterById(entities, caze.cluster) }),
  }
})

//-=x=-=x=-=x=-=x=- Cases Container --=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-

export const getCasesQuery = createSelector([getLocation, getMatch], (location, match) => {
  const searchQuery = qs.parse(String(location.search).substr(1))
  const filter = match.params.filter

  const pageSize = flow(
    (page) => Math.min(page, MAX_PER_PAGE_CASES),
    (page) => Math.max(page, MIN_PER_PAGE_CASES)
  )('pageSize' in searchQuery ? parseInt(searchQuery.pageSize, 10) : MIN_PER_PAGE_CASES)

  return {
    filter,
    pageSize,
    page: 'page' in searchQuery ? parseInt(searchQuery.page, 10) : 0,
    ignored: searchQuery.ignored,
    // sortKey: searchQuery.sortKey || 'ref',
    // sortDir: searchQuery.sortDir || 'DESC',
  }
})

export const getSpecificCasePage = createSelector([getCasesQuery, getPagesOf('cases')], (query, pages) => {
  const key = getCasePaginationKeyForQuery(query)
  return pages[key] || { ...EMPTY_PAGE, query }
})

export const assembleCasesAndTotal = createSelector(
  [getCasesQuery, getSpecificCasePage, getEntities],
  (query, page, entities) => {
    const { total, ids } = page
    const { cases, clusters, domains, images, matches } = entities
    const populatedCases = ids.reduce((array, id) => {
      const caze = cases[id]

      if (!caze) return array

      let cluster = null

      if (caze.cluster) {
        if (!clusters[caze.cluster]) {
          console.error('Error stitching case ' + caze.reference)
          return array
        }

        cluster = {
          ...clusters[caze.cluster],
          domain: domains[clusters[caze.cluster].domain],
          images: clusters[caze.cluster].images.map((i) => images[i]).filter(Boolean),
          matches: clusters[caze.cluster].matches.map((i) => matches[i]).filter(Boolean),
        }
      } else if (caze.submission && caze.submission.images) {
        const caseImages = caze.submission.images.map((id) => images[id]).filter(Boolean)
        let caseMatches = []
        let domain = null
        if (caze.submission.matches) {
          caseMatches = caze.submission.matches.map((id) => matches[id]).filter(Boolean)
          const matchWithDomain = caseMatches.find((m) => m && m.origin && m.origin.url)
          domain = matchWithDomain && {
            _id: matchWithDomain.domain,
            host: urijs(matchWithDomain.origin.url).domain(),
          }
        }
        cluster = {
          ...clusters[caze.cluster],
          domain,
          images: caseImages,
          matches: caseMatches,
        }
      } else {
        return [...array, caze]
      }

      const matchesUnignored = cluster.matches.filter((m) => !m.ignored)

      cluster.matches = matchesUnignored.length === 0 ? cluster.matches : matchesUnignored

      const final = [
        ...array,
        {
          ...caze,
          cluster,
        },
      ]

      return sortBy(final, 'created').reverse()
    }, [])

    return { total, cases: populatedCases, query }
  }
)
// export function assembleCase(state, id) {

//     const case = getCaseById( state, id )
//   return {
//     _id: 'hi',
//   }
// }

// const getClusterId = (state, props ) =>

// const getCluster = createSelector(
//   [getEntities, getClusterId],
//   (entities, clusterId) => {
//     const cluster = {
//       ...get(entities, 'cluster', clusterId),
//     }

//     const matches = cluster.matches.map(id => get(entities, 'matches', id))
//     const images = cluster.images.map(id => get(entities, 'images', id))
//     const domain = get(entities, 'cases', cluster.domain)

//     return {
//       ...cluster,
//       matches,
//       images,
//       domain,
//     }
//   }
// )

//-=x=-=x=-=x=-=x=- Takedowns Container --=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-

export const getTakedownsQuery = createSelector([getLocation, getMatch], (location, match) => {
  // const searchQuery = qs.parse(String(location.search).substr(1))
  // const filter = match.params.filter

  return {
    filter: null,
    page: 0,
    // sortKey: searchQuery.sortKey || 'ref',
    // sortDir: searchQuery.sortDir || 'DESC',
  }
})

export const getImageQueryTags = createSelector([getTagsStringFromRouterMatches], (tagsString) => {
  if (!tagsString) {
    return []
  }
  return String(tagsString)
    .valueOf()
    .split('/')
    .filter(Boolean)
})

export const getImagesQuery = createSelector([getLocation, getImageQueryTags], (location, tags) => {
  const { search } = location
  const queryArgs = qs.parse(String(search).substr(1))

  const cookies = new Cookies()

  const localPageSize = cookies.get('pageSize') || '20'

  const pageSize = flow(
    (page) => Math.min(page, MAX_PER_PAGE_IMAGES),
    (page) => Math.max(page, MIN_PER_PAGE_IMAGES)
  )('pageSize' in queryArgs ? parseInt(queryArgs.pageSize, 10) : parseInt(localPageSize, 10))

  const sort = flow(
    (sortBy) => ['_id', 'title', 'tracked'].find((s) => [s, `-${s}`].includes(sortBy)),
    (sortBy) => (sortBy ? queryArgs.sort : '-tracked')
  )(queryArgs.sort)

  return {
    tags,
    page: 'page' in queryArgs ? parseInt(queryArgs.page, 10) : 0,
    sort,
    pageSize,
  }
})

export const getSpecificTakedownPage = createSelector([getTakedownsQuery, getPagesOf('takedowns')], (query, pages) => {
  const key = getTakedownPaginationKeyForQuery(query)
  return pages[key] || { ...EMPTY_PAGE, query }
})

export const assembleTakedownsAndTotal = createSelector(
  [getTakedownsQuery, getSpecificTakedownPage, getEntities],
  (query, page, entities) => {
    const { total, ids } = page
    const { takedowns } = entities

    const populatedTakedowns = ids.reduce((array, id) => {
      if (!takedowns[id]) return array

      let takedown = {
        ...takedowns[id],
        cluster: getClusterById(entities, takedowns[id].cluster),
      }

      return [...array, takedown]
    }, [])

    return { total, takedowns: populatedTakedowns, query }
  }
)

//-=x=-=x=-=x=-=x=- Images Container --=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-

export const getImageById = (state, props) => {
  return get(state.entities, 'images', props.match.params.id)
}

export const assembleRegistrationsForImage = (state, props) => {
  if (!state.entities.registrations) {
    return []
  }

  // Filter out registrations without images and those without proper id inside images array
  return Object.values(state.entities.registrations)
    .map((r) => {
      if (r.images) {
        const found = r.images.map((image) => image === props.match.params.id).filter(Boolean)

        if (found.length > 0) return r
      }

      return null
    })
    .filter(Boolean)
}

export const getRegistrationsQuery = createSelector([getLocation, getMatch], (location, match) => {
  const searchQuery = qs.parse(String(location.search).substr(1))
  const filter = match.params.filter

  return {
    filter,
    page: 'page' in searchQuery ? searchQuery.page : 0,
    sortKey: searchQuery.sortKey || 'ref',
    sortDir: searchQuery.sortDir || 'DESC',
  }
})

export const getSpecificRegistrationsPage = createSelector(
  [getRegistrationsQuery, getPagesOf('registrations')],
  (query, pages) => {
    const key = getRegistrationPaginationKeyForQuery(query)
    return pages[key] || { ...EMPTY_PAGE, query }
  }
)

export const assembleRegistrationsAndTotal = createSelector(
  [getRegistrationsQuery, getSpecificRegistrationsPage, getEntities],
  (query, page, entities) => {
    const { total, ids } = page
    const { registrations, images } = entities

    const populatedRegistrations = ids.reduce((array, id) => {
      const reg = { ...registrations[id] }

      if (!reg) {
        return array
      }

      reg.images = reg.images.map((i) => {
        if (!i.url) {
          return images[i]
        }
        return i
      })

      return [
        ...array,
        {
          ...reg,
        },
      ]
    }, [])

    return { total, registrations: populatedRegistrations, query }
  }
)
//-=x=-=x=-=x=-=x=- Copyright Registrations Container --=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-=x=-

const getRegistrationById = (state, props) => {
  // return get(state.entities, 'registrations', props.match.params.registrationId)
  return get(state.entities, 'registrations', props.registration._id)
}

export const assembleRegistration = createSelector([getEntities, getRegistrationById], (entities, registration) => {
  return {
    ...registration,
  }
})

export const assembleClustersForImage = createSelector([getImageById, getEntities], (image, entities) => {
  if (!image) return []
  const relevantClusters = Object.values(entities.clusters)
    .filter((cluster) => cluster.images && cluster.images.includes(image._id))
    .map((c) => c._id)

  return relevantClusters.map(getClusterById.bind(null, entities))
})

export const assembleCasesForImage = createSelector([assembleClustersForImage, getEntities], (clusters, entities) =>
  clusters
    .filter(Boolean)
    .filter((c) => c.case)
    .map((c) => ({
      ...get(entities, 'cases', c.case),
      domain: c.domain,
    }))
)