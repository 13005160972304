import * as React from 'react'
import PropTypes from 'prop-types'
import { humanize } from 'pixsy-constants'
import { withRouter } from 'react-router'
import { notifSend } from 'redux-notifications/lib/actions'
import { connect } from 'react-redux'
import {
  Button,
  ChooseBestImageSource,
  Dialog,
  SexyThumbnail,
  SelectableContext,
} from 'common'
import styled, { css, cx } from 'react-emotion'
import { getImagesQuery } from '../../../../redux/selectors'
import { load } from '../../../../redux/modules/images'
import moment from 'moment'

class MergeDialog extends React.Component {
  constructor({ selection, allImages }) {
    super()

    let images = selection.map(id => allImages[id])
    const registeredImage = images.find(this.isImageRegistered)

    let primaryImageId = null
    if (registeredImage) {
      primaryImageId = registeredImage._id
      images.sort((a, b) => a._id !== primaryImageId)
    } else {
      const imagesSortedByMatches = images.sort((a, b) => b.matches - a.matches)
      primaryImageId = imagesSortedByMatches[0]._id
    }

    this.state = {
      images,
      primaryImageId,
      // if a registered image is contained,
      // no manual selection is allowed
      selectionAllowed: !registeredImage,
    }
  }

  isImageRegistered = img =>
    (img.registrations && img.registrations[0]) ||
    (img.registration &&
      img.registration.us &&
      img.registration.us.status &&
      ['Yes', 'Pending', 'Pixsy'].includes(img.registration.us.status)) ||
    (img.licensing &&
      ['yes', 'pending', 'pixsy'].includes(img.licensing.registered))

  selectPrimaryImage = id => {
    this.setState({
      primaryImageId: id,
    })
  }

  onHandleMerge = async () => {
    const {
      mergeImages,
      notifSend,
      reload,
      onClose,
      selection,
      clear,
    } = this.props
    const { primaryImageId } = this.state

    const imagesToMerge = selection.filter(s => s !== primaryImageId)

    notifSend({
      message: 'Merging images. Please wait...',
      kind: 'info',
      dismissAfter: 10e3,
    })

    await mergeImages(primaryImageId, imagesToMerge)

    reload()
    onClose()
    clear()
  }

  render() {
    const { isOpen, onClose, merging, selection } = this.props
    const { images, primaryImageId, selectionAllowed } = this.state

    return (
      <Dialog isOpen={isOpen} wide onRequestClose={merging ? null : onClose}>
        <div>
          <h1 css={dialogHeader}>Merge {selection.length} duplicate images</h1>
          {selectionAllowed ? (
            <p css={dialogDescription}>
              Select the image you wish to keep and all other images will merge
              into.
            </p>
          ) : (
            <p css={dialogDescription}>
              Please confirm you wish to keep the previously registered image
              and merge the other images into it.
            </p>
          )}
          <p css={dialogDescription}>
            Cases, takedowns and other related data will also point to this
            image.
            <br />
            <em>This action cannot be undone.</em>
          </p>
          <div css={dialogImagesContainer}>
            {images.map((img, index) => (
              <div
                css={dialogImageRow}
                key={img._id}
                className={cx({
                  [selectedRow]: img._id === primaryImageId,
                  [selectableRow]: selectionAllowed,
                })}
                onClick={() =>
                  selectionAllowed && this.selectPrimaryImage(img._id)
                }
              >
                <div css={dialogThumbnail}>
                  <ChooseBestImageSource
                    candidates={[img.thumbnail, img.url]}
                    type="image"
                  >
                    {props => <SexyThumbnail {...props} />}
                  </ChooseBestImageSource>
                </div>
                <div css={dialogColumn}>
                  <span css={rowHeader}>Image Title</span>
                  <RowValue>{img.title || 'Untitled Image'}</RowValue>
                  {selectionAllowed ? (
                    <React.Fragment>
                      <span css={rowHeader}>Matches</span>
                      <RowValue>{img.matches}</RowValue>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span css={rowHeader}>Registered</span>
                      <RowValue>
                        {img._id === primaryImageId ? 'Yes' : 'No'}
                      </RowValue>
                    </React.Fragment>
                  )}
                </div>
                <div css={dialogColumn}>
                  <span css={rowHeader}>Imported</span>
                  <RowValue nowrap>
                    {moment(img.created).format('MMMM D, YYYY')}
                  </RowValue>
                  {/* <span css={RowHeader}>Cases</span> */}
                </div>
                <div css={dialogColumn}>
                  <span css={rowHeader}>Source</span>
                  <RowValue>{humanize(img.origin.source)}</RowValue>
                  {/* <span css={RowHeader}>Takedowns</span> */}
                </div>
              </div>
            ))}
          </div>
          <div css={dialogFooter}>
            <Button greyOutline onClick={onClose} disabled={merging}>
              Cancel
            </Button>
            <Button
              danger
              onClick={this.onHandleMerge}
              disabled={!primaryImageId || merging}
            >
              {merging ? 'Merging Images...' : 'Merge'}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const SelectContextAwareMergeDialog = props => (
  <SelectableContext.Consumer>
    {selectableContextProps =>
      selectableContextProps.isSelecting && (
        <MergeDialog {...selectableContextProps} {...props} />
      )
    }
  </SelectableContext.Consumer>
)

export default withRouter(
  connect(
    state => ({
      allImages: state.entities.images,
    }),
    { notifSend, load },
    (stateProps, dispatchProps, { location, match, ...ownProps }) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      reload: () => dispatchProps.load(getImagesQuery({}, { location, match })),
    })
  )(SelectContextAwareMergeDialog)
)

MergeDialog.propTypes = {
  allImages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  merging: PropTypes.bool,
}

const dialogImagesContainer = css`
  margin-top: 20px;
  max-height: 330px;
  overflow-y: auto;
`
const dialogThumbnail = css`
  flex: 215px 0 0;
`
const dialogImageRow = css`
  height: 142px;
  display: flex;
  margin-bottom: 20px;

  opacity: 0.5;
  transition: opacity 0.1s ease-in;
`
const selectedRow = css`
  opacity: 1 !important;
  transition: opacity 0.2s ease-out;
`

const selectableRow = css`
  cursor: pointer;
`

const dialogColumn = css`
  display: flex;
  height: 100%;
  flex-grow: 1;
  padding: 20px 15px 0;
  flex-direction: column;
`
const dialogHeader = css`
  text-align: center;
`
const dialogDescription = css`
  text-align: center;
  em {
    color: #f42534;
  }
`
const rowHeader = css`
  display: block;
  font-size: 0.8em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 2px;
`
const RowValue = styled.span`
  display: block;
  font-size: 12px;
  margin-bottom: 15px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ nowrap }) => (nowrap ? `white-space: nowrap;` : '')};
`
const dialogFooter = css`
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }
`
