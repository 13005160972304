import React from 'react'

export default function Flag({ country, style = { width: '64px', height: '64px' } }) {
  let src
  try {
    src = require(`./assets/${country}.png`)
  } catch (e) {
    src = 'https://s3.amazonaws.com/pixsy-react-static-assets/404.png'
  }

  return <img src={src} style={style} alt={country} />
}
