import React from 'react'
import styled from 'react-emotion'

const MatchViewer3Card = () => {
  return (
    <MatchViewer3CardStyled>
      <h5>
        <MatchesIcon />
        <div>
          MATCHES <em> BETA </em>
        </div>
      </h5>
      <p> TRY OUT THE NEW EXPERIENCE </p>
    </MatchViewer3CardStyled>
  )
}

export default MatchViewer3Card

const MatchesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="58" viewBox="0 0 23.559 21.05">
    <g id="Group_3934" data-name="Group 3934" transform="translate(44.32 -165.3)">
      <g id="Group_3433" data-name="Group 3433" transform="translate(-383.352 163.964)">
        <g id="matches_light" transform="translate(339.731 2.036)">
          <g
            id="Path_1135"
            data-name="Path 1135"
            transform="translate(10592.92 -13288.178)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M-10592.92,13293.771l11.079,5.6,11.079-5.6-11.079-5.594Z" stroke="none"></path>
            <path
              d="M -10581.8408203125 13288.177734375 L -10592.919921875 13293.771484375 L -10581.8408203125 13299.3671875 L -10570.76171875 13293.771484375 L -10581.8408203125 13288.177734375 M -10581.8408203125 13287.4775390625 C -10581.732421875 13287.4775390625 -10581.625 13287.5029296875 -10581.525390625 13287.552734375 L -10570.4462890625 13293.146484375 C -10570.2099609375 13293.265625 -10570.0615234375 13293.5068359375 -10570.0615234375 13293.771484375 C -10570.0615234375 13294.03515625 -10570.2099609375 13294.27734375 -10570.4462890625 13294.396484375 L -10581.525390625 13299.9921875 C -10581.6240234375 13300.0419921875 -10581.732421875 13300.0673828125 -10581.8408203125 13300.0673828125 C -10581.94921875 13300.0673828125 -10582.0576171875 13300.0419921875 -10582.15625 13299.9921875 L -10593.2353515625 13294.396484375 C -10593.4716796875 13294.27734375 -10593.6201171875 13294.03515625 -10593.6201171875 13293.771484375 C -10593.6201171875 13293.5068359375 -10593.4716796875 13293.265625 -10593.2353515625 13293.146484375 L -10582.15625 13287.552734375 C -10582.056640625 13287.5029296875 -10581.94921875 13287.4775390625 -10581.8408203125 13287.4775390625 Z"
              stroke="none"
              fill="#ffffff"
            ></path>
          </g>
          <path
            id="Path_1136"
            data-name="Path 1136"
            d="M-10593.374,13299.664l11.16,5.729,11.164-5.729"
            transform="translate(10593.374 -13291.036)"
            fill="none"
            stroke="#ffffff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.7"
          ></path>
          <path
            id="Path_1137"
            data-name="Path 1137"
            d="M-10593.374,13299.664l11.16,5.729,11.164-5.729"
            transform="translate(10593.374 -13288.239)"
            fill="none"
            stroke="#ffffff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.7"
          ></path>
          <path
            id="Path_1138"
            data-name="Path 1138"
            d="M-10593.374,13299.664l11.16,5.729,11.164-5.729"
            transform="translate(10593.374 -13285.393)"
            fill="none"
            stroke="#ffffff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.7"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

const MatchViewer3CardStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 15px 0;
  height: 145px;
  background: rgb(0, 138, 171);
  color: #ffffff;
  letter-spacing: 0.25em;

  transition: all 0.1s linear;
  border: 3px solid rgb(0, 138, 171);
  &:hover {
    border-color: rgb(255, 161, 104);
  }

  h5 {
    margin: 0;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ffffff;

    @media (max-width: 1300px) {
      flex-direction: column;
    }

    div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  em {
    background: rgb(255, 161, 104);
    padding: 4px 6px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  p {
    margin: 0;
    margin-top: 5px;
    font-size: 0.75em;
    font-weight: 300;
    text-align: center;
  }
`
