import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'common'
import { css } from 'react-emotion'

export default function DowngradePlanWarningModal({
  isOpen,
  onCancel,
  onDowngrade,
}) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} theme="light">
      <div css={content}>
        <h1>Warning - Subscription Active</h1>

        <span>
          Downgrading an active subscription takes effect right away and any
          remaining time left in your plan will be lost. We recommend waiting
          until the end of the plan period before proceeding.
        </span>

        <div css={buttons}>
          <Button outline onClick={onDowngrade}>
            Downgrade Plan
          </Button>
          <Button hoverMain onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

DowngradePlanWarningModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDowngrade: PropTypes.func.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  @media (max-width: 768px) {
    h1 {
      text-align: center;
    }
  }
`

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
