import React from 'react'

const StarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9.816" height="9.759" viewBox="0 0 9.816 9.759" {...props}>
    <path
      id="Path_1231"
      d="M-14442.437-9202.166l-1.344,2.922-3.08.455,2.191,2.263-.467,3.119,2.7-1.444,2.689,1.444-.475-3.119,2.178-2.263-3.029-.455Z"
      transform="translate(14447.361 9202.666)"
      fill="#ffa168"
      stroke="#ffa168"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </svg>
)

const CrossIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.561" height="13.559" viewBox="0 0 13.561 13.559" {...props}>
    <g id="Group_2820" transform="translate(1.061 1.061)">
      <path
        id="Path_941"
        d="M-18586.846-212.974l11.439-11.437"
        transform="translate(18586.846 224.411)"
        fill="none"
        stroke="#3b3b3b"
        strokeWidth="3"
      />
      <path
        id="Path_942"
        d="M0,11.437,11.438,0"
        transform="translate(0 11.438) rotate(-90)"
        fill="none"
        stroke="#3b3b3b"
        strokeWidth="3"
      />
    </g>
  </svg>
)

const WhiteCheckIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.679" height="19.476" viewBox="0 0 19.679 19.476" {...props}>
    <path
      id="Path_940"
      d="M0,10.157l5.941,3.928L15.151,0"
      transform="translate(3.346 0.94) rotate(9)"
      fill="none"
      stroke="#fff"
      strokeWidth="3"
    />
  </svg>
)

const EyeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.276" height="8.038" viewBox="0 0 19.276 8.038" {...props}>
    <g id="Group_2740" transform="translate(0.334 0.25)">
      <path
        id="Path_1046"
        d="M1566.725,12259.228c2.909-1.418,3.5-3.945,9.233-3.945s6.535,2.65,9.191,3.945"
        transform="translate(-1566.541 -12255.282)"
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
      <path
        id="Path_1047"
        d="M0,3.6C2.909,2.183,3.311,0,9.048,0s6.72,2.307,9.375,3.6"
        transform="translate(18.423 7.538) rotate(180)"
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
      <g id="Ellipse_208" transform="translate(5.618 0.876)" fill="#fff" stroke="#707070" strokeWidth="0.5">
        <ellipse cx="3.5" cy="3" rx="3.5" ry="3" stroke="none" />
        <ellipse cx="3.5" cy="3" rx="3.25" ry="2.75" fill="none" />
      </g>
      <path
        id="Path_1048"
        d="M1604.071,12264.7a1.564,1.564,0,0,1,.118-1.876,1.819,1.819,0,0,1,1.8-.955"
        transform="translate(-1596.869 -12259.882)"
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
    </g>
  </svg>
)

const TIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.021" height="11.859" viewBox="0 0 12.021 11.859" {...props}>
    <path
      id="Path_1038"
      d="M1298.984,11765.131h4.527v9.137h2.7v-9.137h4.292v-2.223h-11.521Z"
      transform="translate(-1298.734 -11762.658)"
      fill="none"
      stroke="#707070"
      strokeWidth="0.5"
      opacity="0.3"
    />
  </svg>
)

const LinkIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.415" height="13.033" viewBox="0 0 15.415 13.033" {...props}>
    <g id="Group_2664" transform="translate(0.274 0.433)">
      <g id="Group_2662" transform="matrix(0.777, -0.629, 0.629, 0.777, 0, 9.534)">
        <path
          id="Path_1032"
          d="M8.137.264c1.756.057,1.793.929,1.821,1.8S9.942,3.8,8.251,3.741s-5.234-.208-6.637-.216S.125,2.708.044,1.337"
          transform="translate(5.882 0.193)"
          fill="none"
          stroke="#707070"
          strokeWidth="0.5"
        />
        <path
          id="Path_1035"
          d="M1.878,3.538C.123,3.48.086,2.611.057,1.741S.073,0,1.764.059,7,.267,8.4.274s1.489.821,1.57,2.19"
          fill="none"
          stroke="#707070"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
)

const ImageIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.407" height="14.965" viewBox="0 0 16.407 14.965" {...props}>
    <g id="Group_2661" transform="translate(0.25 0.25)">
      <path
        id="Path_1002"
        d="M-13960,7538.146h15.907v-14.465H-13960Z"
        transform="translate(13960.002 -7523.681)"
        fill="none"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        id="Path_1010"
        d="M-13958.973,7571.461l2.788-2.379,2.661,2.716"
        transform="translate(13961.354 -7561.068)"
        fill="none"
        stroke="#939393"
        strokeWidth="0.5"
      />
      <path
        id="Path_1011"
        d="M-13954.39,7570.634l2.6-2.765,4,3.989"
        transform="translate(13961.1 -7560.925)"
        fill="none"
        stroke="#939393"
        strokeWidth="0.5"
      />
      <g id="Ellipse_203" transform="translate(5.13 4.007)" fill="#fff" stroke="#939393" strokeWidth="0.5">
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.75" fill="none" />
      </g>
    </g>
  </svg>
)

const ThreeDotsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="4.5" height="16.5" viewBox="0 0 3 11" {...props}>
    <g id="Group_2829" transform="translate(0 11.069) rotate(-90)">
      <g id="Group_2819" transform="translate(11 3) rotate(180)">
        <circle id="Ellipse_232" cx="1.5" cy="1.5" r="1.5" transform="translate(-0.069 3) rotate(-90)" />
        <circle id="Ellipse_233" cx="1.5" cy="1.5" r="1.5" transform="translate(3.931 3) rotate(-90)" />
        <circle id="Ellipse_234" cx="1.5" cy="1.5" r="1.5" transform="translate(7.931 3) rotate(-90)" />
      </g>
    </g>
  </svg>
)

const SearchIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.319" height="17.271" viewBox="0 0 17.319 17.271" {...props}>
    <g id="Group_3079" transform="translate(0.071 0.062)" opacity="0.5">
      <g id="Ellipse_229" transform="translate(-0.071 -0.062)" fill="#fff" stroke="#212121" strokeWidth="1.5">
        <ellipse cx="6.5" cy="6" rx="6.5" ry="6" stroke="none" />
        <ellipse cx="6.5" cy="6" rx="5.75" ry="5.25" fill="none" />
      </g>
      <path
        id="Path_1049"
        d="M10597.394,2823.6l6.474,6.47"
        transform="translate(-10587.149 -2813.389)"
        fill="none"
        stroke="#212121"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

const RefreshIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.679" height="18.441" viewBox="0 0 19.679 18.441" {...props}>
    <g id="Group_3080" transform="translate(4.89 -0.922) rotate(30)">
      <path
        id="Path_1065"
        d="M6,12a6,6,0,0,0,6-6A5.674,5.674,0,0,0,6,0,6.4,6.4,0,0,0,0,6"
        transform="translate(3.894 0)"
        fill="none"
        stroke="#c7c7c7"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1066"
        d="M0,0,3.637,3.576,7.275,0"
        transform="matrix(0.966, 0.259, -0.259, 0.966, 0.926, 3.339)"
        fill="none"
        stroke="#c7c7c7"
        strokeLinecap="square"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

const FlagIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.122" height="14.244" viewBox="0 0 13.122 14.244" {...props}>
    <g id="Group_3333" transform="translate(-134.367 -199.037)" opacity="0.5">
      <path
        id="Path_959"
        d="M-13925.133,7261.116a10.32,10.32,0,0,0,6.477,0c3.563-1.158,5.646,0,5.646,0v7.308a8.591,8.591,0,0,0-5.5,0,13.309,13.309,0,0,1-6.627,0Z"
        transform="translate(14060 -7061)"
        fill="#fff"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_960"
        d="M-13957.133,7199.781v-12.763"
        transform="translate(14092 -6987)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <g id="Ellipse_194" transform="translate(134.5 199.6)" fill="#fff" stroke="#000" strokeWidth="1">
        <circle cx="0.35" cy="0.35" r="0.35" stroke="none" />
        <circle cx="0.35" cy="0.35" r="0.15" fill="none" />
      </g>
    </g>
  </svg>
)

const ThreeDashesIcon = (props) => (
  <svg id="Group_2877" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" {...props}>
    <path id="Path_1066" d="M0,0H15V15H0Z" fill="#fff" />
    <g id="Group_2876" transform="translate(1 3.261)">
      <rect id="Rectangle_1191" width="13" height="1" transform="translate(0 -0.144)" fill="#969696" />
      <rect id="Rectangle_1192" width="13" height="1" transform="translate(0 3.856)" fill="#969696" />
      <rect id="Rectangle_1193" width="13" height="1" transform="translate(0 7.856)" fill="#969696" />
    </g>
  </svg>
)

const CalendarIcon = (props) => (
  <svg id="Group_2879" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" {...props}>
    <path id="Path_1067" d="M0,0H15V15H0Z" fill="#fff" />
    <g id="Group_2878" transform="translate(2.138 1.882)">
      <path
        id="Path_1001"
        d="M-13960,7522.436v10.374h10.72v0H-13960Z"
        transform="translate(13960.001 -7521.901)"
        fill="none"
        stroke="#7b7b7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        id="Path_1003"
        d="M-13960,7526.314h10.908"
        transform="translate(13960 -7524.231)"
        fill="none"
        stroke="#7b7b7b"
        strokeWidth="0.5"
      />
      <path
        id="Path_1004"
        d="M-13958.029,7529.238l1.378-1.379v2.965"
        transform="translate(13960.972 -7522.89)"
        fill="none"
        stroke="#7b7b7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_1005"
        d="M-13958.441,7527.859h1.64l-1.447,2.96"
        transform="translate(13964.329 -7522.89)"
        fill="none"
        stroke="#7b7b7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_1006"
        d="M-13956.926,7522.573c0-1.116,1.55-.9,1.55,0"
        transform="translate(13956.926 -7521.813)"
        fill="none"
        stroke="#7b7b7b"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
      <path
        id="Path_1007"
        d="M-13956.926,7522.573c0-1.116,1.55-.9,1.55,0"
        transform="translate(13959.983 -7521.813)"
        fill="none"
        stroke="#7b7b7b"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
      <path
        id="Path_1008"
        d="M-13956.926,7522.573c0-1.116,1.55-.9,1.55,0"
        transform="translate(13963.038 -7521.813)"
        fill="none"
        stroke="#7b7b7b"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
      <path
        id="Path_1009"
        d="M-13956.926,7522.573c0-1.116,1.55-.9,1.55,0"
        transform="translate(13966.097 -7521.813)"
        fill="none"
        stroke="#7b7b7b"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
    </g>
  </svg>
)

const BarChartIcon = (props) => (
  <svg id="Group_3255" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" {...props}>
    <path id="Path_1069" d="M0,0H15V15H0Z" fill="#fff" />
    <g id="Group_2882" transform="translate(1 2)">
      <g id="Group_2760">
        <g id="Rectangle_1194" fill="none" stroke="#7b7b7b" strokeWidth="0.5">
          <rect width="13" height="2" rx="1" stroke="none" />
          <rect x="0.25" y="0.25" width="12.5" height="1.5" rx="0.75" fill="none" />
        </g>
        <g id="Rectangle_1195" transform="translate(0 3)" fill="none" stroke="#7b7b7b" strokeWidth="0.5">
          <rect width="7" height="2" rx="1" stroke="none" />
          <rect x="0.25" y="0.25" width="6.5" height="1.5" rx="0.75" fill="none" />
        </g>
        <g id="Rectangle_1196" transform="translate(0 6)" fill="none" stroke="#7b7b7b" strokeWidth="0.5">
          <rect width="10" height="2" rx="1" stroke="none" />
          <rect x="0.25" y="0.25" width="9.5" height="1.5" rx="0.75" fill="none" />
        </g>
        <g id="Rectangle_1202" transform="translate(0 9)" fill="none" stroke="#7b7b7b" strokeWidth="0.5">
          <rect width="4" height="2" rx="1" stroke="none" />
          <rect x="0.25" y="0.25" width="3.5" height="1.5" rx="0.75" fill="none" />
        </g>
      </g>
    </g>
  </svg>
)

const SquareIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9.9" height="9.9" viewBox="0 0 9.9 9.9" {...props}>
    <path
      id="Path_1071"
      d="M-13960,7523.681v9.4h9.4v-9.4Z"
      transform="translate(13960.248 -7523.431)"
      fill="none"
      stroke="#7b7b7b"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
    />
  </svg>
)

const ArrowLeft = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8.893" height="15.769" viewBox="0 0 8.893 15.769" {...props}>
    <path
      id="Path_1192"
      d="M-13184.193-3499l7.26,6.788,7.137-6.788"
      transform="translate(-3490.838 13184.876) rotate(90)"
      fill="none"
      stroke="#7b7b7b"
      strokeWidth="2"
    />
  </svg>
)

const WhiteMagnifyingGlassIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56.391" height="57.782" viewBox="0 0 56.391 57.782" {...props}>
    <g id="Group_3439" transform="translate(29 -103)">
      <g id="Ellipse_253" transform="translate(-29 103)" fill="none" stroke="#fff" strokeWidth="6">
        <circle cx="19" cy="19" r="19" stroke="none" />
        <circle cx="19" cy="19" r="17" fill="none" />
      </g>
      <path id="Path_1220" d="M1.468,134.859l24.509,24.509" fill="none" stroke="#fff" strokeWidth="6" />
    </g>
  </svg>
)

const ImageLayersIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.992" height="16.102" viewBox="0 0 17.992 16.102" {...props}>
    <g id="matches_light" transform="translate(0.637 0.879)">
      <path
        id="Path_1135"
        d="M-10592.92,13292.257l7.4,4.08,7.4-4.08-7.4-4.08Z"
        transform="translate(10593.878 -13288.177)"
        fill="none"
        stroke="#3b3b3b"
        strokeWidth="1.54"
      />
      <path
        id="Path_1136"
        d="M-10593.375,13299.665l8.417,4.718,8.417-4.718"
        transform="translate(10593.375 -13292.988)"
        fill="none"
        stroke="#3b3b3b"
        strokeWidth="1.54"
      />
      <path
        id="Path_1137"
        d="M-10593.375,13299.665l8.417,4.718,8.417-4.718"
        transform="translate(10593.375 -13290.043)"
        fill="none"
        stroke="#3b3b3b"
        strokeWidth="1.54"
      />
    </g>
  </svg>
)

const TrashBinIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.633" height="17.712" viewBox="0 0 15.633 17.712">
    <g id="Group_2966" transform="translate(0.6 0.6)">
      <path
        id="Path_1091"
        d="M39.443,42.037l2.938,13.447h7.2L52.5,42.037"
        transform="translate(-38.75 -38.972)"
        fill="none"
        stroke="#3b3b3b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        id="Path_1093"
        d="M23.882,46.287l-1.735,3.282H36.58l-1.418-3.285Z"
        transform="translate(-22.147 -46.284)"
        fill="#fff"
        stroke="#3b3b3b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        id="Path_1094"
        d="M29.056,42.949l1.121,7.343"
        transform="translate(-24.282 -36.979)"
        fill="none"
        stroke="#3b3b3b"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        id="Path_1095"
        d="M30.176,42.949l-1.121,7.343"
        transform="translate(-20.826 -36.979)"
        fill="none"
        stroke="#3b3b3b"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        id="Path_1096"
        d="M27.117,38.242l.755,2.826"
        transform="translate(-25.316 -38.028)"
        fill="none"
        stroke="#3b3b3b"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        id="Path_1097"
        d="M39.141,37.771l-1.2,2.892"
        transform="translate(-26.354 -37.528)"
        fill="none"
        stroke="#3b3b3b"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
    </g>
  </svg>
)

const FalseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.311" height="16.308" viewBox="0 0 16.311 16.308" {...props}>
    <g id="Group_2820" transform="translate(0.244 0.707)">
      <g id="Group_3139">
        <g id="Ellipse_248" transform="translate(0 0.301)" fill="#fff" stroke="#3b3b3b" strokeWidth="2">
          <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
          <circle cx="7.5" cy="7.5" r="6.5" fill="none" />
        </g>
        <path
          id="Path_1138"
          d="M-18586.846-209.517l14.9-14.894"
          transform="translate(18587.309 224.411)"
          fill="none"
          stroke="#3b3b3b"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

const CaseSubmissionIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="58.025" height="58.677" viewBox="0 0 58.025 58.677" {...props}>
    <g id="Group_3269" transform="translate(-0.047)">
      <g id="Group_3154" transform="translate(8.852 15.513)">
        <path
          id="Path_1142"
          d="M31.846,21H20.407A1.771,1.771,0,0,0,18.5,22.6a1.771,1.771,0,0,0,1.907,1.6H31.846a1.771,1.771,0,0,0,1.907-1.6A1.771,1.771,0,0,0,31.846,21Z"
          transform="translate(-18.5 -21)"
          fill="#212121"
        />
        <path
          id="Path_1143"
          d="M29.407,24.194H33.22a1.771,1.771,0,0,0,1.907-1.6A1.771,1.771,0,0,0,33.22,21H29.407A1.771,1.771,0,0,0,27.5,22.6,1.771,1.771,0,0,0,29.407,24.194Z"
          transform="translate(-10.339 -21)"
          fill="#212121"
        />
        <path
          id="Path_1144"
          d="M27.22,29H23.407A1.771,1.771,0,0,0,21.5,30.6a1.771,1.771,0,0,0,1.907,1.6H27.22a1.771,1.771,0,0,0,1.907-1.6A1.771,1.771,0,0,0,27.22,29Z"
          transform="translate(-15.78 -16.224)"
          fill="#212121"
        />
        <path
          id="Path_1145"
          d="M33.22,29H29.407A1.771,1.771,0,0,0,27.5,30.6a1.771,1.771,0,0,0,1.907,1.6H33.22a1.771,1.771,0,0,0,1.907-1.6A1.771,1.771,0,0,0,33.22,29Z"
          transform="translate(-10.339 -16.224)"
          fill="#212121"
        />
        <path
          id="Path_1146"
          d="M29.939,26.6A1.771,1.771,0,0,0,28.033,25H20.407A1.771,1.771,0,0,0,18.5,26.6a1.771,1.771,0,0,0,1.907,1.6h7.626A1.771,1.771,0,0,0,29.939,26.6Z"
          transform="translate(-18.5 -18.612)"
          fill="#212121"
        />
        <path
          id="Path_1147"
          d="M37.939,25H28.407A1.771,1.771,0,0,0,26.5,26.6a1.771,1.771,0,0,0,1.907,1.6h9.533a1.771,1.771,0,0,0,1.907-1.6A1.771,1.771,0,0,0,37.939,25Z"
          transform="translate(-11.246 -18.612)"
          fill="#212121"
        />
      </g>
      <g id="Group_3152">
        <g id="Ellipse_229" transform="translate(0.047)" fill="none" stroke="#000" strokeWidth="2">
          <circle cx="22.5" cy="22.5" r="22.5" stroke="none" />
          <circle cx="22.5" cy="22.5" r="21.5" fill="none" />
        </g>
        <path
          id="Path_1049"
          d="M10597.393,2823.6l20.895,20.884"
          transform="translate(-10560.922 -2786.511)"
          fill="none"
          stroke="#000"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

const TakeDownIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="54.758" height="70.75" viewBox="0 0 54.758 70.75" {...props}>
    <path
      id="Path_1150"
      d="M15.179,0,0,34.8H18.218V59.256l16.13-32.775H18.218Z"
      transform="matrix(0.951, 0.309, -0.309, 0.951, 20.201, 1.89)"
      fill="#fff"
      stroke="#404040"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
)

const FlagIconV2 = (props) => (
  <svg viewBox="0 0 17.985 22.482" height="22.48">
    <g id="Group_1568_-_flag" data-name="Group 1568 - flag" transform="translate(-1640.858 -2436.322)">
      <path
        id="Path_746"
        data-name="Path 746"
        d="M-12712.81,22063.865v12.047h16.982l-3.34-5.781,3.34-6.266Z"
        transform="translate(14354 -19626)"
      />
      <path
        id="flag"
        d="M20.306,7.494l3.623-5.8a.375.375,0,0,0-.318-.573H6.749V.375A.375.375,0,1,0,6,.375V22.107a.375.375,0,1,0,.749,0V13.864H23.61a.375.375,0,0,0,.318-.574ZM6.749,13.114V1.873H22.934L19.546,7.3a.375.375,0,0,0,0,.4l3.388,5.421Z"
        transform="translate(1634.858 2436.322)"
      />
    </g>
  </svg>
)

const EmptyFlagIcon = (props) => (
  <svg width={10.78} height={7.518} viewBox="0 0 10.78 7.518" {...props}>
    <defs>
      <linearGradient id="prefix__a" y1={0.5} x2={1} y2={0.5} gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#c7c7c7" />
        <stop offset={0.256} stopColor="#e8e8e8" />
        <stop offset={0.34} stopColor="#e8e8e8" />
        <stop offset={0.341} stopColor="#e8e8e8" />
        <stop offset={0.475} stopColor="#e8e8e8" />
        <stop offset={0.476} stopColor="#e8e8e8" />
        <stop offset={0.485} stopColor="#e8e8e8" />
        <stop offset={0.554} stopColor="#e8e8e8" />
        <stop offset={0.778} stopColor="#e8e8e8" />
        <stop offset={1} stopColor="#c7c7c7" />
      </linearGradient>
    </defs>
    <g data-name="Group 3249">
      <g data-name="Group 3237">
        <path
          data-name="Path 1019"
          d="M-13925.133 7261.047a8.965 8.965 0 005.627 0c3.094-1.006 4.9 0 4.9 0v6.349a7.461 7.461 0 00-4.773 0 11.574 11.574 0 01-5.757 0z"
          transform="translate(13925.258 -7260.42)"
          stroke="#7b7b7b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.25}
          opacity={0.7}
          fill="url(#prefix__a)"
        />
      </g>
    </g>
  </svg>
)

const ArrowDown = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.399" height="8.559" viewBox="0 0 15.399 8.559" {...props}>
    <path
      d="M-13184.193-3499l7.082,6.461,6.963-6.461"
      transform="translate(13184.867 3499.739)"
      fill="none"
      stroke="#3b3b3b"
      strokeWidth="2"
    />
  </svg>
)

const BackArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.442" height="10.044" viewBox="0 0 10.442 10.044" {...props}>
    <g id="Group_2932" transform="translate(0.749 1.06)">
      <path
        id="Path_972"
        d="M-9540.25,7069.873s2.6-5.151-.494-5.151h-4.074"
        transform="translate(9548.171 -7061.897)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_973"
        d="M-9544.515,7061.232l-2.9,2.895,2.9,2.895"
        transform="translate(9547.41 -7061.232)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

const DollarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.134" height="33.487" viewBox="0 0 18.134 33.487" {...props}>
    <g transform="translate(-1966.723 -1238.482)">
      <path
        d="M12.005-28.518V-24.3a23,23,0,0,1,2.45.661A13.5,13.5,0,0,1,17-22.491l-1.568,4.018a10.048,10.048,0,0,0-2.523-1.078,10.041,10.041,0,0,0-2.719-.392,4.5,4.5,0,0,0-2.328.539,1.721,1.721,0,0,0-.906,1.568,1.846,1.846,0,0,0,.784,1.593,9.989,9.989,0,0,0,2.6,1.151L12.1-14.5q6.174,1.911,6.174,7.154a6.723,6.723,0,0,1-.441,2.426,6.465,6.465,0,0,1-1.3,2.082A6.874,6.874,0,0,1,14.382-1.3a9.517,9.517,0,0,1-2.965.808V3.969H7.546V-.392a20.118,20.118,0,0,1-3.308-.637A10.09,10.09,0,0,1,1.274-2.45L3.185-6.566A10.414,10.414,0,0,0,6.051-5.145,9.63,9.63,0,0,0,8.869-4.7a4.544,4.544,0,0,0,2.818-.686,2.067,2.067,0,0,0,.858-1.666,2.111,2.111,0,0,0-.686-1.617A7.048,7.048,0,0,0,9.31-9.9L7.4-10.535a13.05,13.05,0,0,1-2.4-1,8.466,8.466,0,0,1-1.96-1.446,6.368,6.368,0,0,1-1.323-1.936,6.156,6.156,0,0,1-.49-2.523,6.891,6.891,0,0,1,.515-2.695,6.14,6.14,0,0,1,1.445-2.107,6.955,6.955,0,0,1,2.2-1.4,8.852,8.852,0,0,1,2.744-.613v-4.263Z"
        transform="translate(1966.08 1267.5)"
        fill="#fff"
        stroke="#939393"
        strokeWidth="1"
      />
    </g>
  </svg>
)

const DollarIconLineThrough = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" {...props}>
    <g transform="translate(-1958.787 -1311)">
      <g transform="translate(1958.787 1311)" fill="#fff">
        <path
          d="M 19.5 38.5 C 16.93479919433594 38.5 14.44659042358398 37.99771118164062 12.10449981689453 37.0070915222168 C 9.842049598693848 36.0501594543457 7.810060024261475 34.68011856079102 6.064970016479492 32.93503189086914 C 4.31988000869751 31.18993949890137 2.949840068817139 29.15794944763184 1.992910027503967 26.89550018310547 C 1.002290010452271 24.55340957641602 0.5 22.06520080566406 0.5 19.5 C 0.5 16.93479919433594 1.002290010452271 14.44659042358398 1.992910027503967 12.10449981689453 C 2.949840068817139 9.842049598693848 4.31988000869751 7.810060024261475 6.064970016479492 6.064970016479492 C 7.810060024261475 4.31988000869751 9.842049598693848 2.949840068817139 12.10449981689453 1.992910027503967 C 14.44659042358398 1.002290010452271 16.93479919433594 0.5 19.5 0.5 C 22.06520080566406 0.5 24.55340957641602 1.002290010452271 26.89550018310547 1.992910027503967 C 29.15794944763184 2.949840068817139 31.18993949890137 4.31988000869751 32.93503189086914 6.064970016479492 C 34.68011856079102 7.810060024261475 36.0501594543457 9.842049598693848 37.0070915222168 12.10449981689453 C 37.99771118164062 14.44659042358398 38.5 16.93479919433594 38.5 19.5 C 38.5 22.06520080566406 37.99771118164062 24.55340957641602 37.0070915222168 26.89550018310547 C 36.0501594543457 29.15794944763184 34.68011856079102 31.18993949890137 32.93503189086914 32.93503189086914 C 31.18993949890137 34.68011856079102 29.15794944763184 36.0501594543457 26.89550018310547 37.0070915222168 C 24.55340957641602 37.99771118164062 22.06520080566406 38.5 19.5 38.5 Z"
          stroke="none"
        />
        <path
          d="M 19.5 1 C 17.0020809173584 1 14.57939910888672 1.488998413085938 12.29927062988281 2.453411102294922 C 10.0964298248291 3.385139465332031 8.117860794067383 4.719188690185547 6.418529510498047 6.418529510498047 C 4.719188690185547 8.117860794067383 3.385139465332031 10.0964298248291 2.453411102294922 12.29927062988281 C 1.488998413085938 14.57939910888672 1 17.0020809173584 1 19.5 C 1 21.9979190826416 1.488998413085938 24.42060089111328 2.453411102294922 26.70072937011719 C 3.385139465332031 28.9035701751709 4.719188690185547 30.88214111328125 6.418529510498047 32.58147048950195 C 8.117860794067383 34.28081130981445 10.0964298248291 35.61486053466797 12.29927062988281 36.54658889770508 C 14.57939910888672 37.51100158691406 17.0020809173584 38 19.5 38 C 21.9979190826416 38 24.42060089111328 37.51100158691406 26.70072937011719 36.54658889770508 C 28.9035701751709 35.61486053466797 30.88214111328125 34.28081130981445 32.58147048950195 32.58147048950195 C 34.28081130981445 30.88214111328125 35.61486053466797 28.9035701751709 36.54658889770508 26.70072937011719 C 37.51100158691406 24.42060089111328 38 21.9979190826416 38 19.5 C 38 17.0020809173584 37.51100158691406 14.57939910888672 36.54658889770508 12.29927062988281 C 35.61486053466797 10.0964298248291 34.28081130981445 8.117860794067383 32.58147048950195 6.418529510498047 C 30.88214111328125 4.719188690185547 28.9035701751709 3.385139465332031 26.70072937011719 2.453411102294922 C 24.42060089111328 1.488998413085938 21.9979190826416 1 19.5 1 M 19.5 0 C 30.26955032348633 0 39 8.730449676513672 39 19.5 C 39 30.26955032348633 30.26955032348633 39 19.5 39 C 8.730449676513672 39 0 30.26955032348633 0 19.5 C 0 8.730449676513672 8.730449676513672 0 19.5 0 Z"
          stroke="none"
          fill="#939393"
        />
      </g>
      <path
        d="M11.025-26.19v3.87a21.119,21.119,0,0,1,2.25.608,12.4,12.4,0,0,1,2.34,1.058l-1.44,3.69a9.228,9.228,0,0,0-2.318-.99,9.221,9.221,0,0,0-2.5-.36,4.136,4.136,0,0,0-2.138.5,1.581,1.581,0,0,0-.832,1.44,1.7,1.7,0,0,0,.72,1.462A9.174,9.174,0,0,0,9.495-13.86l1.62.54q5.67,1.755,5.67,6.57a6.175,6.175,0,0,1-.405,2.228A5.937,5.937,0,0,1,15.188-2.61a6.313,6.313,0,0,1-1.98,1.417,8.741,8.741,0,0,1-2.723.743V3.645H6.93v-4A18.476,18.476,0,0,1,3.892-.945,9.266,9.266,0,0,1,1.17-2.25L2.925-6.03A9.564,9.564,0,0,0,5.557-4.725a8.844,8.844,0,0,0,2.587.4,4.173,4.173,0,0,0,2.588-.63,1.9,1.9,0,0,0,.788-1.53,1.938,1.938,0,0,0-.63-1.485A6.473,6.473,0,0,0,8.55-9.09L6.795-9.675a11.985,11.985,0,0,1-2.2-.922,7.775,7.775,0,0,1-1.8-1.327A5.849,5.849,0,0,1,1.575-13.7a5.653,5.653,0,0,1-.45-2.318A6.329,6.329,0,0,1,1.6-18.5,5.639,5.639,0,0,1,2.925-20.43,6.387,6.387,0,0,1,4.95-21.712a8.129,8.129,0,0,1,2.52-.562V-26.19Z"
        transform="translate(1969.287 1341.5)"
        fill="none"
        stroke="#939393"
        strokeWidth="1"
      />
      <path d="M0,23,31,0" transform="translate(1962.287 1318.5)" fill="none" stroke="#939393" strokeWidth="1" />
    </g>
  </svg>
)

const PixsyJurisdictionsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.091" height="8.911" viewBox="0 0 13.091 8.911" {...props}>
    <defs>
      <linearGradient id="a" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#046d86" />
        <stop offset="0.256" stopColor="#008aab" />
        <stop offset="0.778" stopColor="#008aab" />
        <stop offset="1" stopColor="#015b71" />
      </linearGradient>
    </defs>
    <g transform="translate(0 0)">
      <path
        d="M-13925.132,7261.156a11.141,11.141,0,0,0,7,0c3.847-1.25,6.1,0,6.1,0v7.894a9.274,9.274,0,0,0-5.935,0,14.394,14.394,0,0,1-7.156,0Z"
        transform="translate(13925.132 -7260.6)"
        opacity="0.7"
        fill="url(#a)"
      />
      <path
        d="M1.124-.946a.4.4,0,0,0-.144-.33.553.553,0,0,0-.36-.1H.126V0H.45V-.51H.62a.553.553,0,0,0,.36-.1A.409.409,0,0,0,1.124-.946ZM.8-.946A.177.177,0,0,1,.73-.8.254.254,0,0,1,.574-.76H.448v-.37H.574a.254.254,0,0,1,.156.038A.173.173,0,0,1,.8-.946Zm1.21-.434H1.682V0h.324Zm1.388.664.43-.664H3.44l-.258.454L2.924-1.38H2.54l.428.664L2.51,0H2.9l.286-.514L3.466,0h.386Zm1.846.3a.361.361,0,0,0-.108-.266A.554.554,0,0,0,4.94-.8C4.9-.816,4.794-.852,4.734-.876s-.14-.056-.14-.14.076-.124.18-.124A.476.476,0,0,1,5.082-1l.138-.226a.68.68,0,0,0-.438-.17.564.564,0,0,0-.364.11.389.389,0,0,0-.144.3.341.341,0,0,0,.108.266.539.539,0,0,0,.164.1c.046.02.15.056.21.078s.162.058.162.152-.08.142-.184.142a.569.569,0,0,1-.36-.174l-.16.236A.748.748,0,0,0,4.7.024.587.587,0,0,0,5.078-.09.418.418,0,0,0,5.24-.42Zm1.288-.96-.282.55-.282-.55H5.6l.48.822V0h.324V-.558l.48-.822Z"
        transform="translate(3.14 5.341)"
        fill="#fff"
      />
    </g>
  </svg>
)

export {
  StarIcon,
  CrossIcon,
  WhiteCheckIcon,
  EyeIcon,
  TIcon,
  LinkIcon,
  ImageIcon,
  ThreeDotsIcon,
  SearchIcon,
  RefreshIcon,
  FlagIcon,
  FlagIconV2,
  ThreeDashesIcon,
  CalendarIcon,
  BarChartIcon,
  SquareIcon,
  ArrowLeft,
  WhiteMagnifyingGlassIcon,
  ImageLayersIcon,
  TrashBinIcon,
  FalseIcon,
  CaseSubmissionIcon,
  TakeDownIcon,
  EmptyFlagIcon,
  ArrowDown,
  BackArrowIcon,
  DollarIcon,
  DollarIconLineThrough,
  PixsyJurisdictionsIcon,
}
