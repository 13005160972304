import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { DATA_ELEVIO } from 'pixsy-constants'
import { css } from 'react-emotion'

export default function KeyboardOverlay({ isVisible }) {
  return (
    <React.Fragment>
      <div css={keyboardShortcutsButton}>
        <span data-elevio-selector={DATA_ELEVIO.MATCHES_VIEWER_SHORT_KEYS}>
          Press and hold shift to display keyboard shortcuts
        </span>
      </div>

      <div
        className={cn(keyboardShortcuts, {
          [keyboardShortcutsOn]: isVisible,
        })}
      >
        <div dangerouslySetInnerHTML={{ __html: require(`./keyboard.svg`) }} />
        <div className={keyboardShortcutsText}>
          <h3>A</h3>
          <p>Hide selected match as approved use</p>
          <h3>N</h3>
          <p>Hide selected match as “not my image”</p>
          <h3>I</h3>
          <p>Ignore selected match</p>
          <h3>D</h3>
          <p>Open domain ignore/approve selector</p>
        </div>
        <div className={keyboardShortcutsText}>
          <h3>Up & Down</h3>
          <p>Navigate between matches in the same domain group</p>
          <h3>Left & Right</h3>
          <p>Navigate between next/previous group</p>
          <h3>U</h3>
          <p>Undo last match action</p>
          <h3>Escape </h3>
          <p>Exit the match viewer</p>
        </div>
      </div>
    </React.Fragment>
  )
}

KeyboardOverlay.propTypes = {
  isVisible: PropTypes.bool.isRequired,
}

const keyboardShortcutsButton = css`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  height: 45px;
  color: #616161;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.2em;
  font-size: 0.9em;
  > span {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 6px 18px;
    border-radius: 4px;
    color: rgba(190, 190, 190);
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const keyboardShortcuts = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease;
  svg {
    width: 50vw;
    g {
      stroke: rgba(255, 255, 255, 0.5);
    }
  }
`
const keyboardShortcutsText = css`
  width: 15vw;
  padding-left: 2vw;
  p {
    color: rgb(235, 235, 235);
    margin-bottom: 20px;
  }
  h3 {
    font-weight: 600;
    font-size: 1.3em;
    letter-spacing: -0.025em;
    color: #008aab;
    margin: 0;
  }
`

const keyboardShortcutsOn = css`
  opacity: 1 !important;
`
