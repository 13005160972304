import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { Elements, injectStripe } from 'react-stripe-elements'
import { notifSend } from 'redux-notifications/lib/actions'
import {
  StripeCardInputInline,
  AlignLabels,
  TextFieldNoLabel,
  CountrySelectStandalone,
  Button,
  SexySeparator,
  // TextFieldPlain,
} from 'common'
import COUNTRIES from 'pixsy-constants/countries.json'
import { handleStripePayment } from '../../../../../redux/modules/registrations'

class StripeForm extends Component {
  constructor(props) {
    super(props)
    const { user } = props
    this.state = {
      name: user.details.legal_name,
      street: user.details.street,
      postalCode: user.details.postal_code,
      state: user.details.state,
      city: user.details.city,
      country: user.details.country,
      email: user.email,
    }
  }

  handleChange = key => e => {
    e.preventDefault()
    this.setState({ [key]: e.target.value })
  }

  handleSubmit = async values => {
    const { stripe, registration, notifSend, dispatch } = this.props

    const country = COUNTRIES.find(
      country => country.country === this.state.country
    )

    if (!stripe) {
      return notifSend({
        message:
          'Our payment partner Stripe is unable to verify the authenticity of your payment. Please disable any content blocking features and/or AdBlockers on this page, reload, and try again.',
        kind: 'warning',
        dismissAfter: 15e3,
      })
    }

    const response = await stripe.createToken({
      name: this.state.name,
      address_line1: this.state.street,
      address_line2: this.state.postalCode,
      address_city: this.state.city,
      address_state: this.state.state,
      address_country: country ? country.iso : null,
    })

    if (response.error) {
      notifSend({
        message: response.error.message,
        kind: 'warning',
        dismissAfter: 6000,
      })
    } else {
      const coupon =
        (typeof registration.couponUsed === 'string' &&
          registration.couponUsed.length > 0 &&
          registration.couponUsed) ||
        undefined
      return dispatch(
        handleStripePayment(registration._id, {
          token: response.token.id,
          registrationId: registration.registration__c,
          email: this.state.email,
          coupon,
        })
      )
    }
  }

  render() {
    const { handleSubmit, submitting } = this.props
    return (
      <form css={wrap} onSubmit={handleSubmit(this.handleSubmit)}>
        <AlignLabels align="right" width="190px">
          <Field
            name="name"
            label="Name on Card"
            input={{
              value: this.state.name,
              onChange: this.handleChange('name'),
            }}
            component={TextFieldNoLabel}
          />
          <Field
            name="street"
            label="Street"
            input={{
              value: this.state.street,
              onChange: this.handleChange('street'),
            }}
            component={TextFieldNoLabel}
          />
          <div css={half}>
            <div>
              <Field
                name="postal_code"
                label="Postal Code"
                input={{
                  value: this.state.postalCode,
                  onChange: this.handleChange('postalCode'),
                }}
                component={TextFieldNoLabel}
              />
            </div>
            <div>
              <Field
                name="city"
                label="City"
                input={{
                  value: this.state.city,
                  onChange: this.handleChange('city'),
                }}
                component={TextFieldNoLabel}
              />
            </div>
          </div>
          <div css={half}>
            <div>
              <Field
                name="state"
                label="State"
                input={{
                  value: this.state.state,
                  onChange: this.handleChange('state'),
                }}
                component={TextFieldNoLabel}
              />
            </div>
            <div>
              <Field
                name="country"
                label="Country"
                input={{
                  value: this.state.country,
                  onChange: this.handleChange('country'),
                }}
                component={CountrySelectStandalone}
                embedded
              />
            </div>
          </div>
          <SexySeparator space={20} />

          <Field
            type="text"
            label="Card Number"
            name="card"
            component={StripeCardInputInline}
          />

          <Field
            name="email"
            label="Email for Receipt"
            input={{
              value: this.state.email,
              onChange: this.handleChange('email'),
            }}
            component={TextFieldNoLabel}
          />

          {/* <Field
            name="coupon"
            label="Coupon"
            maxLength={50}
            component={TextFieldPlain}
          /> */}

          <div css={buttonAndStripe}>
            <Button submit disabled={submitting}>
              Secure Payment
            </Button>
            <a href="https://stripe.com" target="_blank">
              <div css={stripeWrap}>
                <img src="/img/powered_by_stripe.svg" />
              </div>
            </a>
          </div>
        </AlignLabels>
      </form>
    )
  }
}

const ConnectedStripePaymentForm = reduxForm({
  form: 'registrationPaymentForm',
  onSubmit: (values, dispatch, props) =>
    dispatch(handleStripePayment(values)).then(
      () => props.onSuccess && props.onSuccess()
    ),
})(connect(null, { notifSend })(injectStripe(StripeForm)))

export default function StripePaymentForm(props) {
  return (
    <Elements>
      <ConnectedStripePaymentForm {...props} />
    </Elements>
  )
}

const wrap = css`
  position: relative;
  & > div[class*='-Wrap'],
  & > div[class*='-half'] {
    margin-top: 25px;
  }
`

const buttonAndStripe = css`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
`

const stripeWrap = css`
  margin-top: 10px;
`

const half = css`
  display: flex;
  justify-content: space-between;
  > * {
    flex-basis: 48% !important;
  }
  flex: 1;
`

// export class StripePaymentForm extends React.Component {

//   static propTypes = {
//     handleStripePayment: PropTypes.func,
//     disabled: PropTypes.bool,
//     price: PropTypes.object.isRequired,
//     defaultValues: PropTypes.object,
//     onSuccess: PropTypes.func,
//     description: PropTypes.string,
//     requestId: PropTypes.string,
//     onUpdate: PropTypes.func,
//   }

//   static defaultProps = {
//     disabled: false,
//   }
//   handleSubmit(event) {
//     event.preventDefault()
//     const {
//       handleStripePayment,
//       onSuccess,
//       requestId,
//       price,
//       description,
//     } = this.props
//     const payload = Object.entries(this.state).reduce(
//       (acc, [key, { value }]) => ({
//         ...acc,
//         [key]: value,
//       }),
//       { price, description }
//     )

//     handleStripePayment(requestId, payload).then(() => onSuccess && onSuccess())
//   }

//   isFieldValid(key) {
//     const value = this.state[key].value

//     switch (key) {
//       case 'expiry':
//         return -1 !== value.search(/^(0[1-9]|1[0-2])[0-9][0-9]$/)
//       default:
//         return Boolean(value.length)
//     }
//   }

//   isFormValid() {
//     return Object.keys(this.state).every(::this.isFieldValid)
//   }

//   handleChange(event) {
//     const { name, value } = event.target
//     const { onUpdate } = this.props
//     onUpdate && onUpdate(name, value, this.state)
//     this.setState(state => ({
//       [name]: { ...state[name], value },
//     }))
//   }
//   render() {
//     const { disabled, onSuccess } = this.props
//     const { stripeLogo } = this.styles

//     const resolveStyleFor = key => {
//       if (this.state[key].touched) {
//         return this.isFieldValid(key) ? 'success' : 'error'
//       }
//     }

//     const getFormPropsFor = key => ({
//       name: key,
//       value: this.state[key].value,
//       bsStyle: resolveStyleFor(key),
//       onChange: this.handleChange,
//     })

//     /*

//         <Field
//           name="schedule.report.cycle"
//           label="Match Report"
//           component={NotificationSelect}
//         />

// */

//       <form
//         onSubmit={this.handleSubmit}
//         className="hideSpinner"
//         id="stripe-form"
//         name="StripePaymentForm"
//         role="form"
//       >
//         <Row>
//           <Col sm={12}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="user" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="text"
//                   size="32"
//                   placeholder="Name on Card"
//                   required
//                   {...getFormPropsFor('name')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//           <Col sm={12}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="user" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="text"
//                   size="32"
//                   placeholder="Street"
//                   required
//                   {...getFormPropsFor('street')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//           <Col sm={6}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="user" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="text"
//                   size="32"
//                   placeholder="Postal Code"
//                   required
//                   {...getFormPropsFor('postal_code')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//           <Col sm={6}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="user" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="text"
//                   size="32"
//                   placeholder="City"
//                   required
//                   {...getFormPropsFor('city')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//           <Col sm={6}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="user" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="text"
//                   size="32"
//                   placeholder="State"
//                   required
//                   {...getFormPropsFor('state')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//           <Col sm={6}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="user" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="text"
//                   size="32"
//                   placeholder="Country"
//                   required
//                   {...getFormPropsFor('country')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//           <Col sm={12}>
//             <hr />
//           </Col>
//         </Row>
//         <Row>
//           <Col sm={12}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="credit-card" />
//                 </InputGroup.Addon>
//                 <CreditCardInput
//                   type="text"
//                   placeholder="Card Number"
//                   required
//                   {...getFormPropsFor('number')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//           <Col sm={12} md={6}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="calendar" />
//                 </InputGroup.Addon>
//                 <BootstrapNumberInputFix
//                   size="4"
//                   maxLength="4"
//                   placeholder="MMYY"
//                   required
//                   {...getFormPropsFor('expiry')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//           <Col sm={12} md={6}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="lock" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="text"
//                   size="4"
//                   maxLength="4"
//                   placeholder="CVC"
//                   required
//                   {...getFormPropsFor('cvc')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//           <Col sm={12}>
//             <FormGroup>
//               <InputGroup>
//                 <InputGroup.Addon>
//                   <Glyphicon glyph="envelope" />
//                 </InputGroup.Addon>
//                 <FormControl
//                   type="email"
//                   placeholder="Email for receipt"
//                   required
//                   {...getFormPropsFor('email')}
//                 />
//               </InputGroup>
//             </FormGroup>
//           </Col>
//         </Row>
//         {onSuccess && (
//           <Row>
//             <Col sm={12}>
//               <Button
//                 className="btn-action"
//                 bsSize="large"
//                 block
//                 disabled={disabled || !this.isFormValid()}
//                 type="submit"
//               >
//                 Submit Payment
//               </Button>
//               {/* <a href="https://stripe.com/us/privacy/" target="_blank">
//                 <img
//                   className={stripeLogo}
//                   src={require('common/CardForm/stripe.png')}
//                 />
//               </a> */}
//             </Col>
//           </Row>
//         )}
//       </form>
//     )
//   }
// }

// StripePaymentForm.propTypes = {
//   // handleSubmit: PropTypes.func.isRequired,
//   // submitting: PropTypes.bool.isRequired,
// }
