import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
/*
type ButtonPropsType = {
  type: ?(
    | 'primary'
    | 'secondary'
    | 'action'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'hollow'
  ),
  size: ?('small' | 'normal' | 'medium' | 'large' | 'full'),
  full: ?boolean,
  submit: ?boolean,
  onClick: ?Function,
  to: ?string | Object,
  bold: ?boolean,
  external: ?string,
  disabled: ?boolean,
  asDisabled: ?boolean,
  children: any,
}
*/

Button.defaultProps = {
  submit: false,
  disabled: false,
}

export default function Button({
  style,
  full,
  bold,
  submit,
  children,
  danger,
  center,
  fixedWidth,
  outline,
  to,
  external,
  onClick,
  disabled,
  asDisabled,
  className,
  title,
  highlightedFromDark,
  mini,
  medium,
  large,
  greyOutline,
  grey,
  greyTertiary,
  hoverMain,
  orange,
  withFocusOutline,
  domProps = {},
}) {
  const _className = cn(styles.button, className, {
    [styles.full]: full,
    [styles.bold]: bold,
  })

  const handlers = {}
  if (!disabled) {
    if (!submit && to) handlers.href = to
    if (onClick) handlers.onClick = onClick
  }

  const domPropsFinal = {
    ...domProps,
    ...(title && { title }),
  }

  const _c = cn(
    _className,
    danger && styles.danger,
    outline && styles.outline,
    center && styles.center,
    fixedWidth && styles.fixedWidth,
    outline && styles.outline,
    highlightedFromDark && styles.highlightedFromDark,
    mini && styles.mini,
    medium && styles.medium,
    large && styles.large,
    full && styles.large,
    greyOutline && styles.greyOutline,
    greyTertiary && styles.greyTertiary,
    grey && styles.grey,
    hoverMain && styles.hoverMain,
    orange && styles.orange,
    disabled && styles.disabledButton,
    asDisabled && styles.asDisabledButton,
    withFocusOutline && styles.withFocusOutline
  )

  if (handlers.href) {
    return (
      <a {...handlers} className={_c} {...domPropsFinal}>
        {children}
      </a>
    )
  }

  return (
    <button
      className={_c}
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      {...(onClick ? { onClick } : {})}
      {...domPropsFinal}
      data-testid="button-test"
    >
      {children}
    </button>
  )

  // return external ? (
  //   <a
  //     href={external}
  //     className={styles.buttonLink}
  //     onClick={onClick}
  //     target="_blank"
  //   >
  //     {button}
  //   </a>
  // ) : (
  //   <Link {...handlers} className={styles.buttonLink}>
  //     {button}
  //   </Link>
  // )
}

const styles = {}

styles.button = css`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: #008aab;
  display: inline-block;
  padding: 8px 45px;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  /* margin-top: 5px;
  margin-bottom: 5px; */
  white-space: nowrap;
  font-weight: 600;
  color: #fff;
  /* transition: background-color 250ms ease1; */
  a {
    color: #fff;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    /* &:hover {
        background-color: lighten(#008aab, 5%);
      } */
  }

  @media (max-width: 768px) {
    white-space: normal;
  }
`

styles.disabledButton = css`
  opacity: 0.6 !important;
  cursor: not-allowed !important;
`
styles.asDisabledButton = css`
  opacity: 0.6 !important;
`

styles.buttonLink = css`
  border-radius: 4px;
`

styles.mini = css`
  font-size: 12px;
  padding: 4px 15px;
`

styles.danger = css`
  background-color: #e66363 !important;
  &:hover {
    background-color: #b81d1d;
  }
`

styles.fixedWidth = css`
  width: 400px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  text-align: center;
`

styles.outline = css`
  background-color: white;
  border: solid 2px #008aab;
  color: #008aab;
  transition: all 200ms ease;
  &:hover {
    background-color: #009dc3;
    color: white;
    border-color: #009dc3;
  }
`

styles.greyOutline = css`
  color: hsl(0, 0%, 60%);
  background-color: transparent;
  border: solid 2px hsl(0, 0%, 96%);
  transition: all 200ms ease;
  &:hover {
    background-color: hsl(0, 0%, 96%);
    color: hsl(0, 0%, 38%);
  }
`

styles.greyTertiary = css`
  background-color: hsl(0, 0%, 15%);
  border: solid 2px hsl(0, 0%, 46%);
  color: hsl(0, 0%, 60%);
  transition: all 200ms ease;
  &:hover {
    background-color: hsl(0, 0%, 20%);
    color: hsl(0, 0%, 70%);
    border-color: hsl(0, 0%, 60%);
  }
`

styles.grey = css`
  color: hsl(0, 0%, 90%);
  background-color: hsl(0, 0%, 38%);
  border: solid 2px hsl(0, 0%, 90%);
  transition: all 200ms ease;
  &:hover {
    background-color: hsl(0, 0%, 42%);
    color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 100%);
  }
`

styles.hoverMain = css`
  transition: all 200ms ease;
  &:hover {
    background-color: #009dc3;
    border-color: #009dc3;
    color: white;
  }
`

styles.highlightedFromDark = css`
  background-color: #008aab !important;
  color: white !important;
  transition: all 200ms ease !important;
  border-color: #008aab !important;
  &:hover {
    background-color: #009dc3 !important;
    color: white !important;
    border-color: #009dc3 !important;
  }
`

styles.orange = css`
  background-color: #ff7f2f !important;
  /* outline: solid 2px #008aab; */
  &:hover {
    background-color: #ff9451 !important;
  }
`

styles.small = css`
  padding: 2px 15px;
  font-size: 15px;
`

styles.medium = css`
  padding: 5px 37px;
`

styles.large = css`
  padding: 12px 74px;
`

styles.full = css`
  width: 200px;
  display: inline-block;
  padding: 5px;
`

styles.center = css`
  display: block;
  margin: 10px auto;
`

styles.bold = css`
  font-weight: bold;
`

styles.withFocusOutline = css`
  &:focus {
    outline: 1px solid white !important;
    outline-offset: -4px !important;
  }
`
