import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'
import URI from 'urijs'

const log = require('debug')('form:path-selection-slider')

const PathSelectionSliderWrapper = styled.div`
  margin: 50px auto;
  word-break: break-all;
  figure {
    font-size: 2em;

    @media (max-width: 768px) {
      font-size: 1em;
      padding: 0 10px;
    }

    span {
      color: #555555;
    }
    em {
      color: #ffffff;
      font-style: normal;
    }
  }
`
const whiteThemeWrapper = css`
  figure {
    span {
      color: #c3c3c3;
    }
    em {
      color: #000;
    }
  }
`

const StyledInput = styled.input`
  appearance: none;
  max-width: 350px;
  height: 2px;
  outline: none;
  background-color: #aaa;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 50px auto 10px;

  @media (max-width: 768px) {
    width: calc(100% - 20px) !important;
    margin-left: 10px;
    margin-right: 10px;
  }

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 22px;
    background: #fff;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 22px;
    background: #fff;
    cursor: pointer;
  }
`
const styledInputWhiteTheme = css`
  background-color: #2e2e2e;

  &::-webkit-slider-thumb {
    background: #2c2c2c;
  }
  &::-moz-range-thumb {
    background: #2c2c2c;
  }
`

export default class PathSelectionSlider extends Component {
  constructor(props) {
    super()

    this.state = {
      sliderValue: new URI(props.url).subdomain() ? 1 : 0,
    }
  }

  publishChange(useDefault = false) {
    const { url, input } = this.props
    const { sliderValue } = this.state
    const fragments = this.getFragments(url, sliderValue)
    if (useDefault) this.setState({ sliderValue: fragments.length - 1 })
    const stiched = fragments
      .filter(f => f.isActive)
      .map(f => f.text)
      .join('')
    input.onChange(stiched)
  }

  componentDidMount() {
    this.publishChange(true)
  }

  componentDidUpdate(prevProps, prevState) {
    const { sliderValue } = this.state
    if (sliderValue !== prevState.sliderValue) {
      this.publishChange()
    }
  }

  getFragments(url, range) {
    const { sliderValue } = this.state
    const uri = new URI(url)

    // Subdomain
    let subdomain = uri.subdomain()
    subdomain = subdomain ? `${subdomain}.` : ''

    // Host
    let host = uri.host()
    host = host.replace(subdomain, '') + '/'

    // Path
    let path = uri.path()
    let paths = path === '/' ? [] : path.split('/')
    paths.shift()
    paths = paths.filter(Boolean).map(p => `${p}/`)

    // const fragments = parts.map((part, i) => ({
    //   text: part,
    //   isActive: sliderValue >= i,
    // }))
    const fragments = !subdomain
      ? [host, ...paths].map((part, i) => ({
          text: part,
          isActive: sliderValue >= i,
        }))
      : [
          {
            text: subdomain,
            isActive: sliderValue !== 1,
          },
          {
            text: host,
            isActive: true,
          },
          ...paths.map((p, i) => ({
            text: p,
            isActive: sliderValue >= 2 + i,
          })),
        ]

    log('Total Fragments: %o', fragments.length)
    log('Fragments: %O', fragments)

    return fragments
  }

  handleChange = e => {
    const idx = e.target.value
    this.setState({
      sliderValue: parseInt(idx, 10),
    })
  }

  render() {
    const { url, input, white } = this.props
    const { sliderValue } = this.state
    const fragments = this.getFragments(url, sliderValue)
    return (
      <PathSelectionSliderWrapper className={cx(white && whiteThemeWrapper)}>
        <figure>
          {fragments.map((f, i) =>
            f.isActive ? (
              <em key={i}>{f.text}</em>
            ) : (
              <span key={i}>{f.text}</span>
            )
          )}
        </figure>

        <StyledInput
          {...input}
          type="range"
          min={0}
          default={1}
          step={1}
          value={'' + sliderValue}
          max={fragments.length - 1}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          className={cx(white && styledInputWhiteTheme)}
        />
      </PathSelectionSliderWrapper>
    )
  }
}

PathSelectionSlider.propTypes = {
  white: PropTypes.bool,
  url: PropTypes.string.isRequired,
}
