import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'

function zip(arrays) {
  return !arrays || !arrays[0]
    ? []
    : flatten(
        arrays[0].map((_, i) =>
          arrays.map((array) => {
            return array[i]
          })
        )
      )
}

export function useColumnSort(data = [], minCols, maxCols) {
  const theme = useTheme()
  const mediumBreakpointActive = useMediaQuery(theme.breakpoints.up('md'))
  let nColumns = mediumBreakpointActive ? maxCols : minCols
  const chunkedData = chunk(data.sort(), Math.ceil(data.length / nColumns))
  return zip(chunkedData)
}