import { CLUSTER_ARRAY, TAKEDOWN_ARRAY } from './schemas'

export const TAKEDOWNS_INVALIDATE = 'TAKEDOWNS_INVALIDATE'

export const TAKEDOWN_FORM_SUBMIT = 'TAKEDOWN_FORM_SUBMIT'
export const TAKEDOWN_FORM_SUBMIT_SUCCESS = 'TAKEDOWN_FORM_SUBMIT_SUCCESS'
const TAKEDOWN_FORM_SUBMIT_FAIL = 'TAKEDOWN_FORM_SUBMIT_FAIL'

const TAKEDOWNS_LOAD = 'TAKEDOWNS_LOAD'
export const TAKEDOWNS_LOAD_SUCCESS = 'TAKEDOWNS_LOAD_SUCCESS'
const TAKEDOWNS_LOAD_FAIL = 'TAKEDOWNS_LOAD_FAIL'

const TAKEDOWN_CONTENT_LOAD = 'TAKEDOWN_CONTENT_LOAD'
export const TAKEDOWN_CONTENT_LOAD_SUCCESS = 'TAKEDOWN_CONTENT_LOAD_SUCCESS'
const TAKEDOWN_CONTENT_LOAD_FAIL = 'TAKEDOWN_CONTENT_LOAD_FAIL'

const TAKEDOWN_SUGGESTED_EMAILS_LOAD = 'TAKEDOWN_SUGGESTED_EMAILS_LOAD'
const TAKEDOWN_SUGGESTED_EMAILS_LOAD_SUCCESS =
  'TAKEDOWN_SUGGESTED_EMAILS_LOAD_SUCCESS'
const TAKEDOWN_SUGGESTED_EMAILS_LOAD_FAIL =
  'TAKEDOWN_SUGGESTED_EMAILS_LOAD_FAIL'

const initialState = {
  loading: false,
  content: null,
  suggestedEmails: null,
}

export default function takedowns(state = initialState, action = {}) {
  switch (action.type) {
    case TAKEDOWN_FORM_SUBMIT:
    case TAKEDOWNS_LOAD:
    case TAKEDOWN_CONTENT_LOAD:
      return {
        ...state,
        content: null,
        loading: true,
      }
    case TAKEDOWN_FORM_SUBMIT_SUCCESS:
    case TAKEDOWN_FORM_SUBMIT_FAIL:
    case TAKEDOWNS_LOAD_SUCCESS:
    case TAKEDOWNS_LOAD_FAIL:
    case TAKEDOWN_CONTENT_LOAD_FAIL:
      return {
        ...state,
        content: null,
        loading: false,
      }

    case TAKEDOWN_CONTENT_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        content: action.payload.content,
      }
    }

    case TAKEDOWN_SUGGESTED_EMAILS_LOAD:
      return {
        ...state,
        suggestedEmails: null,
      }
    case TAKEDOWN_SUGGESTED_EMAILS_LOAD_SUCCESS:
      return {
        ...state,
        suggestedEmails: action.payload.suggestedEmails,
      }

    default:
      return state
  }
}

export function paginationKey(query = {}) {
  return [query.filter, query.page || '0'].join('-')
}

export function load(query = {}) {
  return {
    types: [TAKEDOWNS_LOAD, TAKEDOWNS_LOAD_SUCCESS, TAKEDOWNS_LOAD_FAIL],
    promise: client => client.get('/takedowns', { params: query }),
    schema: { takedowns: TAKEDOWN_ARRAY },
    payload: { query },
  }
}

export function loadTakedownContentForCluster(query = {}) {
  return {
    types: [
      TAKEDOWN_CONTENT_LOAD,
      TAKEDOWN_CONTENT_LOAD_SUCCESS,
      TAKEDOWN_CONTENT_LOAD_FAIL,
    ],
    promise: client => client.get('/takedowns/content', { params: query }),
  }
}

export function loadSuggestedEmails(query = {}) {
  const SUGGESTED_EMAIL_LOAD_TIMEOUT_MS = 20000

  return {
    types: [
      TAKEDOWN_SUGGESTED_EMAILS_LOAD,
      TAKEDOWN_SUGGESTED_EMAILS_LOAD_SUCCESS,
      TAKEDOWN_SUGGESTED_EMAILS_LOAD_FAIL,
    ],
    promise: client =>
      new Promise((resolve, reject) => {
        const cancelTokenSource = client.generateCancelTokenSource()

        const timeoutId = setTimeout(() => {
          cancelTokenSource.cancel()

          resolve({
            suggestedEmails: '',
          })
        }, SUGGESTED_EMAIL_LOAD_TIMEOUT_MS)

        client
          .get('/takedowns/emailsuggestions', {
            params: query,
            cancelToken: cancelTokenSource.token,
          })
          .then(data => {
            clearTimeout(timeoutId)
            resolve(data)
          })
      }),
  }
}

export function submit(data) {
  return {
    types: [
      TAKEDOWN_FORM_SUBMIT,
      TAKEDOWN_FORM_SUBMIT_SUCCESS,
      TAKEDOWN_FORM_SUBMIT_FAIL,
    ],
    promise: client => client.post('/takedowns/submit', { data }),
    schema: {
      clusters: CLUSTER_ARRAY,
      takedowns: TAKEDOWN_ARRAY,
    },
  }
}
