import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import PasswordSetForm from './PasswordSetForm'

export default connect((state, props) => ({
  token: props.match.params.token,
}))(PasswordSetContainer)

function PasswordSetContainer({ token }) {
  return (
    <div className={wrapper}>
      <Helmet title="Reset Password" />
      <div className={card}>
        <h5>Enter your new password</h5>
        <PasswordSetForm token={token} />
      </div>
      <div className={below}>
        <Link to="/login" className="smallText">
          Back to login
        </Link>
      </div>
    </div>
  )
}

PasswordSetContainer.propTypes = {
  token: PropTypes.string.isRequired,
}

const wrapper = css`
  max-width: 370px;
  flex: 1;

  @media(max-width: 768px) {
    width: 100%;
    padding: 0 5px;
  }
`

const card = css`
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 37px -6px rgba(0, 0, 0, 0.75);
  border-top: 3px solid #008aab;
  &.wide {
    max-width: 800px;
  }
  img {
    display: block;
    margin: 0 auto 10px;
    width: 300px;
  }

  @media(max-width: 768px) {
    width: 100%;
    margin-top: 52px;
    max-height: calc(100vh - 50px - 100px);
    overflow-y: scroll;
  }
`

const below = css`
  padding: 15px 0 15px 0;
  a {
    color: #fff;
    text-shadow: 1px 1px 20px black;
    font-size: 1.15em;
    &:hover span {
      text-decoration: underline;
    }
    span {
      font-weight: 600;
      letter-spacing: -0.025em;
    }
  }
`
