import * as React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import Label from './Label'
import uniqBy from 'lodash/uniqBy'

export default class ImageUrls extends React.Component {
  state = {
    count: this.props.min,
  }

  static getUniqueMatches(matches) {
    return uniqBy(matches, 'url')
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.title) {
      return {
        min: 1,
      }
    } else {
      return {
        min: Math.min(ImageUrls.getUniqueMatches(nextProps.matches), 2),
      }
    }
  }

  toggleDisplayAll = () => {
    const { min } = this.state
    const matches = ImageUrls.getUniqueMatches(this.props.matches)
    this.setState(({ count }) => ({
      count: count === min ? matches.length : min,
    }))
  }

  render() {
    const { min, count } = this.state
    const matches = ImageUrls.getUniqueMatches(this.props.matches)

    let HEADER = `image url${
      matches.length === min ? '' : `s: ${count} of ${matches.length}`
    }`

    const additionalCount = matches.length - min

    return (
      <React.Fragment>
        <Label header={HEADER} />
        <div
          className={cx(imageUrlsContainer, count !== min && expanded)}
          style={{ maxHeight: `${min * 40}px` }}
        >
          {matches.map(match => (
            <Label key={match._id} href={match.url} />
          ))}
        </div>
        {matches.length !== min && (
          <button css={moreUrls} onClick={this.toggleDisplayAll}>
            {count === min
              ? `show ${additionalCount} other URL${
                  additionalCount === 1 ? '' : 's'
                }`
              : `hide URLs`}
          </button>
        )}
      </React.Fragment>
    )
  }
}
ImageUrls.defaultProps = {
  min: 1,
}
ImageUrls.propTypes = {
  min: PropTypes.number,
  matches: PropTypes.array.isRequired,
}

const moreUrls = css`
  align-self: center;
  background-color: #f2f2f2;
  border: none;
  font-size: 10px;
  margin-top: auto;
  min-height: 18px;
  padding: 0;
  text-transform: uppercase;
  width: 180px;

  @media (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 52px;
  }
`
const imageUrlsContainer = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  a {
    font-weight: 300;
  }
`
const expanded = css`
  max-height: 10000px !important;
  transition: max-height 0.4s ease-in;
`
