import { gql } from '@apollo/client'

export default gql`
  query getFiltersAndSummary($filters: CLUSTERS_FILTER_INPUT) {
    countries(onlyPixsyJurisdictions: false) {
      edges {
        iso
        displayName
        isPixsyJurisdiction
      }
    }
    categories {
      edges {
        code
        text
      }
    }
    matchSummary(filters: $filters) {
      total(filters: $filters)
      countries(filters: $filters) {
        country {
          iso
          isPixsyJurisdiction
        }
        matches
      }
      categories(filters: $filters) {
        category {
          code
        }
        matches
      }
      images(filters: $filters) {
        image {
          _id
          title
        }
        matches
      }
      actions(filters: $filters) {
        id
        matches
      }
    }
  }
`
