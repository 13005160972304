import React from 'react'
import { Link } from 'react-router-dom'
import { GridWrappingColumn, Button } from 'common'
import TakedownIntro from '../Intro/TakedownIntro'
import { connect } from 'react-redux'
import { css } from 'react-emotion'

const PremiumUserIntroActionSection = () => {
  const matches = require(`../matchesIcon.svg`)
  return (
    <div css={premiumUserIntro}>
      <Link to="/matches">
        <Button hoverMain>
          <i dangerouslySetInnerHTML={{ __html: matches }} />
          Browse Matches to Submit Takedowns
        </Button>
      </Link>
    </div>
  )
}
const FreeUserIntroActionSection = () => {
  const lightning = require(`../takedownIcon.svg`)
  return (
    <div css={freeUserIntro}>
      <Link to="/profile/plans">
        <Button hoverMain>
          <i dangerouslySetInnerHTML={{ __html: lightning }} />
          Upgrade Now to use Takedowns
        </Button>
      </Link>
    </div>
  )
}

const EmptyStateIntroActionSection = connect(state => ({
  takedownQuota:
    state.auth.user.plan.features.takedowns ||
    state.auth.user.allowance.freeTakedowns,
}))(function EmptyStateIntroActionSection({ takedownQuota }) {
  return takedownQuota > 0 ? (
    <PremiumUserIntroActionSection />
  ) : (
    <FreeUserIntroActionSection />
  )
})

export default function EmptyState() {
  return (
    <GridWrappingColumn maxWidth={876}>
      <TakedownIntro
        renderActionSection={() => <EmptyStateIntroActionSection />}
      />
    </GridWrappingColumn>
  )
}

const freeUserIntro = css`
  button {
    margin-top: 20px;
    height: 46px;
    line-height: 46px;
    padding-top: 0;
    padding-bottom: 0;

    svg {
      height: 45px;
      width: 25px;
      vertical-align: -17px;
      margin-right: 10px;
      transform: scale(1.8);

      path {
        fill: #fff;
      }
    }

    @media(max-width: 375px) {
      line-height: 1.5em;
      padding-top: 8px;
      padding-bottom: 16px;
    }

    @media(max-width: 768px) {
      height: auto;
    }
  `

const premiumUserIntro = css`
  button {
    margin-top: 20px;
    height: 46px;
    line-height: 46px;
    padding-top: 0;
    padding-bottom: 0;

    svg {
      height: 45px;
      width: 25px;
      vertical-align: -17px;
      margin-right: 30px;
      transform: scale(1.4);

      path {
        fill: #fff;
      }
    }

    @media(max-width: 375px) {
      line-height: 1.5em;
      padding-top: 8px;
      padding-bottom: 16px;
    }

    @media(max-width: 768px) {
      height: auto;
    }
  `
