const environment =
  process.env.NODE_ENV === 'production' ? 'production' : 'development'

const config = {
  development: {
    metaParser: 'https://meta-parser.pixsy.io/dev/parse', // Local: localhost:4050
  },
  production: {
    metaParser: 'https://meta-parser.pixsy.io/prod/parse',
  },
}

export default config[environment]
