import { ImageSchema } from 'pixsy-schema/case/ImageSchema'
import { KnockoutSchema } from 'pixsy-schema/case/KnockoutSchema'
import { MatchSchema } from 'pixsy-schema/case/MatchSchema'
import { SettlementSchema } from 'pixsy-schema/case/SettlementSchema'
import { SUBMISSION_KEYS } from 'pixsy-schema/case/SubmissionSchema'
import { QArray, QDefineConstEnums, QSchema } from 'pixsy-schema/core'
import { ProfileSchema } from 'pixsy-schema/user/ProfileSchema'
import * as React from 'react'
import {
  CheckStage,
  DetailsStage,
  ImportSelectStage,
  OverviewStage,
  ProfileStage,
  ReviewStage,
  ScreeningStage,
  SuccessStage,
} from './FormSections/Stages'

export const STAGES = QDefineConstEnums(
  'OVERVIEW',
  'IMPORT',
  'VALIDATION',
  'PROFILE',
  'SCREENING',
  'IMAGES',
  'REVIEW',
  'SUCCESS'
)

export const MAX_GENERAL_FILES = 20
export const MAX_IMAGE_IMPORT_FILES = 20

export const ARRAY_STAGES = Object.values(STAGES)

export const STAGE_LABELS = {
  [STAGES.OVERVIEW]: 'OVERVIEW',
  [STAGES.IMPORT]: 'IMPORT',
  [STAGES.VALIDATION]: 'CHECK',
  [STAGES.PROFILE]: 'PROFILE',
  [STAGES.SCREENING]: 'SCREENING',
  [STAGES.IMAGES]: 'IMAGES',
  [STAGES.REVIEW]: 'REVIEW',
  [STAGES.SUCCESS]: 'SUCCESS',
}

export const STAGE_INFO_STATUS = {
  [STAGES.OVERVIEW]: 'Proceed to Overview',
  [STAGES.IMPORT]: 'Proceed to Import',
  [STAGES.VALIDATION]: 'Proceed to Check',
  [STAGES.PROFILE]: 'Proceed to Profile',
  [STAGES.SCREENING]: 'Proceed to Screening',
  [STAGES.IMAGES]: 'Proceed to Images',
  [STAGES.REVIEW]: 'Proceed to Review',
}

export const STAGE_OTHER_STATUS = {
  SAVING: { text: 'Saving ...', progress: true },
  SAVED: { text: 'Saved {time}', progress: false },
  CHECKS_RUNNING: { text: 'Running Check ...', progress: true },
  CHECKS_STAGE_RUNNING: { text: 'Please Wait ...', progress: true },
  AUTH_LOAD_USER: { text: 'Loading user data ...', progress: true },
  AUTH_UPDATING_USER: { text: 'Updating user profile ...', progress: true },
  AUTH_EMAIL_SEND: { text: 'Sending email verification ...', progress: true },
}

export const STAGE_CONTAINERS = {
  [STAGES.OVERVIEW]: OverviewStage,
  [STAGES.IMPORT]: ImportSelectStage,
  [STAGES.VALIDATION]: CheckStage,
  [STAGES.PROFILE]: ProfileStage,
  [STAGES.SCREENING]: ScreeningStage,
  [STAGES.IMAGES]: DetailsStage,
  [STAGES.REVIEW]: ReviewStage,
  [STAGES.SUCCESS]: SuccessStage,
}

export const STAGE_SCHEMAS = {
  [STAGES.OVERVIEW]: null,
  [STAGES.IMPORT]: QSchema.shape({
    [SUBMISSION_KEYS.images]: QArray.min(1).required(),
  }).noUnknown(false),
  [STAGES.VALIDATION]: null,
  [STAGES.PROFILE]: ProfileSchema,
  [STAGES.SCREENING]: KnockoutSchema.noUnknown(false),
  [STAGES.IMAGES]: QSchema.shape({
    [SUBMISSION_KEYS.images]: QArray.of(ImageSchema)
      .compact()
      .required(),
    [SUBMISSION_KEYS.matches]: QArray.of(MatchSchema)
      .compact()
      .required(),
  }).noUnknown(false),
  [STAGES.REVIEW]: SettlementSchema.noUnknown(false),
  [STAGES.SUCCESS]: null,
}

export const STAGE_INFO = {
  [STAGES.OVERVIEW]: {
    title: `What makes a good case?`,
    link: `http://support.pixsy.com/articles/175`,
    content: (
      <p>
        <b>Clear infringement of your image</b>
        <br />
        The image user is hosting/publishing your image without permission, a
        license or a correct attribution.
        <br />
        <b>An operating business</b>
        <br />
        The website is a commercial business, major publication, organization,
        institution or government agency, and is operating in one of our
        supported countries.
        <br />
        <b>Open questions</b>
        <br />
        There are no open questions about past licensing or conflicts with stock
        agencies.
      </p>
    ),
  },
  [STAGES.IMPORT]: {
    title: `Getting started with a new case`,
    link: `http://support.pixsy.com/`,
    content: (
      <p>
        Found an unauthorized use of your image? We'll review and pursue it for
        you. Include what you can about the image use and anything relevant
        about your image and licensing history. Not sure? Follow the
        instructions on the form and let us guide you to get your submission in
        great shape for resolution.
      </p>
    ),
  },
  [STAGES.PROFILE]: {
    title: `Tell us about yourself`,
    link: `http://support.pixsy.com/`,
    content: (
      <p>
        As we will be acting as your authorised agent, and legally representing
        you. It’s critical that we have your complete and accurate information.
        We may require additional checks or information later in the case
        process.
      </p>
    ),
  },
  [STAGES.SCREENING]: {
    title: `What makes a good case?`,
    link: `http://support.pixsy.com/articles/175`,
    content: (
      <p>
        <b>Clear infringement of your image</b>
        <br />
        The image user is hosting/publishing your image without permission, a
        license or a correct attribution.
        <br />
        <b>An operating business</b>
        <br />
        The website is a commercial business, major publication, organization,
        institution or government agency, and is operating in one of our
        supported countries.
        <br />
        <b>Open questions</b>
        <br />
        There are no open questions about past licensing or conflicts with stock
        agencies.
      </p>
    ),
  },
  [STAGES.IMAGES]: {
    title: `Enrich the value of your case`,
    link: `http://support.pixsy.com/`,
    content: (
      <p>
        Confirm that this is indeed your image and a clear infringement of your
        copyrights. Providing Pixsy with rich and detailed information about
        your image, and the user of it, enables us to smoothly process your case
        and attempt to recover maximum compensation. Image information provided
        to us will be saved and auto-filled during your next submission.
      </p>
    ),
  },
  [STAGES.REVIEW]: {
    title: `The more information, the smoother the process`,
    link: `http://support.pixsy.com/`,
    content: (
      <p>
        You are authorizing Pixsy (and our legal partners) to act on your
        behalf. It is vital that you provide accurate and relevant information
        to us from the outset. If in doubt, please confirm details before
        submitting. You can still add notes to this submission and once the case
        is submitted, you can reply to the case email to speak directly with
        your dedicated case manager, or contact our support center team at any
        time.
      </p>
    ),
  },
}

export const GA_STAGE_VIEWS = {
  [STAGES.OVERVIEW]: {
    funnel: '/c/vpv/fu/cs/v6/gen/overview',
    additionalViews: {
      EXTERNAL: '/c/vpv/fu/cs/v6/man/start',
      MATCH: '/c/vpv/fu/cs/v6/mat/start',
      IMAGE: '/c/vpv/fu/cs/v6/img/start',
    },
  },
  [STAGES.IMPORT]: {
    funnel: '/c/vpv/fu/cs/v6/gen/import',
  },
  [STAGES.IMAGES]: {
    funnel: '/c/vpv/fu/cs/v6/gen/images',
  },
  [STAGES.VALIDATION]: {
    funnel: '/c/vpv/fu/cs/v6/gen/check',
  },
  [STAGES.SCREENING]: {
    funnel: '/c/vpv/fu/cs/v6/gen/screening',
  },
  [STAGES.REVIEW]: {
    funnel: '/c/vpv/fu/cs/v6/gen/review',
  },
  [STAGES.PROFILE]: {
    funnel: '/c/vpv/fu/cs/v6/gen/updateProfile',
  },
  [STAGES.SUCCESS]: {
    funnel: '/c/vpv/fu/cs/v6/gen/submit',
  },
}
