import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { css } from 'react-emotion'
import { updateUser } from '../../../redux/modules/auth'
import { set } from 'lodash'

import {
  AlignLabels,
  Button,
  TextArea,
  PhantomLabelSpacer,
  SexySeparator,
} from 'common'

const ConnectedForm = reduxForm({
  form: 'bio',
  enableReinitialize: true,
  onSubmit: (values, dispatch) => {
    const $u = {}
    for (const [k, v] of Object.entries(values)) {
      set($u, k, v)
    }
    return dispatch(updateUser(values))
  },
})(Form)

export default function BioForm({ user }) {
  return <ConnectedForm initialValues={user} />
}

function Form({ valid, handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <AlignLabels align="left" width="300px">
        <Field
          component={TextArea}
          name="photographerFields.creativeBackground.careerSummary"
          label="Please briefly summarize your professional career. For example, do you have a degree or any special training, or are you self-taught? How did you get started and how long have you been creating?"
        />

        <SexySeparator space={20} />
        <Field
          component={TextArea}
          name="photographerFields.creativeBackground.equipment"
          label="What tools or equipment do you use? Do you use any special equipment such as underwater gear, drones or analog cameras? Any information that shows your work is more expensive than average to produce is helpful."
        />

        <SexySeparator space={20} />
        <Field
          component={TextArea}
          name="photographerFields.creativeBackground.uniqueAttributes"
          label="Every artist stands out in a different way. What characteristics of your images make it particularly unique? Examples might include special post-processing, unique subjects or location, or unique style."
        />

        <SexySeparator space={20} />
        <Field
          component={TextArea}
          name="photographerFields.creativeBackground.awards"
          label="Please briefly summarize any special honors such as awards, distinctions, exhibitions, notable publications, professional memberships or other achievements here."
        />
      </AlignLabels>

      <PhantomLabelSpacer alignContent="right">
        <div css={mobile}>
          <Button disabled={!valid || submitting} submit>
            Save
          </Button>
        </div>
      </PhantomLabelSpacer>
    </form>
  )
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}
