import { MATCH, CLUSTER, CLUSTER_ARRAY, MATCH_ARRAY } from './schemas'
import slsFunctions from '../../slsFunctions'

export const CLUSTERS_DID_MOUNT = 'CLUSTERS_DID_MOUNT'
export const CLUSTERS_INVALIDATE = 'CLUSTERS_INVALIDATE'

export const CLUSTERS_LOAD = 'CLUSTERS_LOAD'
export const CLUSTERS_LOAD_SUCCESS = 'CLUSTERS_LOAD_SUCCESS'
const CLUSTERS_LOAD_FAIL = 'CLUSTERS_LOAD_FAIL'

const MATCH_UPDATE = 'MATCH_UPDATE'
export const MATCH_UPDATE_SUCCESS = 'MATCH_UPDATE_SUCCESS'
const MATCH_UPDATE_FAIL = 'MATCH_UPDATE_FAIL'

export const CLUSTER_UPDATE = 'CLUSTER_UPDATE'
export const CLUSTER_UPDATE_SUCCESS = 'CLUSTER_UPDATE_SUCCESS'
const CLUSTER_UPDATE_FAIL = 'CLUSTER_UPDATE_FAIL'

export const CLUSTER_FLAG = 'CLUSTER_FLAG'
const CLUSTER_FLAG_SUCCESS = 'CLUSTER_FLAG_SUCCESS'
const CLUSTER_FLAG_FAIL = 'CLUSTER_FLAG_FAIL'

const CLUSTER_HIDE = 'CLUSTER_HIDE'
const CLUSTER_HIDE_SUCCESS = 'CLUSTER_HIDE_SUCCESS'
const CLUSTER_HIDE_FAIL = 'CLUSTER_HIDE_FAIL'

const CLUSTER_TOUCH = 'CLUSTER_TOUCH'
const CLUSTER_TOUCH_SUCCESS = 'CLUSTER_TOUCH_SUCCESS'
const CLUSTER_TOUCH_FAIL = 'CLUSTER_TOUCH_FAIL'

const CLUSTER_UPDATE_DOMAIN = 'CLUSTER_UPDATE_DOMAIN'
export const CLUSTER_UPDATE_DOMAIN_SUCCESS = 'CLUSTER_UPDATE_DOMAIN_SUCCESS'
const CLUSTER_UPDATE_DOMAIN_FAIL = 'CLUSTER_UPDATE_DOMAIN_FAIL'

export const CLUSTER_LOAD = 'CLUSTER_LOAD'
const CLUSTER_LOAD_SUCCESS = 'CLUSTER_LOAD_SUCCESS'
const CLUSTER_LOAD_FAIL = 'CLUSTER_LOAD_FAIL'

const CLUSTERS_IGNORE = 'CLUSTERS_IGNORE'
const CLUSTERS_IGNORE_SUCCESS = 'CLUSTERS_IGNORE_SUCCESS'
const CLUSTERS_IGNORE_FAIL = 'CLUSTERS_IGNORE_FAIL'

export const CLUSTER_TAKEDOWN = 'CLUSTER_TAKEDOWN'
export const CLUSTER_TAKEDOWN_SUCCESS = 'CLUSTER_TAKEDOWN_SUCCESS'
const CLUSTER_TAKEDOWN_FAIL = 'CLUSTER_TAKEDOWN_FAIL'

export const CLUSTER_SUMMARY_LOAD = 'CLUSTER_SUMMARY_LOAD'
const CLUSTER_SUMMARY_LOAD_SUCCESS = 'CLUSTER_SUMMARY_LOAD_SUCCESS'
const CLUSTER_SUMMARY_LOAD_FAIL = 'CLUSTER_SUMMARY_LOAD_FAIL'

const CLUSTER_CHECK_ONLINE_MATCHES = 'CLUSTER_CHECK_ONLINE_MATCHES'
const CLUSTER_CHECK_ONLINE_MATCHES_SUCCESS =
  'CLUSTER_CHECK_ONLINE_MATCHES_SUCCESS'
const CLUSTER_CHECK_ONLINE_MATCHES_FAIL = 'CLUSTER_CHECK_ONLINE_MATCHES_FAIL'

const CLUSTER_CHECK_ONLINE_IMAGES = 'CLUSTER_CHECK_ONLINE_IMAGES'
const CLUSTER_CHECK_ONLINE_IMAGES_SUCCESS =
  'CLUSTER_CHECK_ONLINE_IMAGES_SUCCESS'
const CLUSTER_CHECK_ONLINE_IMAGES_FAIL = 'CLUSTER_CHECK_ONLINE_IMAGES_FAIL'

const SOFT_IGNORE_IMAGE_LOAD = 'SOFT_IGNORE_IMAGE_LOAD'
const SOFT_IGNORE_IMAGE_LOAD_SUCCESS = 'SOFT_IGNORE_IMAGE_LOAD_SUCCESS'
const SOFT_IGNORE_IMAGE_LOAD_FAIL = 'SOFT_IGNORE_IMAGE_LOAD_FAIL'

const MATCH_REPLACE_ATTRIBUTES = 'MATCH_REPLACE_ATTRIBUTES'
const MATCH_REPLACE_ATTRIBUTES_SUCCESS = 'MATCH_REPLACE_ATTRIBUTES_SUCCESS'
const MATCH_REPLACE_ATTRIBUTES_FAIL = 'MATCH_REPLACE_ATTRIBUTES_FAIL'

export const MATCHES_FILTER_APPLIED = 'MATCHES_FILTER_APPLIED'

const initialState = {
  total: null,
  error: null,
  loading: false,
  scanning: false,
  summary: null,
  ignoringClusters: false,
  byCountry: [],
  tags: [],
}

export default function matches(state = initialState, action = {}) {
  switch (action.type) {
    case CLUSTERS_LOAD:
    case CLUSTER_SUMMARY_LOAD:
      return {
        ...state,
        loading: true,
      }

    case CLUSTERS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...(action.payload.tags && { tags: action.payload.tags }),
      }
    }
    case CLUSTER_SUMMARY_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    }
    case CLUSTERS_IGNORE:
      return {
        ...state,
        ignoringClusters: true,
      }
    case CLUSTERS_IGNORE_SUCCESS:
      return {
        ...state,
        error: null,
        ignoringClusters: false,
      }
    case CLUSTERS_IGNORE_FAIL:
      return {
        ...state,
        error: action.error,
        ignoringClusters: false,
      }
    case CLUSTER_TAKEDOWN:
    case CLUSTER_TAKEDOWN_SUCCESS:
      return {
        ...state,
        error: null,
      }
    case CLUSTER_TAKEDOWN_FAIL:
      return {
        ...state,
        error: action.error,
      }

    default:
      return state
  }
}

export function defaultQuery() {
  return {
    page: 1,
    tags: [],
    // search: '',
    sort: '-score',
  }
}

/**
 * Get key to access loaded entities from clusterByPage object
 * Sort to make sure its always in the same order
 *
 * @param {?Object} query
 * @returns {string} "like a-b-c-d-e"
 */
export function paginationKey(query) {
  query = {
    ...defaultQuery(),
    ...query,
  }
  return Object.keys(query)
    .sort()
    .map(k => query[k].toString())
    .join('-')
}

/**
 * @param {Object} globalState
 * @returns {boolean}
 */
export function isLoaded(globalState) {
  return globalState.matches && globalState.matches.loaded
}

/**
 * @param {string} _id of the match mongodb record
 * @returns {Object} Action
 */
export function updateMatch(clusterId, matchId, data) {
  return {
    types: [MATCH_UPDATE, MATCH_UPDATE_SUCCESS, MATCH_UPDATE_FAIL],
    promise: client =>
      client.put(`/clusters/${clusterId}/matches/${matchId}`, { data }),
    schema: {
      match: MATCH,
      cluster: CLUSTER,
    },
    payload: { clusterId, matchId },
  }
}

export function update(clusterId, data) {
  return {
    types: [CLUSTER_UPDATE, CLUSTER_UPDATE_SUCCESS, CLUSTER_UPDATE_FAIL],
    promise: client => client.put(`/clusters/${clusterId}`, { data }),
    schema: {
      cluster: CLUSTER,
      match: MATCH,
    },
  }
}

export function flag(clusterId) {
  return {
    types: [CLUSTER_FLAG, CLUSTER_FLAG_SUCCESS, CLUSTER_FLAG_FAIL],
    promise: client => client.put(`/clusters/${clusterId}/flag`),
    schema: {
      cluster: CLUSTER,
    },
  }
}

export function touch(clusterId, data) {
  return {
    types: [CLUSTER_TOUCH, CLUSTER_TOUCH_SUCCESS, CLUSTER_TOUCH_FAIL],
    promise: client => client.get(`/clusters/${clusterId}/touch`),
    schema: {
      cluster: CLUSTER,
    },
  }
}

export function hide(clusterId, bool) {
  return {
    types: [CLUSTER_HIDE, CLUSTER_HIDE_SUCCESS, CLUSTER_HIDE_FAIL],
    promise: client =>
      client.put(`/clusters/${clusterId}/hide`, { data: { hidden: bool } }),
    schema: {
      cluster: CLUSTER,
    },
  }
}

export function softDeleteImageFromCluster(clusterId, imageId) {
  return {
    types: [
      SOFT_IGNORE_IMAGE_LOAD,
      SOFT_IGNORE_IMAGE_LOAD_SUCCESS,
      SOFT_IGNORE_IMAGE_LOAD_FAIL,
    ],
    promise: client =>
      client.put(`/clusters/${clusterId}/images/${imageId}/ignore`),
    schema: { cluster: CLUSTER },
  }
}

export function matchesFilterApplied(tags) {
  return {
    types: MATCHES_FILTER_APPLIED,
    payload: { filter: tags },
  }
}

/**
 * @param {string} domain to be ignored
 * @returns {Object} Action
 */
export function ignoreDomain(domain) {
  return {
    types: [
      CLUSTER_UPDATE_DOMAIN,
      CLUSTER_UPDATE_DOMAIN_SUCCESS,
      CLUSTER_UPDATE_DOMAIN_FAIL,
    ],
    promise: client =>
      client.post(`/users/me/whitelist/${encodeURIComponent(domain)}`),
  }
}

/**
 * @returns {Object} Action
 */
export function invalidate(query) {
  return {
    type: CLUSTERS_INVALIDATE,
    schema: { clusters: CLUSTER_ARRAY, matches: MATCH_ARRAY },
    payload: { query },
  }
}

/**
 * @returns {Object} Action
 */
export function load(query, options = {}) {
  return {
    types: [CLUSTERS_LOAD, CLUSTERS_LOAD_SUCCESS, CLUSTERS_LOAD_FAIL],
    promise: client =>
      client.get('/clusters', { params: { ...query, ...options } }),
    schema: { clusters: CLUSTER_ARRAY },
    payload: { query },
  }
}

export function loadSummary(query) {
  return {
    types: [
      CLUSTER_SUMMARY_LOAD,
      CLUSTER_SUMMARY_LOAD_SUCCESS,
      CLUSTER_SUMMARY_LOAD_FAIL,
    ],
    promise: client => client.get('/clusters/summary'),
  }
}

export function loadSingle(id) {
  return {
    types: [CLUSTER_LOAD, CLUSTER_LOAD_SUCCESS, CLUSTER_LOAD_FAIL],
    payload: { invalidate: true }, // invalidate the cache to have correct cluster matches
    promise: client => client.get(`/clusters/${id}`),
    schema: CLUSTER,
  }
}

export function checkOnlineMatches(id) {
  return {
    types: [
      CLUSTER_CHECK_ONLINE_MATCHES,
      CLUSTER_CHECK_ONLINE_MATCHES_SUCCESS,
      CLUSTER_CHECK_ONLINE_MATCHES_FAIL,
    ],
    promise: client => client.get(`/clusters/${id}/matches/online`),
  }
}

export function checkOnlineImages(id) {
  return {
    types: [
      CLUSTER_CHECK_ONLINE_IMAGES,
      CLUSTER_CHECK_ONLINE_IMAGES_SUCCESS,
      CLUSTER_CHECK_ONLINE_IMAGES_FAIL,
    ],
    promise: client => client.get(`/clusters/${id}/images/online`),
  }
}

/**
 * @param {Array.<string>} clusterIds
 * @param {'ignore' | 'approved'} action
 * @returns {Object} Action
 */
export function ignoreClusters(clusterIds, action) {
  return {
    types: [CLUSTERS_IGNORE, CLUSTERS_IGNORE_SUCCESS, CLUSTERS_IGNORE_FAIL],
    promise: client =>
      client.post('/clusters/bulk/ignore', { data: { clusterIds, action } }),
    schema: { clusters: CLUSTER_ARRAY },
  }
}

export function replaceMatchAttributes(match) {
  return {
    types: [
      MATCH_REPLACE_ATTRIBUTES,
      MATCH_REPLACE_ATTRIBUTES_SUCCESS,
      MATCH_REPLACE_ATTRIBUTES_FAIL,
    ],
    promise: async client => {
      const { numberOfParsed, parsed } = await client.post(
        slsFunctions.metaParser,
        {
          data: {
            id: Array.isArray(match) ? match.map(m => m._id) : match._id,
          },
        }
      )

      if (numberOfParsed) {
        return parsed
      }

      return []
    },
    schema: MATCH_ARRAY,
  }
}
