import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import FormRow from './FormRow'
import Feedback from './Feedback'
import LockedValue from './LockedValue'

export default function ClosedQuestionRadio(props) {
  let {
    items,
    input,
    label,
    iteration,
    meta,
    addon,
    locked,
    showNoAnswer,
  } = props

  const defaultItems = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ]
  items = items && items.length ? items : defaultItems
  const [yes, no] = items

  if (input.value === '') {
    input.value = null
  }
  if (input.value === 'true') {
    input.value = true
  }
  if (input.value === 'false') {
    input.value = false
  }

  return (
    <FormRow {...props}>
      <div className={stacked}>
        <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
          <LockedValue value={label} />

          {locked ? (
            <LockedValue
              css={lockedLabel}
              value={
                input.value === yes.value
                  ? yes.label
                  : input.value === no.value
                    ? no.label
                    : showNoAnswer
                      ? 'No answer'
                      : '-'
              }
            />
          ) : (
            <div className={actionArea}>
              {items.map((item, index) => (
                <div key={item.value} className={hitbox}>
                  <input
                    onChange={e => input.onChange(e) && input.onBlur(e)}
                    checked={input.value === item.value}
                    name={input.name}
                    type="radio"
                    id={`${input.name}-${iteration}-${index}"`}
                    value={item.value}
                  />
                  <label htmlFor={`${input.name}-${iteration}-${index}"`}>
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          )}
          {addon && addon({ name: input.name })}
        </div>
        <Feedback meta={meta} />
      </div>
    </FormRow>
  )
}

const lockedLabel = css`
  color: #999;
`

const stylesUnlocked = css``

const stylesLocked = css`
  /* opacity: 0.5; */
`

const stacked = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const wrap = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;

  label {
    font-weight: 300;
    font-size: 1.42em;
    display: block;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const actionArea = css`
  flex: 0 0 140px;
  text-align: center;
  margin: 3px 0px 0 15px;
  display: flex;

  @media (max-width: 768px) {
    flex: 0;
    margin: 2px;
  }

  label {
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    user-select: none;
    color: #a0a0a0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.85em;
  }

  label,
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    appearance: none;
    margin: 0;
    outline: 1px solid #d9d9d9;
    font-weight: 400;
    font-size: 0.9em !important;

    &:focus {
      outline: inherit;
      outline-offset: inherit;
      border: inherit;
    }

    &:checked {
      background: #008aab;
      + label {
        outline: 1px solid #008aab;
        color: #fff;
        font-weight: 600;
        font-size: 0.9em !important;
      }
    }
  }
`

const hitbox = css`
  position: relative;
  display: inline-block;
  flex-basis: 50%;
  height: 30px;

  &:nth-of-type(2) {
    transform: translateX(1px);
  }
`

ClosedQuestionRadio.propTypes = {
  label: PropTypes.string.isRequired,
  iteration: PropTypes.number,
}

ClosedQuestionRadio.defaultProps = {
  iteration: 0,
}
