import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { css, cx, injectGlobal } from 'react-emotion'

export default class CardFilterBar extends PureComponent {
  constructor() {
    super()
    this.state = {}
    this.togglePanel = (position, component) =>
      this.setState(prevState => ({
        [position]: prevState[position] === component ? null : component,
      }))
  }

  render() {
    const { renderLeftAddon, renderCenterAddon, renderRightAddon } = this.props
    const { LeftComponent, CenterComponent, RightComponent } = this.state

    const props = {
      togglePanel: this.togglePanel,
    }
    return (
      <div className={wrap}>
        <div className={flex}>
          <div css={leftMobileBehaviour}>
            {renderLeftAddon && renderLeftAddon(props)}
          </div>
          <div css={middleMobileBehaviour}>
            {renderCenterAddon && renderCenterAddon(props)}
          </div>
          <div css={rightMobileBehaviour}>
            {renderRightAddon && renderRightAddon(props)}
          </div>
        </div>

        <hr css={divider} />
        <div
          className={cx(expander, {
            'card-filter-bar--expanded':
              LeftComponent || CenterComponent || RightComponent,
          })}
        >
          {LeftComponent && <LeftComponent {...props} />}
          {CenterComponent && <CenterComponent {...props} />}
          {RightComponent && <RightComponent {...props} />}
        </div>
      </div>
    )
  }
}

CardFilterBar.propTypes = {
  renderLeftAddon: PropTypes.func,
  renderCenterAddon: PropTypes.func,
  renderRightAddon: PropTypes.func,
}

const wrap = css`
  margin-top: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.9em;
  color: #999;
`

const flex = css`
  display: flex;
  justify-content: stretch;
  padding: 0 12px;

  @media (max-width: 768px) {
    display: inline-block;
    width: 100%;
  }

  > * {
    flex-basis: 33%;
    align-items: flex-end;
  }
`

const expander = css`
  max-height: 0px;
  transition: 0.3s max-height ease-in;
  overflow: hidden;
`

injectGlobal`
  .card-filter-bar--expanded {
    border-bottom: 2px solid #eee;
    max-height: 130px !important;
  }
`

const divider = css`
  background-color: #eee;
  height: 2px;
  border: 0;
  margin: 8px 0 24px;
  @media (max-width: 768px) {
    margin: 8px 0 0px;
  }
`

const middleMobileBehaviour = css`
  @media (max-width: 768px) {
    display: none;
  }
`

const leftMobileBehaviour = css`
  @media (max-width: 768px) {
    width: 40%;
    float: left;
  }
`

const rightMobileBehaviour = css`
  @media (max-width: 768px) {
    width: 60%;
    float: right;
  }
`
