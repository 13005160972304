import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { css, cx } from 'react-emotion'
/*
type HistoryItem = {
  label: ?string,
  tooltip: ?string,
  date: string,
  id: string,
}
*/

export default function ActivityList({ history }) {
  return (
    <ol css={activityList}>
      {history.map((h, idx) => (
        <li
          key={idx}
          className={cx(
            activityItem,
            idx % 2 === 0 ? even : uneven,
            !idx && activeItem
          )}
        >
          <Item item={h} index={idx} />
        </li>
      ))}
    </ol>
  )
}

class Item extends Component {
  constructor(props) {
    super()
    this.state = { collapsed: props.index > 2 }
  }

  toggleCollapse = () =>
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }))

  render() {
    const { collapsed } = this.state
    const { item, index } = this.props
    return (
      <div css={content}>
        <h4>{item.label}</h4>
        <span>{moment.utc(item.date).format('LL')}</span>
        {!collapsed && <p>{item.tooltip}</p>}

        {index > 2 && (
          <a onClick={this.toggleCollapse}>
            {collapsed ? 'Read More' : 'See Less'}
          </a>
        )}
      </div>
    )
  }
}

ActivityList.propTypes = {
  history: PropTypes.array.isRequired,
}

const activityList = css`
  padding: 0;
  margin-bottom: 60px;
`

const activityItem = css`
  margin-bottom: -40px;
  display: flex;
  width: 100%;
  min-height: 130px;

  span {
    color: #3b3b3b80;
    font-style: italic;
  }
  h4 {
    font-size: 1.15em;
    font-weight: 400;
    margin: 0 0 5px;
  }

  p {
    color: #3b3b3bbf;
    margin-bottom: 4px;
  }

  a {
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    color: #008aab80;
    &:hover {
      color: #008aabff;
    }
  }

  /* &:last-child > div {
    border: 0 !important;
  } */

  &.even:before,
  &.uneven:after {
    content: '';
    flex: 1;
  }

  > div {
    &:before {
      position: absolute;
      top: 0;
      width: 7px;
      height: 7px;
      background-color: #148aa9;
      content: '';
    }
  }
`

const activeItem = css`
  h4 {
    font-size: 1.3em;
    font-weight: 600;
  }
  > div {
    &:before {
      height: 18px !important;
      width: 18px !important;
    }
  }
`

const content = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% + 0px);
`
const spacer = css`
  content: '';
  flex: 1;
`
const even = css`
  /* transform: translateX(-1px); */
  &:before {
    ${spacer};
  }
  > div {
    border-left: 1px solid #148aa9;
    transform: translateX(-2.25px);
    padding-left: 40px;
    &:before {
      left: 0;
      transform: translatex(calc(-50%));
    }
  }
`

const uneven = css`
  /* border: 1px solid red; */
  :after {
    ${spacer};
  }
  > div {
    border-right: 1px solid #148aa9;
    text-align: right;
    padding-right: 40px;
    &:before {
      right: 0;
      transform: translatex(calc(50% - 0.2px));
    }
  }
`
