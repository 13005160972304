import React from 'react'
import PropTypes from 'prop-types'
import Feedback from './Feedback'
import styled from 'react-emotion'

export default function TextFieldNoLabel(props) {
  const { input, meta = {} } = props
  const { dirty, touched, warning, error } = meta

  const wrapProps = {}

  if ((touched || dirty) && (warning || error)) {
    if (error) {
      wrapProps.isError = true
      wrapProps['data-error'] = true
    }
  }

  return (
    <Wrap {...wrapProps}>
      <input
        type="text"
        required={Boolean(props.required)}
        {...input}
        placeholder={props.label}
        disabled={props.disabled}
      />
      <Feedback meta={meta} align="center" />
    </Wrap>
  )
}

TextFieldNoLabel.propTypes = {
  label: PropTypes.string,
}

const Wrap = styled.div`
  flex: 1;
  background-color: ${({ isError }) => (isError ? '#FFF0F0' : 'transparent')};
  input {
    height: 40px;
    width: 100%;
    padding: 0 12px;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    ::placeholder {
      color: #434343bf;
    }
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }

    @media (max-width: 768px) {
      border-radius: 0;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
    }
  }
`
