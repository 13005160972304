import { useCallback } from 'react'
import { notifClear, notifSend } from 'redux-notifications/lib/actions'

/**
 * @typedef {(function({
 *   id: string,
 *   message: string,
 *   kind?: string,
 *   dismissAfter?: number,
 *   [p: string]: *
 * }): void)} NotifyFunction
 */

/**
 * Get a function for sending toast notifications to the main store
 * @param dispatch - Dispatch function from the main store. This is required
 *                   until migrated to a newer version of redux that supports
 *                   useDispatch()
 * @returns {NotifyFunction} Function to trigger toast notifications
 */
export default function useNotify(dispatch) {
  return useCallback(({ id, message, kind = 'info', dismissAfter = 5e3, ...rest }) => {
    // Do this on a different frame of execution
    setTimeout(() => {
      if (kind === 'success') {
        dispatch(notifClear())
      }

      try {
        notifSend({
          id,
          message,
          kind,
          dismissAfter,
          ...rest,
        })(dispatch)
      } catch (error) {
        console.error('useNotify: ', error)
      }
    })
  }, []) /* We do not want to add `dispatch` as a dependency, as it is not 100% safe */ // eslint-disable-line
}
