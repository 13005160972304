import React, { useCallback, useState } from 'react'
import Collapsable from '../../../components/Collapsable'
import { ImageIcon } from '../../../components/Icons'
import MultiSelectRichList from '../../../components/MultiSelectRichList'

const ImagesFilter = ({ items, onSelect }) => {
  const [selectedIds, setSelectedIds] = useState([])

  const onChange = useCallback((ids) => {
    setSelectedIds(ids)
    onSelect(ids)
  }, [])

  return (
    <Collapsable
      Icon={ImageIcon}
      text="Image"
      onOpen={() => false}
      content={
        <MultiSelectRichList 
          items={items} 
          selectedIds={selectedIds} 
          onSelectionChange={onChange} 
          enableTextSearch 
        />
      }
      badgeCount={selectedIds.length}
    />
  )
}

export default ImagesFilter
