import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

export default function DoubleCenteredContent({ children }) {
  return <div className={wrap}>{React.Children.only(children)}</div>
}

DoubleCenteredContent.propTypes = {
  children: PropTypes.node.isRequired,
}

const wrap = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
