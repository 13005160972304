import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ScrollOnMount extends Component {
  constructor(props) {
    super(props)
    this.ref = null
  }

  componentDidMount() {
    this.scheduleScroll({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  componentDidUpdate(prevProps) {
    const { container, element } = this.props

    if (prevProps.container !== container || prevProps.element !== element) {
      this.scheduleScroll()
    }
  }

  scheduleScroll() {
    this.ref.scrollIntoView(true)
  }

  render() {
    return (
      <div ref={ref => (this.ref = ref)} style={{ paddingTop: '30px' }}>
        {React.Children.only(this.props.children)}
      </div>
    )
  }
}

ScrollOnMount.propTypes = {
  container: PropTypes.instanceOf(global.Element),
  element: PropTypes.instanceOf(global.Element),
}
