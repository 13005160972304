import * as React from 'react'
import { css } from 'react-emotion'
import propTypes from 'prop-types'

const iconList = css`
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
`
const IconList = ({ height, width }) => (
  <svg viewBox="0 0 17.758 12.725" height={height} width={width}>
    <g id="Group_1222" data-name="Group 1222" transform="translate(0 1)">
      <line
        id="Line_576"
        data-name="Line 576"
        css={iconList}
        x2="17.757"
        transform="translate(0)"
      />
      <line
        id="Line_577"
        data-name="Line 577"
        css={iconList}
        x2="17.757"
        transform="translate(0 5.362)"
      />
      <line
        id="Line_578"
        data-name="Line 578"
        css={iconList}
        x2="17.757"
        transform="translate(0 10.725)"
      />
    </g>
  </svg>
)
IconList.propTypes = {
  height: propTypes.number,
  width: propTypes.number,
}

export default IconList
