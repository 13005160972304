import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'

import LoginForm from './LoginForm'

export default function LoginContainer() {
  return (
    <div className={wrapper}>
      <Helmet title="Login" />
      <div className={card}>
        <LoginForm />
      </div>
      <div className={below}>
        <Link to="/register">
          No account yet? <span>Sign Up</span>
        </Link>
      </div>
    </div>
  )
}

const wrapper = css`
  max-width: 370px;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 5px;
  }
`

const card = css`
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 4px #000;
  border-top: 3px solid #008aab;
  &.wide {
    max-width: 800px;
  }
  img {
    display: block;
    margin: 0 auto 10px;
    width: 300px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 52px;
    height: auto;
    overflow-y: scroll;
  }
`

const below = css`
  padding: 15px 0 15px 0;
  a {
    color: #fff;
    text-shadow: 1px 1px 20px black;
    font-size: 1.15em;
    &:hover span {
      text-decoration: underline;
    }
    span {
      font-weight: 600;
      letter-spacing: -0.025em;
    }
  }
`
