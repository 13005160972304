import { SORT_OPTIONS } from '../constants'

const getSortQueryByValue = value => {
  const sortOption =
    SORT_OPTIONS.find(option => option.value === value) || SORT_OPTIONS.find(option => option.query === '-score')

  return sortOption.query
}

export default getSortQueryByValue
