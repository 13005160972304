import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Field, reduxForm, reset } from 'redux-form'
import { AlignLabels, Button, PhantomLabelSpacer, TextField } from 'common'
import { changePassword } from '../../../redux/modules/auth'
import { getPasswordStrength } from '../../../redux/modules/users'

export function ChangePasswordForm({
  valid,
  submitting,
  asyncValidating,
  handleSubmit,
}) {
  return (
    <div>
      <h4>Change Password</h4>

      <form onSubmit={handleSubmit}>
        <AlignLabels align="left" width="180px">
          <Field
            name="password_old"
            type="password"
            label="Current Password"
            component={TextField}
          />
          <Field
            name="password_new"
            type="password"
            label="New Password"
            component={TextField}
          />
          <Field
            name="password_confirm"
            type="password"
            label="Confirm Password"
            component={TextField}
          />
        </AlignLabels>

        <PhantomLabelSpacer>
          <div css={mobile}>
            <Button disabled={submitting || asyncValidating} submit>
              Change Password
            </Button>
          </div>
        </PhantomLabelSpacer>
      </form>
    </div>
  )
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

ChangePasswordForm.propTypes = {
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  asyncValidating: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'changePassword',
  asyncValidate,
  asyncBlurFields: ['password_new', 'password_confirm'],
  onSubmit: (values, dispatch) =>
    dispatch(
      changePassword({
        newPasswordConfirm: values.password_confirm,
        newPassword: values.password_new,
        oldPassword: values.password_old,
      })
    ).then(() => dispatch(reset('changePassword'))),
})(ChangePasswordForm)

async function asyncValidate({ password_new, password_confirm }, dispatch) {
  if (!password_confirm) return null

  if (password_new !== password_confirm)
    throw {
      password_confirm: 'Passwords don‘t match.',
    }

  const {
    payload: { score, warning },
  } = await dispatch(getPasswordStrength(password_new))
  if (score < 2)
    throw {
      password_new: warning || `Please choose a stronger password.`,
    }
}
