import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridWrappingColumn, StickyFloater } from 'common'
import { asyncConnect } from 'redux-connect'
import { withCookies } from 'react-cookie'
import Helmet from 'react-helmet'
import { loadSubmission, loadImage } from '../../../redux/modules/registrations'
import SubmissionFormDataProvider from './Form/SubmissionFormDataProvider'
import SubmissionSuccess from './Success/SubmissionSuccess'
import { compose } from 'recompose'
// CR_STYL: import _styles from './SubmissionContainer.styl'
import { createSelector } from 'reselect'
import { css } from 'react-emotion'
import qs from 'qs'

const log = require('debug')('submission')

const iconBack = require('../../Matches/V2/QueryBar/backIcon.svg')

export class RegistrationSubmissionContainer extends Component {
  state = {
    show: 'form',
  }
  handleSave = id => {
    const { location, history } = this.props
    log('handleSave', id)

    if (location.pathname && location.pathname.includes('new')) {
      history.replace(`/registrations/submit/${id}`)
    }

    this.setState({ show: 'form' })
  }

  handleSuccess = () => {
    this.setState({ show: 'success' })
  }

  render() {
    const {
      registration,
      user,
      history,
      image,
      query: { registrationId },
    } = this.props
    const { show } = this.state

    const title = registration ? (
      <span>
        Editing Copyright Registration{' '}
        <b>{registration.salesforce && registration.salesforce.Name}</b>
      </span>
    ) : (
      <b>New Copyright Registration</b>
    )

    return (
      <GridWrappingColumn maxWidth={1024} padding={250}>
        <Helmet
          title={
            registration
              ? 'Registration ' +
                (registration.salesforce && registration.salesforce.Name)
              : 'Copyright Registration'
          }
        />
        <StickyFloater>
          <button
            onClick={history.goBack}
            dangerouslySetInnerHTML={{ __html: iconBack }}
            css={backButt}
          />
          <span className="sticky-floater--left-aligned-description">
            {title}
          </span>
        </StickyFloater>

        <div /* CR_STYL: className={_styles.innerContent}*/>
          {show === 'success' ? (
            <SubmissionSuccess registration={registration} />
          ) : (
            <SubmissionFormDataProvider
              registration={registration}
              image={image}
              registrationId={registrationId}
              user={user}
              onSuccess={this.handleSuccess}
              onSave={this.handleSave}
            />
          )}
        </div>
      </GridWrappingColumn>
    )
  }
}

const resolveLocation = (location, match) => {
  const { image } = qs.parse(String(location.search).substring(1))
  const {
    params: { registrationId },
  } = match
  return {
    registrationId,
    imageId: image,
  }
}

const getRegistrationId = (state, props) => props.match.params.registrationId

const getImageId = (state, props) => {
  const image = qs.parse(String(props.location.search).substring(1)).image
  if (image) return image

  const existingRegistration =
    state.entities.registrations[getRegistrationId(state, props)]
  if (
    existingRegistration &&
    existingRegistration.images &&
    existingRegistration.images.length
  )
    return existingRegistration.images[0]._id || existingRegistration.images[0]
}

const getEntities = (state, props) => state.entities

const getRegistration = createSelector(
  [getRegistrationId, getEntities],
  (registrationId, entities) => {
    if (entities.registrations[registrationId]) {
      const registration = { ...entities.registrations[registrationId] }
      registration.images = registration.images
        .map(i => {
          if (i && i.url) return i
          return entities.images[i]
        })
        .filter(Boolean)

      return registration
    }
    return false
  }
)

const getImage = createSelector(
  [getImageId, getEntities],
  (imageId, entities) =>
    entities.images[imageId] && {
      ...entities.images[imageId],
    }
)

const getQuery = createSelector(
  getRegistrationId,
  getImageId,
  (registrationId, imageId) => ({
    registrationId,
    imageId,
  })
)

const mapStateToProps = (state, props) => ({
  query: getQuery(state, props),
  registration: getRegistration(state, props),
  image: getImage(state, props),
})

export default compose(
  asyncConnect(
    [
      {
        key: 'fetchDataForSubmissionContainer',
        promise: async function fetchDataForSubmissionContainer({
          store: { getState, dispatch },
          match,
          location,
        }) {
          const { registrationId, imageId } = resolveLocation(location, match)

          if (registrationId && registrationId.length === 24) {
            return dispatch(loadSubmission(registrationId))
          }

          if (imageId && imageId.length === 24) {
            return dispatch(loadImage(imageId))
          }
        },
      },
    ],
    mapStateToProps
  ),
  withCookies
)(RegistrationSubmissionContainer)

RegistrationSubmissionContainer.propTypes = {
  history: PropTypes.object.isRequired,
  query: PropTypes.shape({
    registrationId: PropTypes.string.isRequired,
  }).isRequired,
}

const backButt = css`
  max-width: 68px !important;
  height: 54px !important;
  top: 0;
  border: 0;
  padding: 0 !important;
  line-height: 13px;
  background-color: #008aab;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 24px;
    width: 24px;
  }
`
