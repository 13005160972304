import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'querystring'
import { getClustersQuery } from '../../../../redux/selectors'

SortBarControlPanelInner.propTypes = {
  togglePanel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setting: PropTypes.string.isRequired,
}

const injectSetting = (Component) =>
  withRouter(
    connect((state, props) => {
      const { sort } = getClustersQuery(state, props)

      return {
        setting: sort,
        handleClose: props.togglePanel.bind(null, 'LeftComponent', null),
      }
    })(Component)
  )

export const SortBarControlPanel = injectSetting(SortBarControlPanelInner)

function SortBarControlPanelInner({
  togglePanel,
  handleClose,
  location,
  setting,
}) {
  const createDestination = (newSetting) => {
    const queryObj = qs.parse(String(location.search).substr(1))

    const newQueryArgs = {
      ...queryObj,
      sort: newSetting,
    }
    return {
      ...location,
      search: qs.stringify(newQueryArgs),
    }
  }

  return (
    <div className={wrap}>
      <div css={section}>
        <h4>Commercial</h4>
        <ul onClick={handleClose}>
          <li css={cx(setting === '-score' && active)}>
            <Link to={createDestination('-score')}>Most</Link>
          </li>
          <li css={cx(setting === 'score' && active)}>
            <Link to={createDestination('score')}>Least</Link>
          </li>
        </ul>
      </div>

      <div css={section}>
        <h4>Detected</h4>
        <ul onClick={handleClose}>
          <li css={cx(setting === '-matches.created' && active)}>
            <Link to={createDestination('-matches.created')}>Last</Link>
          </li>
          <li css={cx(setting === 'created' && active)}>
            <Link to={createDestination('created')}>First</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

SortBarLabelInner.propTypes = {
  togglePanel: PropTypes.func.isRequired,
  setting: PropTypes.string.isRequired,
}

function SortBarLabelInner({ setting, togglePanel }) {
  const humanized = {
    '-created': 'Newest First',
    created: 'Oldest First',
    '-score': 'Most Commercial',
    score: 'Least Commercial',
    '-accuracy': 'Most Similar',
    accuracy: 'Least Similar',
  }[setting]
  return (
    <p
      css={label}
      onClick={() => togglePanel('LeftComponent', SortBarControlPanel)}
    >
      <span>Sort</span>
      <b>{humanized}</b>
    </p>
  )
}

export const SortBarLabel = injectSetting(SortBarLabelInner)

const wrap = css`
  width: 100%;

  font-size: 0.9em;
  color: #999;
  text-align: left;
  display: flex;
  justify-content: space-between;
  user-select: none;
`

const section = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 30px 40px;
  h4 {
    letter-spacing: normal;
    font-size: 1.6em;
  }

  ul {
    padding-left: 0;
    list-style: none;
    font-size: 1.2em;
    margin-bottom: 0;
    li {
      text-transform: uppercase;
      a {
        color: inherit;
      }
    }
  }
`

const active = css`
  color: #000;
  position: relative;
  &:after {
    display: inline;
    position: relative;
    content: '';
    transform: translateY(10px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid black;
    left: 6px;
    top: 12px;
  }
`

const label = css`
  align-items: center;
  color: rgb(180, 180, 180);
  cursor: pointer;
  display: inline-flex;
  font-size: 0.9em;
  font-weight: 500px;
  justify-content: flex-start;
  letter-spacing: 2px;
  margin-bottom: 0px;
  text-align: right;
  text-transform: uppercase;
  width: 100%;
  span {
    padding-right: 8px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    span {
      padding-bottom: 8px;
      padding-right: 5px;
    }
  }
`
