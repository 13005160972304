import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

export default function StickyHeadTable({ columns, rows }) {

  const cellStyle = (column, value) => {
    let tableStyle = {...tableStyle}
    if (column.style) {
      tableStyle ={...tableStyle, ...column.style(value)}
    }
    return tableStyle
  }

  const headStyle = () => {
    return {
      fontWeight: 400,
      padding: '10px 10px 10px 10px !important',
      border: 0,
      fontSize: '0.75em',
      color: 'hsl(0,0%,60%)',
      letterSpacing: '0.2em',
      textTransform: 'uppercase',
      borderBottom: '0 !important'
    }
  }

  return (
    <Paper style={{ width: '100%', maxHeight: '70vh', overflow: 'hidden' }}>
      <TableContainer style={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table" style={{...tableStyle}}>
          <TableHead>
            <TableRow style={headStyle()}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, ...tableStyle }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{...tableStyle}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const tableStyle = {
  fontSize: 'unset',
  fontWeight: 'unset',
  fontFamily: 'unset',
  color: 'unset'
}