import * as React from 'react'
import { css, cx } from 'react-emotion'

const baseStyle = css`
  fill: #939393;
`

export default React.memo(({ style = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.5 25.134"
    className={cx(baseStyle, style)}
  >
    <g transform="translate(-52 -258)">
      <g transform="translate(45.5 258)">
        <path d="M25.761,5.281l-.334-.334L21.053.573,20.719.239A.809.809,0,0,0,20.143,0H7.567A1.068,1.068,0,0,0,6.5,1.067v23a1.068,1.068,0,0,0,1.067,1.067H24.933A1.068,1.068,0,0,0,26,24.066V5.856A.807.807,0,0,0,25.761,5.281ZM20.8,1.545,24.455,5.2H20.8Zm4.333,22.521a.2.2,0,0,1-.2.2H7.567a.2.2,0,0,1-.2-.2v-23a.2.2,0,0,1,.2-.2H19.958a.668.668,0,0,0-.025.17v5.03h5.03a.673.673,0,0,0,.17-.025Z" />
        <path
          d="M22.5,23.257a4.768,4.768,0,1,0-.692.534l4.516,4.516a.433.433,0,0,0,.613-.613Zm-3.236.41a3.9,3.9,0,1,1,3.9-3.9A3.9,3.9,0,0,1,19.267,23.667Z"
          transform="translate(-4.533 -8.5)"
        />
        <path
          d="M21.533,21h-2.6a.433.433,0,1,0,0,.867h2.6a.433.433,0,1,0,0-.867Z"
          transform="translate(-6.8 -11.9)"
        />
        <path
          d="M27.933,21.867H28.8a.433.433,0,1,0,0-.867h-.867a.433.433,0,1,0,0,.867Z"
          transform="translate(-11.9 -11.9)"
        />
        <path
          d="M22.8,29h-.867a.433.433,0,1,0,0,.867H22.8a.433.433,0,1,0,0-.867Z"
          transform="translate(-8.5 -16.433)"
        />
        <path
          d="M28.8,29h-.867a.433.433,0,1,0,0,.867H28.8a.433.433,0,1,0,0-.867Z"
          transform="translate(-11.9 -16.433)"
        />
        <path
          d="M21.1,25.433A.433.433,0,0,0,20.667,25H18.933a.433.433,0,1,0,0,.867h1.733A.433.433,0,0,0,21.1,25.433Z"
          transform="translate(-6.8 -14.167)"
        />
        <path
          d="M29.1,25H26.933a.433.433,0,1,0,0,.867H29.1a.433.433,0,1,0,0-.867Z"
          transform="translate(-11.333 -14.167)"
        />
      </g>
    </g>
  </svg>
))
