import { memoize } from 'lodash'
import { CREATIVE_COMMONS_OPTIONS } from 'pixsy-constants'
import * as React from 'react'
// import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'
// import { PixsyMenuItemSeparator } from '../PixsyMenuItem/PixsyMenuItemSeparator'

// export const CreativeCommonsItems = memoize(() => {
//   return [
//     <PixsyMenuItem key="0-0" value="" disabled>
//       <em>Choose License</em>
//     </PixsyMenuItem>,
//     <PixsyMenuItemSeparator key="0-1" />,
//   ].concat(
//     CREATIVE_COMMONS_OPTIONS.map(c => (
//       <PixsyMenuItem key={c} value={c}>
//         {c}
//       </PixsyMenuItem>
//     ))
//   )
// })

const MAX_WIDTH = 10
const SEPARATOR = '─'.repeat(MAX_WIDTH)

export const CreativeCommonsItems = memoize(() => {
  return [
    <option key="choose" value="" disabled>
      Choose License
    </option>,
    <option key="options" disabled>
      {SEPARATOR}
    </option>,
  ].concat(
    CREATIVE_COMMONS_OPTIONS.map(c => (
      <option key={c} value={c}>
        {c}
      </option>
    ))
  )
})
