import React from 'react'

export const SubmissionValidationDialogDomainUnresolvable = () => (
  <div>
    <h6>
      Pixsy has previously identified that this domain is not viable for
      commercial resolution.
    </h6>

    <p>
      We recommend that you <b>send a Takedown Notice</b> for this match,
      requesting the image is removed by the website operator or host.
    </p>

    <p>
      Pixsy uses a set of algorithms to pre-determine if a case is suitable for
      resolution. We do this to save you time, and to ensure that our case
      management team can focus their efforts on recovering compensation for
      viable commercial cases.
    </p>

    <p>
      If you believe this domain has been blocked but the case is still a
      commercial use within a Pixsy supported country, you can contact us and we
      will further evaluate the case for you.
    </p>
  </div>
)
