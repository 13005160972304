import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { ContentSection, RegistrationTypeSelect } from 'common'
import { PHASE } from '../../../../../../shared/forms/registrationSubmission'

export default function SubmissionFormRegistrationTypes({
  getFieldPropsFor,
  onWaypoint,
  locked,
  registration,
  phase,
}) {
  return (
    <ContentSection id="package">
      <Waypoint onEnter={onWaypoint} />
      <h1>Registration Types</h1>
      <RegistrationTypeSelect
        getFieldPropsFor={getFieldPropsFor}
        locked={
          phase === PHASE.FINAL ||
          locked ||
          (registration &&
            registration.salesforce &&
            registration.salesforce.PaymentReceivedD__c)
        }
      />
    </ContentSection>
  )
}

SubmissionFormRegistrationTypes.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  getFieldPropsFor: PropTypes.func.isRequired,
  locked: PropTypes.bool,
}
