import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, cx } from 'react-emotion'
import { connect } from 'react-redux'
import { ChooseBestImageSource, SexyThumbnail, SelectableContext } from 'common'

const iconMerge = require('./iconMerge.svg')
const iconIgnore = require('./iconIgnore.svg')

class MergePanel extends Component {
  componentWillReceiveProps({ images: newImages }) {
    if (
      this._mergeThumbnails &&
      this.props.images &&
      this.props.images.length !== newImages.length
    ) {
      this._mergeThumbnails.scrollLeft = 10e10
    }
  }

  resolveImage = id => {
    return this.props.allImages[id]
  }

  render() {
    const {
      onMerge,
      onIgnore,
      // onIgnore = null,
    } = this.props

    const isImageRegistered = img =>
      (img.registrations && img.registrations[0]) ||
      (img.registration &&
        img.registration.us &&
        img.registration.us.status &&
        ['Yes', 'Pixsy', 'Pending'].includes(img.registration.us.status)) ||
      (img.licensing &&
        ['yes', 'pending', 'pixsy'].includes(img.licensing.registered))

    return (
      <SelectableContext.Consumer>
        {({ selection, isSelecting, clear }) => (
          <MergeContainer visible={isSelecting}>
            <div css={mergeBody}>
              <div css={mergeLeftPanel}>
                <div css={mergeSelected}>
                  <div css={MergeSelectedWrap}>
                    <div css={mergeSelectedTop}>
                      {selection.length}
                      {selection.length === 1 ? ' Image' : ' Images'}
                    </div>
                    <div css={mergeSelectedBottom}>SELECTED</div>
                  </div>
                </div>
                <div
                  ref={c => (this._mergeThumbnails = c)}
                  css={mergeThumbnails}
                >
                  <div css={mergeGrid}>
                    {selection.map(this.resolveImage).map(img => (
                      <div css={mergeGridImage} key={img._id}>
                        <ChooseBestImageSource
                          candidates={[img.thumbnail, img.url]}
                          type="image"
                        >
                          {props => <SexyThumbnail {...props} />}
                        </ChooseBestImageSource>
                      </div>
                    ))}
                  </div>
                </div>
                <button onClick={clear} css={mergeClearImages}>
                  clear selection
                </button>
              </div>
              <div css={mergeRightPanel}>
                <div css={MergeActionsLabel}>ACTION</div>
                <div css={MergeActionsButtons}>
                  <ActionButton
                    icon={iconMerge}
                    width={52}
                    onClick={onMerge}
                    disabled={
                      selection.length < 2 ||
                      selection.map(this.resolveImage).filter(isImageRegistered)
                        .length > 1
                    }
                    title={
                      selection.map(this.resolveImage).filter(isImageRegistered)
                        .length > 1
                        ? 'Two or more of the selected images have beed registered. Because image information is locked for registered images, the selection can not be merged'
                        : ''
                    }
                  />
                  <MergeActionSeparator />
                  <ActionButton
                    icon={iconIgnore}
                    width={56}
                    onClick={onIgnore}
                  />
                </div>
              </div>
            </div>
          </MergeContainer>
        )}
      </SelectableContext.Consumer>
    )
  }
}

export default connect(state => ({
  allImages: state.entities.images,
}))(MergePanel)

MergePanel.propTypes = {
  allImages: PropTypes.object.isRequired,
  onMerge: PropTypes.func.isRequired,
}

const ActionButton = ({
  icon,
  onClick = null,
  width,
  disabled = false,
  title = '',
}) => (
  <div
    onClick={disabled ? null : onClick}
    dangerouslySetInnerHTML={{ __html: icon }}
    style={{ width: `${width}px` }}
    css={cx(disabled ? disabledActionBtn : activeActionBtn)}
    title={title}
  />
)
const activeActionBtn = css`
  opacity: 0.6;
  outline: none !important;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
`
const disabledActionBtn = css`
  opacity: 0.2;
  cursor: not-allowed;
  outline: none !important;
`

const MergeContainer = styled.div`
  height: 100px;
  background: white;
  position: fixed;
  bottom: 30px;
  z-index: 2;
  width: calc(100% - 170px); /*navbar plus 30px times 2 */
  left: 140px; /*navbar plus 30px*/
  @media (min-width: 1600px) {
    width: calc(90% - 170px); /*navbar plus 30px times 2 */
    left: calc(140px + 5%); /*navbar plus 30px*/
  }
  @media (min-width: 2000px) {
    width: calc(80% - 170px); /*navbar plus 30px times 2 */
    left: calc(140px + 10%); /*navbar plus 30px*/
  }
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: 0.2s opacity ease-in;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};

  /*@media (max-width: 1280px) {
    left: 251px;
  }*/

  @media (max-width: 768px) {
    top: auto;
    left: 10px;
    right: 10px;
    bottom: 76px;
    width: auto;
    height: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`
const mergeBody = css`
  height: 100%;
  min-width: 100%;
  display: flex;
  padding: 0 20px;
  user-select: none;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const mergeLeftPanel = css`
  display: inline-flex;
  flex-grow: 4;
  height: 100%;
  width: 50%;
  padding: 0 20px;
  @media (max-width: 996px) {
    // display: none;
  }
  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`
const mergeRightPanel = css`
  border-left: 1px solid #e2e2e2;
  @media (max-width: 996px) {
    border: none;
  }
  display: inline-flex;
  flex-grow: 1;
  padding: 0 20px;
  @media (max-width: 768px) {
    display: block;
    width: 50%;
  }
`
const mergeSelected = css`
  width: 120px;
  display: inline-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
`
const MergeSelectedWrap = css`
  width: 120px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
  z-index: 1;
  :after {
    content: '';
    position: absolute;
    right: -25px;
    top: 0;
    width: 25px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }
`

const mergeSelectedTop = css`
  font-weight: 600;
  letter-spacing: 0;
  font-size: 1.2em;

  color: #2e2e2e;
`
const mergeSelectedBottom = css`
  letter-spacing: 2px;
  font-weight: 500;
  color: #2e2e2e;
`
const mergeThumbnails = css`
  overflow-x: hidden;
  max-width: 70%;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    overflow-x: scroll;
    padding: 0;
  }
`
const mergeGrid = css`
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 4px;
`
const mergeGridImage = css`
  max-height: 75px;
  width: 75px;
  padding-left: 10px;
`
const mergeClearImages = css`
  align-items: center;
  display: inline-flex;
  padding: 0 20px;
  appearance: none;
  background-color: transparent;
  border: 0 !important;

  text-decoration: none;
  color: rgba(46, 46, 46, 0.3);
  &:focus {
    outline: 0 !important;
  }
  &:hover {
    text-decoration: underline;
  }
`

const MergeActionsLabel = css`
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  color: #2e2e2e;

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`

const MergeActionsButtons = css`
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
  align-items: center;
  svg {
    text {
      font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
    }
  }
  @media (max-width: 768px) {
    display: block;

    svg {
      height: 50px;
    }
  }
`

const MergeActionSeparator = styled.div`
  height: 60%;
  margin-left: 20px;
  margin-right: 20px;
  width: 1px;
  border-left: 1px solid #e2e2e2;
  align-self: center;
`
