import React from 'react'
import PropTypes from 'prop-types'
import styled, { cx, css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { SexySeparator, ChooseBestImageSource } from 'common'

export default function ImageSection({ image }) {
  const { matches } = image
  return (
    <React.Fragment>
      <div css={cx(imageSection, image.rejected && rejectedStyles)}>
        <div css={padded}>
          <h5>Original Image</h5>

          <ImageRow large>
            <Link to={`/images/${image._id}`}>
              <ChooseBestImageSource candidates={[image.url]} type="image">
                {({ url }) => <img src={url} />}
              </ChooseBestImageSource>
            </Link>
            <div>
              <dl>
                <dt>Title</dt>
                <dd>
                  <Link to={`/images/${image._id}`}>{image.title}</Link>
                </dd>
              </dl>
            </div>
          </ImageRow>
        </div>
        <SexySeparator space={30} />
        <div css={padded}>
          <h5>Matches</h5>
        </div>
        <div css={padded}>
          {matches.map(m => (
            <ChooseBestImageSource
              key={m._id}
              candidates={[m.url]}
              type="match"
              match={m}
            >
              {({ url }) => (
                <ImageRow>
                  <img src={url} />
                  <div>
                    <dl>
                      <dt>Website URL</dt>
                      <dd>
                        <a target="_blank" href={m.origin.url} rel="noreferrer">
                          {m.origin.url}
                        </a>
                      </dd>
                      <dt>Image URL</dt>
                      <dd>
                        <a target="_blank" href={m.url} rel="noreferrer">
                          {m.url}
                        </a>
                      </dd>
                    </dl>
                  </div>
                </ImageRow>
              )}
            </ChooseBestImageSource>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

ImageSection.propTypes = {
  image: PropTypes.object.isRequired,
}
const imageSection = css`
  max-width: 100%;

  h5 {
    font-size: 0.8em;
    color: #3b3b3b80;
    letter-spacing: 1.4px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  dl {
    margin-bottom: 0;
  }

  dt {
    color: #3b3b3b80;
    font-weight: 400;
    font-size: 0.7em;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 auto -2px;
  }

  dd {
    overflow: hidden;
    word-wrap: break-word;
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 1.3em;
    line-height: 1em;
    padding: 0 0 3px;
    a {
      color: #3b3b3b;
      font-size: 0.8em;
    }
  }
`

const padded = css`
  padding: 0 20px;
`

const ImageRow = styled.div`
  display: flex;
  margin-bottom: 35px;

  img {
    max-width: ${props => (props.large ? 300 : 200)}px;
    max-height: ${props => (props.large ? 190 : 120)}px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  }
  div {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0 30px;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > img {
      width: 100%;
      max-height: none;
    }

    > div {
      padding: 10px 0 0;
    }
  }
`

const rejectedStyles = css`
  filter: grayscale(1);
`
