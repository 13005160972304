import _ from 'lodash'
import {
  REGISTRATION,
  REGISTRATION_ARRAY,
  HISTORY_ARRAY,
  ATTACHMENT_ARRAY,
  IMAGE,
  USER,
} from './schemas'
import { IMAGE_LOAD, IMAGE_LOAD_FAIL, IMAGE_LOAD_SUCCESS } from './images'

const REGISTRATION_LOAD = 'REGISTRATION_LOAD'
export const REGISTRATION_LOAD_SUCCESS = 'REGISTRATION_LOAD_SUCCESS'
const REGISTRATION_LOAD_FAIL = 'REGISTRATION_LOAD_FAIL'

const REGISTRATIONS_LOAD = 'REGISTRATIONS_LOAD'
export const REGISTRATIONS_LOAD_SUCCESS = 'REGISTRATIONS_LOAD_SUCCESS'
const REGISTRATIONS_LOAD_FAIL = 'REGISTRATIONS_LOAD_FAIL'

const CHECK_PREVIOUS_SUBMISSION = 'CHECK_PREVIOUS_SUBMISSION'
const CHECK_PREVIOUS_SUBMISSION_SUCCESS = 'CHECK_PREVIOUS_SUBMISSION_SUCCESS'
const CHECK_PREVIOUS_SUBMISSION_FAIL = 'CHECK_PREVIOUS_SUBMISSION_FAIL'

const REGISTRATION_VALIDATION_SUBMIT = 'REGISTRATION_VALIDATION_SUBMIT'
const REGISTRATION_VALIDATION_SUBMIT_SUCCESS =
  'REGISTRATION_VALIDATION_SUBMIT_SUCCESS'
const REGISTRATION_VALIDATION_SUBMIT_FAIL =
  'REGISTRATION_VALIDATION_SUBMIT_FAIL'

export const REGISTRATIONS_INVALIDATE = 'REGISTRATIONS_INVALIDATE'

const REGISTRATION_SUBMIT = 'REGISTRATION_SUBMIT'
export const REGISTRATION_SUBMIT_SUCCESS = 'REGISTRATION_SUBMIT_SUCCESS'
const REGISTRATION_SUBMIT_FAIL = 'REGISTRATION_SUBMIT_FAIL'

const REGISTRATION_SUBMISSION_LOAD = 'REGISTRATION_SUBMISSION_LOAD'
const REGISTRATION_SUBMISSION_LOAD_SUCCESS =
  'REGISTRATION_SUBMISSION_LOAD_SUCCESS'
const REGISTRATION_SUBMISSION_LOAD_FAIL = 'REGISTRATION_SUBMISSION_LOAD_FAIL'

export const REGISTRATION_HISTORY_LOAD = 'REGISTRATION_HISTORY_LOAD'
const REGISTRATION_HISTORY_LOAD_SUCCESS = 'REGISTRATION_HISTORY_LOAD_SUCCESS'
const REGISTRATION_HISTORY_LOAD_FAIL = 'REGISTRATION_HISTORY_LOAD_FAIL'

const REGISTRATION_ATTACHMENTS_LOAD = 'REGISTRATION_ATTACHMENTS_LOAD'
const REGISTRATION_ATTACHMENTS_LOAD_SUCCESS =
  'REGISTRATION_ATTACHMENTS_LOAD_SUCCESS'
const REGISTRATION_ATTACHMENTS_LOAD_FAIL = 'REGISTRATION_ATTACHMENTS_LOAD_FAIL'

const REGISTRATION_UPDATE = 'REGISTRATION_UPDATE'

const REGISTRATIONS_UPDATE = 'REGISTRATIONS_UPDATE'
const REGISTRATIONS_UPDATE_SUCCESS = 'REGISTRATIONS_UPDATE_SUCCESS'
const REGISTRATIONS_UPDATE_FAIL = 'REGISTRATIONS_UPDATE_FAIL'

const PAYMENT_HANDLE = 'PAYMENT_HANDLE'
const PAYMENT_HANDLE_SUCCESS = 'PAYMENT_HANDLE_SUCCESS'
const PAYMENT_HANDLE_FAIL = 'PAYMENT_HANDLE_FAIL'

const BUMP_REQUEST = 'BUMP_REQUEST'
const BUMP_REQUEST_SUCCESS = 'BUMP_REQUEST_SUCCESS'
const BUMP_REQUEST_FAIL = 'BUMP_REQUEST_FAIL'

const REGISTRATIONS_APPLY_SORT_KEY = 'REGISTRATIONS_APPLY_SORT_KEY'
export const REGISTRATIONS_RESET_FILTER = 'REGISTRATIONS_RESET_FILTER'

export const LFC_DETAIL_MODAL = 'LFC_DETAIL_MODAL'
export const LFC_TABLE_ROW_ACCESS_HANDOVER = 'LFC_TABLE_ROW_ACCESS_HANDOVER'

export const SAVE_REGISTRATION = 'SAVE_REGISTRATION'
export const SAVE_REGISTRATION_SUCCESS = 'SAVE_REGISTRATION_SUCCESS'
export const SAVE_REGISTRATION_ERROR = 'SAVE_REGISTRATION_ERROR'

export const NOTIFY_USER = 'NOTI_FY_USER'
export const NOTIFY_USER_SUCCESS = 'NOTI_FY_USER_SUCCESS'
export const NOTIFY_USER_ERROR = 'NOTI_FY_USER_ERROR'

const initialState = {
  sortOrder: 'DESC',
  sortKey: 'Name',
  loading: false,
  images: [],
}

export default function registrations(state = initialState, action = {}) {
  switch (action.type) {
    case REGISTRATION_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      }

    case REGISTRATIONS_LOAD:
    case REGISTRATION_SUBMIT:
      return {
        ...state,
        loading: true,
      }

    case REGISTRATIONS_LOAD_FAIL:
    case REGISTRATIONS_LOAD_SUCCESS:
    case REGISTRATION_SUBMIT_SUCCESS:
    case REGISTRATION_SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
      }

    case REGISTRATION_LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error ? action.error.message : action.error,
      }
    case REGISTRATIONS_LOAD_SUCCESS:
      return {
        ...state,
        error: null,
      }
    case REGISTRATIONS_LOAD_FAIL:
      return {
        ...state,
        data: null,
        error: action.error ? action.error.message : action.error,
      }
    case REGISTRATIONS_APPLY_SORT_KEY:
      return {
        ...state,
        sortKey: action.payload.sortKey,
        sortOrder: state.sortOrder === 'ASC' ? 'DESC' : 'ASC',
      }
    case REGISTRATIONS_RESET_FILTER:
      return {
        ...state,
        sortKey: null,
        sortOrder: 'ASC',
      }

    case SAVE_REGISTRATION:
      return {
        ...state,
        loading: true,
      }
    case SAVE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case SAVE_REGISTRATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error ? action.error.message : action.error,
      }

    case NOTIFY_USER:
      return {
        ...state,
        loading: true,
      }
    case NOTIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case NOTIFY_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error ? action.error.message : action.error,
      }

    default:
      return state
  }
}

export function paginationKey(query = {}) {
  return [
    query.filter,
    query.page || '0',
    query.sortKey || 'Id',
    query.sortDir || 'DESC',
  ].join('-')
}

/**
 * @param {Object} globalState
 * @returns {boolean}
 */
export function isLoaded(globalState) {
  return globalState.registrations && globalState.registrations.loaded
}

/**
 * @param {Object} globalState
 * @param {string} id
 * @returns {boolean}
 */
export function has(globalState, id) {
  return !!globalState.entities.registrations[id]
}

/**
 * @param {Object} globalState
 * @param {string} id registrationId
 * @returns {boolean}
 */
export function areNotesLoaded(globalState, id) {
  const c = globalState.entities.registrations[id]

  if (!c || !c.notes) return false

  for (const n of c.notes) if (n.Id === id) return true

  return false
}

/**
 * @param {Object} entities
 * @returns {Array}
 */
export function find(entities, query = {}) {
  return _.cloneDeep(_.filter(_.toArray(entities), query))
}

/**
 * @param {Object} entities
 * @param {string} id from MongoDB
 * @returns {Array}
 */
export function getNotesForCase(entities, id) {
  return _(entities)
    .toArray()
    .filter(n => n._id === id)
    .value()
}

export function applySortKey(sortKey) {
  return {
    type: REGISTRATIONS_APPLY_SORT_KEY,
    payload: {
      sortKey,
    },
  }
}

export function resetFilter(data = {}) {
  return {
    type: REGISTRATIONS_RESET_FILTER,
    payload: { ...data },
  }
}

export function submit(data) {
  return {
    promise: client => client.post(`/registrations/submission`, { data }),
    types: [
      REGISTRATION_SUBMIT,
      REGISTRATION_SUBMIT_SUCCESS,
      REGISTRATION_SUBMIT_FAIL,
    ],
    schema: {
      registration: REGISTRATION,
    },
  }
}

export function bulkValidate(data, publishedBefore) {
  return {
    promise: client =>
      client.post(
        `/registrations/bulk-submission/validate?publishedBefore=${
          publishedBefore ? 'true' : 'false'
        }`,
        { data }
      ),
    types: [
      REGISTRATION_VALIDATION_SUBMIT,
      REGISTRATION_VALIDATION_SUBMIT_SUCCESS,
      REGISTRATION_VALIDATION_SUBMIT_FAIL,
    ],
  }
}

export function loadSubmission(id, data) {
  return {
    types: [
      REGISTRATION_SUBMISSION_LOAD,
      REGISTRATION_SUBMISSION_LOAD_SUCCESS,
      REGISTRATION_SUBMISSION_LOAD_FAIL,
    ],
    promise: client => client.get(`/registrations/submission/${id}`),
    schema: REGISTRATION,
  }
}

export function checkForPreviousSubmission(url) {
  return {
    types: [
      CHECK_PREVIOUS_SUBMISSION,
      CHECK_PREVIOUS_SUBMISSION_SUCCESS,
      CHECK_PREVIOUS_SUBMISSION_FAIL,
    ],
    promise: client => client.get(`/registrations/by-url`, { params: { url } }),
    schema: REGISTRATION,
  }
}

export function load(id, params = {}) {
  return {
    types: [
      REGISTRATION_LOAD,
      REGISTRATION_LOAD_SUCCESS,
      REGISTRATION_LOAD_FAIL,
    ],
    promise: client => client.get(`/registrations/${id}`, { params }),
    schema: REGISTRATION,
  }
}

export function loadRegistrations(query = {}) {
  return {
    types: [
      REGISTRATIONS_LOAD,
      REGISTRATIONS_LOAD_SUCCESS,
      REGISTRATIONS_LOAD_FAIL,
    ],
    promise: client => client.get('/registrations', { params: query }),
    schema: { registrations: REGISTRATION_ARRAY },
    payload: { query },
  }
}

export function loadImage(id, data) {
  return {
    types: [IMAGE_LOAD, IMAGE_LOAD_SUCCESS, IMAGE_LOAD_FAIL],
    promise: client => client.get(`/images/${id}`),
    schema: IMAGE,
  }
}

/**
 * @param {string} id from Salesforce
 * @returns {Object} Action
 */
export function loadHistory(registrationId) {
  return {
    types: [
      REGISTRATION_HISTORY_LOAD,
      REGISTRATION_HISTORY_LOAD_SUCCESS,
      REGISTRATION_HISTORY_LOAD_FAIL,
    ],
    promise: client => client.get(`/registrations/${registrationId}/history`),
    schema: HISTORY_ARRAY,
    payload: { registrationId },
  }
}

export function loadAttachments(Id) {
  return {
    types: [
      REGISTRATION_ATTACHMENTS_LOAD,
      REGISTRATION_ATTACHMENTS_LOAD_SUCCESS,
      REGISTRATION_ATTACHMENTS_LOAD_FAIL,
    ],
    promise: client => client.get(`/registrations/${Id}/attachments`),
    schema: ATTACHMENT_ARRAY,
  }
}

/**
 * @param {Object} registrations payload
 * @returns {Object} Action
 */
export function update(data) {
  return {
    types: [
      REGISTRATIONS_UPDATE,
      REGISTRATIONS_UPDATE_SUCCESS,
      REGISTRATIONS_UPDATE_FAIL,
    ],
    promise: client => client.put('/registrations', { data }),
    schema: { registrations: REGISTRATION_ARRAY },
  }
}

/**
 * Set Action Required on PH/IF/LF Main Case and attach note
 *
 * @param {Object} data
 * @returns {Object} Action
 */
export function bumpRequest(id, data) {
  return {
    types: [BUMP_REQUEST, BUMP_REQUEST_SUCCESS, BUMP_REQUEST_FAIL],
    promise: client => client.put(`/registrations/${id}/bump`, { data }),
    schema: { registrations: REGISTRATION_ARRAY },
  }
}

export function handleStripePayment(id, data) {
  return {
    types: [PAYMENT_HANDLE, PAYMENT_HANDLE_SUCCESS, PAYMENT_HANDLE_FAIL],
    promise: client =>
      client.post(`/registrations/payment/stripe/${id}`, { data }),
    schema: { registration: REGISTRATION },
  }
}

/**
 * Save Registration for User
 * @param {Object} data Registration Form Data
 */
export function saveRegistration(data) {
  return {
    promise: client => client.post(`/registrations/save`, { data }),
    types: [
      SAVE_REGISTRATION,
      SAVE_REGISTRATION_SUCCESS,
      SAVE_REGISTRATION_ERROR,
    ],
    schema: { registration: REGISTRATION },
  }
}

export function notify(data) {
  return {
    promise: client => client.post('/registrations/notify', { data }),
    types: [NOTIFY_USER, NOTIFY_USER_SUCCESS, NOTIFY_USER_ERROR],
    schema: { user: USER },
  }
}

/**
 * @param {?Object} query
 */
export function invalidate(query) {
  return {
    type: REGISTRATIONS_INVALIDATE,
    schema: { registrations: REGISTRATION_ARRAY },
    payload: { query },
  }
}

/**
 * @deprecated
 * @param {string} _id
 * @param {string} field string
 * @param {any} value
 * @returns {Object} Action
 */
export function updateCase(_id, field, value) {
  return {
    type: REGISTRATION_UPDATE,
    entities: {
      registrations: {
        [_id]: {
          [field]: value,
        },
      },
    },
  }
}
