import * as React from 'react'
import { css, cx } from 'react-emotion'
import { BackButton } from './BackButton'

export const PIXSY_STICKY_BAR_HEIGHT = 54 //px
export const PIXSY_STICKY_BAR_PADDING_TOP = 40 //px
export const PIXSY_STICKY_BAR_PADDING_BOTTOM = 40 //px
export const PIXSY_STICKY_BAR_FULL_HEIGHT =
  PIXSY_STICKY_BAR_PADDING_TOP + // Padding top
  PIXSY_STICKY_BAR_HEIGHT + // Height
  PIXSY_STICKY_BAR_PADDING_BOTTOM // Padding bottom
export const PIXSY_STICKY_BAR_BOX_SHADOW = `0px 2px 10px 0px rgba(0, 0, 0, 0.11)` // X0 Y2 B10 11% in Adobe XD design
export const PIXSY_STICKY_BAR_BOX_SHADOW_HOVER = `0 3px 30px rgba(0, 0, 0, 0.15)`
export const PIXSY_STICKY_BAR_Z_INDEX = 10

const pixsyStickyBarContainer = css`
  padding-top: ${PIXSY_STICKY_BAR_PADDING_TOP}px;
  padding-bottom: ${PIXSY_STICKY_BAR_PADDING_BOTTOM}px;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  z-index: ${PIXSY_STICKY_BAR_Z_INDEX};
  background: linear-gradient(
    rgba(252, 251, 252, 0.9) 50%,
    rgba(252, 251, 252, 0)
  );
  width: 100%;
  max-width: 1420px;
  position: relative;
`
const pixsyStickyBar = css`
  background-color: #ffff;
  width: inherit;
  height: ${PIXSY_STICKY_BAR_HEIGHT}px;
  position: relative;
  transition: all 300ms ease;
  box-shadow: ${PIXSY_STICKY_BAR_BOX_SHADOW};
  &:hover {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15);
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const PixsyStickyBar = React.memo(
  ({ children, style = {}, className, innerStyle = {}, innerClassName }) => (
    <div
      data-type="PixsyStickyBarContainer"
      className={cx(pixsyStickyBarContainer, className, css(style))}
    >
      <div
        data-type="PixsyStickyBar"
        className={cx(pixsyStickyBar, innerClassName, css(innerStyle))}
      >
        {children}
      </div>
    </div>
  )
)
PixsyStickyBar.BackButton = BackButton
