import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { css, cx } from 'react-emotion'
import { Footer } from 'common'
import RandomStory from './RandomStory/RandomStory'
import LoginContainer from './Login/LoginContainer'
import Register from './Register/Register'
import VerifyEmailContainer from './Register/VerifyEmailContainer'
import PasswordResetContainer from './Password/PasswordResetContainer'
import PasswordSetContainer from './Password/PasswordSetContainer'
import FooterWrap from './FooterWrap'
// import Flickr2019 from './Campaigns/Flickr2019'

export default class Home extends Component {
  state = { easedIn: false }

  componentDidMount() {
    setTimeout(() => {
      this.refs.ref && this.setState({ easedIn: true })
    }, 1000)
    const showRecaptcha = () => {
      try {
        document.getElementsByClassName(
          'grecaptcha-badge'
        )[0].parentNode.style.visibility = 'visible'
      } catch (e) {
        setTimeout(showRecaptcha, 100)
      }
    }

    setTimeout(showRecaptcha, 10)
  }

  render() {
    const easedIn = this.state.easedIn

    return (
      <div css={backplate}>
        <div css={home} ref="ref">
          <div css={homeContent}>
            <div className={cx(shadowBack, { in: easedIn })} />

            <div css={innerContainer}>
              <Switch>
                <Route path="/" exact component={LoginContainer} />
                <Route path="/login" exact component={LoginContainer} />
                <Route
                  path="/login/reset/:token"
                  component={PasswordSetContainer}
                />
                <Route
                  path="/login/reset"
                  exact
                  component={PasswordResetContainer}
                />
                <Route
                  path="/register/verify-email/:token"
                  component={VerifyEmailContainer}
                />
                <Route path="/register/ppa" component={Register} />
                <Route path="/register/smugmug" component={Register} />
                <Route path="/register" component={Register} />
              </Switch>
            </div>

            <RandomStory ref="ref">
              <FooterWrap>
                <div css={mobileFooterFix}>
                  <Footer light skipClearbit />
                </div>
              </FooterWrap>
            </RandomStory>

            {/* <Flickr2019 /> */}
          </div>
        </div>
      </div>
    )
  }
}

Home.propTypes = {}

const home = css`
  width: 100%;
  height: 100%;
  display: flex;
`

const homeContent = css`
  overflow: scroll;
  //position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 10px 0 5px 0;
    font-size: 2em;
    letter-spacing: -0.025em;
  }

  h5 {
    margin-bottom: 1.5em;
    margin-top: 0;
    font-size: 1.2em;
    font-weight: 300;
    opacity: 0.75;
  }

  @media (max-height: 680px) {
    align-items: flex-start;
  }
`

const innerContainer = css`
  width: 370px;
  max-width: 96%;
  z-index: 1;
  position: relative;
`

const backplate = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    * {
      box-sizing: border-box;
    }
  }
`

const shadowBack = css`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  transition: background-color cubic-bezier(0.13, 0.58, 0.87, 0.87) 6s;

  &.in {
    background-color: rgba(0, 0, 0, 0);
  }
`

const mobileFooterFix = css`
  @media (max-width: 768px) {
    footer {
      margin: 0px auto 10px;
      padding: 0px;
    }
  }
`
