import { getPasswordStrength } from '../../../redux/modules/users'

export async function asyncValidate(name, dispatch, form) {
  const password = form.values.password
  const {
    payload: { score, warning },
  } = await dispatch(getPasswordStrength(password))
  if (score < 3)
    throw {
      password: warning || 'Please choose a stronger password.',
    }
}
export const asyncBlurFields = ['password']

export const fields = {
  EMAIL: {
    name: 'email',
    type: 'email',
    props: {
      placeholder: 'Email',
    },
    validate(value) {
      return (!value || !value.includes('@')) && 'Invalid email address'
    },
  },
  PASSWORD: {
    name: 'password',
    type: 'password',
    props: {
      placeholder: 'Password',
      instantFeedback: true,
    },
    validate(value) {
      if (!value) return 'Password required.'
      if (value.length < 6) return 'Please choose a stronger password.'
    },
  },
}
