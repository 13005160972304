import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Feedback from './Feedback'
import styled, { css, cx } from 'react-emotion'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import { Label_ as LabelNew, LockedValue } from 'common'

const ActionArea = styled.div`
  width: 100%;
  background-color: ${({ isError }) => (isError ? '#FFF0F0' : 'transparent')};
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 12px;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    cursor: pointer;
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }
  }
`

const DatePickerWrapper = styled.div`
  position: relative;

  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
`
export class DatePickerStandalone extends React.Component {
  handleChangeRaw = e => {
    e.preventDefault()
  }

  render() {
    const { input, meta = {}, datepickerProps = {} } = this.props
    const { dirty, touched, warning, error } = meta

    const actionAreaProps = {}

    if ((touched || dirty) && (warning || error)) {
      if (error) {
        actionAreaProps.isError = true
        actionAreaProps['data-error'] = true
      }
    }

    let maxDate = new Date()
    if (input.value && new Date(input.value) > maxDate) {
      maxDate = new Date(input.value)
    }

    let selected = null

    if (input.value) {
      if (input.value._d) {
        selected = input.value // is moment
      } else {
        selected = moment.utc(correctInputVal(input.value))
      }
    }

    return (
      <ActionArea {...actionAreaProps}>
        <DatePickerWrapper>
          <ReactDatePicker
            onChange={input.onChange}
            onChangeRaw={this.handleChangeRaw}
            minDate={moment.utc().subtract(100, 'years')}
            maxDate={moment.utc(maxDate)}
            dateFormat="LL"
            selected={selected}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            {...datepickerProps}
          />
          <IconArrowDown />
        </DatePickerWrapper>
        <Feedback meta={meta} />
      </ActionArea>
    )
  }
}

export default function DatePickerVertical(props) {
  const { label, locked, showNoAnswer } = props
  return (
    <FormRow {...props} style={{ display: 'block' }}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
        {locked ? (
          <div css={stacked}>
            <LabelNew title={label} />
            <LockedValue
              value={
                props.input.value
                  ? moment.utc(correctInputVal(props.input.value)).format('LL')
                  : showNoAnswer
                  ? '-'
                  : ''
              }
            />
          </div>
        ) : (
          <React.Fragment>
            <LabelNew title={label} />
            <DatePickerStandalone {...props} />
          </React.Fragment>
        )}
      </div>
    </FormRow>
  )
}

const wrap = css`
  display: flex;
  width: 100%;
`

const stacked = css`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const stylesUnlocked = css`
  flex-direction: column;
  align-items: flex-start;
`

const stylesLocked = css`
  justify-content: space-between;
`

DatePickerVertical.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
}

DatePickerVertical.defaultProps = {
  width: '100%',
}

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}

// Firefox is unable to parse GMT dates. See DEV-474
function correctInputVal(inputVal) {
  try {
    const parsed = moment
      .utc(inputVal)
      .format('LL')
      .toLowerCase()
    const lower = inputVal.toLowerCase()
    if (parsed === 'invalid date' && lower.endsWith('gmt')) {
      return lower.replace('gmt', '').trim()
    }
  } catch (err) {}

  return inputVal
}
