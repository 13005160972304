import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import { css, cx } from 'react-emotion'
import { DatePickerStandalone } from './DatePicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

export default function DatePickerVertical(props) {
  const { label, locked, showNoAnswer } = props
  return (
    <FormRow {...props} style={{ display: 'block' }}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
        {locked ? (
          <div css={stacked}>
            <span css={lockedLabel}>{label}</span>
            <p css={lockedValue}>
              {props.input.value
                ? moment(props.input.value).format('LL')
                : showNoAnswer
                ? '-'
                : ''}
            </p>
          </div>
        ) : (
          <React.Fragment>
            <Label title={label} align="left" vertical />
            <DatePickerStandalone {...props} />
          </React.Fragment>
        )}
      </div>
    </FormRow>
  )
}

const wrap = css`
  display: flex;
  width: 100%;
`

const stacked = css`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const stylesUnlocked = css`
  flex-direction: column;
  align-items: flex-start;
`

const stylesLocked = css`
  justify-content: space-between;
  opacity: 0.5;
`

const lockedValue = css`
  font-size: 1.14em;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
`

const lockedLabel = css`
  display: block;
  font-size: 1em;
  font-weight: 400;
`

DatePickerVertical.propTypes = {
  label: PropTypes.string.isRequired,
}
