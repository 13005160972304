import * as React from 'react'
import styled, { css } from 'react-emotion'

export function Container({ children }) {
  return <div css={flexFilesContainer}>{children}</div>
}
export function File({ name, type, url }) {
  return (
    <a href={url} target="_blank" css={fileStyles}>
      <Icon type={type} width="92px" />
      <span>{name}</span>
    </a>
  )
}
export function Icon({ type, width = '64px', height }) {
  const color = { pdf: '#008AAB', png: '#FFA168' }[type] || '#FFA168'

  return (
    <SvgIcon
      viewBox="0 0 43.674 56.458"
      color={color}
      width={width}
      height={height}
    >
      <g transform="translate(-502.5 -2113.862)">
        <g transform="translate(502.5 2114.09)">
          <path
            className="resolve-file-icon-cls-1"
            d="M-3882.219-14129.334v14.146l.88.811,1.477.336h14.063v-.336Z"
            transform="translate(3908.832 14129.634)"
          />
          <g transform="translate(0)">
            <g className="resolve-file-icon-cls-2">
              <path
                className="resolve-file-icon-cls-5"
                d="M0,0H26.791V13.362a2.347,2.347,0,0,0,.747,1.577,3.892,3.892,0,0,0,2.006.475H43.459V56.231H0Z"
              />
              <path
                className="resolve-file-icon-cls-6"
                d="M 42.4589958190918 55.23096084594727 L 42.4589958190918 16.4138298034668 L 29.55583190917969 16.4138298034668 C 29.54057312011719 16.41412734985352 29.51564407348633 16.41447067260742 29.48217391967773 16.41447067260742 C 28.98972320556641 16.41447067260742 27.75627326965332 16.34883117675781 26.94055366516113 15.74079036712646 C 25.90697479248047 14.97033023834229 25.80147361755371 13.57329082489014 25.79284477233887 13.41652011871338 L 25.79133415222168 13.38907051086426 L 25.79133415222168 13.36157035827637 L 25.79133415222168 1.000000476837158 L 1.000004053115845 1.000000476837158 L 1.000004053115845 55.23096084594727 L 42.4589958190918 55.23096084594727 M 43.4589958190918 56.23096084594727 L 4.094848463864764e-06 56.23096084594727 L 4.094848463864764e-06 4.229736418892571e-07 L 26.79133415222168 4.229736418892571e-07 L 26.79133415222168 13.36157035827637 C 26.79133415222168 13.36157035827637 26.84991455078125 14.42596054077148 27.53820419311523 14.93903064727783 C 28.13714790344238 15.38548374176025 29.21295356750488 15.41447448730469 29.482177734375 15.41447448730469 C 29.52234268188477 15.41447448730469 29.54452323913574 15.41383075714111 29.54452323913574 15.41383075714111 L 43.4589958190918 15.41383075714111 L 43.4589958190918 56.23096084594727 Z"
              />
            </g>
            <path
              className="resolve-file-icon-cls-3"
              d="M1216.467,149.888l16.667,15.409"
              transform="translate(-1189.802 -149.749)"
            />
          </g>
        </g>
        <text
          className="resolve-file-icon-cls-4"
          transform="translate(522.538 2161.317)"
        >
          <tspan x="-8" y="0">
            {type.trim().toUpperCase()}
          </tspan>
        </text>
      </g>
    </SvgIcon>
  )
}

const flexFilesContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`
const fileStyles = css`
  border: 1px solid #e6e6e6;
  color: #3b3b3b;
  margin: 14px;
  padding: 15px 10px;
  text-align: center;
  flex-basis: 28%;

  &:hover,
  &:focus {
    color: #000;
    border: 1px solid #cecece;
  }
  > svg {
    padding: 20px;
  }
  > span {
    display: block;
  }
`
const SvgIcon = styled.svg`
  .resolve-file-icon-cls-1 {
    fill: rgba(0, 138, 171, 0.4);
    fill: ${props => props.color};
    opacity: 0.4;
  }
  .resolve-file-icon-cls-2,
  .resolve-file-icon-cls-3 {
    fill: none;
  }
  .resolve-file-icon-cls-3 {
    stroke: #939393;
  }
  .resolve-file-icon-cls-4 {
    fill: ${props => props.color};
    font-size: 8px;
    font-family: Helvetica;
  }
  .resolve-file-icon-cls-5,
  .resolve-file-icon-cls-6 {
    stroke: none;
  }
  .resolve-file-icon-cls-6 {
    fill: #939393;
  }
`
