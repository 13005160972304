import React from 'react'
import PropTypes from 'prop-types'
import Feedback from './Feedback'
import styled from 'react-emotion'

const Wrap = styled.div`
  flex: 1;
  background-color: ${({ isError }) => (isError ? '#FFF0F0' : 'transparent')};

  input {
    height: 40px;
    width: 100%;
    padding: 0 12px;
    border: 1px solid #c7c7c7;
    font-size: 18px;
    ::placeholder {
      color: #434343bf;
    }
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }
  }
`

export default function PriceValueInputStandalone(props) {
  const { input, meta = {}, overwriteSyncError } = props
  const { dirty, touched, warning, error } = meta

  const wrapProps = {}

  if ((touched || dirty) && (warning || error || overwriteSyncError)) {
    if (error || overwriteSyncError) {
      wrapProps.isError = true
      wrapProps['data-error'] = true
    }
  }

  if (!input.value) {
    input.value = ''
  }
  return (
    <Wrap {...wrapProps}>
      <input
        {...input}
        placeholder={props.label}
        type="number"
        step={0.01}
        min={0}
        max={10000000}
        inputMode="numeric"
      />
      <Feedback meta={{ ...meta, error: overwriteSyncError || error }} />
    </Wrap>
  )
}

PriceValueInputStandalone.propTypes = {
  label: PropTypes.string,
}
