import {
  IMAGE_INDEX,
  MATCH_INDEX,
  SUBMISSION_LABEL as LABELS,
  SUBMISSION_REQUIRED as REQUIRED,
} from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import {
  PixsyFormGroup,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
  PixsyTextField,
} from '../../../Components'
import { TooltipContainer } from 'common'

export const PriceCurrency = React.memo(
  ({
    tooltipName,
    leftName,
    rightName,
    disabled,
    padding,
    index,
    readOnly,
  }) => {
    const leftNameWithIndex = leftName
      .replace(IMAGE_INDEX, index)
      .replace(MATCH_INDEX, index)
    const rightNameWithIndex = rightName
      .replace(IMAGE_INDEX, index)
      .replace(MATCH_INDEX, index)

    const tooltip = <TooltipContainer id={tooltipName} />

    return (
      <PixsyPaper
        padding={padding}
        disabled={disabled[leftName]}
        variant="transparent"
        borderless
      >
        <PixsyFormGroup tooltip={tooltip}>
          <PixsyGridItem xs={9}>
            <PixsyLabel text={LABELS[leftName]} required={REQUIRED[leftName]} />
          </PixsyGridItem>
          <PixsyGridItem xs={3}>
            <PixsyFormGroup spacing={0}>
              <PixsyGridItem xs={6}>
                <PixsyTextField
                  type="number"
                  step={0.01}
                  min={0}
                  max={10000000}
                  placeholder="1200.00"
                  inputMode="numeric"
                  name={leftNameWithIndex}
                  disabled={disabled[leftName]}
                  validateOnChange
                  validateOnBlur
                  borderlessRight
                  readOnly={readOnly}
                />
              </PixsyGridItem>
              <PixsyGridItem xs={6}>
                <PixsyTextField
                  type="currency-select"
                  name={rightNameWithIndex}
                  disabled={disabled[rightName]}
                  validateOnChange
                  validateOnBlur
                  borderlessLeft
                  readOnly={readOnly}
                />
              </PixsyGridItem>
            </PixsyFormGroup>
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }
)
