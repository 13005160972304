import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router'

import ClusterListView from './ClusterListView'
import GroupMatchListView from './GroupMatchListView'
import { MatchViewerContextProvider } from '../store'
import DetailViewModalContainer from './DetailViewModalContainer'
import withMatchesHistoryBackHandler from '../utils/withMatchesHistoryBackHandler'

const SwitchWithBackHandler = withMatchesHistoryBackHandler(Switch)

const ListViewsContainer = ({ history }) => {
  // react-route is triggering this component one last time
  // when switching to another page for some reason.
  // That's why we have this if condition here.
  const { pathname } = history.location
  if (pathname && !pathname.startsWith('/matches')) {
    return null
  }

  return (
    <MatchViewerContextProvider routerHistory={history}>
      <DetailViewModalContainer />
      <SwitchWithBackHandler>
        <Route
          // HACK: wont work if multi:match will be in a different place (old URLs)
          path="/matches/v3/multi:match/view/*"
          component={GroupMatchListView}
        />
        <Route path="/matches/v3*" component={ClusterListView} />
      </SwitchWithBackHandler>
    </MatchViewerContextProvider>
  )
}

ListViewsContainer.propTypes = {
  history: PropTypes.object,
}

export default ListViewsContainer
