import * as React from 'react'
import { css } from 'react-emotion'

const rightArrowStyle = css`
  fill: none;
  stroke: #707070;
  stroke-width: 2px;
`

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.879 16.93">
    <path
      id="Path_689"
      data-name="Path 689"
      css={rightArrowStyle}
      d="M-18145.607,2480.949l-7.758,7.758,7.758,7.758"
      transform="translate(-18144.9 2497.172) rotate(180)"
    />
  </svg>
)
