import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { css, cx } from 'react-emotion'
import FormRow from './FormRow'

export default function WideFileUploadLegacy(props) {
  const {
    name,
    input,
    meta = {},
    label,
    afterChange,
    highlighted,
    accept,
  } = props
  const uploadedLength = Array.isArray(input.value) ? input.value.length : 0

  const handleChange = (filesToUpload, e) => {
    input.onChange(filesToUpload)
    if (global.E2E) {
      // testcafe proxies uploads with hammerhead
      // the base64 encoding that is included breaks our upload
      // @see https://github.com/DevExpress/testcafe-hammerhead/issues/1304#issuecomment-328758747
      filesToUpload.forEach(file => delete file.base64)
    }
    afterChange &&
      afterChange(meta.dispatch)(filesToUpload.map(x => ({ ...x })))
  }

  const showWarning = props.editing || meta.touched

  return (
    <FormRow meta={meta} showWarnings={showWarning} highlighted={highlighted}>
      <div css={wrap}>
        <div css={description}>
          <label>{label}</label>
          {showWarning && (meta.error || meta.warning) && (
            <div className={feedbackContainer}>
              <p>{meta.error || meta.warning}</p>
            </div>
          )}
        </div>
        <div className={actionArea}>
          <Dropzone
            className={cx(dropzone, uploadedLength > 0 && disabled)}
            name={name}
            onDrop={handleChange}
            accept={accept}
          >
            {uploadedLength === 0
              ? 'Select or Drop Files'
              : `${uploadedLength} File${
                  uploadedLength !== 1 ? 's' : ''
                } Uploaded`}
          </Dropzone>
        </div>
      </div>
    </FormRow>
  )
}

WideFileUploadLegacy.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.string.isRequired,
  afterChange: PropTypes.func,
}

const wrap = css`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  padding: 1px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const description = css`
  padding-top: 7px;
  width: calc(100% - 180px);

  @media (max-width: 768px) {
    width: 100%;
  }
`

const actionArea = css`
  flex: 0 0 250px;
  text-align: center;
  float: right;
  font-weight: 600;
  user-select: none;
  font-size: 0.9em;
  letter-spacing: 0.15em;
  margin-left: 30px;
  margin-top: 3px;

  @media (max-width: 768px) {
    flex: 1;
    margin: 0;
  }
`

const dropzone = css`
  width: 100%;
  display: inline-block;
  margin-right: 40px;
  outline: 2px solid #008aab;
  padding: 9px 12px 7px 12px;
  text-transform: uppercase;
  cursor: pointer;
  color: #008aab;
  transition: all 150ms ease;
  &:hover {
    background-color: #008aab;
    color: white;
    outline: 2px solid #008aab;
  }

  @media (max-width: 768px) {
    margin: 20px 0 0;
  }
`

const disabled = css`
  outline: 2px solid rgba(0, 0, 0, 0.4);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.4);
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    outline: 2px solid rgba(0, 0, 0, 0.4);
  }
`

const feedbackContainer = css`
  p {
    font-size: 0.95em;
    color: rgb(120, 120, 120);
    margin: 20px 0;
  }
`
