import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { ClearbitLogo, TruncateText } from 'common'

import * as Shapes from '../utils/shapes'
import { EyeIcon, LinkIcon } from './Icons'
import getFirstSeenFromDateText from '../utils/getFirstSeenFromDateText'
import FixedHeightImageContained from './FixedHeightImageContained'

const MatchSummary = ({ match, cluster, displayName }) => (
  <div css={styles.container}>
    <FixedHeightImageContained url={match.url} id={match._id} height={120} offlineLabel="match" />
    <div css={styles.rightColumn}>
      <span css={styles.heading}>Match Summary</span>
      <div css={styles.imageInfoLine}>
        <ClearbitLogo host={cluster.domain.host} size={27} fallbackLetter={displayName.charAt(0)} />
        <span css={styles.boldText}>{displayName}</span>
      </div>
      <div css={styles.imageInfoLine}>
        <EyeIcon />
        <span>{getFirstSeenFromDateText(match.created)}</span>
      </div>
      <div css={styles.imageInfoLine}>
        <LinkIcon />
        <TruncateText text={match.origin.url} />
      </div>
    </div>
  </div>
)

const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  rightColumn: css`
    margin-left: 28px;
  `,
  heading: css`
    color: #21212130;
    font-size: 15px;
    display: flex;
    margin-bottom: 7px;
  `,
  boldText: css`
    font-weight: bold;
  `,
  imageInfoLine: css`
    display: flex;
    align-items: center;

    .MuiAvatar-root {
      width: 27px !important;
      height: 27px !important;
    }

    .MuiAvatar-root > img {
      width: 27px !important;
      height: 27px !important;
      max-width: 27px !important;
      max-height: 27px !important;
      margin-right: 0 !important;
    }

    > svg,
    div,
    img,
    figure {
      width: 27px;
      height: 27px;
      margin-right: 20px;
    }

    span {
      color: #212121;
      font-size: 19px;
    }
  `,
}

MatchSummary.propTypes = {
  match: Shapes.Match,
  displayName: PropTypes.string,
}

export default MatchSummary
