import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { css, cx } from 'react-emotion'
import { Button, Modal } from 'common'
import { DATA_ELEVIO } from 'pixsy-constants'
import { withCookies } from 'react-cookie'
import { withRouter } from 'react-router'
import ActionDialogContainer, { ACTIONS } from './ActionDialog/ActionDialogContainer'
import { validateSync } from '../../../../../../shared/isSubmittableSync'

class ViewerHeader extends Component {
  constructor(props) {
    super(props)
    this._sharkMode = Boolean(props.cookies.get('pixsy-shark'))
    this.state = {
      action: null,
      isFlagged: props.cluster && props.cluster.tags && props.cluster.tags.includes('flagged'),
    }
  }

  setClusterFlag = (cluster) => {
    this.setState({
      isFlagged: cluster && cluster.tags && cluster.tags.includes('flagged'),
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.cluster._id !== nextProps.cluster._id) {
      this.setClusterFlag(nextProps.cluster)
    }
  }

  handleClose = () => this.setState({ action: null })
  handleOpenSubmissionDialog = (e) => this.setState({ action: ACTIONS.CASE })
  handleOpenTakedownDialog = (e) => this.setState({ action: ACTIONS.TAKEDOWN })

  handleIgnoreModalOpenInActionDialog = () => {
    this.handleClose()
    this.props.onIgnoreModalOpen()
  }

  handleFlagToggle = () => {
    const { cluster, flag } = this.props
    flag(cluster._id)
    this.setState(({ isFlagged }) => ({ isFlagged: !isFlagged }))

    if (!this._sharkMode && !cluster.tags.includes('flagged')) {
      // triggers GA event for flagging image
      try {
        window.gtag('event', 'page_view', {
          page_path: '/c/vpv/matches/flagged',
          event_callback(res) {
            console.info('pageview success')
            console.info(res)
          },
        })
        window.gtag('event', 'flagged', {
          event_category: 'matches',
          event_label: 'Match flagged',
          event_callback(res) {
            console.info('event success')
            console.info(res)
          },
        })
      } catch (e) {
        console.error(`Unable to invoke google-analytics method:`, e)
      }
    }
  }

  handleCaseSubmissionNavigation = () => {
    const { location, history, cluster } = this.props

    try {
      window.gtag('event', 'setMetric', {
        casesFromMatch: 1,
        event_label: 'casesFromMatch',
      })

      const path = `/c/vpv/fu/cs/v6/mat/start`
      window.gtag('event', 'page_view', {
        page_path: path,
        event_callback(res) {
          console.info('pageview success: ' + path)
          console.info(res)
        },
      })
    } catch (e) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }

    history.push({
      ...location,
      pathname: `/cases/submission/new`,
      search: `?from=${cluster._id}`,
    })
  }

  render() {
    const { cluster, hide, onIgnoreModalOpen, showMobileActionsMenu, location, activeMatch, user } = this.props
    const { action } = this.state

    const matches = cluster.matches

    const clusterId = cluster._id

    const errorMap = validateSync(cluster)
    const { entityData = {} } = cluster
    const hostName = (entityData && entityData.company_name) || cluster.domain.host

    const isSubmittable = !Object.keys(errorMap).length

    return (
      <div css={wrap}>
        {action && (
          <Modal isOpen onRequestClose={this.handleClose} theme="dark">
            <ActionDialogContainer
              action={action}
              cluster={cluster}
              close={this.handleClose}
              ignore={this.handleIgnoreModalOpenInActionDialog}
              onTakedown={this.handleOpenTakedownDialog}
              onCase={this.handleCaseSubmissionNavigation}
              location={location}
              user={user}
            />
          </Modal>
        )}

        <div className={annotations}>
          <div className={leftTop}>
            <h4>
              {matches.length} <span>{matches.length === 1 ? 'Match' : 'Matches'} on</span>
            </h4>
          </div>
          {!cluster.case && !cluster.takedown && !cluster.tags.includes('hidden:true') && (
            <div className={rightTop}>
              <h5 data-elevio-selector={DATA_ELEVIO.MATCHES_VIEWER_DOMAIN_GROUP}>Take action on this domain group</h5>
            </div>
          )}
        </div>
        <div className={primary}>
          <div className={left}>
            <h1 id="-pixsy-domain-hostname">{hostName}</h1>
          </div>
          <div id="-pixsy-cluster-id" style={{ display: 'none' }}>
            {cluster._id}
          </div>

          <div className={cx(right, showMobileActionsMenu && mobileActionsMenu)}>
            <Link
              to={`/images/${activeMatch ? activeMatch.image._id || activeMatch.photo._id : cluster.images[0]._id}`}
              target="_blank"
            >
              <button css={cx(standaloneButton, goToImage)} title="Review the details of your original source image.">
                <IconGoToImage />
                <span css={desktopOnly}>GO TO</span> IMAGE
              </button>
            </Link>

            <button
              css={cx(
                standaloneButton,
                flag,
                {
                  [flagSharkMode]: this._sharkMode,
                },
                {
                  [flagActive]: this.state.isFlagged,
                  [pixsyFlaggedActive]: cluster.tags.includes('pixsy:flagged'),
                }
              )}
              title="Flag this match group for later review. All groups you flag will be accessible from the matches overview."
              onClick={this.handleFlagToggle}
              data-elevio-selector={DATA_ELEVIO.MATCHES_FLAG}
            >
              <IconFlag />
              Flag
            </button>

            <div className={cx(headerContainerButtonGroup)}>
              {cluster.case ? (
                <Link to={`/cases/${cluster.case}`}>
                  <Button>Go to Submission</Button>
                </Link>
              ) : cluster.takedown ? (
                <Link to="/takedowns">
                  <Button>Go to Takedown</Button>
                </Link>
              ) : cluster.tags.includes('hidden:true') ? (
                <Button css={singleButton} onClick={hide.bind(null, clusterId, false)}>
                  {/* <IconUndo /> */}
                  Undo Ignore
                </Button>
              ) : (
                <React.Fragment>
                  <Button
                    title="Stop this domain from appearing in matches again. Mark the domain (full URL or smaller URL path) as an ignored or approved site. This list can be edited and managed in your account settings."
                    onClick={onIgnoreModalOpen}
                    css={cluster.matches.some((m) => !m.ignored) || singleButton}
                  >
                    Ignore
                  </Button>

                  {!cluster.takedown && cluster.matches.some((m) => !m.ignored) && (
                    <React.Fragment>
                      <Button
                        highlightedFromDark={!isSubmittable}
                        title="Want this image removed off the website? Send a legal takedown notice to the host or site for all images in this group. Pixsy automates the notices to comply with the local law and language of that country."
                        onClick={this.handleOpenTakedownDialog}
                      >
                        Send Takedown
                      </Button>
                      <Button
                        highlightedFromDark={isSubmittable}
                        title={
                          !isSubmittable
                            ? 'This match is not available for Pixsy Case Resolution.'
                            : 'Want to recover compensation? Submit all matches in this group as a case to Pixsy. Our team of expert copyright case manager will handle everything on your behalf and attempt to recover compensation for the use.'
                        }
                        onClick={this.handleOpenSubmissionDialog}
                        asDisabled={!isSubmittable}
                      >
                        Submit Case
                      </Button>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withCookies(withRouter(ViewerHeader))
const wrap = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const right = css`
  margin-top: 5px;
  flex: 729px 0 1;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1280px) {
    width: 50%;
    max-width: auto;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    width: 58%;
    text-align: right;
  }

  @media (max-width: 768px) {
    // display: none !important;
    position: fixed;
    background-color: #262626;
    // top: -84px;
    top: -114px;
    z-index: 10;
    left: 0;
    right: 0;
    width: 100%;
    max-width: none;
    // padding: 20px 10px;
    padding: 50px 10px 20px;
    margin-top: 0;
    transition: all 300ms linear;
  }
`

const mobileActionsMenu = css`
  @media (max-width: 768px) {
    top: 0;
  }
`

const rightTop = css`
  margin-top: 5px;
  flex: 740px 0 0;
  @media (max-width: 1280px) {
    width: 50%;
    max-width: auto;
  }

  display: inline-block;
  text-align: right;
  width: 100%;

  span {
    margin-right: -10px;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    width: 58%;
    text-align: right;
  }

  @media (max-width: 768px) {
    // display: none !important;
    // top: -84px;
    // padding: 20px 10px;
    position: fixed;
    background-color: #262626;
    top: -116px;
    z-index: 10;
    left: 0;
    right: 0;
    width: 100%;
    max-width: none;
    padding: 50px 10px 20px;
    margin-top: 0;
    transition: all 300ms linear;
  }
`

const annotations = css`
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  h4 span {
    font-weight: 400;
  }

  h5 {
    text-align: right;
    font-weight: 400;
    color: white;
    opacity: 0.3;
    font-size: 0.9em;
    margin: 0;
    position: relative;
    top: -5px;
  }
`

const leftTop = css`
  display: flex;
  flex: 0 1;
  padding-right: 5px;

  h1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 1280px) {
    width: 50%;
    padding-right: 15px;
  }
  @media (min-width: 910px) and (max-width: 1280px) {
    width: 42%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const left = css`
  display: flex;
  flex: 1 1;
  padding-right: 5px;

  h1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
    padding-right: 15px;
  }

  @media (min-width: 1280px) {
    width: 42%;
  }
  @media (max-width: 1280px) {
    width: 50%;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    width: 42%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const primary = css`
  display: flex;
  justify-content: space-between;
`

const standaloneButton = css`
  display: inline-block;
  background-color: transparent;
  border: 0;

  text-transform: uppercase;
  vertical-align: top;
  height: 100%;
  font-size: 0.8em;
  letter-spacing: 0.12em;

  margin-top: 4px;
  padding-right: 10px;
  @media (max-width: 1000px) {
    width: 50px;
  }

  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }

  svg {
    display: block;
    margin: 0 auto;
    height: 24px;
    @media (max-width: 1280px) {
      height: 18px;
    }
  }

  color: #e2e2e2;
  transition: opacity 100ms ease-in;

  a {
    color: #e2e2e2;
  }
`

const goToImage = css`
  width: 105px;
  @media (max-width: 1000px) {
    width: 58px;
  }

  &:focus {
    outline: 0;
  }

  svg {
    opacity: 0.6;
    display: block;
    margin: 0 auto;
    height: 24px;

    @media (max-width: 1280px) {
      margin-top: -1px;
      height: 18px;
    }
    @media (max-width: 768px) {
      margin-top: -4px;
    }
  }

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
`

const flag = css`
  cursor: pointer;
  @media (max-width: 1000px) {
    width: 50px;
  }

  &:focus {
    outline: 0;
  }

  svg {
    opacity: 0.4;
    display: block;
    margin: 0 auto;
    height: 24px;

    transition: opacity 100ms ease-in;

    @media (max-width: 1280px) {
      height: 18px;
    }
  }

  &:hover {
    svg {
      opacity: 0.9;
      transition: opacity 100ms ease-out;
    }
  }
`

const flagSharkMode = css`
  color: #ff7f2f;

  .svg-viewer-flag-button {
    stroke: #ff7f2f;
  }

  &:hover {
    .svg-viewer-flag-button {
      background-color: #ff9451 !important;
    }
  }
`

const flagActive = css`
  color: #fff;

  svg {
    opacity: 1;
  }

  .svg-viewer-flag-button {
    fill: #fff;
  }
`
const pixsyFlaggedActive = css`
  color: #ff7f2f;

  svg {
    opacity: 1;
  }

  .svg-viewer-flag-button {
    fill: #ff7f2f;
  }
`

const desktopOnly = css`
  @media (max-width: 1000px) {
    display: none;
  }
`

ViewerHeader.propTypes = {
  activeMatch: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  cluster: PropTypes.object.isRequired,
  flag: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  onIgnoreModalOpen: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

function IconFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2939.971 1561.786 17.477 21.846">
      <defs>
        <style>
          {`
          .svg-viewer-flag-button{fill:transparent; stroke: #FFF;}
          `}
        </style>
      </defs>
      <g transform="translate(-3984 1463)">
        <path
          className="svg-viewer-flag-button"
          d="M19.9,7.282l3.52-5.633a.364.364,0,0,0-.309-.557H6.728V.364A.364.364,0,1,0,6,.364V21.482a.364.364,0,0,0,.728,0v-8.01H23.113a.364.364,0,0,0,.309-.557ZM6.728,12.744V1.821H22.456L19.163,7.089a.364.364,0,0,0,0,.386l3.293,5.268Z"
          transform="translate(1038.029 98.786)"
        />
        <path
          className="svg-viewer-flag-button"
          d="M-2939.286,1563.571v10.99h15.837l-3.33-5.552,3.33-5.438Z"
          transform="translate(3984 -1463)"
        />
      </g>
    </svg>
  )
}

function IconGoToImage() {
  return (
    <svg id="picture" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.862 32.097">
      <defs>
        <style>
          {`
      .cls-1-match-viewer-header-icon-image {
        stroke: current-color;
        stroke-width: 2px;
      }
      `}
        </style>
      </defs>
      <path
        id="Path_30"
        data-name="Path 30"
        className="cls-1-match-viewer-icon-image"
        d="M11.868,19.875a3.437,3.437,0,1,0-3.437-3.438A3.441,3.441,0,0,0,11.868,19.875Zm0-5.64a2.2,2.2,0,1,1-2.2,2.2A2.206,2.206,0,0,1,11.868,14.235Z"
        transform="translate(-3.844 -4.976)"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        className="cls-1-match-viewer-icon-image"
        d="M1,0V32.1H31.863V0ZM2.235,1.235H30.628v16.5L24.256,11.89a.634.634,0,0,0-.444-.162.618.618,0,0,0-.428.2L17.338,18.55,14.41,15.623a.618.618,0,0,0-.844-.027L3.236,24.69h-1ZM30.628,30.863H2.235V25.925H30.628ZM5.1,24.69l8.842-7.784L20.3,23.264a.617.617,0,1,0,.873-.873L18.21,19.424l5.667-6.206,6.751,6.188V24.69Z"
        transform="translate(-1)"
      />
    </svg>
  )
}

const singleButton = css`
  border-right: 1px solid !important;
  border-left: 1px solid !important;
`

const headerContainerButtonGroup = css`
  flex: auto 0 1;
  display: inline-flex;
  align-items: stretch;
  height: 50px;

  @media (max-width: 1100px) {
    height: 40px;
    width: calc(100% - 100px);
  }

  a button {
    height: 100%;
    width: 100%;
  }

  button {
    background-color: transparent;
    margin: 0;
    border: 0;
    color: rgba(226, 226, 226, 0.702);
    border: solid 1px rgba(226, 226, 226, 0.702);
    padding: 14px 42px 14px 42px;
    font-size: 0.9em;
    transition: background-color 100ms ease;
    transition: color 100ms ease;
    margin-left: -1px;

    @media (max-width: 1280px) {
      padding: 12px 28px 11px 28px;
      font-size: 0.8em;

      svg {
        display: none !important;
      }
    }
    @media (max-width: 1000px) {
      font-size: 0.75em !important;
      padding: 9px 8px 11px 8px;
      width: 30%;

      &:nth-of-type(3) {
        width: 40%;
      }
    }
    @media (max-width: 768px) {
      font-size: 0.65em !important;
      padding: 0;
      display: flex;
      white-space: normal;
      justify-content: center;
      align-items: center;
    }

    svg {
      display: block;
      height: 15px;
      margin: 5px auto;
    }

    &.active,
    &:hover {
      background-color: #e8e8e8;
      color: #141414;
    }

    &.active .svg-viewer-approve,
    &:hover .svg-viewer-approve {
      stroke: #141414;
    }

    &.active .svg-viewer-circle-1,
    &:hover .svg-viewer-circle-1 {
      stroke: #141414;
    }

    &.active .svg-viewer-undo,
    &:hover .svg-viewer-undo {
      fill: #141414;
    }

    &.active .svg-viewer-invalid,
    &:hover .svg-viewer-invalid {
      stroke: #141414;
    }

    &:first-of-type {
      margin-left: unset;
    }
  }
`
