import React from 'react'
import { css } from 'react-emotion'

export function BusinessPlanDialog() {
  return (
    <div css={content}>
      <h1>Available on business plans</h1>
      <p>
        Contact our sales team for more information and pricing. <br />
        <a href="mailto:sales@pixsy.com">sales@pixsy.com</a>
      </p>
    </div>
  )
}

export default BusinessPlanDialog

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  em {
    color: #008aab;
  }
`
