import { PLAN_ARRAY } from './schemas'

const PLANS_LOAD = 'PLANS_LOAD'
const PLANS_LOAD_SUCCESS = 'PLANS_LOAD_SUCCESS'
const PLANS_LOAD_FAIL = 'PLANS_LOAD_FAIL'

const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function plans(state = initialState, action = {}) {
  switch (action.type) {
    case PLANS_LOAD:
      return {
        ...state,
        loading: true,
      }
    case PLANS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      }
    case PLANS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      }

    default:
      return state
  }
}

export function isLoaded(globalState) {
  return globalState.plans && globalState.plans.loaded
}

export function load() {
  return {
    types: [PLANS_LOAD, PLANS_LOAD_SUCCESS, PLANS_LOAD_FAIL],
    promise: (client) => client.get('/plans'),
    schema: { plans: PLAN_ARRAY },
  }
}
