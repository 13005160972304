import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import styled, { cx, css } from 'react-emotion'

const CloseButton = styled.div`
  position: absolute;
  top: -55px;
  right: -55px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  align-items: center;
  svg {
    height: 25px;
  }

  @media (max-width: 768px) {
    right: -15px;
  }

  ${props => props.closeButtonClassName}
`

const overlayClassNameDefault = css`
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.82);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const base = css`
  max-width: 800px;
  margin: 55px 10px 0;
  max-height: calc(100vh - 100px);
  position: relative;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;

  @media screen and (min-height: 850px) {
    margin-top: 100px;
    max-height: auto;
    /* overflow-y: inherit; */
  }
  @media screen and (min-width: 740px) {
    margin-left: auto;
    margin-right: auto;
  }
  &:focus {
    outline: 0;
  }

  h1 {
    font-size: 2em;
  }
`

const light = css`
  background-color: white;

  h1,
  h4 {
    color: #000;
  }

  p {
    color: rgba(0, 0, 0, 0.75);
  }
`

const dark = css`
  background-color: #262626;

  h1,
  h4 {
    color: #fff;
  }

  p {
    color: rgba(255, 255, 255, 0.75);
  }
`

const bodyClassName = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    display: block;
  }
`
export default function Modal({
  children,
  className,
  theme = 'light',
  overlayClassName,
  closeButtonClassName,
  ...config
}) {
  const props = {
    ...config,
    overlayClassName: cx(overlayClassNameDefault, overlayClassName),
    ariaHideApp: false,
    shouldCloseOnEsc: true,
    closeButtonClassName,
    className: cx(base, className, theme === 'light' ? light : dark),
  }
  return (
    <ReactModal {...props}>
      <CloseButton
        onClick={props.onRequestClose}
        closeButtonClassName={closeButtonClassName}
      >
        <IconClose />
      </CloseButton>
      <div css={bodyClassName}>{children}</div>
    </ReactModal>
  )
}

Modal.propTypes = {
  theme: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

function IconClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-2126.207 1485.793 34.953 34.953"
    >
      <defs>
        <style>
          {`.svg-modal-close-button {
                fill: none;
                stroke: #ccc;
                stroke-width: 2px;
              }`}
        </style>
      </defs>
      <g id="Group_785" data-name="Group 785" transform="translate(-3903 1427)">
        <line
          id="Line_45"
          data-name="Line 45"
          className="svg-modal-close-button"
          x2="33.539"
          y2="33.539"
          transform="translate(1777.5 59.5)"
        />
        <line
          id="Line_46"
          data-name="Line 46"
          className="svg-modal-close-button"
          y1="33.539"
          x2="33.539"
          transform="translate(1777.5 59.5)"
        />
      </g>
    </svg>
  )
}
