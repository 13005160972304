import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Button } from 'common'

export default function SubmissionValidationDialogIndirectHotlink({
  onTakedown,
  onComplete,
  onIgnore,
  cluster,
}) {
  // <span data-elevio-article="12">hotlink</span>
  return (
    <div css={wrap}>
      <h1>This match may not be a viable case</h1>

      <h4>
        Pixsy has detected that this use is a “hotlink” use, and your work is
        not hosted by the website.
      </h4>

      <p>
        While the image is displaying on the web page, the image file is hosted
        somewhere else. This may mean that we are unable to accept this use for
        resolution.
      </p>
      <p>
        If you are unsure if this case is viable you can contact us and we can
        further evaluate the case for you. Or you can continue to case
        submission.
      </p>
      <p>
        Pixsy uses a set of algorithms to pre-determine if a case is suitable
        for resolution. We do this to save you time, and to ensure that our case
        management team can focus their efforts on recovering compensation for
        viable commercial cases.
      </p>

      <div className="dialog-slider--button-wrapper">
        <Button greyTertiary onClick={onIgnore}>
          Ignore Match
        </Button>
        <Button grey onClick={onTakedown}>
          Send Takedown
        </Button>
        <Button hoverMain onClick={onComplete}>
          Continue
        </Button>
      </div>
      {!global.PRODUCTION && (
        <p className="debug">SubmissionValidationDialogIndirectHotlink</p>
      )}
    </div>
  )
}

SubmissionValidationDialogIndirectHotlink.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onTakedown: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  cluster: PropTypes.object.isRequired,
}

const wrap = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
`
