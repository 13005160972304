import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'

export default function TakedownStatus({ takedown: { done, total } }) {
  const isComplete = done === total

  const styles = cx(statusCell, isComplete ? completeStyle : pendingStyle)

  return <p css={styles}>Takedown {isComplete ? 'Complete' : 'Sent'}</p>
}

TakedownStatus.propTypes = {
  takedown: PropTypes.shape({
    done: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
}

const statusCell = css`
  font-weight: 500;
  font-size: 1em;
  padding: 3px 8px;
`

const pendingStyle = css`
  background-color: #fff7e5;
`

const completeStyle = css`
  /* ?? */
`
