import React from 'react'
import { css } from 'react-emotion'

const MatchPreferenceHeader = ({ total, hidden }) => {
  return (
    <div css={headerWrapperStyle}>
      <div css={headerTextWrapper}>
        <h4>Select your match preferences</h4>
        <span style={{ maxWidth: '70%' }}>
          Control what results are displayed in your matches. Untick any option below to hide those matches from your
          account.{' '}
          <a href="https://support.pixsy.com/articles/195" target="_blank">
            Learn More
          </a>
        </span>
      </div>
      {total > 0 && (
        <div css={headerDataWrapper}>
          <span css={wideText}>MATCHES</span>
          <h5 css={noMargin}>{Number(total).toLocaleString()}</h5>
          {hidden > 0 && <span css={grayText}>{Number(hidden).toLocaleString()} hidden</span>}
        </div>
      )}
    </div>
  )
}

export default React.memo(MatchPreferenceHeader)

const headerWrapperStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
`

const headerDataWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 100px;
`

const headerTextWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const wideText = css`
  letter-spacing: 1.5px;
`

const grayText = css`
  color: #808080;
`

const noMargin = css`
  margin: 0px !important;
  line-height: 1;
`