const SEARCH_START_KEY_THRESHOLD = 2
const SEARCH_TYPE = {
  CATEGORY: 'category',
  COUNTRY: 'country',
  ACTION: 'action',
  FLAG: 'flag',
  DOMAIN: 'domain',
  URL: 'url',
}
const addTypeToItems = (items, type) => items.map((item) => ({ ...item, type }))
const getSuggestionValue = (suggestion) => suggestion.value

export {
  SEARCH_TYPE,
  addTypeToItems,
  getSuggestionValue,
  SEARCH_START_KEY_THRESHOLD,
}
