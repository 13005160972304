import React, { useCallback } from 'react'
import Collapsable from '../../../components/Collapsable'
import { SquareIcon } from '../../../components/Icons'
import MultiSelectRichList from '../../../components/MultiSelectRichList'

const ActionsFilter = ({ onSelect, items, selected, setSelected }) => {
  const onChange = useCallback((ids) => {
    setSelected(ids)
    onSelect(ids)
  }, [])

  return (
    <Collapsable
      Icon={SquareIcon}
      text="Actioned"
      onOpen={() => false}
      content={<MultiSelectRichList items={items} selectedIds={selected} onSelectionChange={onChange} />}
      badgeCount={selected.length}
    />
  )
}

export default ActionsFilter
