import React from 'react'
// import PropTypes from 'prop-types'
import FormRow from './FormRow'
import { connect } from 'react-redux'
import { Button } from 'common'
import { notifSend } from 'redux-notifications/lib/actions'
import { Field, change } from 'redux-form'
import { css } from 'react-emotion'
import Feedback from './Feedback'

const additionRowWrap = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  label {
    margin-bottom: 15px;
  }
`

const additionRow = css`
  width: 100%;
  display: flex;
  input {
    flex: 1;
    font-size: 18px;
    padding: 0 12px;
    ::placeholder {
      color: #434343bf;
    }
  }
  button {
    margin: 0;
  }
`

function AdditionalInputRow({ input, meta, onMore, renderList }) {
  return (
    <FormRow meta={meta}>
      <div css={wrap}>
        <div css={additionRowWrap}>
          <label>Additional locations</label>
          <div className={`${additionRow} ${mobile}`}>
            <input {...input} value={input.value || ''} />
            <Button hoverMain disabled={meta.invalid} onClick={onMore}>
              Add
            </Button>
          </div>
          <Feedback meta={meta} />
          {renderList()}
        </div>
      </div>
    </FormRow>
  )
}

export class AdditionalLinks extends React.Component {
  componentDidUpdate(previousProps) {
    const { fields, change } = this.props
    if (fields.length - 1 === previousProps.fields.length) {
      change('submission', 'ADDITIONAL_LINK', '')
    }
  }

  handleMore = e => {
    const { fields, currentInput, notify } = this.props
    if (!currentInput) return

    if (fields.getAll().includes(currentInput)) {
      notify({
        message: 'This URL is already on the list of additional pages.',
        kind: 'warning',
        dismissAfter: 5e3,
      })
    } else {
      this.props.fields.push(this.props.currentInput)
    }
  }

  renderList = () => {
    const { fields } = this.props
    return (
      <ul>
        {fields.length > 0 &&
          fields.map((field, index) => (
            <li key={index}>
              <Field key={index} name={field} component={Item} />
              <i onClick={() => fields.remove(index)}>x</i>
            </li>
          ))}
      </ul>
    )
  }

  render() {
    return (
      <Field
        name="ADDITIONAL_LINK"
        key="ADDITIONAL_LINK"
        type="text"
        // 🙌 https://stackoverflow.com/a/44554618
        format={fuckingReduxFormJesusChrist => fuckingReduxFormJesusChrist}
        component={AdditionalInputRow}
        onMore={this.handleMore}
        renderList={this.renderList}
      />
    )
  }
}

export default connect(
  state => ({
    currentInput: state.form.submission.values
      ? state.form.submission.values.ADDITIONAL_LINK
      : undefined,
  }),
  { change, notify: notifSend }
)(AdditionalLinks)

function Item({ input }) {
  return <span>{input.value}</span>
}

const wrap = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  i {
    cursor: pointer;
    margin-left: 5px;
    color: red;
  }

  ul {
    padding: 0;
  }
  li {
    list-style: none;
    color: #333;
    margin-bottom: 8px;
    opacity: 0.6;
    &:first-of-type {
      margin-top: 16px;
    }
    &:hover {
      opacity: 1;
    }
  }
`

const mobile = css`
  @media (max-width: 768px) {
    flex-direction: column;
    input {
      border: 1px solid rgb(238, 238, 238);
      margin-bottom: 10px;
    }
  }
`
