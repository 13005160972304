import uuidV4 from 'uuid'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import { FileSchema, transformFromUppyFile } from 'pixsy-schema/file/FileSchema'

/**
 * Wrapper around Uppy to manipulate files
 *  - transforms uppy files to FileSchema files
 *  - wrap around uppy events
 *
 * @see CaseSubmission.js
 */
export class PixsyFileUploader {
  constructor({ user, policy, bucket, uppyOptions = {}, limit = 0, server, source }) {
    this.events = new Map()
    this.files = new Map()

    const options = { ...uppyOptions }

    options.onBeforeFileAdded = (currentFile, files) => {
      let fileUploadCancelled = false

      const cancelFile = () => {
        fileUploadCancelled = true
      }

      const nextFile = transformFromUppyFile(currentFile)

      nextFile.uploadStarted = false
      nextFile.uploadComplete = false
      nextFile.isPaused = false
      nextFile.source = source
      nextFile.bytesTotal = 0
      nextFile.bytesUploaded = 0
      nextFile.percentage = 0
      nextFile.error = null

      this.executeEvent('onBeforeFileAdded', {
        cancelFile,
        nextFile,
      })

      if (fileUploadCancelled) return false

      return true
    }

    this.uppy = Uppy(options)
    this.uppy.use(XHRUpload, {
      endpoint: `https://${bucket}.${server}.amazonaws.com`,
      limit,
      fieldName: 'file',
      metaFields: ['key', 'acl', 'Content-Type', 'AWSAccessKeyId', 'success_action_status', 'Policy', 'Signature'],
      responseType: 'text',
      getResponseData: (responseText, response) => {
        const url = decodeURIComponent(responseText.match(/<Location>(.*?)<\/Location>/)[1])
        return { url }
      },
    })

    this.uppy.on('file-added', (file) => {
      const userId = user._id
      const { name, extension } = file
      const baseName = name.replace(`.${extension}`, '')
      const key = `${userId}/${uuidV4()}.${extension}`

      this.uppy.setFileMeta(file.id, {
        baseName,
        /* fields sent to aws request */
        key,
        acl: 'public-read',
        'Content-Type': file.type,
        AWSAccessKeyId: policy.AWSAccessKeyId,
        success_action_status: '201',
        Policy: policy.s3Policy,
        Signature: policy.s3Signature,
        /* fields sent to aws request */
      })

      const fileObj = transformFromUppyFile(file)

      let previewLocalUrl

      try {
        previewLocalUrl = URL.createObjectURL(file.data)
      } catch (e) {
        console.warn('[PixsyFileLoader][file-added]: preview local file not supported.', e)
      }

      fileObj.key = key
      fileObj.uploadStarted = false
      fileObj.uploadComplete = false
      fileObj.isPaused = false
      fileObj.source = source
      fileObj.bytesTotal = 0
      fileObj.bytesUploaded = 0
      fileObj.percentage = 0
      fileObj.error = null
      fileObj.previewLocalUrl = previewLocalUrl

      this.files.set(file.id, fileObj)

      this.executeEvent('file-added', fileObj, true)
    })

    this.uppy.on('file-removed', (file) => {
      if (!file) return

      const fileObj = this.files.get(file.id)

      this.executeEvent('file-removed', fileObj)
    })

    this.uppy.on('upload', ({ id, fileIDs }) => {
      fileIDs.forEach((fid) => {
        const orgFileObj = this.files.get(fid)
        const fileObj = FileSchema({ strip: false }).cast(orgFileObj)

        fileObj.uploadStarted = true
        fileObj.uploadComplete = false
        fileObj.isPaused = false
        fileObj.bytesTotal = 0
        fileObj.bytesUploaded = 0
        fileObj.percentage = 0
        fileObj.error = null

        this.files.set(fid, fileObj)

        this.executeEvent('onFileChange', fileObj)
      })

      this.executeEvent('upload', { id, fileIDs })
    })
    this.uppy.on('upload-progress', (file, progress) => {
      const orgFileObj = this.files.get(file.id)
      const fileObj = FileSchema({ strip: false }).cast(orgFileObj)

      fileObj.bytesTotal = progress.bytesTotal
      fileObj.bytesUploaded = progress.bytesUploaded

      if (fileObj.bytesTotal !== 0 && fileObj.bytesUploaded !== 0) {
        fileObj.percentage = ((fileObj.bytesUploaded * 100) / fileObj.bytesTotal) | 0
      } else {
        fileObj.percentage = 0
      }

      this.files.set(file.id, fileObj)

      this.executeEvent('upload-progress', fileObj, true)
    })
    this.uppy.on('upload-success', (file, response) => {
      const orgFileObj = this.files.get(file.id)
      const fileObj = FileSchema({ strip: false }).cast(orgFileObj)

      fileObj.uploadComplete = true
      fileObj.uploadStarted = false
      fileObj.url = response.uploadURL
      fileObj.percentage = 100
      fileObj.bytesUploaded = fileObj.bytesTotal
      fileObj.error = null

      this.files.set(file.id, fileObj)

      this.executeEvent('upload-success', fileObj, true)
    })

    this.uppy.on('upload-error', (file, error, response) => {
      const orgFileObj = this.files.get(file.id)
      const fileObj = FileSchema({ strip: false }).cast(orgFileObj)

      fileObj.uploadComplete = false
      fileObj.uploadStarted = false
      fileObj.error = error

      this.files.set(file.id, fileObj)

      this.executeEvent('upload-error', fileObj, true)
    })

    this.uppy.on('error', () => {
      this.executeEvent('error', this.uppy.getState().error)
    })
    this.uppy.on('complete', ({ successful, failed }) => {
      const nextSuccessful = successful.map((f) => this.files.get(f.id))
      const nextFailed = failed.map((f) => this.files.get(f.id))

      this.executeEvent('complete', {
        successful: nextSuccessful,
        failed: nextFailed,
      })
    })
    this.uppy.on('info-visible', () => {
      const info = this.uppy.getState().info
      this.executeEvent('info-visible', info)
    })
    this.uppy.on('info-hidden', () => {
      this.executeEvent('info-hidden')
    })
    this.uppy.on('cancel-all', () => {
      this.executeEvent('cancel-all')
    })
    this.uppy.on('plugin-remove', (plugin) => {
      // A sorta hacky way to make sure Uppy always has given plugin
      // before removing it.
      // This prevents some Oops errors in case submission pages.
      if (plugin && this.uppy.plugins && !this.uppy.plugins[plugin.type]) {
        this.uppy.plugins[plugin.type] = []
      }
    })
  }

  /**
   * @private
   * @param {string} eventName
   * @param {*} args
   * @param {boolean} notifyUpdateObject
   */
  executeEvent(eventName, args, notifyUpdateObject) {
    const events = this.events.get(eventName)

    // console.warn(eventName, args, notifyUpdateObject, events)
    if (events instanceof Set) {
      events.forEach((fn) => fn(args))
    }
    if (notifyUpdateObject) {
      this.executeEvent('onFileChange', args, false)
    }
  }

  /**
   * @private
   * @param {string} eventName
   */
  ensureEvent(eventName) {
    const events = this.events.get(eventName)

    if (!(events instanceof Set)) {
      const nextEvents = new Set()
      this.events.set(eventName, nextEvents)
      return nextEvents
    }

    return events
  }

  onFileChange(fn) {
    this.ensureEvent('onFileChange').add(fn)
  }

  onFileAdded(fn) {
    this.ensureEvent('file-added').add(fn)
  }

  onFileRemoved(fn) {
    this.ensureEvent('file-removed').add(fn)
  }

  onUpload(fn) {
    this.ensureEvent('upload').add(fn)
  }

  onUploadProgress(fn) {
    this.ensureEvent('upload-progress').add(fn)
  }

  onUploadSuccess(fn) {
    this.ensureEvent('upload-success').add(fn)
  }

  onUploadError(fn) {
    this.ensureEvent('upload-error').add(fn)
  }

  onUglyError(fn) {
    this.ensureEvent('error').add(fn)
  }

  onComplete(fn) {
    this.ensureEvent('complete').add(fn)
  }

  onBeforeFileAdded(fn) {
    this.ensureEvent('onBeforeFileAdded').add(fn)
  }

  onInfoShow(fn) {
    this.ensureEvent('info-visible').add(fn)
  }

  onInfoHide(fn) {
    this.ensureEvent('info-hidden').add(fn)
  }

  onCancelAll(fn) {
    this.ensureEvent('cancel-all').add(fn)
  }

  getFile(fid) {
    return this.files.get(fid)
  }

  removeFile(fid) {
    const file = this.getFile(fid)

    if (file) {
      this.files.delete(fid)
      try {
        this.uppy.removeFile(fid)
      } catch (e) {
        // for unknown reasons, this could sometimes fail:
        // https://sentry.io/organizations/pixsy/issues/1436341585
        // https://github.com/transloadit/uppy/issues/1872
        console.error(e)
      }
    }
  }

  getFiles() {
    return this.files
  }

  getFilesArray() {
    return [...this.files.values()]
  }

  startUpload() {
    this.uppy.upload()
  }

  pauseUpload(fid) {
    const file = this.getFile(fid)

    if (file) {
      if (file.isPaused) return
      this.uppy.pauseResume(fid)
    }
  }

  resumeUpload(fid) {
    const file = this.getFile(fid)

    if (file) {
      if (!file.isPaused) return
      this.uppy.pauseResume(fid)
    }
  }

  pauseOrResumeUpload(fid) {
    const file = this.getFile(fid)

    if (file) {
      this.uppy.pauseResume(fid)
    }
  }

  resumeAllUploads() {
    this.uppy.resumeAll()
  }

  retryUpload(fid) {
    const file = this.getFile(fid)

    if (file) {
      this.uppy.retryUpload(fid)
    }
  }

  retryAllUploads() {
    this.uppy.retryAll()
  }

  reset() {
    this.uppy.reset()
  }

  close() {
    this.events.clear()
    this.uppy.close()
  }

  cancelAll() {
    this.uppy.cancelAll()
  }
}
