import React from 'react'
// import { Link } from 'react-router-dom'
import { Button } from 'common'
import { css } from 'react-emotion'

export default function SubmissionExitPrompt({ onCancel, onNavigate, onSave }) {
  return (
    <div css={content}>
      <h1>You have unsaved changes</h1>

      <span>How do you want to proceed?</span>

      <div css={buttons}>
        <Button hoverMain onClick={onCancel}>
          Stay
        </Button>
        <Button outline onClick={onSave}>
          Save
        </Button>
        <Button greyOutline onClick={onNavigate}>
          Exit
        </Button>
      </div>
    </div>
  )
}

SubmissionExitPrompt.propTypes = {}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }
`
