import React, { PureComponent } from 'react'
import PropTypes, { instanceOf } from 'prop-types'
import { push } from 'react-router-redux'
import { asyncConnect } from 'redux-connect'
import Helmet from 'react-helmet'
import { withCookies, Cookies } from 'react-cookie'
import { notifSend } from 'redux-notifications/lib/actions'
import moment from 'moment'
import { loadForPayment } from '../../redux/modules/cases'
import { withEmptyStates } from '../../decorators'
import ResolveHeader from './ResolveHeader'
import PaymentSection from './PaymentSection'
import HighlightsSection from './HighlightsSection'
import LicenseSection from './LicenseSection'
import AttachmentsSection from './AttachmentsSection'
import ResolveFooter from './ResolveFooter'
import { GridWrappingColumn } from 'common'
import NoContent from './NoContent'

export class ResolveContainer extends PureComponent {
  static COOKIE_KEY = 'pixsy-resolve-container'
  state = { paid: false, instalment: false }

  componentDidMount() {
    const hideRecaptcha = () => {
      try {
        document.getElementsByClassName(
          'grecaptcha-badge'
        )[0].parentNode.style.visibility = 'hidden'
      } catch (e) {
        setTimeout(hideRecaptcha, 100)
      }
    }

    setTimeout(hideRecaptcha, 10)
  }

  constructor(props) {
    super(props)

    this._case = props.cazeResponse.payload

    const cachedState = this.props.cookies.get(
      `${ResolveContainer.COOKIE_KEY}-${this._case.id}`
    )

    // If cache exists, prefer it over salesforce items.
    if (
      cachedState &&
      cachedState.hasOwnProperty('paid') &&
      cachedState.hasOwnProperty('instalment')
    ) {
      this.state.paid = cachedState && cachedState.paid
      this.state.instalment = cachedState && cachedState.instalment
    } else {
      this.state.paid = Boolean(this._case.salesforce.PaymentReceivedD__c)
      this.state.instalment = Boolean(
        this._case.salesforce.PaymentType__c === 'Stripe Instalment'
      )
    }
  }

  handlePaymentSuccess = ({ instalment } = {}) => {
    const { dispatch, notifSend } = this.props
    const notification = notifSend({
      message: 'Payment was successful. Thank you.',
      kind: 'success',
      dismissAfter: 10e3,
    })

    dispatch(notification)

    let newState = {
      paid: true,
      instalment: false,
    }

    if (Boolean(instalment)) {
      newState = {
        paid: false,
        instalment: true,
      }
    }

    this.setState(newState)
    this.props.cookies.set(
      `${ResolveContainer.COOKIE_KEY}-${this._case.id}`,
      newState,
      {
        path: '/',
        expires: moment()
          .add(5, 'minutes')
          .toDate(),
      }
    )
  }

  render() {
    const caze = this._case

    let uses = []

    if (caze.submission__c && caze.submission__c.ImageUses__r) {
      uses = caze.submission__c.ImageUses__r.records.filter(
        (iu) => iu.Image__r && iu.Image__r.Name
      )
    }

    const screenshots = caze.screenshots || []
    const inLegal = caze.salesforce.Track__c === 'Legal'
    return (
      <GridWrappingColumn maxWidth={876}>
        <Helmet title="Licensing" />
        <ResolveHeader />
        <HighlightsSection
          case={caze}
          uses={uses}
          inLegal={inLegal}
          paid={this.state.paid}
          instalment={this.state.instalment}
        />
        {!inLegal && (
          <AttachmentsSection
            case={caze}
            screenshots={screenshots}
            salesforce={caze.salesforce}
          />
        )}
        {!inLegal && <LicenseSection case={caze} uses={uses} />}
        <PaymentSection
          case={caze}
          onPaymentSuccess={this.handlePaymentSuccess}
          paid={this.state.paid}
          instalment={this.state.instalment}
        />
        <ResolveFooter />
      </GridWrappingColumn>
    )
  }
}

export default asyncConnect(
  [
    {
      key: 'cazeResponse',
      promise: function handleFetch({
        store: { dispatch },
        match: {
          params: { caseId },
        },
      }) {
        return dispatch(loadForPayment(caseId))
      },
    },
  ],
  () => ({ push, notifSend })
)(
  withEmptyStates([
    {
      shouldRender: (props) =>
        !(
          props.cazeResponse.payload &&
          props.cazeResponse.payload._id &&
          props.cazeResponse.payload.salesforce.InfringerAccount__r &&
          (props.cazeResponse.payload.salesforce.CurrentPrice__c ||
            props.cazeResponse.payload.salesforce.FinalPrice__c)
        ),
      render: () => <NoContent />,
    },
    {
      shouldRender: (props) =>
        props.cazeResponse.payload &&
        ['5cd004efc082c7e896fe02f2', '5dc7f6b82bb11c0015c26690', '5f8b1b82f4f780001636f2e3'].includes(
          props.cazeResponse.payload._id
        ),
      render: () => <NoContent />,
    },
  ])(withCookies(ResolveContainer))
)

ResolveContainer.propTypes = {
  cazeResponse: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
}
