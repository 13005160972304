import React from 'react'
import { css, cx, injectGlobal } from 'react-emotion'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function InstalmentPaymentConfig({
  onChange,
  parts,
  interval,
  startDate,
  maxDate,
  minDate,
}) {
  return (
    <div>
      <div css={container}>
        <div>
          <h5>Parts</h5>
          <div css={buttonGroup}>
            <button
              className={cx({ active: 2 === parts })}
              onClick={() => onChange({ parts: 2 })}
            >
              2
            </button>
            <button
              className={cx({ active: 3 === parts })}
              onClick={() => onChange({ parts: 3 })}
            >
              3
            </button>
            {/* <button
              className={cx({ active: 4 === parts })}
              onClick={() => onChange({ parts: 4 })}
            >
              4
            </button> */}
          </div>
        </div>
        <div>
          <h5>Payment Interval</h5>
          <div css={buttonGroup}>
            <button
              className={cx({ active: 'weekly' === interval })}
              onClick={() => onChange({ interval: 'weekly' })}
            >
              Weekly
            </button>
            <button
              className={cx({ active: 'bimonthly' === interval })}
              onClick={() => onChange({ interval: 'bimonthly' })}
            >
              Bimonthly
            </button>
            <button
              className={cx({ active: 'monthly' === interval })}
              onClick={() => onChange({ interval: 'monthly' })}
            >
              Monthly
            </button>
          </div>
          <h5>Payment Starts</h5>
          <div css={ActionArea}>
            <div css={DatePickerWrapper}>
              <DatePicker
                selected={startDate}
                maxDate={maxDate}
                minDate={minDate}
                onChange={date => onChange({ startDate: date })}
                dateFormat="LL"
              />
              <IconArrowDown />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const container = css`
  display: flex;
  justify-content: space-between;
  h5 {
    color: #434343ff;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
`

const buttonGroup = css`
  display: flex;
  width: 100%;
  button {
    flex: 1 1 auto;
    appearance: none;
    background-color: #ffffff;
    border: 0;
    height: 55px;
    min-width: 50px;
    flex-grow: 1;
    font-size: 1.2em;
    line-height: 15px;
    font-weight: 400;
    outline: 1px solid #e8e8e8;
    outline-offset: -1px;
    padding: 10px;
    opacity: 0.7;
    &:hover:not(.active) {
      opacity: 1;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    }
    &:active {
      outline: inherit;
    }

    &.active {
      opacity: 1;
      color: #008aab;

      outline: 2px solid #008aab;
      outline-offset: -2px;
    }
  }
`

injectGlobal`

  .react-datepicker {
    border-radius: 0;
    border: 1px solid #c7c7c7;
  }
  .react-datepicker__header {
    background-color: #fcfcfc;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #148aa9;
  }

  .react-datepicker__navigation--years-upcoming {
    top: -4px !important;
    border-bottom-color: #ccc !important;
  }

  .react-datepicker__navigation--years-previous {
    top: 4px !important;
    border-top-color: #ccc !important;
  }

`

const DatePickerWrapper = css`
  position: relative;

  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 85px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 200px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
`

const ActionArea = css`
  background-color: 'transparent';
  position: relative;
  margin-bottom: 5px;
  input {
    height: 30px;
    width: 160px;
    border: 0px solid #c7c7c7;
    font-size: 15px;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
`

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}
