import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import styled, { css, cx } from 'react-emotion'

const ActionArea = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-basis: 280px;
  /* padding: 10px 0; */

  input {
    height: 40px;
    width: ${props => props.width};
    padding: 0 12px;
    font-size: 18px;
    &:focus {
      outline: 0;
      border: 1px solid #d9d9d9;
    }
  }
  select {
    &:focus,
    &:active {
      outline: 0;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  float: ${props => props.float};
  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
`

const SelectWrapper = styled.span`
  select {
    width: 100%;
    height: 40px;
    appearance: none;
    box-shadow: none;
    outline: none;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
    padding: ${props =>
      props.embedded ? '0px 36px 0px 16px' : '0px 36px 0px 10px'};
    border: ${props => (props.embedded ? '1px solid #d9d9d9' : '0')};
    border-bottom: ${props => (props.embedded ? '' : ' 1px solid #d9d9d9')};
    color: ${props => (props.embedded ? '#434343' : ' 1px solid #d9d9d9')};
    font-size: ${props => (props.embedded ? '1.25em' : '1em')};
    text-indent: 0.01px;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    width: ${props => props.width};
  }
`

const lockedValue = css`
  font-size: 1.28em;
  font-weight: 500;
  flex-basis: 100px;
  text-align: center;
`

const stylesLocked = css`
  opacity: 0.5;
`

const stylesUnlocked = css``

const mobile = css`
  @media (max-width: 768px) {
    display: block;
  }
`

export class USCOSelectStandalone extends React.Component {
  constructor(props) {
    super(props)
    this.state = { focused: false }
  }

  handleFocus = () => {
    this.setState({ focused: true })
    this.props.input.onFocus()
  }

  handleBlur = () => {
    this.setState({ focused: false })
    this.props.input.onBlur()
  }

  render() {
    const {
      input,
      embedded,
      float = 'left',
      required,
      items,
      // meta = {},
    } = this.props

    return (
      <ActionArea embedded={embedded}>
        <Wrapper embedded={embedded} float={float}>
          <SelectWrapper embedded={embedded}>
            <select
              required={Boolean(required)}
              {...input}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            >
              <option value="">Please select</option>

              {items.map(item => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </SelectWrapper>
          <IconArrowDown />
        </Wrapper>
      </ActionArea>
    )
  }
}

export default function USCOSelect(props) {
  const { label, locked, addon, input, meta } = props
  const labelRight = label && (!props.labelLeft && label.length > 15)
  return (
    <FormRow {...props}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
        <Flex labelRight={labelRight} css={mobile}>
          <React.Fragment>
            <Label align="left" title={label} />
            {locked ? (
              <p css={lockedValue}>{props.input.value || '-'}</p>
            ) : (
              <USCOSelectStandalone embedded {...props} />
            )}
          </React.Fragment>
          {addon && addon({ name: input.name })}
        </Flex>
        <Feedback meta={meta} />
      </div>
    </FormRow>
  )
}

USCOSelect.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
}

USCOSelect.defaultProps = {
  width: '100%',
}

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}

const wrap = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Flex = styled.div`
  width: 100%;
  display: flex;
`
