import React from 'react'

export const SubmissionValidationDialogTMOnly = ({ country }) => (
  <div>
    <h6>
      Due to our experience of legal cases in {country} (this legal
      jurisdiction), we have determined we are unable to proceed with this case.
    </h6>

    <p>
      We work in partnership with our local legal partner in {country} to make
      these determinations based on strict criteria.
      <br />
      We are only able to accept cases where the counterpart is a large
      (operating commercial) company, and the specifics of the image use and
      image would mean a legal case is viable.
    </p>

    <p>
      Pixsy uses a set of advanced algorithms to pre-determine if a case is
      suitable for resolution. We do this to save you time, and to ensure that
      our case management team can focus their efforts on recovering
      compensation for viable cases.
    </p>

    <p>
      We recommend sending a takedown notice for this match. If you feel this
      should be a viable commercial case, you can contact us and we can further
      evaluate the case for you.
    </p>
  </div>
)
