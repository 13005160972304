import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'

const Checkbox = ({ additionalClassName, ...rest }) => {
  return (
    <input
      data-testid="checkbox"
      className={cx(styles.input, additionalClassName)}
      type="checkbox"
      {...rest}
    />
  )
}

const styles = {
  input: css`
    width: 20px;
    height: 20px;
    outline: none !important;
    appearance: none;
    border: 1px solid #eaeaea;
    cursor: pointer;

    &:checked {
      background-color: #ffa168;
      border-color: #ffa168;

      &:after {
        content: '✔';
        color: white;
        margin: 3px;
        font-size: 15px;
      }
    }
  `,
}

Checkbox.propTypes = {
  additionalClassName: PropTypes.string,
}

export default Checkbox
