import { useCallback, useEffect, useState } from 'react'
import { useMatchViewerStore } from '../store/context'
import { useQuery } from '@apollo/client'
import GET_FILTERS from '../graphql/queries/filters'
import { actionCreators, constants, helpers, selectors } from '../store'
import createPaginationProps from './createPageOptionsProps'
import { useClusterFilterInput } from '../store/helpers/createClustersFilterInput'

export default function useClusterFilters({ fromCache = true } = {}) {
  const { state, dispatch } = useMatchViewerStore()
  const filters = selectFilterDataFromState(state)
  const selectedFilters = useClusterFilterInput(filters)
  const { pageOptions } = state

  const { loading, data } = useQuery(GET_FILTERS, {
    fetchPolicy: fromCache ? 'cache-first' : 'cache-and-network',
    variables: { filters: selectedFilters },
  })

  const [mappedFiltersData, setMappedFiltersData] = useState(helpers.mapFiltersData())
  useEffect(() => {
    data && setMappedFiltersData(helpers.mapFiltersData(data))
  }, [data])
  const { totalMatches } = mappedFiltersData

  const [pageOptionProps, setPageOptionProps] = useState(createPaginationProps(dispatch, totalMatches, pageOptions))
  useEffect(() => {
    const options = createPaginationProps(dispatch, totalMatches, pageOptions)
    setPageOptionProps(options)
  }, [totalMatches, pageOptions])

  const didFiltersChange = selectors.areFiltersChanged(filters, constants.LIST_TYPE.CLUSTER)
  const resetFilters = useCallback(() => dispatch(actionCreators.resetUserSelectionState()), [])
  const updateFilterState = useCallback((payload) => {
    dispatch(actionCreators.updateSelectedFiltersState(payload))
  }, [])

  return {
    dispatch,
    loading,
    data,
    mappedFiltersData,
    pageOptionProps,
    filters,
    selectedFilters,
    didFiltersChange,
    resetFilters,
    state,
    updateFilterState,
    totalMatches,
  }
}

export const useHandleCollapsableOpen = () => {
  const {
    state: { lastOpenedCollapsablesCloseFn },
    dispatch,
  } = useMatchViewerStore()
  return useCallback(
    (closeFn) => {
      lastOpenedCollapsablesCloseFn && lastOpenedCollapsablesCloseFn()
      const action = actionCreators.updateCollapsableContentsCloseFunction(closeFn.bind(this))
      dispatch(action)
    },
    [lastOpenedCollapsablesCloseFn]
  )
}

export const selectFilterDataFromState = (state) => ({
  selectedCountryIds: state.clusterListFilters.selectedCountryIds,
  selectedCategoryIds: state.clusterListFilters.selectedCategoryIds,
  selectedKeywordPairs: state.clusterListFilters.selectedKeywordPairs,
  selectedImageIds: state.clusterListFilters.selectedImageIds,
  selectedActionIds: state.clusterListFilters.selectedActionIds,
  selectedYearRange: state.clusterListFilters.selectedYearRange,
  selectedMatchesType: state.clusterListFilters.selectedMatchesType,
  selectedSeenState: state.clusterListFilters.selectedSeenState,
  selectedSimilarityType: state.clusterListFilters.selectedSimilarityType,
  selectedCommercialType: state.clusterListFilters.selectedCommercialType,
  isMultiMatch: state.clusterListFilters.isMultiMatch,
  isFlagged: state.clusterListFilters.isFlagged,
  isPixsyFlagged: state.clusterListFilters.isPixsyFlagged,
  lastIgnoredMatch: state.lastIgnoredMatch,
  pageOptions: state.pageOptions,
  selectedEntityId: state.clusterListFilters.selectedEntityId,
  selectedMatchId: state.clusterListFilters.selectedMatchId,
})
