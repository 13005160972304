import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Dialog } from 'common'
import sharedStyles from '../../styles'
import EXIFContent from './EXIFContent'
import StyledModal from '../../StyledModal'
import ImageDataContent from './ImageDataContent'
import * as Shapes from '../../../utils/shapes'

import DeleteImageDialog from '../../../../../Images/Detail/DeleteImageDialog'

const ImageDetailsModal = ({
  isOpen,
  cluster,
  matchId,
  onRequestClose,
  onFalsePositiveMatchRequest,
  onIgnoreMatchRequest,
}) => {
  const tabTitles = ['Match Details', 'EXIF']
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0)
  const [imageDeletionModalVisible, setImageDeletionModalVisibility] = useState(false)

  const titles = tabTitles.map((title, index) => {
    const className = index === selectedTitleIndex ? styles.activeTabTitle : styles.tabTitle
    return (
      <span key={title} css={className} onClick={() => setSelectedTitleIndex(index)}>
        {title}
      </span>
    )
  })

  const contentToShow =
    selectedTitleIndex === 0 ? (
      <ImageDataContent
        cluster={cluster}
        matchId={matchId}
        onFalsePositiveMatchRequest={onFalsePositiveMatchRequest}
        onIgnoreMatchRequest={onIgnoreMatchRequest}
        onImageDeletion={() => {
          setImageDeletionModalVisibility(true)
        }}
        onRequestClose={onRequestClose}
      />
    ) : (
      <EXIFContent
        cluster={cluster}
        matchId={matchId}
        onImageDeletion={() => {
          setImageDeletionModalVisibility(true)
        }}
      />
    )

  useEffect(() => {
    try {
      window.mixpanel.track(`Matches.mv3.imageDetailsModal`, {
        clusterId: cluster._id,
      })
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }, [])

  return (
    <React.Fragment>
      <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div css={sharedStyles.modalHeader}>
          <div css={sharedStyles.modalTitleWrapper}>{titles}</div>
        </div>
        {contentToShow}
      </StyledModal>
      <Dialog
        isOpen={imageDeletionModalVisible}
        onRequestClose={() => {
          setImageDeletionModalVisibility(false)
        }}
      >
        <DeleteImageDialog
          image={cluster.images[0]}
          onCancel={(isSuccessful) => {
            setImageDeletionModalVisibility(false)
            if (isSuccessful) {
              onRequestClose()
            }
          }}
          overrideLocation="/matches"
        />
      </Dialog>
    </React.Fragment>
  )
}

const styles = {
  columnLabels: css`
    width: 100%;
    height: 100%;
    position: absolute;

    > div {
      position: absolute;
      bottom: -9px;
      top: auto;
      z-index: 3;
    }

    > div:nth-of-type(1) {
      left: calc(25% - 35px);
    }

    > div:nth-of-type(2) {
      left: calc(75% - 35px);
    }
  `,
  activeTabTitle: css`
    color: #000000;
    font-size: 30px;
    margin-right: 14px;
    cursor: pointer;
    font-weight: 600;
  `,
  tabTitle: css`
    font-size: 30px;
    margin-right: 14px;
    color: #00000020;
    cursor: pointer;
    font-weight: 600;
  `,
}

ImageDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  cluster: Shapes.Cluster,
  matchId: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  onIgnoreMatchRequest: PropTypes.func.isRequired,
  onFalsePositiveMatchRequest: PropTypes.func.isRequired,
}

export default ImageDetailsModal
