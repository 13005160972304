import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { resolveCountryNameForCode, formatClusterTag } from 'pixsy-constants'
import { CountryOutline, CardIcon } from 'common'

const card = css`
  background-color: white;
  padding: 30px 0 20px;
  text-align: center;
  border: 2px solid #eee;
  display: flex;
  flex-direction: column;
  color: #1d1d1d;
  margin-bottom: 30px;
  margin: 15px 0;
  user-select: none;
  @media (max-width: 768px) {
    margin: 10px 0;
    height: 100%;
    min-height: 120px;
    padding: 10px 0 10px;
    justify-content: center;
  }
  &:hover {
    outline: 2px solid #148aa9;
    outline-offset: -2px;
  }

  img {
    display: inline-block;
  }
  em {
    font-size: 1.8em;
    font-weight: 300;
    font-style: normal;
  }
  figure {
    font-size: 2.3em;
    font-weight: 600;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      font-size: 1.4em;
    }
  }
  h3 {
    font-size: 1.6em;
    margin: 0;
    color: #000;
  }
  h4 {
    @media (max-width: 768px) {
      margin: 0;
      font-size: 1.1em;
    }
  }
  > span {
    font-size: 0.75em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
  }
`

const placeholderStyle = css`
  background: red;
`

const warningStyle = css`
  box-sizing: border-box;
  border: 5px solid #f42534;
  padding: 28px 0 20px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  figure > span {
    color: #f42534;
    font-size: 2rem;
    text-transform: inherit !important;
    font-weight: bold !important;
  }
  &:hover {
    box-shadow: 0px 10px 35px -21px rgba(0, 0, 0, 7);
    outline: none;
  }
`

const commercialCard = css`
  box-sizing: border-box;
  padding: 29.5px 0 20px;
  border: 5px solid #ff7f2f;
  box-shadow: 0px 10px 35px -21px rgba(0, 0, 0, 0.4);
  transition: all 200ms ease-in-out;

  svg {
    height: 32px;
  }

  label {
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    color: #000;
    letter-spacing: 2px;
  }

  &:hover {
    box-shadow: 0px 10px 35px -21px rgba(0, 0, 0, 7);
    outline: none;
  }
`

export function HeroCard({ textOnly, row1, row2, n, warning, placeholder, tag }) {
  return (
    <div css={cx(card, placeholder && placeholderStyle, warning && warningStyle)}>
      <CardIcon tag={tag} />
      <span>{row1}</span>
      <span>{row2}</span>
      {warning ? (
        <figure>
          <span>{warning}</span>
        </figure>
      ) : (
        <figure>{isNaN(n) ? n || '-' : n.toLocaleString()}</figure>
      )}
    </div>
  )
}

export function TopMatchesCard({ textOnly, n }) {
  const track = () => {
    try {
      window.mixpanel.track('Matches.Display.Top', {
        source: 'card',
      })

      window.gtag('event', 'clickTopMatches', {
        topMatchesClicked: 1,
        event_category: 'matches',
        event_label: 'Top Matches clicked',
      })
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div css={cx(card, commercialCard)} onClick={track}>
      <div dangerouslySetInnerHTML={{ __html: require(`./tmFill.svg`) }} />
      <label>TOP MATCHES</label>
    </div>
  )
}

export function CategoryCard({ tag, n }) {
  if (tag === 'top') {
    return <TopMatchesCard n={n} />
  }
  return <HeroCard placeholder={n === -1} row1={tag && formatClusterTag(tag)} n={n || '-'} />
}

export function IconCard({ tag, n }) {
  return <HeroCard row1={tag && formatClusterTag(tag)} n={n || '-'} tag={tag} />
}

export function CountryCard({ n, country }) {
  return (
    <div css={card}>
      <CountryOutline code={country} />
      <em>{Number(n).toLocaleString()}</em>
      <span>{n === 1 ? 'Match' : 'Matches'} from</span>
      <h4>{resolveCountryNameForCode(country)}</h4>
    </div>
  )
}

HeroCard.propTypes = {
  row1: PropTypes.string.isRequired,
  row2: PropTypes.string,
  n: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tag: PropTypes.string,
}

CategoryCard.propTypes = {
  n: PropTypes.number,
  tag: PropTypes.string,
}
CountryCard.propTypes = {
  country: PropTypes.string.isRequired,
  n: PropTypes.number.isRequired,
}
