import React from 'react'
import Helmet from 'react-helmet'
import { css } from 'react-emotion'
import AgreementContainer from './AgreementContainer'

export default function LicensingAgreement() {
  return (
    <AgreementContainer>
      <Helmet title="License Standard Terms and Conditions" />
      <div css={wrap}>
        <h4>Pixsy License Standard Terms and Conditions</h4>
        <p>
          These standard license terms and conditions govern the licensing of
          certain rights by Pixsy Inc (<strong>“Pixsy”</strong>) in the
          photograph(s) described in the License Summary (the{' '}
          <strong>“Work”</strong>
          ). These terms and conditions are incorporated by reference into the
          License Summary sent to you by Pixsy. Capitalized terms used but not
          defined below have the meanings ascribed to them in the License
          Summary. Pixsy represents the Photographer as its duly appointed
          licensing agent and seeks to ensure the proper use and licensing of
          the intellectual property rights of Photographer’s Work; Pixsy has
          provided you with an <strong>“Unauthorized Use Report”</strong>{' '}
          detailing the circumstances under which the Photographer’s work was
          used without the Photographer’s permission; The Parties have agreed to
          enter into these terms and conditions and to abide by the provisions
          set forth herein.
        </p>
        <p>
          Pixsy represents the Photographer as its duly appointed licensing
          agent and seeks to ensure the proper use and licensing of the
          intellectual property rights of Photographer’s Work;
        </p>
        <p>
          Pixsy has provided you with an “Unauthorized Use Report” detailing the
          circumstances under which the Photographer’s work was used without the
          Photographer’s permission;
        </p>
        <p>
          The Parties have agreed to enter into these terms and conditions and
          to abide by the provisions set forth herein.
        </p>
        <ol>
          <li>
            <em>Description of Work</em>. You wish to license certain rights in
            the Work which was created by the “Photographer” and who has duly
            appointed Pixsy to act on behalf of Photographer as its licensing
            agent. The Work comprises of one or several images described in the
            License Summary.
          </li>
          <li>
            <em>Grants of Rights</em>. Upon receipt of full payment, Pixsy
            acting on behalf of Photographer, grants you the following
            non-exclusive rights in the Work:
            <ol>
              <li>
                Retroactive Use Right Fee. In exchange for the Retroactive Use
                Right Fee, Pixsy grants you a retroactive right to publish the
                Work at the following URL(s): <i>specified in signed version</i>
              </li>
              <li>
                Continued Use Right Fee. In exchange for the Continued Use Right
                Fee, Pixsy grants you a right to continue to publish the Work at
                the following URL(s): <i>specified in signed version</i>
              </li>
              <li>
                Limitations. For clarity, it is agreed and understood that the
                above rights are limited to the specific URLs described above
                and do not extend to any other location, including, but not
                limited to, other URLs, websites, blogs, or online or offline
                publications. All usage rights detailed above, are
                non-exclusive, non-assignable, and non-transferable rights.
              </li>
            </ol>
          </li>
          <li>
            <em>Reservation of Rights</em>. All rights not expressly granted
            hereunder are reserved to the Photographer, including but not
            limited to all rights in preliminary materials and all electronic
            rights. For the purposes of these terms and conditions, electronic
            rights are defined as rights in the digitized form of works that can
            be encoded, stored, and retrieved from such media as computer disks,
            portable disks or drives, computer databases, and network servers.
          </li>
          <li>
            <em>Fee</em>. No rights are granted under these terms and conditions
            until the Retroactive Use Right Fee and/or Continued Use Right Fee
            are paid in full.
          </li>
          <li>
            <em>Additional Usage</em>. If you wish to make any additional uses
            of the Work, you agree to seek permission from Pixsy and make such
            payments as are agreed to between the parties at that time.
          </li>
          <li>
            <em>Alteration</em>. You are permitted the following alterations:
            (i) a change to the image size of the Work equal or less than twenty
            percent (20% ) of the Work’s original size or (ii) a change to the
            Work such that Work matches the screenshot annexed to the License
            Summary. You shall not make or permit any further alterations,
            whether by adding or removing material from the Work, without the
            permission of Pixsy. Alterations include the addition of any
            illustrations, photographs, sound, text, or computerized effects.
          </li>
          <li>
            <em>Payment</em>. You agree to pay, or have paid, Pixsy within
            fourteen (14) days of the invoice date. Overdue payments shall be
            subject to interest charges of five (5%) percent monthly.
          </li>
          <li>
            <em>Severability</em>. If any provision or part of these terms and
            conditions is held to be invalid or unenforceable for any reason,
            such provision shall, if possible, be adjusted rather than voided,
            in order to achieve a result that corresponds to the fullest extent
            possible to the intention of the Parties. The nullity or adjustment
            of any provision of these terms and conditions shall not affect the
            validity and enforceability of any other provision of these terms
            and conditions.
          </li>
          <li>
            <em>Entire Agreement and Amendment</em>. These terms and conditions
            set forth the entire understanding of the Parties relating and
            supersedes any prior agreements between any of the Parties which are
            hereby rescinded. These terms and conditions may be amended only by
            a written agreement executed by the Parties. These terms and
            conditions are executed without reliance upon any promise, warranty,
            or representation by any Party or any representative of any Party
            other than those expressly contained herein.
          </li>
          <li>
            <em>Governing Law</em>. These terms and conditions and any dispute
            arising hereunder or in connection with the matters contemplated
            hereby, shall be governed by the internal laws of the State of New
            York (USA), without giving effect to New York principles or rules of
            conflict of laws to the extent such principles or rules would
            require or permit the application of the laws of another
            jurisdiction. In the event of any litigation arising hereunder or in
            connection with the matters contemplated hereby, each party agrees
            to submit to the exclusive jurisdiction of the courts of the State
            of New York.
          </li>
          <li>
            <em>Compliance with Law</em>. Any provision of these terms and
            conditions which shall be interpreted by any court, arbitral forum
            or regulatory body (having jurisdiction in the premises) to be
            contrary to or in violation of any law, including without
            limitation, any federal securities law, shall be deemed amended and
            reinterpreted ab initio so as to be in compliance with such law.
          </li>
          <li>
            <em>No Waiver</em>. No failure to or delay in exercising any right,
            power or privilege hereunder shall operate as a waiver thereof, and
            no single or partial exercise of any right, power or privilege
            hereunder shall preclude any other or further exercise of any right,
            power or privilege.
          </li>
          <li>
            <em>Assignment</em>. These terms and conditions may not be assigned,
            nor may any obligations hereunder be transferred or delegated, by
            either Party without the prior written consent of the other. These
            terms and conditions shall bind and inure to the benefit of and be
            enforceable by the Parties and their respective permitted successors
            and assigns.
          </li>
        </ol>
      </div>
    </AgreementContainer>
  )
}

const wrap = css`
  max-width: 800px;
  margin: 100px auto 0;
  ol {
    counter-reset: item;
    padding-left: 0;
    li {
      margin-bottom: 20px;
      display: block;
      &:before {
        content: counters(item, '.') '. ';
        counter-increment: item;
        padding-right: 30px;

        ol {
          padding-left: 30px;

          em {
            text-decoration: underline;
            font-style: normal;
          }
        }
      }
    }
  }
`
