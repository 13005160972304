import React from 'react'
import styled from 'react-emotion'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Img } from 'react-image'

export default function ClearbitLogo({ host, size, fallbackLetter }) {
  return (
    <Img
      src={`http://logo.clearbit.com/${host}?size=${size * 2}`}
      alt={host && host.toUpperCase()}
      loader={<CircularProgress size={20} />}
      style={{ display: 'block', height: size, width: size, objectFit: 'contain', borderRadius: 5 }}
      crossorigin="anonymous"
      unloader={<LetterStyled size={size}>{fallbackLetter}</LetterStyled>}
    />
  )
}

ClearbitLogo.propTypes = {
  host: PropTypes.string.isRequired,
  size: PropTypes.number,
  fallbackLetter: PropTypes.string.isRequired,
}

ClearbitLogo.defaultProps = {
  size: 40,
}

const LetterStyled = styled.figure`
  margin: 0;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  background: #99999950;
  color: #999999;
`
