import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { css } from 'react-emotion'
import Slider from 'react-slick'
import ImageSection from './ImageSection'

export default class DetailImageWizard extends React.Component {
  state = { current: 0 }

  afterChange = i => {
    this.setState({ current: i })
  }

  render() {
    const { cluster, submission } = this.props
    const { current } = this.state
    const { images } = cluster

    let imagesFinal = images.map(i => {
      return { ...i }
    })
    submission &&
      submission.ImageUses__r &&
      submission.ImageUses__r.length &&
      imagesFinal.forEach(i => {
        i.matches = i.matches.filter(m =>
          submission.ImageUses__r.find(u => u.Pixsy_ID__c === m._id)
        )
      })
    imagesFinal = imagesFinal.filter(i => i.matches && i.matches.length)

    return (
      <div css={imagesFinal.length === 1 && extraSpace}>
        {imagesFinal.length > 0 && (
          <Slider
            centerMode
            variableWidth
            focusOnSelect
            infinite={false}
            asNavFor={this._slider}
            className={miniSlider}
          >
            {imagesFinal.map((i, index) => (
              <div
                key={index}
                className={cn(activeImageSelection, {
                  [active]: index === current,
                  [rejectedBackground]: i.rejected,
                })}
              >
                {i.rejected && <div className={rejectedLabel}>Rejected</div>}

                <img src={i.url} />
              </div>
            ))}
          </Slider>
        )}
        <Slider
          ref={c => (this._slider = c)}
          infinite={false}
          arrows={false}
          dots={false}
          afterChange={this.afterChange}
        >
          {imagesFinal.map(i => (
            <ImageSection key={i._id} image={i} />
          ))}
        </Slider>
      </div>
    )
  }
}

DetailImageWizard.propTypes = {
  cluster: PropTypes.object.isRequired,
  submission: PropTypes.object,
}

const activeImageSelection = css`
  display: block;
  vertical-align: top;
  width: 100px !important;
  height: 100px;
  position: relative;
  margin: 20px 10px 20px 0;
  box-sizing: border-box;
  border: 3px solid transparent;
  outline: none !important;
  opacity: 0.5;
  cursor: pointer;

  img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
`

const active = css`
  border: 3px solid #008aab;
  opacity: 1;
  cursor: inherit;
  opacity: 1 !important;
`

const rejectedBackground = css`
  opacity: 0.4;
`

const rejectedLabel = css`
  transition: all 250ms ease;
  position: absolute;
  font-weight: 600;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 5%;
  bottom: -22px;
  color: #000;
  font-size: 0.75em;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  z-index: 1;
  padding: 0 7px;
`
const extraSpace = css`
  margin: 40px auto 60px;
`

const miniSlider = css`
  width: 88%;
  margin-left: 6%;
`
