import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Field } from 'redux-form'
import moment from 'moment'
import {
  AlignLabels,
  ClosedQuestionRadio,
  ContentSection,
  CountrySelect,
  DatePicker,
  OffSectionTooltip,
  TextField,
  DatePickerVertical,
} from 'common'
import { PHASE } from '../../../../../../shared/forms/registrationSubmission'

export default function SubmissionFormImages({
  phase,
  registrationId,
  getFieldPropsFor,
  onWaypoint,
  values,
}) {
  const IMAGE_DATE_PUBLISHED = getFieldPropsFor('IMAGE_DATE_PUBLISHED')
  const IMAGE_DATE_TAKEN = getFieldPropsFor('IMAGE_DATE_TAKEN')

  const copyrightRegistrationLocked = false
  // values.image &&
  // values.image.registrations &&
  // values.image.registrations.length > 0
  const locked = phase === PHASE.FINAL

  return (
    <ContentSection id="images">
      <Waypoint onEnter={onWaypoint} />
      <h1>Work</h1>
      <AlignLabels align="left" width="200px">
        {values && values.image && (
          <span>
            Click to view{' '}
            <a href={`/images/${values.image._id}`} target="_blank">
              image details.
            </a>
          </span>
        )}

        {copyrightRegistrationLocked && (
          <span>
            <br />
            This information is locked as it is linked to a copyright
            registration and cannot be edited.
          </span>
        )}

        <OffSectionTooltip>
          IMPORTANT. This title is the identifier used by the Copyright office
          and should not be changed in later use or publishing.
        </OffSectionTooltip>
        <Field
          component={TextField}
          {...getFieldPropsFor('IMAGE_TITLE')}
          locked={locked || copyrightRegistrationLocked}
        />

        {IMAGE_DATE_TAKEN.locked ? (
          <Field component={DatePickerVertical} {...IMAGE_DATE_TAKEN} />
        ) : (
          <Field
            component={DatePicker}
            {...IMAGE_DATE_TAKEN}
            disabled={locked || copyrightRegistrationLocked}
            locked={locked || copyrightRegistrationLocked}
          />
        )}

        <OffSectionTooltip verticalOffset={-20}>
          Leave blank if you have not published your work anywhere yet.
        </OffSectionTooltip>
        {IMAGE_DATE_PUBLISHED.locked ? (
          <Field component={DatePickerVertical} {...IMAGE_DATE_PUBLISHED} />
        ) : (
          <Field
            component={DatePicker}
            {...IMAGE_DATE_PUBLISHED}
            {...(values.image &&
              values.image.date_taken && {
                datepickerProps: {
                  initial: moment.utc(values.image.date_taken),
                  minDate: moment.utc(values.image.date_taken),
                },
              })}
            disabled={locked || copyrightRegistrationLocked}
            locked={locked || copyrightRegistrationLocked}
          />
        )}

        <OffSectionTooltip verticalOffset={-20}>
          {/* You’re German, the photo was shot in Namibia, but you were in Sweden
          when you uploaded it for publishing, then please state Sweden here.
          Leave blank if you did not publish the photo anywhere yet. */}
          This is the country you were located when you first published the work
          online. Leave blank if you have not published the image yet.
        </OffSectionTooltip>
        <Field
          component={CountrySelect}
          {...getFieldPropsFor('IMAGE_COUNTRY_PUBLISHED')}
          labelLeft
          locked={locked || copyrightRegistrationLocked}
        />
      </AlignLabels>

      <AlignLabels>
        <OffSectionTooltip verticalOffset={10}>
          For example select "no" if other artists contributed to the creation
          of this work.
        </OffSectionTooltip>
        <Field
          component={ClosedQuestionRadio}
          {...getFieldPropsFor('IMAGE_USER_THE_ONLY_AUTHOR')}
          locked={locked}
        />

        <OffSectionTooltip verticalOffset={20}>
          For example, select "yes" if the work includes or is a derivative of a
          product that is not your copyrighted work.
        </OffSectionTooltip>
        <Field
          component={ClosedQuestionRadio}
          {...getFieldPropsFor('IMAGE_PUBLISHED_BEFORE')}
          locked={locked}
        />
        {/*
        <Field
          component={ClosedQuestionRadio}
          {...getFieldPropsFor('IMAGE_REGISTERED_CO_BEFORE')}
          locked={phase === PHASE.FINAL}
        /> */}
      </AlignLabels>

      {/* {values.image &&
        values.image.licensing &&
        values.image.licensing.registeredAtCoBefore && (
          <AlignLabels align="left" width="200px">
            <Field
              component={TextField}
              {...getFieldPropsFor('IMAGE_REGISTERED_CO_BEFORE_YEAR')}
              locked={phase === PHASE.FINAL}
            />

            <Field
              component={TextField}
              {...getFieldPropsFor('IMAGE_REGISTERED_CO_BEFORE_NUMBER')}
              locked={phase === PHASE.FINAL}
            />
          </AlignLabels>
        )} */}
    </ContentSection>
  )
}

SubmissionFormImages.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  getFieldPropsFor: PropTypes.func.isRequired,
  registrationId: PropTypes.string,
  phase: PropTypes.string.isRequired,
}
