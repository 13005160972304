import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Field, reduxForm } from 'redux-form'
import styled, { css } from 'react-emotion'
import { update } from '../../../redux/modules/images'
import _get from 'lodash/get'
import moment from 'moment'
import {
  AlignLabels,
  Button,
  CreativeCommonsSelect,
  ClosedQuestionRadio_ as ClosedQuestionRadio,
  CompoundFieldsDoublePrice,
  CurrencySelectStandalone,
  DatePickerVertical_ as DatePickerVertical,
  PriceValueInputStandalone,
  SexySeparator,
  USCOSelect,
  TextField_ as TextField,
  TextFieldVertical_ as TextFieldVertical,
} from 'common'

const normalizeNumber = (value) => (isNaN(Number(value)) ? null : Number(value))
const normalizeBoolean = (value) => (value === 'true' ? true : value === 'false' ? false : null)

const mapStateToProps = (state, { match }) => {
  const id = match.params.id
  const image = state.entities.images[id]

  const values = state.form.imageDetails ? state.form.imageDetails.values : null
  return {
    image,
    formValues: values,
  }
}

class Form extends Component {
  state = {
    detailsVisible: false,
  }

  toggleDetails = () => {
    this.setState((state) => ({
      detailsVisible: !state.detailsVisible,
    }))
  }

  validateURL(value) {
    const isURL = (str) => /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(str)

    return value && !isURL(value) && 'Valid URL starting with http:// or https:// required.'
  }

  render() {
    const {
      // image,
      editing,
      toggleEdit,
      valid,
      reset,
      handleSubmit,
      // owner,
      submitting,
      formValues: values,
    } = this.props
    if (!values) return null

    const { detailsVisible } = this.state

    // subquestions
    const licensing = values.licensing || {}
    const reg = values.registration || {}
    const show = {
      licensingPrice: [true, 'true'].includes(licensing.publicly_available),
      registrationUsStatus: reg && reg.us && ['Pixsy', 'Yes', 'Pending'].includes(reg.us.status),
      ccTerms: !!licensing.cc || (licensing.license && licensing.license.startsWith('CC')),
    }

    const locked = !editing
    const copyrightRegistrationLocked = values.registrations && values.registrations.length > 0

    const TextFieldComponent = locked ? TextField : TextFieldVertical

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Column3>
            <Field
              component={TextFieldComponent}
              name="title"
              label="Image title"
              locked={locked || copyrightRegistrationLocked}
              showNoAnswer
            />
          </Column3>
          <Column3>
            <Field
              component={DatePickerVertical}
              name="date_taken"
              label="Date Created"
              locked={locked || copyrightRegistrationLocked}
              showNoAnswer
            />
          </Column3>
          <Column3>
            <Field
              component={DatePickerVertical}
              name="licensing.first_published.date"
              label="Date Published"
              locked={locked || copyrightRegistrationLocked}
              showNoAnswer
              datepickerProps={{
                maxDate: moment().utc(),
                ...(values.date_taken && {
                  minDate: moment(values.date_taken).utc(),
                }),
              }}
            />
          </Column3>
        </Row>
        <Row>
          <Column3>
            <Field
              component={TextFieldComponent}
              label="Rights Owner"
              name="__OWNER__"
              locked={locked}
              disabled={editing}
            />
          </Column3>
          <Column3>
            <Field
              component={TextFieldComponent}
              name="place_taken"
              label="Place Created"
              locked={locked || copyrightRegistrationLocked}
              showNoAnswer
            />
          </Column3>

          <Column3>
            <Field
              component={TextFieldComponent}
              name="licensing.first_published.place"
              label="Published Location (Web Page)"
              locked={locked || copyrightRegistrationLocked}
              showNoAnswer
              validate={this.validateURL}
            />
          </Column3>
        </Row>
        {!(values.url && values.url.startsWith('https://pixel-vault')) && (
          <Row>
            <Column3 />
            <Column3 />
            <Column3>
              <Field
                component={TextFieldComponent}
                name="origin.url"
                label="Published Location (Image URL)"
                locked={locked}
                showNoAnswer
                validate={this.validateURL}
              />
            </Column3>
          </Row>
        )}

        {(editing || detailsVisible) && (
          <AlignLabels align="left">
            <SexySeparator />
            <Field
              name="licensing.work_for_hire"
              type="checkbox"
              label="I was paid or commissioned to take this image for a client."
              component={ClosedQuestionRadio}
              locked={locked}
              showNoAnswer
            />
            <SexySeparator />
            <Field
              name="licensing.cc"
              type="checkbox"
              label="This image was available under a Creative Commons license in the past."
              normalize={normalizeBoolean}
              component={ClosedQuestionRadio}
              locked={locked}
              showNoAnswer
            />

            {show.ccTerms && (
              // TEMP solution to display it in the same way as locked checkboxes
              <div css={innerLabelFixes}>
                <Field
                  component={CreativeCommonsSelect}
                  name="licensing.license"
                  label="What were the CC terms of the license?"
                  locked={locked}
                  showNoAnswer
                />
              </div>
            )}
            <SexySeparator />
            <Field
              name="registration.us.status"
              label="Have you registered your image with the US Copyright Office?"
              items={[
                { value: 'Yes', label: 'Yes' },
                { value: 'Pending', label: 'Pending' },
                { value: 'Pixsy', label: 'With Pixsy' },
                { value: 'No', label: 'No' },
              ]}
              component={USCOSelect}
              locked={locked}
              showNoAnswer
            />
            {show.registrationUsStatus &&
              (locked ? ( // TEMP solution to display it in the same way as locked checkboxes
                <Field
                  name="registration.us.id"
                  label="Please enter the reference number supplied to you by the US Copyright Office"
                  items={[{ value: reg.us.id, label: reg.us.id }, {}]}
                  component={ClosedQuestionRadio}
                  locked
                  showNoAnswer
                />
              ) : (
                <div css={innerLabelFixes}>
                  <Field
                    component={TextField}
                    name="registration.us.id"
                    label="Please enter the reference number supplied to you by the US Copyright Office"
                    locked={locked}
                    showNoAnswer
                  />
                </div>
              ))}
            <SexySeparator />
            <Field
              name="licensing.publicly_available"
              type="checkbox"
              label="Is public pricing available for this image online?"
              component={ClosedQuestionRadio}
              locked={locked}
              showNoAnswer
            />
            {show.licensingPrice &&
              (locked ? ( // TEMP solution to display it in the same way as locked checkboxes
                <Field
                  name="licensing.price.value"
                  label="At what price point?"
                  items={[
                    {
                      value: licensing.price && licensing.price.value,
                      label: `${licensing.price && licensing.price.value} ${(licensing.price &&
                        licensing.price.currency) ||
                        'USD'}`,
                    },
                    {},
                  ]}
                  component={ClosedQuestionRadio}
                  locked
                  showNoAnswer
                />
              ) : (
                <div className={`${innerLabelFixes} ${doublePriceFixes}`}>
                  <CompoundFieldsDoublePrice label="At what price point?">
                    <Field
                      component={PriceValueInputStandalone}
                      name="licensing.price.value"
                      props={{
                        normalize: normalizeNumber,
                        label: '1200.00',
                      }}
                    />
                    <Field
                      component={CurrencySelectStandalone}
                      name="licensing.price.currency"
                      props={{
                        label: 'Currency',
                      }}
                    />
                  </CompoundFieldsDoublePrice>
                </div>
              ))}
            <SexySeparator />
            <Field
              name="licensing.licensed_before"
              type="checkbox"
              label="Have you ever sold a license for this image?"
              component={ClosedQuestionRadio}
              locked={locked}
              showNoAnswer
            />
          </AlignLabels>
        )}
        <Information>
          This information is vital for future cases. Once provided this information will get filled in automatically
          for every case you submit.
        </Information>
        <SubmitButtons css={mobileButtons}>
          {!editing && (
            <Button greyOutline onClick={this.toggleDetails} css={toggleDetailsButton}>
              {detailsVisible ? (
                <React.Fragment>
                  <IconArrow rotate="180" />
                  Hide details
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <IconArrow />
                  Show details
                </React.Fragment>
              )}
            </Button>
          )}
          <Button
            greyOutline={editing}
            onClick={() => {
              toggleEdit()
              reset()
            }}
          >
            {locked ? 'Edit image info' : 'Dismiss Changes'}
          </Button>
          {!locked && (
            <Button disabled={!valid || submitting} submit>
              Save changes
            </Button>
          )}
        </SubmitButtons>
      </form>
    )
  }
}

const ConnectedForm = reduxForm({
  form: 'imageDetails',
  enableReinitialize: true,
  onSubmit: (values, dispatch, { toggleEdit }) => {
    const { _id, ...data } = values
    return dispatch(update(_id, data)).then(toggleEdit)
  },
  validate(values) {
    const obj = {}
    const firstPublishedDate = _get(values, 'licensing.first_published.date')
    const takenDate = _get(values, 'date_taken')
    if (firstPublishedDate && takenDate) {
      if (firstPublishedDate._d < takenDate) {
        return {
          licensing: {
            first_published: {
              date: 'The publication date can not precede the date the work was created.',
            },
          },
        }
      }
    }
    const registrationId = _get(values, 'registration.us.id')
    if (registrationId) {
      const blacklisted = ['No', 'Ask me', 'N/A', 'NA'].map((s) => s.toLowerCase())
      if (blacklisted.includes(registrationId.toLowerCase())) {
        return {
          registration: {
            us: {
              id: 'Please enter a valid certificate ID or application number',
            },
          },
        }
      }
    }
    if (!values.title) {
      obj.title = 'Required.'
    }
    return obj
  },
})(Form)

const ImageDetailsForm = ({ image, ...rest }) => {
  const owner = (image && image.licensing && image.licensing.creator) || rest.owner
  return (
    <ConnectedForm
      initialValues={{
        ...image,
        __OWNER__: owner,
      }}
      {...rest}
      image={image}
    />
  )
}

export default withRouter(
  connect(mapStateToProps, {}, (stateProps, dispatchProps, ownProps) => {
    const { editing, history, location, match } = ownProps
    const toggleEdit = () => {
      history.replace({
        ...location,
        pathname: editing ? match.url : `${location.pathname}/edit`,
      })
    }

    return {
      ...stateProps,
      ...ownProps,
      toggleEdit,
    }
  })(ImageDetailsForm)
)

const Column3 = styled.div`
  width: calc(100% / 3);
  display: inline-block;

  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`
const Row = styled.div`
  width: 100%;
`

const SubmitButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin-left: 10px;
  }
`

const Information = styled.div`
  font-size: 1.3em;
  padding: 20px 30px 20px 30px;
  color: #c7c7c7;

  @media (max-width: 768px) {
    padding: 20px 0px 20px 0px;
  }
`

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

function IconArrow({ rotate = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}

const toggleDetailsButton = css`
  svg {
    content: ' ';
    width: 20px;
    margin-right: 20px;
    display: inline-block;
    pointer-events: none;
  }
`

const doublePriceFixes = css`
  & [class*='-highlightIndicator'] {
    display: none;
  }
  & [class*='-wrap'] {
    flex-direction: row;
  }
`

const innerLabelFixes = css`
  & [class*='-InnerLabel'] {
    font-size: 18.2px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 4px;
    margin-top: -2px;
  }
`

const mobileButtons = css`
  @media (max-width: 768px) {
    flex-direction: column;

    button {
      margin-left: 0;

      :first-of-type {
        margin-bottom: 10px;
      }
    }
  }
`
