import leven from 'leven'

export const fields = {
  EMAIL: {
    name: 'email',
    type: 'email',
    props: {
      placeholder: 'Email',
    },
    validate(value) {
      // https://tylermcginnis.com/validate-email-address-javascript/
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
        return 'Invalid email address.'
      if (
        value &&
        /^[^\s@]+@(gmail|icloud)\.[^\\.]+$/.test(value) &&
        !value.endsWith('.com')
      )
        return 'Invalid email address.'
    },
    warn(value) {
      if (value) {
        const pixsyTopUserProviders = [
          'gmail',
          'yahoo',
          'icloud',
          'outlook',
          'live',
          'btinternet',
          'yandex',
          'protonmail',
          'bigpond',
          'googlemail',
          'comcast',
          'ymail',
          'mail',
          'hotmail',
        ]

        const tlds = {
          con: 'com',
        }

        const domain = value.split('@')
        const provider = domain[1] && domain[1].split('.')[0]
        const tld = domain[1] && domain[1].split('.')[1]

        if (tlds[tld]) {
          return `Did you mean ${provider}.${tlds[tld]}`
        }

        if (provider) {
          const checkExactMatch = pixsyTopUserProviders.filter(
            p => p === provider
          )
          if (checkExactMatch.length > 0) {
            return
          }
          for (const candidateProvider of pixsyTopUserProviders) {
            const diff = leven(provider, candidateProvider)
            if (diff <= 2) {
              return `Did you mean ${candidateProvider}.${tlds[tld] || tld}?`
            }
          }
        }
      }
    },
  },
  PASSWORD: {
    name: 'password',
    type: 'password',
    props: {
      placeholder: 'Password',
    },
    validate(value) {
      return !value && 'Password required.'
    },
  },
}
