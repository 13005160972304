import React from 'react'
import { Route } from 'react-router'
import { NavLink } from 'react-router-dom'
import { ChooseBestImageSource } from 'common'
import styled, { css, cx } from 'react-emotion'
import { getProxyUrlToMatch } from 'pixsy-constants'
import { withCookies } from 'react-cookie'

const log = require('debug')('matches:viewer:card-grid')

const IgnoreOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.63);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 45px;
  }
`

const MatchCard = ({ match, sharkMode }) => (
  <div className="match-card">
    <ChooseBestImageSource
      candidates={[match.localUrl, match.fallbackUrl, match.url, getProxyUrlToMatch(match._id)]}
      type="match"
      match={match}
    >
      {({ url, isError }) => (
        <div className={'effect-holder ' + effectHolder}>
          <div className={cx(!isError && blurHolder)} style={{ backgroundImage: `url("${url}")` }} />

          <div className={imageHolder}>
            <img src={url} />
          </div>
        </div>
      )}
    </ChooseBestImageSource>

    {match.ignored && (
      <IgnoreOverlay>
        {match.approved && <IconApprove />}
        {match.invalid && <IconCircle />}
        {!match.invalid && !match.approved && <IconIgnore />}
      </IgnoreOverlay>
    )}
  </div>
)

const CardList = ({ matches, activeMatchIndex, renderDetails, linkbase, location, allCookies }) => {
  const ITEMS_PER_ROW = 4
  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    const itemMatches = matches.map((match, index) => {
      const link = { ...location, pathname: `${linkbase}/${match._id}` }
      return (
        <CardListItem key={match._id} match={match} index={index} link={link} sharkMode={!!allCookies['pixsy-shark']} />
      )
    })

    if (activeMatchIndex >= 0) {
      const insertAt = ITEMS_PER_ROW * Math.floor(activeMatchIndex / ITEMS_PER_ROW)

      log('insertAt: %o', insertAt)

      itemMatches.splice(
        insertAt,
        0,
        <Route
          path="/matches/:set/:clusterId/:matchId"
          render={renderDetails.bind(null, itemMatches[activeMatchIndex])}
        />
      )
    }

    setItems(itemMatches)
  }, [matches, activeMatchIndex])

  return <div className={matchCardGrid}>{items}</div>
}

export default withCookies(CardList)

const CardListItem = React.memo(({ match, index, link, sharkMode }) => (
  <div className={cx(matchCardWrapper, index % 4 === 3 && matchCardWrapperLastOfRow)}>
    <NavLink to={link}>
      <MatchCard match={match} sharkMode={sharkMode} />
    </NavLink>
  </div>
))

function IconApprove() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-891.201 2203.65 28.917 21.469">
      <defs>
        <style>
          {`.svg-viewer-card-approve {
                          fill: none;
                          stroke: #fff;
                          stroke-width: 2px;
                        }`}
        </style>
      </defs>
      <path
        className="svg-viewer-card-approve"
        d="M6573.778,3228.232l7.777,7.626,8.247-8.086L6601.3,3216.5"
        transform="translate(-7464.279 -1012.14)"
      />
    </svg>
  )
}
function IconCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-724 2203 23 23">
      <defs>
        <style>
          {`
            .svg-viewer-card-invalid-1, .svg-viewer-card-invalid-3 {
              fill: none;
            }

            .svg-viewer-card-invalid-1 {
              stroke: #fff;
              stroke-width: 2px;
            }

            .svg-viewer-card-invalid-2 {
              stroke: none;
            }`}
        </style>
      </defs>
      <g className="svg-viewer-card-invalid-1" transform="translate(-724 2203)">
        <circle className="svg-viewer-card-invalid-2" cx="11.5" cy="11.5" r="11.5" />
        <circle className="svg-viewer-card-invalid-3" cx="11.5" cy="11.5" r="10.5" />
      </g>
    </svg>
  )
}

function IconIgnore() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-568.4 2203.442 22.015 22.016">
      <defs>
        <style>
          {`
            .svg-viewer-card-ignore {
              fill: none;
              stroke: #FFF;
              stroke-width: 2px;
          }`}
        </style>
      </defs>
      <g transform="translate(-1886 1615)">
        <path
          className="svg-viewer-card-ignore"
          d="M20396.156,2444l20.6,20.6"
          transform="translate(-19077.85 -1854.849)"
        />
        <path
          className="svg-viewer-card-ignore"
          d="M0,0,20.6,20.6"
          transform="translate(1318.307 609.745) rotate(-90)"
        />
      </g>
    </svg>
  )
}

const blurHolder = css`
  filter: blur(12px) brightness(95%);
  transform: scale(1.3);
  height: 100%;
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
`

const effectHolder = css`
  height: 0;
  padding-bottom: 66%;
  background-size: cover;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
`

const imageHolder = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const matchCardGrid = css`
  padding-bottom: 20.72vh;

  @media (max-width: 768px) {
    padding-bottom: 0;
    width: 100%;
    overflow-x: scroll;
    padding: 27px 26px;
  }

  .match-card {
    position: relative;
    opacity: 0.65;
    transition: opacity 250ms ease;
  }
  .match-card:hover {
    opacity: 1;
  }
  .match-card img {
    max-height: 100%;
  }

  a.active .match-card {
    opacity: 1;
    position: relative;
  }

  a.active .effect-holder {
    outline: 3px solid #fff !important;
    @media (max-width: 768px) {
      outline: 0 !important;
      border: 1px solid #fff;
    }
  }

  a.active .match-card:after {
    position: absolute;
    top: -27px;
    left: 50% !important;
    margin-left: -15px;
    content: '';
    border-bottom: 21px solid #fff;
    border-right: 15px solid rgba(0, 0, 0, 0);
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-top: 6px solid rgba(0, 0, 0, 0);
  }
`

const matchCardWrapper = css`
  width: 22.75%;
  margin: 0px 3% 50px 0;
  height: 176px;
  display: inline-block;
  border: 0;
  outline: 0;
  outline-offset: 0 !important;
  vertical-align: top;
  a {
    display: block;
  }
  @media (max-width: 768px) {
    width: 22.75vw;
    height: auto;
    // margin-bottom: 0;
    margin: 0 2px;
  }
`

const matchCardWrapperLastOfRow = css`
  margin: 0 0 2vh 0;
`
