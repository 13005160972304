import React from 'react'

export const SubmissionValidationDialogDomainStock = () => (
  <div>
    <h6>Pixsy has detected that this domain matches a known stock website.</h6>
    <p>
      Individual users post content on stock websites, and infringements on
      stock websites are therefore classified as user-submitted content. As
      Pixsy does not pursue cases against individuals, matches on stock websites
      are not accepted for Pixsy’s Case Resolution Services.
    </p>
    <p>
      We recommend that you <b>send a Takedown Notice</b> for this match,
      requesting the image is removed by the website operator or host.
    </p>
    <p>
      Pixsy uses a set of algorithms to pre-determine if a case is suitable for
      resolution. We do this to save you time and to ensure that our case
      management team can focus their efforts on recovering compensation for
      viable cases.
    </p>
  </div>
)
