import React, { Component } from 'react'
import PropTypes from 'prop-types'

const TooltipContext = React.createContext()

export default class TooltipContextWrapper extends Component {
  constructor({ content }) {
    super()
    this._content = content
  }

  get = id => {
    return this._content[id]
  }

  render() {
    return (
      <TooltipContext.Provider value={{ get: this.get }}>
        {this.props.children}
      </TooltipContext.Provider>
    )
  }
}

TooltipContextWrapper.propTypes = {
  content: PropTypes.object.isRequired,
}
TooltipContextWrapper.Consumer = TooltipContext.Consumer
