import React, { useEffect } from 'react'
import { Redirect } from 'react-router'

export default ({
  shouldRedirect,
  to,
  onEnter,
}) => WrappedComponent => moreProps => {
  const toBeRedirected = shouldRedirect(moreProps)

  useEffect(() => {
    if (onEnter && toBeRedirected) {
      setTimeout(onEnter)
    }
  }, [])

  if (toBeRedirected) {
    return <Redirect to={typeof to === 'string' ? to : to(moreProps)} />
  } else {
    return <WrappedComponent {...moreProps} />
  }
}
