import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BUCKETS } from 'pixsy-constants'
import { FormControl } from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from 'axios'
import { uniqueId } from 'lodash'
import slugify from 'slugify'
import { notifSend } from 'redux-notifications/lib/actions'
import { saveImageDirect } from '../../../../redux/modules/images'

class FileImport extends Component {
  static MAX_FILE_SIZE = 5 * 1048576 // 5 MB

  constructor(props) {
    super(props)
  }

  handleChange = (event) => {
    event.preventDefault()
    const { user, policy, notifSend, saveImageDirect } = this.props
    const userId = user._id
    const files = Array.from(event.target.files)

    if (!files.length) {
      return null
    }

    if (!policy)
      return alert(
        'Failed to prepare necessary information for upload. Please refresh the page and try again.'
      )

    const sendS3Request = (key, policy, file) => {
      const formData = new FormData()
      formData.append('key', key)
      formData.append('acl', 'public-read')
      formData.append('Content-Type', file.type)
      formData.append('AWSAccessKeyId', policy.AWSAccessKeyId)
      formData.append('success_action_status', '201')
      formData.append('Policy', policy.s3Policy)
      formData.append('Signature', policy.s3Signature)
      formData.append('file', file)

      const bucket = global.PRODUCTION
        ? BUCKETS.IMAGE_UPLOAD
        : BUCKETS.IMAGE_UPLOAD_DEV
      const server = bucket.endsWith('dev') ? 's3' : 's3-accelerate'

      return axios.post(`https://${bucket}.${server}.amazonaws.com`, formData)
    }

    const s3RequestReducer = files => {
      const [file, ...rest] = files

      const name = file && (file.name || file.originalname)
      if (!file || !name) return

      // filename must be ok as part of S3 URL
      const extensionMatches = name.match(/\.([0-9a-z]+)(?:[?#]|$)/i)
      const extension =
        extensionMatches && extensionMatches.length ? extensionMatches[0] : ''
      const originalFileName = name.replace(extension, '')
      const fileName = slugify(originalFileName)
      const key = `${userId}/${uniqueId()}_-_${fileName + extension}`

      sendS3Request(key, policy, file)
        .then(res => {
          const text = res.data
          return decodeURIComponent(
            text.substring(
              text.indexOf('<Location>') + 10, // '<Location>'.length
              text.indexOf('</Location>')
            )
          )
        })
        .then(photoUrl => ({
          key,
          url: photoUrl,
          title: originalFileName,
        }))
        .then(saveImageDirect)
        .then(() => s3RequestReducer(rest))
        .catch(console.error)
    }

    if (files.length > 0) {
      notifSend({
        kind: 'info',
        message: `Importing ${files.length} photo(s) from manual upload.`,
        dismissAfter: 10e3,
      })
      s3RequestReducer(files)
    }
  }

  render() {
    const inputFieldStyles = {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      zIndex: -1,
    }
    return (
      <label htmlFor="file-import" style={{ display: 'block' }}>
        <FormControl
          multiple
          type="file"
          id="file-import"
          name="file-import"
          accept="image/gif, image/jpg, image/jpeg, image/png"
          style={inputFieldStyles}
          onChange={this.handleChange}
        />
        {this.props.children}
      </label>
    )
  }
}

FileImport.propTypes = {
  user: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
}

export default connect(
  state => ({
    user: state.auth.user,
    policy: state.policy.data,
  }),
  {
    notifSend,
    saveImageDirect,
  }
)(FileImport)
