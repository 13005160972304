import Fade from '@material-ui/core/Fade'
import ArrowRight from '@material-ui/icons/ArrowRight'
import AttachmentIcon from '@material-ui/icons/AttachmentSharp'
import FailedIcon from '@material-ui/icons/CancelOutlined'
import CloseIcon from '@material-ui/icons/Close'
import RetryIcon from '@material-ui/icons/RefreshSharp'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import memoize from 'lodash/memoize'
import { formatBytes } from 'pixsy-schema/core'
import * as React from 'react'
import { css, cx } from 'react-emotion'
import { PixsyGrid } from '../PixsyGrid/PixsyGrid'
import { PixsyGridItem } from '../PixsyGrid/PixsyGridItem'
import { PixsyIconButton } from '../PixsyIconButton/PixsyIconButton'
import { PixsyProgress } from '../PixsyProgress/PixsyProgress'

/**
 * Display file list
 *
 * Props:
 *  files - array of files. Files created with `FileSchema` or from `PixsyFileUploader`
 *  disabled - if the component is disabled (prevent calling `onRemove`)
 *  onRemove - handle click on `x` (remove) file icon
 *
 * @augments {React.PureComponent<import('./PixsyFileList').IProps>}
 */
export class PixsyFileList extends React.PureComponent {
  state = {
    itemFocused: null,
  }
  handleFocus = fid => () => {
    this.setState(({ itemFocused }) =>
      itemFocused === fid ? null : { itemFocused: fid }
    )
  }
  handleBlur = () => {
    this.setState(({ itemFocused }) =>
      itemFocused === null ? null : { itemFocused: null }
    )
  }
  handleEvent = (event, fid) => {
    return !isFunction(event) ? undefined : () => event(fid)
  }

  getSourceLocation = memoize(source => {
    const { sourceLocation } = this.props

    if (isPlainObject(sourceLocation)) {
      return sourceLocation[source] || null
    }
    return null
  })

  getFormattedSize = memoize(fileSize => {
    if (typeof fileSize === 'number' && fileSize > 0) {
      const { size, value } = formatBytes(fileSize, 1)
      return (
        <span>
          {value} <b>{size}</b>
        </span>
      )
    }
    return null
  })

  fileStates = {
    UPLOADED: file => ({
      Component: <AttachmentDocument extension={file.extension} />,
      Caption: <span>Uploaded</span>,
    }),
    UPLOADING: (file, disabled, onPauseResume) => ({
      Component: (
        <CircularPercentage
          percentage={file.percentage || null}
          isPaused={file.isPaused}
          disabled={disabled}
          onPauseResume={this.handleEvent(onPauseResume, file.id)}
        />
      ),
      Caption: <span css={colorBlue}>Uploading</span>,
    }),
    ERROR: (file, disabled, onRetry) => ({
      Component: isFunction(onRetry) ? (
        <RetryButton
          disabled={disabled}
          onClick={this.handleEvent(onRetry, file.id)}
        />
      ) : (
        <FailedStatic />
      ),
      Caption: <span css={colorRed}>FAILED</span>,
    }),
  }

  getCurrentFileState(file, disabled) {
    const { fileStates: f } = this
    const { onPauseResume, onRetry } = this.props
    if (
      file.uploadStarted || // Progress
      file.isPaused || // If pause/resume supported
      (!file.isPaused && // Stale file, just added but upload not started
        !file.uploadStarted &&
        !file.uploadComplete &&
        !file.percentage &&
        !file.error &&
        !file.bytesTotal &&
        !file.bytesUploaded)
    ) {
      return f.UPLOADING(file, disabled, onPauseResume)
    } else if (file.uploadComplete) {
      return f.UPLOADED(file)
    } else if (file.error) {
      return f.ERROR(file, disabled, onRetry)
    }

    console.warn(
      '[PixsyFileList]: Unknown file state, assume error.\nfile:',
      file
    )

    return f.ERROR(file, disabled, onRetry)
  }

  render() {
    const { files, disabled, onRemove } = this.props
    const { itemFocused } = this.state

    return files.map((file, index) => {
      const fileState = this.getCurrentFileState(file)
      const sourceLoc = this.getSourceLocation(file.source)
      const niceBytes = this.getFormattedSize(file.size)
      const focusStyl = itemFocused === file.id && fileItemFocused
      const key = `${file.id}-${index}`

      return (
        <Fade in key={key}>
          <PixsyGrid
            key={key}
            wrap="nowrap"
            alignItems="center"
            className={cx(fileItemStyle, focusStyl)}
            onMouseEnter={this.handleFocus(file.id)}
            onMouseLeave={this.handleBlur}
          >
            {fileState.Component}
            <PixsyGridItem flex flexGrow={1}>
              <PixsyGrid direction="column">
                <PixsyGridItem css={fileNameStyle}>
                  <div>{file.fileName}</div>
                </PixsyGridItem>
                <PixsyGridItem className={cx(descriptionStyle, colorGray)}>
                  {fileState.Caption} {sourceLoc}
                </PixsyGridItem>
              </PixsyGrid>
            </PixsyGridItem>
            <PixsyGridItem className={rightBoxStyle}>
              <PixsyGrid spacing={1} justify="flex-end">
                <PixsyGridItem className={cx(colorGray)}>
                  <PixsyGrid direction="column">
                    <PixsyGridItem css={bytesStyle}>{niceBytes}</PixsyGridItem>
                    <PixsyGridItem css={extensionStyle}>
                      {file.extension || '–'}
                    </PixsyGridItem>
                  </PixsyGrid>
                </PixsyGridItem>
                {isFunction(onRemove) && (
                  <PixsyGridItem>
                    <PixsyIconButton
                      onClick={this.handleEvent(onRemove, file.id)}
                      color="#ff8181"
                      icon={CloseIcon}
                      disabled={disabled}
                    />
                  </PixsyGridItem>
                )}
              </PixsyGrid>
            </PixsyGridItem>
          </PixsyGrid>
        </Fade>
      )
    })
  }
}

const RetryButton = React.memo(({ onClick, onFocus, onBlur, disabled }) => (
  <PixsyGridItem css={leftBoxStyle}>
    <PixsyGrid direction="column" alignItems="center">
      <PixsyGridItem flex>
        <PixsyIconButton
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          icon={RetryIcon}
          disabled={disabled}
          color="#ff8181"
          size={32}
        />
      </PixsyGridItem>
    </PixsyGrid>
  </PixsyGridItem>
))
const FailedStatic = React.memo(() => (
  <PixsyGridItem css={leftBoxStyle}>
    <PixsyGrid direction="column" alignItems="center">
      <PixsyGridItem flex>
        <PixsyIconButton
          icon={FailedIcon}
          disabled
          color="#ff8181"
          colorDisabled="#ff8181"
          size={32}
        />
      </PixsyGridItem>
    </PixsyGrid>
  </PixsyGridItem>
))
const AttachmentDocument = React.memo(() => (
  <PixsyGridItem css={leftBoxStyle}>
    <PixsyGrid direction="column" alignItems="center">
      <PixsyGridItem flex>
        <PixsyIconButton disabled icon={AttachmentIcon} size={32} />
      </PixsyGridItem>
    </PixsyGrid>
  </PixsyGridItem>
))
const CircularPercentage = React.memo(
  ({ percentage, isPaused, onPauseResume, disabled }) => (
    <PixsyGridItem css={leftBoxStyle}>
      <PixsyGrid direction="column" alignItems="center">
        <PixsyGridItem flex justify="center">
          <PixsyIconButton
            size={26}
            colorDisabled="#008aab"
            color="#008aab"
            onClick={onPauseResume}
            disabled={!onPauseResume || disabled}
          >
            <PixsyProgress
              size={24}
              thickness={5}
              progress={percentage}
              color="inherit"
            />
          </PixsyIconButton>
        </PixsyGridItem>
        {!isPaused ? (
          <PixsyGridItem flex justify="center" className={cx(percentageStyle)}>
            {percentage && percentage !== 100 && <span>{percentage}%</span>}
          </PixsyGridItem>
        ) : (
          <PixsyGridItem
            flex
            justify="center"
            className={cx(percentagePausedStyle)}
          >
            <ArrowRight fontSize="inherit" />
          </PixsyGridItem>
        )}
      </PixsyGrid>
    </PixsyGridItem>
  )
)

const fileItemStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  height: 62px;
  opacity: 0.65;
`
const fileItemFocused = css`
  opacity: 1;
`
const leftBoxStyle = css`
  width: 58px !important;
  min-width: 58px !important;
  max-width: 58px !important;
  padding-right: 10px;
  color: #008aab;
  position: relative;
`
const rightBoxStyle = css`
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
`
const extensionStyle = css`
  font-size: 8px;
  line-height: 12px;
  text-transform: uppercase;
  text-align: right;
`
const bytesStyle = css`
  font-size: 14px;
  max-height: 18px;
`
const percentageStyle = css`
  color: inherit;
  font-size: 8px;
  font-weight: 600;
  line-height: 10px;
  overflow: hidden;
  position: absolute;
  top: 12px;
  white-space: nowrap;
  width: 35px;
  pointer-events: none;
`
const percentagePausedStyle = css`
  color: inherit;
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  position: absolute;
  top: 4px;
  width: 35px;
  pointer-events: none;
`
const fileNameStyle = css`
  font-size: 16px;
  line-height: 18px;
  max-height: 18px;
  overflow: hidden;
`
const descriptionStyle = css`
  font-size: 12px;
  line-height: 12px;
`
const colorGray = css`
  color: rgba(0, 0, 0, 0.5) !important;
`
const colorRed = css`
  color: #ff8181;
`
const colorBlue = css`
  color: #008aab;
`
