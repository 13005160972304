import React from 'react'
import { css } from 'react-emotion'
import { CREATIVE_COMMONS_OPTIONS } from 'pixsy-constants'
import FormRow from './FormRow'
import Label from './Label'
import AlignLabels from './AlignLabels'
import Feedback from './Feedback'

export function CreativeCommonsStandalone({ input }) {
  return (
    <div css={selectWrap}>
      <select css={select} {...input}>
        <option value="" disabled>
          Choose License
        </option>
        {CREATIVE_COMMONS_OPTIONS.map(c => (
          <option key={c} value={c}>
            {c}
          </option>
        ))}
      </select>
      <IconArrowDown />
    </div>
  )
}

export default function CreativeCommonsSelect(props) {
  const { input, meta, label, locked } = props
  return (
    <FormRow {...props}>
      <div css={wrap}>
        <div css={description}>
          <AlignLabels width="auto">
            <Label title={label} />
          </AlignLabels>
          <Feedback meta={meta} />
        </div>

        <div>
          {locked ? (
            <div css={lockedValue}>{input.value}</div>
          ) : (
            <CreativeCommonsStandalone input={input} />
          )}
        </div>
      </div>
    </FormRow>
  )
}

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}

const wrap = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

const description = css`
  display: flex;
  flex-direction: column;
`

const select = css`
  padding: 0px 36px 0px 16px;
  height: 40px;
  appearance: none;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #c7c7c7;
  cursor: pointer;
  color: #434343;
  font-size: 1.25em;
  text-indent: 0.01px;
`

const selectWrap = css`
  position: relative;

  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
`

const lockedValue = css`
  font-size: 1.14em;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  opacity: 0.5;

  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
`
