import { gql } from '@apollo/client'
import ClusterFragment from './cluster.fragment'

export default gql`
  query getClusters(
    $limit: Int!
    $skip: Int
    $sort: String
    $filters: CLUSTERS_FILTER_INPUT
  ) {
    clusters(
      limit: $limit
      skip: $skip
      sort: $sort
      populateImages: true
      populateDomain: true
      filters: $filters
    ) {
      ...cluster
    }
  }
  ${ClusterFragment}
`
