import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'

const GridWrappingColumnComponent = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 ${props => props.padding}px;

  ${props => (props.minWidth > -1 ? `min-width: ${props.minWidth}px;` : '')};
  max-width: ${props => props.maxWidth + 2 * props.padding}px;

  @media (max-width: 1280px) {
    ${props =>
      props.slim
        ? `padding: 0 ${250 / 4}px 0 ${250 / 1.5}px;`
        : props.noPadding
        ? `padding: 0;`
        : ''};
  }

  @media (max-width: 994px) {
    width: auto;
    ${props =>
      props.slim ? `padding: 0 55px 0 140px;` : `padding: 0 55px 0 70px;`};
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    ${props =>
      props.fluid
        ? ''
        : `
    min-width: auto;
    padding-left: 0;
    margin-right: 0;
    width: calc(100vw - 116px - 105px);
    `}
  }

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    max-width: 100%;
    min-width: auto;
    overflow: hidden;
  }
`
export default function GridWrappingColumn({
  children,
  maxWidth,
  minWidth = 876,
  padding,
  slim,
  blurry = false,
  noPadding = false,
  fluid,
}) {
  return (
    <GridWrappingColumnComponent
      padding={padding}
      maxWidth={maxWidth}
      minWidth={minWidth}
      slim={slim}
      fluid={fluid}
    >
      {blurry && (
        <GradientAnchor w={padding}>
          <div css={gradient} />
        </GradientAnchor>
      )}
      {children}
    </GridWrappingColumnComponent>
  )
}

GridWrappingColumn.propTypes = {
  maxWidth: PropTypes.number,
  padding: PropTypes.number,
  blurry: PropTypes.bool,
  slim: PropTypes.bool,
  noPadding: PropTypes.bool,
  fluid: PropTypes.bool,
}

GridWrappingColumn.defaultProps = {
  maxWidth: 1480,
  padding: 60,
}

const GradientAnchor = styled.div`
  float: right;
  position: sticky;
  top: 160px;
  height: 0px;
  z-index: 3;
  width: ${props => props.w - 10}px;
  transform: translateX(${props => props.w - 10}px) translateY(-160px);

  @media (max-width: 1280px) {
    width: 0 ${props => props.padding / 2}px;
  }
`

const gradient = css`
  height: 100vh;
  border: yellow;
  pointer-events: none;
  background: linear-gradient(
    #fcfcfc 10%,
    #fcfcfc00 45%,
    #fcfcfc00 75%,
    #fcfcfc 110%
  );
`
