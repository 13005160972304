import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from 'common'
import { css } from 'react-emotion'
import { requestEmailVerification } from '../../../redux/modules/auth'
import { connect } from 'react-redux'

export class ScanningLoader extends Component {
  static REFRESH_TIMER = null

  componentDidMount() {
    ScanningLoader.REFRESH_TIMER = setTimeout(this.handleTimeout, 60e3)
  }

  handleTimeout() {
    try {
      window.mixpanel.track('Matches.Waiting')
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }

    setTimeout(() => {
      location.reload()
    }, 1000)
  }

  isVerified = () => {
    const { user } = this.props

    // compare user model, isVerified()

    const verified =
      user &&
      user.verification &&
      user.verification.email &&
      user.verification.email.dateVerified

    return verified
  }

  render() {
    const { requestEmailVerification } = this.props
    return (
      <div css={wrap}>
        <div css={card}>
          <div css={circle} />

          {this.isVerified() ? (
            <React.Fragment>
              <h3>Scanning</h3>
              <p>
                Pixsy is currently performing a scan of your images. Any results
                will be shown shortly. It can take hours to days for matches to
                be delivered. You will get an email alert once your account is
                ready.
              </p>
              <Link to="/images/import">
                <Button>Import More Images</Button>
              </Link>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3>Waiting for verification</h3>
              <p>
                Once your email address is verified, Pixsy will perform the
                initial scan of your images. Any results will be shown shortly
                once the scan is underway.
              </p>
              <Button onClick={requestEmailVerification}>Resend link</Button>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default connect(({ auth: user }) => user, { requestEmailVerification })(
  ScanningLoader
)

ScanningLoader.propTypes = {
  user: PropTypes.object.isRequired,
  requestEmailVerification: PropTypes.func.isRequired,
}

const wrap = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const card = css`
  background-color: white;
  display: inline-block;
  width: 400px;
  padding: 35px 20px;
  margin-top: 50px;
  margin-bottom: 35px;
  border: 1px solid rgb(240, 240, 240);
  h3 {
    font-size: 1.75em;
    margin: 0 0 15px 0;
  }
  p {
    margin: 0 0 15px 0;
    padding: 0 30px;
    opacity: 0.75;
  }
`

const circle = css`
  display: inline-block;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 3px solid transparent;
  border-top-color: #008aab;
  border-right-color: #008aab;
  border-radius: 50%;
  margin-bottom: 40px;
  animation: move 0.8s linear infinite;

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      border-radius: 50%;
    }
  }
`
