import React from 'react'
import Helmet from 'react-helmet'
import { Button, FloatingInput } from 'common'
import { Field, reduxForm } from 'redux-form'
import { setPassword } from '../../../redux/modules/auth'
import { fields, asyncValidate, asyncBlurFields } from './fields'

export default reduxForm({
  onSubmit: (values, dispatch, { token }) =>
    dispatch(setPassword({ ...values, token })),
  form: 'passwordReset',
  validate,
  asyncValidate,
  asyncBlurFields,
})(PasswordResetForm)

export function PasswordResetForm({ valid, handleSubmit, submitSucceeded }) {
  const getFieldPropsFor = key => ({
    name: fields[key].name,
    type: fields[key].type,
    props: fields[key].props,
  })

  return (
    <form onSubmit={handleSubmit} className="home-small">
      <Helmet title="Reset Password" />
      {submitSucceeded ? (
        <center>
          <b>Your password has been updated successfully.</b>
        </center>
      ) : (
        <div>
          <Field component={FloatingInput} {...getFieldPropsFor('PASSWORD')} />

          <Button type="action" disabled={!valid} full submit>
            Set Password
          </Button>
        </div>
      )}
    </form>
  )
}

function validate(values) {
  return Object.values(fields).reduce(
    (obj, { name, validate = () => null }) => {
      const entry = validate(values[name])
      return entry ? { ...obj, [name]: entry } : obj
    },
    {}
  )
}
