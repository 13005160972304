import React, { Component } from 'react'
import Waypoint from 'react-waypoint'
import { AlignLabels, Button, ContentSection } from 'common'
import { css } from 'react-emotion'
import Uppy from '@uppy/core'
import BulkSubmissionFormFileUpload from './BulkSubmissionFormFileUpload'
import BulkSubmissionFormImagesVerifier from './BulkSubmissionFormImagesVerifier'
import { bulkValidate } from '../../../../../redux/modules/registrations'
import axios from 'axios'
import { connect } from 'react-redux'
import { notifSend } from 'redux-notifications/lib/actions'
import { SUPPORTED_COUNTRIES } from 'pixsy-constants'

const log = require('debug')('submission:bulk-images')

class SubmissionFormBulkImages extends Component {
  constructor(props) {
    super(props)

    const images =
      this.props.values.images && this.props.values.images.length > 0
        ? this.props.values.images
        : []

    const locked = images.length > 0 && props.phase !== 'DRAFT'
    if (images.length > 0) {
      this.props.values.images = images
    }

    this.state = {
      uploading: false,
      error: null,
      uppy: this.initializeUppy(),
      locked,
      images,
    }
  }

  componentWillReceiveProps(nextProps) {
    // Form just saved, lock "Upload Again" button.
    if (nextProps.registrationId !== this.props.registrationId) {
      this.setState({ locked: true })
    }
  }

  triggerPageView(pageView) {
    if (pageView !== this.state.lastPageView) {
      const self = this
      try {
        window.gtag('event', 'page_view', {
          page_path: '/c/vpv/fu/co/reg/' + pageView,
          event_callback() {
            self.setState({ lastPageView: pageView })
          },
        })
      } catch (err) {
        console.error(err)
      }
    }
  }

  /**
   * Initialize uppy with two hooks:
   * - on adding file it performs XLSX parse and validation
   * - it listens on any errors and calls restartFormWithErrorMessage if necessary
   * @returns {Uppy}
   */
  initializeUppy() {
    const convertXls = file => {
      const body = new FormData()
      body.append('xls', file.data)
      log(`Got target`, file)
      const validate = this.validateImages(
        body,
        this.props.values.publishedBefore
      )
      validate
        .then(response => {
          if (response && response.payload.images) {
            this.setState({
              images: response.payload.images,
              uploading: false,
            })
          }

          if (response && response.payload.errors) {
            const formattedErrors = response.payload.errors.reduce(
              (prev, error) => {
                if (!prev[error.message]) prev[error.message] = []
                prev[error.message].push(error.row)
                return prev
              },
              {}
            )
            this.restartFormWithMessage(
              Object.keys(formattedErrors).map(err => {
                if (formattedErrors[err].length > 0) {
                  return (
                    <li key={err}>
                      <strong>{err}</strong>
                      <br />
                      on Row number {formattedErrors[err].join(', ')}
                      {err.includes('have to be from supported countries') && (
                        <p>
                          <strong>Pixsy supported Countries are:</strong>
                          <br />
                          {SUPPORTED_COUNTRIES.map(c => c.label).join(', ')}
                        </p>
                      )}
                    </li>
                  )
                }

                return <li key={err}>{err}</li>
              })
            )
            // this.restartFormWithMessage(
            //   response.payload.errors.map((error, i) => {
            //     if (error.row) {
            //       return (
            //         <li key={`row:${i}:${error.row}`}>
            //           <strong>Error on row {error.row}</strong>: {error.message}
            //         </li>
            //       )
            //     }

            //     return <li key={`row:${i}:file`}>{error.message}</li>
            //   })
            // )
          }

          this.props.values.images = response.payload.images
        })
        .catch(_ => {
          this.restartFormWithMessage(
            <p>
              Could not import due to internal API failure! If the problem
              persists, please contact:{' '}
              <a href="mailto:support@pixsy.com">support@pixsy.com</a>
            </p>
          )
        })
    }

    const uppy = Uppy({
      debug: true,
      id: 'uppy-image-import',
      restrictions: {
        maxFileSize: 2e6,
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          '.xls',
          '.xlsx',
        ],
      },
    })
    uppy.on('file-added', file => {
      this.triggerPageView('file-imported')
      this.setState({ uploading: true })
      log('Added file', file)
      convertXls(file)
    })
    uppy.on('info-visible', () => {
      const info = uppy.getState().info
      this.restartFormWithMessage(<p>{info.message}</p>)
    })

    return uppy
  }

  /**
   * Call API to verify if images can be submitted.
   * @param body
   * @returns {*|Promise<*>}
   */
  validateImages = (body, publishedBefore) => {
    return this.props.dispatch(bulkValidate(body, publishedBefore))
  }

  /**
   * Restart SubmissionFormBulkImages to its initial state.
   * If error is provided, it will additionally render out why it has to be restarted.
   * @param error
   */
  restartForm = error => {
    const { uppy } = this.state
    this.triggerPageView('reset-upload')
    uppy.reset()
    this.setState({
      error: null,
      images: [],
      uploading: false,
    })
  }

  /**
   * @param error
   */
  restartFormWithMessage = error => {
    this.setState({
      error,
      images: [],
      uploading: false,
    })
  }

  /**
   * Requests user images export - similar logic is inside ImagesExportForm.js (inside Settings/Profile of user)
   * @returns {Promise<void>}
   */
  enqueueImagesExport = async () => {
    const { notifSend } = this.props
    this.triggerPageView('request-export')
    this.props
      .onRequestImages(true)
      .then(registrationId => {
        if (!registrationId) return false
        return axios.post(`/api/images/exportXls/${registrationId}`)
      })
      .then(response => {
        if (response && response.data) {
          notifSend({
            message: response.data.message,
            kind: 'success',
            dismissAfter: 5e3,
          })
        }
      })
  }

  render() {
    const { uppy, uploading, images, error, locked } = this.state

    const UploadAgain = () => {
      if (locked) return null
      return (
        <React.Fragment>
          <br />
          <div>
            <p style={{ textAlign: 'center' }}>Need to edit any details?</p>
            <Button center hoverMain onClick={this.restartForm}>
              Upload Again
            </Button>
          </div>
        </React.Fragment>
      )
    }

    return (
      <ContentSection id="images">
        <Waypoint onEnter={this.props.onWaypoint} />
        <h1>Work</h1>

        <AlignLabels align="left" width="200px">
          {!error && images.length === 0 && (
            <React.Fragment>
              <p>
                To allow for easy bulk review and editing of image data, please
                follow these steps:
              </p>
              <br />
              <p>
                <span css={stepNumber}>1</span>
                <b>Download a copy of your image data</b> (Excel file) for all
                images you have imported into Pixsy. A download file will be
                generated and emailed to you once ready.
              </p>
              <p>
                <Button center onClick={this.enqueueImagesExport}>
                  Request Export
                </Button>
              </p>
              <br />
              <p>
                <span css={stepNumber}>2</span>
                <b>Edit the Excel sheet</b> to only include the images you wish
                to register. Review and complete all the data fields – BE
                CAREFUL – this data will be used to file at the copyright
                office, so ensure it’s accurate.
              </p>
              <p>
                <a href="http://support.pixsy.com/articles/184" target="_blank">
                  <Button center>HOW TO GUIDE</Button>
                </a>
              </p>
              <br />
              <p>
                <span css={stepNumber}>3</span>
                <b>Upload completed image data.</b> Once you have finalized all
                the image data, upload the completed file below.
              </p>
              <BulkSubmissionFormFileUpload uppy={uppy} uploading={uploading} />
              <br />
              <p>
                <span css={stepNumber}>4</span>
                <b>Review final data.</b> The completed data will be shown to
                you for your final review before submitting. If there are any
                errors in the formatting or data, you will be prompted how to
                correct so you can upload again.
              </p>
            </React.Fragment>
          )}

          {error && (
            <React.Fragment>
              <h1>Import failed!</h1>
              <ul>{error}</ul>
              <UploadAgain />
            </React.Fragment>
          )}

          {!uploading && images.length > 0 && (
            <React.Fragment>
              <BulkSubmissionFormImagesVerifier
                images={images}
                locked={locked}
              />
              <UploadAgain />
            </React.Fragment>
          )}
        </AlignLabels>
      </ContentSection>
    )
  }
}

const stepNumber = css`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  color: white;
  background-color: #008aab;
  margin: 0 10px 0 0;
  line-height: 1.6em;
  font-weight: 600;
  font-size: 15px;
  display: inline-block;
  align-items: center;
  justify-content: center;
`

export default connect(null, { notifSend })(SubmissionFormBulkImages)
