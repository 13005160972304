/* global Dropbox */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { notifSend } from 'redux-notifications/lib/actions'
import { saveImageDropbox } from '../../../../redux/modules/images'
import { DROPBOX } from 'pixsy-constants'

const log = require('debug')('import:dropbox')
const isDropboxLoaded = () => typeof Dropbox !== 'undefined'

class DropboxImport extends Component {
  constructor(props) {
    super(props)

    const DISABLED_IMPORTS = global.DISABLED_IMPORTS || ''
    const disabled = DISABLED_IMPORTS.split(',').includes(DROPBOX)
    if (disabled) {
      this.handeClick = () => {}
    }
  }

  handleDropboxSelection = (files) => {
    log('handleDropboxSelection')
    const { notifSend, saveImageDropbox } = this.props
    log(files)
    const chunkedData = chunk(
      files.map(f => ({
        id: f.id,
        url: f.link,
        name: f.name,
      })),
      50
    )

    log('Will push: \n%O', chunkedData)
    chunkedData.forEach(data => {
      saveImageDropbox(data)
    })
    setTimeout(() => {
      notifSend({
        kind: 'info',
        message: `Importing ${files.length} photo(s) from Dropbox.`,
        dismissAfter: 8e3,
      })
    }, 0)
  }

  handleClick = (event) => {
    event.preventDefault()
    const { notifSend } = this.props

    const options = {
      success: this.handleDropboxSelection,
      linkType: 'direct',
      multiselect: true,
      extensions: ['images'],
      cancel() {},
    }

    if (!isDropboxLoaded()) {
      notifSend({
        kind: 'warning',
        message: `Unable to initialize Dropbox image selection. Please verify your browser is not blocking execution of Dropbox code. `,
        dismissAfter: 15e3,
      })
    } else {
      Dropbox.choose(options)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <script
            async
            defer
            src="//www.dropbox.com/static/api/2/dropins.js"
            id="dropboxjs"
            data-app-key="kewomd13h4opwih"
          />
        </Helmet>

        <div onClick={this.handleClick}>{this.props.children}</div>
      </React.Fragment>
    )
  }
}

DropboxImport.propTypes = {
  saveImageDropbox: PropTypes.func.isRequired,
  notifSend: PropTypes.func.isRequired,
}

export default connect(
  null,
  { notifSend, saveImageDropbox }
)(DropboxImport)
