import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Images from './Images'

export default function ImagesContainer({ location, history, match }) {
  return (
    <React.Fragment>
      <Helmet title="Images" />
      <Images location={location} history={history} match={match} />
    </React.Fragment>
  )
}

ImagesContainer.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}
