export default function createContactUsHandler(reason) {
  return () => {
    //     window.Intercom(
    //       'showNewMessage',
    //       `##### Do not edit ####
    // URL:
    // ${window.location.href}

    // Blocked by: ${reason}

    // We aim to respond to all queries within 48 hours. Please leave this information intact and write your question below the line.
    // ###################

    // `
    //     )
    window.location.href = `mailto:support@pixsy.com?subject=Blocked by: ${reason}&body=##### Do not edit ####${'<br/>'}URL: ${
      window.location.href
    }${'<br/>'}${'<br/>'}Blocked by: ${reason}${'<br/>'}${'<br/>'}We aim to respond to all queries within 48 hours. Please leave this information intact and write your question below the line.${'<br/>'}###################`
  }
}
