import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import styled, { css, cx } from 'react-emotion'

export default function TextField(props) {
  const {
    addon,
    input,
    label,
    locked,
    showNoAnswer,
    labelProps = {},
    meta = {},

    type = 'text',
  } = props

  const {
    validate,
    maxLength,
    readOnly = false,
    waiting = false,
    ...otherProps
  } = props

  const disabled = props.disabled || waiting

  return (
    <FormRow {...otherProps}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked, mobile)}>
        {locked ? (
          <div css={stacked}>
            <span css={lockedLabel}>{label}</span>
            <p css={lockedValue}>
              {props.input.value ? props.input.value : showNoAnswer ? '-' : ''}
            </p>
          </div>
        ) : (
          <React.Fragment>
            <Label title={label} align={props.labelAlign} {...labelProps} />
            <div css={stacked}>
              <div css={inputParent}>
                <Input
                  {...input}
                  type={type}
                  width={props.width}
                  {...(maxLength && { maxLength })}
                  {...(disabled && { disabled })}
                  {...(readOnly && { readOnly })}
                />
                {waiting && <Spinner text={input.value} />}
              </div>
              <Feedback meta={meta} />
            </div>
          </React.Fragment>
        )}
        {addon && addon({ name: input.name })}
      </div>
    </FormRow>
  )
}

const stacked = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const stylesUnlocked = css``

const stylesLocked = css`
  opacity: 0.5;
`

const lockedValue = css`
  font-size: 1.14em;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`

const lockedLabel = css`
  display: block;
  font-size: 1em;
  font-weight: 400;
`

const wrap = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Input = styled.input`
  height: 40px;
  width: ${props => props.width};
  padding: 0 12px;
  border: 1px solid #c7c7c7;
  font-size: 15px;
  ${props =>
    props.readOnly
      ? `
      cursor: not-allowed;
      background-color: hsl(0, 0%, 96%);
    `
      : ''}};

  &:focus {
    outline: 0;
    border: 1px solid #148aa9;
  }

  @media (max-width: 768px) {
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
  }
`

const mobile = css`
  @media (max-width: 768px) {
    display: block;
    input {
      width: 100%;
    }
  }
`

const inputParent = css`
  position: relative;
  display: inline-block;
`

const Spinner = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: ${props =>
    props.text.length * 8.84 +
    'px'}; /* We're pusing the spinner further depending on the text length */
  box-sizing: border-box;
  border: 3px solid transparent;
  border-top-color: #008aab;
  border-right-color: #008aab;
  border-radius: 50%;
  animation: move 0.8s linear infinite;

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      border-radius: 50%;
    }
  }
`

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
  type: PropTypes.string,
  labelAlign: PropTypes.string,
}

TextField.defaultProps = {
  width: '100%',
  labelAlign: 'right',
}
