import React from 'react'
import { PixsyGrid, PixsyGridItem } from '../../../../Components'

export const ScenarioMatchOriginApproved = () => {
  return (
    <PixsyGrid alignItems="center" direction="column">
      <PixsyGridItem fullWidth>
        <h1>Warning - Marked as ‘approved use’</h1>
      </PixsyGridItem>

      <PixsyGridItem fullWidth>
        <p>
          You have previously marked this use as an ‘approved use’. Please check
          and confirm this is in fact an unauthorized use before proceeding.
        </p>

        <p>
          <a href="/profile/settings" target="_blank">
            You can edit your Ignore and Approved lists here.
          </a>
        </p>
      </PixsyGridItem>
    </PixsyGrid>
  )
}
