import propTypes from 'prop-types'
import React, { useMemo } from 'react'
import { PixsyGrid } from '../PixsyGrid/PixsyGrid'
import { PixsyRadio } from './PixsyRadio'

const mapItemsToPixsyRadio = ({ flexBasis, name, height, keepPristine }) => (
  { id, value, label, text, variant },
  i
) => (
  <PixsyRadio
    key={`${id || ''}-${name}-${value}-${label}-${text || ''}-${i}`}
    id={id}
    name={name}
    value={value}
    label={label}
    text={text}
    flexBasis={flexBasis}
    variant={variant}
    height={height}
    keepPristine={keepPristine}
  />
)
export const PixsyRadioGroup = React.memo(
  ({ name, items, children, height, spacing = 0, keepPristine = false }) => {
    if (children) {
      return <PixsyGrid justify="space-between">{children}</PixsyGrid>
    }

    const avgFlexBasis = (100 / (items.length || 1)).toFixed(2)
    const flexBasis = `${avgFlexBasis}%`
    const kids = useMemo(
      () =>
        items.map(
          mapItemsToPixsyRadio({ flexBasis, name, height, keepPristine })
        ),
      [items, name, height, keepPristine]
    )

    return (
      <PixsyGrid justify="space-between" spacing={spacing}>
        {kids}
      </PixsyGrid>
    )
  }
)
PixsyRadioGroup.propTypes = {
  children: propTypes.node,
  height: propTypes.number,
  name: propTypes.string.isRequired,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      text: propTypes.string,
      value: propTypes.any.isRequired,
      label: propTypes.string.isRequired,
      variant: propTypes.string,
    })
  ),
}
