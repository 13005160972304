import { CATEGORY_LABELS, resolveCountryNameForCode } from 'pixsy-constants'

const resolveValuesFromTags = (tags) => {
  let countryName, isOnline, categoryName, isTopMatch, categoryCode, countryCode, isFlagged, isPixsyFlagged

  tags.forEach((tag) => {
    const [key, value] = tag.split(':')

    if (key === 'country') {
      countryCode = value
      countryName = resolveCountryNameForCode(countryCode)
      if (countryName == null || countryName.toLowerCase() === 'undefined') {
        countryName = undefined
        countryCode = undefined
      }
    } else if (key === 'category') {
      categoryName = CATEGORY_LABELS[value]
      categoryCode = value
    } else if (key === 'online') {
      isOnline = value !== 'false'
    } else if (key === 'top') {
      isTopMatch = true
    } else if (key === 'flagged') {
      isFlagged = true
    } else if (key === 'pixsy' && value === 'flagged') {
      isPixsyFlagged = true
    }
  })

  return {
    countryName,
    isOnline,
    categoryName,
    isTopMatch,
    countryCode,
    categoryCode,
    isFlagged,
    isPixsyFlagged,
  }
}

export default resolveValuesFromTags
