import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { withCookies } from 'react-cookie'
import moment from 'moment'
import { Button, Dialog } from 'common'

class AdBlockDetector extends Component {
  static COOKIE_KEY = 'pixsy-adblocker-ignore'

  static userHasBeenWarned(cookies) {
    const value = cookies.get(AdBlockDetector.COOKIE_KEY)
    return value ? Number.parseInt(value) : 0
  }

  constructor(props) {
    super(props)
    this.state = {
      adBlockDetected: false,
      userWarned: AdBlockDetector.userHasBeenWarned(props.cookies),
    }
  }

  componentDidMount() {
    if (!this.state.userWarned) {
      this.detectAdBlocker()
    }
  }

  componentWillUnmount() {
    // Prevent this warning:
    // Warning: Can't perform a React state update on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    // To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
    clearTimeout(this._timer)
  }

  detectAdBlocker() {
    const adblockerBait = document.createElement('div')
    adblockerBait.id = 'test-block'
    adblockerBait.className =
      'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads'
    adblockerBait.setAttribute(
      'style',
      'position: absolute; top: -10px; left: -10px; width: 1px; height: 1px;'
    )
    document.body.appendChild(adblockerBait)

    this._timer = setTimeout(() => {
      const detected =
        window.document.body.getAttribute('abp') !== null ||
        adblockerBait.offsetParent === null ||
        adblockerBait.offsetHeight === 0 ||
        adblockerBait.offsetLeft === 0 ||
        adblockerBait.offsetTop === 0 ||
        adblockerBait.offsetWidth === 0 ||
        adblockerBait.clientHeight === 0 ||
        adblockerBait.clientWidth === 0
      this.setState({
        adBlockDetected: detected,
      })
      try {
        document.body.removeChild(adblockerBait)
      } catch (e) {}
    })
  }

  ignoreWarning = () => {
    this.setState({ userWarned: true }, () => {
      this.props.cookies.set(AdBlockDetector.COOKIE_KEY, 1, {
        path: '/',
        expires: moment()
          .add(1, 'month')
          .toDate(),
      })
    })
  }

  render() {
    const { adBlockDetected, userWarned } = this.state
    return (
      <Dialog
        isOpen={adBlockDetected && !userWarned}
        onRequestClose={this.ignoreWarning}
      >
        <div css={content}>
          <h1>Pixsy has detected an ad blocker</h1>
          <span>
            Please deactivate it for <i>my.pixsy.com</i> since it might block
            found matches and interfere with Pixsy‘s functionality. To
            deactivate your ad blocker, navigate to your browser extension‘s
            settings and select <i>disable for this page</i>.
          </span>
          <div css={buttons}>
            <Button onClick={this.ignoreWarning}>I understand</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withCookies(AdBlockDetector)

AdBlockDetector.propTypes = {
  cookies: PropTypes.object.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 768px) {
    h1 {
      text-align: center;
    }
  }
`

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
