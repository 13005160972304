import * as React from 'react'
import { css } from 'react-emotion'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import {
  PixsyGrid,
  PixsyGridItem,
  PixsyPaper,
  PixsyProgress,
} from '../../containers/Cases/CaseSubmission/Components'
import { CheckIcon, SexySeparator } from 'common'

export default class MatchViewer extends React.PureComponent {
  static Status = {
    LOADING: 'LOADING',
    INVALID: 'INVALID',
    VALID: 'VALID',
  }

  CheckItem({ Status, text }) {
    return (
      <PixsyGridItem style={itemStyle} xs={12} md={3}>
        <div>
          <Status size={24} />
        </div>
        <div>
          <p>{text}</p>
        </div>
      </PixsyGridItem>
    )
  }

  InvalidSymbol() {
    return <div css={exclamationMark}>!</div>
  }

  getStatusSymbol(status) {
    if (status === MatchViewer.Status.LOADING) {
      return PixsyProgress
    } else if (status === MatchViewer.Status.INVALID) {
      return this.InvalidSymbol
    } else {
      return CheckIcon
    }
  }

  filterNullChildren() {
    const { children } = this.props
    return children
      ? React.Children.map(children, child => child).filter(Boolean)
      : []
  }

  renderErrors(children) {
    const settings = {
      dots: false,
      infinite: false,
      draggable: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <Slider {...settings} css={errorStyle}>
        {children}
      </Slider>
    )
  }

  render() {
    const {
      imagesCount,
      onlineStatus,
      domainStatus,
      historyStatus,
    } = this.props
    const children = this.filterNullChildren()
    const { CheckItem } = this

    return (
      <PixsyPaper padding="30px 60px 30px 60px">
        <PixsyGrid spacing={2}>
          <CheckItem
            Status={() => <h3>{imagesCount}</h3>}
            text="IMAGE SELECTED FOR SUBMISSION"
          />
          <CheckItem
            Status={this.getStatusSymbol(onlineStatus)}
            text="ONLINE STATUS CHECK"
          />
          <CheckItem
            Status={this.getStatusSymbol(domainStatus)}
            text="DOMAIN CHECK"
          />
          <CheckItem
            Status={this.getStatusSymbol(historyStatus)}
            text="SUBMISSION HISTORY CHECK"
          />
          {children.length > 0 && (
            <PixsyGridItem xs={12}>
              <SexySeparator />
            </PixsyGridItem>
          )}
          {children.length > 0 && (
            <PixsyGridItem xs={12}>{this.renderErrors(children)}</PixsyGridItem>
          )}
        </PixsyGrid>
      </PixsyPaper>
    )
  }
}

MatchViewer.propTypes = {
  imagesCount: PropTypes.number.isRequired,
  onlineStatus: PropTypes.string.isRequired,
  domainStatus: PropTypes.string.isRequired,
  historyStatus: PropTypes.string.isRequired,
}

const exclamationMark = css`
  width: 24px;
  height: 24px;
  font-size: 1.5em;
  font-weight: 500;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #e66363ff;
  margin: 0 auto;
`

const itemStyle = css`
  height: 45px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 35px;
  div {
    height: 25px;
    margin: 5px auto;
  }
  h3 {
    margin: 0;
  }
  svg {
    display: block;
    margin: 3px auto;
    height: 24px;
    width: 24px;
  }
`

const errorStyle = css`
  padding: 0 20px;
  h1 {
    margin: 10px 0 10px 0;
    text-align: center;
    font-weight: 500;
    font-size: 1.6em;
    + p {
      margin-top: 20px;
    }
  }

  h4 {
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 1em;
  }
`
