import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import { ContentSection, ActionSection, ScrollToTop } from 'common'

const BulletPoints = styled.ul`
  margin-top: 30px;
  padding: 10px 0;
  li {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 0 50px 40px;
    position: relative;
    font-size: 1em;
    &:nth-of-type(2n) {
      margin-top: 80px;
    }
    &:nth-of-type(3) {
      margin-top: -40px;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    h3 {
      font-size: 1.8em;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 !important;
      padding: 0 0 40px;
      figure {
        height: 50px;
      }
    }
    &:after {
      /*      content: '';
      display: block;
      width: squareSize;
      height: squareSize;
      position: absolute;
      top: 0;
      left: 50%;
      margin: -(squareSize / 2);
      background-color: $action;*/
    }
    overflow: hidden;
  }

  figure {
    height: 120px;
    margin: 0 auto 15px;
    svg {
      height: 100%;
    }
  }
`

export default function TakedownIntro({ renderActionSection }) {
  // const takedownIcon = require(`./takedownIcon.svg`)
  // const searchIcon = require(`./searchIcon.svg`)
  const takedownIntro1 = require(`./takedownIntro1.svg`)
  const takedownIntro2 = require(`./takedownIntro2.svg`)
  const takedownIntro3 = require(`./takedownIntro3.svg`)
  const takedownIntro4 = require(`./takedownIntro4.svg`)

  return (
    <React.Fragment>
      <ContentSection centered>
        <ScrollToTop />
        <h1>Send Legal Takedown Notices</h1>
        <BulletPoints>
          <li>
            <figure dangerouslySetInnerHTML={{ __html: takedownIntro1 }} />
            <h3>Overview</h3>
            Pixsy’s automated Takedown tool is a self-service solution which
            allows you to send legally binding takedown notices to hosts and
            sites around the globe - including to popular platforms such as
            Pinterest, Facebook and YouTube.
          </li>
          <li>
            <figure dangerouslySetInnerHTML={{ __html: takedownIntro2 }} />
            <h3>Global Reach</h3>
            Pixsy has carefully crafted legally binding texts and templates to
            meet the requirements of that country’s laws, regulations and
            takedown procedures. In jurisdictions where English is not their
            first language, a translated copy of the text is included alongside
            the English text.
          </li>
          <li>
            <figure dangerouslySetInnerHTML={{ __html: takedownIntro3 }} />
            <h3>Compensation</h3>
            If the use of your image is commercial or by a business (including
            on such business's social media account - Twitter, Facebook,
            Linkedin), you should consider submitting this as a case to Pixsy’s
            Case Resolution Service.
          </li>
          <li>
            <figure dangerouslySetInnerHTML={{ __html: takedownIntro4 }} />
            <h3>Coming soon</h3>
            In the coming weeks we will include advanced monitoring tools to see
            all your takedowns, online and offline status indication, and
            options to send reminders or follow up notices when your request has
            not been actioned.
          </li>
        </BulletPoints>
      </ContentSection>

      <ActionSection>{renderActionSection()}</ActionSection>
    </React.Fragment>
  )
}

TakedownIntro.propTypes = {
  renderActionSection: PropTypes.func.isRequired,
}
