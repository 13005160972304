import { gql } from '@apollo/client'

export default gql`
  query falsePositiveReviewMatches {
    falsePositiveReview {
      image {
        _id
        url
      }
      matches {
        _id
        url
        imagesimCollectionEvent
      }
    }
  }
`
