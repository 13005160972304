import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'
import { ContentSection, ScrollToTop, SexySeparator, Flag } from 'common'
import { REGISTRATION_OFFICES } from 'pixsy-constants'
import { connect } from 'react-redux'
import { notify } from '../../redux/modules/registrations'
import { compose, withHandlers, withProps } from 'recompose'
import { Link } from 'react-router-dom'

const officesAvailable = REGISTRATION_OFFICES.filter(o => o.available)
const officesComingSoon = REGISTRATION_OFFICES.filter(o => o.soon)

export default function CopyrightRegistrationGuide({
  onContinue,
  getFieldPropsFor,
  values,
}) {
  return (
    <ContentSection centered>
      <ScrollToTop />
      <h1>Pixsy Copyright Registration Service</h1>
      <div css={matchSubmissionIntroTextCont}>
        <div css={matchSubmissionIntroCard}>
          <IntroIcon num="1" />
          <h3>Official Registration</h3>
          <ul>
            <li>
              <span>
                Register your images with the official government copyright
                office for legal proof of your ownership &amp; authorship.
              </span>
            </li>
            <li>
              <span>
                Enjoy the legal and monetary benefits only available for
                registered works.
              </span>
            </li>
          </ul>
        </div>

        <div css={matchSubmissionIntroCard}>
          <IntroIcon num="2" width="200" />
          <h3>Increased value</h3>
          <ul>
            <li>
              <span>
                In cases of copyright infringement,{' '}
                <strong>statutory damages range from US$750 - $150,000</strong>{' '}
                per infringement (only available for registered works).
              </span>
            </li>
            <li>
              <span>
                In many jurisdictions litigation is only possible if the work is
                officially registered.
              </span>
            </li>
          </ul>
        </div>

        <div css={matchSubmissionIntroCard}>
          <IntroIcon num="3" />
          <h3>Managed for you</h3>
          <ul>
            <li>
              <span>
                Every step is managed by Pixsy’s expert team, from paperwork, to
                filing, to dealing with the copyright office.
              </span>
            </li>
            <li>
              <span>
                Registrations are automatically applied to your images and
                certificates uploaded to your dashboard.
              </span>
            </li>
          </ul>
        </div>
      </div>

      <FeaturedHeader>
        Eligible for compensation up to $150,000 per infringement of registered
        images
      </FeaturedHeader>

      <SexySeparator space={20} />

      <OfficesRow>
        {officesAvailable.length && (
          <OfficesCategories>
            <OfficeCategory>Available copyright registrations</OfficeCategory>
            <Offices>
              {officesAvailable.map(office => (
                <OfficeCard key={office.abbr} {...office} />
              ))}
            </Offices>
          </OfficesCategories>
        )}
        {officesComingSoon.length && (
          <OfficesCategories>
            <OfficeCategory>Others coming soon...</OfficeCategory>
            <Offices>
              {officesComingSoon.map(office => (
                <OfficeCard key={office.abbr} {...office} soon />
              ))}
            </Offices>
          </OfficesCategories>
        )}
      </OfficesRow>
    </ContentSection>
  )
}

CopyrightRegistrationGuide.propTypes = {
  onContinue: PropTypes.func.isRequired,
  getFieldPropsFor: PropTypes.func.isRequired,
  values: PropTypes.object,
}

const OfficesRow = styled.div`
  display: flex;
  justify-content: center;
`
const OfficesCategories = styled.div``
const OfficeCategory = styled.div``
const Offices = styled.div`
  display: table;
`
const officeCard = css`
  display: table-cell;
  text-align: center;
  font-size: 0.9em;
  line-height: 1.3em;
  padding: 0 10px;

  &.soon span,
  &.soon img {
    opacity: 0.5;
  }
  &.soon a {
    opacity: 1;
  }
  &.soon:first-of-type {
    border-left: 1px solid #e6e6e6;
  }

  img {
    display: block;
    margin: 0 auto;
    transform: scale(0.6);
  }
`

const OfficeCardComponent = ({
  label,
  abbr,
  flag,
  soon,
  notifyUser,
  isBeingNotified,
}) => {
  return (
    <div css={officeCard} className={soon ? 'soon' : ''}>
      <Flag country={flag} />
      <span>
        {label} ({abbr})
      </span>
      {!soon && (
        <Link to={{ pathname: '/registrations/submit/new' }}>
          <div style={{ fontSize: 12, color: '#008aab' }}>Available</div>
        </Link>
      )}
      {soon && (
        <div style={{ fontSize: 12, color: '#008aab' }}>
          {(isBeingNotified && <span>You will be notified!</span>) || (
            <a href="#" onClick={notifyUser}>
              Notify when available!
            </a>
          )}
        </div>
      )}
    </div>
  )
}

const OfficeCard = compose(
  connect(
    state => ({
      user: state.entities.users[state.auth.user._id] || state.auth.user,
    }),
    { _notifyUser: notify }
  ),
  withHandlers({
    notifyUser: props => e => {
      e.preventDefault()
      props._notifyUser(REGISTRATION_OFFICES.find(a => a.abbr === props.abbr))
    },
  }),
  withProps(props => ({
    isBeingNotified: Boolean(
      props.user &&
        props.user.photographerFields &&
        props.user.photographerFields.campaigns &&
        props.user.photographerFields.campaigns[
          `copyrightRegistration_${props.abbr}`
        ]
    ),
  }))
)(OfficeCardComponent)

function IntroIcon({ num, width = 100 }) {
  const filename = ['1', '2', '3'].includes(num) ? `introIcon${num}.svg` : null
  if (!filename) return null
  const icon = require(`./EmptyState/${filename}`)
  return (
    <div
      css={emptyStateCardPic}
      style={{ width: `${width}px` }}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}

const matchSubmissionIntroTextCont = css`
  width: 100%;
  display: inline-block;
  margin-top: 40px;
`

const FeaturedHeader = styled.h2`
  margin: 0 auto;
  width: 80%;
  font-size: 1.7em;
  color: #008aab;
  padding-bottom: 20px;
  small {
    display: block;
    font-size: 0.4em;
    font-weight: normal;
    line-height: 3em;
    color: #008aab;
  }
`

const matchSubmissionIntroCard = css`
  width: 33.3%;
  @media (max-width: 994px) {
    width: 100%;
  }
  height: auto;
  float: left;
  padding: 0 20px;
  h3 {
    font-size: 1.2em;
    margin: 0 0 15px 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  li {
    padding-bottom: 20px;
    span {
      color: rgb(40, 40, 40);
      font-size: 1em !important;
      opacity: 0.75;
    }
  }
`

const emptyStateCardPic = css`
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 130px;
`
