import isPlainObject from 'lodash/isPlainObject'

/**
 * @typedef {import('../../CaseSubmission').FileValues} IFile
 * @typedef {import('../../CaseSubmission').ImageValues} IImage
 * @typedef {import('../../CaseSubmission').MatchValues} IMatch
 */

/**
 * @param {IFile=} file
 * @returns {boolean}
 */
export const isItLoading = file => {
  if (!file) return false
  return file.uploadStarted && !file.error
}

/**
 * @param {IFile=} file
 * @returns {boolean}
 */
export const shouldRetry = file => {
  if (!file) return false
  return !!file.error
}

/**
 * @param {IFile=} file
 * @returns {boolean}
 */
export const getPercentage = file => {
  if (!file) return null
  if (file.percentage === 0) return null

  return file.percentage
}

/**
 * @param {boolean} shouldRetry
 * @param {boolean} isLoading
 * @param {number=} percentage
 * @returns {string | null}
 */
export const getCaption = (shouldRetry, isLoading, percentage) => {
  if (shouldRetry) return 'RETRY'
  if (isLoading && percentage) return `${percentage}%`
  return null
}

/**
 * @param {IFile=} file
 * @param {IImage=} image
 * @returns {boolean}
 */
export const getImageUrl = (file, image) => {
  return (
    (file
      ? file.previewLocalUrl || file.url || image.thumbnail || image.url
      : image.thumbnail || image.url) || ''
  )
}

/**
 * @param {boolean} shouldRetry
 * @param {boolean} isLoading
 * @param {IMatch} match
 */
export const getSubTitle = (shouldRetry, isLoading, match) => {
  return shouldRetry
    ? 'UPLOAD FAILED'
    : isLoading
    ? 'Uploading'
    : match.origin.url
}

/**
 * @param {IFile=} file
 * @param {IImage=} image
 * @returns {boolean}
 */
export const getImgTitle = (file, image) => {
  return (file ? file.fileName : image.title) || 'Untitled'
}

/**
 * Get images that were directly uploaded using the `Upload` section in `Imports`
 * - when case is new, it's all images that have `image.file` object
 * - when case is editing, it's all images that have `image.case` === `caseId`
 *
 * @param {IImage[]} images
 * @param {string} caseId
 */
export const getUploadedImagesOnly = (images, caseId) => {
  return images.filter(i => isPlainObject(i.file) || i.case === caseId)
}
