import * as React from 'react'
import propTypes from 'prop-types'

const Label = ({ header, text, href, title }) => (
  <React.Fragment>
    {header && <h1>{header}</h1>}
    {text && <span title={title}>{text}</span>}
    {href && (
      <a href={href} alt={title || href} target="_blank" rel="noreferrer">
        <span style={{ direction: 'ltr' }}>{href}</span>
      </a>
    )}
  </React.Fragment>
)
Label.propTypes = {
  header: propTypes.string,
  text: propTypes.any,
  href: propTypes.string,
}

export default Label
