import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, formValues } from 'redux-form'
import {
  Button,
  TextField,
  VerticalCheckboxGroup,
  ClosedQuestionRadio,
  AlignLabels,
  VerticalRadioGroup,
} from 'common'
import { css } from 'react-emotion'
import { updateUser } from '../../../redux/modules/auth'

export function ArtistQuestionaire({
  valid,
  submitting,
  handleSubmit,
  showReportedLicensingPriceQuestion,
}) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Profile Type</h1>

      <span>Your background helps us to personalize our service.</span>
      <div css={formWrap}>
        <AlignLabels width="50%">
          <Field
            name="artist.media"
            label="What type of work are you interesting in monitoring & protecting?"
            component={VerticalCheckboxGroup}
            defaultValue={[]}
            items={[
              {
                value: '2D Art',
                label: '2D Art',
              },
              {
                value: 'Illustrations',
                label: 'Illustrations',
              },
              {
                value: 'Logos',
                label: 'Logos',
              },
              {
                value: 'Designs',
                label: 'Designs',
              },
              {
                value: 'Other',
                label: 'Other',
              },
            ]}
          />

          <Field
            name="artist.web"
            label="Are these works sold and/or posted online? If yes, where?"
            component={TextField}
          />

          <Field
            name="artist.registered"
            label="Have you registered any works at an official copyright office?"
            component={ClosedQuestionRadio}
            normalize={normalizeBoolean}
          />

          <Field
            name="_reportedLicencingPrice"
            label="Have you ever licensed an image?"
            component={ClosedQuestionRadio}
            normalize={normalizeBoolean}
          />

          {showReportedLicensingPriceQuestion && (
            <Field
              name="reportedLicensingPrice"
              label="What was the average value per license?"
              component={VerticalRadioGroup}
              items={[
                {
                  value: '<400',
                  label: 'Under $400 USD',
                },
                {
                  value: '400-750',
                  label: '$400 - $750 USD',
                },
                {
                  value: '>750',
                  label: '$750 USD or higher',
                },
              ]}
            />
          )}
        </AlignLabels>
      </div>

      <Button submit disabled={!valid || submitting}>
        Continue
      </Button>
    </form>
  )
}

const ArtistQuestionaireWrapper = reduxForm({
  form: 'ArtistQuestionaire',
  onSubmit: (
    { __reportedLicencingPrice, ...values },
    dispatch,
    { onComplete }
  ) =>
    dispatch(
      updateUser({
        ...values,
        shop: undefined,
        photographerFields: {},
      })
    ).then(onComplete),
})(
  formValues({ showReportedLicensingPriceQuestion: '_reportedLicencingPrice' })(
    ArtistQuestionaire
  )
)

export default ArtistQuestionaireWrapper

ArtistQuestionaire.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

ArtistQuestionaireWrapper.eligible = user =>
  user.type === 'artist' &&
  (!user.artist || !user.artist.media || !user.artist.media.length)

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const formWrap = css`
  width: 100%;
  padding: 15px 0 15px 0;
`

function normalizeBoolean(value) {
  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  return null
}
