import { invalidate as invalidateClusters } from '../modules/clusters'
import { CASE_DELETE_DRAFT_SUCCESS } from '../modules/cases'
import {
  invalidate as invalidateImages,
  SERVICE_REMOVE_SUCCESS,
  IMAGE_GOOGLE_SAVE_SUCCESS,
  IMAGE_DROPBOX_SAVE_SUCCESS,
  IMAGES_SYNCHRONIZE_SUCCESS,
  IMAGE_IGNORE_SUCCESS,
  IMAGE_IGNORE_BULK_SUCCESS,
  IMAGE_UNIGNORE_SUCCESS,
  IMAGE_DELETE_SUCCESS,
  IMAGE_MERGE_SUCCESS,
} from '../modules/images'
import { TAKEDOWN_FORM_SUBMIT } from '../modules/takedowns'
import {
  WEBSITE_ADD_SUCCESS,
  WEBSITE_REFRESH_SUCCESS,
  WEBSITE_DELETE_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
} from '../modules/auth'

const resetImagesAfter = [
  SERVICE_REMOVE_SUCCESS,
  IMAGE_GOOGLE_SAVE_SUCCESS,
  IMAGE_DROPBOX_SAVE_SUCCESS,
  IMAGES_SYNCHRONIZE_SUCCESS,
  IMAGE_IGNORE_SUCCESS,
  IMAGE_UNIGNORE_SUCCESS,
  IMAGE_DELETE_SUCCESS,
  WEBSITE_ADD_SUCCESS,
  WEBSITE_REFRESH_SUCCESS,
  WEBSITE_DELETE_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  IMAGE_MERGE_SUCCESS,
  IMAGE_IGNORE_BULK_SUCCESS,
  TAKEDOWN_FORM_SUBMIT,
]

const resetClustersAfter = [
  AUTH_LOGOUT_SUCCESS,
  TAKEDOWN_FORM_SUBMIT,
  CASE_DELETE_DRAFT_SUCCESS,
]

/**
 * If a user updates his images, or logs out,
 * empty the cached default view for Photos and Matches
 */
export default store => next => action => {
  if (resetImagesAfter.includes(action.type)) {
    setTimeout(() => store.dispatch(invalidateImages()), 10)
  }

  if (resetClustersAfter.includes(action.type)) {
    setTimeout(() => store.dispatch(invalidateClusters()), 10)
  }

  return next(action)
}
