import { normalize } from 'normalizr'
import { push } from 'react-router-redux'
import debugFactory from 'debug'
import { logout } from '../modules/auth'

const debug = debugFactory('clientMiddleware')
// debug.enabled = true

// Inspired by:
// https://github.com/reactjs/redux/issues/99#issuecomment-112212639
export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState)
      }

      const { promise, types, schema, check, ...rest } = action

      if (!promise) {
        return next(action)
      }
      debug('action :: ', (types && types[0]) || 'NA', action)

      const [REQUEST, SUCCESS, FAILURE] = types

      if (check && typeof check === 'function') {
        const shouldCallAPI = check(getState())
        if (!shouldCallAPI) {
          debug('[%s] Abort as Check not passed!', REQUEST)
          return void false
        } else {
          debug('[%s] Check Stage Passed')
        }
      }

      next({ ...rest, type: REQUEST, service: action.service })

      return promise(client)
        .then(response => {
          debug('[%s] response', REQUEST, response, schema)

          // Normalize responses
          let data
          if (response && schema) {
            let { entities, result } = normalize(response, schema)
            debug('[%s] Normalizer Response', REQUEST, entities, result)

            if (
              typeof result === 'string' // Sometimes the result is an id string?
            )
              result = {}

            data = {
              entities,
              ...result,
            }
          } else {
            data = response
          }

          return next({
            ...rest,
            type: SUCCESS,
            payload: Array.isArray(data)
              ? data
              : {
                  ...action.payload,
                  ...data,
                },
          })
        })
        .catch(error => {
          debug('error')

          if (error.status === 401 && !/^(Login failed\.|Password expired\.)/.test(error.message)) {
            const { auth } = getState()

            if (!auth.user || auth.loggingOut) {
              // User already logged out or is logging out, don’t show error message
              error = undefined
            } else {
              dispatch(logout())
              dispatch(push('/login'))
            }
          }
          next({
            ...rest,
            error,
            type: FAILURE,
          })
          return {
            error,
          }
        })
    }
  }
}
