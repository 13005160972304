import { Button, createContactUsHandler } from 'common'
import React from 'react'
import {
  PixsyGrid,
  PixsyGridItem,
  PixsyButtonGroup,
} from '../../../../Components'

export const ScenarioDomainStock = ({ onTakedown, showMatchActions }) => {
  return (
    <PixsyGrid alignItems="center" direction="column">
      <PixsyGridItem fullWidth>
        <h1>This match is not a viable case</h1>
      </PixsyGridItem>
      <PixsyGridItem fullWidth>
        <h4>
          Pixsy has detected that this domain matches a known stock website.
        </h4>
      </PixsyGridItem>
      <PixsyGridItem fullWidth>
        <p>
          Individual users post content on stock websites, and infringements on
          stock websites are therefore classified as user-submitted content. As
          Pixsy does not pursue cases against individuals, matches on stock
          websites are not accepted for Pixsy’s Case Resolution Services.
        </p>
        {showMatchActions && (
          <p>
            We recommend that you <b>send a Takedown Notice</b> for this match,
            requesting the image is removed by the website operator or host.
          </p>
        )}
        <p>
          Pixsy uses a set of algorithms to pre-determine if a case is suitable
          for resolution. We do this to save you time and to ensure that our
          case management team can focus their efforts on recovering
          compensation for viable cases.
        </p>
      </PixsyGridItem>

      <PixsyGridItem fullWidth>
        {(!showMatchActions && (
          <PixsyButtonGroup
            itemStyle={{ justify: 'center', style: { display: 'flex' } }}
          >
            <Button
              full
              onClick={createContactUsHandler('Stock Domain')}
              greyOutline
            >
              Contact us
            </Button>
          </PixsyButtonGroup>
        )) || (
          <PixsyButtonGroup
            itemStyle={{ justify: 'center', style: { display: 'flex' } }}
          >
            <Button
              full
              onClick={createContactUsHandler('Stock Domain')}
              greyOutline
            >
              Contact us
            </Button>
            <Button full hoverMain onClick={onTakedown}>
              Send Takedown
            </Button>
          </PixsyButtonGroup>
        )}
      </PixsyGridItem>
    </PixsyGrid>
  )
}
