import React from 'react'
import PropTypes from 'prop-types'
import { uniqBy } from 'lodash'
import { css } from 'react-emotion'
import { resolveCountryNameForCode } from 'pixsy-constants'
import { ChooseBestImageSource } from 'common'

RegistrationHighlights.propTypes = {
  editable: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  leftColumnProps: PropTypes.array.isRequired,
  rightColumnProps: PropTypes.array,
}

RegistrationHighlights.defaultProps = {
  editable: false,
}

const transform = ({ label, value }) => {
  if (label === 'Country' && value.length === 2)
    value = resolveCountryNameForCode(value)
  return { label, value }
}

export default function RegistrationHighlights({
  leftColumnProps,
  rightColumnProps,
  imageLabel,
  images,
  editable,
  leftColumnTitle,
}) {
  return (
    <div css={wrap}>
      <div>
        {leftColumnTitle && <h5>{leftColumnTitle}</h5>}
        <dl>
          {leftColumnProps
            .filter(x => x.value)
            .map(transform)
            .map(({ label, value }) => (
              <React.Fragment key={label}>
                <dt>{label}</dt>
                <dd>{value}</dd>
              </React.Fragment>
            ))}
          {/* <dt>Domain</dt>
          <dd>
            <b>{cluster.domain.host}</b>
          </dd>
          {cluster.domain.country && <dt>Country</dt>}
          {cluster.domain.country && <dd>{cluster.domain.country}</dd>}
          {cluster.domain.alexa &&
            cluster.domain.alexa.category && <dt>Category</dt>}
          {cluster.domain.alexa &&
            cluster.domain.alexa.category && (
              <dd>{cluster.domain.alexa.category}</dd>
            )} */}
          {/* <dt>Type</dt>
            <dd>
              {cluster.domain.commerciality_ranking >= 0
                ? 'Commercial'
                : 'Uncommercial'}
            </dd> */}
        </dl>
      </div>
      <div>
        <div css={matchPreviewContainer}>
          {rightColumnProps && (
            <dl>
              {rightColumnProps
                .filter(x => x.value)
                .map(({ label, value }) => (
                  <React.Fragment key={label}>
                    <dt>{label}</dt>
                    <dd>{value}</dd>
                  </React.Fragment>
                ))}
            </dl>
          )}

          <div css={previewSection}>
            <h5>{imageLabel}</h5>

            <ul>
              {uniqBy(images, 'url')
                .slice(0, 9)
                .map(i => (
                  <ChooseBestImageSource key={i._id} candidates={[i.url]}>
                    {({ url }) => (
                      <li
                        key={i._id}
                        style={{
                          backgroundImage: `url(${url})`,
                        }}
                      />
                    )}
                  </ChooseBestImageSource>
                ))}
            </ul>
            {/*editable */ false && (
              <p>
                Remove matches you don‘t want to include in this case by
                hovering over the match and selecting remove.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const wrap = css`
  margin-top: 40px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  > div {
    flex-basis: 50%;
  }
  h5 {
    text-transform: uppercase;
    color: #999;
    font-size: 0.9em;
    letter-spacing: 3px;
    margin-left: 8px;
    text-transform: uppercase;
    color: #9d9d9d;
    margin: 0;
  }

  dl {
    margin-top: 20px;

    dt {
      color: #b2b2bd;
      font-size: 0.7em;
      text-transform: uppercase;
      letter-spacing: 0.25em;
    }
    dd {
      margin-top: -5px;
      font-size: 1.3em;
      margin-bottom: 8px;
    }
  }
`

const matchPreviewContainer = css`
  ul {
    padding: 0;
    margin-left: -8px;
  }
  li {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  }

  p {
    margin-left: 8px;
    font-size: 0.9em;
    font-weight: bold;
    color: #999;
  }
`

const previewSection = css`
  margin-top: -8px;
`
