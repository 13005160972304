import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import styled, { css, cx } from 'react-emotion'
import { reduxForm, submit, Field } from 'redux-form'
import { PathSelectionSlider } from 'common'
import { connect } from 'react-redux'
import { create } from '../../../../redux/modules/rules'
import { notifSend } from 'redux-notifications/lib/actions'
import {
  getClusterById,
  assembleClustersAndTotal,
} from '../../../../redux/selectors'

export class AddIgnoreRuleDialog extends Component {
  constructor() {
    super()

    this.renderApprovedRadio = this.radioButtonFactoryFor('approved')
    this.renderIgnoredRadio = this.radioButtonFactoryFor('ignored')
  }

  radioButtonFactoryFor = type => ({ input }) => (
    <label htmlFor={'checkbox-' + type} css={label}>
      <input
        type="radio"
        className={styledCheckbox}
        {...input}
        id={'checkbox-' + type}
      />
      {type === 'approved' ? (
        <Fragment>
          <IconApproved />
          Approved Usage
        </Fragment>
      ) : (
        <Fragment>
          <IconInvalid />
          Ignore
        </Fragment>
      )}
    </label>
  )

  renderPathSelectionSlider = props => {
    const { white } = this.props
    return (
      <PathSelectionSlider white={white} url={this.props.matchUrl} {...props} />
    )
  }

  render() {
    const { loading, white, matchesCount, host } = this.props
    return (
      <div className={cx(!white && wrap, white && wrapWhite, 'viewer-modal')}>
        <form>
          <h4>Ignore all matches on URL</h4>
          {matchesCount && (
            <span css={subTitle}>Including 8 matches found in this group</span>
          )}
          <span>
            Use the slider to control which matches on {host} you want to take
            action on.
            <br />
            This action be applied to all present and future matches that match
            this pattern.
          </span>

          <Field
            name="fragment"
            type="text"
            component={this.renderPathSelectionSlider}
          />

          <div className={cx(buttonGroup, white && buttonGroupWhite)}>
            <Field
              name="setting"
              value="approved"
              type="radio"
              component={this.renderApprovedRadio}
              disabled={loading}
            />

            <Field
              name="setting"
              value="ignored"
              type="radio"
              component={this.renderIgnoredRadio}
              disabled={loading}
            />
          </div>

          <BottomNote className={cx(white && bottomNoteWhite)}>
            You can manage your <i>Ignored Domains</i> from your account
            settings.
          </BottomNote>
        </form>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { clusterId } = props

  // get clusters on current page and on same host
  // pass ids via dialog request to API so we can return updated
  // ignored state for sibling matches on the same host as well
  const { clusters } = assembleClustersAndTotal(state, props)

  const selectedCluster = getClusterById(state.entities, clusterId)

  const relatedClusterIds = clusters
    .filter(c => c.domain._id === selectedCluster.domain._id)
    .map(c => c._id)

  return {
    relatedClusterIds,
    host: selectedCluster.domain.host,
    loading: state.rules.loading,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'pathDialog',
      initialValues: { setting: '' },
      onChange: function onChange(values, dispatch) {
        if (values.setting) dispatch(submit('pathDialog', values))
      },
      onSubmit: (
        values,
        dispatch,
        {
          clusterId,
          onClose,
          onSuccess,
          relatedClusterIds,
          host,
          queueAsyncAction,
        }
      ) => {
        const verb = values.setting === 'ignored' ? 'Ignoring' : 'Approving'
        dispatch(
          notifSend({
            kind: 'info',
            message: `${verb} matches on ${host}.`,
            dismissAfter: 10e3,
          })
        )
        onClose()

        const callback = () =>
          dispatch(
            create(
              {
                ...values,
                cluster: clusterId,
              },
              relatedClusterIds
            )
          )

        const promise =
          typeof queueAsyncAction === 'function'
            ? queueAsyncAction(callback)
            : callback()

        return promise.then(resp => {
          onSuccess && onSuccess(resp)
        }, onClose)
      },
    })(AddIgnoreRuleDialog)
  )
)

AddIgnoreRuleDialog.propTypes = {
  matchUrl: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  relatedClusterIds: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  white: PropTypes.bool,
}

const styledCheckbox = css`
  position: absolute;
  top: -40px;
  /* opacity: 0; */
`
const BottomNote = styled.p`
  margin: 25px auto 15px;
  font-size: 0.9em;
  color: #ffffff80;
`
const bottomNoteWhite = css`
  color: rgba(3, 3, 3, 0.5);
`

const iconInvalid = css`
  fill: none;
  stroke: #000000;
  stroke-width: 2px;
`

const iconApproved = css`
  fill: none;
  stroke: #000000;
  stroke-width: 2px;
  opacity: 0.6;
`

const label = css`
  appearance: none;
  cursor: pointer;
`

const wrap = css`
  padding: 40px 90px 30px;
  text-align: center;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  h4 {
    color: #fff;
  }

  span {
    color: rgba(255, 255, 255, 0.75);
  }
`
const wrapWhite = css`
  padding: 36px 68px 46px;
  text-align: center;
  background-color: #fff;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  h4 {
    font-weight: 600;
    font-size: 43px;
    color: #414141;
  }

  span {
    color: rgba(147, 147, 147, 0.75);
  }
`

const subTitle = css`
  color: #101010 !important;
  display: block;
  font-size: 12px;
  margin-bottom: 8px;
  margin-top: -3px;
  text-transform: uppercase;
`

const buttonGroup = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 532px;

  @media (max-width: 768px) {
    width: 100%;
  }

  input[type='radio'] {
    opacity: 0;
  }

  label {
    background-color: #e8e8e8;
    border: 0;
    flex: 1;
    font-size: 1em;
    font-weight: 400;
    height: 83px;
    letter-spacing: 2px;
    margin: 4px;
    outline: 0;
    padding-top: 10px;
    text-transform: uppercase;

    svg {
      display: block;
      margin: 10px auto 5px;
      height: 20px;
    }

    @media (max-width: 768px) {
      font-size: 0.7em;

      svg {
        margin: 10px auto 15px;
      }
    }
  }
`
const buttonGroupWhite = css`
  label {
    background-color: #ffffff;
    border: 1px solid #dedede;
  }
`

function IconApproved() {
  return (
    <svg viewBox="-891.201 2203.65 28.917 21.469">
      <path
        css={iconApproved}
        d="M6573.778,3228.232l7.777,7.626,8.247-8.086L6601.3,3216.5"
        transform="translate(-7464.279 -1012.14)"
      />
    </svg>
  )
}

function IconInvalid() {
  return (
    <svg viewBox="-568.4 2203.442 22.015 22.016">
      <g transform="translate(-1886 1615)">
        <path
          css={iconInvalid}
          d="M20396.156,2444l20.6,20.6"
          transform="translate(-19077.85 -1854.849)"
        />
        <path
          css={iconInvalid}
          d="M0,0,20.6,20.6"
          transform="translate(1318.307 609.745) rotate(-90)"
        />
      </g>
    </svg>
  )
}
