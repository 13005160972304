import * as React from 'react'
import { css } from 'react-emotion'

export default function ResolveFooter() {
  return (
    <footer css={wrapFooter}>
      All payments are processed by an external secure service provider (Stripe
      or PayPal). No credit card information is stored in Pixsy.
      <br />
      Once all payments are completed, a signed license will be generated and sent
      to your email.
    </footer>
  )
}
const wrapFooter = css`
  color: #3b3b3baa;
  padding: 20px 40px;
  line-height: 30px;
  position: relative;
  text-align: center;
`
