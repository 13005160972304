import { Button } from 'common'
import React from 'react'
import { css } from 'react-emotion'
import {
  PixsyGrid,
  PixsyGridItem,
  PixsyButtonGroup,
} from '../../../../Components'

export const ScenarioMatchOriginOffline = ({ onIgnore, url }) => {
  return (
    <PixsyGrid alignContent="stretch" direction="column" alignItems="center">
      <PixsyGridItem fullWidth>
        <h4>Confirm online status</h4>
        <p>
          Pixsy has detected that the match image file does not link to an
          online/active image. This may occur if the image has been removed
          since the last we scanned, or if there are too many redirects for our
          system to accurately perform a check. Please check that this matched
          image is still online before submitting this case
          {/*action.toLowerCase()*/}.
        </p>
      </PixsyGridItem>

      <PixsyGridItem style={{ textAlign: 'center' }}>
        <b>Image URL:</b>
      </PixsyGridItem>
      <PixsyGridItem fullWidth>
        <ul css={listStyle}>
          <li>
            {url ? (
              <a target="_blank" href={url} rel="noreferrer">
                {url}
              </a>
            ) : (
              <p>Url field is blank</p>
            )}
          </li>
        </ul>
      </PixsyGridItem>
      <PixsyGridItem>
        <PixsyButtonGroup>
          <Button
            full
            onClick={() =>
              (window.location.href = `mailto:support@pixsy.com?subject=Blocked by: Matched image unavailable&body=##### Do not edit ####${'<br/>'}URL: ${
                window.location.href
              }${'<br/>'}${'<br/>'}Blocked by: Matched image unavailable${'<br/>'}${'<br/>'}We aim to respond to all queries within 48 hours. Please leave this information intact and write your question below the line.${'<br/>'}###################`)
            }
            grey
          >
            Contact us
          </Button>
        </PixsyButtonGroup>
      </PixsyGridItem>
    </PixsyGrid>
  )
}

const listStyle = css`
  text-align: center;
  list-style-type: none;
  padding-left: 0;
  li {
    &::before {
      display: inline;
      content: '-  ';
    }
  }
  a,
  p {
    display: inline;
  }
`
