import React from 'react'
import { css } from 'react-emotion'

export default function ResolveHeader() {
  return (
    <div className={wrap}>
      <h3>Pixsy Case Portal</h3>
      <p>
        Pixsy is the authorized agent for artists and copyright owners from
        around the world.
      </p>
      <p>
        Copyright infringement puts the livelihood of artists and creatives in
        jeopardy. For many, licensing their work is their main source of income.
        At Pixsy, our goal is to promote good copyright practices in a fair and
        friendly manner, and to protect the legal rights of our creators,
        photographers, and artists. If you received a communication and invoice
        from us in regards to an outstanding license, please review and complete
        your payment below.
      </p>
      <p>
        <b>
          For more information visit our{' '}
          <a href="https://www.pixsy.com/academy/copyright-infringement-faq/">
            FAQ Page
          </a>
        </b>
      </p>
      <div css={steps}>
        <div css={step}>
          <h4>Step 1</h4>
          <p>Review all the details of the case & evidence</p>
        </div>
        <PathArrow />
        <div css={step}>
          <h4>Step 2</h4>
          <p>Purchase a license via our secure payment processor</p>
        </div>
        <PathArrow />
        <div css={step}>
          <h4>Step 3</h4>
          <p>A valid license will be generated, signed and emailed to you</p>
        </div>
      </div>
    </div>
  )
}

const wrap = css`
  text-align: center;
  max-width: 720px;
  margin: 80px auto 30px;
`

const steps = css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 20px auto;
`

const step = css`
  flex-basis: 33.3%;
  h4 {
    font-size: 1.4em;
    font-weight: 400;
    margin-bottom: 5px;
  }
  p {
    line-height: 1.5em;
  }
`

const arrow = css`
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  svg {
    width: 100%;
  }
`

function PathArrow() {
  const arrowIcon = require('./arrowIcon.svg')
  return <div css={arrow} dangerouslySetInnerHTML={{ __html: arrowIcon }} />
}
