import React from 'react'
import PropTypes from 'prop-types'
import { StickyFloater } from 'common'
import { css, cx } from 'react-emotion'
import QueryBarSearch from './QueryBarSearch'
import { withRouter } from 'react-router'
import QueryPill from '../../Matches/V2/QueryBar/QueryPill'

function QueryBarFloater({ tags, location, history }) {
  const iconSearch = require('../../Matches/V2/QueryBar/searchIcon.svg')
  const iconClose = require('../../Matches/V2/QueryBar/closeIcon.svg')
  const iconBack = require('../../Matches/V2/QueryBar/backIcon.svg')

  return (
    <StickyFloater navigation={false}>
      <div className={queryBarWrap}>
        {tags.length ? (
          <button
            onClick={history.goBack}
            dangerouslySetInnerHTML={{ __html: iconBack }}
            className={cx(button, buttonBack)}
          />
        ) : (
          <button
            onClick={() => {} /* TBD ? */}
            dangerouslySetInnerHTML={{ __html: iconSearch }}
            className={cx(button, buttonSearch)}
          />
        )}

        <div className={flex}>
          {tags.map((t, idx) => (
            <QueryPill
              key={t}
              tag={t}
              onClick={() => {
                const newTags = [
                  ...tags.slice(0, idx),
                  ...tags.slice(idx + 1, tags.length),
                ]
                const path = newTags.length === 0 ? '' : '/' + newTags.join('/')
                const destination = `/images${path}`
                history.push(destination)
              }}
            />
          ))}
          <div css={searchPill}>
            <QueryBarSearch
              activeTags={tags}
              location={location}
              history={history}
            />
          </div>
        </div>

        <button
          onClick={() => history.push('/images')}
          dangerouslySetInnerHTML={{ __html: iconClose }}
          className={cx(button, buttonClose)}
        />
      </div>
    </StickyFloater>
  )
}

export default withRouter(QueryBarFloater)

QueryBarFloater.propTypes = {
  location: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
}

const flex = css`
  display: flex;
  height: 54px;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 768px) {
    background-color: white;
  }
`

const searchPill = css`
  flex: 1;
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: none;
    height: 54px;

    input {
      height: 50px;
    }
  }
`

const queryBarWrap = css`
  width: 100%;
  position: relative;
  height: 54px;

  @media (max-width: 768px) {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    width: auto;
    z-index: 1;
  }
`

const button = css`
  position: absolute;
  width: 54px;
  height: 54px;
  top: 0;
  border: 0;
  padding: 0;
  line-height: 13px;
`

const buttonSearch = css`
  background-color: #008aab;
  left: -54px;

  svg {
    height: 44px;
    width: 44px;
  }
`
const buttonBack = css`
  background-color: #008aab;
  left: -54px;
  position: fixed !important;
  svg {
    height: 24px;
    width: 24px;
  }
  @media (max-width: 768px) {
    width: 95px;
    height: 48px;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 30;
  }
`

const buttonClose = css`
  background-color: #383838;
  right: -54px;

  svg {
    height: 20px;
    width: 20px;
  }

  @media (max-width: 768px) {
    right: 0;
  }
`
