import React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { DATA_ELEVIO } from 'pixsy-constants'
import qs from 'querystring'
import { getClusterQueryTags } from '../../../../redux/selectors'

const high = css`
  background-color: #87e699;
`

const medium = css`
  background-color: #fff4a8;
`

const low = css`
  background-color: #c1c1c1;
`
const styles = {
  low,
  medium,
  high,
}

function mapStateToProps(state, props) {
  const tags = getClusterQueryTags(state, props)

  let similarityTag = tags.find(t => t.startsWith('similarity'))

  const settings = !similarityTag
    ? SimilarityControl.ALL_SETTINGS
    : similarityTag.split(':')[1].split('+')
  // ^ e.g /matches/similarity:low+medium/moretags..

  return {
    settings,
  }
}

const createToggleLink = (currentSettings, newSetting) => {
  const regExpMatches = location.pathname.match(/similarity:([a-z+]+)/)

  /***************/
  var newSettings
  if (!currentSettings.includes(newSetting)) {
    newSettings = [...currentSettings, newSetting]
  } else if (currentSettings.length === 3) {
    newSettings = [newSetting]
  } else if (
    currentSettings.length === 1 &&
    currentSettings[0] === newSetting
  ) {
    newSettings = SimilarityControl.ALL_SETTINGS
  } else {
    const removeExisting = s => s !== newSetting
    newSettings = currentSettings.filter(removeExisting)
    if (newSettings.length === 0) {
      newSettings = SimilarityControl.ALL_SETTINGS.filter(removeExisting)
    }
  }

  /***************/

  const sortByLowOverMediumToHigh = (a, b) => {
    const mapping = {
      low: 0,
      medium: 1,
      high: 2,
    }
    return mapping[b] < mapping[a] ? 1 : mapping[b] > mapping[a] ? -1 : 0
  }
  let pathname
  const serialize = settings =>
    `/similarity:${settings.sort(sortByLowOverMediumToHigh).join('+')}`

  if (newSettings.length === 3) {
    // When all shall be enabled, remove similarity config from pathname
    pathname = location.pathname.replace(/(\/similarity:[a-z+]+)/, '')
  } else {
    if (regExpMatches) {
      // If we have a current config, we need to update it
      pathname = location.pathname.replace(
        /\/similarity:[a-z+]+/,
        serialize(newSettings)
      )
    } else {
      // if we don‘t have a current config, but need one: append it
      pathname = location.pathname + serialize(newSettings)
    }
  }

  // reset page but keep other parameters
  const search = qs.stringify({
    ...qs.parse(String(location.search).substr(1)),
    page: 0,
  })

  return {
    ...location,
    pathname,
    search,
  }
}

SimilarityControl.propTypes = {
  togglePanel: PropTypes.func.isRequired,
  settings: PropTypes.array.isRequired,
}

function SimilarityControl({ settings, togglePanel }) {
  return (
    <div css={label} data-elevio-selector={DATA_ELEVIO.MATCHES_ACCURACY_FILTER}>
      <b>Accuracy</b>
      <div>
        {['High', 'Medium', 'Low'].map(capitalized => {
          const lowercased = capitalized.toLowerCase()

          return (
            <Link
              key={lowercased}
              to={createToggleLink(settings, lowercased)}
              css={cx(control, {
                [active]: settings.length < 3 && settings.includes(lowercased),
              })}
            >
              <i css={cx(indicator, styles[lowercased])} />
              <span>{capitalized}</span>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

SimilarityControl.ALL_SETTINGS = ['low', 'medium', 'high']

export default withRouter(connect(mapStateToProps)(SimilarityControl))

const label = css`
  text-transform: uppercase;
  text-align: right;
  font-weight: 500px;
  margin-bottom: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  color: rgb(180, 180, 180);
  font-size: 0.9em;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.8em;
    b {
      font-weight: 400;
      padding-bottom: 5px;
    }
  }
  b {
    font-weight: 400;
  }
`

const control = css`
  display: inline-block;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  padding: 3px 7px 3px 7px;
  margin-left: 5px;
  opacity: 0.6;
  background-color: transparent;
  color: rgb(30, 30, 30);
  border: solid 1px;
  border-color: transparent;
  &:active {
    color: rgb(30, 30, 30);
  }
  &:visited {
    color: rgb(30, 30, 30);
  }
  &:link {
    color: rgb(30, 30, 30);
  }
  &:hover {
    color: rgb(10, 10, 10);
    opacity: 1;
  }
  @media (max-width: 768px) {
    span {
      display: none;
    }
  }
`

const indicator = css`
  height: 17px;
  width: 17px;
  border-radius: 17px;
  margin-right: 8px;
`

const active = css`
  opacity: 1;
  color: rgb(10, 10, 10);
  border-color: rgb(230, 230, 230);

  span {
    display: inline-block;
  }
`
