import React, { useCallback, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { ActionButton } from 'common'

import { CrossIcon, FlagIconV2, RefreshIcon, ThreeDotsIcon, WhiteCheckIcon, WhiteMagnifyingGlassIcon } from './Icons'

const ButtonsWrapper = ({
  isGroupView,
  isMultiMatch,
  isFlagged,
  isPixsyFlagged,
  onOpenMoreModal,
  isMatchIgnored,
  onOpenGroupClick,
  openTakeActionModal,
  onIgnoreRequest,
  onFlagClusterRequest,
  onUndoIgnoreMatchRequest,
  caseId,
  takedownId,
}) => {
  const [_isPixsyFlagged, setIsPixsyFlagged] = useState(isPixsyFlagged)
  const [_isFlagged, setIsFlagged] = useState(isFlagged)

  const isSharkMode = Boolean(localStorage.getItem('pixsy-shark'))
  const handleFlag = useCallback(() => {
    onFlagClusterRequest()
    if (isSharkMode) {
      setIsPixsyFlagged((state) => !state)
    } else {
      setIsFlagged((state) => !state)
    }
  }, [onFlagClusterRequest, isSharkMode, setIsPixsyFlagged])

  const disableTakeAction = !!caseId || !!takedownId

  return (
    <div className={styles.wrapper}>
      {isMatchIgnored ? (
        <ActionButton
          onClick={onUndoIgnoreMatchRequest}
          Icon={RefreshIcon}
          text="UNDO IGNORE"
          className={styles.undoButton}
        />
      ) : (
        <Fragment>
          <div className={`${styles.offsetRight}`}>
            {!isGroupView && (
              <Fragment>
                <div onClick={handleFlag} className={`${styles.userFlag} ${_isFlagged ? 'flagged' : ''}`}>
                  <FlagIconV2 />
                </div>
                {_isPixsyFlagged && (
                  <div className={`${styles.pixsyFlag}`}>
                    <FlagIconV2 />
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {disableTakeAction ? (
            <Fragment>
              {!isGroupView && (
                <Fragment>
                  {caseId && (
                    <Link to={`/cases/${caseId}`}>
                      <ActionButton
                        Icon={() => <WhiteMagnifyingGlassIcon width={18} height={18} />}
                        text="OPEN CASE"
                        className={styles.orangeButton}
                      />
                    </Link>
                  )}
                  {takedownId && (
                    <Link to="/takedowns">
                      <ActionButton
                        Icon={() => <WhiteMagnifyingGlassIcon width={18} height={18} />}
                        text="OPEN TAKEDOWNS"
                        className={styles.orangeButton}
                      />
                    </Link>
                  )}
                </Fragment>
              )}
            </Fragment>
          ) : isMultiMatch ? (
            <Fragment>
              <ActionButton
                onClick={onOpenGroupClick}
                Icon={() => <WhiteMagnifyingGlassIcon width={18} height={18} />}
                text="OPEN GROUP"
                className={styles.orangeButton}
              />
              <ActionButton onClick={onIgnoreRequest} Icon={CrossIcon} text="IGNORE" className={styles.whiteButton} />
            </Fragment>
          ) : (
            <Fragment>
              {!isGroupView && (
                <ActionButton
                  onClick={openTakeActionModal}
                  Icon={WhiteCheckIcon}
                  text="TAKE ACTION"
                  className={styles.orangeButton}
                />
              )}
              {!disableTakeAction && (
                <ActionButton
                  onClick={onIgnoreRequest}
                  Icon={CrossIcon}
                  text="IGNORE"
                  className={styles.whiteButton}
                  data="here"
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      {!disableTakeAction && (
        <ActionButton
          onClick={onOpenMoreModal}
          Icon={ThreeDotsIcon}
          text="MORE"
          className={styles.whiteButton}
          style={{ borderTop: '0.5px #ebebeb solid' }}
        />
      )}
    </div>
  )
}

ButtonsWrapper.propTypes = {
  isMultiMatch: PropTypes.bool,
  isMatchIgnored: PropTypes.bool,
  additionalClassName: PropTypes.string,

  onOpenGroupClick: PropTypes.func,
  openTakeActionModal: PropTypes.func,
  onIgnoreMatchRequest: PropTypes.func,
  onUndoIgnoreMatchRequest: PropTypes.func,
  onOpenMoreModal: PropTypes.func,
}

const styles = {
  wrapper: css`
    position: relative;
    display: flex;
    flex-direction: column;
  `,
  undoButton: css`
    width: 100px;
    height: 80px;
  `,
  orangeButton: css`
    background-color: #ffa168;
    color: white;
    border: 0;
    width: 100px;
    height: 80px;
  `,
  whiteButton: css`
    border: 0;
    width: 100px;
    height: 84px;
  `,
  offsetRight: css`
    position: absolute;
    top: 0;
    left: calc(100% + 5px);
  `,
  userFlag: css`
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;

      g {
        opacity: 0.2;
        stroke-width: 0.5;
        stroke: #000000;
      }

      path {
        stroke-width: 0.5;
        stroke: #000000;
      }
    }

    &.flagged {
      svg {
        g {
          opacity: 1;
          fill: #ffa168;
          stroke: #ffa168;
        }

        path {
          fill: #ffa168;
          stroke: #ffa168;
        }
      }
    }
  `,
  pixsyFlag: css`
    svg {
      width: 20px;
      height: 20px;
      g,
      path {
        fill: #008aab;
        stroke: #008aab;
      }
    }
  `,
}

export default ButtonsWrapper
