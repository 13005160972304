import { Button, Dialog } from 'common'
import isFunction from 'lodash/isFunction'
import React, { useCallback, useState } from 'react'
import { css } from 'react-emotion'
import { compose, branch, renderNothing, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { deleteDraftCase as deleteDraftAction } from '../../../redux/modules/cases'

const DeleteDraft = ({ reload, caze, delCase, title, afterDelete, buttonProps = {} }) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const defaultButtonProps = {
    withFocusOutline: true,
    domProps: { style: { border: '1px solid hsl(0, 0%, 95%)' } },
  }

  return (
    <React.Fragment>
      <Button onClick={() => setIsModalOpen(!isModalOpen)} {...Object.assign({}, defaultButtonProps, buttonProps)}>
        {title || 'Delete'}
      </Button>
      <Dialog isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} wide>
        <ConfirmDeleteModal
          caze={caze}
          processing={isDeleting}
          onCancel={() => {
            setIsModalOpen(false)
            setIsDeleting(false)
          }}
          onRemove={() => {
            setIsDeleting(true)
            delCase(caze).then( () => {
              typeof afterDelete === 'function' && afterDelete()
            })
          }}
        />
      </Dialog>
    </React.Fragment>
  )
}
export default compose(
  branch(
    (props) => !props.caze || props.caze.submission.__DRAFT__ !== true || (props.caze.cm && props.caze.cm.changeRequested === true),
    renderNothing
  ),
  branch((props) => props.caze.submission.version !== '6.0', renderNothing),
  connect(null, { deleteDraft: deleteDraftAction }),
  withHandlers({
    delCase: ({ deleteDraft, caze, query }) => () => deleteDraft(caze, query),
  })
)(DeleteDraft)

function ConfirmDeleteModal({ onRemove, onCancel, caze, processing }) {
  const handleRemove = useCallback(() => {
    if (isFunction(onRemove)) onRemove(caze._id)
  }, [onRemove])

  const handleCancel = useCallback(() => {
    if (isFunction(onCancel)) onCancel()
  }, [onCancel])

  return (
    <div css={content}>
      <h1>Delete Draft Case {caze.reference}?</h1>

      <p>Caution, you are about to delete this case.</p>

      <p>This action can not be undone.</p>

      <div css={buttons}>
        <Button hoverMain disabled={processing} onClick={handleCancel}>
          Cancel
        </Button>

        <Button danger disabled={processing} onClick={handleRemove}>
          {processing ? 'Deleting...' : 'Yes, Delete Case'}
        </Button>
      </div>
    </div>
  )
}

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    text-align: center;
  }
`
