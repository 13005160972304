import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { css, cx } from 'react-emotion'
import { Field } from 'redux-form'
import filesize from 'file-size'
import FormRow from './FormRow'
import { Dialog } from 'common'
import WideFileRemoveDialog from './WideFileRemoveDialog'

export default class WideFileUpload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialogOpen: false,
    }
  }

  renderList(fields) {
    return (
      <ul>
        {fields.map((field, index) => (
          <li key={index}>
            <Field key={index} name={field} component={Item} />
            <i
              onClick={() =>
                this.setState({ dialogOpen: true, deleteIndex: index })
              }
            >
              x
            </i>
          </li>
        ))}
      </ul>
    )
  }

  handleChange(filesToUpload) {
    const {
      accept,
      afterChange,
      fields,
      maxNumberOfFiles,
      maxTotalSize,
      meta,
      notifSend,
    } = this.props
    const currentFiles = fields.getAll() || []
    if (
      maxNumberOfFiles &&
      filesToUpload.length + currentFiles.length > maxNumberOfFiles
    ) {
      notifSend({
        kind: 'warning',
        message: `The maximum number of files is ${maxNumberOfFiles}`,
        dismissAfter: 10e3,
      })
      return
    } else if (maxTotalSize) {
      let currentTotalSize = 0
      currentFiles.forEach(file => (currentTotalSize += file.size))
      let filesToUploadTotalSize = 0
      filesToUpload.forEach(file => (filesToUploadTotalSize += file.size))

      if (currentTotalSize + filesToUploadTotalSize >= maxTotalSize) {
        notifSend({
          kind: 'warning',
          message: `The maximum total size is ${filesize(maxTotalSize, {
            fixed: 0,
          }).human()}`,
          dismissAfter: 10e3,
        })
        return
      }
    }

    const newFiles = filesToUpload.filter(file => {
      if (accept) {
        // double check extension
        // accept should be an array of mime types
        // dropzone should take care of it but this is a double check
        const acceptedExtensions = accept.map(extension =>
          extension.split('/')[1].toUpperCase()
        )

        const extension = file.name.split('.').pop()
        if (
          !acceptedExtensions.find(
            element => element === extension.toUpperCase()
          )
        ) {
          notifSend({
            kind: 'warning',
            message: `Invalid file extension in file "${file.name}", only image/jpg, image/jpeg, image/gif, image/png`,
            dismissAfter: 10e3,
          })
          return false
        }
      }
      // check for duplicate file names
      const index = currentFiles.findIndex(f => f.name === file.name)
      if (index === -1) {
        return true
      } else {
        notifSend({
          kind: 'warning',
          message: `Duplicate file "${file.name}" skipped, please rename it and try again`,
          dismissAfter: 10e3,
        })
        return false
      }
    })
    newFiles.forEach(file => fields.push(file))

    if (global.E2E) {
      // testcafe proxies uploads with hammerhead
      // the base64 encoding that is included breaks our upload
      // @see https://github.com/DevExpress/testcafe-hammerhead/issues/1304#issuecomment-328758747
      newFiles.forEach(file => delete file.base64)
    }
    afterChange && afterChange(meta.dispatch)(newFiles.map(x => ({ ...x })))
  }

  handleConfirm() {
    this.props.fields.remove(this.state.deleteIndex)
    this.setState({ dialogOpen: false })
  }

  handleCancel() {
    this.setState({ dialogOpen: false })
  }

  render() {
    const {
      name,
      fields,
      meta = {},
      label,
      renderFeedback,
      highlighted,
      accept,
      editing,
      maxNumberOfFiles,
      maxTotalSize,
      removeDialogTitle,
      removeDialogDescription,
    } = this.props

    if (!fields) {
      // sometimes fields come undefined (redux-form)
      return null
    }

    const showWarning = editing || meta.touched

    const fileNumberLimitReached =
      maxNumberOfFiles && fields.length >= maxNumberOfFiles
    let sizeLimitReached = false
    if (maxTotalSize) {
      let currentSize = 0
      fields.forEach(file => (currentSize += file.size))
      sizeLimitReached = currentSize >= maxTotalSize
    }

    let buttonLabel = renderFeedback(fields)
    const isDisabled = fileNumberLimitReached || sizeLimitReached
    if (isDisabled) {
      if (fileNumberLimitReached) {
        buttonLabel = `Only ${maxNumberOfFiles} files allowed`
      } else {
        buttonLabel = `Total size limit is ${maxTotalSize}`
      }
    }

    return (
      <FormRow meta={meta} showWarnings={showWarning} highlighted={highlighted}>
        <Dialog
          isOpen={this.state.dialogOpen}
          onRequestClose={() => this.handleCancel()}
        >
          <WideFileRemoveDialog
            title={removeDialogTitle}
            description={removeDialogDescription}
            onCancel={() => this.handleCancel()}
            onConfirm={() => this.handleConfirm()}
          />
        </Dialog>
        <div css={wrap}>
          <div css={wrapDescriptionButton}>
            <div css={description}>
              <label>{label}</label>
              {showWarning && (meta.error || meta.warning) && (
                <div className={feedbackContainer}>
                  <p>{meta.error || meta.warning}</p>
                </div>
              )}
            </div>
            <div className={actionArea}>
              <Dropzone
                className={cx(dropzone, isDisabled && disabled)}
                name={name}
                onDrop={filesToUpload => this.handleChange(filesToUpload)}
                accept={accept}
              >
                {buttonLabel}
              </Dropzone>
            </div>
          </div>
          <div css={list}>{fields.length > 0 && this.renderList(fields)}</div>
        </div>
      </FormRow>
    )
  }
}

WideFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  afterChange: PropTypes.func,
  renderFeedback: PropTypes.func.isRequired,
  accept: PropTypes.array,
  maxNumberOfFiles: PropTypes.number,
  maxTotalSize: PropTypes.number,
}

function Item({ input }) {
  return (
    <span>{`${input.value.name} - ${filesize(input.value.size).human()}`}</span>
  )
}

const wrap = css`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const list = css`
  i {
    cursor: pointer;
    margin-left: 5px;
    color: red;
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;
    color: #333;
    margin-bottom: 8px;
    opacity: 0.6;
    &:first-of-type {
      margin-top: 16px;
    }
    &:hover {
      opacity: 1;
    }
  }
`

const wrapDescriptionButton = css`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  padding: 1px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const description = css`
  padding-top: 7px;
  width: calc(100% - 180px);

  @media (max-width: 768px) {
    width: 100%;
  }
`

const actionArea = css`
  flex: 0 0 300px;
  text-align: center;
  float: right;
  font-weight: 600;
  user-select: none;
  font-size: 0.9em;
  letter-spacing: 0.15em;
  margin-left: 30px;
  margin-top: 3px;

  @media (max-width: 768px) {
    flex: 1;
    margin: 0;
  }
`

const dropzone = css`
  width: 100%;
  display: inline-block;
  margin-right: 40px;
  outline: 2px solid #008aab;
  padding: 9px 12px 7px 12px;
  text-transform: uppercase;
  cursor: pointer;
  color: #008aab;
  transition: all 150ms ease;
  overflow: hidden;
  &:hover {
    background-color: #008aab;
    color: white;
    outline: 2px solid #008aab;
  }

  @media (max-width: 768px) {
    margin: 20px 0 0;
  }
`

const disabled = css`
  outline: 2px solid rgba(0, 0, 0, 0.4);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.4);
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    outline: 2px solid rgba(0, 0, 0, 0.4);
  }
`

const feedbackContainer = css`
  p {
    font-size: 0.95em;
    color: rgb(120, 120, 120);
    margin: 20px 0;
  }
`
