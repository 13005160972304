import React from 'react'
import { Button } from 'common'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import { requestEmailVerification } from '../../../redux/modules/auth'
import { connect } from 'react-redux'

function VerifyEmailQuestionaire({
  user,
  onComplete,
  requestEmailVerification,
}) {
  const closeQuestionaire = () => {
    onComplete()
    try {
      window.mixpanel.track('Verificaton.Email.Skip')
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }

  const sendVerificationEmail = () => {
    requestEmailVerification().then(onComplete)
    try {
      window.mixpanel.track('Verificaton.Email.Resend')
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }

  return (
    <div css={content}>
      <h1>Account Locked - Verify your email</h1>

      <p>
        In order to verify your account, we need to confirm your email address '
        {user.email}' is active and working.
        <br />
        Please click the "Verify Email" button and we will send a confirmation
        email which contains a verification link.
      </p>
      <p>
        <b>Note</b>: Your account and match scanning is on hold (locked) until
        your email is verified.
      </p>

      <div css={buttons}>
        <Button greyOutline onClick={closeQuestionaire}>
          Remind me later
        </Button>
        <Link to="/profile/settings#change-email">
          <Button outline onClick={closeQuestionaire}>
            Update Address
          </Button>
        </Link>
        <Button hoverMain onClick={sendVerificationEmail}>
          Verify Email
        </Button>
      </div>
    </div>
  )
}

VerifyEmailQuestionaire.eligible = (user) =>
  !(
    user
    && user.verification
    && user.verification.email
    && user.verification.email.dateVerified
  )
  && !global.E2E
  && global.NODE_ENV === 'production'

export default connect(null, { requestEmailVerification })(
  VerifyEmailQuestionaire
)

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const buttons = css`
  display: flex;
  justify-content: center;
  flex-direction: row;

  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
