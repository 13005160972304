import React from 'react'
import { css } from 'react-emotion'
import ClusterList from './ClusterList/index'
import ClusterFilters from './ClusterFilters/index'

const ClusterListView = () => {
  return (
    <div css={styles.container}>
      <ClusterFilters />
      <ClusterList />
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
    max-width: 1600px;
    margin: 30px auto 0 auto;
  `,
}

ClusterListView.whyDidYouRender = true

export default ClusterListView
