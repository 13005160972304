import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { cx, css } from 'react-emotion'

export default class WallSlider extends Component {
  componentDidMount() {
    setTimeout(() => this.handleResize())
  }

  componentDidUpdate(oldProps) {
    if (this.props.children !== oldProps.children) {
      this.handleResize()
    }
  }

  // Workaround issue with react-slick
  // https://github.com/akiran/react-slick/issues/809
  handleResize = () =>
    this.refs.slick && this.refs.slick.innerSlider.onWindowResized()

  handleNext = () => {
    this.props.onContinue(() => this.refs.slick && this.refs.slick.slickNext())
  }

  render() {
    const { children, step, onChange } = this.props
    return (
      <React.Fragment>
        <Slider
          ref="slick"
          infinite={false}
          adaptiveHeight
          draggable={false}
          dotsClass={dotsClass}
          arrows={false}
        >
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              onComplete: this.handleNext,
              handleResize: this.handleResize,
            })
          )}
        </Slider>

        {children.length > 1 &&
          !Boolean(
            children[0].props && children[0].props.blockProgressIndicator
          ) && (
            <ul css={progressIndicator}>
              {children.map((c, idx) => (
                <li
                  key={idx}
                  css={cx(step === idx && activeProgressIndicator)}
                  onClick={() => {
                    this.refs.slick && this.refs.slick.slickGoTo(idx)
                    onChange(idx)
                  }}
                />
              ))}
            </ul>
          )}
      </React.Fragment>
    )
  }
}

WallSlider.propTypes = {
  children: PropTypes.node.isRequired,
}

const dotsClass = css`
  background-color: red;
`

const progressIndicator = css`
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  list-style: none;

  li {
    display: inline;
    padding: 5px;
    appearance: none;
    border-radius: 50%;
    border: 1px solid #93939380;
    margin-right: 7px;
    margin-top: 15px;
    cursor: pointer;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    position: fixed;
    bottom: 5px;
    left: 5px;
    right: 5px;
    text-align: center;
    background-color: white;
    padding: 4px 0;

    li {
      margin-top: 0px;
    }
  }
`

const activeProgressIndicator = css`
  background-color: #008aab;
  border: 1px solid #008aab;
`
