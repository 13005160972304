import React from 'react'
import PropTypes from 'prop-types'
import FormRow from './FormRow'
import Label from './Label'
import Feedback from './Feedback'
import styled, { css, cx } from 'react-emotion'

const ActionArea = styled.div`
  width: 100%;
  input {
    height: 40px;
    width: 100%;
    padding: 0 12px;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    &:focus {
      outline: 0;
      border: 1px solid #148aa9;
    }

    @media (max-width: 768px) {
      border-radius: 0;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
    }
  }
`

export default function TextFieldVertical(props) {
  const {
    addon,
    input,
    label,
    locked,
    spacing,
    labelProps = {},
    meta = {},
  } = props

  return (
    <FormRow {...props}>
      <div className={cx(wrap, locked ? stylesLocked : stylesUnlocked)}>
        <Label title={label} align="left" vertical {...labelProps} />
        <Spacer spacing={Number(!!spacing)} />

        {locked ? (
          <p css={lockedValue}>{props.input.value}</p>
        ) : (
          <ActionArea>
            <input {...input} />
            <Feedback meta={meta} />
          </ActionArea>
        )}
        {addon && addon({ name: input.name })}
      </div>
    </FormRow>
  )
}

const wrap = css`
  display: flex;
  width: 100%;
`

const stylesUnlocked = css`
  flex-direction: column;
  align-items: flex-start;
`

const stylesLocked = css`
  justify-content: space-between;
  opacity: 0.5;
`

const lockedValue = css`
  font-size: 1.14em;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
`

const Spacer = styled.div`
  width: 100%;
  height: 10px;
  display: ${props => (props.spacing ? 'block' : 'none')};
`

TextFieldVertical.propTypes = {
  label: PropTypes.string.isRequired,
  labelAlign: PropTypes.string,
}

TextFieldVertical.defaultProps = {
  labelAlign: 'right',
}
