import { memoize } from 'lodash'
import * as React from 'react'
// import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'

const WATERMARK_OPTIONS = [
  { value: '', label: 'Please choose', hidden: true },
  { value: '', label: 'Please choose', default: true, disabled: true },
  { value: 'Unchanged', label: 'No Change' },
  { value: 'Removed', label: 'Watermark Removed' },
  { value: 'Replaced', label: 'Watermark Replaced' },
  { value: 'Added', label: 'Watermark Added' },
]
export const WatermarkItems = memoize(() => {
  return WATERMARK_OPTIONS.map(({ label, ...rest }, idx) => (
    <option key={idx} {...rest}>
      {label}
    </option>
  ))
})
// export const WatermarkItems = memoize(() => {
//   return WATERMARK_OPTIONS.map(({ value, label }) => (
//     <PixsyMenuItem key={value} value={value}>
//       {label}
//     </PixsyMenuItem>
//   ))
// })
