import {
  IMAGE_INDEX,
  MATCH_INDEX,
  SUBMISSION_LABEL as LABELS,
  SUBMISSION_REQUIRED as REQUIRED,
} from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import {
  PixsyFormGroup,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
  PixsyTextField,
} from '../../../Components'
import { TooltipContainer } from 'common'

export const SingleTextField = React.memo(
  ({
    name,
    type,
    disabled,
    readOnly,
    size = 3,
    padding,
    direction,
    rightAddon,
    validateOnChange = true,
    validateOnBlur,
    fieldContextOnBlur,
    fieldContextOnChange,
    debounceValidation,
    debounceValidationOnChange,
    debounceValidationOnBlur,
    alignTooltip,
    index,
  }) => {
    const nameWithIndex = name
      .replace(IMAGE_INDEX, index)
      .replace(MATCH_INDEX, index)
    const isVertical = direction === 'column'
    const labelSize = isVertical ? 12 : size
    const fieldSize = isVertical ? 12 : 12 - size

    const tooltip = <TooltipContainer id={name} />

    return (
      <PixsyPaper
        padding={padding}
        disabled={disabled[name]}
        variant="transparent"
        borderless
      >
        <PixsyFormGroup tooltip={tooltip} alignTooltip={alignTooltip}>
          <PixsyGridItem xs={labelSize}>
            <PixsyLabel text={LABELS[name]} required={REQUIRED[name]} />
          </PixsyGridItem>
          <PixsyGridItem xs={fieldSize}>
            <PixsyTextField
              type={type}
              name={nameWithIndex}
              disabled={disabled[name]}
              rightAddon={rightAddon}
              validateOnChange={validateOnChange}
              validateOnBlur={validateOnBlur}
              fieldContextOnBlur={fieldContextOnBlur}
              fieldContextOnChange={fieldContextOnChange}
              debounceValidation={debounceValidation}
              debounceValidationOnChange={debounceValidationOnChange}
              debounceValidationOnBlur={debounceValidationOnBlur}
              readOnly={readOnly}
            />
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }
)
