import React from 'react'

export const SubmissionValidationDialogIndirectHotlink = () => (
  <div>
    <h6>
      Pixsy has detected that this use is a “hotlink” use, and your work is not
      hosted by the website.
    </h6>

    <p>
      While the image is displaying on the web page, the image file is hosted
      somewhere else. This may mean that we are unable to accept this use for
      resolution.
    </p>
    <p>
      If you are unsure if this case is viable you can contact us and we can
      further evaluate the case for you. Or you can continue to case submission.
    </p>
    <p>
      Pixsy uses a set of algorithms to pre-determine if a case is suitable for
      resolution. We do this to save you time, and to ensure that our case
      management team can focus their efforts on recovering compensation for
      viable commercial cases.
    </p>
  </div>
)
