import * as React from 'react'
import PropTypes from 'prop-types'
import Label from './Label'

const unescapeHTML = escapedStr => {
  const e = document.createElement('span')
  e.innerHTML = escapedStr
  return e.textContent
}

export default class PageTitleAndUrl extends React.Component {
  render() {
    const { matches } = this.props

    const [properMatch] = matches.filter(match => match.origin)

    return (
      <React.Fragment>
        {properMatch.origin.title && (
          <Label
            header="PAGE TITLE"
            text={unescapeHTML(properMatch.origin.title)}
          />
        )}
        {properMatch.origin.url && (
          <Label header="PAGE URL" href={properMatch.origin.url} />
        )}
      </React.Fragment>
    )
  }
}
PageTitleAndUrl.defaultProps = {
  matches: [],
}
PageTitleAndUrl.propTypes = {
  matches: PropTypes.array,
}
