import React from 'react'
import { css, cx } from 'react-emotion'

export default function CheckboxSwitcher({
  onLabel,
  offLabel,
  onChange,
  checked,
}) {
  return (
    <div className={cx(style)} onChange={onChange}>
      <span className={cx({ active: checked })}>{onLabel}</span>
      <input type="checkbox" id="switcher" />
      <label htmlFor="switcher" />
      <span className={cx({ active: !checked })}>{offLabel}</span>
    </div>
  )
}

const pixsyBlue = '#008aab'

const style = css`
  input {
    display: none;
  }
  label {
    margin: 5px 5px;
    display: inline-block;
    width: 45px;
    height: 16px;
    box-sizing: border-box;
    border: 1px solid;
    float: left;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: .3s ease;
    opacity: 0.5; 
  }
  input[type=checkbox]:checked + label {
    opacity: 1;
  }
  input[type=checkbox]:checked + label:before {
    left: 2px;
  }
  label:before {
    transition: .3s ease;
    content: '';
    width: 12px;
    height: 12px;
    position:absolute;
    background: ${pixsyBlue};
    left: 29px;
    top: 1px;
    box-sizing: border-box;
    border: 1px solid;
    color: ${pixsyBlue};
    border-radius: 100px;
  }
  span {
    text-align: left;
    float: left;
    opacity: 0.5;
  }

  span.active {
    opacity: 1;
  }

  &:last-child {
    text-align: right;
    float: right;
  }

`
