import React from 'react'
import { css } from 'react-emotion'
import Helmet from 'react-helmet'
import AgreementContainer from './AgreementContainer'

export default function AgentAuthorization() {
  return (
    <AgreementContainer>
      <Helmet title="Agent Authorization Agreement" />
      <div css={wrap}>
        <h1>PIXSY AGENT AUTHORIZATION AGREEMENT</h1>
        <p>
          This agent authorization agreement (&ldquo;<strong>Agreement</strong>
          &rdquo;) is made and entered into by and between Pixsy, Inc., (&ldquo;
          <strong>Pixsy</strong>&rdquo;), and you or your authorized representative acting on your behalf (collectively,
          the &ldquo;
          <strong>Client</strong>&rdquo;), (each individually a &ldquo;
          <strong>Party</strong>&rdquo; and/or collectively, the &ldquo;
          <strong>Parties</strong>&rdquo;).
        </p>
        <p>
          <strong>WHEREAS</strong>, Client is the sole exclusive owner, rights owner, or legally authorized
          representative of the owner, of its creative visual work(s) including but not limited to; photographs, images,
          illustrations, art, designs, graphics and videos, (the &ldquo;
          <strong>Work(s)</strong>&rdquo;);
        </p>
        <p>
          <strong>WHEREAS</strong>, Client desires to appoint Pixsy and Pixsy desires to act as Client&rsquo;s exclusive
          agent for the purposes of managing and enforcing Client&rsquo;s copyright and related copyright infringement
          litigation; and
        </p>
        <p>
          <strong>WHEREAS</strong>, both Client and Pixsy are in agreement with respect to the terms and conditions upon
          which Pixsy shall act as the exclusive agent.
        </p>
        <p>
          <strong>NOW, THEREFORE</strong>, in consideration of these promises and agreements set forth herein, the
          Parties, each intending to be legally bound hereby, do promise and agree as follows:
        </p>
        <ol>
          <li>
            <b className="underline">Pixsy Services.</b>
            <ol type="A">
              <li>
                Pixsy will collect the information regarding each instance of an alleged copyright infringement of a
                Client&rsquo;s Work(s), as case submissions (hereby referred to as the &ldquo;
                <strong>Case</strong>
                &rdquo;) and will attempt to license Works or otherwise seek compensation on Client&rsquo;s behalf;
              </li>
              <li>
                Pixsy will utilize its image monitoring technology to find copies of the Client&rsquo;s Work(s) on the
                internet. When Client finds an unauthorized use and makes a submission to Pixsy, Pixsy will calculate a
                license fee and invoice the image user at its discretion. Pixsy will investigate Cases to the best of
                its ability and handle any negotiations related to the license fee and settlement. Pixsy will follow up
                with the image user and pursue a license fee as long as it is viable to do so. Should Pixsy determine
                that a Case is not suitable for its resolution program or no longer suitable based on new information,
                Pixsy will advise Client;
              </li>
              <li>If legal action becomes necessary, Pixsy will provide Client with the recommended next steps;</li>
              <li>
                Pixsy is not a law firm and does not engage in the practice of law. This Agreement does not constitute
                an attorney-client relationship. Any general information Pixsy provides should not be interpreted as
                legal advice, and Pixsy is not a substitute for legal counsel. In the event that legal action is
                necessary, Pixsy will engage a law firm to perform the required legal services, in accordance with the
                law firm&rsquo;s professional legal judgment;
              </li>
              <li>
                Pixsy&rsquo;s compensation and provisions for payment thereof shall be as set forth in{' '}
                <strong>Schedule A</strong>; and
              </li>
              <li>Pixsy reserves the right to terminate a Client&rsquo;s account at any time.</li>
            </ol>
          </li>
          <li>
            <b className="underline">Agency Grant.</b>
            <ol type="A">
              <li>
                Client grants Pixsy the authority to act as its exclusive agent for negotiating and settling license fee
                payments, license terms, and settlements for Cases submitted to Pixsy&rsquo;s resolution program. This
                includes retroactive permission to use Client&rsquo;s Work(s) and may include continued use. Permission
                for use beyond what Client instructs Pixsy during a Case, is never granted without Client&rsquo;s
                permission;
              </li>
              <li>
                Client grants Pixsy the authority to negotiate and settle on terms to be determined by Pixsy and/or its
                law firms and/or agents (described herein), at its own discretion;
              </li>
              <li>Client grants Pixsy the authority to accept payment on Client&rsquo;s behalf;</li>
              <li>
                Client grants Pixsy the authority to appoint counsel on Client&rsquo;s behalf to investigate, negotiate,
                enforce and collect license fees, compensation and damages for Client&rsquo;s Case;
              </li>
              <li>
                Client grants Pixsy the authority to file an application for a copyright registration for their Works on
                their behalf if necessary;
              </li>
              <li>
                Client grants Pixsy the authority to employ agents or sub-agents to help it with their tasks, including
                attorneys, debt recovery agents, and subcontractors if necessary;
              </li>
              <li>
                Client grants Pixsy the authority to refer Cases to outside attorneys for review and retain these
                attorneys to investigate, negotiate, and collect compensation as well as license terms for Cases;
              </li>
              <li>
                Client agrees to refer to Pixsy all new inquiries received after the date of this Agreement relating to
                the Case and licensing rights with respect to the Work(s) included in the related Case.
              </li>
              <li>
                Client agrees that any new or additional Works found to be used by the same third-party image user of
                the Case, after the date of this Agreement, will also be handled by Pixsy until a resolution, settlement
                or discontinuation of all pending or new Cases against said image sser has been reached;&nbsp;
              </li>
              <li>
                Pixsy shall have no authority to promote, negotiate, administer or manage any licensing arrangement
                entered into by Client prior to the Effective Date of this Agreement, unless and until such authority is
                expressly granted to Pixsy by Client in writing; and
              </li>
              <li>
                Client grants Pixsy the right to utilize its name, website address, the addresses of its photo-sharing
                profiles, and other information it provides to Pixsy about its Works in correspondence with image users
                to facilitate licensing transactions, collections and settlements. Pixsy will not share Client&rsquo;s
                contact information with third parties other than those strictly necessary for providing Pixsy&rsquo;s
                tools or services to Client.
                <ol type="a">
                  <li>
                    Information and data is handled in accordance with the Pixsy Privacy Policy available at:{' '}
                    <a href="http://www.pixsy.com/privacy-policy/">www.pixsy.com/privacy-policy/</a>.
                  </li>
                  <li>
                    Client grants Pixsy permission to share data with legal counsel to facilitate the processing of the
                    Case.
                  </li>
                  <li>
                    Notwithstanding anything to the contrary of this Agreement, Pixsy and its representatives shall be
                    permitted to retain data relating to the Client, Works, and Case to meet the requirements of law or
                    regulation. It is agreed and understood that any such requirement to retain such data may supersede
                    any request for data deletion.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b className="underline">Representations and Warranties of Client.</b>
            <ol type="A">
              <li>
                Client represents and warrants that it is the sole owner of all rights in and to the Work(s) and that it
                has the right to grant the rights herein granted to Pixsy. Client further represents that it has not
                granted any other person or firm the right and authority to represent Client with respect to the Work(s)
                in such a capacity that, to Client&rsquo;s knowledge, would be in conflict with this Agreement;
              </li>
              <li>
                Client has not assigned any rights in the Work(s) to a third party that would limit Pixsy&rsquo;s
                ability to act on Client&rsquo;s behalf as agent to negotiate a license or settlement;
              </li>
              <li>
                Client has not assigned any rights in the Work(s) to a third party that would limit the terms of use we
                would grant to a third party in a license or settlement. If this is not the case, Client must provide
                Pixsy with the details in writing by email;
              </li>
              <li>
                Client has the authority to enter into this Agreement with Pixsy and no permission or consent from a
                third party is required;
              </li>
              <li>
                Client has not licensed the Work(s) before to a user that Client now identifies as an infringer or Pixsy
                has identified as an infringer;&nbsp;
              </li>
              <li>
                Client has never granted a license to the Work(s) to a third party with the right to sublicense it to a
                user Client or Pixsy identifies an infringer;
              </li>
              <li>
                Client has provided Pixsy with as much information about the Work(s) and its licensing history as
                possible in order to negotiate a proper license/settlement (if Client is not sure if a Work(s) is
                properly licensed or not, Client will inform Pixsy, so that Pixsy may further investigate);
              </li>
              <li>
                Client warrants that the Work(s) does not infringe on the intellectual property rights or personality
                rights of others (if Client believes that the Work(s) does infringe on the intellectual property rights
                or personality rights of others, it must inform Pixsy);
              </li>
              <li>
                Client has provided Pixsy with complete and accurate i) personal and contact information and ii) tax and
                payment information;
              </li>
              <li>
                Client will respond to all requests for additional information regarding a Work, its licensing history,
                and other details as needed within a reasonable time frame;
              </li>
              <li>
                Client agrees that once it makes a Case submission to Pixsy, Pixsy shall act as the exclusive agent for
                the Work(s) for the identified infringement during the period of time until the Case is finally resolved
                or as long as the image is on Pixsy&rsquo;s image monitoring platform, whatever happens later;
              </li>
              <li>Client will not negotiate with the image user or infringer while a Case is pending with Pixsy;</li>
              <li>
                To the extent you are an authorized representative of Client and are entering into this Agreement on
                Client&rsquo;s behalf, you represent that you have the full legal authority to act on the Client&rsquo;s
                behalf with respect to all matters relating to this Agreement and you further understand and agree that
                you may be held jointly or severally liable for any breach of this Agreement; and
              </li>
              <li>
                Client agrees to defend, indemnify and hold Pixsy harmless from and against any claims, liabilities,
                judgments, penalties, and taxes, civil and criminal, and all costs, expenses (including, without
                limitation, attorneys&rsquo; fees) which may arise out of or be related to Client&rsquo;s
                representations and warranties, as above stated, as well as any claims that may arise out of (i)
                Client&rsquo;s content or Work(s) that violates the intellectual property rights of others; (ii)
                deliberately false or misleading information provided to Pixsy (iii) Client&rsquo;s violation of any
                term of this Agreement; and (vi) any other party&rsquo;s access and use of the Services with
                Client&rsquo;s unique username, password or other appropriate security code, without expiry.
              </li>
            </ol>
          </li>
          <li>
            <b className="underline">Confidentiality and Privilege.</b>
            <ol type="A">
              <li>
                The Parties, except as mutually agreed among the Parties or as otherwise may be required by law, rule,
                regulation, legal process or the request of any court, regulatory authority or other governmental body,
                will hold in confidence the terms of this Agreement, any information exchanged hereunder, as well as the
                discussions between the Parties; and
              </li>
              <li>
                To the extent that any information exchanged now or at a future date under this Agreement includes or
                may include, materials or other information that may be subject to the attorney-client privilege or work
                product doctrine concerning Client or any pending, threatened or prospective action, suit, proceeding,
                investigation, arbitration or dispute related to Client, it is acknowledged and agreed that Pixsy and
                Client have a commonality of interest with respect to such information or action, suit, proceeding,
                investigation, arbitration or dispute and that it is Pixsy&rsquo;s and Client&rsquo;s mutual desire,
                intention and understanding that the sharing of such materials and other information is not intended to,
                and shall not, affect the confidentiality of any of such materials or other information or waive or
                diminish the continued protection of any of such materials or other information under the
                attorney-client privilege or work product doctrine. Accordingly, all information exchanged or shared
                under this Agreement relating to Client or Pixsy that is entitled to protection under the
                attorney-client privilege or work product doctrine shall remain entitled to protection thereunder and
                shall be entitled to protection under the joint defense doctrine, and the Parties agree to take all
                reasonable measures necessary to preserve, to the fullest extent possible, the applicability of all such
                privileges or doctrines.
              </li>
            </ol>
          </li>
          <li>
            <b className="underline">Non-circumvention.</b>
            <ol type="A">
              <li>
                It is expressly agreed that Client shall not, at any time prior to the expiration of one year from the
                date of termination of this Agreement (without the prior written consent of Pixsy) directly or
                indirectly, independently of Pixsy, initiate, solicit, negotiate, contract or enter into any engagement
                with any attorney, sub-contractor, employee, service provider, agent, or debt collector that was
                identified or introduced to Client by Pixsy.
              </li>
            </ol>
          </li>
          <li>
            <b className="underline">Miscellaneous.</b>
            <ol type="A">
              <li>
                <span className="underline">Expenses Relating to the Transaction</span>. &nbsp;Each Party shall pay its
                own expenses in connection with this Agreement.
              </li>
              <li>
                <span className="underline">Severability</span>. &nbsp;If any provision or part of this Agreement is
                held to be invalid or unenforceable for any reason, such provision shall, if possible, be adjusted
                rather than voided, in order to achieve a result that corresponds to the fullest extent possible to the
                intention of the Parties. &nbsp;The nullity or adjustment of any provision of this Agreement shall not
                affect the validity and enforceability of any other provision of this Agreement.
              </li>
              <li>
                <span className="underline">Entire Agreement and Amendment</span>. &nbsp;This Agreement sets forth the
                entire understanding of the Parties relating to the Transaction and supersedes any prior agreements
                between any of the Parties which are hereby rescinded. This Agreement may only be amended only by a
                written agreement executed by the Parties. This Agreement is executed without reliance upon any promise,
                warranty, or representation by any Party or any representative of any Party other than those expressly
                contained herein.
              </li>
              <li>
                <span className="underline">Governing Law and Arbitration</span>. Any controversy between the Parties to
                this Agreement involving the construction or application of any of the terms, provisions or conditions
                of this Agreement, shall on written request of either Party served on the other, be submitted first to
                mediation and then, if still unresolved, to binding arbitration. The award rendered by the arbitrator
                shall be final and binding, and judgment may be entered thereon in any court having jurisdiction. The
                prevailing party in the arbitration and/or related legal action or proceedings will be entitled to an
                award of such party&apos;s reasonable costs, including legal fees and expenses. Any issue concerning the
                extent to which any dispute is subject to arbitration, or concerning the applicability, interpretation,
                or enforceability of these arbitration procedures, including any contention that all or part of these
                procedures are invalid or unenforceable, shall be governed by the Federal Arbitration Act and resolved
                by the arbitrators. All other issues shall be governed by the law of the State of New York, United
                States of America, without regard to its choice of law rules. &nbsp;If arbitration is precluded by the
                local law where you reside or for any other reason, then any litigation related to this Agreement will
                take place before the courts located in Berlin, Germany.
              </li>
              <li>
                <span className="underline">Compliance with Law</span>. &nbsp;Any provision of this Agreement which
                shall be interpreted by any court, arbitral forum or regulatory body (having jurisdiction in the
                premises) to be contrary to or in violation of any law, including without limitation, any federal
                securities law, shall be deemed amended and reinterpreted <em>ab initio</em> so as to be in compliance
                with such law.&nbsp;
              </li>
              <li>
                <span className="underline">Survival</span>. &nbsp;The representations, warranties and agreements of
                Client contained herein shall survive the execution hereof.&nbsp;
              </li>
              <li>
                <span className="underline">No Waiver</span>. &nbsp;No failure to or delay in exercising any right,
                power or privilege hereunder shall operate as a waiver thereof, and no single or partial exercise of any
                right, power or privilege hereunder shall preclude any other or further exercise of any right, power or
                privilege.&nbsp;
              </li>
              <li>
                <span className="underline">Notices</span>. &nbsp;All notices and other communications provided for
                under this Agreement shall be in writing and shall be sent to the address set forth below each
                Party&rsquo;s name on the signature page hereof, or to such other address as may be designated in
                writing (registered email address).&nbsp;
              </li>
              <li>
                <span className="underline">Assignment</span>.&nbsp; This Agreement may not be assigned, nor may any
                obligations hereunder be transferred or delegated, by either Party without the prior written consent of
                the other, provided that Pixsy may assign its rights and obligations hereunder without consent in the
                event Pixsy effects a corporate reorganization, consolidates with, or merges into any other person or
                entity, or transfers any of its assets, stock, or business into another person or entity. &nbsp;This
                Agreement shall bind and inure to the benefit of and be enforceable by the Parties and their respective
                permitted successors and assigns.&nbsp;
              </li>
              <li>
                <span className="underline">Termination</span>.&nbsp; This Agreement and its obligations may be
                terminated immediately at any time by either Party in writing (email or posted letter).
              </li>
              <li>
                <span className="underline">Effect of Termination</span>. In the event of the termination of this
                Agreement or the termination of any accepted, open or ongoing Case by the Client, the Client shall pay
                the following fees;
                <ol type="a">
                  <li>
                    US$200 cancellation fee plus the hourly rate, expenses and/or fees of the appointed legal counsel,
                    agent, subcontractor, or debt collector for all work on the Case; or&nbsp;
                  </li>
                  <li>
                    If a lawsuit has been filed for the Case, a US$800 cancellation fee plus the hourly rate, expenses
                    and/or fees of the appointed legal counsel, agent, subcontractor, or debt collector for all work on
                    the Case.
                  </li>
                </ol>
                It is agreed and understood that Pixsy, and appointed legal counsel, agent, subcontractor, or debt
                collector, will be entitled to the cancellation fee(s), when Pixsy is not able to bring the Case to
                successful settlement due to an action by the Client; such events include but are not limited to;
                <ol>
                  <li>Client not being the rightful owner or representative of the Work(s);</li>
                  <li>The Work(s) being licenced prior to the commencement of the Case;</li>
                  <li>The Work(s) being licensed or settled without Pixsy and outside of this Agreement; and</li>
                  <li>
                    Client not accepting a commercially reasonable settlement and/or settlement fee offer for the Case,
                    where there is no other commercially viable resolution available.
                  </li>
                </ol>
                <p>
                  Until all cancellation fees have been paid, the Case may be completed, or file closed at the
                  discretion of Pixsy and/or the appointed legal counsel, agent or debt collector.
                </p>
              </li>
              <li>
                <strong className="underline">ELECTRONIC SIGNATURE</strong>
                <strong>
                  . THE ABOVE DISCLOSURES SHOULD BE CAREFULLY READ, UNDERSTOOD AND ACCEPTED BY YOU BEFORE YOU SIGN THIS
                  AGREEMENT. BY CLICKING &quot;I AGREE&quot; TO SIGN THIS USER AGREEMENT WHEN PRESENTED WITHIN THE PIXSY
                  WEB-APP, YOU ELECTRONICALLY SIGN AND THEREBY LEGALLY BIND YOURSELF TO THE TERMS AND CONDITIONS
                  CONTAINED HEREIN. CLICKING &quot;I AGREE&quot; IS THE EQUIVALENT OF YOU MANUALLY SIGNING THIS
                  AGREEMENT. A COPY (FOR REFERENCE) WILL BE DELIVERED BY EMAIL.
                </strong>
              </li>
            </ol>
          </li>
        </ol>
        <div style={{ textAlign: 'center', margin: '3em 0 1em 0' }}>
          <strong>SCHEDULE A</strong>
        </div>
        <div className="underline">
          <strong>Fees and Charges.</strong>
        </div>
        <p>
          In consideration of the services rendered and to be rendered by Pixsy, Client shall pay Pixsy all fees
          calculated as follows:
        </p>
        <p>
          <strong className="underline">For Cases enforced in the United States of America:&nbsp;</strong>
        </p>
        <ul>
          <li>
            For Cases that are successfully enforced, Pixsy shall receive a success fee defined as{' '}
            <span className="underline">50% gross</span> of all settlement, payments, license fees, and/or royalties
            that Pixsy collects or settles on Client&rsquo;s behalf.&nbsp;
          </li>
          <li>
            In the event that Pixsy appoints legal counsel and files a lawsuit on behalf of Client, Pixsy shall receive
            a success fee defined as <span className="underline">50% net</span> of all settlement, payments, license
            fees, and/or royalties that Pixsy or legal counsel collects or settles on Client&rsquo;s behalf, after the
            deduction of Case expenses, court fees, other lawsuit-related expenses (including attorney fees) and debt
            collection costs.
          </li>
          <li>
            In the event that 100% of the attorney fees, legal costs and/or expenses are awarded as part the verdict,
            settlement or otherwise, Pixsy shall receive a success fee of 35% of the gross settlement.
          </li>
        </ul>
        <p>
          <strong className="underline">For Cases enforced outside of the United States of America:&nbsp;</strong>
        </p>
        <ul>
          <li>
            For Cases that are successfully enforced, Pixsy shall receive a success fee defined as{' '}
            <span className="underline">50% net</span> of all settlement, payments, license fees, and/or royalties that
            Pixsy or legal counsel collects or settles on Client&rsquo;s behalf, after the deduction of Case expenses,
            court fees, other lawsuit-related expenses (including attorney fees) and debt collection costs.
          </li>
          <li>
            In the event that 100% of the attorney fees, legal costs and/or expenses are awarded as part the verdict,
            settlement or otherwise, Pixsy shall receive a success fee of 35% of the gross settlement.
          </li>
        </ul>
        <p>
          In a small percentage of Cases, some expenses may not be covered by this fee schedule. By law some
          jurisdictions do not allow other parties (agent or legal counsel) to pay case expenses. In the event that any
          expenses are not covered, and that they will need to be paid for by Client, Pixsy will notify Client in
          writing (email) prior to any further action and advise next steps. In these situations, regardless of the
          outcome of the Case, these expenses will be the sole responsibility of Client to pay. Pixsy will always first
          gain permission before incurring additional expenses for a Case.
        </p>
        <p>
          Pixsy will remit payment to Client, after the deduction of above-mentioned fees and expenses, within 30 days
          of receiving payment from the image user, infringer, attorney, or agent who has handled the collection.
          Payments will always be remitted in US dollars as this is the receiving currency of case funds to
          Pixsy&rsquo;s bank account.
        </p>
        <p>
          Any bank, transaction and/or currency conversion fees incurred during the payment are the responsibility of
          the Client and may be deducted from such payments by the sending bank, receiving bank, Pixsy or related
          payment provider(s). If a payment is returned, rejected or cancelled due to an error by the Client, any
          related fees will be deducted from the clients account or future payments.
        </p>
        <p>
          Compliance with local tax laws, including payment of any applicable taxes on the license fee or settlement of
          a Case is the Client&rsquo;s responsibility solely. Pixsy may be required in some situations to withhold up to
          30% of royalty payments to comply with US tax law/IRS requirements. Pixsy may request tax documents from
          Client to verify Client&rsquo;s IRS withholding status or for other needs.
        </p>
        <p>
          In the event that a Client has outstanding amounts due for other Pixsy services or other obligations, such as
          subscription fees for paid plans, expenses related to other, or fees from cancelled Cases, Pixsy may use
          payments owed for any outstanding Cases to satisfy these obligations.
        </p>
        <p>
          Client hereby undertakes to keep updated and accurate payment information for as long as the Case is pending
          resolution. In the event that a payment cannot be made to Client, where either the payment or tax information
          is not provided, was incorrect or out of date, Pixsy will notify Client of this event via email and give
          Client the opportunity to provide the correct information for a successful payment. Any payments unable to be
          remitted will be handled in accordance with applicable law.
        </p>
      </div>
    </AgreementContainer>
  )
}

const wrap = css`
  .container {
    max-width: 800px;
    margin: 0 auto;
    ol {
      counter-reset: item;
      padding-left: 0;
      li {
        margin-bottom: 20px;
        padding-left: 10px;
        display: block;
        &:before {
          content: counters(item, '.') '. ';
          counter-increment: item;
          padding-right: 30px;

          ol {
            padding-left: 20px;

            em {
              text-decoration: underline;
              font-style: normal;
            }
          }
        }
      }
    }
  }

  ol[type='A'],
  ol[type='1'] {
    li {
      padding-left: 10px;
    }
  }
`
