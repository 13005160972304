import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Button, createContactUsHandler } from 'common'

export default function SubmissionValidationDialogCountryUnresolvable({
  onTakedown,
  onIgnore,
  cluster,
}) {
  return (
    <div css={wrap}>
      <h1>This match is not a viable case</h1>

      <h4>
        Pixsy has detected that this domain falls outside of the{' '}
        <span data-elevio-article="17">
          jurisdictions we currently support.
        </span>
      </h4>

      <p>
        We recommend that you <b>send a Takedown Notice</b> for this match,
        requesting the image is removed by the website operator or host.
      </p>

      <p>
        Pixsy uses a set of advanced algorithms to pre-determine if a case is
        suitable for resolution. We do this to save you time, and to ensure that
        our case management team can focus their efforts on recovering
        compensation for viable cases.
      </p>
      <p>
        If you believe this domain has been blocked but the case is still
        viable, you can contact us and we can further evaluate the case for you.
      </p>

      <div className="dialog-slider--button-wrapper">
        <Button
          onClick={createContactUsHandler('Unresolvable TLD')}
          greyTertiary
        >
          Contact us
        </Button>
        <Button grey onClick={onIgnore}>
          Ignore Match
        </Button>
        <Button hoverMain onClick={onTakedown}>
          Send Takedown
        </Button>
      </div>
      {!global.PRODUCTION && (
        <p className="debug">SubmissionValidationDialogCountryUnresolvable</p>
      )}
    </div>
  )
}

SubmissionValidationDialogCountryUnresolvable.propTypes = {
  onTakedown: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  cluster: PropTypes.object.isRequired,
}

const wrap = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
`
