import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { css } from 'react-emotion'

const LoadingIndicator = () => (
  <div css={LoadingIndicatorStyle}>
    <h1>LOADING MATCHES</h1>
    <div style={{ color: '#008aab', marginTop: 45 }}>
      <CircularProgress color="inherit" />
    </div>
  </div>
)

const LoadingIndicatorStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 24px;
    letter-spacing: 0.25em;
    font-weight: 300;
  }
`

export default LoadingIndicator
