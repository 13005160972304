import React, { useState, useRef, useEffect } from 'react'
import { css } from 'react-emotion'
import { constants } from '../store'

const { SORT_OPTIONS } = constants

const SelectSort = ({ sort, onSortChange }) => {
  const [isOpen, setOpen] = useState(false)
  const [currentValue, setValue] = useState(getDefault())

  const headerNode = useRef()
  const node = useRef()

  const handleClick = (e) => {
    if (node && node.current && !node.current.contains(e.target)) {
      if (headerNode && headerNode.current && headerNode.current.contains(e.target)) {
        return
      }

      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container} ref={headerNode}>
        <div
          className={styles.header}
          onClick={() => {
            setOpen(!isOpen)
          }}
        >
          {currentValue}
        </div>
      </div>
      {isOpen && (
        <div className={styles.itemsContainer} ref={node}>
          {SORT_OPTIONS.map((option) => {
            return (
              <div
                className={styles.option}
                key={option.query}
                onClick={() => {
                  if (currentValue === option.value) {
                    return
                  }
                  setOpen(false)
                  setValue(option.value)
                  onSortChange(option.value)
                }}
              >
                {option.isRecommended && (
                  <div className={styles.recommendedContainer}>
                    {' '}
                    <b> RECOMMENDED </b>{' '}
                  </div>
                )}
                <div> {currentValue === option.value ? <b> {option.value} </b> : option.value} </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const getDefault = () => SORT_OPTIONS.find((elem) => elem.isRecommended).value

const styles = {
  wrapper: css`
    position: relative;
  `,
  container: css`
    font-size: 14px;
    z-index: 999;
    user-select: none;
  `,
  itemsContainer: css`
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: #00000010 0 1px 10px;
    background: white;
    border: 0.5px #ebebeb solid;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 190px;
    user-select: none;
    z-index: 999;
    text-align: right;
    padding-right: 20px;

    & div:not(:last-child) {
      margin-bottom: 15px;
    }
  `,
  option: css`
    display: block;
    cursor: pointer;
  `,
  recommendedContainer: css`
    margin-bottom: 1px !important;
    color: #ffa168;
    font-size: 12px;
  `,
  header: css`
    cursor: pointer;
    appearance: none;
    border: none;
    background-image: url(${require('./assets/arrow-down.png')});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 14px 7px;
    padding-right: 15px;
    width: 150px;
    outline: none;
    background-color: transparent;
  `,
}

export default SelectSort
