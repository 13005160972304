import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { SexySeparator } from 'common'
import { DATA_ELEVIO } from 'pixsy-constants'
import { CategoryCard } from './Card'
import getTagSuggestions from '../../../getTagSuggestions'
import { css, cx } from 'react-emotion'

export default class SmartFilterSuggestions extends Component {
  static DEFAULT_HEIGHT = 200

  state = { activeTags: [] }

  static getDerivedStateFromProps(props) {
    const { availableTags, activeTags } = props

    if (!props.availableTags) {
      return null
    }

    let filteredBreakdown = availableTags.filter(
      ({ tag }) =>
        !activeTags.includes(tag) &&
        ![
          'commercial:false',
          'commercial:maybe',
          'similarity:low',
          'similarity:medium',
          'similarity:high',
          'pixsy:tm0612',
          'top',
        ].includes(tag)
    )

    return {
      activeTags: getTagSuggestions(filteredBreakdown).slice(0, 4),
    }
  }

  render() {
    const { linkbase, hidden, loading } = this.props

    const { activeTags } = this.state

    const height =
      (hidden === false && activeTags.length) || loading
        ? SmartFilterSuggestions.DEFAULT_HEIGHT
        : 0

    const showMobileFilter = (hidden === false && activeTags.length) || loading

    return (
      <div
        className={cx(
          wrap,
          loading && loadingStyle,
          showMobileFilter && mobileFilter
        )}
        style={{
          height: `${height}px`,
          overflow: height ? 'visible' : 'hidden',
        }}
      >
        <SexySeparator
          space={0}
          label="Smart Filters"
          data-elevio-selector={DATA_ELEVIO.MATCHES_SMART_FILTERS}
        />
        <ul>
          {activeTags.map(({ tag, count }) => (
            <li key={tag}>
              <Link to={`${linkbase}/${tag}`}>
                <CategoryCard tag={tag} n={count} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

SmartFilterSuggestions.propTypes = {
  hidden: PropTypes.bool.isRequired,
  tags: PropTypes.array, // eslint-disable-line
  linkbase: PropTypes.string.isRequired,
}

const wrap = css`
  display: block;
  overflow: hidden;
  transition: height 0.1s ease-in;
  user-select: none;
  height: 0;

  &.on {
    height: 200px;
    @media (max-width: 768px) {
      height: auto !important;
    }
  }

  @media (max-width: 768px) {
    max-height: auto;
  }

  hr {
    margin-top: 10px;
    display: block;
  }
  ul {
    margin-top: 10px;
    display: flex;
    padding-left: 0;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: normal;
      align-items: normal;
    }
  }
  li {
    list-style: none;
    width: 24%;
    margin: 1%;
    @media (max-width: 768px) {
      margin: 0;
      width: 50%;
      padding: 1%;
      display: flex;

      a {
        width: 100%;
      }
    }
  }
`

const mobileFilter = css`
  @media (max-width: 768px) {
    height: auto !important;
  }
`

const loadingStyle = css`
  opacity: 0.05;
  pointer-events: none;
`
