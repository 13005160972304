import React, { useCallback, Fragment } from 'react'
import { css } from 'react-emotion'
import { ClearbitLogo, Flag, ActionButton } from 'common'
import {
  StarIcon,
  DollarIcon,
  DollarIconLineThrough,
  ThreeDotsIcon,
  CrossIcon,
  WhiteCheckIcon,
  WhiteMagnifyingGlassIcon,
} from './Icons'
import determineCommercialRatingFromScore from '../utils/determineCommercialRatingFromScore'
import { useMatchViewerStore } from '../store/context'
import useClusters from '../utils/useClusters'
import { actionCreators } from '../store'
import TagAsSeen from './TagAsSeen'
import { Link } from 'react-router-dom'

const ClusterHeader = ({
  clusterId,
  clusterTags,
  countryName,
  categoryName,
  isTopMatch,
  score,
  displayName,
  domain,
  multiMatchView,
  globalDispatch,
  onOpenMoreOnGroupRequest,
  onTakeActionOnGroupRequest,
  caseId,
  takedownId,
}) => {
  const commercialRating = determineCommercialRatingFromScore(score)

  const { dispatch } = useMatchViewerStore()
  const { ignoreCluster } = useClusters(globalDispatch)
  const handleIgnoreClusterRequest = useCallback(() => {
    ignoreCluster({
      variables: {
        clusterId,
        state: true,
      },
      onCompleted: () => {
        dispatch(actionCreators.setGroupMatchesViewClose(clusterId))
      },
    }).catch((e) => console.error(e))
  }, [])

  const hideTakeActionButton = !!caseId || !!takedownId

  return (
    <div
      className={`
        ${styles.container} ${multiMatchView ? 'multiMatchView' : ''}
      `}
    >
      <div className={styles.title}>
        <ClearbitLogo host={domain} size={50} fallbackLetter={displayName.charAt(0)} />
        {multiMatchView && (
          <TagAsSeen clusterId={clusterId} clusterTags={clusterTags} score={score}>
            <span hidden>tag as seen</span>
          </TagAsSeen>
        )}
        <div>
          <h2>{displayName}</h2>
          {categoryName && <span>{categoryName}</span>}
        </div>
      </div>
      <div className={styles.infoWrapper}>
        {isTopMatch && (
          <div className={styles.infoItem}>
            <StarIcon width={25} height={25} /> <span>Top Match</span>
          </div>
        )}
        <div className={styles.infoItem}>
          <div style={{ display: 'flex' }}>
            {commercialRating > 0 ? (
              [...Array(commercialRating)].map((_, index) => (
                <DollarIcon key={index} style={{ width: 24, height: 24, margin: '0 -5px' }} />
              ))
            ) : (
              <DollarIconLineThrough width={24} height={24} />
            )}
          </div>
          <span>Commercial</span>
        </div>
        {countryName && (
          <div className={styles.infoItem}>
            <Flag country={countryName.split(' ').join('-')} style={{ width: 25, height: 25 }} />
            <span>{countryName}</span>
          </div>
        )}
      </div>
      {multiMatchView && (
        <div className={styles.buttonsWrapper}>
          <ActionButton Icon={ThreeDotsIcon} text="MORE ON GROUP" onClick={onOpenMoreOnGroupRequest} />
          {hideTakeActionButton ? (
            <Fragment>
              {caseId && (
                <Link to={`/cases/${caseId}`}>
                  <ActionButton
                    Icon={() => <WhiteMagnifyingGlassIcon width={18} height={18} />}
                    text="OPEN CASE"
                    className={styles.orangeButton}
                  />
                </Link>
              )}
              {takedownId && (
                <Link to="/takedowns">
                  <ActionButton
                    Icon={() => <WhiteMagnifyingGlassIcon width={18} height={18} />}
                    text="OPEN TAKEDOWNS"
                    className={styles.orangeButton}
                  />
                </Link>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <ActionButton Icon={CrossIcon} text="IGNORE GROUP" onClick={handleIgnoreClusterRequest} />
              <ActionButton
                className={styles.orangeButton}
                Icon={WhiteCheckIcon}
                text="TAKE ACTION ON GROUP"
                onClick={onTakeActionOnGroupRequest}
              />
            </Fragment>
          )}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px #e8e8e8 solid;
    height: 80px;
    width: 100%;
    z-index: 2;

    &.multiMatchView {
      position: sticky;
      top: 0;
      height: 110px;
      margin-bottom: 30px;
      border-bottom: 0;
      padding: 10px 0;

      &::after {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 110px;
        background-color: #f7f7f7;
        border-bottom: 1px solid #eaeaea;
        z-index: -1;
      }
    }
  `,
  buttonsWrapper: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: 15px;

    .actionButton {
      width: 90px;
      height: 75px;
    }
  `,
  orangeButton: css`
    background-color: #ffa168;
    color: white;
  `,
  title: css`
    display: flex;
    align-items: center;
    gap: 10px;

    h2 {
      font-size: 22px;
      font-weight: 600;
      margin: 0;
      max-width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    span {
      font-size: 13px;
    }
  `,
  infoWrapper: css`
    display: flex;
    align-items: flex-end;
    gap: 20px;
    margin-left: auto;
    margin-right: 10px;
  `,
  infoItem: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  `,
}

export default ClusterHeader
