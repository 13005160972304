import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { css, cx } from 'react-emotion'
import { connect } from 'react-redux'
import { notifSend } from 'redux-notifications/lib/actions'
import axios from 'axios'
import { Button } from 'common'
import { withCookies } from 'react-cookie'
import { requestEmailVerification, load } from '../../../redux/modules/auth'

// @TODO: Can come from a constants file for avoiding future typos
const COOKIE_KEY = 'pixsy-email-verify-ignore'

class VerifyEmailContainer extends React.Component {
  state = { checkingToken: true, isVerified: false }

  isErrorResponse = false

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { token } = this.props
    axios
      .put(`/api/users/me/verify-email/${token}`)
      .catch(this.handleVerificationFail)
      .then(this.handleVerificationSuccess)
  }

  handleVerificationSuccess = () => {
    if (this.isErrorResponse) return
    this.setState({ checkingToken: false, isVerified: true })
    this.props.load()

    try {
      // set the cookie that the email was verified
      this.props.cookies.set(COOKIE_KEY, 1, {
        path: '/',
        expires: moment()
          .add(1, 'day')
          .toDate(),
      })
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }

  handleVerificationFail = e => {
    this.isErrorResponse = true

    const { notifSend } = this.props

    console.error(e)
    if (e.response && e.response.data) {
      notifSend({
        message: e.response.data.message,
        kind: 'warning',
        dismissAfter: 10e3,
      })

      this.setState(state => ({ checkingToken: false, isVerified: false }))
    }
  }

  render() {
    const { user, requestEmailVerification } = this.props
    return (
      <div className={cx(wrapper, !user && paddingTop)}>
        <Helmet title="Verify Email" />
        <div className={card}>
          {this.state.checkingToken ? (
            <h3>Checking</h3>
          ) : this.state.isVerified ? (
            <div>
              <h3>Email verified</h3>
              <p>
                Your account is now active. Review your matches to see where and
                how your work has been used.
              </p>
              <Link to="/matches">
                <Button>Get started</Button>
              </Link>
            </div>
          ) : (
            <div>
              <h3>Email verification failed.</h3>
              <p>
                Please check if the link was correct or{' '}
                <span css={resendLink} onClick={requestEmailVerification}>
                  resend the link
                </span>
                .
              </p>
            </div>
          )}
        </div>
        {user ? (
          ''
        ) : (
          <div className={below}>
            <Link to="/login" className="smallText">
              Login now
            </Link>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  token: props.match.params.token,
})

export default connect(mapStateToProps, {
  requestEmailVerification,
  load,
  notifSend,
})(withCookies(VerifyEmailContainer))

VerifyEmailContainer.propTypes = {
  requestEmailVerification: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  notifSend: PropTypes.func.isRequired,
}

const paddingTop = css`
  padding-top: 100px;
`

const resendLink = css`
  font-weight: bold;
  cursor: pointer;
  color: #008aab;
`

const wrapper = css`
  // max-width: 370px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  // background-color: white;
  // flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 5px;
  }
`

const card = css`
  padding: 30px;
  background-color: white;
  text-align: center;
  // box-shadow: 0px 0px 37px -6px rgba(0, 0, 0, 0.75);
  // border-top: 3px solid #008aab;
  &.wide {
    max-width: 800px;
  }
  img {
    display: block;
    margin: 0 auto 10px;
    width: 300px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 52px;
    max-height: calc(100vh - 50px - 100px);
    overflow-y: scroll;
  }
`

const below = css`
  padding: 15px 0 15px 0;
  a {
    color: #3b3b3b;
    text-shadow: 1px 1px 20px lightgrey;
    font-size: 1.15em;
    &:hover span {
      text-decoration: underline;
    }
    span {
      font-weight: 600;
      letter-spacing: -0.025em;
    }
  }
`
