import Grow from '@material-ui/core/Grow'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React, { useCallback, useState } from 'react'
import { css, cx } from 'react-emotion'

/**
 * items - array of items (any, unique, not repeated)
 * itemActive - the item from `items` array that is currently active
 * disabled - if all items are disabled
 * renderItem - function that wraps each item
 *  e.g:
 *    <PixsyBreadcrumbs
 *      items={['a', 'b', 'c']}
 *      itemActive="a"
 *      renderItem={({ active, index, disabled, item, itemActive }) => (
 *        <PixsyBreadcrumbsLink
 *          active={active}
 *          disabled={disabled}
 *        >
 *          {item}
 *        </PixsyBreadcrumbsLink>
 *      )}
 *    />
 */
export const PixsyBreadcrumbs = ({
  items,
  itemActive,
  disabled,
  renderItem,
}) => {
  const [[hoveredItem /*,hoveredIndex*/], setHovered] = useState([null, null])

  // https://next.material-ui.com/customization/breakpoints/
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isBigScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const isSuperBigScreen = useMediaQuery(theme.breakpoints.down('xl'))

  let timeoutOnLeave = null

  const handleMouseLeaveBreadcrumb = useCallback(() => {
    clearTimeout(timeoutOnLeave)
    setTimeout(() => {
      setHovered([null, null])
    }, 400)
  }, [])
  const handleItemHovered = useCallback(state => {
    clearTimeout(timeoutOnLeave)
    setHovered(state)
  }, [])

  /**
   * Use odd numbers. e.g: if ...
   *  MAX_DISPLAY_ITEMS = 3,
   *  has 7 items (0 to 6),
   *  and item with index 2 is selected:
   *
   * ['one', 'two', 'three', 'four', 'five', 'six', 'seven']
   *   0       1       2       3       4       5      6
   *  Hide   (Show   [Show]   Show)   Hide   Hide    Hide
   *
   * You can't display this nicely with even numbers
   **/
  const MAX_DISPLAY_ITEMS =
    (isSmallScreen && 3) ||
    (isMediumScreen && 5) ||
    (isBigScreen && 7) ||
    (isSuperBigScreen && items.length)

  const MIDDLE_ITEM = (MAX_DISPLAY_ITEMS / 2) | 0
  const INDEX = items.findIndex(i =>
    hoveredItem !== null ? i === hoveredItem : i === itemActive
  )

  let LEFT_DISPLAYED = !~INDEX ? 0 : INDEX - MIDDLE_ITEM
  let RIGHT_DISPLAYED = !~INDEX ? items.length - 1 : INDEX + MIDDLE_ITEM

  if (LEFT_DISPLAYED < 0) {
    RIGHT_DISPLAYED += Math.abs(LEFT_DISPLAYED)
  } else if (RIGHT_DISPLAYED > items.length - 1) {
    LEFT_DISPLAYED -= Math.abs(RIGHT_DISPLAYED - items.length + 1)
  }

  return (
    <nav
      data-type="pixsyBreadcrumbs"
      css={pixsyBreadcrumbs}
      onMouseLeave={handleMouseLeaveBreadcrumb}
    >
      <ol
        data-type="pixsyBreadcrumbsContainer"
        // css={pixsyBreadcrumbsContainer}
        className={cx(pixsyBreadcrumbsContainer, {
          // [translateLeft]:
          //   hoveredItem !== null && hoveredIndex < items.length / 2,
          // [translateRight]:
          //   hoveredItem !== null && hoveredIndex > items.length / 2,
        })}
      >
        {items.map((item, indx) => {
          const isItemVisible =
            indx >= LEFT_DISPLAYED && indx <= RIGHT_DISPLAYED
          const isDotsVisible =
            indx === LEFT_DISPLAYED - 1 ||
            indx === RIGHT_DISPLAYED + 1 ||
            indx === LEFT_DISPLAYED - 2 ||
            indx === RIGHT_DISPLAYED + 2

          return (
            <React.Fragment key={item}>
              {!isItemVisible ? (
                item !== hoveredItem && isDotsVisible ? (
                  <React.Fragment>
                    <li
                      key={item}
                      onMouseEnter={() => handleItemHovered([item, indx])}
                      css={dots}
                    >
                      <MoreIcon fontSize="inherit" />
                    </li>
                    {indx !== items.length - 1 && <PixsyBreadcrumbsSeparator />}
                  </React.Fragment>
                ) : null
              ) : (
                <React.Fragment>
                  <Grow in>
                    <li
                      key={item}
                      // onMouseEnter={() => handleItemHovered([item, indx])}
                      css={pixsyBreadcrumbItem}
                    >
                      {renderItem({
                        active: itemActive === item,
                        index: indx,
                        disabled,
                        item,
                        itemActive,
                      })}
                    </li>
                  </Grow>
                  {indx !== items.length - 1 && <PixsyBreadcrumbsSeparator />}
                </React.Fragment>
              )}
            </React.Fragment>
          )
        })}
      </ol>
    </nav>
  )
}

const PixsyBreadcrumbsSeparator = ({ onMouseEnter }) => (
  <li
    data-type="pixsyBreadcrumbsSeparator"
    onMouseEnter={onMouseEnter}
    css={pixsyBreadcrumbsSeparator}
  >
    <NavigateNextIcon fontSize="inherit" />
  </li>
)

const pixsyBreadcrumbItem = css`
  margin: 0 10px;
`
const pixsyBreadcrumbs = css`
  width: 100%;
  overflow: hidden;
  margin: 0 20px;
`
const pixsyBreadcrumbsContainer = css`
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  transition: 0.2s ease-out;
  font-weight: 600;
  /* > li {
    margin-left: 10px;
    margin-right: 10px;
  } */
`
const pixsyBreadcrumbsSeparator = css`
  svg {
    font-size: 1.25em;
    vertical-align: middle;
    color: #c7c7c7;
  }
`
// const translateLeft = css`
//   transition: 0.2s ease-in;
//   transform: translateX(15px);
// `
// const translateRight = css`
//   transition: 0.2s ease-in;
//   transform: translateX(-15px);
// `
const dots = css`
  font-size: 1em;
  color: #c7c7c7;

  svg {
    vertical-align: bottom;
  }
`
