import React from 'react'
import { css } from 'react-emotion'
import PropTypes from 'prop-types'

const styles = css`
  position: relative;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 40px;

  h1 {
    margin: 0 auto 25px;
    text-align: center;
    font-weight: 300;
    font-size: 32px;

    ~ span {
      margin-top: -10px;
      display: block;
      text-align: center;
      color: #3b3b3b80;
      font-size: 0.9em;
    }
  }

  h1 {
    margin: 0 auto 25px;
    text-align: center;
    font-weight: 300;
    font-size: 32px;
  }

  h4 {
    font-weight: 400;
    font-size: 1.5em;
  }

  & + & {
    margin-top: 30px;
  }

  @media(max-width: 768px) {
    margin: 10px;
    padding: 20px;
  }
`

const centerStyles = css`
  text-align: center;
`
export default function ContentSection({
  id,
  centered,
  children,
  customClass,
}) {
  return (
    <section
      id={id}
      className={`${styles} ${centered ? centerStyles : ''} ${customClass}`}
    >
      {children}
    </section>
  )
}

ContentSection.propTypes = {
  centered: PropTypes.bool,
  id: PropTypes.string,
  customClass: PropTypes.string,
}

ContentSection.defaultProps = {
  centered: false,
  id: null,
  customClass: '',
}
