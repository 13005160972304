import * as React from 'react'
import propTypes from 'prop-types'

const styles = {
  fill: 'none',
  stroke: 'currentColor',
  strokeWidth: '2px',
}
const IconGrid = ({ height, width }) => (
  <svg viewBox="0 0 17.185 12.174" height={height} width={width}>
    <g id="Group_1226" data-name="Group 1226" transform="translate(0 1)">
      <g id="Group_1223" data-name="Group 1223" transform="translate(0 0)">
        <line id="Line_576" data-name="Line 576" style={styles} x2="3.861" />
        <line
          id="Line_577"
          data-name="Line 577"
          style={styles}
          x2="3.861"
          transform="translate(0 5.087)"
        />
        <line
          id="Line_578"
          data-name="Line 578"
          style={styles}
          x2="3.861"
          transform="translate(0 10.174)"
        />
      </g>
      <g id="Group_1224" data-name="Group 1224" transform="translate(6.662 0)">
        <line id="Line_576-2" data-name="Line 576" style={styles} x2="3.861" />
        <line
          id="Line_577-2"
          data-name="Line 577"
          style={styles}
          x2="3.861"
          transform="translate(0 5.087)"
        />
        <line
          id="Line_578-2"
          data-name="Line 578"
          style={styles}
          x2="3.861"
          transform="translate(0 10.174)"
        />
      </g>
      <g id="Group_1225" data-name="Group 1225" transform="translate(13.324 0)">
        <line id="Line_576-3" data-name="Line 576" style={styles} x2="3.861" />
        <line
          id="Line_577-3"
          data-name="Line 577"
          style={styles}
          x2="3.861"
          transform="translate(0 5.087)"
        />
        <line
          id="Line_578-3"
          data-name="Line 578"
          style={styles}
          x2="3.861"
          transform="translate(0 10.174)"
        />
      </g>
    </g>
  </svg>
)
IconGrid.propTypes = {
  height: propTypes.number,
  width: propTypes.number,
}

export default IconGrid
