import React from 'react'
import { PixsyGrid, PixsyGridItem } from '..'

/**
 * Group buttons
 *  Each child is wrapped in a PixsyGridItem with the same width
 *  e.g:
 *    if two children, then each is given 6 columns (grid has 12 columns)
 *    so it shown as 50% width on each one
 */
export function PixsyButtonGroup({
  children,
  containerStyle = {},
  itemStyle = {},
}) {
  const childrenCount = React.Children.count(children)
  const itemSize = Math.floor(12 / childrenCount)
  return (
    <PixsyGrid alignItems="center" spacing={0} {...containerStyle}>
      {React.Children.map(children, (child, i) => (
        <PixsyGridItem
          key={i}
          xs={12}
          md={itemSize}
          flexGrow={1}
          {...itemStyle}
        >
          {child}
        </PixsyGridItem>
      ))}
    </PixsyGrid>
  )
}
