import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Button } from 'common'
import ImageWizardSelection from './ImageWizardSelection'

export default class ImageWizard extends PureComponent {
  state = { current: 0 }

  componentDidMount() {
    // Workaround issue with react-slick
    // https://github.com/akiran/react-slick/issues/809
    setTimeout(() => {
      this.refs.slick && this.refs.slick.innerSlider.onWindowResized()
    })
  }

  afterChange = i => {
    this.setState({ current: i })
    this.props.onSlideChange && this.props.onSlideChange(i)
  }

  handleNext = () => {
    this.refs.slick.slickNext()
  }

  handlePrevious = () => {
    this.refs.slick.slickPrev()
  }

  handleGoTo = i => {
    this.refs.slick && this.refs.slick.slickGoTo(i)
  }
  render() {
    const { images, render } = this.props
    const { current } = this.state
    // const Wrapper =  wrap || (({ children }) => children)

    const validStates = null
    const invalidImagesCount = 0

    return (
      <div css={wrap}>
        <ImageWizardSelection
          images={images}
          selectedIndex={current}
          onSelect={this.handleGoTo}
          validStates={validStates}
        />

        <Slider
          ref="slick"
          draggable={false}
          infinite={false}
          arrows={false}
          dots={false}
          afterChange={this.afterChange}
        >
          {images.map((i, index) => (
            <div key={i._id}>{render({ index, image: images[index] })}</div>
          ))}
        </Slider>
        <p css={hotlinkingNote}>
          If the infringing image URL is hosted on your site, be aware you are
          unable to send a notice. Manage and whitelist your own domains{' '}
          <Link to="/profile/settings/">here</Link>.
        </p>

        {images.length > 1 && (
          <div css={bottomNav}>
            <Button
              hoverMain
              onClick={this.handlePrevious}
              disabled={current === 0}
            >
              Previous Image
            </Button>
            <div className="centerContent">
              <p>
                Image {current + 1} of {images.length}
              </p>
              {validStates && invalidImagesCount > 0 && (
                <span>
                  {invalidImagesCount} images still need your attention before
                  proceeding
                </span>
              )}
            </div>
            <Button
              hoverMain
              onClick={this.handleNext}
              disabled={current === images.length - 1}
            >
              Next Image
            </Button>
          </div>
        )}
      </div>
    )
  }
}

ImageWizard.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      preview: PropTypes.string,
    })
  ),
}

const wrap = css`
  margin-bottom: 20px;
`

const bottomNav = css`
  margin: 25px 0 40px;
  display: flex;
  flex-direction: row;

  button {
    width: 220px;
    height: 50px;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }

  p {
    font-weight: 400;
  }

  span {
    color: #43434380;
  }
  .centerContent {
    flex: 1;
    text-align: center;
    padding-top: 4px;
    > * {
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
    }
  }
`

const hotlinkingNote = css`
  color: #3b3b3bbf;
  font-size: 0.85em;
  text-align: center;
  margin: 0;
  transform: translateY(16px);
`
