import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cx, css } from 'react-emotion'
import { withCookies } from 'react-cookie'
import moment from 'moment'

const log = require('debug')('broadcast-banner')
class BroadcastBanner extends Component {
  constructor({ isActive, cookieKey }) {
    super()
    this.state = { hidden: !isActive }
    this.interval = null
  }

  componentWillReceiveProps(nextProps) {
    const { isActive } = this.props

    if (isActive === false && nextProps.isActive === true) {
      log('isActive false => true')
      this.enable()
    }
    if (isActive === true && nextProps.isActive === false) {
      log('isActive true => false')
      this.setState({
        hidden: true,
      })
    }
  }

  componentDidMount() {
    if (this.props.isActive) {
      this.enable()
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  enable() {
    log('enable')
    const { cookies, cookieKey } = this.props

    // @TODO: Need to update the react-cookie version to version 3 and use `useCookie` and remove setInterval
    this.interval = setInterval(() => {
      log('timer!')
      const value = cookies.get(cookieKey)
      if (value && Boolean(value)) {
        clearInterval(this.interval)
        this.setState({
          hidden: Boolean(value),
        })
      }
    }, 1000)
  }

  handleClose = () => {
    log('handle close')
    const { cookies, cookieKey } = this.props
    this.setState({ hidden: true })

    // Not setting expiry -> session cookie
    cookies.set(cookieKey, 1, {
      path: '/',
      expires: moment()
        .add(1, 'hour')
        .toDate(),
    })
  }

  render() {
    const { children } = this.props
    const { hidden } = this.state

    return (
      <div css={cx(wrap, hidden && hiddenStyles)}>
        {children}
        <div
          onClick={this.handleClose}
          css={notificationBarCloseButton}
          dangerouslySetInnerHTML={{ __html: require(`./closeIcon.svg`) }}
        />
      </div>
    )
  }
}

export default withCookies(BroadcastBanner)

BroadcastBanner.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  cookieKey: PropTypes.string.isRequired,
}

const wrap = css`
  display: flex;
  width: calc(100% - 110px);
  position: fixed;
  top: 0px;
  left: 110px;
  z-index: 100;
  height: 55px;
  background-color: #008aab;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  transition: all 250ms ease;
  box-shadow: 11px 3px 9px 1px rgba(0, 0, 0, 0.75);

  @media (max-width: 1280px) {
    width: calc(100% - 95px);
    left: 95px;
    height: 47.5px;
  }

  a {
    display: inline-block;
    line-height: 1;
    margin-left: 10px;
    position: relative;
    bottom: -1px;
    color: white;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    left: 0;
    right: 0;
    width: auto;
    // padding-right: 60px;
    padding: 10px 60px 10px 10px;
    height: auto;
    min-height: 55px;
    box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.75);
  }
`

const hiddenStyles = css`
  opacity: 0;
  top: -60px;

  @media (max-width: 768px) {
    top: -100px;
  }
`

const notificationBarCloseButton = css`
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 55px;
  cursor: pointer;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 13px;
    width: 13px;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`
