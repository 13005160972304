import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

export default function SexyDefinitionList({ items }) {
  return (
    <dl css={wrap}>
      {items.filter(x => x && x[1]).map(([title, content], i) => (
        <React.Fragment key={i}>
          <dt css={dt}>{title}</dt>
          <dd css={dd}>
            {typeof content === 'function' ? content() : content}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  )
}

SexyDefinitionList.propTypes = { items: PropTypes.array.isRequired }

const wrap = {}

const dl = css`
  padding: 10px 0 5px;
  flex: 1;
  justify-content: space-between;
  min-width: 0;
  overflow-wrap: break-word;
  margin-bottom: 10px;
`

const dd = css`
  margin-top: 0px;
  margin-bottom: 18px;
  padding: 0 0 3px;
  word-break: break-word;

  svg {
    vertical-align: 1px;
    height: 8px;
    margin-right: 3px;
  }
  a {
    color: #bbb;
  }
`

const dt = css`
  color: #43434380;
  font-size: 0.9em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.15em;
`
