import { USER, USER_ARRAY } from './schemas' // eslint-disable-line import/named

export const USERS_INVALIDATE = 'USERS_INVALIDATE'

const USERS_LOAD = 'USERS_LOAD'
export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS'
const USERS_LOAD_FAIL = 'USERS_LOAD_FAIL'

const USER_LOAD = 'USER_LOAD'
const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS'
const USER_LOAD_FAIL = 'USER_LOAD_FAIL'

const USER_RESET_SOURCE = 'USER_RESET_SOURCE'
const USER_RESET_SOURCE_SUCCESS = 'USER_RESET_SOURCE_SUCCESS'
const USER_RESET_SOURCE_FAIL = 'USER_RESET_SOURCE_FAIL'

const USER_GIFT_TAKEDOWNS = 'USER_GIFT_TAKEDOWNS'
const USER_GIFT_TAKEDOWNS_SUCCESS = 'USER_GIFT_TAKEDOWNS_SUCCESS'
const USER_GIFT_TAKEDOWNS_FAIL = 'USER_GIFT_TAKEDOWNS_FAIL'

const USER_LOAD_EMAIL_PREVALIDATION = 'USER_LOAD_EMAIL_PREVALIDATION'
const USER_LOAD_EMAIL_PREVALIDATION_SUCCESS =
  'USER_LOAD_EMAIL_PREVALIDATION_SUCCESS'
const USER_LOAD_EMAIL_PREVALIDATION_FAIL = 'USER_LOAD_EMAIL_PREVALIDATION_FAIL'

const USER_LOAD_PASSWORD_STRENGTH = 'USER_LOAD_PASSWORD_STRENGTH'
const USER_LOAD_PASSWORD_STRENGTH_SUCCESS =
  'USER_LOAD_PASSWORD_STRENGTH_SUCCESS'
const USER_LOAD_PASSWORD_STRENGTH_FAIL = 'USER_LOAD_PASSWORD_STRENGTH_FAIL'

const USER_LOAD_USAGE = 'USER_LOAD_USAGE'
const USER_LOAD_USAGE_SUCCESS = 'USER_LOAD_USAGE_SUCCESS'
const USER_LOAD_USAGE_FAIL = 'USER_LOAD_USAGE_FAIL'

const HIDE_TOP_MATCH_MODAL = 'HIDE_TOP_MATCH_MODAL'
const HIDE_TOP_MATCH_MODAL_SUCCESS = 'HIDE_TOP_MATCH_MODAL_SUCCESS'
const HIDE_TOP_MATCH_MODAL_FAIL = 'HIDE_TOP_MATCH_MODAL_FAIL'

const VALIDATE_REFERRAL_CODE = 'VALIDATE_REFERRAL_CODE'
const VALIDATE_REFERRAL_CODE_SUCCESS = 'VALIDATE_REFERRAL_CODE_SUCCESS'
const VALIDATE_REFERRAL_CODE_FAIL = 'VALIDATE_REFERRAL_CODE_FAIL'

const HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL =
  'HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL'
const HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL_SUCCESS =
  'HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL_SUCCESS'
const HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL_FAIL =
  'HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL_FAIL'

const initialState = {
  loading: false,
  loaded: false,
  total: 0,
  passwordStrength: {},
  usage: {},
}

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case USERS_LOAD:
    case USER_LOAD:
    case USER_RESET_SOURCE:
    case USER_GIFT_TAKEDOWNS:
    case USER_LOAD_USAGE:
      return {
        ...state,
        loading: true,
      }
    case USERS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        // total is only included in response when explicitly requested
        total: isFinite(action.payload.total)
          ? action.payload.total
          : state.total,
      }
    case USER_LOAD_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usage: action.payload,
      }
    case USER_LOAD_SUCCESS:
    case USER_RESET_SOURCE_SUCCESS:
    case USER_GIFT_TAKEDOWNS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case USERS_LOAD_FAIL:
    case USER_LOAD_PASSWORD_STRENGTH_FAIL:
    case USER_LOAD_USAGE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      }
    case USER_LOAD_PASSWORD_STRENGTH_SUCCESS:
      return {
        ...state,
        passwordStrength: action.payload,
      }
    default:
      return state
  }
}

export function defaultQuery() {
  return {
    page: 1,
    search: '',
    sort: '-_id',
  }
}

export function paginationKey(query) {
  return query.page + '-' + query.sort + '-' + query.search
}

export function isLoaded(globalState) {
  return globalState.users && globalState.users.loaded
}

export function invalidate(query) {
  return {
    type: USERS_INVALIDATE,
    schema: { users: USER_ARRAY },
    payload: { query },
  }
}

export function loadUsers(query) {
  return {
    types: [USERS_LOAD, USERS_LOAD_SUCCESS, USERS_LOAD_FAIL],
    promise: client => client.get('/users', { params: query }),
    schema: { users: USER_ARRAY },
    payload: { query },
  }
}

export function prevalidateEmailAddress(email) {
  return {
    types: [
      USER_LOAD_EMAIL_PREVALIDATION,
      USER_LOAD_EMAIL_PREVALIDATION_SUCCESS,
      USER_LOAD_EMAIL_PREVALIDATION_FAIL,
    ],
    promise: client =>
      client.post('/users/email-prevalidation', {
        data: { email },
      }),
  }
}

export function getPasswordStrength(password) {
  return {
    types: [
      USER_LOAD_PASSWORD_STRENGTH,
      USER_LOAD_PASSWORD_STRENGTH_SUCCESS,
      USER_LOAD_PASSWORD_STRENGTH_FAIL,
    ],
    promise: client =>
      client.post('/users/password-strength', {
        data: { password },
      }),
  }
}

export function validateReferralCode(code) {
  return {
    types: [
      VALIDATE_REFERRAL_CODE,
      VALIDATE_REFERRAL_CODE_SUCCESS,
      VALIDATE_REFERRAL_CODE_FAIL,
    ],
    promise: client => client.get(`/codes/verify?code=${code}`),
  }
}

export function loadUser(id) {
  return {
    types: [USER_LOAD, USER_LOAD_SUCCESS, USER_LOAD_FAIL],
    promise: client => client.get(`/users/${id}`),
    schema: USER,
  }
}

export function resetSource(id, source) {
  return {
    types: [
      USER_RESET_SOURCE,
      USER_RESET_SOURCE_SUCCESS,
      USER_RESET_SOURCE_FAIL,
    ],
    promise: client => client.delete(`/users/${id}/sources/${source}`),
  }
}

export function giftTakedowns(id, numTakedowns) {
  return {
    types: [
      USER_GIFT_TAKEDOWNS,
      USER_GIFT_TAKEDOWNS_SUCCESS,
      USER_GIFT_TAKEDOWNS_FAIL,
    ],
    promise: client =>
      client.put(`/users/${id}/takedowns`, { data: { numTakedowns } }),
  }
}

export function getUsage() {
  return {
    types: [USER_LOAD_USAGE, USER_LOAD_USAGE_SUCCESS, USER_LOAD_USAGE_FAIL],
    promise: client => client.get('/users/me/usage'),
  }
}

export function hideTopMatchModal() {
  return {
    types: [
      HIDE_TOP_MATCH_MODAL,
      HIDE_TOP_MATCH_MODAL_SUCCESS,
      HIDE_TOP_MATCH_MODAL_FAIL,
    ],
    promise: client => client.post('/users/modal/topmatch'),
    schema: {
      user: USER,
    },
  }
}

export function hidePrefilledCaseSubmissionInfoModal() {
  return {
    types: [
      HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL,
      HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL_SUCCESS,
      HIDE_PREFILLED_CASE_SUBMISSION_INFO_MODAL_FAIL,
    ],
    promise: client => client.post('/users/modal/casesubinfo'),
    schema: {
      user: USER,
    },
  }
}
