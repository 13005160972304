import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { CrashSite } from 'common'
import { connect } from 'react-redux'
import ApiClient from '../redux/apiClient'

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null }

  /* @see https://gist.github.com/impressiver/5092952 */
  static BLACKLIST_URLS = [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /popup_closed_by_user/i, // google import
    /^chrome:\/\//i,
    // Other plugins
    /frame\.[a-z0-9]{8}\.js/i, // intercom
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /recaptcha\/releases/,
    /fbevents\.js/i, // facebook
    /kaspersky-labs\.com/i,
    /nortonsafeweb/i,
    /_avast_submit/i,
    /elevio-main\.js/i,
  ]

  static IGNORED_EXCEPTION_TYPES = ['UnhandledRejection']

  static IGNORED_ERRORS = [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    'Network Error',
    '"access_denied"', // google import
    '"popup_closed_by_user"',
    '"popup_closed_by_browser"',
    'Permission denied to access property "setData"',
    '"password":"', // errors from login screen
    'UnhandledRejection: {"referral_code": "Invalid code"}',
  ]

  initSentry(user) {
    const userFullName = user
      ? `${user.details.first_name} ${user.details.last_name}`
      : 'no_user' // Sentry expects a name

    const environment = window.PRODUCTION
      ? 'production'
      : window.NODE_ENV === 'production'
        ? 'staging'
        : 'development'

    Sentry.init({
      dsn: window.SENTRY_DSN,
      blacklistUrls: ErrorBoundary.BLACKLIST_URLS,
      environment,

      beforeSend: (event, hint) => {
        const exceptions = event.exception.values

        if (exceptions.some((e) => ErrorBoundary.IGNORED_EXCEPTION_TYPES.includes(e.type))) {
          return null
        }

        const extra = event.extra || {}
        const xhr = extra.Error && extra.Error.request

        extra.auth = this.context.store.getState().auth

        if (xhr) {
          // if (xhr.status >= 500) {
          //   // ignore error
          //   return null
          // }
          // check if oops tag has been set, otherwise set to false
          if (!event.tags) {
            event.tags = {}
            event.tags.oops = false
          } else if (event.tags && !event.tags.oops) {
            event.tags.oops = false
          }
        }

        if (environment !== 'development' && event.exception && event.tags && event.tags.oops) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
              email: user ? user.email : 'dev+sentry-oops-no-user@pixsy.com', // Sentry expects an email with correct format
              name: userFullName,
            },
          })
        }

        return event
      },
      // via webpack define plugin:
      release: `${__COMMIT_HASH__}`, // eslint-disable-line
      ...(!__DEVELOPMENT__ && {
        integrations: integrations =>
          integrations.filter(
            integration => integration.name !== 'Breadcrumbs'
          ),
      }),
    })
  }

  updateUserInSentryContext(user) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user._id,
        email: user.email,
        username: `${user.details.first_name} ${user.details.last_name}`,
        ...Object.fromEntries(
          Object.entries(user.features).map(([key, value]) => [`feature_${key}`, value])
        )
      })
    })
  }

  trackErrorInBackend(user, error, errorInfo) {
    try {
      const client = new ApiClient()
      client.put('/error', {
        data: {
          error: error.message,
          stack: error.stack,
          errorInfo,
          user: {
            id: user._id,
            email: user.email,
            username: `${user.details.first_name} ${user.details.last_name}`,
            ...Object.fromEntries(
              Object.entries(user.features).map(([key, value]) => [`feature_${key}`, value])
            )
          },
          location: window.location.href,
        },
      })
    } catch (err) {}
  }

  trackErrorInMixpanel(error, errorInfo) {
    try {
      window.mixpanel.track('Oops', {
        extra: errorInfo,
        message: error.message,
      })
    } catch (e) {}
  }

  trackErrorInSentry(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      scope.setTag('oops', true)

      Sentry.captureException(error)
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props
    const { error, errorInfo } = this.state

    if (error) {
      this.trackErrorInBackend(user, error, errorInfo)
    }

    if (user !== prevProps.user && user) {
      this.updateUserInSentryContext(user)
    }

    if (__CLIENT__ && error && !prevState.error) {
      if (window.mixpanel) {
        this.trackErrorInMixpanel(error, errorInfo)
      }

      const errorShouldBeIgnored = ErrorBoundary.IGNORED_ERRORS.some((err) => err.includes(error.message))

      if (!errorShouldBeIgnored) {
        this.trackErrorInSentry(error, errorInfo)
      }
    }
  }

  componentDidMount() {
    const { user } = this.props

    this.initSentry(user)

    if (user) {
      this.updateUserInSentryContext(user)
    }
    window.__COMMIT_HASH__ = `${__COMMIT_HASH__}` // eslint-disable-line
  }

  render() {
    const component = this.state.error ? (
      //render fallback UI
      <CrashSite error={this.state.error} />
    ) : (
      //when there's not an error, render children untouched
      this.props.children
    )

    return <React.Fragment>{component}</React.Fragment>
  }
}

export default connect(state => ({ user: state.auth.user }))(ErrorBoundary)

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

ErrorBoundary.contextTypes = { store: PropTypes.object }
