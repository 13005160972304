import Fade from '@material-ui/core/Fade'
import ImageIcon from '@material-ui/icons/Image'
import { Button, Dialog } from 'common'
import { produce } from 'immer'
import flow from 'lodash/flow'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isMatch from 'lodash/isMatch'
import debounce from 'lodash/debounce'
import Helmet from 'react-helmet'
import { BUCKETS, MIME_TYPES } from 'pixsy-constants'
import { ImageSchema } from 'pixsy-schema/case/ImageSchema'
import { MatchSchema } from 'pixsy-schema/case/MatchSchema'
import { SETTLEMENT_KEYS } from 'pixsy-schema/case/SettlementSchema'
import {
  GENERAL_ATTACH_INDEX,
  IMAGE_INDEX,
  MATCH_INDEX,
  SUBMISSION_NAMES as NAMES,
} from 'pixsy-schema/case/SubmissionSchema'
import { FILE_SOURCES } from 'pixsy-schema/file/FileSchema'
import * as React from 'react'
import { css } from 'react-emotion'
import { connect } from 'react-redux'
import randID from 'uuid/v4'
import { load as loadImages } from '../../../redux/modules/images'
import {
  MAX_GENERAL_FILES,
  MAX_IMAGE_IMPORT_FILES,
  STAGES,
  STAGE_CONTAINERS,
  STAGE_INFO,
  STAGE_LABELS,
} from './CaseConstants'
import { ConfirmRemovalDialog } from './FormSections/Modals/ConfirmRemovalDialog'
import { ImageOnPageDialog } from './FormSections/Modals/ImageOnPageModal'
import {
  assignIdsToImagesMatches,
  assignUUIDsToExistingImages,
  findImageByUUID,
  getDerivedStateForCaseName,
  getDerivedStateForCaseStatus,
  getDerivedStateForDomainName,
  getDerivedStateForEditableImages,
  getDerivedStateForImagesCompleted,
  getDerivedStateForSectionValidation,
  getInformationStatus,
  getInitialState,
  getNextStage,
  getSearchResultFromCache,
  getStateFromImagesSearchResult,
  getVirtualImagesQuery,
  gotoEditableImage,
  gotoNextOrPrevEditableImage,
  handleAddGeneralFiles,
  handleAddImageFiles,
  handleAddImageImportFiles,
  handleBeforeGeneralFileAdded,
  handleBeforeImageFileAdded,
  handleBeforeImageImportFileAdded,
  handleChangeGeneralFiles,
  handleChangeImageFiles,
  handleChangeImageImportFiles,
  makeUppyOptions,
  removeImageFromSubmission,
  triggerGA,
} from './CaseFunctional'
import {
  PixsyBreadcrumbs,
  PixsyBreadcrumbsLink,
  PixsyContainer,
  PixsyFileUploader,
  PixsyGrid,
  PixsyGridItem,
  PixsyPage,
  PixsyScrollTo,
  PixsyStickyBar,
} from './Components'
import { ImagesSidebar } from './FormSections/ImagesSidebar/ImagesSidebar'

/**
 * For dev:
 * - Set to `false` to allow manually saving form using `Save` button instead
 */
const ENABLE_AUTO_SAVE = true

const IMAGES_SEARCH_QUERY_SIZE = 8

/**
 * @typedef {import('./CaseSubmission').IProps} IProps
 * @typedef {import('./CaseSubmission').IState} IState
 * @typedef {import('./CaseSubmission').ImageValues} IImage
 * @typedef {import('./CaseSubmission').MatchValues} IMatch
 * @typedef {import('./CaseSubmission').CtrBeforeFileAdded} CtrBeforeFileAdded
 * @typedef {import('./CaseSubmission').CtrMethodFile} CtrMethodFile
 * @typedef {import('./CaseSubmission').IQuerySearchImage} IQuerySearchImage
 */

/**
 * @augments {React.Component<IProps, Readonly<IState>>}
 */
class CaseSubmissionContainer extends React.Component {
  /**
   * Shown in PixsyFileList components according to `file.source` value
   */
  sourceLocation = {
    [FILE_SOURCES.CASE_GENERAL_FILES]: `in review - Additional attachments`,
    [FILE_SOURCES.CASE_GENERAL_FILES_SCREENING_BREACH_CONTRACT]: `in screening - Evidence of breach of contract or terms`,
    [FILE_SOURCES.CASE_GENERAL_FILES_SCREENING_CONTACT_USER]: `in screening - Evidence of previous contact with image user`,
    [FILE_SOURCES.CASE_IMAGE_IMPORT]: `in import`,
    [FILE_SOURCES.CASE_IMAGE_IMPORT_EXISTING]: `in import`,
    [FILE_SOURCES.CASE_IMAGE_LICENSING_HISTORY_FILES]: `in image files`,
    [FILE_SOURCES.CASE_IMAGE_US_REGISTRATION_FILES]: `in image files`,
  }
  bucketGeneralFiles = global.PRODUCTION ? BUCKETS.CASE_UPLOAD : BUCKETS.CASE_UPLOAD_DEV
  bucketImagesImport = global.PRODUCTION ? BUCKETS.IMAGE_UPLOAD : BUCKETS.IMAGE_UPLOAD_DEV

  formScrollableContentRef = React.createRef()

  getServer(bucket) {
    return bucket.endsWith('development') ? 's3' : 's3-accelerate'
  }

  /**
   * @param {Readonly<IProps>} nextProps
   * @param {Readonly<IState>} prevState
   * @returns {IState | null}
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const emptyState = {}
    const nextState = flow(
      getDerivedStateForCaseName(nextProps, prevState),
      getDerivedStateForDomainName(nextProps, prevState),
      getDerivedStateForCaseStatus(nextProps, prevState),
      getDerivedStateForEditableImages(nextProps, prevState),
      getDerivedStateForSectionValidation(nextProps, prevState),
      getDerivedStateForImagesCompleted(nextProps, prevState)
    )(emptyState)

    if (nextState === emptyState) return null

    return nextState
  }

  constructor(props) {
    super(props)
    this.state = getInitialState(this.props)
    this.killUppy = this.startUppyWithOptions()
    this.props.subscribeToOnBlurEvent(this.triggerAutoSave)
    this.renderStageNavigation = this.renderStageNavigation.bind(this)
  }

  startUppyWithOptions = () => {
    const { policy, user } = this.props

    // General Uploads
    const generalFilesOpts = makeUppyOptions({
      source: FILE_SOURCES.CASE_GENERAL_FILES,
      bucket: this.bucketGeneralFiles,
      server: this.getServer(this.bucketGeneralFiles),
      policy: policy.generalAttachments,
      mime: MIME_TYPES.CASE_SUBMISSION.GENERAL_ATTACHMENTS,
      user,
    })
    const breachContractFileOpts = makeUppyOptions({
      source: FILE_SOURCES.CASE_GENERAL_FILES_SCREENING_BREACH_CONTRACT,
      bucket: this.bucketGeneralFiles,
      server: this.getServer(this.bucketGeneralFiles),
      policy: policy.generalAttachments,
      mime: MIME_TYPES.CASE_SUBMISSION.GENERAL_ATTACHMENTS,
      user,
    })
    const contactUserFileOpts = makeUppyOptions({
      source: FILE_SOURCES.CASE_GENERAL_FILES_SCREENING_CONTACT_USER,
      bucket: this.bucketGeneralFiles,
      server: this.getServer(this.bucketGeneralFiles),
      policy: policy.generalAttachments,
      mime: MIME_TYPES.CASE_SUBMISSION.GENERAL_ATTACHMENTS,
      user,
    })
    // Uploads in image section
    const usRegistrationFileOpts = makeUppyOptions({
      source: FILE_SOURCES.CASE_IMAGE_US_REGISTRATION_FILES,
      bucket: this.bucketGeneralFiles,
      server: this.getServer(this.bucketGeneralFiles),
      policy: policy.generalAttachments,
      mime: MIME_TYPES.CASE_SUBMISSION.GENERAL_ATTACHMENTS,
      user,
    })
    const licensingHistoryFileOpts = makeUppyOptions({
      source: FILE_SOURCES.CASE_IMAGE_LICENSING_HISTORY_FILES,
      bucket: this.bucketGeneralFiles,
      server: this.getServer(this.bucketGeneralFiles),
      policy: policy.generalAttachments,
      mime: MIME_TYPES.CASE_SUBMISSION.GENERAL_ATTACHMENTS,
      user,
    })
    // Image Import
    const imageImportFileOpts = makeUppyOptions({
      source: FILE_SOURCES.CASE_IMAGE_IMPORT,
      bucket: this.bucketImagesImport,
      server: this.getServer(this.bucketImagesImport),
      policy: policy.imageUpload,
      mime: MIME_TYPES.CASE_SUBMISSION.IMAGE_UPLOAD,
      user,
    })

    const getProps = () => this.props
    const getImage = (uuid) => findImageByUUID(uuid, this.props)
    const getImageRegFiles = (image) => image.__US_REGISTRATION_FILES__
    const getImageLicFiles = (image) => image.__LICENSING_HISTORY_FILES__

    this.generalFilesUploader = new PixsyFileUploader(generalFilesOpts)
    this.breachContractUploader = new PixsyFileUploader(breachContractFileOpts)
    this.contactUserUploader = new PixsyFileUploader(contactUserFileOpts)
    this.imageImportsUploader = new PixsyFileUploader(imageImportFileOpts)

    this.__usRegistrationFileUploaders = new Map()
    this.__licensingHistoryFileUploader = new Map()
    this.__generalUploaders = new Set([
      this.generalFilesUploader,
      this.breachContractUploader,
      this.contactUserUploader,
    ])

    flow(
      handleBeforeImageImportFileAdded(MAX_IMAGE_IMPORT_FILES),
      handleAddImageImportFiles,
      handleChangeImageImportFiles
    )({ getProps, u: this.imageImportsUploader })

    const handleUpload = debounce(() => {
      this.manuallyValidateWholeForm().then(this.triggerAutoSave)
    }, 3000)

    this.imageImportsUploader.onUploadSuccess(handleUpload)
    // this.imageImportsUploader.onUploadSuccess(this.triggerAutoSave)

    this.__generalUploaders.forEach((u) => {
      flow(
        handleBeforeGeneralFileAdded(MAX_GENERAL_FILES),
        handleAddGeneralFiles,
        handleChangeGeneralFiles
      )({ getProps, u })
      u.onUploadSuccess(() => this.manuallyValidateWholeForm().then(this.triggerAutoSave))
      // u.onUploadSuccess(this.triggerAutoSave)
    })

    this.getUSRegistrationUploader = (uuid) => {
      const u = this.__usRegistrationFileUploaders.get(uuid)

      if (u) return u

      const fieldKey = NAMES.images.__US_REGISTRATION_FILES__
      const uploader = new PixsyFileUploader(usRegistrationFileOpts)

      this.__usRegistrationFileUploaders.set(uuid, uploader)

      flow(
        handleBeforeImageFileAdded(MAX_GENERAL_FILES),
        handleAddImageFiles,
        handleChangeImageFiles
      )({
        getProps,
        u: uploader,
        getImage: () => getImage(uuid),
        getFiles: getImageRegFiles,
        getName: (i) => this.getNormalName(fieldKey, i),
      })

      uploader.onUploadSuccess(() => this.manuallyValidateWholeForm().then(this.triggerAutoSave))
      // uploader.onUploadSuccess(this.triggerAutoSave)

      return uploader
    }
    this.getLicensingUploader = (uuid) => {
      const u = this.__licensingHistoryFileUploader.get(uuid)

      if (u) return u

      const fieldKey = NAMES.images.__LICENSING_HISTORY_FILES__
      const uploader = new PixsyFileUploader(licensingHistoryFileOpts)

      this.__licensingHistoryFileUploader.set(uuid, uploader)

      flow(
        handleBeforeImageFileAdded(MAX_GENERAL_FILES),
        handleAddImageFiles,
        handleChangeImageFiles
      )({
        getProps,
        u: uploader,
        getImage: () => getImage(uuid),
        getFiles: getImageLicFiles,
        getName: (i) => this.getNormalName(fieldKey, i),
      })

      uploader.onUploadSuccess(() => this.manuallyValidateWholeForm().then(this.triggerAutoSave))
      // uploader.onUploadSuccess(this.triggerAutoSave)

      return uploader
    }

    return () => {
      /**
       * - Stop current uploads
       * - Remove files from uppy and form (reset)
       */
      this.imageImportsUploader.close()
      this.__usRegistrationFileUploaders.forEach((u) => u.close())
      this.__licensingHistoryFileUploader.forEach((u) => u.close())
      this.__generalUploaders.forEach((u) => u.close())
    }
  }
  componentWillUnmount() {
    this._isMounted = false
    if (this.selectedImageTimeout) clearTimeout(this.selectedImageTimeout)
    try {
      /**
       * Based on the issue report on the repository, a reset on uppy is required
       * in case of unmounting the component
       * @see https://github.com/transloadit/uppy/issues/996
       */
      this.generalFilesUploader.uppy.reset()
      this.breachContractUploader.uppy.reset()
      this.contactUserUploader.uppy.reset()
      this.imageImportsUploader.uppy.reset()
      this.killUppy()
    } catch (err) {}
  }

  componentDidMount() {
    this._isMounted = true
    /**
     * Form is started as readOnly in `CaseSubmissionProvider`
     * - the case cannot be edited while the first initial validation runs
     *   because the form contains no errors and is temporarily correct
     * - once initial validation finished, `props.errors` is populated
     *   and errors are displayed
     * - only then, the form is made editable only if the case was not submitted
     */
    this.manuallyValidateWholeForm().then(() => {
      if (!this._isMounted) return
      const { setFormReadOnly, values } = this.props

      if (!values.__DRAFT__) return

      setFormReadOnly(false)
    })
    const { values } = this.props
    const { stageActive } = this.state
    triggerGA(stageActive, values && values.submission && values.submission.source)
  }

  removeGeneralFile = (fid) => {
    const { values, setFieldValue } = this.props
    const files = values.__GENERAL_ATTACHMENTS__
    const nextFiles = files.filter((file) => file.id !== fid)

    this.__generalUploaders.forEach((u) => u.removeFile(fid))

    setFieldValue(SETTLEMENT_KEYS.__GENERAL_ATTACHMENTS__, nextFiles)

    this.triggerAutoSave()
  }
  retryGeneralFile = (fid) => {
    this.__generalUploaders.forEach((u) => u.retryUpload(fid))
  }

  /**
   * Removes a file from image
   * - Licensing or US Registration files
   */
  removeImageFile = (fid, uuid, type) => {
    const { images: IMAGE } = NAMES
    const { values, setFieldValue, getValue } = this.props
    const { images } = values
    const index = images.findIndex((i) => i.uuid === uuid)

    if (!~index) return

    let name
    let files

    if (type === IMAGE.__LICENSING_HISTORY_FILES__) {
      name = this.getNormalName(IMAGE.__LICENSING_HISTORY_FILES__, index)
      files = getValue(name)
    } else if (type === IMAGE.__US_REGISTRATION_FILES__) {
      name = this.getNormalName(IMAGE.__US_REGISTRATION_FILES__, index)
      files = getValue(name)
    }

    if (Array.isArray(files)) {
      setFieldValue(
        name,
        files.filter((f) => f.id !== fid)
      )
      this.triggerAutoSave()
    }
  }

  removeImage = (uuid) => {
    this.setState(
      (state) => removeImageFromSubmission(state, this.props, this.imageImportsUploader, uuid),
      () => this.manuallyValidateWholeForm().then(this.triggerAutoSave)
    )
  }

  showConfirmImageRemovalDialog = (uuid) => {
    const {
      values: { matches, images },
      case: caze,
    } = this.props

    if (!uuid) return

    if (images.length === 1) return false

    if (get(caze, 'cm.changeRequested')) {
      const imageToDelete = images.find((i) => i.uuid === uuid)
      if (!imageToDelete || caze.submission.images.some((i) => i._id === imageToDelete._id)) {
        return
      }
    }

    const matchIndex = matches.findIndex((m) => m.uuid === uuid)

    if (~matchIndex) {
      this.setState({
        confirmImageRemovalIndex: matchIndex,
        confirmImageRemovalUUID: uuid,
      })
    }
  }

  hideConfirmImageRemovalDialog = () => {
    this.setState({
      confirmImageRemovalIndex: null,
      confirmImageRemovalUUID: null,
    })
  }

  hideImageOnPageDialog = () => {
    this.setState({
      showImageOnPageDialog: false,
    })
  }

  addExistingImageFromSearch = (img) => {
    if (img.uuid) return

    const {
      values: { images, matches },
      setFormValues,
    } = this.props

    const image = ImageSchema.cast(img, { context: { values: {} } })
    const match = MatchSchema.default()

    image.uuid = randID()
    match.uuid = image.uuid

    this.setState(
      (state) =>
        produce(state, (draft) => {
          draft.searchImages = draft.searchImages.map((i) => {
            if (i._id === image._id) return image
            return i
          })
        }),
      () => {
        setFormValues(
          produce({ images, matches }, (draft) => {
            draft.images.push(image)
            draft.matches.push(match)
          })
        )
        this.manuallyValidateWholeForm().then(this.triggerAutoSave)
      }
    )
  }

  clearSearchImages = () => {
    this.setState({ searchImages: [], searchReachLimit: false }, () => {
      const { setFieldValue } = this.props
      setFieldValue(NAMES.__SEARCH_QUERY__, '')
    })
  }

  retryImageImportFile = (fid) => {
    this.imageImportsUploader.retryUpload(fid)
  }

  /**
   * @template T
   * @param name {T}
   * @return {T}
   */
  getNormalName = (name, index) => {
    return name
      .replace(IMAGE_INDEX, index)
      .replace(MATCH_INDEX, index)
      .replace(GENERAL_ATTACH_INDEX, index)
  }

  manuallyValidateWholeForm = async () => {
    const TIMEOUT = 1e1

    return Promise.delay(TIMEOUT).then(() => {
      if (!this._isMounted) return

      const {
        validateForm,
        values: { __DRAFT__ },
        case: caze,
        context,
      } = this.props

      if (!__DRAFT__ && !get(caze, 'cm.changeRequested')) return

      const matches = (context && context.cluster && context.cluster.matches) || []

      const validateFieldsWithContext = () => {
        const { values, handleValidationPath, fieldContext, caseId } = this.props
        const { isMatchSubmission } = this.state
        const NEW_CASE = 'new'

        /**
         * If submission started from match and it's new,
         * it was probably validated in match viewer, so prevent async
         * initial validation until case saved
         */
        if (isMatchSubmission && caseId === NEW_CASE) return

        values.images.forEach((img, indx) => {
          const originUrl = this.getNormalName(NAMES.matches.origin.url, indx)
          const imageUrl = this.getNormalName(NAMES.matches.url, indx)
          const attributionProvided = this.getNormalName(NAMES.matches.attribution_provided, indx)
          const firstPublished = this.getNormalName(NAMES.images.licensing.first_published.place, indx)
          const ctx = {
            ...fieldContext,
            image: img,
            match: values.matches[indx],
            index: indx,
            getDomain: () => this.state.domainName,
            getAsyncIgnoredError: () => this.state.asyncIgnoredErrors,
          }

          handleValidationPath(originUrl, ctx)
          handleValidationPath(imageUrl, ctx)
          handleValidationPath(attributionProvided, ctx)
          handleValidationPath(firstPublished, ctx)
        })
      }

      return new Promise((done) => {
        const next = () => {
          validateFieldsWithContext()
          done()
        }
        return validateForm(next, next)
      })
    })
  }

  renderBreadcrumbLink = ({ active, disabled, item }) => {
    const { stagesCompleted } = this.state
    return (
      <PixsyBreadcrumbsLink
        stage={item}
        active={active}
        checked={item !== STAGES.OVERVIEW && !!stagesCompleted[item]}
        onClick={!disabled ? this.handleStageChange(item) : null}
        disabled={disabled}
      >
        {STAGE_LABELS[item]}
      </PixsyBreadcrumbsLink>
    )
  }

  /**
   * Sidebar navigation buttons
   */
  renderStageNavigation = () => {
    const { isFormPristine, isFormSubmitting, isFormReadOnly, errors, values, clusterId, case: caze } = this.props
    const { stageActive, selectedImage: selectedImageIndex, editableImages, stagesCompleted } = this.state

    const { images } = values

    const selectedImage = images[selectedImageIndex] || {}
    const selectedImageInEditableImagesIndex = editableImages.indexOf(selectedImage.uuid)
    const atLeastTwoImg = editableImages.length > 1

    const isFirstImage = selectedImageInEditableImagesIndex === 0
    const isFinalImage = editableImages.length - 1 === selectedImageInEditableImagesIndex

    const isButtonsDisabled = editableImages.length <= 1 || !~selectedImageInEditableImagesIndex
    const isPreviousButtonDisabled = isButtonsDisabled || isFirstImage
    const isNextButtonDisabled = isButtonsDisabled || isFinalImage

    const isTakedownSuggest =
      errors[NAMES.country] ||
      (errors[NAMES.koImageUserIsBusiness] && errors[NAMES.koImageUserIsBusiness].value === false)
    const isPrevNextVisible = stageActive === STAGES.IMAGES && atLeastTwoImg
    // const isProfileDisplayed = stageActive === STAGES.PROFILE
    const isContinueDisabled = stageActive === STAGES.VALIDATION ? false : !stagesCompleted[stageActive]

    const isReadyToSubmit = Object.keys(stagesCompleted).every((k) => stagesCompleted[k])

    const LABEL_SAVE = 'Save'
    const LABEL_SUBMIT = 'Submit'
    const saveOrSubmitBtnLabel = isReadyToSubmit // isEmpty(Object.keys(errors))
      ? LABEL_SUBMIT
      : LABEL_SAVE
    // Make sure that handleSave and handleSubmit have the correct this context with every render
    const saveOrSubmitCallback = isReadyToSubmit ? this.handleSubmit.bind(this) : this.handleSave.bind(this)
    const saveOrSubmitDisabled = isReadyToSubmit
      ? isFormSubmitting || isFormReadOnly
      : isFormSubmitting || isFormPristine || isFormReadOnly

    return (
      <PixsyGrid direction="column" spacing={1}>
        {isPrevNextVisible && (
          <PixsyGridItem>
            <PixsyGrid spacing={1}>
              <PixsyGridItem xs={6}>
                <Button withFocusOutline onClick={this.handleGotoPrevImage} disabled={isPreviousButtonDisabled}>
                  <SVGImageIcon /> Prev
                </Button>
              </PixsyGridItem>
              <PixsyGridItem xs={6}>
                <Button withFocusOutline onClick={this.handleGotoNextImage} disabled={isNextButtonDisabled}>
                  Next <SVGImageIcon />
                </Button>
              </PixsyGridItem>
            </PixsyGrid>
          </PixsyGridItem>
        )}
        {isTakedownSuggest && clusterId && !isFormReadOnly && (
          <PixsyGridItem>
            <Button withFocusOutline orange onClick={this.handleNavigate(`/takedowns/submit/new?from=${clusterId}`)}>
              Issue Takedown
            </Button>
          </PixsyGridItem>
        )}
        {/* {isProfileDisplayed && (
          <PixsyGridItem>
            <Button withFocusOutline>Save Profile</Button>
          </PixsyGridItem>
        )} */}
        {(stageActive !== STAGES.REVIEW || (stageActive === STAGES.REVIEW && !isReadyToSubmit)) && (
          <PixsyGridItem>
            <Button withFocusOutline asDisabled={isContinueDisabled} onClick={this.handleGotoNextStage}>
              Continue
            </Button>
          </PixsyGridItem>
        )}
        {(!ENABLE_AUTO_SAVE || isReadyToSubmit) && (!isFormReadOnly || get(caze, 'cm.changeRequested') === true) && (
          <PixsyGridItem>
            <Button
              withFocusOutline
              asDisabled={saveOrSubmitDisabled && get(caze, 'cm.changeRequested') !== true}
              onClick={saveOrSubmitCallback}
            >
              {saveOrSubmitBtnLabel}
            </Button>
          </PixsyGridItem>
        )}
      </PixsyGrid>
    )
  }

  handleNavigate = (path) => () => {
    const { history } = this.props
    history.replace(path)
  }

  handleStageChange = (stageActive) => (e) => {
    e && e.preventDefault()
    this.setState({ stageActive })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.stageActive !== this.state.stageActive) {
      triggerGA(this.state.stageActive, this.props.values.source)
    }
  }

  handleGotoNextStage = () => {
    const { stages, selectedImage } = this.state
    const validateStage = this.manuallyValidateWholeForm()

    this.selectedImageTimeout = setTimeout(() => {
      if (
        !Object.keys(this.state.imagesCompleted)
          .map(Number)
          .includes(selectedImage)
      ) {
        if (this._isMounted) this.setState({ selectedImage: 0 })
      }
    }, 500)

    this.setState(
      (state) => ({ fullStageValidation: state.stageActive }),
      () => {
        validateStage.then(() => {
          this.setState((state) => {
            const { stageActive, stagesCompleted } = state
            const isContinueDisabled = stageActive === STAGES.VALIDATION ? false : !stagesCompleted[stageActive]

            if (!isContinueDisabled) {
              const dataForNextStage = { ...state, stages }
              const nextStage = getNextStage(dataForNextStage)

              return { stageActive: nextStage }
            }
            return state
          }, this.triggerAutoSave.bind(this))
        })
      }
    )
  }

  handleSelectImage = (index) => {
    this.setState((state) => gotoEditableImage(state, this.props, index))
  }
  handleGotoNextImage = () => {
    this.setState((state) => gotoNextOrPrevEditableImage(state, this.props, 1))
  }
  handleGotoPrevImage = () => {
    this.setState((state) => gotoNextOrPrevEditableImage(state, this.props, -1))
  }

  handleSave = (done, fail) => {
    const {
      values: currentValues,
      setSubmitting,
      saveCase,
      caseId,
      clusterId,
      history,
      errors,
      isFormSubmitting,
    } = this.props
    const { lastUpdated } = this.state

    if (isFormSubmitting) return false

    setSubmitting(true)

    const submission = produce(currentValues, (draft) => {
      const images = []
      const matches = []
      draft.images.forEach((img, index) => {
        if (img.url) {
          const match = draft.matches[index]
          images.push(img)
          matches.push(match)
        }
      })

      draft.images = images
      draft.matches = matches
      draft.errors = errors
    })

    const uuids = submission.images.filter((i) => !!i.url).map((i) => i.uuid)

    const saveCasePromise = saveCase({
      caseId,
      clusterId,
      submission,
      lastUpdated,
    })
    if (!saveCasePromise) {
      setTimeout(() => {
        this.handleSave(done, fail)
      }, 500)
    }
    saveCasePromise.then((res) => {
      if (res.error) {
        console.error('Got Error:', res.error)
        setSubmitting(false)
        isFunction(fail) && fail()
        return
      }
      if (!this._isMounted) return

      this.setState({ hasCaseSavedOnce: true })

      if (
        isPlainObject(res) &&
        isPlainObject(res.payload) &&
        isString(res.payload.case) &&
        isString(res.payload.caseId) &&
        isPlainObject(res.payload.entities) &&
        isPlainObject(res.payload.entities.cases[res.payload.caseId])
      ) {
        const pathToReplace = `/cases/submission/${res.payload.caseId}`
        if (window.location.pathname !== pathToReplace) {
          history.replace(pathToReplace)
        }

        const caze = res.payload.entities.cases[res.payload.caseId]

        const { lastUpdated } = caze
        const { images: imagesIds, matches: matchesIds } = caze.submission
        const { values, setFormValues, setPristine } = this.props

        const { images, matches } = assignIdsToImagesMatches(
          uuids,
          imagesIds,
          matchesIds,
          values.images,
          values.matches
        )

        this.setState({ lastUpdated }, () => {
          setFormValues({ images, matches })
          setTimeout(() => {
            // Give the frontend some time to evaluate the new caseId
            // in response to the potential route change triggered above
            // which requires the parent component’s `state` to have updated
            // and pass down the caseId as prop,
            // BEFORE allowing to submit again
            setSubmitting(false, () => {
              if (values === currentValues) {
                setPristine(true)
              }
              isFunction(done) && done()
            })
            this.checkImagesOnPage()
          }, 100)
        })
      } else {
        setSubmitting(false)
        isFunction(fail) && fail()
      }
    })
  }

  handleExitForm = () => {
    const { case: caze, isFormReadOnly, history } = this.props
    const navigateBack = history.goBack
    if (!caze || isFormReadOnly) {
      // nothing was entered
      navigateBack()
    } else {
      this.handleSave(navigateBack, navigateBack)
    }
  }

  checkImagesOnPage = () => {
    const matches = get(this.props.case, 'submission.matches', [])
    const matchesNeedAction = matches.filter((m) => {
      const status = get(m, 'meta.image_on_page.status', 'pass')
      const userConfirm = get(m, 'meta.image_on_page.user_confirm', false)
      return status === 'fail' && !userConfirm
    })
    if (!matchesNeedAction.length) return true

    /** Show the Dialog here start **/
    this.setState({ showImageOnPageDialog: true, matchWithImageMissingOnPage: matchesNeedAction[0] })
    /** Show the Dialog here end **/

    return false
  }

  handleImageOnPageDialogConfirm = () => {
    this.setState({ showImageOnPageDialog: false })
    return this.handleSave()
  }

  handleSubmit = debounce(() => {
    const { setFormReadOnly, submitCase, setSubmitting, setFormValues, isCaseSaving } = this.props

    if (isCaseSaving) {
      return false
    }

    setFormReadOnly(true)

    this.handleSave(
      () => {
        if (!this._isMounted) return
        if (!this.checkImagesOnPage()) {
          setFormReadOnly(false)
          return
        }

        const { caseId } = this.props

        setSubmitting(true)

        submitCase({ caseId })
          .then((res) => {
            if (!this._isMounted) return
            if (isPlainObject(res) && isPlainObject(res.payload) && res.payload.submitted) {
              this.setState({ stageActive: STAGES.SUCCESS }, () => {
                setFormValues({ __DRAFT__: false })
                triggerGA(STAGES.SUCCESS)
              })
            } else {
              setFormReadOnly(false)
            }
            setSubmitting(false)
          })
          .catch((err) => {
            setSubmitting(false)
            setFormReadOnly(false)
          })
      },
      () => setFormReadOnly(false)
    )
  }, 500)

  scheduleAutoSave = () => {
    const ATTEMPT_NEXT_SAVE_AT = 3e3 // 3 sec

    if (this.autoSaveTimer) {
      clearTimeout(this.autoSaveTimer)
    }

    this.autoSaveTimer = setTimeout(() => {
      if (this._isMounted) this.triggerAutoSave()
    }, ATTEMPT_NEXT_SAVE_AT)
  }

  // debounce = (func, wait) => {
  //   let timeout
  //   return function() {
  //     const context = this
  //     const args = arguments
  //     const later = function() {
  //       timeout = null
  //       func.apply(context, args)
  //     }
  //     clearTimeout(timeout)
  //     timeout = setTimeout(later.bind(this), wait)
  //   }
  // }

  triggerAutoSave = debounce(
    () => {
      if (!ENABLE_AUTO_SAVE || !this._isMounted) return

      const { values, isFormPristine, isFormSubmitting, caseId } = this.props
      const { editableImages } = this.state

      if (!values.__DRAFT__ || isFormPristine || (caseId === 'new' && isEmpty(editableImages))) return

      // if (isFormSubmitting) {
      //   return void this.scheduleAutoSave()
      // }

      if (!isFormSubmitting) {
        // Make sure that handleSave has the correct this context with every save
        const saveFn = this.handleSave.bind(this)
        return void saveFn()
      }
    },
    3000,
    // Invoke the autosave as soon as it is triggered and
    // debounce the subsequent calls
    {
      leading: true,
      trailing: false,
    }
  )

  /**
   * Search Images
   */
  getImages = () => {
    const { loadImages } = this.props

    let __SEARCH_QUERY__ = this.props.values.__SEARCH_QUERY__

    if (String(__SEARCH_QUERY__).trim() === '' || !__SEARCH_QUERY__) {
      __SEARCH_QUERY__ = ''
    }

    const offset = IMAGES_SEARCH_QUERY_SIZE + 1
    const title = 'title:' + (__SEARCH_QUERY__ || '')
    const query = getVirtualImagesQuery({
      tags: [title],
      pageSize: offset,
    })

    const getImagesFromCache = () => {
      const prevEntry = getSearchResultFromCache(this.state, __SEARCH_QUERY__)

      if (prevEntry) {
        this.setState({
          searchImages: assignUUIDsToExistingImages(this.props, prevEntry.searchImages),
          searchReachLimit: prevEntry.searchReachLimit,
        })
      }
    }

    getImagesFromCache()

    return loadImages(query)
      .then((res) => {
        if (!this._isMounted) {
          return
        }

        const data = getStateFromImagesSearchResult(res, this.state, this.props, __SEARCH_QUERY__)

        this.setState(data, getImagesFromCache)
      })
      .catch((err) => {
        if (!this._isMounted) return
        // @TODO - maybe handle this better (e.g: show 'Try again' kinda msg)
        return this.setState({ searchImages: [], searchReachLimit: false })
      })
  }

  getStageInfoStatus = () => {
    return getInformationStatus(this.state, this.props)
  }

  ignoreAsyncError = (validationErrorData) => {
    this.setState((state) =>
      produce(state, (draft) => {
        const exist = draft.asyncIgnoredErrors.find((e) => isMatch(e, validationErrorData))

        if (exist) return

        draft.asyncIgnoredErrors.push(validationErrorData)
      })
    )
  }

  render() {
    const {
      // caseTitle,
      // editableImagesWithIds,
      asyncIgnoredErrors,
      asyncResolvableState,
      caseStatus,
      confirmImageRemovalIndex,
      confirmImageRemovalUUID,
      domainName,
      editableImages,
      imagesCompleted,
      searchImages,
      searchReachLimit,
      selectedImage,
      stageActive,
      stages,
      fullStageValidation,
      showImageOnPageDialog,
      matchWithImageMissingOnPage,
    } = this.state
    const { values, isImagesLoading, caseId, isFormSubmitting, case: caze, ...api } = this.props
    const StageContainer = STAGE_CONTAINERS[stageActive]
    const stageInfo = STAGE_INFO[stageActive]

    // console.warn('STATE > ', this.state)
    // console.warn('PROPS > ', this.props)

    const isSuccess = stageActive === STAGES.SUCCESS
    const isEditingCase = get(caze, 'cm.changeRequested') || false

    return (
      <PixsyPage>
        <Helmet title={`Cases | ${stageActive.toUpperCase()}`} />
        <Dialog isOpen={isNumber(confirmImageRemovalIndex)} onRequestClose={this.hideConfirmImageRemovalDialog} wide>
          <ConfirmRemovalDialog
            onRemove={this.removeImage}
            onCancel={this.hideConfirmImageRemovalDialog}
            matchUUID={confirmImageRemovalUUID}
          />
        </Dialog>
        <Dialog isOpen={showImageOnPageDialog} wide>
          <ImageOnPageDialog
            caze={caze}
            onConfirm={this.handleImageOnPageDialogConfirm}
            onCancel={() => this.setState({ showImageOnPageDialog: false })}
            match={matchWithImageMissingOnPage}
          />
        </Dialog>
        <Fade in>
          <PixsyStickyBar>
            <PixsyStickyBar.BackButton onClick={this.handleExitForm} />
            <PixsyBreadcrumbs items={stages} itemActive={stageActive} renderItem={this.renderBreadcrumbLink} />
          </PixsyStickyBar>
        </Fade>
        {!isSuccess && (
          <Fade in>
            <PixsyContainer footer={false} style={{ position: 'absolute' }}>
              <PixsyGrid spacing={4}>
                <PixsyGridItem xs={8} flexGrow />
                <ImagesSidebar
                  domain={domainName}
                  handleSelectImage={this.handleSelectImage}
                  images={values.images}
                  imagesCompleted={imagesCompleted}
                  isFormReadOnly={!isEditingCase && api.isFormReadOnly}
                  matches={values.matches}
                  // removeImage={this.removeImage}
                  removeImage={this.showConfirmImageRemovalDialog}
                  selectedImage={selectedImage}
                  stageInfo={stageInfo}
                  stageInfoStatus={(stageActive !== STAGES.REVIEW || isFormSubmitting) && this.getStageInfoStatus()}
                  title={caseStatus}
                  caseId={caseId}
                >
                  {this.renderStageNavigation(stageActive)}
                </ImagesSidebar>
              </PixsyGrid>
            </PixsyContainer>
          </Fade>
        )}
        <Fade in>
          <PixsyContainer containerRef={this.formScrollableContentRef}>
            <PixsyGrid spacing={4} justify="center">
              <PixsyGridItem xs={8} flexGrow>
                <StageContainer
                  // removeImage={this.removeImage}
                  case={caze}
                  addExistingImageFromSearch={this.addExistingImageFromSearch}
                  asyncResolvableState={asyncResolvableState}
                  asyncIgnoredErrors={asyncIgnoredErrors}
                  caseId={caseId}
                  clearSearchImages={this.clearSearchImages}
                  domainName={domainName}
                  editableImages={editableImages}
                  generalFiles={this.generalFilesUploader}
                  getImages={this.getImages}
                  getLicensingFiles={this.getLicensingUploader}
                  getRegistrationFiles={this.getUSRegistrationUploader}
                  ignoreAsyncError={this.ignoreAsyncError}
                  imageImportFiles={this.imageImportsUploader}
                  isImagesLoading={isImagesLoading}
                  manuallyValidateWholeForm={this.manuallyValidateWholeForm}
                  removeExistingImage={this.removeExistingImage}
                  removeGeneralFile={this.removeGeneralFile}
                  removeImage={this.showConfirmImageRemovalDialog}
                  removeImageFile={this.removeImageFile}
                  retryGeneralFile={this.retryGeneralFile}
                  retryImageImportFile={this.retryImageImportFile}
                  screeningBreachFiles={this.breachContractUploader}
                  screeningContactUsrFiles={this.contactUserUploader}
                  searchImages={searchImages}
                  searchReachLimit={searchReachLimit}
                  selectedImage={selectedImage}
                  sourceLocation={this.sourceLocation}
                  values={values}
                  fullStageValidation={fullStageValidation}
                  {...api}
                />
              </PixsyGridItem>
              {!isSuccess && <PixsyGridItem xs={4} />}
            </PixsyGrid>
          </PixsyContainer>
        </Fade>
        <PixsyScrollTo whenChanges={[stageActive, selectedImage]} element={this.formScrollableContentRef.current} />
      </PixsyPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  authLoading: state.auth.loading,
  authUpdatingUser: state.auth.updatingUser,
  authVerifyEmailLoading: state.auth.emailVerifyLoading,
  isImagesLoading: state.images.loading,
  isCaseSaving: state.cases.loading,
})

export const CaseSubmission = connect(mapStateToProps, { loadImages })(CaseSubmissionContainer)

const imageIconStyle = css`
  vertical-align: sub;
  font-size: 16px !important;
`
const SVGImageIcon = React.memo(() => <ImageIcon css={imageIconStyle} fontSize="inherit" />)