import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { resolveCountryNameForCode, formatClusterTag } from 'pixsy-constants'

export default function QueryPill({ tag, onClick }) {
  return (
    <div className={pill} onClick={onClick}>
      <div className={inner}>{decorate(tag)}</div>
    </div>
  )
}

QueryPill.propTypes = {
  tag: PropTypes.string.isRequired,
}

const pill = css`
  text-align: center;
  padding: 0 0 0 10px;
  cursor: pointer;
  max-height: 54px;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  &:after {
    transition: all 250ms ease;
    position: absolute;
    content: '×';
    font-weight: 600;
    top: -10px;
    right: -7px;
    color: #ffa168;
    /*color: rgb(120,120,120);*/
    font-size: 2em;
    opacity: 0;
    @media (max-width: 768px) {
      opacity: 1;
      right: 3px;
      top: -1px;
      overflow-x: hidden;
      overflow-y: hidden;
      font-size: 1.5em;
    }
  }
  &:hover {
    > div {
      opacity: 0.5;
    }
    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    background-color: white;
    position: relative;
    display: inline-block;
  }
`
const inner = css`
  padding: 5px 15px;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
  transition: all 250ms ease;

  @media (max-width: 768px) {
    padding: 5px 20px 5px 15px;
  }
`
function decorate(tag) {
  if (tag.startsWith('country:'))
    return <CountryPill code={tag.split(':')[1]} />

  if (tag.startsWith('image:')) return <ImagePill id={tag.split(':')[1]} />

  if (tag.endsWith('true') || tag.endsWith('false')) {
    const [flag, state] = tag.split(':')

    return (
      <span
        css={bold}
        title={`${state === 'false' ? 'Not ' : ''} ${capitalize(flag)}`}
      >
        {`${state === 'false' ? 'Not ' : ''} ${capitalize(flag)}`}
      </span>
    )
  }

  return (
    <span css={bold} title={formatClusterTag(tag)}>
      {formatClusterTag(tag)}
    </span>
  )
}

function CountryPill({ code }) {
  return (
    <span css={bold} title={`Matches from ${resolveCountryNameForCode(code)}`}>
      From {resolveCountryNameForCode(code)}
    </span>
  )
}

const ImagePillUndecorated = ({ title }) => {
  return (
    <span title={title} css={bold}>
      Image: {title}
    </span>
  )
}

const ImagePill = connect((state, { id }) => ({
  title: state.entities.images[id] ? state.entities.images[id].title : id,
}))(ImagePillUndecorated)

function capitalize(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

const bold = css`
  font-weight: bold;
`
