import React, { useState } from 'react'
import { Spring } from 'react-spring/renderprops.cjs'

const AnimatedNumbers = ({ number }) => {
  const [previousNumber, setPreviousNumber] = useState(0)

  return (
    <Spring
      from={{ number: previousNumber }}
      to={{ number }}
      onRest={() => setPreviousNumber(number)}
      config={{ tension: 150, friction: 15, precision: 1, clamp: true }}
    >
      {(animationProps) => <span>{parseInt(animationProps.number).toLocaleString()}</span>}
    </Spring>
  )
}

export default AnimatedNumbers
