import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import availableTags from './availableTags'
import getTagSuggestions from '../../../../getTagSuggestions'
import { formatClusterTag } from 'pixsy-constants'
import Autosuggest from 'react-autosuggest'

// Teach Autosuggest how to calculate suggestions for any given input value.

const getSearchSuggestions = value => {
  return value && value.length
    ? [
        'domain:' + value,
        'url:' + value,
        'title:' + value,
        'image-title:' + value,
      ]
    : []
}
const formatSuggestionForTag = tag => {
  const [category] = tag.split(':')
  if (category === 'country') {
    return (
      <React.Fragment>
        Matches from <b>{formatClusterTag(tag)}</b>
      </React.Fragment>
    )
  }
  if (category === 'category') {
    return (
      <React.Fragment>
        Matches of the Category <b>{formatClusterTag(tag)}</b>
      </React.Fragment>
    )
  }
  if (category === 'title') {
    return (
      <React.Fragment>
        Matches on Websites titled <b>{formatClusterTag(tag)}</b>
      </React.Fragment>
    )
  }
  if (category === 'url') {
    return (
      <React.Fragment>
        Matches on URLs containing <b>{formatClusterTag(tag)}</b>
      </React.Fragment>
    )
  }
  if (category === 'domain') {
    return (
      <React.Fragment>
        Matches on Domains called <b>{formatClusterTag(tag)}</b>
      </React.Fragment>
    )
  }
  if (category === 'image-title') {
    return (
      <React.Fragment>
        Matches of source Images titled <b>{formatClusterTag(tag)}</b>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      Matches tagged <code>{formatClusterTag(tag)}</code>
    </React.Fragment>
  )
}

const getSuggestions = (value = '', activeTags, tags = []) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  if (!inputLength === 0) return []

  const rawTagsInBreakdown = tags.map(({ tag }) => tag)

  const allAvailableTags = [
    ...tags,
    ...availableTags
      .filter(t => !rawTagsInBreakdown.includes(t))
      .map(tag => ({ tag, count: 0 })),
  ].filter(({ tag }) => 'string' === typeof tag && !activeTags.includes(tag)) // sentry reported tag sometimes being null

  const availableTagsWithNeutralCounts = allAvailableTags.filter(({ tag }) => {
    if (tag.toLowerCase().slice(0, inputLength) === inputValue) return true

    const formattedTag = formatClusterTag(tag) // resolveCountryNameForCode may return `null`

    if (typeof formattedTag !== 'string') return false

    return formattedTag.toLowerCase().slice(0, inputLength) === inputValue
  })

  return [
    ...getSearchSuggestions(inputValue),
    ...getTagSuggestions(availableTagsWithNeutralCounts)
      .slice(0, 4)
      .map(({ tag }) => tag),
  ]
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => formatClusterTag(suggestion)

// Use your imagination to render suggestions.
const renderSuggestion = tag => <div>{formatSuggestionForTag(tag)}</div>

export class QueryBarSearch extends Component {
  state = {
    value: '',
    suggestions: [],
  }

  autoSuggestRef = React.createRef()

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.setState({
        suggestions: [],
        value: '',
      })
    }
  }

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  handleKeyDown = ({ key, target }) => {
    if (key === 'Escape') {
      target.blur()
    }
  }
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(
        value,
        this.props.activeTags,
        this.props.availableTags
      ),
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState(
      {
        suggestions: [],
      },
      () => {
        const { location, history } = this.props
        const { state } = location

        if (state && state.fromDefaultStickyFloater) {
          const nextLocation = {
            ...location,
            state: {
              ...(location.state || {}),
              fromDefaultStickyFloater: false,
            },
          }
          history.replace(nextLocation)
        }
      }
    )
  }

  onSuggestionSelected = (event, selection) => {
    const { suggestion, destination } = selection
    const { history, location } = this.props

    let path
    if (destination) {
      path = destination
    } else {
      // safe suggestions e.g. when searching for text
      // containing whitespace or slashes
      path = encodeURI(suggestion.split('/')[0])
    }

    try {
      window.mixpanel.track('Matches.QueryBar.FollowSuggestion', {
        tag: path,
      })
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }

    requestAnimationFrame(() => {
      history.push({
        ...location,
        pathname: location.pathname + '/' + path,
      })
    })
  }

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const symbolDollar = require('./symbolDollar.svg')
    const symbolCountries = require('./symbolJurisdiction.svg')
    const { activeTags } = this.props
    const { suggestions } = this.state
    const ifCollapsedStyles = !suggestions.length
      ? { pointerEvents: 'none', opacity: 0 }
      : { maxHeight: '100vh' }

    const topMatchesSymbol = require('../tmFill.svg')
    const topMatchesLabel = 'Top Matches'
    const topMatchesCSS = suggestionsTopMatchesIcon
    const topMatchesDestination = 'top'

    // https://github.com/moroshko/react-autosuggest/blob/master/src/Autosuggest.js#L117
    // Use onMouseDown:
    // - onClick cannot be captured because suggestions are cleared onMouseUp

    return (
      <React.Fragment>
        <div css={suggestionsBlur} style={ifCollapsedStyles} />
        <div css={suggestionsWrap} style={ifCollapsedStyles}>
          <div {...containerProps}>
            <div
              className={cx(
                suggestionsListWrap,
                activeTags.length > 2 && suggestionsListWrapFullWidth
              )}
            >
              {children}
            </div>

            {activeTags.length > 2 || (
              <div css={suggestionsInfo}>
                <h1>Or try these popular searches</h1>
                <div css={suggestionsPopular}>
                  <button
                    onMouseDown={e =>
                      this.onSuggestionSelected(e, {
                        destination: topMatchesDestination,
                      })
                    }
                    css={topMatchesCSS}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: topMatchesSymbol }}
                    />
                    {topMatchesLabel}
                  </button>
                  <button
                    css={suggestionsPopularCommercialIcon}
                    onMouseDown={e =>
                      this.onSuggestionSelected(e, {
                        destination: 'commercial:true',
                      })
                    }
                  >
                    <span dangerouslySetInnerHTML={{ __html: symbolDollar }} />
                    Commercial matches
                  </button>
                  <button
                    css={suggestionsPopularJurisdiction}
                    onMouseDown={e =>
                      this.onSuggestionSelected(e, {
                        destination: 'pixsy:jurisdictions',
                      })
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: symbolCountries }}
                    />
                    Matches from our jurisdictions
                  </button>
                </div>
              </div>
            )}

            <div
              className={cx(
                suggestionsCombined,
                activeTags.length > 2 && suggestionsCombinedFullWidth
              )}
            >
              Combine multiple keywords to refine your results even further
              <div css={suggestionsPills}>
                <span css={suggestionsPill}>High Accuracy</span>
                <span css={suggestionsPlusSign}>+</span>
                <span css={suggestionsPill}>United Kingdom</span>
                <span css={suggestionsPlusSign}>+</span>
                <span css={suggestionsPill}>abc.com</span>
              </div>
            </div>
          </div>

          <div css={suggestionsFooter}>
            <span
              data-elevio-article={112}
              onMouseDown={e => e.target.click() /* 🤷 */}
            >
              Learn more
            </span>{' '}
            on how to get better results using the search bar
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { value, suggestions } = this.state
    const { activeTags } = this.props
    const pristine = activeTags.length === 0

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder:
        activeTags.length < 3
          ? 'Search for name, country, category...'
          : 'Add more filters',
      value,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyDown,
    }

    // Finally, render it!
    return (
      <Autosuggest
        ref={this.autoSuggestRef}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions
        theme={{
          input: pristine ? inputStylesPristine : inputStylesAddMore,
          suggestionsContainer,
          suggestion,
          suggestionsList,
          suggestionHighlighted,
        }}
        inputProps={inputProps}
      />
    )
  }
}

QueryBarSearch.propTypes = {
  location: PropTypes.object.isRequired,
  activeTags: PropTypes.array.isRequired,
}

export default QueryBarSearch

const inputStyles = css`
  width: 100%;
  border: 0;
  height: 54px;
  padding: 2px 18px;
  font-size: 24px;
  font-weight: 300;
  caret-color: #dbdbdb;
  color: #000;

  &:focus {
    outline: 0;
  }
`
const inputStylesPristine = css`
  ${inputStyles};
  font-size: 18px;
  font-weight: 400;

  &::placeholder {
    color: rgb(70, 70, 70);
  }
`
const inputStylesAddMore = css`
  ${inputStyles} font-size: 18px;
  font-weight: 400;
  &::placeholder {
    color: #4b4b4b80;
  }
`

const suggestionsWrap = css`
  left: 0;
  right: 0;
  position: absolute;
  min-height: 0;
  max-height: 0;
  opacity: 1;
  transition: max-height 250ms ease-out;
  transition: opacity 250ms ease-out;
`

const suggestionsContainer = css`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`

const suggestionsListWrap = css`
  margin-right: 10px;
  width: calc(50% - 10px);
  background: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.11);
  margin: 10px 10px 12px 0px;
  min-height: 210px;

  @media (max-width: 1440px) {
    width: 100%;
    margin-right: 0;
  }
`
const suggestionsListWrapFullWidth = css`
  width: 100%;
  margin-right: 0;
`

const suggestionsList = css`
  list-style: none;
  padding: 0 0;
`

const suggestionsInfo = css`
  background: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.11);
  margin: 10px 0 12px 10px;
  padding: 22px 10px;
  width: calc(50% - 10px);

  h1 {
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    margin: 0 0 24px;
  }

  @media (max-width: 1440px) {
    margin-left: 0;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const suggestionsPopular = css`
  display: flex;

  button {
    align-items: center;
    appearance: none;
    border: 0;
    border-right: 1px solid #efefef;
    background: transparent;
    display: flex;
    flex-basis: 33%;
    flex-direction: column;
    flex-grow: 1;
    font-size: 16px;
    justify-content: flex-start;
    line-height: 19px;
    outline: none;
    padding: 0 22px 5px;

    span {
      height: 57px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 10px;
      width: 100%;
      animation: fadeInQueryBarPopularBtn 350ms ease-out;
    }

    &:last-of-type {
      border-right: 0;
    }
  }

  @keyframes fadeInQueryBarPopularBtn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const suggestionsTopMatchesIcon = css`
  svg {
    height: 44px;
  }
`

const suggestionsPopularCommercialIcon = css`
  svg {
    height: 47.74px;
  }
  @media (max-width: 1440px) {
    display: none !important;
  }
`
const suggestionsPopularJurisdiction = css`
  svg {
    height: 56.93px;
  }
`

const suggestionsCombined = css`
  background: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.11);
  font-size: 20px;
  font-weight: 100;
  line-height: 24px;
  margin: 8px 0 12px;
  padding: 22px 10px;
  padding: 26px 36px;
  width: 100%;

  @media (max-width: 1440px) {
    margin-left: 5px;
    width: calc(50% - 10px);
  }

  @media (max-width: 768px) {
    display: none;
  }
`
const suggestionsCombinedFullWidth = css`
  width: 100% !important; /* over-write @media */
  margin-left: 0px !important; /* over-write @media */
`

const suggestionsPills = css`
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;

  @media (max-width: 1440px) {
    justify-content: center;
    margin-top: 20px;
  }
`
const suggestionsPill = css`
  background-color: #f0f0f0;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  margin: 0 14px;
  text-align: center;
  width: 134px;

  @media (max-width: 1440px) {
    margin-bottom: 14px;
  }
`
const suggestionsPlusSign = css`
  color: #ffa168;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;

  @media (max-width: 1440px) {
    margin-bottom: 14px;
  }
`
const suggestionsBlur = css`
  z-index: -1;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 111px;
  top: 0;
  background: white;
  opacity: 0.8;
  transition: opacity 150ms ease-out;

  @media (max-width: 768px) {
    left: 0;
  }
`

const suggestionsFooter = css`
  text-align: center;
`

const suggestion = css`
  font-size: 1.4em;
  margin: 10px 0;
  font-weight: 300;
  padding: 6px 0;
  padding-left: 14px;
  border-left: 5px solid transparent;
  transition: background-color 200ms ease;

  code {
    color: #00000080;
    padding: 6px 12px;
    font-size: 1em;
  }
`

const suggestionHighlighted = css`
  background-color: rgb(251, 251, 251);
`
