import * as React from 'react'
import PropTypes from 'prop-types'
import Tooltip from './Tooltip'
import { footer as footerStyle } from './defaultStyle'
import TooltipContext from './TooltipContext'
/**
 * Class for context aware tooltip. Renders Tooltip base class.
 *
 * @class TooltipContainerInner (name)
 * @param {object} context - from context provider
 * @param {string} id - string constant id to identify content
 */
class TooltipContainerInner extends React.Component {
  constructor(props) {
    super()

    const { id, get, content } = props

    let _content = null
    if (content) {
      _content = content
    } else {
      if (!get) {
        throw new Error('TooltipContext.get unavailable')
      }
      if (!id) {
        throw new Error(
          'TooltipContainer.id undefined: ' + JSON.stringify(props)
        )
      }
      _content = get(id)
    }

    if (_content) {
      this._content = _content
    } else {
      console.error('No content found for tooltip ' + id)
    }
  }

  renderTitle(title) {
    return this._content.title
  }

  renderBody(title) {
    return this._content.body
  }

  renderFooter(title) {
    const { elevioId, footer } = this._content
    return elevioId ? (
      <p css={footerStyle}>
        Check our{' '}
        <a
          href={`https://support.pixsy.com/en/articles/${elevioId}`}
          target="_blank"
        >
          help center
        </a>{' '}
        for more
      </p>
    ) : footer ? (
      <p css={footerStyle}>{footer}</p>
    ) : null
  }

  render() {
    return this._content ? (
      <Tooltip
        title={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
      />
    ) : null
  }
}

function TooltipContainer(containerProps) {
  return (
    <TooltipContext.Consumer>
      {contextProps => (
        <TooltipContainerInner {...contextProps} {...containerProps} />
      )}
    </TooltipContext.Consumer>
  )
}
export default TooltipContainer

TooltipContainerInner.propTypes = {
  id: PropTypes.string.isRequired,
  get: PropTypes.func.isRequired,
  // content: PropTypes.object.isRequired,
}
