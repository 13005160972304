import * as React from 'react'

export default function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-7694.959 -22957.117 11.414 11.416"
    >
      <g
        id="Group_318"
        data-name="Group 318"
        transform="translate(-9012.559 -23545.559)"
      >
        <path
          id="Path_104"
          data-name="Path 104"
          d="M20396.156,2444l10,10"
          transform="translate(-19077.85 -1854.85)"
        />
        <path
          id="Path_105"
          data-name="Path 105"
          d="M0,0,10,10"
          transform="translate(1318.307 599.147) rotate(-90)"
        />
      </g>
    </svg>
  )
}
