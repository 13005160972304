import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'

const FormRowWrap = styled.div`
  display: flex;
  align-items: space-around;
  padding: ${props => (props.padding ? '20px 30px 20px 30px' : '0')};
  padding-bottom: ${props =>
    'undefined' !== typeof props.paddingBottom
      ? props.paddingBottom + 'px'
      : 'auto'};
  padding-top: ${props =>
    'undefined' !== typeof props.paddingTop ? props.paddingTop + 'px' : 'auto'};
  position: relative;
  background-color: ${props => props.backgroundColor};

  & {
    ${props =>
      props.disabled &&
      css`
        pointer-events: none;
        opacity: 0.6;
      `};
  }

  @media (max-width: 768px) {
    padding: 15px 0;
    display: block;
  }
`

const highlightIndicator = css`
  position: absolute;
  left: -40px;
  top: -1px;
  bottom: -1px;
  background-color: #ffa168;
  width: 8px;
`

export default function FormRow(props) {
  const {
    children,
    meta: { dirty, touched, warning, error } = {},
    highlighted,
  } = props

  let backgroundColor

  const data = {}

  if ((touched || dirty) && (warning || error)) {
    if (warning) backgroundColor = '#FFFFDF'
    if (error) {
      backgroundColor = '#FFF0F0'
      data['data-error'] = 'true'
    }
  }

  return (
    <FormRowWrap
      backgroundColor={backgroundColor}
      disabled={props.disabled}
      padding={props.padding}
      paddingBottom={props.paddingBottom}
      paddingTop={props.paddingTop}
      {...data}
    >
      {highlighted && <div css={highlightIndicator} />}
      {children}
    </FormRowWrap>
  )
}

FormRow.propTypes = {
  meta: PropTypes.object,
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool,
}

FormRow.defaultProps = {
  padding: true,
}
