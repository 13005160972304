import Checkbox from '@material-ui/core/Checkbox'
import CheckBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp'
import CheckIcon from '@material-ui/icons/CheckBoxSharp'
import React, { useCallback, useContext, useMemo } from 'react'
import { css } from 'react-emotion'
import { PixsyFormContext } from '../PixsyForm/PixsyForm'

const checkedClassName = css({})

const DEFAULT_ITEMS = {
  true: true,
  false: false,
}

/**
 * @see PixsyTextField
 * @see PixsyForm
 *
 * items:
 *  An object containing two properties `true` and `false` which allows to customize the state of `checked / unchecked`
 *    e.g:
 *
 *      <PixsyCheckbox name="single" items={{ true: 'yes, sadly', false: 'no' }} />
 *
 *    if checkbox is checked, the `values.single` property in PixsyForm will be `yes, sadly` instead of `true`
 */

export const PixsyCheckbox = React.memo(
  ({
    icon: IconCheckBlank = CheckBlankIcon,
    checkedIcon: IconCheck = CheckIcon,
    items = DEFAULT_ITEMS,
    validateOnChange = false,
    validateOnBlur = false,
    validateOtherPaths,
    validateEntireForm,
    fieldContext,
    fieldContextOnBlur,
    fieldContextOnChange,
    color = '#939393',
    colorChecked = '#008aab',
    disabled,
    name,
    readOnly,
  }) => {
    if (
      typeof items !== 'object' ||
      items === null ||
      !('true' in items) ||
      !('false' in items)
    ) {
      throw new Error(
        'PixsyCheckbox - unexpected items property, expected object with shape "{ true: ..., false: ... }"'
      )
    }

    const classes = useMemo(
      () => ({
        root: css`
          width: 52px;
          height: 52px;
          font-size: 22px;
          color: ${color} !important;
          &.${checkedClassName} {
            color: ${colorChecked} !important;
          }
        `,
        checked: checkedClassName,
      }),
      [color, checkedClassName, colorChecked]
    )

    const contextOnChange = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnChange || {}),
      }),
      [fieldContext, fieldContextOnChange]
    )
    const contextOnBlur = useMemo(
      () => ({
        ...(fieldContext || {}),
        ...(fieldContextOnBlur || {}),
      }),
      [fieldContext, fieldContextOnBlur]
    )

    const api = useContext(PixsyFormContext)
    const checked = api.getValue(name)

    const handleValidateOtherPaths = useCallback(
      ctx => {
        if (validateOtherPaths) {
          if (typeof validateOtherPaths === 'string') {
            api.handleValidationPath(validateOtherPaths, ctx)
          } else if (Array.isArray(validateOtherPaths)) {
            validateOtherPaths.forEach(otherName =>
              api.handleValidationPath(otherName, ctx)
            )
          }
        }
      },
      [validateOtherPaths]
    )

    const handleValidateCurrentPath = useCallback(
      ctx => {
        api.handleValidationPath(name, ctx)
        handleValidateOtherPaths(ctx)
      },
      [name]
    )

    const handleOnChange = useCallback(
      e => {
        const val = e.target.checked ? items.true : items.false

        api.handleChange(name, val)

        if (validateOnChange) {
          if (validateEntireForm) {
            return api.validateForm(null, null, contextOnChange)
          }
          handleValidateCurrentPath(contextOnChange)
        }
      },
      [name]
    )

    const handleOnBlur = useCallback(() => {
      api.notifyListenersOnBlurEvent(name)

      if (validateOnBlur) {
        if (validateEntireForm) {
          return api.validateForm(null, null, contextOnBlur)
        }
        handleValidateCurrentPath(contextOnBlur)
      }
    }, [name])

    return (
      <Checkbox
        icon={<IconCheckBlank fontSize="inherit" />}
        checkedIcon={<IconCheck fontSize="inherit" />}
        name={name}
        checked={!!checked}
        disabled={!!disabled || readOnly}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        color="primary"
        classes={classes}
      />
    )
  }
)
