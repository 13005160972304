import React, { useCallback, useState } from 'react'
import styled from 'react-emotion'
import ToggleButton from '../../../components/Filters/ToggleButton'

const SimilarityToggle = ({ onSelect }) => {
  const [selectedToggle, setSelectedToggle] = useState(null)
  const toggle = useCallback(
    (type) => {
      const newType = type === selectedToggle ? null : type
      setSelectedToggle(newType)
      onSelect(newType)
    },
    [selectedToggle]
  )

  return (
    <SimilarityToggleStyled>
      <ToggleButton
        text="Exact Matches"
        active={selectedToggle === 'exact'}
        color="#f5f5f5"
        onClick={() => toggle('exact')}
      />
      <ToggleButton
        text="Similar Matches"
        active={selectedToggle === 'similar'}
        color="#f5f5f5"
        onClick={() => toggle('similar')}
      />
    </SimilarityToggleStyled>
  )
}

export default SimilarityToggle

const SimilarityToggleStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 10px;

  &:nth-of-type(1) {
    border-top: 1px #e8e8e8 solid;
  }
`
