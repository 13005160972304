import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'common'
import { css } from 'react-emotion'

export function TakedownUpgradeDialog() {
  return (
    <div css={content}>
      <h1>Upgrade to continue</h1>

      <span>Your background helps us to personalize our service.</span>

      <ul>
        <li>
          <em>Priority</em> image monitoring
        </li>
        <li>
          Monitor <em>more images</em>
        </li>
        <li>
          Issue instant <em>takedowns</em>
        </li>
      </ul>

      <Link to="/profile/plans">
        <Button>Upgrade now</Button>
      </Link>
      <a href="https://www.pixsy.com/find" target="_blank">
        <p>Click here to find out more</p>
      </a>
    </div>
  )
}

TakedownUpgradeDialog.propTypes = {}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    h1,
    span {
      text-align: center;
    }
  }
`
