import React, { useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { useMutation } from '@apollo/client'
import { useInView } from 'react-hook-inview'
import REMOVE_CLUSTER_TAG from '../graphql/mutations/removeClusterTag'

const TagAsSeen = ({ clusterId, clusterTags, children, score }) => {
  const [tagAsSeen] = useMutation(REMOVE_CLUSTER_TAG)
  const [ref, inView] = useInView({ threshold: 1 })

  const debouncedTagRemoving = useCallback(
    debounce(async () => {
      await tagAsSeen({
        variables: {
          clusterId,
          tag: 'unseen',
        },
      })

      try {
        window.mixpanel.track(`Matches.mv3.seen`, {
          score,
        })
      } catch (e) {
        console.error(`Unable to invoke mixpanel method:`, e)
      }
    }, 2000),
    []
  )

  useEffect(() => {
    if (inView) {
      debouncedTagRemoving()
    } else {
      debouncedTagRemoving.cancel()
    }
  }, [inView])

  //return early without binding the intersection observer event if the cluster has already been seen
  if (!clusterId || !clusterTags.includes('unseen')) return children

  return <div ref={ref}>{children}</div>
}

export default TagAsSeen
