import { memoize } from 'lodash'
import { CURRENCIES } from 'pixsy-constants'
import * as React from 'react'
// import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'

// export const CurrenciesItems = memoize(() => {
//   return CURRENCIES.map(c => (
//     <PixsyMenuItem key={c} value={c}>
//       {c}
//     </PixsyMenuItem>
//   ))
// })
export const CurrenciesItems = memoize(() => {
  return CURRENCIES.map(c => (
    <option key={c} value={c}>
      {c}
    </option>
  ))
})
