import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import {
  Button,
  ClosedQuestionRadio,
  VerticalRadioGroup,
  AlignLabels,
} from 'common'
import { css } from 'react-emotion'
import { updateUser } from '../../../redux/modules/auth'
import _get from 'lodash/get'

export function PhotographerQuestionaire({
  valid,
  submitting,
  handleSubmit,
  showReportedLicensingPriceQuestion,
  user,
}) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Profile Type</h1>

      <span>Your background helps us to personalize our service.</span>

      <div css={formWrap}>
        {(!user.photographerFields ||
          !user.photographerFields.photographerType) && (
          <AlignLabels width="190px">
            <Field
              name="photographerFields.photographerType"
              label="Please select the option that fits you best"
              component={VerticalRadioGroup}
              items={[
                {
                  value: 'professional',
                  label:
                    'I am a professional photographer and publish images regularly',
                },
                {
                  value: 'hobbyist',
                  label:
                    'I am a hobbyist photographer and publish images regularly',
                },
                {
                  value: 'just curious',
                  label:
                    'I do not publish  images regularly / am not a photographer (just curious)',
                },
              ]}
            />
          </AlignLabels>
        )}

        {(!user.photographerFields ||
          typeof user.photographerFields.wonAwards !== 'boolean') && (
          <Field
            name="photographerFields.wonAwards"
            label="Have you ever won an award for your images?"
            component={ClosedQuestionRadio}
            normalize={normalizeBoolean}
          />
        )}

        {(!user.photographerFields ||
          typeof user.photographerFields.registeredForCopyright !==
            'boolean') && (
          <Field
            name="photographerFields.registeredForCopyright"
            label="Have you ever registered any images with a government copyright office (e.g. USCO)"
            component={ClosedQuestionRadio}
            normalize={normalizeBoolean}
          />
        )}

        {(!user.reportedLicensingPrice ||
          typeof user.reportedLicensingPrice === 'undefined') && (
          <AlignLabels width="70%">
            <Field
              name="reportedLicensingPrice"
              label={
                <span>
                  Have you ever licensed an image?
                  <br />
                  If so, what was the average value per license?
                </span>
              }
              component={VerticalRadioGroup}
              items={[
                {
                  value: '<400',
                  label: 'Under $400 USD',
                },
                {
                  value: '400-750',
                  label: '$400 - $750 USD',
                },
                {
                  value: '>750',
                  label: '$750 USD or higher',
                },
                {
                  value: '-',
                  label: 'I have never licensed an image',
                },
              ]}
            />
          </AlignLabels>
        )}
      </div>

      <Button submit disabled={!valid || submitting}>
        Continue
      </Button>
    </form>
  )
}

PhotographerQuestionaire.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const PhotographerQuestionaireWrapper = connect(state => ({
  user: state.auth.user,
}))(
  reduxForm({
    form: 'PhotographerQuestionaire',
    // asyncChangeFields: ['_reportedLicencingPrice'], // hack to adjust to new height
    // asyncValidate: (values, dispatch, props) => {
    //   props.handleResize()
    //   return Promise.resolve()
    // },
    validate: values => ({
      photographerFields: {
        ...(undefined ===
          _get(values, 'photographerFields.photographerType') && {
          photographerType: 'Choice required',
        }),
        ...(undefined === _get(values, 'photographerFields.wonAwards') && {
          wonAwards: 'Choice required',
        }),
        ...(undefined ===
          _get(values, 'photographerFields.registeredForCopyright') && {
          registeredForCopyright: 'Choice required',
        }),
      },
      ...(undefined === _get(values, 'reportedLicensingPrice') && {
        reportedLicensingPrice: 'Choice required',
      }),
    }),
    onSubmit: (values, dispatch, { onComplete }) =>
      dispatch(updateUser(values)).then(onComplete),
  })(PhotographerQuestionaire)
)

PhotographerQuestionaireWrapper.eligible = user =>
  user.type === 'photographer' &&
  !(
    user.photographerFields &&
    typeof user.reportedLicensingPrice !== 'undefined' &&
    typeof user.photographerFields.photographerType !== 'undefined'
  )

export default PhotographerQuestionaireWrapper
const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const formWrap = css`
  width: 100%;
  padding: 15px 0 15px 0;
`

function normalizeBoolean(value) {
  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  return null
}
