import React, { useState } from 'react'
import { Label, PhantomLabelSpacer, Button } from 'common'
import { css } from 'react-emotion'
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js'
// import PaymentStatus from './PaymentStatus';

const CouponField = ({ label, onChange, maxLength }) => {
  return (
    <div css={couponParent}>
      <Label title={label} />
      <input style={{ flex: '1 1 90%' }} onChange={onChange} maxLength={maxLength} />
    </div>
  )
}

const SetupForm = ({ applyCoupon }) => {
  const [coupon, setCoupon] = useState(null)
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)

  const handleError = (error) => {
    setErrorMessage(error.message)
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    try {
      event.preventDefault()
      setLoading(true)

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        return null
      }
      const return_url = window.location.href
      const { error: submitError } = await elements.submit()
      if (submitError) {
        handleError(submitError)
        return
      }
      let res
      if (coupon) {
        res = await applyCoupon({ couponId: coupon })
      }
      if (coupon && (!res || !res.payload.user)) return
      const { error } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url,
        },
      })

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message)
      } else {
        // Your customer will be redirected to `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (error) {
      console.log('Error ', error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    setCoupon(event.target.value)
  }

  return (
    <form onSubmit={handleSubmit} id="submit-payment">
      <PaymentElement />
      <CouponField onChange={handleChange} name="coupon" label="Coupon" maxLength={50} />
      <PhantomLabelSpacer alignContent="right">
        <div css={mobile}>
          <Button submit disabled={!stripe || loading}>
            Add Payment Method
          </Button>
        </div>
      </PhantomLabelSpacer>
      {/* <button disabled={!stripe}>Submit</button> */}
      {/* Show error message to your customers */}
      {errorMessage && <div style={{ color: '#DF1B41' }}>Oops: {errorMessage}</div>}
    </form>
  )
}

export default SetupForm

const couponParent = css`
  display: flex;
  padding: 20px 30px;
  position: relative;
`

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
      padding: 8px 10px;
    }
  }
`
