import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { ChooseBestImageSource } from 'common'
import { getProxyUrlToMatch } from 'pixsy-constants'

export default function TakeActionMatchSelectionPreview({ matches }) {
  return (
    <div css={wrap} id="modal-viewer-select-scope" className="viewer-modal">
      {matches.length < 5 ? (
        <ul css={thumbnailRow}>
          {matches.map(m => (
            <li key={m._id}>
              <ChooseBestImageSource
                candidates={[m.url, getProxyUrlToMatch(m._id)]}
                type="match"
                match={m}
              >
                {({ url }) => (
                  <div
                    css={thumbWrap}
                    style={{ backgroundImage: `url("${url}")` }}
                  />
                )}
              </ChooseBestImageSource>
            </li>
          ))}
        </ul>
      ) : (
        <ul css={thumbnailGrid}>
          {matches.map(m => (
            <li key={m._id}>
              <ChooseBestImageSource
                candidates={[m.url, getProxyUrlToMatch(m._id)]}
                type="match"
                match={m}
              >
                {({ url }) => (
                  <div
                    css={thumbWrap}
                    style={{ backgroundImage: `url("${url}")` }}
                  />
                )}
              </ChooseBestImageSource>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const wrap = css`
  padding: 40px 70px 30px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px 10px;
    ul {
      white-space: nowrap;
      overflow-x: scroll;
      display: block;
      width: 100%;

      li {
        display: inline-block;
      }
    }
  }

  h4 {
    color: #fff;
  }

  span {
    color: rgba(255, 255, 255, 0.75);
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  button {
    margin-bottom: 20px;
  }
`

const thumbnailRow = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  margin: 20px 0 40px;
  list-style: none;
  li {
    margin: 0 10px;
    height: 100px;
    width: 100px;
  }
`

const thumbnailGrid = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  margin: 20px 0 40px;
  li {
    height: 100px;
    width: 100px;

    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }
`

const thumbWrap = css`
  height: 100%;
  background-size: cover;
  border: 1px solid #999;
  background-position: center center;
`

TakeActionMatchSelectionPreview.propTypes = {
  matches: PropTypes.array.isRequired,
}
