import { asyncConnect } from 'redux-connect'
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import { load, loadHistory } from '../../../redux/modules/cases'
import { redirectWhen } from '../../../decorators'
import CaseDetails from './CaseDetails'

const log = require('debug')('case:details')

function mapStateToProps(state, ownProps) {
  const {
    entities: { clusters, domains, cases, matches, images, history: _history },
  } = state
  const {
    match: {
      params: { id },
    },
  } = ownProps

  let caze = { ...cases[id] } || null

  if (caze && caze.salesforce) {
    const history = _cloneDeep(
      Object.values(_history)
        .filter(
          historyItem =>
            historyItem.requestId &&
            historyItem.requestId.startsWith(caze.salesforce.Id)
        )
        .sort((a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix())
    )

    // partial reconstruction due to potentially broken db relationships
    if (!clusters[caze.cluster]) return { case: caze }
    const cluster = { ...clusters[caze.cluster] }

    cluster.domain = domains[cluster.domain]

    let tempMatches = cluster.matches
      .map(i => matches[i])
      .filter(m => m.case || !m.ignored)

    /**
     * If case.submission is available, we need to filter cluster matches
     * based on the matches that were actually submitted
     */
    if (caze.submission) {
      const submissionMatches = caze.submission.matches
      tempMatches = tempMatches.filter(m => submissionMatches.includes(m._id))
    }

    if (tempMatches.length === 0) {
      tempMatches = cluster.matches.map(i => matches[i]).filter(m => !m.ignored)
    }

    if (tempMatches.length === 0) {
      tempMatches = cluster.matches.map(i => matches[i])
    }

    cluster.matches = tempMatches

    cluster.images = cluster.images
      .map(i => images[i])
      .filter(img => cluster.matches.map(c => c.image).includes(img._id))
      .map(i => ({
        ...i,
        matches: cluster.matches.filter(m => m.image === i._id),
      }))
      .map(i => ({
        ...i,
        rejected: i.matches.some(m => m.rejected),
      }))

    caze = {
      ...caze,
      cluster,
      history,
    }

    if (
      caze.cluster &&
      caze.cluster.legacy &&
      caze.cluster.legacy.cases &&
      caze.cluster.legacy.cases.length
    ) {
      log('legacy case detected')
      caze.cluster.matches = caze.cluster.matches.filter(
        m => m._id === caze.match
      )
      caze.cluster.images = caze.cluster.images.filter(i =>
        caze.cluster.matches.some(m => m.image === i._id)
      )
    }

    // TODO: Still missing more case properties like LawFirm__r.Name
    // Thinking about whether to best create a different endpoint next to readOne
    // Or give the handlers a way to be customized via query
    log('Case %O', caze)
  }

  return {
    case: caze,
  }
}

export default asyncConnect(
  [
    {
      promise({
        store: { dispatch },
        match: {
          params: { id },
        },
      }) {
        return Promise.all([dispatch(load(id)), dispatch(loadHistory(id))])
      },
    },
  ],
  mapStateToProps,
  { load, loadHistory }
)(
  redirectWhen({
    shouldRedirect: ({ case: caze }) => !caze || !caze._id,
    to: '/cases/all',
  })(
    redirectWhen({
      shouldRedirect: props => {
        return (
          props.case.submission &&
          props.case.submission.version &&
          !(props.case.history && props.case.history.length > 0)
        )
      },
      to: props => '/cases/submission/' + props.case._id,
    })(CaseDetails)
  )
)
