import styled from 'react-emotion'
import PropTypes from 'prop-types'

const InfoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  border-top: 1px #dddddd solid;
  margin-top: 15px;
`

const InfoColumn = styled.div`
  position: relative;
  ${(props) => props.grid && ' display: grid; grid-template-columns: 1fr 79px; gap: 5px;'};
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  margin: 30px 0;

  .infoColumnButtonsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    .actionButton span {
      font-size: 8px;
    }
  }

  ${(props) => props.left && 'border-right: 1px solid #DDDDDD; padding-right:20px;'}
`
InfoColumn.propTypes = {
  directionRow: PropTypes.bool,
  flexWrap: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  col: PropTypes.number,
  paddingBottom: PropTypes.number,
}
InfoColumn.defaultProps = {
  col: 1,
  paddingBottom: 100,
}

const ColumnTitle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -38px;
  width: 100%;

  span {
    width: 70px;
    padding: 1px 0;
    font-size: 8px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    background-color: white;
    text-align: center;
  }
`

const RowData = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.textSize}px;
  color: #212121;
  word-break: break-all;
  width: ${(props) => 100 / props.col}%;

  &:before {
    content: '${(props) => props.property}';
    color: #21212140;
    font-size: ${(props) => props.titleSize}px;
    letter-spacing: 0.25em;
  }
`
RowData.propTypes = {
  col: PropTypes.number,
  property: PropTypes.string,
  titleSize: PropTypes.number,
  textSize: PropTypes.number,
}

RowData.defaultProps = {
  col: 1,
  titleSize: 10,
  textSize: 16,
}

const ScrollableContent = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 0 45px 30px 45px;
  width: 100%;
  z-index: 2;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
  margin-bottom: ${(props) => props.marginBottom}px;
`
Divider.propTypes = {
  marginBottom: PropTypes.number,
}
Divider.defaultProps = {
  marginBottom: 0,
}

const CenterAlignedBigText = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  flex: 1;
`

export { InfoSection, InfoColumn, ColumnTitle, RowData, ScrollableContent, Divider, CenterAlignedBigText }
