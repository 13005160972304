import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import ViewCardGrid from './ViewCardGrid/ViewCardGrid'
import ViewCardList from './ViewCardList/ViewCardList'

const grid = css`
  margin: 30px 0 70px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 70px;
  justify-content: space-between;
`
const list = css`
  margin: 30px 0 70px;
  display: flex;
  flex-direction: column;
`

const views = {
  list: {
    Container: ViewCardList,
    className: list,
  },
  grid: {
    Container: ViewCardGrid,
    className: grid,
  },
}

export default function View({ clusters, view, ...rest }) {
  /**
   * @type {{ Container: React.Component, className: string }}
   */
  view = window.innerWidth < 576 ? views.grid : view
  const { Container, className } = views[view] || views.grid
  return (
    <div className={className}>
      {clusters
        .filter((c) => c.matches.length > 0)
        .map((c, index) => (
          <Container key={c._id} cluster={c} index={index} {...rest} />
        ))}
    </div>
  )
}

View.propTypes = {
  clusters: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  view: PropTypes.oneOf(['list', 'grid']),
}