import React, { PureComponent } from 'react'
import { CrashSite } from 'common'

export default class NotFound extends PureComponent {
  render() {
    return (
      <CrashSite>
        <p>
          404 - The page you are looking for is missing, was removed, does not
          exist or maybe never even existed in the first place.
        </p>
        <p>We‘re sorry about that.</p>
      </CrashSite>
    )
  }
}
