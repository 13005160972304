import React from 'react'
import { css } from 'react-emotion'
import {
  ContentSection,
  SexySeparator,
  SexyDefinitionList,
  PriceTag,
} from 'common'
import moment from 'moment'

export default function LicenseSection({ case: caze, uses }) {
  const items = []

  const isLV =
    caze.salesforce.Estimated_Value__c === 'Low' &&
    caze.salesforce.FirstLetterSentD__c >= '2019-06-25'
  if (!isLV) {
    items.push(['Geographic Factors', caze.salesforce.MarketSize__c])
    items.push(['Category', caze.salesforce.InfringementCategory__c])
    items.push(['Image Size', caze.salesforce.SizeOnPage__c])
  }

  items.push([
    'License Fee',
    () => (
      <PriceTag
        value={caze.salesforce.FinalPrice__c || caze.salesforce.CurrentPrice__c}
        currency={caze.salesforce.CurrencyIsoCode}
      />
    ),
  ])

  return (
    <ContentSection>
      <div className={wrap}>
        <h1>License</h1>
        <SexySeparator space={30} />
        <div css={flex}>
          <div css={leftCol}>
            <h3>License Fee</h3>
          </div>
          <div css={rightCol}>
            <SexyDefinitionList items={items} />
          </div>
        </div>
        <SexySeparator space={20} />

        <div css={flex}>
          <div css={leftCol}>
            <h3>License Conditions</h3>
          </div>
          <div css={rightCol}>
            <SexyDefinitionList
              items={[
                ['Retroactive Use', 'Included'],
                ['Continued Use', caze.salesforce.ContinuedLicense__c],
                [
                  'License End Date',
                  /^\d/.test(caze.salesforce.ContinuedLicense__c) &&
                    moment(caze.salesforce.LicenseExpiryD__c).format('LL'),
                ],
                /*[
                  `Licensed Image${uses.length > 1 ? 's' : ''}`,
                  uses.length > 1
                    ? uses.map(u => <p key={u.Id}>{u.Image__r.Name}</p>)
                    : caze.salesforce.Photo_Title__c,
                ],*/
              ]}
            />
          </div>
        </div>
      </div>
    </ContentSection>
  )
}

const wrap = css`
  h3 {
    font-weight: 400;
    margin-top: 0;
    font-size: 1.7em;
    margin: 0 auto 30px;
  }
`

const flex = css`
  display: flex;
`

const leftCol = css`
  width: 280px;
  margin-right: 40px;
`

const rightCol = css`
  flex: 1;
`
