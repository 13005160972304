import React from 'react'
import styled, { css } from 'react-emotion'
import PropTypes from 'prop-types'
import Label from './Label'
import FormRow from './FormRow'
import AlignLabels from './AlignLabels'

const wrap = css`
  /*display: flex;
  width: 100%;
  flex-direction: column;*/
  display: inline-block;
  width: 100%;
`

const flex = css`
  display: flex;
  > div:nth-child(1) {
    margin-right: 25px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  > div:nth-child(2) {
    flex: 1;
  }
`

const Spacer = styled.div`
  width: 100%;
  height: 10px;
  display: ${props => (props.spacing ? 'block' : 'none')};
`

export default function CompoundFields({
  children,
  label,
  highlighted = false,
  paddingBottom,
  spacing,
}) {
  return (
    <FormRow highlighted={highlighted} paddingBottom={paddingBottom}>
      <div css={wrap}>
        <AlignLabels width="100%">
          <Label title={label} />
          <Spacer spacing={Number(!!spacing)} />
        </AlignLabels>
        <div css={flex}>
          {children.map((child, idx) => (
            <div key={idx}>{child}</div>
          ))}
        </div>
      </div>
    </FormRow>
  )
}

CompoundFields.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  spacing: PropTypes.bool,
  // paddingBottom: PropTypes
}
