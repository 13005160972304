import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'common'
import { SUBMISSION_VALIDATION_SCENARIOS } from 'pixsy-constants'
import { validateSync } from '../../../../../../../shared/isSubmittableSync'
import SubmissionValidationDialogDomainUnresolvable from './SubmissionValidationDialogDomainUnresolvable'
import SubmissionValidationDialogCountryUnresolvable from './SubmissionValidationDialogCountryUnresolvable'
import SubmissionValidationDialogDomainStock from './SubmissionValidationDialogDomainStock'
import SubmissionValidationDialogDirectHotlink from './SubmissionValidationDialogDirectHotlink'
import SubmissionValidationDialogIndirectHotlink from './SubmissionValidationDialogIndirectHotlink'
import SubmissionValidationDialogCategoryUnresolvable from './SubmissionValidationDialogCategoryUnresolvable'
import SubmissionValidationDialogTMOnly from './SubmissionValidationDialogTMOnly'
import SubmissionValidationDialogCC0 from './SubmissionValidationDialogCC0'
import SubmissionValidationDialogCCLegalOnly from './SubmissionValidationDialogCCLegalOnly'
// import SubmissionValidationDialogOnlineMatchesCheck from './SubmissionValidationDialogOnlineMatchesCheck'
// import SubmissionValidationDialogOnlineImagesCheck from './SubmissionValidationDialogOnlineImagesCheck'
import SubmissionValidationDialogDuplicateTest from './SubmissionValidationDialogDuplicateTest'

// import SubmissionValidationDialogOriginApproved from './SubmissionValidationDialogOriginApproved'

import SelectMatchScopeDialogCase from './SelectMatchScopeDialogCase'
import SelectMatchScopeDialogTakedown from './SelectMatchScopeDialogTakedown'

const log = require('debug')('action-dialog')

export default function ActionDialogContainer({
  action,
  matches,
  header,
  subtitle,
  destination,
  cluster,
  close,
  ignore,
  onTakedown,
  onCase,
  location,
  user,
}) {
  const errorMap = validateSync(cluster)
  log('%O', errorMap)

  const alwaysPassDown = {
    matches: cluster.matches.filter(m => !m.ignored && !m.invalid),
    cluster,
    onTakedown,
    onClose: close,
    onIgnore: ignore,
    location,
    user,
  }

  const ERROR_SCENARIO_MAP = {
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_IMAGE_CC0]: props => (
      <SubmissionValidationDialogCC0
        key="SubmissionValidationDialogCC0"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_COUNTRY_UNRESOLVABLE]: props => (
      <SubmissionValidationDialogCountryUnresolvable
        key="SubmissionValidationDialogCountryUnresolvable"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_CATEGORY_UNRESOLVABLE]: props => (
      <SubmissionValidationDialogCategoryUnresolvable
        key="SubmissionValidationDialogCategoryUnresolvable"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_STOCK]: props => (
      <SubmissionValidationDialogDomainStock
        key="SubmissionValidationDialogDomainStock"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_UNRESOLVABLE]: props => (
      <SubmissionValidationDialogDomainUnresolvable
        key="SubmissionValidationDialogDomainUnresolvable"
        {...props}
      />
    ),
    // [SUBMISSION_VALIDATION_SCENARIOS.ERROR_MATCH_ORIGIN_APPROVED]: props => (
    //   <SubmissionValidationDialogOriginApproved
    //     key="SubmissionValidationDialogOriginApproved"
    //     {...props}
    //   />
    // ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_MATCH_HOTLINKED_IMAGE]: props => (
      <SubmissionValidationDialogDirectHotlink
        key="SubmissionValidationDialogDirectHotlink"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.WARNING_MATCH_HOTLINKED_ORIGIN]: props => (
      <SubmissionValidationDialogIndirectHotlink
        key="SubmissionValidationDialogIndirectHotlink"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_DOMAIN_COUNTRY_TM_ONLY]: props => (
      <SubmissionValidationDialogTMOnly
        key="SubmissionValidationDialogTMOnly"
        {...props}
      />
    ),
    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_IMAGE_CC_LEGAL_ONLY]: props => (
      <SubmissionValidationDialogCCLegalOnly
        key="SubmissionValidationDialogCCLegalOnly"
        {...props}
      />
    ),

    [SUBMISSION_VALIDATION_SCENARIOS.ERROR_CLUSTER_MINIMUM_SCORE]: props => (
      <SubmissionValidationDialogCCLegalOnly
        key="SubmissionValidationDialogCCLegalOnly"
        {...props}
      />
    ),

    // WARNING_MATCH_HOTLINKED_ORIGIN: () => <SubmissionValidationDialog/>,
    // ERROR_IMAGE_SOURCE_UNAVAILABLE: () => <SubmissionValidationDialog/>,
    // ERROR_IMAGE_UNAVAILABLE: () => <SubmissionValidationDialog/>,
  }

  const children = []

  if (action === ACTIONS.CASE) {
    // Push case-error scenario warnings
    children.push(
      ...Object.keys(errorMap).map(key =>
        ERROR_SCENARIO_MAP[key](alwaysPassDown)
      )
    )

    children.push(
      <SubmissionValidationDialogDuplicateTest
        key="SubmissionValidationDialogDuplicateTest"
        action={action}
        {...alwaysPassDown}
      />
    )

    // children.push(
    //   <SubmissionValidationDialogOnlineImagesCheck
    //     key="SubmissionValidationDialogOnlineImagesCheck"
    //     action={action}
    //     {...alwaysPassDown}
    //   />
    // )
  }

  // Push online check for both
  // children.push(
  //   <SubmissionValidationDialogOnlineMatchesCheck
  //     key="SubmissionValidationDialogOnlineMatchesCheck"
  //     action={action}
  //     {...alwaysPassDown}
  //   />
  // )

  const STATIC_COMPONENT_MAP = {
    [ACTIONS.CASE]: () => (
      <SelectMatchScopeDialogCase
        key="SelectMatchScopeDialogCase"
        {...alwaysPassDown}
      />
    ),
    [ACTIONS.TAKEDOWN]: () => (
      <SelectMatchScopeDialogTakedown
        key="SelectMatchScopeDialogTakedown"
        {...alwaysPassDown}
      />
    ),
  }

  let onFinish = onCase
  // if (action === ACTIONS.TAKEDOWN) {
  children.push(STATIC_COMPONENT_MAP[action]())
  onFinish = () => {}
  // }

  return <Dialog.Slider onFinish={onFinish}>{children}</Dialog.Slider>
}

ActionDialogContainer.propTypes = {
  cluster: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onCase: PropTypes.func.isRequired,
  onTakedown: PropTypes.func.isRequired,
}

export const ACTIONS = {
  CASE: 'CASE',
  TAKEDOWN: 'TAKEDOWN',
}
