import React from 'react'
import { ZENFOLIO } from 'pixsy-constants'
import PropTypes from 'prop-types'
import { Button, TextFieldNoLabel } from 'common'
import { reduxForm, Field } from 'redux-form'
import { css } from 'react-emotion'

export function ZenfolioDialog({
  synchronize,
  submitting,
  handleSubmit,
  valid,
}) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Importing from Zenfolio</h1>

      <p>
        Pixsy can access your public images from Zenfolio (private images are
        not accessible).
        <br />
        To proceed please provide the URL of your Zenfolio website.
      </p>

      <p>
        <i>Example:</i>
        <br />
        <b>https://username.zenfolio.com/</b>
      </p>

      <div css={actionArea}>
        <div css={fieldRow}>
          <Field
            component={TextFieldNoLabel}
            type="text"
            name="zenfolioDomain"
            label="Website URL - starting with https://"
            labelAlign="right"
          />
        </div>

        <Button submit disabled={!valid || submitting}>
          Import
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'zenfolioImport',
  validate(values, props) {
    const { zenfolioDomain } = values

    if (!zenfolioDomain) return { zenfolioDomain: 'Website URL required' }
    if (!zenfolioDomain.startsWith('https')) {
      return { zenfolioDomain: 'Please provide an URL that starts with https' }
    }

    const exp = /https?:\/\/(.+?\.)?zenfolio\.com\/?$/

    if (!exp.test(zenfolioDomain.toLowerCase())) {
      return { zenfolioDomain: 'Zenfolio domain name invalid.' }
    }

    try {
      const loginName = new URL(zenfolioDomain).hostname
        .split('.')[0]
        .toLowerCase()

      if (loginName === 'www') {
        return { zenfolioDomain: 'Zenfolio domain name invalid.' }
      }
    } catch (err) {
      return { zenfolioDomain: 'Zenfolio domain name invalid.' }
    }
  },
  onSubmit(values, dispatch, { synchronize, onClose }) {
    return synchronize(
      ZENFOLIO,
      values.zenfolioDomain && values.zenfolioDomain.trim()
    ).then(onClose)
  },
})(ZenfolioDialog)

ZenfolioDialog.propTypes = {
  synchronize: PropTypes.func.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  em {
    color: #008aab;
  }
`

const actionArea = css`
  width: 100%;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  input[type='text'] {
    width: 100%;
  }

  button {
    margin-bottom: 10px;
    max-width: 240px;
    margin: 0 auto;
  }
`

const fieldRow = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 20px;
`
