/**
 * @param tags
 * @param matches
 * @param images
 * @returns {*}
 */
export default function getBestImageBasedOnTag(tags, matches, images) {
  const imageScopeTag = tags.find((t) => t.startsWith('image:'))

  if (imageScopeTag) {
    const preferredImage = imageScopeTag.split(':')[1]
    return [
      matches.find((m) => m.image._id === preferredImage) || [matches],
      images.find((i) => i._id === preferredImage) || [images],
    ]
  }

  const [match] = matches
  return [match, (match && images.find((i) => i._id === match.image._id)) || images[0]]
}
