import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import RegisterFormContainer from './RegisterFormContainer'

export default function Register({ match }) {
  const isPPASignup = match.path === '/register/ppa'
  const isSmugmugSignup = match.path === '/register/smugmug'
  const isCherrydeckSignup = location.pathname.includes('/cherrydeck')

  return (
    <div className={wrapper}>
      {isCherrydeckSignup && (
        <div css={cherrydeckIcon}>
          <img src="https://static.pixsy.io/cherrydeck.png" />
        </div>
      )}
      {isPPASignup && (
        <div css={ppaIcon}>
          <img src="https://static.pixsy.io/ppa2.png" />
        </div>
      )}
      {isSmugmugSignup && (
        <div css={smugmugIcon}>
          <img src="https://static.pixsy.io/smugmug.png" />
        </div>
      )}
      <Helmet title="Register" />
      <div className={card}>
        <h2>Nice to meet you</h2>
        <h5>
          Create a Pixsy account by completing the registration form below
        </h5>
        <RegisterFormContainer />
      </div>

      <div className={below}>
        <Link to="/login">
          Already have an account? <span>Log in</span>
        </Link>
      </div>
    </div>
  )
}

const wrapper = css`
  max-width: 370px;
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 5px;
  }
`

const flickIcon = css`
  position: absolute;
  top: -65px;
  width: 100%;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
    transform: scale(1);
    border: 2px solid #008aab;
    border-radius: 30px;
    transition: transform 100ms ease-in;
  }
`

const smugmugIcon = css`
  position: absolute;
  top: -65px;
  width: 100%;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    background-color: #ffff;
    width: 60px;
    height: 60px;
    transform: scale(1);
    border: 2px solid #008aab;
    border-radius: 30px;
    transition: transform 100ms ease-in;
  }
`

const cherrydeckIcon = css`
  position: absolute;
  top: -75px;
  width: 100%;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
    transform: scale(1);
    border: 2px solid #fff;
    border-radius: 30px;
    transition: transform 100ms ease-in;
  }
`

const ppaIcon = css`
  position: absolute;
  top: -95px;
  width: 100%;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 180px;
    transform: scale(1);
    transition: transform 100ms ease-in;
  }
`

const below = css`
  padding: 15px 0 15px 0;

  a {
    color: #fff;
    text-shadow: 1px 1px 20px black;
    font-size: 1.15em;
     {
      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }

    span {
      font-weight: 600;
      letter-spacing: -0.025em;
    }
  }
`

const card = css`
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 37px -6px rgba(0, 0, 0, 0.75);
  border-top: 3px solid #008aab;

  &.wide {
    max-width: 800px;
  }

  img {
    display: block;
    margin: 0 auto 10px;
    width: 300px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 52px;

    overflow: scroll;
  }
  @media (max-height: 670px) {
    height: calc(100vh - 50px - 100px);
  }
`
