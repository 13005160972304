import _ from 'lodash'
import { CASE, CASE_ARRAY, HISTORY_ARRAY, ATTACHMENT_ARRAY, MATCH_ARRAY } from './schemas'

const CASE_LOAD = 'CASE_LOAD'
const CASE_LOAD_SUCCESS = 'CASE_LOAD_SUCCESS'
const CASE_LOAD_FAIL = 'CASE_LOAD_FAIL'

const CASES_LOAD = 'CASES_LOAD'
export const CASES_LOAD_SUCCESS = 'CASES_LOAD_SUCCESS'
const CASES_LOAD_FAIL = 'CASES_LOAD_FAIL'

const PAYMENT_PAGE_LOAD = 'PAYMENT_PAGE_LOAD'
const PAYMENT_PAGE_LOAD_SUCCESS = 'PAYMENT_PAGE_LOAD_SUCCESS'
const PAYMENT_PAGE_LOAD_FAIL = 'PAYMENT_PAGE_LOAD_FAIL'

const CHECK_PREVIOUS_SUBMISSION = 'CHECK_PREVIOUS_SUBMISSION'
const CHECK_PREVIOUS_SUBMISSION_SUCCESS = 'CHECK_PREVIOUS_SUBMISSION_SUCCESS'
const CHECK_PREVIOUS_SUBMISSION_FAIL = 'CHECK_PREVIOUS_SUBMISSION_FAIL'

export const CASES_INVALIDATE = 'CASES_INVALIDATE'

const CASE_SUBMIT = 'CASE_SUBMIT'
export const CASE_SUBMIT_SUCCESS = 'CASE_SUBMIT_SUCCESS'
const CASE_SUBMIT_FAIL = 'CASE_SUBMIT_FAIL'

const CASE_SUBMISSION_LOAD = 'CASE_SUBMISSION_LOAD'
export const CASE_SUBMISSION_LOAD_SUCCESS = 'CASE_SUBMISSION_LOAD_SUCCESS'
const CASE_SUBMISSION_LOAD_FAIL = 'CASE_SUBMISSION_LOAD_FAIL'

export const CASE_HISTORY_LOAD = 'CASE_HISTORY_LOAD'
const CASE_HISTORY_LOAD_SUCCESS = 'CASE_HISTORY_LOAD_SUCCESS'
const CASE_HISTORY_LOAD_FAIL = 'CASE_HISTORY_LOAD_FAIL'

const CASE_ATTACHMENTS_LOAD = 'CASE_ATTACHMENTS_LOAD'
const CASE_ATTACHMENTS_LOAD_SUCCESS = 'CASE_ATTACHMENTS_LOAD_SUCCESS'
const CASE_ATTACHMENTS_LOAD_FAIL = 'CASE_ATTACHMENTS_LOAD_FAIL'

const CASE_UPDATE = 'CASE_UPDATE'

const CASES_UPDATE = 'CASES_UPDATE'
const CASES_UPDATE_SUCCESS = 'CASES_UPDATE_SUCCESS'
const CASES_UPDATE_FAIL = 'CASES_UPDATE_FAIL'

const PAYMENT_HANDLE = 'PAYMENT_HANDLE'
const PAYMENT_HANDLE_SUCCESS = 'PAYMENT_HANDLE_SUCCESS'
const PAYMENT_HANDLE_FAIL = 'PAYMENT_HANDLE_FAIL'

const BUMP_REQUEST = 'BUMP_REQUEST'
const BUMP_REQUEST_SUCCESS = 'BUMP_REQUEST_SUCCESS'
const BUMP_REQUEST_FAIL = 'BUMP_REQUEST_FAIL'

const CASES_APPLY_SORT_KEY = 'CASES_APPLY_SORT_KEY'
export const CASES_RESET_FILTER = 'CASES_RESET_FILTER'

const INFRINGERS_LOAD = 'INFRINGERS_LOAD'
const INFRINGERS_LOAD_SUCCESS = 'INFRINGERS_LOAD_SUCCESS'
const INFRINGERS_LOAD_FAIL = 'INFRINGERS_LOAD_FAIL'

export const LFC_DETAIL_MODAL = 'LFC_DETAIL_MODAL'
export const LFC_TABLE_ROW_ACCESS_HANDOVER = 'LFC_TABLE_ROW_ACCESS_HANDOVER'

const IMAGE_VALIDATE = 'IMAGE_VALIDATE'
const IMAGE_VALIDATE_SUCCESS = 'IMAGE_VALIDATE_SUCCESS'
const IMAGE_VALIDATE_FAIL = 'IMAGE_VALIDATE_FAIL'

const CASE_CHECK_DUPLICATE = 'CASE_CHECK_DUPLICATE'
const CASE_CHECK_DUPLICATE_SUCCESS = 'CASE_CHECK_DUPLICATE_SUCCESS'
const CASE_CHECK_DUPLICATE_FAIL = 'CASE_CHECK_DUPLICATE_FAIL'

const CASE_DELETE_DRAFT = 'CASE_DELETE_DRAFT'
export const CASE_DELETE_DRAFT_SUCCESS = 'CASE_DELETE_DRAFT_SUCCESS'
const CASE_DELETE_DRAFT_FAIL = 'CASE_DELETE_DRAFT_FAIL'

const initialState = {
  sortOrder: 'DESC',
  sortKey: 'Name',
  infringers: [],
  loading: false,
}

export default function cases(state = initialState, action = {}) {
  switch (action.type) {
    case CASE_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      }

    case CASES_LOAD:
    case CASE_SUBMIT:
      return {
        ...state,
        loading: true,
      }

    case CASES_LOAD_FAIL:
    case CASES_LOAD_SUCCESS:
    case CASE_SUBMIT_SUCCESS:
    case CASE_SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
      }

    case CASE_LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error ? action.error.message : action.error,
      }
    case CASES_LOAD_SUCCESS:
      return {
        ...state,
        error: null,
      }
    case CASES_LOAD_FAIL:
      return {
        ...state,
        data: null,
        error: action.error ? action.error.message : action.error,
      }
    case CASES_APPLY_SORT_KEY:
      return {
        ...state,
        sortKey: action.payload.sortKey,
        sortOrder: state.sortOrder === 'ASC' ? 'DESC' : 'ASC',
      }
    case CASES_RESET_FILTER:
      return {
        ...state,
        sortKey: null,
        sortOrder: 'ASC',
      }

    default:
      return state
  }
}

export function paginationKey(query = {}) {
  return [query.filter, query.page || '0', query.sortKey || 'Id', query.sortDir || 'DESC'].join('-')
}

/**
 * @param {Object} globalState
 * @returns {boolean}
 */
export function isLoaded(globalState) {
  return globalState.cases && globalState.cases.loaded
}

/**
 * @param {Object} globalState
 * @param {string} id
 * @returns {boolean}
 */
export function has(globalState, id) {
  return !!globalState.entities.cases[id]
}

/**
 * @param {Object} globalState
 * @param {string} id caseId
 * @returns {boolean}
 */
export function areNotesLoaded(globalState, id) {
  const c = globalState.entities.cases[id]

  if (!c || !c.notes) return false

  for (const n of c.notes) if (n.Id === id) return true

  return false
}

/**
 * @param {Object} entities
 * @returns {Array}
 */
export function find(entities, query = {}) {
  return _.cloneDeep(_.filter(_.toArray(entities), query))
}

/**
 * @param {Object} entities
 * @param {string} id from MongoDB
 * @returns {Array}
 */
export function getNotesForCase(entities, id) {
  return _(entities)
    .toArray()
    .filter((n) => n._id === id)
    .value()
}

export function applySortKey(sortKey) {
  return {
    type: CASES_APPLY_SORT_KEY,
    payload: {
      sortKey,
    },
  }
}

export function resetFilter(data = {}) {
  return {
    type: CASES_RESET_FILTER,
    payload: { ...data },
  }
}

export function submit(data) {
  return {
    promise: (client) => client.post(`/cases/submission`, { data }),
    types: [CASE_SUBMIT, CASE_SUBMIT_SUCCESS, CASE_SUBMIT_FAIL],
    schema: {
      case: CASE,
      // cluster: CLUSTER,
    },
  }
}

// Submission v6
export function saveCase(data) {
  return {
    promise: (client) => client.post(`/cases/submission`, { data }),
    check: (state) => !state.cases.loading,
    types: [CASE_SUBMIT, CASE_SUBMIT_SUCCESS, CASE_SUBMIT_FAIL],
    schema: {
      case: CASE,
      matches: MATCH_ARRAY,
    },
  }
}

// Submission v6
export function submitCase(data) {
  return {
    promise: (client) => client.post(`/cases/submission/submit`, { data }),
    types: [CASE_SUBMIT, CASE_SUBMIT_SUCCESS, CASE_SUBMIT_FAIL],
    schema: {
      case: CASE,
    },
  }
}

export function deleteDraftCase(data, query) {

  const invalidationPayload = {}
  if (data.cluster && data.cluster._id) {
    invalidationPayload.clusters = [data.cluster._id]
  }
  if (data.cluster && data.cluster.matches) {
    invalidationPayload.matches = data.cluster.matches.map((m) => m._id)
  }
  if( data._id ) {
    invalidationPayload.cases = [data._id]
  }
  return {
    promise: (client) => client.post('/cases/submission/delete', { data }),
    types: [CASE_DELETE_DRAFT, CASE_DELETE_DRAFT_SUCCESS, CASE_DELETE_DRAFT_FAIL],
    schema: {
      case: CASE,
      matches: MATCH_ARRAY,
    },
    payload: {
      query,
      invalidate: invalidationPayload
    },
  }
}

// Submission v6
export function loadCaseSubmission(id) {
  return {
    promise: (client) => client.get(`/cases/submission/${id}`),
    types: [CASE_SUBMISSION_LOAD, CASE_SUBMISSION_LOAD_SUCCESS, CASE_SUBMISSION_LOAD_FAIL],
    schema: CASE,
  }
}

export function loadSubmission(id, data) {
  return {
    types: [CASE_SUBMISSION_LOAD, CASE_SUBMISSION_LOAD_SUCCESS, CASE_SUBMISSION_LOAD_FAIL],
    promise: (client) => client.get(`/cases/submission/${id}`),
    schema: CASE,
  }
}

export function checkForPreviousSubmission(url) {
  return {
    types: [CHECK_PREVIOUS_SUBMISSION, CHECK_PREVIOUS_SUBMISSION_SUCCESS, CHECK_PREVIOUS_SUBMISSION_FAIL],
    promise: (client) => client.get(`/cases/by-url`, { params: { url } }),
    schema: CASE,
  }
}

export function load(id, params = {}) {
  return {
    types: [CASE_LOAD, CASE_LOAD_SUCCESS, CASE_LOAD_FAIL],
    promise: (client) => client.get(`/cases/${id}`, { params }),
    schema: CASE,
  }
}

export function loadForPayment(id, params = {}) {
  return {
    types: [PAYMENT_PAGE_LOAD, PAYMENT_PAGE_LOAD_SUCCESS, PAYMENT_PAGE_LOAD_FAIL],
    promise: (client) => client.get(`/cases/payment-page/${id}`, { params }),
  }
}

export function loadCases(query = {}) {
  return {
    types: [CASES_LOAD, CASES_LOAD_SUCCESS, CASES_LOAD_FAIL],
    promise: (client) => client.get('/cases', { params: query }),
    schema: { cases: CASE_ARRAY },
    payload: { query },
  }
}

export function loadInfringers(params = {}) {
  return {
    types: [INFRINGERS_LOAD, INFRINGERS_LOAD_SUCCESS, INFRINGERS_LOAD_FAIL],
    promise: (client) => client.get('/infringers', { params }),
  }
}

/**
 * @param {string} id from Salesforce
 * @returns {Object} Action
 */
export function loadHistory(caseId) {
  return {
    types: [CASE_HISTORY_LOAD, CASE_HISTORY_LOAD_SUCCESS, CASE_HISTORY_LOAD_FAIL],
    promise: (client) => client.get(`/cases/${caseId}/history`),
    schema: HISTORY_ARRAY,
    payload: { caseId },
  }
}

export function loadAttachments(Id) {
  return {
    types: [CASE_ATTACHMENTS_LOAD, CASE_ATTACHMENTS_LOAD_SUCCESS, CASE_ATTACHMENTS_LOAD_FAIL],
    promise: (client) => client.get(`/cases/${Id}/attachments`),
    schema: ATTACHMENT_ARRAY,
  }
}

/**
 * @param {Object} cases payload
 * @returns {Object} Action
 */
export function update(data) {
  return {
    types: [CASES_UPDATE, CASES_UPDATE_SUCCESS, CASES_UPDATE_FAIL],
    promise: (client) => client.put('/cases', { data }),
    schema: { cases: CASE_ARRAY },
  }
}

/**
 * Set Action Required on PH/IF/LF Main Case and attach note
 *
 * @param {Object} data
 * @returns {Object} Action
 */
export function bumpRequest(id, data) {
  return {
    types: [BUMP_REQUEST, BUMP_REQUEST_SUCCESS, BUMP_REQUEST_FAIL],
    promise: (client) => client.put(`/cases/${id}/bump`, { data }),
    schema: { cases: CASE_ARRAY },
  }
}

export function handleSingleStripePayment(data) {
  return {
    types: [PAYMENT_HANDLE, PAYMENT_HANDLE_SUCCESS, PAYMENT_HANDLE_FAIL],
    promise: (client) => client.post('/cases/payment/stripe', { data }),
    schema: { case: CASE },
  }
}

export function handleInstalmentStripePayment(data) {
  return {
    types: [PAYMENT_HANDLE, PAYMENT_HANDLE_SUCCESS, PAYMENT_HANDLE_FAIL],
    promise: (client) => client.post('/cases/payment/stripe/instalment', { data }),
    schema: { case: CASE },
  }
}

/**
 * @param {?Object} query
 */
export function invalidate(query) {
  return {
    type: CASES_INVALIDATE,
    schema: { cases: CASE_ARRAY },
    payload: { query },
  }
}

/**
 * @deprecated
 * @param {string} _id
 * @param {string} field string
 * @param {any} value
 * @returns {Object} Action
 */
export function updateCase(_id, field, value) {
  return {
    type: CASE_UPDATE,
    entities: {
      cases: {
        [_id]: {
          [field]: value,
        },
      },
    },
  }
}

/**
 * @param {Object} data
 * @returns {Object} Action
 */
export function validate(data) {
  return {
    types: [IMAGE_VALIDATE, IMAGE_VALIDATE_SUCCESS, IMAGE_VALIDATE_FAIL],
    promise: (client) => client.post('/cases/validate', { data }),
  }
}

/**
 * @param {string} host
 * @param {[string]|string} ignored
 * @returns {Object} Action
 */
export function checkDuplicate(host, ignored = []) {
  return {
    types: [CASE_CHECK_DUPLICATE, CASE_CHECK_DUPLICATE_SUCCESS, CASE_CHECK_DUPLICATE_FAIL],
    promise: (client) =>
      client.get(`/cases`, {
        params: {
          filter: `host:${host}`,
          ignored: ignored.join(),
          page: 0,
          pageSize: 5,
        },
      }),
  }
}
