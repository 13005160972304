import CircularProgress from '@material-ui/core/CircularProgress'
import * as React from 'react'
import { css, cx } from 'react-emotion'

export const PixsyProgress = React.memo(
  ({ size = 24, progress, thickness = 2, color, className }) => {
    const ownStyles = css({
      color: `${color || '#212121'} !important`,
    })
    return (
      <CircularProgress
        classes={{ colorPrimary: cx(ownStyles, className) }}
        size={size}
        thickness={thickness}
        variant={progress == null ? 'indeterminate' : 'static'}
        value={progress}
        color="primary"
      />
    )
  }
)
