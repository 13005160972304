import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import { ChooseBestImageSource, SelectableContext } from 'common'
import { requestEmailVerification } from '../../../redux/modules/auth'
import QuotaExceededOverlay from './QuotaExceededOverlay'
import VerificationPendingOverlay from './VerificationPendingOverlay'
import OfflineOverlay from './OfflineOverlay'
import ImageCardThumbnail from './ImageCardThumbnail'

function ImageCard({
  image,
  onClickCheck,
  checked = false,
  requestEmailVerification,
  notVerified,
  eligibleForTracking,
  imageOffline,
  destination,
  aboveQuota,
  isSelecting,
}) {
  const renderAddon = (props) =>
    Overlay({
      requestEmailVerification,
      notVerified,
      aboveQuota,
      imageOffline,
      image,
      ...props,
    })

  const candidates = [
    image.thumbnail,
    image.url,
    `https://accessible.pixsy.io/image/from-url?resize=500&q=${image.url}`,
    `https://accessible.pixsy.io/image/${image._id}`,
    `https://online.pixsy.io/image/from-url?resize=500&q=${image.url}`,
    `https://online.pixsy.io/image/${image._id}`,
  ].filter(Boolean)

  if (image.origin && image.origin.source === 'instagram') {
    let originUrl = image.origin.url || ''
    if (originUrl && originUrl.endsWith('/')) {
      originUrl = originUrl.slice(0, -1)
    }
    candidates.unshift(`${originUrl}/media?size=l`)
  }

  return (
    <div style={{ zIndex: '2' }}>
      <div css={gridItem}>
        <ChooseBestImageSource
          candidates={candidates}
          type={image.tags && image.tags.includes('processing') ? 'imageProcessing' : 'image'}
          useCache
        >
          {(innerProps) => (
            <ImageCardThumbnail
              {...innerProps}
              image={image}
              destination={destination}
              isSelecting={isSelecting}
              {...(renderAddon && { renderAddon })}
            />
          )}
        </ChooseBestImageSource>
        <div css={text}>
          <h3>{image.title ? image.title : <i>Untitled Image</i>}</h3>

          <Link to={`/matches/image:${image._id}`}>
            <span>
              {image.matches} Match
              {image.matches !== 1 && 'es'}
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

function Overlay({ notVerified, imageOffline, aboveQuota, image, ...props }) {
  if (notVerified) {
    return <VerificationPendingOverlay {...props} />
  }

  if (imageOffline) {
    return <OfflineOverlay {...props} />
  }

  if (aboveQuota) {
    return <QuotaExceededOverlay {...props} />
  }

  return null
}

export default connect(
  (state, props) => {
    // compare user model isVerified and scanning spinner
    const { image, linkbase } = props
    const user = state.auth.user

    const verified = user && user.verification && user.verification.email && user.verification.email.dateVerified

    const imageOffline = image && image.tags && image.tags.includes('online:false')

    /******* */
    // see api/controllers/images/middleware/strategies/pivotalPhotoStrategy.js
    const imageTags = image.tags || []
    const eligibleForTracking = !(
      image.ignored ||
      image.duplicated ||
      imageTags.includes('issue') ||
      imageTags.includes('online:false')
    )
    /******* */

    const destination = {
      pathname: `/images/${image._id}`,
      state: {
        imageContext: linkbase,
      },
    }

    return {
      notVerified: !verified,
      eligibleForTracking,
      imageOffline,
      destination,
      aboveQuota: eligibleForTracking && !image.tracked && Date.now() - new Date(image.created).getTime() > 60 * 1000, // Give 1 minute of headstart to start tracking images
    }
  },
  { requestEmailVerification }
)(function ImageCardContainer(props) {
  return (
    <SelectableContext.Consumer>
      {({ isSelecting }) => <ImageCard {...props} isSelecting={isSelecting} />}
    </SelectableContext.Consumer>
  )
})

const gridItem = css`
  width: 100%;
`

const text = css`
  text-align: center;
  padding: 0 4px;

  h3 {
    margin: 10px 0 -2px;
    font-size: 1.05em;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    font-size: 0.875em;
    margin: 0;
    font-weight: 300;
    color: rgb(120, 120, 120);

    &.zero {
      opacity: 0.5;
    }
  }
`

ImageCard.propTypes = {
  image: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  onClickCheck: PropTypes.func,
  checked: PropTypes.bool,
}
