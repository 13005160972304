import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Modal } from 'common'

const StyledModal = ({ isOpen, onRequestClose, children }) => {
  const htmlElement = document.getElementsByTagName('HTML')[0]
  useEffect(() => {
    if (isOpen) {
      htmlElement.style.overflow = 'hidden'
    } else {
      htmlElement.style.removeProperty('overflow')
    }
    return () => {
      htmlElement.style.removeProperty('overflow')
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={modalStyles.modal}
      overlayClassName={modalStyles.overlay}
      closeButtonClassName={modalStyles.closeButton}
      theme="light"
    >
      {children}
    </Modal>
  )
}

const modalStyles = {
  modal: css`
    max-width: 850px;
    margin-top: 15px !important;
    margin-right: 50px !important;
    max-height: calc(100vh - 30px);
    box-shadow: #00000016 0 3px 6px;
    padding: 15px 0 0 0;

    @media only screen and (max-width: 992px) {
      margin-right: auto !important;
    }
  `,
  overlay: css`
    background-color: #00000050;
  `,
  closeButton: css`
    position: absolute;
    top: -15px;
    right: -54px;
    z-index: 10;
    svg line {
      stroke-width: 3px;
      stroke: #ffffff;
    }
  `,
}

StyledModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,

  onRequestClose: PropTypes.func.isRequired,
}

export default StyledModal
