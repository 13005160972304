import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'common'
import { createBatch } from '../../../redux/modules/rules'

function BulkPasteRulesModalComponent({
  onClose,
  setting,
  handleSubmit,
  pristine,
  invalid,
}) {
  const verb = setting.slice(0, -1)
  return (
    <form css={wrap} onSubmit={handleSubmit}>
      <h1>Importing {setting} websites</h1>
      <p>
        To {verb} multiple Websites at once, paste their URLs into below box,
        <em> line by line or seperated by space</em>. Duplicates and empty lines
        will be skipped.
      </p>

      <Field
        name="list"
        type="text"
        component="textarea"
        props={{
          wrap: 'soft',
        }}
      />

      <p css={help}>
        Supported formats: <span>https://pixsy.com</span>{' '}
        <span>https://pixsy.com/about</span> <span>pixsy.com</span>{' '}
        <span>pixsy.com/blog/2020</span>
      </p>

      <div css={buttons}>
        <Button greyOutline onClick={onClose}>
          Cancel
        </Button>
        <Button hoverMain submit disabled={pristine || invalid}>
          Import
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'bulkPasteRules',
  validate: values =>
    !(values.list && values.list.trim().includes('.')) && {
      list: 'ERROR_NOT_VISIBLE',
    }, // needs at least one item that has a dot
  onSubmit: async (values, dispatch, { setting, onSuccess, onClose }) => {
    await dispatch(createBatch(setting, values.list))
    onClose()
    onSuccess()
  },
})(BulkPasteRulesModalComponent)

BulkPasteRulesModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  setting: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
}

const buttons = css`
  display: flex;
  justify-content: center;
  flex-direction: row;

  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const wrap = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;

  textarea {
    width: 100%;
    border: 1px solid #e8e8e8;
    min-height: 200px;
    margin-bottom: 20px;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
  }

  p {
    text-align: center;
  }
`

const help = css`
  font-style: italic;
  font-size: 0.8em;
  span {
    font-style: normal;
    background-color: #38383838;
    padding: 4px;
  }
`
