import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { HotKeys } from 'react-hotkeys'
import { css } from 'react-emotion'

const SelectableContext = React.createContext()

class SelectableContextWrapper extends Component {
  constructor({ initialValues = {} }) {
    super()
    this.state = {
      enabled: initialValues.enabled === false ? false : true,
      selection: initialValues.selection || [],
    }
    this._keyMap = {
      esc: 'esc',
    }
    this._keyHandlers = {
      esc: this.clear,
    }
  }

  toggleSelection = id => {
    const { selection } = this.state
    const { limit, handleLimitExceeded } = this.props

    if (!id) return null

    const foundAtIndex = selection.indexOf(id)

    if (foundAtIndex === -1) {
      if (selection.length < limit) {
        this.setState({ selection: [...selection, id] })
      } else {
        typeof handleLimitExceeded === 'function' && handleLimitExceeded(limit)
      }
    } else {
      this.setState(({ selection }) => ({
        selection: [
          ...selection.slice(0, foundAtIndex),
          ...selection.slice(foundAtIndex + 1),
        ],
      }))
    }
  }

  clear = cb => {
    this.setState({ selection: [] }, typeof cb === 'function' ? cb : undefined)
  }

  render() {
    const { enabled, selection } = this.state
    const passDown = {
      ...this.props,
      enabled,
      selection,
      isAtLimit: this.state.selection.length >= this.props.limit,
      isSelecting: Boolean(enabled && selection.length > 0),
      toggleSelection: this.toggleSelection,
      clear: this.clear,
    }
    return (
      <HotKeys
        focused
        attach={global}
        keyMap={this._keyMap}
        handlers={this._keyHandlers}
        css={focusTrapStyle}
      >
        <SelectableContext.Provider value={passDown}>
          {this.props.children}
        </SelectableContext.Provider>
      </HotKeys>
    )
  }
}

SelectableContextWrapper.defaultProps = {
  limit: 100,
}

SelectableContextWrapper.propTypes = {
  limit: PropTypes.number,
}

SelectableContext.Wrapper = SelectableContextWrapper

export default SelectableContext

const focusTrapStyle = css`
  outline: none !important;
`
