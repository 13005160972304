import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Route } from 'react-router'
import { withCookies } from 'react-cookie'
import { asyncConnect } from 'redux-connect'
import { GridWrappingColumn } from 'common'
import _isEqual from 'lodash/isEqual'
import { redirectWhen } from '../../../decorators'
import { load } from '../../../redux/modules/clusters'
import Viewer from './Viewer/Viewer'
import DefaultStickyFloater from './QueryBar//DefaultStickyFloater'
import QueryBarFloater from './QueryBar//QueryBarFloater'
import MatchesCardList from './CardView/MatchesCardList'
import {
  getClustersQuery,
  getClusterQueryTags,
  assembleClustersAndTotal,
  getClusterAndMatchIdFromPathname,
} from '../../../redux/selectors'
import { compose } from 'recompose'
const log = require('debug')('matches')

let lastQuery = null
let lastTags = null

export class MatchesContainer extends Component {
  UNSAFE_componentWillMount() {
    const { location, history } = this.props
    const { pathname } = location

    if (pathname.slice(-1) === '/' && pathname.slice(-5) !== 'view/') {
      history.replace({ ...location, pathname: pathname.slice(0, -1) })
    } else {
      this.sanitizePathname(location)
    }
  }

  componentWillUnmount() {
    lastQuery = null
    lastTags = null
  }

  componentWillUpdate(nextProps) {
    const { location, clusters, clusterId } = nextProps
    const position = location.state ? location.state.navigateToClusterInPage : 0
    const cluster = clusters[position]

    const hasLocationToNavigateTo = this.props.location.key !== location.key
    const hasClusterToNavigateInto = cluster && !clusterId

    if (hasLocationToNavigateTo || hasClusterToNavigateInto) {
      this.sanitizePathname(location, cluster)
    }
  }

  sanitizePathname(nextLocation, cluster) {
    const { history } = this.props
    const { pathname } = nextLocation
    if (pathname.length < 9) {
      // no changes when necessary when on 'matches/'
      return
    }

    let newPath = pathname

    if (cluster && pathname.slice(-5) === '/view') {
      newPath = pathname + '/' + cluster._id
    }

    const UNARY_TAG_PHANTOM_VALUE = Symbol()

    const fragmentMap = newPath.split('/').reduce((obj, curr) => {
      const [key, value] = curr.split(':')
      obj[key] = value || UNARY_TAG_PHANTOM_VALUE
      return obj
    }, {})

    const sanitizedNewPath = Object.entries(fragmentMap)
      .map(([key, value]) =>
        value === UNARY_TAG_PHANTOM_VALUE ? key : `${key}:${value}`
      )
      .join('/')

    if (sanitizedNewPath === pathname) {
      return
    }
    if (decodeURI(sanitizedNewPath) === pathname) {
      // if the url shows /image:a%20b/ then react router
      // location will replace %20 with space the sanitized version should not sanitize in this case
      return null
    }

    history.replace({
      ...nextLocation,
      pathname: sanitizedNewPath,
      ...(nextLocation.state &&
        typeof nextLocation.state === 'boolean' && {
          state: {
            ...nextLocation.state,
            clusterPageInvalidated: null,
          },
        }),
    })
  }

  render() {
    const {
      clusterId,
      clusters,
      history,
      loading,
      location,
      matchId,
      newSubtitle,
      pageCount,
      paginationProps,
      query,
      availableTagsForPage,
      availableTagsFromSummary,
      total,
      match,
    } = this.props

    const { pageSize, tags } = query

    // Display the Navigation if
    // [I] No tags have been selected
    // or
    // [II] Only new or only hidden has been selected

    const showDefaultNavigation =
      !(location.search && location.search.includes('search')) &&
      (tags.length === 0 ||
        (tags.length === 1 && tags[0] === 'v2') ||
        (tags.length === 1 && (tags[0] === 'new' || tags[0] === 'hidden:true')))

    const searchSuggestions =
      availableTagsForPage || availableTagsFromSummary || []

    return (
      <GridWrappingColumn maxWidth={1490} padding={60}>
        {/* <ScrollToTop whenChanges={page} offset={345} /> */}
        {clusters.length && clusterId ? (
          <Route
            render={props => (
              <Viewer
                {...props}
                tags={tags}
                total={total}
                pageSize={pageSize}
                matchId={matchId}
                clusters={clusters}
                clusterId={clusterId}
              />
            )}
          />
        ) : (
          <React.Fragment>
            {showDefaultNavigation ? (
              <DefaultStickyFloater tags={tags} />
            ) : (
              <QueryBarFloater availableTags={searchSuggestions} />
            )}
          </React.Fragment>
        )}
        <MatchesCardList
          clusters={clusters}
          history={history}
          loading={loading}
          location={location}
          match={match}
          newSubtitle={newSubtitle}
          pageCount={pageCount}
          paginationProps={paginationProps}
          query={query}
          total={total}
          locationMatch={match}
          availableTags={availableTagsForPage}
        />
      </GridWrappingColumn>
    )
  }
}

function mapStateToProps(state, props) {
  const {
    clusters: { loading, tags: availableTagsFromSummary },
    auth: { user },
  } = state

  // During first render location state is null and params need to be deserialized from query
  const {
    total,
    clusters,
    tags: availableTagsForPage,
    query,
  } = assembleClustersAndTotal(state, props)
  const [clusterId, matchId] = getClusterAndMatchIdFromPathname(state, props)
  const pageSize = query.pageSize
  const pageCount = Math.ceil(total / pageSize)
  const paginationProps = {
    total: total || 0,
    forcePage: query.page,
    pageCount,
    perPage: pageSize,
  }

  var newMatchesReferenceDate =
    user.schedule.report.cycle > 0
      ? user.schedule.report.previous || user.schedule.report.last
      : user.last_seen || user.created
  const newSubtitle =
    (query.tags.includes('new') &&
      user &&
      `since your previous report on ${moment(newMatchesReferenceDate).format(
        'MMMM Do'
      )}`) ||
    null

  return {
    clusterId,
    clusters,
    loading,
    matchId,
    newSubtitle,
    pageCount,
    paginationProps,
    query,
    availableTagsForPage,
    availableTagsFromSummary,
    total,
    user,
  }
}

export default compose(
  withCookies,
  asyncConnect(
    [
      {
        promise({ store: { getState, dispatch }, match, location }) {
          const { auth } = getState()
          if (auth.user && auth.user.features.matches3Enabled != null) {
            return Promise.resolve()
          }
          log('match %o: ', match)
          log('location %o: ', location)
          const query = getClustersQuery(getState(), { location, match })
          const tags = getClusterQueryTags(getState(), { location, match })
          log('last query %o', lastQuery)
          log('query %o', query)

          log('last tags %o', lastTags)
          log('tags %o', tags)

          if (__CLIENT__) {
            // const isNotFirstLoad = Object.values(getState().entities.clusters)
            //   .length

            // compare last query by reference (reselect cache entries)
            const clusterPageInvalidated =
              location.state && location.state.clusterPageInvalidated

            const mustUpdate =
              clusterPageInvalidated === true ||
              !_isEqual(query, lastQuery) ||
              !_isEqual(tags, lastTags)

            if (!mustUpdate) {
              log('fast return')
              return
            }
            lastQuery = query
            lastTags = tags
          }

          // Shortcut UI actions
          log('dispatch!')
          const promise = dispatch(
            load({
              ...query,
              tags,
            })
          )
          return __SERVER__ && promise
        },
      },
    ],
    mapStateToProps
  ),
  redirectWhen({
    onEnter: () => {
      try {
        window.mixpanel.track('Matches.Display.Top', {
          source: 'top-match-report',
        })
      } catch (e) {
        console.error(e)
      }
    },
    shouldRedirect: ({ location }) => location.hash === '#tmr',
    to: ({ location }) => ({
      ...location,
      hash: '',
    }),
  }),
  redirectWhen({
    shouldRedirect: ({ loading, query, total, summary, location }) =>
      !loading &&
      (total === 0 || total === null) &&
      query.tags.length === 0 &&
      !(
        location.pathname === '/matches' || location.pathname === '/matches/'
      ) &&
      !location.pathname.endsWith('/view'),
    to: ({ location }) => ({
      ...location,
      pathname: '/matches/overview',
    }),
  })
)(MatchesContainer)

MatchesContainer.propTypes = {
  clusters: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  newSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  clusterId: PropTypes.string,
  matchId: PropTypes.string,
  pageCount: PropTypes.number,
  total: PropTypes.number,

  paginationProps: PropTypes.shape({
    forcePage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  query: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    sort: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
  }).isRequired,
}
