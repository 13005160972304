/**
 * shallowUpdate - Only the image URL needs to be updated, other data is not required.
 * inferFromOrigin - Should the parser look for the new image URL at the origin URL
 * alwaysOverwriteOriginUrl - Should the parser overwrite the origin URL in any case
 * onFallbackOverwriteOrigin - If there is a fallback attempt should the old origin URL be overwrite by the new detected URL
 * fallbackUrl - Receives one parameter (either originUrl or url based on inferFromOrigin) and return the fallback URL at which the parser will look for the valid image URL
 * allowedOrigins - Infer only when origin is in the allowed list. When `*`, all origins are allowed.
 * domains - Used to more efficiently query the database while looking for matching origin URLs during the migration.
 */
const registry = {
  'lookaside.instagram.com': {
    shallowUpdate: false,
    inferFromOrigin: false,
    alwaysOverwriteOriginUrl: true,
    onFallbackOverwriteOrigin: false,
    allowedOrigins: '*',
    domains: ['instagram.com'],
    parseUrl: (url) => {
      if (url.includes('?media_id=')) return url
      const parsedUrl = new URL(url)
      const params = Object.fromEntries(parsedUrl.searchParams.entries())
      return `https://instagram.com/${params.media_id}`
    },
  },
  'lookaside.fbsbx.com': {
    shallowUpdate: false,
    inferFromOrigin: false,
    alwaysOverwriteOriginUrl: true,
    onFallbackOverwriteOrigin: false,
    allowedOrigins: '*',
    domains: ['facebook.com'],
    scriptJsonParser: true,
    parseUrl: (url) => {
      return url
      if (!url.includes('?media_id=')) return url
      const parsedUrl = new URL(url)
      const params = Object.fromEntries(parsedUrl.searchParams.entries())
      return `https://facebook.com/${params.media_id}`
    },
  },
  'facebook.com': {
    shallowUpdate: false,
    inferFromOrigin: false,
    alwaysOverwriteOriginUrl: false,
    onFallbackOverwriteOrigin: true,
    fallbackUrl: (url) => {
      const parsedUrl = new URL(url)
      return `https://www.facebook.com/photo/?fbid=${parsedUrl.pathname
        .split('/')
        .pop()}`
    },
    allowedOrigins: '*',
    domains: ['facebook.com'],
  },
  'fbcdn.net': {
    shallowUpdate: true,
    inferFromOrigin: true,
    alwaysOverwriteOriginUrl: false,
    onFallbackOverwriteOrigin: false,
    allowedOrigins: ['facebook.com'],
    domains: ['facebook.com'],
  },
  'cdninstagram.com': {
    shallowUpdate: true,
    inferFromOrigin: true,
    alwaysOverwriteOriginUrl: false,
    onFallbackOverwriteOrigin: false,
    allowedOrigins: ['instagram.com'],
    domains: ['instagram.com'],
  },
}

module.exports = {
  detect(url) {
    // Check if the url hostname uses an expiring CDN
    try {
      const parsedUrl = new URL(url)
      return registry[Object.keys(registry).find((expiringCdn) => parsedUrl.hostname.endsWith(expiringCdn))]
    } catch (err) { }
  },
  registry,
}
