import { gql } from '@apollo/client'
import ClusterFragment from '../queries/cluster.fragment'

export default gql`
  mutation toggleFlag($clusterId: String!) {
    toggleFlag(clusterId: $clusterId) {
      ...cluster
    }
  }
  ${ClusterFragment}
`
