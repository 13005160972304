import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

const heading = css`
  text-align: center;
  margin: 70px 0 30px;
  h2 {
    color: #3b3b3b;
    font-size: 1.8em;
    margin-bottom: 8px;
  }
  span {
    color: #434343;
  }

  @media(max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

// const progressBarWrap = css`
//   margin: 5px 20px 5px;
//   height: 2px;
//   background-color: #e8e8e8;
// `

// const ProgressBar = styled.div`
//   height: 100%;
//   width: ${props => props.completeness * 100}%;
//   background-color: #008aab;
// `

export default function BioHeader({ user, completeness }) {
  completeness = 0.3
  return (
    <div>
      <div css={heading}>
        <h2>Your Biography</h2>
        <span>
          A full and complete profile and bio increases the value of your cases
          and improves the likelihood of success.
        </span>
      </div>
      {/* <div className={`${progressBarWrap}`}>
        <ProgressBar completeness={completeness} />
      </div>
      <StrengthIndicator completeness={completeness} /> */}
    </div>
  )
}

BioHeader.propTypes = {
  user: PropTypes.object.isRequired,
}

// const strengIndicatorWrap = css`
//   width: 170px;
//   text-align: center;
//   border: 1px solid #939393;
//   margin: 15px auto 30px;
//   padding: 5px 15px;
//   color: #3b3b3b80;
//   span {
//     padding: 0px 10px 0px 20px;
//     color: #3b3b3b;
//     text-transform: uppercase;
//     letter-spacing: 2px;
//   }
// `
// function StrengthIndicator(completeness) {
//   let strength = 'low'
//   if (completeness > 0.25) strength = 'medium'
//   if (completeness > 0.5) strength = 'high'
//   if (completeness > 0.75) strength = 'excellent'
//   return (
//     <div css={strengIndicatorWrap}>
//       Bio Strength <span>{strength}</span>
//     </div>
//   )
// }
