import React, { Fragment, Component } from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import { css } from 'react-emotion'
import cn from 'classnames'
import { format } from 'currency-formatter'
import { resolveCountryNameForCode } from 'pixsy-constants'
import getCaseStatusFromSubmission from '../../../helpers/getCaseStatusFromSubmission'
import {
  Button,
  ScrollToTop,
  StickyFloater,
  GridWrappingColumn,
  ContentSection,
  CaseHighlights,
  StatusHeading,
  OffSectionProgressIndicator,
  SexySeparator,
  ActionSection,
} from 'common'
import ActivityList from './ActivityList'
import DetailImageWizard from './DetailImageWizard'
import CaseMissingCluster from './CaseMissingCluster'

export default withRouter(
  class Detail extends Component {
    state = { currentSection: '' }

    handleWaypoint = section => {
      this.setState({ currentSection: section })
    }

    renderPrice({ value, currency }) {
      return !value
        ? '-'
        : format(value, {
            format: '%s %v',
            code: currency,
            thousand: ',',
            decimal: '.',
          }) +
            ' ' +
            currency
    }

    _columnConfig = [{}]

    render() {
      const { case: caze, history, submission__c } = this.props
      const { cluster } = caze
      const { currentSection } = this.state

      const domainIcon = require(`./domainIcon.svg`)
      const imageIcon = require(`./imageIcon.svg`)
      const activityIcon = require(`./activityIcon.svg`)

      if (!cluster || !cluster._id) {
        return <CaseMissingCluster />
      }

      if (
        caze.submission &&
        caze.submission.images &&
        caze.submission.images.length > 0
      ) {
        cluster.images = cluster.images.filter(i =>
          caze.submission.images.map(String).includes(String(i._id))
        )
      }

      return (
        <GridWrappingColumn maxWidth={876} padding={220}>
          <Helmet title={`Case ${caze.reference} `} />

          <ScrollToTop />

          <StickyFloater hasBackButton>
            <Button
              onClick={history.goBack}
              className="sticky-floater--back-button"
            />
            <span className="sticky-floater--left-aligned-description">
              <b>
                Case&nbsp;
                {caze.reference}
              </b>
              &nbsp;Submitted&nbsp;
              {moment.utc(caze.created).fromNow()}
            </span>
          </StickyFloater>
          <StatusHeading
            pre="Status"
            title={
              caze && caze.history && caze.history[0]
                ? caze.history[0].label
                : getCaseStatusFromSubmission(caze) || 'Unavailable'
            }
            {...(caze &&
              caze.history &&
              caze.history[0] && {
                subtitle:
                  'Last change ' + moment.utc(caze.history[0].date).fromNow(),
              })}
          />
          <OffSectionProgressIndicator>
            {/* <TakedownProgressIndicator
            images={images}
            validImageCount={images.length ? validImages : 0}
            step="overview"
            submitting={submitting}
          /> */}
            <a
              href="#overview"
              className={cn({ active: currentSection === 'overview' })}
            >
              <div>
                <figure dangerouslySetInnerHTML={{ __html: domainIcon }} />
                <h3>Overview</h3>
              </div>
            </a>
            <SexySeparator space={20} />

            <a
              href="#activity"
              className={cn({ active: currentSection === 'activity' })}
            >
              <div>
                <figure dangerouslySetInnerHTML={{ __html: activityIcon }} />
                <h3>Activity</h3>
              </div>
            </a>

            <SexySeparator space={20} />

            <a
              href="#images"
              className={cn({ active: currentSection === 'images' })}
            >
              <div>
                <figure dangerouslySetInnerHTML={{ __html: imageIcon }} />
                <h3>{cluster.images.length > 1 ? 'Images' : 'Image'}</h3>
              </div>
            </a>
          </OffSectionProgressIndicator>
          <Waypoint onEnter={this.handleWaypoint.bind(this, 'overview')} />
          <ContentSection id="overview">
            <h1>Overview</h1>
            <SexySeparator space={30} />
            <CaseHighlights
              columns={this._columnConfig}
              images={cluster.images}
              imageLabel="Images Used"
              leftColumnProps={[
                {
                  label: 'Domain',
                  value: cluster.domain.host,
                },
                {
                  label: 'Country',
                  value:
                    caze.salesforce.Country__c ||
                    resolveCountryNameForCode(cluster.domain.country),
                },
                {
                  label: 'Category',
                  value: cluster.domain.alexa && cluster.domain.alexa.category,
                },
                {
                  label: 'Company',
                  value:
                    caze.salesforce.InfringerAccount__r &&
                    caze.salesforce.InfringerAccount__r.Name &&
                    caze.salesforce.InfringerAccount__r.Name.replace(
                      /incomplete/gi,
                      ''
                    ),
                },
                {
                  label: 'Law Firm',
                  value:
                    caze.salesforce.StateNumber__c >= 415 &&
                    caze.salesforce.LawFirm__r &&
                    caze.salesforce.LawFirm__r.Name,
                },
                {
                  label: 'Case Type',
                  value:
                    caze.salesforce.Track__c !== 'None' &&
                    caze.salesforce.Track__c + ' Resolution',
                },
              ]}
              rightColumnProps={[
                {
                  label: 'Royalty',
                  value:
                    caze.salesforce.PaymentReceivedD__c &&
                    Number.isFinite(caze.salesforce.RoyaltyDue__c) &&
                    this.renderPrice({
                      value: caze.salesforce.RoyaltyDue__c,
                      currency: caze.salesforce.CurrencyIsoCode,
                    }),
                },
              ]}
            />
            {[910, 999].includes(caze.salesforce.StateNumber__c) && caze.salesforce.Xero_Photographer_Invoice_ID__c &&
              <div css={invoiceButton}>
                <Button orange>
                  <a target="_blank" href={`${window.XERO_SERVICE_API_BASE}invoice?requestId=${caze.salesforce.Id}&accountId=${caze.salesforce.Photographer__r.Id}`}>Download Financial Breakdown</a>
                </Button>
              </div>
            }

          </ContentSection>
          {caze.history && caze.history.length > 0 && (
            <Fragment>
              <ContentSection id="activity">
                <h1>Activity</h1>
                <span>Follow the progress of your case</span>
                <SexySeparator space={30} />
                <ActivityList history={caze.history} />
                <Waypoint
                  onEnter={this.handleWaypoint.bind(this, 'activity')}
                />
              </ContentSection>
            </Fragment>
          )}

          <ContentSection id="images">
            <h1>{cluster.images.length > 1 ? 'Images' : 'Image'}</h1>
            <DetailImageWizard cluster={cluster} submission={submission__c} />
            <Waypoint onEnter={this.handleWaypoint.bind(this, 'images')} />
          </ContentSection>

          {[800, 920].includes(caze.salesforce.StateNumber__c) &&
            caze.cluster &&
            !caze.cluster.takedown && (
              <div css={freeTakedownSection}>
                <ActionSection last>
                  <Link
                    to={`/takedowns/submit/new?from=${caze.cluster._id}&case=${caze.cluster._id}`}
                  >
                    <Button hoverMain>
                      <i
                        dangerouslySetInnerHTML={{
                          __html: require(`../../Takedowns/takedownIcon.svg`),
                        }}
                      />
                      Submit free takedown
                    </Button>
                  </Link>
                  <p>
                    Pixsy has gifted you a free takedown notice. This is
                    available for any case that is discontinued or not accepted.
                  </p>
                </ActionSection>
              </div>
            )}
        </GridWrappingColumn>
      )
    }
  }
)

const freeTakedownSection = css`
 button {
    margin-top: 20px;
    height: 46px;
    line-height: 46px;
    padding-top: 0;
    padding-bottom: 0;

    svg {
      height: 45px;
      width: 25px;
      vertical-align: -17px;
      margin-right: 10px;
      transform: scale(1.8);

      path {
        fill: #fff;
      }
    }
  } 

`



const invoiceButton = css`
   display: flex;
   flex-direction: row-reverse;
`