import * as React from 'react'
import { css } from 'react-emotion'

const baseStyle = css`
  fill: none;
  stroke: #008aab;
  stroke-width: 2px;
`

export default ({ style = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-891.201 2203.65 28.917 21.469"
  >
    <path
      css={css`
        ${baseStyle};
        ${style};
      `}
      d="M6573.778,3228.232l7.777,7.626,8.247-8.086L6601.3,3216.5"
      transform="translate(-7464.279 -1012.14)"
    />
  </svg>
)
