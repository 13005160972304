import React from 'react'
import { Button } from 'common'
import { Link } from 'react-router-dom'
import { css } from 'react-emotion'
import { requestEmailVerification } from '../../../redux/modules/auth'
import { connect } from 'react-redux'

function BounceEmailWarning({ user, onComplete, requestEmailVerification }) {
  const closeQuestionaire = () => {
    onComplete()
    try {
      window.mixpanel.track('Verification.Bounce.Skip')
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }

  const sendVerificationEmail = () => {
    requestEmailVerification().then(onComplete)
    try {
      window.mixpanel.track('Verification.Bounce.Resend')
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }

  return (
    <div css={content}>
      <h1>Email Address Error</h1>

      <p>
        We tried to send you a confirmation email however we encountered an error. Until we can confirm your email
        address, your account will be paused and no new matches will be delivered.
      </p>
      <p>
        <b>Email Address</b>:&nbsp;{user.email}
      </p>
      {user.verification.bounce.reason && (
        <p>
          <b>Error:</b>&nbsp;
          {user.verification.bounce.reason}
        </p>
      )}

      <div css={buttons}>
        <Link to="/profile/settings#change-email">
          <Button outline onClick={closeQuestionaire}>
            Edit Email Address
          </Button>
        </Link>
        <Button hoverMain onClick={sendVerificationEmail}>
          Try again
        </Button>
      </div>
    </div>
  )
}

BounceEmailWarning.eligible = (user) => 
  user &&
  user.verification &&
  user.verification.bounce &&
  user.verification.bounce.dateBounced &&
  !global.E2E &&
  global.NODE_ENV === 'production'

export default connect(null, { requestEmailVerification })(BounceEmailWarning)

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const buttons = css`
  display: flex;
  justify-content: center;
  flex-direction: row;

  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
