import { StickyFloater } from 'common'
import { format } from 'currency-formatter'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { cx } from 'react-emotion'
import Helmet from 'react-helmet'
import { Redirect, Route, Switch } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import { push } from 'react-router-redux'
import { compose } from 'recompose'
import { asyncConnect } from 'redux-connect'
import { loadRegistrations } from '../../redux/modules/registrations'
import {
  assembleRegistrationsAndTotal,
  getRegistrationsQuery,
} from '../../redux/selectors'
// import NoResults from './Table/NoResults'
import RegistrationsTable from './Table/RegistrationsTable'

const log = require('debug')('registrations:registrations-table')

export class RegistrationsContainer extends Component {
  /*
  props: {
    registrations: Array<Object>,
    loading: boolean,
    onSortSelection: Function,
  }
  */

  componentDidMount() {
    requestAnimationFrame(() => {
      try {
        window.mixpanel.track('CopyrightRegistration.DisplayOverview', {
          source: 'navigation',
        })
      } catch (e) {
        console.error(e)
      }
    })
  }

  constructor() {
    super()
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.loading !== nextProps.loading ||
      nextProps.registrations.map(x => x._id).join('-') !==
        this.props.registrations.map(x => x._id).join('-')
    )
  }

  renderPrice({ value, currency }) {
    return !value
      ? '-'
      : format(value, {
          format: '%s %v',
          code: currency,
          thousand: ',',
          decimal: '.',
        }) +
          ' ' +
          currency
  }

  handleCaptionClick(sortKey) {
    log(sortKey)
  }

  render() {
    const {
      loading,
      registrations,
      total,
      location: { pathname },
      query: { filter },
    } = this.props

    const slug = filter[0].toUpperCase() + filter.slice(1)

    const cleanedRegistrations = registrations.map(registration => {
      const uniq = []
      registration.images = registration.images
        .map(image => {
          if (image._id && !uniq.includes(image._id)) {
            uniq.push(image._id)
            return image
          }
          return false
        })
        .filter(Boolean)

      return registration
    })

    return (
      <React.Fragment>
        <Helmet title={'Registrations | ' + slug} />
        <StickyFloater navigation>
          <Link
            to="/registrations/all"
            className={cx(pathname.endsWith('all') && 'active')}
          >
            All
          </Link>
          <NavLink to="/registrations/draft">Draft</NavLink>
          <NavLink to="/registrations/open">Open</NavLink>
          <NavLink to="/registrations/final">Final</NavLink>

          <Link
            to={{
              pathname: '/registrations/submit/new',
              state: { skip: filter === 'all' && total === 0 && 'intro' },
            }}
          >
            <div
              className="sticky-floater--highlight"
              style={{ color: '#fff' }}
            >
              Submit Registrations
            </div>
          </Link>
        </StickyFloater>
        <Switch>
          <Route path="/registrations/:filter">
            <RegistrationsTable
              loading={loading}
              registrations={cleanedRegistrations}
              total={total}
              filter={filter}
            />
          </Route>
          <Redirect from="/registrations" to="/registrations/all" />
        </Switch>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  const {
    registrations: { loading },
  } = state

  const { total, registrations, query } = assembleRegistrationsAndTotal(
    state,
    props
  )

  return { loading, query, total, registrations, key: location.key }
}

export default compose(
  asyncConnect(
    [
      {
        promise: function fetchRegistrationsForRegistrationsContainer({
          store: { getState, dispatch },
          location,
          match,
        }) {
          const query = getRegistrationsQuery(getState(), { location, match })
          const promise = dispatch(loadRegistrations(query))
          return promise
        },
      },
    ],
    mapStateToProps,
    { loadRegistrations, push }
  )
)(RegistrationsContainer)

RegistrationsContainer.propTypes = {
  registrations: PropTypes.array.isRequired,
}
