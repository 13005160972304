import * as React from 'react'
import { css, cx } from 'react-emotion'
import propTypes from 'prop-types'

const iconFlagVisible = css`
  fill: currentColor;
`
const iconFlagTransparent = css`
  fill: transparent;
`
const IconFlag = ({ active, height = 22.48, width }) => (
  <svg viewBox="0 0 17.985 22.482" height={height} width={width}>
    <g
      id="Group_1568_-_flag"
      data-name="Group 1568 - flag"
      transform="translate(-1640.858 -2436.322)"
    >
      <path
        id="Path_746"
        data-name="Path 746"
        className={cx(iconFlagTransparent, active && iconFlagVisible)}
        d="M-12712.81,22063.865v12.047h16.982l-3.34-5.781,3.34-6.266Z"
        transform="translate(14354 -19626)"
      />
      <path
        id="flag"
        css={iconFlagVisible}
        d="M20.306,7.494l3.623-5.8a.375.375,0,0,0-.318-.573H6.749V.375A.375.375,0,1,0,6,.375V22.107a.375.375,0,1,0,.749,0V13.864H23.61a.375.375,0,0,0,.318-.574ZM6.749,13.114V1.873H22.934L19.546,7.3a.375.375,0,0,0,0,.4l3.388,5.421Z"
        transform="translate(1634.858 2436.322)"
      />
    </g>
  </svg>
)
IconFlag.propTypes = {
  active: propTypes.bool,
  height: propTypes.number,
  width: propTypes.number,
}

export default IconFlag
