import React, { createContext, useContext, useMemo, useReducer } from 'react'

import MatchViewerContextReducer from './reducer'
import { getInitialState } from './utils'
import { selectNone } from './selectors'

const store = createContext({})
const { Provider, Consumer } = store

const MatchViewerContextProvider = ({ routerHistory, children }) => {
  const initialState = getInitialState()
  const [state, dispatch] = useReducer(MatchViewerContextReducer, {
    ...initialState,
    routerHistory,
  })

  const contextValue = useMemo(() => ({ state, dispatch }), [initialState])

  return <Provider value={contextValue}>{children}</Provider>
}

const useMatchViewerStore = () => {
  return useContext(store)
}

const withMatchViewerContext = (WrappedComponent, dataSelect = selectNone) => {
  return function ContextConsumerComponent(props) {
    const combineProps = ({ state, dispatch }) => ({
      dispatch,
      ...dataSelect(state),
      ...props,
    })

    return <Consumer>{context => <WrappedComponent {...combineProps(context)} />}</Consumer>
  }
}

export default store
export { MatchViewerContextProvider, withMatchViewerContext, useMatchViewerStore }
