import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { CrossIcon } from './Icons'
import Checkbox from './Checkbox'
import * as Shapes from '../utils/shapes'
import cutLongText from '../utils/cutLongText'

const MultiSelectRichList = ({
  items = [],
  selectedIds,
  onSelectionChange,
  enableTextSearch,
  enableLazySearch,
  searchInitialQuery,
  searchPromptHint,
  decorateId,
  decorateValue,
}) => {
  const [searchText, setSearchText] = useState(searchInitialQuery)
  let filteredItems = items
  if (enableTextSearch) {
    filteredItems = items.filter((item) => item.value.toLowerCase().includes(searchText.toLowerCase()))
  }

  if (enableLazySearch) {
    if (searchText === '') {
      filteredItems = []
    }
  }

  /*
   * When using lazy search, clearing the text field should reset the selection
   * When using lazy search, only one element may be active at the same time
  */
  useEffect( () => {
    if( enableLazySearch && selectedIds.length && searchText === '' ) {
      onSelectionChange([])
    }
  }, [searchText, selectedIds])

  const areItemsFiltered = items.length > filteredItems.length

  const itemsToShow = useMemo(() => {
    const selected = filteredItems
      .filter((i) => selectedIds.includes(i.id) && i.id !== 'pixsy')
      .sort((a, b) => b.count - a.count)

    const pixsy = filteredItems.find((i) => i.id === 'pixsy')

    const otherItems = filteredItems
      .filter((i) => !selectedIds.includes(i.id) && i.id !== 'pixsy')
      .sort((a, b) => b.count - a.count)

    return [
      selectedIds.includes('pixsy') && pixsy,
      ...selected,
      !selectedIds.includes('pixsy') && pixsy,
      ...otherItems,
    ].filter(Boolean)
  }, [searchText, filteredItems])

  return (
    <div css={styles.container}>
      {enableTextSearch && (
        <div css={styles.searchWrapper}>
          <input
            placeholder={searchPromptHint}
            data-lpignore="true" // so that LastPass doesn't propose countries
            type="text"
            css={styles.searchInput}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {areItemsFiltered && (
            <React.Fragment>
              <span css={styles.foundCount}>
                {`${itemsToShow.length} result${itemsToShow.length > 1 ? 's' : ''} found`}
              </span>
              <CrossIcon css={styles.xButton} onClick={() => setSearchText('')} />
            </React.Fragment>
          )}
        </div>
      )}
      {enableLazySearch && (
        <div css={styles.searchWrapper}>
          <input
            placeholder={searchPromptHint}
            data-lpignore="true" // so that LastPass doesn't propose countries
            type="text"
            css={styles.searchInput}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {areItemsFiltered && enableTextSearch && (
            <React.Fragment>
              <span css={styles.foundCount}>
                {`${itemsToShow.length} result${itemsToShow.length > 1 ? 's' : ''} found`}
              </span>
              <CrossIcon css={styles.xButton} onClick={() => setSearchText('')} />
            </React.Fragment>
          )}
        </div>
      )}
      <ul css={[styles.list, itemsToShow.length === 0 && styles.listEmpty]}>
        {itemsToShow.map(({ id, value, iconComponent, count }, index) => (
          <Item
            key={`${value}-${id}`}
            id={decorateId(id, searchText)}
            value={decorateValue(value, searchText)}
            iconComponent={iconComponent}
            count={count}
            index={index}
            selectedIds={selectedIds}
            searchText={searchText}
            setSearchText={setSearchText}
            onSelectionChange={onSelectionChange}
            allowOnlySingleChoice={enableLazySearch}
          />
        ))}
      </ul>
    </div>
  )
}

const Item = ({ id, value, iconComponent, count, selectedIds, searchText, index, onSelectionChange, allowOnlySingleChoice }) => {
  const checkboxID = `cb-${value}-${id}`
  const isSelected = selectedIds.includes(id)
  const onCheck = useCallback(
    (event) => {
      let newIds = [...selectedIds]

      if( allowOnlySingleChoice ) {
        // if lazy search, single element is allowed
        // overwrite previous if new choice is made

        newIds = newIds.includes(id) ? [] : [id]
      } else {
        // if text search, multiple elements are allowed
        // toggle current id

        if (event.target.checked) {
          newIds.push(id)
        } else {
          const foundIndex = newIds.indexOf(id)
          if (foundIndex > -1) {
            newIds.splice(foundIndex, 1)
          }
        }
      }

      onSelectionChange(newIds)
    },
    [selectedIds, searchText]
  )

  return (
    <li css={styles.listItem} key={index}>
      <label css={styles.label} htmlFor={checkboxID}>
        {iconComponent && <div css={styles.iconWrapper}>{iconComponent()}</div>}
        <span css={styles.value}>{cutLongText(value)}</span>
        {count != null && <span css={styles.count}>{parseInt(count).toLocaleString()}</span>}

        <Checkbox additionalClassName={styles.checkbox} id={checkboxID} checked={isSelected} onChange={onCheck} />
      </label>
    </li>
  )
}

MultiSelectRichList.propTypes = {
  items: PropTypes.arrayOf(Shapes.MultiSelectRichListItem),
  selectedIds: PropTypes.array,
  enableLazySearch: PropTypes.bool,
  enableTextSearch: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  decorateId: PropTypes.func,
  decorateValue: PropTypes.func,
  searchPromptHint: PropTypes.string,
  searchInitialQuery: PropTypes.string,
}

MultiSelectRichList.defaultProps = {
  searchInitialQuery: '',
  searchPromptHint: 'Filter by name…',
  decorateValue: (x) => x,
  decorateId: (x) => x,
}

const styles = {
  container: css`
    color: #121212;
  `,
  xButton: css`
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
  `,
  searchWrapper: css`
    width: 100%;
    display: flex;
    height: 31px;
    background-color: #f5f5f5;
    align-items: center;
    padding: 0 25px 0 20px;
    border-bottom: 1px #e8e8e8 solid;
  `,
  searchInput: css`
    border: none;
    background: none;
    outline: none;
    font-size: 12px;
    display: flex;
    flex: 1;
  `,
  foundCount: css`
    font-size: 10px;
    margin-right: 5px;
  `,
  list: css`
    max-height: 146px;
    overflow-y: auto;
    margin: 0;
    padding: 7px 0 0 0;
  `,
  listEmpty: css`
    height: 0;
    padding: 0;
  `,
  listItem: css`
    list-style: none;
    font-size: 12px;
    margin: 0 20px;
  `,
  label: css`
    height: 30px;
    align-items: center;
    display: flex;
    font-weight: normal;
    cursor: pointer;
    font-size: 14px;

    img {
      display: block;
    }
  `,
  value: css`
    flex: 1;
  `,
  iconWrapper: css`
    margin-right: 10px;
  `,
  checkbox: css`
    margin: 0 0 0 11px !important;
  `,
}

export default MultiSelectRichList
