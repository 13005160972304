import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

const anchorStyles = css`
  width: 110px;
  position: sticky;
  top: 150px;
  height: 0;
  float: left;
  transform: translateX(-120px);
  z-index: 1;
  &:after {
    content: '';
    height: 100%;
    width: 200px;
    background: linear-gradient(red, yellow, blue);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
  }
`

const contentStyles = css`
  text-align: center;
  margin: 0 auto;

  a {
    color: #999;
    display: inline-block;
    width: 110px;
    padding: 15px 3px 15px 3px;
    transform: scale(0.9);
    border: solid 1px transparent;
    transition: all 200ms ease-in;
    line-height: 1;
    box-shadow: 0 0 10px rgba(0,0,0,0);
    }
  }
  h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.8em;
    margin-top: 8px;
    margin-bottom: 2px;
    color: #999;
    font-weight: 600;
  }
  p {
    margin-top: 4px;
    font-size: 0.8em;
    font-weight: 400;
  }

  figure {
    height: 34px;
    svg {
      height: 34px;
      path, circle, rect{
        stroke: rgb(130,130,130)
      }
      .settlementIconFill {
        fill: rgb(130,130,130);
        stroke: none;
      }
    }
  }

  button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin-top: 25px
  }

  .active {
    background-color: white;
    border: solid 1px rgb(235,235,235);
    transform: scale(1);
    box-shadow: 0 0 10px rgba(24,24,24,0.1);
    h3 {
      color: #148aa9 !important;
    }
    h3 a {
      color: #148aa9 !important;
    }

    svg{
      path, circle, rect{
        stroke: #148aa9;
        // stroke-width: 2px;
        stroke-width: 1px;
      }

    }
    .settlementIconFill {
      fill: #148aa9 !important;
      stroke: none;
    }
    .svg-takedown-domain-1,
    .svg-takedown-images {
      fill: #148aa9 !important;
    }

    .svg-takedown-domain-3,
    .svg-takedown-domain-5,
    .svg-takedown-submit-cls-1,
    .submission-step-cls-1,
    .submission-step-2-cls-1,
    .submission-step-3-cls-1 {
      stroke: #148aa9 !important;
    }

  }
`
export default class OffSectionProgressIndicator extends Component {
  componentDidMount() {
    const parent = this.refs.parent

    this._links = parent.querySelectorAll('a[href^="#"]')
    for (const l of this._links) {
      l.addEventListener('click', this.handleClick)
    }
  }

  componentWillUnmount() {
    for (const l of this._links) {
      l.removeEventListener('click', this.handleClick)
    }
  }

  handleClick = e => {
    // Find clicked a element
    if (!e.path)
      return console.info(
        'Tried to scroll page, but couldn‘t extract path from event'
      )
    const anchor = e.path.find(p => p.localName === 'a' && p.hash)

    if (anchor) {
      const hash = anchor.hash

      // Identify dom node to scorll to
      const target = document.querySelector(hash)
      if (target) {
        const { top } = target.getBoundingClientRect()

        // Scroll using native smoothing
        window.scrollBy({ top: top - 100, behavior: 'smooth' })
      }
      e.preventDefault()
      e.stopPropagation()
    }
  }

  render() {
    return (
      <div className={anchorStyles} ref="parent">
        <aside className={contentStyles}>{this.props.children}</aside>
      </div>
    )
  }
}

OffSectionProgressIndicator.propTypes = {
  children: PropTypes.node.isRequired,
}
