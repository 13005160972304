import React from 'react'
import { css } from 'react-emotion'

import { SEARCH_TYPE } from './constants'
import * as Shapes from '../../utils/shapes'
import cutLongText from '../../utils/cutLongText'
import { EmptyFlagIcon, ThreeDashesIcon, SquareIcon, FlagIcon, TIcon } from '../Icons'

const SUGGESTION_ITEM_RENDERER_MAP = {
  [SEARCH_TYPE.COUNTRY]: [
    EmptyFlagIcon,
    (value) => (
      <React.Fragment>
        <span css={styles.orangeColored}>{value}</span>&nbsp; in Countries
      </React.Fragment>
    ),
  ],
  [SEARCH_TYPE.CATEGORY]: [
    ThreeDashesIcon,
    (value) => (
      <React.Fragment>
        <span css={styles.orangeColored}>{value}</span>&nbsp; in Categories
      </React.Fragment>
    ),
  ],
  [SEARCH_TYPE.ACTION]: [
    SquareIcon,
    (value) => (
      <React.Fragment>
        Actioned as &nbsp;<span css={styles.orangeColored}>{value}</span>
      </React.Fragment>
    ),
  ],
  [SEARCH_TYPE.FLAG]: [
    FlagIcon,
    (value) => (
      <React.Fragment>
        <span css={styles.orangeColored}>{value}</span>
      </React.Fragment>
    ),
  ],
  [SEARCH_TYPE.DOMAIN]: [
    TIcon,
    (value) => (
      <React.Fragment>
        <span css={styles.orangeColored}>{value}</span>&nbsp; in Domains
      </React.Fragment>
    ),
  ],
  [SEARCH_TYPE.URL]: [
    TIcon,
    (value) => (
      <React.Fragment>
        <span css={styles.orangeColored}>{value}</span>&nbsp; in Url
      </React.Fragment>
    ),
  ],
}

const Suggestion = ({ suggestion }) => {
  const [Icon, valueRenderer] = SUGGESTION_ITEM_RENDERER_MAP[suggestion.type]

  return (
    <div css={styles.container}>
      <Icon css={styles.icon} />
      <div css={styles.value}>
        {valueRenderer(cutLongText(suggestion.value, 18))}
      </div>
      <div className="searchSuggestionItemApplyButton">APPLY</div>
    </div>
  )
}

const styles = {
  value: css`
    display: flex;
    flex: 1;
  `,
  orangeColored: css`
    color: #ffa168;
    font-weight: 400;
  `,
  icon: css`
    display: flex;
    width: 11px;
    height: 11px;
    margin: 0 9px;
  `,
  container: css`
    display: flex;
    font-size: 12px;
    font-weight: 300;
    color: #121212;
    flex-direction: row;
    align-items: center;
    flex: 1;
    cursor: pointer;

    .searchSuggestionItemApplyButton {
      background-color: #ffa168;
      font-size: 8px;
      font-weight: 600;
      padding: 4px 8px;
      border-radius: 2px;
      line-height: 8px;
      color: white;
      margin-right: 10px;
      display: none;
    }
  `,
}

Suggestion.propTypes = {
  suggestion: Shapes.Suggestion,
}

export default Suggestion
