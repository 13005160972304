import React from 'react'
import PropTypes from 'prop-types'
import { asyncConnect } from 'redux-connect'
import styled, { css } from 'react-emotion'
import RailsPayeeFrame from './PaymentRails/RailsPayeeFrame'
import { DATA_ELEVIO } from 'pixsy-constants'
import { getPaymentFrameURL } from '../../../redux/modules/auth'
import { OffSectionTooltip } from 'common'

export function PaymentRailsContainer({ user, paymentFrameURL }) {
  const isMobile = () => {
    return window.innerWidth < 768
  }
  return (
    <div>
      <h4 data-elevio-selector={DATA_ELEVIO.PROFILE_PAYMENTS_REMITTANCE}>
        Remittance Payments
      </h4>

      <ExplanationList>
        <li>
          Get paid to any account or method around the world. Pixsy partners
          with Payment Rails to process all our payments and to manage US tax
          compliance. Payment Rails is a registered and accredited US payment
          provider - you data is stored using bank level encryption.
          <br />
        </li>
        <li>
          To comply with US IRS tax requirements, you must complete a valid W8
          or W9 form. The guided questionnaire will help you choose and complete
          the correct form. We recommend completing this information as soon as
          possible to avoid any payment delays.
        </li>
        <li>
          <ul>
            <li>
              Ensure you include your local tax ID number (for your local
              government/tax office).
            </li>
            <li>
              Ensure your country matches your address (your tax residency
              country).
            </li>
            <li>Ensure you accept all available tax treaty benefits.</li>
          </ul>
        </li>
      </ExplanationList>
      {isMobile() ? (
        <OffSectionTooltip>
          To update your payment setting please switch to the desktop version.
        </OffSectionTooltip>
      ) : (
        <RailsPayeeFrame userId={user._id} url={paymentFrameURL} css={mobile} />
      )}
    </div>
  )
}

export default asyncConnect(
  [
    {
      promise: async function fetchDataForRailsPaymentContainer({ store }) {
        if (!store.getState().auth.paymentFrameURL)
          return store.dispatch(getPaymentFrameURL())
      },
    },
  ],
  state => ({
    user: state.auth.user,
    paymentFrameURL: state.auth.paymentFrameURL,
  })
)(PaymentRailsContainer)

PaymentRailsContainer.propTypes = {
  user: PropTypes.object.isRequired,
  paymentFrameURL: PropTypes.string.isRequired,
}

const ExplanationList = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 20px 0;
  justify-content: space-between;
  li {
    flex-basis: 30%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    li {
      flex-basis: 100%;
      margin: 10px 0;
    }
  }
`

const mobile = css`
  @media (max-width: 768px) {
    display: none;
  }
`
