import React, { useEffect, useState } from 'react'

import V2 from './V2/Matches'
import V3 from './V3/Matches'
import { css } from 'react-emotion'
import { CircularProgress } from '@material-ui/core'
import { withCookies } from 'react-cookie'

const loadingScreenContainer = css`
  position: fixed;
  top: 15%;
  left: calc(10% + 110px);
  @media (max-width: 1280px) {
    left: calc(10% + 95px);
  }
  width: calc(80% - 110px);
  height: 70%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;

  h1 {
    font-size: 24px;
    letter-spacing: 0.25em;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    left: 0;
    width: 100%;
  }
`

const SwitchingVersionsLoadingScreen = ({ title, body }) => {
  const [displayBody, setDisplayBody] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => setDisplayBody(true), 2500)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div css={loadingScreenContainer}>
      <div>
        <h1>{title}</h1>
        {displayBody && <p>{body}</p>}
        <div style={{ color: '#008aab', marginTop: 45 }}>
          <CircularProgress color="inherit" />
        </div>
      </div>
    </div>
  )
}

const MatchesGateway = (props) => {
  const { user, location } = props
  useEffect(() => {
    /**
     * This is required since pageSize cookie will make v2 show 0 matches.
     * @TODO: Find a better way to do this and reuse this cookie between v2 and v3
     */
    props.cookies.remove('pageSize')
  }, [])

  const { matches3Available, matches3Enabled } = (user && user.features) || {}

  if (matches3Available && location.pathname.startsWith('/matches/activate-v2')) {
    try {
      window.mixpanel.track('Matches.mv3.MatchViewer.switch', {
        mv3: false,
      })
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }

    window.location.reload() // Force the router to hit the server, so that server-side redirects work correctly

    return (
      <SwitchingVersionsLoadingScreen
        title="REVERTING NEW MATCHES EXPERIENCE (BETA)"
        body="Please, stand by. Reverting to the stable version might take a bit"
      />
    )
  }

  if (matches3Available && location.pathname.startsWith('/matches/activate-v3')) {
    try {
      window.mixpanel.track('Matches.mv3.MatchViewer.switch', {
        mv3: true,
      })
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }

    window.location.reload() // Force the router to hit the server, so that server-side redirects work correctly

    return (
      <SwitchingVersionsLoadingScreen
        title="LOADING MATCH VIEWER (BETA)"
        body="Please, stand by. Switching to the new matches experience (beta) might take some time"
      />
    )
  }

  const MatchesComponent = matches3Enabled ? V3 : V2
  return <MatchesComponent {...props} />
}

export default withCookies(MatchesGateway)
