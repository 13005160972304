import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'

export default function AlignLabels({ children, align, width }) {
  return (
    <ThemeProvider theme={{ labelAlign: align, labelWidth: width }}>
      <React.Fragment>{children}</React.Fragment>
    </ThemeProvider>
  )
}

AlignLabels.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
}

AlignLabels.defaultProps = {
  align: 'left',
}
