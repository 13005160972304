import { MATCHES_TYPE, SEEN_STATE, SIMILARITY_TYPE, COMMERCIAL_TYPE } from '../constants'
import { useMemo } from 'react'

const createClustersFilterInput = (filters) => ({
  country: filters.selectedCountryIds,
  category: filters.selectedCategoryIds,
  images: filters.selectedImageIds,
  topMatch: filters.selectedMatchesType === MATCHES_TYPE.TOP,
  seen: filters.selectedSeenState === null ? null : filters.selectedSeenState === SEEN_STATE.SEEN,
  commercial:
    filters.selectedCommercialType === null ? null : filters.selectedCommercialType === COMMERCIAL_TYPE.COMMERCIAL,
  fromDate: new Date(filters.selectedYearRange[1], 0, 1),
  toDate: new Date(filters.selectedYearRange[0], 11, 31),
  tags: createTags(filters),
  actions: filters.selectedActionIds,
  urlFragment: filters.selectedKeywordPairs.filter( (pair) => pair.startsWith('url')).map((pair) => pair.split(':').pop()).shift(),
  domainFragment: filters.selectedKeywordPairs.filter( (pair) => pair.startsWith('domain')).map((pair) => pair.split(':').pop()).shift(),
  entity: filters.selectedEntityId,
  matchId: filters.selectedMatchId,
})

const useClusterFilterInput = (props) => {
  const dependencies = [
    props.selectedCountryIds,
    props.selectedCategoryIds,
    props.selectedImageIds,
    props.selectedActionIds,
    props.selectedKeywordPairs,
    props.selectedYearRange,
    props.selectedMatchesType,
    props.selectedSeenState,
    props.selectedSimilarityType,
    props.selectedCommercialType,
    props.isMultiMatch,
    props.isFlagged,
    props.isPixsyFlagged,
    props.selectedEntityId,
    props.selectedMatchId,
  ]

  return useMemo(() => createClustersFilterInput(props), dependencies)
}

const createTags = (filters) => {
  const { selectedSimilarityType, isMultiMatch, selectedActionIds, selectedKeywordPairs } = filters
  const tags = []

  if (selectedSimilarityType) {
    if (selectedSimilarityType === SIMILARITY_TYPE.EXACT) {
      tags.push('similarity:high')
    } else if (selectedSimilarityType === SIMILARITY_TYPE.SIMILAR) {
      tags.push('similarity:medium', 'similarity:low')
    }
  }

  if (isMultiMatch) {
    tags.push('multi:match')
  }

  if (selectedActionIds.includes('FLAGGED')) {
    tags.push('flagged')
  }

  if (selectedActionIds.includes('PIXSY_FLAGGED')) {
    tags.push('pixsy:flagged')
  }

  if (selectedActionIds.includes('OFFLINE')) {
    tags.push('online:false')
  }

  if (selectedActionIds.includes('IGNORED')) {
    tags.push('hidden:true')
  }

  if (selectedActionIds.includes('CASE_SUBMITTED')) {
    tags.push('submitted:case')
  }

  if (selectedActionIds.includes('TAKEDOWN_SUBMITTED')) {
    tags.push('submitted:takedown')
  }

  return tags
}

export default createClustersFilterInput

export { useClusterFilterInput }
