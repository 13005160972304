import React from 'react'
import { css, cx } from 'react-emotion'

export default function NoContent({ icon, header, text, children }) {
  return (
    <div  className={noContent}>
      <div className={noContentInner}>
        {icon && <div className={cx(`icon-${icon}`, iconStyle)} />}

        {header && <h3>{header}</h3>}

        {text && <p>{text}</p>}

        {children}
      </div>
    </div>
  )
}

const noContent = css`
  padding: 40px;
  text-align: center;
  background-color: white;
  border: 2px solid #008aab;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`
const noContentInner = css`
  max-width: 600px;
  margin: 0 auto;
`
const iconStyle = css`
  font-size: 60px;
`
