import * as React from 'react'
import { SUBMISSION_NAMES as NAMES } from 'pixsy-schema/case/SubmissionSchema'

export default {
  [NAMES.matches.origin.url]: {
    title: 'Where the image is published online',
    body:
      'This is the URL (online address) that is displaying your image without permission.',
  },
  [NAMES.matches.url]: {
    title: 'The exact online location of the image file',
    body: `A direct image URL is the exact location of the image on the
      web and usually ends in a common image file extension such as jpg, png, or gif.`,
    elevioId: 69,
  },
  [NAMES.matches.__ADDITIONAL_LINK__]: {
    title:
      'Did you find other infringements on the same website or committed by the same image user?',
    body: `Examples of additional links could include multiple instances of use on this same webpage, or could refer to the image user using your work on multiple company webpages (for example, on their various social media accounts).`,
  },
  [NAMES.images.licensing.cc]: {
    title: 'Previous Creative Commons license',
    body: `Creative Commons licenses are irrevocable - this means that even if you've
      changed your license type (for example, to an All Rights Reserved license), usages
      that comply with your previous license type will continue to be permitted usages.`,
    elevioId: 98,
  },
  CREATIVE_COMMONS_TERMS_TOOLTIP: {
    title: 'What are the Creative Commons license types?',
    body: `There are a variety of different Creative Commons licenses - please provide
      information about the specific license type that applies to this image.`,
    elevioId: 98,
  },
  [NAMES.images.registration.us.status]: {
    title: 'What is the US Copyright Office?',
    body: `While your work is protected by copyright from the moment of creation,
      registering your work with the US Copyright Office can entitle you to statutory
      damages including attorney fees. Read more about registration and the benefits here`,
    footer: (
      <React.Fragment>
        Check our&nbsp;
        <a
          href="https://www.pixsy.com/academy/image-owner/us-copyright-office/"
          target="blank"
        >
          academy article&nbsp;
        </a>
        for more
      </React.Fragment>
    ),
  },
  [NAMES.images.registration.us.id]: {
    title: 'What is the registration number?',
    body: `Your processed US Copyright Registration certificate includes a registration number (standard format for visual works is VA XXX XXXXXXX).`,
  },
  [NAMES.matches.watermark]: {
    title: 'Watermarks',
    body: `Please indicate if a watermark has been removed, replaced or added. Keep in mind that if your
      image is available in some locations online without a watermark (for example, it was
      licensed to a client and is or has been displayed publicly without a watermark), it
      is difficult for us make a claim for watermark removal.`,
  },
  [NAMES.matches.attribution_provided]: {
    title:
      'Was attribution provided according to your Creative Commons license?',
    body: `Very few legal precedents exist that define Creative Commons license terms.
      For this reason we do not currently accept cases where the image user has made a
      good faith effort to comply with your Creative Commons license by providing you
      with attribution`,
  },
  [NAMES.images.title]: {
    title: 'Consistency is key!',
    body: `We use this title in communications with the image user and in legal documentation.
      The image title should be consistent with the title that appears on your public
      portfolio sites. Based on our experience, properly formatted and content relevant
      titles increase the willingness of image users to pay for unlicensed use.`,
  },
  [NAMES.images.date_taken]: {
    title: 'Date & Place of creation',
    body: `Please provide the date (day, month and year), city and country where
      you created the image, e.g.; Berlin, Germany. Sydney, Australia.`,
  },
  [NAMES.images.licensing.first_published.date]: {
    title: 'Your online publication date',
    body: `This date should be the date of when you first uploaded your image to the web.`,
  },
  [NAMES.images.licensing.publicly_available]: {
    title: 'What is online-web use?',
    body: `Online-web use refers to the availability of your image to be licensed for
      online publication.`,
  },
  [NAMES.images.licensing.price.value]: {
    title: 'Why does Pixsy require specific information about public pricing?',
    body: `Understanding the rates at which your image is available for licensing
      online helps us determine the best course of action for your case and to
      increase the likelihood of a successful resolution.`,
  },
  [NAMES.images.licensing.licensed_before]: {
    title: 'Licensing history',
    body: `Licensing history related to the specific image in question is extremely
      valuable to the case.`,
  },
  [NAMES.images.licensing.price_1y_online.value]: {
    title: 'Provide supporting documentation',
    body: `In order to support the value of your image, we require proof of your licensing history. This supporting documentation can be a past license agreement or an invoice of a sale, and should ideally include information regarding the scope and terms of use (the duration of the license and the geographic scope, for example). We'll keep this information on record for you so that you don't have to provide it again in the future.`,
    elevioId: 32,
  },
}
