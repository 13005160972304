// import { PHASE } from '../../shared/forms/submission'

export default function getCaseStatus(c) {
  if (c.status) return c.status
  let label

  if (!c.salesforce && !c.submission && !c.submission__c) {
    return 'Unavailable'
  }
  if (!c.salesforce && c.zendesk) {
    return 'Unavailable'
  }

  if (c.submission) {
    // const phase = c.submission.__PHASE__
    const draft = c.submission.__DRAFT__

    // if (phase === PHASE.REVIEW || (phase === PHASE.FINAL && draft)) {
    //   return 'Pending'
    // }

    if (draft) {
      return 'Draft'
    }
  }

  return label || 'Pending'
}
