import * as React from 'react'

export default function SettingsIcon() {
  return (
    <svg viewBox="14862 -15508 26 26" width="100%" height="100%">
      <g style={{ opacity: 0.6 }} transform="translate(14862 -15508)">
        <path d="M24.661,10.111H22.229a.934.934,0,0,1-.661-1.594L23.288,6.8a1.34,1.34,0,0,0,0-1.894L21.1,2.712a1.371,1.371,0,0,0-1.894,0L17.483,4.431a.934.934,0,0,1-1.594-.66V1.339A1.34,1.34,0,0,0,14.55,0h-3.1a1.34,1.34,0,0,0-1.339,1.339V3.771a.934.934,0,0,1-1.594.66L6.8,2.712a1.371,1.371,0,0,0-1.894,0L2.712,4.9a1.34,1.34,0,0,0,0,1.894l1.72,1.719a.934.934,0,0,1-.661,1.594H1.339A1.34,1.34,0,0,0,0,11.45v3.1a1.34,1.34,0,0,0,1.339,1.339H3.771a.934.934,0,0,1,.661,1.594L2.712,19.2a1.34,1.34,0,0,0,0,1.894L4.9,23.288a1.37,1.37,0,0,0,1.894,0l1.719-1.72a.934.934,0,0,1,1.594.661v2.432A1.34,1.34,0,0,0,11.45,26h3.1a1.34,1.34,0,0,0,1.339-1.339V22.229a.934.934,0,0,1,1.594-.661l1.719,1.72a1.371,1.371,0,0,0,1.894,0L23.288,21.1a1.34,1.34,0,0,0,0-1.894l-1.72-1.719a.934.934,0,0,1,.661-1.594h2.432A1.34,1.34,0,0,0,26,14.55v-3.1A1.34,1.34,0,0,0,24.661,10.111Zm.376,4.439a.376.376,0,0,1-.376.376H22.229a1.9,1.9,0,0,0-1.341,3.238l1.72,1.719a.376.376,0,0,1,0,.532l-2.192,2.192a.376.376,0,0,1-.532,0l-1.719-1.72a1.9,1.9,0,0,0-3.238,1.341v2.432a.376.376,0,0,1-.376.376h-3.1a.376.376,0,0,1-.376-.376V22.229A1.867,1.867,0,0,0,9.9,20.476a1.919,1.919,0,0,0-.738-.15,1.871,1.871,0,0,0-1.329.56l-1.719,1.72a.376.376,0,0,1-.532,0L3.393,20.415a.376.376,0,0,1,0-.532l1.72-1.719a1.9,1.9,0,0,0-1.341-3.238H1.339a.376.376,0,0,1-.376-.376v-3.1a.376.376,0,0,1,.376-.376H3.771A1.9,1.9,0,0,0,5.112,7.836L3.393,6.116a.376.376,0,0,1,0-.532L5.585,3.393a.376.376,0,0,1,.532,0L7.836,5.112a1.9,1.9,0,0,0,3.238-1.341V1.339A.376.376,0,0,1,11.45.963h3.1a.376.376,0,0,1,.376.376V3.771a1.9,1.9,0,0,0,3.238,1.341l1.719-1.719a.376.376,0,0,1,.532,0l2.192,2.192a.376.376,0,0,1,0,.532l-1.72,1.719a1.9,1.9,0,0,0,1.341,3.238h2.432a.375.375,0,0,1,.376.375Z" />
        <path
          d="M22.333,18a4.333,4.333,0,1,0,4.333,4.333A4.338,4.338,0,0,0,22.333,18Zm0,7.7a3.37,3.37,0,1,1,3.37-3.37A3.375,3.375,0,0,1,22.333,25.7Z"
          transform="translate(-9.333 -9.333)"
        />
      </g>
    </svg>
  )
}
