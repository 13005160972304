import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { Button } from 'common'
import COUNTRIES from 'pixsy-constants/countries.json'
import TakeActionMatchSelectionPreview from './TakeActionMatchSelectionPreview'

export default function SelectMatchScopeDialogCase({
  matches,
  cluster,
  location,
}) {
  const triggerGA = () => {
    // funnel: cs v5
    try {
      window.gtag('event', 'setMetric', {
        casesFromMatch: 1,
        event_label: 'casesFromMatch',
      })

      window.gtag('event', 'page_view', {
        page_path: '/c/vpv/fu/cs/v5/mat/start',
        event_callback(res) {
          console.info('pageview success: /c/vpv/fu/cs/v5/mat/start')
          console.info(res)
        },
      })
    } catch (e) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }
  }
  const checkDisabled = () => {
    if(matches.length === 0) return true;
    const country = COUNTRIES.find(c => c.iso === cluster.domain.country)
    if(country && country.minimumCCScore && country.minimumCCScore >= cluster.score) return true

    return false
  }
  const pathname = `/cases/submission/new`

  return (
    <div css={wrap} id="modal-viewer-select-scope" className="viewer-modal">
      <h1>Submit Case</h1>
      <p>
        Are you sure you want to submit a case for the following matches on this
        domain?
      </p>

      <TakeActionMatchSelectionPreview matches={matches} />

      <Link
        to={{
          ...location,
          pathname,
          search: `?from=${cluster._id}`,
        }}
        onClick={() => triggerGA()}
      >
        <Button highlightedFromDark disabled={checkDisabled()}>
          Proceed to Case Submission Form
        </Button>
      </Link>
    </div>
  )
}

const wrap = css`
  padding: 40px 70px 30px;
  text-align: center;

  h4 {
    color: #fff;
  }

  span {
    color: rgba(255, 255, 255, 0.75);
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  button {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
    h4 {
      margin: 0;
      width: 100%;
    }
    ul {
      margin: 20px 0;
    }
    button {
      max-width: 100%;
      white-space: auto;
    }
  }
`

SelectMatchScopeDialogCase.propTypes = {
  matches: PropTypes.array.isRequired,
  cluster: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
