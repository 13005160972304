import React, { useEffect } from 'react'
import { css } from 'react-emotion'

import { ImageLayersIcon } from './Icons'
import useClusterFilters from '../utils/useClusterFilters'

const EmptyResults = () => {
  const { selectedFilters } = useClusterFilters()
  useEffect(() => {
    try {
      window.mixpanel.track('Matches.mv3.NoResults', {
        selectedFilters,
      })
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }, [selectedFilters])

  return (
    <div data-testid="empty-results" css={styles.container}>
      <ImageLayersIcon />
      <h1>0 Matches</h1>
      <p>Your current selection didn‘t return any matches.</p>
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1300px;
    margin-top: 100px;
    align-items: center;

    svg {
      width: 200px;
      height: 200px;

      path {
        stroke: #939393;
      }
    }
  `,
}

export default EmptyResults
