import CheckIcon from '@material-ui/icons/Check'
import isFunction from 'lodash/isFunction'
import React, { useCallback } from 'react'
import { css, cx } from 'react-emotion'

/**
 * @see "./PixsyBreadcrumbs"
 */
export const PixsyBreadcrumbsLink = React.memo(
  ({ active, className, checked, children, href = '#', disabled, onClick }) => {
    const handleOnClick = useCallback(e => {
      if (disabled) return void e.preventDefault()
      if (isFunction(onClick)) return void onClick(e)
    }, [])

    return (
      <a
        onClick={handleOnClick}
        href={href}
        className={cx(
          className,
          pixsyBreadcrumbsLink,
          active ? pixsyBreadcrumbsLinkActive : pixsyBreadcrumbsLinkNotActive,
          {
            [notClickable]: disabled,
            [pixsyBreadcrumbsLinkChecked]: checked,
          }
        )}
      >
        <CheckIcon fontSize="inherit" />
        {children}
      </a>
    )
  }
)

const pixsyBreadcrumbsLink = css`
  text-transform: uppercase;
  letter-spacing: 2px;
  svg {
    transition: width 0.2s ease-out, margin-right 0.2s ease-out;
    color: #ffa168;
    vertical-align: middle;
    margin-right: 0px;
    font-size: 1em;
    width: 0px;
  }
  &:active,
  &:hover,
  &:focus {
    border: none !important;
    text-decoration: none;
  }
`
const pixsyBreadcrumbsLinkChecked = css`
  svg {
    transition: width 0.2s ease-in, margin-right 0.2s ease-in;
    margin-right: 10px;
    width: 1em;
  }
`
const pixsyBreadcrumbsLinkNotActive = css`
  color: #c7c7c7;
  &:active,
  &:hover,
  &:focus {
    color: #a7a7a7;
  }
  &:focus {
    /* outline: 1px dashed #a7a7a7 !important; */
    /* outline-offset: 4px !important; */
    outline: none !important;
  }
`
const pixsyBreadcrumbsLinkActive = css`
  &:focus {
    /* outline: 1px dashed #008aab !important; */
    /* outline-offset: 4px !important; */
    outline: none !important;
  }
`
const notClickable = css`
  cursor: not-allowed;
`
