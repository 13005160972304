import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

// needs to be set also on google analytics
const customDimensionMetricMap = {
  dimension1: 'userPlan',
  dimension2: 'caseValue',
  dimension3: 'generalValue',
  dimension4: 'userType',
  dimension5: 'userCountry',
  dimension6: 'userState',
  dimension7: 'userAgeGroup',
  dimension8: 'hasBio',
  dimension9: 'stockAgencies',
  dimension10: 'copyrightRegistered',
  dimension11: 'lastMatchView',
  dimension12: 'userDeleted',
  dimension13: 'registeredWithFlickrForm',
  dimension14: 'isFlickrProUser',
  dimension15: 'imageCount',
  dimension16: 'matchCount',
  metric1: 'casesStarted',
  metric2: 'casesSubmitted',
  metric3: 'casesFromMatch',
  metric4: 'casesFromImage',
  metric5: 'casesFromManual',
  metric6: 'takedowns',
  metric7: 'caseRevenue',
  metric8: 'subscriptionsRevenue',
  metric9: 'loginCount',
  metric10: 'duplicateCaseWarning',
  metric11: 'successfulCases',
}

export default class GoogleAnalytics extends React.Component {
  componentDidMount() {
    if (!window.gtag) {
      console.info('Start initializing gtag')

      try {
        window.dataLayer = window.dataLayer || []
        window.gtag = function() {
          window.dataLayer.push(arguments)
        }
        window.gtag('js', new Date())

        this.config()
      } catch (e) {
        console.error(`Unable to initialize gtag:`, e)
      }
    } else {
      console.info('gtag is already initialized.')
    }
  }

  config = () => {
    const user = this.props.user

    const options = {
      custom_map: customDimensionMetricMap,
    }
    if (user && user._id) {
      console.info('Initializing gtag with userId: ', user._id)
      options['user_id'] = `${user._id}`
    } else console.info('Initializing gtag without userId')

    window.gtag('config', window.GA_TRACKER, options)
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.config()
    }
  }

  // componentWillReceiveProps({ user }) {
  //   if (this.props.user !== user) {
  //     this.setUserId()
  //   }
  // }

  // handleHistoryChange = location => {
  //   this.setUserId()
  //   this.invoke('set', 'page', `${location.pathname}`)
  //   this.invoke('send', 'pageview')
  // }

  // async invoke(method, ...args) {
  //   await this._loaded
  //   if (!window.ga) {
  //     console.error('Unable to invoke ga method, no ga instance found.')
  //     return
  //   }

  //   window.ga(`${method}`, ...args)
  // }

  render() {
    const trackerId = (global.env || global).GA_TRACKER
    return (
      <Helmet>
        <script
          async
          src={'https://www.googletagmanager.com/gtag/js?id=' + trackerId}
        />
        <script>
          {`
          window.gtag("config", window.GA_TRACKER, {
            linker: {
              domains: 'pixsy.com'
            }
          })
          // https://developers.google.com/gtagjs/reference/api
          window.gtag("config", "UA-90492712-1")
        `}
        </script>

        {/*<script>{`
          window.dataLayer = window.dataLayer || [];
          window.gtag = window.gtag || function gtag(){dataLayer.push(arguments);}
        `}</script>*/}
      </Helmet>

      // <Helmet>

      //   <script>
      //     {`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      //       (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      //       m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      //       })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

      //       ga('create', '${trackerId}', 'auto');

      //       if (${user ? 'true' : 'false'}) {
      //         ga('set', 'userId', ${user ? JSON.stringify(user._id) : null})
      //       }

      //       ga('send', 'pageview');
      //       `}
      //   </script>
      // </Helmet>
    )
  }
}

GoogleAnalytics.propTypes = {
  user: PropTypes.object,
}
