import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Button } from 'common'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import moment from 'moment'

const COOKIE_KEY = 'pixsy-flickr-reminder-seen'

function FlickrImportDialogContent({
  endpoint,
  onComplete,
  user,
  cookies,
  connected = false,
  enableCloseButton,
}) {
  const hideModal = function () {
    cookies.set(COOKIE_KEY, 1, {
      path: '/',
      expires: moment().add(2, 'days').toDate(),
    })
    onComplete()
  }

  return (
    <div css={content}>
      <h3>{connected ? 'Manage' : 'Connect'} your Flickr account</h3>
      {enableCloseButton && (
        <div css={closeButton} onClick={hideModal}>
          x
        </div>
      )}

      <div className="flickr-import-dialog--actions">
        <a href={`/api/auth/${endpoint}`}>
          <Button outline>Import All Images</Button>
        </a>

        <a href={`/api/auth/${endpoint}?public_only=true`}>
          <Button hoverMain>Import only Public images</Button>
        </a>
      </div>
    </div>
  )
}

FlickrImportDialogContent.propTypes = {
  endpoint: PropTypes.string.isRequired,
  user: PropTypes.object,
  connected: PropTypes.bool,
}

export default connect((state) => ({ user: state.auth.user }))(
  withCookies(FlickrImportDialogContent)
)

const inner = css`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const left = css`
  display: flex;
  flex-direction: column;

  a {
    text-align: center;
  }

  ul {
    font-size: inherit;
    text-align: inherit;
    list-style: inherit;
    padding-left: inherit;
    margin: 10px 0 10px 10px;

    li {
      margin-left: 20px;
    }
  }
`

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  position: relative;

  h3 {
    font-size: 30px !important;
  }

  .flickr-import-dialog--actions {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > * {
      margin: 10px 0;
    }

    > a {
      width: 100%;

      &:first-child {
        margin-bottom: 5px;
      }

      > button {
        width: 100%;
      }
    }

    button {
      height: 45px;
    }
  }
`

const listAndIcon = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  img {
    margin-top: 10px;
    height: 100px;
    margin-bottom: 20px;
  }
`

const closeButton = css`
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
  border-width: 0px;
  padding: 5px;
`
