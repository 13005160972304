import * as React from 'react'
import { css, cx } from 'react-emotion'

export const PixsyGrayedLabel = ({
  children,
  text,
  style = {},
  className,
  element = 'span',
}) =>
  React.createElement(
    element,
    { className: cx(labelStyle, className, css(style)) },
    ...React.Children.toArray(children || text)
  )

const labelStyle = css`
  color: #707070;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
`
