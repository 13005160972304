import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { Dialog, SexySeparator } from 'common'
import DeleteImageDialog from './DeleteImageDialog'

class ImageActions extends Component {
  state = {
    modal: null,
  }
  handleModalShow = () => {
    this.setState({
      modal: (
        <DeleteImageDialog
          image={this.props.image}
          onCancel={this.handleModalClose}
        />
      ),
    })
  }

  handleModalClose = () => {
    this.setState({ modal: null })
  }

  render() {
    const { user, image, toggleIgnore, registrations } = this.props
    const { modal } = this.state

    const ENABLE_COPYRIGHT_REGISTRATION =
      !global.PRODUCTION || user.features.copyrightRegistration

    // const iconCase = require('./iconCase.svg')
    const iconDelete = require('./iconDelete.svg')
    const iconIgnore = require('./iconIgnore.svg')
    const iconRegistration = require('./iconRegistration.svg')

    return (
      <React.Fragment>
        <SexySeparator
          label="actions"
          labelStyles={{ backgroundColor: '#FFF', color: '#46464680' }}
          space={25}
        />
        <ActionButtons>
          {/* <ActionButton title="New case" icon={iconCase} width={20} /> */}
          {ENABLE_COPYRIGHT_REGISTRATION && (
            <ActionButton
              title="New Registration"
              icon={iconRegistration}
              width={22}
              linkTo={`/registrations/submit/new?image=${image._id}`}
              onClick={() =>
                requestAnimationFrame(() => {
                  try {
                    window.mixpanel.track('CopyrightRegistration.Navigate', {
                      source: 'image-details',
                    })

                    window.gtag('event', 'clickRegisterInImageDetails', {
                      topMatchesClicked: 1,
                      event_category: 'register',
                      event_label: 'Navigated to Register section',
                    })
                  } catch (e) {
                    console.error(e)
                  }
                })
              }
            />
          )}
          <ActionButton
            title={image.ignored ? 'Unignore' : 'Ignore'}
            icon={iconIgnore}
            width={14}
            top={2}
            onClick={toggleIgnore}
          />
          {registrations && registrations.length === 0 && (
            <ActionButton
              title="Delete"
              icon={iconDelete}
              width={15}
              top={2}
              onClick={this.handleModalShow}
            />
          )}
        </ActionButtons>
        <Dialog isOpen={Boolean(modal)} onRequestClose={this.handleModalClose}>
          {modal}
        </Dialog>
      </React.Fragment>
    )
  }
}

export default connect(state => ({ user: state.auth.user }))(ImageActions)

const ActionButtons = styled.div`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  background-color: inherit;
  border-color: transparent;
  text-align: center;
  justify-content: space-evenly;

  & > :first-child {
    border-left: none;
  }
  > * {
    flex: 1;
    padding: 0;
    display: inline-block;
    box-sizing: content-box;
    > button {
      /* for when link is parent */
      padding-top: 2px;
    }
  }
`

const actionButton = css`
  background: transparent;
  border: 0;
  border-left: 1px solid #e6e6e6;
  transition: all 200ms ease;
  color: #999;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: #3b3b3b;
  }

  div {
    height: 35px;
    display: inline-block;
  }

  figure {
    font-size: 0.8em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`

const link = css`
  color: #999;
  border-left: 1px solid #e6e6e6;
  & > button {
    border-left: none;
  }
`

const ActionButton = ({
  title,
  icon,
  width = 25,
  top = 0,
  onClick,
  linkTo,
}) => {
  const button = (
    <button css={actionButton} onClick={onClick}>
      <div
        dangerouslySetInnerHTML={{ __html: icon }}
        style={{ width: `${width}px`, paddingTop: `${top}px` }}
      />
      <figure
        dangerouslySetInnerHTML={{ __html: title.replace(' ', '&nbsp;') }}
      />
    </button>
  )

  if (linkTo) {
    return (
      <Link to={linkTo} css={link}>
        {button}
      </Link>
    )
  }

  return button
}

ImageActions.propTypes = {
  image: PropTypes.object,
  user: PropTypes.object.isRequired,
}
