import * as React from 'react'
import { css, cx } from 'react-emotion'
import IconButton from '@material-ui/core/IconButton'

export const PixsyIconButton = React.memo(
  ({
    onClick,
    onFocus,
    onBlur,
    disabled,
    size = 16,
    rippleSize = 5,
    color = '#212121',
    colorDisabled = 'rgba(0, 0, 0, 0.26)',
    opacity = 1,
    opacityDisabled = 1,
    children,
    icon: Icon,
    className,
  }) => {
    const ownStyles = css({
      fontSize: typeof size === 'number' ? `${size}px !important` : size,
      padding: `${rippleSize}px !important`,
      opacity,
      color,
      ['&:disabled']: {
        color: `${colorDisabled} !important`,
        opacity: `${opacityDisabled} !important`,
      },
    })
    return (
      <IconButton
        className={cx(ownStyles, className)}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      >
        {children || <Icon fontSize="inherit" color="inherit" />}
      </IconButton>
    )
  }
)
