import React from 'react'
import SmugmugDialog from '../../Images/Import/Import/SmugmugDialog'

const COOKIE_KEY = 'pixsy-smugmug-reminder-seen'

export function SmugmugShortCut(props) {
  return (
    <div>
      <SmugmugDialog {...props} enableCloseButton />
    </div>
  )
}

SmugmugShortCut.eligible = (user, _, cookies) => {
  return (
    !cookies.get(COOKIE_KEY) &&
    user.smugmug &&
    user.smugmug.registeredWithDedicatedForm &&
    !user.smugmug.id
  )
}

export default SmugmugShortCut
