import React from 'react'
import styled, { css } from 'react-emotion'
import { WhiteMagnifyingGlassIcon } from './Icons'
import { Img } from 'react-image'
import { CircularProgress } from '@material-ui/core'
import SelfCenter from './SelfCenter'
import FixedHeightImageContained from './FixedHeightImageContained'
import useClusterFilters from '../utils/useClusterFilters'

const priority = {
  true: 1,
  false: 2,
  null: 3,
}

const MultiMatchTiles = ({ matches, expandView }) => {
  const {
    filters: { selectedActionIds },
  } = useClusterFilters()

  // const matchesSortedByOnline = [...matches].map((match) => ({ ...match, sortPriority: priority[match.isOnline] }))
  // .sort((a, b) => a.sortPriority - b.sortPriority)
  const _matches = [...matches]
  const firstMatchPreview = _matches.shift()
  const moreMatchPreviews = _matches.slice(1, matches.length <= 5 ? matches.length - 1 : 4).filter(Boolean)

  const matchesCount = matches.filter((match) => {
    if (selectedActionIds.includes('IGNORED')) {
      return match
    } else {
      return match.ignored !== true
    }
  }).length

  return (
    <MultiMatchTilesStyled onClick={expandView}>
      <div style={{ position: 'relative' }}>
        <FixedHeightImageContained
          url={firstMatchPreview.url}
          id={firstMatchPreview._id}
          height={168}
          offlineLabel="match"
        />
        <CountStyled>
          <em>{matchesCount}</em> <span>MATCH{matchesCount <= 1 ? '' : 'ES'}</span>
        </CountStyled>
        <ShadowStyled />
      </div>
      {moreMatchPreviews.length === 1 ? (
        <FixedHeightImageContained
          url={moreMatchPreviews[0].url}
          id={moreMatchPreviews[0]._id}
          height={168}
          offlineLabel="match"
        />
      ) : (
        <MoreMatchesStyled matchCount={moreMatchPreviews.length}>
          {moreMatchPreviews.map((match, index) => (
            <div className="tile" key={match._id} style={{ gridArea: `tile-${index}` }}>
              <Img
                src={[
                  match.url,
                  `https://accessible.pixsy.io/image/${match._id}`,
                  `https://static.pixsy.io/match_offline.svg`,
                  'https://static.pixsy.io/404.png',
                ]}
                alt=""
                loader={
                  <SelfCenter>
                    <CircularProgress size={20} />
                  </SelfCenter>
                }
              />
            </div>
          ))}
        </MoreMatchesStyled>
      )}
      <HoverOverlayStyled className="hoverCard">
        <WhiteMagnifyingGlassIcon width={15} height={15} />
        <span>OPEN GROUP</span>
      </HoverOverlayStyled>
    </MultiMatchTilesStyled>
  )
}

export default MultiMatchTiles

const MultiMatchTilesStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 1px;
  width: 450px;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .hoverCard {
      opacity: 1;
    }
  }
`

const MoreMatchesStyled = styled.div`
  display: grid;
  gap: 1px;

  div.tile {
    position: relative;
    aspect-ratio: 4/3;
    overflow: hidden;
    background: #fcfcfc;
  }

  ${(props) =>
    props.matchCount === 2 &&
    css`
      grid-template-areas:
        'tile-0 tile-1'
        'tile-0 tile-1';

      div {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          aspect-ratio: 4/6;
        }
      }
    `};

  ${(props) =>
    props.matchCount === 3 &&
    css`
      grid-template-areas:
        'tile-0 tile-1'
        'tile-0 tile-2';

      div {
        &:nth-of-type(1) {
          aspect-ratio: 4/6;
        }
      }
    `};

  ${(props) =>
    props.matchCount === 4 &&
    css`
      grid-template-areas:
        'tile-0 tile-1'
        'tile-2 tile-3';
    `};
`

const CountStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #ffffff;
  padding: 10px 15px;
  z-index: 3;
  font-weight: 600;
  letter-spacing: 0.15em;

  em {
    font-style: normal;
    font-size: 40px;
    line-height: 30px;
  }

  span {
    font-size: 10px;
  }
`

const ShadowStyled = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(30deg, rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0));
  z-index: 2;
`

const HoverOverlayStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  transition: opacity 0.1s linear;
  opacity: 0;
  font-weight: 600;
  letter-spacing: 0.15em;
  z-index: 10;
`
