const calculateAccuracyFromScore = score => {
  let percentage

  if (score < 0) {
    percentage = 0
  } else if (score > 200) {
    percentage = 100
  } else {
    percentage = Math.round((100 * score) / 200)
  }

  return `${percentage}%`
}
export default calculateAccuracyFromScore
