import React, { Fragment } from 'react'
import styled from 'react-emotion'
import ToggleButton from '../../../../components/Filters/ToggleButton'
import { constants } from '../../../../store'
import useClusterFilters from '../../../../utils/useClusterFilters'

const {
  MATCHES_TYPE: { TOP, ALL },
  SEEN_STATE: { SEEN, UNSEEN },
  SIMILARITY_TYPE: { EXACT, SIMILAR },
  COMMERCIAL_TYPE: { COMMERCIAL, NONCOMMERCIAL },
  LIST_TYPE,
} = constants

const Toggles = () => {
  const {
    state: {
      activeList,
      clusterListFilters: { selectedMatchesType, selectedSeenState, selectedSimilarityType, selectedCommercialType },
    },
    updateFilterState,
  } = useClusterFilters()

  if (activeList !== LIST_TYPE.CLUSTER) return null

  return (
    <TogglesStyled>
      <ToggleButton
        text="Top Matches"
        active={selectedMatchesType === TOP}
        color="#ffa168"
        onClick={() =>
          updateFilterState({
            selectedMatchesType: TOP,
            selectedSimilarityType: null,
          })
        }
      />
      <ToggleButton
        text="All Matches"
        active={selectedMatchesType === ALL}
        color="#ffa168"
        onClick={() =>
          updateFilterState({
            selectedMatchesType: ALL,
          })
        }
      />
      <ToggleButton
        text="Commercial"
        active={selectedCommercialType === COMMERCIAL}
        color="#f5f5f5"
        onClick={() =>
          updateFilterState({
            selectedCommercialType: selectedCommercialType === COMMERCIAL ? null : COMMERCIAL,
          })
        }
      />
      <ToggleButton
        text="Not Commercial"
        active={selectedCommercialType === NONCOMMERCIAL}
        color="#f5f5f5"
        onClick={() =>
          updateFilterState({
            selectedCommercialType: selectedCommercialType === NONCOMMERCIAL ? null : NONCOMMERCIAL,
          })
        }
      />
      <ToggleButton
        text="Unseen"
        active={selectedSeenState === UNSEEN}
        color="#f5f5f5"
        onClick={() =>
          updateFilterState({
            selectedSeenState: selectedSeenState === UNSEEN ? null : UNSEEN,
          })
        }
      />
      <ToggleButton
        text="Seen"
        active={selectedSeenState === SEEN}
        color="#f5f5f5"
        onClick={() =>
          updateFilterState({
            selectedSeenState: selectedSeenState === SEEN ? null : SEEN,
          })
        }
      />
      {selectedMatchesType === ALL && (
        <Fragment>
          <ToggleButton
            text="Exact Matches"
            active={selectedSimilarityType === EXACT}
            color="#f5f5f5"
            onClick={() =>
              updateFilterState({
                selectedSimilarityType: selectedSimilarityType === EXACT ? null : EXACT,
              })
            }
          />
          <ToggleButton
            text="Similar Matches"
            active={selectedSimilarityType === SIMILAR}
            color="#f5f5f5"
            onClick={() =>
              updateFilterState({
                selectedSimilarityType: selectedSimilarityType === SIMILAR ? null : SIMILAR,
              })
            }
          />
        </Fragment>
      )}
    </TogglesStyled>
  )
}

export default Toggles

const TogglesStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 15px;
  border-top: 1px #e8e8e8 solid;
  border-bottom: 1px #e8e8e8 solid;
`
