import * as React from 'react'
import { css, cx } from 'react-emotion'

const baseStyle = css`
  fill: #212121;
`

export default React.memo(({ style = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="20592.74 -1297.285 34.846 40.966"
    className={cx(baseStyle, style)}
  >
    <g
      id="avatar_1_"
      data-name="avatar (1)"
      transform="translate(20556.668 -1297.285)"
    >
      <g id="Group_62" data-name="Group 62" transform="translate(36.073)">
        <path
          id="Path_26"
          data-name="Path 26"
          d="M145.787,22.074h.271a7.828,7.828,0,0,0,5.981-2.587c3.266-3.682,2.723-9.993,2.664-10.6-.212-4.522-2.35-6.685-4.114-7.694A9.432,9.432,0,0,0,146.025,0h-.144a9.447,9.447,0,0,0-4.564,1.162c-1.782,1.01-3.953,3.173-4.165,7.728-.059.6-.6,6.914,2.664,10.6A7.8,7.8,0,0,0,145.787,22.074ZM139.416,9.1c0-.025.008-.051.008-.068.28-6.083,4.6-6.736,6.447-6.736h.1c2.29.051,6.184.984,6.447,6.736a.165.165,0,0,0,.008.068c.009.059.6,5.828-2.1,8.865a5.56,5.56,0,0,1-4.369,1.815h-.085a5.542,5.542,0,0,1-4.36-1.815C138.831,14.948,139.408,9.154,139.416,9.1Z"
          transform="translate(-128.513)"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M70.916,269.794v-.025c0-.068-.008-.136-.008-.212-.051-1.68-.161-5.607-3.843-6.863l-.085-.026a24.48,24.48,0,0,1-7.041-3.207,1.145,1.145,0,1,0-1.315,1.875,26.418,26.418,0,0,0,7.745,3.538c1.977.7,2.2,2.817,2.257,4.751a1.707,1.707,0,0,0,.008.212,15.4,15.4,0,0,1-.178,2.621A31.22,31.22,0,0,1,53.5,275.936a31.4,31.4,0,0,1-14.965-3.487,14.58,14.58,0,0,1-.178-2.621c0-.068.008-.136.008-.212.059-1.934.28-4.047,2.257-4.751a26.667,26.667,0,0,0,7.745-3.538,1.145,1.145,0,1,0-1.315-1.875,24.213,24.213,0,0,1-7.041,3.207c-.034.008-.059.017-.085.025-3.682,1.264-3.792,5.192-3.843,6.863a1.7,1.7,0,0,1-.008.212v.025a13.009,13.009,0,0,0,.433,3.843,1.089,1.089,0,0,0,.441.534c.255.17,6.354,4.055,16.559,4.055s16.3-3.894,16.559-4.055a1.135,1.135,0,0,0,.441-.534A13.649,13.649,0,0,0,70.916,269.794Z"
          transform="translate(-36.073 -237.252)"
        />
      </g>
    </g>
  </svg>
))
