import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Field, reduxForm } from 'redux-form'
import {
  AlignLabels,
  Button,
  MatchReportNotificationSelect,
  OffSectionTooltip,
  PhantomLabelSpacer,
} from 'common'
import { updateUser } from '../../../redux/modules/auth'

export function Form({ valid, submitting, handleSubmit }) {
  return (
    <div>
      <h4>Match Reports</h4>

      <form onSubmit={handleSubmit}>
        <OffSectionTooltip>
          Pixsy will send you email reports to summarize your new matches, and
          highlight interesting statistics.
        </OffSectionTooltip>

        <AlignLabels align="left" width="180px">
          <Field
            name="schedule.report.cycle"
            label="Match Report"
            component={MatchReportNotificationSelect}
          />
        </AlignLabels>

        <PhantomLabelSpacer alignContent="right">
          <div css={mobile}>
            <Button disabled={!valid || submitting} submit>
              Save Setting
            </Button>
          </div>
        </PhantomLabelSpacer>
      </form>
    </div>
  )
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const ConnectedForm = reduxForm({
  form: 'notifications',
  enableReinitialize: true,
  onSubmit: (values, dispatch) => dispatch(updateUser(values)),
})(Form)

export default function NotificationSettings({ user }) {
  return <ConnectedForm initialValues={user} />
}
