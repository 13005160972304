import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Button, Dialog, ActionSection, ContentSection } from 'common'
import { css } from 'react-emotion'

export default class TakedownFormExternalSubmitSection extends React.Component {
  state = {
    showConfirmDialog: false,
  }

  handleModalClose = () => {
    this.setState({ showConfirmDialog: false })
  }

  handleConfirm = () => {
    this.setState({ showConfirmDialog: false })
    this.props.onConfirm()
  }

  handleModalShow = () => {
    this.setState({ showConfirmDialog: true })
  }

  render() {
    const { onWaypoint, disabled, domain } = this.props
    const { showConfirmDialog } = this.state

    return (
      <ContentSection id="submit">
        <Dialog
          isOpen={showConfirmDialog}
          onRequestClose={this.handleModalClose}
        >
          <div css={content}>
            <h1>Did you submit the takedown?</h1>

            <p>
              If you have successfully submitted the takedown using the external
              form for {domain.host}, please indicate that below and Pixsy will
              include and monitor this in your Takedown Overview.
            </p>

            <div css={buttons}>
              <Button outline onClick={this.handleModalClose}>
                Not yet
              </Button>
              <Button hoverMain onClick={this.handleConfirm}>
                Yes, I have
              </Button>
            </div>
          </div>
        </Dialog>

        <Waypoint onEnter={onWaypoint} />

        <ActionSection last>
          <Button
            hoverMain
            disabled={disabled}
            onClick={this.handleModalShow}
            {...(disabled && {
              title: 'Please submit the external form first.',
            })}
          >
            Takedown Issued Externally
          </Button>
          <p>
            Once you have completed above step, submit this form to save the
            external takedown details. <br />
            You can then use this information to manage or follow up on your
            takedown.
          </p>
        </ActionSection>
      </ContentSection>
    )
  }
}

TakedownFormExternalSubmitSection.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onWaypoint: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
