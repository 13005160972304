import React, { Component } from 'react'
import { injectGlobal } from 'react-emotion'
import axios from 'axios'
import { Helmet } from 'react-helmet'

const log = require('debug')('elevio')

export default class Elevio extends Component {
  static ACCOUNT_ID = '5889fe1d76030'
  static BOOT_TIMEOUT = 2500

  constructor() {
    super()

    const isNew = !window._elev
    window._elev = window._elev || {
      account_id: Elevio.ACCOUNT_ID,
      q: [],
      on(z, y) {
        log('.on triggered')
        window._elev.q.push([z, y])
      },
    }

    log('is new: %o', isNew)
    if (isNew) {
      window._elev.on('load', () => {
        window._elev.setSettings({
          side: 'left',
          hideLauncher: true,
        })
        log('in on load, loggin in  ')
        this.handleLogin()
      })
    } else {
      log('not new, logging in ')
      this.handleLogin()
    }
  }

  componentDidUpdate(prevProps) {
    log('cDU')
    const { user } = this.props
    const prevUser = prevProps.user

    if (user && !prevUser) this.handleLogin()
    if (!user && prevUser) this.handleLogout()
  }

  componentWillUnmount() {
    this.handleLogout()
  }

  handleLogin = () => {
    log('handleLogin')
    if (!this.props || !this.props.user) {
      return
    }
    const { user } = this.props

    axios
      .get('/api/users/me/elevio')
      .then(function handleElevioHashResponse({ data }) {
        const _elevUser = {
          first_name: user.details.first_name,
          last_name: user.details.last_name,
          email: user.email,
          user_hash: data,
          groups: user.role,
        }
        window._elev.setUser(_elevUser)
      })
      .catch(err => console.error('Unable to set Elevio user: ', err))
  }

  handleLogout() {
    window._elev &&
      'function' == typeof window._elev.logoutUser &&
      window._elev.logoutUser()
  }

  render() {
    return (
      <Helmet>
        <script
          type="text/javascript"
          async
          defer
          src={`https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=${Elevio.ACCOUNT_ID}`}
        />
      </Helmet>
    )
  }
}

injectGlobal`
  ._elevio_launcher > div > div {
    top: 80% !important;
  }

  #_elev_io ._dwq77 ._1lbby {
    background: #FFA168 !important;
  }
  #_elev_io ._1r6qx ._1lbby svg {
    margin: unset;
  }

  [data-elevio-article] {
    color: #008aab;
    &:hover {
      text-decoration: underline;
    }
  }

  /* Elevio underline clear */
  span.ctxUnderline {
    border-bottom: none !important;
  }
`
