import React from 'react'
import { css } from 'react-emotion'

const dashLine1 = require('./assets/firstCon.svg')
const dashLine2 = require('./assets/secondCon.svg')
const dashLine3 = require('./assets/thirdCon.svg')
const dashLine3Alt = require('./assets/thirdConAlt.svg')
// const dashLine4 = require('./assets/fourthCon.svg')

export default function Path({ onContinue }) {
  return (
    <div css={caseProcessTimelineWrap}>
      {/* <div css={caseProcessTimelineCard}>
        <h4>Submit initial case information to Pixsy for review</h4>
        <p>
          Only provide the base information needed for an initial review.
          Pixsy’s expert Case Managers will assess to see if your case meets the
          criteria for a viable commercial case.
        </p>
        <p>
          <span>Takes 3-5 minutes</span>
        </p>
      </div> */}
      <div css={caseProcessTimelineCard}>
        <h4>Submit your case to Pixsy</h4>
        <p>
          Providing us all the key information upfront, means we have everything
          we need to manage your case smoothly and efficiently.
          <br />
          Pixsy's Case Managers handle everything else on your behalf.
        </p>
        <p>
          <span>Takes 5-10 minutes</span>
        </p>
      </div>
      <div css={caseProcessTimelineCard}>
        <h4>Pixsy’s Case Managers prepare everything for you</h4>
        <p>
          Your case is assigned to our licensing team or a legal partner, and
          your dedicated Case Manager prepares everything to initiate the case
          and contact the image user.
        </p>
      </div>
      <div css={caseProcessTimelineCard}>
        <h4>We pursue the image user for resolution</h4>
        <p>
          Pixsy or our legal partner handles all communications, negotiations,
          and settlements to attempt to bring your case to a successful
          resolution and payment.
        </p>
      </div>
      <div css={caseProcessTimelineCard}>
        <h4>Payments are processed to your bank account</h4>
        <p>
          Once Pixsy is in receipt of the funds, payment is remitted to you
          within 14-21 days anywhere in the world, to the account/method of your
          choice.
        </p>
      </div>
      <div
        css={caseProcessLineOne}
        dangerouslySetInnerHTML={{ __html: dashLine1 }}
      />
      <div
        css={caseProcessLineTwo}
        dangerouslySetInnerHTML={{ __html: dashLine2 }}
      />
      <div
        css={caseProcessLineThree}
        dangerouslySetInnerHTML={{ __html: dashLine3 }}
      />
      <div
        css={caseProcessLineThreeAlt}
        dangerouslySetInnerHTML={{ __html: dashLine3Alt }}
      />
      {/* <div
        css={caseProcessLineFour}
        dangerouslySetInnerHTML={{ __html: dashLine4 }}
      /> */}
    </div>
  )
}

Path.propTypes = {}

const caseProcessTimelineWrap = css`
  display: inline-block;
  width: 100%;
  position: relative;
  padding-top: 30px;
  margin-bottom: 50px;
`

const caseProcessTimelineCard = css`
  width: 280px;
  height: 270px;
  float: left;
  display: block;
  text-align: left;
  border: solid 1px rgb(240, 240, 240);
  background-color: white;
  z-index: 2;
  padding: 25px 25px;
  position: relative;

  /* // @media (max-width: 994px) { */
  @media (max-width: 1150px) {
    width: 100% !important;
    height: auto !important;
    margin: 0 0 35px 0 !important;
  }

  h4 {
    font-weight: 400;
    font-size: 1.2em;
  }
  p {
    color: hsl(0, 0%, 38%);
  }
  span {
    font-style: italic;
    color: black;
  }
  &:before {
    position: absolute;
    content: '1';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #008aab;
    top: -15px;
    left: 25px;
    font-size: 1.75em;
    text-align: center;
    color: white;
    font-weight: 600;
  }
  &:nth-of-type(1) {
    margin-right: calc(100% - 340px);
    margin-left: 40px;
    &:after {
      content: '** Evidence is automatically compiled and captured **';
      position: absolute;
      bottom: -25px;
      left: 5px;
      width: 120%;
      font-size: 0.8em;
      color: rgb(150, 150, 150);
    }

    @media (min-width: 1150px) and (max-width: 1440px) {
      margin-right: calc(100% - 280px);
      margin-left: 0px;
    }
  }
  &:nth-of-type(2) {
    margin-left: calc(100% - 350px);
    margin-top: -100px;
    &:before {
      content: '2';
    }

    @media (min-width: 1150px) and (max-width: 1440px) {
      margin-left: calc(100% - 280px);
      margin-right: 0px;
    }
  }
  &:nth-of-type(3) {
    height: 240px;
    margin-right: calc(100% - 200px);
    margin-top: -50px;
    margin-left: 20px;
    &:before {
      content: '3';
    }

    @media (min-width: 1150px) and (max-width: 1440px) {
      margin-right: calc(100% - 280px);
      margin-left: 0px;
    }
  }
  &:nth-of-type(4) {
    height: 230px;
    margin-left: calc(100% - 300px);
    margin-top: -100px;
    &:before {
      content: '4';
    }

    @media (min-width: 1150px) and (max-width: 1440px) {
      margin-left: calc(100% - 280px);
      margin-right: 0px;
    }
  }
  &:nth-of-type(5) {
    height: 205px;
    margin-right: calc(100% - 300px);
    margin-left: 50px;
    margin-top: 0px;
    &:before {
      content: '5';
    }

    @media (min-width: 1150px) and (max-width: 1440px) {
      margin-right: calc(100% - 280px);
      margin-left: 0px;
    }
  }
`
const caseProcessLineOne = css`
  position: absolute;
  top: 70px;
  left: 320px;
  /* // @media (max-width: 994px) { */
  @media (max-width: 1150px) {
    display: none;
  }

  @media (min-width: 1150px) and (max-width: 1440px) {
    left: 260px;
  }
`
const caseProcessLineTwo = css`
  position: absolute;
  top: 360px;
  left: 150px;
  /* // @media (max-width: 994px) { */
  @media (max-width: 1150px) {
    display: none;
  }

  @media (min-width: 1150px) and (max-width: 1440px) {
    left: auto;
    right: 280px;
  }
`
const caseProcessLineThree = css`
  position: absolute;
  top: 490px;
  left: 300px;
  /* // @media (max-width: 994px) { */
  @media (max-width: 1150px) {
    display: none;
  }

  @media (min-width: 1150px) and (max-width: 1440px) {
    display: none;
    left: 190px;
  }
`
const caseProcessLineThreeAlt = css`
  display: none;
  @media (min-width: 1150px) and (max-width: 1440px) {
    display: block;
    position: absolute;
    top: 490px;
    left: 200px;
    width: 372px;
    height: 147px;
  }
`

// const caseProcessLineFour = css`
//   position: absolute;
//   top: 730px;
//   left: 190px;
//   // @media (max-width: 994px) {
//   @media (max-width: 1150px) {
//     display: none;
//   }
//   svg {
//     width: 100%;
//     height: 100%;
//   }

//   @media (min-width: 1150px) and (max-width: 1440px) {
//     left: auto;
//     right: 280px;
//   }
// `
