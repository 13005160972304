import React from 'react'
import styled from 'react-emotion'

const FeedbackWrapper = styled.div`
  margin-top: 2px;
  font-size: 1.1em;
  font-weight: 400;
  text-align: ${props => props.align};
  color: #434343;
  font-style: italic;
  transition: max-height 0.2s ease-in;
  overflow: hidden;
  margin-top: ${props =>
    props.active ? (props.isRegister ? '0px' : '15px') : '0px'};
  display: ${props => (props.active ? 'inline-block' : 'block')};
  max-height: ${props => (props.active ? '130px' : '0px')};
`
export default function Feedback({
  meta: { dirty, touched, error, warning },
  align,
  isRegister,
}) {
  const errorOrWarning = error || warning
  const reason =
    (typeof errorOrWarning === 'object' &&
      errorOrWarning !== null &&
      errorOrWarning.reason) ||
    errorOrWarning
  return (
    <FeedbackWrapper
      active={(dirty || touched) && errorOrWarning}
      align={align}
      isRegister={isRegister}
    >
      {errorOrWarning ? <span data-error>{reason}</span> : null}
    </FeedbackWrapper>
  )
}

Feedback.defaultProps = {
  align: 'left',
}
