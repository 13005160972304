import {
  objectWithKeysAsValues,
  prefixConstEnumKeys,
  prefixConstEnumValues,
  QAny,
  QArray,
  QBoolean,
  QDefineConstEnums,
  QEnumString,
  QSchema,
  QString,
} from '../core'
import {
  ImageSchema,
  IMAGE_LABEL,
  IMAGE_NAMES,
  IMAGE_REQUIRED,
} from './ImageSchema'
import {
  KNOCKOUT_INFO,
  KNOCKOUT_LABEL,
  KNOCKOUT_NAMES,
  KNOCKOUT_REQUIRED,
  KNOCKOUT_TYPES,
} from './KnockoutSchema'
import {
  MatchSchema,
  MATCH_LABEL,
  MATCH_NAMES,
  MATCH_REQUIRED,
} from './MatchSchema'
import {
  SETTLEMENT_LABEL,
  SETTLEMENT_NAMES,
  SETTLEMENT_REQUIRED,
  SETTLEMENT_TYPES,
} from './SettlementSchema'
export { GENERAL_ATTACH_INDEX } from './SettlementSchema'

export const SUBMISSION_VERSION = '6.0'
export const SUBMISSION_SOURCES = QDefineConstEnums('MATCH', 'EXTERNAL')

export const IMPORT_TYPES = QDefineConstEnums(
  'SEARCH_IMAGE',
  'UPLOAD_IMAGE',
  'UPLOAD_BY_URL'
)

export const SUBMISSION_KEYS = objectWithKeysAsValues({
  version: 'version',
  fromVersion: 'fromVersion',
  metadata: 'metadata',
  errors: 'errors',
  source: 'source',
  images: 'images',
  matches: 'matches',
  entity_id: 'entity_id',
  requested_price: 'requested_price',
  __DRAFT__: '__DRAFT__',
  __INFRINGEMENT_URL__: '__INFRINGEMENT_URL__',
  __INFRINGEMENT_FILE_URL__: '__INFRINGEMENT_FILE_URL__',
  __SEARCH_QUERY__: '__SEARCH_QUERY__',
  __IMPORT_VALUE__: '__IMPORT_VALUE__',
})

export const IMAGE_INDEX = '_IMAGE_INDEX_'
export const MATCH_INDEX = '_MATCH_INDEX_'

export const SUBMISSION_NAMES = {
  ...SUBMISSION_KEYS,
  ...KNOCKOUT_NAMES,
  ...SETTLEMENT_NAMES,
  [SUBMISSION_KEYS.images]: prefixConstEnumValues(
    `${SUBMISSION_KEYS.images}[${IMAGE_INDEX}]`,
    IMAGE_NAMES
  ),
  [SUBMISSION_KEYS.matches]: prefixConstEnumValues(
    `${SUBMISSION_KEYS.matches}[${MATCH_INDEX}]`,
    MATCH_NAMES
  ),
}

export const SUBMISSION_LABEL = {
  [SUBMISSION_NAMES.__INFRINGEMENT_URL__]: `Infringement URL`,
  [SUBMISSION_NAMES.__INFRINGEMENT_FILE_URL__]: `Infringement file URL`,
  ...SETTLEMENT_LABEL,
  ...KNOCKOUT_LABEL,
  ...prefixConstEnumKeys(
    `${SUBMISSION_KEYS.images}[${IMAGE_INDEX}]`,
    IMAGE_LABEL
  ),
  ...prefixConstEnumKeys(
    `${SUBMISSION_KEYS.matches}[${MATCH_INDEX}]`,
    MATCH_LABEL
  ),
}

export const SUBMISSION_REQUIRED = {
  ...KNOCKOUT_REQUIRED,
  ...SETTLEMENT_REQUIRED,
  ...prefixConstEnumKeys(
    `${SUBMISSION_KEYS.images}[${IMAGE_INDEX}]`,
    IMAGE_REQUIRED
  ),
  ...prefixConstEnumKeys(
    `${SUBMISSION_KEYS.matches}[${MATCH_INDEX}]`,
    MATCH_REQUIRED
  ),
}

export const SUBMISSION_INFO = {
  // @TODO remove | simplify
  ...KNOCKOUT_INFO,
}

// prettier-ignore
export const SubmissionSchema = QSchema
  .shape(SETTLEMENT_TYPES)
  .shape(KNOCKOUT_TYPES)
  .shape({
    // @TODO - v6.1, only for ONLINE use
    // [SUBMISSION_NAMES.__INFRINGEMENT_URL__]: QString
    //   .url()
    //   .strip(true),
    // [SUBMISSION_NAMES.__INFRINGEMENT_FILE_URL__]: QString
    //   .url()
    //   .strip(true),
    [SUBMISSION_NAMES.__IMPORT_VALUE__]: QEnumString(IMPORT_TYPES)
      .default(IMPORT_TYPES.SEARCH_IMAGE)
      .strip(true),
    [SUBMISSION_NAMES.__SEARCH_QUERY__]: QString
      .default('')
      .strip(true),
    [SUBMISSION_NAMES.errors]: QAny,
    [SUBMISSION_NAMES.metadata]: QAny,
    [SUBMISSION_NAMES.fromVersion]: QString,
    [SUBMISSION_NAMES.version]: QString
      .default(SUBMISSION_VERSION)
      .transform(() => SUBMISSION_VERSION)
      .required(),
    [SUBMISSION_NAMES.source]: QEnumString(SUBMISSION_SOURCES)
      .required(),
    [SUBMISSION_NAMES.entity_id]: QString.default(''),
    [SUBMISSION_KEYS.requested_price]: QAny,
    [SUBMISSION_KEYS.__DRAFT__]: QBoolean
      .required()
      .default(true),
    [SUBMISSION_KEYS.images]: QArray
      .of(ImageSchema)
      .compact((i) => !i || !i.url)
      .required(),
    [SUBMISSION_KEYS.matches]: QArray
      .of(MatchSchema)
      .compact()
      .required()
  })
