import COUNTRY_LIST from './countries.json'
import CATEGORY_LIST from './categories.json'
import _reduce from 'lodash/reduce'
import _transform from 'lodash/transform'

export const CATEGORIES = CATEGORY_LIST

export const EXPIRING_CDNS = require('./expiring-cdns')

export const STATE_LIST = require('./state.json')

// temporary patch until spider updates to node 12
Object.fromEntries =
  Object.fromEntries ||
  function fromEntries(iterable) {
    var entries = 'entries' in iterable ? iterable.entries() : iterable
    var object = {}
    var entry
    while ((entry = entries.next()) && !entry.done) {
      var pair = entry.value
      Object.defineProperty(object, pair[0], {
        configurable: true,
        enumerable: true,
        writable: true,
        value: pair[1],
      })
    }
    return object
  }

export const CATEGORY_LABELS = Object.fromEntries(Object.entries(CATEGORY_LIST).map((a) => a.reverse()))

export const PIXSY_RECOMMENDED_CATEGORIES = [
  'arts-and-entertainment',
  'automotive',
  'business-and-commerce',
  'construction-and-building',
  'e-commerce-and-shopping',
  'education',
  'environment',
  'film-and-tv',
  'finance-and-banking',
  'government-and-law',
  'health-and-medicine',
  'home-and-garden',
  'hospitality',
  'industrial',
  'jobs-and-career',
  'marketing-and-advertising',
  'news-and-media-commercial',
  'publishing-and-printing',
  'real-estate',
  'science',
  'sports-and-hobbies',
  'technology-and-computing',
  'telecommunications',
  'transportation-and-logistics',
  'travel-and-tourism',
]

export const AGENT_HOSTS = []

export const FLICKR = 'flickr'
export const FLICKR_SIGNUP = 'flickr-signup'
export const SHOPIFY = 'shopify'
export const SMUGMUG = 'smugmug'
export const TUMBLR = 'tumblr'
export const INSTAGRAM = 'instagram'
export const EYEEM = 'eyeem'
export const PHOTOSHELTER = 'photoshelter'
export const PHOTODECK = 'photodeck'
export const ZENFOLIO = 'zenfolio'
export const GOOGLE = 'google'
export const DROPBOX = 'dropbox'
export const UPLOAD = 'upload'
export const EXTERNAL = 'external'
export const S3 = 's3-bucket'

// Legacy import services. Not adding more images, but can still display these in web app.
export const WEBSITE = 'website'
export const BINDED = 'binded'

export const FLICKR_PRO_TAKEDOWNS_ALLOTMENT = 10
export const SMUGMUG_TAKEDOWNS_ALLOTMENT = 10
export const PARTNERSHIP_PLAN_FAMILIES = ['flickr', 'smugmug']

export const SERVICES = [
  FLICKR,
  FLICKR_SIGNUP,
  SHOPIFY,
  SMUGMUG,
  TUMBLR,
  INSTAGRAM,
  EYEEM,
  PHOTOSHELTER,
  PHOTODECK,
  ZENFOLIO,
]

export const SOURCES = [...SERVICES, UPLOAD, DROPBOX, GOOGLE, WEBSITE, BINDED]

export const SERVICE_HOSTS = [
  'flickr.com',
  'staticflickr.com',
  'assetcdn.500px.org',
  'smugmug.com',
  'media.tumblr.com',
  'fbcdn.com',
]

export const CURRENCIES = ['USD', 'EUR', 'GBP', 'AUD', 'CAD', 'JPY', 'TRY']

export const STOCK_PHOTO_HOSTS = [
  'gettyimages.com',
  'fotolia.com',
  'alamy.com',
  'bigstock.com',
  'dreamstime.com',
  'istockphoto.com',
  'masterfile.com',
  'shutterstock.com',
  'thinkstock.com',
  'veer.com',
  'natgeocreative.com',
]

export const AGENCIES = [
  {
    title: '500px Prime',
    logo: '500pxprime.png',
  },
  {
    title: 'Alamy',
    logo: 'alamy.jpeg',
  },
  {
    title: 'Bigstock',
    logo: 'bigstock.jpg',
  },
  {
    title: 'corbis',
    logo: 'corbis.png',
  },
  {
    title: 'Dreamstime',
    logo: 'dreamstime.png',
  },
  {
    title: 'Fotolia',
    logo: 'fotolia.jpg',
  },
  {
    title: 'iStock',
    logo: 'istock.png',
  },
  {
    title: 'Masterfile',
    logo: 'masterfile.jpg',
  },
  {
    title: 'NatGeo',
    logo: 'natgeo.jpg',
  },
  {
    title: 'Shutterstock',
    logo: 'shutterstock.png',
  },
  {
    title: 'Thinkstock',
    logo: 'thinkstock.png',
  },
  {
    title: 'Veer',
    logo: 'veer.jpg',
  },
  {
    title: 'Getty Images',
    logo: 'gettyimages.jpg',
  },
]

export const AGENCY_TITLES = AGENCIES.map((a) => a.title)

export function domainByService(service) {
  return (
    {
      flickr: 'flickr.com',
      shopify: 'shopify.com',
      photoshelter: 'photoshelter.com',
      photodeck: 'photodeck.com',
      tumblr: 'tumblr.com',
      smugmug: 'smugmug.com',
      instagram: 'instagram.com',
      eyeem: 'eyeem.com',
    }[service] || null
  )
}

export const BUCKETS = {
  IMAGE_UPLOAD: 'pixel-vault',
  IMAGE_UPLOAD_DEV: 'pixel-vault-dev',
  CASE_UPLOAD: 'pixsy-case-uploads',
  CASE_UPLOAD_DEV: 'pixsy-case-uploads-development',
  REGISTRATION_SNAPSHOT: 'registration-snapshots',
  REGISTRATION_SNAPSHOT_DEV: 'registration-snapshots-dev',
}

export const MIME_TYPES = {
  CASE_SUBMISSION: {
    GENERAL_ATTACHMENTS: [
      'text/',
      'application/pdf',
      'application/zip',
      'application/x-zip',
      'application/x-zip-compressed',
      'image/',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ],
    IMAGE_UPLOAD: ['image/'],
  },
}

export const QUEUES = {
  IMAGE_UPLOAD: 'https://sqs.us-east-1.amazonaws.com/532925374247/ada-image-upload-event-queue',
  IMAGE_UPLOAD_DEV: 'https://sqs.us-east-1.amazonaws.com/532925374247/ada-image-upload-event-queue-dev',
  IMAGE_UPLOAD_GOOGLE: 'https://sqs.us-east-1.amazonaws.com/532925374247/ada-google-image-upload-event-queue',
  IMAGE_UPLOAD_GOOGLE_DEV: 'https://sqs.us-east-1.amazonaws.com/532925374247/ada-google-image-upload-event-queue-dev',
  IMAGE_UPLOAD_DROPBOX: 'https://sqs.us-east-1.amazonaws.com/532925374247/ada-dropbox-image-upload-event-queue',
  IMAGE_UPLOAD_DROPBOX_DEV: 'https://sqs.us-east-1.amazonaws.com/532925374247/ada-dropbox-image-upload-event-queue-dev',
}

export function humanize(name) {
  return (
    {
      [EXTERNAL]: 'Submission',
      [FLICKR]: 'Flickr',
      [SHOPIFY]: 'Shopify',
      [SMUGMUG]: 'SmugMug',
      [TUMBLR]: 'Tumblr',
      [INSTAGRAM]: 'Instagram',
      [EYEEM]: 'EyeEm',
      [PHOTOSHELTER]: 'Photoshelter',
      [PHOTODECK]: 'PhotoDeck',
      [ZENFOLIO]: 'Zenfolio',
      [GOOGLE]: 'Google Drive',
      [DROPBOX]: 'Dropbox',
      [UPLOAD]: 'Computer',
      [WEBSITE]: 'Website',
      [BINDED]: 'Binded',
      [S3]: 'S3',
    }[name] || name
  )
}

export const WHITELISTED_DOMAIN_CONTACTS = [
  'abuse@webzilla.com',
  'abuse@godaddy.com',
  'abuse@cloudflare.com',
  'abuse@ripe.net',
  'ec2-abuse@amazon.com',
]

export const CREATIVE_COMMONS_OPTIONS = [
  'CC BY',
  'CC BY-SA',
  'CC BY-ND',
  'CC BY-NC',
  'CC BY-NC-SA',
  'CC BY-NC-ND',
  'CC BY 4.0',
  'CC0',
]

export const ATTACHMENT_TYPES = {
  SCREENSHOT: 'Screenshot',
  INVOICE: 'Invoice',
  REGISTRATION: 'Registration',
  COMMUNICATION: 'Communication',
  INFRINGEMENT: 'Infringement',
  CLAIM: 'Claim',
  SETTLEMENT_AGREEMENT: 'Settlement Agreement',
  LICENSING_AGREEMENT: 'Licensing Agreement',
}

export const WORK_TYPES = {
  PHOTO: 'Photograph',
  LOGO: 'Logo',
  SCULPTURE: 'Sculpture',
  JEWELERY: 'Piece of Jewelry',
  PAINTING: 'Painting',
  DRAWING: 'Drawing',
  ILLUSTRATION: 'Illustration',
  COMIC: 'Comic Strip',
  FABRIC: 'Fabric Design',
  TEXTILE: 'Textile Design',
  WALLPAPER: 'Wallpaper Designs',
  ARTWORK: 'Artwork',
}

export function recalculateRegistrationTotalAmount(registrationTypes) {
  return _reduce(
    registrationTypes,
    (result, value) => {
      result += (REGISTRATION_TYPES_ENUM[value] && REGISTRATION_TYPES_ENUM[value].price) || 0
      return result
    },
    0
  )
}

export const REGISTRATION_TYPES = {
  USCO: {
    form: 'USCO_TYPE',
    label: 'United States Copyright Office',
    country_iso: 'us',
    flag: 'United-States',
    items: [
      {
        id: 'us-single',
        value: 'USCO_SINGLE',
        label: 'Single Image Registration',
        price: 99,
        description:
          'Single published or unpublished image.\n\nImage must not be work for hire.\n\nClaimants must be the author.',
      },
      {
        id: 'us-multi',
        value: 'USCO_MULTI',
        label: 'Multi Image Registration',
        price: 119,
        description: 'Register up to 750 photographs.\n\nIn a single registration. Published or unpublished.',
      },
    ],
  },
  CIPO: {
    form: 'CIPO_TYPE',
    label: 'Canadian Intellectual Property Office',
    country_iso: 'ca',
    flag: 'Canada',
    items: [
      {
        id: 'ca-single',
        value: 'CIPO_SINGLE',
        label: 'Single Image Registration',
        price: 119,
        description: 'Register a single image with CIPO',
      },
    ],
  },
}

export const REGISTRATION_TYPES_ENUM = _transform(
  REGISTRATION_TYPES,
  (result, value, key) => {
    value.items.forEach((item) => {
      result[item.value] = item
    })
  },
  {}
)

export const REGISTRATION_OFFICES = [
  {
    label: 'United States Copyright Office',
    abbr: 'USCO',
    iso: 'us',
    flag: 'United-States',
    available: true,
  },
  {
    label: 'Canadian Intellectual Property Office',
    abbr: 'CIPO',
    iso: 'ca',
    flag: 'Canada',
    available: true,
  },
  {
    label: 'Copyright Protection Center of China',
    abbr: 'CPCC',
    iso: 'cn',
    flag: 'China',
    soon: true,
  },
  {
    label: 'Indian Copyright Office',
    abbr: 'ICO',
    iso: 'in',
    flag: 'India',
    soon: true,
  },
]

export const SUBMISSION_VALIDATION_SCENARIOS = {
  ERROR_SUBMITTED_CASE: 'ERROR_SUBMITTED_CASE',
  ERROR_SUBMITTED_TAKEDOWN: 'ERROR_SUBMITTED_TAKEDOWN',
  WARNING_PREVIOUSLY_SUBMITTED_CASE_ON_DOMAIN: 'WARNING_PREVIOUSLY_SUBMITTED_CASE_ON_DOMAIN',
  ERROR_DOMAIN_COUNTRY_UNRESOLVABLE: 'ERROR_DOMAIN_COUNTRY_UNRESOLVABLE',
  ERROR_DOMAIN_UNRESOLVABLE: 'ERROR_DOMAIN_UNRESOLVABLE',
  ERROR_DOMAIN_CATEGORY_UNRESOLVABLE: 'ERROR_DOMAIN_CATEGORY_UNRESOLVABLE',
  ERROR_DOMAIN_STOCK: 'ERROR_DOMAIN_STOCK',
  ERROR_MATCH_HOTLINKED_IMAGE: 'ERROR_MATCH_HOTLINKED_IMAGE',
  WARNING_MATCH_HOTLINKED_ORIGIN: 'WARNING_MATCH_HOTLINKED_ORIGIN',
  ERROR_MATCH_ORIGIN_APPROVED: 'ERROR_MATCH_ORIGIN_APPROVED',
  ERROR_IMAGE_SOURCE_UNAVAILABLE: 'ERROR_IMAGE_SOURCE_UNAVAILABLE',
  ERROR_IMAGE_HOSTED_ON_STOCK: 'ERROR_IMAGE_HOSTED_ON_STOCK',
  ERROR_IMAGE_UNAVAILABLE: 'ERROR_IMAGE_UNAVAILABLE',
  ERROR_MATCH_ORIGIN_UNAVAILABLE: 'ERROR_MATCH_ORIGIN_UNAVAILABLE',
  ERROR_MATCH_UNAVAILABLE: 'ERROR_MATCH_UNAVAILABLE',
  ERROR_DOMAIN_COUNTRY_TM_ONLY: 'ERROR_DOMAIN_COUNTRY_TM_ONLY',
  ERROR_IMAGE_CC0: 'ERROR_IMAGE_CC0',
  ERROR_IMAGE_CC_LEGAL_ONLY: 'ERROR_IMAGE_CC_LEGAL_ONLY',
  ERROR_CLUSTER_MINIMUM_SCORE: 'ERROR_CLUSTER_MINIMUM_SCORE',
}

export const regularExpressions = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

// https://github.com/erikras/redux-form/issues/1249
export const REDUX_FORM_OMITTED_PROPS = [
  'initialValue',
  'autofilled',
  'onUpdate',
  'valid',
  'invalid',
  'dirty',
  'pristine',
  'active',
  'touched',
  'visited',
  'error',
  'defaultChecked',
  'defaultValue',
]

const JURISDICTIONS = [
  { code: 101, title: 'United States of America' },
  { code: 201, title: 'Australia' },
  { code: 301, title: 'Canada' },
  { code: 401, title: 'China' },
  { code: 501, title: 'Europe (English)' },
  { code: 601, title: 'Europe (French)' },
  { code: 701, title: 'Europe (German)' },
  { code: 801, title: 'Europe (Italian)' },
  { code: 901, title: 'Europe (Spanish)' },
  { code: 950, title: 'Europe (Portugal)' },
  { code: 1001, title: 'India' },
  { code: 1101, title: 'International (English)' },
  { code: 1201, title: 'International (French)' },
  { code: 1301, title: 'International (Portuguese)' },
  { code: 1401, title: 'International (Spanish)' },
  { code: 1501, title: 'Japan' },
  { code: 1601, title: 'New Zealand' },
  { code: 1701, title: 'Russia' },
  { code: 1801, title: 'South Africa' },
  { code: 1901, title: 'United Kingdom' },
]

export const TAKEDOWN_JURISDICTIONS = Object.values(
  COUNTRY_LIST.reduce((obj, country) => {
    const node = obj[country.takedownTemplate] || {
      countries: [],
      code: country.takedownTemplate,
      title: JURISDICTIONS.find((j) => j.code === country.takedownTemplate).title,
    }

    node.countries.push(country.iso)

    return {
      ...obj,
      [country.takedownTemplate]: node,
    }
  }, {})
)
TAKEDOWN_JURISDICTIONS.find((j) => j.code === 101).countries.push('us')

export const COLORS = {
  BRAND_PRIMARY: '#008aab',
  BRAND_SECONDARY: '#00758d',

  ACTION: '#ff7f2f',

  SUCCESS: '#147a22',
  SUCCESS_LIGHT: '#238e4e',
  WARNING: '#dda300',
  WARNING_LIGHT: '#e8bb00',
  DANGER: '#f42534',
  DANGER_LIGHT: '#ff5959',

  INFO: '#3d81c5',
  INFO_LIGHT: '#8cb4dc',

  GREY_LIGHT: '#f5f5f5',
  MUTED: '#d9d9d9',
  GREY_MEDIUM: '#999999',
}

export const COUNTRIES = COUNTRY_LIST.map((c) => c.country)
export const SUPPORTED_COUNTRIES = COUNTRY_LIST.filter((c) => c.resolution).map((c) => ({
  code: c.iso,
  country: c.displayName.replace(' ', '-'),
  label: c.displayName,
}))
export const resolveCountryNameForCode = (code) => {
  if (code === 'undefined') return 'Undefined'
  const isoCode = code && code.toLowerCase()
  const match = COUNTRY_LIST.find((c) => c.iso === isoCode)
  return match ? match.displayName : null
}

export const resolveCodeForCountryName = (name) => {
  const match = COUNTRY_LIST.find((c) => c.displayName === name)
  return match ? match.iso : null
}

export function formatClusterTag(tag) {
  if (tag && tag[0] === '!') {
    return 'Not ' + formatClusterTag(tag.slice(1))
  }
  const capitalize = (str) => str.slice(0, 1).toUpperCase() + str.slice(1)

  const [category, value] = tag.split(':')

  if (category === 'country') {
    return resolveCountryNameForCode(value)
  }

  if (
    category === 'image-title' ||
    category === 'title' ||
    category === 'domain' ||
    category === 'url' ||
    category === 'matchid'
  ) {
    return value
  }

  if (category === 'similarity') return `${value.split('+').map(capitalize).join(' or ')} Accuracy`

  if (category === 'category') {
    // TODO: SIMPLIFY ME WHEN ALL CLUSTER TAGS ARE CLEANED UP
    const matchOnNewList = CATEGORY_LABELS[value]

    return matchOnNewList || `${capitalize(value.replace(/-/g, ' '))}`
  }

  if (category === 'submitted') {
    return capitalize(value) + ' Submitted'
  }

  if (tag === 'commercial:maybe' || tag === 'commercial:mixed') return 'Maybe Commercial'

  if (['true', 'false'].includes(value)) {
    if (value === 'true') {
      return capitalize(category)
    } else {
      return 'Not ' + capitalize(category)
    }
  }

  if (tag === 'pixsy:jurisdictions') {
    return 'Pixsy’s Jurisdictions'
  }
  if (tag === 'pixsy:flagged') {
    return 'Pixsy Flagged '
  }
  if (tag === 'pixsy:entities') {
    return 'Pixsy‘s familiar Companies'
  }
  if (tag === 'top') {
    return 'Top Matches'
  }

  if (tag.startsWith('pixsy:tm')) {
    return 'Pixsy’s Suggestions'
  }

  switch (tag) {
    case 'multi:image':
      return 'Multiple Images'
    case 'multi:match':
      return 'Multiple Uses'
    case 'hd':
      return 'High Resolution'
    default:
      return capitalize(tag)
  }
}

export const UNRESOLVABLE_DOMAIN_CATEGORIES = [
  'adult',
  'blogging',
  // 'community', // to evaluate
  'directories-and-classifieds',
  'gambling',
  // 'news-editorial',
  'online-forum',
  'religion',
  'scraper-sites',
  'search-engines',
  'spam',
]

export const DATA_ELEVIO = {
  CASES_STATUS: 'CASES_STATUS',
  IMAGES_IMPORT_SYNC: 'IMAGES_IMPORT_SYNC',
  IMAGES_IMPORT_UPLOAD: 'IMAGES_IMPORT_UPLOAD',
  IMAGES_INFORMATION: 'IMAGES_INFORMATION',
  MATCHES_ACCURACY_FILTER: 'MATCHES_ACCURACY_FILTER',
  MATCHES_FLAG: 'MATCHES_FLAG',
  MATCHES_OVERVIEW: 'MATCHES_OVERVIEW',
  MATCHES_SMART_FILTERS: 'MATCHES_SMART_FILTERS',
  MATCHES_VIEW_SELECTOR: 'MATCHES_VIEW_SELECTOR',
  MATCHES_VIEWER_DOMAIN_GROUP: 'MATCHES_VIEWER_DOMAIN_GROUP',
  MATCHES_VIEWER_HIDE_SINGLE: 'MATCHES_VIEWER_HIDE_SINGLE',
  MATCHES_VIEWER_SHORT_KEYS: 'MATCHES_VIEWER_SHORT_KEYS',
  PROFILE_PAYMENTS_REMITTANCE: 'PROFILE_PAYMENTS_REMITTANCE',
  PROFILE_SETTINGS_DOMAINS: 'PROFILE_SETTINGS_DOMAINS',
  TAKEDOWN_PUBLISHED_LOCATION: 'TAKEDOWN_PUBLISHED_LOCATION',
  TAKEDOWN_TO_FIELD: 'TAKEDOWN_TO_FIELD',
}

// prettier-ignore
export const UNSUBMITTABLE_TLDS = [
  'ac',
  'ad',
  'ae',
  'af',
  'ag',
  'al',
  'am',
  'an',
  'ao',
  'aq',
  'ar',
  'aw',
  'ax',
  'az',
  'ba',
  'bb',
  'bd',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bm',
  'bn',
  'bo',
  'br',
  'bs',
  'bt',
  'bv',
  'bw',
  'by',
  'bz',
  'cd',
  'cf',
  'cg',
  'ci',
  'ck',
  'cl',
  'club',
  'cm',
  'cn',
  'cr',
  'cu',
  'cv',
  'cx',
  'cz',
  'co.id',
  'co.in',
  'co.ke',
  'co.kr',
  'co.th',
  'com.ar',
  'com.br',
  'com.hk',
  'com.pk',
  'com.pl',
  'com.mx',
  'com.cn',
  'com.ng',
  'com.tw',
  'com.uy',
  'com.ve',
  'com.vn',
  'dj',
  'dm',
  'do',
  'dz',
  'ec',
  'eg',
  'eh',
  'er',
  'et',
  'edu.vn',
  'fi',
  'fj',
  'fk',
  'fo',
  'ga',
  'gd',
  'ge',
  'gf',
  'gh',
  'gm',
  'gn',
  'gp',
  'gq',
  'gr',
  'gs',
  'gt',
  'gu',
  'gw',
  'gy',
  'hk',
  'hm',
  'hn',
  'hr',
  'ht',
  'hu',
  'id',
  'ie',
  'in',
  'iq',
  'ir',
  'in.ua',
  'jm',
  'jo',
  'kiev.ua',
  'ke',
  'kg',
  'kh',
  'ki',
  'km',
  'kn',
  'kp',
  'kr',
  'kw',
  'ky',
  'kz',
  'lb',
  'lc',
  'lk',
  'lr',
  'ls',
  'lt',
  'lv',
  'ly',
  'ma',
  'mc',
  'mg',
  'mh',
  'mk',
  'ml',
  'mm',
  'mn',
  'mo',
  'mos.ru',
  'mp',
  'mq',
  'mr',
  'ms',
  'msk.ru',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'na',
  'nc',
  'ne',
  'net.cn',
  'nf',
  'ng',
  'no',
  'np',
  'nr',
  'om',
  'org.br',
  'org.ua',
  'pa',
  'pe',
  'pf',
  'pg',
  'ph',
  'pk',
  'pl',
  'pm',
  'pn',
  'ps',
  'pt',
  'pw',
  'py',
  'pp.ua',
  'qa',
  're',
  'ro',
  'rs',
  'ru',
  'rw',
  'sb',
  'sd',
  'sh',
  'si',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'spb.ru',
  'sr',
  'st',
  'su',
  'sv',
  'sy',
  'sz',
  'tc',
  'td',
  'tf',
  'tg',
  'th',
  'tj',
  'tk',
  'tm',
  'tn',
  'tp',
  'tt',
  'tw',
  'tz',
  'ua',
  'ug',
  'um',
  'uy',
  'uz',
  'va',
  'vc',
  've',
  'vn',
  'vu',
  'wf',
  'ye',
  'yt',
  'yu',
  'zm',
]

export const getProxyUrlToMatch = (id) => `https://accessible.pixsy.io/image/${id}`

export const STRIPE_ZERO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
]
