import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'

export default {
  Container: PathContainer,
  Element: PathElement,
  Arrow: PathArrow,
}

const PathContainerWrap = styled.div`
  height: ${props => props.height};
  display: flex;
  align-items: stretch;
  margin: 65px auto;

  svg {
    width: 60px;
  }
`

function PathContainer({ height, children }) {
  const items = []

  React.Children.forEach(children, (child, index) => {
    items.push(child)
    if (index !== children.length - 1) items.push(<PathArrow />)
  })

  return (
    <PathContainerWrap height={height}>
      {items.map((item, index) => (
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </PathContainerWrap>
  )
}

function PathElement({ children }) {
  return <div css={pathElement}>{children}</div>
}

function PathArrow() {
  const arrowIcon = require('./arrowIcon.svg')
  return <div css={arrow} dangerouslySetInnerHTML={{ __html: arrowIcon }} />
}

const arrow = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  max-width: 70px;
  width: 140%; /* magic number that scales nicely */

  svg {
    width: 14px;
  }
`

const pathElement = css`
  width: 310px;
  padding: 20px 40px;
  background: #fff;
  border: 1px solid #e6e6e6;

  h2 {
    margin: 0 auto 10px;
    font-size: 1.7em;
  }

  p {
    color: #3b3b3baa;
    font-size: 0.9em;
    line-height: 1.4em;
  }

  figure {
    margin: 0 auto 20px;
    width: 230px;
    height: 150px;
    position: relative;
    border: 1px solid red; /* remove me */

    div {
      max-width: 100%;
      max-height: 100%;
    }
  }
`

PathContainerWrap.defaultProps = {
  height: 320,
}

PathContainer.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
}

PathElement.propTypes = {
  children: PropTypes.node.isRequired,
}

PathArrow.propTypes = {}
