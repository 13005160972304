import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'

const log = require('debug')('hubspot')

/**
 * This component is used to perform tracking of user inside hubspot.
 * For actual creation of customer in hubspot you should take a look at:
 * - api/controllers/auth/updateOnHubspot
 * - api/controllers/auth/register
 */
class Hubspot extends Component {
  static LAST_LOCATION_PATHNAME = null

  static HUBSPOT_SCRIPT_SRC = `//js.hs-scripts.com/${global.HUBSPOT_ACCOUNT_ID}.js`

  constructor() {
    super()
    this._identified = false
  }

  static invoke(method, ...args) {
    log('Track hubspot %o | %o', method, args)
    const _hsq = (window._hsq = window._hsq || [])

    try {
      _hsq.push([method, ...args])
    } catch (e) {
      console.error(`Unable to invoke hubspot method:`, e)
    }
  }

  componentDidMount() {
    const { user, location } = this.props

    log('cDM %O', this.props)
    user && this.handleLogin(user)
    this.setupPageviewTracker(location)

    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    window._elev.events = {
      moduleOpened(id) {
        const HUBSPOT_CUSTOM_TRIGGER_ID = 33
        if (id === HUBSPOT_CUSTOM_TRIGGER_ID) {
          try {
            window.HubSpotConversations.widget.load()
            window.HubSpotConversations.widget.open()
          } catch (e) {
            console.error('Error activating hubspot conversation')
            console.error(e)
          }
        }
      },
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props
    if (user !== prevProps.user && user) {
      log('cDM')
      this.handleLogin(user)
    }
  }

  setupPageviewTracker(location) {
    const track = (location) => {
      if (location && location.pathname !== Hubspot.LAST_LOCATION_PATHNAME) {
        log('track %o', location.pathname)
        log('last %o', Hubspot.LAST_LOCATION_PATHNAME)
        Hubspot.invoke('setPath', location.pathname)
        Hubspot.LAST_LOCATION_PATHNAME = location.pathname
      }
    }

    log('initial')
    track(location)

    this.props.history.listen((location) => {
      log('callback')
      track(location)
    })
  }

  handleLogin(user) {
    log('handle login')
    Hubspot.invoke('identify', {
      email: user.email,
      id: user._id,
      userid: user._id,
      lifecyclestage: 'customer',
    })
  }

  render() {
    return (
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={Hubspot.HUBSPOT_SCRIPT_SRC}
        />
      </Helmet>
    )
  }
}

Hubspot.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Hubspot)
