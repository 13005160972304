import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'
import Waypoint from 'react-waypoint'
import { Field, reduxForm, submit, change } from 'redux-form'
import { DATA_ELEVIO } from 'pixsy-constants'
import {
  loadTakedownContentForCluster,
  loadSuggestedEmails,
} from '../../../redux/modules/takedowns'
import { update as updateImage } from '../../../redux/modules/images'
import { updateMatch } from '../../../redux/modules/clusters'
import _isEqual from 'lodash/isEqual'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import {
  SexySeparator,
  StaticText,
  JurisdictionSelect,
  OffSectionTooltip,
  MultipleEmailField,
  ScrollToTop,
  ContentSection,
  AlignLabels,
  OffSectionProgressIndicator,
  ImageWizard,
} from 'common'
import TakedownFormImageComparison from './TakedownFormImageComparison'
import TakedownFormEmailSubmitSection from './TakedownFormEmailSubmitSection'
import TakedownFormExternalSubmitSection from './TakedownFormExternalSubmitSection'
import TakedownFormExternalSection from './TakedownFormExternalSection'
import TakedownFormFreeUserSection from './TakedownFormFreeUserSection.js'

export class TakedownForm extends Component {
  constructor({ user, cluster, loadSuggestedEmails }) {
    super()

    this.debouncedContentRefresh = _debounce(
      this.reloadContentForExternalTakedown,
      500
    )

    this.state = {
      currentSection: 'domain',
      currentSlide: 0,
      blockExternalSubmit: !!cluster.domain.dmcaFormUrl,
      imageUseInformationUpdated: false,
    }

    loadSuggestedEmails({
      domainId: cluster.domain._id,
      userId: user._id,
    })
  }

  reloadContentForExternalTakedown = () => {
    this.props.loadTakedownContentForCluster({
      clusterId: this.props.cluster._id,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { formState, submitFailed } = this.props

    if (
      formState &&
      formState.values &&
      nextProps.formState &&
      nextProps.formState.images &&
      (!_isEqual(formState.values.images, nextProps.formState.values.images) ||
        !_isEqual(formState.values.matches, nextProps.formState.values.matches))
    ) {
      this.setState({
        imageUseInformationUpdated: true,
      })
    }
    if (!submitFailed && nextProps.submitFailed) {
      const target = document.querySelector('[data-error]')
      if (target) {
        const { top } = target.getBoundingClientRect()

        // Scroll using native smoothing
        window.scrollBy({ top: top - 100, behavior: 'smooth' })
      }
    }
  }

  handleSaveAndRegenerate = async () => {
    const {
      cluster,
      updateImage,
      updateMatch,
      loadTakedownContentForCluster,
    } = this.props

    const imagesToUpdate = this.props.formState.values.images.filter(
      (i, idx) => {
        const counterpart = cluster.images[idx]
        if (i.title !== counterpart.title) {
          return true
        }
        if (
          _get(i, 'licensing.first_published.place') &&
          _get(i, 'licensing.first_published.place') !==
            _get(counterpart, 'licensing.first_published.place')
        ) {
          return true
        }
        return false
      }
    )

    const matchesToUpdate = this.props.formState.values.matches.filter(
      (m, idx) => {
        const counterpart = cluster.matches[idx]
        if (m.url !== counterpart.url) {
          return true
        }
        if (
          _get(m, 'origin.url') &&
          _get(m, 'origin.url') !== _get(counterpart, 'origin.url')
        ) {
          return true
        }
        return false
      }
    )

    await Promise.all([
      ...imagesToUpdate.map(i =>
        updateImage(i._id, {
          'licensing.first_published.place': _get(
            i,
            'licensing.first_published.place'
          ),
          title: i.title,
        })
      ),
      ...matchesToUpdate.map(m =>
        updateMatch(cluster._id, m._id, {
          url: m.url,
          'origin.url': _get(m, 'origin.url'),
        })
      ),
    ])

    await loadTakedownContentForCluster({ clusterId: cluster._id })
    this.setState({ imageUseInformationUpdated: false })
  }

  triggerSubmit = () => {
    this.props.submit('takedown')
  }

  handleExternalPageVisited = () => {
    this.setState(
      {
        blockExternalSubmit: false,
      },
      () => {
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight)
        }, 100)
      }
    )
  }

  handleWizardSlideChange = index => {
    this.setState({
      currentSlide: index,
    })
  }

  handleWaypointImages = () => {
    this.setState({ currentSection: 'images' })
  }

  handleWaypointDomain = () => {
    this.setState({ currentSection: 'domain' })
  }

  handleWaypointExternal = () => {
    this.setState({ currentSection: 'external' })
  }

  handleWaypointSubmit = () => {
    this.setState({ currentSection: 'submit' })
  }

  renderWizard = props => {
    const cluster = this.props.cluster
    return <TakedownFormImageComparison {...props} cluster={cluster} />
  }

  render() {
    const {
      cluster,
      handleSubmit,
      canTakedown,
      submitting,
      loading,
      suggestedEmails,
      requiresExternalSubmission,
      location,
      change,
    } = this.props
    const {
      currentSection,
      currentSlide,
      blockExternalSubmit,
      imageUseInformationUpdated,
    } = this.state

    const domainIcon = require(`./domainIcon.svg`)
    const imageIcon = require(`./imageIcon.svg`)
    const submitIcon = require(`./submitIcon.svg`)

    return (
      <form onSubmit={handleSubmit}>
        <ScrollToTop />
        <OffSectionProgressIndicator>
          <a
            href="#domain"
            className={cn({ active: currentSection === 'domain' })}
          >
            <div>
              <figure dangerouslySetInnerHTML={{ __html: domainIcon }} />
              <h3>Domain</h3>
            </div>
          </a>
          <SexySeparator space={20} />

          <a
            href="#images"
            className={cn({ active: currentSection === 'images' })}
          >
            <div>
              <figure dangerouslySetInnerHTML={{ __html: imageIcon }} />
              <h3>
                {cluster.images.length > 1 ? 'Images' : 'Image'}

                {cluster.images.length > 1 && (
                  <p>
                    {currentSlide + 1} of {cluster.images.length}
                  </p>
                )}
              </h3>
            </div>
          </a>

          <SexySeparator space={20} />

          <a
            href="#submit"
            className={cn({ active: currentSection === 'submit' })}
          >
            <div>
              <figure dangerouslySetInnerHTML={{ __html: submitIcon }} />
              <h3>Submit</h3>
            </div>
          </a>
        </OffSectionProgressIndicator>

        <ContentSection id="domain">
          <Waypoint onEnter={this.handleWaypointDomain} />
          {!requiresExternalSubmission && (
            <OffSectionTooltip verticalOffset={-40}>
              Pixsy has detected this email address. If you think you have a
              more appropriate address, please insert it here.
            </OffSectionTooltip>
          )}
          <AlignLabels align="left" width="150px">
            <StaticText title="Domain" text={cluster.domain.host} />

            {!requiresExternalSubmission && (
              <React.Fragment>
                <Field
                  name="to"
                  label="To"
                  onEmailChange={newEmails => {
                    change('takedown', 'to', newEmails)
                  }}
                  labelProps={{
                    'data-elevio-selector': DATA_ELEVIO.TAKEDOWN_TO_FIELD,
                  }}
                  waiting={suggestedEmails === null}
                  component={MultipleEmailField}
                />

                <OffSectionTooltip>
                  We will send a legally binding notice specific to the
                  jurisdiction. Our primary language will always be English, and
                  all notices will be sent with a translated copy in the local
                  language.
                </OffSectionTooltip>

                <Field
                  name="jurisdiction"
                  label="Jurisdiction"
                  component={JurisdictionSelect}
                />
              </React.Fragment>
            )}
          </AlignLabels>
        </ContentSection>

        <ContentSection id="images">
          <Waypoint onEnter={this.handleWaypointImages} />
          <ImageWizard
            images={cluster.images}
            onEnter={this.handleWaypointImages}
            onSlideChange={this.handleWizardSlideChange}
            render={this.renderWizard}
          />
        </ContentSection>

        {!location.search.includes('case=') && !canTakedown ? (
          <TakedownFormFreeUserSection onWaypoint={this.handleWaypointSubmit} />
        ) : (
          <Fragment>
            {requiresExternalSubmission && (
              <TakedownFormExternalSection
                requiresRefresh
                loading={loading}
                dmcaFormUrl={cluster.domain.dmcaFormUrl}
                onSaveAndRegenerate={this.handleSaveAndRegenerate}
                onWaypoint={this.handleWaypointExternal}
                onExternalPageVisited={this.handleExternalPageVisited}
                imageUseInformationUpdated={imageUseInformationUpdated}
              />
            )}

            {requiresExternalSubmission ? (
              !blockExternalSubmit && (
                <TakedownFormExternalSubmitSection
                  onWaypoint={this.handleWaypointSubmit}
                  disabled={blockExternalSubmit || submitting}
                  domain={cluster.domain}
                  onConfirm={this.triggerSubmit}
                />
              )
            ) : (
              <TakedownFormEmailSubmitSection
                onWaypoint={this.handleWaypointSubmit}
                submitting={submitting}
                fetchingData={suggestedEmails === null}
              />
            )}
          </Fragment>
        )}
      </form>
    )
  }
}

export default reduxForm({
  form: 'takedown',
  enableReinitialize: true,
  validate,
})(
  connect(null, {
    submit,
    change,
    updateImage,
    updateMatch,
    loadTakedownContentForCluster,
    loadSuggestedEmails,
  })(TakedownForm)
)

// function gatherErrorsAndWarnings(level) {
//   return function gatherErrorOrWarning(values) {
//     return Object.values(fieldMap).reduce((obj, { name, ...spec }) => {
//       const fn = spec[level] || (() => null)
//       const entry = fn(values[name], values)
//       return entry ? { ...obj, [name]: entry } : obj
//     }, {})
//   }
// }

TakedownForm.propTypes = {
  formState: PropTypes.object,
  cluster: PropTypes.object,
  location: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  updateImage: PropTypes.func.isRequired,
  suggestedEmails: PropTypes.string,
}

function validate(values) {
  const errors = {}
  if (!values.to) {
    errors.to = 'Email(s) required'
  } else {
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    const emails = values.to.trim().split(',')
    let found = emails.find(email => !regex.test(email.trim()))
    if (found) {
      errors.to = 'Invalid Email: ' + found
    }
  }

  if (!values.goodFaith) {
    errors.goodFaith = 'Required'
  }
  if (!values.disclaimer) {
    errors.disclaimer = 'Required'
  }
  return errors
}
