import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { TwitterShareButton, TwitterIcon } from 'react-share'

const PIXSY_TWITTER = 'https://twitter.com/PixsyHQ'

const Button = props => (
  <TwitterShareButton url={PIXSY_TWITTER} css={style} {...props}>
    <TwitterIcon size={32} />
    Share with friends
  </TwitterShareButton>
)

Button.propTypes = {
  title: PropTypes.string,
  via: PropTypes.string,
  hashtags: PropTypes.array,
}

const style = css`
  width: 100%;
  display: flex;
  background-color: #00aced;
  color: white;
  align-items: center;
  justify-content: center;
`

export default React.memo(Button)
