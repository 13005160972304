import { css } from 'react-emotion'

export const title = css`
  text-align: left;
  font-size: 1.1em;
  font-weight: 600;
  margin: 22px 0 14px;
  color: #3b3b3b;
`

export const body = css`
  text-align: justify;
  font-size: 14px;
  line-height: 1.35em;
  font-weight: 500;
  color: #707070;
`

export const footer = css`
  text-align: left;
  margin-top: 18px;
  font-size: 14px;
  line-height: 1.1em;
  color: #707070;
`
