import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import ReactPaginate from 'react-paginate'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'

export default class Pagination extends Component {
  render() {
    const {
      forcePage,
      perPage,
      onPageChange,
      pageCount,
      size = 'small',
      pageRangeDisplayed = 2,
      marginPagesDisplayed = 2,
      containerClassName,
    } = this.props
    if (pageCount < 2) return null

    const shared = [
      block,
      size === 'big' && bigBlock,
      page,
      size === 'big' && bigPage,
    ]
    return (
      <ReactPaginate
        containerClassName={cx(
          containerClassName,
          wrap,
          size === 'big' && bigWrap
        )}
        itemsCountPerPage={perPage}
        pageRangeDisplayed={pageRangeDisplayed}
        forcePage={forcePage}
        pageCount={pageCount}
        onPageChange={onPageChange}
        breakClassName={cx(
          'paginationBreak',
          breäk,
          size === 'big' && bigbreäk
        )}
        previousClassName={
          forcePage > 0
            ? cx('paginationPrevious', ...shared, controlButton)
            : cx('paginationPrevious', disabled)
        }
        nextClassName={
          forcePage < pageCount - 1
            ? cx('paginationNext', ...shared, controlButton)
            : cx('paginationNext', disabled)
        }
        pageClassName={cx('paginationPage', ...shared)}
        marginPagesDisplayed={marginPagesDisplayed}
        nextLabel={forcePage < pageCount - 1 && <RightArrow />}
        previousLabel={forcePage > 0 && <LeftArrow />}
      />
    )
  }
}

const block = css`
  min-width: 23px;
  height: 24px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  font-size: 11px;
  user-select: none;
  position: relative;
`
const bigBlock = css`
  min-width: 31px;
  height: 32px;
  font-size: 14px;
`

const wrap = css`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 28px;
  text-align: center;
`
const bigWrap = css`
  height: 35;
`

const controlButton = css`
  &:first-of-type {
    /* margin-right: 21px; */
    margin-right: 10.5px;
  }
  &:last-of-type {
    /* margin-left: 21px; */
    margin-left: 10.5px;
  }
`
const disabled = css`
  display: none;
`
const page = css`
  background-color: rgb(234, 234, 234);
  transition: all 200ms ease;

  &.selected {
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
    a {
      color: #222222;
    }
    &.selected a {
      color: black;
    }
  }

  > a {
    display: inline-block;
    height: 100%;
    width: 100%;
    line-height: 23px;
    vertical-align: top;
    color: rgba(70, 70, 70, 0.75);
    padding-left: 0px;
    cursor: pointer;
    transition: all 200ms ease;
    padding: 0px 5px 0 7px;

    svg {
      width: 3.88px;
      height: 7.76px;
    }

    &.selected {
      outline: 1px solid #333;
      outline-offset: 1px;
    }

    &:hover {
      background-color: #f7f7f7;
    }

    &:focus {
      outline: inherit;
      outline-offset: inherit;
    }
  }
`
const bigPage = css`
  &.selected {
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
  }

  > a {
    line-height: 32px;

    svg {
      width: 7.76px;
      height: 15.52px;
      margin-top: 8px;
    }
  }
`

const breäk = css`
  display: inline-block;
  text-align: right;
  width: 23px;
  text-align: center;
  pointer-events: none;
  user-select: none;
  color: #46464680;
`
const bigbreäk = css`
  width: 31px;
`

Pagination.propTypes = {
  size: PropTypes.oneOf(['big', 'small']),
  perPage: PropTypes.number.isRequired,
  forcePage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

// function IconArrowDown() {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="13638.514 -16337.511 21.205 11.311"
//     >
//       <path
//         style={{ fill: 'none', stroke: '#3B3B3B' }}
//         d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
//         transform="translate(13913.421 -2581.789)"
//       />
//     </svg>
//   )
// }
