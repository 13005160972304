import {
  IMAGE_INDEX,
  MATCH_INDEX,
  SUBMISSION_LABEL as LABELS,
  SUBMISSION_REQUIRED as REQUIRED,
} from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import {
  PixsyFormGroup,
  PixsyGridItem,
  PixsyLabel,
  PixsyPaper,
  PixsyTextField,
} from '../../../Components'
import { TooltipContainer } from 'common'

export const CombinedTextField = React.memo(
  ({
    leftName,
    rightName,
    leftType,
    rightType,
    leftPlaceholder,
    rightPlaceholder,
    leftValidateOnChange,
    leftValidateOnBlur,
    rightValidateOnChange,
    rightValidateOnBlur,
    rightContextOnBlur,
    labelName,
    disabled,
    padding,
    index,
    readOnly,
  }) => {
    const leftNameWithIndex = leftName
      .replace(IMAGE_INDEX, index)
      .replace(MATCH_INDEX, index)
    const rightNameWithIndex = rightName
      .replace(IMAGE_INDEX, index)
      .replace(MATCH_INDEX, index)

    const tooltip = <TooltipContainer id={labelName} />

    return (
      <PixsyPaper
        padding={padding}
        disabled={disabled[leftName] && disabled[rightName]}
        variant="transparent"
        borderless
      >
        <PixsyFormGroup
          direction="column"
          tooltip={tooltip}
          alignTooltip="flex-end"
        >
          <PixsyGridItem xs={12}>
            <PixsyLabel
              text={LABELS[labelName]}
              required={REQUIRED[labelName]}
              isHorizontal
            />
          </PixsyGridItem>
          <PixsyGridItem xs={12}>
            <PixsyFormGroup>
              <PixsyGridItem xs={5}>
                <PixsyPaper borderless disabled={disabled[leftName]}>
                  <PixsyTextField
                    type={leftType}
                    name={leftNameWithIndex}
                    disabled={disabled[leftName]}
                    placeholder={leftPlaceholder}
                    validateOnChange={leftValidateOnChange}
                    validateOnBlur={leftValidateOnBlur}
                    readOnly={readOnly}
                  />
                </PixsyPaper>
              </PixsyGridItem>
              <PixsyGridItem xs={7}>
                <PixsyPaper borderless disabled={disabled[rightName]}>
                  <PixsyTextField
                    type={rightType}
                    name={rightNameWithIndex}
                    index={index}
                    disabled={disabled[rightName]}
                    placeholder={rightPlaceholder}
                    validateOnChange={rightValidateOnChange}
                    validateOnBlur={rightValidateOnBlur}
                    fieldContextOnBlur={rightContextOnBlur}
                    readOnly={readOnly}
                  />
                </PixsyPaper>
              </PixsyGridItem>
            </PixsyFormGroup>
          </PixsyGridItem>
        </PixsyFormGroup>
      </PixsyPaper>
    )
  }
)
