import React from 'react'
import { FlagIcon } from '../../../../components/Icons'
import MultiSelectRichList from '../../../../components/MultiSelectRichList'
import Collapsable from '../../../../components/Collapsable'
import useClusterFilters, { useHandleCollapsableOpen } from '../../../../utils/useClusterFilters'

const CountriesFilter = () => {
  const {
    mappedFiltersData: { countryItems: items },
    filters: { selectedCountryIds: selectedIds },
    updateFilterState,
  } = useClusterFilters()

  const onChange = (ids) => updateFilterState({ selectedCountryIds: ids })
  const onCollapsableOpen = useHandleCollapsableOpen()

  return (
    <Collapsable
      Icon={FlagIcon}
      text="Country"
      onOpen={onCollapsableOpen}
      content={
        <MultiSelectRichList items={items} selectedIds={selectedIds} onSelectionChange={onChange} enableTextSearch />
      }
      badgeCount={selectedIds.length}
      openByDefault
    />
  )
}

export default CountriesFilter
