import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

const CREATE_PAYMENT_URLS = {
  cases: '/api/cases/payment/paypal/create',
  registrations: '/api/registrations/payment/paypal/create',
}
const EXECUTE_PAYMENT_URLS = {
  cases: '/api/cases/payment/paypal/execute',
  registrations: '/api/registrations/payment/paypal/execute',
}

export default class PayPalButton extends Component {
  /*
  state: {
    linked: false,
  }
  */

  componentDidMount() {
    if (typeof __CLIENT__ !== 'undefined' && __CLIENT__) {
      const timer = setInterval(
        function() {
          if (!window.paypal || !window.paypal.Button) return
          this._linkPaypal()
          window.clearInterval(timer)
        }.bind(this),
        300
      )
    }
  }

  _linkPaypal() {
    const { type, requestId, onSuccess } = this.props

    const CREATE_PAYMENT_URL = CREATE_PAYMENT_URLS[type]
    const EXECUTE_PAYMENT_URL = EXECUTE_PAYMENT_URLS[type]

    window.paypal.Button.render(
      {
        env: window.PRODUCTION ? 'production' : 'sandbox',

        // PayPal Client IDs - replace with your own
        // Create a PayPal app: https://developer.paypal.com/developer/applications/create
        client: {
          sandbox:
            'Ab2P9Yk15uP1SGDvx0TNgcsu_Vbns_sAzCQ14nCGxh2XiY8W0O1BwIIX8wXBpK0qvDwePovpG5PFJj0z',
          production:
            'ASxoLKYmBGJ2fcFaRnWssV0x13_ODl-kd3knKNlXVN18xQY_UkFxxs3AJIl9SLoHrevlSyaWB-G_fOyR',
        },

        style: {
          label: 'checkout',
          size: 'responsive', // small | medium | large | responsive
          shape: 'rect', // pill | rect
          color: 'blue', // gold | blue | silver | black
        },

        // Show the buyer a 'Pay Now' button in the checkout flow
        commit: true,

        payment() {
          return window.paypal.request
            .post(CREATE_PAYMENT_URL, { requestId })
            .then(data => data.id)
        },

        onAuthorize(data, actions) {
          // Make a call to the REST api to execute the payment
          return window.paypal.request
            .post(EXECUTE_PAYMENT_URL, {
              paymentID: data.paymentID,
              payerID: data.payerID,
              requestId,
            })
            .then(() => {
              // At this point the overlay is still in the way,
              // so wait a little before continuing e.g. with notifications
              setTimeout(onSuccess, 2000)
            })
        },

        onCancel(data, actions) {
          console.info('Payment cancelled', arguments)
        },
      },
      '#paypal-button-container'
    )
  }

  render() {
    return <div css={btn} id="paypal-button-container" />
  }
}

PayPalButton.propTypes = {
  type: PropTypes.oneOf(['cases', 'registrations']).isRequired,
}

const btn = css`
  height: 100px;
`
