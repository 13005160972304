import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import debug from 'debug'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

const log = debug('GRAPHQL')
log.enabled = true

const mergeLazyLoadedFields = (existing, incoming) => {
  if (existing == null) return incoming
  else if (typeof existing === 'string') return incoming
  else if (typeof incoming === 'string' && existing._id === incoming) return existing
  else return incoming
}

const mergeLazyLoadedArrays = (existing = [], incoming) => {
  if (existing.length === 0) return incoming

  const existingElementsMap = existing.reduce((map, existingElem) => {
    const existingElemId = typeof existingElem === 'string' ? existingElem : existingElem._id

    return map.set(existingElemId, existingElem)
  }, new Map())

  return incoming.map((incomingElem) => {
    const incomingElemId = typeof incomingElem === 'string' ? incomingElem : incomingElem._id
    const existingElem = existingElementsMap.get(incomingElemId)

    return mergeLazyLoadedFields(existingElem, incomingElem)
  })
}

const cache = new InMemoryCache({
  typePolicies: {
    Cluster: {
      fields: {
        // The following fields are nested entities that not always loaded when resolving a Cluster. When using the
        // getCluster or getClusters queries, they are loaded, but when using any other query that returns a Cluster
        // they will only return the ID of the object. Therefore, we must be extra careful when merging the cached data
        // with the returned data, as we might be overriding a full nested object with just an ID.
        domain: {
          merge(existing, incoming) {
            return mergeLazyLoadedFields(existing, incoming)
          },
        },
        entityData: {
          merge(existing, incoming) {
            return mergeLazyLoadedFields(existing, incoming)
          },
        },
        images: {
          merge(existing= [], incoming) {
            return mergeLazyLoadedArrays(existing, incoming)
          },
        },
      },
    },
  },
})

const httpLink = new HttpLink({
  uri: window.GRAPH_API_URL,
})
const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem('graph-session')
  const sharkuser = cookies.get('pixsy-shark')
  return {
    headers: {
      ...headers,
      authorization: authToken,
      ...(sharkuser ? { sharkuser } : {}),
    },
  }
})

const GraphQLErrorManager = ({
  message,
  locations,
  path,
  extensions: { code },
}) => {
  log('🕷  Message: %O, Location: %O, Path: %O', message, locations, path)

  if (code === 'UNAUTHENTICATED') {
    window.location.href = '/login'
  }
}

const GraphQLErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.map(GraphQLErrorManager) // Or send the log to a logging service
  if (networkError) log('🔌  [Network error]: %O', networkError) // Network related errors. Maybe logout
}

const errorLink = onError(GraphQLErrorHandler)

const client = new ApolloClient({
  cache,
  link: from([authLink, errorLink, httpLink]),
  connectToDevTools: true, //window.NODE_ENV === 'development',
})

export default client
