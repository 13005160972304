import { memoize } from 'lodash'
import * as React from 'react'
// import { PixsyMenuItem } from '../PixsyMenuItem/PixsyMenuItem'

const IMAGE_IS_REGISTERED_WITH_USCO = [
  { value: '', label: 'Please choose', hidden: true },
  { value: '', label: 'Please choose', default: true, disabled: true },
  { value: 'No', label: 'No' },
  { value: 'Pending', label: 'Registration Pending' },
  { value: 'Yes', label: 'Registration Complete' },
  { value: 'Pixsy', label: 'Submitted to Pixsy' },
]
export const USCopyrightOfficeItems = memoize(() => {
  return IMAGE_IS_REGISTERED_WITH_USCO.map(({ label, ...rest }, idx) => (
    <option key={idx} {...rest}>
      {label}
    </option>
  ))
})
// export const USCopyrightOfficeItems = memoize(() => {
//   return IMAGE_IS_REGISTERED_WITH_USCO.map(({ value, label }) => (
//     <PixsyMenuItem key={value} value={value}>
//       {label}
//     </PixsyMenuItem>
//   ))
// })
