import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Button, SexySeparator, OffSectionProgressIndicator } from 'common'
import { PHASE } from '../../../../../shared/forms/registrationSubmission'
import { resolveLabel } from './Sections/SubmissionFormSubmit'

export default class SubmissionFormProgressIndicator extends Component {
  render() {
    const {
      currentSection,
      onSave,
      loading,
      phase,
      registration,
      registrationId,
    } = this.props

    const packageIcon = require(`./Icons/registerIcon.svg`)
    const pencilIcon = require(`./Icons/pencilIcon.svg`)
    const imageIcon = require(`./Icons/imageIcon.svg`)
    const submitIcon = require(`./Icons/submitIcon.svg`)
    const paymentIcon = require(`./Icons/paymentIcon.svg`)
    const authorIcon = require(`./Icons/authorIcon.svg`)

    let label = resolveLabel(phase, loading, registrationId, registration)

    return (
      <OffSectionProgressIndicator>
        <a
          href="#package"
          className={cn({ active: currentSection === 'package' })}
        >
          <div>
            <figure
              style={{
                fill: currentSection === 'package' ? '#00758d' : '#939393',
              }}
              dangerouslySetInnerHTML={{ __html: packageIcon }}
            />
            <h3>Type</h3>
          </div>
        </a>

        <SexySeparator space={16} />

        <a
          href="#overview"
          className={cn({ active: currentSection === 'overview' })}
        >
          <div>
            <figure dangerouslySetInnerHTML={{ __html: pencilIcon }} />
            <h3>Overview</h3>
          </div>
        </a>

        <SexySeparator space={16} />

        <a
          href="#images"
          className={cn({ active: currentSection === 'images' })}
        >
          <div>
            <figure dangerouslySetInnerHTML={{ __html: imageIcon }} />
            <h3>Work</h3>
          </div>
        </a>

        <SexySeparator space={16} />

        <a
          href="#author"
          className={cn({ active: currentSection === 'author' })}
        >
          <div>
            <figure dangerouslySetInnerHTML={{ __html: authorIcon }} />
            <h3>Author</h3>
          </div>
        </a>

        <SexySeparator space={16} />

        <a href="#legal" className={cn({ active: currentSection === 'legal' })}>
          <div>
            <figure dangerouslySetInnerHTML={{ __html: submitIcon }} />
            <h3>Legal</h3>
          </div>
        </a>

        <SexySeparator space={16} />

        {/* <a
          href="#submit"
          className={cn({ active: currentSection === 'submit' })}
        >
          <div>
            <figure dangerouslySetInnerHTML={{ __html: submitIcon }} />
            <h3>Review</h3>
          </div>
        </a>
        <SexySeparator space={16} /> */}

        {registration &&
          registration.salesforce &&
          !registration.salesforce.PaymentReceivedD__c && (
            <React.Fragment>
              <a
                href="#payment"
                className={cn({ active: currentSection === 'payment' })}
              >
                <div>
                  <figure dangerouslySetInnerHTML={{ __html: paymentIcon }} />
                  <h3>Payment</h3>
                </div>
              </a>
              <SexySeparator space={16} />
            </React.Fragment>
          )}
        {phase !== PHASE.FINAL && (
          <Button hoverMain size="small" disabled={loading} onClick={onSave}>
            {label}
          </Button>
        )}
      </OffSectionProgressIndicator>
    )
  }
}

SubmissionFormProgressIndicator.propTypes = {
  currentSection: PropTypes.string.isRequired,
  // currentImage: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  phase: PropTypes.string.isRequired,
}
