import React from 'react'

export const MatchDetailsAccuracy = ({ match, cluster, score, testMode }) => {
  if (testMode === true || !global.PRODUCTION) {
    return (
      <React.Fragment>
        <dt className="debug">Score: {score}</dt>
        {cluster && cluster.scores && cluster.scores.v2 && <dt className="debug">Score V2: {cluster.scores.v2}</dt>}
      </React.Fragment>
    )
  }
  return null
}

export default MatchDetailsAccuracy
