import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

async function initMixpanel(user) {
  await Promise.delay(5000)
  if (!window.mixpanel) {
    console.error(
      `Unable to invoke mixpanel method, no mixpanel instance found.`
    )
    return
  }

  try {
    user && window.mixpanel.identify(user._id)
  } catch (e) {
    console.error(`Unable to invoke mixpanel method:`, e)
  }
}

const Mixpanel = ({ user }) => {
  useEffect(() => {
    initMixpanel(user)
    return () => window.mixpanel && window.mixpanel.reset()
  }, [user && user._id])

  const env = __SERVER__ ? process.env : window
  return (
    <Helmet>
      <script>
        {`(function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
mixpanel.init("${env.MIXPANEL_TOKEN}", {batch_requests: true})`}
      </script>
      <style type="text/css">
        {`
          #mixpanel-notification-overlay {
            font-family: inherit !important;
          }
          
          #mixpanel-notification-bg {
            background-color: white !important;
          }
          
          #mixpanel-notification-bg.mixpanel-notification-visible {
            opacity: 0.85 !important;
          }
          
          #mixpanel-notification-mainbox {
            border-radius: 0 !important;
            -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 30px !important;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 30px !important;
            border-top: 3px solid rgb(0, 138, 171) !important;
          }
          
          #mixpanel-notification-content {
            padding: 30px 20px 20px 20px !important;
          }
          
          #mixpanel-notification-tagline {
            display: none !important;
          }
          
          #mixpanel-notification-button {
            border-radius: 0 !important;
            height: auto !important;
            width: auto !important;
            max-width: 90% !important;
            display: inline-block !important;
            margin-bottom: 30px !important;
            padding: 0px 45px !important;
            background-color: rgb(0, 138, 171) !important;
          }
          
          #mixpanel-notification-button:hover {
            background-color: rgb(0, 157, 195) !important;
          }
          
          #mixpanel-notification-button-link {
            color: #fff !important;
            letter-spacing: 0.25em !important;
            text-transform: uppercase !important;
          }
          
          #mixpanel-notification-button #mixpanel-notification-button-close {
            display: none !important;
          }
          
          #mixpanel-notification-title {
            color: #3b3b3b !important;
          }
          
          #mixpanel-notification-body {
            color: #3b3b3b !important;
            text-align: center !important;
          }
          
          #mixpanel-notification-cancel {
            top: -55px !important;
            right: -45px !important;
          }
          
          #mixpanel-notification-cancel:hover {
            background: none !important;
          }
          
          #mixpanel-notification-cancel-icon {
            width: 25px !important;
            height: 25px !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-2126.207 1485.793 34.953 34.953'%3E%3Cdefs%3E%3Cstyle%3E.svg-modal-close-button %7B fill: none; stroke: %233B3B3B; stroke-width: 2px; %7D%3C/style%3E%3C/defs%3E%3Cg id='Group_785' data-name='Group 785' transform='translate(-3903 1427)'%3E%3Cline id='Line_45' data-name='Line 45' class='svg-modal-close-button' x2='33.539' y2='33.539' transform='translate(1777.5 59.5)'%3E%3C/line%3E%3Cline id='Line_46' data-name='Line 46' class='svg-modal-close-button' y1='33.539' x2='33.539' transform='translate(1777.5 59.5)'%3E%3C/line%3E%3C/g%3E%3C/svg%3E") !important;
          }
          
          #mixpanel-notification-overlay > div:first-of-type {
            margin-top: 40px !important;
          }
        `}
      </style>
    </Helmet>
  )
}

export default React.memo(Mixpanel)
