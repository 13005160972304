import React from 'react'
import { Button, SexySeparator } from 'common'
import { css } from 'react-emotion'
import moment from 'moment'
import { withCookies } from 'react-cookie'

const COOKIE_KEY = 'pixsy-smugmug-reminder-seen'

function SmugmugDialog({ cookies, onComplete, enableCloseButton }) {
  const hideModal = function() {
    cookies.set(COOKIE_KEY, 1, {
      path: '/',
      expires: moment()
        .add(2, 'days')
        .toDate(),
    })
    onComplete()
  }

  return (
    <div css={content}>
      {enableCloseButton && (
        <div css={closeButton} onClick={hideModal}>
          x
        </div>
      )}

      <h1>Connect your SmugMug Account</h1>
      <p>
        Please ensure the following settings are active in your SmugMug account
        before connecting:
        <br />
        <b>Account Settings</b> &gt; <b>Discovery</b> &gt; <b>Search</b> &gt;{' '}
        <b>Site Visibility</b> is set to <b>Yes / Yes</b>
      </p>
      <p>
        Please note that Pixsy can only access images with the visibility
        setting of <b>Public</b> or <b>Unlisted</b>. This can be changed in the
        album settings.
      </p>
      <div css={actionArea}>
        <a href="/api/auth/smugmug">
          <Button hoverMain>Connect your SmugMug</Button>
        </a>
      </div>
      <SexySeparator space={20} />
      <h3>Pixsy & SmugMug Partnership Offer</h3>
      <div css={inner}>
        <div css={left}>
          <h6>Exclusive benefits for SmugMug members:</h6>
          <div css={listAndIcon}>
            <ul>
              <li>2,000 images monitored monthly</li>
              <li>10 takedown notices</li>
              <li>Unlimited case submission</li>
              <li>Savings of more than $234 per year</li>
            </ul>
            <a href="https://www.pixsy.com/partner/smugmug/" target="_blank">
              <img src="https://s3.amazonaws.com/static.pixsy.io/services/smugmug.png" />
            </a>
          </div>
          <p>
            <i>
              Connect your SmugMug account to get started -
              <a href="https://www.pixsy.com/partner/smugmug/" target="_blank">
                &nbsp;more information here
              </a>
              .
            </i>
          </p>
        </div>
      </div>
    </div>
  )
}

SmugmugDialog.propTypes = {}

export default withCookies(SmugmugDialog)

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p,
  h3 {
    text-align: center;
  }

  em {
    color: #008aab;
  }
`

const actionArea = css`
  max-width: 530px;
  margin: 20px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  a {
    margin-left: 10px;
    margin-right: 10px;
    button {
      height: 100%;
    }
  }
`

const inner = css`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const left = css`
  display: flex;
  flex-direction: column;

  a {
    text-align: center;
  }

  ul {
    font-size: inherit;
    text-align: inherit;
    list-style: inherit;
    padding-left: inherit;
    margin: 10px 0 10px 10px;

    li {
      margin-left: 20px;
    }
  }
`

const listAndIcon = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  img {
    margin-top: 10px;
    height: 100px;
    margin-bottom: 20px;
  }
`

const closeButton = css`
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
  border-width: 0px;
  padding: 5px;
`
