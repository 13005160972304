import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { css } from 'react-emotion'

export default class FloatingInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { focused: false }
  }

  handleFocus = () => {
    this.setState({ focused: true })
    this.props.input.onFocus()
  }

  handleBlur = () => {
    this.setState({ focused: false })
    this.props.input.onBlur(this.props.input.value)
  }

  render() {
    const {
      input = {},
      meta: { touched, error, warning },
      autocomplete,
      maxLength,
      ...custom
    } = this.props

    return (
      <div
        className={cn(
          container,
          touched && hasValue,
          touched && error && hasError,
          touched && warning
        )}
      >
        <input
          className={inputStyle}
          {...input}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={custom.placeholder}
          type={custom.type}
          {...(autocomplete && { autoComplete: autocomplete })}
          {...(maxLength && { maxLength })}
        />

        <div className={cn(bar, this.state.focused && barTouched)} />

        <div
          className={cn(
            feedbackContainer,
            (touched || custom.instantFeedback) &&
              (error || warning) &&
              feedbackContainerHasErrorOrWarning,
            !error && warning && feedbackContainerWarningColor
          )}
        >
          {error || warning || ' '}
        </div>
      </div>
    )
  }
}

FloatingInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
}

const container = css`
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 200ms ease all;
  margin-bottom: 20px;
`

const hasValue = css`
  .label {
    font-size: 11px;
    transform: translateY(-20px);
    transform-origin: left top;
    opacity: 0.5;
  }
`

const hasError = css`
  .input {
    border-bottom: 1px solid #e66363;
  }
  .bar {
    opacity: 0;
  }
`

const bar = css`
  // position: absolute;
  margin-top: 1px;
  width: 0%;
  height: 1px;
  background: #008aab;
  left: 0;
  bottom: 0;
  transition: inherit;
  transform: translateY(-2px);
`

const barTouched = css`
  width: 100% !important;
`

const inputStyle = css`
  border: 0;
  padding: 0 6px 2px 10px;
  appearance: none;
  outline: none;
  caret-color: #3b3b3b;
  color: #3b3b3b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &:focus {
    &::webkit-input-placeholder {
      opacity: 0.6;
    }
  }
  &::webkit-input-placeholder {
    color: #3b3b3b;
  }

  @media (max-width: 768px) {
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
  }
`

const feedbackContainer = css`
  margin-top: 2px;
  font-size: 0.9em;
  font-weight: 400;
  text-align: right;
  // color: hsl(0, 0%, 60%);
  color: #e66363;
  max-height: 0;
  transition: max-height 0.2s ease-in;
  overflow: hidden;
`

const feedbackContainerWarningColor = css`
  color: #dda300;
`

const feedbackContainerHasErrorOrWarning = css`
  max-height: 100px;
`
