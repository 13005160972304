import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, Form, submit } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { css } from 'react-emotion'
import { compose } from 'recompose'
import get from 'lodash/get'
import _ from 'lodash'
import { notifSend } from 'redux-notifications/lib/actions'
import SubmissionFormNavigationBehavior from './SubmissionFormNavigationBehavior'
import SubmissionFormRegistrationTypes from './Sections/SubmissionFormRegistrationTypes'
import SubmissionFormOverview from './Sections/SubmissionFormOverview'
import SubmissionFormImages from './Sections/SubmissionFormImages'
import SubmissionFormAuthor from './Sections/SubmissionFormAuthor'
import SubmissionFormLegal from './Sections/SubmissionFormLegal'
import SubmissionFormSubmit from './Sections/SubmissionFormSubmit'
import SubmissionPayment from './SubmissionPayment'
import SubmissionFormBulkImages from './Sections/SubmissionFormBulkImages'
import SubmissionFormProgressIndicator from './SubmissionFormProgressIndicator'
import SubmissionFormIntro from './Sections/SubmissionFormIntro'
import { saveRegistration } from '../../../../redux/modules/registrations'
// CR_STYL: import _styles from './SubmissionForm.styl'
import { AlignLabels, ScrollToTop } from 'common'
import {
  fields as fieldMap,
  PHASE,
} from '../../../../../shared/forms/registrationSubmission'

const log = require('debug')('SubmissionForm')

export class SubmissionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSection: 'overview',
      lastSyncErrors: null,
      stage: props.registration ? 'form' : 'intro',
      fullStageValidation: false,
    }

    this.handleWaypointPackage = () =>
      this.setState({ currentSection: 'package' })
    this.handleWaypointOverview = () =>
      this.setState({ currentSection: 'overview' })
    this.handleWaypointImages = () =>
      this.setState({ currentSection: 'images' })
    this.handleWaypointAuthor = () =>
      this.setState({ currentSection: 'author' })
    this.handleWaypointLegal = () => this.setState({ currentSection: 'legal' })
    this.handleWaypointSubmit = () =>
      this.setState({ currentSection: 'submit' })
    this.handleWaypointPayment = () =>
      this.setState({ currentSection: 'payment' })
  }

  componentDidMount() {
    this.triggerPageView('open')
  }

  difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value
        }
      })
    }

    const result = changes(object, base)
    return Object.keys(result).length ? result : changes(base, object)
  }

  getFirstKeyRecursive(o, name) {
    if (name === undefined) {
      name = ''
    }

    if (o instanceof Object) {
      if (Object.keys(o).length === 0) {
        return name
      }
      const firstKey = Object.keys(o)[0]
      return this.getFirstKeyRecursive(
        o[firstKey],
        name + (name.length ? '.' : '') + firstKey
      )
    } else {
      return name
    }
  }

  triggerPageView(pageView) {
    if (pageView !== this.state.lastPageView) {
      const self = this
      try {
        window.gtag('event', 'page_view', {
          page_path: '/c/vpv/fu/co/reg/' + pageView,
          event_callback() {
            self.setState({ lastPageView: pageView })
          },
        })
      } catch (err) {
        console.error(err)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.props.validate(nextProps.values)
    const updatedProps = this.difference(nextProps.values, this.props.values)
    const firstChangedProp = this.getFirstKeyRecursive(updatedProps)

    if (
      firstChangedProp &&
      nextProps.syncErrors &&
      get(nextProps.syncErrors, firstChangedProp)
    ) {
      this.triggerPageView('err/' + firstChangedProp)
    } else if (
      firstChangedProp &&
      firstChangedProp === 'image.date_taken' &&
      get(nextProps.syncErrors, 'image.licensing.first_published.date')
    ) {
      this.triggerPageView('err/image.licensing.first_published.date')
    } else if (
      (firstChangedProp === 'usco' || firstChangedProp === 'cipo') &&
      nextProps.values.__PHASE__ === 'INTRO'
    ) {
      this.triggerPageView('select-package')
    } else if (
      this.props.values.__PHASE__ === 'INTRO' &&
      nextProps.values.__PHASE__ === 'DRAFT'
    ) {
      this.triggerPageView('enter-registration')
    } else if (
      firstChangedProp === 'workType' ||
      firstChangedProp === 'publishedBefore' ||
      firstChangedProp === 'koDisclaimerOwnership'
    ) {
      this.triggerPageView('overview')
    } else if (
      firstChangedProp === 'firstName' ||
      firstChangedProp === 'lastName' ||
      firstChangedProp === 'dateOfBirth' ||
      firstChangedProp === 'citizen' ||
      firstChangedProp === 'resident' ||
      firstChangedProp === 'street' ||
      firstChangedProp === 'city' ||
      firstChangedProp === 'state' ||
      firstChangedProp === 'postalCode' ||
      firstChangedProp === 'country' ||
      firstChangedProp === 'phone'
    ) {
      this.triggerPageView('author')
    } else if (
      firstChangedProp === 'comments' ||
      firstChangedProp === 'koIsAuthor' ||
      firstChangedProp === 'koDisclaimerLiability' ||
      firstChangedProp === 'koDisclaimerPersistence' ||
      firstChangedProp === 'koDisclaimerAgentAuthorization' ||
      firstChangedProp === 'koDisclaimerTos'
    ) {
      this.triggerPageView('legal')
    } else if (firstChangedProp === 'couponUsed') {
      this.triggerPageView('coupon')
    } else if (
      firstChangedProp.substr(0, 'image'.length) === 'image' &&
      (get(this.props, 'values.image.title') !==
        get(nextProps, 'values.image.title') ||
        get(this.props, 'values.image.date_taken') !==
          get(nextProps, 'values.image.date_taken') ||
        get(this.props, 'values.image.licensing.first_published.date') !==
          get(nextProps, 'values.image.licensing.first_published.date') ||
        get(this.props, 'values.image.licensing.first_published.country') !==
          get(nextProps, 'values.image.licensing.first_published.country') ||
        get(this.props, 'values.image.licensing.isSingleAuthor') !==
          get(nextProps, 'values.image.licensing.isSingleAuthor') ||
        get(this.props, 'values.image.licensing.publishedBefore') !==
          get(nextProps, 'values.image.licensing.publishedBefore'))
    ) {
      this.triggerPageView('image')
    } else if (
      this.props.values.salesforce &&
      this.props.values.salesforce.Paid__c === undefined &&
      nextProps.values.salesforce &&
      nextProps.values.salesforce.Paid__c === true
    ) {
      this.triggerPageView('payment-accepted')
    } else if (
      this.props.values.phase &&
      this.props.values.phase === 'REVIEW' &&
      nextProps.values.phase &&
      nextProps.values.phase === 'FINAL'
    ) {
      this.triggerPageView('submit')
    }

    if (nextProps.syncErrors && !this.state.lastSyncErrors) {
      this.setState({ lastSyncErrors: nextProps.syncErrors })
    }
  }

  triggerSync = (phase, isDraft, cb) => {
    log('The values to be synced are:', this.props.values)
    if (this.props.values.phase !== phase) {
      this.props.change('phase', phase)
    }
    if (this.props.values.__PHASE__ !== phase) {
      this.props.change('__PHASE__', phase)
    }
    if (this.props.values.__DRAFT__ !== isDraft) {
      this.props.change('__DRAFT__', isDraft)
    }

    const errors = this.props.validate(this.props.values)
    if (!errors || Object.keys(errors).length === 0) {
      this.setState({ lastSyncErrors: null }, () => {
        setTimeout(() => {
          log('Preparing to dispatch save...')
          const submittedSubmission = submit('submission')
          log('The Submitted Submission is:', submittedSubmission)
          this.props.dispatch(submittedSubmission)

          'function' === typeof cb && cb()
        }, 50)
      })
    }
  }

  scrollTo = element => {
    setTimeout(() => {
      const target = document.querySelector(element)
      if (target) {
        const { top } = target.getBoundingClientRect()

        // Scroll using native smoothing
        window.scrollBy({ top: top - 150, behavior: 'smooth' })
      }
    }, 0)
  }

  handleSave = cb => {
    const { registrationId, syncErrors, registration } = this.props
    // this.props.values.__ACTION__ =
    //   ((!registrationId || registrationId === 'new') && 'payment') || 'save'

    const newAction = (() => {
      const paymentReceived =
        registration &&
        (registration.isFreeSubmission ||
          (registration.salesforce &&
            registration.salesforce.PaymentReceivedD__c))
      const requiresSave =
        registrationId === 'new' || !registrationId || syncErrors
      const submitStatus = paymentReceived ? 'submit' : 'payment'
      return requiresSave ? 'save' : submitStatus
    })()

    const newPhase = newAction === 'save' ? PHASE.DRAFT : PHASE.REVIEW
    log('[handleSave] Save phase %o', newPhase)

    this.props.change('__ACTION__', newAction)
    log('[handleSave] Save Action %o', newAction)

    this.triggerSync(newPhase, true, cb)
    this.triggerPageView('saved')
  }

  handleSaveAndRedirectToPayment = cb => {
    const { phase } = this.props
    log('Save %o', phase)
    // this.props.values.__ACTION__ = 'payment'
    this.props.change('__ACTION__', 'payment')
    this.triggerSync(phase, true, cb)
    this.triggerPageView('saved')
  }

  handleSubmit = cb => {
    this.triggerPageView('submit')
    this.props.change('__ACTION__', 'submit')
    this.props.change('phase', PHASE.FINAL)
    this.props.change('__PHASE__', PHASE.FINAL)
    this.triggerSync(PHASE.FINAL, false, cb)
    log('[handleSubmit] Done!')
  }

  resetErrorsAndSubmit = async (onlySave = false) => {
    const {
      phase,
      saveRegistration,
      values,
      registrationId,
      history,
      notify,
    } = this.props
    log('[resetErrorsAndSubmit] isOnlySave:', onlySave)
    const syncErrors = this.props.validate(this.props.values)
    log('[resetErrorsAndSubmit] Errors:', syncErrors)
    if (phase !== PHASE.FINAL) {
      log('Save %o', phase)
      let finalPhase = phase
      if (
        (!syncErrors || Object.keys(syncErrors).length === 0) &&
        phase === PHASE.DRAFT
      ) {
        finalPhase = PHASE.REVIEW
        this.props.change('__ACTION__', 'submit')
      }
      if (onlySave === true) {
        if (syncErrors.publishedBefore || syncErrors.koDisclaimerOwnership) {
          const message = !syncErrors.publishedBefore
            ? syncErrors.koDisclaimerOwnership
            : 'In order to save your registration, please complete the Overview stage.'
          this.scrollTo('#overview')
          return notify({
            kind: 'warning',
            message,
            dismissAfter: 5e3,
          })
        }

        return saveRegistration({
          ...values,
          registrationId,
          phase: finalPhase,
        })
          .then(data => {
            const { registration } = data.payload
            this.setState({ fullStageValidation: true })
            history.replace(`/registrations/submit/${registration}`)
            return registration
          })
          .catch(log)
      }
    }

    const error = this.getFirstKeyRecursive(syncErrors)
    if (error) {
      log('[resetErrorsAndSubmit] Triggered Error:', error)
      this.triggerPageView('err/' + error)
      const errors = this.props.validate(this.props.values)
      this.setState({ lastSyncErrors: errors })
    } else {
      const wasPaid =
        this.props.registration &&
        (this.props.registration.isFreeSubmission ||
          (this.props.registration.salesforce &&
            this.props.registration.salesforce.PaymentReceivedD__c))

      log('[resetErrorsAndSubmit] wasPaid: %o', wasPaid)

      if (wasPaid) {
        this.props.change('__ACTION__', 'submit')
        this.props.change('__PHASE__', 'FINAL')
        this.props.change('phase', 'FINAL')
        setTimeout(() => {
          log('Prepparing to dispatch save...')
          const submittedSubmission = submit('submission')
          log('The Submitted Submission is:', submittedSubmission)
          this.props.dispatch(submittedSubmission)
          this.props.onSubmit(_, this.props.dispatch, this.props)
        }, 50)
        // this.props.change('__ACTION__', 'submit')
        // this.setState({ lastSyncErrors: null }, () => {
        //   // debugger
        //   log('[resetErrorsAndSubmit] Ready to submit')
        //   this.props.handleSubmit(this.handleSubmit)
        // })
        // setTimeout(() => {
        //   // this.props.change('__PHASE__', PHASE.FINAL)
        // }, 1000)
      } else {
        log('[resetErrorsAndSubmit] Ready for payment')
        this.props.change('__ACTION__', 'payment')
        this.setState({ lastSyncErrors: null }, () => {
          this.props.handleSubmit(this.handleSaveAndRedirectToPayment)
        })
      }
    }
  }

  handleContinue = () => {
    const { phase, values, notify } = this.props
    if (phase === PHASE.INTRO) {
      if (
        values.usco &&
        values.image &&
        values.image.tags &&
        values.image.tags.includes('registration:usco')
      ) {
        notify({
          kind: 'warning',
          message:
            'You have already requested USCO Registration for this image',
          dismissAfter: 5e3,
        })
        return false
      }

      if (
        values.cipo &&
        values.image &&
        values.image.tags &&
        values.image.tags.includes('registration:cipo')
      ) {
        notify({
          kind: 'warning',
          message:
            'You have already requested CIPO Registration for this image',
          dismissAfter: 5e3,
        })
        return false
      }

      // this.props.values.__PHASE__ = PHASE.DRAFT
      this.props.change('phase', PHASE.DRAFT) // this will trigger validation for DRAFT
      this.props.change('__PHASE__', PHASE.DRAFT) // this will trigger validation for DRAFT
      this.setState({ stage: 'form' })
      return true
    }
  }

  resetErrorsAndContinue = () => {
    const errors = this.props.validate(this.props.values)
    if (!errors || Object.keys(errors).length === 0) {
      this.setState(
        {
          lastSyncErrors: null,
        },
        this.props.handleSubmit(this.handleContinue)
      )
    }
  }

  getFieldPropsFor = (key, member) => {
    const { initialValues, values, validate } = this.props

    const fieldSpec = fieldMap[key]
    let name
    if (!fieldSpec) throw new Error('Unable to resolve field spec for ' + key)
    name = fieldSpec.name
    if (!member) {
    } else {
      const regexpResult = member.match(/\d/)
      if (!regexpResult)
        throw new Error('Unable to extract index from path ' + member)

      const index = parseInt(regexpResult[0], 10)
      name = `${fieldSpec.path}[${index}].${fieldSpec.name}`
    }

    if (!name) throw new Error('No name for ' + key)

    const syncErrors = validate(values)

    const editing = initialValues._id !== 'new'
    let spec = {
      name,
      type: fieldSpec.type,
      placeholder: fieldSpec.placeholder,
      ...fieldSpec.props,
      editing,
      hasError: syncErrors ? Boolean(get(syncErrors, name)) : false,
    }

    if (!spec.hasError) {
      spec.meta = {}
    }

    if (spec.hasError && this.state.fullStageValidation) {
      spec.meta = {
        error: {
          reason: get(syncErrors, name),
        },
        dirty: true,
      }
    }

    if (!editing && initialValues[name]) {
      spec.value = initialValues[name]
    }

    return spec
  }

  registrationRequiresSave = () => {
    const { registration, registrationId, values, validate } = this.props
    const errors = validate(values)
    if (errors && Object.keys(errors).length > 0) return true
    const paymentReceived =
      registration &&
      (registration.isFreeSubmission ||
        (registration.salesforce &&
          registration.salesforce.PaymentReceivedD__c))

    return registrationId === 'new' || !registrationId || !paymentReceived
  }

  render() {
    const { currentSection, currentImage, stage } = this.state
    const {
      dirty,
      submitting,
      valid,
      registrationId,
      values,
      phase,
      registration,
      handleSubmit,
      user,
      loading,
      syncErrors,
    } = this.props

    const renderBulkImagesForm = (() => {
      if (values.usco && values.usco === 'USCO_MULTI') {
        return true
      }

      return false
    })()

    return (
      <SubmissionFormNavigationBehavior
        dirty={dirty}
        valid={valid}
        registrationId={registrationId}
        onSave={this.handleSave}
        submitting={submitting}
        phase={registration && registration.phase}
      >
        {({ navigateToProfile }) => {
          if (stage === 'intro') {
            return (
              <Form ref="form" onSubmit={handleSubmit}>
                <SubmissionFormIntro
                  getFieldPropsFor={this.getFieldPropsFor}
                  values={values}
                  onContinue={this.resetErrorsAndContinue}
                />
              </Form>
            )
          } else {
            return (
              <React.Fragment>
                <Form ref="form" onSubmit={handleSubmit}>
                  <AlignLabels align="left">
                    <Field name="phase" type="hidden" component="input" />
                    <Field name="__PHASE__" type="hidden" component="input" />
                    <Field name="__DRAFT__" type="hidden" component="input" />
                    <Field name="__ACTION__" type="hidden" component="input" />

                    <div css={submissionFormStyles}>
                      <SubmissionFormProgressIndicator
                        currentSection={currentSection}
                        currentImage={currentImage}
                        onSave={
                          this.registrationRequiresSave()
                            ? () => this.resetErrorsAndSubmit(true)
                            : this.resetErrorsAndSubmit
                        }
                        values={values}
                        loading={loading}
                        phase={phase}
                        registration={registration}
                      />
                      <div /* CR_STYL: className={_styles.innerContent}*/>
                        <SubmissionFormRegistrationTypes
                          locked
                          onWaypoint={this.handleWaypointPackage}
                          phase={phase}
                          getFieldPropsFor={this.getFieldPropsFor}
                          values={values}
                          registration={registration}
                        />

                        <SubmissionFormOverview
                          onWaypoint={this.handleWaypointOverview}
                          phase={phase}
                          getFieldPropsFor={this.getFieldPropsFor}
                          values={values}
                          isBulkSubmission={renderBulkImagesForm}
                        />

                        {!renderBulkImagesForm && (
                          <SubmissionFormImages
                            onWaypoint={this.handleWaypointImages}
                            phase={phase}
                            getFieldPropsFor={this.getFieldPropsFor}
                            values={values}
                          />
                        )}

                        {renderBulkImagesForm && (
                          <SubmissionFormBulkImages
                            onWaypoint={this.handleWaypointImages}
                            registrationId={registrationId}
                            getFieldPropsFor={this.getFieldPropsFor}
                            values={values}
                            phase={registration && registration.phase}
                            onRequestImages={this.resetErrorsAndSubmit}
                            dispatch={this.props.dispatch}
                          />
                        )}

                        <SubmissionFormAuthor
                          onWaypoint={this.handleWaypointAuthor}
                          phase={phase}
                          getFieldPropsFor={this.getFieldPropsFor}
                          values={values}
                        />

                        <SubmissionFormLegal
                          onWaypoint={this.handleWaypointLegal}
                          getFieldPropsFor={this.getFieldPropsFor}
                          values={values}
                          phase={phase}
                        />

                        {registration &&
                          registration.salesforce &&
                          !!registration.salesforce.PaymentReceivedD__c &&
                          !registration.isFreeSubmission && (
                            <SubmissionPayment
                              user={user}
                              registration={registration}
                              onWaypoint={this.handleWaypointPayment}
                              values={values}
                            />
                          )}

                        {(!registration ||
                          (registration &&
                            registration.phase !== PHASE.FINAL)) && (
                          <SubmissionFormSubmit
                            registrationId={registrationId}
                            registration={registration}
                            phase={phase}
                            onWaypoint={this.handleWaypointSubmit}
                            getFieldPropsFor={this.getFieldPropsFor}
                            loading={loading}
                            values={values}
                            validate={this.props.validate}
                            onSubmit={
                              this.registrationRequiresSave()
                                ? () => this.resetErrorsAndSubmit(true)
                                : this.resetErrorsAndSubmit
                            }
                          />
                        )}
                      </div>
                    </div>
                  </AlignLabels>
                  <ScrollToTop />
                </Form>
                <React.Fragment>
                  {registration &&
                    registration.submission &&
                    (!syncErrors || Object.keys(syncErrors).length === 0) &&
                    (registration.image || registration.images) &&
                    registration.images &&
                    registration.images.length > 0 &&
                    registration.submission.koDisclaimerTos &&
                    registration.salesforce &&
                    !registration.salesforce.PaymentReceivedD__c &&
                    !registration.isFreeSubmission && (
                      <SubmissionPayment
                        user={user}
                        registration={registration}
                        onWaypoint={this.handleWaypointPayment}
                        values={values}
                      />
                    )}
                </React.Fragment>
              </React.Fragment>
            )
          }
        }}
      </SubmissionFormNavigationBehavior>
    )
  }
}

SubmissionForm.propTypes = {
  values: PropTypes.object,
  syncErrors: PropTypes.object,
  registration: PropTypes.object,
  phase: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default compose(
  withRouter,
  reduxForm({
    form: 'submission',
    destroyOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    persistentSubmitErrors: false,
    touchOnChange: true,
    validate(values, props) {
      return props.validate(values) || {}
    },
    onSubmitFail,
  }),
  connect(
    state => ({
      values: state.form.submission && state.form.submission.values,
      syncErrors: state.form.submission && state.form.submission.syncErrors,
      loading: state.registrations.loading,
    }),
    {
      saveRegistration,
      notify: notifSend,
    }
  )
)(SubmissionForm)

function onSubmitFail(err) {
  // Swiping react-slick handled via componentReceivedProps + lastSyncErrors state
  setTimeout(() => {
    const target = document.querySelector('div[data-error]')
    if (target) {
      const { top } = target.getBoundingClientRect()

      // Scroll using native smoothing
      window.scrollBy({ top: top - 150, behavior: 'smooth' })
    }
  }, 0)
}

export const submissionFormStyles = css`
  margin: 25px 0 45px;
  position: relative;

  label {
    font-weight: 300;
    font-size: 1.42em;
  }
  h2 {
    text-align: center;
  }
`
