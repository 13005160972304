import {
  ContentSection,
  PaypalContext,
  PriceTag,
  SexySeparator,
  StripeContext,
} from 'common'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { css, cx } from 'react-emotion'
import { connect } from 'react-redux'
import Waypoint from 'react-waypoint'
import { notifSend } from 'redux-notifications/lib/actions'
import PaymentsContainer from './Payments/PaymentsContainer'

export class PaymentSection extends PureComponent {
  state = { paid: true }

  constructor(props) {
    super(props)
    this.state.paid = Boolean(
      props.registration.salesforce &&
        props.registration.salesforce.PaymentReceivedD__c
    )
    this.ref = React.createRef()
  }

  // Scroll to ref function
  // scrollTo = () => {
  //   if (this.ref && this.ref.current) this.ref.current.scrollIntoView()
  // }

  // On component mount, scroll to ref
  // componentDidMount() {
  //   this.scrollTo()
  // }

  handlePaymentSuccess = () => {
    this.props.notifSend({
      message: 'Payment was successful. Thank you.',
      kind: 'success',
      dismissAfter: 10e3,
    })
    this.setState({ paid: true })
  }

  render() {
    const { registration, onWaypoint, user } = this.props
    const { paid } = this.state

    registration.salesforce = registration.salesforce || {}

    return (
      <PaypalContext>
        <StripeContext account="subscription">
          <ContentSection id="payment">
            <Waypoint onEnter={onWaypoint} />
            <h1>Payment</h1>
            <SexySeparator space={20} />
            <div className={`${wrap} ${flex}`}>
              <div css={cx(leftCol, paid && paidStyle)}>
                <h5>
                  Amount{' '}
                  {registration.salesforce.PaymentReceivedD__c ? 'Paid' : 'Due'}
                </h5>
                <PriceTag
                  value={registration.totalPrice}
                  originalPrice={registration.originalPrice}
                  currency="USD"
                />
                {registration.salesforce.CouponUsed__c && (
                  <div>Coupon: {registration.salesforce.CouponUsed__c}</div>
                )}
              </div>
              <div css={rightCol} ref={this.ref}>
                {registration.salesforce.PaymentReceivedD__c ? (
                  <React.Fragment>
                    <h5>Payment Received</h5>
                    {moment(registration.salesforce.PaymentReceivedD__c).format(
                      'LL'
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/*<h5>Payment Method</h5>*/}
                    <PaymentsContainer
                      user={user}
                      registration={registration}
                      onPaymentSuccess={this.handlePaymentSuccess}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </ContentSection>
        </StripeContext>
      </PaypalContext>
    )
  }
}

export default connect(null, { notifSend })(PaymentSection)

PaymentSection.propTypes = {
  registration: PropTypes.object.isRequired,
}

const wrap = css`
  h5 {
    color: #43434380;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
`

const flex = css`
  display: flex;
`

const leftCol = css`
  width: 280px;
  margin-right: 40px;
`

const rightCol = css`
  flex-grow: 1;
`

const paidStyle = css`
  opacity: 0.1;
`
