/** @see https://github.com/gaearon/normalizr */
import { schema } from 'normalizr'

export const USER = new schema.Entity('users', {}, { idAttribute: '_id' })
export const IMAGE = new schema.Entity('images', {}, { idAttribute: '_id' })
export const CASE = new schema.Entity('cases', {}, { idAttribute: '_id' })
export const MATCH = new schema.Entity('matches', {}, { idAttribute: '_id' })
export const CLUSTER = new schema.Entity('clusters', {}, { idAttribute: '_id' })
export const DOMAIN = new schema.Entity('domains', {}, { idAttribute: '_id' })
export const HISTORY = new schema.Entity('history', {}, { idAttribute: 'id' })
export const PLAN = new schema.Entity('plans', {}, { idAttribute: '_id' })
export const ATTACHMENT = new schema.Entity(
  'attachments',
  {},
  {
    idAttribute: 'id',
  }
)
export const RULE = new schema.Entity('rules', {}, { idAttribute: '_id' })
export const TAKEDOWN = new schema.Entity(
  'takedowns',
  {},
  { idAttribute: '_id' }
)
export const REGISTRATION = new schema.Entity(
  'registrations',
  {},
  {
    idAttribute: '_id',
  }
)

export const USER_ARRAY = new schema.Array(USER)
export const IMAGE_ARRAY = new schema.Array(IMAGE)
export const CASE_ARRAY = new schema.Array(CASE)
export const MATCH_ARRAY = new schema.Array(MATCH)
export const CLUSTER_ARRAY = new schema.Array(CLUSTER)
export const DOMAIN_ARRAY = new schema.Array(DOMAIN)
export const HISTORY_ARRAY = new schema.Array(HISTORY)
export const ATTACHMENT_ARRAY = new schema.Array(ATTACHMENT)
export const PLAN_ARRAY = new schema.Array(PLAN)
export const RULE_ARRAY = new schema.Array(RULE)
export const REGISTRATION_ARRAY = new schema.Array(REGISTRATION)
export const TAKEDOWN_ARRAY = new schema.Array(TAKEDOWN)

// USER.define({})
// DOMAIN.define({})
// PLAN.define({})
// RULE.define({})
REGISTRATION.define({
  user: USER,
  images: IMAGE_ARRAY,
})
IMAGE.define({
  owner: USER,
  registrations: REGISTRATION_ARRAY,
})
CLUSTER.define({
  case: CASE,
  domain: DOMAIN,
  takedown: TAKEDOWN,
  matches: MATCH_ARRAY,
  images: IMAGE_ARRAY,
})
CASE.define({
  user: USER,
  cluster: CLUSTER,
  history: HISTORY_ARRAY,
  submission: {
    images: IMAGE_ARRAY,
    matches: MATCH_ARRAY,
  },
})
TAKEDOWN.define({
  user: USER,
  cluster: CLUSTER,
})
