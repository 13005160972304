import React from 'react'
import PropTypes from 'prop-types'
import { cx, css } from 'react-emotion'

StickyFloater.propTypes = {
  children: PropTypes.node.isRequired,
}

const spacer = css`
  height: 0;
  margin: 0 auto 50px;
`

// 122: navigation width
const fix = css`
  position: fixed;
  width: calc(100vw - 116px - 105px);
  right: 55px;
  z-index: 100;
  top: 0;
  padding-top: 30px;
  background: linear-gradient(
    rgba(252, 251, 252, 0.9) 50%,
    rgba(252, 251, 252, 0)
  );
  &.bannerOn {
    top: 55px;
    background: transparent;
  }

  @media (max-width: 768px) {
    position: fixed;
    width: calc(100vw - 95px);
    z-index: 100;
    top: 0;
    left: 95px;
    padding-top: 0;
    height: 48px;
    overflow: hidden;
  }
`

const fixBackButton = css`
  @media (max-width: 768px) {
    position: fixed;
    width: 100vw;
    z-index: 100;
    top: 0;
    left: 0px;
    padding-top: 0;
    height: 48px;
    overflow: hidden;
  }
`

// 122: navigation width
const shadowDummy = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    width: 100vw;
    z-index: 0;
    top: 0px;
    left: 0;
    height: 48px;
    background-color: #008aab;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
  }
`

const container = css`
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin: 0 auto;
  min-width: 600px;
  max-width: 1100px;
  background: #fff;
  transition: all 300ms ease;
  &:hover {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    min-width: auto;
    max-width: auto;
    width: 100%;
    height: calc(100% + 17px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
  }

  @media screen and (min-width: 1024px) {
    max-width: 900px;
  }
  @media screen and (min-width: 1441px) {
    max-width: 1200px;
  }
  @media screen and (min-width: 1681px) {
    max-width: 1400px;
  }
  @media screen and (min-width: 1921px) {
    max-width: 1600px;
  }
`

const content = css`
  width: 100%;
  @media(max-width: 768px){
    height: 100%;
  }


  .sticky-floater--left-aligned-description {
    text-align: left;
    padding-left: 20px;
    padding-right: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;}
  }

  .sticky-floater--back-button {
    height: 54px;
    max-width: 80px;
    background-color: #148aa9;

    &:hover {
      border-bottom: 0 !important;
    }

    &:after {
      content: '<';
      color: #fff;
      font-size: 2em;
    }
  }

  .sticky-floater--highlight {
    background-color: #148aa9;
    height: 100%;
  }


  .sticky-floater--slight-highlight{
      border-left: solid 2px rgb(240,240,240);
      transition: all 200ms ease;
      &:hover {
        background-color: #148aa9;
        color: white;
      }
    }
  }
`

export default function StickyFloater({ navigation, hasBackButton, children }) {
  const render = children =>
    navigation ? <div css={nav}>{children}</div> : children

  return (
    <div className={spacer}>
      {/*<div className={cx(hasBackButton ? fixBackButton : fix)}>*/}
      <div className={hasBackButton ? cx(fix, fixBackButton) : fix}>
        <div className={container}>
          <div className={hasBackButton ? cx(content, navBackButton) : content}>
            {render(children)}
          </div>
        </div>
      </div>
      <div className={shadowDummy} />
    </div>
  )
}

StickyFloater.propTypes = {
  navigation: PropTypes.bool,
  hasBackButton: PropTypes.bool,
}
StickyFloater.defaultProps = {
  navigation: true,
  hasBackButton: false,
}

const nav = css`
  display: flex;
  font-size: 1.25em;
  height: 100%;
  position: relative;
  overflow-y: hidden;
  white-space: nowrap;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }

  > * {
    flex: 1;
    height: 54px;
    line-height: 56px;
  }

  > a {
    color: #999;
    font-weight: bold;
    text-align: center;
    font-size: 0.87em;
    @media screen and (max-width: 1200px) {
      flex-basis: auto;
      font-size: 0.78em;
    }

    &:hover {
      color: #148aa9;
    }
  }
  @media (max-width: 768px) {
    font-size: 1.2em;
    display: block;

    a {
      height: calc(100% - 17px);
      width: 120px;
      display: inline-block;
    }
  }

  button {
    margin: 0;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    font-size: 0.9em;
  }

  .active {
    color: #000;
    box-sizing: border-box;
    border-bottom: 2px solid #148aa9;
    &:hover {
      color: black;
    }
  }
`

const navBackButton = css`
  @media (max-width: 768px) {
    overflow-x: hidden;

    button {
      float: left;
      outline: 0;
      &:focus,
      &:active {
        outline: 0 !important;
      }
    }
  }
`
