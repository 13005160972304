import * as React from 'react'
import PropTypes from 'prop-types'

export default function IconCircle({ color, width = 11, height = 11 }) {
  return (
    <svg width={width} height={height} viewBox="-6771 2098 8.499 8.499">
      <ellipse
        style={{ fill: color }}
        cx="4.25"
        cy="4.25"
        rx="4.25"
        ry="4.25"
        transform="translate(-6771 2098)"
      />
    </svg>
  )
}
IconCircle.propTypes = {
  color: PropTypes.string,
}
