import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Field, reduxForm, reset } from 'redux-form'
import { AlignLabels, Button, PhantomLabelSpacer, TextField } from 'common'
import { changeEmail } from '../../../redux/modules/auth'

export function ChangeEmailForm({ valid, submitting, handleSubmit }) {
  return (
    <div>
      <h4>Change Email</h4>

      <form onSubmit={handleSubmit}>
        <AlignLabels align="left" width="180px">
          <Field name="email_old" label="Current Email" component={TextField} />
          <Field
            name="password_old"
            type="password"
            label="Current Password"
            component={TextField}
          />
          <Field name="email_new" label="New Email" component={TextField} />
          <Field
            name="email_repeat"
            label="Confirm Email"
            component={TextField}
          />
        </AlignLabels>

        <PhantomLabelSpacer alignContent="right">
          <div css={mobile}>
            <Button disabled={submitting} submit>
              Change Email
            </Button>
          </div>
        </PhantomLabelSpacer>
      </form>
    </div>
  )
}

const mobile = css`
  @media (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

ChangeEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'changeEmail',
  onSubmit: (values, dispatch) =>
    dispatch(
      changeEmail({
        newEmailConfirm: values.email_repeat,
        newEmail: values.email_new,
        oldEmail: values.email_old,
        oldPassword: values.password_old,
      })
    ).then(() => dispatch(reset('changeEmail'))),
})(ChangeEmailForm)
