import React from 'react'
import PropTypes from 'prop-types'
import { cx, css } from 'react-emotion'
import { NotAllowedIcon } from 'common'

export default function VerificationPendingAddon({
  hover,
  requestEmailVerification,
}) {
  return (
    <React.Fragment>
      <a
        css={cx(untrackedBackground, hover && untrackedBackgroundHover)}
        onClick={e => {
          setTimeout(() => {
            requestEmailVerification()
          })
          return e.stopPropagation()
        }}
      />
      <div css={cx(untracked, hover && untrackedHover)}>
        <p>
          <b>Account not verified</b>
          <br />
          <span css="color: #148aa9; cursor: pointer">
            Verify your email
          </span>{' '}
          to scan your images
        </p>
        <NotAllowedIcon />
      </div>
    </React.Fragment>
  )
}

VerificationPendingAddon.propTypes = {
  requestEmailVerification: PropTypes.func.isRequired,
  hover: PropTypes.bool.isRequired,
}

const untracked = css`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  p {
    position: absolute;
    width: 100%;
    bottom: -25px;
    text-align: center;
    transition: 0.2s ease-out, opacity 0.2s ease-in;
    opacity: 0;
  }
  a {
    color: #148aa9;
  }
  svg {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 8px;
    right: 8px;
    color: #3b3b3b;
    transition: opacity 0.025s ease-out;
  }
`

const untrackedBackground = css`
  position: absolute;
  height: 100%;
  top: 60%;
  width: 130%;
  background-color: rgba(255, 255, 255, 0.9);
  transform: rotate(-10deg) translateX(20px);
  cursor: pointer;
  transition: 0.2s ease-out;
`

const untrackedBackgroundHover = css`
  transform: rotate(-10deg) translateY(-20px);
`

const untrackedHover = css`
  svg {
    opacity: 0;
  }
  p {
    transform: translateY(-30px);
    opacity: 1;
  }
`
