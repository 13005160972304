import React from 'react'
import styled from 'react-emotion'
import { WhiteMagnifyingGlassIcon } from './Icons'
import FixedHeightImageContained from './FixedHeightImageContained'

const SingleMatchTiles = ({ match, image, expandView, height }) => {
  return (
    <SingleMatchTilesStyled onClick={expandView}>
      <FixedHeightImageContained url={image.url} height={height} id={image._id} offlineLabel="image" />
      <FixedHeightImageContained url={match.url} height={height} id={match._id} offlineLabel="match" />
      <HoverOverlayStyled className="hoverCard">
        <WhiteMagnifyingGlassIcon width={15} height={15} />
        <span>EXPAND VIEW</span>
      </HoverOverlayStyled>
    </SingleMatchTilesStyled>
  )
}

export default SingleMatchTiles

const SingleMatchTilesStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 1px;
  width: 450px;
  cursor: pointer;

  &:hover {
    .hoverCard {
      opacity: 1;
    }
  }
`

const HoverOverlayStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  transition: opacity 0.1s linear;
  opacity: 0;
  font-weight: 600;
  letter-spacing: 0.15em;
  z-index: 10;
`
