import * as React from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { Pagination, GoToPage, ItemsPerPage } from 'common'

export default class PaginationFooter extends React.Component {
  render() {
    const {
      size,
      className,
      paginationProps,
      onPerPageChange,
      singularType,
      pluralType,
      onPageChange,
      marginPagesDisplayed,
      isRenderWhenOnePage,
    } = this.props
    const {
      pageCount: totalPages,
      forcePage: currentPage,
      total: totalMatches,
      perPage,
    } = paginationProps

    if (totalPages < 2 && !isRenderWhenOnePage) return null

    return (
      <div className={cx(className, wrap)}>
        <div className={flex}>
          <div css={hideOnMobile}>
            <ItemsPerPage
              selected={perPage}
              onPerPageChange={onPerPageChange}
            />
          </div>
          <div css={wrapPagination}>
            <Pagination
              {...paginationProps}
              onPageChange={onPageChange}
              marginPagesDisplayed={marginPagesDisplayed}
              size={size}
            />
            <p className="paginationFooterInfoParagraph">
              {`${Number(totalMatches).toLocaleString()} ${
                totalMatches === 1 ? singularType : pluralType
              } on ${Number(totalPages).toLocaleString()} ${
                totalPages === 1 ? 'page' : 'pages'
              }`}
            </p>
          </div>
          <div css={hideOnMobile}>
            <GoToPage
              value={currentPage + 1}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
        <div css={paginationMobile}>
          <div>
            <ItemsPerPage
              selected={perPage}
              onPerPageChange={onPerPageChange}
            />
          </div>
          <div>
            <GoToPage
              value={currentPage + 1}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    )
  }
}

PaginationFooter.defaultProps = {
  size: 'small',
  marginPagesDisplayed: 0,
}

PaginationFooter.propTypes = {
  size: PropTypes.oneOf(['big', 'small']),
  marginPagesDisplayed: PropTypes.number,
  paginationProps: PropTypes.shape({
    pageCount: PropTypes.number.isRequired,
    forcePage: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  onPageChange: PropTypes.func.isRequired,
  onPerPageChange: PropTypes.func.isRequired,
  singularType: PropTypes.oneOf(['image', 'match', 'case', 'result']),
  pluralType: PropTypes.oneOf(['images', 'matches', 'cases', 'results']),
}

const wrap = css`
  margin-top: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.9em;
  color: #999;
`
const wrapPagination = css`
  p {
    text-align: center;
    font-size: 12px;
    color: rgba(147, 147, 147, 0.5);
    padding-top: 24.71px;
  }
`
const flex = css`
  display: flex;
  justify-content: stretch;
  padding: 0 12px;

  > * {
    flex-grow: 1;
    align-items: flex-end;
  }
`
const hideOnMobile = css`
  @media (max-width: 768px) {
    display: none;
  }
`

const paginationMobile = css`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    text-align: center;
    opacity: 0.7;
    transform: scale(0.9);
    padding-top: 30px;
    label {
      display: block;
    }
    select {
      margin: 0;
    }
  }
`
