import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import {
  ContentSection,
  RegistrationTypeSelect,
  Button,
  ActionSection,
} from 'common'
import { compose, lifecycle, withProps } from 'recompose'
import CopyrightRegistrationGuide from '../../../CopyrightRegistrationGuide'

function SubmissionFormIntro({
  onContinue,
  getFieldPropsFor,
  values,
  sectionRef,
}) {
  return (
    <React.Fragment>
      <CopyrightRegistrationGuide />
      <div ref={sectionRef}>
        <ContentSection>
          <h1>Select Registration Type</h1>
          <RegistrationTypeSelect
            getFieldPropsFor={getFieldPropsFor}
            values={values}
            showBottomBar
          />
          <ActionSection>
            {/* <Hint>
            <strong>HINT:</strong> You can include more than one jurisdiction
            per registration.
          </Hint> */}
            <Button onClick={onContinue.bind(null)}>Continue</Button>
            <small>
              All prices include the filing fees at the copyright office
            </small>
          </ActionSection>
        </ContentSection>
      </div>
    </React.Fragment>
  )
}

SubmissionFormIntro.propTypes = {
  onContinue: PropTypes.func.isRequired,
  getFieldPropsFor: PropTypes.func.isRequired,
  values: PropTypes.object,
}

export default compose(
  withProps(props => ({ sectionRef: createRef() })),
  lifecycle({
    componentDidMount() {
      setTimeout(() => {
        this.props.sectionRef &&
          this.props.sectionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
          })
      }, 500)
    },
  })
)(SubmissionFormIntro)
