import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

const InnerLabel = styled.div`
  display: block;
  font-size: 12.6px;
  font-weight: 400;
  letter-spacing: 1.9px;
  line-height: 22px;
  overflow-wrap: break-word;
  text-transform: uppercase;
  color: #999;
`

export default function Label({ title }) {
  return <InnerLabel>{title}</InnerLabel>
}

Label.propTypes = {
  title: PropTypes.node,
}
