import React from 'react'
import styled, { css } from 'react-emotion'
import slugify from 'slugify'

class RandomStory extends React.Component {
  constructor(props) {
    super(props)

    this.prepare()
  }

  prepare() {
    const stories = [
      {
        prefix: 'jenkins',
        profile: require('./profiles/jenkins.jpg'),
        name: 'Arno Jenkins',
        align: 'center center',
        recent: false,
        description:
          'Freelance photographer and graphic designer Arno Jenkins has traveled extensively on his quest for the perfect photo.',
        link: 'https://www.pixsy.com/featured-artists/arno-jenkins-photo-copyright/',
      },
      {
        prefix: 'mcdonald',
        profile: require('./profiles/macdonald.jpg'),
        name: 'Jamie MacDonald',
        align: 'center center',
        recent: false,
        description:
          'Olympus Visionary Jamie MacDonald’s primary photographic focus is on capturing vivid landscape stories.',
        link: 'https://www.pixsy.com/featured-artists/jamie-macdonald-photo-copyright/',
      },
      {
        prefix: 'boss',
        profile: require('./profiles/boss.jpg'),
        name: 'Mike Boss',
        align: 'center center',
        recent: false,
        description:
          'Mike Boss is a keen landscape photographer, specialising in stories from the Dorset and New Forest areas of the United Kingdom.',
        link: 'https://www.pixsy.com/featured-artists/mike-boss/',
      },
      {
        prefix: 'mustard',
        profile: require('./profiles/mustard.jpg'),
        name: 'Amanda Mustard',
        align: 'center center',
        recent: false,
        description:
          'Mustard is an advocate for the protection and sustainability of the freelance community and gender equality in the media industry.',
        link: 'https://www.pixsy.com/featured-artist/amanda-mustard/',
      },
      {
        prefix: 'hunder',
        profile: require('./profiles/hunder.png'),
        name: 'Michelle Grace Hunder',
        align: 'center center',
        recent: false,
        description:
          'Australia‘s Michelle Grace Hunder is amplifying the voices of female-identifying creatives in the music industry.',
        link: 'https://www.pixsy.com/featured-artists/michelle-hunder-stolen-pictures/',
      },
      {
        prefix: 'koudis',
        profile: require('./profiles/koudis.jpg'),
        name: 'Nick Koudis',
        align: 'top left',
        recent: false,
        description:
          'Award winning photographer Nick Koudis specialises in humorous conceptual images.',
        link: 'https://www.pixsy.com/featured-artists/nick-koudis/',
      },
      {
        prefix: 'swann',
        profile: require('./profiles/swann.jpg'),
        name: 'Emma Swann',
        align: 'bottom center',
        recent: false,
        description:
          'Founding DIY Magazine whilst still at uni, Emma Swann started a career that allowed her to pursue her two great passions, music and photography.',
        link: 'https://www.pixsy.com/featured-artists/emma-swann/',
      },
      {
        prefix: 'krieger',
        profile: require('./profiles/krieger.jpg'),
        name: 'Salem Krieger',
        align: 'unset',
        recent: false,
        description:
          'Krieger’s work focuses on portrait, corporate portraiture, documentary, and architectural photography. When not working for clients he is involved with personal art projects.',
        link: 'https://www.pixsy.com/featured-artists/salem-krieger/',
      },
      {
        prefix: 'leventi_a',
        profile: require('./profiles/leventi.jpg'),
        name: 'David Leventi',
        align: 'bottom center',
        recent: false,
        description:
          'As the son of two architects, his work is influenced by the built environments and organizations seen in the field of design.',
        link: 'https://www.pixsy.com/featured-artists/david-leventi/',
      },
      {
        prefix: 'leventi_b',
        profile: require('./profiles/leventi.jpg'),
        name: 'David Leventi',
        align: 'bottom center',
        recent: false,
        description:
          'As the son of two architects, his work is influenced by the built environments and organizations seen in the field of design.',
        link: 'https://www.pixsy.com/featured-artists/david-leventi/',
      },
      {
        prefix: 'luka',
        profile: require('./profiles/luka.jpg'),
        name: 'Luka Esenko',
        align: 'center center',
        recent: true,
        description:
          "St Thomas is the most picturesque of Slovenia's numerous hilltop churches.",
        link: 'https://www.pixsy.com/featured-artists/luka-esenko/',
      },
    ]

    const favorMostRecent = Math.random() > 0.75
    const storiesToConsider = stories.filter((s) => s.recent === favorMostRecent)
    const storyIndex = Math.floor(storiesToConsider.length * Math.random())
    this._selectedImage = storiesToConsider[storyIndex]
  }

  render() {
    const { name, link, align, prefix, profile, description } =
      this._selectedImage

    const href =
      link +
      '?utm_source=webapp&utm_medium=login&utm_campaign=featured_photographer&utm_content=' +
      slugify(name, { lower: true, replacement: '_' })

    return (
      <Background align={align} prefix={prefix}>
        {this.props.children}
        <a href={href} target="_blank">
          <div className={container}>
            <div className={header}>
              <div
                className={photographerImage}
                style={{ backgroundImage: `url( "${profile}")` }}
              />
              <div>
                <span>Image by</span>
                <h4>{name}</h4>
              </div>
            </div>
            <div className={body}>
              <p>
                {description} <b>Read more</b>
              </p>
            </div>
          </div>
        </a>
      </Background>
    )
  }
}

export default RandomStory

const Background = styled.div`
  width: 100%;
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  background-position: ${({ align }) => align};

  ${({ prefix }) => `

    background-image: url("/backgrounds/${prefix}_480.jpg");
    
    ${[
      [481, 768],
      [769, 992],
      [993, 1440],
      [1440, 1920],
    ]
      .map(
        ([breakpoint, resolution]) => `
        @media screen and (min-width: ${breakpoint}px) {
          background-image: url("/backgrounds/${prefix}_${resolution}.jpg");
        }`
      )
      .join('\n')}
  `}
`

const container = css`
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 0 0 20px 25px;
  max-width: 350px;
  color: #fff;
  z-index: 1;
  background: linear-gradient(25deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) 50%);

  /* start of large tablet styles */
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    padding-bottom: 40px;
  }

  p {
    margin: 0;
  }

  span {
    margin-top: -2px;
    font-size: 1em;
    vertical-align: top;
    text-shadow: 0px 0px 4px #000;
  }

  h4 {
    margin: 0;
    font-size: 1.3em;
    font-weight: bold;
    color: #fff;
    vertical-align: top;
    text-shadow: 0px 0px 4px #000;
  }

  b:hover {
    text-decoration: underline;
  }
`

const body = css`
  text-align: left;
  text-shadow: 0px 0px 4px #000;
`

const header = css`
  text-align: left;
  font-size: 0.9em;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const photographerImage = css`
  width: 70px;
  height: 70px;
  background-size: 70px 70px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: #008aab;
  background-position: center;
  border: 2px solid #fff;
  float: left;
`
