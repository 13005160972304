import { produce } from 'immer'
import getIn from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import moment from 'moment'
import { ImageSchema } from 'pixsy-schema/case/ImageSchema'
import { MatchSchema } from 'pixsy-schema/case/MatchSchema'
import { SUBMISSION_SOURCES } from 'pixsy-schema/case/SubmissionSchema'
import { QString } from 'pixsy-schema/core'
import urijs from 'urijs'
import randID from 'uuid/v4'
import {
  ARRAY_STAGES,
  STAGES,
  STAGE_INFO_STATUS,
  STAGE_OTHER_STATUS,
  STAGE_SCHEMAS,
  GA_STAGE_VIEWS,
} from './CaseConstants'

/**
 * @typedef {import('./CaseSubmission').ImageValues} ImageObject
 * @typedef {import('./CaseSubmission').MatchValues} MatchObject
 * @typedef {import('./CaseSubmission').SubmissionValues} SubmissionObject
 * @typedef {import('./CaseSubmission').FileUploader} FileUploader
 * @typedef {import('./CaseSubmission').CtrBeforeFileAdded} CtrBeforeFileAdded
 * @typedef {import('./CaseSubmission').CtrMethodFile} CtrMethodFile
 * @typedef {import('./CaseSubmission').FileValues} FileValues
 * @typedef {import('./CaseSubmission').IProps} IProps
 * @typedef {import('./CaseSubmission').IState} IState
 * @typedef {import('./CaseSubmission').IAsyncValidationStatus} IAsyncValidationStatus
 * @typedef {import('./CaseSubmission').IAsyncValidationErrors} IAsyncValidationErrors
 * @typedef {import('./CaseSubmission').IAsyncValidateState} IAsyncValidateState
 * @typedef {import('./CaseSubmission').SearchImageCacheEntry} SearchImageCacheEntry
 * @typedef {import('./CaseSubmission').IQuerySearchImage} IQuerySearchImage
 * @typedef {Array<ImageObject>} ImagesArray
 * @typedef {Array<MatchObject>} MatchesArray
 */

const REGEXP_MATCH = /matches\[([0-9])+\]/
const REGEXP_IMAGE = /images\[([0-9])+\]/
const REGEXP_MATCH_ORIGIN_URL = /matches\[[0-9]+\].origin.url/
const REGEXP_MATCH_URL = /matches\[[0-9]+\].url/
const REGEXP_IMAGE_URL = /images\[[0-9]+\].url/
const REGEXP_IMAGE_ORIGIN_URL = /images\[[0-9]+\].origin.url/
const REGEXP_IMAGE_PUBLISHED_URL = /images\[[0-9]+\].origin.url/
const REGEXP_IMAGE_FIRST_PUBLISHED_URL = /images\[[0-9]+\].licensing.first_published.place/
const REGEXP_RESOLVABLE = {
  matchURL: REGEXP_MATCH_URL.test.bind(REGEXP_MATCH_URL),
  matchOriginUrl: REGEXP_MATCH_ORIGIN_URL.test.bind(REGEXP_MATCH_ORIGIN_URL),
  imageUrl: REGEXP_IMAGE_URL.test.bind(REGEXP_IMAGE_URL),
  imageOriginUrl: REGEXP_IMAGE_ORIGIN_URL.test.bind(REGEXP_IMAGE_ORIGIN_URL),
  imagePublishedUrl: REGEXP_IMAGE_PUBLISHED_URL.test.bind(REGEXP_IMAGE_PUBLISHED_URL),
  imageFirstPublisedUrl: REGEXP_IMAGE_FIRST_PUBLISHED_URL.test.bind(REGEXP_IMAGE_FIRST_PUBLISHED_URL),
}
const REGEXP_RESOLVABLE_KEYS = Object.keys(REGEXP_RESOLVABLE)
const CASE_NAME_EMPTY = 'New Case Submission'
const CASE_NAME_VALID = 'Case Submission {ref}'
const IMAGES_SEARCH_QUERY_SIZE = 8
const LIMIT_SEARCH_RESULT_CACHE = 20 // number of max entries in cache

/**
 * - Submission Container initial State
 * @param {Readonly<IProps>} props
 * @returns {Readonly<IState>}
 */
export const getInitialState = (props) => {
  const { values, case: caze } = props
  const { images } = values

  const isDraftOrChangesRequested = values.__DRAFT__ || (caze.cm && caze.cm.changeRequested)

  const isMatchSubmission = values.source === SUBMISSION_SOURCES.MATCH
  const isExternalSubmission = values.source === SUBMISSION_SOURCES.EXTERNAL
  const domainName = getDomainName(props)
  const caseStatus = getCaseTitle(props)
  const selectedImage = images.length ? 0 : null
  const editableImages = images.map((i) => i.uuid)
  const editableImagesWithIds = images.filter((i) => !!i._id).map((i) => i._id)
  const stagesCompleted = {}
  const imagesCompleted = {}
  const stageActive = isDraftOrChangesRequested ? STAGES.OVERVIEW : STAGES.SUCCESS
  const asyncResolvableState = getAsyncResolvableState(props)
  const caseTitle = getCaseTitle(props)
  const lastUpdated = getIn(props, ['case', 'lastUpdated'])

  // Dialogs
  const confirmImageRemovalIndex = null
  const confirmImageRemovalUUID = null

  let excludeStages = [
    /*STAGES.PROFILE */
  ]
  let includeStages = []

  // if (isMatchSubmission) excludeStages.push(STAGES.VALIDATION, STAGES.IMPORT)

  if (isDraftOrChangesRequested) {
    excludeStages.push(STAGES.SUCCESS)
  } else {
    excludeStages.push(STAGES.REVIEW)
  }

  if (isMatchSubmission && getIn(caze, 'cm.changeRequested') !== true) {
    excludeStages.push(STAGES.IMPORT)
  }

  let stages = ARRAY_STAGES.filter((s) => !excludeStages.includes(s))

  includeStages = [...stages]

  if (isEmpty(images)) {
    stages = stages.filter((s) => s !== STAGES.IMAGES && s !== STAGES.VALIDATION)
  }

  const nextState = {
    asyncIgnoredErrors: [],
    asyncResolvableState,
    caseStatus,
    caseTitle,
    confirmImageRemovalIndex,
    confirmImageRemovalUUID,
    domainName,
    editableImages,
    editableImagesWithIds,
    excludeStages,
    imagesCompleted,
    includeStages,
    isExternalSubmission,
    isMatchSubmission,
    lastUpdated,
    searchImages: [],
    searchImagesCache: [],
    searchReachLimit: false,
    selectedImage,
    stageActive,
    stages,
    stagesCompleted,
    fullStageValidation: '',
    hasCaseSavedOnce: false,
    showImageOnPageDialog: false,
    matchWithImageMissingOnPage: null,
  }

  return nextState
}

/**
 * Display caption for new case or editing case with reference number
 * @param {IProps} props
 * @returns {string}
 */
export const getCaseTitle = (props) => {
  const { caseId, case: caze } = props

  if (!caseId) return CASE_NAME_EMPTY
  if (isPlainObject(caze) && isString(caze.reference)) {
    return CASE_NAME_VALID.replace('{ref}', caze.reference)
  }

  return CASE_NAME_EMPTY
}

/**
 * - Compute next uncompleted stage ('Continue' button)
 * - If all stages completed, then computes next completed stage
 * @param {IState} state
 * @returns {string}
 */
export const getNextStage = (state, dontFallInPossibleEternalLoop) => {
  const { stageActive, stages, includeStages } = state

  let nextStage = stageActive
  let indxStage = stages.indexOf(stageActive)

  // Why this happens?
  // For instance, if Profile has been saved successfully, `PROFILE` stage is removed from stages
  // But the user may still be in the Profile stage
  if (!~indxStage) {
    if (dontFallInPossibleEternalLoop) return stageActive

    const prevStages = includeStages.filter((s) => stages.includes(s) || s === stageActive)

    const anotherNextStage = getNextStage({ ...state, stages: prevStages }, true)

    return anotherNextStage
  }

  const nextAvailableStages = stages.slice(indxStage + 1).concat(stages.slice(0, indxStage))

  nextStage = nextAvailableStages.find((s) => s !== STAGES.OVERVIEW)

  if (!nextStage || nextStage === stageActive) return stageActive

  return nextStage
}

/**
 * Get domain name from:
 *
 * - cluster: if has domain.host
 * - match: gets the most commonly set domain among all matches' origin.url
 *
 * @param {IProps} props
 * @returns {string | null}
 */
export const getDomainName = (props) => {
  const { cluster, values } = props

  let matches

  if (values.source === SUBMISSION_SOURCES.MATCH && isPlainObject(cluster) && Array.isArray(cluster.matches)) {
    if (isPlainObject(cluster.domain) && isString(cluster.domain.host)) {
      return cluster.domain.host
    }

    matches = cluster.matches
  } else {
    matches = values.matches
  }

  if (!matches.length) return null

  const commonDomains = new Map()

  matches.forEach((m) => {
    try {
      if (
        !QString.url()
          .required()
          .isValidSync(m.origin.url)
      )
        return

      const domain = urijs(m.origin.url).domain()

      if (domain) {
        const dcount = (commonDomains.get(domain) || 0) + 1
        commonDomains.set(domain, dcount)
      }
    } catch (e) {}
  })

  if (!commonDomains.size) return null

  const keyDomain = 0
  const keyCountr = 1

  const domainName = [...commonDomains].sort((a, b) => (a[keyCountr] > b[keyCountr] ? -1 : 1))[0][keyDomain]

  return domainName.toLowerCase().trim()
}

/**
 * Go to Next/Prev editable image
 * Editable is image whose status is ..:
 * - not uploading
 * - or uploadCompleted
 * - previously imported (loaded from cluster)
 * @param {IState} state
 * @param {IProps} props
 * @param {number} indexCount
 * @returns {Partial<IState>}
 */
export const gotoNextOrPrevEditableImage = (state, props, indexCount) => {
  const { selectedImage: selected, editableImages: editable } = state
  const { images } = props.values

  if (isEmpty(editable) || selected == null) return null

  const uuid = images[selected].uuid
  const indx = editable.indexOf(uuid)

  if (!~indx) return null

  const nextIndx = indx + indexCount
  const nextuuid = editable[nextIndx >= editable.length ? 0 : nextIndx < 0 ? editable.length - 1 : nextIndx]

  const nextSelected = images.findIndex((i) => i.uuid === nextuuid)

  if (!~nextSelected) return null

  return { selectedImage: nextSelected, fullStageValidation: '' }
}

/**
 * Select image only if editable
 * - not uploading
 * - or is uploadCompleted
 * - previously imported (loaded from cluster)
 * @param {IState} state
 * @param {IProps} props
 * @param {number} indexCount
 * @returns {Partial<IState>}
 */
export const gotoEditableImage = (state, props, index) => {
  const { selectedImage: selected, editableImages: editable } = state
  const { images } = props.values

  if (isEmpty(editable) || selected == null) return null

  const image = images[index]

  if (!isPlainObject(image)) return null

  const uniqueIdentifier = image.uuid
  const canImageSelected = editable.includes(uniqueIdentifier)

  if (!canImageSelected) return null

  return { selectedImage: index }
}

/**
 * Get new case name into state
 * @param {Readonly<IProps>} props
 * @param {Readonly<IState>} prevState
 * @returns {(nextState: Readonly<Partial<IState>>) => Readonly<Partial<IState>>}
 */
export const getDerivedStateForCaseName = (props, prevState) => (nextState) => {
  const { caseTitle: prevCaseTitle } = prevState

  const caseTitle = getCaseTitle(props)

  if (caseTitle === prevCaseTitle) return nextState

  // return { ...nextState, caseTitle }
  return produce(nextState, (draft) => void (draft.caseTitle = caseTitle))
}

/**
 * Get domain name into state
 * @param {Readonly<IProps>} props
 * @param {Readonly<IState>} prevState
 * @returns {(nextState: Readonly<Partial<IState>>) => Readonly<Partial<IState>>}
 */
export const getDerivedStateForDomainName = (props, prevState) => (nextState) => {
  const { domainName: prevDomainName } = prevState
  const domainName = getDomainName(props)

  if (prevDomainName === domainName) return nextState

  // return { ...nextState, domainName }
  return produce(nextState, (draft) => void (draft.domainName = domainName))
}

/**
 * Get new case status into state if domain name has changed
 * @param {Readonly<IProps>} props
 * @param {Readonly<IState>} prevState
 * @returns {(nextState: Readonly<Partial<IState>>) => Readonly<Partial<IState>>}
 */
export const getDerivedStateForCaseStatus = (props, prevState) => (nextState) => {
  const { caseStatus: prevCaseStatus } = prevState
  const caseStatus = getCaseTitle(props)

  if (prevCaseStatus === caseStatus) return nextState

  // return { ...nextState, caseStatus }
  return produce(nextState, (draft) => void (draft.caseStatus = caseStatus))
}

/**
 * Gets editable images into state
 * - Editable images are the ones previously uploaded
 * - Or images imported successfully (have file.uploadComplete = true, and has being assigned a image.url)
 * @param {Readonly<IProps>} props
 * @param {Readonly<IState>} prevState
 * @returns {(nextState: Readonly<Partial<IState>>) => Readonly<Partial<IState>>}
 */
export const getDerivedStateForEditableImages = (props, prevState) => (nextState) => {
  const { values } = props
  const { images } = values
  const { selectedImage, /*editableImages,*/ includeStages } = prevState

  // if (values.source === SUBMISSION_SOURCES.MATCH) return nextState

  const nextEditableImages = []
  const nextEditableImagesWithIds = []

  let nextSelectedImage = selectedImage

  const len = images.length

  for (let i = 0; i < len; i++) {
    const img = images[i]
    const uuid = (isPlainObject(img.file) && img.file.uploadComplete) || img.file == null ? img.uuid : null
    if (uuid) {
      if (nextSelectedImage == null) nextSelectedImage = i
      nextEditableImages.push(uuid)
    }
    if (isString(img._id) && img._id) {
      nextEditableImagesWithIds.push(img._id)
    }
  }

  return produce(nextState, (draft) => {
    draft.selectedImage = nextSelectedImage
    draft.stages = !isEmpty(nextEditableImages)
      ? includeStages
      : includeStages.filter((s) => s !== STAGES.IMAGES && s !== STAGES.VALIDATION)
    if (
      isEmpty(nextEditableImages) &&
      (prevState.stageActive === STAGES.IMAGES || prevState.stageActive === STAGES.VALIDATION)
    ) {
      draft.stageActive = STAGES.OVERVIEW
    }
    draft.editableImages = nextEditableImages
    draft.editableImagesWithIds = nextEditableImagesWithIds
  })
}

/**
 * Checks which section is complete
 * - If a section has a schema, runs the schema against values
 * @param {Readonly<IProps>} props
 * @param {Readonly<IState>} prevState
 * @returns {(nextState: Readonly<Partial<IState>>) => Readonly<Partial<IState>>}
 */
export const getDerivedStateForSectionValidation = (props, prevState) => (nextState) => {
  return produce(nextState, (draft) => {
    const asyncResolvableState = getAsyncResolvableState(props)

    draft.asyncResolvableState = asyncResolvableState
    draft.stagesCompleted = {}

    for (const stage of ARRAY_STAGES) {
      const StageSchema = STAGE_SCHEMAS[stage]

      if (stage !== STAGES.PROFILE && stage !== STAGES.VALIDATION && StageSchema) {
        let isNextStageValid = StageSchema.isValidSync(props.values, {
          context: {
            ...props.context,
            values: props.values,
          },
        })

        if (stage === STAGES.IMAGES) {
          isNextStageValid = asyncResolvableState.isOkay && isNextStageValid
          // run sync checks for matches (not included in the asyncResolvableState)
          // isNextStageValid =
          // MatchSchema.isValidSync(props.values) && isNextStageValid
          // console.log('Third:', isNextStageValid, props.values)
        }

        draft.stagesCompleted[stage] = isNextStageValid
      } else if (stage === STAGES.VALIDATION) {
        draft.stagesCompleted[stage] = asyncResolvableState.isOkay

        if (asyncResolvableState.isOkay) {
          draft.stages = (draft.stages || prevState.stages).filter((s) => s !== STAGES.VALIDATION)
        }
      } else if (stage === STAGES.PROFILE && StageSchema) {
        const isProfileValid = StageSchema.isValidSync(props.user.details, {
          context: props.context,
        })

        if (isProfileValid) {
          draft.stages = (draft.stages || prevState.stages).filter((s) => s !== STAGES.PROFILE)
        }

        draft.stagesCompleted[stage] = isProfileValid
      } else {
        draft.stagesCompleted[stage] = true
      }
    }
  })
}

/**
 * Get images completed (no errors) into state
 * - use RegExp to filter errors for a specific match / image using its index
 * @param {Readonly<IProps>} props
 * @param {Readonly<IState>} prevState
 * @returns {(nextState: Readonly<Partial<IState>>) => Readonly<Partial<IState>>}
 */
export const getDerivedStateForImagesCompleted = (props, prevState) => (nextState) => {
  const { errors, values } = props

  const errorKeys = Object.keys(errors).filter((k) => REGEXP_IMAGE.test(k) || REGEXP_MATCH.test(k))

  return produce(nextState, (draft) => {
    draft.imagesCompleted = {}

    for (const i in values.images) {
      const imageKey = `images[${i}]`
      const matchKey = `matches[${i}]`
      const hasError = errorKeys.some((k) => {
        const isImageErr = k.startsWith(imageKey)
        const isMatchErr = k.startsWith(matchKey)

        return isImageErr || isMatchErr
      })

      draft.imagesCompleted[i] = !hasError
    }
  })
}

/**
 * Get the status of the async checks, if running or has errors
 * @param {Readonly<IProps>} props
 * @returns {IAsyncValidateState}
 */
export const getAsyncResolvableState = (props) => {
  const { validating, errors } = props

  /** @type {IAsyncValidateState} */
  const status = {
    status: {},
    errors: {},
    isOkay: true,
  }

  const errorKeys = Object.keys(errors)
  const validKeys = Object.keys(validating)

  const onlyAsync = (err) => isPlainObject(err.message) && err.message.asyncError
  const find = (test, o) => (key) => (test(key) ? onlyAsync(o[key]) : false)

  for (const key of REGEXP_RESOLVABLE_KEYS) {
    const test = REGEXP_RESOLVABLE[key]
    const errorPath = errorKeys.find(find(test, errors))
    const valdValue = validKeys.some(find(test, validating))

    status.status[key] = valdValue
    status.errors[key] = errors[errorPath] || null

    if (status.isOkay && (status.status[key] || status.errors[key])) {
      status.isOkay = false
    }
  }

  return status
}

export const prefixMimeTypes = (arrMimeTypes = []) => {
  return arrMimeTypes.map((v) => (v.endsWith('/') ? `${v}*` : v))
}

/**
 * Util
 * - Common func to define Uppy options for all Pixsy uploaders
 */
export const makeUppyOptions = ({
  source,
  bucket,
  server,
  user,
  policy,
  mime,
  // limit,
}) => {
  return {
    user,
    bucket,
    server,
    policy,
    source,
    limit: 3,
    uppyOptions: {
      // debug: true,
      id: source,
      autoProceed: true,
      restrictions: {
        maxFileSize: 52428800, // In bytes per file (50MB salesforce limit)
        maxNumberOfFiles: 15, // In the dialog
        allowedFileTypes: prefixMimeTypes(mime),
      },
    },
  }
}

/**
 * GENERAL ATTACHMENTS
 * Before file is added to Uppy
 * - Prevent duplicates
 * - Set max limit of allowed uploads
 *
 * @TODO - maybe display a nice notification to the user when file is duplicated and prevented from being imported
 * @param {number} limit
 * @return {(args: { getProps: () => IProps, u: FileUploader }) => void}
 */
export const handleBeforeGeneralFileAdded = (limit) => (args) => {
  const { getProps, u } = args
  /**
   * @type {CtrBeforeFileAdded}
   */
  const event = ({ cancelFile, nextFile }) => {
    const { values } = getProps()
    const files = values.__GENERAL_ATTACHMENTS__
    const fileExists = files.find((f) => f.fileName === nextFile.fileName)

    if (files.length >= limit || fileExists) return cancelFile()
  }
  u.onBeforeFileAdded(event)
  return args
}

/**
 * GENERAL ATTACHMENTS
 * Add Uppy files to general attachments `values`
 * @type {(args: { getProps: () => IProps, u: FileUploader }) => void}
 */
export const handleAddGeneralFiles = (args) => {
  const { getProps, u } = args
  /**
   * @type {CtrMethodFile}
   */
  const event = (file) => {
    const { values, setFieldValue } = getProps()
    const nextFiles = [...values.__GENERAL_ATTACHMENTS__, file]

    setFieldValue('__GENERAL_ATTACHMENTS__', nextFiles)
  }
  u.onFileAdded(event)
  return args
}

/**
 * When Uppy file is updated, replace the old file values with next file
 * @type {(args: { getProps: () => IProps, u: FileUploader }) => void}
 */
export const handleChangeGeneralFiles = (args) => {
  const { getProps, u } = args
  /**
   * @type {CtrMethodFile}
   */
  const event = (nextFile) => {
    const { values, setFieldValue } = getProps()
    const files = values.__GENERAL_ATTACHMENTS__
    const nextFiles = files.map((f) => ((f.id === nextFile.id ? nextFile : f)))

    setFieldValue('__GENERAL_ATTACHMENTS__', nextFiles)
  }
  u.onFileChange(event)
  return args
}

/**
 * Use unique id assigned by the form to easily identify images to find a specific image
 * @param {string} uuid
 * @param {IProps} props
 */
export const findImageByUUID = (uuid, props) => {
  const { values } = props
  const index = values.images.findIndex((i) => i.uuid === uuid)
  const image = values.images[index]

  return { index, image }
}

/**
 * LICENSING & REGISTRATION FILES
 * Before file is added to Uppy
 * - Prevent duplicates
 * - Set max limit of allowed uploads
 *
 * @TODO - maybe display a nice notification to the user when this happens
 * @param {number} limit
 * @return {(args: { getImage: () => { index: number, image: ImageObject }, getFiles: (image: ImageObject) => FileValues[], getProps: () => IProps, getName: (i) => string, u: FileUploader }) => void}
 */
export const handleBeforeImageFileAdded = (limit) => (args) => {
  const { getImage, getFiles, u } = args
  /**
   * @type {CtrBeforeFileAdded}
   */
  const event = ({ cancelFile, nextFile }) => {
    const { image } = getImage()

    if (!image) return

    const files = getFiles(image)
    const exist = files.find((f) => f.fileName === nextFile.fileName)

    if (files.length >= limit || exist) return cancelFile()
  }
  u.onBeforeFileAdded(event)
  return args
}

/**
 * LICENSING & REGISTRATION FILES
 * Add Uppy files to image files
 * @type {(args: { getImage: () => { index: number, image: ImageObject }, getFiles: (image: ImageObject) => FileValues[], getProps: () => IProps, getName: (i) => string, u: FileUploader }) => void}
 */
export const handleAddImageFiles = (args) => {
  const { getImage, getFiles, getName, getProps, u } = args
  /**
   * @type {CtrMethodFile}
   */
  const event = (file) => {
    const { setFieldValue } = getProps()
    const { index, image } = getImage()

    if (!image) return

    setFieldValue(getName(index), [...getFiles(image), file])
  }
  u.onFileAdded(event)
  return args
}

/**
 * LICENSING & REGISTRATION FILES
 * Add Uppy files to image files
 * @type {(args: { getImage: () => { index: number, image: ImageObject }, getFiles: (image: ImageObject) => FileValues[], getProps: () => IProps, getName: (i) => string, u: FileUploader }) => void}
 */
export const handleChangeImageFiles = (args) => {
  const { getImage, getFiles, getName, getProps, u } = args
  /**
   * @type {CtrMethodFile}
   */
  const event = (file) => {
    const { setFieldValue } = getProps()
    const { index, image } = getImage()

    if (!image) return

    setFieldValue(
      getName(index),
      getFiles(image).map((f) => ((f.id === file.id ? file : f)))
    )
  }
  u.onFileChange(event)
  return args
}

/**
 * IMAGE IMPORTS
 * Before file is added to Uppy
 * - Prevent duplicates
 * - Set max limit of allowed uploads
 *
 * @TODO - maybe display a nice notification to the user when this happens
 * @param {number} limit
 * @return {(args: { getProps: () => IProps, u: FileUploader }) => void}
 */
export const handleBeforeImageImportFileAdded = (limit) => (args) => {
  const { getProps, u } = args
  /**
   * @type {CtrBeforeFileAdded}
   */
  const event = ({ cancelFile, nextFile }) => {
    const { values } = getProps()
    const { images } = values
    const exist = images.find((f) => isPlainObject(f.file) && f.file.fileName === nextFile.fileName)

    if (images.length >= limit || exist) return cancelFile()
  }
  u.onBeforeFileAdded(event)

  return args
}

/**
 * IMAGE IMPORTS
 * Add Uppy files as new images to values.images
 * @type {(args: { getProps: () => IProps, u: FileUploader }) => void}
 */
export const handleAddImageImportFiles = (args) => {
  const { getProps, u } = args
  /**
   * @type {CtrMethodFile}
   */
  const event = (file) => {
    const { values, setFormValues } = getProps()
    const image = ImageSchema.default()
    const match = MatchSchema.default()

    image.uuid = randID()
    image.file = file
    image.title = image.file.fileName

    match.uuid = image.uuid

    const nextImages = [...values.images, image]
    const nextMatches = [...values.matches, match]

    setFormValues({
      images: nextImages,
      matches: nextMatches,
    })
  }
  u.onFileAdded(event)

  return args
}

/**
 * IMAGE IMPORTS
 * On file change, updates images values
 * @type {(args: { getProps: () => IProps, u: FileUploader }) => void}
 */
export const handleChangeImageImportFiles = (args) => {
  const { getProps, u } = args
  /**
   * @type {CtrMethodFile}
   */
  const event = (file) => {
    const { values, setFieldValue } = getProps()
    const nextImages = values.images.map((img) => {
      if (isPlainObject(img.file) && img.file.id === file.id) {
        const i = { ...img }
        i.file = file
        i.title = i.file.fileName
        i.url = i.file.url
        return i
      }
      return img
    })

    setFieldValue('images', nextImages)
  }
  u.onFileChange(event)

  return args
}

/**
 * When new / existing case is saved, get from response images / matches ids
 * and assign them to the ones in the form
 *
 * - Use unique identifiers (uuids) to find which images were saved to assign ids
 * - User may have had removed existing or added new images while saving is in progress
 *
 * @param {string[]} uuids
 * @param {string[]} imagesIds
 * @param {string[]} matchesIds
 * @param {ImagesArray} images
 * @param {MatchesArray} matches
 * @returns { images: ImagesArray, matches: MatchesArray }
 */
export const assignIdsToImagesMatches = (uuids, imagesIds, matchesIds, images, matches) => {
  return produce({ images, matches }, (draft) => {
    draft.images.forEach((image, index) => {
      const found = uuids.indexOf(image.uuid)

      if (~found) {
        const match = matches[index]
        image._id = imagesIds[found]._id || imagesIds[found]
        match._id = matchesIds[found]
      }
    })
  })
}

/**
 * @param {IState} state
 * @param {IProps} props
 * @returns {string | undefined}
 */
export const getInformationStatus = (state, props) => {
  props.authLoading
  props.authUpdatingUser
  props.authVerifyEmailLoading
  if (props.isFormSubmitting) return STAGE_OTHER_STATUS.SAVING

  if (props.authUpdatingUser) return STAGE_OTHER_STATUS.AUTH_UPDATING_USER
  if (props.authVerifyEmailLoading) return STAGE_OTHER_STATUS.AUTH_EMAIL_SEND
  if (props.authLoading) return STAGE_OTHER_STATUS.AUTH_LOAD_USER

  const { stagesCompleted, stageActive, lastUpdated } = state
  const { validating } = props

  const complete = stagesCompleted[stageActive]

  if (stageActive === STAGES.VALIDATION && !isEmpty(validating)) {
    return STAGE_OTHER_STATUS.CHECKS_STAGE_RUNNING
  }

  if (!isEmpty(validating)) {
    return STAGE_OTHER_STATUS.CHECKS_RUNNING
  }

  if (!complete && lastUpdated && moment(lastUpdated).isValid()) {
    const timeAgo = moment(lastUpdated).fromNow()

    return {
      text: STAGE_OTHER_STATUS.SAVED.text.replace('{time}', timeAgo),
      progress: STAGE_OTHER_STATUS.SAVED.progress,
    }
  }

  const stageInfoStatus = STAGE_INFO_STATUS[getNextStage(state)]

  if (complete && isString(stageInfoStatus)) {
    return { text: stageInfoStatus, progress: false }
  }

  return null
}

/**
 * - Get result from request into state
 * @param {IProps} props
 * @param {ImageObject[]} images
 * @returns {ImageObject[]}
 */
export const assignUUIDsToExistingImages = (props, images) => {
  const {
    values: { images: existingImages },
    setFormValues,
  } = props

  let updatedImagesResult = []

  const imagesUpdatedWithIds = produce(existingImages, (imgs) => {
    updatedImagesResult = images.map((image) => {
      const existIndex = imgs.findIndex((i) => {
        return i._id === image._id || i.url === image.url
      })

      if (~existIndex) {
        const exist = imgs[existIndex]
        if (exist.url === image.url && !exist._id) {
          exist._id = image._id
        }

        const nextSearchImage = ImageSchema.cast(image, {
          context: { values: {} },
        })
        nextSearchImage.uuid = exist.uuid
      }

      return image
    })
  })

  setFormValues({ images: imagesUpdatedWithIds })

  return updatedImagesResult
}

/**
 * @param {IQuerySearchImage} args0
 * @returns {IQuerySearchImage}
 */
export const getVirtualImagesQuery = ({
  tags = [],
  page = 0,
  sort = '-tracked',
  pageSize = IMAGES_SEARCH_QUERY_SIZE,
}) => {
  return {
    tags,
    page,
    sort,
    pageSize,
  }
}

/**
 * - Get result from request into state
 * @param {object} res
 * @param {IState} state
 * @param {IProps} props
 * @param {string} text
 * @returns {Partial<IState>}
 */
export const getStateFromImagesSearchResult = (res, state, props, text) => {
  const {
    values: { images, __SEARCH_QUERY__ },
  } = props
  const { searchImagesCache } = state

  if (
    isPlainObject(res) &&
    isPlainObject(res.payload) &&
    isPlainObject(res.payload.entities) &&
    isPlainObject(res.payload.entities.images) &&
    isArray(res.payload.images)
  ) {
    const idsImages = res.payload.images
    const resImages = []
    const imagesObj = res.payload.entities.images

    const assignUUID = (arr) => (id) => {
      const img = ImageSchema.cast(imagesObj[id], {
        context: { values: {} },
      })
      const exist = images.find((i) => i._id === id)

      if (exist) {
        // Assign same unique identifier, this way we know image has been selected already
        // and allows for removing this image
        img.uuid = exist.uuid
      }

      if (isPlainObject(img)) arr.push(img)
    }

    idsImages.slice(0, IMAGES_SEARCH_QUERY_SIZE).forEach(assignUUID(resImages))

    const searchReachLimit = idsImages.length > IMAGES_SEARCH_QUERY_SIZE
    let nextSearchImagesCache = searchImagesCache

    const prevEntry = getSearchResultFromCache(state, text)
    const nextEntry = {
      input: String(text).trim(),
      searchImages: resImages,
      searchReachLimit,
    }

    if (prevEntry) {
      const prevEntryIndex = nextSearchImagesCache.findIndex((e) => {
        return String(e.input).trim() === String(text).trim()
      })

      if (~prevEntryIndex) {
        nextSearchImagesCache = nextSearchImagesCache
          .slice(0, prevEntryIndex)
          .concat(nextSearchImagesCache.slice(nextSearchImagesCache + 1))

        nextSearchImagesCache.unshift(nextEntry)
      }
    } else {
      nextSearchImagesCache = searchImagesCache.slice(0, LIMIT_SEARCH_RESULT_CACHE - 1)
      nextSearchImagesCache.unshift(nextEntry)
    }

    /**
     * If this happens, it means, user was probably typing something else
     * Only set the cache, another search query is probably in progress
     */
    if (text !== __SEARCH_QUERY__) {
      return {
        searchImagesCache: nextSearchImagesCache,
      }
    }

    return {
      searchImages: resImages,
      searchReachLimit,
      searchImagesCache: nextSearchImagesCache,
    }
  }

  const prevEntry = getSearchResultFromCache(state, text)

  if (prevEntry) {
    return {
      searchImages: prevEntry.searchImages,
      searchReachLimit: prevEntry.searchReachLimit,
    }
  }

  return { searchImages: [], searchReachLimit: false }
}

/**
 * - Find a previously made request in cache and returns it
 * @param {IState} state
 * @param {string} text
 * @returns {SearchImageCacheEntry | undefined}
 */
export const getSearchResultFromCache = (state, text) => {
  const { searchImagesCache } = state

  return searchImagesCache.find((cache) => cache.input === String(text).trim())
}

/**
 * @param {IState} state
 * @param {IProps} props
 * @param {FileUploader} uploader
 * @param {string} uuid
 * @returns {Partial<IState>}
 */
export const removeImageFromSubmission = (state, props, uploader, uuid) => {
  const { index, image } = findImageByUUID(uuid, props)

  if (!image || !~index) return null

  const { setFormValues } = props

  const nextProps = produce(props, (draft) => {
    const { images, matches } = draft.values
    draft.values.images = images.slice(0, index).concat(images.slice(index + 1))
    draft.values.matches = matches.slice(0, index).concat(matches.slice(index + 1))
  })

  /**
   * If image has file, remove file from Uppy uploader
   * Otherwise, if same file is uploaded again, Uppy will prevent it
   * because it already exists internally in Uppy
   */
  if (isPlainObject(image.file) && isString(image.file.id)) {
    uploader.removeFile(image.file.id)
  }

  /**
   * If image was / is in search results, just remove uuid so it's not associated to any image
   * in `values.images`
   */
  const nextState = produce(state, (draft) => {
    draft.searchImages = draft.searchImages.map((i) => {
      if (i.uuid === uuid) {
        const img = ImageSchema.cast(i, { context: { values: {} } })
        img.uuid = null
        return img
      }
      return i
    })
    Object.assign(draft, getDerivedStateForEditableImages(nextProps, draft)({}))
  })

  const { images, matches } = nextProps.values

  setFormValues({ images, matches })

  return nextState
}

export const triggerGA = (stage, source) => {
  try {
    function recordPageView(vpv) {
      window.gtag('event', 'page_view', {
        page_path: vpv,
        event_callbacka(res) {
          console.info('pageview success: ', vpv)
          console.info(res)
        },
      })
    }
    const gaProperty = GA_STAGE_VIEWS[stage]
    if (gaProperty.additionalViews && gaProperty.additionalViews[source]) {
      recordPageView(gaProperty.additionalViews[source])
    }
    recordPageView(gaProperty.funnel)
  } catch (e) {
    if (window.PRODUCTION) {
      console.error(`Unable to invoke google-analytics method:`, e)
    }
  }
}
