import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import SelectableContext from './SelectableContext'

export default class SelectableItem extends Component {
  render() {
    return (
      <SelectableContext.Consumer>
        {this.renderInner}
      </SelectableContext.Consumer>
    )
  }

  createToggleHandler = fn => e => {
    fn(this.props.id)
  }

  renderInner = contextProps => {
    const { children, id } = this.props
    const {
      enabled,
      toggleSelection: toggleSelectionUnspecific,
      selection,
      isSelecting,
    } = contextProps

    const isSelected = enabled && selection.includes(id)
    const toggleSelectionSpecific = this.createToggleHandler(
      toggleSelectionUnspecific
    )

    return (
      <Selectable
        isSelected={isSelected}
        isSelecting={isSelecting}
        {...contextProps}
        toggleSelection={toggleSelectionSpecific}
      >
        {children}
      </Selectable>
    )
  }
}

class Selectable extends Component {
  state = {
    hovering: false,
  }

  handleMouseEnter = () => {
    this.setState({
      hovering: true,
    })
  }

  handleMouseLeave = () => {
    this.setState({
      hovering: false,
    })
  }

  render() {
    const {
      children,
      enabled,
      isSelected,
      isSelecting,
      isAtLimit,
      toggleSelection,
    } = this.props

    const { hovering } = this.state

    const isCheckBoxVisible = isSelecting || hovering

    const passToWrapperWhenEnabled = {
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    }

    const selectionCability = {
      onClick: toggleSelection,
    }

    return (
      <div
        css={cx(
          wrap,
          isSelecting && selectModeStyle,
          (isSelected || isAtLimit) && notAllowedStyle
        )}
        {...(enabled && passToWrapperWhenEnabled)}
        {...(isSelecting && selectionCability)}
      >
        <div
          css={cx(checkContainer, {
            [checkContainerVisible]: isCheckBoxVisible && !isAtLimit,
            [checkContainerActive]: isSelected,
            [checkContainerAtLimit]: isAtLimit,
          })}
          onClick={toggleSelection}
        >
          <CheckIcon />
        </div>
        {React.Children.only(children)}
      </div>
    )
  }
}

Selectable.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSelecting: PropTypes.bool.isRequired,
  isAtLimit: PropTypes.bool.isRequired,
}

const selectModeStyle = css`
  transform: scale(0.85);
  opacity: 0.65;
  &:hover {
    cursor: copy;
  }
`
const notAllowedStyle = css`
  transform: scale(1);
  opacity: 1;
  &:hover {
    cursor: not-allowed;
  }
`

const iconSize = css`
  width: 24px;
  height: 24px;
`

const checkContainer = css`
  height: 39px;
  width: 39px;
  border: 1px solid red;
  background-color: rgba(255, 255, 255, 0.82);
  position: absolute;
  z-index: 3;
  top: -15px;
  right: -15px;
  // margin-top: -15px;
  // margin-right: -15px; /* for Firefox */
  // margin-left: 15px; /* for Chrome */
  border-radius: 50%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(112, 112, 112, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.15s ease-in;
  transition: background-color 0.25s ease-in;
  &:hover {
    background-color: rgba(255, 255, 255, 0.92);
    transition: background-color 0.25s ease-in;
    transform: scale(1.08);
  }

  svg {
    transition: opacity 0.1s ease-in;
    opacity: 0;
  }
`

const checkContainerVisible = css`
  opacity: 1;
  transition: opacity 0.2s ease-out;
`

const checkContainerActive = css`
  background-color: #fff;
  opacity: 1;

  svg {
    opacity: 1;
    position: relative;
    bottom: -1px;
  }
`

const checkContainerAtLimit = css`
  pointer-events: none;
`

const wrap = css`
  height: 100%;
  user-select: none;
  position: relative;
`

const CheckIcon = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.09 19.716"
    css={iconSize}
  >
    <defs>
      <style>
        {`
      .cls-1-checkicon-Selectable {
        fill: none;
        stroke: #008aab;
        stroke-width: 4px;
      }`}
      </style>
    </defs>
    <path
      id="Path_660"
      data-name="Path 660"
      className="cls-1-checkicon-Selectable"
      d="M-21090.262-11630.928l6.791,6.152,15.541-15.541"
      transform="translate(21091.605 11641.73)"
    />
  </svg>
))
