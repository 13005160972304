import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { css, cx } from 'react-emotion'
import { ChooseBestImageSource, SexyThumbnail, SelectableContext } from 'common'
import * as Icons from '../Icons'
import { assembleClusterByIds } from '../../../../../redux/selectors'

class ActionsPanel extends Component {
  componentWillReceiveProps({ clusters: newClusters }) {
    if (
      this._panelThumbnails &&
      this.props.clusters.length !== newClusters.length
    ) {
      this._panelThumbnails.scrollLeft = 10e10
    }
  }

  render() {
    const { clusters, onIgnoreDialogOpen } = this.props

    return (
      <SelectableContext.Consumer>
        {({ isSelecting, clear }) => (
          <MergeContainer visible={isSelecting}>
            <div css={panelBody}>
              <div css={panelLeftPanel}>
                <div css={panelSelected}>
                  <div css={panelSelectedWrap}>
                    <div css={panelSelectedTop}>
                      {clusters.length}
                      {clusters.length === 1 ? ' Match' : ' Matches'}
                    </div>
                    <div css={panelSelectedBottom}>SELECTED</div>
                  </div>
                </div>
                <div
                  ref={c => (this._panelThumbnails = c)}
                  css={panelThumbnails}
                >
                  <div css={panelGrid}>
                    {clusters.map(cluster => (
                      <div css={panelGridImage} key={cluster._id}>
                        <ChooseBestImageSource.ForCluster cluster={cluster}>
                          {props => <SexyThumbnail {...props} />}
                        </ChooseBestImageSource.ForCluster>
                      </div>
                    ))}
                  </div>
                </div>
                <div css={panelClearImages}>
                  <a href="#" onClick={clear}>
                    clear selection
                  </a>
                </div>
              </div>
              <div css={panelRightPanel}>
                <div css={panelActionsLabel}>ACTION</div>
                <div css={panelActionsButtons}>
                  <ActionButton
                    icon={Icons.Ignore}
                    onClick={onIgnoreDialogOpen}
                    label="IGNORE"
                  />
                </div>
              </div>
            </div>
          </MergeContainer>
        )}
      </SelectableContext.Consumer>
    )
  }
}

export default connect((state, props) => ({
  clusters: assembleClusterByIds(state, props),
}))(ActionsPanel)

ActionsPanel.propTypes = {
  visible: PropTypes.bool,
  clusters: PropTypes.arrayOf(PropTypes.object),
  onIgnoreDialogOpen: PropTypes.func,
}

const ActionButton = ({
  icon: Icon,
  onClick = null,
  width = 56,
  disabled = false,
  label = '',
}) => (
  <a
    href={disabled ? '' : '#'}
    onClick={disabled ? null : onClick}
    style={{ width: `${width}px` }}
    css={cx(actionBtn, disabled ? disabledActionBtn : activeActionBtn)}
  >
    <Icon />
    <span>{label}</span>
  </a>
)

const actionBtn = css`
  color: #696969;
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 12px;

  > svg {
    height: 20px;
  }
`
const activeActionBtn = css`
  opacity: 0.6;
  outline: none !important;
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    color: inherit;
  }
`
const disabledActionBtn = css`
  opacity: 0.2;
  outline: none !important;
`

const MergeContainer = styled.div`
  height: 100px;
  background: white;
  position: fixed;
  bottom: 30px;
  z-index: 2;
  width: calc(100% - 170px); /*navbar plus 30px times 2 */
  left: 140px; /*navbar plus 30px*/
  @media (min-width: 1600px) {
    width: calc(90% - 170px); /*navbar plus 30px times 2 */
    left: calc(140px + 5%); /*navbar plus 30px*/
  }
  @media (min-width: 2000px) {
    width: calc(80% - 170px); /*navbar plus 30px times 2 */
    left: calc(140px + 10%); /*navbar plus 30px*/
  }
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: 0.1s opacity ease-in;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};

  /*@media (max-width: 1280px) {
    left: 251px;
  }*/
`
const panelBody = css`
  height: 100%;
  min-width: 100%;
  display: flex;
  padding: 0 20px;
`
const panelLeftPanel = css`
  display: inline-flex;
  flex-grow: 4;
  height: 100%;
  width: 50%;
  padding: 0 20px;
  @media (max-width: 996px) {
    display: none;
  }
`
const panelRightPanel = css`
  border-left: 1px solid #e2e2e2;
  @media (max-width: 996px) {
    border: none;
  }
  display: inline-flex;
  flex-grow: 1;
  padding: 0 20px;
`
const panelSelected = css`
  width: 120px;
  display: inline-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
`
const panelSelectedWrap = css`
  width: 120px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
  z-index: 1;
  :after {
    content: '';
    position: absolute;
    right: -25px;
    top: 0;
    width: 25px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }
`

const panelSelectedTop = css`
  font-weight: 600;
  letter-spacing: 0;
  font-size: 1.2em;

  color: #2e2e2e;
`
const panelSelectedBottom = css`
  letter-spacing: 2px;
  font-weight: 500;
  color: #2e2e2e;
`
const panelThumbnails = css`
  overflow-x: hidden;
  max-width: 70%;
`
const panelGrid = css`
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 4px;
`
const panelGridImage = css`
  max-height: 75px;
  width: 75px;
  padding-left: 10px;
`
const panelClearImages = css`
  align-items: center;
  display: inline-flex;
  padding: 0 20px;

  a {
    text-decoration: none;
    color: rgba(46, 46, 46, 0.3);
  }
  a:hover {
    text-decoration: underline;
  }
`

const panelActionsLabel = css`
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  color: #2e2e2e;
`

const panelActionsButtons = css`
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
  align-items: center;
  svg {
    text {
      font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
    }
  }
`
