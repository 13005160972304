import * as yup from 'yup'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isMatch from 'lodash/isMatch'
import urijs from 'urijs'
import COUNTRIES from 'pixsy-constants/countries.json'
import {
  QDefineConstEnums,
  QBoolean,
  QString,
  QDate,
  QEnumString,
  QNumber,
  QSchema,
  prefixConstEnumValues,
  objectWithKeysAsValues,
  QSchemaConditional,
} from '../core'
import { CURRENCIES, CREATIVE_COMMONS_OPTIONS } from 'pixsy-constants/index'
import moment from 'moment'
import getIn from 'lodash/get'
import { KNOCKOUT_NAMES } from './KnockoutSchema'

export const CC_ENUM = QDefineConstEnums(CREATIVE_COMMONS_OPTIONS)
export const CURRENCIES_ENUM = QDefineConstEnums(CURRENCIES)

const handleAsyncResult = (test, value, ignoredErrors) => (res) => {
  if (isPlainObject(res) && isArray(res.payload) && isArray(ignoredErrors)) {
    const isResolvable = res.payload.every((v) => v.resolvable)

    if (isResolvable) return true

    const isDomainStockError = res.payload.filter(
      (p) => p.data.type === 'domainIsNotStock'
    )

    // const isPublishedUrlOffline = res.payload.filter(
    //   p => p.data.type === 'matchPageOffline'
    // )

    // if (isDomainStockError.length === 0 && isPublishedUrlOffline.length === 0)
    if (isDomainStockError.length === 0) return true

    const nextPayload = res.payload.filter((e) => {
      if (isPlainObject(e) && isPlainObject(e.data) && isString(e.data.type)) {
        const ignoredError = ignoredErrors.find((i) => isMatch(i, e.data))

        if (ignoredError) return false

        return true
      }
      return false
    })

    if (isEmpty(nextPayload)) return true

    const [firstPayload] = nextPayload

    let errorTitle

    if (isDomainStockError.length > 0) {
      errorTitle =
        'We include this URL in all communications with the image user, and therefore require an online publication source that does not lead to external licensing sites.'
    } else {
      errorTitle = firstPayload.reason
    }

    const errorPayload = {
      message: {
        asyncError: true,
        title: errorTitle,
        alwaysShow: true,
      },
      path: test.path,
    }

    return test.createError(errorPayload)
  }
}

const handleAsyncError = (err) => {
  console.warn('[AsyncValidation][Err]', err)
  return true
}

export const LICENSING_KEYS = objectWithKeysAsValues({
  cc: 'cc',
  publicly_available: 'publicly_available',
  licensed_before: 'licensed_before',
  agreementsUnavailable: 'agreementsUnavailable',
  license: 'license',
  first_published: 'first_published',
  price: 'price',
  price_1y_online: 'price_1y_online',
  available_on_stock: 'available_on_stock',
})

export const LICENSING_FIRST_PUBLISHED_KEYS = objectWithKeysAsValues({
  date: 'date',
  place: 'place',
})
export const LICENSING_PRICE_KEYS = objectWithKeysAsValues({
  value: 'value',
  currency: 'currency',
})
export const LICENSING_ONE_YEAR_KEYS = objectWithKeysAsValues({
  value: 'value',
  currency: 'currency',
})

export const LICENSING_NAMES = {
  ...LICENSING_KEYS,
  [LICENSING_KEYS.first_published]: prefixConstEnumValues(
    LICENSING_KEYS.first_published,
    LICENSING_FIRST_PUBLISHED_KEYS
  ),
  [LICENSING_KEYS.price]: prefixConstEnumValues(
    LICENSING_KEYS.price,
    LICENSING_PRICE_KEYS
  ),
  [LICENSING_KEYS.price_1y_online]: prefixConstEnumValues(
    LICENSING_KEYS.price_1y_online,
    LICENSING_ONE_YEAR_KEYS
  ),
}

// prettier-ignore
export const LICENSING_LABEL = {
  [LICENSING_NAMES.cc]: `Has this image been available under a Creative Commons license in the past?`,
  [LICENSING_NAMES.license]: `What were the Creative Commons terms of the license?`,
  [LICENSING_NAMES.publicly_available]: `Is public pricing available for this image online?`,
  [LICENSING_NAMES.licensed_before]: `Have you ever sold a license for this image?`,
  [LICENSING_NAMES.agreementsUnavailable]: `I don't have a contract or licensing agreement available`,
  [LICENSING_NAMES.first_published.date]: `When and where did you first publish the image online?`, //`When did you first publish the image online?`,
  [LICENSING_NAMES.first_published.place]: `Where did you first publish the image online?`,
  [LICENSING_NAMES.price.value]: `At what price point?`,
  [LICENSING_NAMES.price.currency]: `At what price point?`,
  [LICENSING_NAMES.price_1y_online.value]: `What's the highest license fee sold for 1 year online use?`,
  [LICENSING_NAMES.price_1y_online.currency]: `What's the highest license fee sold for 1 year online use?`,
  [LICENSING_NAMES.available_on_stock]: 'Is this image currently available on any stock site?'
}

export const LICENSING_REQUIRED = {
  [LICENSING_NAMES.cc]: true,
  [LICENSING_NAMES.license]: true,
  [LICENSING_NAMES.publicly_available]: true,
  [LICENSING_NAMES.licensed_before]: true,
  [LICENSING_NAMES.agreementsUnavailable]: false,
  [LICENSING_NAMES.first_published.date]: true,
  [LICENSING_NAMES.first_published.place]: true,
  [LICENSING_NAMES.price.value]: true,
  [LICENSING_NAMES.price.currency]: true,
  [LICENSING_NAMES.price_1y_online.value]: false,
  [LICENSING_NAMES.price_1y_online.currency]: false,
  [LICENSING_NAMES.available_on_stock]: true,
}

// prettier-ignore
export const LICENSING_ONE_YEAR_TYPES = {
  [LICENSING_ONE_YEAR_KEYS.value]: QNumber.positive({ title: 'Minimum allowed value is 1' }),
  [LICENSING_ONE_YEAR_KEYS.currency]: QEnumString(CURRENCIES_ENUM).default(CURRENCIES_ENUM.USD),
}
export const LicensingOneYearSchema = QSchema.shape(LICENSING_ONE_YEAR_TYPES)
export const StockAvailableSchema = {
  [LICENSING_KEYS.available_on_stock]: QBoolean.required({
    title:
      'Please let us know if this image is currently available on a stock site.',
  }),
}

export const LICENSING_PRICE_TYPES = {
  [LICENSING_PRICE_KEYS.value]: QNumber.max(1e9, {
    title: `Maximum allowed value is ${Number(1e9).toLocaleString()}`,
  })
    .required({
      title: 'You must provide the public price point for this image online',
    })
    .test(
      'is-public-pricing-available',
      {
        title: 'Minimum allowed value is 1',
      },
      function(value) {
        const ctx = this.options.context
        if (isPlainObject(ctx) && isPlainObject(ctx.values)) {
          const [img] = this.path.split('.')
          const publicPricingAvailable = `${img}.licensing.publicly_available`
          const isPublicPricingAvailable = getIn(
            ctx.values,
            publicPricingAvailable
          )
          if (isPublicPricingAvailable && Number(value) < 1) {
            return false
          }
        }

        return true
      }
    )
    .test(
      'stock-price-gt-500',
      {
        title:
          'Pixsy is unable to pursue cases where the image is still available on stock sites at the price point you indicated.',
      },
      function(value) {
        return true

        /**
         * @TODO: As per the latest update from Kain, Temporarily holding
         * to block Case Submission in lieu of COVID-19
         *
         * Re-enable once approved by the business
         */
        const ctx = this.options.context
        if (isPlainObject(ctx) && isPlainObject(ctx.values)) {
          const [img] = this.path.split('.')
          const stockIncludedIndex = `${img}.licensing.available_on_stock`
          const isStockIncluded = getIn(ctx.values, stockIncludedIndex)
          if (isStockIncluded && value < 500) {
            return false
          }
        }

        return true
      }
    ),
  [LICENSING_PRICE_KEYS.currency]: QEnumString(CURRENCIES_ENUM)
    .required()
    .default(CURRENCIES_ENUM.USD),
}
export const LicensingPriceSchema = QSchema.shape(LICENSING_PRICE_TYPES)

export const LICENSING_FIRST_PUBLISHED_TYPES = {
  [LICENSING_FIRST_PUBLISHED_KEYS.date]: QDate.required({
    title: 'The publication date cannot be empty',
  }).test(
    'does-not-violate-laws-of-physics',
    {
      title:
        'The publication date can not precede the date the image was created',
      alwaysShow: true,
    },
    function(value) {
      const ctx = this.options.context
      if (ctx && ctx.values && ctx.values.images && moment(value).isValid()) {
        const [path] = this.path.split('.')
        const dateTakenIndex = `${path}.date_taken`
        const dateTaken = getIn(ctx.values, dateTakenIndex)
        return moment(value).startOf('day') >= moment(dateTaken).startOf('day')
      }
      return true
    }
  ),
  [LICENSING_FIRST_PUBLISHED_KEYS.place]: QString.url({
    title:
      'Please specify a valid URL starting with http:// or https:// where the image was first published',
  })
    .test(
      'is-hotlinked',
      {
        title:
          'It looks like the image user hotlinked the image from its original source. Because many jurisdictions do not legally consider this an infringement, we are unable to accept submissions for such usage.',
      },
      function(value) {
        const values = this.options.context
        if (values && values.match && values.match.url === value) {
          return false
        }

        return true
      }
    )
    .test('async-checks-is-resolvable', null, function isOnline(value) {
      const ctx = this.options.context
      if (isPlainObject(ctx) && value && isFunction(ctx.validator)) {
        try {
          const domain = urijs(value).domain()
          return ctx
            .validator(this.path, {
              host: domain,
              imagePublishedUrl: value,
              caseId: ctx.caseId,
            })
            .then(handleAsyncResult(this, value, ctx.getAsyncIgnoredError()))
            .catch(handleAsyncError)
        } catch (err) {
          console.error(err)
          return false
        }
      }

      return true
    })
    .required({
      title: 'Please input the URL where the image was first published',
    }),
}

export const LicensingFirstPublishedSchema = QSchema.shape(LICENSING_FIRST_PUBLISHED_TYPES) // prettier-ignore

// prettier-ignore
export const LICENSING_TYPES = {
  [LICENSING_KEYS.cc]: QBoolean.required({
    title: 'Please choose if the image has been available under a Creative Commons license in the past'
  }).test('block-cc-legal-jurisdictions', {
    title: 'We are unable to process Creative Commons cases',
    subtitle: 'Based on our previous knowledge of legal cases in this jurisdiction, we are unable to accept cases involving Creative Commons. We encourage you to send a Takedown notice.',
  }, function checkLegalJurisdictions(value) {
    const ctx = this.options.context

    if (ctx && ctx.cluster && ctx.cluster.tags) {
      if (ctx.cluster.tags.includes('pixsy:flagged')) {
        return true
      }
    }

    if (isPlainObject(ctx) && isPlainObject(ctx.values) && ctx.values.country) {
      if (ctx.values.country === 'Undefined') {
        return true
      }

      const obj = COUNTRIES.find((c) => c.displayName === ctx.values.country)
      // Block CC license for Legal Only jurisdictions
      return !(value === true && obj.legalOnly === true)
    }

    return true
  }),
  [LICENSING_KEYS.license]: QString.when(LICENSING_KEYS.cc, {
    is: true,
    then: QEnumString(CC_ENUM).required({
      title: 'Please choose the Creative Common terms of the license'
    }).test('check-license-validity-cc', {
      title: 'Images offered through a CC0 license have been released into the public domain',
      subtitle: 'Because copyright no longer applies to such images, we cannot take action on this case.'
    }, function isCCLicenseValid(value) {
      return value !== 'CC0'
    }).test('allowed-countries-license-validity-cc', {
      title: 'This country is not allowed to submit with CC licence',
    }, function isCCLicenseValidForCountry() {
      const originalMatch = this.from.find((obj) => {
        return obj.value && obj.value.source && obj.value.source === 'MATCH'
      })

      if (originalMatch) {
        const currentCountry = getIn(originalMatch, 'value.country', '')
        if (['greece', 'gr', 'spain', 'es'].includes(currentCountry.toLowerCase())) {
          return this.createError({
            message: {
              title: `We are not currently accepting submissions related to Creative Commons images in this infringement country ${currentCountry}. Contact Pixsy support if you have any questions.`,
            },
            path: this.path
          })
        }
      }

      return true
    }),
    otherwise: QString.transform((v) => null)
  }),
  [LICENSING_KEYS.publicly_available]: QBoolean.required({
    title: 'Please choose if the pricing for the image is available publicly'
  }),
  [LICENSING_KEYS.licensed_before]: QBoolean.required({
    title: 'Please choose if you have ever sold a license for this image'
  }),
  [LICENSING_KEYS.agreementsUnavailable]: QBoolean,
  [LICENSING_KEYS.first_published]: LicensingFirstPublishedSchema,
  [LICENSING_KEYS.available_on_stock]: QBoolean.when('$values', (values, schema) => {
    const stockIncluded = values && values[KNOCKOUT_NAMES.stockIncluded]
    const stockVerified = values && values[KNOCKOUT_NAMES.stockVerified]

    if (stockIncluded === true && stockVerified === true) {
      return schema.required({
        title: 'Please let us know if this image is currently available on a stock site.',
      })
    }

    return schema
  }),
  [LICENSING_KEYS.price]: QSchemaConditional(
    LicensingPriceSchema,
    [LICENSING_KEYS.publicly_available, LICENSING_KEYS.available_on_stock],
    (publiclyAvailable, onStock) => publiclyAvailable === true || onStock === true
  ),
  [LICENSING_KEYS.price_1y_online]: QSchemaConditional(
    LicensingOneYearSchema,
    LICENSING_KEYS.licensed_before,
    (v) => v === true
  )
}

export const LicensingSchema = QSchema.shape(LICENSING_TYPES)
