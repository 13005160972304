import React from 'react'
import PropTypes from 'prop-types'
import { cx, css } from 'react-emotion'

const wrap = css`
  text-align: center;
  margin: 5px 0 24px;
  transition: opacity 300ms ease;

  span {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #3b3b3b80;
  }

  h2 {
    margin: 2px 0 10px;
    letter-spacing: -0.025em;
  }

  h3 {
    font-size: 1em;
    color: #3b3b3b80;
    margin: 0;
    font-weight: 400;
  }
`

const faded = css`
  opacity: 0;
  transition-duration: 0s !important;
`

export default function StatusHeading({ pre, title, subtitle, isVisible }) {
  return (
    <div css={cx(wrap, !isVisible && faded)}>
      {pre && <span>{pre}&nbsp;</span>}
      <h2>{title}&nbsp;</h2>
      {subtitle && <h3>{subtitle}&nbsp;</h3>}
    </div>
  )
}

StatusHeading.defaultProps = {
  isVisible: true,
}

StatusHeading.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  pre: PropTypes.string,
  subtitle: PropTypes.string,
}
