import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import FormRow from './FormRow'
import Label from './Label'

const styles = css`
  flex: 1;
  font-size: 1.3em;
  font-weight: 400;
  line-height: 40px;
`

export default function StaticText({ children, title, text, labelAlign }) {
  return (
    <FormRow>
      <Label title={title} align={labelAlign} />
      <div className={styles}>{text}</div>
    </FormRow>
  )
}

StaticText.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
}

StaticText.defaultProps = {
  title: '',
}
