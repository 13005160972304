import React, { memo } from 'react'
import { Flag } from 'common'

import { ACTION_ITEMS, PIXSY_JURISDICTION_COUNTRY } from '../constants'

const mapFiltersData = (data) => {
  if (!data) {
    return {
      isLoading: true,
      totalMatches: 0,
      countryItems: [],
      categoryItems: [],
      imageItems: [],
      actionItems: [],
      entityId: null,
      matchId: null,
    }
  }

  return {
    totalMatches: data.matchSummary.total,
    countryItems: [PIXSY_JURISDICTION_COUNTRY]
      .concat(data.countries.edges)
      .map(({ iso, displayName }) => ({
        id: iso,
        value: displayName,
        iconComponent: () => <CountryFlag name={displayName} />,
        count:
          iso === PIXSY_JURISDICTION_COUNTRY.iso
            ? data.matchSummary.countries
                .filter((s) => s.country && s.country.isPixsyJurisdiction)
                .reduce((count, s) => count + s.matches, 0)
            : (data.matchSummary.countries.find((s) => s.country && s.country.iso === iso) || {}).matches || 0,
      }))
      .filter((item) => item.count > 0),
    categoryItems: data.categories.edges.map(({ code, text }) => {
      const categorySummary = data.matchSummary.categories.find(
        (summary) => summary.category && summary.category.code === code
      )
      const count = categorySummary ? categorySummary.matches : 0

      return {
        id: code,
        value: text,
        count,
      }
    }),
    imageItems:
      data.matchSummary.images &&
      data.matchSummary.images.map(({ image, matches }) => ({
        id: image._id,
        value: image.title,
        count: matches,
      })),
    actionItems:
      data.matchSummary.actions &&
      data.matchSummary.actions.map(({ id, matches }) => ({
        id,
        value: ACTION_ITEMS[id],
        count: matches,
      })),
  }
}

const CountryFlag = memo(({ name }) => <Flag style={styles.flag} country={name.replace(/ /g, '-')} />)

const styles = {
  flag: { width: '16px', height: '16px' },
}

export default mapFiltersData
