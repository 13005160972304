import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import { GridWrappingColumn, StatusHeading, LoadingOverlay, StickyFloater } from 'common'

import { css } from 'react-emotion'
import TakedownOverviewHeaderRow from './TakedownOverviewHeaderRow'
import TakedownOverviewRow from './TakedownOverviewRow'

export default class TakedownOverview extends Component {
  render() {
    const { takedowns, total, loading, history } = this.props
    const iconBack = require('../../Matches/V2/QueryBar/backIcon.svg')
    return (
      <GridWrappingColumn maxWidth={1280}>
        <StickyFloater>
          <button onClick={history.goBack} dangerouslySetInnerHTML={{ __html: iconBack }} css={backButton} />
          <span className="sticky-floater--left-aligned-description">Takedowns</span>
        </StickyFloater>

        <StatusHeading
          title={`${Number(total).toLocaleString()} Takedown${total !== 1 ? 's' : ''}`}
          isVisible={!loading}
        />
        <LoadingOverlay show={loading}>
          {/* <TakedownMaterialTable data={takedowns} /> */}
          <Table responsive css={takedownsTable}>
            <TakedownOverviewHeaderRow />
            <tbody>
              {takedowns.map((t) => (
                <Fragment key={t.id}>
                  <TakedownOverviewRow takedown={t} />
                  <TakedownOverviewSpacer />
                </Fragment>
              ))}
            </tbody>
          </Table>
        </LoadingOverlay>
      </GridWrappingColumn>
    )
  }
}

TakedownOverview.propTypes = {
  takedowns: PropTypes.array.isRequired,
}

const takedownsTable = css`
  margin-top: 20px;
  table-layout: fixed;

  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    tr {
      margin: 10px;
      padding: 10px 0;
    }
    tbody td {
      text-align: center !important;
      &:nth-child(1) {
        padding: 0 10px !important;
      }
      &:nth-child(6) {
        text-align: center !important;
      }
    }
  }
`

const backButton = css`
  max-width: 87px !important;
  height: 54px !important;
  top: 0;
  border: 0;
  padding: 0 !important;
  line-height: 13px;
  background-color: #008aab;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 24px;
    width: 24px;
  }
`

function TakedownOverviewSpacer() {
  return (
    <tr>
      <td css={spacer} colSpan={5}>
        <div css={spacerEffect} />
      </td>
    </tr>
  )
}

const spacer = css`
  border-top: none !important;
  position: relative;
  height: 12px;
`

const spacerEffect = css`
  position: absolute;
  height: 1px;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
`
