import React, { useState, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import isEqual from 'lodash/isEqual'

import Content from './content'
import Checkbox from './Checkbox'
import { useColumnSort } from './helpers'

const ALL_PLATFORMS = {
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  pinterest: 'Pinterest',
  youtube: 'YouTube',
  blogspot: 'Blogger (BlogSpot)',
  wordpress: 'Wordpress',
  tumblr: 'Tumblr',
  alamy: 'Alamy',
  shutterstock: 'Shutterstock',
  getty: 'Getty',
  etsy: 'Etsy',
  amazon: 'Amazon',
  ebay: 'eBay',
  aliexpress: 'AliExpress',
  flickr: 'Flickr',
}

function useMatchPreferencePlatforms(hiddenPlatforms) {
  const [hidden, setHidden] = useState(hiddenPlatforms)

  const onChange = useCallback((platform) => {
    setHidden((state) => {
      if (state.includes(platform))
        return state.filter((h) => {
          return h !== platform
        })
      return [...state, platform]
    })
  }, [])

  const showSaveButton = !isEqual(hiddenPlatforms.sort(), hidden.sort())

  return {
    hidden,
    onChange,
    showSaveButton,
  }
}

const MatchPreferencePlatforms = ({ hiddenPlatforms = [], updateMatchPreference }) => {
  const { hidden, onChange, showSaveButton } = useMatchPreferencePlatforms(hiddenPlatforms)

  const saveMatchPreference = useCallback(() => {
    return updateMatchPreference({ variables: { platforms: hidden } })
  }, [hidden, updateMatchPreference])

  const allPlatforms = useColumnSort(Object.keys(ALL_PLATFORMS), 2, 3)

  return (
    <Content title="Platforms" showSaveButton={showSaveButton} onSave={saveMatchPreference}>
      <Grid container>
        {allPlatforms.map((platform) => {
          if (!platform) return <Grid item md={4} xs={6} />
          return (
            <RenderItems
              key={platform}
              title={ALL_PLATFORMS[platform]}
              checked={!hidden.includes(platform)}
              onChange={onChange.bind(this, platform)}
            />
          )
        })}
      </Grid>
    </Content>
  )
}

const RenderItems = React.memo(({ title, checked, onChange }) => (
  <Grid item md={4} sm={6} xs={12}>
    <Checkbox title={title} checked={checked} onChange={onChange} />
  </Grid>
))

export default React.memo(MatchPreferencePlatforms)