import React from 'react'
import { PHOTODECK } from 'pixsy-constants'
import PropTypes from 'prop-types'
import { Button, TextFieldNoLabel } from 'common'
import { reduxForm, Field } from 'redux-form'
import { css } from 'react-emotion'

export function PhotodeckDialog({
  synchronize,
  submitting,
  handleSubmit,
  valid,
}) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Importing from Photodeck</h1>

      <p>
        In order to import your images from Photodeck, you need to activate your{' '}
        <b>Website‘s Indexing URL</b> on Photodeck's settings under{' '}
        <b>My Website</b> &gt; <b>Setup</b> &gt; <b>Advanced Settings</b> &gt;{' '}
        <b>Integration with another website</b> /{' '}
        <b>Activate my website indexing URL</b>.
      </p>

      <p>
        <i>Example Indexing URL:</i>
        <br />
        <b>http://username.photodeck.com/medias.xml?auth=TOKEN</b>
      </p>

      <div css={actionArea}>
        <div css={fieldRow}>
          <Field
            component={TextFieldNoLabel}
            type="text"
            name="photodeckURL"
            label="Indexing URL"
            labelAlign="right"
          />
        </div>

        <Button submit disabled={!valid || submitting}>
          Import
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'photodeckImport',
  validate(values, props) {
    if (!values.photodeckURL) return { photodeckURL: 'URL required' }
    if (!values.photodeckURL.startsWith('http')) {
      return { photodeckURL: 'Indexing URL starts with http' }
    }
    if (!values.photodeckURL.includes('auth')) {
      return { photodeckURL: 'Incomplete URL' }
    }
  },
  onSubmit(values, dispatch, { synchronize, onClose }) {
    synchronize(
      PHOTODECK,
      values.photodeckURL && values.photodeckURL.trim()
    ).then(onClose)
  },
})(PhotodeckDialog)

PhotodeckDialog.propTypes = {
  synchronize: PropTypes.func.isRequired,
}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  em {
    color: #008aab;
  }
`

const actionArea = css`
  width: 100%;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  input[type='text'] {
    width: 100%;
  }

  button {
    margin-bottom: 10px;
    max-width: 240px;
    margin: 0 auto;
  }
`

const fieldRow = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 20px;
`
