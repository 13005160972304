const YEAR_DIFF = 8
const YEAR_MAX = new Date().getFullYear()
const YEAR_MIN = YEAR_MAX - YEAR_DIFF

const ACTION_ITEMS = {
  APPROVED: 'Approved',
  CASE_SUBMITTED: 'Case Submitted',
  FLAGGED: 'Flagged',
  IGNORED: 'Ignored',
  OFFLINE: 'Offline',
  PIXSY_FLAGGED: 'Pixsy Flagged',
  TAKEDOWN_SUBMITTED: 'Takedown Sent',
}

const LIST_TYPE = {
  CLUSTER: 'cluster',
  GROUP_MATCH: 'groupMatch',
}

const DETAIL_MODAL_VIEW_TYPE = {
  MORE: 'more',
  DETAILS: 'details',
  ACTIONS: 'actions',
}

const MATCHES_TYPE = {
  TOP: 'top',
  ALL: 'all',
}

const SEEN_STATE = {
  SEEN: 'seen',
  UNSEEN: 'unseen',
}

const SIMILARITY_TYPE = {
  EXACT: 'exact',
  SIMILAR: 'similar',
}

const COMMERCIAL_TYPE = {
  COMMERCIAL: 'true',
  NONCOMMERCIAL: 'false',
}

const PIXSY_JURISDICTION_COUNTRY = {
  iso: 'pixsy',
  country: 'Pixsy Jurisdiction',
  displayName: 'Pixsy Jurisdiction',
}

const SORT_OPTIONS = [
  // { value: 'Automatic sorting', query: '-score' },
  { value: 'Most commercial first', query: '-score', isRecommended: true },
  { value: 'Newest detected first', query: '-matches.created' },
  { value: 'Oldest detected first', query: 'created' },
  { value: 'Least commercial first', query: 'score' },
]

const defaultSelectedClusterFiltersState = {
  selectedCountryIds: [],
  selectedCategoryIds: [],
  selectedImageIds: [],
  selectedActionIds: [],
  selectedKeywordPairs: [],
  selectedSeenState: null,
  selectedSimilarityType: null,
  selectedCommercialType: null,
  selectedMatchesType: MATCHES_TYPE.ALL,
  selectedYearRange: [YEAR_MAX, YEAR_MIN],
  isFlagged: false,
  isPixsyFlagged: false,
  selectedEntityId: null,
  selectedMatchId: null,
}

const defaultSelectedGroupMatchFiltersState = {
  clusterId: null,
  cluster: null,
  selectedCountryIds: [],
  selectedCategoryIds: [],
  selectedImageIds: [],
  selectedActionIds: [],
  selectedSimilarityType: null,
  selectedCommercialType: null,
  selectedSeenState: null,
  selectedYearRange: [YEAR_MAX, YEAR_MIN],
}

const defaultPageOptions = {
  perPage: 20,
  forcePage: 0,
  sort: SORT_OPTIONS.find((option) => option.query === '-score').value,
}

const defaultState = {
  clusterListFilters: {
    ...defaultSelectedClusterFiltersState,
  },
  groupMatchListFilters: {
    ...defaultSelectedGroupMatchFiltersState,
  },
  detailsView: {
    modal: null,
    cluster: null,
    matchId: null,
    clusterId: null,
  },
  lastIgnoredMatch: {
    clusterId: null,
    matchId: null,
    undoFn: null,
  },
  pageOptions: {
    ...defaultPageOptions,
  },
  lastOpenedCollapsablesCloseFn: null,
  routerHistory: null,
  activeList: LIST_TYPE.CLUSTER,
  scrollToClusterId: null,
}

export {
  YEAR_MAX,
  YEAR_MIN,
  YEAR_DIFF,
  LIST_TYPE,
  SEEN_STATE,
  ACTION_ITEMS,
  MATCHES_TYPE,
  defaultState,
  SIMILARITY_TYPE,
  COMMERCIAL_TYPE,
  SORT_OPTIONS,
  defaultPageOptions,
  DETAIL_MODAL_VIEW_TYPE,
  PIXSY_JURISDICTION_COUNTRY,
  defaultSelectedGroupMatchFiltersState,
  defaultSelectedClusterFiltersState,
}
