import React from 'react'
import FormRow from './FormRow'
import { css } from 'react-emotion'
import Label from './Label'
import AlignLabels from './AlignLabels'
import Feedback from './Feedback'
import { merge } from 'lodash'

export default function CompoundFieldsDoublePrice({
  label,
  children,
  overwriteSyncError,
}) {
  const childProps = React.Children.toArray(children).map(child => child.props)
  const mergedProps = {
    highlighted: childProps.reduce(
      (value, current) => value || current.highlighted,
      false
    ),
    editing: childProps.reduce((value, current) => value || current.editing),
    meta: childProps.reduce((value, current) => merge(value || current), {
      error: overwriteSyncError,
    }),
    paddingTop: 0,
  }

  return (
    <FormRow {...mergedProps}>
      <div css={wrap}>
        <AlignLabels width="100%">
          <Label title={label} />
        </AlignLabels>
        <div css={flex}>
          {children.map((child, idx) => (
            <div key={idx}>{child}</div>
          ))}
        </div>
        <Feedback
          meta={{
            ...mergedProps.meta,
            error: overwriteSyncError || mergedProps.meta.error,
          }}
        />
      </div>
    </FormRow>
  )
}

const wrap = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`

const flex = css`
  display: flex;

  > div:nth-child(1) {
    flex-basis: 230px !important;
    margin-right: 25px;
  }

  > div:nth-child(2) {
    flex-basis: 230px !important;
    margin-right: 25px;
  }

  > div:last-child {
    flex-basis: 136px !important;
    margin-right: 0 !important;
  }
`
