import React, { useEffect, useState } from 'react'
import { css, cx } from 'react-emotion'
import { HotKeys } from 'react-hotkeys'
import { Button, ChooseBestImageSource } from 'common'
import { getProxyUrlToMatch } from 'pixsy-constants'

const FalsePositiveViewer = class FalsePositiveViewer extends React.Component {
  render() {
    const { data, next, prev, takeAction } = this.props

    const { image, matches } = data

    const id2list = {
      droppable: 'matches-10',
      droppable2: 'matches-11',
      droppable3: 'matches-12',
    }

    const getList = id => this.state[this.id2List[id]]

    /* **************************************************************** */
    console.log(matches)
    const match = matches[0]
    const responded = !!match.verification
    const falsePositiveCssMap = {
      10: falsePositive,
      11: truePositiveSimilar,
      12: truePositiveExact,
    }
    const falsePositiveCss = falsePositiveCssMap['10']

    const keyMap = {
      // previous: 'left',
      // next: 'right',
      // ignoreMatch: '1',
      // approveSimilarMatch: '9',
      // approveExactMatch: '0',
    }

    const handlers = {
      // previous: prev,
      // next,
      // ignoreMatch: () => takeAction(0),
      // approveSimilarMatch: () => takeAction(1),
      // approveExactMatch: () => takeAction(2),
    }

    return (
      <HotKeys keyMap={keyMap} focused handlers={handlers} attach={global}>
        <div css={cx([headerWrapper, responded && falsePositiveCss])}>
          <a
            onClick={prev}
            href="#"
            style={{
              color: 'inherit',
            }}
          >
            {`< Back`}
          </a>
          <span>
            False Positive Review for Image ID: <b>{image._id}</b>
          </span>
          <a
            href="#"
            onClick={next}
            style={{
              color: 'inherit',
            }}
          >
            Next &gt;
          </a>
        </div>

        <div css={wrapper}>
          <div css={originalImageWrapper}>
            <Image url={image.url} />
          </div>
          <div css={columnWrapper}>
            <div css={column}>
              <h3>False positives</h3>
            </div>

            <div css={column}>
              <h3>Similar</h3>
            </div>

            <div css={column}>
              <h3>Exact</h3>
            </div>
          </div>
        </div>

        <div css={center}>
          <strong>Pixsy Similarity Score: </strong>
          {(match.similarity && (
            <h1>{Math.round(match.similarity * 100)}%</h1>
          )) || <span>Not Available</span>}
        </div>
        {/* 
        <div css={wrapper}>
          <Button danger onClick={() => takeAction(0)}>
            Yes, This match is false positive (1)
          </Button>
          <div>
            <Button orange onClick={() => takeAction(1)}>
              Similar Match (9)
            </Button>
            <Button onClick={() => takeAction(2)}>Exact Match (0)</Button>
          </div>
        </div> */}
      </HotKeys>
    )
  }
}

const Image = ({ url, matchId }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    requestAnimationFrame(() => setLoading(false))
  }, [url])

  if (loading) {
    return null
  }

  const candidates = [url]
  if (matchId) candidates.push(getProxyUrlToMatch(matchId))

  return (
    <ChooseBestImageSource type="image" candidates={candidates}>
      {({ url: imageUrl }) => (
        <img
          css={image}
          src={imageUrl}
          alt="Unable to load image. Match could be offline or Geo Blocked. Please confirm by using a VPN"
        />
      )}
    </ChooseBestImageSource>
  )
}

const headerWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 12px 6px #eaeaea;
`

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
`

const column = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 33%;
  padding: 20px;
  border: 1 px solid red;
`

const originalImageWrapper = css`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  width: 300px;
`

const columnWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`

const center = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const urlWrapper = css`
  word-break: break-all;
`

const image = css`
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
`

const truePositiveSimilar = css`
  background-color: #ffc947 !important;
  color: white !important;
`

const truePositiveExact = css`
  background-color: #52c7b8 !important;
  color: white !important;
`

const falsePositive = css`
  background-color: #f44336 !important;
  color: white !important;
`

export default FalsePositiveViewer
