import useNotify from './useNotify'
import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

/**
 * Wrap an apollo mutation with toast notifications on request, success and error
 *
 * @param {function} dispatch - Dispatch function from the target store. This is required until redux is upgraded to a
 *                              version that supports useDispatch()
 * @param {DocumentNode|TypedDocumentNode<any, OperationVariables>} query - GraphQL query to be executed
 * @param {string|null} requestMessage - (optional) Message to be displayed when the mutation is triggered
 * @param {string|null} successMessage - (optional) Message to be displayed when the mutation succeeds
 * @param {string|null} defaultErrorMessage - (optional) Message to be displayed when the mutation fails and no error
 *                                            message is provided
 * @param {function} onCompleted - (optional) Callback to be performed when the mutation is completed (see useMutation)
 * @param {function} onError - (optional) Callback to be performed when the mutation fails (see useMutation)
 * @param {MutationHookOptions<any, OperationVariables, DefaultContext>} mutationOptions - Other parameters to be passed down to useMutation
 * @returns {MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>>}
 *   Forwards the return value of useMutation, but with the altered function
 */
export default function useNotifyMutation(dispatch, query, {
  requestMessage = null,
  successMessage = null,
  defaultErrorMessage = null,
  onCompleted = () => {},
  onError = () => {},
  ...mutationOptions
}) {
  const notify = useNotify(dispatch)
  const [mutate, result] = useMutation(query, {
    ...mutationOptions,

    onCompleted: (...args) => {
      if (successMessage) {
        notify({
          id: successMessage,
          message: successMessage,
          kind: 'success',
          dismissAfter: 5e3,
        })
      }

      return onCompleted(...args)
    },

    onError: (error) => {
      const errorMessage = error.message || defaultErrorMessage

      if (errorMessage) {
        notify({
          id: errorMessage,
          message: errorMessage,
          kind: 'warning',
          dismissAfter: 10e3,
        })
      }

      return onError(error)
    },
  })

  const mutateWithNotification = useCallback((...args) => {
    if (requestMessage) {
      notify({
        message: requestMessage,
        kind: 'info',
        dismissAfter: 10e3,
      })
    }

    return mutate(...args)
  }, [notify])

  return [mutateWithNotification, result]
}
