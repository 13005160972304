import React from 'react'
import { withRouter } from 'react-router'
import {
  ContentSection,
  GridWrappingColumn,
  StickyFloater,
  ScrollToTop,
} from 'common'

import { css } from 'react-emotion'

function AgreementContainer({ history, match, location, children }) {
  const iconBack = require('../Matches/V2/QueryBar/backIcon.svg')
  return (
    <GridWrappingColumn maxWidth={1040}>
      <ScrollToTop whenChanges={location} />
      <StickyFloater>
        {/*<Button
            onClick={history.goBack}
            className="sticky-floater--back-button"
          />*/}
        <button
          onClick={history.goBack}
          dangerouslySetInnerHTML={{ __html: iconBack }}
          css={backButt}
        />
        <span className="sticky-floater--left-aligned-description">
          Please review the agreement below:
          {/* (<b>New Case Submission</b>
            &nbsp;{cluster &&
              `for ${cluster.domain.host}`)} */}
        </span>
      </StickyFloater>
      <ContentSection>{children}</ContentSection>
    </GridWrappingColumn>
  )
}

export default withRouter(AgreementContainer)

const backButt = css`
  max-width: 87px !important;
  height: 54px !important;
  top: 0;
  border: 0;
  padding: 0 !important;
  line-height: 13px;
  background-color: #008aab;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 24px;
    width: 24px;
  }
`
