import axios from 'axios'
import _get from 'lodash/get'

function formatUrl(path) {
  if (/^https?:\/\//.test(path)) return path

  const adjustedPath = path[0] !== '/' ? '/' + path : path
  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return 'http://localhost:3030' + adjustedPath
  }
  // Prepend `/api` to relative URL, to proxy to API server.
  return '/api' + adjustedPath
}

function wrapError(err) {
  err.message = _get(err, 'response.data.message') || err.message
  err.status = _get(err, 'response.status', 500)

  if ([500, 503].includes(err.status)) {
    err.message =
      'Pixsy was temporarily unable to process your request. Please try again in a minute.'
  }

  return err
}

/*
 * This silly underscore is here to avoid a mysterious "ReferenceError: ApiClient is not defined" error.
 * See Issue #14. https://github.com/erikras/react-redux-universal-hot-example/issues/14
 *
 * Remove it at your own risk.
 */
export default class APIClient {
  constructor(req) {
    ;['get', 'post', 'put', 'patch', 'delete'].forEach((method, i) => {
      const fn = (path, { params, data, headers, cancelToken } = {}) => {
        const url = formatUrl(path)
        const requestConfig = { url, method, cancelToken }

        if (params) {
          requestConfig.params = params
        }

        if (headers) {
          requestConfig.headers = headers
        }

        if (data) {
          requestConfig.data = data
        }

        if (__SERVER__ && req.get('cookie')) {
          requestConfig.headers = requestConfig.headers || {}
          requestConfig.headers.Cookie = req.get('cookie')
        }

        return axios(requestConfig)
          .then(res => res.data)
          .catch(err => Promise.reject(wrapError(err)))
      }
      Object.assign(this, { [method]: fn })
    }, this)
  }

  generateCancelTokenSource() {
    return axios.CancelToken.source()
  }
}
