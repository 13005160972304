// 1. Import and select images
import Fade from '@material-ui/core/Fade'
import CheckIcon from '@material-ui/icons/Check'
import CloseRounded from '@material-ui/icons/CloseRounded'
import SearchIcon from '@material-ui/icons/Search'
import { TooltipContext } from 'common'
import debounce from 'lodash/debounce'
import {
  IMPORT_TYPES,
  SUBMISSION_NAMES as NAMES,
} from 'pixsy-schema/case/SubmissionSchema'
import * as React from 'react'
import { css } from 'react-emotion'
import {
  PixsyFormGroup,
  PixsyGridItem,
  PixsyIconButton,
  PixsyLabel,
  PixsyPaper,
  PixsyProgress,
  PixsyRadioGroup,
  PixsyTextField,
  UppyDragDrop,
} from '../../../Components'
import { getUploadedImagesOnly } from '../../util/images'
import { ImagesGrid } from './ImagesGrid'
import importStageTooltipContent from './importStageTooltipContent'
// import { ImagesSearchProgress } from './ImagesSearchProgress'

const UPLOAD_OPTIONS = [
  {
    label: 'SELECT',
    value: IMPORT_TYPES.SEARCH_IMAGE,
  },
  {
    label: 'UPLOAD',
    value: IMPORT_TYPES.UPLOAD_IMAGE,
  },
  // {
  //   label: 'URL',
  //   value: IMPORT_TYPES.UPLOAD_BY_URL,
  // },
]

const UPLOAD_IMAGE_ICON_COLOR = 'rgba(230, 99, 99, 0.5)'
const SEARCH_IMAGE_ICON_COLOR = 'rgba(0, 138, 171, 0.5)'
const UPLOAD_IMAGE_ICON_SELECTED_COLOR = 'rgba(230, 99, 99)'
const SEARCH_IMAGE_ICON_SELECTED_COLOR = 'rgba(0, 138, 171)'
const DEBOUNCE_SEARCH = 3e2

/**
 * @augments {React.Component<import('../StageProps').IProps, {}>}
 */
export class ImportSelectStage extends React.PureComponent {
  handleSearchPressEnter = e => {
    const ENTER_KEY = 'Enter'
    if (e.key === ENTER_KEY) {
      const { getImages } = this.props
      getImages()
    }
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      this.props.getImages()
    })
  }

  handleDebouncedSearch = debounce(e => {
    const { getImages } = this.props
    getImages()
  }, DEBOUNCE_SEARCH)

  render() {
    const {
      addExistingImageFromSearch,
      caseId,
      clearSearchImages,
      editableImagesWithIds,
      getImages,
      imageImportFiles,
      isFormReadOnly,
      isImagesLoading,
      removeImage,
      retryImageImportFile,
      searchImages,
      searchReachLimit,
      values: { images, __IMPORT_VALUE__ },
      case: caze,
    } = this.props

    const canEdit =
      (caze && caze.cm && caze.cm.changeRequested) || !isFormReadOnly

    const lockedImages =
      (caze &&
        caze.cm &&
        caze.cm.changeRequested &&
        caze.submission.images.map(i => i._id)) ||
      []

    const uploadedImages = getUploadedImagesOnly(images, caseId)

    return (
      <TooltipContext content={importStageTooltipContent}>
        <PixsyPaper padding="40px 60px 20px 60px" borderlessBottom>
          <PixsyFormGroup spacing={2}>
            <PixsyGridItem xs={6}>
              <PixsyLabel text="Select or upload the images that were infringed" />
            </PixsyGridItem>
            <PixsyGridItem xs={6}>
              <PixsyRadioGroup
                spacing={1}
                name={NAMES.__IMPORT_VALUE__}
                items={UPLOAD_OPTIONS}
                keepPristine
              />
            </PixsyGridItem>
          </PixsyFormGroup>
        </PixsyPaper>

        {__IMPORT_VALUE__ === IMPORT_TYPES.UPLOAD_IMAGE && (
          <React.Fragment>
            <PixsyPaper
              padding="30px 60px 30px 60px"
              borderlessTop
              borderlessBottom={!!uploadedImages.length}
            >
              <UppyDragDrop
                uppy={imageImportFiles.uppy}
                title="Drop your images here or %{browse} to select"
                description="Images Import"
                browse="click"
              />
            </PixsyPaper>
            {!!uploadedImages.length && (
              <PixsyPaper padding="40px 60px 40px 60px" borderlessTop>
                <ImagesGrid
                  color={UPLOAD_IMAGE_ICON_COLOR}
                  colorSelected={UPLOAD_IMAGE_ICON_SELECTED_COLOR}
                  disabled={!canEdit}
                  icon={CloseRounded}
                  iconSelected={CloseRounded}
                  images={uploadedImages}
                  removeImage={removeImage}
                  retryImageImportFile={retryImageImportFile}
                  lockedImages={lockedImages}
                />
              </PixsyPaper>
            )}
          </React.Fragment>
        )}

        {__IMPORT_VALUE__ === IMPORT_TYPES.SEARCH_IMAGE && (
          <React.Fragment>
            <PixsyPaper
              padding="40px 60px 40px 60px"
              borderlessTop
              borderlessBottom={!!searchImages.length && !searchReachLimit}
            >
              <PixsyTextField
                borderlessLeft
                borderlessRight
                borderlessTop
                keepPristine
                css={searchInputStyle}
                name={NAMES.__SEARCH_QUERY__}
                onKeyDown={this.handleSearchPressEnter}
                onChange={this.handleDebouncedSearch}
                leftAddon={
                  isImagesLoading ? (
                    <ProgressAsButton />
                  ) : (
                    <SearchButton
                      onClick={getImages}
                      disabled={isImagesLoading || !canEdit}
                    />
                  )
                }
                rightAddon={
                  <ClearSearchButton
                    onClick={clearSearchImages}
                    disabled={isImagesLoading || !canEdit}
                  />
                }
              />
              {/* {isImagesLoading && <ImagesSearchProgress />} */}
            </PixsyPaper>

            {searchReachLimit && (
              <Fade in>
                <PixsyPaper
                  padding="15px 60px 15px 60px"
                  expanded
                  variant="grey"
                >
                  <div css={greyTitleStyle}>
                    Search by image title to display more results
                  </div>
                </PixsyPaper>
              </Fade>
            )}
            {!!searchImages.length && (
              <PixsyPaper
                padding="40px 60px 40px 60px"
                borderlessTop={!searchReachLimit}
              >
                <ImagesGrid
                  addImage={addExistingImageFromSearch}
                  color={SEARCH_IMAGE_ICON_COLOR}
                  colorSelected={SEARCH_IMAGE_ICON_SELECTED_COLOR}
                  disabled={!canEdit}
                  editableImagesWithIds={editableImagesWithIds}
                  iconSelected={CheckIcon}
                  images={searchImages}
                  isImagesLoading={isImagesLoading}
                  removeImage={removeImage}
                  lockedImages={lockedImages}
                  isImagesSearch
                />
              </PixsyPaper>
            )}
          </React.Fragment>
        )}
      </TooltipContext>
    )
  }
}

const SearchButton = React.memo(({ onClick, disabled }) => {
  const ICON_SIZE_PX = 24
  const ICON_COLOURS = 'rgba(67, 67, 67, 0.2)'
  return (
    <PixsyIconButton
      size={ICON_SIZE_PX}
      icon={SearchIcon}
      color={ICON_COLOURS}
      onClick={onClick}
      disabled={disabled}
    />
  )
})

const ProgressAsButton = React.memo(() => {
  const ICON_SIZE_PX = 24
  const PROG_SIZE_PX = 24
  const ICON_COLOURS = '#148aa9'
  const THICKNESS = 3
  return (
    <PixsyIconButton size={ICON_SIZE_PX} color={ICON_COLOURS} disabled>
      <PixsyProgress
        size={PROG_SIZE_PX}
        color={ICON_COLOURS}
        thickness={THICKNESS}
      />
    </PixsyIconButton>
  )
})

const ClearSearchButton = React.memo(({ onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} css={clearSearchStyle}>
      Clear Search
    </button>
  )
})

const searchInputStyle = css`
  font-size: 24px !important;

  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

  input {
    padding: 4px 0 !important;
    min-height: 24px !important;
    max-height: 24px !important;
    height: 24px !important;
    line-height: normal;
  }
`

const clearSearchStyle = css`
  margin: 0;
  padding: 0;
  min-height: 18px;
  max-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  border: none;
  color: #3b3b3b;
  background-color: #f4f4f4;
  width: 128px;

  &:focus {
    outline: 1px solid #e8e8e8 !important;
    outline-offset: -1px !important;
  }
  &:active {
    background-color: #e8e8e8;
  }
  &:disabled {
    opacity: 0.5;
  }
`

const greyTitleStyle = css`
  font-size: 16px;
  text-align: center;
`
