import qs from 'query-string'

import {
  defaultSelectedClusterFiltersState,
  defaultSelectedGroupMatchFiltersState,
  defaultPageOptions,
  LIST_TYPE,
  SIMILARITY_TYPE,
  SORT_OPTIONS,
  YEAR_MAX,
  YEAR_MIN,
} from '../constants'

const parseLocationForSelectedContextState = (location) => {
  const isMultiMatch = location.pathname.includes('multi:match')
  const filtersFromPath = parsePath(location.pathname)
  const clusterId = getValueForParam(location.pathname, 'view')
  const isGroupMatchView = isMultiMatch && clusterId
  const matchId = getValueForParam(location.pathname, clusterId)
  const listType = isGroupMatchView ? LIST_TYPE.GROUP_MATCH : LIST_TYPE.CLUSTER
  const defaultFilters = isGroupMatchView ? defaultSelectedGroupMatchFiltersState : defaultSelectedClusterFiltersState
  const queryParams = qs.parse(location.search)
  const pageOptions = getPageOptions(queryParams)
  const selectedYearRange = [
    queryParams.toDate ? parseInt(queryParams.toDate) : YEAR_MAX,
    queryParams.fromDate ? parseInt(queryParams.fromDate) : YEAR_MIN,
  ]

  return {
    [`${listType}ListFilters`]: {
      ...defaultFilters,
      ...filtersFromPath,
      ...(isGroupMatchView ? { clusterId } : { isMultiMatch }),
      selectedYearRange,
    },
    detailsView: {
      cluster: null,
      clusterId,
      matchId,
      modal: queryParams.modal,
    },
    activeList: listType,
    pageOptions,
  }
}

const getPageOptions = (queryParams) => ({
  forcePage: parseInt(queryParams.page) - 1 || defaultPageOptions.forcePage,
  perPage: parseInt(queryParams.pageSize) || defaultPageOptions.perPage,
  sort: queryParams.sort
    ? SORT_OPTIONS.find((option) => option.query === queryParams.sort).value
    : defaultPageOptions.sort,
})

const parsePath = (path) =>
  path.split('/').reduce((accumulator, filter) => {
    if (filter === 'top') {
      return { ...accumulator, selectedMatchesType: 'top' }
    } else if (filter === 'seen') {
      return { ...accumulator, selectedSeenState: 'seen' }
    } else if (filter === 'unseen') {
      return { ...accumulator, selectedSeenState: 'unseen' }
    } else if (filter.startsWith('similarity')) {
      const values = convertFilterParamToArray(filter)
      const selectedSimilarityType = values[0] === 'high' ? SIMILARITY_TYPE.EXACT : SIMILARITY_TYPE.SIMILAR
      return {
        ...accumulator,
        selectedSimilarityType,
      }
    } else if (filter === 'commercial:true') {
      return {
        ...accumulator,
        selectedCommercialType: 'true',
      }
    } else if (filter === 'commercial:false') {
      return {
        ...accumulator,
        selectedCommercialType: 'false',
      }
    } else if (filter.startsWith('domain') || filter.startsWith('url')) {
      return {
        ...accumulator,
        selectedKeywordPairs: [...(accumulator.selectedKeywordPairs || []), filter],
      }
    } else if (filter.startsWith('country')) {
      return {
        ...accumulator,
        selectedCountryIds: [...(accumulator.selectedCountryIds || []), ...convertFilterParamToArray(filter)],
      }
    } else if (filter === 'pixsy:jurisdictions') {
      return {
        ...accumulator,
        selectedCountryIds: [...(accumulator.selectedCountryIds || []), 'pixsy'],
      }
    } else if (filter.startsWith('category')) {
      return {
        ...accumulator,
        selectedCategoryIds: convertFilterParamToArray(filter),
      }
    } else if (filter.startsWith('images')) {
      return {
        ...accumulator,
        selectedImageIds: convertFilterParamToArray(filter),
      }
    } else if (filter === 'flagged') {
      return {
        ...accumulator,
        selectedActionIds: [...(accumulator.selectedActionIds || []), 'FLAGGED'],
      }
    } else if (filter === 'pixsy:flagged') {
      return {
        ...accumulator,
        selectedActionIds: [...(accumulator.selectedActionIds || []), 'PIXSY_FLAGGED'],
      }
    } else if (filter === 'online:false') {
      return {
        ...accumulator,
        selectedActionIds: [...(accumulator.selectedActionIds || []), 'OFFLINE'],
      }
    } else if (filter === 'hidden:true') {
      return {
        ...accumulator,
        selectedActionIds: [...(accumulator.selectedActionIds || []), 'IGNORED'],
      }
    } else if (filter === 'submitted:case') {
      return {
        ...accumulator,
        selectedActionIds: [...(accumulator.selectedActionIds || []), 'CASE_SUBMITTED'],
      }
    } else if (filter === 'submitted:takedown') {
      return {
        ...accumulator,
        selectedActionIds: [...(accumulator.selectedActionIds || []), 'TAKEDOWN_SUBMITTED'],
      }
    } else if (filter.startsWith('entity')) {
      return {
        ...accumulator,
        selectedEntityId: convertFilterParamToArray(filter),
      }
    } else if (filter.startsWith('matchid')) {
      return {
        ...accumulator,
        selectedMatchId: convertFilterParamToArray(filter),
      }
    }
    return accumulator
  }, {})

const getValueForParam = (path, param) => {
  const segments = path.split('/')
  const paramValueFoundIndex = segments.findIndex((segment) => segment === param) + 1

  return segments[paramValueFoundIndex] || null
}

const convertFilterParamToString = (filter) => filter.split(':')[1]
const convertFilterParamToArray = (filter) => filter.split(':')[1].split('+')

export default parseLocationForSelectedContextState
