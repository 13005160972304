import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createCache from 'lru-cache'
import _isEqual from 'lodash/isEqual'
import { EXPIRING_CDNS, getProxyUrlToMatch } from 'pixsy-constants'

import { replaceMatchAttributes } from '../../redux/modules/clusters'

const cache = new createCache(500)
const log = require('debug')('choose-best-image-source')

class ChooseBestImageSourceComponent extends Component {
  static SAD_IMAGE = 'https://static.pixsy.io/404.png'
  static MATCH_OFFLINE = 'https://static.pixsy.io/match_offline.svg'
  static IMAGE_OFFLINE = 'https://static.pixsy.io/image_offline.svg'
  static IMAGE_PROCESSING = 'https://static.pixsy.io/image_processing.svg'
  static BLOCKED_BY_HOST = 'https://static.pixsy.io/blocked-by-host.svg'
  // static UNABLE_TO_LOAD = 'https://s3.amazonaws.com/static.pixsy.io/unable-to-load.svg'

  state = { options: [] }

  constructor({ candidates, type, useCache }) {
    super()

    const fallback =
      {
        image: ChooseBestImageSource.IMAGE_OFFLINE,
        imageProcessing: ChooseBestImageSource.IMAGE_PROCESSING,
        match: ChooseBestImageSource.MATCH_OFFLINE,
      }[type] || ChooseBestImageSource.SAD_IMAGE

    const validCandidates = candidates.filter(Boolean)

    this.type = type
    this.useCache = useCache
    this._fallback = fallback
    this.state = {
      urlReparsed: false,
      options: [...validCandidates],
    }
  }

  shouldComponentUpdate({ candidates, children }, { options }) {
    // type and useCache is not intended to be changed
    return (
      this.props.children !== children ||
      !_isEqual(this.props.candidates, candidates) ||
      !_isEqual(this.state.options, options)
    )
  }

  componentDidMount() {
    log('Mount! Options: %o', this.state.options)
    this._mounted = true
    window.requestAnimationFrame(() => {
      log('On Mount - Verify!')
      this.verify()
    })
  }

  UNSAFE_componentWillReceiveProps({ candidates = [] }) {
    // candidates might be [thumb, url] where thumb is undefined
    if (this.props.candidates === candidates) {
      return
    }

    // if one of the candidates we're currently displaying
    // is part of the next batch, we dont have to update
    const newCandidates = candidates.filter(Boolean)
    for (const oldC of this.props.candidates) {
      if (newCandidates.includes(oldC)) {
        return
      }
    }

    // ^ reference check!
    log('Update!')
    this.setState(
      {
        options: newCandidates,
      },
      () => {
        this.verify()
      }
    )
  }

  componentWillUnmount() {
    this._mounted = false
    if (this.image) {
      this.image.onerror = null
      this.image.onload = null
      this.image = null
    }
  }

  unshift = () => {
    if (!this._mounted) {
      log('Unshift - component already unmounted')
      return
    }
    if (this.useCache && this.state.options[0]) {
      cache.set(`${this.type + this.state.options[0]}`, true)
    }

    log('Unshift -  before %o', this.state.options[0])
    this.setState(
      ({ options }) => ({
        options: options.slice(1),
      }),
      () => {
        log('Unshift -  after %o', this.state.options[0])
        this.verify()
      }
    )
  }

  handleImageOnload(img) {
    log('OnLoad %o', img.naturalHeight)
    if ('naturalHeight' in img) {
      if (img.naturalHeight + img.naturalWidth === 0) {
        this.unshift() // error
        return
      }
    } else if (img.width + img.height === 0) {
      this.unshift() // error
      return
    }
  }

  verify() {
    log('Verify - Current State: %o', this.state.options)
    if (this.image) {
      log('Got current image - Cleaning..')
      this.image.onerror = null
      this.image.onload = null
      this.image = null
    }
    if (this.state.options[0]) {
      log('Got current state - Setting..')
      this.image = new window.Image()
      const component = this
      this.image.onload = function() {
        // need to preseve this(=image) context
        component.handleImageOnload(this)
      }

      this.image.onerror = () => {
        if (
          this.props.type !== 'match' ||
          (this.props.type === 'match' && !this.props.match) ||
          this.state.urlReparsed
        ) {
          this.unshift()
          return
        }

        // If the error is because of an expiring CDN, indicate processing,
        // and try to load the correct image.
        const targetCdn = EXPIRING_CDNS.detect(this.state.options[0])
        if (targetCdn) {
          // While parsing indicate processing
          this.setState(
            state => ({
              options: [
                ChooseBestImageSource.IMAGE_PROCESSING,
                ...state.options,
              ],
            }),
            async () => {
              try {
                const { match, replaceMatchAttributes } = this.props
                const result = await replaceMatchAttributes(match)

                if (result && result.error) {
                  throw new Error('failed to parse new urls')
                }

                const updatedMatchUrl =
                  result.payload.entities.matches[match._id].url
                this.setState(state => ({
                  urlReparsed: true,
                  options: [
                    updatedMatchUrl,
                    ...state.options.filter(
                      option =>
                        option !== ChooseBestImageSource.IMAGE_PROCESSING
                    ),
                  ],
                }))
              } catch (_e) {
                // When parsing failed, remove processing and continue with fallback options.
                this.setState(
                  state => ({
                    urlReparsed: true,
                    options: [
                      ...state.options.filter(
                        option =>
                          option !== ChooseBestImageSource.IMAGE_PROCESSING
                      ),
                    ],
                  }),
                  this.unshift
                )
              }
            }
          )
        } else {
          // Try next option.
          this.unshift()
        }
      }

      this.image.src = this.state.options[0]
      log('Set this.image to ', this.state.options[0])
    }
  }

  render() {
    const { options } = this.state
    const { children } = this.props

    if (this.useCache && options[0] && cache.has(`${this.type + options[0]}`)) {
      return children({
        url: this._fallback,
        isError: true,
      })
    }

    return children({
      url: options[0] || this._fallback,
      isError: !options[0],
    })
  }
}
const ChooseBestImageSource = connect(null, { replaceMatchAttributes })(
  ChooseBestImageSourceComponent
)

ChooseBestImageSource.ForCluster = function ChooseBestImageSourceForCluster({
  children,
  cluster,
  preferredImage,
}) {
  const { matches, images } = cluster
  let [match] = matches
  let image

  if (preferredImage) {
    match = matches.find(m => m.image._id === preferredImage) || [matches]
    image = images.find(i => i._id === preferredImage) || [images]
  } else {
    image = images.find(i => i._id === match.image._id) || images[0]
  }

  const clusterData = {
    cluster,
    match,
    imgs: [match.url, image.url, getProxyUrlToMatch(match._id)],
  }

  if (!match) {
    console.error('cluster', cluster)
    console.error('match', match)
    console.error('image', image)
    throw new Error('Unable to access match')
  }

  if (!image) {
    console.error('cluster', cluster)
    console.error('match', match)
    console.error('image', image)
    throw new Error('Unable to access image')
  }

  return (
    <ChooseBestImageSource
      candidates={clusterData.imgs}
      type="match"
      match={match}
      useCache
    >
      {children}
    </ChooseBestImageSource>
  )
}

ChooseBestImageSource.propTypes = {
  candidates: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  match: PropTypes.object,
  useCache: PropTypes.bool,
}

ChooseBestImageSource.defaultProps = {
  candidates: [],
  useCache: false,
}

export default ChooseBestImageSource
