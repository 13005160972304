import qs from 'query-string'

import getSortQueryByValue from './getSortQueryByValue'
import {
  defaultPageOptions,
  LIST_TYPE,
  MATCHES_TYPE,
  SEEN_STATE,
  SIMILARITY_TYPE,
  COMMERCIAL_TYPE,
  YEAR_MAX,
  YEAR_MIN,
} from '../constants'

const generateMatchViewersPath = (state) => {
  const filters = state[`${state.activeList}ListFilters`]
  const isGroupMatch = state.activeList === LIST_TYPE.GROUP_MATCH && filters.clusterId
  const isMultiMatch = filters.isMultiMatch
  const detailsView = state.detailsView
  const queryParams = {}
  let output = ''

  if (isGroupMatch || isMultiMatch) {
    output += '/multi:match'
  }

  if (filters.selectedMatchesType === MATCHES_TYPE.TOP) {
    output += '/top'
  }

  if (filters.selectedSeenState === SEEN_STATE.SEEN) {
    output += '/seen'
  }

  if (filters.selectedSeenState === SEEN_STATE.UNSEEN) {
    output += '/unseen'
  }

  if (filters.selectedSimilarityType) {
    if (filters.selectedSimilarityType === SIMILARITY_TYPE.EXACT) {
      output += '/similarity:high'
    } else if (filters.selectedSimilarityType === SIMILARITY_TYPE.SIMILAR) {
      output += '/similarity:medium+low'
    }
  }

  if (filters.selectedCommercialType) {
    if (filters.selectedCommercialType === COMMERCIAL_TYPE.COMMERCIAL) {
      output += '/commercial:true'
    } else if (filters.selectedCommercialType === COMMERCIAL_TYPE.NONCOMMERCIAL) {
      output += '/commercial:false'
    }
  }

  if (filters.selectedCountryIds.length > 0) {
    if (filters.selectedCountryIds.some((c) => c === 'pixsy')) {
      output += '/pixsy:jurisdictions'
    }
    const filteredCountries = filters.selectedCountryIds.filter((c) => c !== 'pixsy')
    if (filteredCountries.length) {
      output += '/country:' + filteredCountries.join('+')
    }
  }

  if (filters.selectedCategoryIds.length > 0) {
    output += '/category:' + filters.selectedCategoryIds.join('+')
  }

  if (filters.selectedImageIds.length > 0) {
    output += '/images:' + filters.selectedImageIds.join('+')
  }

  if (filters.selectedActionIds.includes('FLAGGED')) {
    output += '/flagged'
  }

  if (filters.selectedActionIds.includes('PIXSY_FLAGGED')) {
    output += '/pixsy:flagged'
  }

  if (filters.selectedActionIds.includes('OFFLINE')) {
    output += '/online:false'
  }

  if (filters.selectedActionIds.includes('IGNORED')) {
    output += '/hidden:true'
  }

  if (filters.selectedActionIds.includes('CASE_SUBMITTED')) {
    output += '/submitted:case'
  }

  if (filters.selectedActionIds.includes('TAKEDOWN_SUBMITTED')) {
    output += '/submitted:takedown'
  }

  if (filters.selectedKeywordPairs && filters.selectedKeywordPairs.length > 0) {
    filters.selectedKeywordPairs.forEach( (keywordPair) => {
      let term = keywordPair.replace('selectedKeywordDomain', 'domain').replace('selectedKeywordUrl', 'url')
      output += '/' + term
    })
  }

  if (isGroupMatch) {
    output += '/view/' + filters.clusterId
  } else if (detailsView.modal && detailsView.clusterId) {
    output += '/view/' + detailsView.clusterId
  }

  if (isGroupMatch && detailsView.modal && detailsView.matchId) {
    output += '/' + detailsView.matchId
  }

  if (detailsView.modal && detailsView.clusterId) {
    queryParams.modal = detailsView.modal
  }

  if (
    state.pageOptions.forcePage > defaultPageOptions.forcePage ||
    state.pageOptions.perPage > defaultPageOptions.perPage
  ) {
    queryParams.pageSize = state.pageOptions.perPage
    queryParams.page = state.pageOptions.forcePage + 1
  }

  if (state.pageOptions.sort !== defaultPageOptions.sort) {
    queryParams.sort = getSortQueryByValue(state.pageOptions.sort)
  }

  if (filters.selectedYearRange[0] !== YEAR_MAX) {
    queryParams.toDate = filters.selectedYearRange[0]
  }

  if (filters.selectedYearRange[1] !== YEAR_MIN) {
    queryParams.fromDate = filters.selectedYearRange[1]
  }

  if (filters.selectedEntityId !== null) {
    output += '/entity:' + filters.selectedEntityId
  }

  if (filters.selectedMatchId !== null) {
    output += '/matchid:' + filters.selectedMatchId
  }

  const queryString = qs.stringify(queryParams)
  if (queryString) output += `?${queryString}`

  return output
}

export default generateMatchViewersPath
