import * as React from 'react'

export default React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="20841.512 -1291.433 43.613 33.441"
  >
    <g id="stack-of-square-papers" transform="translate(20841.512 -1291.433)">
      <g id="Group_67" data-name="Group 67" transform="translate(0 0)">
        <path
          id="Path_32"
          data-name="Path 32"
          d="M.707,45.524,21.35,54.36a1.14,1.14,0,0,0,.457.094,1.171,1.171,0,0,0,.457-.094l20.643-8.836a1.162,1.162,0,0,0-.044-2.155L22.22,35.518a1.157,1.157,0,0,0-.826,0L.75,43.369a1.162,1.162,0,0,0-.044,2.155Zm21.1-7.677,17.541,6.671L21.807,52.026,4.266,44.519Z"
          transform="translate(0 -35.441)"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M.707,127.422l21.1,9.031,21.1-9.031a1.162,1.162,0,0,0-.914-2.137l-20.185,8.64-20.186-8.64a1.162,1.162,0,1,0-.914,2.137Z"
          transform="translate(-0.001 -112.311)"
        />
        <path
          id="Path_34"
          data-name="Path 34"
          d="M.707,159.821l21.1,9.032,21.1-9.032a1.162,1.162,0,1,0-.914-2.137l-20.185,8.64-20.186-8.64a1.162,1.162,0,1,0-.914,2.137Z"
          transform="translate(-0.001 -140.061)"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M.707,192.224l21.1,9.032,21.1-9.032a1.162,1.162,0,0,0-.914-2.137l-20.185,8.64-20.186-8.64a1.162,1.162,0,1,0-.914,2.137Z"
          transform="translate(-0.001 -167.815)"
        />
      </g>
    </g>
  </svg>
))
