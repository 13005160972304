import React from 'react'
// import { NavLink } from 'react-router-dom'
import { GridWrappingColumn /*Button, ActionSection */ } from 'common'
import CopyrightRegistrationGuide from '../CopyrightRegistrationGuide'

export default function EmptyState() {
  return (
    <GridWrappingColumn maxWidth={876} padding={250}>
      <CopyrightRegistrationGuide />
      {/* <ActionSection>
        <NavLink
          to={{
            pathname: '/registrations/submit/new',
            state: { skip: 'intro' },
          }}
        >
          <Button fixedWidth outline>
            Submit a copyright registration
          </Button>
        </NavLink>
      </ActionSection> */}
    </GridWrappingColumn>
  )
}
