import React, { Component } from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DATA_ELEVIO, REGISTRATION_TYPES, REGISTRATION_TYPES_ENUM, getProxyUrlToMatch } from 'pixsy-constants'
import capitalize from 'lodash/capitalize'
import {
  Button,
  ScrollToTop,
  StickyFloater,
  GridWrappingColumn,
  ContentSection,
  SexySeparator,
  SexyThumbnail,
  Flag,
  ChooseBestImageSource,
} from 'common'
import moment from 'moment'
import ImageDetails from './ImageDetails'
import ImageActions from './ImageActions'
import ImageDetailsForm from './ImageDetailsForm'
import { loadOne, loadSummary, ignore, unignore } from '../../../redux/modules/images'
import { load as loadClusters } from '../../../redux/modules/clusters'
import { loadCases } from '../../../redux/modules/cases'
import { loadRegistrations } from '../../../redux/modules/registrations'
import { redirectWhen } from '../../../decorators'
import getCaseStatusFromSubmission from '../../../helpers/getCaseStatusFromSubmission'
import {
  getImageById,
  assembleClustersForImage,
  assembleCasesForImage,
  assembleRegistrationsForImage,
} from '../../../redux/selectors'
import { IconCases, IconMatches, IconRegister } from '../../App/Navigation/Navigation'

// const log = require('debug')('image:details')

const iconBack = require('../../Matches/V2/QueryBar/backIcon.svg')

const flex = css`
  display: flex;
  > div {
    flex-basis: 0;
    flex-grow: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > div {
      flex-basis: auto;
      flex-grow: auto;
    }
  }
`

const imgWrap = css`
  height: 360px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  > div {
    width: 100%;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`
const details = css`
  margin-left: 5%;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const contentSectionStats = css`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  background-color: inherit;
  border-color: transparent;
  text-align: center;

  @media (max-width: 768px) {
    display: block;
    overflow: hidden;
    margin-top: 10px !important;
    // flex-direction: row;
    // flex-basis: 50%;
    // flex-wrap: wrap;
  }
`

const statCard = css`
  flex: 1;
  padding: 5px;
  border-left: 1px solid #e6e6e6;

  :first-of-type {
    border-left: none;
  }

  @media (max-width: 768px) {
    border-color: transparent;
    float: left;
    width: 50%;
    padding: 15px 5px;
  }

  div {
    line-height: 1.8em;
    font-size: 1.8em;
    font-weight: 600;
  }

  figure {
    line-height: 1em;
    font-size: 1.1em;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
`
const StatCard = ({ value, titleSingular, titlePlural, tooltip }) => (
  <div css={statCard} title={tooltip}>
    <div>{value}</div>
    <figure>{value === 1 ? titleSingular : titlePlural}</figure>
  </div>
)

const noResultsContainer = css`
  margin: 56px 0;
  text-align: center;
  svg {
    fill: #e6e6e6;
    width: 100%;
  }
  div {
    display: inline-block;
  }
  figure {
    font-size: 2.3em;
    font-weight: 600;
  }
`
const NoResultsContainer = ({ type }) => {
  let icon = null,
    width = '',
    text = ''
  switch (type) {
    case 'registrations':
      icon = IconRegister()
      width = '50px'
      text = 'No registrations yet'
      break
    case 'cases':
      icon = IconCases()
      width = '50px'
      text = 'No cases yet'
      break
    case 'matches':
      icon = IconMatches()
      width = '80px'
      text = 'No matches yet'
      break
  }
  return (
    <div css={noResultsContainer}>
      <div style={{ width }}>{icon}</div>
      <figure>{text}</figure>
    </div>
  )
}

const ImageActionsConnected = connect(
  null,
  { ignore, unignore },
  (_, { unignore, ignore }, { image, registrations }) => ({
    image,
    registrations,
    toggleIgnore: image.ignored ? unignore.bind(null, image._id) : ignore.bind(null, image._id),
  })
)(ImageActions)

export class Detail extends Component {
  render() {
    const { image, clusters, registrations, cases, summary, owner, history, editing, user } = this.props

    const stats = []
    const scans = image.job && image.job.history ? image.job.history.length : 0
    stats.push([scans, 'scan', 'scans'])
    if (summary) {
      stats.unshift([summary.matches || 0, 'match', 'matches'])
      if (summary.cases !== undefined) stats.push([summary.cases, 'case', 'cases'])
      if (summary.income !== undefined)
        stats.push([
          `$${summary.income}`,
          'income USD',
          'income USD',
          'NOTE. Any cases resolved in a currency other than USD is converted using the mid-market exchange rate of that date, for display purposes only. The exact amount you received may slightly differ.',
        ])
      if (summary.takedowns !== undefined) stats.push([summary.takedowns, 'takedown', 'takedowns'])
    }

    const ENABLE_COPYRIGHT_REGISTRATION = !global.PRODUCTION || (user.features && user.features.copyrightRegistration)
    const candidates = [
      image.thumbnail,
      image.url,
      `https://accessible.pixsy.io/image/from-url?resize=500&q=${image.url}`,
      `https://accessible.pixsy.io/image/${image._id}`,
      `https://online.pixsy.io/image/from-url?resize=500&q=${image.url}`,
      `https://online.pixsy.io/image/${image._id}`,
    ].filter(Boolean)

    if (image.origin && image.origin.source === 'instagram') {
      let originUrl = image.origin.url
      if (originUrl.endsWith('/')) {
        originUrl = originUrl.slice(0, -1)
      }
      candidates.unshift(`${originUrl}/media?size=l`)
    }

    return (
      <GridWrappingColumn maxWidth={1168} minWidth={876}>
        <Helmet title={`Image | ${image.title}`} />

        <ScrollToTop />

        <StickyFloater hasBackButton>
          <button
            onClick={() => {
              history.length ? history.goBack() : history.push({ pathname: '/images/' })
            }}
            dangerouslySetInnerHTML={{ __html: iconBack }}
            css={backButton}
          />
          <span className="sticky-floater--left-aligned-description" css={mobileTitle}>
            {editing ? 'Editing ' : ''}
            &nbsp;Image&nbsp;
            <b>{image.title}</b>
          </span>
        </StickyFloater>

        <ContentSection>
          <div css={flex}>
            <div css={imgWrap}>
              <ChooseBestImageSource candidates={candidates} type="image">
                {(props) => <SexyThumbnail {...props} />}
              </ChooseBestImageSource>
            </div>

            <div css={details}>
              <ImageDetails image={image} />
              <ImageActionsConnected image={image} registrations={registrations} />
            </div>
          </div>
        </ContentSection>

        <ContentSection customClass={contentSectionStats}>
          {stats.map(([value, titleSingular, titlePlural, tooltip], idx) => (
            <StatCard
              key={`stat${idx}`}
              value={value}
              titleSingular={`${titleSingular}${tooltip ? '*' : ''}`}
              titlePlural={`${titlePlural}${tooltip ? '*' : ''}`}
              tooltip={tooltip}
            />
          ))}
        </ContentSection>

        <ContentSection>
          <h1 data-elevio-selector={DATA_ELEVIO.IMAGES_INFORMATION}>Image Information</h1>
          <SexySeparator />
          <ImageDetailsForm editing={editing} owner={owner} />
        </ContentSection>

        {summary && (
          <ContentSection>
            <MatchesContainerConnected image={image} clusters={clusters} total={summary.matches} />
          </ContentSection>
        )}

        {summary && (
          <ContentSection>
            <CasesContainer image={image} cases={cases} totalCases={summary.cases} />
          </ContentSection>
        )}

        {summary && ENABLE_COPYRIGHT_REGISTRATION && (
          <ContentSection>
            <RegistrationsContainer image={image} registrations={registrations} />
          </ContentSection>
        )}
      </GridWrappingColumn>
    )
  }
}

class RegistrationsContainer extends Component {
  render() {
    const { registrations, image } = this.props

    if (registrations.length === 0) {
      return <NoResultsContainer type="registrations" />
    }

    return (
      <div css={registrationsContainer}>
        <h3>
          {registrations.length} {registrations.length === 1 ? 'Registration' : 'Registrations'}
        </h3>
        <RegisterCardsContainer>
          {registrations.map((r, idx) => (
            <RegisterCard key={`registration_${idx}_${r._id}`}>
              <td>
                <Link to={`/registrations/submit/${r._id}`}>
                  <RegisterCardImage>
                    <ChooseBestImageSource candidates={[image.url]}>
                      {({ url }) => <img src={url} />}
                    </ChooseBestImageSource>
                  </RegisterCardImage>
                </Link>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ color: '#808080' }}>
                  <span>{(r.salesforce && r.salesforce.Name) || 'Incomplete'}</span>
                </div>
                <div
                  style={{
                    fontSize: 24,
                    fontWeight: 800,
                    maxWidth: 400,
                    lineHeight: 0.9,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <span>{image.title}</span>
                </div>
                <div style={{ color: '#808080' }}>
                  <span>{moment(r.created).fromNow()}</span>
                </div>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <div>
                  <span style={{ fontSize: 14, color: '#808080' }}>
                    {(r.salesforce && r.salesforce.WorkType__c) || '-'}
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: 18, fontWeight: 500 }}>
                    {Object.values(r.registrationTypes).map((registrationType) => (
                      <div key={registrationType}>
                        <Flag
                          country={REGISTRATION_TYPES[registrationType.split('_')[0]].flag}
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 5,
                            marginBottom: 4,
                          }}
                        />
                        {REGISTRATION_TYPES_ENUM[registrationType] && REGISTRATION_TYPES_ENUM[registrationType].label}
                      </div>
                    ))}
                  </span>
                </div>
              </td>

              <RegisterCardDetail>
                <RegisterCardDetailLabel>Submitted</RegisterCardDetailLabel>
                <RegisterCardDetailValue>{moment(r.created).format('DD MMMM YYYY')}</RegisterCardDetailValue>
              </RegisterCardDetail>

              <RegisterCardDetail>
                <RegisterCardDetailLabel>Status</RegisterCardDetailLabel>
                <RegisterCardDetailValue>
                  {capitalize((r.status && r.status.replace('_', ' ')) || r.phase || 'DRAFT')}
                </RegisterCardDetailValue>
              </RegisterCardDetail>
            </RegisterCard>
          ))}
        </RegisterCardsContainer>
        <Link to="/registrations/all">
          <Button>Show all registrations</Button>
        </Link>
      </div>
    )
  }
}

RegistrationsContainer.propTypes = {
  registrations: PropTypes.array.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

const registrationsContainer = css`
  text-align: center;
`

const RegisterCardsContainer = styled.table`
  margin-top: 34px;
  width: 100%;
  text-align: left;
  button {
    padding: 7px 30px;
    background-color: transparent;
    border: 2px solid #ccc;
    color: #000;
    opacity: 0.6;
    font-size: 0.8em;
    margin: 0 25px 0 10px;
    transition: all 200ms ease-in;

    &:hover {
      border-color: #008aab;
      opacity: 1;
      background-color: transparent;
    }

    svg {
      height: 14px;
      vertical-align: -3px;
      margin-right: 5px;
    }

    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }

  td {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const RegisterCard = styled.tr`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #e6e6e6;

  &:last-child {
    margin-bottom: 15px;
  }
`

const RegisterCardImage = styled.div`
  width: 115px;
  height: 115px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.02);
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    background-color: rgba(0, 0, 0, 0.02);
  }
`
const RegisterCardDetail = styled.td`
  padding: 15px 5px 0 5px;
  text-align: left;
  vertical-align: middle;
`
const RegisterCardDetailLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.9px;
  line-height: 22px;
  overflow-wrap: break-word;
  text-transform: uppercase;
`
const RegisterCardDetailValue = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`

class MatchesContainer extends Component {
  static FIRST_ROW_COUNT = 4

  render() {
    const { clusters, image, total, user } = this.props
    const showMore = total > MatchesContainer.FIRST_ROW_COUNT

    const matchViewerV3Url = user && user.features && user.features.matches3Available && user.features.matches3Enabled

    if (!total) {
      return <NoResultsContainer type="matches" />
    }

    return (
      <div css={matchesContainer}>
        <h3>
          {total} {total === 1 ? 'Match' : 'Matches'}
        </h3>
        <SexySeparator space={30} />
        <MatchCardsContainer>
          {clusters.slice(...[0, MatchesContainer.FIRST_ROW_COUNT]).map((c, idx) => (
            <MatchCard key={c._id}>
              <MatchCardImage>
                <Link to={`/matches/image:${image._id}/view/${c._id}`}>
                  <ChooseBestImageSource
                    candidates={[
                      ...c.matches.map((m) => m.localUrl),
                      ...c.matches.map((m) => m.fallbackUrl),
                      ...c.matches.map((m) => m.url),
                      ...c.matches.map((m) => getProxyUrlToMatch(m._id)),
                    ]}
                    type="match"
                  >
                    {({ url }) => <SexyThumbnail url={url} />}
                  </ChooseBestImageSource>
                </Link>
                {showMore && idx + 1 === MatchesContainer.FIRST_ROW_COUNT && (
                  <Link to={`/matches/image:${image._id}/view/${c._id}`}>
                    <MatchesMore>+{total - MatchesContainer.FIRST_ROW_COUNT} more</MatchesMore>
                  </Link>
                )}
              </MatchCardImage>
              <MatchUrl>{c.domain.host}</MatchUrl>
              <MatchFound>found {moment(c.created).fromNow()}</MatchFound>
            </MatchCard>
          ))}
        </MatchCardsContainer>
        <Link to={matchViewerV3Url ? `/matches/images:${image._id}` : `/matches/image:${image._id}`}>
          <Button>Show all matches</Button>
        </Link>
      </div>
    )
  }
}

const MatchesContainerConnected = connect((state) => ({
  user: state.auth.user,
}))(MatchesContainer)

const matchesContainer = css`
  text-align: center;
`
const MatchCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  grid-gap: 2.5rem 5.2rem;
  margin-bottom: 30px;
`

const MatchCard = styled.div`
  & .css-puaqcy-wrap {
    height: auto;
  }
`

const MatchCardImage = styled.div`
  position: relative;
`

const MatchUrl = styled.h3`
  margin: 10px 0 -2px;
  font-size: 1.05em;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const MatchFound = styled.span`
  font-size: 0.875em;
  margin: 0;
  font-weight: 300;
  color: rgb(120, 120, 120);
`

const MatchesMore = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  font-size: 1.8em;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`

class CasesContainer extends Component {
  static INITIAL_ITEM_COUNT = 10

  render() {
    const { cases, image, totalCases } = this.props

    if (!totalCases) return <NoResultsContainer type="cases" />

    return (
      <div css={casesContainer}>
        <h3>
          {totalCases} {totalCases === 1 ? 'Case' : 'Cases'}
        </h3>
        <CaseCardsContainer>
          {cases.slice(...[0, CasesContainer.INITIAL_ITEM_COUNT]).map((c, idx) => (
            <CaseCard key={`case_${idx}_${c._id}`}>
              <CaseCardImage>
                <Link to={`/cases/${c._id}`}>
                  <img src={image.url} />
                </Link>
              </CaseCardImage>
              <CaseCardDetail className="caseNo">
                <CaseCardDetailLabel>Case No</CaseCardDetailLabel>
                <CaseCardDetailValue>
                  <Link to={`/cases/${c._id}`}>{c.reference}</Link>
                </CaseCardDetailValue>
              </CaseCardDetail>

              <CaseCardDetail>
                <CaseCardDetailLabel>Domain</CaseCardDetailLabel>
                <CaseCardDetailValue>{c.domain.host}</CaseCardDetailValue>
              </CaseCardDetail>

              <CaseCardDetail>
                <CaseCardDetailLabel>Submitted</CaseCardDetailLabel>
                <CaseCardDetailValue>{moment(c.created).format('DD MMMM YYYY')}</CaseCardDetailValue>
              </CaseCardDetail>

              <CaseCardDetail>
                <CaseCardDetailLabel>Status</CaseCardDetailLabel>
                <CaseCardDetailValue>{getCaseStatusFromSubmission(c)}</CaseCardDetailValue>
              </CaseCardDetail>
            </CaseCard>
          ))}
        </CaseCardsContainer>

        {totalCases > CasesContainer.INITIAL_ITEM_COUNT && (
          <CasesMore>+{totalCases - CasesContainer.INITIAL_ITEM_COUNT} more</CasesMore>
        )}
        <Link to={`/matches/image:${image._id}/submitted:case`}>
          <Button>Show all cases</Button>
        </Link>
      </div>
    )
  }
}

const casesContainer = css`
  text-align: center;
`

const CaseCardsContainer = styled.div`
  margin-top: 34px;
`

const CaseCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #e6e6e6;

  &:last-child {
    margin-bottom: 15px;
  }
`

const CaseCardImage = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  img {
    max-height: 90px;
    max-width: 90px;
  }
`
const CaseCardDetail = styled.div`
  width: 20%;
  padding: 15px 5px 0 5px;
  text-align: left;
`
const CaseCardDetailLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.9px;
  line-height: 22px;
  overflow-wrap: break-word;
  text-transform: uppercase;
`
const CaseCardDetailValue = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;

  .caseNo & {
    font-weight: 600;
    letter-spacing: 1.9px;
  }
`

const CasesMore = styled.div`
  margin: 15px 0;
  font-size: 1.3em;
  color: #c7c7c7;
`

export default asyncConnect(
  [
    {
      promise({
        store: { dispatch, getState },
        match: {
          params: { id },
        },
      }) {
        const entities = getState().entities
        const queries = [loadSummary(id)]

        if (!entities.images[id]) {
          queries.push(loadOne(id))
        }

        if (Object.values(entities.clusters).filter((c) => c.images && c.images.includes(id)).length === 0) {
          queries.push(
            loadClusters({
              tags: [`image:${id}`],
              page: 0,
              pageSize: 4,
            })
          )
        }

        queries.push(
          loadCases({
            filter: `image:${id}`,
            page: '0',
            pageSize: '10',
            // sortDir: 'DESC',
            // sortKey: 'ref',
          })
        )

        if (Object.keys(entities.registrations).length === 0) {
          queries.push(loadRegistrations())
        }

        return Promise.all(queries.map((fn) => dispatch(fn)))
      },
    },
  ],
  (state, props) => {
    return {
      image: getImageById(state, props),
      clusters: assembleClustersForImage(state, props),
      cases: assembleCasesForImage(state, props),
      registrations: assembleRegistrationsForImage(state, props),
      summary: state.images.summary,
      owner: state.auth.user.details.legal_name,
      editing: props.location.pathname.split('/')[3] === 'edit',
    }
  },
  { loadOne, loadClusters, loadRegistrations }
)(
  redirectWhen({
    shouldRedirect: (props) => !props.image,
    to: () => '/images',
  })(Detail)
)

const backButton = css`
  max-width: 87px !important;
  height: 54px !important;
  top: 0;
  border: 0;
  padding: 0 !important;
  line-height: 13px;
  background-color: #008aab;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 24px;
    width: 24px;
  }
`

const mobileTitle = css`
  @media (max-width: 768px) {
    width: calc(100vw - 87px);
    overflow-x: scroll !important;
    display: inline-block;
    text-overflow: unset !important;
    box-sizing: border-box;
  }
`
