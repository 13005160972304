import COUNTRIES from 'pixsy-constants/countries.json'

let tags = []

tags = tags.concat(COUNTRIES.map(c => `country:${c.iso}`))

tags.push('commercial:true')
tags.push('multi:image', 'multi:match')
tags.push('similarity:high', 'similarity:low', 'similarity:medium')
tags.push('hidden:true')
tags.push(
  'category:adult',
  'category:arts',
  'category:business',
  'category:games',
  'category:health',
  'category:home',
  'category:kids-and-teens',
  'category:news',
  'category:reference',
  'category:recreation',
  'category:regional',
  'category:shopping',
  'category:science',
  'category:society',
  'category:sports',
  'category:stock',
  'category:world'
)

export default tags
