import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

const styles = css`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 70px;
  }
  svg {
    width: 120px;
    max-height: 100%;
    path {
      fill: transparent;
      stroke: #1d1d1daa;
      stroke-width: 60px;
    }
    &.at {
      path {
        stroke-width: 10px;
      }
    }
  }
`

export default function CountryOutline({ code }) {
  let raw = ''
  try {
    raw = require(`./assets/${code}.svg`)
  } catch (e) {
    console.error('Unknown country: ' + code)
  }

  return <div className={styles} dangerouslySetInnerHTML={{ __html: raw }} />
}

CountryOutline.propTypes = {
  code: PropTypes.string.isRequired,
}
