import React, { Fragment } from 'react'
import { CollapsedLink } from 'common'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'

export default function TakedownProgress({ takedown: { cluster } }) {
  const getUniqueLinksOfCategory = fn =>
    Array.from(
      new Set(
        cluster.matches
          .filter(fn)
          .map(m => m.url)
          .filter(Boolean)
      )
    )
      .slice(0, 3)
      .sort((a, b) => a.length - b.length)

  const onlineMatches = getUniqueLinksOfCategory(
    m => m.inspection && m.inspection.status === 'online'
  )

  const offlineMatches = getUniqueLinksOfCategory(
    m => m.inspection && m.inspection.status === 'offline'
  )

  const pendingMatches = getUniqueLinksOfCategory(m => !m.inspection)

  return (
    <div css={wrapper}>
      {onlineMatches.length > 0 && (
        <Fragment>
          <div css={colLeft}>
            <span css={cx(statusPill, online)}>ONLINE</span>
          </div>
          <div css={colRight}>
            <ul>
              {onlineMatches.map(originUrl => (
                <li key={originUrl}>
                  <CollapsedLink url={originUrl} />
                </li>
              ))}
            </ul>
          </div>
        </Fragment>
      )}
      {offlineMatches.length > 0 && (
        <Fragment>
          <div css={colLeft}>
            <span css={cx(statusPill, offline)}>REMOVED</span>
          </div>
          <div css={colRight}>
            <ul>
              {offlineMatches.map(originUrl => (
                <li key={originUrl}>
                  <CollapsedLink url={originUrl} secondary />
                </li>
              ))}
            </ul>
          </div>
        </Fragment>
      )}
      {pendingMatches.length > 0 && (
        <Fragment>
          <div css={colLeft} />
          <div css={colRight}>
            <ul>
              {pendingMatches.map(originUrl => (
                <li key={originUrl}>
                  <CollapsedLink url={originUrl} />
                </li>
              ))}
            </ul>
          </div>
        </Fragment>
      )}
    </div>
  )
}

const wrapper = css`
  min-height: 100%;
  display: grid;
  grid-template-columns: 76px 1fr;
  /* grid-template-rows: 1fr 1fr; */
  gap: 1px 1px;
  grid-template-areas: '. .' '. .';
  padding: 20px 0 5px 0px;
`

const colLeft = css`
  text-align: left;
`

const colRight = css`
  text-align: left;
  min-width: 0;
  ul {
    padding: 0;
    list-style: none;
    li {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }
  }
`

const statusPill = css`
  display: inline-block;
  margin-top: 2px;
  padding: 0px 4px;
  font-size: 0.6em;
  color: white;
  letter-spacing: 1.5px;
  font-weight: 600;
  border-radius: 12px;
  vertical-align: middle;
`

const online = css`
  background-color: #ff7f2f;
`
const offline = css`
  background-color: #d9d9d9;
`

TakedownProgress.propTypes = {
  takedown: PropTypes.shape({
    done: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
}
