import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Button, createContactUsHandler } from 'common'

export default function SubmissionValidationDialogDirectHotlink({
  onTakedown,
  onIgnore,
  onClose,
  cluster,
}) {
  return (
    <div css={wrap}>
      <h1>This match is not a viable case</h1>

      <h4>
        Pixsy has detected that this use is a “hotlink” use, and your work is
        not hosted by the website.
      </h4>

      <p>
        While the image may be displayed on the website, the image file is
        hosted somewhere else. According to copyright law in the jurisdictions
        in which we currently operate, we’re only able to hold websites that
        actively host your content responsible for any unauthorized use. If your
        image is hosted elsewhere (for example, hotlinked to your own website),
        we are unable to accept this match for Pixsy Case Resolution.
      </p>

      <p>
        Pixsy uses a set of algorithms to pre-determine if a case is suitable
        for resolution. We do this to save you time, and to ensure that our case
        management team can focus their efforts on recovering compensation for
        viable commercial cases.
      </p>

      <div className="dialog-slider--button-wrapper">
        <Button onClick={createContactUsHandler('Direct Hotlink')} greyTertiary>
          Contact us
        </Button>
        <Button grey onClick={onIgnore}>
          Ignore Match
        </Button>
        <Button grey onClick={onTakedown}>
          Send Takedown
        </Button>
      </div>
      {!global.PRODUCTION && (
        <p className="debug">SubmissionValidationDialogDirectHotlink</p>
      )}
    </div>
  )
}

SubmissionValidationDialogDirectHotlink.propTypes = {
  cluster: PropTypes.object.isRequired,
  onTakedown: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const wrap = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
`
