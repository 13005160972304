import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import {
  Button,
  TextField,
  VerticalRadioGroup,
  ClosedQuestionRadio,
  AlignLabels,
} from 'common'
import { css } from 'react-emotion'
import { updateUser } from '../../../redux/modules/auth'
import { get } from 'lodash'

export function ShopQuestionaire({ valid, submitting, handleSubmit }) {
  return (
    <form css={content} onSubmit={handleSubmit}>
      <h1>Profile Type</h1>

      <span>Your background helps us to personalize our service.</span>

      <div css={formWrap}>
        <AlignLabels width="50%">
          <Field
            name="shop.media"
            label="What type of work are you interesting in monitoring & protecting?"
            component={VerticalRadioGroup}
            items={[
              {
                value: 'Product Images',
                label: 'Product Images',
              },
              {
                value: 'Designs',
                label: 'Designs',
              },
              {
                value: 'Logos',
                label: 'Logos',
              },
              {
                value: 'Other',
                label: 'Other',
              },
            ]}
          />
          <Field
            name="shop.web"
            label="Are these works sold and/or posted online? If yes, where?"
            component={TextField}
          />
          <Field
            name="shop.registered"
            label="Have you registered any works at an official copyright office"
            component={ClosedQuestionRadio}
            normalize={normalizeBoolean}
          />
        </AlignLabels>
      </div>

      <Button submit disabled={!valid || submitting}>
        Continue
      </Button>
    </form>
  )
}

ShopQuestionaire.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const ShopQuestionaireWrapper = reduxForm({
  form: 'ShopQuestionaire',
  validate: values => ({
    shop: {
      ...(undefined === get(values, 'shop.media') && {
        media: 'Choice required',
      }),
      ...(undefined === get(values, 'shop.registered') && {
        registered: 'Choice required',
      }),
    },
  }),
  onSubmit: (values, dispatch, { onComplete }) =>
    dispatch(updateUser(values)).then(onComplete),
})(ShopQuestionaire)

ShopQuestionaireWrapper.eligible = user => user.type === 'shop' && !user.shop

export default ShopQuestionaireWrapper

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const formWrap = css`
  width: 100%;
  padding: 15px 0 15px 0;
`

function normalizeBoolean(value) {
  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  return null
}
