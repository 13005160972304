import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ExitPrompt, Dialog } from 'common'
import { withRouter } from 'react-router'
import SubmissionExitPrompt from './SubmissionExitPrompt'
import { PHASE } from '../../../../../shared/forms/registrationSubmission'

export class SubmissionFormNavigationBehavior extends Component {
  state = { blockExit: false }

  componentWillReceiveProps(nextProps) {
    const { blockExit } = this.state
    const { dirty, submitting, phase, registrationId } = this.props

    if (
      (!registrationId || registrationId === 'new') &&
      nextProps.registrationId !== 'new'
    ) {
      return this.setState({ blockExit: false })
    }

    if (registrationId === nextProps.registrationId) {
      return this.setState({ blockExit: false })
    }

    if (!blockExit && !dirty && nextProps.dirty && phase !== PHASE.FINAL) {
      this.setState({ blockExit: true })
    }
    if (blockExit === true && !submitting && nextProps.submitting) {
      this.setState({ blockExit: false })
    }
  }

  navigateToProfile = () => {
    const { history, dirty, valid, onSave } = this.props

    const doNavigate = () =>
      history.push({
        pathname: '/profile',
        state: {
          forceContactValidation: 1,
        },
      })

    const afterSave = () => this.setState({ blockExit: false }, doNavigate)

    if (!dirty) {
      afterSave()
    } else {
      onSave(() => {
        if (valid) {
          afterSave()
        }
      })
    }
  }

  renderExitPrompt = ({ isOpen, onConfirm, onCancel }) => {
    return (
      <Dialog isOpen={isOpen} onRequestClose={onCancel}>
        <SubmissionExitPrompt
          onCancel={onCancel}
          onNavigate={onConfirm}
          onSave={() => {
            onCancel()
            this.props.onSave()
          }}
        />
      </Dialog>
    )
  }

  render() {
    const { blockExit } = this.state

    return (
      <React.Fragment>
        <ExitPrompt when={blockExit} render={this.renderExitPrompt} />
        {this.props.children({ navigateToProfile: this.navigateToProfile })}
      </React.Fragment>
    )
  }
}

export default withRouter(SubmissionFormNavigationBehavior)

SubmissionFormNavigationBehavior.propTypes = {
  history: PropTypes.object.isRequired,
  valid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
}
