// 2. validation/check
import { MatchViewer } from 'common'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import isEmpty from 'lodash/isEmpty'
import * as React from 'react'
import { ScenarioDomainDuplicate } from './Scenarios/ScenarioDomainDuplicate'
import { ScenarioDomainStock } from './Scenarios/ScenarioDomainStock'
import { ScenarioDomainTLDBlocked } from './Scenarios/ScenarioDomainTLDBlocked'
import { ScenarioDomainUnresolvable } from './Scenarios/ScenarioDomainUnresolvable'
import { ScenarioMatchOriginApproved } from './Scenarios/ScenarioMatchOriginApproved'
import { ScenarioMatchOriginOffline } from './Scenarios/ScenarioMatchOriginOffline'

/**
 * Example of payload:
 *
 * [
 *  {
 *    resolvable: false,
 *    data: {
 *      ignored: ['5cde8c1c22dea20015b228cb'],
 *      host: 'picsum.photos',
 *      type: 'matchHasNotBeenSubmittedBefore',
 *    },
 *    reason: 'Heads up! You have previously submitted a case for this domain.',
 *    field: 'INFRINGING_PAGE',
 *    type: 'warning',
 *  },
 *]
 *
 * - Check `isMatchFragmentResolvable.js`
 * - `data.type` is used to associate an error type with a component
 *
 * @todo
 *
 * Currently gets only one error at a time from `/cases/validate`
 * it should be changed for v6 to return an array of all errors that failed
 * (`isMatchFragmentResolvable` returns first error found only, adapt for v6 to return all failed errors)
 *
 * @augments {React.Component<import('../StageProps').IProps, {}>}
 */
export class CheckStage extends React.Component {
  ERROR_KEYS_REGEXP = [
    /matches\[+[0-9]+\].origin.url/,
    /matches\[+[0-9]+\].url/,
  ]
  ASYNC_DOMAIN_COMPONENTS = {
    matchOriginTLDValid: ScenarioDomainTLDBlocked,
    domainIsNotStock: ScenarioDomainStock,
    domainIsResolvable: ScenarioDomainUnresolvable,
  }
  ASYNC_ONLINE_COMPONENTS = {
    matchOriginURLValid: ScenarioMatchOriginOffline,
    matchOriginNotApproved: ScenarioMatchOriginApproved,
  }
  ASYNC_HISTORY_COMPONENTS = {
    matchHasNotBeenSubmittedBefore: ScenarioDomainDuplicate,
  }

  handleIgnoreError(validationError, errors) {
    if (!isPlainObject(validationError) || !isPlainObject(errors)) return

    const {
      clearFieldError,
      setFieldError,
      manuallyValidateWholeForm,
      ignoreAsyncError,
    } = this.props

    if (
      isPlainObject(errors) &&
      isPlainObject(errors.message) &&
      isArray(errors.message.payload)
    ) {
      const nextPayload = errors.message.payload.filter(
        e =>
          !(
            isPlainObject(e) &&
            isPlainObject(e.data) &&
            e.data.type === validationError.data.type
          )
      )

      ignoreAsyncError(validationError.data)
      if (isEmpty(nextPayload)) {
        clearFieldError(errors.path)
      } else {
        setFieldError(errors.path, {
          ...errors,
          message: {
            ...errors.message,
            payload: nextPayload,
          },
        })
      }
      manuallyValidateWholeForm()
    }
  }

  /**
   * @TODO
   * These next three methods look awfully similar, it could be simplified
   */

  getDomainStatus = () => {
    const { LOADING, VALID, INVALID } = MatchViewer.Status
    const {
      asyncResolvableState: { errors, status, isOkay },
    } = this.props

    if (status.matchOriginUrl) return { status: LOADING }
    if (isOkay) return { status: VALID }

    if (
      isPlainObject(errors.matchOriginUrl) &&
      isPlainObject(errors.matchOriginUrl.message) &&
      isArray(errors.matchOriginUrl.message.payload)
    ) {
      const { payload } = errors.matchOriginUrl.message
      const knownError = payload.find(res => {
        return (
          isPlainObject(res) &&
          isPlainObject(res.data) &&
          !!this.ASYNC_DOMAIN_COMPONENTS[res.data.type]
        )
      })

      if (knownError) {
        return {
          status: INVALID,
          validationError: knownError,
          errors: errors.matchOriginUrl,
          Component: this.ASYNC_DOMAIN_COMPONENTS[knownError.data.type],
        }
      }
    }

    return {
      status: VALID,
    }
  }

  getOnlineStatus = () => {
    const { LOADING, VALID, INVALID } = MatchViewer.Status
    const {
      asyncResolvableState: { errors, status, isOkay },
    } = this.props

    if (status.matchOriginUrl) return { status: LOADING }
    if (isOkay) return { status: VALID }

    if (
      isPlainObject(errors.matchOriginUrl) &&
      isPlainObject(errors.matchOriginUrl.message) &&
      isArray(errors.matchOriginUrl.message.payload)
    ) {
      const { payload } = errors.matchOriginUrl.message
      const knownError = payload.find(res => {
        return (
          isPlainObject(res) &&
          isPlainObject(res.data) &&
          !!this.ASYNC_ONLINE_COMPONENTS[res.data.type]
        )
      })

      if (knownError) {
        return {
          status: INVALID,
          validationError: knownError,
          errors: errors.matchOriginUrl,
          Component: this.ASYNC_ONLINE_COMPONENTS[knownError.data.type],
        }
      }
    }

    return {
      status: VALID,
    }
  }

  getHistoryStatus = () => {
    const { LOADING, VALID, INVALID } = MatchViewer.Status
    const {
      asyncResolvableState: { errors, status, isOkay },
    } = this.props

    if (status.matchOriginUrl) return { status: LOADING }
    if (isOkay) return { status: VALID }

    if (
      isPlainObject(errors.matchOriginUrl) &&
      isPlainObject(errors.matchOriginUrl.message) &&
      isArray(errors.matchOriginUrl.message.payload)
    ) {
      const { payload } = errors.matchOriginUrl.message
      const knownError = payload.find(res => {
        return (
          isPlainObject(res) &&
          isPlainObject(res.data) &&
          !!this.ASYNC_HISTORY_COMPONENTS[res.data.type]
        )
      })

      if (knownError) {
        return {
          status: INVALID,
          validationError: knownError,
          errors: errors.matchOriginUrl,
          Component: this.ASYNC_HISTORY_COMPONENTS[knownError.data.type],
        }
      }
    }

    return {
      status: VALID,
    }
  }

  render() {
    const {
      editableImages,
      history,
      clusterId,
      values: { source },
    } = this.props

    const OnlineStatus = this.getOnlineStatus()
    const DomainStatus = this.getDomainStatus()
    const HistoryStatus = this.getHistoryStatus()

    const { Component, errors, validationError } =
      [OnlineStatus, DomainStatus, HistoryStatus].find(c =>
        Boolean(c.Component)
      ) || {}

    return (
      <MatchViewer
        imagesCount={editableImages.length}
        onlineStatus={OnlineStatus.status}
        domainStatus={DomainStatus.status}
        historyStatus={HistoryStatus.status}
      >
        {Component && (
          <Component
            onConfirm={() => this.handleIgnoreError(validationError, errors)}
            onTakedown={() =>
              history.replace(`/takedowns/submit/new?from=${clusterId}`)
            }
            showMatchActions={source === 'MATCH'}
          />
        )}
      </MatchViewer>
    )
  }
}
