import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

export default function SexySeparator({
  label,
  width,
  lineColor,
  labelStyles,
  space,
  ...props
}) {
  return (
    <SexySeparatorWrap w={width} space={space} {...props}>
      <StyledHr color={lineColor} />
      {label && (
        <StyledLabel
          color={labelStyles.color}
          background={labelStyles.backgroundColor}
        >
          {label}
        </StyledLabel>
      )}
    </SexySeparatorWrap>
  )
}

const SexySeparatorWrap = styled.div`
  display: block;
  height: 2px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: ${props => props.w};
  margin-top: ${props => props.space}px;
  margin-bottom: ${props => props.space}px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`

const StyledHr = styled.hr`
  border-top-style: solid;
  border-top-width: 1px;
  margin: 0;
  background-color: transparent;
  border-top-color: ${props => props.color};
`

const StyledLabel = styled.label`
  color: ${props => props.color};
  background-color: ${props => props.background};
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  padding: 0 40px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2.5px;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`

SexySeparator.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  lineColor: PropTypes.string,
  space: PropTypes.number,
  labelStyles: PropTypes.object,
}

SexySeparator.defaultProps = {
  width: '100%',
  lineColor: '#F0F0F0',
  space: 0,
  labelStyles: {
    color: '#46464680',
    backgroundColor: '#fcfcfc',
  },
}
