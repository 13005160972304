import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { css } from 'react-emotion'
import {
  humanize,
  GOOGLE,
  DROPBOX,
  UPLOAD,
  FLICKR,
  INSTAGRAM,
  EYEEM,
  PHOTOSHELTER,
  PHOTODECK,
  SMUGMUG,
  TUMBLR,
  SHOPIFY,
  ZENFOLIO,
} from 'pixsy-constants'
import { SettingsIcon } from 'common'

class ImportCard extends Component {
  constructor({ service, blurred }) {
    super()
    const DISABLED_IMPORTS = global.DISABLED_IMPORTS || ''
    this._disabled = blurred || DISABLED_IMPORTS.split(',').includes(service)
  }
  render() {
    const { service, onRemove, onUpdate, onConfig, connected, blurred, loading, loadingService } = this.props
    const { image, title, subtitle } = resolvePropertiesForImport(service)

    const removeIcon = require(`./removeIcon.svg`)
    const refreshIcon = require(`./refreshIcon.svg`)

    const isServiceLoading = loading && service === loadingService

    return (
      <div
        className={cn(cardProvider, {
          [cardProviderBlurred]: blurred,
          [cardProviderDisabled]: this._disabled,
          [cardProviderConnected]: connected,
          [borderStyleLoading]: isServiceLoading,
        })}
        {...(this._disabled && {
          title: `Import from ${humanize(service)} will be back soon!`,
        })}
      >
        <div className={iconBox}>
          {!this._disabled /* disabled can be removed, but not synced */ && connected && onUpdate && (
            <span
              dangerouslySetInnerHTML={{ __html: refreshIcon }}
              className={cn(leftIconStyle, {
                [leftIconStyleLoading]: isServiceLoading,
              })}
              aria-hidden="true"
              onClick={!isServiceLoading ? onUpdate : () => ({})}
            />
          )}
          {connected && onRemove && (
            <span
              dangerouslySetInnerHTML={{ __html: removeIcon }}
              className={rightIconStyle}
              aria-hidden="true"
              onClick={onRemove}
            />
          )}
        </div>
        {subtitle && <p className={subtitleStyle}>{subtitle}</p>}

        <div className={serviceImageHolder}>{image && image()}</div>
        <h4 style={this.props.titleStyle}>{title}</h4>

        {connected && (
          <div className={connectedIconHolder}>
            <IconConnected />
          </div>
        )}
        {connected && onConfig && (
          <div className={bottomRightIconStyle} onClick={onConfig}>
            <SettingsIcon />
          </div>
        )}
      </div>
    )
  }
}

ImportCard.propTypes = {
  /** Title text of the box */
  /** Title text of the box */

  connected: PropTypes.bool,
  service: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
}

export default ImportCard

function resolvePropertiesForImport(key) {
  return (
    {
      [GOOGLE]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/google-drive.png" />,
        title: humanize(GOOGLE), //'Drive',
        subtitle: 'Import from',
      },
      [DROPBOX]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/dropbox.png" />,
        title: humanize(DROPBOX), //'Dropbox',
        subtitle: 'Import from',
      },
      [UPLOAD]: {
        image: () => <IconComputer />,
        title: humanize(UPLOAD), //'Computer',
        subtitle: 'Upload from',
      },
      [FLICKR]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/flickr.png" />,
        title: humanize(FLICKR), //'FLICKR',
      },
      [SHOPIFY]: {
        image: () => <IconShopify />,
        title: humanize(SHOPIFY), //'SHOPIFY',
      },
      [INSTAGRAM]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/instagram.png" />,
        title: humanize(INSTAGRAM), //'INSTAGRAM',
      },
      [EYEEM]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/eyeem.png" />,
        title: humanize(EYEEM), //'EYEEM',
      },
      [PHOTOSHELTER]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/photoshelter.png" />,
        title: humanize(PHOTOSHELTER), //'PHOTOSHELTER',
      },
      [PHOTODECK]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/photodeck.png" />,
        title: humanize(PHOTODECK), //'PHOTODECK',
      },
      [SMUGMUG]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/smugmug.png" />,
        title: humanize(SMUGMUG), //'SMUGMUG',
      },
      [TUMBLR]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/tumblr.png" />,
        title: humanize(TUMBLR), //'TUMBLR',
      },
      [ZENFOLIO]: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/zenfolio.png" />,
        title: humanize(ZENFOLIO), //'ZENFOLIO',
      },
      ftp: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/ftp.png" />,
        title: 'FTP',
      },
      'aws-s3': {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/aws.png" />,
        title: 'AWS S3',
      },
      api: {
        image: () => <img src="https://s3.amazonaws.com/static.pixsy.io/services/api.png" />,
        title: 'API',
      },
    }[key] || {}
  )
}

function IconShopify() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 0 173 173">
      <path
        fill="#95BF47"
        d="M130.6 33c-.1-.9-.9-1.3-1.5-1.4-.6-.1-13.7-1-13.7-1s-9.1-9-10-10c-1-1-2.9-.7-3.7-.5-.1 0-2 .6-5.1 1.6C93.7 13 88.3 5 78.9 5h-.8c-2.7-3.5-6-5.1-8.8-5.1C47.4 0 37 27.3 33.7 41.2c-8.5 2.6-14.5 4.5-15.3 4.7-4.7 1.5-4.9 1.6-5.5 6.1C12.4 55.5 0 151.5 0 151.5l96.7 18.1 52.4-11.3c0-.1-18.4-124.4-18.5-125.3zm-39.3-9.6c-2.4.8-5.2 1.6-8.2 2.5v-1.8c0-5.4-.8-9.8-2-13.2 4.9.7 8.2 6.2 10.2 12.5zM75.2 12c1.3 3.4 2.2 8.2 2.2 14.7v.9c-5.3 1.6-11.1 3.4-16.9 5.2 3.3-12.4 9.4-18.5 14.7-20.8zm-6.5-6.1c.9 0 1.9.3 2.8.9-7 3.3-14.5 11.6-17.7 28.2-4.6 1.4-9.2 2.8-13.4 4.1C44.2 26.5 53 5.9 68.7 5.9z"
      />
      <path
        fill="#5E8E3E"
        d="M129.1 31.6c-.6-.1-13.7-1-13.7-1s-9.1-9-10-10c-.4-.4-.9-.6-1.4-.6l-7.3 149.6 52.4-11.3S130.7 33.9 130.6 33.1c-.1-.9-.9-1.4-1.5-1.5z"
      />
      <path
        fill="#FFF"
        d="M78.9 60.6l-6.5 19.2s-5.7-3-12.6-3c-10.2 0-10.7 6.4-10.7 8 0 8.8 22.9 12.1 22.9 32.7 0 16.2-10.3 26.6-24.1 26.6-16.6 0-25.1-10.3-25.1-10.3l4.4-14.7s8.7 7.5 16.1 7.5c4.8 0 6.8-3.8 6.8-6.6 0-11.5-18.8-12-18.8-30.8C31.3 73.4 42.7 58 65.6 58c9 .1 13.3 2.6 13.3 2.6z"
      />
    </svg>
  )
}

function IconComputer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10348.4 -22512 61.856 45">
      <defs>
        <style>
          {`.svg-import-computer {
        fill: none;
      }`}
        </style>
      </defs>
      <g id="Group_301" data-name="Group 301" transform="translate(-11182.4 -22877)">
        <g id="picture" transform="translate(854.022 374.606)">
          <path
            id="Path_186"
            data-name="Path 186"
            className="svg-import-computer"
            d="M0,0H20.952V21.863H0Z"
            transform="translate(-0.029)"
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M10.763,17.664a2.332,2.332,0,1,0-2.332-2.332A2.334,2.334,0,0,0,10.763,17.664Zm0-3.826a1.494,1.494,0,1,1-1.494,1.495A1.5,1.5,0,0,1,10.763,13.837Z"
            transform="translate(-5.322 -7.556)"
          />
          <path
            id="Path_31"
            data-name="Path 31"
            d="M1,0V21.775H21.937V0Zm.837.837H21.1V12.029L16.777,8.066a.43.43,0,0,0-.3-.11.42.42,0,0,0-.291.136l-4.1,4.492L10.1,10.6a.419.419,0,0,0-.573-.018L2.517,16.75H1.837ZM21.1,20.937H1.837v-3.35H21.1ZM3.784,16.75l6-5.281L14.1,15.782a.419.419,0,0,0,.592-.592l-2.013-2.013,3.844-4.21,4.58,4.2V16.75Z"
            transform="translate(-1.003)"
          />
        </g>
        <g id="monitor" transform="translate(834 300.824)">
          <path
            id="Path_206"
            data-name="Path 206"
            d="M58.974,64.176H0V105.6H22.364l-1.039,2.572H17.153v1.007H44.7v-1.007H40.532L39.493,105.6H61.857V64.176H58.974ZM38.4,108.169H23.456L24.5,105.6H37.361Zm21.486-4.586H1.97V66.19H59.887Z"
          />
        </g>
      </g>
    </svg>
  )
}

function IconConnected() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-7868 -22830 27 27">
      <defs>
        <style>
          {`.svg-imports-icon-connected-a{fill:#008aab;}.svg-imports-icon-connected-b{fill:none;stroke:#fff;stroke-width:2px;}`}
        </style>
      </defs>
      <g transform="translate(-8929 -23253)">
        <circle className="svg-imports-icon-connected-a" cx="13.5" cy="13.5" r="13.5" transform="translate(1061 423)" />
        <path
          className="svg-imports-icon-connected-b"
          d="M6573.779,3223.06l4.262,4.262,4.521-4.519,6.3-6.3"
          transform="translate(-5506.469 -2785.643)"
        />
      </g>
    </svg>
  )
}

const iconBox = css`
  display: flex;
  width: 100%;
  padding: 16px 10px 0;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;

  span {
    top: 3px;
    font-size: 15px;
  }
`

const cardProviderDisabled = css`
  opacity: 0.4;
  filter: grayscale(100%);
`

const cardProvider = css`
  position: relative;
  height: 150px;
  margin-bottom: 14px;
  padding-bottom: 10px;
  text-decoration: none;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ededed;
  user-select: none;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .${iconBox} {
    height: 15px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    opacity: 0.6;

    span {
      line-height: 0em;
    }

    svg {
      height: 20px;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.21;
    letter-spacing: 0;
    color: rgba(38, 38, 38, 1);
    margin-top: 0px;
  }

  p {
    font-size: 10px;
    font-weight: 600;
    color: rgba(147, 147, 147, 1);
    width: 100%;
    margin: 0;
    margin-bottom: 3px;
  }

  img {
    align-self: center;
    width: 100%;
  }

  &:hover {
    border: 2px solid #008aab;
    .iconBox {
      opacity: 1;
    }
  }

  &:not(.${cardProviderDisabled}) {
    cursor: pointer;
  }
`

const cardProviderConnected = css`
  &:not(.${cardProviderDisabled}) {
    cursor: pointer;
    border: 3px solid #008aab;
  }
  border: 3px solid #008aab;
`

const leftIconStyle = css`
  position: absolute;
  left: 5px;
  vertical-align: bottom;
  color: #008aab;
  align-self: flex-end;
  cursor: pointer;
`

const leftIconStyleLoading = css`
  animation-name: iconLoading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;

  @keyframes iconLoading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const borderStyleLoading = css`
  animation-name: borderLoading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  /* change opacity to give a blinking effect */
  @keyframes borderLoading {
    0% {
      border-color: #008aab2f;
    }
    100% {
      border-color: #008aabff;
    }
  }
`

const cardProviderBlurred = css`
  filter: grayscale(1);
  opacity: 0.5;
  .${leftIconStyle} {
    visibility: hidden;
  }
`
const rightIconStyle = css`
  position: absolute;
  right: 5px;
  cursor: pointer;
`

const bottomRightIconStyle = css`
  position: absolute;
  right: 4px;
  bottom: 0;
  cursor: pointer;
  width: 24px;
  svg {
    height: 24px;
  }
`

const subtitleStyle = css`
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
`

const serviceImageHolder = css`
  max-width: 60px;
  height: 45px;
  user-select: none;

  img,
  svg {
    height: 100%;
    width: auto;
  }
`

const connectedIconHolder = css`
  position: absolute;
  display: block;
  bottom: -10px;
  left: -10px;
  height: 26px;
  width: 26px;
`
