import * as React from 'react'
import { PixsyPaper, PixsyGrid, PixsyGridItem } from '../../../Components'
import { US_USCO_STATUS_ENUM } from 'pixsy-schema/case/RegistrationSchema'
import moment from 'moment'
import { SexyThumbnail } from 'common'
import { css, cx } from 'react-emotion'

const generateCopyrightMessage = ({ cc, ccType }, usco) => {
  let s = []
  if (cc) {
    s.push(`Creative Commons${ccType ? ` ${ccType}` : ''}.`)
  } else {
    s.push('No Creative Commons.')
  }
  if (usco && usco !== US_USCO_STATUS_ENUM.No) {
    s.push(` Registered with the US Copyright Office.`)
  } else {
    s.push(' No US Copyright Registration.')
  }
  return s
}

export const ImageInformation = React.memo(
  ({
    image: {
      title,
      date_taken: created,
      place_taken: place,
      registration: {
        us: { status: usco },
      },
      licensing: { cc, license: ccType, price, first_published: published },
      url,
    },
    match: { attribution_provided: attributionProvided },
    onClick,
    disabled,
  }) => (
    <PixsyPaper padding="30px 60px 30px 60px">
      <PixsyGrid alignItems="center" spacing={1}>
        <PixsyGridItem xs={5} sm={4} lg={3}>
          <SexyThumbnail url={url} />
        </PixsyGridItem>
        <PixsyGridItem xs={9} sm={8} lg={6}>
          <ul css={listStyle}>
            <h5>TITLE</h5>
            <h3>{title}</h3>
            <p>
              {`Created on ${moment(created).format('DD.MM.YYYY')}${
                place ? `, in ${place}` : ''
              }`}
            </p>
            {published.date && (
              <p>
                Published on{' '}
                {published.date && moment(published.date).format('DD.MM.YYYY')}
              </p>
            )}
            {published.place && <p>Published location: {published.place}</p>}
            {generateCopyrightMessage({ cc, ccType }, usco).map(msg => (
              <p key={msg}>{msg}</p>
            ))}
            <p>
              {price.value
                ? `Public pricing: ${price.currency} ${price.value.toFixed(2)}`
                : 'No public pricing'}
            </p>
            {cc && (
              <p>
                {attributionProvided
                  ? 'Attribution provided'
                  : 'No attribution provided'}
              </p>
            )}
          </ul>
        </PixsyGridItem>
        <PixsyGridItem xs={12} lg={3}>
          <button
            className={cx(buttonStyle, disabled && disabledButtonStyle)}
            onClick={() => !disabled && onClick()}
          >
            EDIT
            <br />
            INFORMATION
          </button>
        </PixsyGridItem>
      </PixsyGrid>
    </PixsyPaper>
  )
)

const listStyle = css`
  padding: 0;
  margin: 0 0 0 20px;
  h3 {
    margin: 0 0 4px;
    font-size: 1.3em;
    font-weight: 500;
  }
  h5 {
    font-size: 0.75em;
    margin: 20px 0 5px 0;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: rgb(151, 151, 151, 0.8);
  }
  p {
    color: #6c6c6c;
    margin: 2px 0;
    word-break: break-all;
  }
`

const buttonStyle = css`
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  background-color: hsl(0, 0%, 96%);
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-size: 0.9em;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  transition: all 200ms ease;
  &:hover {
    background-color: hsl(0, 0%, 85%);
    text-decoration: none;
  }
`

const disabledButtonStyle = css`
  cursor: not-allowed;
`
