import { original, produce } from 'immer'
import getPath from 'lodash/get'
import setPath from 'lodash/set'
import { getMeaningfulErrors, isValidationError } from 'pixsy-schema/core'
import PropTypes from 'prop-types'
import * as React from 'react'

export const PixsyFormContext = React.createContext({})

/**
 * @typedef {import('./PixsyForm').PixsyFormValues} Values
 * @typedef {import('./PixsyForm').PixsyFormContext} FormContext
 * @typedef {import('./PixsyForm').IProps<Values, FormContext>} IProps
 * @typedef {import('./PixsyForm').IState<Values, FormContext>} IState
 */

/**
 * PixsyForm: Form builder framework
 *
 *  - Provides an api using React context which is accessed by:
 *    -> PixsyTextField
 *    -> PixsyBooleanField
 *    -> PixsyCheckbox
 *    -> PixsyErrorMessage
 *    -> PixsyRadioGroup
 *  ... to set values and trigger validation
 *
 *  Props:
 *      schema - the schema to use to validate the fields set by Pixsy fields components
 *      initialValues - initial values object, reflected on Pixsy fields components
 *      render - called on `render` providing its api as first argument
 *      context - the context data to pass to the schema when validating fields
 *
 * @see CaseSubmissionProvider.js
 * @see ProfileStage.js
 * @augments {React.Component<IProps, IState>}
 */
export class PixsyForm extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      initialValues: this.props.initialValues,
      values: this.props.initialValues,
      touched: {},
      errors: {},
      validating: {},
      isFormPristine: true,
      isFormReadOnly: this.props.isFormReadOnly || false,
      isFormSubmitting: false,
      isFormValidating: false,
      schema: this.props.schema,
      context: this.props.context || {},
    }
  }

  listenersOnBlurEvent = []

  /**
   * @type {(producerFunction: (draft: IState, prevState: IState) => IState, cb: () => void) => void}
   */
  setImmutableState = (producerFunction, cb) => {
    this.setState(prevState => {
      const nextState = produce(prevState, producerFunction)
      if (prevState === nextState) return null
      return nextState
    }, cb)
  }

  isMounted = false

  componentDidMount() {
    this.isMounted = true
  }
  componentWillUnmount() {
    this.isMounted = false
  }

  setFormReadOnly = value => {
    this.setImmutableState(draft => {
      draft.isFormReadOnly = !!value
    })
  }

  setFieldValue = (path, value, keepPristine) => {
    this.setImmutableState(draft => {
      setPath(draft.values, path, value)
      if (!keepPristine) {
        draft.isFormPristine = false
      }
    })
  }

  setFormValues = nextValues => {
    this.setImmutableState(draft => {
      const keys = Object.keys(nextValues)

      for (const k of keys) {
        draft.values[k] = nextValues[k]
      }
      draft.isFormPristine = false
    })
  }

  setFieldTouched = (path, value) => {
    this.setImmutableState(draft => {
      setPath(draft.touched, path, !!value)
      draft.isFormPristine = false
    })
  }

  setFieldError = (path, error) => {
    this.setImmutableState(draft => {
      setPath(draft.errors, path, error)
    })
  }

  clearFieldError = path => {
    this.setImmutableState(draft => {
      delete draft.errors[path]
    })
  }

  getValue = path => {
    return getPath(this.state.values, path)
  }

  getTouched = path => {
    return getPath(this.state.touched, path)
  }

  handleChange = (path, value, keepPristine) => {
    this.setImmutableState(draft => {
      setPath(draft.values, path, value)
      if (!keepPristine) {
        setPath(draft.touched, path, true)
        draft.isFormPristine = false
      }
    })
  }

  setSubmitting = (value, cb) => {
    this.setState({ isFormSubmitting: !!value }, cb)
  }

  setContext = context => {
    this.setState({ context })
  }

  setPristine = value => {
    this.setState({ isFormPristine: !!value })
  }

  handleValidationSuccessOnPathPromise = (path, validationPromise) => {
    if (this.isMounted) {
      this.setImmutableState(draft => {
        const { validating, errors } = draft

        if (validating[path] !== validationPromise) return

        delete validating[path]
        delete errors[path]

        draft.isFormValidating = !!Object.keys(validating).length
      })
    }
  }

  handleValidationErrorOnPathPromise = (path, reason, validationPromise) => {
    if (this.isMounted) {
      if (!isValidationError(reason)) {
        this.setImmutableState(draft => {
          const { validating } = draft

          if (validating[path] !== validationPromise) return

          delete validating[path]

          draft.isFormValidating = !!Object.keys(validating).length
        })
        return console.warn(reason)
      }

      const nextErrors = getMeaningfulErrors(reason)
      const firstError = nextErrors.length ? nextErrors[0] : null

      this.setImmutableState(draft => {
        const { validating, errors } = draft

        if (validating[path] !== validationPromise) return

        delete validating[path]

        if (firstError) {
          errors[path] = firstError
        } else {
          console.warn(
            `Validation on path "${path}" failed without providing an error. Dev fault.`
          )
        }

        draft.isFormValidating = !!Object.keys(validating).length
      })
    }
  }

  makeValidationPathPromise = (schema, path, values, context) => {
    const validationPromise = schema
      .validateAt(path, values, {
        abortEarly: true, // Catch only first error
        context,
      })
      .then(() =>
        this.handleValidationSuccessOnPathPromise(path, validationPromise)
      )
      .catch(reason =>
        this.handleValidationErrorOnPathPromise(path, reason, validationPromise)
      )
    return validationPromise
  }

  handleValidationPath = (path, fieldContext) => {
    const { schema } = this.state

    this.setImmutableState(draft => {
      const spread = produce(Object.assign)
      const values = original(draft.values)
      const context = spread(
        original(draft.context) || {},
        fieldContext || {},
        { values }
      )

      draft.isFormValidating = true
      draft.validating[path] = this.makeValidationPathPromise(
        schema,
        path,
        values,
        context
      )
    })
  }

  makeValidationFormPromise = (
    path,
    onSuccessCallback,
    onErrorCallback,
    values,
    context
  ) => {
    const { schema } = this.state

    const validationPromise = schema
      .validate(values, { abortEarly: false, context })
      .then(goodValues => {
        if (this.isMounted) {
          this.setImmutableState(
            draft => {
              if (draft.validating[path] !== validationPromise) return

              delete draft.validating[path]

              draft.isFormValidating = !!Object.keys(draft.validating).length
              draft.errors = {}
            },
            () => {
              if (typeof onSuccessCallback === 'function') {
                onSuccessCallback(goodValues)
              }
            }
          )
        }
      })
      .catch(reason => {
        if (this.isMounted) {
          if (!isValidationError(reason)) {
            this.setImmutableState(
              draft => {
                if (draft.validating[path] !== validationPromise) return

                delete draft.validating[path]

                draft.isFormValidating = !!Object.keys(draft.validating).length
              },
              () => {
                if (typeof onErrorCallback === 'function') {
                  onErrorCallback(reason)
                }
              }
            )
            return console.warn(reason)
          }

          const validationErrors = getMeaningfulErrors(reason)

          this.setImmutableState(
            draft => {
              if (draft.validating[path] !== validationPromise) return

              delete draft.validating[path]

              draft.errors = {}
              draft.validating = {}

              for (const error of validationErrors) {
                if (!(error.path == null) && !draft.errors[error.path]) {
                  draft.errors[error.path] = error
                }
              }

              draft.isFormValidating = false
            },
            () => {
              if (typeof onSuccessCallback === 'function') {
                onErrorCallback(reason)
              }
            }
          )
        }
      })
    return validationPromise
  }

  validateForm = (onSuccessCallback, onErrorCallback, overlapContext) => {
    const formKey = '__PIXSY_FORM__'

    this.setImmutableState(draft => {
      const spread = produce(Object.assign)
      const values = original(draft.values)
      const context = spread(
        original(draft.context) || {},
        overlapContext || {},
        { values }
      )

      draft.isFormValidating = true
      draft.validating[formKey] = this.makeValidationFormPromise(
        formKey,
        onSuccessCallback,
        onErrorCallback,
        values,
        context
      )
    })
  }

  subscribeToOnBlurEvent = cb => {
    this.listenersOnBlurEvent.push(cb)
  }

  notifyListenersOnBlurEvent = path => {
    const len = this.listenersOnBlurEvent.length
    for (let i = 0; i < len; i++) {
      const cb = this.listenersOnBlurEvent[i]
      setTimeout(() => cb(path)) // Not blocking
    }
  }

  render() {
    const {
      context,
      errors,
      initialValues,
      isFormPristine,
      isFormReadOnly,
      isFormSubmitting,
      isFormValidating,
      touched,
      validating,
      values,
    } = this.state
    const {
      clearFieldError,
      getTouched,
      getValue,
      handleChange,
      handleValidationPath,
      notifyListenersOnBlurEvent,
      setContext,
      setFieldError,
      setFieldTouched,
      setFieldValue,
      setFormReadOnly,
      setFormValues,
      setPristine,
      setSubmitting,
      subscribeToOnBlurEvent,
      validateForm,
    } = this

    const API = {
      clearFieldError,
      context,
      errors,
      getTouched,
      getValue,
      handleChange,
      handleValidationPath,
      initialValues,
      isFormPristine,
      isFormReadOnly,
      isFormSubmitting,
      isFormValidating,
      notifyListenersOnBlurEvent,
      setContext,
      setFieldError,
      setFieldTouched,
      setFieldValue,
      setFormReadOnly,
      setFormValues,
      setPristine,
      setSubmitting,
      subscribeToOnBlurEvent,
      touched,
      validateForm,
      validating,
      values,
    }

    return (
      <PixsyFormContext.Provider value={API}>
        {this.props.children ||
          (typeof this.props.render === 'function' && this.props.render(API))}
      </PixsyFormContext.Provider>
    )
  }
}
PixsyForm.propTypes = {
  render: PropTypes.func,
  children: PropTypes.node,
  schema: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  context: PropTypes.object,
}
