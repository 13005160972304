import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Switch, Route, withRouter } from 'react-router'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

import MatchesOverview from '../V2/Overview/Overview'
import ListViewsContainer from './views/ListViewsContainer'

const Matches = ({ location, history }) => {
  useEffect(() => {
    const pathname = location.pathname

    if (pathname.startsWith('/matches') && !pathname.startsWith('/matches/v3')) {
      history.replace(pathname.replace(/^\/matches/, '/matches/v3'))
    }
  }, [location.pathname, history])

  return (
    <ThemeProvider theme={theme}>
      <Helmet title="Matches" />
      <Switch>
        <Route path="/matches/v3/overview" component={MatchesOverview} />
        <Route path="/matches/v3*" component={ListViewsContainer} />
      </Switch>
    </ThemeProvider>
  )
}

export default withRouter(Matches)
