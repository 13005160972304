import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'react-emotion'
import { humanize } from 'pixsy-constants'
import moment from 'moment'
import { Label_ as Label, LockedValue } from 'common'

const ImageDetails = ({ image, owner }) => {
  const { title, job, created, origin, binded } = image
  const lastScan = job && job.history && job.history.length && job.history[job.history.length - 1]
  return (
    <Form>
      <Row>
        <Field label="Title" value={title} />
      </Row>
      <Row>
        <Column3>
          {image.tracked ? (
            <Field label="Monitored" value="Yes" size="small" />
          ) : (
            <Field label="Monitored" value="No" size="small" />
          )}
        </Column3>
        <Column3>
          <Field label="Imported" value={moment(created).format('LL')} size="small" />
        </Column3>
        <Column3>
          <span>
            <Field
              label="Source"
              value={humanize(origin.source)}
              size="small"
              link={binded && binded.proofUrl}
              linkTitle="(Verify)"
              onLinkClick={() => {
                requestAnimationFrame(() => {
                  try {
                    window.mixpanel.track('Binded.Verify')
                  } catch (e) {
                    console.error(e)
                  }
                })
              }}
            />
          </span>
        </Column3>
      </Row>
    </Form>
  )
}

export default ImageDetails

const Form = styled.form`
  margin-bottom: 60px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`

const Column3 = styled.div`
  width: calc(100% / 3);
  display: inline-block;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const Row = styled.div`
  width: 100%;
`

// styling similar to TextField component
const Field = ({ label, value, size, link, linkTitle, onLinkClick }) => {
  return (
    <div className={size ? size : ''}>
      <div css={stacked}>
        <Label title={label} />
        <div>
          <LockedValue value={value} size={size} style={link && { display: 'inline-flex' }} />
          {link && (
            <a href={link} target="_blank" onClick={onLinkClick} style={{ marginLeft: 10 }}>
              {linkTitle}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
const stacked = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

ImageDetails.propTypes = {
  image: PropTypes.object,
}
