import React from 'react'
import { css } from 'react-emotion'

export default function NoResults() {
  return (
    <React.Fragment>
      <div css={NoResultsContainer}>
        <div>
          <IconImages />
          <h1>0 Images</h1>
          <p>Try changing the search criteria</p>
        </div>
      </div>
    </React.Fragment>
  )
}


const NoResultsContainer = css`
  position: fixed;
  top: 15%;
  left: calc(10% + 110px);
  @media(max-width: 1280px){
    left: calc(10% + 95px);
  }
  width: calc(80% - 110px);
  height: 70%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    min-width: 100px;
    opacity: 0.25;
  }
  @media(max-width: 768px){
    left: 0;
    width: 100%;
    svg {
      max-height: 130px;
    }
    h1 {
      font-size: 38px;
    }
  }
`

function IconImages() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="4629.029 -5900 22.983 23.903"
      width={36}
    >
      <defs>
        <style>{`.svg-navigation-images {fill: #939393;}`}</style>
      </defs>
      <g id="picture" transform="translate(4629.029 -5900)">
        <path
          id="Path_30"
          data-name="Path 30"
          className="svg-navigation-images"
          d="M10.991,18.12a2.56,2.56,0,1,0-2.56-2.56A2.563,2.563,0,0,0,10.991,18.12Zm0-4.2A1.641,1.641,0,1,1,9.35,15.56,1.643,1.643,0,0,1,10.991,13.919Z"
          transform="translate(-5.015 -7.024)"
        />
        <path
          id="Path_31"
          data-name="Path 31"
          className="svg-navigation-images"
          d="M1,0V23.9H23.983V0Zm.919.919H23.064V13.2l-4.746-4.35a.472.472,0,0,0-.331-.12.461.461,0,0,0-.319.149l-4.5,4.931-2.18-2.18a.46.46,0,0,0-.629-.02L2.665,18.387H1.919ZM23.064,22.983H1.919V19.306H23.064Zm-19.008-4.6,6.584-5.8,4.735,4.735a.46.46,0,1,0,.65-.65l-2.21-2.21,4.22-4.621,5.027,4.608v3.935Z"
          transform="translate(-1)"
        />
      </g>
    </svg>
  )
}