import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as Shapes from '../utils/shapes'
import MoreModal from '../components/modals/MoreModal'
import withEnsureClusterData from '../utils/withEnsureClusterData'
import ChooseActionModal from '../components/modals/ChooseActionModal'
import ImageDetailsModal from '../components/modals/ImageDetailsModal'
import { actionCreators, constants, withMatchViewerContext } from '../store'
import { useIgnoreMatch, useFalsePositiveMatch } from '../utils/useClusters'
import { compose } from 'redux'

const { DETAIL_MODAL_VIEW_TYPE } = constants

const DetailViewModalContainer = ({ dispatch, globalDispatch, modal, cluster, matchId }) => {
  const isMoreModalOpen = modal === DETAIL_MODAL_VIEW_TYPE.MORE
  const isTakeActionModalOpen = modal === DETAIL_MODAL_VIEW_TYPE.ACTIONS
  const isImageDetailsModalOpen = modal === DETAIL_MODAL_VIEW_TYPE.DETAILS
  const isMultiMatch = cluster.matches.length > 1

  const [ignoreMatch] = useIgnoreMatch(globalDispatch)
  const [falsePositiveMatch] = useFalsePositiveMatch(globalDispatch, cluster._id)

  const handleCloseModalRequest = () => {
    dispatch(actionCreators.setModalViewClose(cluster._id, matchId, cluster))
  }

  const handleIgnoreMatchRequest = () => {
    const matchIdToIgnore = isMultiMatch ? matchId : cluster.matches[0]._id
    ignoreMatch({
      variables: {
        clusterId: cluster._id,
        matchId: matchIdToIgnore,
        state: true,
      },
      onCompleted: () => {
        try {
          window.mixpanel.track('Matches.mv3.Ignore', {
            _id: matchId,
            tm: cluster.tags.some((t) => ['top', 'top:world', 'top:us'].includes(t)),
          })
        } catch (e) {
          console.error(`Unable to invoke mixpanel method:`, e)
        }
      },
    })

    dispatch(
      actionCreators.setLastIgnoredMatch({
        matchId: matchIdToIgnore,
        clusterId: cluster._id,
        undoFn: () =>
          ignoreMatch({
            clusterId: cluster._id,
            matchId: matchIdToIgnore,
            ignore: false,
          }).then(dispatch(actionCreators.resetLastIgnoredMatch())),
      })
    )
    handleCloseModalRequest()
  }

  const handleFalsePositiveMatchRequest = async () => {
    const match_id = isMultiMatch ? matchId : cluster.matches[0]._id
    await falsePositiveMatch({
      variables: {
        matchId: match_id,
        verification: 10,
      },
    })

    handleCloseModalRequest()
  }

  return (
    <Fragment>
      <MoreModal
        cluster={cluster}
        matchId={matchId}
        isMultiMatch={isMultiMatch}
        isOpen={isMoreModalOpen}
        onRequestClose={handleCloseModalRequest}
        onIgnoreMatchRequest={handleIgnoreMatchRequest}
      />
      <ChooseActionModal
        isMultiMatch={isMultiMatch}
        cluster={cluster}
        matchId={matchId}
        isOpen={isTakeActionModalOpen}
        onRequestClose={handleCloseModalRequest}
      />
      <ImageDetailsModal
        cluster={cluster}
        matchId={matchId}
        isOpen={isImageDetailsModalOpen}
        onRequestClose={handleCloseModalRequest}
        onFalsePositiveMatchRequest={handleFalsePositiveMatchRequest}
        onIgnoreMatchRequest={handleIgnoreMatchRequest}
      />
    </Fragment>
  )
}

const selectDataToProps = (state) => ({
  ...state.detailsView,
})

DetailViewModalContainer.propTypes = {
  modal: PropTypes.oneOf(Shapes.ModalViewTypes),
  cluster: Shapes.Cluster,
  matchId: PropTypes.string,

  dispatch: PropTypes.func,
  globalDispatch: PropTypes.func,
}

export default compose(
  connect(null, (globalDispatch) => ({ globalDispatch })),
  (component) => withMatchViewerContext(component, selectDataToProps),
  withEnsureClusterData
)(DetailViewModalContainer)
