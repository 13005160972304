import { Button } from 'common'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { css } from 'react-emotion'

export function ConfirmRemovalDialog({ onRemove, onCancel, match, matchUUID }) {
  const handleRemove = useCallback(() => {
    if (isFunction(onRemove) && isString(matchUUID)) onRemove(matchUUID)
    if (isFunction(onCancel)) onCancel()
  }, [onRemove, match, matchUUID, onCancel])

  const handleCancel = useCallback(() => {
    if (isFunction(onCancel)) onCancel()
  }, [onCancel])

  return (
    <div css={content}>
      <h1>Remove Image From Submission</h1>

      <p>
        Caution, you are about to remove this image from the case submission.
      </p>

      <p>This action can not be undone.</p>

      <div css={buttons}>
        <Button hoverMain onClick={handleCancel}>
          Cancel
        </Button>

        <Button danger onClick={handleRemove}>
          Remove
        </Button>
      </div>
    </div>
  )
}

ConfirmRemovalDialog.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    text-align: center;
  }
`
