import React from 'react'
import Helmet from 'react-helmet'
import { GridWrappingColumn, StickyFloater } from 'common'
import { Link } from 'react-router-dom'

import Import from './Import/Import'

export default function ImportContainer(props) {
  return (
    <GridWrappingColumn maxWidth={1040}>
      <Helmet title="Import" />
      <StickyFloater>
        <Link to="/images" className="sticky-floater--back-button" />
        <span className="sticky-floater--left-aligned-description">
          <b>Import Images</b>
        </span>
      </StickyFloater>

      <Import {...props} />
    </GridWrappingColumn>
  )
}
