import React from 'react'
import PropTypes from 'prop-types'
import { StickyFloater } from 'common'
import { css, cx } from 'react-emotion'
import { connect } from 'react-redux'
import QueryBarSearch from './QueryBarSearch'
import { withRouter } from 'react-router'
import QueryPill from './QueryPill'
import { getClusterQueryTags } from '../../../../redux/selectors'

export class QueryBarFloater extends React.Component {
  barSearchRef = React.createRef()

  focusSearchInput = e => {
    const queryBarInstance = this.barSearchRef.current
    const searchInputDOMEl =
      queryBarInstance &&
      queryBarInstance.autoSuggestRef &&
      queryBarInstance.autoSuggestRef.current &&
      queryBarInstance.autoSuggestRef.current.input

    e && e.preventDefault()

    if (
      searchInputDOMEl &&
      searchInputDOMEl !== document.activeElement // If not already focused
    ) {
      searchInputDOMEl.focus()
    }
  }

  handleRemoveTag = idx => () => {
    const { tags, history } = this.props
    const newTags = [...tags.slice(0, idx), ...tags.slice(idx + 1, tags.length)]
    const path = newTags.length === 0 ? '' : '/' + newTags.join('/')
    const destination = `/matches${path}`
    history.push(destination)
  }

  componentDidMount() {
    const { location } = this.props
    const { state } = location
    if (state && state.fromDefaultStickyFloater) {
      this.focusSearchInput()
    }
  }

  render() {
    const { tags, location, history, availableTags } = this.props
    const iconSearch = require('./searchIcon.svg')
    const iconClose = require('./closeIcon.svg')
    const iconBack = require('./backIcon.svg')

    return (
      <StickyFloater navigation={false} hasBackButton>
        <div className={queryBarWrap}>
          {!!tags.length && (
            <button
              onClick={history.goBack}
              dangerouslySetInnerHTML={{ __html: iconBack }}
              className={cx(button, buttonBack)}
            />
          )}

          <div className={flex}>
            <button
              onMouseDown={this.focusSearchInput}
              dangerouslySetInnerHTML={{ __html: iconSearch }}
              css={buttonSearch}
            />
            <div className={pillContainer}>
              {tags.map((t, idx) => (
                <QueryPill
                  key={`${t}-${idx}`}
                  tag={t}
                  onClick={this.handleRemoveTag(idx)}
                />
              ))}
            </div>
            <div css={searchPill}>
              <QueryBarSearch
                ref={this.barSearchRef}
                availableTags={availableTags}
                activeTags={tags}
                location={location}
                history={history}
              />
            </div>
          </div>

          <button
            onClick={() => history.push('/matches')}
            dangerouslySetInnerHTML={{ __html: iconClose }}
            className={cx(button, buttonClose)}
          />
        </div>
      </StickyFloater>
    )
  }
}

export default withRouter(
  connect((state, props) => ({
    tags: getClusterQueryTags(state, props),
  }))(QueryBarFloater)
)

QueryBarFloater.propTypes = {
  location: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
}

const flex = css`
  display: flex;
  height: 54px;
  justify-content: space-between;
  align-items: stretch;
`

const searchPill = css`
  flex: 1;
  min-width: 200px;

  @media (max-width: 768px) {
    // display: none;
    min-width: auto;
    input {
      height: 50px;
    }
  }
`

const pillContainer = css`
  display: flex;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 8px;
    left: 90px;
    right: 0;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
`

const queryBarWrap = css`
  width: 100%;
  position: relative;
  height: 54px;
  @media (max-width: 768px) {
    left: 95px;
    position: fixed;
    top: 48px;
    bottom: auto;
    left: 0;
    right: 0;
  }
`

const button = css`
  position: absolute;
  width: 54px;
  height: 54px;
  top: 0;
  border: 0;
  padding: 0;
  line-height: 13px;
`

const buttonSearch = css`
  background-color: transparent;
  border: 0;
  display: flex;
  outline: 0;
  padding: 0 0 0 10px;

  @media (max-width: 768px) {
    background-color: white;
  }

  svg {
    display: inline-block;
    height: 44px;

    .svg-search-2,
    .svg-search-3 {
      stroke: #999;
    }
    .svg-search-2 {
      fill: #fff;
    }

    .svg-search-4 {
      display: none;
    }
  }
`
const buttonBack = css`
  background-color: #008aab;
  left: -54px;
  position: fixed !important;
  display: none;

  @media (max-width: 768px) {
    top: 0;
    left: 0;
    width: 95px;
    height: 48px;
    z-index: 30;
    display: block;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`

const buttonClose = css`
  background-color: #383838;
  right: -54px;

  svg {
    height: 20px;
    width: 20px;
  }

  @media (max-width: 768px) {
    right: 0;
  }
`
