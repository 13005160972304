import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import * as Shapes from '../../utils/shapes'
import sharedStyles from '../../components/styles'
import GroupMatchListItem from './GroupMatchListItem'
import EmptyResults from '../../components/EmptyResults'
import AnimatedList from '../../components/AnimatedList'
import ClusterHeader from '../../components/ClusterHeader'
import resolveValuesFromTags from '../../utils/resolveValuesFromTags'
import resolveValuesFromDomain from '../../utils/resolveValuesFromDomain'
import getClusterTitle from '../../utils/getClusterTitle'

const GroupMatchList = ({
  cluster,
  clusterId,
  showEmptyResultsView,
  onOpenMoreOnGroupRequest,
  onTakeActionOnGroupRequest,
}) => {
  if (!cluster) return <div css={sharedStyles.listContainer} />
  const matches = cluster.matches.filter((m) => !m.ignored)
  const previouslyActionedMatches = cluster.matches.filter((m) => m.ignored)
  const { countryName, isTopMatch } = resolveValuesFromTags(cluster.tags)
  const { categoryName } = resolveValuesFromDomain(cluster.domain)

  return (
    <div css={sharedStyles.listContainer}>
      <ClusterHeader
        countryName={countryName}
        categoryName={categoryName}
        isTopMatch={isTopMatch}
        score={cluster.score}
        domain={cluster.domain.host}
        clusterId={clusterId}
        clusterTags={cluster.tags}
        displayName={getClusterTitle(cluster)}
        onOpenMoreOnGroupRequest={onOpenMoreOnGroupRequest}
        onTakeActionOnGroupRequest={onTakeActionOnGroupRequest}
        caseId={cluster.case}
        takedownId={cluster.takedown}
        multiMatchView
      />
      {showEmptyResultsView ? (
        <EmptyResults />
      ) : (
        <div>
          <AnimatedList items={matches} keys={(match) => match._id}>
            {(match) => <GroupMatchListItem key={match._id} cluster={cluster} match={match} />}
          </AnimatedList>
          {previouslyActionedMatches.length > 0 && (
            <div className={styles.sectionSeparator}>
              <span>Previously Actioned Upon</span>
            </div>
          )}
          <AnimatedList items={previouslyActionedMatches} keys={(match) => match._id}>
            {(match) => <GroupMatchListItem key={match._id} cluster={cluster} match={match} />}
          </AnimatedList>
        </div>
      )}
    </div>
  )
}

const styles = {
  sectionSeparator: css`
    display: flex;
    width: 100%;
    height: 34px;
    align-items: center;
    margin-bottom: 30px;

    span {
      position: absolute;
      background: #fcfcfc;
      font-size: 18px;
      line-height: 18px;
      color: #212121;
      border: 1px solid #e8e8e8;
      width: 250px;
      text-align: center;
      padding: 7px 0;
      left: calc(50% - 125px);
    }

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  `,
}

GroupMatchList.propTypes = {
  cluster: Shapes.Cluster,
  showEmptyResultsView: PropTypes.bool,
  onOpenMoreOnGroupRequest: PropTypes.func.isRequired,
  onTakeActionOnGroupRequest: PropTypes.func.isRequired,
}

export default connect(null, (globalDispatch) => ({ globalDispatch }))(GroupMatchList)
