import COUNTRIES from 'pixsy-constants/countries.json'
import {
  objectWithKeysAsValues,
  QDate,
  QDefineConstEnums,
  QEnumString,
  QSchema,
  QString,
} from '../core'
import moment from 'moment'

const RESPONSE_REQUIRED = {
  title: 'Response required.',
}
const MIN_CHARS = m => ({
  title: `Minimum of ${m} characters required.`,
})
const MAX_CHARS = m => ({
  title: `Maximum of ${m} characters allowed.`,
})

export const TITLE_ENUM = QDefineConstEnums(
  'Mr.',
  'Mrs.',
  'Miss.',
  'Ms.',
  'Dr.'
)
export const COUNTRIES_ENUM = QDefineConstEnums(
  ...COUNTRIES.map(c => c.country)
)

export const PROFILE_KEYS = objectWithKeysAsValues({
  title: 'title',
  first_name: 'first_name',
  last_name: 'last_name',
  legal_name: 'legal_name',
  email: 'email',
  date_of_birth: 'date_of_birth',
  phone: 'phone',
  street: 'street',
  city: 'city',
  postal_code: 'postal_code',
  state: 'state',
  country: 'country',
})

export const PROFILE_NAMES = PROFILE_KEYS

export const PROFILE_LABELS = {
  [PROFILE_NAMES.title]: 'Title',
  [PROFILE_NAMES.first_name]: 'First name',
  [PROFILE_NAMES.last_name]: 'Last Name',
  [PROFILE_NAMES.legal_name]: 'Legal Name',
  [PROFILE_NAMES.email]: 'Email',
  [PROFILE_NAMES.date_of_birth]: 'Date of Birth',
  [PROFILE_NAMES.phone]: 'Phone No.',
  [PROFILE_NAMES.street]: 'Street and No',
  [PROFILE_NAMES.city]: 'City',
  [PROFILE_NAMES.postal_code]: 'ZIP/Postal Code',
  [PROFILE_NAMES.state]: 'State',
  [PROFILE_NAMES.country]: 'Country',
}

export const PROFILE_INFO = {
  [PROFILE_NAMES.date_of_birth]: {
    title: 'Invalid Date of Birth',
  },
}

export const PROFILE_REQUIRED = {
  [PROFILE_NAMES.title]: true,
  [PROFILE_NAMES.first_name]: true,
  [PROFILE_NAMES.last_name]: true,
  [PROFILE_NAMES.legal_name]: true,
  [PROFILE_NAMES.email]: true,
  [PROFILE_NAMES.date_of_birth]: true,
  [PROFILE_NAMES.phone]: true,
  [PROFILE_NAMES.street]: true,
  [PROFILE_NAMES.city]: true,
  [PROFILE_NAMES.postal_code]: true,
  [PROFILE_NAMES.state]: true,
  [PROFILE_NAMES.country]: true,
}

// prettier-ignore
export const PROFILE_TYPES = {
  [PROFILE_NAMES.title]: QEnumString(TITLE_ENUM).required(RESPONSE_REQUIRED),
  [PROFILE_NAMES.first_name]: QString.required(RESPONSE_REQUIRED),
  [PROFILE_NAMES.last_name]: QString.required(RESPONSE_REQUIRED),
  [PROFILE_NAMES.legal_name]: QString.required(RESPONSE_REQUIRED),
  [PROFILE_NAMES.email]: QString,
  [PROFILE_NAMES.date_of_birth]: QDate
    .required(RESPONSE_REQUIRED)
    .test('min-16-years-old', {
      title: 'As per our Terms of Use, we are unable to accept cases from persons under the age of 16 years old'
    }, function (v) {
      if (moment(v).isValid()) {
        return moment(v) < moment().subtract('16', 'years')
      }
      return true
    }),
  [PROFILE_NAMES.phone]: QString.required(RESPONSE_REQUIRED)
    .min(6, MIN_CHARS(6))
    .max(40, MAX_CHARS(40)),
  [PROFILE_NAMES.street]: QString.required(RESPONSE_REQUIRED)
    .max(40, MAX_CHARS(40)),
  [PROFILE_NAMES.city]: QString.required(RESPONSE_REQUIRED)
    .max(40, MAX_CHARS(40)),
  [PROFILE_NAMES.postal_code]: QString.required(RESPONSE_REQUIRED)
    .max(20, MAX_CHARS(20)),
  [PROFILE_NAMES.state]: QString.required(RESPONSE_REQUIRED)
    .max(40, MAX_CHARS(40)),
  [PROFILE_NAMES.country]: QEnumString(COUNTRIES_ENUM).required(RESPONSE_REQUIRED),
}

export const ProfileSchema = QSchema.shape(PROFILE_TYPES)
