import React from 'react'
import { css } from 'react-emotion'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import IconButton from '@material-ui/core/IconButton'
import { LoadingOverlay } from 'common'

const ReferralCodeComponent = ({ code }) => {
  return (
    <React.Fragment>
      <h4>Refer a friend</h4>
      <p>
        Refer a friend to create an account with Pixsy. This is your unique code
        and we will soon be offering some great perks and benefits based on
        sign-ups from your code.
      </p>

      {(code && <ShowCode code={code} />) || <LoadingOverlay show />}
    </React.Fragment>
  )
}

function ShowCode({ code }) {
  const copyText = React.useCallback(text => {
    if (
      navigator &&
      navigator.clipboard &&
      typeof navigator.clipboard.writeText === 'function'
    ) {
      try {
        navigator.clipboard.writeText(text)
      } catch (err) {
        console.error(err)
      }
    }
  }, [])

  return (
    <div css={referralCodeStyles}>
      <span>Your Referral Code</span>
      <div css={copyWrapper}>
        <span style={{ fontSize: 32 }}>{code}</span>
        <IconButton
          aria-label="copy"
          onClick={() => copyText(code)}
          size="small"
        >
          <FileCopyRoundedIcon fontSize="large" />
        </IconButton>
      </div>
      <span>Your Referral Link</span>
      <div css={copyWrapper}>
        <span>https://my.pixsy.com/register?referralcode={code}</span>
        <IconButton
          aria-label="copy"
          onClick={() =>
            copyText(`https://my.pixsy.com/register?referralcode=${code}`)
          }
          size="small"
        >
          <FileCopyRoundedIcon fontSize="medium" />
        </IconButton>
      </div>
    </div>
  )
}

export default React.memo(ReferralCodeComponent)

const referralCodeStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const copyWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    padding-right: 5px;
    font-weight: bold;
    font-family: monospace;
  }
`
