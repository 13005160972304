import React from 'react'
import styled from 'react-emotion'
import PropTypes from 'prop-types'

const StyledSection = styled.section`
  margin: 30px auto ${props => (props.last ? 0 : 10)}px;
  color: #3b3b3b80;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    margin-bottom: 16px;
  }

  p {
    text-align: center;
    color: #3b3b3baa;
    margin: 0 auto;
    max-width: 85%;
  }

  .clickable {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
`
export default function ActionSection({ last, children }) {
  return <StyledSection last={last}>{children}</StyledSection>
}

ActionSection.propTypes = {
  children: PropTypes.node.isRequired,
  last: PropTypes.bool,
}

ActionSection.defaultProps = {
  last: false,
}
