/**
 * @see https://github.com/rackt/redux/blob/master/examples/real-world/reducers/paginate.js
 * @param {string} entity
 * @param {string} successType
 * @param {string} invalidateType
 * @param {Function} mapActionToKey
 */
export default function paginate(
  entity,
  successType,
  invalidateType,
  decrementTypes,
  mapActionToKey
) {
  const defaultState = {
    ids: [],
  }

  function updatePagination(state = defaultState, action) {
    switch (action.type) {
      case successType:
        return {
          ...state,
          ids: [...new Set([...action.payload[entity], ...state.ids])],
          ...(Number.isInteger(action.payload.total) && {
            total: action.payload.total,
          }),
          ...(Array.isArray(action.payload.tags) && {
            tags: action.payload.tags,
          }),
        }

      default:
        return state
    }
  }

  return function updatePaginationByKey(state = {}, action) {
    if (action.type === invalidateType) {
      // Clear ids of every page but keep metadata (e.g. "total")
      return Object.entries(state).reduce(
        (obj, [key, value]) => ({
          ...obj,
          [key]: {
            ...value,
            ids: [],
          },
        }),
        {}
      )
    }

    if (action.type === successType) {
      let key = mapActionToKey(action)
      return {
        ...state,
        [key]: updatePagination(state[key], action),
      }
    }

    if (decrementTypes.includes(action.type)) {
      let key = mapActionToKey(action)
      const hasCurrentCount = state[key] && state[key].total
      if (!hasCurrentCount) {
        return state
      }
      return {
        ...state,
        [key]: {
          ...state[key],
          total: state[key].total - 1,
        },
      }
    }

    return state
  }
}
