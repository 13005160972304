import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { DATA_ELEVIO } from 'pixsy-constants'
import qs from 'querystring'
import {
  GridWrappingColumn,
  Button,
  StatusHeading,
  ChooseBestImageSource,
  Pagination,
  PaginationFooter,
  CardFilterBar,
  SexySeparator,
} from 'common'
import { format } from 'currency-formatter'
import URI from 'urijs'
import NoResults from './NoResults'
import EmptyState from '../EmptyState/EmptyState'
import { withEmptyStates } from '../../../decorators'
// import { PHASE } from '../../../../shared/forms/submission'
import getCaseStatusFromSubmission from '../../../helpers/getCaseStatusFromSubmission'
import isPlainObject from 'lodash/isPlainObject'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import moment from 'moment'
import DeleteDraft from './DeleteDraft'

const log = require('debug')('cases:cases-table')

export class CasesTable extends Component {
  constructor() {
    super()

    this._statusColorMapping = {
      'Pending Review': '#D39A1C',
      'Action Needed': '#008AAB',
      'Not Accepted': '#FD8282',
      Merged: '#FD8282',
      Discontinued: '#FD8282',
      'Paid / Closed': '#28BF45',
      Canceled: '#FD8282',
    }

    this._columnConfiguration = {
      'Photo / Case No': {
        sortKey: 'ref',
        visibleFor: [
          'draft',
          'pending',
          'open',
          'closed',
          'archived',
          'host',
          'all',
        ],
        getCellData: c => (
          <div>
            <span>{c.reference}</span>
            <h4 style={{ fontSize: 24, fontWeight: 800, maxWidth: 400 }}>
              {this.getTitleForCase(c)}
            </h4>
            <div style={{ color: '#808080' }}>
              <span>{moment(c.created).fromNow()}</span>
            </div>
          </div>
        ),
      },
      Domain: {
        visibleFor: [
          'draft',
          'pending',
          'open',
          'closed',
          'archived',
          'host',
          'all',
        ],
        sortKey: 'domain',
        getCellData: c => (
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 18, color: '#000' }}>
              {this.renderDomain(c)}
            </span>
          </div>
        ),
      },
      Status: {
        visibleFor: [
          'draft',
          'pending',
          'open',
          'closed',
          'archived',
          'host',
          'all',
        ],
        sortKey: 'status',
        elevioSelector: DATA_ELEVIO.CASES_STATUS,
        getCellData: c => {
          let label
          label = getCaseStatusFromSubmission(c)
          return (
            <div style={{ textAlign: 'center' }}>
              <div
                style={{
                  backgroundColor:
                    (this._statusColorMapping[c.status] || '#D39A1C') + '50',
                  padding: '5px 10px',
                  borderRadius: 4,
                  display: 'inline-block',
                }}
              >
                <span>{label || 'Submitted'}</span>
              </div>
            </div>
          )
        },
      },
      Royalty: {
        visibleFor: ['pending', 'open', 'closed', 'archived', 'host', 'all'],
        sortKey: 'royalty',
        getCellData: c => {
          const royalty =
            c.salesforce &&
            c.salesforce.PaymentReceivedD__c &&
            c.salesforce.RoyaltyDue__c
              ? this.renderPrice({
                  value: c.salesforce.RoyaltyDue__c,
                  currency: c.salesforce.CurrencyIsoCode,
                })
              : '-'
          return (
            <div style={{ textAlign: 'center' }}>
              <span>{royalty}</span>
            </div>
          )
        },
      },
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.loading !== nextProps.loading ||
      nextProps.cases.map(x => x._id).join('-') !==
        this.props.cases.map(x => x._id).join('-')
    )
  }

  renderPrice({ value, currency }) {
    return !value
      ? '-'
      : format(value, {
          format: '%s %v',
          code: currency,
          thousand: ',',
          decimal: '.',
        }) +
          ' ' +
          currency
  }

  renderDomain(c) {
    if (c.cluster && c.cluster.domain && c.cluster.domain.host) {
      return c.cluster.domain.host
    }
    const url =
      c.submission &&
      c.submission.matches &&
      c.submission.matches[0] &&
      (c.submission.matches[0].origin
        ? c.submission.matches[0].origin.url
        : c.submission.matches[0].url)

    if (url) {
      return URI(url)
        .host()
        .replace('www.', '')
    }
    return '-'
  }

  handleCaptionClick(sortKey) {
    log(sortKey)
  }

  getTitleForCase(c) {
    const cluster = c.cluster
    let candidate

    if (c.salesforce && c.salesforce.Photo_Title__c) {
      candidate = c.salesforce.Photo_Title__c
    }
    if (!candidate) {
      if (c.submission && c.submission.version >= '6.0') {
        let activeMatches = cluster.matches.filter(m => !m.rejected)

        if (activeMatches.length === 0) {
          activeMatches = cluster.matches
        }

        candidate = activeMatches
          .map(m => {
            let candidate = cluster.images.find(
              i => i._id === (m.image || m.photo)
            )
            return candidate && candidate.title
          })
          .filter(Boolean)[0]
      }
    }

    if (cluster.images.length === 1) {
      candidate = cluster.images[0].title
    } else {
      let activeImagesIds = cluster.matches
        .filter(m => !m.rejected && !m.ignored)
        .map(m => m.image || m.photo)
      const activeImages = cluster.images.filter(i =>
        activeImagesIds.includes(i._id)
      )

      if (activeImages.length) {
        candidate = activeImages.map(i => i.title).filter(Boolean)[0]
      }
    }

    if (!candidate) {
      candidate = '-'
    }

    return candidate
  }

  getCandidatesForCase(c) {
    const cluster = c.cluster
    let candidates = []
    if (c.salesforce && c.salesforce.CroppedPhoto__c) {
      candidates.push(c.salesforce.CroppedPhoto__c)
    }

    if (c.salesforce && c.salesforce.Photo_URL__c) {
      candidates.push(c.salesforce.Photo_URL__c)
    }

    if (!cluster) return candidates

    let isMatchActive = cluster.matches.map(m => !m.rejected)
    candidates = [
      ...candidates,
      ...cluster.images
        .filter(i => c.submission.images.map(String).includes(String(i._id)))
        .filter((i, idx) => isMatchActive[idx])
        .map(i => i.url),
    ]

    return candidates
  }

  renderAction(caze) {
    const { query } = this.props
    const submission =
      isPlainObject(caze) && isPlainObject(caze.submission)
        ? caze.submission
        : null
    const isDraft = submission && submission.__DRAFT__

    if (!caze.cluster) return null

    const isChangeRequested = caze.cm && caze.cm.changeRequested
    const hasSalesforceCounterpart = caze.submission__c || caze.salesforce

    if (hasSalesforceCounterpart && !isChangeRequested) {
      const linkToDetails = `/cases/${caze._id}`
      return (
        <Link to={linkToDetails}>
          <Button
            domProps={{
              style: { backgroundColor: 'hsl(0, 0%, 95%)', border: 0 },
            }}
          >
            <MagnifyingGlass />
            See Details
          </Button>
        </Link>
      )
    } else if (isDraft || isChangeRequested) {
      return (
        <React.Fragment>
          <DeleteDraft caze={caze} query={query} reload={this.props.reload} />
          <Link to={`/cases/submission/${caze._id}`}>
            <Button
              domProps={{
                style: { backgroundColor: 'hsl(0, 0%, 95%)', border: 0 },
              }}
            >
              Finalize
            </Button>
          </Link>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  handlePageChange = event => {
    const selected = typeof event === 'number' ? event : event.selected
    const { perPage } = this.props.paginationProps
    const {
      location: { pathname },
      history,
      query,
    } = this.props

    history.push({
      pathname,
      search: qs.stringify({
        page: selected,
        pageSize: perPage,
        filter: query.filter || 'all',
      }),
    })
  }

  handlePerPageChange = nPages => {
    const {
      location: { pathname },
      history,
      query,
    } = this.props

    history.push({
      pathname,
      search: qs.stringify({
        page: 0,
        pageSize: nPages,
        filter: query.filter || 'all',
      }),
    })
  }

  componentDidUpdate() {
    const { cases, total } = this.props
    if (cases.length === 0 && total > 0) {
      this.props.reload()
    }
  }

  render() {
    const {
      cases,
      filter,
      loading,
      total,
      paginationProps,
      pageCount,
    } = this.props

    const [filterBy, filterValue] = filter.split(':')
    const localeTotal = Number(total).toLocaleString()
    const localePageCount = Number(pageCount).toLocaleString()
    const title = `${localeTotal} case${total !== 1 ? 's' : ''}`
    const subtitle = `${(() => {
      if (filterBy === 'host') return `for "${filterValue}" `
      return ''
    })()}on ${localePageCount} page${pageCount > 1 ? 's' : ''}`

    return (
      <GridWrappingColumn maxWidth={1340}>
        <StatusHeading
          title={title}
          subtitle={pageCount > 0 && subtitle}
          isVisible={!loading}
        />
        <React.Fragment>
          <CardFilterBar
            renderCenterAddon={props => (
              <Pagination
                {...paginationProps}
                onPageChange={this.handlePageChange}
                marginPagesDisplayed={0}
                size="small"
                {...props}
              />
            )}
          />
          <div css={mobileTable}>
            <Table responsive className={casesTable}>
              <thead>
                <tr>
                  {/* this._columnConfiguration['Photo / Case No'] */}
                  <th>&nbsp;</th>
                  {Object.entries(this._columnConfiguration)
                    .filter(([key, value]) =>
                      value.visibleFor.includes(filterBy)
                    )
                    .map(
                      (
                        [key, { sortKey, getCellData, elevioSelector }],
                        idx
                      ) => (
                        <th
                          key={sortKey}
                          onClick={this.handleCaptionClick.bind(this, sortKey)}
                          data-elevio-selector={elevioSelector}
                          style={{ textAlign: idx < 1 ? 'left' : 'center' }}
                        >
                          {idx ? key : <b>{key}</b>}
                          {!idx && <Arrow />}
                        </th>
                      )
                    )}
                  <th>{/* Actions */}</th>
                </tr>
              </thead>
              <tbody>
                {cases.map(c => (
                  <tr key={c._id}>
                    <td>
                      <div className={previewImage}>
                        <ChooseBestImageSource
                          candidates={this.getCandidatesForCase(c)}
                          type="image"
                          useCache
                        >
                          {({ url }) => <img src={url} />}
                        </ChooseBestImageSource>
                      </div>
                    </td>
                    {Object.entries(this._columnConfiguration)
                      .filter(([key, value]) =>
                        value.visibleFor.includes(filterBy)
                      )
                      .map(([key, { sortKey, getCellData }], idx) => (
                        <td key={key}>{getCellData(c)}</td>
                      ))}
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {this.renderAction(c)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {cases.length > 0 && (
            <React.Fragment>
              <SexySeparator space={20} />
              <PaginationFooter
                singularType="case"
                pluralType="cases"
                onPerPageChange={this.handlePerPageChange}
                onPageChange={this.handlePageChange}
                paginationProps={paginationProps}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </GridWrappingColumn>
    )
  }
}

export default withEmptyStates([
  {
    shouldRender: props => props.total === 0 && props.filter === 'all',
    render: () => <EmptyState />,
  },
  {
    shouldRender: props => props.total === 0,
    render: () => <NoResults />,
  },
])(CasesTable)

CasesTable.propTypes = {
  cases: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageCount: PropTypes.number.isRequired,
  paginationProps: PropTypes.shape({
    forcePage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  query: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.string.isRequired,
  }).isRequired,
}

function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="4484.181 -6765.508 14.697 8.13"
      className={arrow}
    >
      <path
        style={{ fill: 'none', stroke: '#3b3b3b' }}
        d="M4479.76-5503.546l7.066,7.065,6.92-7.065"
        transform="translate(4.775 -1261.609)"
      />
    </svg>
  )
}

function MagnifyingGlass() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="5419.006 -6373.018 13.194 14.114"
    >
      <path
        d="M22.988,23.758a5.058,5.058,0,1,0-.734.566l4.79,4.79.65-.65Zm-3.432.435a4.137,4.137,0,1,1,4.137-4.137A4.141,4.141,0,0,1,19.556,24.192Z"
        transform="translate(5404.506 -6388.018)"
      />
    </svg>
  )
}

const mobileTable = css`
  @media (max-width: 768px) {
    > div {
      border: none;
    }
  }
`
const casesTable = css`
  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    tr {
      border: 1px solid #ddd;
      margin: 10px;
      padding: 10px 0;
    }
    tbody td {
      border-width: 0 0 1px !important;
      text-align: center !important;
      &:nth-child(1) {
        padding: 0 10px !important;
      }
      &:nth-child(6) {
        text-align: center !important;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    thead {
      display: none;
    }
    tbody,
    tr,
    th,
    td {
      display: block;
    }

    tbody tr {
      overflow: hidden;
      position: relative;
      margin-bottom: 20px;
      padding: 20px;
      border: 1px solid #ddd;
      width: calc(100% - 20px);
    }

    tbody tr td {
      width: calc(100% - 115px - 200px);
      margin-left: 125px;
      &:first-child {
        margin-left: 0;
        position: absolute;
        width: 115px;
      }
      &:nth-child(6) {
        text-align: left !important;
      }
    }

    tbody td {
      position: relative;
      margin-top: 15px;
      margin-bottom: 25px;
      border-bottom: none !important;
      &:after {
        color: black;
        width: 200px;
        height: 15px;
        position: absolute;
        left: 10px;
        top: -15px;
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        color: hsl(0, 0%, 60%);
      }
      &:nth-child(2) {
        &:after {
          top: -20px;
          content: 'Photo / Case No';
        }
      }
      &:nth-child(3) {
        &:after {
          content: 'Domain';
        }
      }
      &:nth-child(4) {
        &:after {
          content: 'Status';
        }
      }
      &:nth-child(5) {
        &:after {
          content: 'Royalty';
        }
      }
      &:nth-child(1) {
        margin-top: 0px;
      }
      &:nth-child(6) {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  }

  thead > tr > th {
    font-weight: 400;
    padding: 10px 10px 10px 10px !important;
    border: 0;
    font-size: 0.75em;
    color: hsl(0, 0%, 60%);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border-bottom: 0 !important;
    b {
      font-weight: 600;
      color: black;
    }

    &:nth-child(1) {
      width: 130px;
    }
    &:nth-child(4) {
      width: 165px;
    }
    &:nth-child(6) {
      width: 160px;
      text-align: right;
      padding-right: 25px !important;
    }
  }

  tbody > tr {
    background-color: rgb(255, 255, 255);

    td {
      padding: 0 10px !important;
      vertical-align: middle !important;
      text-align: left;
      border-top: 0 !important;
      border-bottom: 5px solid #fcfcfc;
      font-weight: 400;
      color: #000;
      &:first-of-type {
        padding: 0 !important;
        @media (max-width: 768px) {
          padding: 0 10px !important;
        }
      }

      h4 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        font-size: 1em;
        font-weight: 400;
        max-width: 50ch;
        margin-bottom: 2px;
      }

      span {
        color: #00000080;
        font-style: normal !important;
        font-size: 0.85em;
      }

      &:nth-child(6) {
        text-align: right;
        padding: 0 !important;
      }
    }
  }

  button {
    padding: 7px 30px;
    background-color: transparent;
    border: 2px solid #ccc;
    color: #000;
    opacity: 0.6;
    font-size: 0.8em;
    margin: 0 25px 0 10px;
    transition: all 200ms ease-in;

    &:hover {
      border-color: #008aab;
      opacity: 1;
      background-color: transparent;
    }

    svg {
      height: 14px;
      vertical-align: -3px;
      margin-right: 5px;
    }

    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }
`

const arrow = css`
  margin-left: 7px;
  height: 7px;
`

const previewImage = css`
  width: 115px;
  height: 115px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.02);
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    background-color: rgba(0, 0, 0, 0.02);
  }
`
