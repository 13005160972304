import React from 'react'
import { TIcon } from '../../../../components/Icons'
import MultiSelectRichList from '../../../../components/MultiSelectRichList'
import Collapsable from '../../../../components/Collapsable'
import useClusterFilters, { useHandleCollapsableOpen } from '../../../../utils/useClusterFilters'

const KeywordFilter = () => {
  const {
    filters: { selectedKeywordPairs },
    updateFilterState,
  } = useClusterFilters()

  const initialSearchQuery =
    selectedKeywordPairs.length > 0 ? selectedKeywordPairs[selectedKeywordPairs.length - 1].split(':')[1] : ''

  // No counts, because search is lazy
  const items = [
    { id: 'domain', value: 'Domains', count: null },
    { id: 'url', value: 'Urls', count: null },
  ]

  const onChange = (selectedPairs) => {
    updateFilterState({ selectedKeywordPairs: selectedPairs })
  }

  return (
    <Collapsable
      Icon={TIcon}
      text="Search"
      onOpen={useHandleCollapsableOpen()}
      openByDefault={selectedKeywordPairs.length > 0}
      content={
        <MultiSelectRichList
          items={items}
          searchInitialQuery={initialSearchQuery}
          searchPromptHint="Enter search term…"
          selectedIds={selectedKeywordPairs}
          onSelectionChange={onChange}
          enableLazySearch
          decorateValue={(id, searchText) => `"${searchText}" in ${id}`}
          decorateId={(id, searchText) => `${id}:${searchText}`}
        />
      }
      badgeCount={selectedKeywordPairs.length}
    />
  )
}

export default KeywordFilter
