import { Flag } from 'common'
import COUNTRIES from 'pixsy-constants/countries.json'
import React from 'react'
import { PixsyGrid, PixsyGridItem } from '../../../Components'

const SUPPORTED_COUNTRIES = COUNTRIES.filter(c => c.resolution)
  .sort((a, b) => a.country.localeCompare(b.country))
  .map(c => ({
    country: c.country.replace(/\s+/g, '-'),
    label: c.country,
  }))

export default function Flags() {
  return (
    <PixsyGrid spacing={2}>
      {SUPPORTED_COUNTRIES.map(specs => (
        <FlagItem key={specs.country} {...specs} />
      ))}
    </PixsyGrid>
  )
}

const FlagItem = ({ label, country }) => {
  return (
    <PixsyGridItem xs={4} md={4} lg={3}>
      <PixsyGrid spacing={1} alignItems="center">
        <PixsyGridItem xs={12} md={4}>
          <Flag country={country} style={{ width: '100%', height: 'auto' }} />
        </PixsyGridItem>
        <PixsyGridItem
          xs={12}
          md={8}
          style={{
            fontSize: '0.8em',
            lineHeight: '0.9em',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {label}
        </PixsyGridItem>
      </PixsyGrid>
    </PixsyGridItem>
  )
}
