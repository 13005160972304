import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

export default class Wootric extends React.Component {
  static ACCOUNT_TOKEN = global.PRODUCTION ? 'NPS-4d613bf7' : 'NPS-6ca7a469'

  componentDidMount() {
    setTimeout(() => {
      this.handleLogin()
    }, 3000)
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && !prevProps.user) {
      this.handleLogin()
    }
  }

  handleLogin() {
    const { user } = this.props
    if (!user) return

    const { email, created } = user

    const created_at = Math.round(new Date(created).getTime() / 1000)

    // window.wootric_survey_immediately = true
    window.wootricSettings = {
      email,
      created_at,
      account_token: Wootric.ACCOUNT_TOKEN,
    }
    window.wootric && window.wootric('run')
  }

  render() {
    // Prevent annoying cookie bar from interfering from ui
    if (global.E2E) return null

    return (
      <Helmet>
        <script
          type="text/javascript"
          async
          defer
          src="https://disutgh7q0ncc.cloudfront.net/beacon.js"
        />
      </Helmet>
    )
  }
}

Wootric.propTypes = {
  user: PropTypes.object,
}
