import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { Button } from 'common'
import React, { useState, useCallback } from 'react'
import { css } from 'react-emotion'

const useStyles = makeStyles((theme) => ({
  panel: {
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'transparent',
    },
    '& .Mui-expanded': {
      margin: 'auto',
    },
    transition: 'none',
  },
  summary: {
    borderBottom: '1px solid #E0E0E0',
    alignItems: 'flex-end',
    '& h3': {
      fontSize: '1.8em',
      marginBottom: 0,
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: '10px 0 !important',
    },
    '& .MuiExpansionPanelSummary-expandIcon': {
      margin: '7px 0 0 0 !important',
    },
    '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(-90deg)',
    },
  },
  container: {
    padding: 20,
  },
  icon: {
    width: '30px',
    height: '30px',
    transform: 'rotate(90deg)',
  },
  progress: {
    color: '#00758d',
  },
}))

const MatchPreferencesContent = ({ title, children, showSaveButton = false, onSave, defaultExpanded = false }) => {
  const styles = useStyles()
  const [isSaving, setIsSaving] = useState(false)

  const save = useCallback(
    (event, ...args) => {
      event.stopPropagation()
      setIsSaving(true)
      async function performSave() {
        await onSave(event, ...args)
        setIsSaving(false)
      }
      performSave()
    },
    [setIsSaving, onSave]
  )

  return (
    <ExpansionPanel
      className={styles.panel}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={defaultExpanded}
    >
      <ExpansionPanelSummary
        className={styles.summary}
        expandIcon={<ExpandMore fontSize="large" className={styles.icon} />}
      >
        <h3
          css={`
            ${header} ${showSaveButton && headerUnsaved}
          `}
        >
          {title}
        </h3>
        <div className={saveButtonContainer}>
          {showSaveButton && !isSaving && (
            <Button variant="contained" color="primary" size="mini" onClick={save} disableElevation>
              SAVE
            </Button>
          )}
          {isSaving && <CircularProgress size={24} className={styles.progress} />}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.container}>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default MatchPreferencesContent

const header = css`
  font-size: 1.7em;
  font-weight: 400;
`

const headerUnsaved = css`
  color: hsl(0, 0%, 70%);
`

const saveButtonContainer = css`
  margin-left: 30px;
  align-self: center;
  margin-top: 20px;
  &:button {
    padding: 2px 15px;
  }
`