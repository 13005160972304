import React from 'react'
import { css } from 'react-emotion'
import { DragDrop } from '@uppy/react'
import { PixsyPaper } from '../PixsyPaper/PixsyPaper'
import { compose, withState, lifecycle, branch } from 'recompose'

export const UppyDragDropComponent = ({
  uppy,
  description,
  title = 'Drop or %{browse} to add more',
  browse = 'click here',
  height = '100px',
}) => {
  // const api = useContext(PixsyFormContext)

  return (
    <PixsyPaper className={uppyDragDropStyle} variant="transparent">
      <DragDrop
        uppy={uppy}
        width="100%"
        height={height}
        note={description}
        locale={{
          strings: {
            dropHereOr: title,
            browse,
          },
        }}
      />
    </PixsyPaper>
  )
}

export const UppyDragDrop = compose(
  branch(
    props => props.disabled,
    () => () => <div />
  ),
  withState('loading', 'setLoading', true),
  lifecycle({
    componentDidMount() {
      requestAnimationFrame(() => {
        this.props.setLoading(false)
      })
    },
    componentWillUnmount() {
      try {
        this.props.uppy.close()
      } catch (err) {
        console.error(err)
      }
    },
  }),
  branch(
    props => props.loading,
    () => () => <div />
  )
)(UppyDragDropComponent)

const uppyDragDropStyle = css`
  /**
* General Uppy styles that apply to everything inside the .Uppy container
*/
  .uppy-Root {
    box-sizing: border-box !important;
    font-family: system-ui, -apple-system, BLinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif !important;
    line-height: 1 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .uppy-Root *,
  .uppy-Root *:before,
  .uppy-Root *:after {
    box-sizing: inherit !important;
  }

  .uppy-Root *:focus {
    outline: 2px solid #2275d7 !important;
    outline-offset: 2px !important;
  }

  .UppyIcon {
    max-width: 100% !important;
    max-height: 100% !important;
    fill: currentColor;
    /* display: inline-block !important; */
    display: none !important;
    overflow: hidden !important;
  }

  .UppyIcon--svg-baseline {
    bottom: -0.125em !important;
    position: relative !important;
  }

  .uppy-u-reset {
    animation: none 0s ease 0s 1 normal none running;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    background: transparent none repeat 0 0 / auto auto padding-box border-box
      scroll !important;
    border: medium none currentColor !important;
    border-collapse: separate !important;
    border-image: none !important;
    border-radius: 0 !important;
    border-spacing: 0 !important;
    bottom: auto !important;
    box-shadow: none !important;
    caption-side: top !important;
    clear: none !important;
    clip: auto !important;
    color: #000 !important;
    columns: auto !important;
    column-count: auto !important;
    column-fill: balance !important;
    column-gap: normal !important;
    column-rule: medium none currentColor !important;
    column-span: 1 !important;
    column-width: auto !important;
    content: normal !important;
    counter-increment: none !important;
    counter-reset: none !important;
    cursor: auto !important;
    display: inline !important;
    empty-cells: show !important;
    float: none !important;
    font-family: serif !important;
    font-size: medium !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    height: auto !important;
    -webkit-hyphens: none !important;
    -ms-hyphens: none !important;
    hyphens: none !important;
    left: auto !important;
    letter-spacing: normal !important;
    list-style: disc outside none !important;
    margin: 0 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    opacity: 1 !important;
    orphans: 2 !important;
    outline: medium none invert !important;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    padding: 0 !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
    page-break-inside: auto !important;
    perspective: none !important;
    perspective-origin: 50% 50% !important;
    position: static !important;
    right: auto !important;
    -moz-tab-size: 8 !important;
    tab-size: 8 !important;
    table-layout: auto !important;
    text-align: left !important;
    text-align-last: auto !important;
    text-decoration: none !important;
    text-indent: 0 !important;
    text-shadow: none !important;
    text-transform: none !important;
    top: auto !important;
    transform: none !important;
    transform-origin: 50% 50% 0 !important;
    transform-style: flat !important;
    transition: none 0s ease 0s !important;
    unicode-bidi: normal !important;
    vertical-align: baseline !important;
    visibility: visible !important;
    white-space: normal !important;
    widows: 2 !important;
    width: auto !important;
    word-spacing: normal !important;
    z-index: auto !important;
  }

  .uppy-c-textInput {
    border: 1px solid rgba(147, 147, 147, 0.5) !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    line-height: 1.5 !important;
    padding: 6px 8px !important;
  }

  .uppy-size--md .uppy-c-textInput {
    font-size: 15px !important;
    line-height: 1.8 !important;
    padding: 8px 12px !important;
  }

  .uppy-c-textInput:focus {
    border-color: #2275d7 !important;
    outline: none !important;
    box-shadow: 0 0 1px 1px rgba(34, 117, 215, 0.5) !important;
  }

  .uppy-c-btn {
    display: inline-block !important;
    text-align: center !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    font-family: inherit !important;
    font-size: 16px !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    transition: background-color 0.3s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  .uppy-c-btn:not(:disabled):not(.disabled) {
    cursor: pointer !important;
  }

  .uppy-c-btn-primary {
    font-size: 13px !important;
    padding: 10px 18px !important;
    border-radius: 4px !important;
    background-color: #2275d7 !important;
    color: #fff !important;
  }

  .uppy-size--md .uppy-c-btn-primary {
    font-size: 15px !important;
    padding: 13px 22px !important;
  }

  .uppy-c-btn-primary:hover {
    background-color: #1b5dab !important;
  }

  .uppy-c-btn-primary:focus {
    outline: none !important;
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5) !important;
  }

  .uppy-c-btn-link {
    font-size: 13px !important;
    line-height: 1 !important;
    padding: 10px 18px !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    color: #000 !important;
  }

  .uppy-size--md .uppy-c-btn-link {
    font-size: 15px !important;
    padding: 13px 28px !important;
  }

  .uppy-c-btn-link:hover {
    text-decoration: underline !important;
  }

  .uppy-c-btn-link:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.2rem rgba(34, 117, 215, 0.5) !important;
  }

  .uppy-c-btn--small {
    font-size: 0.9em !important;
    padding: 7px 16px !important;
    border-radius: 2px !important;
  }

  .uppy-size--md .uppy-c-btn--small {
    padding: 8px 10px !important;
    border-radius: 2px !important;
  }

  .uppy-DragDrop-container {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    border-radius: 0px !important;
    background-color: #fff !important;
  }

  .uppy-DragDrop-inner {
    margin: 0 !important;
    text-align: center !important;
    padding: 80px 20px !important;
    line-height: 1.4 !important;
  }

  .uppy-DragDrop-arrow {
    width: 60px !important;
    height: 60px !important;
    fill: #e0e0e0 !important;
    margin-bottom: 17px !important;
  }

  .uppy-DragDrop--is-dragdrop-supported {
    border: 2px dashed !important;
    border-color: #cccccc !important;
  }

  .uppy-DragDrop-container.drag {
    border-color: #939393 !important;
    background-color: #e6e6e6 !important;
  }

  .uppy-DragDrop-container.drag .uppy-DragDrop-arrow {
    fill: #939393 !important;
  }

  .uppy-DragDrop-label {
    display: block !important;
    cursor: pointer !important;
    font-size: 1.15em !important;
    margin-bottom: 5px !important;
    font-weight: 400 !important;
  }

  .uppy-DragDrop-note {
    font-size: 1em !important;
    color: #adadad !important;
  }

  .uppy-DragDrop-dragText {
    /* color: #2275d7 !important; */
    color: #00758d !important;
    &:hover,
    &:focus {
      color: #005364 !important;
    }
  }
`
