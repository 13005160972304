import * as React from 'react'
import { css } from 'react-emotion'
import InputAdornment from '@material-ui/core/InputAdornment'

export const LeftAddon = React.memo(({ children }) => (
  <InputAdornment css={addonStyle} position="start">
    {children}
  </InputAdornment>
))
export const RightAddon = React.memo(({ children }) => (
  <InputAdornment css={addonStyle} position="end">
    {children}
  </InputAdornment>
))

const addonStyle = css`
  > p {
    /* when addon is not a React.node|component but plain text */
    font-size: 14px;
    font-family: inherit;
    line-height: inherit;
  }
`
