import { gql } from '@apollo/client'
import ClusterFragment from './cluster.fragment'

export default gql`
  query getCluster($id: String) {
    cluster(id: $id, populateImages: true, populateDomain: true) {
      ...cluster
    }
  }
  ${ClusterFragment}
`
