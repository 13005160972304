/**
 * # Config
 *
 * Environment-based configuration variables
 *
 * @see server
 * @see api
 */
module.exports = {
  app: {
    name: 'Pixsy',
    title: 'Pixsy',
    description: 'Find and fight image theft',
    head: {
      charSet: 'utf-8',
      titleTemplate: 'Pixsy | %s',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Find & Fight Image Theft' },
        { property: 'og:site_name', content: 'Pixsy' },
        {
          property: 'og:image',
          content: 'https://static.pixsy.io/pixsy-logo-square.png',
        },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:title', content: 'Pixsy' },
        {
          property: 'og:description',
          content: 'Find & Fight Image Theft',
        },
        { property: 'fb:admins', content: '1598056840465002' },
        { property: 'twitter:card', content: 'summary' },
        { property: 'twitter:site', content: '@pixsyhq' },
        { property: 'twitter:creator', content: '@pixsyhq' },
        { property: 'twitter:title', content: 'Pixsy' },
        {
          property: 'twitter:description',
          content: 'Find & Fight Image Theft',
        },
        {
          property: 'twitter:image',
          content: 'https://static.pixsy.io/pixsy-logo-square.png',
        },
        { property: 'twitter:image:width', content: '200' },
        { property: 'twitter:image:height', content: '200' },
      ],

      // If adding link here make sure to update Helmet in App.js so favicon links don't override it
    },
  },
}
