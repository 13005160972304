import * as React from 'react'
import { css } from 'react-emotion'

export const ListCardItem = ({ children, ignored, approved }) => (
  <div css={listCardItem}>
    {React.Children.map(children, child =>
      React.cloneElement(child, { ignored, approved })
    )}
  </div>
)
export default ListCardItem

const listCardItem = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 26.21px;
  min-height: 216.82px;

  @media (max-width: 768px) {
    position: relative;
  }
`
