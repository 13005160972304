import React from 'react'
import { Button } from 'common'
import { css } from 'react-emotion'

export default function UppyExitPrompt({ onCancel, onNavigate }) {
  return (
    <div css={content}>
      <h1>You have ongoing uploads</h1>

      <span>Do you want to cancel them and leave?</span>

      <div css={buttons}>
        <Button hoverMain onClick={onCancel}>
          Stay
        </Button>
        <Button greyOutline onClick={onNavigate}>
          Leave
        </Button>
      </div>
    </div>
  )
}

UppyExitPrompt.propTypes = {}

const content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    text-align: center;
  }
`

const buttons = css`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 30px 4px 0 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
