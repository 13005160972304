import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { withCookies } from 'react-cookie'
import Modal from 'react-modal'
import WallSlider from './WallSlider'
import ProfileTypeQuestionaire from './ProfileTypeQuestionaire'
import PhotographerQuestionaire from './PhotographerQuestionaire'
import ShopQuestionaire from './ShopQuestionaire'
import AgencyQuestionaire from './AgencyQuestionaire'
import ArtistQuestionaire from './ArtistQuestionaire'
import CountryQuestionaire from './CountryQuestionaire'
import ImportReminder from './ImportReminder.js'
import VerifyEmailQuestionaire from './VerifyEmailQuestionaire.js'
import BounceEmailWarning from './BounceEmailWarning.js'
import FlickrShortcut from './FlickrShortcut.js'
import SmugmugShortcut from './SmugmugShortcut'
import AdBlockDetector from '../Warnings/AdBlockDetector'
import TopMatches from './TopMatches'
import HighValuePhoneNumber from './HighValuePhoneNumberQuestionaire'

class Wall extends Component {
  constructor(props) {
    super()
    this._sharkMode = Boolean(props.cookies.get('pixsy-shark'))
  }

  static COMPONENTS = [
    <FlickrShortcut key="flickrFastImport" blockProgressIndicator />,
    <SmugmugShortcut key="smugmugFastImport" blockProgressIndicator />,
    <CountryQuestionaire key="profile" />,
    <ProfileTypeQuestionaire key="profile" />,
    <ArtistQuestionaire key="artist" />,
    <ShopQuestionaire key="shop" />,
    <PhotographerQuestionaire key="photographer" />,
    <HighValuePhoneNumber key="highValueUser" />,
    <AgencyQuestionaire key="agency" />,
    <ImportReminder key="imports" />,
    <VerifyEmailQuestionaire key="verifyEmail" />,
    <BounceEmailWarning key="bounceEmail" />,
    <TopMatches key="TopMatches" />,
  ]

  state = {
    step: 0,
    components: [],
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      components: this.determineComponents(nextProps),
    }))
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ components: this.determineComponents(this.props) })
    requestAnimationFrame(() => {
      this.scheduleComponents(this.props)
    })
  }

  scheduleComponents({ user, location, cookies }) {
    if ((location.state && location.state['skip-wall']) || user.debug) {
      return []
    }

    const components = Wall.COMPONENTS.filter(component =>
      component.type.eligible(user, location, cookies)
    ).filter(component => component.type.delay && component.type.delay > 0)

    for (const comp of components) {
      const delay = comp.type.delay
      setTimeout(() => {
        this.setState(state => ({
          ...state,
          components: [...state.components, React.cloneElement(comp, { user })],
        }))
      }, delay)
    }
  }

  determineComponents({ user, location, cookies }) {
    if (location.state && location.state['skip-wall']) {
      return []
    }

    return Wall.COMPONENTS.filter(component =>
      component.type.eligible(user, location, cookies)
    )
      .filter(component => !component.type.delay)
      .map(component => React.cloneElement(component, { user }))
  }

  handleChange = n => this.setState({ step: n })

  handleContinue = cycleToNext => {
    this.setState(({ step }) => {
      cycleToNext()

      const componentsCount = this.state.components.length
      const newStep =
        componentsCount > 0 && componentsCount < step + 1
          ? componentsCount - 1
          : step + 1
      return {
        step: newStep,
      }
    })
  }

  render() {
    const { children } = this.props
    const { step, components } = this.state

    const modalOpen = step < components.length && components.length > 0

    if (this._sharkMode) {
      return children
    }

    return (
      <React.Fragment>
        {modalOpen ? (
          <Modal isOpen className={modalWrap} overlayClassName={overlay}>
            <WallSlider
              onContinue={this.handleContinue}
              onChange={this.handleChange}
              step={step}
            >
              {components}
            </WallSlider>
          </Modal>
        ) : (
          <AdBlockDetector />
        )}
        {children}
      </React.Fragment>
    )
  }
}

Wall.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.object.isRequired, // eslint-disable-line
}

export default withCookies(Wall)

const modalWrap = css`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 820px;

  padding: 10px 30px 30px;
  background-color: white;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15);
  border-top: 3px solid #008aab;
  overflow-y: scroll;
  max-height: 85%;

  @media screen and (min-height: 910px) {
    top: 110px;
    max-height: 80%;
  }

  @media (max-width: 768px) {
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    transform: none;
    max-height: 100% !important;
    width: auto !important;
  }

  &:focus {
    outline: 0;
  }

  h1 {
    font-size: 2.2em;
    + span {
      margin-top: -10px;
      font-size: 1.1em;
      color: #3b3b3bb3;
      font-weight: 300;
    }
  }
`

const overlay = css`
  z-index: 200;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.85);
`
