import React from 'react'
import Helmet from 'react-helmet'
import { Field, reduxForm } from 'redux-form'
import { Button, FloatingInput } from 'common'
import { resetPassword } from '../../../redux/modules/auth'
import { fields } from './fields'

export default reduxForm({
  onSubmit: (values, dispatch) => dispatch(resetPassword(values)),
  form: 'passwordReset',
  validate,
})(PasswordResetForm)

export function PasswordResetForm({
  valid,
  handleSubmit,
  submitting,
  submitSucceeded,
}) {
  // TODO: success screen needs approved icon
  // https://team-pixsy.atlassian.net/browse/MAR-307
  return (
    <form onSubmit={handleSubmit} className="home-small">
      <Helmet title="Reset Password" />

      <h2>Forgot your password?</h2>

      {submitSucceeded ? (
        <center>
          If this email matches a Pixsy user account you will receive a reset email.
        </center>
      ) : (
        <div>
          <h5>Enter your email to reset your password</h5>
          <Field
            component={FloatingInput}
            name={fields['EMAIL'].name}
            type={fields['EMAIL'].type}
            props={fields['EMAIL'].props}
          />
          <Button type="action" disabled={submitting} full submit>
            Reset
          </Button>
        </div>
      )}
    </form>
  )
}

function validate(values) {
  return Object.values(fields).reduce(
    (obj, { name, validate = () => null }) => {
      const entry = validate(values[name])
      return entry ? { ...obj, [name]: entry } : obj
    },
    {}
  )
}
