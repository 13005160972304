import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { BroadcastBanner } from 'common'
import { withRouter } from 'react-router'
import { requestEmailVerification } from '../../../redux/modules/auth'
import { connect } from 'react-redux'

VerifyEmailWarning.COOKIE_KEY = 'pixsy-email-verify-ignore'

function VerifyEmailWarning({ user, requestEmailVerification, location }) {
  const isLoggedIn = Boolean(user)
  const isVerified =
    user &&
    user.verification &&
    user.verification.email &&
    user.verification.email.dateVerified
  const isOnVerificationScreen = location.pathname.includes(
    'register/verify-email'
  )
  const isOnSubmissionScreen = location.pathname.includes('cases/submission')

  const isActive =
    isLoggedIn &&
    !isVerified &&
    !isOnVerificationScreen &&
    !isOnSubmissionScreen

  return (
    <BroadcastBanner
      cookieKey={VerifyEmailWarning.COOKIE_KEY}
      isActive={isActive}
    >
      Account not verified (match scanning on hold) - Please verify your email.
      <span css={resendLink} onClick={requestEmailVerification}>
        Resend link
      </span>
    </BroadcastBanner>
  )
}

export default withRouter(
  connect(null, { requestEmailVerification })(VerifyEmailWarning)
)

const resendLink = css`
  display: inline-block;
  line-height: 1;
  margin-left: 10px;
  position: relative;
  color: white;
  font-weight: bold;
  cursor: pointer;
`

VerifyEmailWarning.propTypes = {
  // also load component when user is not available so transition is smooth
  user: PropTypes.object,
  requestEmailVerification: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}
