import React from 'react'
import FormRow from './FormRow'
import styled, { css, cx } from 'react-emotion'
import Label from './Label'
import Feedback from './Feedback'
import { WORK_TYPES } from 'pixsy-constants'

const Select = styled.select`
  padding: 0px 36px 0px 16px;
  width: 260px;
  height: 40px;
  appearance: none;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #c7c7c7;
  cursor: pointer;
  color: #434343;
  font-size: 1.25em;
  text-indent: 0.01px;
`

const SelectWrapper = styled.div`
  position: relative;

  svg {
    content: ' ';
    width: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
`

export default function TypeOfWorkSelect(props) {
  const { input, meta, label, locked, multi } = props

  let metaUpdated = { ...meta }
  let highlightError = false
  if (
    metaUpdated.error &&
    (metaUpdated.error === 'ONLY_PHOTOGRAPHS_FOR_USCO_MULTI' ||
      metaUpdated.error.reason === 'ONLY_PHOTOGRAPHS_FOR_USCO_MULTI')
  ) {
    highlightError = true
    metaUpdated.error = null
  }
  return (
    <FormRow {...props}>
      <div className={stacked}>
        <div className={wrap}>
          <Label title={label} />
        </div>
        <p
          css={cx(
            subLabel,
            multi &&
              input.value !== 'PHOTO' &&
              highlightError &&
              subLabelHighlight
          )}
        >
          Important: You can only register photographs as part of a group
          registration
        </p>
      </div>
      <div>
        <SelectWrapper>
          <Select {...input} disabled={locked}>
            <option value="">Choose Type of work</option>
            {Object.keys(WORK_TYPES).map(type => (
              <option key={type} value={type}>
                {WORK_TYPES[type]}
              </option>
            ))}
          </Select>
          <IconArrowDown />
        </SelectWrapper>
        <div css={feedbackWrap}>
          <Feedback meta={metaUpdated} />
        </div>
      </div>
    </FormRow>
  )
}

const subLabel = css`
  max-width: 275px;
  font-size: 1em;
  color: #dda300;
  transition: color 200ms ease;
`

const subLabelHighlight = css`
  color: #f42534;
`

const stacked = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const feedbackWrap = css`
  max-width: 260px;
`

const wrap = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;

  label {
    font-weight: 300;
    font-size: 1.42em;
    display: block;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

function IconArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13638.514 -16337.511 21.205 11.311"
    >
      <path
        style={{ fill: 'none', stroke: '#3B3B3B' }}
        d="M-274.554-13755.368l10.249,10.25,10.249-10.25"
        transform="translate(13913.421 -2581.789)"
      />
    </svg>
  )
}
