import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'

import { ArrowLeft } from './Icons'

const Collapsable = ({ Icon, text, onOpen, content, badgeCount, openByDefault }) => {
  const [collapsed, setCollapsed] = useState(true)
  const isOpen = !collapsed

  const handleCollapsableClick = useCallback(() => {
    collapsed && onOpen(() => setCollapsed(true))
    setCollapsed(!collapsed)
  }, [collapsed, onOpen, setCollapsed])

  useEffect(() => {
    if (openByDefault) {
      handleCollapsableClick()
    }
  }, [])

  return (
    <div css={styles.collapsable} className={isOpen && 'open'}>
      <div className="collapsableHeader" onClick={handleCollapsableClick}>
        {badgeCount > 0 ? <div css={styles.badge}>{badgeCount}</div> : <Icon />}
        <span style={{ color: badgeCount > 0 && '#121212' }}>{text}</span>
        <ArrowLeft className="collapsableHeaderArrow" />
      </div>
      {isOpen && <div className="collapsableContent">{content}</div>}
    </div>
  )
}

const styles = {
  badge: css`
    width: 17px;
    height: 17px;
    background-color: #ffa168;
    border-radius: 30px;
    color: white;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
  `,
  collapsable: css`
    &.open {
      .collapsableHeader {
        .collapsableHeaderArrow {
          transform: rotate(-90deg);
        }
      }
    }

    .collapsableHeader {
      height: 49px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 23px;
      padding-right: 28px;
      cursor: pointer;
      border-bottom: 1px #e8e8e8 solid;

      > span {
        flex: 1;
        margin-left: 10px;
        color: #121212;
        font-size: 16px;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }

    .collapsableContent {
      border-bottom: 1px #e8e8e8 solid;
      > span {
        font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
      }
    }
  `,
}

Collapsable.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  text: PropTypes.string,
  content: PropTypes.node,
  badgeCount: PropTypes.number,
  onOpen: PropTypes.func.isRequired,
  openByDefault: PropTypes.bool,
}

export default Collapsable
