import React from 'react'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'

const wrap = css`
  width: 110px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  will-change: transform; /* force new render layer */
  @media(max-width: 1280px){
    width: 95px !important;
    svg {
      height: 100%;
      width: 100%;
    }
  }

`

export default function Logo() {
  const logo = require('../../theme3/logo.svg')

  return (
    <Link to="/">
      <div css={wrap} dangerouslySetInnerHTML={{ __html: logo }} />
    </Link>
  )
}
