import React from 'react'
import { ImageIcon } from '../../../../components/Icons'
import MultiSelectRichList from '../../../../components/MultiSelectRichList'
import Collapsable from '../../../../components/Collapsable'
import useClusterFilters, {
  useHandleCollapsableOpen,
} from '../../../../utils/useClusterFilters'

const ImagesFilter = () => {
  const {
    mappedFiltersData: { imageItems: items },
    filters: { selectedImageIds: selectedIds },
    updateFilterState,
  } = useClusterFilters()

  const onChange = (ids) => updateFilterState({ selectedImageIds: ids })
  const onCollapsableOpen = useHandleCollapsableOpen()

  return (
    <Collapsable
      Icon={ImageIcon}
      text="Image"
      onOpen={onCollapsableOpen}
      content={
        <MultiSelectRichList
          items={items}
          selectedIds={selectedIds}
          onSelectionChange={onChange}
          enableTextSearch
        />
      }
      badgeCount={selectedIds.length}
    />
  )
}

export default ImagesFilter
