import React from 'react'
import PropTypes from 'prop-types'

export default class InternalServerError extends React.PureComponent {
  static defaultProps = {
    showStackTrace: true,
  }

  static propTypes = {
    err: PropTypes.object.isRequired,
    showStackTrace: PropTypes.bool,
  }

  renderStacktrace() {
    const { err } = this.props
    const response = err.response

    let [firstLine, ...rest] = err.stack.split('\n')

    let message = response && response.data ? response.data.message : firstLine
    const status = (response && response.status) || 500

    return (
      <pre>
        <h3>{message}</h3>
        {status === 500 && (
          <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
            {rest.map((item, i) => <li key={i}>{item}</li>)}
          </ul>
        )}
      </pre>
    )
  }

  render() {
    return (
      <div style={{ margin: '0 auto', maxWidth: '1400px' }}>
        <h1>Sorry, something went wrong. We have been notified.</h1>
        <a href="/">Go back.</a>
        {this.props.showStackTrace && this.renderStacktrace()}
      </div>
    )
  }
}
