import React, { useCallback } from 'react'
import AnimatedNumbers from '../../../components/AnimatedNumbers'
import { css, cx } from 'react-emotion'
import { CircularProgress } from '@material-ui/core'
import SearchInput from '../../../components/SearchInput'
import { RefreshIcon } from '../../../components/Icons'
import useClusterFilters from '../../../utils/useClusterFilters'
import SelectSort from '../../../components/SelectSort'

const ClusterFilterHeader = () => {
  const {
    loading,
    didFiltersChange,
    mappedFiltersData,
    pageOptionProps,
    pageOptionProps: { perPage },
    resetFilters,
  } = useClusterFilters()

  const handleRefreshFiltersClick = useCallback(() => {
    if (didFiltersChange) resetFilters()
  }, [didFiltersChange])

  const { totalMatches } = mappedFiltersData
  const pageCount = Math.ceil(totalMatches / perPage)
  const refreshButtonClassName = cx(styles.refreshButton, {
    [styles.refreshButtonWhenFiltersChanged]: didFiltersChange,
  })

  return (
    <React.Fragment>
      <div css={styles.searchAndRefresh}>
        <SearchInput mappedFiltersData={mappedFiltersData} />
        <RefreshIcon className={refreshButtonClassName} onClick={handleRefreshFiltersClick} />
      </div>
      <div css={styles.searchResultInfoWrapper}>
        <div css={styles.pageInfo}>
          {loading
            ? 'Loading...'
            : pageCount === 0
            ? 'No results found'
            : `${pageCount > 1 ? `${pageCount} pages of results` : '1 page of results'}`}
        </div>
        <div css={styles.searchResultsCount}>
          {loading ? <CircularProgress size={30} /> : <AnimatedNumbers number={totalMatches} />}
        </div>
        <SelectSort sort={pageOptionProps.sort} onSortChange={pageOptionProps.onSortChange} />
      </div>
    </React.Fragment>
  )
}

const styles = {
  searchResultInfoWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #121212;
    margin-bottom: 10px;
  `,
  searchResultsCount: css`
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
  `,
  pageInfo: css`
    color: #12121250;
    font-size: 12px;
  `,
  searchAndRefresh: css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    min-height: 24px;
  `,
  refreshButton: css`
    position: absolute;
    top: 5px;
    right: 5px;
  `,
  refreshButtonWhenFiltersChanged: css`
    cursor: pointer;

    path {
      stroke: #ffa168;
    }
  `,
}

export default ClusterFilterHeader
