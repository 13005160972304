import { css } from 'react-emotion'

const resetPaginationPage = `
.paginationPage,
.paginationNext,
.paginationPrevious {
  background: transparent;
  box-shadow: none;

  &:hover > a {
    background: transparent;
  }
}
`

const styles = {
  listContainer: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    margin: 0 5px;

    @media only screen and (max-width: 1750px) {
      margin-right: 50px; //leave space for pixsy flags
    }
  `,
  paginationFooter: css`
    font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;

    .paginationItemsPerPageSelect {
      appearance: none;
      font-size: 19px;
      height: 19px;
      line-height: 19px;
      color: #121212;
      border: none;
      outline: none;
      margin-left: 0;
      background-image: url(${require('./assets/arrow-down.png')});
      background-size: 17px 9px;
      background-repeat: no-repeat;
      background-position: right center;
      background-color: transparent;
    }

    .paginationGotoContainer {
      display: flex;
      justify-content: flex-end;

      input {
        border: none;
        background: transparent;
        color: #121212;
        font-size: 19px;
        line-height: 19px;
        height: 19px;
        margin-left: 0;
      }

      button {
        height: 19px;
        position: relative;
        top: -1px;
        margin-left: 0;

        svg path {
          stroke: #212121;
        }
      }
    }

    ${resetPaginationPage}

    .paginationPage {
      letter-spacing: 0px;
      color: #121217;
      font-size: 20px;
      font-weight: 600;

      &.selected {
        background-color: #ffa168;

        a {
          color: white;
        }
      }
    }
    .paginationNext,
    .paginationPrevious {
      display: none;
    }

    .paginationFooterInfoParagraph {
      color: #21212130;
      font-size: 16px;
      text-transform: capitalize !important;
      letter-spacing: 0px;
    }
  `,
  paginationInFilterPanel: css`
    font-family: 'canada-type-gibson', Helvetica, Arial, sans-serif;
    background-color: #fcfcfc;
    position: absolute;
    top: calc(100% + 10px);

    ${resetPaginationPage}

    .paginationPage.selected {
      box-shadow: none;

      a {
        color: #3b3b3b;
        font-size: 23px;
      }
    }

    .paginationPage > a,
    .paginationBreak {
      font-size: 17px;
      font-weight: 600;
      color: #9b9b9b70;
    }

    .paginationNext,
    .paginationPrevious {
      border: 1px solid #e6e6e6;
      width: 30px;
      height: 30px;

      svg {
        margin-top: 6px;
        width: 15px;
        height: 15px;

        path {
          stroke: #3b3b3b;
        }
      }
    }
  `,
  modalHeader: css`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    padding-bottom: 10px;
    padding-left: 45px;
    justify-content: space-between;
    border-bottom: 1px #dddddd solid;
  `,
  modalTitleWrapper: css`
    z-index: 3;
    position: relative;
  `,
  modalTitle: css`
    font-size: 30px;
    color: #212121;
    width: 100%;
    display: flex;
    font-weight: 600;
  `,
  modalSubTitle: css`
    font-size: 18px;
    color: #212121;
  `,

  clusterListItemContainer: css`
    display: flex;
    flex-direction: column;
    color: #212121;
    margin-bottom: 50px;
    position: relative;
    scroll-margin-top: 30px;

    @media only screen and (max-width: 992px) {
      padding-bottom: 40px;
      margin-bottom: 20px;
    }
  `,
  clusterListItemBody: css`
    border: 1px #e8e8e8 solid;
    box-shadow: #00000010 0 1px 10px;
    margin-top: 8px;
    display: flex;
    background-color: white;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
  `,
}

export default styles
