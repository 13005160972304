import React from 'react'

export const SubmissionValidationDialogDirectHotlink = () => (
  <div>
    <h6>
      Pixsy has detected that this use is a “hotlink” use, and your work is not
      hosted by the website.
    </h6>

    <p>
      While the image may be displayed on the website, the image file is hosted
      somewhere else. According to copyright law in the jurisdictions in which
      we currently operate, we’re only able to hold websites that actively host
      your content responsible for any unauthorized use. If your image is hosted
      elsewhere (for example, hotlinked to your own website), we are unable to
      accept this match for Pixsy Case Resolution.
    </p>

    <p>
      Pixsy uses a set of algorithms to pre-determine if a case is suitable for
      resolution. We do this to save you time, and to ensure that our case
      management team can focus their efforts on recovering compensation for
      viable commercial cases.
    </p>
  </div>
)
