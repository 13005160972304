import * as React from 'react'
import { Button, LeftArrow } from 'common'
import { css } from 'react-emotion'

const pixsyStickyBarBackButton = css`
  width: 87px !important;
  min-width: 87px !important;
  max-width: 87px !important;
  height: 100% !important;
  top: 0;
  border: 0;
  padding: 0 !important;
  line-height: 13px;
  background-color: #008aab;
  align-items: center;
  justify-content: center;
  svg {
    height: 24px;
    width: 24px;
  }
`

const arrowStyle = css`
  stroke: white;
`

export const BackButton = React.memo(props => (
  <Button {...props} withFocusOutline css={pixsyStickyBarBackButton}>
    <LeftArrow style={arrowStyle} />
  </Button>
))
