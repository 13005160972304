import React, { PureComponent } from 'react'
import FullStory, { FullStoryAPI } from 'react-fullstory';


export default class FullStoryComponent extends PureComponent {


  componentDidMount() {
    setTimeout(() => {
      this.handleLogin()
    }, 3000)
  }


 componentDidUpdate(prevProps) {
    if (this.props.user && !prevProps.user) {
      this.handleLogin()
    }
  }

  handleLogin() {
    const { user } = this.props
    if (user) {
      FullStoryAPI('identify', user._id);
    } else {
      FullStoryAPI('shutdown');
    }
  }


  render() {

    return (
      <FullStory org="BB0QX"  debug />
    )
  }
}
