import * as React from 'react'
import { css, cx } from 'react-emotion'
import { Footer } from 'common'
import { PIXSY_STICKY_BAR_FULL_HEIGHT } from '../PixsyStickyBar/PixsyStickyBar'

const pixsyContainer = css`
  margin-top: -${PIXSY_STICKY_BAR_FULL_HEIGHT}px;
  padding-top: ${PIXSY_STICKY_BAR_FULL_HEIGHT}px;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
const pixsyFooter = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`
const pixsyContainerWrapperStyle = css`
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 1420px;
  width: 100%;
  margin-bottom: 84px; /* TODO: maybe handle this better (covers footbar) */
`

export const PixsyContainer = ({
  children,
  style = {},
  className,
  footer = true,
  containerRef,
}) => (
  <div
    ref={containerRef}
    data-type="PixsyContainer"
    className={cx(pixsyContainer, className, css(style))}
  >
    <div data-type="PixsyContainerWrapper" css={pixsyContainerWrapperStyle}>
      {children}
    </div>
    {footer && (
      <div data-type="PixsyFooter" css={pixsyFooter}>
        <Footer />
      </div>
    )}
  </div>
)
