import isElement from 'lodash/isElement'
import isString from 'lodash/isString'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'

export const PixsyScrollTo = React.memo(
  ({ whenChanges, offset, element = window }) => {
    const elem = useMemo(() => {
      if (element === window) return window
      if (isElement(element)) return element
      if (isString(element)) return document.querySelector(element)
    }, [element])

    const watch = useMemo(() => ({}), whenChanges)

    useEffect(() => {
      if (elem && elem.scrollTo && typeof elem.scrollTo === 'function')
        elem.scrollTo(0, offset)
    }, [watch, elem, offset])

    return null
  }
)
PixsyScrollTo.propTypes = {
  whenChanges: PropTypes.array.isRequired,
  offset: PropTypes.number,
  element: PropTypes.any,
}
