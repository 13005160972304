import { actionCreators } from '../store'

const createPageOptionsProps = (
  dispatch,
  total,
  { perPage, forcePage, sort }
) => ({
  pageCount: Math.ceil(total / perPage),
  forcePage,
  perPage,
  total,
  sort,
  onSortChange: selectedSort =>
    dispatch(actionCreators.setListViewPageSort(selectedSort)),
  onPageChange: ({ selected: page }) =>
    dispatch(actionCreators.setListViewPageNumber(page)),
  onPerPageChange: perPage =>
    dispatch(actionCreators.setListViewPerPageCount(perPage)),
})

export default createPageOptionsProps
