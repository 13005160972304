import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Link } from 'react-router-dom'
import { Button, ActionSection, ContentSection } from 'common'
import { css } from 'react-emotion'

export default function TakedownFormFreeUserSection({ onWaypoint }) {
  const lightning = require(`../takedownIcon.svg`)
  return (
    <ContentSection id="submit">
      <div css={wrap}>
        <Waypoint onEnter={onWaypoint} />

        <ActionSection last>
          <Link to="/profile/plans">
            <Button hoverMain>
              <i dangerouslySetInnerHTML={{ __html: lightning }} />
              Upgrade now to send this takedown
            </Button>
          </Link>
          <p>
            Upgrade your plan today to send and monitor takedown notices and
            benefit from additional premium features.
          </p>
        </ActionSection>
      </div>
    </ContentSection>
  )
}

TakedownFormFreeUserSection.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
}

const wrap = css`
  button {
    margin-top: 20px;
    height: 46px;
    line-height: 46px;
    padding-top: 0;
    padding-bottom: 0;

    svg {
      height: 45px;
      width: 25px;
      vertical-align: -17px;
      margin-right: 18px;
      transform: scale(1.8);
      path {
        fill: #fff;
      }
    }

    @media (max-width: 375px) {
      line-height: 2.5em;
      padding-top: 8px;
      padding-bottom: 16px;
    }

    @media (max-width: 768px) {
      height: auto;
    }
  }
`
