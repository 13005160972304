import React, {useState} from 'react';
import { css } from 'react-emotion';
import Avatar from '@material-ui/core/Avatar';
import AddCardIcon from '@material-ui/icons/CreditCardTwoTone';
import { connect } from 'react-redux';
import { updateSubscriptPaymentMethod } from '../../redux/modules/auth'
// import { paymentMethodsIconsMappping } from './utils'

const PaymentMethodItem = ({item = {}, subscription = {}, handleMakeDefault, updateSubscriptPaymentMethod}) => {
    const [loading, setLoading] = useState(false);
    const {type = '' } = item;
    const details = item[type] || {};
    const logo = type === 'card' ? item[type].brand : type;
    // const src = paymentMethodsIconsMappping[logo] || 'https://img.icons8.com/?size=512&id=82675&format=png';
    const defaultMethod =  item.id === subscription.default_payment_method
    const { last4 = '', brand, exp_month, exp_year } = details;


    const handleClick = async (e) => {
      e.preventDefault();
      setLoading(true);
      await updateSubscriptPaymentMethod(subscription.id, item.id)
      handleMakeDefault()
      setLoading(false);
    }
    return (
      <li css={liItem}>
        <div>
          <Avatar style={{ alignItems: 'none', borderRadius: 0}}>
            <AddCardIcon style={{ width: '100%', height: '100%', background: '#fff' }}  htmlColor="#008aab" />
          </Avatar>
        </div>
        <div css={cardItem}>
          <span>{`**** **** **** ${last4 || type.toUpperCase()}`}</span> <span> {exp_year && exp_year && `${exp_month}/${exp_year}`} </span>
        </div>
        {defaultMethod ? 
            <div
                style={{
                    backgroundColor: 'rgb(197,234,237)',
                    borderRadius: 4,
                    display: 'inline-block',
                }}
                css={makeDefaultButton}
            >
                <span style={{ color: 'rgb(34,102,190)'}} >Default</span>
            </div> :
          <button css={makeDefaultButton} onClick={handleClick} disabled={loading}>Make Default</button>}
      </li>
  );
}

export default connect(
  null,
  {
    updateSubscriptPaymentMethod
  }
)(PaymentMethodItem)

const liItem = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 10px 0;
`
const cardItem = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px 0 20px;
`

const makeDefaultButton = css`
  width: 100px;
  margin: 10px auto;
  opacity: 1;
  border: 0.5px #008aab;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
`