import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import * as containers from '..'
import { ErrorBoundary, Footer } from 'common'
import styled, { css } from 'react-emotion'
import Wall from './Wall/Wall'
import '../../theme3/global.css'

const renderChildRoute = (user, Component, componentProps) =>
  Component === containers.Home ? (
    <div css={routeContent}>
      <Component {...componentProps} user={user} />
    </div>
  ) : (
    <React.Fragment>
      <div css={routeContent}>
        <Component {...componentProps} user={user} />
      </div>
      <Footer />
    </React.Fragment>
  )

const renderChildRouteWithNoFooter = (user, Component, componentProps) =>
  Component === containers.Home ? (
    <div css={routeContent}>
      <Component {...componentProps} user={user} />
    </div>
  ) : (
    <React.Fragment>
      <div css={routeContent}>
        <Component {...componentProps} user={user} />
      </div>
    </React.Fragment>
  )

function PrivateRoute({ user, component, hideFooter, children, ...props }) {
  return user ? (
    <Wall user={user} location={props.location}>
      <Route
        {...props}
        render={
          hideFooter
            ? renderChildRouteWithNoFooter.bind(null, user, component)
            : renderChildRoute.bind(null, user, component)
        }
      />
    </Wall>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          redirect: props.location,
        },
      }}
    />
  )
}
function PublicRoute({ user, component, ...props }) {
  if (!user || props.location.pathname.includes('verify-email')) {
    return <Route {...props} render={renderChildRoute.bind(null, user, component)} />
  }

  const state = props.location.state

  let newState = { ...state }
  let newPathname = '/matches/overview'

  const hash = props.location.hash || ''
  if (hash.startsWith('#skip-wall')) {
    newState['skip-wall'] = true
    if (hash.includes('token:')) {
      const graphApiToken = hash.split(':')[1]
      localStorage.setItem('graph-session', graphApiToken)
      localStorage.setItem('pixsy-shark', 1)
    }
  }

  if (state && state.redirect) {
    delete newState.redirect
    return <Redirect to={{ ...state.redirect, state: newState }} />
  }

  return <Redirect to={{ state: newState, pathname: newPathname }} />
}

const ConservativeRedirect = ({ location, to, from }) => (
  // Redirects but keeps query etc intact
  <Redirect
    from={from}
    to={{
      ...location,
      pathname: to,
    }}
  />
)

const App = ({ user, location }) => {
  const isAuthenticated = !!user
  const { matches3Enabled } = (user && user.features) || {}
  const hasTopNavigation =
    isAuthenticated &&
    !(
      matches3Enabled &&
      location.pathname.startsWith('/matches') &&
      !(location.pathname.startsWith('/matches/overview') || location.pathname.startsWith('/matches/v3/overview'))
    )

  return (
    <AppWrapper hasNavigation={isAuthenticated} hasTopNavigation={hasTopNavigation} hasNavigation={Boolean(user)}>
      <ErrorBoundary>
        <Switch>
          <PublicRoute path="/resolve/:caseId" exact user={user} component={containers.ResolveContainer} />

          <PublicRoute
            path="/register/verify-email/:token"
            exact
            user={user}
            component={containers.VerifyEmailContainer}
          />
          <PublicRoute path="/(login.*|register.*)?" exact user={user} component={containers.Home} />
          <PrivateRoute path="/images/import" exact user={user} component={containers.ImportContainer} />
          <PrivateRoute path="/images/import/upload" exact user={user} component={containers.UppyImportContainer} />
          <PrivateRoute path="/images/:id(.{24})" user={user} component={containers.ImageDetailsContainer} />
          <PrivateRoute path="/images/:tags*" exact user={user} component={containers.ImagesContainer} />
          <PrivateRoute path="/matches" user={user} component={containers.Matches} />
          <PrivateRoute
            path="/takedowns/submit/:takedownId?"
            user={user}
            component={containers.TakedownSubmissionContainer}
          />
          <PrivateRoute path="/takedowns" user={user} component={containers.Takedowns} />
          <PrivateRoute
            path="/cases/submission/:caseId?"
            user={user}
            component={containers.CaseSubmissionProvider}
            hideFooter
          />
          <PrivateRoute path="/cases/:id(.{24})" user={user} component={containers.CaseDetailsContainer} />
          <PrivateRoute path="/cases/:filter" user={user} component={containers.CasesContainer} />
          <PrivateRoute
            path="/registrations/submit/:registrationId?"
            user={user}
            component={containers.RegistrationSubmissionContainer}
          />
          <PrivateRoute path="/registrations/:filter" user={user} component={containers.RegistrationsContainer} />
          <PrivateRoute path="/profile/:slug?" component={containers.ProfileContainer} user={user} />
          <PrivateRoute path="/agreements/agent-authorization" component={containers.AgentAuthorization} user={user} />
          <PublicRoute path="/agreements/licensing-agreement" component={containers.LicensingAgreement} user={user} />

          <PrivateRoute path="/manager" component={containers.ManagerContainer} user={user} />

          <Redirect from="/registrations" to="/registrations/all" />

          <ConservativeRedirect from="/cases" to="/cases/all" />
          <Route path="*" component={containers.NotFound} />
        </Switch>
      </ErrorBoundary>
    </AppWrapper>
  )
}

App.propTypes = {
  user: PropTypes.object,
}

export default App

// When positioning absolutely, center minding Navigation, but take full height:
const AppWrapper = styled.div`
  margin-left: ${(props) => (props.hasNavigation ? '110px' : 0)};
  @media (max-width: 1280px) {
    margin-left: ${(props) => (props.hasNavigation ? '95px' : 0)};
  }

  padding-top: ${(props) => (props.hasTopNavigation ? '100px' : 0)};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (max-width: 768px) {
    margin-left: 0;
    padding-top: 30px;
  }
`

const routeContent = css`
  flex: 1;
`
