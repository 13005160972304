import * as React from 'react'
import { css } from 'react-emotion'
import propTypes from 'prop-types'

const iconIgnore = css`
  fill: none;
  stroke: currentColor;
  stroke-width: 1.2px;
`
const IconIgnore = ({ height = 14.05, width }) => (
  <svg viewBox="0 0 14.896 14.896" height={height} width={width}>
    <g
      id="Group_1559"
      data-name="Group 1559"
      transform="translate(-1317.884 -588.725)"
    >
      <path
        id="Path_104"
        data-name="Path 104"
        css={iconIgnore}
        d="M20396.156,2444l14.043,14.047"
        transform="translate(-19077.85 -1854.849)"
      />
      <path
        id="Path_105"
        data-name="Path 105"
        css={iconIgnore}
        d="M0,0,14.044,14.047"
        transform="translate(1318.307 603.194) rotate(-90)"
      />
    </g>
  </svg>
)
IconIgnore.propTypes = {
  height: propTypes.number,
  width: propTypes.number,
}

export default IconIgnore
