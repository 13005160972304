import React from 'react'
import { css } from 'react-emotion'
import { Flag } from 'common'
import COUNTRIES from 'pixsy-constants/countries.json'

const SUPPORTED_COUNTRIES = COUNTRIES.filter(c => c.resolution)
  .sort((a, b) => a.country.localeCompare(b.country))
  .map(c => ({
    country: c.country.replace(/\s+/g, '-'),
    label: c.country,
  }))

export default function Flags() {
  return (
    <ul className={countries}>
      {SUPPORTED_COUNTRIES.map(specs => (
        <FlagItem key={specs.country} {...specs} />
      ))}
    </ul>
  )
}

Flags.propTypes = {}

function FlagItem({ label, country }) {
  return (
    <li>
      <Flag country={country} />
      {label}
    </li>
  )
}

const countries = css`
    padding: 0;
    text-align: left;
    margin: 20px auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    

    li{
        flex: 0 auto;
        display: inline-block;
        vertical-align: top;
        list-style: none;
        width: 20%;
        text-align: center;
        
        @media(max-width: 994px) {
            width: 25%;
            height: 110px;
            text-align: center;
        }

        img{
            display: block;
            margin: 10px auto 0;
        }
    }
}
`
