import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import _get from 'lodash/get'
import { LoadingOverlay } from 'common'
import { Grid } from '@material-ui/core'

import Header from './header'
import currentUserQuery from '../../../../graphql/queries/user.query'
import updateMatchPreferenceMutation from '../../../../graphql/queries/updateMatchPreferences.mutation'
import UserContent from './userContent'
import Countries from './countries'
import Categories from './categories'
import Platforms from './platforms'

const MatchPreferencesContainer = () => {
  const { loading, error, data } = useQuery(currentUserQuery, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <React.Fragment>
      <Header
        total={_get(data, 'user.clusterSummary.actionable')}
        hidden={_get(data, 'user.clusterSummary.excludedViaPreferences')}
      />
      <MatchPreferenceComponent data={data} loading={loading} error={error} />
    </React.Fragment>
  )
}

const MatchPreferenceComponent = ({ data, loading, error }) => {
  const [updateMatchPreference] = useMutation(updateMatchPreferenceMutation, {
    refetchQueries: [{ query: currentUserQuery }],
  })

  if (loading) return <LoadingOverlay show />

  if (error) {
    return (
      <Grid container direction="column" align="center" justify="center">
        <h1>Oops. Something went wrong!</h1>
      </Grid>
    )
  }

  const tagCounts = _get(data, 'user.clusterSummary.tags')
  const userPhotographerFields = _get(data, 'user.photographerFields')
  const hiddenUserContent = Object.fromEntries(
    Object.entries(_get(data, 'user.matchPreferences.hidden.userContent') || { websites: [] }).filter(
      ([key, value]) => key !== '__typename' && Boolean(value)
    )
  )
  const hiddenCountries = _get(data, 'user.matchPreferences.hidden.countries')
  const hiddenCategories = _get(data, 'user.matchPreferences.hidden.categories')
  const platforms = Object.entries(_get(data, 'user.matchPreferences.hidden.platforms') || {})
  const hiddenPlatforms =
    platforms.filter(([key, value]) => key !== '__typename' && value && value.length > 0).map(([key]) => key) || []

  return (
    <React.Fragment>
      <UserContent
        hiddenUserContent={hiddenUserContent}
        userPhotographerFields={userPhotographerFields}
        updateMatchPreference={updateMatchPreference}
      />
      <Countries
        hiddenCountries={hiddenCountries}
        updateMatchPreference={updateMatchPreference}
        tagCounts={tagCounts}
      />
      <Platforms hiddenPlatforms={hiddenPlatforms} updateMatchPreference={updateMatchPreference} />
      <Categories
        hiddenCategories={hiddenCategories}
        updateMatchPreference={updateMatchPreference}
        tagCounts={tagCounts}
      />
    </React.Fragment>
  )
}

export default MatchPreferencesContainer