import React from 'react'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { asyncConnect } from 'redux-connect'
import { compose } from 'recompose'
import { withEmptyStates } from '../../../../decorators'
import PaymentSection from './PaymentSection'
import NoContent from './Payments/NoContent'
import { load } from '../../../../redux/modules/registrations'
import { assembleRegistration } from '../../../../redux/selectors'

const log = require('debug')('registrations:payment')
export function SubmissionPayment({ user, registration, onWaypoint, values }) {
  log('Loaded: %O', registration)

  return (
    <PaymentSection
      user={user}
      registration={registration}
      onWaypoint={onWaypoint}
      values={values}
    />
  )
}

export default compose(
  asyncConnect(
    [
      {
        promise: function handleFetch({
          store: { dispatch },
          match: {
            params: { registrationId },
          },
        }) {
          return Promise.all([dispatch(load(registrationId))])
        },
      },
    ],
    (state, props) => ({
      registration: assembleRegistration(state, props),
    }),
    { push }
  ),
  withEmptyStates([
    {
      shouldRender: props => false,
      render: () => <NoContent />,
    },
  ])
)(SubmissionPayment)

SubmissionPayment.propTypes = {
  registration: PropTypes.object.isRequired,
}
