import { gql } from '@apollo/client'

export default gql`
  mutation updateMatchFalsePositiveReview($id: ObjectID!, $review: Int!) {
    updateMatchFalsePositiveReview(id: $id, review: $review) {
      _id
      origin
      url
      photo
      similarity
      verification
    }
  }
`
