import generateMatchViewersPath from './helpers/generateMatchViewersPath'
import parseLocationForSelectedContextState from './helpers/parseLocationForSelectedContextState'
import {
  defaultSelectedClusterFiltersState,
  defaultSelectedGroupMatchFiltersState,
  defaultState,
  LIST_TYPE,
} from './constants'

/**
 * Synchronize the filters in the store with the displayed /matches URL, as both
 * should reflect the same information.
 *
 * NOTE: This only handles the /matches URL
 *
 * @param {object} state - Current state of the store to be synced into the URL
 */
const syncStoreAndMatchesURL = (state) => {
  const currentURL = state.routerHistory.location.pathname + state.routerHistory.location.search
  const shouldUpdateURL = currentURL.startsWith('/matches/v3')

  if (!shouldUpdateURL) return

  const newURL = `/matches/v3${generateMatchViewersPath(state)}`

  if (currentURL !== newURL) {
    state.routerHistory.push(newURL)
  }
}

const resetSelectedFiltersToDefault = state => {
  if (state.activeList === LIST_TYPE.CLUSTER) {
    return {
      ...state,
      clusterListFilters: {
        ...defaultSelectedClusterFiltersState,
      },
    }
  } else {
    return {
      ...state,
      groupMatchListFilters: {
        ...defaultSelectedGroupMatchFiltersState,
        cluster: state.groupMatchListFilters.cluster,
        clusterId: state.groupMatchListFilters.clusterId,
      },
    }
  }
}

const placePayloadToViewState = (state, payload) => ({
  ...state,
  [`${state.activeList}ListFilters`]: {
    ...state[`${state.activeList}ListFilters`],
    ...payload,
  },
})

const placePayloadToContextState = (state, payload) => ({
  ...state,
  ...payload,
})

const addIdToSelectedFiltersState = (state, filterKey, idToAdd) => ({
  ...state,
  [`${state.activeList}ListFilters`]: {
    ...state[`${state.activeList}ListFilters`],
    [filterKey]: [
      ...new Set([
        ...state[`${state.activeList}ListFilters`][filterKey],
        idToAdd,
      ]),
    ],
  },
})

const getInitialState = () => {
  if (typeof window !== 'undefined') {
    const contextState = parseLocationForSelectedContextState(window.location)

    return {
      ...defaultState,
      ...contextState,
    }
  } else return defaultState
}

export {
  syncStoreAndMatchesURL,
  resetSelectedFiltersToDefault,
  placePayloadToViewState,
  placePayloadToContextState,
  addIdToSelectedFiltersState,
  getInitialState,
}
