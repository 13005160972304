import React from 'react'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { ActionButton, ClearbitLogo, Flag } from 'common'
import FixedHeightImageContained from './FixedHeightImageContained'

import * as Shapes from '../utils/shapes'
import resolveValuesFromTags from '../utils/resolveValuesFromTags'
import resolveValuesFromDomain from '../utils/resolveValuesFromDomain'
import * as PropTypes from 'prop-types'
import { useIgnoreCategory, useIgnoreCountry, useIgnoreDomain } from '../utils/useClusters'
import getClearBitLogoFallbackLetter from '../utils/getClearBitLogoFallbackLetter'

const IgnoredMatchItem = ({ cluster, undoIgnore, globalDispatch }) => {
  const { countryName, countryCode } = resolveValuesFromTags(cluster.tags)
  const { categoryName } = resolveValuesFromDomain(cluster.domain)

  const [ignoreCategory] = useIgnoreCategory(globalDispatch, categoryName)
  const [ignoreCountry] = useIgnoreCountry(globalDispatch, countryName)
  const [ignoreDomain] = useIgnoreDomain(globalDispatch, cluster.domain.host)

  const handleIgnoreCategory = () => {
    ignoreCategory({ variables: { category: categoryName, state: true } })
  }

  const handleIgnoreCountry = () => {
    ignoreCountry({ variables: { isoCode: countryCode, state: true } })
  }

  const handleIgnoreDomain = () => {
    ignoreDomain({ variables: { url: cluster.domain.host, clusterId: cluster._id } })
  }

  return (
    <div className={styles.container}>
      <div className={styles.flexRow}>
        <div className={styles.images}>
          <FixedHeightImageContained
            url={cluster.images[0].url}
            height={104}
            id={cluster.images[0]._id}
            offlineLabel="image"
          />
          <FixedHeightImageContained
            url={cluster.matches[0].url}
            height={104}
            id={cluster.matches[0]._id}
            offlineLabel="match"
          />
        </div>
        <p className={styles.descriptionText}>
          Match ignored. &nbsp;
          <a onClick={undoIgnore} className={styles.undoLink}>
            Click here to undo
          </a>
        </p>
      </div>
      <div className={styles.flexRow}>
        <p className={styles.descriptionText}>Hide all future matches from this</p>
        <div className={styles.buttonsWrapper}>
          {cluster.domain.host && (
            <ActionButton
              Icon={() => (
                <ClearbitLogo
                  host={cluster.domain.host}
                  size={25}
                  fallbackLetter={getClearBitLogoFallbackLetter(cluster)}
                />
              )}
              text="DOMAIN"
              onClick={handleIgnoreDomain}
            />
          )}
          {categoryName && (
            <ActionButton
              Icon={() => (
                <span style={{ fontSize: 10, fontWeight: 300, lineHeight: 1.2, textAlign: 'center' }}>
                  {categoryName}
                </span>
              )}
              text="CATEGORY"
              onClick={handleIgnoreCategory}
            />
          )}
          {countryName && (
            <ActionButton
              Icon={() => (
                <Flag
                  country={countryName.replace(/ /g, '-')}
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                />
              )}
              text="COUNTRY"
              onClick={handleIgnoreCountry}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  buttonsWrapper: css`
    display: flex;
    flex-direction: row;
    margin-right: 14px;
    gap: 7px;
  `,
  descriptionText: css`
    color: #343434;
    font-size: 14px;
    margin: 0;
    padding: 0 30px;
  `,
  undoLink: css`
    cursor: pointer;
    color: #008aab;
  `,
  flexRow: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  container: css`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e8e8e8;
    box-shadow: #00000006 0 1px 10px;
    height: 104px;
    background: #fcfcfc;
    color: #212121;
    margin-bottom: 50px;
  `,
  images: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
    width: 300px;
    opacity: 0.75;
  `,
}

IgnoredMatchItem.propTypes = {
  cluster: Shapes.Cluster,
  undoIgnore: PropTypes.func.isRequired,
}

export default connect(null, (globalDispatch) => ({ globalDispatch }))(IgnoredMatchItem)
