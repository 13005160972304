import { SUBMISSION_NAMES as NAMES } from 'pixsy-schema/case/SubmissionSchema'

export default {
  [NAMES.koUserIsLegalRightsOwner]: {
    title: 'You must be the legal copyright holder',
    body:
      'Submitting a case to Pixsy authorizes us to take legal action on your behalf as the legal owner of the image(s). Pixsy requires that you are the owner of the image or the authorized agent of the owner in order for us to accept your case.',
    elevioId: 70, // null!
  },

  [NAMES.koImageUserIsBusiness]: {
    title: 'This must be a commercial use of your work',
    body:
      'Unless the website is a commercial business, major publication, organization, institution or government agency, it is unlikely that Pixsy will be able to pursue your case. For non-commercial infringements, we recommend sending a takedown notice using our takedown tool.',
    elevioId: 70,
  },

  [NAMES.screeningCaseAboutBreachOfContract]: {
    title: 'What does a breach of contract or licensing terms mean?',
    body:
      'If you originally had a licensing agreement or contract for a specified use of your work, and these terms have been breached by the original image user or client, Pixsy may still be able to accept this case.',
  },

  [NAMES.screeningCaseAboutBreachOfContractComment]: {
    title: 'What are the specific terms that have been breached?',
    body:
      '	In order to review this matter for case resolution, we require a copy of all past documentation, including licensing agreements, contracts, or communications specifying the terms of use. Please summarize the situation here and documents can be uploaded at the end of the form.	',
  },

  [NAMES.stockIncluded]: {
    title:
      'Select "yes" if the work was available with a stock agency at any point in time',
    body:
      'Select "yes" if you‘ve ever worked with an agency, past or present - even if the image is no longer listed with this agency and you never received a report of a license sale.',
    elevioId: 72,
  },

  [NAMES.stockVerified]: {
    title: 'How do I verify sales information with my stock agencies?',
    body:
      'Simply referencing your agency sales sheet won‘t always provide you with accurate information regarding whether or not a usage has been licensed to the image user in question. Pixsy recommends contacting your stock agency or agencies on an individual basis to confirm that a specific use is unlicensed and in order to ensure that we‘re strictly acting on an unlicensed use. You can save your case to a “draft” while you await confirmation from your stock agency or agencies.',
  },

  [NAMES.screeningPreviousContactWithImageUser]: {
    title:
      'Why is it important to tell us about your communication with the image user?',
    body:
      'Some circumstances make it difficult for us to continue with a case when they image user has already been contacted - for example, if a takedown request was previously sent with no request for financial compensation made. We review these kinds of submissions on a case by case basis.',
    elevioId: 19,
  },

  [NAMES.screeningPreviousContactWithImageUserComment]: {
    title: 'What kind of information do we require to review your case?',
    body:
      'We require a copy of all documentation, including any past communications or invoices that were exchanged. Please summarize the situation briefly here and documents can be uploaded at the end of the form.',
    elevioId: 19,
  },

  [NAMES.workForHire]: {
    title: 'What is a paid or commissioned work?',
    body:
      'Select "yes" if a client specifically hired you to take an image or images.',
  },

  [NAMES.contest]: {
    title: 'Check the terms and conditions',
    body:
      'Many contests hold broad usage terms for submitted content, including in some cases third-party rights. Please check the contract terms to ensure that there are no usage rights that could include this usage of your work. ',
  },

  [NAMES.country]: {
    title: 'What is the legally operating country?',
    body:
      'We are able to accept cases for resolution if an image user has an operating office and business address in one of Pixsy‘s supported countries.',

    elevioId: 17,
  },

  [NAMES.noticed]: {
    title:
      'Were you aware of this image use prior to submitting this case to Pixsy?',
    body:
      'By default, Pixsy will automatically provide the date that this match of your work was found by Pixsy. Please manually enter an exact or approximate date if you were aware of this usage prior to the match being found by Pixsy.',
  },
}
