import React from 'react'
import PropTypes from 'prop-types'
import { Button, createContactUsHandler } from 'common'
import {
  PixsyGrid,
  PixsyGridItem,
  PixsyButtonGroup,
} from '../../containers/Cases/CaseSubmission/Components'

export default function DomainUnresolvable({ onTakedown, onIgnore }) {
  return (
    <PixsyGrid alignContent="stretch" direction="column" alignItems="center">
      <h1>This match is not a viable case</h1>

      <h4>
        Pixsy has previously identified that this domain is not viable for
        commercial resolution.
      </h4>

      <p>
        We recommend that you <b>send a Takedown Notice</b> for this match,
        requesting the image is removed by the website operator or host.
      </p>

      <p>
        Pixsy uses a set of algorithms to pre-determine if a case is suitable
        for resolution. We do this to save you time, and to ensure that our case
        management team can focus their efforts on recovering compensation for
        viable commercial cases.
      </p>

      <p>
        If you believe this domain has been blocked but the case is still a
        commercial use within a Pixsy supported country, you can contact us and
        we will further evaluate the case for you.
      </p>
      <PixsyGridItem>
        <PixsyButtonGroup>
          <Button
            onClick={createContactUsHandler('Unresolvable Domain')}
            greyOutline
            full
          >
            Contact us
          </Button>
          <Button full grey onClick={onIgnore}>
            Ignore Match
          </Button>
          <Button full hoverMain onClick={onTakedown}>
            Send Takedown
          </Button>
        </PixsyButtonGroup>
      </PixsyGridItem>

      {!global.PRODUCTION && <p className="debug">DomainUnresolvable</p>}
    </PixsyGrid>
  )
}

DomainUnresolvable.propTypes = {
  onIgnore: PropTypes.func.isRequired,
}
