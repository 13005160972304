import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Field } from 'redux-form'
import {
  ContentSection,
  SexySeparator,
  TextAreaVertical,
  TickCheckbox,
  TextField,
  OffSectionTooltip,
} from 'common'
import { PHASE } from '../../../../../../shared/forms/registrationSubmission'

function SubmissionFormLegal({ onWaypoint, getFieldPropsFor, phase }) {
  return (
    <ContentSection id="legal">
      <Waypoint onEnter={onWaypoint} />
      <Field
        component={TextAreaVertical}
        {...getFieldPropsFor('LEGAL_COMMENTS')}
        disabled={phase === PHASE.FINAL}
      />
      <SexySeparator />
      <Field
        component={TickCheckbox}
        {...getFieldPropsFor('KNOCKOUT_USER_IS_AUTHOR')}
        locked={phase === PHASE.FINAL}
      />
      <SexySeparator />
      <Field
        component={TickCheckbox}
        {...getFieldPropsFor('KNOCKOUT_LIABILITY')}
        locked={phase === PHASE.FINAL}
      />
      <SexySeparator />
      <Field
        component={TickCheckbox}
        {...getFieldPropsFor('KNOCKOUT_PERSISTENCE')}
        locked={phase === PHASE.FINAL}
      />
      <SexySeparator />

      <Field
        component={TickCheckbox}
        {...getFieldPropsFor('KNOCKOUT_AGENT_AUTHORIZATION')}
        locked={phase === PHASE.FINAL}
      />
      <SexySeparator />
      <Field
        component={TickCheckbox}
        {...getFieldPropsFor('KNOCKOUT_TOS')}
        locked={phase === PHASE.FINAL}
      />
      <OffSectionTooltip>
        If you enter a coupon code, your registration transaction will been
        processed and the discount will be applied to the payment.
      </OffSectionTooltip>
      <Field
        component={TextField}
        {...getFieldPropsFor('COUPON_PROXY')}
        locked={phase === PHASE.FINAL}
      />
    </ContentSection>
  )
}

export default SubmissionFormLegal

SubmissionFormLegal.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  getFieldPropsFor: PropTypes.func.isRequired,
}
