import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Redirect, Route, Switch } from 'react-router'
import { GridWrappingColumn, LoadingOverlay } from 'common'
import _get from 'lodash/get'
import FalsePositiveReviewContainer from './FalsePositiveReview'

const ManagerContainer = ({ user }) => {
  const [sharkMode, setSharkMode] = useState(false)
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      const isSharkMode = Boolean(localStorage.getItem('pixsy-shark'))
      setSharkMode(isSharkMode)
      setShouldRender(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  if (!shouldRender) {
    return <LoadingOverlay show />
  }

  return (
    <React.Fragment>
      <Helmet title="Manager" />
      {(sharkMode && (
        <GridWrappingColumn>
          <Switch>
            <Route
              path="/manager/false-positive-review/:matchId?"
              render={props => (
                <FalsePositiveReviewContainer
                  key={
                    _get(props, 'match.params.matchId') || 'falsePositiveReview'
                  }
                  user={user}
                  {...props}
                />
              )}
            />
          </Switch>
        </GridWrappingColumn>
      )) || <Redirect to="/" />}
    </React.Fragment>
  )
}

export default ManagerContainer
