import { createStore as _createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createMiddleware from './middleware/clientMiddleware'
import notificationMiddleware from './middleware/notificationMiddleware'
import sentryMiddleware from './middleware/sentryMiddleware'
import invalidationMiddleware from './middleware/invalidationMiddleware'
import mixpanelMiddleware from './middleware/mixpanelMiddleware'

export default function createStore(client, history, data = {}) {
  const middleware = [createMiddleware(client)]

  // Only show notifications on client (cant dispose them if they originate from the server)
  if (__CLIENT__) {
    middleware.push(
      notificationMiddleware,
      invalidationMiddleware,
      mixpanelMiddleware,
      routerMiddleware(history)
    )

    if (global.NODE_ENV === 'production') {
      middleware.push(sentryMiddleware)
    }
  }

  let finalCreateStore

  // NOTE: See Github issue #470
  if (__DEVELOPMENT__ && __CLIENT__) {
    // https://github.com/leoasis/redux-immutable-state-invariant/blob/master/src/isImmutable.js
    const isImmutable = value =>
      typeof value !== 'object' ||
      value === null ||
      typeof value === 'undefined'
    // Function passed to risi determines whether properties of a object should be compared
    const immutableMiddleware =
      require('redux-immutable-state-invariant').default({
        isImmutable: value => isImmutable(value) || value instanceof File,
        ignore: ['form', 'reduxAsyncConnect', 'router', 'pagination'],
      })
    middleware.unshift(immutableMiddleware)
  }

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: 1, traceLimit: 25 })
      : compose

  finalCreateStore = composeEnhancers(applyMiddleware(...middleware))(
    _createStore
  )

  let store
  if (__CLIENT__) {
    store = finalCreateStore(require('./modules/reducer').default, data)
  } else {
    store = finalCreateStore(require('./modules/reducer'), data)
  }

  if (module.hot) {
    module.hot.accept('../routes', () => {
      store.replaceReducer(require('./modules/reducer').default)
    })
  }

  return store
}
