import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TAKEDOWN_JURISDICTIONS } from 'pixsy-constants'
import { Dialog } from 'common'
import { submit } from '../../../redux/modules/takedowns'
import { TakedownUpgradeDialog } from './TakedownUpgradeDialog'
import TakedownForm from './TakedownForm'

const log = require('debug')('takedown:data')

export class TakedownFormDataProvider extends React.Component {
  static MAX_IMAGES_PER_TAKEDOWN = 20

  state = { modal: null }

  shouldComponentUpdate(nextProps) {
    // Navigating away
    if (this.props.cluster && nextProps.cluster === null) return false

    return true
  }

  onSubmitForm = async (values, dispatch, { cluster }) => {
    const { images, matches, ...rest } = values

    let response

    try {
      response = await dispatch(
        submit({
          cluster: {
            _id: cluster._id,
            case: cluster.case,
            takedown: cluster.takedown,
            images,
            matches,
          },
          ...rest,
          external: false,
        })
      )
    } catch (e) {
      console.error(e.status, e.message)
      if (e.status === 402) this.setState({ modal: <TakedownUpgradeDialog /> })
      return
    }

    if (response && response.payload) {
      this.props.onSuccess(response.payload.remainingQuota)
    }
  }
  onSubmitExternal = async (values, dispatch, { cluster }) => {
    const { images, matches, ...rest } = values

    let response

    try {
      response = await dispatch(
        submit({
          cluster: {
            _id: cluster._id,
            case: cluster.case,
            takedown: cluster.takedown,
            images,
            matches,
          },
          ...rest,
          external: true,
          goodFaith: true,
          disclaimer: true,
        })
      )
    } catch (e) {
      console.error(e.status, e.message)
      if (e.status === 402) this.setState({ modal: <TakedownUpgradeDialog /> })
      return
    }

    if (response && response.payload) {
      this.props.onSuccess(response.payload.remainingQuota)
    }
  }

  resolveDefaultJurisdiction(cluster) {
    let jurisdiction = TAKEDOWN_JURISDICTIONS[0].code
    const countryTag = cluster.tags.find(t => t.startsWith('country:'))
    if (countryTag) {
      log('Country Tag found: %o', countryTag)
      const iso = countryTag.split(':')[1]
      let matchingJurisdiction = TAKEDOWN_JURISDICTIONS.find(j =>
        j.countries.includes(iso)
      )
      log('Matching Jurisdiction foun: %o', matchingJurisdiction)
      if (matchingJurisdiction) {
        return matchingJurisdiction.code
      }
    }
    return jurisdiction
  }

  updateClusterData(data) {
    // console.log('update cluster data ', data)
  }

  handleModalClose = () => {
    this.setState({ modal: null })
  }

  render() {
    const {
      user,
      loading,
      cluster,
      formState,
      content,
      location,
      suggestedEmails,
    } = this.props
    const { modal } = this.state

    const requiresExternalSubmission = !!cluster.domain.dmcaFormUrl

    const images = cluster.images.map(image => {
      if (!image.origin.url) {
        return image
      }

      let bestPublicationSource =
        image.licensing &&
        image.licensing.first_published &&
        image.licensing.first_published.place

      if (!bestPublicationSource) {
        bestPublicationSource = image.origin.url
      }

      return {
        ...image,
        licensing: {
          ...image.licensing,
          first_published: {
            ...(image && image.licensing
              ? image.licensing.first_published
              : {}),
            place: bestPublicationSource,
          },
        },
      }
    })

    const canTakedown =
      (user.allowance && user.allowance.freeTakedowns > 0) ||
      user.plan.features.takedowns > 0

    const initialValues = {
      images,
      matches: cluster.matches,
      to: suggestedEmails === null ? 'Fetching data' : suggestedEmails,
      jurisdiction: this.resolveDefaultJurisdiction(cluster),
      additionalExplanation: '',
      goodFaith: false,
      disclaimer: false,
      ...(requiresExternalSubmission && { content }),
    }

    return (
      <React.Fragment>
        <Dialog isOpen={Boolean(modal)} onRequestClose={this.handleModalClose}>
          {modal}
        </Dialog>
        <TakedownForm
          location={location}
          onSubmit={
            requiresExternalSubmission
              ? this.onSubmitExternal
              : this.onSubmitForm
          }
          updateClusterData={this.updateClusterData}
          requiresExternalSubmission={requiresExternalSubmission}
          cluster={cluster}
          loading={loading}
          formState={formState}
          initialValues={initialValues}
          canTakedown={canTakedown}
          suggestedEmails={suggestedEmails}
          user={user}
        />
      </React.Fragment>
    )
  }
}

export default connect(state => ({
  formState: state.form.takedown,
  content: state.takedowns.content,
  suggestedEmails: state.takedowns.suggestedEmails,
  loading:
    state.takedowns.loading || state.images.loading || state.clusters.loading,
}))(TakedownFormDataProvider)

TakedownFormDataProvider.propTypes = {
  location: PropTypes.object.isRequired,
  cluster: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  content: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  suggestedEmails: PropTypes.string,
}
