import React from 'react'
import styled from 'react-emotion'

const SelfCenter = ({ children }) => {
  return <SelfCenterStyled>{children}</SelfCenterStyled>
}

export default SelfCenter

const SelfCenterStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
