import React from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import { Field } from 'redux-form'
import {
  CountrySelect,
  DatePicker,
  AlignLabels,
  ContentSection,
  TextField,
} from 'common'
import moment from 'moment'
import { PHASE } from '../../../../../../shared/forms/registrationSubmission'

export default function SubmissionFormAuthor({
  registrationId,
  getFieldPropsFor,
  onWaypoint,
  phase,
  values,
}) {
  const AUTHOR_DATE_OF_BIRTH = getFieldPropsFor('AUTHOR_DATE_OF_BIRTH')

  return (
    <ContentSection id="author">
      <Waypoint onEnter={onWaypoint} />
      <h1>Author</h1>
      <AlignLabels align="left" width="200px">
        <Field
          component={TextField}
          {...getFieldPropsFor('AUTHOR_FIRST_NAME')}
          locked={phase === PHASE.FINAL}
        />
        <Field
          component={TextField}
          {...getFieldPropsFor('AUTHOR_LAST_NAME')}
          locked={phase === PHASE.FINAL}
        />

        <Field
          component={DatePicker}
          {...AUTHOR_DATE_OF_BIRTH}
          datepickerProps={{
            minDate: moment().subtract(120, 'years'),
            maxDate: moment().subtract(16, 'years'),
          }}
          disabled={phase === PHASE.FINAL}
        />

        <Field
          component={CountrySelect}
          {...getFieldPropsFor('AUTHOR_CITIZEN')}
          locked={phase === PHASE.FINAL}
        />
        <Field
          component={CountrySelect}
          {...getFieldPropsFor('AUTHOR_RESIDENT')}
          locked={phase === PHASE.FINAL}
        />
        <Field
          component={TextField}
          {...getFieldPropsFor('AUTHOR_STREET')}
          locked={phase === PHASE.FINAL}
        />
        <Field
          component={TextField}
          {...getFieldPropsFor('AUTHOR_CITY')}
          locked={phase === PHASE.FINAL}
        />
        <Field
          component={TextField}
          {...getFieldPropsFor('AUTHOR_STATE')}
          locked={phase === PHASE.FINAL}
        />
        <Field
          component={TextField}
          {...getFieldPropsFor('AUTHOR_POSTAL_CODE')}
          locked={phase === PHASE.FINAL}
        />
        <Field
          component={CountrySelect}
          {...getFieldPropsFor('AUTHOR_COUNTRY')}
          disabled={phase === PHASE.FINAL}
        />
        <Field
          component={TextField}
          {...getFieldPropsFor('AUTHOR_PHONE')}
          locked={phase === PHASE.FINAL}
        />
      </AlignLabels>
    </ContentSection>
  )
}

SubmissionFormAuthor.propTypes = {
  onWaypoint: PropTypes.func.isRequired,
  getFieldPropsFor: PropTypes.func.isRequired,
  registrationId: PropTypes.string,
  phase: PropTypes.string.isRequired,
}
