import React from 'react'
import PropTypes from 'prop-types'
import { ContentSection } from 'common'
import BioHeader from './BioHeader'
import BioForm from './BioForm'
import AgencyForm from './AgencyForm'
import LicensingForm from './LicensingForm'

export default function BioContainer({ user }) {
  return (
    <div>
      <BioHeader user={user} />
      <ContentSection>
        <BioForm user={user} />
      </ContentSection>
      <ContentSection>
        <LicensingForm user={user} />
      </ContentSection>
      <ContentSection>
        <AgencyForm user={user} />
      </ContentSection>
    </div>
  )
}

BioContainer.propTypes = {
  user: PropTypes.object.isRequired,
}
