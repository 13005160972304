import * as actions from './actions'
import { LIST_TYPE } from './constants'
import {
  addIdToSelectedFiltersState,
  syncStoreAndMatchesURL,
  placePayloadToContextState,
  placePayloadToViewState,
  resetSelectedFiltersToDefault,
} from './utils'
import debug from 'debug'

const log = debug('MV3:Store')

const MatchViewerContextReducer = (state, { type, payload } = {}) => {
  log(`[${type}] Payload:`, payload)
  let newState

  switch (type) {
    case actions.SET_SELECTED_ACTION_IDS:
    case actions.SET_SELECTED_YEAR_RANGE:
    case actions.SET_SELECTED_SEEN_STATE:
    case actions.UPDATE_SELECTED_FILTERS:
    case actions.SET_SELECTED_COUNTRY_IDS:
    case actions.SET_SELECTED_MATCHES_TYPE:
    case actions.SET_SELECTED_CATEGORY_IDS:
      newState = placePayloadToViewState(state, payload)
      newState.pageOptions.forcePage = 0
      break

    case actions.SET_GROUP_MATCHES_VIEW_OPEN:
      newState = {
        ...state,
        groupMatchListFilters: { ...state.groupMatchListFilters, ...payload },
        activeList: LIST_TYPE.GROUP_MATCH,
      }
      break

    case actions.ADD_SELECTED_ACTION_ID:
    case actions.ADD_SELECTED_COUNTRY_ID:
    case actions.ADD_SELECTED_CATEGORY_ID:
      newState = addIdToSelectedFiltersState(state, payload.selectedFilterKey, payload.id)
      newState.pageOptions.forcePage = 0
      break

    case actions.RESET_USER_SELECTIONS_STATE:
      newState = resetSelectedFiltersToDefault(state)
      newState.pageOptions.forcePage = 0
      break

    case actions.SET_COLLAPSABLE_CONTENT_CLOSE_FUNCTION:
    case actions.RESET_LAST_IGNORED_MATCH:
    case actions.UPDATE_SELECTED_CONTEXT:
    case actions.SET_LAST_IGNORED_MATCH:
      newState = placePayloadToContextState(state, payload)
      break

    case actions.SET_MORE_MODAL_VIEW_OPEN:
    case actions.SET_DETAILS_MODAL_VIEW_OPEN:
    case actions.SET_ACTIONS_MODAL_VIEW_OPEN:
    case actions.SET_MODAL_VIEW_CLOSE:
      newState = {
        ...state,
        detailsView: {
          ...payload,
        },
      }
      break

    case actions.SET_LIST_VIEW_PAGE_NUMBER:
    case actions.SET_LIST_VIEW_PER_PAGE_COUNT:
    case actions.SET_LIST_VIEW_PAGE_SORT:
      newState = {
        ...state,
        pageOptions: {
          ...state.pageOptions,
          ...payload,
        },
      }
      break

    case actions.SET_GROUP_MATCHES_VIEW_CLOSE:
      newState = {
        ...state,
        activeList: LIST_TYPE.CLUSTER,
        groupMatchListFilters: {
          ...state.groupMatchListFilters,
          cluster: null,
          clusterId: null,
        },
        scrollToClusterId: payload.clusterId,
      }
      break

    case actions.RESET_SCROLL_TO_CLUSTER_ID:
      newState = {
        ...state,
        scrollToClusterId: null,
      }
      break

    default:
      return state
  }

  // TODO BELOW:
  // This is not a very good practice, as reducers should be free of any side effects. Ideally, this implementation
  // should be removed in favour of just using the router alone or using a sync library such as connected-react-router.
  //
  // Check this for reference:
  // * https://stackoverflow.com/questions/36596996/how-to-sync-redux-state-and-url-query-params/36657751#36657751
  // * https://www.npmjs.com/package/connected-react-router.
  // * https://github.com/supasate/connected-react-router
  //
  // Keep in mind that, when replacing this implementation, we should ensure that the filters state is kept when
  // navigating back and forth between group views and the list view.
  syncStoreAndMatchesURL(newState)

  return newState
}

export default MatchViewerContextReducer
