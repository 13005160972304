import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { Button, createContactUsHandler } from 'common'

export default function SubmissionValidationDialogDomainStock({
  onTakedown,
  onIgnore,
  cluster,
}) {
  return (
    <div css={wrap}>
      <h1>This match is not a viable case</h1>

      <h4>
        Pixsy has detected that this domain matches a known stock website.
      </h4>
      <p>
        Individual users post content on stock websites, and infringements on
        stock websites are therefore classified as user-submitted content. As
        Pixsy does not pursue cases against individuals, matches on stock
        websites are not accepted for Pixsy’s Case Resolution Services.
      </p>
      <p>
        We recommend that you <b>send a Takedown Notice</b> for this match,
        requesting the image is removed by the website operator or host.
      </p>
      <p>
        Pixsy uses a set of algorithms to pre-determine if a case is suitable
        for resolution. We do this to save you time and to ensure that our case
        management team can focus their efforts on recovering compensation for
        viable cases.
      </p>

      <div className="dialog-slider--button-wrapper">
        <Button onClick={createContactUsHandler('Stock Domain')} greyTertiary>
          Contact us
        </Button>
        <Button grey onClick={onIgnore}>
          Ignore Match
        </Button>

        <Button hoverMain onClick={onTakedown}>
          Send Takedown
        </Button>
      </div>
      {!global.PRODUCTION && (
        <p className="debug">SubmissionValidationDialogDomainStock</p>
      )}
    </div>
  )
}

SubmissionValidationDialogDomainStock.propTypes = {
  onTakedown: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  cluster: PropTypes.object.isRequired,
}

const wrap = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
`
