import {
  QDefineConstEnums,
  QNumber,
  QEnumString,
  QBoolean,
  QString,
  QSchema,
  objectWithKeysAsValues,
  QDate,
  QAny,
} from '../core'
import memoize from 'lodash/memoize'

export const FILE_SOURCES = QDefineConstEnums(
  'CASE_GENERAL_FILES',
  'CASE_GENERAL_FILES_SCREENING_BREACH_CONTRACT',
  'CASE_GENERAL_FILES_SCREENING_CONTACT_USER',
  'CASE_IMAGE_US_REGISTRATION_FILES',
  'CASE_IMAGE_LICENSING_HISTORY_FILES',
  'CASE_IMAGE_IMPORT',
  'CASE_IMAGE_IMPORT_EXISTING',
  'UNKNOWN'
)

export const FILE_SCHEMA_NAMES = objectWithKeysAsValues({
  id: 'id',
  key: 'key',
  type: 'type',
  fileName: 'fileName',
  extension: 'extension',
  url: 'url',
  previewLocalUrl: 'previewLocalUrl',
  isRemote: 'isRemote',
  isPaused: 'isPaused',
  size: 'size',
  lastModifiedDate: 'lastModifiedDate',
  source: 'source',
  uploadComplete: 'uploadComplete',
  uploadStarted: 'uploadStarted',
  bytesTotal: 'bytesTotal',
  bytesUploaded: 'bytesUploaded',
  percentage: 'percentage',
  error: 'error',
})

export const FAIL_ON_FALSE = value => {
  return !!value
}

export const FILE_SCHEMA_TYPES = strip => ({
  [FILE_SCHEMA_NAMES.id]: QString.required(),
  [FILE_SCHEMA_NAMES.key]: QString.required(),
  [FILE_SCHEMA_NAMES.type]: QString.required(),
  [FILE_SCHEMA_NAMES.fileName]: QString.required(),
  [FILE_SCHEMA_NAMES.extension]: QString.required(),
  [FILE_SCHEMA_NAMES.url]: QString.url().required(),
  [FILE_SCHEMA_NAMES.previewLocalUrl]: QString.strip(strip),
  [FILE_SCHEMA_NAMES.isRemote]: QBoolean.required(),
  [FILE_SCHEMA_NAMES.isPaused]: QBoolean.strip(strip),
  [FILE_SCHEMA_NAMES.size]: QNumber.positive().required(),
  [FILE_SCHEMA_NAMES.lastModifiedDate]: QDate,
  [FILE_SCHEMA_NAMES.source]: QEnumString(FILE_SOURCES)
    .default(FILE_SOURCES.UNKNOWN)
    .required(),
  [FILE_SCHEMA_NAMES.uploadComplete]: QBoolean.required().test(
    'NO-UNCOMPLETED-URL',
    'Upload is not complete',
    FAIL_ON_FALSE
  ),
  [FILE_SCHEMA_NAMES.uploadStarted]: QBoolean.strip(strip),
  [FILE_SCHEMA_NAMES.bytesTotal]: QNumber.positive().strip(strip),
  [FILE_SCHEMA_NAMES.bytesUploaded]: QNumber.positive().strip(strip),
  [FILE_SCHEMA_NAMES.percentage]: QNumber.positive().strip(strip),
  [FILE_SCHEMA_NAMES.error]: QAny.test(
    'file-error',
    'File contains error',
    function fileError(v) {
      return v == null
    }
  ),
})

export const transformFromUppyFile = function transformFromUppyFile(
  data,
  source
) {
  if (typeof data !== 'object' || data == null) {
    const errorMessage = `[transformFromUppyFile] cannot transform provided data to FileSchema object`
    const error = new Error(errorMessage)

    error.data = data

    console.warn(errorMessage, '\n', 'Expected object, instead got:', data)

    throw error
  }

  const rawFileData = {}

  if (typeof source === 'string' && source in FILE_SOURCES) {
    rawFileData.source = source
  } else {
    rawFileData.source = FILE_SOURCES.UNKNOWN
  }

  const topProperties = [
    'id',
    'extension',
    { key: 'name', replace: 'fileName' },
    'isRemote',
    'isPaused',
    'size',
    'type',
  ]
  const dataProperties = ['lastModifiedDate']

  const map = source => prop => {
    const isObject = typeof prop === 'object'
    const key = isObject ? prop.key : prop
    const replace = isObject ? prop.replace : null

    if (!(source[key] == null)) {
      rawFileData[replace || key] = source[key]
    }
  }

  topProperties.forEach(map(data))

  if (typeof data.data === 'object' && data.data !== null) {
    dataProperties.forEach(map(data.data))
  }
  // if (typeof data.progress === 'object' && data.progress !== null) {
  //   progressProperties.forEach(map(data.progress))
  // }

  return FileSchema({ strip: false }).cast(rawFileData)
}

export const FileSchema = memoize(
  ({ strip }) => QSchema.shape(FILE_SCHEMA_TYPES(strip)),
  function() {
    return JSON.stringify(arguments)
  }
)
