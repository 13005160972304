import { gql } from '@apollo/client'

export default gql`
  mutation updateMatchPreference(
    $countries: [String]
    $categories: [String]
    $platforms: [String]
    $userContent: USER_CONTENT_INPUT
  ) {
    updateMatchPreference(
      countries: $countries
      categories: $categories
      platforms: $platforms
      userContent: $userContent
    ) {
      _id
      hidden {
        countries
        categories
        platforms {
          twitter
          facebook
          instagram
          linkedin
          pinterest
          youtube
          blogspot
          wordpress
          tumblr
          alamy
          shutterstock
          getty
          etsy
          amazon
          ebay
          aliexpress
          flickr
        }
        userContent {
          twitter
          facebook
          linkedin
          instagram
          pinterest
          websites
        }
      }
    }
  }
`
