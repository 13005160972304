import * as React from 'react'
import { css } from 'react-emotion'
import propTypes from 'prop-types'

const iconMagnifierGlass = css`
  fill: currentColor;
`
const IconMagnifierGlass = ({ height = 19.16, width }) => (
  <svg viewBox="0 0 19.164 20.484" height={height} width={width}>
    <path
      id="Path_602"
      data-name="Path 602"
      css={iconMagnifierGlass}
      d="M26.829,27.72a7.346,7.346,0,1,0-1.065.822l6.957,6.943.439-.422.5-.508Zm-4.985.632a6.008,6.008,0,1,1,6.008-6.008A6.015,6.015,0,0,1,21.843,28.351Z"
      transform="translate(-14.5 -15)"
    />
  </svg>
)
IconMagnifierGlass.propTypes = {
  height: propTypes.number,
  width: propTypes.number,
}

export default IconMagnifierGlass
