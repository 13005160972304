import {
  AUTH_REGISTER_SUCCESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SUBSCRIBE_SUCCESS,
  AUTH_DELETE_USER,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_EMAIL_SUCCESS,
} from '../modules/auth'
import {
  IMAGE_IGNORE_SUCCESS,
  IMAGE_UNIGNORE_SUCCESS,
  SERVICE_REMOVE_SUCCESS,
  IMAGE_DELETE_SUCCESS,
} from '../modules/images'

import { CLUSTERS_LOAD_SUCCESS } from '../modules/clusters'

const BLOCKER = __CLIENT__ ? Promise.delay(6000) : Promise.resolve()

const invokeMixpanel = async (method, ...args) => {
  await BLOCKER
  if (!window.mixpanel) {
    console.error(`Unable to invoke mixpanel method, no mixpanel instance found.`)
    return
  }
  setTimeout(() => {
    try {
      window.mixpanel[method].apply(window.mixpanel, args)
    } catch (e) {
      console.error(`Unable to invoke mixpanel method:`, e)
    }
  }, 50)
}

const invokeGoogleAnalytics = async (method, args = { options: {} }) => {
  await BLOCKER

  if (!window.gtag) {
    console.error(`Unable to invoke gtag method, no gtag instance found.`)
    return
  }
  setTimeout(() => {
    try {
      if (method === 'pageview') {
        // args.options.send_to = window.GA_TRACKER
        args.options.event_callback = function() {
          console.info('PAGEVIEW SUCCESS')
        }
        window.gtag('event', 'page_view', args.options)
      } else if (method === 'set') {
        console.info(args.options)
        args.options.event_callback = function() {
          console.info('USERID SET')
        }
        window.gtag('set', args.options)
      } else {
        args.options.event_callback = function() {
          console.info('EVENT SUCCESS')
        }
        window.gtag(method, args.eventAction, args.options)
      }
    } catch (e) {
      console.error(`Unable to invoke gtag method:`, e)
    }
  }, 50)
}

// const invokeFacebookAnalytics = async (method, ...args) => {
//   await BLOCKER
//   if (!window.fbq) {
//     console.error(`Unable to invoke fbq method, no fbq instance found.`)
//     return
//   }
//   setTimeout(() => {
//     try {
//       window.fbq.call(window.fbq, method, ...args)
//     } catch (e) {
//       console.error(`Unable to invoke fbq method:`, e)
//     }
//   }, 50)
// }

const handleLoginEvent = (action) => {
  const { user } = action.payload
  // invokeGoogleAnalytics('set', { options: { user_id: user._id } })
  invokeMixpanel('identify', user._id)
}

const handleRegistration = (action) => {
  const user = action.payload.user
  // invokeFacebookAnalytics('fbq', 'track', 'CompleteRegistration')
  invokeGoogleAnalytics('set', { options: { user_id: user._id } })
  window.gtag('event', 'Submit', {
    event_category: 'Signup',
    event_label: 'Complete_Registration',
    send_to: 'UA-90492712-1',
  })

  // set userId to all calls
  // invokeGoogleAnalytics('set', { options: { user_id: user._id } })
  // invokeGoogleAnalytics(
  //   'send',
  //   'event',
  //   'Signup',
  //   'Submit',
  //   'Complete_Registration'
  // )

  invokeMixpanel('identify', user._id)
}

const handleLogoutEvent = (action) => {
  invokeGoogleAnalytics('logout')
  invokeMixpanel('reset')
}

const handlePlanChange = (action) => {
  const kind = action.payload.kind

  invokeMixpanel('track', `Subscription.${kind}`, {
    'New Plan': action.payload.user.plan.title,
    'New Cycle': action.payload.user.plan.billing_period,
  })
}

const handleLocationChange = (action) => {
  if (action.payload.pathname === '/takedowns/submit/new') {
    const clusterId = action.payload.search.split('=')[1]
    invokeMixpanel('track', `Takedown.Start`, {
      'Cluster ID': clusterId,
    })
  }
}

const handleMatchesFilterApplied = (action) => {
  console.info('handleMatchesFilterApplied')
  const { query } = action.payload
  if (query.tags.length > 0) {
    console.info(query.tags)
    const filter = query.tags

    invokeGoogleAnalytics('pageview', {
      options: { page_path: '/c/vpv/matches/filter' },
    })
    invokeGoogleAnalytics('event', {
      eventAction: 'filter',
      options: {
        event_category: 'matches',
        event_label: 'Matches Filter Applied',
        value: filter,
      },
    })
  }
}

const observedActions = {
  '@@router/LOCATION_CHANGE': handleLocationChange,
  [AUTH_LOGIN_SUCCESS]: handleLoginEvent,
  [AUTH_REGISTER_SUCCESS]: handleRegistration,
  [AUTH_LOGOUT_SUCCESS]: handleLogoutEvent,
  [AUTH_SUBSCRIBE_SUCCESS]: handlePlanChange,
  [SERVICE_REMOVE_SUCCESS]: ({ service }) => invokeMixpanel('track', `Images.Unlink`, { service }),
  [AUTH_DELETE_USER]: () => invokeMixpanel('track', `Account.Delete`),
  [IMAGE_IGNORE_SUCCESS]: () => invokeMixpanel('track', `Image.Ignore`),
  [IMAGE_UNIGNORE_SUCCESS]: () => invokeMixpanel('track', `Image.Unignore`),
  [IMAGE_DELETE_SUCCESS]: () => invokeMixpanel('track', `Image.Delete`),
  [IMAGE_DELETE_SUCCESS]: () => invokeMixpanel('track', `Image.Inspect`),
  [AUTH_CHANGE_PASSWORD_SUCCESS]: () => invokeMixpanel('track', `Account.Change.Password`),
  [AUTH_CHANGE_EMAIL_SUCCESS]: () => invokeMixpanel('track', `Account.Change.Email`),
  [CLUSTERS_LOAD_SUCCESS]: handleMatchesFilterApplied,
}

export default (store) => (next) => (action) => {
  if (action.type in observedActions) {
    observedActions[action.type](action)
  }

  return next(action)
}