import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import uuidV4 from 'uuid'
import { pixsyUppyStyle } from '../../../../common/UppyDashboard'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
// import GoogleDrive from '@uppy/google-drive'
// import Dropbox from '@uppy/dropbox'
import XHRUpload from '@uppy/xhr-upload'
import { notifSend } from 'redux-notifications/lib/actions'
import { S3_UPLOAD, S3_UPLOAD_FINISH, s3Upload, saveImageDirect } from '../../../../redux/modules/images'
import { BUCKETS } from 'pixsy-constants'

const getXHROptions = () => {
  const bucket = global.PRODUCTION_DB ? BUCKETS.IMAGE_UPLOAD : BUCKETS.IMAGE_UPLOAD_DEV
  const server = bucket.endsWith('dev') ? 's3' : 's3-accelerate'
  return {
    endpoint: `https://${bucket}.${server}.amazonaws.com`,
    limit: 3,
    fieldName: 'file',
    metaFields: ['key', 'acl', 'Content-Type', 'AWSAccessKeyId', 'success_action_status', 'Policy', 'Signature'],
    responseType: 'text',
    getResponseData: (responseText) => {
      const url = decodeURIComponent(
        responseText.substring(
          responseText.indexOf('<Location>') + 10, // '<Location>'.length
          responseText.indexOf('</Location>')
        )
      )
      return { url }
    },
  }
}

function useUppyImport(props) {
  const { user, policy, s3Upload, notifSend, saveImageDirect } = props

  const notify = useCallback((type, message) => {
    notifSend({ kind: type, message, dismissAfter: 10e3 })
  }, [])

  const onFileAdded = useCallback(
    (file, uppyInstance) => {
      const userId = user._id
      const { name, extension } = file
      const baseName = name.replace(`.${extension}`, '')
      const key = `${userId}/${uuidV4()}.${extension}`
      uppyInstance.setFileMeta(file.id, {
        baseName,
        /* fields sent to aws request */
        key,
        acl: 'public-read',
        'Content-Type': file.type,
        AWSAccessKeyId: policy.AWSAccessKeyId,
        success_action_status: '201',
        Policy: policy.s3Policy,
        Signature: policy.s3Signature,
        /* fields sent to aws request */
      })
    },
    [policy]
  )

  const onUpload = useCallback((data) => {
    const numFiles = data.fileIDs.length
    if (numFiles === 0) return
    s3Upload(S3_UPLOAD)
    notify('info', `Importing ${numFiles} file${numFiles > 1 ? 's' : ''} from manual upload`)
    notify('info', 'Files will be automatically cleared after successful upload.')
  }, [])

  const onUploadSuccess = useCallback((file, data, ...rest) => {
    const {
      name: reference,
      meta: { key, baseName: title },
    } = file
    const payload = { key, url: data.uploadURL, title, reference }
    saveImageDirect(payload).catch(console.error)
  }, [])

  const onUploadError = useCallback((file, error) => {
    console.error('error with file:', file.id)
    console.error('error message:', error)
  }, [])

  const onUploadComplete = useCallback((result) => {
    s3Upload(S3_UPLOAD_FINISH)
    const successfulImages = result.successful.length
    const failedImages = result.failed.length
    if (successfulImages > 0) {
      notify('success', `Successfully uploaded ${successfulImages} file${successfulImages > 1 ? 's' : ''}.`)
      result.successful.forEach((file) => uppy.removeFile(file.id))
    }
    if (failedImages > 0) {
      notify(
        'warning',
        `Upload failed for ${failedImages} file${
          failedImages > 1 ? 's' : ''
        }, see upload dashboard to see affected files.`
      )
    }
  }, [])

  const uppy = React.useMemo(() => {
    const uppyOptions = {
      id: 'uppy-image-import',
      restrictions: {
        maxNumberOfFiles: UppyImport.MAX_NUMBER_FILES,
        allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
      },
    }

    return Uppy(uppyOptions)
      .use(XHRUpload, getXHROptions())
      .on('file-added', (file) => onFileAdded(file, uppy))
      .on('upload', onUpload)
      .on('upload-success', onUploadSuccess)
      .on('upload-error', onUploadError)
      .on('complete', onUploadComplete)
  }, [])

  useEffect(() => {
    return () => uppy.close()
  }, [])

  return uppy
}

const UppyImport = (props) => {
  const uppy = useUppyImport(props)

  return (
    <div className={pixsyUppyStyle}>
      <Dashboard
        uppy={uppy}
        note={`Select up to ${UppyImport.MAX_NUMBER_FILES} images of any size (jpg, png, gif)`}
        disableThumbnailGenerator
        width="100%"
      />
    </div>
  )
}

UppyImport.MAX_NUMBER_FILES = 1000

export default connect((state) => ({ user: state.auth.user, policy: state.policy.data }), {
  notifSend,
  saveImageDirect,
  s3Upload,
})(UppyImport)
