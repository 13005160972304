import React, { useEffect } from 'react'
import { css, cx } from 'react-emotion'
import { connect } from 'react-redux'
import ClusterListItem from './Item'
import sharedStyles from '../../../components/styles'
import EmptyResults from '../../../components/EmptyResults'
import useClusters from '../../../utils/useClusters'
import Loading from './Loading'
import Footer from './Footer'
import TagAsSeen from '../../../components/TagAsSeen'
import scrollToClusterInList from '../../../utils/scrollToClusterInList'
import { useMatchViewerStore } from '../../../store/context'

const ClusterList = ({ globalDispatch }) => {
  const { clusters, pageOptionProps, loading } = useClusters(globalDispatch)
  const {
    state: { scrollToClusterId },
    dispatch,
  } = useMatchViewerStore()

  useEffect(() => {
    if (!loading && !!scrollToClusterId) scrollToClusterInList(dispatch, scrollToClusterId)
  }, [loading])

  if (!loading && clusters.length === 0) {
    return <EmptyResults />
  }

  const containerClass = cx(sharedStyles.listContainer, {
    [styles.loading]: loading,
  })

  return (
    <div css={containerClass}>
      {loading ? (
        <Loading />
      ) : (
        <div className="clusterListItemsWrapper">
          {clusters.map((cluster) => (
            <TagAsSeen key={cluster._id} clusterId={cluster._id} clusterTags={cluster.tags} score={cluster.score}>
              <ClusterListItem
                cluster={{
                  ...cluster,
                  matches: cluster.matches.filter((m) => m.invalid !== true),
                }}
              />
            </TagAsSeen>
          ))}
          <Footer pageOptionProps={pageOptionProps} />
        </div>
      )}
    </div>
  )
}

const styles = {
  loading: css`
    .clusterListItemsWrapper {
      opacity: 0.5;
    }
  `,
}

ClusterList.whyDidYouRender = true

export default connect(null, (globalDispatch) => ({ globalDispatch }))(ClusterList)
